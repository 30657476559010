/**
 * Implementation of the FocusIT.DataContracts.Pulse.NGENTenant [DataContract].
 */
export class AccountUser {
  public FirstName: string = '';
  public LastName: string = '';
  public Email: string = '';
  public WorkPhone: string = '';
  public Fax: string = '';
  public MobilePhone: string = '';
  public NmlsId: string = '';
  public FaceBookURL: string = '';
  public TwitterURL: string = '';
  public LinkedInURL: string = '';
  public Role: any = [];
  public Logo: string = '';
  constructor(
    FirstName?: string,
    LastName?: string,
    Email?: string,
    WorkPhone?: string,
    Fax?: string,
    MobilePhone?: string,
    NmlsId?: string,
    FaceBookURL?: string,
    TwitterURL?: string,
    LinkedInURL?: string,
    Logo?: string,
    Role?: string
  ) {
    if (FirstName !== undefined && FirstName !== null) this.FirstName = FirstName;
    if (LastName !== undefined && LastName !== null) this.LastName = LastName;
    if (Email !== undefined && Email !== null) this.Email = Email;
    if (WorkPhone !== undefined && WorkPhone !== null) this.WorkPhone = WorkPhone;
    if (Fax !== undefined && Fax !== null) this.Fax = Fax;
    if (MobilePhone !== undefined && MobilePhone !== null) this.MobilePhone = MobilePhone;
    if (NmlsId !== undefined && NmlsId !== null) this.NmlsId = NmlsId;
    if (FaceBookURL !== undefined && FaceBookURL !== null) this.FaceBookURL = FaceBookURL;
    if (TwitterURL !== undefined && TwitterURL !== null) this.TwitterURL = TwitterURL;
    if (LinkedInURL !== undefined && LinkedInURL !== null) this.LinkedInURL = LinkedInURL;
    if (Logo !== undefined && Logo !== null) this.Logo = Logo;
    if (Role !== undefined && Role !== null) this.Role = Role;
  }
}

/**
 * Collection of FocusIT.DataContracts.Pulse.NGENTenant [DataContract] objects.
 */
export class NGENTenantCollection {
  Items: AccountUser[] = [];
}
