<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:15px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="row align-items-center" [formGroupName]="i" id="people_list_filter_new">
      <!-- <div class="col-auto">
        <label style="font-size: 16px; line-height: 16px; color: #2e2e2e;font-weight: 400;">{{ item.get('label').value
          }}</label>
      </div> -->
      <div class="col-12 mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" formControlName="operandDropDown" class="selectLabel" filterBy="name"
          [filter]="true" filterBy="OpDisplay" [style]="{'width':'98%'}"></p-dropdown>
      </div>
      <div class="col-12 d-flex p-new-added">
        <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1">
          <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1 =='Calendar'">
            <container-element [ngSwitch]="item.get('operandDropDown').value?.OpDisplay?.toLowerCase()">
              <i class="pi pi-reply replytosection col-1 me-2 ms-2"></i>
              <p-dropdown *ngSwitchCase="'month is'" placeholder="Select Month" [options]="monthsList"
                optionLabel="item_text" dataKey="item_id" id="txtRHSValue1{{ i }}" [style]="{'border-radius': '25px','width':'98%'}"
                [formControlName]="'txtRHSValue1'" filterBy="name" [filter]="false" filterBy="name"
                [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}">
              </p-dropdown>
              <!-- <p-calendar *ngSwitchCase="'month is'" view="month" dateFormat="M" id="txtRHSValue1{{ i }}"
                yearNavigator="false" [yearRange]="yearRange" [formControlName]="'txtRHSValue1'" 
                [readonlyInput]="true"
                [showIcon]="false" inputId="monthpicker">
              </p-calendar> -->


              <p-dropdown *ngSwitchCase="'year is'" placeholder="Select Year" [options]="YearList" optionLabel="item_id"
                optionValue="item_id" id="txtRHSValue1{{ i }}" [style]="{'border-radius': '25px','width':'98%'}"
                [formControlName]="'txtRHSValue1'" filterBy="item_id" [filter]="true" filterBy="item_id"
                [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}">
              </p-dropdown>

              <p-calendar *ngSwitchDefault dateFormat="mm/dd/yy" id="txtRHSValue1{{ i }}" [style]="{'border-radius': '25px'}"
                [formControlName]="'txtRHSValue1'" [readonlyInput]="true" [showIcon]="false" [inputId]="'icon'">
              </p-calendar>
              <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;margin-top: -4px;"
                *ngIf="item.get('txtRHSValue1')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue1')?.errors">
                Required.
              </div>
            </container-element>
          </ng-container>
          <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1 == 'Textbox'">
            <p-inputNumber [min]="1" [mode]="decimal" inputId="minmaxfraction" [minFractionDigits]="0" [maxlength]="3"
              id="txtRHSValue1{{ i }}" [formControlName]="'txtRHSValue1'" [style]="{'border-radius': '25px'}"
              [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}">
            </p-inputNumber>

            <!-- <input pInputText pKeyFilter="int" maxlength="50" min="1" class="form-control" pInputText type="number"
              id="txtRHSValue1{{ i }}" formControlName="txtRHSValue1"
              [ngClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}"> -->
            <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;margin-top: -4px;"
              *ngIf="item.get('txtRHSValue1')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue1')?.errors">
              Required.
            </div>
          </ng-container>
          <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1 == 'Select'">
            <i class="pi pi-reply replytosection col-1 me-2 ms-2"></i>
            <!-- optionValue="name" -->
            <p-dropdown placeholder="Select Month" [options]="monthsList" optionLabel="item_text" dataKey="item_id"
              id="txtRHSValue1{{ i }}" [formControlName]="'txtRHSValue1'" filterBy="name" class="selectLabel " [style]="{'border-radius': '25px','width': '140px'}"
              [filter]="false" filterBy="name" [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}">
            </p-dropdown>
            <!-- <span class="fw-bold">/</span> -->
            <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;margin-top: -4px;"
              *ngIf="item.get('txtRHSValue1')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue1')?.errors">
              Required.
            </div>

          </ng-container>
        </ng-container>
        <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
          <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl2 =='Calendar'">
            <p-calendar dateFormat="mm/dd/yy" id="txtRHSValue2{{ i }}" [formControlName]="'txtRHSValue2'" [style]="{'border-radius': '25px'}"
              [readonlyInput]="true" [showIcon]="false" [inputId]="'icon'">
            </p-calendar>
            <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;"
              *ngIf="item.get('txtRHSValue2')?.touched && item.get('operandDropDown').value?.RhsInputControl2 && item.get('txtRHSValue2')?.errors">
              Required.
            </div>
          </ng-container>

          <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl2 =='Select'">
            <p-dropdown placeholder="Select Day" [options]="daysList" optionLabel="item_text" dataKey="item_id"
              id="txtRHSValue2{{ i }}" [formControlName]="'txtRHSValue2'" filterBy="item_text" [filter]="false" [style]="{'border-radius': '25px','width': '132px'}"
              [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue2')?.errors}">
            </p-dropdown>
            <div style="color:#FC5151; font-size:11px;position:absolute;margin-left: 160px;font-weight:normal;"
              *ngIf="item.get('txtRHSValue2')?.touched && item.get('operandDropDown').value?.RhsInputControl2 && item.get('txtRHSValue2')?.errors">
              Required.
            </div>

          </ng-container>
        </ng-container>
      </div>
      <!-- <div class="col-sm-1">
        <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button"
          (click)="deleteRow(i)">
          <i class="pi pi-trash"></i>
        </span>
      </div> -->
    </div>
  </div>
  <!-- <div class="row" style="padding:15px 30px;">
    <div class="col-12 btn-no-radius">
      <p-button (click)="addMore()" icon="pi pi-plus" label=""></p-button>
    </div>
  </div> -->
  <!-- <hr class="m-0"> -->
  <div class="row" style="padding:15px 30px;">
    <div class="col-12 text-right">
      <!-- <a (click)="cancelFilter()" class="link-text"
        style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;font-weight: normal; line-height:34px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a> -->
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()"  label="Apply"></p-button>
    </div>
  </div>
</div>