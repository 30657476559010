import { Injectable } from '@angular/core';
import { ContactType } from 'src/models/enumerations';
import { BitwiseService } from './bitwise.service';

/**
* Utility service for working with the ContactType flag enumeration.
* Since the ContactType enumeration can be used as flags, the methods in this class are intended to aid in their implementation.
*/
@Injectable()
export class ContactTypeService {

  // **********************************************
  // DESCRIPTION CONSTANTS
  // !!! KEEP ContactTypeHelper.cs IN SYNC !!!
  // **********************************************

  /** ContactType description */
  readonly Borrower = 'Borrower';
  /** ContactType description */
  readonly CoBorrower = 'CoBorrower';
  /** ContactType description */
  readonly Lender = 'Lender';
  /** ContactType description */
  readonly Partner = 'Partner';
  /** ContactType description */
  readonly Builder = 'Builder';
  /** ContactType description */
  readonly BuyersAttorney = 'Buyers Attorney';
  /** ContactType description */
  readonly BuyersAgent = 'Buyers Agent';
  /** ContactType description */
  readonly ClosingAgent = 'Closing Agent';
  /** ContactType description */
  readonly FloodInsurance = 'Flood Insurance';
  /** ContactType description */
  readonly HazardInsurance = 'Hazard Insurance';
  /** ContactType description */
  readonly Investor = 'Investor';
  /** ContactType description */
  readonly MortgageBroker = 'Mortgage Broker';
  /** ContactType description */
  readonly MortgageInsurance = 'Mortgage Insurance';
  /** ContactType description */
  readonly RealEstateBrokerBuyer = 'Real Estate Broker Buyer';
  /** ContactType description */
  readonly TitleInsurance = 'Title Insurance';
  /** ContactType description */
  readonly RealEstateBrokerSeller = 'Real Estate Broker Seller';
  /** ContactType description */
  readonly Appraiser = 'Appraiser';
  /** ContactType description */
  readonly Referral = 'Referral';
  /** ContactType description */
  readonly SellersAttorney = 'Sellers Attorney';
  /** ContactType description */
  readonly PropertyTax = 'Property Tax';
  /** ContactType description */
  readonly Surveyor = 'Surveyor';
  /** ContactType description */
  readonly CustomAgent1 = 'Custom Agent1';
  /** ContactType description */
  readonly CustomAgent2 = 'Custom Agent2';
  /** ContactType description */
  readonly Executive = 'Executive';
  /** ContactType description */
  readonly BranchManager = 'Branch Manager';
  /** ContactType description */
  readonly LoanOfficer = 'Loan Officer';
  /** ContactType description */
  readonly LoanOfficerAssistant = 'Loan Officer Assistant';
  /** ContactType description */
  readonly Processor = 'Processor';
  /** ContactType description */
  readonly Underwriter = 'Underwriter';
  /** ContactType description */
  readonly DocumentPreparer = 'Document Preparer';
  /** ContactType description */
  readonly Shipper = 'Shipper';
  /** ContactType description */
  readonly JuniorProcessor = 'Junior Processor';
  /** ContactType description */
  readonly SellersAgent = 'Sellers Agent';
  /** ContactType description */
  readonly Prospect = 'Prospect';
  /** ContactType description */
  readonly Lead = 'Lead';
  /** ContactType description */
  readonly PulseUser = 'Pulse User';
  /** ContactType description */
  readonly Realtor = 'Realtor';

  /**
  * Default constructor
  */
  constructor(private bitwiseService: BitwiseService) { }

  /**
  * Contact types that originate from a LendingPad Loan
  */
  public LENDINGPAD_LOAN_CONTACTS: ContactType = (
    ContactType.Borrower |
    ContactType.CoBorrower |
    ContactType.BuyersAgent |
    ContactType.SellersAgent |
    ContactType.ClosingAgent |
    ContactType.LoanOfficer |
    ContactType.LoanOfficerAssistant |
    ContactType.Processor |
    ContactType.Appraiser |
    ContactType.HazardInsurance |
    ContactType.TitleInsurance);

  /**
  * Determines if the passed contact type flag(s) includes any of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are tested for inclusion.
  * @returns True if any of the specified contact type flag(s) are included, false otherwise.
  */
  public isAny(contactType: number, contactTypes: number): boolean { return (this.bitwiseService.AND(contactType, contactTypes) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes any of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are tested for inclusion.
  * @returns True if any of the specified contact type flag(s) are included, false otherwise.
  */
  public IsAny(contactType: number, contactTypes: ContactType[]): boolean {
    if ((contactTypes == null) || (contactTypes.length == 0))
      return false;
    contactTypes.forEach(function (type) {
      //if ((contactType & type) != 0)
      if (this.bitwiseService.AND(contactType, type) != 0)
        return true;
    });

    return false;
  }

  /**
  * Determines if the passed contact type flag(s) includes all of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are tested for inclusion.
  * @returns True if any of the specified contact type flag(s) are included, false otherwise.
  */
  public isAll(contactType: number, contactTypes: number): boolean { return (this.bitwiseService.AND(contactType, contactTypes) != 0); }


  /**
  * Determines if the passed contact type flag(s) includes all of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are tested for inclusion.
  * @returns True if any of the specified contact type flag(s) are included, false otherwise.
  */
  public IsAll(contactType: number, contactTypes: ContactType[]): boolean {
    if ((contactTypes == null) || (contactTypes.length == 0))
      return false;
    contactTypes.forEach(function (type) {
      //if ((contactType & type) == 0)
      if (this.bitwiseService.AND(contactType, type) == 0)
        return false;
    });

    return true;
  }

  /**
  * Adds all of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are to be added.
  * @returns Updated contact type flag(s).
  */
  public add(contactType: number, contactTypes: number): number { return this.bitwiseService.OR(contactType, contactTypes); }

  /**
  * Adds all of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are to be added.
  * @returns Updated contact type flag(s).
  */
  public Add(contactType: number, contactTypes: ContactType[]): number {
    let result: number = contactType;
    if ((contactTypes == null) || (contactTypes.length == 0))
      return result;

    contactTypes.forEach(function (type) {
      //result = (result | type);
      result = this.bitwiseService.OR(result, type);
    });

    return result;
  }

  /**
  * Removes all of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are to be added.
  * @returns Updated contact type flag(s).
  */
  public remove(contactType: number, contactTypes: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(contactTypes))); }

  /**
  * Removes all of the specified contact type flag(s).
  * @param contactType Contact type flag(s).
  * @param contactTypes Contact type flag(s) that are to be added.
  * @returns Updated contact type flag(s).
  */
  public Remove(contactType: number, contactTypes: ContactType[]): number {
    let result: number = contactType;
    if ((contactTypes == null) || (contactTypes.length == 0))
      return result;

    contactTypes.forEach(function (type) {
      result = (this.bitwiseService.AND(result, this.bitwiseService.NOT(type)));
    });

    return result;
  }

  /**
  * Parses the passed contact type flags into an array of ContactType enumeration values.
  * @param contactType Contact type flag(s) to be parsed.
  * @returns List of ContactType enumeration values.
  */
  public Parse(contactType: number): ContactType[] {
    let result: ContactType[] = [];

    // **********************************************
    // !!! KEEP ContactTypeHelper.cs IN SYNC !!!
    // !!! Parse and Descriptions Methods !!!
    // **********************************************

    // **********************************************
    // Placed in Priority Order
    // **********************************************
    if (this.IsLoanOfficer(contactType))
      result.push(ContactType.LoanOfficer);

    if (this.IsLoanOfficerAssistant(contactType))
      result.push(ContactType.LoanOfficerAssistant);

    if (this.IsProcessor(contactType))
      result.push(ContactType.Processor);

    if (this.IsBorrower(contactType))
      result.push(ContactType.Borrower);

    if (this.IsCoBorrower(contactType))
      result.push(ContactType.CoBorrower);

    if (this.IsRealEstateBrokerBuyer(contactType))
      result.push(ContactType.RealEstateBrokerBuyer);

    if (this.IsBuyersAgent(contactType))
      result.push(ContactType.BuyersAgent);

    if (this.IsRealEstateBrokerSeller(contactType))
      result.push(ContactType.RealEstateBrokerSeller);

    if (this.IsClosingAgent(contactType))
      result.push(ContactType.ClosingAgent);

    if (this.IsSellersAgent(contactType))
      result.push(ContactType.SellersAgent);

    // Lending Pad = Appraisal Company
    if (this.IsAppraiser(contactType))
      result.push(ContactType.Appraiser);

    if (this.IsTitleInsurance(contactType))
      result.push(ContactType.TitleInsurance);

    if (this.IsFloodInsurance(contactType))
      result.push(ContactType.FloodInsurance);

    if (this.IsHazardInsurance(contactType))
      result.push(ContactType.HazardInsurance);

    if (this.IsSellersAttorney(contactType))
      result.push(ContactType.SellersAttorney);

    if (this.IsReferral(contactType))
      result.push(ContactType.Referral);

    if (this.IsLead(contactType))
      result.push(ContactType.Lead);

    if (this.IsRealtor(contactType))
      result.push(ContactType.Realtor);
    // **********************************************

    //// **********************************************
    //// Lending Pad
    //// **********************************************
    //if (this.IsLoanOfficerAssistant(contactType))
    //  result.push(ContactType.LoanOfficerAssistant);
    //// **********************************************

    // **********************************************
    // Not yet in Priority Order
    // **********************************************
    if (this.IsLender(contactType))
      result.push(ContactType.Lender);

    if (this.IsPartner(contactType))
      result.push(ContactType.Partner);

    if (this.IsBuilder(contactType))
      result.push(ContactType.Builder);

    if (this.IsBuyersAttorney(contactType))
      result.push(ContactType.BuyersAttorney);

    if (this.IsInvestor(contactType))
      result.push(ContactType.Investor);

    if (this.IsMortgageBroker(contactType))
      result.push(ContactType.MortgageBroker);

    if (this.IsMortgageInsurance(contactType))
      result.push(ContactType.MortgageInsurance);

    if (this.IsPropertyTax(contactType))
      result.push(ContactType.PropertyTax);

    if (this.IsSurveyor(contactType))
      result.push(ContactType.Surveyor);

    if (this.IsCustomAgent1(contactType))
      result.push(ContactType.CustomAgent1);

    if (this.IsCustomAgent2(contactType))
      result.push(ContactType.CustomAgent2);

    if (this.IsExecutive(contactType))
      result.push(ContactType.Executive);

    if (this.IsBranchManager(contactType))
      result.push(ContactType.BranchManager);

    if (this.IsUnderwriter(contactType))
      result.push(ContactType.Underwriter);

    if (this.IsDocumentPreparer(contactType))
      result.push(ContactType.DocumentPreparer);

    if (this.IsShipper(contactType))
      result.push(ContactType.Shipper);

    if (this.IsJuniorProcessor(contactType))
      result.push(ContactType.JuniorProcessor);

    if (this.IsProspect(contactType))
      result.push(ContactType.Prospect);
    // **********************************************

    // ALWAYS LAST
    if (this.IsPulseUser(contactType))
      result.push(ContactType.PulseUser);

    return result;
  }

  // ********************************************************************************
  // Individual Contact Type Determination
  // ********************************************************************************

  /**
  * Determines if the passed contact type flag(s) includes Borrower.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Borrower, false otherwise.
  */
  public IsBorrower(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Borrower) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes CoBorrower.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes CoBorrower, false otherwise. 
  */
  public IsCoBorrower(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.CoBorrower) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Lender.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Lender, false otherwise. 
  */
  public IsLender(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Lender) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Partner.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Partner, false otherwise. 
  */
  public IsPartner(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Partner) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Builder.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Builder, false otherwise. 
  */
  public IsBuilder(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Builder) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes BuyersAttorney.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes BuyersAttorney, false otherwise. 
  */
  public IsBuyersAttorney(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.BuyersAttorney) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes BuyersAgent.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes BuyersAgent, false otherwise. 
  */
  public IsBuyersAgent(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.BuyersAgent) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes ClosingAgent.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes ClosingAgent, false otherwise. 
  */
  public IsClosingAgent(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.ClosingAgent) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes FloodInsurance.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes FloodInsurance, false otherwise. 
  */
  public IsFloodInsurance(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.FloodInsurance) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes HazardInsurance.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes HazardInsurance, false otherwise. 
  */
  public IsHazardInsurance(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.HazardInsurance) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Investor.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Investor, false otherwise. 
  */
  public IsInvestor(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Investor) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes MortgageBroker.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes MortgageBroker, false otherwise. 
  */
  public IsMortgageBroker(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.MortgageBroker) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes MortgageInsurance.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes MortgageInsurance, false otherwise. 
  */
  public IsMortgageInsurance(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.MortgageInsurance) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes RealEstateBrokerBuyer.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes RealEstateBrokerBuyer, false otherwise. 
  */
  public IsRealEstateBrokerBuyer(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.RealEstateBrokerBuyer) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes TitleInsurance.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes TitleInsurance, false otherwise. 
  */
  public IsTitleInsurance(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.TitleInsurance) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes RealEstateBrokerSeller.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes RealEstateBrokerSeller, false otherwise. 
  */
  public IsRealEstateBrokerSeller(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.RealEstateBrokerSeller) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Appraiser.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Appraiser, false otherwise. 
  */
  public IsAppraiser(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Appraiser) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Referral.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Referral, false otherwise. 
  */
  public IsReferral(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Referral) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes SellersAttorney.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes SellersAttorney, false otherwise. 
  */
  public IsSellersAttorney(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.SellersAttorney) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes PropertyTax.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes PropertyTax, false otherwise. 
  */
  public IsPropertyTax(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.PropertyTax) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Surveyor.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Surveyor, false otherwise. 
  */
  public IsSurveyor(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Surveyor) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes CustomAgent1.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes CustomAgent1, false otherwise. 
  */
  public IsCustomAgent1(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.CustomAgent1) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes CustomAgent2.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes CustomAgent2, false otherwise. 
  */
  public IsCustomAgent2(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.CustomAgent2) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Executive.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Executive, false otherwise. 
  */
  public IsExecutive(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Executive) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes BranchManager.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes BranchManager, false otherwise. 
  */
  public IsBranchManager(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.BranchManager) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes LoanOfficer.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes LoanOfficer, false otherwise. 
  */
  public IsLoanOfficer(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.LoanOfficer) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes LoanOfficerAssistant.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes LoanOfficerAssistant, false otherwise. 
  */
  public IsLoanOfficerAssistant(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.LoanOfficerAssistant) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Processor.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Processor, false otherwise. 
  */
  public IsProcessor(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Processor) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Underwriter.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Underwriter, false otherwise. 
  */
  public IsUnderwriter(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Underwriter) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes DocumentPreparer.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes DocumentPreparer, false otherwise. 
  */
  public IsDocumentPreparer(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.DocumentPreparer) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Shipper.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Shipper, false otherwise. 
  */
  public IsShipper(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Shipper) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes JuniorProcessor.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes JuniorProcessor, false otherwise. 
  */
  public IsJuniorProcessor(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.JuniorProcessor) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes SellersAgent.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes SellersAgent, false otherwise. 
  */
  public IsSellersAgent(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.SellersAgent) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Prospect.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Prospect, false otherwise. 
  */
  public IsProspect(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Prospect) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes Lead.
  * @param contactType Contact type flag(s).
        * @returns True if the contact type flag(s) includes Lead, false otherwise.
  */
  public IsLead(contactType: number) { return (this.bitwiseService.AND(contactType, ContactType.Lead) != 0); }

  /**
  * Determines if the passed contact type flag(s) includes PulseUser.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Lead, false otherwise.
  */
  public IsPulseUser(contactType: number) {
    return (this.bitwiseService.AND(contactType, ContactType.PulseUser) != 0);
  }

  /**
  * Determines if the passed contact type flag(s) includes Realtor.
  * @param contactType Contact type flag(s).
  * @returns True if the contact type flag(s) includes Lead, false otherwise.
  */
  public IsRealtor(contactType: number) {
    return (this.bitwiseService.AND(contactType, ContactType.Realtor) != 0);
  }

  // ********************************************************************************
  // End Individual Contact Type Determination
  // ********************************************************************************

  // ********************************************************************************
  // Add Individual Contact Type
  // ********************************************************************************

  /**
  * Adds the Borrower contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddBorrower(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Borrower); }

  /**
  * Adds the CoBorrower contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddCoBorrower(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.CoBorrower); }

  /**
  * Adds the Lender contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddLender(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Lender); }

  /**
  * Adds the Partner contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddPartner(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Partner); }

  /**
  * Adds the Builder contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddBuilder(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Builder); }

  /**
  * Adds the BuyersAttorney contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddBuyersAttorney(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.BuyersAttorney); }

  /**
  * Adds the BuyersAgent contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddBuyersAgent(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.BuyersAgent); }

  /**
  * Adds the ClosingAgent contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddClosingAgent(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.ClosingAgent); }

  /**
  * Adds the FloodInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddFloodInsurance(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.FloodInsurance); }

  /**
  * Adds the HazardInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddHazardInsurance(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.HazardInsurance); }

  /**
  * Adds the Investor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddInvestor(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Investor); }

  /**
  * Adds the MortgageBroker contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddMortgageBroker(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.MortgageBroker); }

  /**
  * Adds the MortgageInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddMortgageInsurance(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.MortgageInsurance); }

  /**
  * Adds the RealEstateBrokerBuyer contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddRealEstateBrokerBuyer(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.RealEstateBrokerBuyer); }

  /**
  * Adds the TitleInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddTitleInsurance(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.TitleInsurance); }

  /**
  * Adds the RealEstateBrokerSeller contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddRealEstateBrokerSeller(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.RealEstateBrokerSeller); }

  /**
  * Adds the Appraiser contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddAppraiser(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Appraiser); }

  /**
  * Adds the Referral contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddReferral(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Referral); }

  /**
  * Adds the SellersAttorney contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddSellersAttorney(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.SellersAttorney); }

  /**
  * Adds the PropertyTax contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddPropertyTax(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.PropertyTax); }

  /**
  * Adds the Surveyor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddSurveyor(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Surveyor); }

  /**
  * Adds the CustomAgent1 contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddCustomAgent1(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.CustomAgent1); }

  /**
  * Adds the CustomAgent2 contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddCustomAgent2(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.CustomAgent2); }

  /**
  * Adds the Executive contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddExecutive(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Executive); }

  /**
  * Adds the BranchManager contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddBranchManager(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.BranchManager); }

  /**
  * Adds the LoanOfficer contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddLoanOfficer(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.LoanOfficer); }

  /**
  * Adds the LoanOfficerAssistant contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddLoanOfficerAssistant(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.LoanOfficerAssistant); }

  /**
  * Adds the Processor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddProcessor(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Processor); }

  /**
  * Adds the Underwriter contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddUnderwriter(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Underwriter); }

  /**
  * Adds the DocumentPreparer contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddDocumentPreparer(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.DocumentPreparer); }

  /**
  * Adds the Shipper contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddShipper(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Shipper); }

  /**
  * Adds the JuniorProcessor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddJuniorProcessor(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.JuniorProcessor); }

  /**
  * Adds the SellersAgent contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddSellersAgent(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.SellersAgent); }

  /**
  * Adds the Prospect contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddProspect(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Prospect); }

  /**
  * Adds the Lead contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddLead(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Lead); }

  /**
  * Adds the PulseUser contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddPulseUser(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.PulseUser); }

  /**
  * Adds the Realtor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public AddRealtor(contactType: number): number { return this.bitwiseService.OR(contactType, ContactType.Realtor); }

  // ********************************************************************************
  // End Add Individual Contact Type
  // ********************************************************************************


  // ********************************************************************************
  // Remove Individual Contact Type
  // ********************************************************************************

  /**
  * Removes the Borrower contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveBorrower(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Borrower))); }

  /**
  * Removes the CoBorrower contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveCoBorrower(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.CoBorrower))); }

  /**
  * Removes the Lender contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveLender(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Lender))); }

  /**
  * Removes the Partner contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemovePartner(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Partner))); }

  /**
  * Removes the Builder contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveBuilder(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Builder))); }

  /**
  * Removes the BuyersAttorney contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveBuyersAttorney(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.BuyersAttorney))); }

  /**
  * Removes the BuyersAgent contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveBuyersAgent(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.BuyersAgent))); }

  /**
  * Removes the ClosingAgent contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveClosingAgent(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.ClosingAgent))); }

  /**
  * Removes the FloodInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveFloodInsurance(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.FloodInsurance))); }

  /**
  * Removes the HazardInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveHazardInsurance(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.HazardInsurance))); }

  /**
  * Removes the Investor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveInvestor(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Investor))); }

  /**
  * Removes the MortgageBroker contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveMortgageBroker(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.MortgageBroker))); }

  /**
  * Removes the MortgageInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveMortgageInsurance(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.MortgageInsurance))); }

  /**
  * Removes the RealEstateBrokerBuyer contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveRealEstateBrokerBuyer(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.RealEstateBrokerBuyer))); }

  /**
  * Removes the TitleInsurance contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveTitleInsurance(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.TitleInsurance))); }

  /**
  * Removes the RealEstateBrokerSeller contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveRealEstateBrokerSeller(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.RealEstateBrokerSeller))); }

  /**
  * Removes the Appraiser contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveAppraiser(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Appraiser))); }

  /**
  * Removes the Referral contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveReferral(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Referral))); }

  /**
  * Removes the SellersAttorney contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveSellersAttorney(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.SellersAttorney))); }

  /**
  * Removes the PropertyTax contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemovePropertyTax(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.PropertyTax))); }

  /**
  * Removes the Surveyor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveSurveyor(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Surveyor))); }

  /**
  * Removes the CustomAgent1 contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveCustomAgent1(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.CustomAgent1))); }

  /**
  * Removes the CustomAgent2 contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveCustomAgent2(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.CustomAgent2))); }

  /**
  * Removes the Executive contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveExecutive(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Executive))); }

  /**
  * Removes the BranchManager contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveBranchManager(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.BranchManager))); }

  /**
  * Removes the LoanOfficer contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveLoanOfficer(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.LoanOfficer))); }

  /**
  * Removes the LoanOfficerAssistant contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveLoanOfficerAssistant(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.LoanOfficerAssistant))); }

  /**
  * Removes the Processor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveProcessor(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Processor))); }

  /**
  * Removes the Underwriter contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveUnderwriter(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Underwriter))); }

  /**
  * Removes the DocumentPreparer contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveDocumentPreparer(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.DocumentPreparer))); }

  /**
  * Removes the Shipper contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveShipper(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Shipper))); }

  /**
  * Removes the JuniorProcessor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveJuniorProcessor(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.JuniorProcessor))); }

  /**
  * Removes the SellersAgent contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveSellersAgent(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.SellersAgent))); }

  /**
  * Removes the Prospect contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveProspect(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Prospect))); }

  /**
  * Removes the Lead contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveLead(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Lead))); }

  /**
  * Removes the PulseUser contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemovePulseUser(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.PulseUser))); }

  /**
  * Removes the Realtor contact type flag.
  * @param contactType Contact type flag(s).
  * @returns Updated contact type flag(s).
  */
  public RemoveRealtor(contactType: number): number { return (this.bitwiseService.AND(contactType, this.bitwiseService.NOT(ContactType.Realtor))); }

  // ********************************************************************************
  // End Remove Individual Contact Type
  // ********************************************************************************

  // ********************************************************************************
  // Conversion To String
  // ********************************************************************************

  /**
  * Returns a comma separated string of the passed contact type flag(s).
  * 
  * @param contactType Contact type flag(s) to be parsed.
  * @returns Comma separated string of contact type descriptions.
  */
  public Descriptions(contactType: number): string {
    let result: string = '';

    // **********************************************
    // !!! KEEP ContactTypeHelper.cs IN SYNC !!!
    // !!! Parse and Descriptions Methods !!!
    // **********************************************

    // **********************************************
    // Placed in Priority Order
    // **********************************************
    if (this.IsLoanOfficer(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.LoanOfficer);

    if (this.IsLoanOfficerAssistant(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.LoanOfficerAssistant);

    if (this.IsProcessor(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Processor);

    if (this.IsBorrower(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Borrower);

    if (this.IsCoBorrower(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.CoBorrower);

    if (this.IsRealEstateBrokerBuyer(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.RealEstateBrokerBuyer);

    if (this.IsBuyersAgent(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.BuyersAgent);

    if (this.IsRealEstateBrokerSeller(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.RealEstateBrokerSeller);

    if (this.IsClosingAgent(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.ClosingAgent);

    if (this.IsSellersAgent(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.SellersAgent);

    // Lending Pad = Appraisal Company
    if (this.IsAppraiser(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Appraiser);

    if (this.IsTitleInsurance(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.TitleInsurance);

    if (this.IsFloodInsurance(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.FloodInsurance);

    if (this.IsHazardInsurance(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.HazardInsurance);

    if (this.IsSellersAttorney(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.SellersAttorney);

    if (this.IsReferral(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Referral);

    if (this.IsLead(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Lead);

    if (this.IsRealtor(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Realtor);
    // **********************************************

    //// **********************************************
    //// Lending Pad
    //// **********************************************
    //if (this.IsLoanOfficerAssistant(contactType))
    //  result += (((result.length > 0) ? ', ' : '') + this.LoanOfficerAssistant);
    //// **********************************************

    // **********************************************
    // Not yet in Priority Order
    // **********************************************
    if (this.IsLender(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Lender);

    if (this.IsPartner(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Partner);

    if (this.IsBuilder(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Builder);

    if (this.IsBuyersAttorney(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.BuyersAttorney);

    if (this.IsInvestor(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Investor);

    if (this.IsMortgageBroker(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.MortgageBroker);

    if (this.IsMortgageInsurance(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.MortgageInsurance);

    if (this.IsPropertyTax(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.PropertyTax);

    if (this.IsSurveyor(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Surveyor);

    if (this.IsCustomAgent1(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.CustomAgent1);

    if (this.IsCustomAgent2(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.CustomAgent2);

    if (this.IsExecutive(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Executive);

    if (this.IsBranchManager(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.BranchManager);

    if (this.IsUnderwriter(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Underwriter);

    if (this.IsDocumentPreparer(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.DocumentPreparer);

    if (this.IsShipper(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Shipper);

    if (this.IsJuniorProcessor(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.JuniorProcessor);

    if (this.IsProspect(contactType))
      result += (((result.length > 0) ? ', ' : '') + this.Prospect);

    // ALWAYS LAST
    if (this.IsPulseUser(contactType)) {
      result += (((result.length > 0) ? ', ' : '') + this.PulseUser);
    }

    return result;
  }

  /**
  * Returns the display string of the passed contact type flag(s).
  * The display string is the first description returned by the Descriptions() method.
  * Result depends upon the order of precedence defined in Descriptions() method.
  * @param contactType Contact type flag(s) to be parsed.
  * @param addEllipse Adds '...' to the end of the string indicating the contact has multiple types defined (default = false).
  * @returns Display string of the passed contact type flag(s).
  */
  public DisplayString(contactType: number, addEllipse: boolean = false): string {
    let descriptions: string = this.Descriptions(contactType);
    let result: string = '';

    var parts = descriptions.split(",");
    if (parts.length > 0)
      result = parts[0];

    if ((parts.length > 1) && ((addEllipse === undefined) || (addEllipse)))
      result += "...";

    return result;
  }

  // ********************************************************************************
  // End Conversion To String
  // ********************************************************************************

  // ********************************************************************************
  // Conversion From String
  // ********************************************************************************

  /**
  * Returns the contact type flag(s) parsed from a comma separated string of the passed contact type flag descriptions.
  * @param description Comma separated string of the passed contact type flags descriptions.
  * @returns Contact type flag(s).
  */
  public ParseDescriptions(descriptions: string): number {
    let result: number = 0;
    let parts: string[] = descriptions.split(",");

    // **********************************************
    // !!! KEEP ContactTypeHelper.cs IN SYNC !!!
    // !!! Parse and Descriptions Methods !!!
    // **********************************************

    // **********************************************
    // Placed in Priority Order
    // **********************************************

    for (var x = 0; x < parts.length; x++) {
      if (parts[x].indexOf(this.LoanOfficer) >= 0)
        result = this.add(ContactType.LoanOfficer, result);

      else if (parts[x].indexOf(this.Processor) >= 0)
        result = this.add(ContactType.Processor, result);

      else if (parts[x].indexOf(this.Borrower) >= 0)
        result = this.add(ContactType.Borrower, result);

      else if (parts[x].indexOf(this.CoBorrower) >= 0)
        result = this.add(ContactType.CoBorrower, result);

      else if (parts[x].indexOf(this.RealEstateBrokerBuyer) >= 0)
        result = this.add(ContactType.RealEstateBrokerBuyer, result);

      else if (parts[x].indexOf(this.BuyersAgent) >= 0)
        result = this.add(ContactType.BuyersAgent, result);

      else if (parts[x].indexOf(this.RealEstateBrokerSeller) >= 0)
        result = this.add(ContactType.RealEstateBrokerSeller, result);

      else if (parts[x].indexOf(this.ClosingAgent) >= 0)
        result = this.add(ContactType.ClosingAgent, result);

      else if (parts[x].indexOf(this.SellersAgent) >= 0)
        result = this.add(ContactType.SellersAgent, result);

      else if (parts[x].indexOf(this.Appraiser) >= 0)
        result = this.add(ContactType.Appraiser, result);

      else if (parts[x].indexOf(this.TitleInsurance) >= 0)
        result = this.add(ContactType.TitleInsurance, result);

      else if (parts[x].indexOf(this.FloodInsurance) >= 0)
        result = this.add(ContactType.FloodInsurance, result);

      else if (parts[x].indexOf(this.HazardInsurance) >= 0)
        result = this.add(ContactType.HazardInsurance, result);

      else if (parts[x].indexOf(this.SellersAttorney) >= 0)
        result = this.add(ContactType.SellersAttorney, result);

      else if (parts[x].indexOf(this.Referral) >= 0)
        result = this.add(ContactType.Referral, result);

      else if (parts[x].indexOf(this.Lead) >= 0)
        result = this.add(ContactType.Lead, result);

      else if (parts[x].indexOf(this.Realtor) >= 0)
        result = this.add(ContactType.Realtor, result);
      // **********************************************

      // **********************************************
      // Lending Pad
      // **********************************************
      else if (parts[x].indexOf(this.LoanOfficerAssistant) >= 0)
        result = this.add(ContactType.LoanOfficerAssistant, result);
      // **********************************************

      // **********************************************
      // Not yet in Priority Order
      // **********************************************

      else if (parts[x].indexOf(this.Lender) >= 0)
        result = this.add(ContactType.Lender, result);

      else if (parts[x].indexOf(this.Partner) >= 0)
        result = this.add(ContactType.Partner, result);

      else if (parts[x].indexOf(this.Builder) >= 0)
        result = this.add(ContactType.Builder, result);

      else if (parts[x].indexOf(this.BuyersAttorney) >= 0)
        result = this.add(ContactType.BuyersAttorney, result);

      else if (parts[x].indexOf(this.Investor) >= 0)
        result = this.add(ContactType.Investor, result);

      else if (parts[x].indexOf(this.MortgageBroker) >= 0)
        result = this.add(ContactType.MortgageBroker, result);

      else if (parts[x].indexOf(this.MortgageInsurance) >= 0)
        result = this.add(ContactType.MortgageInsurance, result);

      else if (parts[x].indexOf(this.PropertyTax) >= 0)
        result = this.add(ContactType.PropertyTax, result);

      else if (parts[x].indexOf(this.Surveyor) >= 0)
        result = this.add(ContactType.Surveyor, result);

      else if (parts[x].indexOf(this.CustomAgent1) >= 0)
        result = this.add(ContactType.CustomAgent1, result);

      else if (parts[x].indexOf(this.CustomAgent2) >= 0)
        result = this.add(ContactType.CustomAgent2, result);

      else if (parts[x].indexOf(this.Executive) >= 0)
        result = this.add(ContactType.Executive, result);

      else if (parts[x].indexOf(this.BranchManager) >= 0)
        result = this.add(ContactType.BranchManager, result);

      else if (parts[x].indexOf(this.JuniorProcessor) >= 0)
        result = this.add(ContactType.JuniorProcessor, result);

      else if (parts[x].indexOf(this.Underwriter) >= 0)
        result = this.add(ContactType.Underwriter, result);

      else if (parts[x].indexOf(this.DocumentPreparer) >= 0)
        result = this.add(ContactType.DocumentPreparer, result);

      else if (parts[x].indexOf(this.Shipper) >= 0)
        result = this.add(ContactType.Shipper, result);

      else if (parts[x].indexOf(this.Prospect) >= 0)
        result = this.add(ContactType.Prospect, result);
      // **********************************************

      // ALWAYS LAST
      else if (parts[x].indexOf(this.PulseUser) >= 0)
        result = this.add(ContactType.PulseUser, result);
    }

    return result;
  }


  /**
  * Returns the contact type flag(s) where their description contains the passed value (case insensitive).
  * @param filterValue The user entered global filter value.
  * @returns Contact type flag(s).
  */
  public GlobalFilterToContactType(filterValue: string): number {
    let result: number = 0;

    if (this.Borrower.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
      result = this.add(ContactType.Borrower, result);

    if (this.CoBorrower.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.CoBorrower, result);

    if (this.Lender.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Lender, result);

    if (this.Partner.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Partner, result);

    if (this.Builder.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Builder, result);

    if (this.BuyersAttorney.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.BuyersAttorney, result);

    if (this.BuyersAgent.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.BuyersAgent, result);

    if (this.ClosingAgent.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.ClosingAgent, result);

    if (this.FloodInsurance.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.FloodInsurance, result);

    if (this.HazardInsurance.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.HazardInsurance, result);

    if (this.Investor.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Investor, result);

    if (this.MortgageBroker.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.MortgageBroker, result);

    if (this.MortgageInsurance.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.MortgageInsurance, result);

    if (this.RealEstateBrokerBuyer.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.RealEstateBrokerBuyer, result);

    if (this.TitleInsurance.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.TitleInsurance, result);

    if (this.RealEstateBrokerSeller.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.RealEstateBrokerSeller, result);

    if (this.Appraiser.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Appraiser, result);

    if (this.Referral.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Referral, result);

    if (this.SellersAttorney.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.SellersAttorney, result);

    if (this.PropertyTax.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.PropertyTax, result);

    if (this.Surveyor.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Surveyor, result);

    if (this.CustomAgent1.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.CustomAgent1, result);

    if (this.CustomAgent2.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.CustomAgent2, result);

    if (this.Executive.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Executive, result);

    if (this.BranchManager.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.BranchManager, result);

    if (this.LoanOfficerAssistant.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.LoanOfficerAssistant, result);

    if (this.LoanOfficer.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.LoanOfficer, result);

    if (this.JuniorProcessor.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.JuniorProcessor, result);

    if (this.Processor.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Processor, result);

    if (this.Underwriter.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Underwriter, result);

    if (this.DocumentPreparer.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.DocumentPreparer, result);

    if (this.Shipper.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Shipper, result);

    if (this.SellersAgent.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.SellersAgent, result);

    if (this.Prospect.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Prospect, result);

    if (this.Lead.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Lead, result);

    if (this.PulseUser.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.PulseUser, result);

    if (this.Realtor.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0)
        result = this.add(ContactType.Realtor, result);

    return result;
  }

  // ********************************************************************************
  // End Conversion From String
  // ********************************************************************************
}
