import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation, AfterContentInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ToastService } from 'src/services/toast.service';
import { RequestResponse } from 'src/models/RequestResponse';
import { PagingData } from 'src/models/PagingData';
import { WhereClauseCriteria, WhereClauseCriteriaCollection } from 'src/models/WhereClauseCriteria';
import { AndOr, DbType, Group, SqlComparison } from 'src/models/enumerations';
import { userRoles, report } from 'src/models/userRoles';
import { Campaign } from 'src/models/Campaign';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { LoansService } from 'src/services/Loans.service';
import { UserManagementService } from 'src/services/userManagement.service';
import { TenantUser } from 'src/models/TenantUser';
import { environment } from 'src/environments/environment';
//import { ComponentSpecificService } from 'src/services/componentSpecific.service'; 
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'campaignDetailsDialog-root',
  templateUrl: './campaignDetailsDialog.component.html',
  styleUrls: ['./campaignDetailsDialog.component.scss'],
})
export class campaignDetailsDialogComponent implements OnInit, AfterViewInit {
  @Input() loanStatusList = [];
  @Input() userList = [];
  @Input() masterContactTypes = [];
  @Input() loanStatusTypeMasterList = [];
  @Input() loanAssignTypeRoles = [];
  @Input() isCompanyUserWithMarketing = false;
  @Input() campaignCategoryList = [];
  private user: UserToken;
  currentClientID: string;
  // #region Properties

  // used for showing/hiding spinner, progress bar or skeleton.
  loadingPage: boolean = false;
  // Standard Request/Response object. Includes Paging support if necessary
  webAPI: RequestResponse = new RequestResponse();

  // Lazy load data filtering support
  specifyingFilter: boolean = false;
  allCriteria: WhereClauseCriteriaCollection =
    new WhereClauseCriteriaCollection();
  // Data sorting support
  orderBy: string = '';

  // Horizontal scroll position
  currentHScrollPos: number = 0;

  whoValue: boolean = true;
  toValue: boolean = true;
  bccValue: boolean = true;

  reportList = report;
  selectedRole: string = '';
  selectedReport: string = '';
  dropdownSettings: IDropdownSettings = {};
  userDropdownSettings: IDropdownSettings = {};
  isFromEmailInValid = false;
  isToEmailInValid = false;
  isCCEmailInValid = false;
  isBCCEmailInValid = false;
  isCustomRoleSelected = false;
  isDisabled = false;
  /* Campaign details */
  campaignDetails: any = {
    campaignId: '',
    tenantUserIdCSV: '',
    fromLoanAssignTypeId: '',
    fromEmailAddress: '',
    fromEmailAddressName: '',
    fromContactOwner: false,
    toReportCSV: '',
    toContactTypeIdCSV: '',
    toLoanAssignTypeIdCSV: '',
    toEmailCSV: '',
    ccContactTypeIdCSV: '',
    ccLoanAssignTypeIdCSV: '',
    ccEmailCSV: '',
    bccContactTypeIdCSV: '',
    bccLoanAssignTypeIdCSV: '',
    bccEmaiCSV: '',
    isContactOnly: false,
    isFromRole: 0,
    fromRole: '',
    fromRoleOverrideEmailAddress: '',
    fromRoleOverrideName: '',
    isToReport: true,
    toCustomAddresses: '',
    toReportId: '',
    toReportCustomConditions: '',
    toReportName: '',
    toRecipientRoles: -1,
    ccCustomAddresses: '',
    ccRecipientRoles: -1,
    bccCustomAddresses: '',
    bccRecipientRoles: -1,
    campaignCategoryId: '',
    isRecommended: false,
    isQuickStart: false,
  };
  isUserListDisabled: boolean;

  // #endregion

  // #region Constructor

  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   */
  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    private loanService: LoansService,
    private userTokenService: UserTokenService
  ) {

  }

  // #endregion

  // #region Component Initialization

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    this.user = this.userTokenService.getUser();
    this.currentClientID = environment.clientId;
    this.userDropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 3,
      enableCheckAll: true,
      unSelectAllText: 'Unselect All',
    };

    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 3,
      unSelectAllText: 'Unselect All',
    };

    // if (this.isCompanyUserWithMarketing) {
    //   this.userDropdownSettings.enableCheckAll = false;
    // }

    // obtain data for the page
    //this.getData(this.webAPI.paging.currentPage);
  }

  onItemDeSelect(event) {
    //this.reselectOwner(event.item_id);
  }
  onDeSelectAll(event) {
    this.userList.forEach((item, index) => {
      if (item.campiagnOwner == true && !this.user.isCompanyAdmin()) {
        setTimeout(() => {
          this.campaignDetails.tenantUserIdCSV = this.getDataList(
            item.item_id,
            this.userList
          );
        }, 100);
      }
    });
  }

  onSelectAll(event) {
    let ids = '';
    this.userList.forEach((item, index) => {
      ids += item.item_id + ',';
    });
    ids = ids.slice(0, -1);
    setTimeout(() => {
      this.campaignDetails.tenantUserIdCSV = this.getDataList(
        ids,
        this.userList
      );
    }, 100);
  }

  async reselectOwner(item_id) {
    this.userList.forEach((item, index) => {
      if (item_id == item.item_id && item.campiagnOwner == true) {
        let ids = '';
        this.campaignDetails.tenantUserIdCSV.forEach((item, index) => {
          ids += item.item_id + ',';
        });
        ids += item_id;
        setTimeout(() => {
          this.campaignDetails.tenantUserIdCSV = this.getDataList(
            ids,
            this.userList
          );
        }, 100);
      }
    });
  }

  /**
   * Occurs after view initialization
   */
  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {}

  // #endregion

  // #region Data Acquisition

  /**
   * Retrieves all necessary data for this component.
   *
   * @param: currentPage - paging support.
   */
  async getData(currentPage) {}

  validateEmailDialog() {
    let isValid = false;
    let errorMessage: string = '';    
    const singleEmailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.campaignDetails.fromEmailAddress != '') {
      isValid = singleEmailRegularExpression.test(String(this.campaignDetails.fromEmailAddress).toLowerCase());
      if (!isValid) {
        this.isFromEmailInValid = true;
      } else {
        this.isFromEmailInValid = false;
      }
    } else {
      this.isFromEmailInValid = false;
    }
  }

  validateMultipleToEmail() {
    let isValid = false;

    if (this.campaignDetails.toEmailCSV != '') {
      isValid = this.validateMultipleEmails(this.campaignDetails.toEmailCSV);
      if (!isValid) {
        this.isToEmailInValid = true;
      } else {
        this.isToEmailInValid = false;
      }
    } else {
      this.isToEmailInValid = false;
    }
  }

  validateMultipleCCEmail() {
    let isValid = false;

    if (this.campaignDetails.ccEmailCSV != '') {
      isValid = this.validateMultipleEmails(this.campaignDetails.ccEmailCSV);
      if (!isValid) {
        this.isCCEmailInValid = true;
      } else {
        this.isCCEmailInValid = false;
      }
    } else {
      this.isCCEmailInValid = false;
    }
  }

  validateMultipleBCCEmail() {
    let isValid = false;

    if (this.campaignDetails.bccEmailCSV != '') {
      isValid = this.validateMultipleEmails(this.campaignDetails.bccEmailCSV);
      if (!isValid) {
        this.isBCCEmailInValid = true;
      } else {
        this.isBCCEmailInValid = false;
      }
    } else {
      this.isBCCEmailInValid = false;
    }
  }

  public validateAllDetails(): boolean {
    let isDisabledSaveButton = false;
    if (this.campaignDetails.tenantUserIdCSV.length == 0) {
      isDisabledSaveButton = true;
    } else if (
      this.campaignDetails.isFromRole == 0 &&
      !this.campaignDetails.fromLoanAssignTypeId
    ) {
      isDisabledSaveButton = true;
    } else if (
      this.campaignDetails.isFromRole == 1 &&
      !this.campaignDetails.fromEmailAddress
    ) {
      isDisabledSaveButton = true;
    } else if (this.campaignDetails.toReportCSV == null) {
      isDisabledSaveButton = false;
    } else if (this.campaignDetails.toReportCSV.length == 0) {
      isDisabledSaveButton = true;
    } else if (
      (this.campaignDetails.toContactTypeIdCSV.length == 0 &&
        this.campaignDetails.toLoanAssignTypeIdCSV.length == 0 &&
        !this.campaignDetails.toEmailCSV) ||
      this.isToEmailInValid
    ) {
      isDisabledSaveButton = true;
    } else if (
      this.isBCCEmailInValid ||
      this.isCCEmailInValid ||
      this.isFromEmailInValid
    ) {
      isDisabledSaveButton = true;
    } else if (!this.campaignDetails.campaignCategoryId) {
      isDisabledSaveButton = true;
    }
    return isDisabledSaveButton;
  }

  // #endregion

  // #region Paging Support

  /**
   * Selected page changed in the paginator
   * @param event
   */
  onPageChanged(event) {
    // Uncomment the following if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //if (PagingData.onPageChanged(this.webAPI.paging, event, this.dataTable)) {
    //  this.refreshData();
    //}
  }

  /**
   *  Goes to the user entered page.
   */
  onGotoPage() {
    // Uncomment the following if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
    //  this.refreshData();
    //}
  }

  /**
   * Retrieves data applying any sorting and filtering, and scrolls the
   * list to its previous horizontal scroll position.
   */
  async refreshData() {
    // Uncomment the following if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //this.currentHScrollPos = this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft;
    //await this.getData(this.webAPI.paging.currentPage);
    //setTimeout(() => {
    //  this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft = this.currentHScrollPos;
    //}, 10);
  }

  onRoleChange(role) {
    if (role == 1) {
      this.campaignDetails.isCustomRoleSelected = true;
    } else {
      this.campaignDetails.isCustomRoleSelected = false;
    }
  }

  public getCampaignDetails() {
    this.campaignDetails.fromLoanAssignTypeId =
      this.campaignDetails.isFromRole == 0
        ? this.campaignDetails.fromLoanAssignTypeId
        : null;
    this.campaignDetails.fromEmailAddress =
      this.campaignDetails.isFromRole == 1
        ? this.campaignDetails.fromEmailAddress
        : '';
    this.campaignDetails.fromEmailAddressName =
      this.campaignDetails.isFromRole == 1
        ? this.campaignDetails.fromEmailAddressName
        : '';
    this.campaignDetails.fromContactOwner =
      this.campaignDetails.isFromRole == 2 ? true : false;

    return this.campaignDetails;
  }

  getDataList(list: string, dataList: any) {
    let ids;
    let items;
    let itemslist = [];
    if (list) {
      ids = list.split(',');
      //item_id: obj.tenantUserId, item_text: obj.name
      for (let i = 0; i < ids.length; i++) {
        items = dataList.find((x) => x.item_id == ids[i]);
        if (items) {
          itemslist.push(items);
        }
      }
    }

    return itemslist;
  }

  public setCampaignDetails(campaignDetails: Campaign) {
    this.isDisabled = false;
    if (environment.clientId === 'AduvoAdmin') {
      campaignDetails.whoCanUseCSV = '-1';
      this.isDisabled = true;
    }
    else{
      if (
        campaignDetails.isAllUsersWhoCanUseThisCampaign &&
        !this.isCompanyUserWithMarketing
      ) {
        campaignDetails.whoCanUseCSV = this.userList
          .map((x) => x.item_id)
          .join();
      }
    }
    if (this.isCompanyUserWithMarketing) {
      this.isDisabled = true;
    }
    
    // Std User with Marketing users created campaigns, who can use this campaign will be disabled for
    // everyone including admins and cannot be changed and will have just the owner user there.
    //if (campaignDetails.campaignOwnerRole == 'CompanyUserWithMarketing') {
      //this.isUserListDisabled = true;
      //this.isDisabled = true;
    //}
    

    this.campaignDetails = {
      campaignId: campaignDetails.campaignId,
      tenantUserIdCSV: this.getDataList(
        campaignDetails.whoCanUseCSV,
        this.userList
      ),
      fromLoanAssignTypeId: campaignDetails.fromLoanAssignTypeId,
      fromEmailAddress: campaignDetails.fromEmailAddress,
      fromEmailAddressName: campaignDetails.fromEmailAddressName,
      //toReportCSV: campaignDetails.toListCSV,
      toReportCSV: this.getDataList(
        campaignDetails.toListCSV,
        this.loanStatusTypeMasterList
      ),
      toContactTypeIdCSV: this.getDataList(
        campaignDetails.to_ContactTypeIdCSV,
        this.masterContactTypes
      ),
      toLoanAssignTypeIdCSV: this.getDataList(
        campaignDetails.to_LoanAssignTypeIdCSV,
        this.loanAssignTypeRoles
      ),
      toEmailCSV: campaignDetails.to_EmailCSV,
      ccEmailCSV: campaignDetails.cc_EmailCSV,
      bccEmailCSV: campaignDetails.bcc_EmailCSV,
      ccContactTypeIdCSV: this.getDataList(
        campaignDetails.cc_ContactTypeIdCSV,
        this.masterContactTypes
      ),
      bccContactTypeIdCSV: this.getDataList(
        campaignDetails.bcc_ContactTypeIdCSV,
        this.masterContactTypes
      ),
      ccLoanAssignTypeIdCSV: this.getDataList(
        campaignDetails.cc_LoanAssignTypeIdCSV,
        this.loanAssignTypeRoles
      ),
      bccLoanAssignTypeIdCSV: this.getDataList(
        campaignDetails.bcc_LoanAssignTypeIdCSV,
        this.loanAssignTypeRoles
      ),
      campaignCategoryId: campaignDetails.campaignCategoryId,
      fromContactOwner: campaignDetails.fromContactOwner,
      isAnyTemplateHasLoanTag: campaignDetails.isAnyTemplateHasLoanTag,
      isToListContactOnly: campaignDetails.isToListContactOnly,
      isQuickStart: campaignDetails.isQuickStart,
      isRecommended: campaignDetails.isRecommended,
      //ccEmailCSV: campaignDetails.ccEmailCSV,
      //bccContactTypeIdCSV: campaignDetails.bccContactTypeIdCSV? campaignDetails.bccContactTypeIdCSV.map(x => x.item_id).join() : null,
      //bccLoanAssignTypeIdCSV: campaignDetails.bccLoanAssignTypeIdCSV? campaignDetails.bccLoanAssignTypeIdCSV.map(x => x.item_id).join() : null,
      //bccEmaiCSV: campaignDetails.bccEmaiCSV
    };
   if (this.user.isCompanyAdmin() && !campaignDetails.whoCanUseCSV) { 
      //case of all
      this.campaignDetails.tenantUserIdCSV = this.userList;
    }

    this.setFromFieldsValue();
  }

  setFromFieldsValue() {
    this.campaignDetails.isCustomRoleSelected = false;
    if (this.campaignDetails.isToListContactOnly) {
      if (this.campaignDetails.fromLoanAssignTypeId) {
        this.campaignDetails.isFromRole = 2;
      } else if (this.campaignDetails.fromEmailAddress) {
        this.campaignDetails.isFromRole = 1;
        this.campaignDetails.isCustomRoleSelected = true;
      } else if (this.campaignDetails.fromContactOwner) {
        this.campaignDetails.isFromRole = 2;
      } else {
        this.campaignDetails.isFromRole = 0;
      }
    } else {
      if (this.campaignDetails.fromLoanAssignTypeId) {
        this.campaignDetails.isFromRole = 0;
      } else if (this.campaignDetails.fromEmailAddress) {
        this.campaignDetails.isFromRole = 1;
        this.campaignDetails.isCustomRoleSelected = true;
      } else if (this.campaignDetails.fromContactOwner) {
        this.campaignDetails.isFromRole = 2;
      } else {
        this.campaignDetails.isFromRole = 0;
      }
    }
  }

  OnMasterListChange(event) {
    let selectedValue = event.value;
    let selectedMasterList = this.loanStatusTypeMasterList.find(
      (x) => x.item_id.toLowerCase() === selectedValue
    );
    this.campaignDetails.isToListContactOnly =
      selectedMasterList.item_is_contact;
    this.setFromFieldsValue();
  }

  validateMultipleEmails(emailIds: string) {
    let isValid = false;
    let isAllEmailvalid = true;
    //emailIds = emailIds.replace(/\s/g, '').toLowerCase();
    const singleEmailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailIds && emailIds.length > 0) {
      let emails = emailIds
        .split(';')
        .map((e) => e.split(','))
        .reduce((acc, cur) => cur.concat(acc), [])
        .filter((e) => !!e);
      if (emails && emails.length > 0) {
        for (var x = 0; x < emails.length; x++) {
          isValid = singleEmailRegularExpression.test(String(emails[x]).toLowerCase());
          if (!isValid) {
            isAllEmailvalid = false;
          }
        }
      } else {
        isAllEmailvalid = false;
      }
    } else {
      isAllEmailvalid = false;
    }
    return isAllEmailvalid;
  }

  // #endregion

  // #region Methods

  // #endregion

  // #region Event Handlers

  // #endregion
}

