import { LoanStatusType } from "./LoanStatusType";

export class MessageScheduleData {
    public showMessageScheduleDialog: boolean;
    constructor() {
    }
}
// export class MessageScheduleData {
//     public showMessageScheduleDialog: boolean;
//     public campaignMessageId: string;
//     public dialogTitle: string;
//     public loanStatusTypeId: string;//cahgne name QuickPickStatusFieldId
//     public loanStatusList: LoanStatusType[];
//     public scheduleText: string;

//     /**
//      *
//      */
//     constructor() {
//         this.showMessageScheduleDialog = false;
//         this.loanStatusList = [];
//         this.scheduleText = '';
//     }
// }
export class StatusQuickPickData {
  public campaignScheduleEventStatusQuickPickId: string;
  public campaignMessageId: string;
  public loanStatusTypeId: string;
  public day: string;
  public periodUnit: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  public offsetTypeId: string;
  public sendOndayTypeId: string;
  public sendOnTimeType: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}

export class CMSComponentData {
  public componentNumber: number; //component number
  public componentValueJSON: any; //json
  public scheduleMessage: string;
  public campaignMessageId: string;
  public isFormValid: boolean;
  /**
   *
   */
  constructor(index: number, _campaignMessageId: string = '') {
    this.componentNumber = index;
    this.campaignMessageId = _campaignMessageId;
    this.componentValueJSON = {};
    this.scheduleMessage = '';
    this.isFormValid=true;
  }

  Dispose() {
    this.componentNumber = 0; //default of number datatype is 0
    this.componentValueJSON = {};
    this.scheduleMessage = '';
    this.campaignMessageId = '';
    this.isFormValid = true;
  }
}

export class HolidayStatusData {
  public HolidayEventId: string;
  public TimezoneId: string;
  public TimezoneInfoId: string;
  public Hour: number;
  public Minute: number;
  public AMPM: string;
  public scheduleJson: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  public scheduleMode: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class ContactStatusData {
  public CampaignMessageId: string;
  public ContactEventId: string;
  public TimezoneId: string;
  public TimezoneInfoId: string;
  public Time: any;
  public Hour: number;
  public Minute: number;
  public AMPM: string;
  public scheduleJson: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  public scheduleMode: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class CustomDateData {
  public deliverOn: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public timezoneOffset: number;
  public hour: number;
  public minute: number;
  public AMPM: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  public scheduleMode: string;
  public sendASAP: boolean;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}



// export class CMSStatusQuickPickData {
//   public loanStatusTypeId: string;
//   public isReload: boolean;
//   public oldScheduleType: string;
//   public oldScheduleSubType: string;
//   public oldHolidayEventId: string;
//   public oldContactEventId: string;
//   public scheduleMode: string;
//   constructor() {
//     this.isReload = false;
//     this.loanStatusTypeId = '';
//     this.scheduleMode = 'NEW';
//     this.oldScheduleType = '';
//     this.oldScheduleSubType = '';
//   }
//   Dispose() {
//     this.loanStatusTypeId = '';
//   }
// }

// export class scheduleData {
//     public campaignMessageId: string;
//     public scheduleText: string;
//     constructor(cmsgId: string, stext: string) {
//         this.campaignMessageId = cmsgId;
//         this.scheduleText = stext;
//     }
// }
export class CMS_Data {
    public campaignMessageId: string;
    public scheduleText: string;

    public scheduleType: string;
    public ScheduleSubtype: string;
    public ScheduleDataJSON: any;
    public dialogTitle: string;


    public currentComponentIndex: number;
    public isReload: boolean;


    constructor(_campaignMessageId: string = '', _scheduleText: string = '',
        _scheduleType: string = '', _scheduleSubtype: string = '', _scheduleDataJSON: any = null, _dialogTitle = '') {
        this.campaignMessageId = _campaignMessageId;
        this.scheduleText = _scheduleText;
        this.scheduleType = _scheduleType;
        this.ScheduleSubtype = _scheduleSubtype;
        this.ScheduleDataJSON = _scheduleDataJSON;
        this.dialogTitle = _dialogTitle;
        this.isReload = false;
        this.currentComponentIndex = 0;
    }

    Dispose() {
        this.scheduleText = '';
        this.scheduleType = '';
        this.ScheduleSubtype = '';
        this.ScheduleSubtype = '';
        this.ScheduleDataJSON = '';
        this.dialogTitle = '';
        this.campaignMessageId = '';
    }
}
// export class SavedStatusQuickPick {
//   LoanStatusTypeId: string;
//   Days: string;
//   public scheduleMode: string;
//   public oldScheduleType: string;
//   public oldScheduleSubType: string;
//   public oldHolidayEventId: string;
//   public oldContactEventId: string;
//   constructor() {
//     this.scheduleMode = 'NEW';
//     this.oldScheduleType = '';
//     this.oldScheduleSubType = '';
//   }
// }
export class SavedHolidayQuickPick {
  HolidayEventId: string;
  Time: string;
  TimezoneId: string;
  public TimezoneInfoId: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class SavedContactQuickPick {
  ContactEventId: string;
  Time: string;
  TimezoneId: string;
  public TimezoneInfoId: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class SavedOneTimeCustomDate {
  HolidayEventId: string;
  Time: string;
  TimezoneId: string;
  DeliverOn: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  public TimezoneInfoId: string;
  public SendASAP: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class RepeatingMonthlyData {
  public campaignScheduleEventRepeatingMonthlyId: string;
  public campaignMessageId: string;
  public repeatingMonthlyType: string;
  public ordinalName: string;
  public weekday: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  public dateStart: string;
  public endingCriteria: string;
  public dateEnd: string;
  public dateOption: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class RepeatingYearlyCustomData {
  public campaignScheduleEventRepeatingYearlyCustomId: string;
  public campaignMessageId: string;
  public repeatingMonthlyType: string;
  public onTheXofEveryMonthOrdinal: string;
  public everyXweekdayOfEveryMonthOrdinal: string;
  public weekday: string;
  public month: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  public startingYear: string;
  public endingAfterNoccurrences: string;
  public dateOption: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class RepeatingYearlyHolidayData {
  public campaignScheduleEventRepeatingYearlyHolidayId: string;
  public campaignMessageId: string;
  public holidayEventId: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  public startingYear: string;
  public endingYear: string;
  public scheduleJson: string;
  public dateOption: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class RepeatingYearlyContactData {
  public campaignScheduleEventRepeatingYearlyEventId: string;
  public campaignMessageId: string;
  public contactEventId: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  public startingYear: string;
  public endingYear: string;
  public scheduleJson: string;
  public dateOption: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class RepeatingWeeklyData {
  public campaignScheduleEventRepeatingWeeklyId: string;
  public campaignMessageId: string;
  public dateStart: string;
  public dateEnd: string;
  public dateOption: string;
  public weeklyWeekdayList: RepeatingWeeklyWeekdayData[] = [];
  public campaignScheduleEventDateList: CampaignScheduleEventData[] = [];
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}
export class CampaignScheduleEventData {
  public campaignScheduleEventRepeatingWeeklyWeekdayId: string;
  public campaignScheduleEventRepeatingWeekdayId: string;
  public deliverOn: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  constructor() {}
}
export class RepeatingWeeklyWeekdayData {
  public campaignScheduleEventRepeatingWeeklyWeekdayId: string;
  public campaignScheduleEventRepeatingWeekdayId: string;
  public weekday: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  constructor() {}
}
export class RepeatingWeeklyScheduleData {
  public campaignScheduleEventRepeatingWeeklyId: string;
  public dateStart: string;
  public dateEnd: string;
  public dateOption: string;
  public scheduleDetail: ScheduleDetail[];
  constructor() {}
}
export class ScheduleDetail {
  weekday: string;
  time: string;
  timezoneId: string;
  timezoneInfoId: string;
  campaignScheduleEventRepeatingWeeklyId: string;
  constructor() {}
}
export class RepeatingDailyData {
  public campaignScheduleEventRepeatingDailyId: string;
  public campaignMessageId: string;
  public timezoneId: string;
  public timezoneInfoId: string;
  public time: any;
  public dateStart: string;
  public endingCriteria: string;
  public dateEnd: string;
  public dateOption: string;
  public scheduleMode: string;
  public oldScheduleType: string;
  public oldScheduleSubType: string;
  public oldHolidayEventId: string;
  public oldContactEventId: string;
  constructor() {
    this.scheduleMode = 'NEW';
    this.oldScheduleType = '';
    this.oldScheduleSubType = '';
  }
}