export class StorageHelper {

    constructor() {
    }

    SetDataInStorage(key, value, hours = 1, minutes = 0) {//5000 = 5 Seconds
        const now = new Date()
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        let expiryTime = 0;
        if (hours > 0) {
            expiryTime = now.getTime() + 1000 * 60 * 60 * hours;
        } else if (minutes > 0) {
            expiryTime = now.getTime() + 1000 * 60 * minutes;
        }

        const item = {
            value: value,
            expiry: expiryTime,
            AddedTime: now.getTime()
        }
        sessionStorage.setItem(key, JSON.stringify(item))
    }

    GetDataFromStorage(key) {
        const itemStr = sessionStorage.getItem(key)

        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }

        const item = JSON.parse(itemStr)
        const now = new Date()

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            sessionStorage.removeItem(key)
            return null
        }
        return item
    }

    ClearStorageByKey(key: string) {
        sessionStorage.removeItem(key);
    }

    // ClearStorageForCamapign(keyFlag : boolean) {
    //     var keyArr = ['LoanStatusKey', 'LoanAssignTypeRoleKey', 'CampaignCategoriesKey',
    //         'UserListKey', 'LoanStatusTypeMasterDataKey', 'MasterContactTypesKey', 'LoanAssignTypeRolesDataKey'];
    //     for (var key of keyArr) {
    //         sessionStorage.removeItem(Number(keyFlag).toString()+key);
    //     }
    // }

    ClearUserList(tenantId: string) {
        sessionStorage.removeItem("UserListKey");
        sessionStorage.removeItem((tenantId ?? '') + "UserListKey");
    }

    ClearAll() {
        // Remove all saved data from sessionStorage
        sessionStorage.clear();
    }
}
export enum AnalyticsTypes {
    BirthDaysThisWeek = "BirthDaysThisWeek",
    LoanAniversariesThisWeek = "LoanAniversariesThisWeek",
    MarketingData = "MarketingData",
    ActiveLeads = "ActiveLeads",
    ActiveLoans = "ActiveLoans",
    ConvertedFromLeadToLoan = "ConvertedFromLeadToLoan",
    ApplicationPhase = "ApplicationPhase",
    CancelledLoans = "CancelledLoans",
    ClosedLoans = "ClosedLoans",
    RefiLoans = "RefiLoans",
    PurchaseLoans = "PurchaseLoans",
    OtherLoans = "OtherLoans",
}
