<div class="row" [formGroup]="weeklyForm">
  <ng-container formArrayName="otherDays">
    <div
      *ngFor="let date of otherDayControl.controls; let otherDayInex = index"
      class="p-col-12"
      style="padding-left: 45px;"
    >
      <ng-container [formGroupName]="otherDayInex">
        <div class="row mt-3">
          <div class="p-col-10">
            <label>Send Every</label>
          </div>

          <div class="p-col-1" style="float: right">
            <a style="cursor: pointer;"
              *ngIf="weeklyForm.controls.otherDays.length > 1"
              class="p-ml-3"
              (click)="removeOtherDay(otherDayInex)"
              ><i class="pi pi-trash"></i
            ></a>
          </div>
          <div class="p-col-1">&nbsp;</div>
        </div>
        <div class="row">
          <div class="p-col-6">
            <p-dropdown
              [options]="weekdays"
              optionLabel="name"
              [filter]="false"
              filterBy="name"
              [showClear]="false"
              placeholder="Select week"
              formControlName="otherWeekDay"
              optionValue="code"
              appendTo="body"
            >
              <ng-template let-weekday pTemplate="item">
                <div class="weekday-item">
                  <div>{{ weekday.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="p-col-6">&nbsp;</div>
        </div>
        <div class="row">
          <div class="p-col-5">
            <label>Time</label>&nbsp;
            <span *ngIf="messageType == 2 && allowTCPAForTextMessages==true" [title]="" class="fa fa-info-circle info-icon info"
              pTooltip="{{timeToolTipe}}" tooltipPosition="top" tooltipZIndex="9999999" style="width:100px;font-weight: 500!important;color:#BCC3CB!important;font-size: 14px;">
            </span>
          </div>
          <div class="p-col-6" style="margin-left: 15px;">
            <label>Time Zone</label>
          </div>
        </div>
        <div class="row">
          <div class="p-col-2" style="width: 14% !important; padding-right: 7.5px;">
            <p-dropdown
              [options]="hours"
              optionLabel="name"
              [filter]="false"
              filterBy="name"
              [showClear]="false"
              placeholder="Hour"
              formControlName="otherHour"
              optionValue="code"
              appendTo="body"
              (onChange)="OnHourChange(otherDayInex)"
            >
              <ng-template let-otherHour pTemplate="item">
                <div class="otherHour-item">
                  <div>{{ otherHour.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="col-1 align-self-center justify-self-center p-0" style="width: 1%; color: #797979; margin-bottom: 20px;">:</div>
          <div class="p-col-2" style="width: 14% !important; padding-right: 7.5px; padding-left: 7.5px;">
            <p-dropdown
              [options]="minutes"
              optionLabel="name"
              [filter]="false"
              filterBy="name"
              [showClear]="false"
              placeholder="Minute"
              formControlName="otherMinute"
              optionValue="code"
              appendTo="body"
            >
              <ng-template let-otherMinute pTemplate="item">
                <div class="otherMinute-item">
                  <div>{{ otherMinute.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="p-col-2" style="width: 15% !important; padding-right: 7.5px; padding-left: 7.5px;">
            <p-dropdown
              [options]="meridiems"
              optionLabel="name"
              [filter]="false"
              filterBy="name"
              [showClear]="false"
              placeholder="Meridiem"
              formControlName="otherMeridiem"
              optionValue="code"
              appendTo="body"
              [disabled]="messageType == 2 && allowTCPAForTextMessages==true"
            >
              <ng-template let-otherMeridiem pTemplate="item">
                <div class="otherMeridiem-item">
                  <div>{{ otherMeridiem.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="p-col-5" style="padding-right: 7.5px; padding-left: 7.5px;">
            <p-dropdown
              [options]="timeZones"
              optionLabel="displayName"
              [filter]="true"
              filterBy="displayName"
              [showClear]="true"
              resetFilterOnHide="true"
              placeholder="Time Zone"
              formControlName="otherTimeZone"
              optionValue="timezoneId"
              appendTo="body"
            >
              <ng-template let-otherTimeZone pTemplate="item">
                <div class="otherTimeZone-item">
                  <div>{{ otherTimeZone.displayName }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div style="width:95%;margin: 0 auto;margin-left: -15px !important;">
            <hr style="border-top:3px solid #fff;opacity: 1;"/>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="p-col-12" style="padding-left: 25px;">
    <div class="row">
      <div class="p-col-2">
        <p-button
          icon="pi pi-plus text-white"
          [style]="{ 'margin-left': '17.5px' }"
          (click)="addOtherDayFormGroup()"
          label="Add Another Day"
        ></p-button>
      </div>
      <div class="p-col-2"></div>
      <div class="p-col-8">
        <span class="text-danger" *ngIf="otherDayControl.errors">Duplicate schedule</span>
      </div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px;">
    <div class="row">
      <div class="p-col-3">
        <label>Starting</label>
      </div>

      <div class="p-col-3">
        <label>Ending</label>
      </div>
      <div class="p-col-6">&nbsp;</div>
    </div>
    <div class="row">
      <div class="p-col-3">
        <p-calendar
        id="week_Start_date"
          [dateFormat]="dateFormat"
          [minDate]="tomorrowDate"
          formControlName="startDate"
          [readonlyInput]="true"
          [showIcon]="true"
          [disabledDays]="disableDaysArray"
        ></p-calendar>
      </div>

      <div class="p-col-3">
        <p-dropdown
          [options]="dateOptions"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="dateOption"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-dateOption pTemplate="item">
            <div class="dateOption-item">
              <div>{{ dateOption.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div *ngIf="isAfter"  class="p-col-3">
        <p-calendar 
        id="week_End_date"
          [dateFormat]="dateFormat"
          [minDate]="tomorrowAfterDate"
          formControlName="endDate"
          [readonlyInput]="true"
          [showIcon]="true"
          [disabledDays]="disableDaysArray"
        ></p-calendar>
      </div>
      <div *ngIf="isAfter" class="p-col-3">
        <span class="text-danger" *ngIf="weeklyForm.errors">{{weeklyForm.errors?.dates}}</span>
      </div>
    </div>
  </div>
</div>
