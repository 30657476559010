<div *ngIf="campaignDetail" class="card rounded-1 card_left_section">
  <div class="align-items-center bg-white card-header container d-flex justify-content-between p-4">
    <span class="fs-3 fw-semibold text-dark">Campaign Details</span>
    <ng-container *ngIf="!campaignDetail.isPublishCampaign">
      <i *ngIf="campaignDetail.isLoading" class="pi pi-spin pi-spinner" style="font-size: 25px; margin-top: 10px"></i>
      <button id="btnEditCampaignDetails" *ngIf="!campaignDetail?.isLoading && !campaignDetail.isPreviewMode"
        type="button" class="p-column-filter-menu-button table-ellipse-button" (click)="onShowDetailsDialog()">
        <span class="fa fa-pencil text-blue pencil"></span>
      </button>
    </ng-container>
  </div>
  <div class="card-body p-4 pb-0">
    <div>
      <span class="campaign-field-title title_campaign fade_title">Campaign Category</span>
      <span class="fa fa-flag-o" style="float: right; color: red"
        *ngIf="showValidationFlag && !campaignDetail.campaignCategory"></span>
    </div>
    <div>
      <span class="editorQuad2aData" *ngIf="campaignDetail.campaignCategory">
        {{ campaignDetail.campaignCategory }}
      </span>
      <span class="editorQuadNoData" *ngIf="!campaignDetail.campaignCategory">
        <i>None selected</i>
      </span>
    </div>
    <div>
      <span class="campaign-field-title title_campaign fade_title">Who can use this campaign</span>
      <span class="fa fa-flag-o" style="float: right; color: red"
        *ngIf="showValidationFlag && !campaignDetail.whoCanUsers"></span>
    </div>
    <div>
      <ng-container *ngIf="campaignDetail.isPublishCampaign; else divWho">
        <span class="editorQuad2aData">All users</span>
      </ng-container>
      <ng-template #divWho>
        <span class="editorQuad2aData">
          <ng-container *ngIf="campaignDetail.isAllUsersWhoCanUseThisCampaign; else div2">
            All
          </ng-container>
          <ng-template #div2>
            {{ campaignDetail.whoCanUsers || "All" }}
          </ng-template>
        </span>
        <!-- <span class="editorQuadNoData" *ngIf="!campaignDetail.whoCanUsers">
          <i>None selected</i>
        </span> -->
      </ng-template>
    </div>
    <div class="campaign-field-title title_campaign">
      <span class="fade_title">Who is it from?</span>
      <span class="fa fa-flag-o" style="float: right; color: red"
        *ngIf="showValidationFlag && !campaignDetail.fromValue"></span>
    </div>
    <div>
      <span class="editorQuad2aData" *ngIf="campaignDetail.fromValue">
        {{ campaignDetail.fromValue }}
      </span>
      <span class="editorQuadNoData" *ngIf="!campaignDetail.fromValue">
        <i>None selected</i>
      </span>
    </div>
    <div class="campaign-field-title title_campaign">
      <span class="fade_title">To List</span>
      <span class="fa fa-flag-o" style="float: right; color: red"
        *ngIf="showValidationFlag && !campaignDetail.toList"></span>
    </div>
    <div>
      <span pTooltip="{{campaignDetail.toListToolTip}}" class="editorQuad2aData" *ngIf="campaignDetail.toList">
        {{ campaignDetail.toList }}
      </span>
      <span class="editorQuadNoData" *ngIf="!campaignDetail.toList">
        <i>None selected</i>
      </span>
    </div>
    <div class="campaign-field-title title_campaign">
      <span class="fade_title">To Recipients</span>
      <span class="fa fa-flag-o" style="float: right; color: red"
        *ngIf="showValidationFlag && !campaignDetail.toRecipientData"></span>
    </div>
    <div>
      <span class="editorQuad2aData" pTooltip="{{campaignDetail.toRecipientDataToolTip}}" *ngIf="campaignDetail.toRecipientData">
        {{ campaignDetail.toRecipientData }}
      </span>
      <span class="editorQuadNoData" *ngIf="!campaignDetail.toRecipientData">
        <i>None selected</i>
      </span>
    </div>
    <div *ngIf="isEmail" class="campaign-field-title title_campaign">
      <span class="fade_title">CC</span>
    </div>
    <div *ngIf="isEmail">
      <span class="editorQuad2aData" pTooltip="{{campaignDetail.ccDataToolTip}}" *ngIf="campaignDetail.ccData">
        {{ campaignDetail.ccData }}
      </span>
      <span class="editorQuadNoData" *ngIf="!campaignDetail.ccData">
        <i>None selected</i>
      </span>
    </div>
    <div *ngIf="isEmail" class="campaign-field-title title_campaign">
      <span class="fade_title">BCC</span>
    </div>
    <div *ngIf="isEmail">
      <span class="editorQuad2aData" pTooltip="{{campaignDetail.bccDataToolTip}}" *ngIf="campaignDetail?.bccData">
        {{ campaignDetail.bccData }}
      </span>
      <span class="editorQuadNoData" *ngIf="!campaignDetail?.bccData">
        <i>None selected</i>
      </span>
    </div>
    <ng-container *ngIf="currentClientID === 'AduvoAdmin'; else divAQS">
      <div class="tileBackground">
        <div class="p-d-flex my-2">
          <p-checkbox [(ngModel)]="campaignDetail.isQuickStart" binary="true" inputId="chkQuickStart"
            [disabled]="ischkQuickStartDisabled">
          </p-checkbox>
          <label class="control-label">Aduvo Quick Start</label>
        </div>
        <div class="p-d-flex my-2">
          <p-checkbox [(ngModel)]="campaignDetail.isRecommended" binary="true" inputId="chkRecommended"
            [disabled]="ischkRecommendedDisabled">
          </p-checkbox>
          <label class="control-label">Aduvo Recommended</label>
        </div>
      </div>
    </ng-container>
    <ng-template #divAQS>
      <div class="tileBackground" *ngIf="campaignDetail.isPublishCampaign">
        <div class="p-d-flex my-2">
          <p-checkbox [(ngModel)]="campaignDetail.isQuickStart" binary="true" inputId="chkQuickStart" [disabled]="true">
          </p-checkbox>
          <label class="control-label">Aduvo Quick Start</label>
        </div>
        <div class="p-d-flex my-2">
          <p-checkbox [(ngModel)]="campaignDetail.isRecommended" binary="true" inputId="chkRecommended"
            [disabled]="true">
          </p-checkbox>
          <label class="control-label">Aduvo Recommended</label>
        </div>
      </div>
    </ng-template>
  </div>
</div>