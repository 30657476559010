<!--
  *************************************************************************************
  Page Heading and Buttons
  *************************************************************************************
  -->
<div class="content table-search-row second-header-fixed">
  <div class="text-wrapper">
    <div class="d-flex justify-content-between align-items-center">
      <div class="align-items-center d-flex">
        <div class="title-main me-3 text-black">Campaigns</div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="p-input-icon-left p-ml-auto p-float-label">
            <input
              class="search-input rounded-pill"
              type="text"
              placeholder="Search by keyword"
              [(ngModel)]="searchParameter"
              pInputText
              (keyup.enter)="onTblCampaignSearchClicked($event)"
            />
            <!-- <label class="search-label">Search by Name</label> -->
          </span>
          <span
            pButton
            pRipple
            icon="fal fa-search search-fa"
            pTooltip="Execute Search"
            tooltipPosition="bottom"
            showDelay="500"
            (click)="onTblCampaignSearchClicked($event)"
            class="p-button-secondary execute-search"
          >
          </span>
        </div>
      </div>
      <div>
        <button
          pButton
          type="button"
          class="header-button"
          [disabled]="isCreateDisable"
          (click)="CreateNewCampaign()"
        >
          <i class="pi pi-plus text-white"></i>&nbsp; Create New Campaign</button
        >&nbsp;
      </div>
    </div>
  </div>
</div>

<!-- Requirement for the ticket PV6MVP-58 -->
<!-- <div class="p-datatable-filter-header" style="vertical-align:central" *ngIf="this.showFilterMenu">
    <table><tr>
      <td>
  <button pButton pRipple type="button"
          icon="pi pi-sort-alt" class="p-button-secondary-rounded"
          pTooltip="Restore default sort order" tooltipPosition="bottom" showDelay=500
          style="width:2.5rem; margin-left:0.5rem; margin-right:0.5rem; display: none;"
          (click)="onTblCampaignClearSorting($event)"></button>
  <p-chip label="{{campaignManagementPagesState.campaignGridSortLabel}}" styleClass="p-mr-2"><b style="margin-right:0.5rem;">Sort: </b></p-chip>

  <p-chip *ngIf="isTblCampaignFilterSet('Name')" label="Name {{this.campaignManagementPagesState.ChipTooltipName}}" [removable]="true" styleClass="p-mr-2" (onRemove)="onTblCampaignChipRemoved('Title')" pTooltip="{{this.campaignManagementPagesState.ChipTooltipTitle}}" tooltipPosition="bottom" showDelay=500></p-chip>
  <p-chip *ngIf="isTblCampaignFilterSet('Type')" label="Type {{this.campaignManagementPagesState.ChipTooltipType}}" [removable]="true" styleClass="p-mr-2" (onRemove)="onTblCampaignChipRemoved('Type')" pTooltip="{{this.campaignManagementPagesState.ChipTooltipType}}" tooltipPosition="bottom" showDelay=500></p-chip>
  <p-chip *ngIf="isTblCampaignFilterSet('Category')" label="Category {{this.campaignManagementPagesState.ChipTooltipCategory}}" [removable]="true" styleClass="p-mr-2" (onRemove)="onTblCampaignChipRemoved('Category')" pTooltip="{{this.campaignManagementPagesState.ChipTooltipCategory}}" tooltipPosition="bottom" showDelay=500></p-chip>
  <p-chip *ngIf="isTblCampaignFilterSet('Owner')" label="Owner {{this.campaignManagementPagesState.ChipTooltipOwner}}" [removable]="true" styleClass="p-mr-2" (onRemove)="onTblCampaignChipRemoved('Owner')" pTooltip="{{this.campaignManagementPagesState.ChipTooltipOwner}}" tooltipPosition="bottom" showDelay=500></p-chip>
  <p-chip *ngIf="isTblCampaignFilterSet('Published')" label="Published {{this.campaignManagementPagesState.ChipTooltipPublished}}" [removable]="true" styleClass="p-mr-2" (onRemove)="onTblCampaignChipRemoved('Published')" pTooltip="{{this.campaignManagementPagesState.ChipTooltipPublished}}" tooltipPosition="bottom" showDelay=500></p-chip>

  <button pButton pRipple type="button"
  icon="pi pi-plus" class="p-button-secondary-square"
  pTooltip="Add filter" tooltipPosition="bottom" showDelay=500 (click)="filterMenu.toggle($event)"
  style="width:2.5rem; margin-left:0.5rem; margin-right:0.5rem;border-radius:0px;min-height: 2rem;"
  ></button>
  <p-tieredMenu #filterMenu [model]="filterColumns" [popup]="true"></p-tieredMenu>
  </td>
  </tr>
  </table>
</div> -->

<!--
*************************************************************************************
Campaigns Table
*************************************************************************************
-->
<p-menu
  [showTransitionOptions]="'0ms'"
  [hideTransitionOptions]="'0ms'"
  [autoZIndex]="false"
 class="groundmenu"
  #ellipseMenu
  [popup]="true"
  [model]="campaignManagementPagesState.campaignGridContextMenuItems"
></p-menu>

<div class="text-wrapper py-3 grid-section">
  <div class="d-flex">
    <div class="d-flex flex-column flex-wrap campaigns-filters mt-2">
      <div
        role="button"
        (click)="loadAllCategoryTemplates($event)"
        [class.active]="selectedCategoryItem === -1"
      >
        Browse All
      </div>
      <div
        role="button"
        *ngFor="let category of messageCategoryList; let index = index"
        [class.active]="selectedCategoryItem == index"
        (click)="loadCategoryTemplates($event, category, index)"
      >
        <span> {{ category.item_text }} </span>
      </div>
    </div>

    <div role="button" class="flex-fill" id="campaigngrid">
      <p-table
        #tblCampaigns
        [value]="campaignManagementPagesState.campaigns"
        class="pulseTable"
        [columns]="campaignManagementPagesState.campaignColumns"
        [globalFilterFields]="
          campaignManagementPagesState.campaignColumnGlobalFilters
        "
        [rows]="25"
        [rowsPerPageOptions]="[25, 50, 100]"
        [paginator]="
          campaignManagementPagesState.webAPI.paging.totalRecords > 0
        "
        [showCurrentPageReport]="true"
        [totalRecords]="campaignManagementPagesState.webAPI.paging.totalRecords"
        [loading]="loadingPage"
        [lazy]="true"
        (onLazyLoad)="onPageChanged($event)"
        [scrollable]="false"
        [virtualScroll]="false"
        [(first)]="pageIndex"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              <div class="flex justify-content-between align-items-center">
                Name
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="templateTypeCount">
              <div class="flex justify-content-between align-items-center">
                MESSAGE TYPE
                <p-sortIcon field="templateTypeCount"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="recipientCount">
              <div class="flex justify-content-between align-items-center">
                RECIPIENTS
                <p-sortIcon field="recipientCount"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="ownerName">
              <div class="flex justify-content-between align-items-center">
                OWNER
                <p-sortIcon field="ownerName"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="published">
              <div class="flex justify-content-between align-items-center">
                ACTIVE
                <p-sortIcon field="published"></p-sortIcon>
              </div>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-row
          let-record
          let-rowIndex="rowIndex"
        >
          <tr>
            <td
              pTooltip="{{
                campaignManagementPagesState.getRowToolTipText(
                  record.templateTypeCount
                )
              }}"
              tooltipPosition="bottom"
              showDelay="500"
            >
              <span class="campaignGridName">
                <a [routerLink]="'edit/' + record.campaignId + '/default/'">{{
                  record.name
                }}</a>
              </span>
            </td>
            <td>
              <span
                style="margin-left: px"
                innerHTML="{{
                  campaignManagementPagesState.formatField(
                    record.templateTypeCount,
                    'CampaignMessageTypes'
                  )
                }}"
              ></span>
            </td>
            <td>
              <span class="subHeaderText">{{ record.recipientCount }}</span>
            </td>
            <td>
              <!-- Requirement for the ticket PV6MVP-58 -->
              <!-- <div style="margin-bottom: 5px;" pTooltip="{{campaignManagementPagesState.getRowToolTipText(record.MessageTypes)}}" tooltipPosition="bottom" showDelay=500>
              <span class="standardText">Catagory:</span>&nbsp;<span class="subHeaderText">{{record.Target}}</span>
            </div> -->
              <div style="margin-bottom: 5px">
                <span class="subHeaderText">{{ record.ownerName }}</span>
              </div>
              <!-- <div>
              <span class="standardText">Last Modified:</span>&nbsp;<span class="subHeaderText">{{campaignManagementPagesState.formatField(record.modifiedOnUtc)}}</span>
            </div> -->
            </td>
            <td>
              <!-- PV6UI-822 -->
              <ng-container
                *ngIf="
                  isCompanyUserWithMarketing &&
                    record.activationMode == 'admin-mandatory';
                  else showPublishDiv
                "
              >
                <p-inputSwitch
                  disabled
                  id="inputSwithActive"
                  class="inputSwithActive"
                  [(ngModel)]="record.published"
                >
                </p-inputSwitch>
              </ng-container>
              <ng-template #showPublishDiv>
                <span *ngIf="record.published" class="switch">
                  <p-inputSwitch
                    id="inputSwithActive"
                    class="inputSwithActive"
                    (onChange)="
                      onSwitchUnPublished(
                        record.campaignId,
                        record.isPublishingDb
                      )
                    "
                    [(ngModel)]="record.published"
                  >
                  </p-inputSwitch>
                </span>
                <span *ngIf="!record.published" class="switch switchInactive">
                  <p-inputSwitch
                    id="inputSwithInActive"
                    class="inputSwithActive"
                    [disabled]="record.templateTypeCount == 0"
                    (onChange)="onShowPublishedCampaignDialog(record)"
                    [(ngModel)]="record.published"
                  ></p-inputSwitch>
                </span>
              </ng-template>
            </td>

            <td class="table-ellipse">
              <button
                type="button"
                aria-haspopup="true"
                class="p-column-filter-menu-button table-ellipse-button"
                (focus)="ellipseMenu.hide()"
                (click)="onEllipseClick($event, record)"
              >
                <span class="pi pi-ellipsis-h"></span>
              </button>
            </td>

            <!-- <td>
              <table>
                <tr>
                  <td>
                    <p-button *ngIf="!record.isPublishingDb else disabledLink" label="Edit"
                      [routerLink]="'edit/' + record.campaignId+'/default'"></p-button>
                    <ng-template #disabledLink>
                      <p-button [disabled]="record.isPublishingDb" label="Edit"></p-button>
                    </ng-template>
                  </td>
                  <td><a [routerLink]="" (click)="onShowCopyCampaignDialog(record);"><i class="pi pi-clone"></i></a>
                  </td>
                  <td><a *ngIf="!record.isPublishingDb  else disableDeleteLink" [routerLink]=""
                      (click)="onShowDeleteCampaignDialog(record);"><i class="pi pi-trash"></i></a>
                    <ng-template #disableDeleteLink>
                      <a href="javascript:void(0)"><i class="pi pi-ban"></i></a>
                    </ng-template>
                  </td>
                  <td><a href="javascript:void(0)"
                      (click)="previewCampaign(record.campaignId, record.name);">preview</a></td>
                </tr>
              </table>
            </td> -->
            <!-- Requirement for the ticket PV6MVP-58 -->

            <!-- <div style="margin: 5px;">
              <table>
                <tr>
                  <td colspan="2" style="padding: 5px;">
                    <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Opened</span>
                    <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Clicked</span>
                    <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Bounced</span>
                    <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Opted Out</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button pButton [routerLink]="'/lists/campaigns/' + record.CampaignId" [disabled]="loadingPage===true" type="button" label="View Report" class="p-button-secondary" style="color:var(--primary-color-pulse) !important; min-height: 1rem; max-height: 1.5rem; margin-top: 10px;" ></button>
                  </td>
                  <td align="right">
                    <a [routerLink]="'edit/' + record.CampaignId"><i class="pi pi-clone"></i></a>&nbsp;
                    <a [routerLink]="" (click)="confirmDelete(record.CampaignId)"><i class="pi pi-trash"></i></a>
                  </td>
                </tr>
              </table>
            </div> -->
          </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft" let-state>
          <label style="font-weight: 400"
            >{{
              campaignManagementPagesState.webAPI.paging.totalRecords | number
            }}&nbsp;results</label
          >
        </ng-template>

        <ng-template pTemplate="paginatorright" let-state>
          <div>
            <label style="margin-top: 1px; margin-right: 8px; font-weight: 400"
              >Go to Page</label
            >
            <input
              type="text"
              pInputText
              pKeyFilter="int"
              [(ngModel)]="
                campaignManagementPagesState.webAPI.paging.currentPage
              "
              width="3"
              maxlength="3"
              style="
                text-align: center;
                width: 4rem;
                margin-right: 8px;
                border-radius: 20px;
              "
              (change)="onTblCampaignGotoPage($event)"
            />
            <!-- <button pButton pRipple type="button" iconPos="right" icon="pi pi-caret-right" class="p-button-secondary-rounded" style="margin-right: 0px;" (click)="onTblCampaignGotoPage()"></button> -->
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8">No records found</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<!--
*************************************************************************************
Dialog to Add Column Filter
*************************************************************************************
-->
<p-dialog
  class="custom-dialog"
  [resizable]="false"
  (onHide)="onFilterPopupCancel()"
  [(visible)]="showFilterPopup"
  [modal]="true"
  [style]="{ width: '20em', 'font-weight': '400' }"
>
  <p-header>
    <span class="dialog-head">Add Filter</span>
  </p-header>
  <ng-template pTemplate="body">
    {{ this.selectedFilterColumn }}

    <!-- 'DisplayOptedIn' custom filter specification -->

    <p-dropdown
      appendTo="body"
      *ngIf="this.selectedFilterColumn === 'Enabled'"
      [ngModel]="
        campaignManagementPagesState.campaignGridEnabledFilterState.yesNo
      "
      [options]="optedInFilterState.options"
      optionLabel="name"
      (onChange)="optedInFilterChanged($event)"
    >
      <ng-template let-option pTemplate="item">
        <span class="p-ml-1">{{ option.name }}</span>
      </ng-template>
    </p-dropdown>

    <!-- 'DisplayVeteran' custom filter specification -->

    <p-dropdown
      appendTo="body"
      *ngIf="this.selectedFilterColumn === 'isPublished'"
      [ngModel]="
        campaignManagementPagesState.campaignGridEnabledFilterState.yesNo
      "
      [options]="veteranFilterState.options"
      optionLabel="name"
      (onChange)="veteranFilterChanged($event)"
    >
      <ng-template let-option pTemplate="item">
        <span class="p-ml-1">{{ option.name }}</span>
      </ng-template>
    </p-dropdown>

    <!-- 'DisplayContentType' custom filter specification -->

    <p-multiSelect
      [displaySelectedLabel]="false"
      appendTo="body"
      *ngIf="this.selectedFilterColumn === 'Type'"
      [ngModel]="filters.templateTypeCount[0].value"
      placeholder="Message Types"
      class="pulseMultiSelect"
      [filter]="false"
      optionLabel="name"
    >
      <ng-template let-option pTemplate="item">
        <span class="p-ml-1" appendTo="body">Email</span>
        <span class="p-ml-1" appendTo="body">Text</span>
      </ng-template>
    </p-multiSelect>

    <!-- Default-->
    <div
      *ngIf="
        this.selectedFilterColumn !== 'State' &&
        this.selectedFilterColumn !== 'Opted In' &&
        this.selectedFilterColumn !== 'Veteran' &&
        this.selectedFilterColumn !== 'Birthday' &&
        this.selectedFilterColumn !== 'Type'
      "
    >
      <p-dropdown
        appendTo="body"
        [(ngModel)]="selectedOperator"
        [options]="filterOperators"
        (onChange)="operatorChanged($event)"
      >
        <ng-template let-option pTemplate="item">
          <span class="p-ml-1">{{ option }}</span>
        </ng-template>
      </p-dropdown>
      <input
        class="form-input"
        [(ngModel)]="filterValue"
        (input)="
          dataTable.filter(
            $event.target.value,
            selectedFilterColumn,
            selectedOperator
          )
        "
        pInputText
        type="text"
        style="padding: 10px; border-radius: 2px"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      (click)="onFilterPopupCancel()"
      label="Cancel"
      styleClass="p-button-secondary"
    ></p-button>
    <p-button (click)="onApplyColumnFilter()" label="Apply"></p-button>
  </ng-template>
</p-dialog>

<p-dialog
  class="custom-alert"
  #deleteCampaignDialog
  [(visible)]="showDeleteCampaignDialog"
  [resizable]="false"
  [modal]="true"
  [style]="{ width: '52.5em', 'font-weight': '700' }"
>
  <div class="pb-25">
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="alert-header"
      >Please confirm you want to delete {{ selectedCampaign?.name }}</span
    >
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button
        class=""
        (click)="deleteCampaign()"
        [style]="{ 'margin-right': '17.5px' }"
        label="Yes, I want to delete"
        styleClass="p-button-rounded"
      >
      </p-button>
      <p-button
        [style]="{ 'margin-left': '17.5px' }"
        (click)="showDeleteCampaignDialog = false"
        label="Cancel"
        styleClass="p-button-secondary"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
<p-confirmPopup></p-confirmPopup>

<!--Published dialog-->
<p-dialog
  class="custom-alert"
  #publishCampaignDialog
  [(visible)]="showPublishedCampaignDialog"
  [resizable]="false"
  [modal]="true"
  [style]="{ width: '52.5em', 'font-weight': '700' }"
>
  <div class="pb-25">
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="alert-header"
      >Please confirm you want to activate {{ selectedCampaign?.name }}</span
    >
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex">
      <p-button
        class=""
        (click)="onSwitchPublished(selectedCampaign)"
        [style]="{ 'margin-right': '17.5px' }"
        label="Yes, I want to activate"
        styleClass="p-button-rounded"
      >
      </p-button>
      <div>
        <p-button
          (click)="showPublishedCampaignDialog = false"
          label="Continue Editing"
          styleClass="p-button-secondary"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  class="custom-alert custom-modal"
  id="deactivated"
  class="custom-alert custom-modal"
  [resizable]="false"
  [(visible)]="isDeactivated"
  [modal]="true"
  [baseZIndex]="10000"
  [style]="{ width: '52.5em', 'font-weight': '700' }"
>
  <div class="pb-25">
    <p class="confirm-sms">This Campaign has been deactivated.</p>
  </div>
  <div>
    <p class="dialog-head">
      Campaigns can only be edited while inactive and will not execute any
      queued steps until reactivated.
    </p>
  </div>
  <div class="pb-35"></div>
  <div class="d-flex align-items-center">
    <p-button
      [style]="{ 'margin-right': '17.5px' }"
      (onClick)="closeDeactivate()"
      label="Close"
    >
    </p-button>
  </div>
</p-dialog>

<!--Validation Campaign dialog-->
<p-dialog
  class="custom-alert"
  #validateCampaignDialog
  [(visible)]="showValidateCampaignDialog"
  [resizable]="false"
  [modal]="true"
  [style]="{ 'font-size': '16px', padding: '0px !important' }"
>
  <!-- span changed to div -->
  <div>
    <span class="fw-bold mb-4" style="font-size: 20px"
      >The following are incomplete and required to activate this campaign:
    </span>
    <ul [innerHTML]="validationString" style="padding: 2% 8%">
      <!-- <li style=" padding:0 5%; list-style-type: none;"  ></li> -->
    </ul>
  </div>

  <div class=""></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex">
      <p-button
        class="pt-2"
        (click)="showValidateCampaignDialog = false"
        [style]="{ 'margin-right': '17.5px' }"
        label="Close"
        styleClass="p-button-rounded"
      >
      </p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- Copy campaign dialog -->
<p-dialog
  class="custom-dialog"
  #copyCampaignDialog
  [resizable]="false"
  (onHide)="onCloseCopyCampaignDialog()"
  [(visible)]="showCopyCampaignDialog"
  [modal]="true"
  class="custom-dialog"
  [style]="{ width: '53em', 'font-weight': '700', 'z-index': '1000!important' }"
>
  <p-header>
    <!-- <i class="pi pi-send" style="font-size: 1em; margin-right: 10px;"></i> -->
    <!-- <span class="dialog-head">Make a Copy</span> -->
    <span class="dialog-head">Copy Campaign</span>
  </p-header>
  <!-- <div class="dialog-head modal-title-copy">Copy from {{selectedCampaign?.name}}</div> -->
  <!-- <div class="mt-20" style="margin-top: 1.4rem;"></div> -->
<table width="100%">
  <tr>
    <td style="text-align: left; font-family: HelveticaNeue; font-weight: 500">
      <label class="form-label fw-bold">New campaign name</label>
      <div class="pb-2 text-secondary fst-italic">For internal use only</div>
    </td>
  </tr>
  <tr>
    <td style="width: 100%">
      <input placeholder="Campaign Name [Copy]"
             #txtCopyCampaign
             maxlength="100"
             id="txtCopyCampaign"
             type="text"
             style="width: 100%; font-size: 14px"
             [(ngModel)]="copyCampaignName"
             pInputText />
    </td>
  </tr>
  <tr>
    <td style="text-align: left; font-family: HelveticaNeue; font-weight: 500">
      <label class="form-label fw-bold">Description</label>
    </td>
  </tr>
  <tr>
    <td style="width: 100%">
      <textarea maxlength="255"
                [(ngModel)]="copyCampaignDesc"
                style="width: 100%; font-size: 14px; height: 100px"
                pInputText></textarea>
    </td>
  </tr>
  <!--PV6UI-1269
  <tr *ngIf="selectedCampaign?.isPublishingDb">
    <td>
      <p-checkbox id="chkCopyMessgeTemplate"
                  name="groupname"
                  [binary]="true"
                  label="Make a copy of the Message Template."
                  [(ngModel)]="copyMessageTemplate"></p-checkbox>
    </td>
  </tr>-->
  <tr *ngIf="alertMessageDuplicateCampaignName">
    <td>
      <span class="text-danger">{{ alertMessageDupCampaignNameString }}</span>
    </td>
  </tr>
</table>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end align-items-center footermodal">
      <a
        (click)="onCloseCopyCampaignDialog()"
        style="
          color: #2e2e2e;
          cursor: pointer;
          margin-right: 17.5px;
          text-decoration: underline;
          font-weight: 400;
        "
        styleClass="p-button-secondary"
        >Cancel</a
      >
      <p-button
        (onClick)="onSaveCopyCampaignDialog()"
        [style]="{ 'margin-left': '17.5px' }"
        [disabled]="!copyCampaignName || saveClicked"
        label="Save New Campaign"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- CampaignPreviewDialog -->
<app-campaign-preview
  *ngIf="showCampaignPreviewFlag"
  (DialogCloseEvent)="DialogCloseEvent($event)"
  [CampaignPreviewData]="campaignPreviewData"
>
</app-campaign-preview>
