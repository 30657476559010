<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:0px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="flex flex-column p-mt-2" [formGroupName]="i">

      <div class="w-auto mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" [style]="{'width':'98%'}" formControlName="operandDropDown"
          filterBy="name" [filter]="true" filterBy="OpDisplay"></p-dropdown>
      </div>

      <div class="w-auto mb-3">
        <div class="col-12 d-flex " *ngIf="item.get('operandDropDown').value?.RhsInputControl1">
          <i class="pi pi-reply replytosection col-1 me-2 ms-2"
            [ngClass]="{ 'me-3': item.get('operandDropDown').value?.RhsInputControl2}"></i>
          <p-inputNumber class="w-auto rhs-input-one" 
            [ngClass]="{ 'rhs-input-one' : item.get('operandDropDown').value?.RhsInputControl2, 'rhs-input-one-extended' : !item.get('operandDropDown').value?.RhsInputControl2}" 
            [min]="1" mode="decimal" inputId="minmaxfraction" [minFractionDigits]="3"
            [maxFractionDigits]="3" [maxlength]="13" id="txtRHSValue1{{ i }}" suffix="%"
            [formControlName]="'txtRHSValue1'"
            [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors }">
          </p-inputNumber>
          <div class="col-1 mx-1 d-flex" *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
            <div class="m-auto font-weight-bold">
              and
            </div>
          </div>
          <p-inputNumber class="w-auto rhs-input-two" [min]="1" [mode]="decimal" inputId="minmaxfraction" [minFractionDigits]="3"
              [maxFractionDigits]="3" [maxlength]="13" id="txtRHSValue2{{ i }}" suffix="%" *ngIf="item.get('operandDropDown').value?.RhsInputControl2"
              [formControlName]="'txtRHSValue2'" [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue2')?.errors}" >
            </p-inputNumber>
        </div>
      </div>

      

      <div class="col-12">
        <div class="required-text" 
          *ngIf="item.get('txtRHSValue1')?.touched 
            && item.get('operandDropDown').value?.RhsInputControl1
            && item.get('txtRHSValue1')?.errors 
            && !item.get('operandDropDown').value?.RhsInputControl2">
          Required
        </div>
        <div class="required-text" 
          *ngIf="(item.get('txtRHSValue2')?.touched 
            && item.get('operandDropDown').value?.RhsInputControl2 
            && item.get('txtRHSValue2')?.errors) || 
            (item.get('txtRHSValue1')?.touched 
            && item.get('operandDropDown').value?.RhsInputControl1 
            && item.get('txtRHSValue1')?.errors) && item.get('operandDropDown').value?.RhsInputControl2">
          Both fields are required
        </div>
      </div>

    </div>
  </div>
  <div class="row" style="padding:15px 30px;">
    <div class="col-12 text-right">
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" label="Apply"></p-button>
    </div>
  </div>
</div>