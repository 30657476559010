import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CampaignInfo } from 'src/models/Campaign';

@Component({
  selector: 'app-build-your-own-message',
  templateUrl: './build-your-own-message.component.html',
  styleUrls: ['./build-your-own-message.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildYourOwnMessageComponent implements OnInit {

  @Input() campaignInfo: CampaignInfo;

  constructor() { }

  ngOnInit(): void {

  }

}
