import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { AppSettings } from '../appSettings';
import { RequestResponse } from '../models/RequestResponse';
import {  ResponseTicket } from '../models/ResponseTicket';

@Injectable({ providedIn:'root'} )
export class ZenDeskService {
  constructor(private http: HttpClient) {
  }

  public CreateZenDeskTicket(subject : String, comments: String, requesterEmail: String, requesterName: String,
    formData: FormData): Observable<ResponseTicket> {
    const baseUrl = `${AppSettings.API_GET_ZenDesk_Url}`;
    var obj = {
      Subject : subject,
      Comments : comments,
      RequesterEmail :requesterEmail,
      RequesterName : requesterName
    }
    return this.http.post<ResponseTicket>(baseUrl, obj);

    //let apiUrl :string = baseUrl + `?Subject=${subject}&Comments=${comments}&RequesterEmail=${requesterEmail}&RequesterName=${requesterName}`;
    //return this.http.post<ResponseTicket>(apiUrl,formData);
  }
}
