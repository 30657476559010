import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { OnBoardSetupAdminTenant } from 'src/models/NGENTenant';
import { ToastService } from 'src/services/toast.service';
import { OnBoardingService } from '../../../services/onBoarding.service';
import { BillingService } from '../../../services/billingService';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { environment } from 'src/environments/environment';

declare var _ps: any;

@Component({
  selector: 'app-set-up-account',
  templateUrl: './set-up-account.component.html',
  styleUrls: ['./set-up-account.component.scss']
})

export class SetUpAccountComponent implements OnInit, AfterViewInit {
  companyForm4: FormGroup | any = null;
  public navigatingFrom: string;
  public loadingPage: boolean = false;
  public firstName: string;
  public lastName: string;
  public tenant: OnBoardSetupAdminTenant = new OnBoardSetupAdminTenant();
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";
  public user: UserToken;
  public isTestMode = false;
  public isSMSSetting = false;
  public testModeEmail = '';
  public testModeMobile = '';
  public liveModeEmail = '';
  public liveModeMobile = '';
  public isValidTestEmail: boolean = true;
  public stateLicenses: any[] = [];
  public showSateLicenseDropDown = false;
  public smsBranding: string = 'NO';
  public isSubscribed: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private BillingService: BillingService,
    private onBoardingService: OnBoardingService,
    private userTokenService: UserTokenService
  ) {
    window['_ps'] = null;
    this.storageHelper = new StorageHelper();
    this.storageHelper.ClearStorageByKey(this.onBoardingNavigationCacheKey);
    this.user = this.userTokenService.getUser();
    this.firstName = '';
    this.lastName = '';

    if (window['_ps'] == null || window['_ps'] == undefined) {
      (function (w, d, s, c, f, n, t, g, a, b, l) {
        // Defines the global _ps object and initializes the _ps() function
        // that will queue commands until the Ironclad Clickwrap Library is ready.
        w['PactSafeObject'] = n;
        w[n] = w[n] || function () {
          (w[n].q = w[n].q || []).push(arguments)
        },

        // Defines the event functions for the global _ps object.
        w[n].on = function () {
          (w[n].e = w[n].e || []).push(arguments)
        },
        w[n].once = function () {
          (w[n].eo = w[n].eo || []).push(arguments)
        },
        w[n].off = function () {
          (w[n].o = w[n].o || []).push(arguments)
        },

        // Marks the time that the script is inserted.
        w[n].t = 1 * new Date().getTime(),
        w[n].l = 0;

        // Inserts a new script element to load the Ironclad Clickwrap Library JS file (ps.js).
        a = d.createElement(s);
        b = d.getElementsByTagName(s)[0];
        a.async = 1;
        a.src = c;

        // Marks that the script has started loading or failed to load.
        a.onload = a.onreadystatechange = function () { w[n].l = 1 };
        a.onerror = a.onabort = function () { w[n].l = 0 };
        b.parentNode.insertBefore(a, b);

        // Retry loading the script from a fallback location after 4 seconds.
        setTimeout(function () {
          if (!w[n].l && !w[n].loaded) {
            w[n].error = 1;
            a = d.createElement(s);
            a.async = 1;
            a.src = f;
            a.onload = a.onreadystatechange = function () { w[n].l = 1 };
            a.onerror = a.onabort = function () { w[n].l = 0 };
            b.parentNode.insertBefore(a, b);

            // Log the loading error via beacon.
            l = function (u, e) {
              try {
                e = d.createElement('img');
                e.src = 'https://d3r8bdci515tjv.cloudfront.net/error.gif?t=' + w[n].t + '&u=' + encodeURIComponent(u);
                d.getElementsByTagName('body')[0].appendChild(e);
              }
              catch (x) { }
            };
            l(c);

            // Call the optional error callback function after a second failed attempt.
            setTimeout(function () {
              if (!w[n].l && !w[n].loaded) {
                w[n].error = 1;
                if (g && 'function' == typeof g) {
                  g.call(this);
                }
                l(f);
              }
            }, t);
          }
        }, t);
      })(window, document, 'script', '//vault.pactsafe.io/ps.min.js', '//d3l1mqnl5xpsuc.cloudfront.net/ps.min.js', '_ps', 4000, function optionalErrorCallback() { alert('Unable to load the JS Library.') });

      // Creates a Site object with the default configuration.
      _ps('create', environment.ironcladSiteId);
    }
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await this.loadTenantUser();
    var groupKey = "embedded-authorizedconfirmation";
    _ps("load", groupKey, { container_selector: "clickwrapContainer", signer_id: this.user.email, display_all: true });
    _ps("set", "signer_id", this.user.email);
    _ps("send", "updated", { custom_data: { first_name: this.user.given_name, last_name: this.user.family_name, company_name: this.user.TenantName, lender_id: this.user.TenantId } });

    // Submits the form once the contracts have been accepted.
    _ps.on('valid', function (parameters, group) {
      // do nothing
    }.bind(this));

    /******************************
     * Tracking acceptance and getting the contract
     * Acceptance of a Clickwrap Group, by default, is sent when your signer checks a checkbox or clicks the “I Agree” button. To send 
     * acceptance outside of that default workflow, you can follow the below syntax:
     * Note: Sending acceptance like this requires a callback
    *******************************/
    _ps(groupKey, "agreed", {
      // The event_callback function will be invoked once the "send" is complete.
      event_callback: function (err, eventType, clickwrapGroup, payload) {
        if (err) {
          console.log(err);
        }
        // The send is complete and acceptance was captured successfully.
      }.bind(this)
    });
  }

  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => { this.setControlStates(); }, 10);
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() { }

  createForm() {
    return new Promise((resolve, reject) => {
      this.companyForm4 = this.formBuilder.group({
        companyAdminFirstName: [null, [Validators.required]],
        companyAdminLastName: [null, [Validators.required]],
        companyAdminEmail: [null, [Validators.required]],
      });
      resolve(true);
    });
  }

  async loadTenantUser() {
    await this.getData();
    if (this.tenant) {
      this.companyForm4.setValue({
        companyAdminFirstName: this.firstName,
        companyAdminLastName: this.lastName,
        companyAdminEmail: this.user.email
      });
    }
  }

  async getData() {
    this.loadingPage = true;
    try {
      var response = await this.onBoardingService.GetLoggedOnUser();
      this.firstName = response.data.firstName;
      this.lastName = response.data.lastName;
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async saveAccount() {
    this.loadingPage = true;
    try {
      let viewModel = new OnBoardSetupAdminTenant(
        this.tenant.TenantId,
        this.companyForm4.value.companyAdminFirstName,
        this.companyForm4.value.companyAdminLastName,
        this.companyForm4.value.companyAdminEmail
      );

      const formData: FormData = new FormData();
      formData.append('companyAdminFirstName', viewModel.CompanyAdminFirstName);
      formData.append('companyAdminLastName', viewModel.CompanyAdminLastName);
      formData.append('companyAdminEmail', viewModel.CompanyAdminEmail);
      formData.append('companyAdminEmailReadonly', 'true');

      var response = await this.onBoardingService.OnBoardingAdminProfile(formData);
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      }
      else if (response.status == 200) {
        await this.getCompanyAdditionalDetail();
        await this.saveCompanyadditionalDetail();
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  getCompanyAdditionalDetail() {
    this.BillingService.GetCompanyAdditionalDetail().subscribe(
      (res) => {
        if (res.data) {
          this.isTestMode = res.data.marketingMode;
          this.isSMSSetting = res.data.smsSetting
          this.testModeEmail = res.data.emailTestMode;
          this.testModeMobile = res.data.mobileTestMode;
          this.liveModeEmail = res.data.emailLiveMode;
          this.liveModeMobile = res.data.mobileLiveMode;
          this.isSubscribed = res.data.subscribed;
          if (res.data.smsBranding) {
            this.smsBranding = "YES";
          } else {
            this.smsBranding = "NO";
          }
          if (res.data.stateLicenses) {
            this.stateLicenses = res.data.stateLicenses;
          }
        }
      },
      (err) => {
      }
    );
  }

  saveCompanyadditionalDetail() {
    let data = {
      marketingMode: this.isTestMode,
      smsSetting: this.isSMSSetting,
      emailTestMode: this.testModeEmail,
      mobileTestMode: this.testModeMobile,
      emailLiveMode: this.liveModeEmail,
      mobileLiveMode: this.liveModeMobile,
      stateLicenses: this.stateLicenses,
      smsBranding: (this.smsBranding == "YES" ? 1 : 0)
    }
    try {
      this.BillingService.saveCompanyAdditionalDetails(data).subscribe((res) => {
        if (res.status == 200) {
          console.log('Additional information changed successfully');
        } else {
          this.toastService.showError('Additional information cannot be saved, this is due to error: ' + res.message);
        }
      },
        (err) => {
          this.toastService.showError('Additional information cannot be saved, please contact your administrator for assistance');
        });
      return true;
    }
    catch (error) {
      return false;
    }
  }

  redirectBackToWelcomePage(): void {
    this.storageHelper.ClearStorageByKey(this.onBoardingNavigationCacheKey);
    this.router.navigate(['/welcome-screen']);
  }

  redirectNextToWelcomeForm(): void {
    var element = <HTMLInputElement>document.getElementById("ps-contract-checkbox-271110");
    var contractChecked = false;
    if(element) {
      contractChecked = element.checked;
    }

    if (this.companyForm4.valid) {
      if (contractChecked) {
        this.saveAccount();

        if (this.navigationStack === undefined) {
          this.navigationStack = [];
        }
        
        let navigateTo = '/welcome-form';
        let navigateFrom = '/set-up-account';
        this.navigationStack.push(navigateFrom);
        this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
        this.router.navigate([navigateTo]);
        window['_ps'] = null;
      } else {
        this.toastService.showError("Please accept Initial Authorized Confirmation.");
      }
    }
  }
}
