import { AndOr, Group, SqlComparison, DbType } from './enumerations';

/** 
* Implementation of the FocusIT.Common.DataContracts.WhereClauseCriteria [DataContract].
*/
export class WhereClauseCriteria {

  public ParameterName: string = '';
  public Value: any = null;
  public AndOr: AndOr = 0; // AND
  public SqlDbType: DbType = 12; // NVarChar
  public Comparison: SqlComparison = 0; // EQ
  public Group: Group = 0; // NONE

  /**
  * Constructor - ParameterName and Value are required, all other parameters are optional.
  * 
  * @param ParameterName
  * @param Value
  * @param SqlDbType
  * @param Comparison
  * @param AndOr
  * @param Group
  */
  constructor(
    ParameterName: string,
    Value: any,
    SqlDbType?: DbType,
    Comparison?: SqlComparison,
    AndOr?: AndOr,
    Group?: Group) {

    if ((ParameterName !== undefined) && (ParameterName !== null)) this.ParameterName = ParameterName;
    if ((SqlDbType !== undefined) && (SqlDbType !== null))
      this.SqlDbType = SqlDbType;
    else
      this.SqlDbType = DbType.NVarChar;
    if ((Comparison !== undefined) && (Comparison !== null)) this.Comparison = Comparison;
    if ((AndOr !== undefined) && (AndOr !== null)) this.AndOr = AndOr;
    if ((Group !== undefined) && (Group !== null)) this.Group = Group;

    if (Value !== undefined) {
      this.Value = Value;
    }
    else {
      this.Comparison = SqlComparison.ISNULL;
    }

  }
}

/**
* Collection of FocusIT.Common.DataContracts.WhereClauseCriteria [DataContract] objects.
*/
export class WhereClauseCriteriaCollection
{
  Items: WhereClauseCriteria[] = [];
}

