<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
            style="width:11.11%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="setup-form position-relative container-middle h-100">
    <div class="container-size">
      <div class="m-auto d-flex justify-content-center flex-column text-center mt-2">
        <h5 class="title-onboarding">Set Up Account</h5>
      </div>
      <div class="m-auto">
        <div class="mt-5 row">
          <div class="col-6">
            <form [formGroup]="companyForm4" class="form-container">
              <div class="row mb-4 ">
                <div class="col-12">
                  <label for="companyAdminFirstName" class="form-label fw-bold">First Name</label>
                  <input type="text" name="companyAdminFirstName" id="companyAdminFirstName" maxlength="64"
                    placeholder="Admin First Name" class="form-control" formControlName="companyAdminFirstName"
                    [ngClass]="{
                        'is-invalid':
                          companyForm4.get('companyAdminFirstName').invalid &&
                          (companyForm4.submitted),
                        'is-valid':
                          companyForm4.get('companyAdminFirstName').valid &&
                          (companyForm4.submitted)
                      }" />
                  <span class="text-danger" *ngIf="
                              companyForm4.get('companyAdminFirstName').invalid &&
                              (companyForm4.get('companyAdminFirstName').dirty ||
                              companyForm4.get('companyAdminFirstName').touched ||
                              companyForm4.submitted) &&
                              companyForm4.get('companyAdminFirstName').errors?.required
                            ">First name cannot be blank</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <label for="companyAdminLastName" class="form-label fw-bold">Last Name</label>
                  <input type="text" name="companyAdminLastName" id="companyAdminLastName" maxlength="64"
                    placeholder="Admin Last Name" class="form-control" formControlName="companyAdminLastName" [ngClass]="{
                              'is-invalid':
                                companyForm4.get('companyAdminLastName').invalid &&
                                (companyForm4.submitted),
                              'is-valid':
                                companyForm4.get('companyAdminLastName').valid &&
                                (companyForm4.submitted)
                            }" />
                  <span class="text-danger" *ngIf="
                              companyForm4.get('companyAdminLastName').invalid &&
                              (companyForm4.get('companyAdminLastName').dirty ||
                              companyForm4.get('companyAdminLastName').touched ||
                              companyForm4.submitted) &&
                              companyForm4.get('companyAdminLastName').errors?.required
                            ">Last name cannot be blank</span>
                </div>
              </div>
              <div class="row mb-4 ">
                <div class="col-12">
                  <label for="companyAdminEmail" class="form-label fw-bold">Email</label>
                  <input type="text" readonly="true" name="companyAdminEmail" id="companyAdminEmail" maxlength="60"
                    placeholder="Email" class="form-control" formControlName="companyAdminEmail" />
                </div>
              </div>
            </form>
          </div>
          <div class="col-6 pt-3">
            <!-- <p class="para-section mt-10">
              You represent and warrant that you ("Administrator") have the general authority to enter
              into all contracts on behalf of Company listed above. Administrator will have access to
              all Client data imported into the ADUVO Applications. The Client data includes the historical
              loan records previously associated with the Client's loan origination system. Notwithstanding
              any provision to the contrary, this sign-up process is not intended to create any express or
              implied authority for the Administrator to sign on behalf of the Client for any purpose other
              than those set out by Client.
            </p> -->
              <div class="para-section mt-10" id="clickwrapContainer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section flex flex-column" style="position: absolute;">
    <div class="onboarding-bottom my-3 container-size w-100">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded font-16-bold"
          (click)="redirectBackToWelcomePage()">
          <i class="fa fa-arrow-left"></i>&nbsp;<span class="intro-text">Back</span>
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end font-16-bold" (click)="redirectNextToWelcomeForm()">
          <span class="intro-text">Next</span>
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>
        <app-to-dashboard-button class="float-end"></app-to-dashboard-button>
      </div>
    </div>
  </div>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
          [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>