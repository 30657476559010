import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { LookupService } from 'src/services/lookup.service';
import { OnBoardTenant } from 'src/models/NGENTenant';
import { OnBoardingService } from '../../../services/onBoarding.service';
import { ToastService } from 'src/services/toast.service';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { USAStatesService } from 'src/services/usstates.service';
import { UserTokenService } from 'src/services/user-token.service';

@Component({
  selector: 'app-welcome-form',
  templateUrl: './welcome-form.component.html',
  styleUrls: ['./welcome-form.component.scss']
})

export class WelcomeFormComponent implements OnInit, AfterViewInit {
  companyForm1: FormGroup | any = null;
  public loadingPage: boolean = false;
  public tenant: OnBoardTenant = new OnBoardTenant();
  public usStates: any[] = [];
  public selectedState: any;
  public timezoneList: any[] = [];
  public isValidCompanyEmail: boolean;
  public uploadedImagefile: any = null;
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;  
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";
  public tenantId: any = '00000000-0000-0000-0000-000000000000';
  public user: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private toastService: ToastService,
    private onBoardingService: OnBoardingService,
    private usStatesService: USAStatesService,
    private userTokenService: UserTokenService,
  ) {

    

    this.isValidCompanyEmail = false;
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
    this.usStates = usStatesService.getUSAStateCodesAndNames();

    this.lookupService.GetTimezones().subscribe(result => {
      if (result && result.data) {
        this.timezoneList = result.data.map((obj) => {
          return { code: obj.timezoneId, name: obj.displayName }
        });
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.user = this.userTokenService.getUser();
    this.createForm();
    await this.loadCompany();

    // Only company admins should have access to the onboarding process. Anything sending a non-admin user to the welcome screen should be redirected to dashboard.
    if (!this.user.isCompanyAdmin())
      this.router.navigateByUrl("dashboard");
  }

  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => { this.setControlStates(); }, 10);
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() { }

  createForm() {
    return new Promise((resolve, reject) => {
      this.companyForm1 = this.formBuilder.group({
        companyName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(255),],],
        companyEmail: [null, [Validators.required]],
        addressLine1: [null],
        addressLine2: [null],
        city: [null],
        state: [null],//@ts-ignore
        zip: [null, [Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/s)]],//@ts-ignore
        workPhone: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],//@ts-ignore
        fax: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
        nmls: [null],
        website: [null, Validators.pattern(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?/),],
        timezoneId: [null, [Validators.required]],
        losVendorId: [null]
      });
      resolve(true);
    });
  }

  async loadCompany() {
    await this.getData();
    if (this.tenant) {
      this.companyForm1.setValue({
        companyName: this.tenant.Name,
        companyEmail: this.tenant.Email,
        addressLine1: this.tenant.AddressLine1,
        addressLine2: this.tenant.AddressLine2,
        city: this.tenant.City,
        state: this.tenant.State,
        zip: this.tenant.Zip,
        workPhone: this.formatNumber(this.tenant.WorkPhone),
        fax: this.formatNumber(this.tenant.Fax),
        nmls: this.tenant.NmlsId,
        website: this.tenant.WebSite,
        timezoneId: this.tenant.TimezoneId,
        losVendorId: this.tenant.LosVendorId
      });
      this.validateCompanyEmail();
    }
  }

  workPhoneFormat(event: any) {//returns (###) ###-####
    let input: any = this.companyForm1.controls['workPhone'].value;
    input = this.formatNumber(input);    
    this.companyForm1.controls['workPhone'].setValue(input);
  }

  faxFormat(event: any) {//returns (###) ###-####
    let input: any = this.companyForm1.controls['fax'].value;
    input = this.formatNumber(input);    
    this.companyForm1.controls['fax'].setValue(input);
  }

  formatNumber(input) {
    if(input == '' || input == null || input === undefined) {
    } else {
      input = input.replace(/\D/g,'').substring(0,10); //Strip everything but 1st 10 digits
      var size = input.length;
      if (size>0) {input="("+input}
      if (size>3) {input=input.slice(0,4)+") "+input.slice(4)}
      if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
    }    
    return input;
  }

  async getData() {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      var response = await this.onBoardingService.Get(this.tenantId);
      // assign returned object to class variable.
      this.tenant.Name = response.data.name;
      this.tenant.Email = response.data.email;
      this.tenant.AddressLine1 = response.data.addressLine1;
      this.tenant.AddressLine2 = response.data.addressLine2;
      this.tenant.City = response.data.city;
      this.tenant.State = response.data.state;
      this.tenant.Zip = response.data.zip;
      this.tenant.WorkPhone = response.data.workPhone;
      this.tenant.Fax = response.data.fax;
      this.tenant.NmlsId = response.data.nmls;
      this.tenant.WebSite = response.data.website;
      this.tenant.TimezoneId = response.data.timezoneId;
      this.tenant.LosVendorId = response.data.losVendorId;

      // when data is retrieved, set this.loadingPage to false.
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  validateCompanyEmail() {
    if (this.companyForm1.value.companyEmail == "") {
      this.isValidCompanyEmail = true;
    }
    else {
      this.isValidCompanyEmail = this.validateEmail(this.companyForm1.value.companyEmail);
    }
  }

  validateEmail(emailAddress: any) {
    let isValid = false;
    const singleEmailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = singleEmailRegularExpression.test(String(emailAddress).toLowerCase());
    return isValid;
  }

  async saveCompany() {
    this.loadingPage = true;
    try {
      let viewModel = new OnBoardTenant(
        this.tenant.TenantId,
        this.companyForm1.value.companyName,
        this.companyForm1.value.companyEmail,
        this.companyForm1.value.addressLine1,
        this.companyForm1.value.addressLine2,
        this.companyForm1.value.city,
        this.companyForm1.value.state,
        this.companyForm1.value.zip,        
        this.companyForm1.value.workPhone,
        this.companyForm1.value.fax,
        this.companyForm1.value.nmls,
        this.companyForm1.value.website,
        this.companyForm1.value.timezoneId,
        this.companyForm1.value.losVendorId
      );

      const formData: FormData = new FormData();
      formData.append('name', viewModel.Name);
      formData.append('email', viewModel.Email);
      formData.append('addressLine1', viewModel.AddressLine1);
      formData.append('addressLine2', viewModel.AddressLine2);
      formData.append('city', viewModel.City);
      formData.append('state', viewModel.State);
      formData.append('zip', viewModel.Zip);
      formData.append('workPhone', viewModel.WorkPhone);
      formData.append('fax', viewModel.Fax);
      formData.append('nmls', viewModel.NmlsId);      
      formData.append('website', viewModel.WebSite);
      formData.append('timezoneId', viewModel.TimezoneId);
      formData.append('losVendorId', viewModel.LosVendorId);
      
      var response = await this.onBoardingService.OnBoardWelcomeTenantProfile(formData);
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      }
      else if (response.status == 200) {
        let navigateTo = '/add-admin';
        let navigateFrom = '/welcome-form';
        if (this.navigationStack === undefined) {
          this.navigationStack = [];
        }        
        this.navigationStack.push(navigateFrom);
        this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
        this.router.navigate([navigateTo]);
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/set-up-account']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToAddAdmin(): void {
    if (this.companyForm1.valid && this.isValidCompanyEmail) {
      this.saveCompany();
    }
  }
}
