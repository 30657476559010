import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ConfirmationService,
  LazyLoadEvent,
  MenuItem,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { ToastService } from 'src/services/toast.service';
import { RequestResponse } from 'src/models/RequestResponse';
import { ImageLibraryService } from 'src/services/imageLibrary.service';
import { Menu } from 'primeng/menu';
import { ImageLibrary } from 'src/models/ImageLibrary';
import { imageUploadDialogComponent } from 'src/components/dialogs/imageUploadDialog.component';
import { imagePreviewDialogComponent } from 'src/components/dialogs/imagePreviewDialog.component';
import { ImageService } from 'src/services/image.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { PagingData } from 'src/models/PagingData';
import { MessageTemplate } from 'src/models/MessageTemplate';
import { Router } from '@angular/router';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
declare var ExternalImageLibrary: any;
import { ExternalImageLibrary } from 'src/scripts/imageLibrary';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'imageLibrary-root',
  templateUrl: './imageLibrary.component.html',
  styleUrls: ['./imageLibrary.component.scss'],
})
export class imageLibraryComponent implements OnInit {
  @ViewChild('libraryTableId', { static: false }) dataTable: Table;
  @ViewChild('ellipseMenu', { static: false }) ellipseMenu: Menu;
  @ViewChild('imageUploadDialog', { static: false })
  imageUploadDialog: imageUploadDialogComponent;
  @ViewChild('imagePreviewDialog', { static: false })
  imagePreviewDialog: imagePreviewDialogComponent;

  dialogTitle = 'Add Image';
  // used for showing/hiding spinner, progress bar or skeleton.
  loadingPage: boolean = false;
  isEdit: boolean = false;
  // Standard Request/Response object. Includes Paging support if necessary
  webAPI: RequestResponse = new RequestResponse();
  // select statement order by clause (passed to service/controller)
  orderBy: string = 'DateUpdatedUTC DESC';
  defaultOrderBy = 'DateUpdatedUTC DESC';
  sortField = 'DateUpdatedUTC';
  sortOrder = -1;
  // row selections
  selectedImages: ImageLibrary[] = [];
  selectedImage: ImageLibrary = new ImageLibrary();
  selectedImageClone: ImageLibrary = new ImageLibrary();
  // Context (...) menu contents
  contextMenuItems: MenuItem[];
  /** Used to show/hide Preview dialog */
  showImagePreviewDialog: boolean = false;
  /** Used to show/hide Image upload dialog */
  showImageUploadDialog: boolean = false;
  /** Used to show/hide Error dialog */
  showErrors: boolean = false;
  /** Holds the errors generated by the upload dialog's validator */
  errors: any[] = [];
  /** Used to enable/disable Add Image button */
  addImageDisabled: string = 'disabled';
  // Global Keyword filter
  searchKeyword: string = '';
  refreshRequired: boolean = false;
  userToken: UserToken;
  showDeleteImageDialog: boolean = false;
  public pageIndex: number = 0;
  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   * @param permissionGroupService Service for working with permission groups.
   * @param userSessionService Service for user session information.
   */
  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    public dialogService: DialogService,
    private messageTemplatesService: MessageTemplatesService,
    private imageService: ImageService,
    private imageLibraryService: ImageLibraryService,
    private userTokenService: UserTokenService,
    //private teamsService: TeamsService,
    private router: Router
  ) {
    // initialize context menu
    this.contextMenuItems = this.getContextMenuItems();
    this.webAPI.paging.pageSize = 25;
    this.webAPI.paging.orderBy = this.defaultOrderBy;
  }

  // #endregion

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    this.userToken = this.userTokenService.getUser();
    // obtain data for the page
    this.getData();
  }

  async loadImages(event: LazyLoadEvent) {
    let orderBy = this.defaultOrderBy;
    if (event.sortField == null || event.sortField == undefined) return;

    this.sortField = event.sortField.toString();
    this.sortOrder = event.sortOrder;
    orderBy = this.setSort(this.sortField, this.sortOrder);

    this.webAPI.paging.currentPage = event.first / event.rows + 1; // First row offset
    this.webAPI.paging.pageSize = event.rows; //Number of rows per page
    this.webAPI.paging.orderBy = orderBy;

    await this.refreshData();
  }

  /**
   * Retrieves all necessary data for this component.
   *
   * @param: currentPage - paging support.
   */
  async getData() {
    if (this.loadingPage) return;
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      this.webAPI.data = []; //  Required - Clear data from last request or set your specific controller request data.
      if (this.searchKeyword.length > 0) {
        let columnValue = this.searchKeyword;
        this.webAPI.data = columnValue;
      }
      this.webAPI.paging.pagingRead = true; // Required for lazy loading of data (default gets all records).
      var response = await this.imageLibraryService.getLibraryImages(
        this.webAPI,
        false
      );
      this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;
      this.webAPI.data = this.imageLibraryService.toCollectionInstance(
        response.data,
        this.userToken
      );

      // when data is retrieved, set this.loadingPage to false.
      this.loadingPage = false;
      this.addImageDisabled = '';
    } catch (message: any) {
      this.webAPI.paging.totalRecords = 0;
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }
  /**
   *  Goes to the user entered page.
   */
  onGotoPage() {
    if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
      this.refreshData();
    }
  }

  /**
   * Retrieves data applying any sorting and filtering, and scrolls the
   * list to its previous horizontal scroll position.
   */
  async refreshData() {
    await this.getData();
  }

  async onPublishChange(imageLibrary: ImageLibrary, $event) {
    if (!imageLibrary.Published) {
      this.imageLibraryService
        .setPublished(imageLibrary.ImageId, imageLibrary.Published)
        .subscribe((response) => {
          if (response.status == 200) {
            this.toastService.showSuccess('Image Unpublished');
          } else {
            this.toastService.showError(
              'Error Unpublishing! ' + response.message
            );
          }
        });
    } else {
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: {
          'max-height': '500px',
          overflow: 'auto',
          padding: '60px 50px 50px 50px',
        },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message:
            'Please confirm you want to activate image ' + imageLibrary.Title,
          successButtonText: 'Yes, I want to activate',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          this.imageLibraryService
            .setPublished(imageLibrary.ImageId, imageLibrary.Published)
            .subscribe((response) => {
              if (response.status == 200) {
                this.toastService.showSuccess('Image Published');
              } else {
                this.toastService.showError(
                  'Error Publishing! ' + response.message
                );
              }
            });
        } else {
          imageLibrary.Published = false;
        }
      });
    }
  }
  /**
   * Occurs when '...' is clicked in a row to diplay the Context Menu replacement.
   *
   * @param event
   * @param template
   */
  async onEllipseClick(event: any, image: any) {
    this.selectedImage = image;
    this.selectedImageClone = JSON.parse(JSON.stringify(this.selectedImage));

    this.contextMenuItems[1].disabled = !this.selectedImage.CanEdit;
    this.contextMenuItems[1].visible = this.selectedImage.CanEdit;
    this.contextMenuItems[2].disabled = !this.selectedImage.CanDelete;
    this.contextMenuItems[2].visible = this.selectedImage.CanDelete;

    this.ellipseMenu.toggle(event);
  }

  /** Gets the Context menu's items */
  getContextMenuItems(): MenuItem[] {
    let result = [
      {
        label: 'Preview',
        //icon: 'pi pi-eye',
        command: () => this.onPreview(event),
      },
      {
        label: 'Edit',
        //icon: 'fa fa-pencil pencil',
        command: () => this.onEdit(event),
        disabled: true,
        visible: true,
      },
      {
        label: 'Delete',
        //icon: 'pi pi-trash',
        command: () => this.onDelete(event),
        disabled: true,
        visible: true,
      },
    ];

    return result;
  }

  /**
   *
   * @param columnName
   * @param order
   */

  selectedTemplates: ImageLibrary[] = [];
  selectedTemplate: ImageLibrary = new ImageLibrary();

  setSort(columnName: any, order: any) {
    var orderBy = this.defaultOrderBy;
    this.sortOrder = order;

    if (columnName == null || columnName == undefined) return;
    // set sort chip label and order by clause
    if (order == -1) {
      orderBy = columnName + ' DESC';
    } else {
      orderBy = columnName + ' ASC';
    }

    return orderBy;
  }

  /**
   * Called when the global filter value changes.
   * (called on every keystroke)
   */
  onGlobalFilterChange() {
    if (this.searchKeyword.length == 0) this.onApplyGlobalFilter();
  }

  /**
   * Applies the global filter to lazy loaded data.
   */
  async onApplyGlobalFilter() {
    this.webAPI.paging.currentPage = 1;
    this.pageIndex = 0;
    this.refreshData();
  }

  /**
   * User clicked on the 'Add Image' button.
   * @param event
   */
  async onAddImageBtnClick(event: any) {
    this.dialogTitle = 'Upload Image';
    this.isEdit = false;
    await this.imageUploadDialog.clearDialog();
    this.selectedImage = new ImageLibrary();
    this.selectedImageClone = new ImageLibrary();
    setTimeout(() => {
      this.showImageUploadDialog = true;
    }, 100);
  }

  /**
   //* Called when the Preview context menu item is chosen.
   * 
   * @param event
   */
  async onPreview(event: any) {
    await this.imagePreviewDialog.initializeDialog();
    setTimeout(() => {
      this.showImagePreviewDialog = true;
    }, 100);
  }

  /**
   * Called when the Edit context menu item is chosen.
   *
   * @param event
   */
  async onEdit(event: any) {
    this.dialogTitle = 'Edit Image';
    this.isEdit = true;
    this.refreshRequired = false;
    await this.imageUploadDialog.initializeDialog();
    setTimeout(() => {
      this.showImageUploadDialog = true;
    }, 100);
  }

  async onRowEdit(event: any, image: any) {
    this.selectedImage = image;
    this.selectedImageClone = JSON.parse(JSON.stringify(this.selectedImage));
    if (this.selectedImage.IsPublishingDb) {
      this.onPreview(event);
    } else {
      this.onEdit(event);
    }
  }

  /**
   * Called when the Delete context menu item is chosen.
   *
   * @param event
   */
  onDelete(event: any) {
    this.showDeleteImageDialog = true;
  }

  async deleteImage() {
    if (this.loadingPage) return;

    this.loadingPage = true;
    try {
      let response = await this.imageLibraryService.DeleteLibraryImage(
        this.selectedImage.ImageId
      );
      this.showDeleteImageDialog = false;
      this.loadingPage = false;
      this.refreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onCloseImageUploadDialog() {
    this.showErrors = false;
    //await this.imageUploadDialog.clearDialog();
    setTimeout(() => {
      this.showImageUploadDialog = false;

      if (this.refreshRequired) this.refreshData();

      this.refreshRequired = false;
    }, 100);
  }

  async onCloseImagePreviewDialog() {
    await this.imagePreviewDialog.clearDialog();
    setTimeout(() => {
      this.showImagePreviewDialog = false;

      if (this.refreshRequired) this.refreshData();
    }, 100);
  }

  onSaveImage() {
    this.selectedImage = this.imageUploadDialog.selectedImage;
    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message:
          'Are you sure you want to save the ' +
          this.selectedImage.Title +
          ' image?',
        successButtonText: 'Yes, I want to save',
        cancelButtonText: 'Cancel',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        this.doSave();
      }
    });
  }

  /**
   * Saves the template.
   */
  async doSave() {
    if (this.loadingPage) return;

    this.showErrors = false;
    this.loadingPage = true;
    this.selectedImage = this.imageUploadDialog.selectedImage;

    try {
      let imageModel = {
        fileImage: this.imageUploadDialog.uploadedImagefile,
        imageId: this.selectedImage.ImageId,
        imageURL: this.selectedImage.ImageURL,
        title: this.selectedImage.Title,
        width: `${this.selectedImage.Width}`,
        height: `${this.selectedImage.Height}`,
        avatar: `${this.selectedImage.Avatar}`,
        published: 'true',
        // published: `${this.selectedImage.Published}`,
        enabled: 'true',
        deleted: 'false',
        imageSize: this.selectedImage.ImageSize,
        imageLength: `${this.selectedImage.ImageLength}`,
      };
      const formData: FormData = new FormData();
      formData.append('fileImage', imageModel.fileImage);
      formData.append('imageId', imageModel.imageId);
      formData.append('imageURL', imageModel.imageURL);
      formData.append('title', imageModel.title);
      formData.append('width', imageModel.width);
      formData.append('height', imageModel.height);
      formData.append('avatar', imageModel.avatar);
      formData.append('published', imageModel.published);
      formData.append('enabled', imageModel.enabled);
      formData.append('deleted', imageModel.deleted);
      formData.append('imageSize', imageModel.imageSize);
      formData.append('imageLength', imageModel.imageLength);
      // create or save the Image

      if (this.isEdit) {
        var response = await this.imageLibraryService.EditLibraryImage(
          formData
        );
        if (response.status != 200) {
          this.toastService.showError(response.message);
        } else {
          ExternalImageLibrary.thumbnails = undefined;
          JSON.parse(
            JSON.stringify(
              this.imageLibraryService.toCollectionInstance(
                response.data,
                this.userToken
              )
            )
          );

          this.refreshRequired = true;
          this.loadingPage = false;
          this.onCloseImageUploadDialog();
          this.toastService.showSuccess(
            "Image '" + this.selectedImage.Title + "' updated successfully."
          );
        }
      } else {
        var response = await this.imageLibraryService.SaveLibraryImage(
          formData
        );

        if (response.status != 200) {
          this.toastService.showError(response.message);
        } else {
          ExternalImageLibrary.thumbnails = undefined;
          JSON.parse(
            JSON.stringify(
              this.imageLibraryService.toCollectionInstance(
                response.data,
                this.userToken
              )
            )
          );
          this.refreshRequired = true;
          this.loadingPage = false;
          this.onCloseImageUploadDialog();
          this.toastService.showSuccess(
            "Image '" + this.selectedImage.Title + "' saved successfully."
          );
        }
      }
    } catch (message: any) {
      console.error(message);
      this.toastService.showError(message);
    } finally {
      this.loadingPage = false;
    }
  }

  // #endregion
}
