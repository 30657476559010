import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, observable, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserDefineList } from '../../src/app/models/UserDefineList';
import { AppSettings } from '../appSettings';
import { environment } from '../environments/environment';
import { StorageHelper } from '../helpers/StorageHelper';
import { RhsQueryData } from '../models/PeopleAndLoanFilterOutput';
import { RequestResponse } from '../models/RequestResponse';
import { SearchParam } from '../app/models/SearchParam';
import { ImportResponse } from 'src/models/ContactAndLoanImportModel';

@Injectable({ providedIn: 'root' })

export class ContactAndLoanService {
  private storageHelper: StorageHelper;
  private expiredTimeInHour: number;

  constructor(private http: HttpClient) {
    this.storageHelper = new StorageHelper();
    this.expiredTimeInHour = 240;//24 Hours

  }

  public getAduvoFields(): Observable<RequestResponse> {
    let cacheKey = 'getAduvoFieldsKey';
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      return this.http.get<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/getaduvofields')
        .pipe(
          tap((x) => this.storageHelper.SetDataInStorage(cacheKey, x, this.expiredTimeInHour)),
          catchError(this.handleError)
        );
    }
  }

  public importContactsAndLoans(req?: any) {

    // let data = {
    //   tenantId: this.user.TenantId,
    //   tenantUserId: this.user.TenantUserId,
    //   contactOrLoanFile: this.saveModel.uploadedExcelFile,
    //   uploadedFileType: 1  //None = 0, Contact = 1,LoanContact = 2
    // }
    // Create form data
    const formData = new FormData();
    if (req.contactOrLoanFile) {
      // Store form name as "file" with file data
      formData.append('tenantId', req.tenantId);
      formData.append('tenantUserId', req.tenantUserId);
      formData.append("contactOrLoanFile", req.contactOrLoanFile, req.contactOrLoanFile.name);
    }
    return this.http.post<RequestResponse>(AppSettings.API_GET_NEW_CONTACTS + '/import-contacts-or-loans', formData)
      .pipe(catchError(this.handleError));
  }

  public updateMappingJson(importBatchId: string, mappedJsonResponse: string, importType: string, tags: string = '', defaultCompanyUserId: string = null, masterListId: string = null) {
    let req = {
      importBatchId: importBatchId,
      mappedJsonResponse: mappedJsonResponse,
      tags: tags,
      importType: importType,//not in use 
      defaultCompanyUserId: defaultCompanyUserId,
      masterListId: masterListId
    }
    return this.http.post<RequestResponse>(AppSettings.API_GET_NEW_CONTACTS + '/updateMappingJson', req)
      .pipe(catchError(this.handleError));
  }

  private handleError(caught: any) {
    console.error(caught?.error?.message);
    return throwError(caught?.error?.message ?? 'Error while connecting to server!');
  }
}
