<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0"
               aria-valuemax="100" style="width:100%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-auto d-flex justify-content-center flex-column text-center cta-screen">
    <h5>To get started, let's have a look around at our most popular content.</h5> <br>
    <button pButton pRipple class="p-button p-button-rounded font-16-bold" (click)="redirectToDashboard()">Let's Go!</button>
  </div>
</div>
