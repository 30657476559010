<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
     <div class="col-12" style="padding:15px 30px;" formArrayName="items"
          *ngFor="let item of formDataCtrl.controls; let i = index">
          <div class="row p-mt-2 align-items-center" [formGroupName]="i">
               <!-- <div class="col-auto">
                    <label style="font-size: 16px;line-height: 16px;color: #2e2e2e;font-weight: 400;">{{
                         item.get('label').value }}</label>
               </div> -->
               <div class="col-12 mb-2">
                    <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList"
                         optionLabel="OpDisplay" (onChange)="operandChanged($event,i)" [style]="{'width':'98%'}"
                         formControlName="operandDropDown" [filter]="true" filterBy="OpDisplay"></p-dropdown>
               </div>
               <div class="col-12">
                    <div class="col-12 d-flex justify-content-around "
                         *ngIf="item.get('operandDropDown').value?.RhsInputControl1">
                         <i class="pi pi-reply replytosection col-1 me-2 ms-2"></i>
                         <p-multiSelect  #txtRHSValue1 formControlName="txtRHSValue1" placeholder="Select" [showToggleAll]="true"
                              [options]="rhsDropdownItem" optionLabel="Name" dataKey="Code" class="col-11 tagradius"
                              [style]="{'width':'96.5%', 'border-radius': '20px!important'}" filterBy="Name"
                              [filter]="true" id="txtRHSValue1"
                              [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}"
                              (onChange)="onListChange($event, i)">
                              <p-footer>
                                   <div class="flex justify-content-between py-3 px-4">
                                        <p-button label="Done" [style]="{'width':'100%'}" (click)="close();"
                                             styleClass="p-button-rounded"></p-button>
                                   </div>
                              </p-footer>
                         </p-multiSelect>
                         <!-- <p-multiSelect appendTo="body"
                              [formControlName]="'txtRHSValue1'" placeholder="Select" [showToggleAll]="false"
                              [options]="getRhsDropDownData(item.get('operandDropDown').value?.PeopleAndLoansFieldLookupId)"
                              optionLabel="Name" dataKey="Code" class="col-11"
                              [style]="{'width':'98%','border-radius': '25px','height': '45px','min-height': '45px', 'padding':'05px'}"
                              filterBy="Name" [filter]="true" id="txtRHSValue1"
                              [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}"
                              (onChange)="onListChange($event, i)">
                         </p-multiSelect> -->
                    </div>
                    <div style="color:#FC5151; font-size:11px; position:absolute; font-weight:normal; margin-left:40px;"
                         *ngIf="item.get('txtRHSValue1')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue1')?.errors">
                         Required.
                    </div>
               </div>
               <!-- <div class="col-1">
                    <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button"
                         (click)="deleteRow(i)">
                         <i class="pi pi-trash"></i>
                    </span>
               </div> -->
          </div>
     </div>
     <!-- <div class="row mt-3" style="padding:15px 30px;">
          <div class="col-12">
               <p-button (click)="addMore()" class="radius4" icon="pi pi-plus" label=""></p-button>
          </div>
     </div>
     <hr class="m-0"> -->
     <div class="row" style="padding:15px 30px;">
          <div class="col-12 text-right">
               <!-- <a (click)="cancelFilter()" class="link-text"
                    style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;font-weight: normal; line-height:34px;text-decoration: underline;"
                    styleClass="p-button-secondary">Cancel</a> -->
               <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" [style]="{'margin-left': '17.5px'}"
                    label="Apply Filter"></p-button>
          </div>
     </div>
</div>