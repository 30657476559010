import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ToastService } from 'src/services/toast.service';
import { formState } from 'src/helpers/formState.helper';
import { ImageService } from 'src/services/image.service';
import { FileUpload } from 'primeng/fileupload';
import { ImageLibrary } from 'src/models/ImageLibrary';
import { AppSettings } from 'src/appSettings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'imageUploadDialog-root',
  templateUrl: './imageUploadDialog.component.html',
  styleUrls: ['./imageUploadDialog.component.scss'],
})

export class imageUploadDialogComponent implements OnInit {
  // vital logged in user session information (includes name, permission, etc.)
  imageForm: FormGroup | any = null;
  // allows [selectedImage] = '...' to be added to the selector and passed in
  @Input() selectedImage: ImageLibrary;
  @Input() display: Boolean;
  @Input() title: String;
  @Input() edited: Boolean;
  @Output() onCloseDialog: EventEmitter<string> = new EventEmitter();
  @Output() onSaveImageClick: EventEmitter<string> = new EventEmitter();
  @ViewChild('libraryImageUploader', { static: false })
  imageUploader: FileUpload;
  @ViewChild('avatarImage', { static: false }) avatarImage: HTMLImageElement;
  @ViewChild('rectangleImage', { static: false })
  rectangleImage: HTMLImageElement;

  /** AppSettings defined image size label for HTML content */
  readonly IMAGE_SIZE_LABEL = AppSettings.IMAGE_SIZE_LABEL;

  // Data Formatting and Validation
  validator: formState = new formState();

  // blocks calls to validate controls until we are sure controls are initialized.
  canValidate: boolean = false;

  // image uploading
  /** Reads the image file from disk. */
  fileReader: FileReader = new FileReader();

  /** Uploaded image. */
  uploadedImageBytes: any = null;
  uploadedImagefile: any = null;

  /** Indicates we are uploading a new Avatar image (when we save). */
  imageUploaded: boolean = false;
  imageSrc: string = null;
  isFormSubmitted=false;
  isImageSelected: boolean = false;
  showErrorMessage: boolean = false;
  url: any;

  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>
   * @param messageService MessageService required for <p-toast>
   */
  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    private imageService: ImageService,
    private formBuilder: FormBuilder
  ) {
    this.initializeDialog();
  }

  /** Occurs upon component initialization */
  async ngOnInit(): Promise<void> {
    this.createForm();
  }

  createForm() {
    return new Promise((resolve, reject) => {
      this.imageForm = this.formBuilder.group({
        chkPrivate: [false],
        chkAvatar: [false],
        files: [null, [Validators.required]],
        txtTitle: [ null, [ Validators.required, Validators.minLength(2), Validators.maxLength(64), ],],
      });
      resolve(true);
    });
  }

  /**
   * Initializes the dialog.
   */
  async initializeDialog() {
    setTimeout(() => {
      this.validator.errors = [];
      this.uploadedImageBytes = null;
      this.uploadedImagefile = null;
      this.imageUploaded = false;
      this.imageSrc = null;

      if(this.imageUploader != undefined)
      {
        this.imageUploader.clear();

        // if (this.selectedImage.Avatar)
        //   this.imageUploader.content.nativeElement.className = 'avatar-image-upload';
        // else
        //   this.imageUploader.content.nativeElement.className = 'as-is-image-upload';
      }

      if (this.selectedImage.ImageURL.length > 0) {
        this.url = this.selectedImage.ImageURL;
      }

      if(this.edited){
          this.isImageSelected = true;
          this.imageForm.patchValue({
            files: 'exists',
            txtTitle: this.selectedImage.Title,
            chkPrivate: 'true',
            chkAvatar: this.selectedImage.Avatar
          });
          this.imageForm.get('files').updateValueAndValidity();
          this.imageForm.get('txtTitle').updateValueAndValidity();
      }
      //this.avatarImage.src = this.imageSrc;
      //this.rectangleImage.src = this.imageSrc;
    }, 10);
  }

  /**
   * Resets the dialog.
   */
  async clearDialog() {
    this.isImageSelected = false;
    this.showErrorMessage = false;
    this.validator.errors = [];
    this.uploadedImageBytes = null;
    this.uploadedImagefile = null;
    this.imageUploaded = false;
    if(this.imageUploader != undefined)
    {
      this.imageUploader.clear();
      //this.imageUploader.content.nativeElement.className = 'as-is-image-upload';
    }
    
    //this.imageSrc = '';
    //this.imageSrc = '/assets/images/RectangleImageTemplate.jpg';

    //patchValue
    this.imageForm.patchValue({
      files: this.uploadedImagefile,
      txtTitle: '',
      chkPrivate: false,
      chkAvatar: false,
    });

    this.imageForm.get('files').updateValueAndValidity();
    this.imageForm.get('txtTitle').updateValueAndValidity();
    
    // setTimeout(() => {
    //   this.avatarImage.src = '/assets/images/RoundImageTemplate.png';
    //   this.rectangleImage.src = '/assets/images/RectangleImageTemplate.jpg';
    // }, 10);
  }

  onAvatarCheckboxChange(event: any) {
    if (event.checked){
      this.selectedImage.Avatar=true;
      //this.imageUploader.content.nativeElement.className = 'avatar-image-upload';
    }
    else
    {
      this.selectedImage.Avatar = false;
      //this.imageUploader.content.nativeElement.className = 'as-is-image-upload';
    }
  }

  /**
   * Called when an image is to be uploaded.
   * (we will only allow a single image)
   *
   * @param event
   */
  async imageUploadHandler(event: any) {
    // are any files selected
    if (event.files == null || event.files.length == 0) {
      return;
    }

    // even if someone set the mode to multiple, we only allow one image
    if (event.files[0].size > AppSettings.MAX_IMAGE_SIZE) {
      this.showErrorMessage = true;
      this.uploadedImageBytes = null;
      this.uploadedImagefile = null;
      this.imageUploaded = false;
      this.imageUploader.clear();
      //this.imageUploader.content.nativeElement.className = 'as-is-image-upload';
      //this.imageSrc = '';
      //this.imageSrc = '/assets/images/RectangleImageTemplate.jpg';
      this.imageForm.patchValue({ files: this.uploadedImagefile, });
      this.toastService.showError(AppSettings.IMAGE_SIZE_ERROR_MSG);
      return;
    }

    this.isImageSelected = true;
    this.showErrorMessage = false;
    this.imageUploaded = true;

    // read the image file
    this.doUpload(event.files[0]);
  }

  /**
   * Reads an image from disk.
   *
   * @param file
   */
  async doUpload(file: any) {
    this.uploadedImagefile = file;
    this.fileReader.onload = (e) => (this.uploadedImageBytes = e.target.result);

    //patchValue
    this.imageForm.patchValue({
      files: this.uploadedImagefile,
    });

    this.imageForm.get('files').updateValueAndValidity();

    // read the image
    this.fileReader.readAsBinaryString(file);
    console.log(this.imageUploader.previewWidth);

    var reader = new FileReader();
		reader.readAsDataURL(this.uploadedImagefile);
		reader.onload = (_event) => {
			this.url = reader.result; 
		}
    
    let fileName = this.uploadedImagefile.name;
    var lastDotPosition = fileName.lastIndexOf(".");

    if (lastDotPosition === -1) 
      this.imageForm.get('txtTitle').value = fileName;
    else 
      this.imageForm.get('txtTitle').value = fileName.substr(0, lastDotPosition);
  }

  /**
   * Undo (clear) the uploded image.
   *
   * @param event
   */
  onUndoUpload(event: any) {
    this.isImageSelected = false;
    this.showErrorMessage = false;
    this.imageUploader.clear();
    this.uploadedImageBytes = null;
    this.imageUploaded = false;
    this.uploadedImagefile = null;

    //patchValue
    this.imageForm.patchValue({
      files: this.uploadedImagefile,
    });

    this.imageForm.get('files').updateValueAndValidity();
  }

  async onSaveClick(): Promise<void> {
    this.isFormSubmitted=true;
    if (this.imageForm.valid) {
      this.selectedImage.Title = this.imageForm.value.txtTitle;
      this.selectedImage.Avatar =this.imageForm.value.chkAvatar;
      this.selectedImage.Published = this.imageForm.value.chkPrivate;
      this.onSaveImageClick.emit();
      this.isFormSubmitted=false;
      this.resetForm();
    } else {
      this.toastService.showError('Please validate all data..');
    }
  }

  onClose() {
    this.isFormSubmitted=false;
    this.resetForm();
    this.onCloseDialog.emit();
  }

  resetForm() {
    if(this.imageForm) {
      this.imageForm.markAsPristine();
      this.imageForm.markAsUntouched();
    }
  }
}
