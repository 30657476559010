import { Injectable } from "@angular/core";

export enum UtcToLocalTimeFormat {
    FULL = 'full',        // 'EEEE, MMMM d, y, h:mm:ss a zzzz'   - Monday, June 15, 2015 at 9:03:01 AM GMT+01:00
    SHORT = 'short',       // 'd/M/yy, h:mm'                      - 15/6/15, 9:03
    SHORT_DATE = 'shortDate',   // 'd/M/yy'                            - 15/6/15
    SHORT_TIME = 'shortTime',   // 'h:mm'                              - 9:03
}

@Injectable({ providedIn: 'root' })
export class UtcConverterService {
    public convertUtcToLocalTime(
        utcDate: string,    // UTC ISO-8601
        format: UtcToLocalTimeFormat = UtcToLocalTimeFormat.FULL
    ): string {

        var browserLanguage = navigator.language;

        if (format === UtcToLocalTimeFormat.SHORT) {
            let date = new Date(utcDate).toLocaleDateString(browserLanguage);
            let time = new Date(utcDate).toLocaleTimeString(browserLanguage);

            return `${date} at ${time}`;
        }
        else if (format === UtcToLocalTimeFormat.SHORT_DATE) {
            return new Date(utcDate).toLocaleDateString(browserLanguage);
        }
        else if (format === UtcToLocalTimeFormat.SHORT_TIME) {
            return new Date(utcDate).toLocaleTimeString(browserLanguage);
        }
        else if (format === UtcToLocalTimeFormat.FULL) {
            //debugger;
            // March 3, 2023 at 3:23 PM CST
            const date = new Date(utcDate);
            const month = date.toLocaleString('default', { month: 'long' });
            const day = date.getDate();            
            const year = date.getFullYear();
            //const hours = String(date.getHours()).padStart(2, '0');
            const hours12 = date.getHours();
            var hour12 = (hours12 % 12) || 12;
            const hours = String(hour12).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const ampm = hours12 >= 12 ? 'PM' : 'AM';
            const dateInUTC = date.toString();
            const dateInUTCArray = dateInUTC.split('(');
            const timeZoneNameFull = dateInUTCArray[1].slice(0, -1);
            const timeZoneNameShort = this.convertLongTimezoneNameToShort(timeZoneNameFull);

            const myFormattedDate = month + ' ' + day + ', ' + year + ' at ' + hours + ':' + minutes + ' ' + ampm + ' ' + timeZoneNameShort;
            return myFormattedDate;
        }
        else {
            console.error(
                `Do not have logic to format utc date, format:${format}`
            );
            return new Date(utcDate).toString();
        }
    }

    public convertLocalTimeToUtc(localDate: string): string {
        var date = new Date(localDate);
        return date.toUTCString();
    }

    private convertLongTimezoneNameToShort(timeZoneNameFull: string) {
        let timezoneShortName = timeZoneNameFull; //default if not found
        switch (timeZoneNameFull) {
            case 'Alaskan Standard Time':
                timezoneShortName = 'AKDT';
                break;
            case 'Alaskan Daylight Time':
                timezoneShortName = 'AKST';
                break;
            case 'Pacific Standard Time':
                timezoneShortName = 'PST';
                break;
            case 'Pacific Daylight Time':
                timezoneShortName = 'PDT';
                break;
            case 'Indiana (East)':
                timezoneShortName = 'EST';
                break;
            case 'Hawaii–Aleutian Daylight Time':
                timezoneShortName = 'HDT';
                break;
            case 'Hawaii-Aleutian Standard Time':
                timezoneShortName = 'HST';
                break;
            case 'Eastern Standard Time':
                timezoneShortName = 'EST';
                break;
            case 'Eastern Daylight Time':
                timezoneShortName = 'EDT';
                break;
            case 'Central Standard Time':
                timezoneShortName = 'CST';
                break;
            case 'Central Daylight Time':
                timezoneShortName = 'CDT';
                break;
            case 'Mountain Standard Time':
                timezoneShortName = 'MST';
                break;
            case 'Mountain Daylight Time':
                timezoneShortName = 'MDT';
                break;
            case 'Arizona':
                timezoneShortName = 'MST';
                break;
            case 'India Standard Time':
                timezoneShortName = 'IST';
                break;
            case 'Singapore Standard Time':
                timezoneShortName = 'SST';
                break;
            case 'Mexican Pacific Standard Time':
                timezoneShortName = 'MPST';
                break;
            case 'Easter Island Standard Time':
                timezoneShortName = 'EAST';
                break;
            case 'Colombia Standard Time':
                timezoneShortName = 'COT';
                break;
            case 'Cuba Daylight Time':
                timezoneShortName = 'CDT';
                break;
            case 'Paraguay Standard Time':
                timezoneShortName = 'PYT';
                break;
            case 'Atlantic Daylight Time':
                timezoneShortName = 'ADT';
                break;
        }
        return timezoneShortName;
    }
}