import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core'; 
import { HttpClient } from '@angular/common/http'; 
import { ConfirmationService, MessageService } from 'primeng/api'; 
import { Table } from 'primeng/table'; 
import { ToastService } from 'src/services/toast.service'; 
import { RequestResponse } from 'src/models/RequestResponse'; 
import { PagingData } from 'src/models/PagingData'; 
import { WhereClauseCriteria, WhereClauseCriteriaCollection } from 'src/models/WhereClauseCriteria'; 
import { AndOr, DbType, Group, SqlComparison } from 'src/models/enumerations'; 
//import { ComponentSpecificService } from 'src/services/componentSpecific.service'; 
 
@Component({ 
    encapsulation: ViewEncapsulation.None, 
    selector: 'textMessagePreviewDialog-root', 
    templateUrl: './textMessagePreviewDialog.component.html', 
    styleUrls: ['./textMessagePreviewDialog.component.scss'] 
}) 
export class textMessagePreviewDialogComponent implements OnInit, AfterViewInit { 
  //title = 'textMessagePreviewDialog'; 
 
  // allows [messageText] = '...' to be added to the selector and passed in 
  @Input() messageText: string; 

  // true delivers the event asynchronously, false delivers synchronously.
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true); 
 
  // property for the control with #dataTableId to be accessed with propertyName dataTable
  // Uncomment the following line if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
  // @ViewChild('dataTableId', { static: false }) dataTable: Table;

  // #region Properties

  // used for showing/hiding spinner, progress bar or skeleton.
  loadingPage: boolean = false; 
  // Standard Request/Response object. Includes Paging support if necessary 
  webAPI: RequestResponse = new RequestResponse();

  // Lazy load data filtering support
  specifyingFilter: boolean = false;
  allCriteria: WhereClauseCriteriaCollection = new WhereClauseCriteriaCollection();
  // Data sorting support
  orderBy: string = '';

  // Horizontal scroll position
  currentHScrollPos: number = 0;
  
  // #endregion

  // #region Constructor

  /** 
   * Constructor 
   *  
   * @param http HTTPClient required to call actions in any .ts file in the services folder. 
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   * @param permissionGroupService Service for working with permission groups.
   * @param userSessionService Service for user session information.
   */ 
  constructor(private http: HttpClient, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService,
    private toastService: ToastService,
    //,private componentSpecificService: ComponentSpecificService
    ) { 
  }

  // #endregion
 
  // #region Component Initialization 

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> { 
    // obtain data for the page 
    this.getData(this.webAPI.paging.currentPage);
  } 
 
  /**
  * Occurs after view initialization
  */ 
  ngAfterViewInit(): void { 
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  } 
 
  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {
  }

  // #endregion

  // #region Data Acquisition 

  /** 
   * Retrieves all necessary data for this component. 
   * 
   * @param: currentPage - paging support. 
   */ 
  async getData(currentPage) { 
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true; 
 
    //try {
    //  // code to acquire data via a Web API call.
    //  // returned object is a RequestResponse object.
    //  this.webAPI.data = []; //  Required - Clear data from last request or set your specific controller request data.
    //  this.webAPI.data = JSON.stringify(this.allCriteria);
    //  //this.webAPI.paging.pagingRead = true; // Required for lazy loading of data (default gets all records).

    //  var response = await this.componentSpecificService.getData(this.webAPI);
    //  // assign returned object to class variable.
    //  this.webAPI = response; // Required - do not remove.
    //  // convert the 'data' property of the RequestResponse object's 'data' property JSON string value to an object.
    //  RequestResponse.dataToObject(this.webAPI); // Required - do not remove.

    //  // when data is retrieved, set this.loadingPage to false.
    //  this.loadingPage = false;

    //} catch (message: any) {
    //  this.webAPI.paging.totalRecords = 0;
    //  this.loadingPage = false;
    //  this.toastService.showError(message);
    //}

    // REMOVE this when acquiring data via a Web API call. 
    this.loadingPage = false; 
  }

  // #endregion
 
  // #region Paging Support

  /**
   * Selected page changed in the paginator
   * @param event
   */
  onPageChanged(event) {
    // Uncomment the following if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //if (PagingData.onPageChanged(this.webAPI.paging, event, this.dataTable)) {
    //  this.refreshData();
    //}
  }

  /**
   *  Goes to the user entered page.
   */
  onGotoPage() {
    // Uncomment the following if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
    //  this.refreshData();
    //}
  }

  /**
   * Retrieves data applying any sorting and filtering, and scrolls the
   * list to its previous horizontal scroll position.
   */
  async refreshData() {
    // Uncomment the following if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //this.currentHScrollPos = this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft;
    //await this.getData(this.webAPI.paging.currentPage);
    //setTimeout(() => {
    //  this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft = this.currentHScrollPos;
    //}, 10);
  }

  // #endregion

  // #region Methods

  // #endregion

  // #region Event Handlers 

  // #endregion
} 
 
