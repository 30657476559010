import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from '../../../../../../src/app/services/CalendarAndTimeZoneService';
import { formState } from 'src/helpers/formState.helper';
import { CMSComponentData, RepeatingMonthlyData } from 'src/models/MessageScheduleData';
import { LookupService } from 'src/services/lookup.service';
import { ToastService } from 'src/services/toast.service';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-repeating-monthly',
  templateUrl: 'campaign-message-schedule-repeating-monthly.component.html',
})
export class CampaignMessageScheduleRepeatingMonthlyComponent
  implements OnInit
{
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public CMSComponentData: CMSComponentData;
  repeatingMonthlyData: RepeatingMonthlyData;

  monthlyForm: FormGroup | any = null;
  validator: formState = new formState();
  isFormValueChange = false;
  isAfter = false;

  hours: any[];
  minutes: any[];
  meridiems: any[];
  weeks: any[];
  days: any[];
  daysArray: any[];
  weekdays: any[];
  timeZones: any[];
  startDates: any[];
  endDates: any[];
  dateOptions: any[];
  dateFormat: string = 'mm/dd/yy';
  todayDate = new Date();
  tomorrowDate = new Date();
  tomorrowAfterDate = new Date();
  userTimeZoneId: any;
  userToken: UserToken;
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  public disableDaysArray: string;
  
  constructor(
    private http: HttpClient,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    public datepipe: DatePipe,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private userTokenService: UserTokenService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(8);
    this.repeatingMonthlyData = new RepeatingMonthlyData();
    this.userToken = this.userTokenService.getUser();
    this.messageType = this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe = 'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
    if(this.messageType == 2 && this.allowTCPAForTextMessages) {
      this.disableDaysArray = '[0,6]';
    } else {
      this.disableDaysArray = '';
    }
  }

  ngOnInit(): void {
    this.tomorrowDate.setDate(this.todayDate.getDate());
    this.tomorrowAfterDate.setDate(this.todayDate.getDate() + 1);
    this.weeks = this.calendarAndTimeZoneService.GetWeeks();
    this.weekdays = this.calendarAndTimeZoneService.GetWeekDays();
    this.dateOptions = this.calendarAndTimeZoneService.GetDateOption();
    this.days = this.calendarAndTimeZoneService.getListOfDays(1);
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;

    forkJoin(this.lookupService.GetTimezones()).subscribe(
      ([timezonesresponse]) => {
        this.timeZones = timezonesresponse.data;
        this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();

        if (this.userTimeZoneId) {
          let defaultObject =
            this.calendarAndTimeZoneService.getDefaultDateTimeObject(
              this.userTimeZoneId,
              this.timeZones
            );
          let defaultEndDate = new Date();
          defaultEndDate.setDate(defaultObject.defaultDate.getDate() + 1);
          let defaultData = {
            annual: 'AMD',
            day: 1,
            week: '1',
            weekday: '1',
            hour: defaultObject.defaultHour,
            minute: defaultObject.defaultMinute,
            meridiem:
              this.messageType == 2 && this.allowTCPAForTextMessages
                ? this.calendarAndTimeZoneService.getMeridiem(
                    defaultObject.defaultHour
                  )
                : defaultObject.defaultMeridiem,
            timeZoneId: defaultObject.defaultTimeZoneId,
            startDate: defaultObject.defaultDate,
            endDate: defaultEndDate,
            dateOption: 'NEVER',
          };
          let savedObject = new RepeatingMonthlyData();
          if (
            scheduleDataJSON &&
            scheduleDataJSON != 'No Schedule' &&
            scheduleType == 'Repeating' &&
            ScheduleSubtype == 'Monthly'
          ) {
            savedObject = JSON.parse(scheduleDataJSON)[0];
            if (savedObject) {
              let dateTimeHelper = new DateTimeHelper();
              var time = dateTimeHelper.Get12HourTimeComponent(
                savedObject.time
              );
              if (time) {
                defaultData.hour = parseInt(time.hours);
                defaultData.minute = parseInt(time.minutes);
                defaultData.meridiem = time.AmPM;
              }
              defaultData.timeZoneId = savedObject.timezoneId;
              defaultData.annual =
                savedObject.repeatingMonthlyType.toUpperCase();

              if (defaultData.annual == 'AMD') {
                defaultData.day = parseInt(savedObject.ordinalName);
                defaultData.weekday = defaultData.weekday;
                defaultData.week = defaultData.week;
              } else {
                defaultData.week = savedObject.ordinalName;
                defaultData.weekday = savedObject.weekday;
                defaultData.day = defaultData.day;
              }

              if (savedObject.dateStart) {
                defaultData.startDate = new Date(savedObject.dateStart);
                defaultData.startDate.setMinutes(
                  defaultData.startDate.getMinutes() +
                    defaultData.startDate.getTimezoneOffset()
                );
              }
              if (savedObject.dateEnd) {
                defaultData.endDate = new Date(savedObject.dateEnd);
                defaultData.endDate.setMinutes(
                  defaultData.endDate.getMinutes() +
                    defaultData.endDate.getTimezoneOffset()
                );
              }
              defaultData.dateOption = savedObject.endingCriteria;
            }
          }
          this.createForm(defaultData);
        }
      }
    );
  }
  createForm(defaultData) {
    this.monthlyForm = this.formBuilder.group(
      {
        annual: [defaultData.annual],
        day: [defaultData.day],
        week: [defaultData.week],
        weekday: [defaultData.weekday],
        hour: [defaultData.hour],
        minute: [defaultData.minute],
        meridiem: [defaultData.meridiem],
        timeZone: [defaultData.timeZoneId, [Validators.required]],
        startDate: [defaultData.startDate, [Validators.required]],
        endDate: [defaultData.endDate, [Validators.required]],
        dateOption: [defaultData.dateOption],
      },
      {
        validator: this.calendarAndTimeZoneService.CustomeDateValidators(
          'startDate',
          'endDate',
          'dateOption'
        ),
      }
    );
    this.monthlyForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;
      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.fillFormData();
    }
  }

  fillFormData() {
    this.repeatingMonthlyData.campaignMessageId = '';
    this.repeatingMonthlyData.repeatingMonthlyType =
      this.monthlyForm.value.annual;
    this.repeatingMonthlyData.ordinalName =
      this.monthlyForm.value.annual == 'AMD'
        ? this.monthlyForm.value.day.toString()
        : this.monthlyForm.value.week.toString();
    this.repeatingMonthlyData.weekday = this.monthlyForm.value.weekday;
    this.repeatingMonthlyData.timezoneId = this.monthlyForm.value.timeZone;
    this.repeatingMonthlyData.timezoneInfoId = this.timeZones.find(
      (x) => x.timezoneId.toLowerCase() === this.repeatingMonthlyData.timezoneId
    )?.timezoneInfoId;
    let hr = Number(this.monthlyForm.value.hour);
    let mi = Number(this.monthlyForm.value.minute);
    let mer = this.monthlyForm.value.meridiem;
    this.repeatingMonthlyData.time =
      this.calendarAndTimeZoneService.getTimeSpan(hr, mi, mer);
    this.repeatingMonthlyData.dateStart = this.datepipe.transform(
      this.monthlyForm.value.startDate,
      'MM/dd/yyyy'
    );
    this.repeatingMonthlyData.endingCriteria =
      this.monthlyForm.value.dateOption;
    this.repeatingMonthlyData.dateOption = this.monthlyForm.value.dateOption;
    if (this.monthlyForm.value.dateOption == 'NEVER') {
      this.isAfter = false;
      let calculateDate = new Date(this.repeatingMonthlyData.dateStart);
      calculateDate.setFullYear(calculateDate.getFullYear() + 9);
      this.repeatingMonthlyData.dateEnd = this.datepipe.transform(
        calculateDate,
        'MM/dd/yyyy'
      );
    } else {
      this.isAfter = true;
      let calculateDate = new Date(this.monthlyForm.value.endDate);
      this.repeatingMonthlyData.dateEnd = this.datepipe.transform(
        calculateDate,
        'MM/dd/yyyy'
      );
    }
    this.doEmit();
  }
  OnAnnualChange(event) {
    let selectedValue = this.monthlyForm.value.annual;
  }
  OnHourChange(event) {
      if (this.messageType == 2 && this.allowTCPAForTextMessages) {
        let selectedHour = this.monthlyForm.value.hour;
        this.monthlyForm.patchValue({
          meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
        });
      }
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.monthlyForm.valid;
    this.CMSComponentData.componentValueJSON = this.repeatingMonthlyData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
}