<!--
*************************************************************************************
Page Heading and Buttons
*************************************************************************************
-->
<div class="content table-search-row second-header-fixed" role="main">
  <div class="text-wrapper row">
    <div class="col-auto pl-0 admin-text flex-column justify-content-center">
      <label id="lblPageHeader" *ngIf="currentClientID === 'Aduvo' && user.Email == ''">New User</label>
      <label id="lblPageHeader" *ngIf="currentClientID === 'AduvoAdmin' && user.Email == ''">New Aduvo Admin User</label>
      <label id="lblPageHeader">{{ user.FirstName }} {{ user.LastName }}</label>
      <label *ngIf="user.Email !== ''">{{ user.Email }}</label>
    </div>
    <div class="col-md-6 mt-2" style="float: left">
      <label class="page-head-3">{{ role }}</label>
    </div>
  </div>
</div>

<!--
*************************************************************************************
User Editor Form
*************************************************************************************
-->

<div class="text-wrapper py-1 mb-3 grid-section">
  <form [formGroup]="userForm" (ngSubmit)="onSaveButtonClick()">
    <div>
      <div class="row">
        <div class="col-sm-3">
          <p-accordion class="custom-accordion" expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-down">
            <p-accordionTab header="Contact Details">
              <div class="side-detail">
                <div class="p-formgrid p-grid">
                  <div style="width: 6rem;">
                    <div class="p-col" style="text-align: left">
                      <img style="border-radius: 50%;"
                        [src]="imageSrc == null || imageCleared ? '/assets/images/RoundImageTemplate.png' : imageSrc"
                        onerror="this.onerror=null; this.src='/assets/images/RoundImageTemplate.png'"
                        class="pulseProfileEditAvatar"
                        [style]="{width: '5rem', height: '5rem', 'border-radius': '50%', 'object-fit': 'cover', border: '2px solid #0066ff'}" />
                      <span>
                        <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>
                      </span>
                    </div>
                  </div>
                  <div class="p-field p-col-12 p-md-7">
                    <div class="p-col p-ai-xl-center" style="text-align: center">
                      <div>
                        <p-button class="mt-4" ly-button color="primary" type="button" (click)="_fileInput.click()">
                          Upload a Photo
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-col-12">
                  <label for="firstName" class="col-form-label">First Name</label>
                  <input type="text" name="firstName" id="firstName" maxlength="64" placeholder="First Name"
                    class="form-control" formControlName="firstName" [ngClass]="{
                    'is-invalid':
                      userForm.get('firstName').invalid &&
                      (userForm.submitted),
                    'is-valid':
                      userForm.get('firstName').valid &&
                      (userForm.submitted)
                  }" />
                  <span class="text-danger" *ngIf="
                    userForm.get('firstName').invalid &&
                    (userForm.get('firstName').dirty ||
                      userForm.get('firstName').touched ||
                      userForm.submitted) &&
                    userForm.get('firstName').errors?.required
                  ">First name cannot be blank</span>
                  <span class="text-danger" *ngIf="
                    userForm.get('firstName').invalid &&
                    (userForm.get('firstName').dirty ||
                      userForm.get('firstName').touched ||
                      userForm.submitted) &&
                    userForm.get('firstName').errors?.minlength
                  ">First name should contain at least 2 characters</span>
                </div>
                <div class="p-col-12">
                  <label for="lastName" class="col-form-label">Last Name</label>
                  <input type="text" name="lastName" id="lastName" maxlength="64" placeholder="Last Name"
                    class="form-control" formControlName="lastName" [ngClass]="{
                  'is-invalid':
                    userForm.get('lastName').invalid &&
                    (userForm.submitted),
                  'is-valid':
                    userForm.get('lastName').valid &&
                    (userForm.submitted)
                }" />
                  <span class="text-danger" *ngIf="
                  userForm.get('lastName').invalid &&
                  (userForm.get('lastName').dirty ||
                    userForm.get('lastName').touched ||
                    userForm.submitted) &&
                  userForm.get('lastName').errors?.required
                ">Last name cannot be blank</span>
                  <span class="text-danger" *ngIf="
                  userForm.get('lastName').invalid &&
                  (userForm.get('lastName').dirty ||
                    userForm.get('lastName').touched ||
                    userForm.submitted) &&
                  userForm.get('lastName').errors?.minlength
                ">Last name should contain at least 2 characters</span>
                </div>

                <div class="p-col-12" *ngIf="!isShown || isCompanyNewUser">
                  <label for="userEmailId" class="col-form-label">Email</label>
                  <input type="text" name="userEmailId" id="userEmailId" maxlength="128"
                    placeholder="User Email Address" class="form-control" formControlName="userEmailId"
                    (input)="validateUserEmail()" [ngClass]="{
                    'is-invalid':
                      userForm.get('userEmailId').invalid &&
                      (userForm.submitted),
                    'is-valid':
                      userForm.get('userEmailId').valid &&
                      (userForm.submitted)
                  }" />
                  <span class="text-danger" *ngIf="
                    userForm.get('userEmailId').invalid &&
                    (userForm.get('userEmailId').dirty ||
                      userForm.get('userEmailId').touched ||
                      userForm.submitted) &&
                    userForm.get('userEmailId').errors?.required
                  ">Email cannot be blank</span>
                  <span class="text-danger" *ngIf="!isValidUserEmail">Email Address is invalid</span>
                </div>

                <div class="p-col-12">
                  <label for="userRole" class="col-form-label">Role</label>
                  <p-dropdown [options]="roles" name="userRole" id="userRole" formControlName="userRole"
                    placeholder="Select Role" [(ngModel)]="selectedRole" required>
                  </p-dropdown>
                </div>

                <div class="p-col-12">
                  <label for="nmls" class="col-form-label">NMLS</label>
                  <input type="text" name="nmls" id="nmls" maxlength="12" placeholder="NMLS" class="form-control"
                    formControlName="nmls" maxlength="12" [ngClass]="{
                  'is-invalid':
                    userForm.get('nmls').invalid &&
                    (userForm.submitted),
                  'is-valid':
                    userForm.get('nmls').valid &&
                    (userForm.submitted)
                }" />
                </div>
                <div class="p-col-12">
                  <label for="workPhone" class="col-form-label">Work Phone</label>
                  <input type="text" name="workPhone" id="workPhone" class="form-control" formControlName="workPhone"
                    maxlength="25" (input)="workPhoneFormat($event)" [ngClass]="{
                  'is-invalid':
                    userForm.get('workPhone').invalid &&
                    (userForm.submitted),
                  'is-valid':
                    userForm.get('workPhone').valid &&
                    (userForm.submitted)
                }" />
                  <span class="text-danger" *ngIf="
                  userForm.get('workPhone').invalid &&
                  (userForm.get('workPhone').dirty ||
                    userForm.get('workPhone').touched ||
                    userForm.submitted) &&
                  userForm.get('workPhone').errors?.pattern
                ">Work Phone is invalid.</span>
                  <span class="text-danger" *ngIf="
                  userForm.get('workPhone').invalid &&
                  (userForm.get('workPhone').dirty ||
                    userForm.get('workPhone').touched ||
                    userForm.submitted) &&
                  userForm.get('workPhone').errors?.maxlength
                ">Max length of Work Phone is 25 digits.</span>
                </div>

                <div class="p-col-12">
                  <label for="mobilePhone" class="col-form-label">Mobile Phone</label>
                  <input type="text" name="mobilePhone" id="mobilePhone" class="form-control" maxlength="25"
                    formControlName="mobilePhone" (input)="mobilePhoneFormat($event)" [ngClass]="{
                  'is-invalid':
                    userForm.get('mobilePhone').invalid &&
                    (userForm.submitted),
                  'is-valid':
                    userForm.get('mobilePhone').valid &&
                    (userForm.submitted)
                }" />
                  <span class="text-danger" *ngIf="
                  userForm.get('mobilePhone').invalid &&
                  (userForm.get('mobilePhone').dirty ||
                    userForm.get('mobilePhone').touched ||
                    userForm.submitted) &&
                  userForm.get('mobilePhone').errors?.pattern
                ">Mobile Phone is invalid.</span>
                  <span class="text-danger" *ngIf="
                  userForm.get('mobilePhone').invalid &&
                  (userForm.get('mobilePhone').dirty ||
                    userForm.get('mobilePhone').touched ||
                    userForm.submitted) &&
                  userForm.get('mobilePhone').errors?.maxlength
                ">Max length of Mobile Phone is 25 digits.</span>
                </div>

                <div class="p-col-12">
                  <label for="fax" class="col-form-label">Fax</label>
                  <input type="text" name="fax" id="fax" class="form-control" maxlength="25" formControlName="fax"
                    (input)="faxFormat($event)" [ngClass]="{
                    'is-invalid':
                    userForm.get('fax').invalid &&
                      (userForm.submitted),
                    'is-valid':
                    userForm.get('fax').valid &&
                      (userForm.submitted)
                  }" />
                  <span class="text-danger" *ngIf="
                    userForm.get('fax').invalid &&
                    (userForm.get('fax').dirty ||
                    userForm.get('fax').touched ||
                    userForm.submitted) &&
                    userForm.get('fax').errors?.pattern
                  ">Fax is invalid.</span>
                  <span class="text-danger" *ngIf="
                    userForm.get('fax').invalid &&
                    (userForm.get('fax').dirty ||
                    userForm.get('fax').touched ||
                    userForm.submitted) &&
                    userForm.get('fax').errors?.maxlength
                  ">Max length of fax is 25 digits.</span>
                </div>
                <div class="p-field p-col-12 p-md-3">
                  <p-button (click)="onSaveButtonClick()" label="Save"></p-button>
                  <p-confirmDialog header="Save Confirmation" icon="pi pi-question-circle"></p-confirmDialog>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div class="col-sm-9">
          <div class="card-body">
          </div>
          <div class="profileinput">
            <div *ngIf="isShowSubscription">
              <h4>Subscription</h4>
              <div class="card rounded-1 editor-card-header-text mb-4">
                <div class="justify-content-between align-items-center">
                  <span class="text-white cam-msg-haeder-text">Premium Subscriber</span>
                  <p class="text-white font-weight-normal">This user is a member of the <span
                      style="font-weight:bold">{{companyName}}</span> {{billingOwner}} subscription.
                  </p>
                  <div class="tagline" *ngIf="billingOwner != 'Company'">User has been invited to accept ownership of
                    their subscription.</div>
                </div>
              </div>
            </div>
            <h4 hidden>Marketing Mode</h4>

            <div hidden class="mb-4 current-email">
              Current email/SMS marketing mode
              <span style="float: right;" class="d-flex" pTooltip="All text messages will be sent to the current email and 
              mobile phone number of the contact owner." tooltipPosition="top"><i
                  class="pi pi-exclamation-circle mr-1 mt-two"></i> <span class="test-span"> <b>Test </b></span>
                <p-inputSwitch for="testMode" formControlName="testMode" class="inputSwitchActive"
                  [(ngModel)]="isTestMode">
                </p-inputSwitch>
              </span>
            </div>

            <div class="Signature-panel">
              <h4>Email Signature</h4>
              <span style="float:right;">
                <button pButton [ngClass]="{'active': isSimpleEmailEditor}" type="button" class="editor-btn"
                  (click)="isSimpleEmailEditor=true" label="Simple">
                </button>
                <button pButton [ngClass]="{'active': !isSimpleEmailEditor}" type="button" class="editor-btn"
                  (click)="isSimpleEmailEditor=false" label="HTML">
                </button>
              </span>
            </div>

            <p-editor [(ngModel)]="emailSignatureHtml" formControlName="emailSignatureHtml" *ngIf="isSimpleEmailEditor"
              [style]="{'height':'200px'}">
              <ng-template pTemplate="footer">
                <span class="ql-formats hello">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                  <button type="button" value="ordered" class="ql-list" aria-label="Ordered List"></button>
                  <button type="button" value="bullet" class="ql-list" aria-label="Unordered List"></button>
                  <button aria-label="Insert Image" type="button" class="ql-image"></button>
                  <button aria-label="Insert Link" type="button" class="ql-link"></button>
                </span>
              </ng-template>
            </p-editor>
            <textarea [(ngModel)]="emailSignatureText" formControlName="emailSignatureText" rows="5"
              class="form-control" style="min-height:200px;" *ngIf="!isSimpleEmailEditor"></textarea>

            <h4 hidden class="mt-4">Default Sending Address</h4>

            <div hidden class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-4 p-md-3 pl-10">
                <p-dropdown placeholder="ACMEMortgage (Company)">
                </p-dropdown>
              </div>
              <div class="p-field p-col-4 pt-3 p-col-3 px-0">
                <span class="font11"> Select Default Address:</span>
                <p>1735 Vista del Cerro, San Diego, CA 92154</p>
              </div>
            </div>
            <h4 hidden class="mt-1">Password</h4>
            <div hidden class="p-field p-col-12 buttonwrapper px-0">
              <input type="password" readonly="true" value="Password" name="legalBusinessStreet" maxlength="128"
                class="form-control" />
              <a (click)="showChangePasswordArea()" class="link-text inside-item" style="
              color: blue;
              cursor: pointer;
              margin-left: 17.5px;
              line-height: 34px;
              text-decoration: underline; background-color:transparent" styleClass="p-button-secondary">Change Password
              </a>
            </div>
            <div hidden *ngIf="isChangePasswordVisible" class="eyesection">

              <div class="p-field p-col-6 form-group inner-addon right-addon pl-0">
                <label for="currentPassword" class="col-form-label">Current Password</label>
                <div class="input-group col-6 mx-auto">
                  <input pInputPassword formControlName="currentPassword" name="currentPassword"
                    [type]="currentShowPassword ? 'text' : 'password'" class="form-control pwd-control"
                    id="password-field" [(ngModel)]="oldPassword" (input)="onInput()">
                  <div>
                    <i alt="show" class="far fa-eye eye-show" (click)="currentShowPassword = !currentShowPassword"
                      [class.hide]="currentShowPassword"></i>
                    <i alt="hide" class="far fa-eye-slash eye-hide" (click)="currentShowPassword = !currentShowPassword"
                      [class.hide]="!currentShowPassword"></i>
                  </div>
                </div>
              </div>
              <div class="p-fluid p-formgrid p-grid">
                <div class=" p-field p-col-6 form-group inner-addon right-addon">
                  <label for="newPassword" class="col-form-label">New Password</label>
                  <div class="input-group col-6 mx-auto">
                    <input pInputPassword formControlName="newPassword" name="newPassword"
                      [type]="newShowPassword ? 'text' : 'password'" class="form-control pwd-control"
                      id="password-field" [(ngModel)]="newPassword1" (input)="onInput()">
                    <div>
                      <i alt="show" class="far fa-eye eye-show" (click)="newShowPassword = !newShowPassword"
                        [class.hide]="newShowPassword"></i>
                      <i alt="hide" class="far fa-eye-slash eye-hide" (click)="newShowPassword = !newShowPassword"
                        [class.hide]="!newShowPassword"></i>
                    </div>
                  </div>
                </div>
                <div class=" p-field p-col-6 form-group inner-addon right-addon">
                  <label for="confirmPassword" class="col-form-label">Confirm New Password</label>
                  <div class="input-group col-6 mx-auto">
                    <input pInputPassword formControlName="confirmPassword" name="confirmPassword"
                      [type]="confirmShowPassword ? 'text' : 'password'" class="form-control pwd-control"
                      id="password-field" [(ngModel)]="newPassword2" (input)="onInput()">
                    <div>
                      <i alt="show" class="far fa-eye eye-show" (click)="confirmShowPassword = !confirmShowPassword"
                        [class.hide]="confirmShowPassword"></i>
                      <i alt="hide" class="far fa-eye-slash eye-hide"
                        (click)="confirmShowPassword = !confirmShowPassword" [class.hide]="!confirmShowPassword"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="requirements-text">
                <b> Password Requirements:</b>
                <ul style="list-style-type: none;">
                  <li><small [ngClass]="{'text-success': passwordIsCorrectLength && oldPasswordIsCorrectLength}"
                      style="font-weight:400;">- Must be at least 8 characters long</small></li>
                  <li><small [ngClass]="{'text-success': passwordContainsUpper}" style="font-weight:400; ">- Must have
                      at least one uppercase character</small></li>
                  <li><small [ngClass]="{'text-success': passwordContainsLower}" style="font-weight:400; ">- Must have
                      at least one lowercase character</small></li>
                  <li><small [ngClass]="{'text-success': passwordContainsNumber}" style="font-weight:400;">- Must have
                      at least one number</small></li>
                  <li><small [ngClass]="{'text-success': passwordContainsSpecial}" style="font-weight:400;">- Must have
                      at least one special character: !$@%^&*</small></li>
                  <li><small [ngClass]="{'text-success': newPasswordsMatch}" style="font-weight:400;">- Passwords must
                      match</small></li>
                </ul>
              </div>
              <p-button (onClick)="onSavePasswordClick()" label="Save New Password"
                [disabled]="!isPasswordValid"></p-button>
              <a class="link-text" (click)="hideChangePasswordArea()" style="
                color: #2e2e2e;
                cursor: pointer;
                margin-left: 17.5px;
                line-height: 34px;
                text-decoration: underline;" styleClass="p-button-secondary">Cancel
              </a>
              <div class="mt-3 gray-section">
                <div class="font-weight-bold">Don't know your current password?</div>
                <div>if you forgot your password, reset it here and we'll send you an email to create a new one.</div>
                <p-button (onClick)="onResetPassword()" styleClass="p-button-secondary mt-3"
                  label="Reset Password"></p-button>
              </div>
            </div>
            <h4 hidden class="mt-4 mb-3">Marketing Preferences</h4>
            <div hidden>
              <span class="d-flex">
                <p-inputSwitch formControlName="marketingPreference" class="inputSwitchActive"
                  [(ngModel)]="isMarketingPreference">
                </p-inputSwitch>
                When new campaigns are available, activate them immediately.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<!--
*************************************************************************************
Reset Password  Confirmation Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" #removeFromPulseDialog [(visible)]="showResetPasswordDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.resetPasswordMessage }}</span>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="resetPasswordConfirmation()"
        label="Yes, I want to reset password" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showResetPasswordDialog = false" label="No"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>