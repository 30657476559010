import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomGridComponent } from 'src/components/custom-grid/custom-grid.component';
import { FilterTypes } from 'src/components/custom-grid/enums/filter-types';
import { ISearchModel, SearchModel } from 'src/components/custom-grid/models/search-model';
import { ActivityTypes } from 'src/enums/activity-types';
import { MessageTypes } from 'src/enums/message-types';
import { CampaignMessageLog, ICampaignMessageLog } from 'src/models/CampaignMessageLog';
import { CampaignsService } from 'src/services/campaigns.service';
import { CsvService } from 'src/services/csv.service';
import { LookupService } from 'src/services/lookup.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';

@Component({
  selector: 'app-message-log-grid',
  templateUrl: './message-log-grid.component.html',
  styleUrls: ['./message-log-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageLogGridComponent implements OnInit {

  @ViewChild('previewDialog', { static: false }) previewDialog: Component;
  campaignMessageLogs: Array<CampaignMessageLog> = new Array<CampaignMessageLog>();
  campaignMessageLogFilters: Array<any> = new Array<any>();
  MessageTypes = MessageTypes;
  ActivityTypes = ActivityTypes;
  FilterTypes = FilterTypes;
  columns: Array<any> = [
    {
      field: 'recipientName',
      header: 'Name',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'recipientEmail',
      header: 'Email',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'messageName',
      header: 'Message Name',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'messageSentDate',
      header: 'Sent Date',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.DATE,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: [],
      beginDate: null,
      endDate: null
    },
    {
      field: 'recipientMobilePhone',
      header: 'Phone',
      isSortable: false,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'messageType',
      header: 'Message Type',
      isSortable: false,
      isFilterable: true,
      filterType: FilterTypes.ENUM,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: [
        {
          value: {
            value: MessageTypes.EMAIL,
            label: 'Email'
          },
          field: 'Email'
        },
        {
          value: {
            value: MessageTypes.SMS,
            label: 'SMS'
          },
          field: 'SMS'
        }
      ]
    },
    {
      field: 'activity',
      header: 'Activity',
      isSortable: false,
      isFilterable: true,
      filterType: FilterTypes.ENUM,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: [
        {
          value: {
            value: ActivityTypes.BLOCKED,
            label: 'Blocked'
          },
          field: 'Blocked'
        },
        {
          value: {
            value: ActivityTypes.BOUNCED,
            label: 'Bounced'
          },
          field: 'Bounced'
        },
        {
          value: {
            value: ActivityTypes.CLICKED,
            label: 'Clicked'
          },
          field: 'Clicked'
        },
        {
          value: {
            value: ActivityTypes.DELIVERED,
            label: 'Delivered'
          },
          field: 'Delivered'
        },
        {
          value: {
            value: ActivityTypes.DROPPED,
            label: 'Dropped'
          },
          field: 'Dropped'
        },
        {
          value: {
            value: ActivityTypes.OPENED,
            label: 'Opened'
          },
          field: 'Opened'
        },
        {
          value: {
            value: ActivityTypes.SPAMREPORTED,
            label: 'Spam Reported'
          },
          field: 'Spam Reported'
        },
        {
          value: {
            value: ActivityTypes.UNSUBSCRIBED,
            label: 'Unsubscribed'
          },
          field: 'Unsubscribed'
        }
      ]
    },
    {
      field: 'droppedMessage',
      header: 'Dropped Message',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.BOOLEAN,
      showOperator: false,
      display: false,
      filterValues: [],
      filterOptions: []
    },
  ]
  campaignId: string;
  searchModel: SearchModel;
  showSmsPreview: boolean = false;
  showEmailPreview: boolean = false;
  messageName: string = "";
  public smsMsgBody: any;
  public textPreviewFlag: boolean;
  public messageCategoryList: any;
  public messageCategory: string;
  private includeDroppedMessages: boolean = false;
  @ViewChild(CustomGridComponent) CustomGridComponent: CustomGridComponent;
  columnsLoaded: boolean = false;
  constructor(private campaignService: CampaignsService, private route: ActivatedRoute, private csvService: CsvService,
    private messageTemplatesService: MessageTemplatesService, private lookupService: LookupService) {
    this.campaignId = this.route.snapshot.paramMap.get('id');
    this.searchModel = new SearchModel({
      campaignId: this.campaignId,
      pageNum: 1,
      pageSize: 10
    });
  }

  ngOnInit(): void {

    this.searchModel.filterStr = '{"includeDroppedMessages":false}';
    this.campaignService.GetCampaignMessageLogs(this.searchModel).then((data) => {
      data.data.forEach((item: any) => {
        this.campaignMessageLogs.push(new CampaignMessageLog(item as ICampaignMessageLog));
      });
      this.searchModel.totalRecords = data.pageInfo.totalRecords;
    }, (error) => {
      console.error(error);
    });

    this.campaignService.GetCampaignMessageLogFilters(this.campaignId).then((data) => {
      data.data.forEach((item: any) => {
        this.campaignMessageLogFilters.push(item);
      });

      this.columns
        .find((column) => column.field === 'recipientName')
        .filterOptions = this.campaignMessageLogFilters
          .filter((item) => { return item.field === 'Recipient Name' })
          .map((item) => { return { value: item.value, field: item.value } });

      this.columns
        .find((column) => column.field === 'recipientEmail')
        .filterOptions = this.campaignMessageLogFilters
          .filter((item) => { return item.field === 'Recipient Email' })
          .map((item) => { return { value: item.value, field: item.value } });

      this.columns
        .find((column) => column.field === 'recipientMobilePhone')
        .filterOptions = this.campaignMessageLogFilters
          .filter((item) => { return item.field === 'Recipient Phone' })
          .map((item) => { return { value: item.value, field: item.value } });

      this.columns
        .find((column) => column.field === 'messageName')
        .filterOptions = this.campaignMessageLogFilters
          .filter((item) => { return (item.field === 'Message Name') && item.value !== null })
          .map((item) => { return { value: item.value, field: item.value } });

      this.columnsLoaded = true;

    }
      , (error) => {
        console.error(error);
      });

    this.lookupService.GetCampaignCategories().subscribe((result) => {
      if (result && result.data) {
        this.messageCategoryList = result.data.map((obj) => {
          return {
            item_id: obj.categoryId,
            item_text: obj.categoryName,
          };
        });
      }
    });

  }

  onPageChangedParent($event: any): void {
    this.searchModel.sortColumn = $event.sortField;
    this.searchModel.sortOrder = $event.sortOrder;
    this.updateTable($event.first);


  }

  updatedFilters() {
    let filters = this.createFilters();
    filters = this.createSort(filters);
    filters = this.createDateTypeFilter(filters);
    this.searchModel.filterStr = JSON.stringify(filters);
    if (this.searchModel.filterStr?.includes('messageName')) {
      this.searchModel.filterStr = this.searchModel.filterStr.replace('messageName', 'messageTitle');
    }
    this.searchModel.pageNum = 1;
    this.searchModel.pageSize = 10;

    this.campaignService.GetCampaignMessageLogs(this.searchModel).then((data) => {
      this.campaignMessageLogs.length = 0;
      data.data.forEach((item: any) => {
        this.campaignMessageLogs.push(new CampaignMessageLog(item as ICampaignMessageLog));
      });

      this.searchModel.totalRecords = data.pageInfo.totalRecords;

      this.campaignMessageLogs = [...this.campaignMessageLogs];
    }, (error) => {
      console.error(error);
    });
  }

  updateTable(first: number) {

    let filters = this.createFilters();
    filters = this.createSort(filters);
    filters = this.createDateTypeFilter(filters);
    this.searchModel.filterStr = JSON.stringify(filters);
    if (this.searchModel.filterStr?.includes('messageName')) {
      this.searchModel.filterStr = this.searchModel.filterStr.replace('messageName', 'messageTitle');
    }

    const pageNumber = Math.floor(first / this.searchModel.pageSize) + 1;
    this.searchModel.pageNum = first === 0 ? 0 : pageNumber;

    this.campaignService.GetCampaignMessageLogs(this.searchModel).then((data) => {
      this.campaignMessageLogs.length = 0;
      data.data.forEach((item: any) => {
        this.campaignMessageLogs.push(new CampaignMessageLog(item as ICampaignMessageLog));
      });

      this.searchModel.totalRecords = data.pageInfo.totalRecords;

      this.campaignMessageLogs = [...this.campaignMessageLogs];
    }, (error) => {
      console.error(error);
    });
  }

  createSort(filters: any) {

    if (this.searchModel.sortColumn !== undefined && this.searchModel.sortOrder !== undefined) {

      const sortOrder = this.searchModel.sortOrder.toString() === '-1' ? 'DESC' : 'ASC';
      let sortColumn = ''
      switch (this.searchModel.sortColumn) {
        case 'recipientName':
          sortColumn = 'RecipientName';
          break;
        case 'recipientEmail':
          sortColumn = 'RecipientEmail';
          break;
        case 'messageName':
          sortColumn = 'MessageName';
          break;
        case 'messageSentDate':
          sortColumn = 'MessageSentDate';
          break;
      }

      filters['orderBy'] = `${sortColumn} ${sortOrder}`;

    }

    return filters;

  }

  createFilters() {
    const columnsWithFilters = this.columns.filter((column) => column.filterValues.length > 0);

    let filters = columnsWithFilters.reduce((acc, column) => {
      if (column.filterType === FilterTypes.ENUM && column.field !== 'activity') {
        acc[column.field] = column.filterValues.map((filterValue) => filterValue.value).join(',');
      } else if (column.field !== 'activity') {
        acc[column.field] = column.filterValues.join(',');
      }

      return acc;
    }, {});

    filters = this.createActivityFilter(filters);

    if (this.includeDroppedMessages) {
      filters['includeDroppedMessages'] = true;
    }
    return filters;
  }

  createActivityFilter(filters: any) {
    const activityColumn = this.columns.find((column) => column.field === 'activity');

    if (activityColumn && activityColumn.filterValues.length > 0) {
      const selectedActivities = activityColumn.filterValues.map((filterValue) => filterValue.value);

      selectedActivities.forEach((activity) => {
        filters[activity] = true;
      });
    }

    return filters;
  }


  createDateTypeFilter(filters: any) {

    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based in JavaScript
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const milliseconds = Math.floor(date.getMilliseconds() / 10).toString().padStart(2, '0'); // Get first 2 digits of milliseconds

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    const columnWithDateFilter = this.columns.find((column) => column.filterType === FilterTypes.DATE);

    if (columnWithDateFilter.beginDate !== null) {
      filters['dateSentStart'] = formatDate(columnWithDateFilter.beginDate);
    }

    if (columnWithDateFilter.endDate !== null) {
      filters['dateSentEnd'] = formatDate(columnWithDateFilter.endDate);
    }

    return filters;
  }

  createSearchFilter(filters: any) {

    if (this.searchModel.searchStr !== undefined && this.searchModel.searchStr !== null && this.searchModel.searchStr !== '') {
      // filters['searchStr'] = this.searchModel.searchStr;
    }
  }

  async showMessage(templateId: string, isPublishedDb: boolean) {


    let template = await this.messageTemplatesService.GetTemplateById(
      templateId,
      //"0a801dea-bbf2-4997-b4a9-65d20a011587",// Test sms tempalteId for testing with DEV01 db
      //"ea07f532-3db9-402a-a13f-8110473d1b95", // Test email tempalteId for testing with DEV01 db
      isPublishedDb
    );
    this.messageName = template.data.title;

    if (template.data.templateTypeId == 1) {
      //Email Preview
      let dlg: any = this.previewDialog;
      console.log(template.data.previewBody);
      dlg.initializeDialog(template.data.previewBody, 616, 936);
      this.showEmailPreview = true;
    } else {
      //SMS Preview
      this.smsMsgBody = JSON.parse(template.data.previewBody);
      this.textPreviewFlag = true;
      this.messageCategoryList.forEach((item, index) => {
        if (item.item_id == template.data.categoryId) {
          this.messageCategory = item.item_text;
        }
      });

      this.showSmsPreview = true;

    }

  }

  downloadAllEvent() {

    const searchModelForAllData = new SearchModel({
      campaignId: this.searchModel.campaignId,
      pageNum: 1,
      pageSize: this.searchModel.totalRecords,
    } as ISearchModel);

    this.campaignService.GetCampaignMessageLogs(searchModelForAllData).then((data) => {

      const allMessageLogs = new Array<CampaignMessageLog>();
      data.data.forEach((item: any) => {
        allMessageLogs.push(new CampaignMessageLog(item as ICampaignMessageLog));
      });

      this.csvService.downloadCSV(allMessageLogs, 'data.csv');

    }, (error) => {
      console.error(error);
    });

  }

  filterBasedOnColumnSelect(columns: any) {
    this.includeDroppedMessages = (columns.find(s => s.field === 'droppedMessage').display === true);
    this.updatedFilters();
  }

  loadTableWithFilters($event: any) {

    const messageTypeColumn = this.columns.find((column) => column.field === 'messageType');
    this.columns[5].filterValues.length = 0;
    if ($event.messageType === MessageTypes.EMAIL) {
      this.columns[5].filterValues.push(messageTypeColumn.filterOptions[0].value);
    } else {
      this.columns[5].filterValues.push(messageTypeColumn.filterOptions[1].value);
    }
    this.CustomGridComponent.FiltersComponent.selectedFilterColumns.length = 0;
    this.CustomGridComponent.FiltersComponent.selectedFilterColumns.push(messageTypeColumn);

    if ($event.filter !== null) {
      const activityColumn = this.columns.find((column) => column.field === 'activity');
      this.columns[6].filterValues.length = 0;
      this.columns[6].filterValues.push(activityColumn.filterOptions.filter(option => option.value.value === $event.filter)[0].value);
      this.CustomGridComponent.FiltersComponent.selectedFilterColumns.push(activityColumn);
    }



    this.updateTable(0);
  }

}
