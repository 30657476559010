<div *ngIf="user?.original_userfullname"
    style="width: 100%;background: yellow;display: block;position: absolute;top: 0px;text-align: center; z-index: 999999 !important;">
    <table>
        <tr>
            <td style="padding-left:255px">
                Logged in as {{user.original_userfullname}}. Impersonating: {{user.getFullName()}}
                [{{user.TenantName}}].
                Role: {{role}}
            </td>

            <td style="padding-left:25px">
                <div role="button" (click)="changeImpersonatedUser()"><u> Switch Tenant</u></div>
            </td>
        </tr>
    </table>
</div>