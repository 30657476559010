import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { BillingService } from 'src/services/billingService';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class Dashboard implements OnInit {
  @Output() onExploreMore: EventEmitter<Event> = new EventEmitter<Event>(true);

  loadingPage: boolean = false;
  totalRecords: number = 0;
  currentPage: number = 1;
  txtCurrentPage: string = '1';
  pageSize: number = 10;
  tableFirst: number = 0;
  showRecommendations: boolean = false;
  currentClientID: string;
  public onboarded: boolean;

  /**
   * Constructor
   * 
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>
   * @param messageService MessageService required for <p-toast>
   */
  constructor(
    public http: HttpClient,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private billingService: BillingService
  ) {
    this.showRecommendations = false;
    this.currentClientID = environment.clientId;
    this.onboarded = false;
  }

  //***************************************************************************************
  // Component Initialization
  //***************************************************************************************
  /** Occurs upon component initialization */
  async ngOnInit(): Promise<void> {
    this.billingService.GetCompanyAdditionalDetail().subscribe(
      (res) => {
        if (res.data) {
          this.onboarded = res.data.onboarded;
        }
      },
    );
    // obtain component data
    this.getData(this.currentPage);
  }

  //***************************************************************************************
  // Data Acquisition
  //***************************************************************************************
  /**
   * Retrieves all necessary data for this component.
   *
   * @param: currentPage - paging support.
   */
  getData(currentPage) {
    // start the progress bar/spinner
    this.loadingPage = true;
    // add code to acquire data here.
    this.loadingPage = false;
  }

  //***************************************************************************************
  // Event Handlers
  //***************************************************************************************
  /**
  * Called when the 'Explore More' button is clicked in the Recommendations component.
  * Re-emit the event so it is received by the Application component for processing.
  * (navigates to the 'All Messages' page)
  */
  async onExploreMoreClicked(event: any) {
    // (onExploreMore)="onExploreMore($event)" MUST be added to the dashboard
    // (this) component's HTML tag to indicate that the onExploreMore event is being emitted.
    // The Application component (direct parent of this component) implements the onExploreMore($event) method
    // that receives and processes the event.
    this.onExploreMore.emit(event);
  }
}
