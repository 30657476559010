import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CMSComponentData, StatusQuickPickData } from '../../../../../../src/models/MessageScheduleData';
import { CommunicationService } from '../../../../../services/communication.service';
import { LoansService } from '../../../../../../src/services/Loans.service';
import { LoanStatusType } from '../../../../../models/LoanStatusType';
import { Subscription, forkJoin } from 'rxjs';
import { ToastService } from '../../../../../services/toast.service';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { CalendarAndTimeZoneService } from 'src/app/services/CalendarAndTimeZoneService';
import { LookupService } from 'src/services/lookup.service';
import { DateTimeHelper } from 'src/helpers/DateTimeHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-one-time-status-quick-pick',
  templateUrl: './campaign-message-schedule-status-quick-pick.component.html',
  styleUrls: ['./campaign-message-schedule-status-quick-pick.component.scss'],
})
export class CampaignMessageScheduleStatusQuickPickComponent implements OnInit {
  public loanStatusList: LoanStatusType[];
  public CMSComponentData: CMSComponentData;
  public statusQuickPickData: StatusQuickPickData;
  public sche: string;
  public sub: Subscription;
  statusForm: FormGroup | any = null;
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  days: any[];
  periodUnits: any[];
  offsetTypes: any[];
  sendOnDayTypes: any[];
  hours: any[];
  minutes: any[];
  meridiems: any[];
  timeZones: any[];
  userTimeZoneId: any;
  userToken: UserToken;
  isFormValueChange = false;
  messageType: number;
  showTimeControls: boolean = false;
  public timeToolTipe: string;
  isNoDelay: boolean = false;
  allowTCPAForTextMessages: boolean = true;
  constructor(
    private communicationService: CommunicationService,
    private loanService: LoansService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private userTokenService: UserTokenService,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(0); //index is mandatory
    this.statusQuickPickData = new StatusQuickPickData(); //intialize object
    this.userToken = this.userTokenService.getUser();
    this.messageType =
      this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe =
      'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
  }
  ngOnInit(): void {
    this.days = this.calendarAndTimeZoneService.getListOfDaysRange('DAY');
    this.periodUnits = this.calendarAndTimeZoneService.GetPeriodUnit();
    this.offsetTypes = this.calendarAndTimeZoneService.GetOffsetType();
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    this.sendOnDayTypes = this.calendarAndTimeZoneService.GetSendOnDayType();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;

    forkJoin(
      this.loanService.GetLoanStatusType(false),
      this.lookupService.GetTimezones()
    ).subscribe(([loanStatusTypeResponse, timezonesresponse]) => {
      this.loanStatusList = loanStatusTypeResponse.data;
      this.timeZones = timezonesresponse.data;
      this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
      let defaultObject =
        this.calendarAndTimeZoneService.getDefaultDateTimeObject(
          this.userTimeZoneId,
          this.timeZones
        );
      if (this.userTimeZoneId) {
        let defaultData = {
          hour: defaultObject.defaultHour,
          minute: defaultObject.defaultMinute,
          meridiem:
            this.messageType == 2 && this.allowTCPAForTextMessages
              ? this.calendarAndTimeZoneService.getMeridiem(
                  defaultObject.defaultHour
                )
              : defaultObject.defaultMeridiem,
          timeZoneId: defaultObject.defaultTimeZoneId,
          sendOnTimeType: 'ANYTIME',
          day: 1,
          periodUnit: 'DAY',
          offsetType: 'AFTER',
          loanStatusType: this.loanStatusList[0].loanStatusTypeId,
          sendOnDayType: 'ANYDAY',
        };
        let savedObject = new StatusQuickPickData();
        if (
          scheduleDataJSON &&
          scheduleDataJSON != 'No Schedule' &&
          scheduleType == 'OneTime' &&
          ScheduleSubtype == 'StatusQuickPick'
        ) {
          savedObject = JSON.parse(scheduleDataJSON)[0];
          if (savedObject) {
            if (savedObject.offsetTypeId) {
              defaultData.day = parseInt(savedObject.day);
              defaultData.periodUnit = savedObject.periodUnit;
              defaultData.offsetType = savedObject.offsetTypeId;
              defaultData.sendOnDayType = savedObject.sendOndayTypeId;
              defaultData.loanStatusType = savedObject.loanStatusTypeId;
              if (savedObject.time) {
                this.showTimeControls = true;
                let dateTimeHelper = new DateTimeHelper();
                defaultData.timeZoneId = savedObject.timezoneId;
                var time = dateTimeHelper.Get12HourTimeComponent(
                  savedObject.time
                );
                if (time) {
                  defaultData.hour = parseInt(time.hours);
                  defaultData.minute = parseInt(time.minutes);
                  defaultData.meridiem = time.AmPM;
                }
                defaultData.sendOnTimeType = 'ATSPECIFICTIME';
              } else {
                this.showTimeControls = false;
                defaultData.sendOnTimeType = 'ANYTIME';
              }
            } else {
              defaultData.loanStatusType = savedObject.loanStatusTypeId;
            }
          }
        }

        this.createForm(defaultData);
      }
    });
  }
  createForm(defaultData) {
    this.statusForm = this.formBuilder.group({
      hour: [defaultData.hour],
      minute: [defaultData.minute],
      meridiem: [defaultData.meridiem],
      timeZone: [defaultData.timeZoneId],
      day: [defaultData.day],
      periodUnit: [defaultData.periodUnit],
      offsetType: [defaultData.offsetType],
      loanStatusType: [defaultData.loanStatusType],
      sendOnDayType: [defaultData.sendOnDayType],
      sendOnTimeType: [defaultData.sendOnTimeType],
    });
    this.statusForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;
      this.days = this.calendarAndTimeZoneService.getListOfDaysRange(
        this.statusForm.value.periodUnit
      );

      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.days = this.calendarAndTimeZoneService.getListOfDaysRange(
        this.statusForm.value.periodUnit
      );
      if (this.statusForm.value.periodUnit === 'NODELAY') {
        this.isNoDelay = true;
      }
      this.fillFormData();
    }
  }
  OnHourChange(event) {
    if (this.messageType == 2 && this.allowTCPAForTextMessages) {
      let selectedHour = this.statusForm.value.hour;
      this.statusForm.patchValue({
        meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
      });
    }
  }
  OnPeriodChange(event) {
    if (this.statusForm.value.periodUnit === 'NODELAY') {
      this.isNoDelay = true;
      this.statusForm.patchValue({
        day: '',
      });
    } else {
      this.isNoDelay = false;
      this.statusForm.patchValue({
        day: 1,
      });
    }
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.statusForm.valid;
    this.CMSComponentData.componentValueJSON = this.statusQuickPickData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
  fillFormData() {
    this.statusQuickPickData.campaignMessageId = '';
    this.statusQuickPickData.loanStatusTypeId =
      this.statusForm.value.loanStatusType;
    let selectedloanStatusType = this.loanStatusList.find(
      (x) =>
        x.loanStatusTypeId.toLowerCase() ===
        this.statusForm.value.loanStatusTypeId
    );
    this.statusQuickPickData.timezoneId = this.statusForm.value.timeZone;
    this.statusQuickPickData.timezoneInfoId = this.timeZones.find(
      (x) => x.timezoneId.toLowerCase() === this.statusQuickPickData.timezoneId
    )?.timezoneInfoId;
    let hr = Number(this.statusForm.value.hour);
    let mi = Number(this.statusForm.value.minute);
    let mer = this.statusForm.value.meridiem;
    this.statusQuickPickData.time = this.calendarAndTimeZoneService.getTimeSpan(
      hr,
      mi,
      mer
    );
    this.statusQuickPickData.day = this.statusForm.value.day;
    this.statusQuickPickData.periodUnit = this.statusForm.value.periodUnit;
    this.statusQuickPickData.offsetTypeId = this.statusForm.value.offsetType;
    this.statusQuickPickData.sendOndayTypeId =
      this.statusForm.value.sendOnDayType;
    this.statusQuickPickData.sendOnTimeType =
      this.statusForm.value.sendOnTimeType;
    if (this.statusForm.value.sendOnTimeType == 'ATSPECIFICTIME') {
      this.showTimeControls = true;
    } else {
      this.showTimeControls = false;
    }
    this.doEmit();
  }

  // ngOnDestroy(): void {
  //   this.sub.unsubscribe();
  //   this.loanStatusList = [];
  //   this.CMSComponentData.Dispose();
  // }
}
