<div style="background: #F4EDFE;" *ngIf="campaignInfo?.campaignId">
  <div class="row pt-5">
    <span class="lable-text text-center"> Add Message </span>
  </div>
  <div class="row pb-5 ">
    <div class="col text-center" style="color: #000000; font-weight: 500;font-size: 16px;">What type of messages
      would you like to create?</div>
  </div>
  <div class="row pb-5 justify-content-center">
    <div class="card d-flex justify-content-center align-items-center build-style">
      <div class="col-6 d-flex flex-column align-items-center">
        <i class="fa-regular fa-comment-lines message-dialog-icon facomment pb-3 fw-light"></i>
        <button class="p-button text-white p-component fw-light" [routerLink]="['/marketing/template-library/sms']"
          [queryParams]="{ cmpid: campaignInfo.campaignId , type :'build' , change: campaignInfo?.campaignMessageId  }">
          Text Message
        </button>
      </div>
    </div>
    <div class="card d-flex justify-content-center align-items-center build-style">
      <div class="col-6 d-flex flex-column align-items-center">
        <i class="fa-regular fa-envelope message-dialog-icon facomment pb-3 fw-light"></i>
        <button class="p-button text-white p-component fw-light" [routerLink]="['/marketing/template-library/email']"
          [queryParams]="{ id: campaignInfo.campaignId , type :'build', change: campaignInfo?.campaignMessageId }">Email
          Message
        </button>
      </div>
    </div>
  </div>
</div>
