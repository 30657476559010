import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core'; 
import { HttpClient } from '@angular/common/http'; 
import { ConfirmationService, MessageService } from 'primeng/api'; 
 
@Component({ 
    selector: 'recommendationsSkeleton-root', 
    templateUrl: './recommendationsSkeleton.component.html', 
    styleUrls: ['./recommendationsSkeleton.component.scss'] 
}) 
export class recommendationsSkeletonComponent implements OnInit, AfterViewInit { 
  //title = 'recommendationsSkeleton'; 
 
  // allows [tenantId] = '...' to be added to the selector and passed in 
  //@Input() tenantId: string; 
 
  // true delivers the event asynchronously, false delivers synchronously. 
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true); 
 
  // property for the control with  #myControlId to be accessed with this.propertyName 
  // @ViewChild('myControlId', { static: boolean }) propertyName: type 
 
  // used for showing/hiding progress bar 
  loadingPage: boolean = false; 
 
  // Paging support if necessary 
  totalRecords: number = 0; 
  // current page being displayed 
  currentPage: number = 1; 
  txtCurrentPage: string = '1'; 
  // number of rows per page (initialize to one of the values in the 'rowsPerPageOptions' paginator property) 
  pageSize: number = 10; 
  tableFirst: number = 0; 
  // End Paging support 

  skeletonData: string[];
 
  /** 
   * Constructor 
   *  
   * @param http HTTPClient required to call actions in any .ts file in the services folder. 
   * @param confirmationService ConfirmationService required for <p-confirmDialog> 
   * @param messageService MessageService required for <p-toast> 
   */ 
  constructor(private http: HttpClient, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService) { 
 
    // skelton template data
    this.skeletonData = ['', '', '', ''];
  }
 
  //*************************************************************************************** 
  // Component Initialization 
  //*************************************************************************************** 
  /** Occurs upon component initialization */ 
  async ngOnInit(): Promise<void> { 
 
    // obtain data for the page 
    this.getData(this.currentPage); 
  } 
 
  /** Occurs after view initialization */ 
  ngAfterViewInit(): void { 
  } 
 
  //*************************************************************************************** 
  // Data Acquisition 
  //*************************************************************************************** 
  /** 
   * Retrieves all necessary data for this component. 
   * 
   * @param: currentPage - paging support. 
   */ 
  getData(currentPage) { 
    // start the progress bar/spinner 
    this.loadingPage = true; 
 
    // add code to acquire data here. 
 
    // when data is retrieved, set this.loadingPage to false. 
    this.loadingPage = false; 
  } 
 
  //*************************************************************************************** 
  // Methods and Event Handlers 
  //*************************************************************************************** 
 
} 
 
