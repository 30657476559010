import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { BillingService } from 'src/services/billingService';
import { OnBoardingService } from '../../../services/onBoarding.service';

@Component({
  selector: 'app-send-query',
  templateUrl: './send-query.component.html',
  styleUrls: ['./send-query.component.scss']
})

export class SendQueryComponent implements OnInit {
  public selectedLabel: string;
  public selectedItem: any;
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";

  constructor(
    private router: Router,
    private billingService: BillingService,
    private onBoardingService: OnBoardingService,
  ) {
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
  }

  ngOnInit(): void {
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/add-admin'] or ['/carrier-form']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToOptIn(): void {
    let navigateTo;
    let navigateFrom = '/send-query';

    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    
    if (this.selectedLabel == "YesRadio") {
      //this.billingService.SaveCompanySMSSetting(true);
      this.onBoardingService.OnBoardUpdateSmsMarketing(true);
      navigateTo = '/carrier-form';
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
    }
    else if (this.selectedLabel == "NoRadio") {
      //this.billingService.SaveCompanySMSSetting(false);
      this.onBoardingService.OnBoardUpdateSmsMarketing(false);
      navigateTo = '/select-plan';
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
    }
  }

  actionContent(event, label) {
    if (event.target.checked) {
      this.selectedLabel = label
    }
    if(label == "YesRadio") {
      this.selectedItem = 1;
    } else {
      this.selectedItem = 0;
    }
  }
}
