<div [formGroup]="imageForm" (ngSubmit)="onSaveClick()">
  <p-dialog [(visible)]="display" [resizable]="false" [modal]="true" class="custom-dialog" 
            [style]="{ 'max-width': '90%', width: '52.5em', 'font-weight': '700' }" [baseZIndex]="10000" (onHide)="onClose()">
    <p-header>
      <span class="dialog-head">{{ title }}</span>
    </p-header>
    <div style="padding-right: 1rem" *ngIf="!isImageSelected">
      <div class="p-fluid p-formgrid p-grid" style="font-weight: 400 !important">
        <div class="control-div p-col-12 p-md-12 row p-0 w-100" style="height: 250px; background:#f5f5f5;">
          <div class="d-flex justify-content-center align-items-center">
            <table>
              <tr>
                <td align="center">
                  <button pButton icon="pi pi-upload text-white" type="button" label="Upload Image" class="p-button p-component mb-3" (click)="imageUploader.choose()"></button>
                  <span>
                    <p-fileUpload #libraryImageUploader formControlName="files" name="files" accept="image/*" [auto]="true" customUpload="true"
                      (uploadHandler)="imageUploadHandler($event)" class="as-is-image-upload">
                    </p-fileUpload>              
                  </span>
                  <span *ngIf="!showErrorMessage" class="text-center">
                   <strong> File size limit:</strong> No larger than 1MB
                  </span>
                  <span class="text-danger text-center fw-bold" *ngIf="showErrorMessage">
                <span class="pi pi-times fw-bolder" style="font-size:13px !important;"></span> File size limit exceeded. Please upload an image no larger than 1MB.
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>  
    </div>
    <div style="padding-right: 1rem" *ngIf="isImageSelected">
      <div class="p-fluid p-formgrid p-grid" style="font-weight: 400 !important">
        <div class="control-div p-col-12 p-md-12 row p-0">
          <div class="p-col-6">           
            <div>
              <img [src]="url" *ngIf="selectedImage.Avatar" 
                   [style]="{'margin-left': '0rem', width: '14rem', height: '14rem', 'max-width': '14rem', 
                            'max-height': '14rem', 'object-fit': 'cover', 'border-radius': '50%'}">

              <img [src]="url" *ngIf="!selectedImage.Avatar" width="320" height="235"
                   [style]="{'margin-left': '0rem', width: '20rem', height: '14.6rem', 
                            'max-width': '20rem', 'max-height': '14.6rem', 'object-fit': 'contain'}">
            </div>
          </div>
          <div class="p-col-6" style="padding-left: 30px">
            <div class="p-d-flex">
              <label for="txtTitle" class="col-form-label fw-bold">Title *</label>
            </div>
            <div class="p-d-flex">
              <input type="text" name="txtTitle" id="txtTitle" maxlength="64" placeholder="Image Title" class="form-control" formControlName="txtTitle"
                [class.ng-invalid]="(isFormSubmitted || imageForm.get('txtTitle').touched) && imageForm.get('txtTitle').invalid"/>
            </div>
            <div class="p-d-flex">
              <span class="text-danger" *ngIf="(isFormSubmitted || imageForm.get('txtTitle').touched) && imageForm.get('txtTitle').errors?.required">
                Image title cannot be blank</span>
              <span class="text-danger" *ngIf="(isFormSubmitted || imageForm.get('txtTitle').touched) && imageForm.get('txtTitle').errors?.minlength">
                Image title should contain at least 2 characters</span>
            </div>
            <!-- <div class="p-d-flex mt-4">
              <p-checkbox class="imageCheckbox" [formControl]="imageForm.controls['chkAvatar']" binary="true" inputId="chkAvatar" 
                          (onChange)="onAvatarCheckboxChange($event)"></p-checkbox>
              <label class="control-label">Avatar</label>
            </div> -->
            <!-- <div class="p-d-flex">
              <p-checkbox
                class="imageCheckbox"
                [formControl]="imageForm.controls['chkPrivate']"
                binary="true"
                inputId="chkPrivate"
              ></p-checkbox>
              <label class="control-label">Active</label>
            </div> -->
            <div class="p-d-flex">             
              <!-- <button type="button" *ngIf="imageUploaded" (click)="onUndoUpload($event)" style="cursor: pointer; box-shadow: unset" class="p-column-filter-menu-button">
                <span class="pi pi-undo"></span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>    
    <ng-template pTemplate="footer">
      <a (click)="onClose()"
      style="color:#2e2e2e;cursor: pointer;font-weight: 400; margin: 0px 17.5px;text-decoration: underline;"
      styleClass="p-button-secondary" *ngIf="isImageSelected">Cancel</a>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="onSaveClick()" label="Save" *ngIf="isImageSelected"></p-button>
    </ng-template>    
  </p-dialog>
</div>
