import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { BillingService } from 'src/services/billingService';

@Component({
  selector: 'app-opt-in-email',
  templateUrl: './opt-in-email.component.html',
  styleUrls: ['./opt-in-email.component.scss']
})

export class OptInEmailComponent implements OnInit {
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";

  constructor(
    private router: Router,
    private billingService: BillingService
  ) {
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
  }

  ngOnInit(): void {
  }

  redirectToBack(): void {
    var navigateBack = this.navigationStack.pop();
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    //navigate back to ['/opt-in'] or ['/permit-collect']
    this.router.navigate([navigateBack]);
  }

  redirectToSubscribeText(): void {
    let navigateTo = '/select-plan';
    let navigateFrom = '/opt-in-email';
    this.navigationStack.push(navigateFrom);
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    this.router.navigate([navigateTo]);
  }
}
