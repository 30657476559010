       
      <table class="stats-table">
        <tr>
          <td valign="top" align="center">
            <div><span>Sent</span>
                <a routerLink="/marketing/reports/lists/messages/delivered">{{this.emailStats.GetSent()}}</a>
        </div>  
          </td>
          <td valign="top" align="center">
             <div><span>Opened</span>
                <a routerLink="/marketing/reports/lists/messages/opened">{{this.openedPercent | percent}}</a></div> 
          </td>
          <td valign="top" align="center" >
              <div><span>Clicked</span>
                <a routerLink="/marketing/reports/lists/messages/clicked">{{this.clickedPercent | percent}}</a></div>

          </td>
          <td valign="top" align="center">
            <div class="tooltip_width" pTooltip="Emails that weren't able to be delivered because the email address is permanently unavailable, an email server outage is occurring, or the recipient's inbox is full." tooltipPosition="top">
                <span>Bounced</span>
                <a routerLink="/marketing/reports/lists/messages/undelivered">{{this.undeliveredPercent | percent}}</a>
            </div>
          </td>
          <td valign="top" align="center" >
              <div><span>Opted&nbsp;Out</span>
                <a routerLink="/marketing/reports/lists/messages/opt-out" >{{this.optedOutPercent | percent}}</a></div>
          </td>
        </tr>
      </table>


<table class="stats-overview-table">
    <tr class="header"><td colspan="5">Email</td></tr>
    <tr>
        <td class="data">Sent</td>
        <td><a routerLink="/marketing/reports/lists/messages/sent">7242</a></td>
        <td class="separator"></td>
        <td class="data">Clicks</td>
        <td><a routerLink="/marketing/reports/lists/messages/clicks">13</a></td>
    </tr>
    <tr>
        <td class="data">Opens</td>
        <td><a routerLink="/marketing/reports/lists/messages/opened">612</a></td>
        <td class="separator"></td>
        <td class="data">Did Not Open</td>
        <td><a routerLink="/marketing/reports/lists/messages/notopened">3936</a></td>
    </tr>
    <tr>
        <td class="data">Bounced</td>
        <td><a routerLink="/marketing/reports/lists/messages/bounced">2694</a></td>
        <td class="separator"></td>
        <td class="data">Unsubscribed</td>
        <td><a routerLink="/marketing/reports/lists/messages/unsubscribed">9</a></td>
    </tr>
    <tr>
        <td class="data">Successful Deliveries</td>
        <td><a routerLink="/marketing/reports/lists/messages/delivered">4548</a></td>
        <td class="separator"></td>
        <td class="data">Spam Reports</td>
        <td><a routerLink="/marketing/reports/lists/messages/spam">0</a></td>
    </tr>
    <tr>
        <td class="data">Desktop Open Percentage
        </td>
        <td><a routerLink="/marketing/reports/lists/messages/desktopopen">90.2%</a></td>
        <td class="separator"></td>
        <td class="data">Mobile Open Percentage</td>
        <td><a routerLink="/marketing/reports/lists/messages/mobileopen">9.8%</a></td>
    </tr>
    <tr class="header"><td colspan="5">SMS</td></tr>
    <tr>
        <td class="data">Sent</td>
        <td><a routerLink="/marketing/reports/lists/messages/smssent">1034</a></td>
        <td class="separator"></td>
        <td class="data">Blocked by Carrier</td>
        <td><a routerLink="/marketing/reports/lists/messages/smsblocked">65</a></td>
    </tr>
    <tr>
        <td class="data">Delivered</td>
        <td><a routerLink="/marketing/reports/lists/messages/smsdelivered">870</a></td>
        <td class="separator"></td>
        <td class="data">Sent to Bad Number</td>
        <td><a routerLink="/marketing/reports/lists/messages/smsbad">99</a></td>
    </tr>
</table>