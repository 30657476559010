import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core'; 

@Component({ 
    encapsulation: ViewEncapsulation.None, 
    selector: 'previewDialog-root', 
    templateUrl: './previewDialog.component.html', 
    styleUrls: ['./previewDialog.component.scss'] 
}) 
export class previewDialogComponent implements OnInit, AfterViewInit { 
  //title = 'previewDialog'; 
 
  // allows [compiledEmail] = '...' to be added to the selector and passed in
  @Input() compiledEmail: string;
  @Input() templateWidth: number;
  @Input() templateHeight: number;
 
  // true delivers the event asynchronously, false delivers synchronously. 
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true); 
 
  // property for the control with #dataTableId to be accessed with propertyName dataTable
  // Uncomment the following line if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
  // @ViewChild('dataTableId', { static: false }) dataTable: Table;

  @ViewChild('iframePreview', { static: false }) iframePreview: any;

  // #region Constructor

  /** 
   * Constructor 
   *  
   * @param http HTTPClient required to call actions in any .ts file in the services folder. 
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   * @param permissionGroupService Service for working with permission groups.
   * @param userSessionService Service for user session information.
   */ 
  constructor() { 
  }

  // #endregion
 
  // #region Component Initialization

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> { 
  } 
 
  /**
  * Occurs after view initialization
  */ 
  ngAfterViewInit(): void { 
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  } 
 
  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  
  initializeDialog(compiledEmail: string, templateWidth: number, templateHeight: number) {

    let aspectRatio = templateHeight / templateWidth;
    this.compiledEmail = compiledEmail;
    // increase width so if a background image is set, it shows
    this.templateWidth = templateWidth + 40;
    // decrease height to account for increased width
    this.templateHeight = templateHeight - (41 * aspectRatio);
    var frame = this.iframePreview.nativeElement;
    frame.srcdoc = this.compiledEmail;
  }

  // #endregion
} 
 
