<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0"
               aria-valuemax="100" style="width:74.97%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-auto subs-text d-flex justify-content-center flex-column text-center container-middle" *ngIf="showSubscriptionContract">
    <div class="container-size">
      <h5>Your Subscription </h5>
      <br>
      <!-- <p>I acknowledge that I am over 18 and accept ownership of my Aduvo Premium subscription. Aduvo will automatically charge the plan fee 
        wither monthly or annually to the payment method provided on the following page. I further acknowlwdge the plan fee is recurring untill 
        I elect to cancel my Aduvo Premium subscription.</p> -->
      <div class="para-section mt-10" id="clickwrapContainer"></div>
      <button pButton pRipple class="p-button p-button-rounded float-center mt-3" (click)="redirectToBookTime()">I accept, continue to subscription options</button>
    </div>
  </div>

  <div class="m-auto subs-text d-flex justify-content-center flex-column text-center container-middle" *ngIf="showManageSubscription">
    <div class="container-size">
      <h5>Your Subscription </h5>      
      <button pButton pRipple class="p-button p-button-rounded float-center mt-3 font-16-bold" (click)="manageSubscription()">Manage Your Subscription</button>
      <button (click)="redirectToCTAScreenDirectly()" class="btn-skip ml-4">Skip this step</button>
    </div>
  </div>

  <!-- <div class="footer-section">
      <div class="onboarding-bottom my-3 container-size">
          <div>
              <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded"
                  (click)="redirectToBack()"><i class="fa fa-arrow-left"></i>&nbsp;Back</button>
              <button pButton pRipple class="p-button p-button-rounded float-end" (click)="redirectToBookTime()">Next
                  &nbsp; <i class="fa fa-arrow-right"></i></button>
          </div>
      </div>
  </div> -->
</div>
