import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterTypes } from 'src/components/custom-grid/enums/filter-types';
import { SearchModel } from 'src/components/custom-grid/models/search-model';
import { ActivityTypes } from 'src/enums/activity-types';
import { MessageTypes } from 'src/enums/message-types';
import { CampaignsService } from 'src/services/campaigns.service';

export enum ValueType {
  PERCENTAGE,
  NUMERIC
}

@Component({
  selector: 'app-campaign-report',
  templateUrl: './campaign-report.component.html',
  styleUrls: ['./campaign-report.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class CampaignReportComponent implements OnInit {

  campaignId: string = '';
  ValueType = ValueType;
  campaignMessageReportFilters: Array<any> = new Array<any>();
  filters: Array<any> = [
    {
      field: 'messageName',
      header: 'Title',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      display: true,
      filterValues: [],
      filterOptions: []
    },
  ];
  emailData: Array<{label: string, field: string, valueType:ValueType,  value: number, backgroundColor: string, filterType: ActivityTypes | null}> = [
    {
      label: 'Sent',
      field: 'totalSentEmail',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#F5F5F5',
      filterType: null
    },
    {
      label: 'Delivered',
      field: 'deliveredEmail',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#F5F5F5',
      filterType: ActivityTypes.DELIVERED
    },
    {
      label: 'Opened',
      field: 'openedEmail',
      valueType: ValueType.PERCENTAGE,
      value: 0,
      backgroundColor: '#EBFAF7',
      filterType: ActivityTypes.OPENED
    },
    {
      label: 'Clicked',
      field: 'clickedEmail',
      valueType: ValueType.PERCENTAGE,
      value: 0,
      backgroundColor: '#EBFAF7',
      filterType: ActivityTypes.CLICKED
    },
    {
      label: 'Not Opened',
      field: 'notOpened',
      valueType: ValueType.PERCENTAGE,
      value: 0,
      backgroundColor: '#FFF7E7',
      filterType: ActivityTypes.OPENED
    },
    {
      label: 'Bounced',
      field: 'bouncedEmail',
      valueType: ValueType.PERCENTAGE,
      value: 0,
      backgroundColor: '#FFF7E7',
      filterType: ActivityTypes.BOUNCED
    },
    {
      label: 'Unsubscribed',
      field: 'unsubscribedEmail',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#FFF1EC',
      filterType: ActivityTypes.UNSUBSCRIBED
    },
    {
      label: 'Reported as Spam',
      field: 'reportedAsSpamEmail',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#FFF1EC',
      filterType: ActivityTypes.SPAMREPORTED
    },
  ];
  smsData: Array<{label: string, field: string, valueType:ValueType,  value: number, backgroundColor: string, filterType: ActivityTypes | null}> = [
    {
      label: 'Sent',
      field: 'totalSentSMS',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#F5F5F5',
      filterType: null
    },
    {
      label: 'Delivered',
      field: 'deliveredSMS',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#F5F5F5',
      filterType: ActivityTypes.DELIVERED
    },
    {
      label: 'Blocked by Carrier',
      field: 'blockedSMS',
      valueType: ValueType.PERCENTAGE,
      value: 0,
      backgroundColor: '#FFF7E7',
      filterType: ActivityTypes.BLOCKED
    },
    {
      label: 'Sent to Bad Number',
      field: 'badNumberSMS',
      valueType: ValueType.PERCENTAGE,
      value: 0,
      backgroundColor: '#FFF7E7',
      filterType: null
    },
    {
      label: 'Opted Out',
      field: 'optedOutSMS',
      valueType: ValueType.NUMERIC,
      value: 0,
      backgroundColor: '#FFF1EC',
      filterType: null
    }
  ];
  searchModel: SearchModel;
  @Output() loadMessageLogEvent: EventEmitter<{messageType: MessageTypes, filter: ActivityTypes}> = new EventEmitter<{messageType: MessageTypes, filter: ActivityTypes}>();
  MessageTypes = MessageTypes;
  constructor(private campaignService: CampaignsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.campaignId = this.route.snapshot.paramMap.get('id');

    this.searchModel = new SearchModel({
      campaignId: this.campaignId,
      pageNum: 1,
      pageSize: 10
    });

    this.campaignService.GetCampaignReport(this.searchModel).then(data => {
      this.emailData.forEach( (dataPoint: {label: string, field: string, valueType:ValueType,  value: number, backgroundColor: string}) => {
        dataPoint.value = data.data[dataPoint.field];
      });
      this.smsData.forEach( (dataPoint: {label: string, field: string, valueType:ValueType,  value: number, backgroundColor: string}) => {
        dataPoint.value = data.data[dataPoint.field];
      });
    });

    this.campaignService.GetCampaignReportFilters(this.campaignId).then(data => {
      data.data.forEach((item: any) => {
        this.campaignMessageReportFilters.push(item);
      });

      this.filters
        .find((column) => column.field === 'messageName')
        .filterOptions = this.campaignMessageReportFilters
          .filter((item) => { return item.field === 'Title' })
          .map((item) => { return { value: item.value, field: item.value } });
    });
  }

  updateReport($event: any) {
    console.log($event);

    let filters = this.filters.reduce((acc, column) => {

      acc[column.field] = column.filterValues.join(',');
      return acc;

    }, {});
    this.searchModel.filterStr = JSON.stringify(filters);

    this.campaignService.GetCampaignReport(this.searchModel).then(data => {
      this.emailData.forEach( (dataPoint: {label: string, field: string, valueType:ValueType,  value: number, backgroundColor: string}) => {
        dataPoint.value = data.data[dataPoint.field];
      });
      this.smsData.forEach( (dataPoint: {label: string, field: string, valueType:ValueType,  value: number, backgroundColor: string}) => {
        dataPoint.value = data.data[dataPoint.field];
      });

      this.emailData = [...this.emailData];
      this.smsData = [...this.smsData];
    });
  }

  loadFilteredMessages(messageType: MessageTypes, filter: ActivityTypes){
    this.loadMessageLogEvent.emit({
      messageType, 
      filter
    });
  }
}