<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:15px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="row p-mt-2 align-items-center" [formGroupName]="i">
      <div class="col-12 mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" [style]="{'width':'100%'}" formControlName="operandDropDown"
          filterBy="name" [filter]="true" filterBy="OpDisplay"></p-dropdown>
      </div>
      <div class="mb-2 col-12">
        <div *ngIf="item.get('operandDropDown').value?.RhsInputControl1" class="d-flex flex-row col-12" 
          [ngClass]="{
            'justify-content-between': item.get('operandDropDown').value?.RhsInputControl2
          }">
          <div class="col-1 d-flex">
            <i class="pi pi-reply replytosection"></i>
          </div>
          <div class="col-11 d-flex" *ngIf="!item.get('operandDropDown').value?.RhsInputControl2">
            <p-inputNumber id="txtRHSValue{{ i }}" formControlName="txtRHSValue1" class="rounded currency-filter-input"
              [ngClass]="{ 
                'is-invalid': item.get('txtRHSValue1')?.errors }" mode="currency" inputId="currency-us" currency="USD"
              locale="en-US" maxlength="20">
            </p-inputNumber>
          </div>
          <div class="w-auto" *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
            <p-inputNumber id="txtRHSValue{{ i }}" formControlName="txtRHSValue1"
              class="rounded currency-filter-input-short" [ngClass]="{ 
                'is-invalid': item.get('txtRHSValue1')?.errors }" mode="currency" inputId="currency-us" currency="USD"
              locale="en-US" maxlength="20">
            </p-inputNumber>
          </div>
          <div class="col-1 mx-1 d-flex" *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
            <div class="m-auto font-weight-bold">
              and
            </div>
          </div>
          <div class="w-auto" *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
            <p-inputNumber id="txtRHSValue2{{ i }}" formControlName="txtRHSValue2"
              class="rounded currency-filter-input-short" [ngClass]="{ 
              'is-invalid': item.get('txtRHSValue2')?.errors }" mode="currency" inputId="currency-us" currency="USD"
              locale="en-US" maxlength="20">
            </p-inputNumber>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="required-text" 
          *ngIf="item.get('txtRHSValue1')?.touched 
            && item.get('operandDropDown').value?.RhsInputControl1
            && item.get('txtRHSValue1')?.errors 
            && !item.get('operandDropDown').value?.RhsInputControl2">
          Required
        </div>
        <div class="required-text" 
          *ngIf="(item.get('txtRHSValue2')?.touched 
            && item.get('operandDropDown').value?.RhsInputControl2 
            && item.get('txtRHSValue2')?.errors) || 
            (item.get('txtRHSValue1')?.touched 
            && item.get('operandDropDown').value?.RhsInputControl1 
            && item.get('txtRHSValue1')?.errors) && item.get('operandDropDown').value?.RhsInputControl2">
          Both fields are required
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding:0px 30px;">
    <div class="col-12 text-right">
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" label="Apply"></p-button>
    </div>
  </div>
</div>