import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from '../../../../../../src/app/services/CalendarAndTimeZoneService';
import { formState } from 'src/helpers/formState.helper';
import {
  CMSComponentData,
  RepeatingYearlyContactData,
} from '../../../../../../src/models/MessageScheduleData';
import { LookupService } from '../../../../../../src/services/lookup.service';
import { ToastService } from 'src/services/toast.service';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-repeating-contact',
  templateUrl: 'campaign-message-schedule-repeating-contact.component.html',
})
export class CampaignMessageScheduleRepeatingContactComponent
  implements OnInit
{
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public CMSComponentData: CMSComponentData;
  repeatingYearlyContactData: RepeatingYearlyContactData;

  contactForm: FormGroup | any = null;
  validator: formState = new formState();
  isFormValueChange = false;
  isAfter = false;
  hours: any[];
  minutes: any[];
  meridiems: any[];
  contacts: any[];
  timeZones: any[];
  startYears: any[];
  endYears: any[];
  todayDate = new Date();
  userTimeZoneId: any;
  userToken: UserToken;
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  constructor(
    private http: HttpClient,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private userTokenService: UserTokenService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(6);
    this.repeatingYearlyContactData = new RepeatingYearlyContactData();
    this.userToken = this.userTokenService.getUser();
    this.messageType =
      this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe =
      'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
  }

  ngOnInit(): void {
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    this.startYears = this.calendarAndTimeZoneService.GetStartYears();
    this.endYears = this.calendarAndTimeZoneService.GetEndYears();
    forkJoin(
      this.lookupService.GetContacts(),
      this.lookupService.GetTimezones()
    ).subscribe(([contactresponse, timezonesresponse]) => {
      this.timeZones = timezonesresponse.data;
      let scheduleDataJSON =
        this.campaignMessageScheduleDataService.scheduleDataJSON;
      let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
      let ScheduleSubtype =
        this.campaignMessageScheduleDataService.scheduleSubtype;
      this.contacts = contactresponse.data;
      let contactEventId = this.contacts[0].contactEventId;
      this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
      if (contactEventId && this.userTimeZoneId) {
        let defaultObject =
          this.calendarAndTimeZoneService.getDefaultDateTimeObject(
            this.userTimeZoneId,
            this.timeZones
          );
        let defaultData = {
          hour: defaultObject.defaultHour,
          minute: defaultObject.defaultMinute,
          meridiem:
            this.messageType == 2 && this.allowTCPAForTextMessages
              ? this.calendarAndTimeZoneService.getMeridiem(
                  defaultObject.defaultHour
                )
              : defaultObject.defaultMeridiem,
          timeZoneId: defaultObject.defaultTimeZoneId,
          startYear: this.todayDate.getFullYear(),
          endingYear: 0,
          yearOption: 'NEVER',
          txtOccurrence: 1,
          contactEventId: contactEventId,
        };
        let savedObject = new RepeatingYearlyContactData();
        if (
          scheduleDataJSON &&
          scheduleDataJSON != 'No Schedule' &&
          scheduleType == 'Repeating' &&
          ScheduleSubtype == 'YearlyContact'
        ) {
          savedObject = JSON.parse(scheduleDataJSON)[0];
          if (savedObject) {
            let dateTimeHelper = new DateTimeHelper();
            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              defaultData.hour = parseInt(time.hours);
              defaultData.minute = parseInt(time.minutes);
              defaultData.meridiem = time.AmPM;
            }
            defaultData.timeZoneId = savedObject.timezoneId;
            defaultData.contactEventId = savedObject.contactEventId;
            defaultData.startYear = parseInt(savedObject.startingYear);

            defaultData.startYear = parseInt(savedObject.startingYear);
            defaultData.endingYear = parseInt(savedObject.endingYear);
            // defaultData.yearOption = parseInt(savedObject.endingAfterNoccurrences) > 0 ? 'AFTER' : 'NEVER';
            if (savedObject.endingYear) {
              defaultData.yearOption = 'AFTER';
              defaultData.txtOccurrence =
                defaultData.endingYear == defaultData.startYear
                  ? 1
                  : defaultData.endingYear - defaultData.startYear + 1;
            }
          }
        }

        this.createForm(defaultData);
      }
    });
  }
  createForm(defaultData) {
    this.contactForm = this.formBuilder.group({
      hour: [defaultData.hour],
      minute: [defaultData.minute],
      meridiem: [defaultData.meridiem],
      timeZone: [defaultData.timeZoneId, Validators.required],
      startYear: [defaultData.startYear],
      yearOption: [defaultData.yearOption],
      txtOccurrence: [
        defaultData.txtOccurrence,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          this.calendarAndTimeZoneService.occurrenceRangeValidator,
        ],
      ],
      contact: [defaultData.contactEventId, Validators.required],
    });

    this.contactForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;

      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.fillFormData();
    }
  }
  fillFormData() {
    this.repeatingYearlyContactData.campaignMessageId = '';
    this.repeatingYearlyContactData.contactEventId =
      this.contactForm.value.contact;
    let selectedContact = this.contacts.find(
      (x) => x.contactEventId.toLowerCase() === this.contactForm.value.contact
    );
    this.repeatingYearlyContactData.scheduleJson = selectedContact.scheduleJSON;
    this.repeatingYearlyContactData.timezoneId =
      this.contactForm.value.timeZone;
    this.repeatingYearlyContactData.timezoneInfoId = this.timeZones.find(
      (x) =>
        x.timezoneId.toLowerCase() ===
        this.repeatingYearlyContactData.timezoneId
    )?.timezoneInfoId;
    let hr = Number(this.contactForm.value.hour);
    let mi = Number(this.contactForm.value.minute);
    let mer = this.contactForm.value.meridiem;
    this.repeatingYearlyContactData.time =
      this.calendarAndTimeZoneService.getTimeSpan(hr, mi, mer);
    this.repeatingYearlyContactData.startingYear =
      this.contactForm.value.startYear.toString();
    this.repeatingYearlyContactData.dateOption =
      this.contactForm.value.yearOption;
    if (this.contactForm.value.yearOption == 'NEVER') {
      this.isAfter = false;
      let calculateYear =
        Number(this.repeatingYearlyContactData.startingYear) + 9;
      this.repeatingYearlyContactData.endingYear = calculateYear.toString();
    } else {
      this.isAfter = true;
      let calculateYear =
        Number(this.repeatingYearlyContactData.startingYear) +
        Number(this.contactForm.value.txtOccurrence) -
        1;
      this.repeatingYearlyContactData.endingYear = calculateYear.toString();
    }
    this.doEmit();
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.contactForm.valid;
    this.CMSComponentData.componentValueJSON = this.repeatingYearlyContactData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
  OnOptionChange(event) {
    let selectedValue = this.contactForm.value.yearOption;
    if (selectedValue == 'NEVER') {
      this.contactForm.patchValue({
        txtOccurrence: '1',
      });
    }
  }
  OnHourChange(event) {
     if (this.messageType == 2 && this.allowTCPAForTextMessages) {
       let selectedHour = this.contactForm.value.hour;
       this.contactForm.patchValue({
         meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
       });
     }
  }
}
