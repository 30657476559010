import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestResponse } from 'src/models/RequestResponse'
import { PagingData } from 'src/models/PagingData';
import { AppSettings } from 'src/appSettings';
import { ImageLibrary, ImageLibraryCollection } from '../models/ImageLibrary';
import { UserToken } from 'src/models/UserToken';
declare var ExternalImageLibrary: any;
import { ExternalImageLibrary } from 'src/scripts/imageLibrary';


@Injectable()
export class ImageLibraryService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves all necessary data for calling component.
   *
   * @param requestResponse - optional in oder to specify specific service information.
   */
  async getLibraryImages(
    requestResponse?: RequestResponse,
    onlyPublished: boolean = false
  ) {
    // if we were not passed a RequestResponse object, use the default.
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      searchValue: requestResponse.data?.length > 0 ? requestResponse.data : '',
      orderBy: requestResponse.paging.orderBy,
      onlyPublished: onlyPublished,
    };

    console.log(
      "Calling the default /POST WebApi 'GetLibraryImages' Controller action."
    );

    return await this.http
      .post<RequestResponse>(
        AppSettings.API_GET_IMAGE_LIBRARY + 'list',
        searchModel
      )
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  async getLibraryImagesByFilter(
    requestResponse?: RequestResponse,
    onlyPublished: boolean = false
  ) {
    // if we were not passed a RequestResponse object, use the default.
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      filterJSON:requestResponse.searchInfo,
      searchValue: requestResponse.data?.length > 0 ? requestResponse.data : '',
      orderBy: requestResponse.paging.orderBy,
      onlyPublished: onlyPublished,
    };

    console.log(
      "Calling the default /POST WebApi 'GetLibraryImages' Controller action."
    );

    return await this.http
      .post<RequestResponse>(
        AppSettings.API_GET_IMAGE_LIBRARY + 'filterList',
        searchModel
      )
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Saves a ImageLibrary record.
   *
   * @param requestResponse
   */
  async SaveLibraryImage(requestResponse?: any) {
    ExternalImageLibrary.thumbnails = undefined;

    console.info(
      'Call /SaveLibraryImage in the webapi imageLibrary Controller'
    );
    return await this.http
      .post<RequestResponse>(AppSettings.API_GET_IMAGE_LIBRARY, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Edit ImageLibrary record.
   *
   * @param requestResponse
   */
  async EditLibraryImage(requestResponse?: any) {
    ExternalImageLibrary.thumbnails = undefined;
    console.info(
      'Call /EditLibraryImage in the webapi imageLibrary Controller'
    );
    return await this.http
      .put<RequestResponse>(AppSettings.API_GET_IMAGE_LIBRARY, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Deletes a ImageLibrary record.
   *
   * @param imageId
   */
  async DeleteLibraryImage(imageId: string) {
    ExternalImageLibrary.thumbnails = undefined;
    console.info(
      'Call /DeleteLibraryImage in the webapi imageLibrary Controller'
    );
    return await this.http
      .delete<any>(AppSettings.API_GET_IMAGE_LIBRARY + imageId)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Get TinyUrl for ImageLibrary record.
   *
   * @param imageId
   */
  async GetLibraryImageTinyUrl(imageId: string) {
    console.info(
      'Call /GetLibraryImageTinyUrl in the webapi imageLibrary Controller'
    );
    return await this.http
      .get<RequestResponse>(
        AppSettings.API_GET_IMAGE_LIBRARY + 'previewTinyUrl?imageId=' + imageId
      )
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Set a Publish record.
   *
   * @param imageId
   * @param title
   */
  setPublished(imageId: string, published: boolean) {
    ExternalImageLibrary.thumbnails = undefined;
    let publishModel = {
      imageId: imageId,
      published: published,
    };
    console.info('Call /Publish in the webapi image Controller');
    return this.http
      .post<any>(AppSettings.API_GET_IMAGE_LIBRARY + 'Publish', publishModel)
      .pipe(catchError(this.handleError));
  }
  /**
   * Error processing.
   *
   * @param caught The caught error information.
   */
  private handleError(caught: any) {
    if (caught.error && caught.error.message) {
      console.error(caught.error.message);
      // throw error back to the calling method.
      return throwError(caught.error.message);
    } else {
      console.error(caught);
      // throw error back to the calling method.
      return throwError(caught);
    }
  }

  toCollectionInstance(data: any[], userToken: UserToken) {
    let result: ImageLibraryCollection = new ImageLibraryCollection();
    for (let x = 0; x < data.length; x++) {
      let item = this.toInstance(data[x], userToken);
      result.Items.push(item);
    }
    return result;
  }

  toInstance(data: any, userToken: UserToken) {
    let result = new ImageLibrary(
      data.tenantId,
      data.imageId,
      data.title,
      null,
      data.width,
      data.height,
      data.avatar,
      data.ownerUserId,
      data.ownerName,
      data.published,
      data.additionalUsers,
      data.additionalTeams,
      data.imageType,
      data.imageLength,
      data.imageSize,
      data.createdOnUtc,
      data.modifiedOnUtc,
      data.modifiedBy,
      data.imageURL,
      data.ownerRoleId,
      data.isPublishingDb
    );

    let canEdit = false;
    //let canDuplicate = false;
    let canDelete = false;

    if (result.TenantId == userToken.TenantId) {
      if (userToken.isCompanyAdmin() || userToken.isFocusITUser()) {
        canEdit = true;
        canDelete = true;
      } else if (userToken.isMarketingAdmin()) {
        if (result.OwnerRoleId == 'COMPANYMARKETINGADMIN') {
          canEdit = true;
          canDelete = true;
        }
      } else if (userToken.isCompanyUserWithMarketing()) {
        if (result.Owner == userToken.TenantUserId) {
          canEdit = true;
          canDelete = true;
        }
      }
    }
    result.CanDelete = canDelete;
    result.CanEdit = canEdit;

    return result;
  }
}
