import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { Roles } from './auth/roles';

@Injectable({
  providedIn: 'root'
})
export class IronCladGuard implements CanActivate {
  storageHelper: StorageHelper;
  user: UserToken;
  constructor(private userTokenService: UserTokenService, private router: Router) {
    this.storageHelper = new StorageHelper();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (environment.clientId === 'AduvoAdmin') {
      return true;
    }
    var ironclad = this.storageHelper.GetDataFromStorage("IronClad");
    if (ironclad && ironclad.value === "true")
      return true;

    //check if user has accepted then set the storage, else send user to accept the agreement 

    this.user = this.userTokenService.getUser();
    if (this.user && this.user.email) {
      //if impersonated
      if (this.user.original_tenantuserId)
        return true;
      var groupKey = this.user.role === Roles.CompanyAdmin ? "embedded-nhlag2i2a" : "embedded-c6xenovks";
      return this.checkPactSafeAPI(this.user.email, groupKey).then(accepted => {
        if (accepted) {
          this.storageHelper.SetDataInStorage("IronClad", accepted ? "true" : "false", 100);
          return true;
        } else {
          this.router.navigateByUrl('/clickwrap');
          return false;
        }
      });
    }
    return false;
  }

  checkPactSafeAPI(signerIdValue, groupKey) {
    var url = 'https://pactsafe.io/latest?sid=' +
      environment.ironcladSiteId +
      '&gkey=' + groupKey +
      '&sig=' + signerIdValue;

    return fetch(url)
      .then(response => response.json())
      .then(data => {
        //any of the contract is accepted
        var accepted = !(Object.values(data).includes(false));

        return accepted;

        /*
          The response may look like the following:
          {
            92928: false,
            92931: true
          }
          Knowing that a contract hasn't been accepted, we can then
          appropriately handle the next step, which may be
          sending the user to another page to accept the contract.
        */
      })
      .catch(err => {
        console.log(err);
        return false;
      });

  }
}
