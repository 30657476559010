<div id="campaignDescriptionDialogDiv" class="content" role="main">
  <br/>
  <table width="100%">
    <tr width="100%"><td style="font-size: 1.1em; font-weight: 500;">Name&nbsp;<font style="font-size: .75em; font-weight:400;"><i>For internal use only</i></font></td></tr>
    <tr><td><input #txtCampaignName id="txtName" pInputText type="text" [(ngModel)]="campaign.name" maxlength="100" style="width:100% !important;" [ngClass]="campaign.name ==''? 'field-error':''"></td></tr>
    <tr><td *ngIf="campaign.name ==''"><span class="valiadtion-error">*Campaign name is required</span></td></tr>
    <tr><td>&nbsp;</td></tr>
    <tr><td style="font-size: 1.1em; font-weight: 500;">Description</td></tr>
    <tr><td><textarea #txtCampaignDescription id="txtCampaignDescription" pInputTextarea type="text" [(ngModel)]="campaign.description" rows="6" maxlength="255" style="width:100% !important; resize:none;"></textarea></td></tr>
  </table>
</div> 
