<!--
*************************************************************************************
Page Heading and Buttons
*************************************************************************************
-->
<div id="manageListsDiv" class="content" role="main">
  <!-- removed z-index: 2; from style -->
  <div class="content table-search-row second-header-fixed" style="isolation: isolate">
    <table class="text-wrapper">
      <tr>
        <td>
          <label id="lblPageHeader">{{ reportTitle }}</label>
          <ng-container>
            <button id="btnEditList" type="button" class="p-column-filter-menu-button header_title_campaign"
              (click)="onShowListEditDialog()" *ngIf="predefinedList == 0 && canEditList">
              <span class="fa fa-pencil text-blue pencil"></span>
            </button>
          </ng-container>
          <span class="p-input-icon-left p-ml-auto p-float-label">
            <input [(ngModel)]="searchKeyword" class="search-input rounded-pill p-inputtext" pInputText type="text"
              style="height: 44px !important" (keyup.enter)="onApplyGlobalFilter()" (input)="onGlobalFilterChange()"
              placeholder="Search by keyword" />
          </span>
          <span type="button" pButton pRipple icon="fal fa-search search-fa" pTooltip="Execute Search"
            tooltipPosition="bottom" showDelay="500" (click)="onApplyGlobalFilter()"
            class="p-button-secondary execute-search" style="margin-bottom: 10px; margin-right: 20px">
          </span>
          <!-- <div class="input-group search-field position-relative p-ml-1"></div>  -->
          <span class="showHide">
            <button *ngIf="!isFilterButtonShow" pButton icon="pi pi-angle-down " iconPos="right"
              class="p-button-outlined header-button" label="Show Filters" (click)="onShowButtonClick()"></button>
            <button *ngIf="isFilterButtonShow" pButton icon="pi pi-angle-up " iconPos="right"
              class="p-button-outlined header-button" label="Hide Filters" (click)="onHideButtonClick()"></button>
          </span>
        </td>
        <td align="right">
          <button pButton (click)="SendCampaign()" class="header-button">
            <i class="pi pi-send text-white mx-2"></i><span class="send-campaign-text">Send a Campaign</span>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
<!-- max-width: 110px; -->
<div style="padding-top: 78px">
  <div id="divfilter" [hidden]="!isFilterButtonShow" style="
      background-color: #F4EDFE;">
    <div class="text-wrapper row">
      <!-- removed style="height: 80px" -->
      <div class="col px-0">
        <!-- <people-and-list-filter [PeopleAndLoanFilterCanEditData]="canEditList"
          [PeopleAndLoanFilterEditData]="peopleAndLoanFilterEditData"
          (onFilterCreatedEvent)="onFilterCreatedEvent($event)"></people-and-list-filter>
          <br>
          <hr>
          <br> -->
        <app-people-and-list-advanced-filter [PeopleAndLoanFilterCanEditData]="canEditList"
          [PeopleAndLoanFilterEditData]="peopleAndLoanFilterEditData"
          (onFilterCreatedEvent)="onFilterCreatedEvent($event)"></app-people-and-list-advanced-filter>
      </div>
      <div style="
          position: relative;
          z-index: 0;
          top: 10px;
          margin-top: 10px;
          margin-bottom: 10px;
          text-align: right;
          width: 200px;
          padding-right: 0;
          padding-left: 0;
        ">
        <div class="dropdown">
          <ng-container *ngIf="(!isListChangeSaved && predefinedList == 0); else showOnlyOneOption">
            <button [disabled]="!hasConditionChange"
              class="btn btn-outline-primary dropdown-toggle create-btn rounded-pill font-size-16 mx-2" type="button"
              id="create-new-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <span>Save</span>
            </button>

            <ul class="dropdown-menu" aria-labelledby="create-new-dropdown">
              <li>
                <a class="dropdown-item" style="cursor: pointer" (click)="SaveList()">Save this list</a>
              </li>
              <li>
                <a class="dropdown-item" style="cursor: pointer" (click)="onSaveAsNewListBtnClick()">Save as new
                  list</a>
              </li>
            </ul>
          </ng-container>
          <ng-template #showOnlyOneOption>
            <button [disabled]="!hasConditionChange"
              class="btn btn-outline-primary create-btn rounded-pill font-size-16 mx-2 lh-1" type="button"
              id="create-new-dropdown" data-bs-toggle="dropdown" aria-expanded="false"
              (click)="onSaveAsNewListBtnClick()">
              <span class="secondary-buttons">Save</span>
            </button>
          </ng-template>
          <button (click)="importContactLoans()" label="" styleClass="p-button-secondary"
            class="btn btn-outline-primary create-btn rounded-pill font-size-16 ml-2 lh-1" type="button"
            id="create-new-dropdown" aria-expanded="false"><span class="secondary-buttons">Import</span></button>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-section" *ngIf="predefinedList == 0">
    <div class="text-wrapper d-flex justify-content-between align-items-center" style="height: 50px">
      <span class="tab-section-tab">
        List Contacts
      </span>
      <span *ngIf="predefinedList == 0" class="created-text">
        This list created by {{ ownerName }}
        <span style="position: relative; top: -3px">
          <i class="fa-solid fa-period dot-allign"></i>
        </span>
        Last updated: {{ listUpdatedDate | date : "M/d/yy h:mm:ss a" }}
      </span>
    </div>
  </div>

  <!--
*************************************************************************************
Peoples & Loans Grid
*************************************************************************************
-->
  <div id="loanGrid" class="text-wrapper Hello mb-8">
    <p-table #PeopleLoanTableId [value]="webAPI.data" class="pulseTable" [frozenColumns]="frozenColumns"
      frozenWidth="1px" [columns]="displayedColumns" [rows]="25" [rowsPerPageOptions]="[25, 50, 100]"
      [paginator]="webAPI.paging.totalRecords > webAPI.paging.pageSize" [showCurrentPageReport]="true"
      [totalRecords]="webAPI.paging.totalRecords" [loading]="loadingPage" [lazy]="true"
      (onLazyLoad)="onPageChanged($event)" [scrollable]="false" scrollWidth="100%" scrollHeight="150px;"
      scrollDirection="both" [resizableColumns]="true" [style]="{ width: '100%' }" sortField="name"
      dataKey="masterContactPrimaryId" (onRowExpand)="onRowExpand()" (onRowCollapse)="onRowCollapse()"
      [(first)]="pageIndex">
      <ng-template pTemplate="caption">
        <p-multiSelect [options]="ColumnsList" [(ngModel)]="selectedColumns" optionLabel="header"
          class="groundSelect column-select" optionValue="field" selectedItemsLabel="{0} items selected"
          [autoZIndex]="false" [baseZIndex]="10" [autoWidth]="false" placeholder="Choose Columns"
          defaultLabel="Show / hide columns" resetFilterOnHide="true" (onChange)="onColumnSelectionChange($event)"
          [style]="{
            'border-radius': '25px',
            color: 'grey',
            'padding-left': '10px',
            'min-width': '170px'
          }"></p-multiSelect>

      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns;let i = index" pSortableColumn="{{ col.isSortable ? col.header : '' }}">
            <ng-container *ngIf="i==0; else elseDiv2">
              <b id="span{{i}}" class="hover-purple">{{ col.header }}</b>
              <p-sortIcon class="column-sort-icon" *ngIf="col.isSortable" id="spani{{i}}" field="{{ col.header }}"
                [style]="{ color: 'grey' }"></p-sortIcon>
            </ng-container>
            <ng-template #elseDiv2>
              <b id="span{{i}}" class="hover-purple">{{ col.header }}</b>
              <p-sortIcon *ngIf="col.isSortable" class="column-sort-icon" id="spani{{i}}" field="{{ col.header }}"
                [style]="{ color: 'grey' }"></p-sortIcon>
            </ng-template>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
        <tr>
          <td *ngFor="let col of columns;let i = index">
            <ng-template [ngIf]="col.field === 'dateOfBirth'" [ngIfElse]="noDate">
              <span id="span{{i}}">{{ row[col.field] | date : "MM/dd" }}</span>
            </ng-template>
            <ng-template #noDate>
              <ng-container *ngIf="i==0; else elseDiv1">
                <span class="cursor-pointer aduvo-purple-text font-weight-normal" id="span{{i}}"
                  [routerLink]="['/people-and-loans/person', row.masterContactId]" *ngIf="col.field != 'loanCount'"
                  title=" {{ row[col.field] }} ">
                  {{ row[col.field]}}
                </span>
              </ng-container>
              <ng-template #elseDiv1>
                <span id="span{{i}}" *ngIf="col.field != 'loanCount'">
                  {{ row[col.field] }}
                </span>
                <div id="span{{i}}">
                  <button *ngIf="col.field === 'loanCount' && row[col.field] > 0"
                    class="btn btn-outline-primary dropdown-toggle create-btn rounded-pill font-size-16" type="button"
                    id="create-new-dropdown " [pRowToggler]="row" (click)="rowExpand(row, rowIndex)"
                    [style]="{ width: '60px' }">
                    <span> {{ row[col.field] }}</span>
                  </button>
                  <span [style]="{ 'width' : '60px', 'padding-left' : '46px' }"
                    *ngIf="col.field === 'loanCount' && row[col.field] < 1"> {{ row[col.field] }}</span>
                </div>
              </ng-template>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-row let-columns="columns">
        <tr>
          <td [attr.colspan]="columns.length + 1">
            <div class="pl-4" *ngIf="row.loanList[0].loanNumber" style="border-radius: 50%;padding-left:2rem;">
              <div *ngFor="let record of row.loanList"
                style="background: #f5f5f5; margin-bottom: 15px;border-top-right-radius: 3px;border-bottom-right-radius: 3px;">
                <table style="width: 100%" id="{{ record.loanId }}" class="loan loan-table">
                  <tr>
                    <td rowspan="5" class="table-column-text">
                      <span class="p-auto">LOAN</span>
                    </td>
                    <td rowspan="5">&nbsp;</td>
                    <td rowspan="5">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td class="fw-bold" style="color: #474747; width: 22%">
                      Loan Number:
                      <span class="fw-normal">{{ record.loanNumber }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747; width: 22%">
                      Buyer's Agent:
                      <span class="fw-normal">{{ record.buyersAgents }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747; width: 20%">
                      Loan Amount:
                      <span class="fw-normal">{{
                        record.loanAmount
                        | currency : "USD" : "symbol" : "2.0-0"
                        }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747; width: 36%">
                      Type: <span class="fw-normal">{{ record.type }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-bold" style="color: #474747">
                      Borrower:
                      <span class="fw-normal">{{ record.borrower }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747">
                      Seller's Agent:
                      <span class="fw-normal">{{ record.sellersAgent }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747">
                      Interest Rate:
                      <span class="fw-normal">{{ record.noteRate | number : "1.3-3" }}%</span>
                    </td>
                    <td class="fw-bold" style="color: #474747">
                      Purpose:
                      <span class="fw-normal">{{ record.purpose }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="fw-bold" style="color: #474747">
                      Co-Borrower:
                      <span class="fw-normal">{{ record.coBorrower }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747">
                      Loan Officer:
                      <span class="fw-normal">{{ record.loanOfficer }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747">
                      Status:
                      <span class="fw-normal">{{ record.loanStatus }}</span>
                    </td>
                    <td class="fw-bold" style="color: #474747">
                      Subject Property:
                      <span class="fw-normal">{{
                        record.subjectPropertyStreet
                        }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </div>
            </div>

            <div *ngIf="row.loanList[0].loanNumber == null && isExpanded == true" style="text-align: center">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft" let-state>
        <label style="font-weight: 400">{{ webAPI.paging.totalRecords | number }}&nbsp;results</label>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <div>
          <label style="margin-top: 1px; margin-right: 8px; font-weight: 400">Go to Page</label>
          <input type="text" pInputText pKeyFilter="int" class="p-inputtext" [(ngModel)]="webAPI.paging.currentPage"
            width="3" maxlength="3" style="
              text-align: center;
              width: 4rem;
              margin-right: 8px;
              border-radius: 20px;
            " (change)="onGotoPage($event)" />
        </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<!--
*************************************************************************************
List  Add/Edit Dialog
*************************************************************************************
-->
<people-loan-list-edit-dialog #editListDialog [display]="showListEditDialog" (onCloseDialog)="onCloseListEditDialog()"
  [selectedList]="selectedListClone" (onSaveListClick)="onSaveListClick()" [title]="dialogListEditTitle"
  [edited]="isEdit">
</people-loan-list-edit-dialog>



<p-dialog class="p1001" #importDialog [(visible)]="showImportDialog" [resizable]="false" [modal]="true"
  [showHeader]="false" [style]="{ 'max-width': '90%', width: '50em', 'font-weight': '700' }" [baseZIndex]="10000"
  (onHide)="closeImportDialog()">
  <app-contact-and-loan-import (onCloseEvent)="closeMe()" [selectedList]="selectedListClone"
    *ngIf="showImportDialog"></app-contact-and-loan-import>
</p-dialog>