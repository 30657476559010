import { DateTimeKind } from './enumerations';

/**
 * Implementation of the Common.Data.Contracts.Datetime [DataContract].
 */
export class Datetime {
  Kind: DateTimeKind = DateTimeKind.Utc;
  IsoString: string = '1000-01-01T00:00:00.0Z';

  /**
   * Constructor
   * 
   * @param kind
   * @param isoString
   */
  constructor(kind?: number, isoString?: string) {
    if ((kind !== undefined) && (kind !== null)) this.Kind = kind;
    if ((isoString !== undefined) && (isoString !== null)) this.IsoString = isoString;

  }

  /**
   * Sets all properties to their default values.
   */
  clear() {
    this.Kind = DateTimeKind.Utc;
    this.IsoString = '1000-01-01T00:00:00.0Z';
  }



  /**
   * Gets the year (1000 thru 9999)
   */
  get Year() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCFullYear();
    return this.Date.getFullYear();
  }

  /**
   * Gets the month (1 thru 12)
   */
  get Month() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCMonth() + 1;
    return this.Date.getMonth() + 1;
  }

  /**
   * Gets the month (0 thru 10)
   */
  get month() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCMonth();
    return this.Date.getMonth();
  }

  /**
   * Gets the day (1 thru days in the month)
   */
  get Day() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCDate();
    return this.Date.getDate();
  }

  /**
   * Gets the hour (0 thru 23)
   */
  get Hour() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCHours();
    return this.Date.getHours();
  }

  /**
   * Gets the minute (0 thru 59)
   */
  get Minute() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCMinutes();
    return this.Date.getMinutes();
  }

  /**
   * Gets the second (0 thru 59)
   */
  get Second() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCSeconds();
    return this.Date.getSeconds();
  }

  /**
   * Gets the millisecond (0 thru 999)
   */
  get Millisecond() {
    if (this.Kind == DateTimeKind.Utc)
      return this.Date.getUTCMilliseconds();
    return this.Date.getMilliseconds();
  }

  /**
   * Gets a value indicating that the year is a Leap Year.
   */
  get isLeapYear() {

    let dateTime = this.Date;
    let year = dateTime.getUTCFullYear();
    if (this.Kind != DateTimeKind.Utc)
      year = dateTime.getFullYear();

    // A leap year is:
    // any year evenly divisible by 4
    // AND
    // NOT evenly divisible by 100 unless evenly divisible by 400

    // ALL years evenly divisible by 400
    if ((year % 400) == 0) {
      return true;
    }

    // evenly divisible by 4 and NOT evenly divisible by 100
    if (((year % 4) == 0) && ((year % 100) != 0)) {
      return true;
    }

    return false;
  }

  /**
   * Gets a value indicating that the datetime Kind is Unspecified.
   * (treated as a local time)
   */
  get isUnspecified() {
    return ((this.Kind != DateTimeKind.Utc) && (this.Kind != DateTimeKind.Local));
  }

  /**
   * Gets a value indicating that the datetime Kind is UTC.
   */
  get isUTC() {
    return (this.Kind == DateTimeKind.Utc);
  }

  /**
   * Gets a value indicating that the datetime Kind is Local.
   */
  get isLocal() {
    return (this.Kind == DateTimeKind.Local);
  }

  /**
   * Gets the Javascript Date.
   */
  get Date() {
    return this.toDate();
  }

  /**
   * Gets the Javascript Date.
   */
  private toDate() {
    let dateTime = new Date(Date.parse(this.IsoString));
    return dateTime;
  }

  /**
   * Gets the Javascript Date ticks.
   */
  get ticks() {
    return Date.parse(this.IsoString);
  }

  /**
   * Gets .NET DateTime ticks.
   */
  get netTicks() {
    let dateTime = this.Date;
    let ticks = dateTime.valueOf();
      ticks += 62135596800000;
    ticks *= 10000;
    return (ticks);
  }

  /**
   * Gets the date part string representation in the format 'MM/DD/YYYY'.
   * Like '9/8/2021'.
   */
  get shortDateString() {
    let dateTime = this.Date;
    return dateTime.getMonth() + 1 + '/' + dateTime.getDate() + '/' + dateTime.getFullYear();
  }

  /**
   * Gets the Time part string representation in the format 'HH:MM:SS'.
   * Like '2:42:43 PM'.
   */
  get shortTimeString() {
    let dateTime = this.Date;
    let minute = ((dateTime.getMinutes() < 10) ? ('0' + dateTime.getMinutes()) : dateTime.getMinutes())
    let second = ((dateTime.getSeconds() < 10) ? ('0' + dateTime.getSeconds()) : dateTime.getSeconds())
    if (dateTime.getHours() === 0)
      return '12:' + minute + ':' + second + ' AM';
    else if (dateTime.getHours() < 12)
      return dateTime.getHours() + ':' + minute + ':' + second + ' AM';
    else if (dateTime.getHours() === 12)
      return '12:' + minute + ':' + second + ' PM';
    else if (dateTime.getHours() > 12)
      return (dateTime.getHours() - 12) + ':' + minute + ':' + second + ' PM';
  }

  /**
   * Gets the Local Date and Time string representation in the format 'MM/DD/YYYY HH:MM:SS.MS' (24 hour time format).
   * Like '08/03/2021 14:42:43.026'.
   */
  get logString() {
    let dateTime = this.Date;
    let month = (dateTime.getMonth() + 1);
    let date = ((month < 10) ? ('0' + month) : month) + '/' +
      ((dateTime.getDate() < 10) ? ('0' + dateTime.getDate()) : dateTime.getDate()) + '/' +
      dateTime.getFullYear();
    let hour = ((dateTime.getHours() < 10) ? ('0' + dateTime.getHours()) : dateTime.getHours())
    let minute = ((dateTime.getMinutes() < 10) ? ('0' + dateTime.getMinutes()) : dateTime.getMinutes())
    let second = ((dateTime.getSeconds() < 10) ? ('0' + dateTime.getSeconds()) : dateTime.getSeconds())
    return date + ' ' + hour + ':' + minute + ':' + second + '.' + dateTime.getMilliseconds().toString().padStart(3, "0");
  }

  /**
   * Gets the UTC Date and Time string representation in the format 'MM/DD/YYYY HH:MM:SS.MS' (24 hour time format).
   * Like '08/03/2021 18:42:43.026'.
   */
  get logStringUTC() {
    let dateTime = this.Date;
    let month = (dateTime.getUTCMonth() + 1);
    let date = ((month < 10) ? ('0' + month) : month) + '/' +
      ((dateTime.getUTCDate() < 10) ? ('0' + dateTime.getUTCDate()) : dateTime.getUTCDate()) + '/' +
      dateTime.getUTCFullYear();
    let hour = ((dateTime.getUTCHours() < 10) ? ('0' + dateTime.getUTCHours()) : dateTime.getUTCHours())
    let minute = ((dateTime.getUTCMinutes() < 10) ? ('0' + dateTime.getUTCMinutes()) : dateTime.getUTCMinutes())
    let second = ((dateTime.getUTCSeconds() < 10) ? ('0' + dateTime.getUTCSeconds()) : dateTime.getUTCSeconds())
    return date + ' ' + hour + ':' + minute + ':' + second + '.' + dateTime.getUTCMilliseconds().toString().padStart(3, "0");
  }

  /**
   * Gets the string representation in the format 'WkDy Mo DD YYYY HH:MM:SS GMT-TimeZoneOffset (TimeZone)'.
   * Like 'Wed Sep 08 2021 14:42:43 GMT-0400 (Eastern Daylight Time)'.
   * 
   * @returns String representation in the format 'WkDy Mo DD YYYY HH:MM:SS GMT-TimeZoneOffset (TimeZone)'.
   */
  toString() {
    //return new Date(this.JsTicks).toString();
    return new Date(this.Date.valueOf()).toString();
  }

  /**
   * Gets the Locale string representation in the format 'MM/DD/YYYY, HH:MM:SS [AM | PM]'.
   * Like '9/8/2021, 2:42:43 PM'.
   * 
   * @returns String representation in the format 'MM/DD/YYYY, HH:MM:SS [AM | PM]'.
   */
  get LocaleString() {
    return this.toLocaleString();
  }

  /**
   * Gets the Locale string representation in the format 'MM/DD/YYYY, HH:MM:SS [AM | PM]'.
   * Like '9/8/2021, 2:42:43 PM'.
   *
   * @returns String representation in the format 'MM/DD/YYYY, HH:MM:SS [AM | PM]'.
   */
  toLocaleString() {
    return this.Date.toLocaleString();
  }

  /**
   * Gets the UTC string representation in the format 'Dy, DD Mo YYYY HH:MM:SS GMT'.
   * Like 'Wed, 08 Sep 2021 18:42:43 GMT'.
   *
   * @returns String representation in the format 'Dy, DD Mo YYYY HH:MM:SS GMT'.
   */
  get UTCString() {
    return this.toUTCString();
  }

  /**
   * Gets the UTC string representation in the format 'Dy, DD Mo YYYY HH:MM:SS GMT'.
   * Like 'Wed, 08 Sep 2021 18:42:43 GMT'.
   *
   * @returns String representation in the format 'Dy, DD Mo YYYY HH:MM:SS GMT'.
   */
  toUTCString() {
    return this.Date.toUTCString();
  }

  /**
   * Gets the string representation in the format 'YYYY-MM-DDTHH:MM:SS.MSZ'.
   * Like '2021-09-08T18:42:43.026Z'.
   * 
   * @returns String representation in the format 'YYYY-MM-DDTHH:MM:SS.MSZ'.
   */
  get ISOString() {
    return this.toISOString();
  }

  /**
   * Gets the string representation in the format 'YYYY-MM-DDTHH:MM:SS.MSZ'.
   * Like '2021-09-08T18:42:43.026Z'.
   *
   * @returns String representation in the format 'YYYY-MM-DDTHH:MM:SS.MSZ'.
   */
  toISOString() {
    return this.Date.toISOString();
  }

  /**
   * Assigns the values from the specified JSON string to this object.
   */
  fromJSON(jsonString) {

    this.clear();
    if ((jsonString === undefined) || (jsonString === null))
      return;
    let objectToCopy = JSON.parse(jsonString);

    this.Kind = objectToCopy.Kind;
    this.IsoString = objectToCopy.IsoString;
  }

  /**
   * Copies (assigns) the values from the specified object to this object.
   * 
   * @param objectToCopy Source object for property values.
   */
  copy(objectToCopy) {
    if ((objectToCopy === undefined) || (objectToCopy === null))
      return;

    this.fromJSON(JSON.stringify(objectToCopy));
    return;
  }

  /**
   * Assigns the values from the specified javascript Date object to this object.
   *
   * @param date A javscript Date object.
   * @param kind The date time kind (1 is UTC, 2 is Local, Undefined (unknown) otherwise.
   */
  fromJsDate(date: Date, kind: DateTimeKind) {
    this.Kind = kind;
    this.IsoString = date.toISOString();
  }

  /**
   * Returns a clone (copy) of this object.
   * 
   * @returns A copy of this object.
   */
  clone() {

    let result = new Datetime(
      this.Kind,
      this.IsoString);

    return result;
  }

  /**
   * Copies (assigns) the values from specified hex encoded JSON string to this object.
   */
  fromJSONHex(jsonHex) {
    let decoded = '';
    if ((jsonHex === undefined) || (jsonHex === null))
      return decoded;

    this.clear();

    let temp = jsonHex.toString(); //force conversion to string
    for (let i = 0; i < temp.length; i += 2)
      decoded += String.fromCharCode(parseInt(temp.substr(i, 2), 16));

    this.fromJSON(decoded);
  }

  /**
   * Returns the Hex encoded JSON string for the object's JSON string.
   *
   * @returns String containing the Hex encoded JSON.
   */
  toJSONHex() {
    let jsonHex = '';
    let json = JSON.stringify(this);
    if ((json === undefined) || (json === null))
      return jsonHex;
    let temp = json.toString(); //force conversion to string
    for (let i = 0; i < temp.length; i++)
      jsonHex += temp.charCodeAt(i).toString(16);

    return jsonHex;
  }

  /**
   * Current Local time.
   */
  static get now() {
    let temp = new Date(Date.now());

    let result = new Datetime(
      DateTimeKind.Local,
      temp.toISOString()
    );

    return result;
  }

  /**
   * Current UTC time.
   */
  static get utcNow() {
    let temp = new Date(Date.now());

    let result = new Datetime(
      DateTimeKind.Utc,
      temp.toISOString()
    );

    return result;
  }
}

