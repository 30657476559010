import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { Roles } from '../auth/roles';
import { BillingService } from 'src/services/billingService';

declare var _ps: any;

@Component({
  selector: 'app-click-wrap',
  templateUrl: './click-wrap.component.html',
  styleUrls: ['./click-wrap.component.scss']
})
export class ClickWrapComponent implements OnInit {
  storageHelper: StorageHelper;
  user: UserToken;
  loadingPage: boolean;
  onboarded: boolean;

  constructor(private userTokenService: UserTokenService,
    private billingService: BillingService,
    private router: Router) {
    this.storageHelper = new StorageHelper();
    // // Invoke snippet, if it's not already created
    // if (window['_ps'] == null || window['_ps'] == undefined) {
    //   (function (w, d, s, c, n, a, b) {
    //     w['PactSafeObject'] = n;
    //     w[n] = w[n] || function () {
    //         (w[n].q = w[n].q || []).push(arguments);
    //       }, w[n].on = function () {
    //       (w[n].e = w[n].e || []).push(arguments);
    //     }, w[n].once = function () {
    //       (w[n].eo = w[n].eo || []).push(arguments);
    //     }, w[n].off = function () {
    //       (w[n].o = w[n].o || []).push(arguments);
    //     }, w[n].t = (new Date()).getDate();
    //     a = d.createElement(s);
    //     b = d.getElementsByTagName(s)[0];
    //     a.async = 1;
    //     a.src = c;
    //     b.parentNode.insertBefore(a, b);
    //   })(window, document, 'script', '//vault.pactsafe.io/ps.min.js', '_ps');
    // }

    if (window['_ps'] == null || window['_ps'] == undefined) {
      (function (w, d, s, c, f, n, t, g, a, b, l) {
        // Defines the global _ps object and initializes the _ps() function
        // that will queue commands until the Ironclad Clickwrap Library is ready.
        w['PactSafeObject'] = n;
        w[n] = w[n] || function () {
          (w[n].q = w[n].q || []).push(arguments)
        },

          // Defines the event functions for the global _ps object.
          w[n].on = function () {
            (w[n].e = w[n].e || []).push(arguments)
          },
          w[n].once = function () {
            (w[n].eo = w[n].eo || []).push(arguments)
          },
          w[n].off = function () {
            (w[n].o = w[n].o || []).push(arguments)
          },

          // Marks the time that the script is inserted.
          w[n].t = 1 * new Date().getTime(),
          w[n].l = 0;

        // Inserts a new script element to load the Ironclad Clickwrap Library JS file (ps.js).
        a = d.createElement(s);
        b = d.getElementsByTagName(s)[0];
        a.async = 1;
        a.src = c;

        // Marks that the script has started loading or failed to load.
        a.onload = a.onreadystatechange = function () { w[n].l = 1 };
        a.onerror = a.onabort = function () { w[n].l = 0 };
        b.parentNode.insertBefore(a, b);

        // Retry loading the script from a fallback location after 4 seconds.
        setTimeout(function () {
          if (!w[n].l && !w[n].loaded) {
            w[n].error = 1;
            a = d.createElement(s);
            a.async = 1;
            a.src = f;
            a.onload = a.onreadystatechange = function () { w[n].l = 1 };
            a.onerror = a.onabort = function () { w[n].l = 0 };
            b.parentNode.insertBefore(a, b);

            // Log the loading error via beacon.
            l = function (u, e) {
              try {
                e = d.createElement('img');
                e.src = 'https://d3r8bdci515tjv.cloudfront.net/error.gif?t=' + w[n].t + '&u=' + encodeURIComponent(u);
                d.getElementsByTagName('body')[0].appendChild(e);
              }
              catch (x) { }
            };
            l(c);

            // Call the optional error callback function after a second failed attempt.
            setTimeout(function () {
              if (!w[n].l && !w[n].loaded) {
                w[n].error = 1;
                if (g && 'function' == typeof g) {
                  g.call(this);
                }
                l(f);
              }
            }, t);
          }
        }, t);
      })(window, document, 'script', '//vault.pactsafe.io/ps.min.js', '//d3l1mqnl5xpsuc.cloudfront.net/ps.min.js', '_ps', 4000, function optionalErrorCallback() { alert('Unable to load the JS Library.') });

      // Creates a Site object with the default configuration.
      _ps('create', environment.ironcladSiteId);
    }
  }

  ngOnInit(): void {
    this.user = this.userTokenService.getUser();
    this.billingService.GetCompanyAdditionalDetail().subscribe((res) => {
      if (res.data) {
        this.onboarded = res.data.onboarded;
      };
    });

    if (this.user) {
      //if impersonated
      if (this.user.original_tenantuserId)
      {
        this.storageHelper.SetDataInStorage("IronClad", "true", 100);
        this.router.navigateByUrl("dashboard");
      }
      
      var groupKey = this.user.role === Roles.CompanyAdmin ? "embedded-nhlag2i2a" : "embedded-c6xenovks";

      _ps("load", groupKey, { container_selector: "clickwrap-content", signer_id: this.user.email, display_all: false });

      _ps("set", "signer_id", this.user.email);
      _ps("send", "updated", { custom_data: { first_name: this.user.given_name, last_name: this.user.family_name, company_name: this.user.TenantName, lender_id: this.user.TenantId } });

      // Submits the form once the contracts have been accepted.
      _ps.on('valid', function (parameters, group) {
        this.loadingPage = true;
        document.getElementById('loadingDiv').style.visibility = 'visible';
        document.getElementsByTagName("input")[0].disabled = true;
        this.storageHelper.SetDataInStorage("IronClad", "true", 100);
        if (this.user.role === Roles.CompanyAdmin && !this.onboarded) {
          this.router.navigateByUrl("welcome-screen");
        }
        else {
          this.router.navigateByUrl("dashboard");
        }
          
      }.bind(this));

      /******************************
        * Tracking acceptance and getting the contract
        * Acceptance of a Clickwrap Group, by default, is sent when your signer checks a checkbox or clicks the “I Agree” button. To send 
        * acceptance outside of that default workflow, you can follow the below syntax:
        * Note: Sending acceptance like this requires a callback
        *******************************/
      _ps(groupKey, "agreed", {
        // The event_callback function will be invoked once the "send" is complete.
        event_callback: function (err, eventType, clickwrapGroup, payload) {
          if (err) {
            console.log(err);
          }
          this.loadingPage = true;
          document.getElementById('loadingDiv').style.visibility = 'visible';
          document.getElementsByTagName("input")[0].disabled = true;
          // The send is complete and acceptance was captured successfully.
          //debugger;
          this.storageHelper.SetDataInStorage("IronClad", "true", 100);
          if (this.user.role === Roles.CompanyAdmin && !this.onboarded) {
            this.router.navigateByUrl("welcome-screen");
          }
          else {
            this.router.navigateByUrl("dashboard");
          }
        }.bind(this)
      });

      // Enables additional logging to the browser console.
      //_ps.debug = true;

    }

  }
}
