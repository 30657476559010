import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class CommonService {

 constructor(private http: HttpClient) {
 }

 stringToHash(cacheKey: string): string {
  var hash = 0;
  if (cacheKey.length == 0) return hash.toString();

  for (var i = 0; i < cacheKey.length; i++) {
   var char = cacheKey.charCodeAt(i);
   hash = (hash << 5) - hash + char;
   hash = hash & hash;
  }

  return hash.toString();
 }

}