<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:15px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="row p-mt-2 align-items-center" [formGroupName]="i">
      <!-- <div class="col-auto">
        <label style="font-size: 16px; line-height: 16px; color: #2e2e2e;font-weight: 400;">{{
          item.get('label').value }}</label>
      </div> -->
      <div class="col-12 mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" [style]="{'width':'98%'}" formControlName="operandDropDown"
          filterBy="name" [filter]="true" filterBy="OpDisplay"></p-dropdown>
      </div>
      <div class="col-12 mb-2">
        <ng-container *ngIf="selectedOpCode =='NOTEQ' || selectedOpCode =='EQ'">
          {{ item.get('label').value }}
        </ng-container>
      </div>
      <!-- <div class="col-1">
        <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button"
          (click)="deleteRow(i)">
          <i class="pi pi-trash"></i>
        </span>
      </div> -->
    </div>
  </div>
  <!-- <div class="row" style="padding:15px 30px;">
    <div class="col-12 btn-no-radius">
      <p-button (click)="addMore()" icon="pi pi-plus" label=""></p-button>
    </div>
  </div> -->
  <!-- <hr class="m-0"> -->
  <div class="row " style="padding:15px 30px;" >
    <div class="col-12 text-right">
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" 
     label="Apply"></p-button>
    </div>
  </div>
</div>