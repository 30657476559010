// csv.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor() {}

  downloadCSV(data: any[], filename: string, headers?: string[]): void {
    let csv = '';
    if (headers) {
      csv += headers.join(',') + '\n';
    } else {
      // Extract the headers from the first row of data
      csv += Object.keys(data[0]).join(',') + '\n';
    }

    data.forEach((row) => {
      let line = '';
      for (let key in row) {
        if (line !== '') {
          line += ',';
        }
        line += this.escapeSpecialChars(String(row[key]));
      }
      csv += line + '\n';
    });

    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private escapeSpecialChars(str: string): string {
    if (str.includes(',') || str.includes('\n') || str.includes('"') || str.includes('\r')) {
      return '"' + str.replace(/"/g, '""') + '"';
    }
    return str;
  }
}
