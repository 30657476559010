import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter, RhsDropdownItem } from '../../../models/PeopleAndLoanFilterOutput';
import { ToastService } from '../../../services/toast.service';
import { DropDownItem } from '../../../models/TenantUser';
import { PeopleAndLoanService } from '../../../services/peopleandloans.service';
import { CalendarAndTimeZoneService } from '../../services/CalendarAndTimeZoneService';

@Component({
  selector: 'app-people-and-list-advanced-filter-date',
  templateUrl: './people-and-list-filter-date.component.html',
  styleUrls: ['./people-and-list-filter-date.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterDateComponent implements OnInit, OnDestroy {
  public selectedOperand: any | null;
  public textFormGroup: FormGroup | null;
  public items: FormArray | null;

  private sub: Array<Subscription>;
  private selectedFilter: PeopleAndLoanFieldLookup | null;
  private peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput;
  @Input() OperandDropDownList: any | null;
  private tmpPeopleAndLoanFilters: PeopleAndLoanFilterData;
  private peopleAndLoanFilters: PeopleAndLoanFilterData;

  @Input() get SelectedFilter(): any | null {
    return this.selectedFilter;
  }
  set SelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      this.fillAllDropDown();
      this.generateForm();
    }
  }
  @Output() onCreateFilterEvent = new EventEmitter<PeopleAndLoanFilterOutput>();
  @Output() onCancelFilterEvent = new EventEmitter<boolean>();
  //private existingListOrFilters: PeopleLoanListOrFilter[];
  private storageHelper: StorageHelper;
  private editNode: PeopleAndLoanEditTagData | null;

  public disableApplyFilter: boolean;
  public YearList: DropDownItem[];
  public yearRange: string;
  public daysList: DropDownItem[];
  public monthsList: DropDownItem[];
  private insertIndex: number;


  constructor(public formBuilder: FormBuilder, private toastService: ToastService, private datePipe: DatePipe,
    private peopleAndLoanService: PeopleAndLoanService,
    private cdr: ChangeDetectorRef,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService) {
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.sub = new Array<Subscription>();
    this.peopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(false, null);
    //this.existingListOrFilters = [];
    this.storageHelper = new StorageHelper();
    this.disableApplyFilter = true;
    let year = new Date().getFullYear()
    this.yearRange = `${year}:${year}`;
    this.daysList = [];
    this.monthsList = [];
    this.insertIndex = 0;
  }

  ngOnInit(): void {
    this.fillAllDropDown();
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
  }

  private fillAllDropDown() {
    this.getYears();
    this.getDays();
    this.getMonths();
  }

  getYears() {
    this.YearList = [];
    for (var x = 1985; x < 2050; x++) {
      this.YearList.push({ item_id: x.toString(), item_text: x.toString(), isDisabled: false });
    }
  }

  getDays() {
    this.daysList = [];
    for (var x = 1; x <= 31; x++) {
      this.daysList.push(new DropDownItem(x.toString(), ('0' + x.toString()).slice(-2), false));
    }
  }

  getMonths() {
    this.monthsList = [];
    this.monthsList.push(new DropDownItem('1', 'January', false));
    this.monthsList.push(new DropDownItem('2', 'February', false));
    this.monthsList.push(new DropDownItem('3', 'March', false));
    this.monthsList.push(new DropDownItem('4', 'April', false));
    this.monthsList.push(new DropDownItem('5', 'May', false));
    this.monthsList.push(new DropDownItem('6', 'June', false));
    this.monthsList.push(new DropDownItem('7', 'July', false));
    this.monthsList.push(new DropDownItem('8', 'August', false));
    this.monthsList.push(new DropDownItem('9', 'September', false));
    this.monthsList.push(new DropDownItem('10', 'October', false));
    this.monthsList.push(new DropDownItem('11', 'November', false));
    this.monthsList.push(new DropDownItem('12', 'December', false));
  }

  operandChanged(event, index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue1;
      const lookupControl2 = k.controls[index]['controls'].txtRHSValue2;

      lookupControl?.clearValidators();//clear Validators  
      lookupControl2?.clearValidators();//clear Validators  
      lookupControl?.reset();
      lookupControl2?.reset();

      if (event.value.OpCode == 'IS') {
        lookupControl?.setValidators([Validators.required]);//set Validators
        lookupControl2?.setValidators([Validators.required]);//set Validators
      }

      lookupControl?.updateValueAndValidity();//validate right now.
      lookupControl2?.updateValueAndValidity();//validate right now
    }
  }

  
  operandChangedNew(index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  private generateForm() {
    this.unSubscribeAllSubs();

    this.textFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });
    this.cdr.detectChanges();//make sure changes are detected on edit flow.

    if (this.SelectedFilter?.isAddFlag) {
      this.operandChangedNew(0);
    }

    let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();

    this.items = this.formDataCtrl;
    this.sub.push(
      this.textFormGroup.valueChanges.pipe(
        //debounceTime(500),//delay and  stop unnecessary propogation.
        distinctUntilChanged()
      ).subscribe(item => {
        listAndFilters.PeopleLoanListOrFilters = [];//clear all
        let idx = 0;
        listAndFilters.id = idx + 1;
        listAndFilters.peopleAndLoansFieldLookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId;

        //Changed json fetchiing first rrecord value
        if (item?.items?.length) {
          listAndFilters.group = item.items[0].operandDropDown.FieldGroupName;
          listAndFilters.datasource = item.items[0].operandDropDown.DataSource;
          listAndFilters.datacolumn = item.items[0].operandDropDown.DataColumn;
          listAndFilters.type = item.items[0].operandDropDown.DataType;
          // if (item.items[0].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
          //   listAndFilters.subGroup = item.items[0].operandDropDown.FieldSubGroupName;
          // }
        }

        // this.existingListOrFilters = [];
        // let newIndex = -1;
        // let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
        // if (savedFilter?.value) {
        //   let andFilterIndex = -1;
        //   let obj = JSON.parse(JSON.stringify(savedFilter.value));
        //   andFilterIndex = obj?.PeopleLoanList?.PeopleLoanListAndFilters?.length ?? -1;
        //   if (obj && andFilterIndex) {
        //     if (andFilterIndex != -1) {
        //       listAndFilters.id = andFilterIndex + 1;
        //     }
        //     obj.PeopleLoanList.PeopleLoanListAndFilters.forEach(laf => {
        //       if (laf.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
        //         this.existingListOrFilters = laf.PeopleLoanListOrFilters;
        //         return false;//break;
        //       }
        //     });
        //     newIndex = this.existingListOrFilters.length + 1;
        //   }
        // }
        //loop and add/remove validation as per dropdown value.
        item.items.forEach((element, index) => {
          if (index == 0) {
            listAndFilters.PeopleLoanListOrFilters = [];//clear all
          }
          if (item.items[index].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
            listAndFilters.subGroup = item.items[index].operandDropDown.FieldSubGroupName;
          }

          let rhsValueMonth = element?.txtRHSValue1?.item_id ?? '';//month 
          let rhsValueDay = element?.txtRHSValue2?.item_id;//day
          let dateStr = '';
          if (rhsValueMonth && rhsValueDay) {
            dateStr = element?.txtRHSValue1?.item_text + '/' + element?.txtRHSValue2?.item_text;
          }


          // if (element.operandDropDown.OpDisplay == 'month is') {
          //   /// no need as calender has been replaced by dropdown. but keep the if condition to bypass processing
          //   //   rhsValue1 = rhsValue1?.toLocaleString('en-US', {
          //   //     month: 'long',
          //   //   });
          //   //   debugger;
          //   //   //console.log(rhsValue1);
          //   //   //convert month to number to be saved.
          // }
          // else if (element.operandDropDown.OpDisplay == 'year is') {
          //   if (rhsValue1?.toString()?.length > 4) {
          //     rhsValue1 = rhsValue1?.toString()?.substr(0, 4);
          //   }
          // }
          // else {
          //   if (element.operandDropDown.RhsInputControl1 == 'Calendar' && element?.txtRHSValue1) {
          //     let dateStr = this.datePipe.transform(element?.txtRHSValue1, 'yyyy-MM-dd')?.toString();
          //     rhsValue1 = dateStr ?? '';
          //     rhsValue1DateStr = this.datePipe.transform(element?.txtRHSValue1, 'dd-MM-yyyy')?.toString() ?? ''
          //   }
          //   if (element.operandDropDown.RhsInputControl2 == 'Calendar' && element?.txtRHSValue2) {
          //     let dateStr = this.datePipe.transform(element?.txtRHSValue2, 'yyyy-MM-dd')?.toString()
          //     rhsValue2 = dateStr ?? ''
          //     rhsValue2DateStr = this.datePipe.transform(element?.txtRHSValue2, 'dd-MM-yyyy')?.toString() ?? ''
          //   }
          // }

          // rhsValue1DateStr = this.datePipe.transform(element?.txtRHSValue1, 'MM/dd/yyyy')?.toString() ?? '';
          // rhsValue2DateStr = this.datePipe.transform(element?.txtRHSValue2, 'MM/dd/yyyy')?.toString() ?? '';

          let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
          filter.id = (index + 1);
          filter.operator = element.operandDropDown.OpCode;
          //filter.lhs = '';
          filter.lhs = element.operandDropDown.OpCode;
          filter.rhs = [rhsValueMonth, rhsValueDay];
          filter.peopleAndLoansTypeDefLookupId = element.operandDropDown.PeopleAndLoansTypeDefLookupId;

          let criteriaQuery = element.operandDropDown.Criteria;
          if (criteriaQuery) {
            criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
            criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
            criteriaQuery = criteriaQuery.replace('{{rhs1}}', rhsValueMonth || '');
            criteriaQuery = criteriaQuery.replace('{{rhs2}}', rhsValueDay || '');
            filter.criteria = criteriaQuery;
          }

          let htmlTemplate = element.operandDropDown.DisplayHtmlTemplate;
          if (htmlTemplate) {
            htmlTemplate = htmlTemplate.replace('{{lhs}}', element.operandDropDown.DataColumnDisplay);
            htmlTemplate = htmlTemplate.replace('{{rhs1}}', dateStr || '');
            //htmlTemplate = htmlTemplate.replace('{{rhs2}}', rhsValueDay || '');
            let prefix = '';//listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
            filter.displayHtml = prefix + htmlTemplate;
          }
          listAndFilters.PeopleLoanListOrFilters.push(filter)
        });
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
        // if (this.existingListOrFilters?.length) {
        //   this.existingListOrFilters.forEach(filter => {
        //     listAndFilters.PeopleLoanListOrFilters.push(filter)
        //   });
        // }

        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters);

        //validate and show apply button
        if (!this.textFormGroup?.valid) {
          this.disableApplyFilter = true;
        } else {
          this.disableApplyFilter = false;
        }
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');
      })
    );
  }
  applyFilter() {
    if (this.textFormGroup?.valid) {//if json has been received.
      this.validateAndEmitSuccessJson();

    } else {
      this.emitFailureJson();
    }
  }

  private createItems(): FormGroup[] {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //edit filter from input or observable.
    const formGroupArr: FormGroup[] = [];

    //Edit CASE  
    let editNodeTmp = this.storageHelper.GetDataFromStorage('PeopleAndLoan_editNode');//fetch 
    if (editNodeTmp?.value?.peopleAndLoansFieldLookupId &&
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length > 0) {
      this.editNode = editNodeTmp.value;
      let andIndex = this.editNode?.andIndex;
      let lookupId = this.editNode?.peopleAndLoansFieldLookupId;
      //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean

      let toIterate = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.
        filter(x => x.id == andIndex && x.peopleAndLoansFieldLookupId == lookupId);

      let lblValue = editNodeTmp?.value?.labelStr;
      toIterate[0].PeopleLoanListOrFilters.forEach((itm) => {
        // if (!lblValue) {
        //   lblValue = itm.displayHtml.substring(0, itm.displayHtml.indexOf("</b>"))
        //   lblValue = lblValue.replace("<b class=\"lhs\">", "").trim();
        // }
        let selectedDropdown = this.OperandDropDownList.find(x => x.PeopleAndLoansTypeDefLookupId == itm.peopleAndLoansTypeDefLookupId);
        //selectedDropdown = selectedDropdown.sort((a, b) => { return a.OpDisplay.toLowerCase() >= b.OpDisplay.toLowerCase() ? 1 : -1 });

        // let validatorOrOpts = [
        //   Validators.required,
        //   Validators.maxLength(50)
        // ];

        // let rhsValue1: any;
        // let rhsValue2: any;
        // if (itm?.rhs?.length > 0) {
        //   rhsValue1 = itm.rhs[0];
        // }
        // if (itm?.rhs?.length > 1) {
        //   rhsValue2 = itm.rhs[1];
        // }
        // let opDisplay = selectedDropdown.OpDisplay;
        // // if (opDisplay == 'month is') {
        // //   rhsValue1 = new Date(rhsValue1 + "1, 2023");//to only get month //not need as now we are showing dropdown instead of calender.
        // // }
        // // else 
        // if (selectedDropdown.OpCode == 'NOTBLANK' || selectedDropdown.OpCode == 'BLANK') {
        //   //conditional validation
        //   validatorOrOpts = [];//no validation
        // }
        // //else if (opDisplay == 'year is') { }
        // else {
        //   // if (Object.prototype.toString.call(rhsValue1) === '[object Date]') {//checking if function exists 
        //   //   rhsValue1 = new Date(rhsValue1);
        //   // }
        //   // if (Object.prototype.toString.call(rhsValue2) === '[object Date]') {//checking if function exists 
        //   //   rhsValue2 = new Date(rhsValue2);
        //   // }

        //   if (rhsValue1?.split("-")?.length > 2) {//check if it is date.
        //     rhsValue1 = new Date(rhsValue1);
        //   }
        //   if (rhsValue2?.split("-")?.length > 2) {//check if it is date.
        //     rhsValue2 = new Date(rhsValue2);
        //   }

        // }

        let rhsValue1: any;
        let rhsValue2: any;
        let validatorOrOpts: any = [];
        if (selectedDropdown.OpCode == 'IS') {
          validatorOrOpts = [
            Validators.required
          ];
          if (itm?.rhs?.length > 0) {
            rhsValue1 = itm.rhs[0];
            // if (rhsValue1?.split("-")?.length > 2) {//check if it is date.
            //   rhsValue1 = new Date(rhsValue1);
            // }
          }
          if (itm?.rhs?.length > 1) {
            rhsValue2 = itm.rhs[1];
            // if (rhsValue2?.split("-")?.length > 2) {//check if it is date.
            //   rhsValue2 = new Date(rhsValue2);
            // }
          }
        }
        // let rhsSelectedItem1: RhsDropdownItem;
        // let rhsSelectedItem2: RhsDropdownItem;
        let rhsSelectedItem1 = this.monthsList.find(x => x.item_id == rhsValue1);
        let rhsSelectedItem2 = this.daysList.find(x => x.item_id == rhsValue2);


        formGroupArr.push(
          this.formBuilder.group({
            label: new FormControl(lblValue),
            operandDropDown: new FormControl(selectedDropdown, [Validators.required]),
            txtRHSValue1: new FormControl(rhsSelectedItem1, validatorOrOpts),//month
            txtRHSValue2: new FormControl(rhsSelectedItem2, validatorOrOpts)//day
          })
        );
      });
    }
    else {
      let selectedDropdown = null;
      if (this.SelectedFilter?.isAddFlag) {//insert as blank
        selectedDropdown = this.OperandDropDownList.find(x => x.OpCode == 'BLANK');
      }

      formGroupArr.push(
        this.formBuilder.group({
          label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
          operandDropDown: new FormControl(selectedDropdown, [Validators.required]),
          txtRHSValue1: new FormControl('',),
          txtRHSValue2: new FormControl('',)
        })
      );
    }
    return formGroupArr;
  }

  // private createItems(): FormGroup[] {
  //   const formGroupArr: FormGroup[] = [];
  //   formGroupArr.push(
  //     this.formBuilder.group({
  //       label: new FormControl(this.SelectedFilter?.label),
  //       operandDropDown: new FormControl({ value: '' }),
  //       txtRHSValue1: new FormControl('', [Validators.required, Validators.maxLength(50)]),
  //       txtRHSValue2: new FormControl('', [])
  //       //txtRHSValue2: new FormControl('', [Validators.required, Validators.maxLength(50)])
  //     })
  //   );
  //   return formGroupArr;
  //   // this.MoreThanStart(this.textFormGroup?.value.txtRHSValue1)
  // }

  // higherThan(field_name): ValidatorFn {

  //   return (control: AbstractControl): { [key: string]: any } => {

  //     const input = control.value;

  //     const isHigher = input < control?.root?.value?.items[field_name];

  //     return isHigher ? null : { 'higherThan': { isHigher: isHigher } };
  //   };
  // }
  // public MoreThanStart(end: number): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } => {
  //     return parseInt(control?.value?.txtRHSValue1) < end ? { 'moreThan': true } : {};
  //   };
  // }

  get formDataCtrl(): FormArray {
    const formArray = this.textFormGroup?.get('items') as FormArray;
    return formArray;
  }

  deleteRow(index) {
    let k = this.textFormGroup?.get('items') as FormArray
    k.removeAt(index);
  }

  addMore() {
    let newrow = this.formBuilder.group({
      label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
      operandDropDown: new FormControl('', [Validators.required]),
      txtRHSValue1: new FormControl(''),
      txtRHSValue2: new FormControl('')
    });
    (<FormArray>this.textFormGroup?.get('items')).push(newrow);
  }

  validateAndEmitSuccessJson() {
    console.log('validateAndEmitSuccessJson-EMITed fromm here:');
    let len = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length;
    if (len) {
      if (this.editNode?.peopleAndLoansFieldLookupId) {//if it is edit
        let andIndexRemove =
          this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.findIndex(x =>
            x.id == this.editNode?.andIndex &&
            x.peopleAndLoansFieldLookupId == this.editNode.peopleAndLoansFieldLookupId);

        if (andIndexRemove !== -1) {
          this.peopleAndLoanFilters.PeopleLoanList.
            PeopleLoanListAndFilters.splice(andIndexRemove, 1);
        }
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = this.editNode?.andIndex;

      } else {
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = len + 1
      }

      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.forEach(ele => {
      //   if (ele.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
      //     ele.isDeleted = true;//set existing deleted.
      //   }
      // });
      // let tm = this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => x.isDeleted == false);

      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);

    }
    else {//add without any logic 
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);
    }
    this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];//reset 

    // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters =
    //   this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => !x.isDeleted);
    this.peopleAndLoanFilterOutput.isSuccess = true;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = this.peopleAndLoanFilters;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  emitFailureJson() {
    this.peopleAndLoanFilterOutput.isSuccess = false;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = null;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  cancelFilter() {
    this.onCancelFilterEvent.emit(true);
  }

  setAndEmit() {
    if (this.selectedFilter) {
      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      //loop
      let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
      filter.id = (this.insertIndex + 1);
      filter.operator = this.selectedFilter.OpCode;
      filter.lhs = '';
      filter.rhs = [''];
      filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
      let criteriaQuery = this.selectedFilter.Criteria;
      if (criteriaQuery) {
        criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
        criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
        filter.criteria = criteriaQuery;
      }

      let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
      if (htmlTemplate) {
        htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
        htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
        let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
        filter.displayHtml = ' ' + htmlTemplate;
      }
      listAndFilters.PeopleLoanListOrFilters.push(filter);
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
      this.insertIndex++;
      this.validateAndEmitSuccessJson();
    }
  }

  unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  ngOnDestroy(): void {
    this.selectedOperand = null;
    this.OperandDropDownList = null;
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.peopleAndLoanFilters.PeopleLoanList = new PeopleLoanList();//clean all json
    //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
    this.unSubscribeAllSubs();
  }

}
