import { CampaignMessage } from "./CampaignMessage";

/** Implementation of the FocusIT.DataContracts.Pulse.Campaign [DataContract]. */
export class Campaign {
  public tenantId: string = '00000000-0000-0000-0000-000000000000';
  public campaignId: string = '00000000-0000-0000-0000-000000000000';
  public name: string = '';
  public description: string = '';

  public categoryN: string;
  public datePublishedUtc: Date;
  public ownerName: string;
  public published: boolean;
  public recipientCount: number;
  public templateTypeCount: number;
  public totalMessageCount: number;
  // public enabled: boolean = true;
  public updatedByName: string = '';
  public dateUpdatedUTC: Date = null;
  //-----------------------------------------------

  public whoCanUseCSV: string = '';
  public cc_ContactTypeIdCSV: string = '';
  public bcc_ContactTypeIdCSV: string = '';
  public cc_LoanAssignTypeIdCSV: string = '';
  public bcc_LoanAssignTypeIdCSV: string = '';
  public to_ContactTypeIdCSV: string = '';
  public to_LoanAssignTypeIdCSV: string = '';
  public to_EmailCSV: string = '';
  public cc_EmailCSV: string = '';
  public bcc_EmailCSV: string = '';
  public fromEmailAddress: string = '';
  public fromEmailAddressName: string = '';
  public replyToEmailAddress: string = '';
  public toListCSV: string = null;
  public toListCustomConditions: string = '';
  public toReportName: string = '';
  public toRecipientRoles: number = null;
  public ccCustomAddresses: string = '';
  public ccRecipientRoles: number = null;
  public bccCustomAddresses: string = '';
  public bccRecipientRoles: number = null;
  public proxyOwnersJson: string = '';
  public owner: string = '';
  public ownerFullName: string = '';
  public ownerId: string = '00000000-0000-0000-0000-000000000000';
  public messageCount: number = 0;
  public messageSequenceJson: string = '';
  public messageSequenceRulesJson: string = '';
  public modifiedBy: string = '';
  public modifiedOnUtc: Date = null;
  public isPublished: boolean = false;
  public publishedUtc: Date = null;
  public isDeleted: boolean = false;
  public deletedOnUtc: Date = null;
  public messageTypes: number = 0;
  public target: string = '';
  public messages: CampaignMessage[] = [];
  public proxyOwners: ProxyOwner[] = [];
  public displayProxyOwners: string = '';
  public optedInTenants: string = '';
  public campaignCategoryId: string = '';
  public isPublishDbRecord: boolean = false;
  public publishTenantId: string = '';
  public fromContactOwner: boolean = false;
  public isToListContactOnly: boolean = false;
  public isAnyTemplateHasLoanTag: boolean = false;
  public activationMode: string;
  public activatedByTenantUserId: string;
  public fromLoanAssignTypeId: string = '00000000-0000-0000-0000-000000000000';
  public campaignOwnerRole: string;
  public isAllUsersWhoCanUseThisCampaign: boolean;
  public isQuickStart: boolean = false;
  public isRecommended: boolean = false;

  /**
   * Constructor - all parameters are optional.
   * Only passed parameters are assigned to their corresponding property.
   * @param TenantId
   * @param CampaignId
   * @param Name
   * @param Description
   * @param WhoCanUseCSV
   * @param FromEmailAddress
   * @param FromEmailAddressName
   * @param ToListCSV
   * @param ToListCustomConditions
   * @param ToReportName
   * @param ToRecipientRoles
   * @param CcRecipientRoles
   * @param BccRecipientRoles
   * @param ProxyOwnersJson
   * @param Owner
   * @param OwnerFullName
   * @param OwnerId
   * @param MessageCount
   * @param MessageSequenceJson
   * @param MessageSequenceRulesJson
   * @param ModifiedBy
   * @param ModifiedOnUtc
   * @param isPublished
   * @param PublishedUtc
   * @param IsDeleted
   * @param DeletedOnUtc
   * @param MessageTypes
   * @param Target
   * @param Messages
   * @param ProxyOwners
   * @param DisplayProxyOwners
   * @param OptedInTenants
   * @param Enabled,
   * @param FromLoanAssignTypeId,
   * @param categoryN,
   * @param datePublishedUtc,
   * @param ownerName,
   * @param published,
   * @param recipientCount,
   * @param templateTypeCount,
   * @param totalMessageCount,
   * @param FromContactOwner
   */
  constructor(
    TenantId?: string,
    CampaignId?: string,
    Name?: string,
    Description?: string,
    WhoCanUseCSV?: string,
    FromEmailAddress?: string,
    FromEmailAddressName?: string,
    ReplyToEmailAddress?: string,
    ToListCSV?: string,
    ToListCustomConditions?: string,
    ToReportName?: string,
    ToRecipientRoles?: number,
    CcCustomAddresses?: string,
    CcRecipientRoles?: number,
    BccCustomAddresses?: string,
    BccRecipientRoles?: number,
    ProxyOwnersJson?: string,
    Owner?: string,
    OwnerFullName?: string,
    OwnerId?: string,
    MessageCount?: number,
    MessageSequenceJson?: string,
    MessageSequenceRulesJson?: string,
    ModifiedBy?: string,
    ModifiedOnUtc?: Date,
    isPublished?: boolean,
    PublishedUtc?: Date,
    IsDeleted?: boolean,
    DeletedOnUtc?: Date,
    MessageTypes?: number,
    Target?: string,
    DisplayProxyOwners?: string,
    Messages?: CampaignMessage[],
    ProxyOwners?: ProxyOwner[],
    OptedInTenants?: string,
    FromLoanAssignTypeId?: string,
    CategoryN?: string,
    DatePublishedUtc?: Date,
    OwnerName?: string,
    Published?: boolean,
    RecipientCount?: number,
    TemplateTypeCount?: number,
    TotalMessageCount?: number,
    UpdatedByName?: string,
    DateUpdatedUTC?: Date,
    CampaignCategoryId?: string,
    FromContactOwner?: boolean,
    IsToListContactOnly?: boolean,
    IsAnyTemplateHasLoanTag?: boolean,
    isQuickStart?: boolean,
    isRecommended?: boolean
  ) {
    if (TenantId !== undefined && TenantId !== null) this.tenantId = TenantId;
    if (CampaignId !== undefined && CampaignId !== null)
      this.campaignId = CampaignId;
    if (Name !== undefined && Name !== null) this.name = Name;
    if (Description !== undefined && Description !== null)
      this.description = Description;
    if (WhoCanUseCSV !== undefined && WhoCanUseCSV !== null)
      this.whoCanUseCSV = WhoCanUseCSV;
    if (FromEmailAddress !== undefined && FromEmailAddress !== null)
      this.fromEmailAddress = FromEmailAddress;
    if (FromEmailAddressName !== undefined && FromEmailAddressName !== null)
      this.fromEmailAddressName = FromEmailAddressName;
    if (ReplyToEmailAddress !== undefined && ReplyToEmailAddress !== null)
      this.replyToEmailAddress = ReplyToEmailAddress;
    if (ToListCSV !== undefined) this.toListCSV = ToListCSV;
    if (ToListCustomConditions !== undefined && ToListCustomConditions !== null)
      this.toListCustomConditions = ToListCustomConditions;
    if (ToReportName !== undefined && ToReportName !== null)
      this.toReportName = ToReportName;
    if (ToRecipientRoles !== undefined)
      this.toRecipientRoles = ToRecipientRoles;
    if (CcCustomAddresses !== undefined && CcCustomAddresses !== null)
      this.ccCustomAddresses = CcCustomAddresses;
    if (CcRecipientRoles !== undefined)
      this.ccRecipientRoles = CcRecipientRoles;
    if (BccCustomAddresses !== undefined && BccCustomAddresses !== null)
      this.bccCustomAddresses = BccCustomAddresses;
    if (BccRecipientRoles !== undefined)
      this.bccRecipientRoles = BccRecipientRoles;
    if (ProxyOwnersJson !== undefined && ProxyOwnersJson !== null)
      this.proxyOwnersJson = ProxyOwnersJson;
    if (Owner !== undefined && Owner !== null) this.owner = Owner;
    if (OwnerFullName !== undefined && OwnerFullName !== null)
      this.ownerFullName = OwnerFullName;
    if (OwnerId !== undefined && OwnerId !== null) this.ownerId = OwnerId;
    if (MessageCount !== undefined && MessageCount !== null)
      this.messageCount = MessageCount;
    if (MessageSequenceJson !== undefined && MessageSequenceJson !== null)
      this.messageSequenceJson = MessageSequenceJson;
    if (
      MessageSequenceRulesJson !== undefined &&
      MessageSequenceRulesJson !== null
    )
      this.messageSequenceRulesJson = MessageSequenceRulesJson;
    if (ModifiedBy !== undefined && ModifiedBy !== null)
      this.modifiedBy = ModifiedBy;
    if (ModifiedOnUtc !== undefined) this.modifiedOnUtc = ModifiedOnUtc;
    if (isPublished !== undefined && isPublished !== null)
      this.isPublished = isPublished;
    if (PublishedUtc !== undefined) this.publishedUtc = PublishedUtc;
    if (IsDeleted !== undefined && IsDeleted !== null)
      this.isDeleted = IsDeleted;
    if (DeletedOnUtc !== undefined) this.deletedOnUtc = DeletedOnUtc;
    if (MessageTypes !== undefined && MessageTypes !== null)
      this.messageTypes = MessageTypes;
    if (Target !== undefined && Target !== null) this.target = Target;
    if (Messages !== undefined && Messages !== null) this.messages = Messages;
    if (ProxyOwners !== undefined && ProxyOwners !== null)
      this.proxyOwners = ProxyOwners;
    if (DisplayProxyOwners !== undefined && DisplayProxyOwners !== null)
      this.displayProxyOwners = DisplayProxyOwners;
    if (OptedInTenants !== undefined && OptedInTenants !== null)
      this.optedInTenants = OptedInTenants;
    // if ((Enabled !== undefined) && (Enabled !== null)) this.enabled = Enabled;
    if (FromLoanAssignTypeId !== undefined && FromLoanAssignTypeId !== null)
      this.fromLoanAssignTypeId = FromLoanAssignTypeId;
    if (CategoryN) this.categoryN = CategoryN;
    if (DatePublishedUtc) this.datePublishedUtc = DatePublishedUtc;
    if (OwnerName) this.ownerName = OwnerName;
    if (Published) this.published = Published;
    if (RecipientCount) this.recipientCount = RecipientCount;
    if (TemplateTypeCount) this.templateTypeCount = TemplateTypeCount;
    if (TotalMessageCount) this.totalMessageCount = TotalMessageCount;
    if (UpdatedByName) this.updatedByName = UpdatedByName;
    if (DateUpdatedUTC) this.dateUpdatedUTC = DateUpdatedUTC;
    if (CampaignCategoryId) this.campaignCategoryId = CampaignCategoryId;
    if (FromContactOwner !== undefined && FromContactOwner !== null)
      this.fromContactOwner = FromContactOwner;
    if (IsToListContactOnly !== undefined && IsToListContactOnly !== null)
      this.isToListContactOnly = IsToListContactOnly;
    if (
      IsAnyTemplateHasLoanTag !== undefined &&
      IsAnyTemplateHasLoanTag !== null
    )
      this.isAnyTemplateHasLoanTag = IsAnyTemplateHasLoanTag;
    if (this.proxyOwnersJson) {
      let po = new ProxyOwnersParser(JSON.parse(this.proxyOwnersJson));
      this.proxyOwners = po.Parse;
    }
    this.isAllUsersWhoCanUseThisCampaign = false;
    if (isQuickStart !== undefined && isQuickStart !== null)
      this.isQuickStart = isQuickStart;
    if (isRecommended !== undefined && isRecommended !== null)
      this.isRecommended = isRecommended;
  }
}

/** Collection of FocusIT.DataContracts.Pulse.Campaign [DataContract] objects. */
export class CampaignCollection {
  Items: Campaign[] = [];
}

/** User who have had this Campaign pushed to them by a surrogate (marketing admin) */
export class ProxyOwner {
  public Id: string = '00000000-0000-0000-0000-000000000000';
  public Type: string = '';

  /** Constructor   
  * @param Id - the object GUID id
  * @param Type - the object type: either 'user' or 'team'
  */
  constructor(Type?: string, Id?: string) {
    if ((Id !== undefined) && (Id !== null)) this.Id = Id;
    if ((Type !== undefined) && (Type !== null)) this.Type = Type;
  }
}

export class OptedInTenants {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    TenantId?: string) {
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
  }
}
export class CampaignDetailBlockObject {
  constructor() {
    this.isPublishCampaign = false;
    this.isPublished = false;
    this.isLoading = true;
  }
  public bccData: string;
  public ccData: string;
  public toRecipientData: string;
  public toList: string;
  public fromValue: string;
  public whoCanUsers: string;
  public campaignCategory: string;
  public isPublishCampaign: boolean;
  public isPublished: boolean;
  public isLoading: boolean;
  public isPreviewMode: boolean;
  public isAllUsersWhoCanUseThisCampaign: boolean;
  public isQuickStart: boolean = false;
  public isRecommended: boolean = false;

  public Clear() {
    this.bccData = '';
    this.ccData = '';
    this.toRecipientData = '';
    this.toList = '';
    this.fromValue = '';
    this.whoCanUsers = '';
    this.campaignCategory = '';
    this.isLoading = true;
    this.isPreviewMode = false;
    this.isPublishCampaign = false;
    this.isPublished = false;
    this.isAllUsersWhoCanUseThisCampaign = false;
    this.isQuickStart = false;
    this.isRecommended = false;
  }

  public trimAllString() {
    //trim to 50 char and add ...
    this.bccData = this.trimStr(this.bccData);
    this.ccData = this.trimStr(this.ccData);
    this.toRecipientData = this.trimStr(this.toRecipientData);
    this.toList = this.trimStr(this.toList);
    this.fromValue = this.trimStr(this.fromValue);
    this.whoCanUsers = this.trimStr(this.whoCanUsers);
    this.campaignCategory = this.trimStr(this.campaignCategory);
  }
  public trimStr(str) {
    //trim to 50 char and add ...
    return str?.length > 50 ? str.substring(0, 50) + ' ...' : str;
  }
}
export class CampaignPublishOperaionObject {
  public campaingId: string;
  public isPublished: boolean;
  constructor() {
    this.isPublished = false;
    this.campaingId = '';
  }
}
export class CampaignPreviewOperationObject {
  public templateTypeId: number;
  constructor() {
    this.templateTypeId = 0;
  }
}

/** Parser for ProxyOwnersJson */
export class ProxyOwnersParser {
  public Parse: ProxyOwner[] = [];

  /** Constructor
  * @param json - serialized ProxyOwners
  */
  constructor(json?: any[]) {
    if (json) this.deserialize(json);
  }

  /** Deserialize stringified ProxyOwners
  * @param data - serialized ProxyOwners
  */
  deserialize(data: any[]) {
    for (let x = 0; x < data.length; x++)
      this.Parse.push(new ProxyOwner(data[x].Type, data[x].Id));
  }
}
export class CampaignAddVM {
  constructor() { }
  public campaignName: string;
  public campaignCategory: string;
  public campaignDesc: string;
  public tenantUserIdList: string[]; // whoCanUseList
  public isAllUsers: boolean;
  public toList: string[];
  public toRecipientContacts: string[];
  public toRecipientUsers: string[];
  public toRecipientCustomAddress: string; //string csv
  public ccRecipientContacts: string[];
  public ccRecipientUsers: string[];
  public ccRecipientCustomAddress: string;
  public bccRecipientContacts: string[];
  public bccRecipientUsers: string[];
  public bccRecipientCustomAddress: string;

  public isFromRole: string;
  public fromLoanAssignTypeId: string;
  public fromEmail: string;
  public fromName: string;
  public isQuickStart: string;
  public isRecommended: string;
}


export class TemplateHtmlData {
  constructor(public id: string, public html: string, public isPublishDb: boolean = false) { }
}

export class UpdateMsgModel {
  constructor() {
    this.messageTypeToSend = '';
    this.isCalledFromWizard = 0;
    this.campaignId = null;
    this.campaignMessageId = null;
    this.oldMessageTemplateId = null;
    this.isChangeMessage = false;

  }
  //public isReplaceOrAdd: number //1 replace | 2 Add
  public messageTypeToSend: string //SMS/Email
  public isCalledFromWizard: number;//called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4


  //to replace message or add message
  public campaignId: string // add in campaign
  public campaignMessageId: string //for replace   in campaign
  //public currentMessageType: number;
  public oldMessageTemplateId: string;
  public isChangeMessage: boolean;//replace
}

export class CampaignInfo {
  constructor() {
    this.campaignId = null;
    this.campaignMessageId = null;
  }
  public campaignId: string // add in campaign
  public campaignMessageId: string //for replace   in campaign
}