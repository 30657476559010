<div class="row" [formGroup]="statusForm">
  <div class="p-col-12" style="padding-left: 45px">
    <div class="row">
      <div class="p-col-5"><label>Wait to send:</label>&nbsp;</div>
      <div class="p-col-6" style="margin-left: 15px">&nbsp;</div>
    </div>
    <div class="row"  id="wait-to-send">
      <div class="p-col-2" style="width: 15% !important;">
        <p-dropdown
          [options]="days"
          optionLabel="code"
          [filter]="false"
          filterBy="code"
          [showClear]="false"
          formControlName="day"
          optionValue="code"
          appendTo="body"
          [disabled]="isNoDelay"
        >
          <ng-template let-day pTemplate="item">
            <div class="day-item">
              <div>{{ day.code }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div
        class="p-col-3"
        style="width: 19% !important;"
      >
        <p-dropdown
          [options]="periodUnits"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="periodUnit"
          optionValue="code"
          appendTo="body"
          (onChange)="OnPeriodChange($event)"
        >
          <ng-template let-periodUnit pTemplate="item">
            <div class="periodUnit-item" >
              <div>{{ periodUnit.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div
        class="p-col-2"
        style="width: 17% !important;"
      >
        <p-dropdown
          [options]="offsetTypes"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="offsetType"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-offsetType pTemplate="item">
            <div class="offsetType-item">
              <div>{{ offsetType.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-5" >
        <p-dropdown
          [options]="loanStatusList"
          optionLabel="displayName"
          [filter]="true"
          filterBy="displayName"
          [showClear]="true"
          resetFilterOnHide="true"
          formControlName="loanStatusType"
          optionValue="loanStatusTypeId"
          appendTo="body"
        >
          <ng-template let-loanStatus pTemplate="item">
            <div class="loanStatus-item">
              <div>{{ loanStatus.displayName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div style="width: 95%; margin: 0 auto; margin-top: -10px">
    <hr style="border-top: 3px solid #fff; opacity: 1" />
  </div>
  <div class="p-col-12" style="padding-left: 45px">
    <div class="row">
      <div class="p-col-12">
        <label>Send on:</label>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="p-col-3">
        <p-dropdown
          [options]="sendOnDayTypes"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="sendOnDayType"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-sendon pTemplate="item">
            <div class="sendon-item">
              <div>{{ sendon.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="p-col-2">
        <p-radioButton
          name="sendOnTimeType"
          value="ANYTIME"
          label="Any time"
          formControlName="sendOnTimeType"
        ></p-radioButton>
      </div>
      <div class="p-col-3">
        <p-radioButton
          name="sendOnTimeType"
          value="ATSPECIFICTIME"
          label="At specific time"
          formControlName="sendOnTimeType"
        ></p-radioButton>
      </div>
      <div class="p-col-4"></div>
    </div>
  </div>
  <div *ngIf="showTimeControls" style="width: 95%; margin: 0 auto; margin-top: -10px">
    <hr style="border-top: 3px solid #fff; opacity: 1" />
  </div>
  <div *ngIf="showTimeControls" class="p-col-12" style="padding-left: 45px">
    <div class="row">
      <div class="p-col-5">
        <label>Time</label>&nbsp;
        <span
          *ngIf="messageType == 2 && allowTCPAForTextMessages==true"
          [title]=""
          class="fa fa-info-circle info-icon info"
          pTooltip="{{ timeToolTipe }}"
          tooltipPosition="top"
          tooltipZIndex="9999999"
          style="
            width: 100px;
            font-weight: 500 !important;
            color: #bcc3cb !important;
            font-size: 14px;
          "
        >
        </span>
      </div>

      <div class="p-col-6" style="margin-left: 15px">
        <label>Time Zone</label>
      </div>
    </div>
    <div class="row" id="specific-time-schedule">
      <div class="p-col-2" style="width: 14% !important; padding-right: 7.5px">
        <p-dropdown
          [options]="hours"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="hour"
          optionValue="code"
          appendTo="body"
          (onChange)="OnHourChange($event)"
        >
          <ng-template let-hour pTemplate="item">
            <div class="hour-item">
              <div>{{ hour.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div
        class="p-col-1 align-self-center justify-self-center p-0"
        style="width: 1%; color: #797979;">
        :
      </div>
      <div
        class="p-col-2"
        style="width: 14% !important; padding-right: 7.5px; padding-left: 7.5px"
      >
        <p-dropdown
          [options]="minutes"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="minute"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-minute pTemplate="item">
            <div class="minute-item">
              <div>{{ minute.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div
        class="p-col-2"
        style="width: 15% !important; padding-right: 7.5px; padding-left: 7.5px"
      >
        <p-dropdown
          [options]="meridiems"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="meridiem"
          optionValue="code"
          appendTo="body"
          [disabled]="messageType == 2 && allowTCPAForTextMessages == true"
        >
          <ng-template let-meridiem pTemplate="item">
            <div class="meridiem-item">
              <div>{{ meridiem.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-5" style="padding-right: 7.5px; padding-left: 7.5px">
        <p-dropdown
          [options]="timeZones"
          optionLabel="displayName"
          [filter]="true"
          filterBy="displayName"
          [showClear]="true"
          resetFilterOnHide="true"
          formControlName="timeZone"
          optionValue="timezoneId"
          appendTo="body"
        >
          <ng-template let-timeZone pTemplate="item">
            <div class="timeZone-item">
              <div>{{ timeZone.displayName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
</div>
