import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { forkJoin } from "rxjs";
import { CalendarAndTimeZoneService } from "src/app/services/CalendarAndTimeZoneService";
import { LoanStatusType } from "src/models/LoanStatusType";
import { Message } from "src/models/Message";
import { CMS_Data, MessageScheduleData, RepeatingDailyData, RepeatingMonthlyData, RepeatingWeeklyScheduleData, RepeatingYearlyContactData, RepeatingYearlyCustomData, RepeatingYearlyHolidayData, SavedContactQuickPick, SavedHolidayQuickPick, SavedOneTimeCustomDate, StatusQuickPickData } from "src/models/MessageScheduleData";
import { RequestResponse } from "src/models/RequestResponse";
import { LoansService } from "src/services/Loans.service";
import { CampaignMessageScheduleDataService } from "src/services/campaignMessageSchedule.Data.service";
import { CommunicationService } from "src/services/communication.service";
import { LookupService } from "src/services/lookup.service";
import { MessagesService } from "src/services/messages.service";
import { ToastService } from "src/services/toast.service";

@Component({
  selector: 'app-content-message-schedule',
  templateUrl: './content-message-schedule.component.html',
  styleUrls: ['./content-message-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentMessageScheduleComponent {
  @ViewChild('messagesTableId', { static: false }) dataTable: Table;
  @Input() selectedCampaignId: string;
  @Input() selectedCampaignMessageList: any;
  @Output() onCloseScheduleDialog: EventEmitter<string> = new EventEmitter();
  webAPI: RequestResponse = new RequestResponse();
  messageScheduleData: MessageScheduleData;
  message: Message = new Message();
  public pageIndex: number = 0;
  loadingPage: boolean = false;
  public noRecordFound: boolean = false;
  currentMessage: any;
  messageId = '';
  public loanStatusTypeList: LoanStatusType[];
  timeZones: any[];
  holidays: any[];
  contacts: any[];

  constructor(
    private toastService: ToastService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    public datePipe: DatePipe,
    private lookupService: LookupService,
    private loanService: LoansService,
    private communicationService: CommunicationService,
    private messagesService: MessagesService,
    private cdr: ChangeDetectorRef
  ) {
    this.webAPI.paging.pageSize = 25;
    this.messageScheduleData = new MessageScheduleData();
    this.loanStatusTypeList = [];
  }
  ngOnInit(): void {
    this.loadingPage = true;
    forkJoin([
      this.loanService.GetLoanStatusType(false),
      this.lookupService.GetTimezones(),
      this.lookupService.GetContacts(),
      this.lookupService.GetHolidays(),
    ]).subscribe(
      ([
        loanStatusTypeResponse,
        timezonesresponse,
        contactresponse,
        holidayresponse,
      ]) => {
        if (loanStatusTypeResponse) {
          this.loanStatusTypeList = loanStatusTypeResponse.data;
        }
        this.timeZones = timezonesresponse.data;
        this.contacts = contactresponse.data;
        this.holidays = holidayresponse.data;
        
        this.generateMessages();
        this.loadingPage = false;
        this.cdr.detectChanges();
      },
      (err) => {
        this.loadingPage = false;
        this.cdr.detectChanges();
      }
    );
    //for instant ScheduleMessage change
    this.communicationService.CMS_Schedule$.subscribe((data: CMS_Data) => {
      if (data.campaignMessageId && data.isReload) {
        let campaignMessageId = data.campaignMessageId;
        //getting only single record.
        this.messagesService
          .GetCampaignMessageScheduleById(
            this.selectedCampaignId,
            campaignMessageId
          )
          .subscribe(
            (response) => {
              if (response?.data?.scheduleDataJSON) {
                let index = this.selectedCampaignMessageList.data.findIndex(
                  (x) => x.campaignMessageId == campaignMessageId
                );
                let msg = this.selectedCampaignMessageList.data[index];
                if (index > -1) {
                  msg.scheduleType = response.data.scheduleType;
                  msg.scheduleSubtype = response.data.scheduleSubtype;
                  msg.scheduleDataJSON = response.data.scheduleDataJSON;

                  this.CreateScheduleMessageSub(
                    msg.scheduleType,
                    msg.scheduleSubtype,
                    msg.scheduleDataJSON,
                    index
                  );
                }
              }
              //Generate ScheduleMessage for every message
              this.loadingPage = false;
            },
            (err) => {
              this.loadingPage = false;
            }
          );
      }
    });
  }
  async generateMessages() {
    try {
      if (this.selectedCampaignMessageList.data) {
        this.webAPI.data = [];
        this.webAPI.paging.pagingRead = true;
        this.webAPI.data = this.selectedCampaignMessageList.data;
        this.webAPI.paging.totalRecords =
          this.selectedCampaignMessageList.pageInfo.totalRecords;
        this.noRecordFound =
          this.selectedCampaignMessageList?.pageInfo?.totalRecords < 1;
        this.CreateScheduleMessage();
      }
    } catch (message: any) {
      this.webAPI.paging.totalRecords = 0;
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }
  CreateScheduleMessage() {
    if (this.selectedCampaignMessageList?.data?.length) {
      //has messages
      for (let i = 0; i < this.selectedCampaignMessageList.data.length; i++) {
        let scheduleType =
          this.selectedCampaignMessageList.data[i].scheduleType;
        let ScheduleSubtype =
          this.selectedCampaignMessageList.data[i].scheduleSubtype;
        let ScheduleDataJSON =
          this.selectedCampaignMessageList.data[i].scheduleDataJSON;

        this.CreateScheduleMessageSub(
          scheduleType,
          ScheduleSubtype,
          ScheduleDataJSON,
          i
        );
      }
    }
  }
  private CreateScheduleMessageSub(
    scheduleType: string,
    ScheduleSubtype: string,
    ScheduleDataJSON: string,
    i: number
  ) {
    if (ScheduleDataJSON) {
      if (scheduleType === 'OneTime') {
        if (ScheduleSubtype === 'StatusQuickPick') {
          let savedObject: StatusQuickPickData =
            JSON.parse(ScheduleDataJSON)[0];
          let status =
            this.loanStatusTypeList.find(
              (x) => x.loanStatusTypeId == savedObject.loanStatusTypeId
            )?.displayName ?? '';
          let messageText = '';
          let dayType = '';
          let periodUnit = '';
          let timeType = '';
          let offset = 'before';
          if (savedObject.offsetTypeId) {
            if (savedObject.offsetTypeId == 'AFTER') {
              offset = 'after';
            }
            if (savedObject.day === '1') {
              periodUnit = `${savedObject.periodUnit.toLowerCase()}`;
            } else {
              periodUnit = `${savedObject.periodUnit.toLowerCase()}s`;
            }
            if (savedObject.sendOndayTypeId == 'ANYDAY') {
              dayType = 'anyday';
            } else {
              dayType = 'a weekday';
            }
            if (savedObject.time) {
              let timeZoneDisplayName =
                this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                  savedObject.timezoneId.toLowerCase(),
                  this.timeZones
                );
              let time = this.Get12HourTime(savedObject.time);

              let timeString = `<span>${time}</span><span>${timeZoneDisplayName}</span>`;
              timeType = timeString;
            } else {
              timeType = 'anytime';
            }
            if (savedObject.periodUnit == 'NODELAY') {
              messageText = `Wait to send message <span class="fw-bold" >One Time </span>with <span>no delay ${offset} ${status}</span> on <span class="fw-bold">${dayType}</span> at <span class="fw-bold">${timeType}</span>`;
            } else if (savedObject.periodUnit == 'DAY') {
              messageText = `Wait to send message <span class="fw-bold" >One Time </span>when <span class="fw-bold">${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span class="fw-bold">${dayType}</span> at <span class="fw-bold">${timeType}</span>`;
            } else if (savedObject.periodUnit == 'MONTH') {
              messageText = `Wait to send message <span class="fw-bold" >One Time </span>when <span class="fw-bold">${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span class="fw-bold">${dayType}</span> at <span class="fw-bold">${timeType}</span>`;
            } else if (savedObject.periodUnit == 'WEEK') {
              messageText = `Wait to send message <span class="fw-bold">One Time </span>when <span class="fw-bold">${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span class="fw-bold">${dayType}</span> at <span class="fw-bold">${timeType}</span>`;
            } else if (savedObject.periodUnit == 'YEAR') {
              messageText = `Wait to send message <span class="fw-bold">One Time </span>when <span class="fw-bold">${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span class="fw-bold">${dayType}</span> at <span class="fw-bold">${timeType}</span>`;
            }
          } else {
            messageText = `Wait to send message <span class="fw-bold" >One Time </span>when <span class="fw-bold" >${status}</span>`;
          }

          this.selectedCampaignMessageList.data[i].ScheduleMessage =
            messageText;
        } else if (ScheduleSubtype === 'HolidayQuickPick') {
          let savedObject: SavedHolidayQuickPick =
            JSON.parse(ScheduleDataJSON)[0];
          let calanderDisplayName =
            this.calendarAndTimeZoneService.getHolidayDisplayName(
              savedObject.HolidayEventId.toLowerCase(),
              this.holidays
            );
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.TimezoneId.toLowerCase(),
              this.timeZones
            );
          let time = this.Get12HourTime(savedObject.Time);
          this.selectedCampaignMessageList.data[
            i
          ].ScheduleMessage = `Send messages on <span class="fw-bold">${calanderDisplayName}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'ContactQuickPick') {
          let savedObject: SavedContactQuickPick =
            JSON.parse(ScheduleDataJSON)[0];
          let calanderDisplayName =
            this.calendarAndTimeZoneService.getContactEventDisplayName(
              savedObject.ContactEventId.toLowerCase(),
              this.contacts
            );
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.TimezoneId.toLowerCase(),
              this.timeZones
            );
          let time = this.Get12HourTime(savedObject.Time);
          this.selectedCampaignMessageList.data[
            i
          ].ScheduleMessage = `Send messages on <span class="fw-bold">${calanderDisplayName}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'CustomDate') {
          let savedObject: SavedOneTimeCustomDate =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.TimezoneId.toLowerCase(),
              this.timeZones
            );
          let time = this.Get12HourTime(savedObject.Time);
          var dateStr = this.datePipe.transform(
            savedObject.DeliverOn,
            'MM/dd/yyyy'
          );
          this.selectedCampaignMessageList.data[
            i
          ].ScheduleMessage = `Send messages on <span class="fw-bold">${dateStr}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
        }
      } else if (scheduleType === 'Repeating') {
        if (ScheduleSubtype === 'Monthly') {
          let savedObject: RepeatingMonthlyData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.timezoneId.toLowerCase(),
              this.timeZones
            );
          let time = this.Get12HourTime(savedObject.time);
          let days = this.calendarAndTimeZoneService.getOrdinalNumber(
            savedObject.ordinalName
          );

          // repeating -> Montlhy -> day of the month (1,30) ->amd Ordianlname,weekday =null|0
          // repeating-> first ,weekday of everymonth. -> amwd Ordinalname|weekday
          if (savedObject.repeatingMonthlyType.toLowerCase() == 'amd') {
            //Send messages every {{Nth}} day of every month at {{HH:MM AMPM}}{{TIMEZONE}}
            this.selectedCampaignMessageList.data[
              i
            ].ScheduleMessage = `Send messages every <span class="fw-bold">${days}</span> day of every month at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
          } else if (savedObject.repeatingMonthlyType.toLowerCase() == 'amwd') {
            let weekNumber = Number(savedObject.ordinalName);
            let weekDays = 'Last';
            if (weekNumber != 5) {
              weekDays =
                this.calendarAndTimeZoneService.getOrdinalNumber(weekNumber);
            }
            // Send messages every {{Nth Weekday}} of every {{Month}} at {{HH:MM AMPM}}{{TIMEZONE}}
            let weekName =
              this.calendarAndTimeZoneService.weekdays.find(
                (x) => x.code == savedObject.weekday
              )?.name ?? '-';
            this.selectedCampaignMessageList.data[
              i
            ].ScheduleMessage = `Send messages every <span class="fw-bold" >${weekDays}</span> <span class="fw-bold">${weekName}</span> of every month at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
          }
        } else if (ScheduleSubtype === 'YearlyCustom') {
          let savedObject: RepeatingYearlyCustomData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.timezoneId.toLowerCase(),
              this.timeZones
            );
          let time = this.Get12HourTime(savedObject.time);
          let monthName =
            this.calendarAndTimeZoneService.months.find(
              (x) => x.code == savedObject.month
            )?.name ?? '-';

          if (savedObject.repeatingMonthlyType.toLowerCase() == 'amd') {
            let days = this.calendarAndTimeZoneService.getOrdinalNumber(
              savedObject.onTheXofEveryMonthOrdinal
            );
            this.selectedCampaignMessageList.data[
              i
            ].ScheduleMessage = `Send messages on the <span class="fw-bold">${days}</span> day of every <span class="fw-bold">${monthName}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
          } else if (savedObject.repeatingMonthlyType.toLowerCase() == 'amwd') {
            let weekNumber = Number(
              savedObject.everyXweekdayOfEveryMonthOrdinal
            );
            let weekDays = 'Last';
            if (weekNumber != 5) {
              weekDays =
                this.calendarAndTimeZoneService.getOrdinalNumber(weekNumber);
            }
            let weekName =
              this.calendarAndTimeZoneService.weekdays.find(
                (x) => x.code == savedObject.weekday
              )?.name ?? '-';
            this.selectedCampaignMessageList.data[
              i
            ].ScheduleMessage = `Send messages on the <span class="fw-bold">${weekDays}</span> <span class="fw-bold">${weekName}</span>
             of every <span class="fw-bold">${monthName}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
          }
        } else if (ScheduleSubtype === 'YearlyContact') {
          //`Send messages on the contact's {{Calendar Event Display Name}} at {{HH:MM AMPM}} {{TIMEZONE}} `
          let savedObject: RepeatingYearlyContactData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.timezoneId.toLowerCase(),
              this.timeZones
            );
          let calanderDisplayName =
            this.calendarAndTimeZoneService.getContactEventDisplayName(
              savedObject.contactEventId.toLowerCase(),
              this.contacts
            );
          let time = this.Get12HourTime(savedObject.time);

          this.selectedCampaignMessageList.data[
            i
          ].ScheduleMessage = `Send messages on the contact's <span class="fw-bold">${calanderDisplayName}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'WeeklyWeekday') {
          let savedObject: RepeatingWeeklyScheduleData =
            JSON.parse(ScheduleDataJSON)[0];
          let msg = '';
          let cnt = savedObject?.scheduleDetail?.length;
          if (savedObject && cnt > 0) {
            let time = this.Get12HourTime(savedObject.scheduleDetail[0].time);
            let timeZoneDisplayName =
              this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                savedObject.scheduleDetail[0].timezoneId.toLowerCase(),
                this.timeZones
              );

            if (cnt === 1) {
              let weekName =
                this.calendarAndTimeZoneService.weekdays.find(
                  (x) => x.code == savedObject.scheduleDetail[0].weekday
                )?.name ?? '-';
              msg = `Send messages every <span class="fw-bold">${weekName}</span> at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
              // Send messages every {{Weekday}} at {{HH:MM AMPM}}{{TIMEZONE}} [Cancel messages button]
            } else if (cnt > 1) {
              // If there are two or more weekly schedules:
              // Send messages multiple weekly schedules at {{HH:MM AMPM}}{{TIMEZONE}} [Cancel messages button]
              //msg = `Send messages multiple weekly schedules at ${savedObject.scheduleDetail[0].weekday} at ${time} ${ timeZoneDisplayName }`;
              let postFix = 'Send messages every ';
              let preFix = '';
              for (
                let index = 0;
                index < savedObject.scheduleDetail.length;
                index++
              ) {
                var schedule = savedObject.scheduleDetail[index];
                if (schedule) {
                  let weekName =
                    this.calendarAndTimeZoneService.weekdays.find(
                      (x) => x.code == schedule.weekday
                    )?.name ?? '-';

                  let scheduleTime = this.Get12HourTime(schedule.time);
                  let scheduleTimeZone =
                    this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                      schedule.timezoneId.toLowerCase(),
                      this.timeZones
                    );
                  msg += `${postFix} ${preFix} <span>${weekName}</span> at <span>${scheduleTime}</span><span>${scheduleTimeZone}</span>`;
                  preFix = ' and every ';
                  postFix = '';
                }
              }
            }
          }
          this.selectedCampaignMessageList.data[i].ScheduleMessage = msg;
        } else if (ScheduleSubtype === 'YearlyHoliday') {
          let savedObject: RepeatingYearlyHolidayData =
            JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.timezoneId.toLowerCase(),
              this.timeZones
            );
          let calanderDisplayName =
            this.calendarAndTimeZoneService.getHolidayDisplayName(
              savedObject.holidayEventId.toLowerCase(),
              this.holidays
            );
          let time = this.Get12HourTime(savedObject.time);
          let endYearMsg = '';
          if (savedObject.endingYear) {
            endYearMsg = ` | Ending Year <span>${savedObject.endingYear}</span>`;
          }
          this.selectedCampaignMessageList.data[
            i
          ].ScheduleMessage = `Send messages on <span class="fw-bold">${calanderDisplayName}</span> (Starting Year <span class="fw-bold">${savedObject.startingYear}</span>${endYearMsg}) at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
        } else if (ScheduleSubtype === 'DailyEvent') {
          let savedObject: RepeatingDailyData = JSON.parse(ScheduleDataJSON)[0];
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              savedObject.timezoneId.toLowerCase(),
              this.timeZones
            );
          let time = this.Get12HourTime(savedObject.time);
          this.selectedCampaignMessageList.data[
            i
          ].ScheduleMessage = `Send messages daily at <span class="fw-bold">${time}</span><span class="fw-bold">${timeZoneDisplayName}</span>`;
        }
        if (this.selectedCampaignMessageList.data[i].ScheduleMessage) {
          //this.showValidationFlag = false;
        }
      } else {
        this.selectedCampaignMessageList.data[i].ScheduleMessage =
          'Schedule not set';
      }
      this.cdr.detectChanges();
    }
  }
  private Get12HourTime(timeStr: string) {
    //HH:MM AMPM
    let hours = '',
      minutes = '',
      AmPM = '';

    if (timeStr?.length >= 6) {
      var timeArr = timeStr.substring(0, 6).split(':'); //[HH:MM]
      if (parseInt(timeArr[0]) >= 12) {
        if (parseInt(timeArr[0]) == 12) {
          hours = parseInt(timeArr[0]).toString(); //PM
        } else {
          hours = (parseInt(timeArr[0]) - 12).toString(); //PM
        }
        AmPM = 'PM';
      } else {
        if (parseInt(timeArr[0]) == 0) {
          hours = '12'; //AM
        } else {
          hours = parseInt(timeArr[0]).toString(); //AM
        }
        AmPM = 'AM';
      }
      minutes = parseInt(timeArr[1]).toString();
      hours = '0' + hours;
      hours = hours.substring(hours.length - 2);
      minutes = '0' + minutes;
      minutes = minutes.substring(minutes.length - 2);
    } else {
      return `00: 00 AM`;
    }

    return `${hours}:${minutes} ${AmPM} `;
  }
  async onShowMessageScheduleDialog(message: any) {
    // if (this.campaign.isPublishDbRecord) {
    //   return;
    // }
    if (message) {
      this.messageScheduleData.showMessageScheduleDialog = true;
      this.campaignMessageScheduleDataService.campaignMessageId =
        message.campaignMessageId;
      this.campaignMessageScheduleDataService.scheduleType =
        message.scheduleType;
      this.campaignMessageScheduleDataService.scheduleSubtype =
        message.scheduleSubtype;
      this.campaignMessageScheduleDataService.scheduleDataJSON =
        message.scheduleDataJSON;
      this.campaignMessageScheduleDataService.dialogTitle =
        message.messageTemplateTitle;
      this.campaignMessageScheduleDataService.isReload = false;
      this.campaignMessageScheduleDataService.tabIndex = 0; //default
      this.campaignMessageScheduleDataService.componentIndex = 0; //default
      this.campaignMessageScheduleDataService.campaignMessageType =
        message.templateTypeId;
      if (message.scheduleType) {
        this.campaignMessageScheduleDataService.tabIndex =
          message.scheduleType == 'OneTime' ? 0 : 1;
      }
      if (message.scheduleSubtype) {
        let index = 0;
        if (message.scheduleSubtype == 'StatusQuickPick') {
          index = 0;
        } else if (message.scheduleSubtype == 'HolidayQuickPick') {
          index = 1;
        } else if (message.scheduleSubtype == 'ContactQuickPick') {
          index = 10;
        } else if (message.scheduleSubtype == 'CustomDate') {
          index = 2;
        } else if (message.scheduleSubtype == 'YearlyCustom') {
          index = 4;
        } else if (message.scheduleSubtype == 'YearlyHoliday') {
          //RepeatingHolidayQuickPick'
          index = 5;
        } else if (message.scheduleSubtype == 'YearlyContact') {
          index = 6;
        } else if (message.scheduleSubtype == 'WeeklyWeekday') {
          index = 7;
        } else if (message.scheduleSubtype == 'Monthly') {
          index = 8;
        } else if (message.scheduleSubtype == 'DailyEvent') {
          index = 9;
        }

        this.campaignMessageScheduleDataService.componentIndex = index;
      }

      this.currentMessage = message;
      this.messageId = message.campaignMessageId;
    }
  }

  async onCloseMessageScheduleDialog() {
    this.messageScheduleData.showMessageScheduleDialog = false;
    this.onCloseScheduleDialog.emit();
  }
  public DialogCloseEvent(event: any) {
    if (event) {
      this.messageScheduleData.showMessageScheduleDialog = false;
      this.cdr.detectChanges();
      this.onCloseScheduleDialog.emit();
    }
  }
  async onCancelMessageScheduleDialog() {
    this.messageScheduleData.showMessageScheduleDialog = false;
    this.onCloseScheduleDialog.emit();
    //this.showMessageScheduleDialog = false;
  }
}


 