
<div id="AuthorizationFailedDiv" class="content" role="main">
  <table width="100%" style="padding: 0px; margin: 0px;">
    <tr><td colspan="3" style="color:white; background:#00b3c3; vertical-align:middle;"><img height="48" src="assets/images/logo-white.png" style="padding:10px 110px 10px 20px;" /><span style="font-size:2em; font-weight:bold; vertical-align:top; text-align: center">Page Not Found</span></td></tr>
    <tr><td colspan="3"><div style="width:100%; background:#ff0000; height:4px;">&nbsp;</div></td></tr>
    <tr style="min-width:600px;">
      <td style="min-width:175px; width:175px;" align="center"><img src="assets/images/hand_stop.png" /></td>
      <td style="min-width:250px;">
        <br />
        <b><u>What happened?</u></b>
        <br />
        The page you requested does not exist.<br />
        <p style="color:tomato; font-size:smaller; font-style:italic; font-weight:bold;"><label></label></p>
        <br />
        <b><u>What does this mean?</u></b><br />
        You have attempted to access an area, section, or resource in the Pulse management solution
        that does not exist.<br />
        <br />
        <b><u>Next Steps</u></b><br />
        <ul>
          <li>Check with your Team or Company admin to ensure you have sufficient user rights.</li>
          <li>Please contact your focusIT liasion for assistance.</li>
        </ul>
      </td>
      <td style="min-width:175px; width:175px;">&nbsp;</td>
    </tr>
    <tr><td colspan="3">&nbsp;</td></tr>
  </table>
</div>
