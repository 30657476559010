import { ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { SearchModel } from './models/search-model';
import { CsvService } from 'src/services/csv.service';
import { FiltersComponent } from './filters/filters.component';

@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomGridComponent implements OnInit {

  @Input() data: Array<any> = new Array<any>();
  @Input() columns: Array<any> = new Array<any>();
  @Input() showFilters: boolean = true;
  @Input() showExport: boolean = true;
  @Input() totalCountLabel: string = 'Messages';
  @Input() searchModel: SearchModel = new SearchModel({
    campaignId: '',
    pageNum: 1,
    pageSize: 10
  });
  @Input() hideSearchBar: boolean = false;
  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadAllEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterBasedOnColumnSelect: EventEmitter<any> = new EventEmitter<any>();
  @ContentChild('tabletTemplate') tabletTemplate!: TemplateRef<any>;
  selectedColumns: any = [];
  selectedRows: any = [];
  @ViewChild(FiltersComponent) FiltersComponent: FiltersComponent;
  constructor(private csvService: CsvService, private changeDetectionRefernce: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.columns.forEach((column: any) => {

      if (column.display) {
        this.selectedColumns.push(column.field);
      }
    });
  }

  internalOnPageChanged($event: any) {

    if($event.rows !== undefined){
      this.searchModel.pageSize = $event.rows;
      this.searchModel.pageNum = 1;
    }
    this.onPageChanged.emit($event);
  }

  onColumnSelectionChange(event: any) {

    this.columns.forEach((column: any) => {
      if (this.selectedColumns.includes(column.field)) {
        column.display = true;
      }
      else {
        column.display = false;
      }
    });

    this.columns = [...this.columns];

    this.filterBasedOnColumnSelect.emit(this.columns);
  }

  updateFilters(){
    this.updatedFilters.emit();
  }

  onPageNumChange($event) {
    this.searchModel.pageNum = Number($event.target.value);
    this.onPageChanged.emit($event);
  }

  downloadData(downloadAll: boolean){

    if(!downloadAll){
      this.csvService.downloadCSV(this.selectedRows, 'download.csv');
      return;
    }

    this.downloadAllEvent.emit();
  }

  detectGridChanges(){
    this.changeDetectionRefernce.detectChanges();
  }

  public detectFilterChanges(){
    this.columns = [...this.columns];
    this.FiltersComponent.detectFilterChanges();
  }

  detectGridAndFilterChanges(){
    this.changeDetectionRefernce.detectChanges();
    this.FiltersComponent.detectFilterChanges();
  }

}
