import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MultiSelect } from 'primeng/multiselect';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter, RhsDropdown, RhsDropdownItem, RhsQueryData } from '../../../models/PeopleAndLoanFilterOutput';
import { PeopleAndLoanService } from '../../../services/peopleandloans.service';
import { ToastService } from '../../../services/toast.service';
@Component({
  selector: 'app-people-and-list-advanced-filter-generic-list',
  templateUrl: './people-and-list-filter-generic-list.component.html',
  styleUrls: ['./people-and-list-filter-generic-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterGenericListComponent implements OnInit, OnDestroy {
  public selectedOperand: any | null;
  public textFormGroup: FormGroup | null;
  public items: FormArray | null;
  private sub: Array<Subscription>;
  private selectedFilter: PeopleAndLoanFieldLookup | null;
  private peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput;
  @Input() OperandDropDownList: any | null;
  private tmpPeopleAndLoanFilters: PeopleAndLoanFilterData;
  private peopleAndLoanFilters: PeopleAndLoanFilterData;
  @Input() get SelectedFilter(): any | null {
    return this.selectedFilter;
  }
  set SelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      this.textFormGroup = null;
      this.items = null;
      this.generateForm();
    }
  }
  @Output() onCreateFilterEvent = new EventEmitter<PeopleAndLoanFilterOutput>();
  @Output() onCancelFilterEvent = new EventEmitter<boolean>();
  //private existingListOrFilters: PeopleLoanListOrFilter[];
  public disableApplyFilter: boolean;
  public rhsDropdown: RhsDropdown[];
  private storageHelper: StorageHelper;
  private editNode: PeopleAndLoanEditTagData | null;
  private insertIndex: number;
  public rhsDropdownItem: RhsDropdownItem[]
  //public selectedRhsDropdown = null;

  @ViewChild('txtRHSValue1', { static: false }) multiSelect: MultiSelect;
  close() {
    this.multiSelect.overlayVisible = false;
    //this.multiSelect.close(null);
    this.cdr.detectChanges();
  }

  constructor(public formBuilder: FormBuilder, private toastService: ToastService,
    private peopleAndLoanService: PeopleAndLoanService, private cdr: ChangeDetectorRef) {
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.sub = new Array<Subscription>();
    this.peopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(false, null);
    //this.existingListOrFilters = [];
    this.storageHelper = new StorageHelper();
    this.disableApplyFilter = false;
    this.rhsDropdown = [];
    this.insertIndex = 0;
    this.rhsDropdownItem = [];
    //this.selectedRhsDropdown = null;
  }

  ngOnInit(): void {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
  }

  //func called from dropdown 
  setRhsDropDownData(lookupId) {
    let flag = false;
    if (lookupId && this.rhsDropdown.length) {
      let arr = this.rhsDropdown.filter((x) => x.lookupId == lookupId);
      if (arr?.length) {
        flag = true;
        this.rhsDropdownItem = arr[0].rhsDropdownItem;
        this.cdr.detectChanges();
      }
    }
    if (!flag) {
      console.log('No Rhs dropdown.');
    }
  }
  //func called from dropdown 
  getRhsDropDownData(lookupId, isSetFlag = false) {
    //console.log('getRhsDropDownData called...' + lookupId);
    let flag = false;
    if (lookupId && this.rhsDropdown.length) {
      let arr = this.rhsDropdown.filter((x) => x.lookupId == lookupId);
      if (arr?.length) {
        flag = true;
        // if (isSetFlag) {
        //   setTimeout(() => {
        //     if (arr[0].rhsDropdownItem?.length) {
        //       let selectedRhsDropdown = arr[0].rhsDropdownItem?.find(x => x.Code == 'Borrower');
        //       this.setValueForDropdown(selectedRhsDropdown);
        //     }
        //   }, 0);
        // }
        //let ddList = arr[0].rhsDropdownItem.sort((a, b) => { return a.Name.toLowerCase() >= b.Name.toLowerCase() ? 1 : -1 });;
        return arr[0].rhsDropdownItem;
      }
    }
    if (!flag) {
      console.log('No Rhs dropdown.');
    }
  }

  setValueForDropdown(selectedRhsDropdown: RhsDropdownItem) {
    //console.log('setValueForDropdown', selectedRhsDropdown);
    this.textFormGroup.patchValue({
      txtRHSValue1: selectedRhsDropdown,
    }, { onlySelf: false });
    this.cdr.detectChanges();
    // let k = this.textFormGroup?.get('items') as FormArray
    // let lookupControl = k.controls[0]['controls']?.txtRHSValue1;
    // if (lookupControl) {
    //   lookupControl?.setValue(selectedRhsDropdown);
    //   lookupControl?.setValidators([Validators.required]);//reset Validators
    //   this.cdr.detectChanges();
    // }
  }

  operandChangedNew(rhsSelectInputControlSqlCmd, peopleAndLoansFieldLookupId, index, opCode) {//to hide show textbox and remove validations.
    if (index > -1) {
      let showRhsDropdownFlag: boolean = false;
      let query = rhsSelectInputControlSqlCmd ?? '';
      let lookupId = peopleAndLoansFieldLookupId ?? '';
      //to fill Rhs
      if (query) {
        //generate cache key
        let cacheKey = this.peopleAndLoanService.stringToHash(query);
        //fetch data and fill dropdown 
        showRhsDropdownFlag = this.fillMainDropDown(cacheKey, lookupId);
        if (lookupId) {
          this.setRhsDropDownData(lookupId);
        }
      }
      //let isAllContactTypeFlag = this.SelectedFilter?.DataColumnDisplay == 'All Contacts Contact Type';

      let k = this.textFormGroup?.get('items') as FormArray
      if (k && k?.controls?.length) {
        const lookupControl = k.controls[index]['controls']?.txtRHSValue1;
        lookupControl?.clearValidators();//clear Validators
        if (!/\bBLANK\b|\bNOTBLANK\b/.test(opCode)) {
          lookupControl?.setValidators([Validators.required]);//reset Validators
        }
        // set validation if showing dropdown.
        if (showRhsDropdownFlag) {
          lookupControl?.setValidators([Validators.required]);//reset Validators
        }
        // if (isAllContactTypeFlag) {
        //   let lst = this.getRhsDropDownData(lookupId);
        //   if (lst?.length) {
        //     let selectedRhsDropdown = lst.find(x => x.Code == 'Borrower');
        //     //lookupControl?.setValue(selectedRhsDropdown);//reset Validators
        //     // this.textFormGroup.patchValue({
        //     //   txtRHSValue1: selectedRhsDropdown,
        //     // });
        //     this.setValueForDropdown(selectedRhsDropdown);
        //    // lookupControl?.setValue(selectedRhsDropdown);
        //   }
        // }

        lookupControl?.updateValueAndValidity();//validate right now
      }
    }
  }

  //@@##todo
  // operandChangedNew1(index) {//to hide show textbox and remove validations.
  //   if (index > -1) {
  //     let k = this.textFormGroup?.get('items') as FormArray
  //     const lookupControl = k.controls[index]['controls'].txtRHSValue;

  //     lookupControl?.clearValidators();//clear Validators
  //     if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode)) {
  //       lookupControl?.setValidators([Validators.required]);//reset Validators
  //     }
  //     lookupControl?.updateValueAndValidity();//validate right now
  //   }
  // }

  private fillMainDropDown(cacheKey: string, lookupId: any) {
    let showRhsDropdownFlag: boolean = false;
    let item: RhsQueryData = this.storageHelper.GetDataFromStorage(cacheKey)?.value;
    if (item && item?.jsonStr) {
      showRhsDropdownFlag = true;
      if (!this.rhsDropdown.find(x => x.lookupId == lookupId)) { //if not already set
        let RhsDropdownItemArr: RhsDropdownItem[] = JSON.parse(item?.jsonStr);
        let rhsDropdown = new RhsDropdown(lookupId, RhsDropdownItemArr);
        this.rhsDropdown.push(rhsDropdown);
        this.cdr.detectChanges();
      }
    }
    return showRhsDropdownFlag;
  }

  operandChanged(event, index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let showRhsDropdownFlag: boolean = false;
      let query = event?.value?.RhsSelectInputControlSqlCmd ?? '';
      let lookupId = event?.value?.PeopleAndLoansFieldLookupId ?? '';
      this.selectedFilter = event?.value;
      //to fill Rhs
      if (query) {
        //generate cache key
        let cacheKey = this.peopleAndLoanService.stringToHash(query);
        // //fetch data and fill dropdown 
        // let item: RhsQueryData = this.storageHelper.GetDataFromStorage(cacheKey)?.value;
        // if (item && item?.jsonStr) {
        //   showRhsDropdownFlag = true;
        //   // if (!this.rhsDropdown.find(x => x.lookupId == lookupId)) {//if not already set
        //   //   let RhsDropdownItemArr: RhsDropdownItem[] = JSON.parse(item?.jsonStr);
        //   //   let rhsDropdown = new RhsDropdown(lookupId, RhsDropdownItemArr);
        //   //   this.rhsDropdown.push(rhsDropdown)
        //   //   this.cdr.detectChanges();
        //   // }
        //fetch data and fill dropdown 
        showRhsDropdownFlag = this.fillMainDropDown(cacheKey, lookupId);
        if (lookupId) {
          this.setRhsDropDownData(lookupId);
        }
      }

      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue1;
      //const lookupControl2 = k.controls[index]['controls'].txtRHSValue2;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(event.value.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      // set validation if showing dropdown.
      if (showRhsDropdownFlag) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      // if (lookupControl2) {
      //   lookupControl2?.clearValidators();//clear Validators
      //   if (/\BETWEEN\b/.test(event.value.OpCode)) {
      //     lookupControl2?.setValidators([Validators.required]);//reset Validators
      //   }
      //   lookupControl2?.updateValueAndValidity();//validate right now
      // }

      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  private generateForm() {
    this.unSubscribeAllSubs();


    //this.lazyRHSValue = [];
    this.textFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });
    this.cdr.detectChanges();


    if (this.SelectedFilter?.isAddFlag) {
      //this.operandChangedNew1(0);
      //let opCode = this.SelectedFilter?.DataColumnDisplay == 'All Contacts Contact Type' ? 'EQ' : this.SelectedFilter?.OpCode;
      let opCode = this.SelectedFilter?.DataColumnDisplay == 'Contact Type' ? 'EQ' : this.SelectedFilter?.OpCode;

      this.operandChangedNew(this.SelectedFilter?.RhsSelectInputControlSqlCmd,
        this.SelectedFilter?.PeopleAndLoansFieldLookupId, 0, opCode);
    }
    // if (this.lazyRHSValue.length) {
    //   let k = this.textFormGroup?.get('items') as FormArray
    //   for (let index = 0; index < this.lazyRHSValue.length; index++) {
    //     const lookupControl = k.controls[index]['controls'].txtRHSValue1;
    //     lookupControl.patchValue(this.lazyRHSValue[index]);
    //     lookupControl.updateValueAndValidity();
    //   }
    //   this.cdr.detectChanges();
    // }


    let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
    this.items = this.formDataCtrl;

    this.sub.push(
      this.textFormGroup.valueChanges.pipe(
        //debounceTime(500),//delay and  stop unnecessary prorogation.
        distinctUntilChanged()
      ).subscribe(item => {
        listAndFilters.PeopleLoanListOrFilters = [];//clear all
        let idx = 0;
        listAndFilters.id = idx + 1;
        listAndFilters.peopleAndLoansFieldLookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId;

        //Changed json fetching first record value
        if (item?.items?.length) {
          listAndFilters.group = item.items[0].operandDropDown.FieldGroupName;
          listAndFilters.datasource = item.items[0].operandDropDown.DataSource;
          listAndFilters.datacolumn = item.items[0].operandDropDown.DataColumn;
          listAndFilters.type = item.items[0].operandDropDown.DataType;
          if (item.items[0].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
            listAndFilters.subGroup = item.items[0].operandDropDown.FieldSubGroupName;
          }
        }

        let rhsDropDown = this.getRhsDropDownData(listAndFilters.peopleAndLoansFieldLookupId);


        // this.existingListOrFilters = [];
        // let newIndex = -1;
        // let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
        // if (savedFilter?.value) {
        //   let andFilterIndex = -1;
        //   let obj = JSON.parse(JSON.stringify(savedFilter.value));
        //   andFilterIndex = obj?.PeopleLoanList?.PeopleLoanListAndFilters?.length ?? -1;
        //   if (obj && andFilterIndex) {
        //     if (andFilterIndex != -1) {
        //       listAndFilters.id = andFilterIndex + 1;
        //     }

        //     obj.PeopleLoanList.PeopleLoanListAndFilters.forEach(laf => {
        //       if (laf.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
        //         this.existingListOrFilters = laf.PeopleLoanListOrFilters;
        //         return false;//break;
        //       }
        //     });
        //     newIndex = this.existingListOrFilters.length + 1;
        //   }
        // }
        //loop and add/remove validation as per dropdown value.
        item.items.forEach((element, index) => {
          if (index == 0) {
            listAndFilters.PeopleLoanListOrFilters = [];//clear all
          }
          //let rhsCodeValue1, rhsNameValue1 = '', 
          let rhsCodeValue = '', rhsCodeValue1 = '', rhsNameValue1 = '', rhsValue2 = '', criteriaValue = '';
          if (element?.txtRHSValue1?.length) {//if arr
            //loop and remove 
            rhsCodeValue = element?.txtRHSValue1.map(x => x.Code).join(" , ");
            // rhsNameValue1 = element?.txtRHSValue1.map(x => x.Name).join(" , ");
            let arr = element?.txtRHSValue1.map(x => x.Name);
            rhsNameValue1 = arr[0];//take first item only so we can show +1 

            rhsCodeValue1 = "'" + element?.txtRHSValue1.map(x => x.Code).join("','") + "'";
            if (rhsDropDown && rhsDropDown.some(x => x.CriteriaStr)) { //if CriteriaStr is coming (special case)
              let arr = rhsDropDown.filter(x => element?.txtRHSValue1.map(x => x.Code).includes(x.Code));
              if (arr) {
                criteriaValue = arr.map(x => " " + x.CriteriaStr).join(",");
              }
            }
            else { //normal scnerio.
              criteriaValue = rhsCodeValue1;
            }
            //not used
            rhsValue2 = element?.txtRHSValue2;
          }

          let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
          filter.id = (index + 1);
          filter.operator = element.operandDropDown.OpCode;
          filter.lhs = '';
          filter.rhs = [rhsCodeValue, rhsValue2];
          filter.peopleAndLoansTypeDefLookupId = element.operandDropDown.PeopleAndLoansTypeDefLookupId;

          let criteriaQuery = element.operandDropDown.Criteria;
          if (criteriaQuery) {
            criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
            criteriaQuery = criteriaQuery.replace('{{rhs1}}', criteriaValue);
            criteriaQuery = criteriaQuery.replace('{{rhs2}}', rhsValue2);
            filter.criteria = criteriaQuery;
          }

          let htmlTemplate = element.operandDropDown.DisplayHtmlTemplate;
          if (htmlTemplate) {
            //fetch text value of dropdown
            // let rhsDropDown = this.getRhsDropDownData(listAndFilters.peopleAndLoansFieldLookupId);
            // let rhsValueTextValue1: string = '';
            // if (rhsDropDown) {
            //   let arr = rhsDropDown.filter(x => element?.txtRHSValue1.includes(x.Code));
            //   if (arr) {
            //     rhsValueTextValue1 = arr.map(x => " " + x.Name).join(",");
            //   }
            // }
            // if (rhsValue1 && rhsValue1.indexOf("''") != -1) {
            //   rhsValue1 = rhsValue1.replace("''", "'");
            // }

            htmlTemplate = htmlTemplate.replace('{{lhs}}', element.operandDropDown.DataColumnDisplay);
            htmlTemplate = htmlTemplate.replace('{{rhs1}}', rhsNameValue1);
            htmlTemplate = htmlTemplate.replace('{{rhs2}}', rhsValue2);
            let prefix = '';// listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
            filter.displayHtml = prefix + htmlTemplate;
          }
          listAndFilters.PeopleLoanListOrFilters.push(filter)
        });
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
        // if (this.existingListOrFilters?.length) {
        //   this.existingListOrFilters.forEach(filter => {
        //     listAndFilters.PeopleLoanListOrFilters.push(filter)
        //   });
        // }

        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters);

        //validate and show apply button
        if (!this.textFormGroup?.valid) {
          this.disableApplyFilter = true;
        } else {
          this.disableApplyFilter = false;
        }
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');
      })
    );
  }
  applyFilter() {
    if (this.textFormGroup?.valid) {//if json has been received.
      this.validateAndEmitSuccessJson();

    } else {
      this.emitFailureJson();
    }
  }

  private createItems(): FormGroup[] {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //edit filter from input or observable.
    const formGroupArr: FormGroup[] = [];

    //Edit CASE  
    let editNodeTmp = this.storageHelper.GetDataFromStorage('PeopleAndLoan_editNode');//fetch 
    if (editNodeTmp?.value?.peopleAndLoansFieldLookupId &&
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length > 0) {
      this.editNode = editNodeTmp.value;
      let andIndex = this.editNode?.andIndex;
      let lookupId = this.editNode?.peopleAndLoansFieldLookupId;
      //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean

      let toIterate = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.
        filter(x => x.id == andIndex && x.peopleAndLoansFieldLookupId == lookupId);

      let lblValue = editNodeTmp?.value?.labelStr;
      toIterate[0].PeopleLoanListOrFilters.forEach((itm, index) => {
        // if (!lblValue) {
        //    lblValue = itm.displayHtml.substring(0, itm.displayHtml.indexOf("</b>"))
        //    lblValue = lblValue.replace("<b class=\"lhs\">", "").trim();
        // }

        let validatorOrOpts = [
          Validators.required,
          Validators.maxLength(50)
        ];
        let selectedDropdown = this.OperandDropDownList.find(x => x.PeopleAndLoansTypeDefLookupId == itm.peopleAndLoansTypeDefLookupId);
        this.operandChangedNew(selectedDropdown?.RhsSelectInputControlSqlCmd || '',
          lookupId, index, selectedDropdown?.OpCode);

        // let rhsValue = '';
        // if (itm?.rhs?.length) {
        //   rhsValue = itm.rhs[0];
        // }
        let rhsDropDown = this.getRhsDropDownData(lookupId);
        let rhsValueArr: string[] = [];
        let rhsSelectedItem: RhsDropdownItem[] = [];
        if (itm?.rhs?.length) {
          let rhsValueStr = itm?.rhs[0]?.toString().trim();
          rhsValueStr = itm?.rhs[0]?.replace(/ , /g, ','); //trim values
          rhsValueArr = rhsValueStr?.split(",");

          //Build dropdown.
          if (rhsDropDown) {
            rhsSelectedItem = rhsDropDown.filter(x => rhsValueArr.includes(x.Code?.toString()));
          }
        }
        //conditional validation
        if (selectedDropdown && (selectedDropdown.OpCode == 'NOTBLANK' || selectedDropdown.OpCode == 'BLANK')) {
          validatorOrOpts = [];//no validation
        }

        formGroupArr.push(
          this.formBuilder.group({
            label: new FormControl(lblValue),
            operandDropDown: new FormControl(selectedDropdown),
            txtRHSValue1: new FormControl(rhsSelectedItem, validatorOrOpts)
          })
        );
      });
    }
    else {
      // let query = this.SelectedFilter?.RhsSelectInputControlSqlCmd ?? ''; 
      // let lookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId ?? ''; 
      // //to fill Rhs
      // if (query) {
      //   //generate cache key
      //   let cacheKey = this.peopleAndLoanService.stringToHash(query);
      //   //fetch data and fill dropdown 
      //   this.fillMainDropDown(cacheKey, lookupId);
      // }

      let selectedOperandDropdown = null;
      if (this.SelectedFilter?.isAddFlag) {//insert as blank
        let query = this.SelectedFilter?.RhsSelectInputControlSqlCmd ?? '';
        let lookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId ?? '';
        //to fill Rhs
        if (query) {
          //generate cache key
          let cacheKey = this.peopleAndLoanService.stringToHash(query);
          //fetch data and fill dropdown 
          this.fillMainDropDown(cacheKey, lookupId);
        }

        // let isAllContactTypeFlag = this.SelectedFilter?.DataColumnDisplay == 'All Contacts Contact Type';
        // let opCode = isAllContactTypeFlag ? 'EQ' : 'BLANK';
        // selectedOperandDropdown = this.OperandDropDownList.find(x => x.OpCode == opCode);
        // if (isAllContactTypeFlag) {
        //   //select Borrower
        //   let lst = this.getRhsDropDownData(this.selectedFilter.PeopleAndLoansFieldLookupId, true);

        // }
        // if (isAllContactTypeFlag) {
        //   //select Borrower
        //   let lst = this.getRhsDropDownData(this.selectedFilter.PeopleAndLoansFieldLookupId);
        //   if (lst?.length) {
        //     selectedRhsDropdown = lst.find(x => x.Code == 'Borrower');
        //   }
        // }
      }

      formGroupArr.push(
        this.formBuilder.group({
          label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
          operandDropDown: new FormControl(selectedOperandDropdown),
          txtRHSValue1: new FormControl('', [Validators.required]),
        })
      );
    }
    return formGroupArr;
  }
  // private createItems(): FormGroup[] {
  //   const formGroupArr: FormGroup[] = [];
  //   formGroupArr.push(
  //     this.formBuilder.group({
  //       label: new FormControl(this.SelectedFilter?.label),
  //       operandDropDown: new FormControl({ value: '' }),
  //       txtRHSValue1: new FormControl('', [Validators.required, Validators.maxLength(50)]),
  //       //txtRHSValue2: new FormControl('')
  //     })
  //   );
  //   return formGroupArr;
  //   // this.MoreThanStart(this.textFormGroup?.value.txtRHSValue1)
  // }

  // higherThan(field_name): ValidatorFn {

  //   return (control: AbstractControl): { [key: string]: any } => {

  //     const input = control.value;

  //     const isHigher = input < control?.root?.value?.items[field_name];

  //     return isHigher ? null : { 'higherThan': { isHigher: isHigher } };
  //   };
  // }
  public MoreThanStart(end: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return parseInt(control?.value) < end ? { 'moreThan': true } : {};
    };
  }

  get formDataCtrl(): FormArray {
    const formArray = this.textFormGroup?.get('items') as FormArray;
    return formArray;
  }

  deleteRow(index) {
    let k = this.textFormGroup?.get('items') as FormArray
    k.removeAt(index);
  }

  addMore() {
    let newRow = this.formBuilder.group({
      label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
      operandDropDown: new FormControl('', [Validators.required]),
      txtRHSValue1: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      //txtRHSValue2: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    });
    (<FormArray>this.textFormGroup?.get('items')).push(newRow);
  }

  validateAndEmitSuccessJson() {
    //console.log('validateAndEmitSuccessJson-Emitted from here:');
    let len = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length;
    if (len) {
      if (this.editNode?.peopleAndLoansFieldLookupId) {//if it is edit
        let andIndexRemove =
          this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.findIndex(x =>
            x.id == this.editNode?.andIndex &&
            x.peopleAndLoansFieldLookupId == this.editNode.peopleAndLoansFieldLookupId);

        if (andIndexRemove !== -1) {
          this.peopleAndLoanFilters.PeopleLoanList.
            PeopleLoanListAndFilters.splice(andIndexRemove, 1);
        }
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = this.editNode?.andIndex;

      } else {
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = len + 1
      }

      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.forEach(ele => {
      //   if (ele.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
      //     ele.isDeleted = true;//set existing deleted.
      //   }
      // });
      // let tm = this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => x.isDeleted == false);

      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);

    }
    else {//add without any logic 
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);
    }
    this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];//reset 

    // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters =
    //   this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => !x.isDeleted);

    this.peopleAndLoanFilterOutput.isSuccess = true;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = this.peopleAndLoanFilters;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  onListChange(event: any, index: number) {
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue1;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode) && !(event.value?.length)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }

      lookupControl?.updateValueAndValidity();//validate right now
      this.cdr.detectChanges();
    }
  }

  emitFailureJson() {
    this.peopleAndLoanFilterOutput.isSuccess = false;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = null;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  cancelFilter() {
    this.onCancelFilterEvent.emit(true);
  }

  setAndEmit() {
    if (this.selectedFilter) {
      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      //loop
      let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
      filter.id = (this.insertIndex + 1);
      filter.operator = this.selectedFilter.OpCode;
      filter.lhs = '';
      filter.rhs = [''];
      filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
      let criteriaQuery = this.selectedFilter.Criteria;
      if (criteriaQuery) {
        criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
        criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
        filter.criteria = criteriaQuery;
      }

      let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
      if (htmlTemplate) {
        htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
        htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
        //let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
        filter.displayHtml = ' ' + htmlTemplate;
      }
      listAndFilters.PeopleLoanListOrFilters.push(filter);
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
      this.insertIndex++;
      this.validateAndEmitSuccessJson();
    }
  }

  unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  ngOnDestroy(): void {
    this.selectedOperand = null;
    this.OperandDropDownList = null;
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.peopleAndLoanFilters.PeopleLoanList = new PeopleLoanList();//clean all json
    //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
    this.unSubscribeAllSubs();
  }

}

