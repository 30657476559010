<div
  [formGroup]="textMessageFirstFormGroup"
  (ngSubmit)="onNextClick()"
  class="head-pad-top"
>
  <div>
    <div class="text-wrapper grid-section-text">
      <div class="text-wrapper grid-section-details" id="grid">
        <div class="d-flex justify-content-center message-details-form pt-5">
          <div class="mt-2 step-one-form">
            <!-- removed class row -->
            <div class="mb-4">
              <div class="form-label">
                Message Name &nbsp;* &nbsp;
                <label class="text-black-50 fst-italic" style="font-size: 12px"
                  >For internal use only</label
                >
              </div>
              <div class="mb-4">
                <input
                  type="text"
                  maxlength="255"
                  name="txtMessageName"
                  id="txtMessageName"
                  placeholder="Message Name"
                  class="form-control"
                  formControlName="txtMessageName"
                  [class.ng-invalid]="
                    (isFirstPageSubmitted ||
                      textMessageFirstFormGroup.get('txtMessageName')
                        .touched) &&
                    textMessageFirstFormGroup.get('txtMessageName').invalid
                  "
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (isFirstPageSubmitted ||
                      textMessageFirstFormGroup.get('txtMessageName')
                        .touched) &&
                    textMessageFirstFormGroup.get('txtMessageName').errors
                      ?.required
                  "
                  >Message Name is required</span
                >
              </div>
              <div class="form-label">Message Category &nbsp;* &nbsp;</div>
              <div class="mb-4 ground w-75">
                <p-dropdown
                  formControlName="messageCategory"
                  id="message-category-dropdown"
                  panelStyleClass="OverrideIndex"
                  [style]="{
                    width: '100%',
                    border: '1px solid #cccccc',
                    'z-index': '1 !important'
                  }"
                  autoWidth="false"
                  appendTo="body"
                  #messageCategory
                  [options]="messageCategoryList"
                  optionLabel="item_text"
                  optionValue="item_id"
                  placeholder="Select Category"
                >
                </p-dropdown>
                <span
                  class="text-danger"
                  *ngIf="
                    (isFirstPageSubmitted ||
                      textMessageFirstFormGroup.get('messageCategory')
                        .touched) &&
                    textMessageFirstFormGroup.get('messageCategory').errors
                      ?.required
                  "
                  >Category is required</span
                >
              </div>
              <div class="form-label">
                Message Description &nbsp;<label
                  class="text-black-50 fst-italic"
                  style="font-size: 12px"
                  >For internal use only</label
                >
              </div>
              <div class="mb-4">
                <textarea
                  formControlName="txtMessageDescription"
                  id="txtMessageDescription"
                  pInputTextarea
                  type="text"
                  rows="6"
                  maxlength="255"
                  style="
                    width: 100% !important;
                    resize: none;
                    height: 150px;
                    border: 1px solid #cccccc;
                    padding: 8px 10px;
                  "
                ></textarea>
                <span
                  class="text-danger"
                  *ngIf="
                    (isFirstPageSubmitted ||
                      textMessageFirstFormGroup.get('txtMessageDescription')
                        .touched) &&
                    textMessageFirstFormGroup.get('txtMessageDescription')
                      .errors?.required
                  "
                  >Message Description is required</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    (isFirstPageSubmitted ||
                      textMessageFirstFormGroup.get('txtMessageDescription')
                        .touched) &&
                    textMessageFirstFormGroup.get('txtMessageDescription')
                      .errors?.minlength
                  "
                  >Message Description should contain at least 2
                  characters</span
                >
              </div>
              <div *ngIf="currentClientID === 'AduvoAdmin'" class="mb-4 ">
                <div class="p-d-flex my-2">
                  <p-checkbox
                    [formControl]="
                      textMessageFirstFormGroup.controls['chkQuickStart']
                    "
                    binary="true"
                    inputId="chkQuickStart"
                  >
                  </p-checkbox>
                  <label class="control-label">Aduvo Quick Start</label>
                </div>
                <div class="p-d-flex my-2">
                  <p-checkbox
                    [formControl]="
                      textMessageFirstFormGroup.controls['chkRecommended']
                    "
                    binary="true"
                    inputId="chkRecommended"
                  >
                  </p-checkbox>
                  <label class="control-label">Aduvo Recommended</label>
                </div>
              </div>
              <div class="mb-4">
                <p-button
                  (onClick)="onNextClick()"
                  label="Next: Content"
                ></p-button>
                <p-button
                  styleClass="p-button-secondary"
                  label="Cancel"
                  (onClick)="onExitClick()"
                  [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }"
                ></p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
