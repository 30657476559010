import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/appSettings';
import { RequestResponse } from 'src/models/RequestResponse';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HolidayEventVM, TimezoneVM } from 'src/models/LookupModel';
import { ContactsEventList } from 'src/models/LoanStatusType';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import {
  RepeatingWeeklyData,
  RepeatingWeeklyWeekdayData,
} from 'src/models/MessageScheduleData';
import { AnnualMonthWeekDayJson } from '../models/CalendarEvent';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CalendarAndTimeZoneService {
  hours: any[];
  minutes: any[];
  meridiems: any[];
  months: any[];
  weeks: any[];
  days: any[];
  daysArray: any[];
  weekdays: any[];
  startYears: any[];
  yearOptions: any[];
  dateOptions: any[];
  currentYear: number;
  todayDate = new Date();
  categories: any[];
  periodUnit: any[];
  offsetType: any[];
  sendOnDayType: any[];
  constructor(private http: HttpClient, public datePipe: DatePipe) {
    this.offsetType = [
      { name: 'after', code: 'AFTER' },
      { name: 'before', code: 'BEFORE' },
    ];
    this.sendOnDayType = [
      { name: 'Any day', code: 'ANYDAY' },
      { name: 'Weekday', code: 'WEEKDAY' },
    ];
    this.dateOptions = [
      { name: 'Never', code: 'NEVER' },
      { name: 'On', code: 'ON' },
    ];

    this.meridiems = [
      { name: 'AM', code: 'AM' },
      { name: 'PM', code: 'PM' },
    ];
    this.months = [
      { name: 'January', code: '1' },
      { name: 'February', code: '2' },
      { name: 'March', code: '3' },
      { name: 'April', code: '4' },
      { name: 'May', code: '5' },
      { name: 'June', code: '6' },
      { name: 'July', code: '7' },
      { name: 'August', code: '8' },
      { name: 'September', code: '9' },
      { name: 'October', code: '10' },
      { name: 'November', code: '11' },
      { name: 'December', code: '12' },
    ];
    this.daysArray = [
      { name: '1st', code: 1 },
      { name: '2nd', code: 2 },
      { name: '3rd', code: 3 },
      { name: '4th', code: 4 },
      { name: '5th', code: 5 },
      { name: '6th', code: 6 },
      { name: '7th', code: 7 },
      { name: '8th', code: 8 },
      { name: '9th', code: 9 },
      { name: '10th', code: 10 },
      { name: '11th', code: 11 },
      { name: '12th', code: 12 },
      { name: '13th', code: 13 },
      { name: '14th', code: 14 },
      { name: '15th', code: 15 },
      { name: '16th', code: 16 },
      { name: '17th', code: 17 },
      { name: '18th', code: 18 },
      { name: '19th', code: 19 },
      { name: '20th', code: 20 },
      { name: '21st', code: 21 },
      { name: '22nd', code: 22 },
      { name: '23rd', code: 23 },
      { name: '24th', code: 24 },
      { name: '25th', code: 25 },
      { name: '26th', code: 26 },
      { name: '27th', code: 27 },
      { name: '28th', code: 28 },
      { name: '29th', code: 29 },
      { name: '30th', code: 30 },
      { name: '31st', code: 31 }
    ];

    this.weeks = [
      { name: 'First', code: '1' },
      { name: 'Second', code: '2' },
      { name: 'Third', code: '3' },
      { name: 'Fourth', code: '4' },
      { name: 'Last', code: '5' },
    ];
    this.weekdays = [
      { name: 'Sunday', code: '1' },
      { name: 'Monday', code: '2' },
      { name: 'Tuesday', code: '3' },
      { name: 'Wednesday', code: '4' },
      { name: 'Thursday', code: '5' },
      { name: 'Friday', code: '6' },
      { name: 'Saturday', code: '7' },
    ];
    this.yearOptions = [
      { name: 'Never', code: 'NEVER' },
      { name: 'After', code: 'AFTER' },
    ];
    this.categories = [
      { name: 'List of dates', code: 'LOD' },
      { name: 'Annually (month and day)', code: 'ANN' },
      { name: 'Annually (month, week, and weekday)', code: 'AMWD' },
    ];
    this.periodUnit = [
      { name: 'day(s)', code: 'DAY' },
      { name: 'week(s)', code: 'WEEK' },
      { name: 'month(s)', code: 'MONTH' },
      { name: 'year(s)', code: 'YEAR' },
      { name: 'no delay', code: 'NODELAY' },
    ];
  }
  GetStartYears() {
    let startYearsArray = [];
    let currentYear = Number(this.todayDate.getFullYear());
    for (let i = 0; i <= 2; i++) {
      let newYear = {
        code: currentYear + i,
        name: currentYear + i,
      };
      startYearsArray.push(newYear);
    }
    return startYearsArray;
  }
  GetPeriodUnit() {
    return this.periodUnit;
  }
  GetSendOnDayType() {
    return this.sendOnDayType;
  }
  GetOffsetType() {
    return this.offsetType;
  }
  GetDateOption() {
    return this.dateOptions;
  }
  GetEndYears() {
    return this.yearOptions;
  }
  GetCategory() {
    return this.categories;
  }
  GetHours() {
    let hoursArray = [];
    for (let i = 1; i <= 12; i++) {
      var hour = i.toString();
      if (i < 10) {
        hour = '0' + hour; // adding leading zero
      }

      let newHour = {
        code: i,
        name: hour,
      };
      hoursArray.push(newHour);
    }
    return hoursArray;
  }
  GetMinutes() {
    let minutesArray = [];
    for (let i = 0; i <= 59; i++) {
      var minute = i.toString();
      if (i < 10) {
        minute = '0' + minute; // adding leading zero
      }
      let newMinute = {
        code: i,
        name: minute,
      };
      minutesArray.push(newMinute);
    }
    return minutesArray;
  }
  GetMeridiems() {
    return this.meridiems;
  }
  GetWeeks() {
    return this.weeks;
  }
  GetWeekDays() {
    return this.weekdays;
  }
  GetMonths() {
    return this.months;
  }
  async GetJsonMonths() {
    return await this.http
      .get<RequestResponse>('assets/files/aduvo-months.json')
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  getListOfDaysRange(mode: string) {
    const dayCount = -1;
    let newdaysArray = [];
    if (mode == 'DAY' || mode == 'YEAR') {
      this.currentYear = this.todayDate.getFullYear();
      let currentMonth = this.todayDate.getMonth();
      const dayCount = this.getDaysInMonths(this.currentYear, currentMonth + 1);
      newdaysArray = this.daysArray.filter(function (op) {
        if (op.code < dayCount) {
          return op.name;
        }
      });
      if (mode == 'DAY') {
        let lastDay = {
          code: dayCount,
          name: 'Last',
        };
        newdaysArray.push(lastDay);
      } else {

        newdaysArray.length = 0;
        for(let i = 1;i< 11; i++){
          newdaysArray.push({
            code: i,
            name: `${i}`,
          });
        }
        
      }
    } else if (mode == 'WEEK') {
      const dayCount = 5;
      newdaysArray = this.daysArray.filter(function (op) {
        if (op.code < dayCount) {
          return op.name;
        }
      });
      let lastDay = {
        code: 5,
        name: 'Last',
      };
      newdaysArray.push(lastDay);
    } else if (mode == 'MONTH') {
      const dayCount = 12;
      newdaysArray = this.daysArray.filter(function (op) {
        if (op.code < dayCount) {
          return op.name;
        }
      });
      let lastDay = {
        code: 12,
        name: 'Last',
      };
      newdaysArray.push(lastDay);
    } else if (mode == 'NODELAY') {
      let lastDay = {
        code: ' ',
        name: ' ',
      };
      newdaysArray.push(lastDay);
    }

    return newdaysArray;
  }
  getListOfDays(selectedMonth: number) {
    this.currentYear = this.todayDate.getFullYear();
    const dayCount = this.getDaysInMonths(
      this.currentYear,
      Number(selectedMonth)
    );
    let newdaysArray = this.daysArray.filter(function (op) {
      if (op.code < dayCount) {
        return op.name;
      }
    });
    let lastDay = {
      code: 0,
      name: 'Last',
    };
    newdaysArray.push(lastDay);
    return newdaysArray;
  }
  getDaysInMonth(year: number, month: number) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }
  getDaysInMonths(year: number, month: number) {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;

      case 2:
        return 29;

      case 4:
      case 6:
      case 9:
      case 11:
        return 30;
    }
  }
  getTimeSpanNext(hours: number, minutes: number, meridiem: string) {
    let time = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    let Hour = Number(hours);
    time.minutes = Number(minutes);
    if (meridiem == 'AM') {
      time.hours = Hour == 12 ? 0 : Hour;
    } else {
      time.hours = Hour == 12 ? 12 : 12 + Hour;
    }
    return time;
  }
  getTimeSpan(hours: number, minutes: number, meridiem: string) {
    let time = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    let Hour = Number(hours);
    time.minutes = Number(minutes);
    if (meridiem == 'AM') {
      time.hours = Hour;
    } else {
      time.hours = 12 + Hour;
    }
    return time;
  }
  getNextScheduleMessage(
    timezoneInfoId: string,
    timezoneOffset: number,
    scheduleJson: string,
    hours: number,
    minutes: number,
    seconds: number,
    meridiem: string
  ) {
    // let currentHour = currentTimeZoneDateTime.getHours() + 2;
    // let currentTimeZoneDateTimeByTwoHrs = new Date(
    //   currentTimeZoneDateTime.getFullYear(),
    //   currentTimeZoneDateTime.getMonth(),
    //   currentTimeZoneDateTime.getDate(),
    //   currentHour,
    //   0,
    //   0
    // );
    let timeSpan = this.getTimeSpanNext(hours, minutes, meridiem);
    var scheduleDate = this.getNextScheduleDate(
      scheduleJson,
      timezoneOffset,
      timeSpan.hours,
      timeSpan.minutes,
      seconds
    );
    if (scheduleDate != 'NO_SCHEDULE') {
      var nextNotification = `Next schedule time will be ${this.datePipe.transform(
        scheduleDate,
        'M/d/yyyy hh:mm a'
      )} ${timezoneInfoId}`;
      return nextNotification;
    } else {
      return '';
    }
  }
  getMeridiem(hour) {
    var meridim = 'AM';
    if (hour >= 8 && hour <= 11) {
      meridim = 'AM';
    } else {
      meridim = 'PM';
    }
    return meridim;
  }
  getOrdinalNumber(day) {
    if (parseInt(day) == 0) {
      return 'Last';
    } else if (parseInt(day) == 1) {
      return 'First';
    } else if (parseInt(day) == 2) {
      return 'Second';
    } else if (parseInt(day) == 3) {
      return 'Third';
    } else if (parseInt(day) == 4) {
      return 'Fourth';
    } else if (parseFloat(day) == parseInt(day) && !isNaN(day)) {
      var s = ['th', 'st', 'nd', 'rd'],
        v = day % 100;
      return day + (s[(v - 20) % 10] || s[v] || s[0]);
    } else {
      day = 0;
    }
    return day;
  }
  getNextScheduleDate(
    scheduleJson: string,
    timezoneOffset: number,
    hours: number,
    minutes: number,
    seconds: number
  ) {
    var scheduleDate;
    let savedObject = new AnnualMonthWeekDayJson();
    let startYear = this.todayDate.getFullYear();
    let endYear = this.todayDate.getFullYear() + 1;
    savedObject = scheduleJson ? JSON.parse(scheduleJson) : {};
    let categoryType: string = '';
    let currentTimeZoneDateTime = this.getCountryDateTime(timezoneOffset);
    let currentHour = currentTimeZoneDateTime.getHours() + 2;
    let currentTimeZoneDateTimeByTwoHrs = new Date(
      currentTimeZoneDateTime.getFullYear(),
      currentTimeZoneDateTime.getMonth(),
      currentTimeZoneDateTime.getDate(),
      currentHour,
      0,
      0
    );
    if (savedObject) {
      if (
        savedObject.method == 'loananniversary' ||
        savedObject.method == 'birthday'
      ) {
        categoryType = 'LOB';
      } else if (savedObject.method == 'list') {
        var listOfDatesCount = savedObject.arguments.arg1.split(',').length;
        if (listOfDatesCount > 0) {
          var listOfDates = savedObject.arguments.arg1.split(',');
          for (let i = 0; i < listOfDatesCount; i++) {
            var dateArray = listOfDates[i].split('/');
            scheduleDate = new Date(
              parseInt(dateArray[2]),
              parseInt(dateArray[0]) - 1,
              parseInt(dateArray[1]),
              hours,
              minutes,
              seconds
            );
            if (scheduleDate >= currentTimeZoneDateTimeByTwoHrs) {
              return scheduleDate;
            }
          }
          return 'NO_SCHEDULE';
        }
      } else if (savedObject.method == 'annually-monthday') {
        var argCount = savedObject.methodDisplay.split(',').length;
        if (argCount == 2) {
          categoryType = 'AMD';
          var monthCode = parseInt(savedObject.arguments.arg1);
          var dayCode = parseInt(savedObject.arguments.arg2);
          let totalDeliverCount = endYear > startYear ? endYear - startYear : 0;
          for (let i = 0; i <= totalDeliverCount; i++) {
            let newYear = startYear + i;
            if (dayCode == 0) {
              var lastDay = this.getDaysInMonths(newYear, monthCode);
              scheduleDate = new Date(
                newYear,
                monthCode - 1,
                lastDay,
                hours,
                minutes,
                seconds
              );
              if (scheduleDate >= currentTimeZoneDateTimeByTwoHrs) {
                return scheduleDate;
              }
            } else {
              scheduleDate = new Date(
                newYear,
                monthCode - 1,
                dayCode,
                hours,
                minutes,
                seconds
              );
              if (scheduleDate >= currentTimeZoneDateTimeByTwoHrs) {
                return scheduleDate;
              }
            }
          }
          return 'NO_SCHEDULE';
        }
      } else if (savedObject.method == 'annually-monthweekday') {
        var argCount = savedObject.methodDisplay.split(',').length;
        if (argCount > 2) {
          categoryType = 'AMWD';
          var ordinalCode = 1;
          var monthCode = parseInt(savedObject.arguments.arg1);
          if (
            savedObject.arguments.arg2.toString().toLowerCase() == '0' ||
            savedObject.arguments.arg2.toString().toLowerCase() == 'last'
          ) {
            ordinalCode = 5;
          } else {
            ordinalCode = parseInt(savedObject.arguments.arg2);
          }
          var weekDayCode = parseInt(savedObject.arguments.arg3);
          let totalDeliverCount = endYear > startYear ? endYear - startYear : 0;
          for (let i = 0; i <= totalDeliverCount; i++) {
            let newYear = startYear + i;
            var latestDate = this.GetNoOfXWeekDayDate(
              newYear,
              weekDayCode,
              monthCode,
              ordinalCode
            );
            scheduleDate = new Date(
              latestDate.getFullYear(),
              latestDate.getMonth(),
              latestDate.getDate(),
              hours,
              minutes,
              seconds
            );
            if (scheduleDate >= currentTimeZoneDateTimeByTwoHrs) {
              return scheduleDate;
            }
          }
          return 'NO_SCHEDULE';
        }
      }
    }
    return 'NO_SCHEDULE';
  }
  DaysInMonth(year: number, month: number) {
    return new Date(year, month - 1, 0).getDate();
  }
  GetNoOfXWeekDayDate(
    year: number,
    weekDay: number,
    month: number,
    weekDayCount: number
  ) {
    let XDateOfMonth = new Date(year, month - 1, 1);
    if (weekDayCount == 5) {
      return this.GetLastWeekDayOfTheMonth(XDateOfMonth, weekDay);
    } else {
      let dayOfWeek = this.weekdays[weekDay - 1].name;
      let count = 0;
      while (count < weekDayCount) {
        let xDateOfMonthWeek = this.weekdays[XDateOfMonth.getDay()].name;
        if (xDateOfMonthWeek == dayOfWeek) {
          count++;
        }

        if (count == weekDayCount) {
          return XDateOfMonth;
        }

        XDateOfMonth.setDate(XDateOfMonth.getDate() + 1);
      }
      return XDateOfMonth;
    }
  }
  GetLastWeekDayOfTheMonth(date: Date, weekdayCode: number) {
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var weekday = new Array();
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';
    return weekday[lastDayOfMonth.getDay()];
  }
  /**
   * Error processing.
   * @param caught The caught error information.
   */
  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }
  public getTimeZoneDisplayName(
    timezoneId: string,
    timezoneList: TimezoneVM[]
  ) {
    return timezoneList.find((x) => x.timezoneId.toLowerCase() === timezoneId)
      ?.displayName;
  }
  public getHolidayDisplayName(
    holidayEventId: string,
    holidayEventList: HolidayEventVM[]
  ) {
    return holidayEventList.find(
      (x) => x.holidayEventId.toLowerCase() === holidayEventId
    )?.name;
  }
  public getContactEventDisplayName(
    contactEventId: string,
    contactsEventList: ContactsEventList[]
  ) {
    return contactsEventList.find(
      (x) => x.contactEventId.toLowerCase() === contactEventId
    )?.name;
  }
  public Get12HourTime(hr: string, min: string, meri: string) {
    if (parseInt(hr) < 10) hr = '0' + hr.toString();
    if (parseInt(min) < 10) min = '0' + min.toString();
    return `${hr}:${min} ${meri} `;
  }
  public GetHourTime(hr: string, min: string) {
    let meri = 'AM';
    if (parseInt(hr) < 10) hr = '0' + hr.toString();
    if (parseInt(hr) > 12) {
      hr = (parseInt(hr) - 12).toString();
      meri = 'PM';
    }
    if (parseInt(min) < 10) min = '0' + min.toString();
    return `${hr}:${min} ${meri} `;
  }
  public getCountryDateTime(offset) {
    // create Date object for current location
    var d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    var utc = d.getTime() + d.getTimezoneOffset() * 60000;

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + 3600000 * offset);

    // return time as a string
    return nd;
  }
  CustomeDateValidators(from: string, to: string, option: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      let o = group.controls[option];
      if (f.value > t.value && o.value == 'ON') {
        return {
          dates: 'Starting date should be less than Ending date',
        };
      }
      return null;
    };
  }
  occurrenceRangeValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (
      control.value !== undefined &&
      (isNaN(control.value) || control.value < 1 || control.value > 20)
    ) {
      return { occurrenceRange: true };
    }
    return null;
  }
  CustomeDuplicateValidators() {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const controlsArray = formArray.controls.map((control) => control.value);
      //const names = controlsArray.map((value) => value.username);
      const hasDuplicate = controlsArray.some((opt) => {
        let counter = 0;
        for (const iterator of controlsArray) {
          if (
            iterator.otherWeekDay === opt.otherWeekDay &&
            iterator.otherHour === opt.otherHour &&
            iterator.otherMinute === opt.otherMinute &&
            iterator.otherMeridiem === opt.otherMeridiem &&
            iterator.otherTimeZone === opt.otherTimeZone
          ) {
            counter += 1;
          }
        }
        return counter > 1;
      });
      return hasDuplicate ? { duplicate: true } : null;
    };
    return validator;
  }
  getDefaultDateTimeObject(time_zone_id, timezoneList: TimezoneVM[]) {
    let timezoneOffset = timezoneList.find(
      (x) => x.timezoneId.toLowerCase() === time_zone_id
    )?.currentTimeZoneOffsetInHours;

    let currentTimeZoneDateTime = this.getCountryDateTime(timezoneOffset);
    let currentHour = 11; //currentTimeZoneDateTime.getHours() + 2;
    let currentTimeZoneDateTimeByHrs = new Date(
      currentTimeZoneDateTime.getFullYear(),
      currentTimeZoneDateTime.getMonth(),
      currentTimeZoneDateTime.getDate(),
      currentHour,
      0,
      0
    );
    let scheduleDate = new Date();
    if (currentTimeZoneDateTime > currentTimeZoneDateTimeByHrs) {
      scheduleDate = new Date(
        currentTimeZoneDateTime.getFullYear(),
        currentTimeZoneDateTime.getMonth(),
        currentTimeZoneDateTime.getDate(),
        11,
        0,
        0
      );
      scheduleDate.setDate(scheduleDate.getDate() + 1);
    } else {
      scheduleDate = new Date(
        currentTimeZoneDateTime.getFullYear(),
        currentTimeZoneDateTime.getMonth(),
        currentTimeZoneDateTime.getDate(),
        11,
        0,
        0
      );
    }
    var hours = scheduleDate.getHours() % 12;
    // Check whether AM or PM
    var newformat = scheduleDate.getHours() >= 12 ? 'PM' : 'AM';

    let defaultTimeArray = {
      defaultHour: hours == 0 ? 12 : hours,
      defaultMinute: scheduleDate.getMinutes(),
      defaultMeridiem: newformat,
      defaultTimeZoneId: time_zone_id,
      defaultDate: scheduleDate,
    };
    return defaultTimeArray;
  }
  getDefaultDateTimeObjectByNoOfMinute(time_zone_id, timezoneList: TimezoneVM[],noOfMinutes) {
      let timezoneOffset = timezoneList.find(
        (x) => x.timezoneId.toLowerCase() === time_zone_id
      )?.currentTimeZoneOffsetInHours;

    //let currentTimeZoneDateTime = new Date();
    //let timezoneOffsetLocal = currentTimeZoneDateTime.getTimezoneOffset();
    // let timezoneOffsetLocalInhrs = -(new Date().getTimezoneOffset() / 60);
    //  let timezoneOffsetId = timezoneList.find(
    //    (x) =>
    //      x.currentTimeZoneOffsetInHours ===
    //      timezoneOffsetLocalInhrs
    //  )?.timezoneId;
    let currentTimeZoneDateTime = this.getCountryDateTime(timezoneOffset);
    //let currentMinutes = 11; //currentTimeZoneDateTime.getHours() + 2;
    // let currentTimeZoneDateTimeByHrs = new Date(
    //   currentTimeZoneDateTime.getFullYear(),
    //   currentTimeZoneDateTime.getMonth(),
    //   currentTimeZoneDateTime.getDate(),
    //   currentTimeZoneDateTime.getHours(),
    //   currentTimeZoneDateTime.getMinutes(),
    //   0
    // );
    currentTimeZoneDateTime.setMinutes(
      currentTimeZoneDateTime.getMinutes() + parseInt(noOfMinutes)
    );
    var hours = currentTimeZoneDateTime.getHours() % 12;
    // Check whether AM or PM
    var newformat = currentTimeZoneDateTime.getHours() >= 12 ? 'PM' : 'AM';
    let defaultTimeArray = {
      defaultHour: hours == 0 ? 12 : hours,
      defaultMinute: currentTimeZoneDateTime.getMinutes(),
      defaultMeridiem: newformat,
      defaultTimeZoneId: time_zone_id,
      defaultDate: currentTimeZoneDateTime,
    };
    return defaultTimeArray;
  }
  // Add ordinal to a number
  addOrdinal(n) {
    var ord = [, 'st', 'nd', 'rd'];
    var a = n % 100;
    return n + (ord[a > 20 ? a % 10 : a] || 'th');
  }

  // Return the ordinal number of a day in the month
  ordinalDay(d) {
    d = d || new Date();
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return this.addOrdinal(Math.ceil(d.getDate() / 7)) + ' ' + days[d.getDay()];
  }
  getOrdinal(d) {
    d = d || new Date();
    return Math.ceil(d.getDate() / 7);
  }
  get12HourTimeString(timeStr: string) {
    //HH:MM AMPM
    let hours = '',
      minutes = '',
      AmPM = '';

    if (timeStr?.length >= 6) {
      var timeArr = timeStr.substring(0, 6).split(':'); //[HH:MM]
      if (parseInt(timeArr[0]) >= 12) {
        if (parseInt(timeArr[0]) == 12) {
          hours = parseInt(timeArr[0]).toString(); //PM
        } else {
          hours = (parseInt(timeArr[0]) - 12).toString(); //PM
        }
        AmPM = 'PM';
      } else {
        if (parseInt(timeArr[0]) == 0) {
          hours = '12'; //AM
        } else {
          hours = parseInt(timeArr[0]).toString(); //AM
        }
        AmPM = 'AM';
      }
      minutes = parseInt(timeArr[1]).toString();
      hours = '0' + hours;
      hours = hours.substring(hours.length - 2);
      minutes = '0' + minutes;
      minutes = minutes.substring(minutes.length - 2);
    } else {
      return `00: 00 AM`;
    }

    return `${hours}:${minutes} ${AmPM} `;
  }
}

// export class KeyValuePair {
//   /**
//    *
//    */
//   constructor(nameValue: string = "", codeValue: number = 0) {
//     this.code = codeValue;
//     this.name = nameValue;
//   }
//   public code: number;
//   public name: string;
// }
