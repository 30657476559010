import { Pipe, PipeTransform } from '@angular/core';
import { MessageTypes } from 'src/enums/message-types';

@Pipe({
  name: 'messageType'
})
export class MessageTypePipe implements PipeTransform {

  transform(deviceType: number): string {

    if(deviceType === MessageTypes.EMAIL){
      return 'Email';
    }
    else if(deviceType === MessageTypes.SMS){
      return 'SMS';
    }

    return 'Unknown';
  }

}
