import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { Observable, throwError } from 'rxjs'; 
import { catchError, tap } from 'rxjs/operators'; 
import { RequestResponse } from 'src/models/RequestResponse' 
import { PagingData } from 'src/models/PagingData'; 
import { AppSettings } from 'src/appSettings'; 
 
@Injectable() 
export class StripoService { 
  constructor(private http: HttpClient) { } 
 
  /**  
   * Retrieves a new Stripo Plugin Token for the default Pulse tenant. 
   *  
   * @param requestResponse - optional in oder to specify specific service information. 
   */ 
  async getNewPulsePluginToken(requestResponse?: RequestResponse) { 
    // Angular does not support posting a body inside the /GET verb. 
    // https://github.com/angular/angular/issues/9927 
 
    // if we were not passed a RequestResponse object, use the default. 
    if ((requestResponse === undefined) || (requestResponse === null)) 
      requestResponse = new RequestResponse(); 
 
    //console.log("Calling default /POST WebApi 'Stripo' Controller action.");

    return await this.http.post<RequestResponse>(AppSettings.API_GET_STRIPO_TOKEN + '/Get', requestResponse) 
      .pipe(catchError(this.handleError)).toPromise(); 
  }

  /**  
   * NOT IMPLEMENTED IN API. Retrieves a new Stripo Plugin Token for the user's tenant. 
   *  
   * @param requestResponse - optional in oder to specify specific service information. 
   */
  async getNewTenantPluginToken(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb. 
    // https://github.com/angular/angular/issues/9927 

    // if we were not passed a RequestResponse object, use the default. 
    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    //console.log("Calling the 'GetTenantToken' /POST WebApi 'Stripo' Controller action.");

    //return await this.http.get<RequestResponse>(AppSettings.API_GET_STRIPO_TOKEN + '/GetTenantToken', requestResponse)
    return await this.http.get<RequestResponse>(AppSettings.API_GET_STRIPO_TOKEN + '/DefaultToken')
      .pipe(catchError(this.handleError)).toPromise();
  }

  /**
  * Error processing. 
  *  
  * @param caught The caught error information. 
  */ 

  private handleError(caught: any) {
    console.error(caught?.error?.message);
    return throwError(caught?.error?.message ?? 'Error while connecting to server!');
  }
} 
