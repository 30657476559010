import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, observable, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserDefineList } from '../../src/app/models/UserDefineList';
import { AppSettings } from '../appSettings';
import { environment } from '../environments/environment';
import { StorageHelper } from '../helpers/StorageHelper';
import { RhsQueryData } from '../models/PeopleAndLoanFilterOutput';
import { RequestResponse } from '../models/RequestResponse';
import { SearchParam } from '../app/models/SearchParam';


@Injectable()
export class PeopleAndLoanService {
  storageHelper: StorageHelper;
  expiredTimeInHour: number;
  expiredTimeInMins: number;
  expiredTimeInHourForFilter: number;

  constructor(private http: HttpClient) {
    this.storageHelper = new StorageHelper();
    this.expiredTimeInHour = environment.SessionStorageExpiredTimeInHourForPeopleAndLoanRHSQuery;
    this.expiredTimeInHourForFilter = 720;//24Hour* 30 Days= 720 Hour
    this.expiredTimeInMins = 5;//5 mins
  }
  public GetLoanDetail(loanIds: Array<string>): Observable<RequestResponse> {
    console.info('Call /GetReports list in the webapi List Controller');
    return this.http.post<RequestResponse>(
      AppSettings.API_GET_REPORTS + '/GetLoanDetails',
      loanIds
    );
  }
  public GetAllList(searchParam: SearchParam): Observable<RequestResponse> {
    console.info('Call /GetReports list in the webapi List Controller');
    return this.http.post<RequestResponse>(
      AppSettings.API_GET_REPORTS + '/AllList',
      searchParam
    );
  }

  public GetAllContact(
    searchParam: SearchParam
  ): Observable<RequestResponse> {
    console.info(
      'Call /GetReportsListResult list in the webapi List Controller'
    );
    return this.http.post<RequestResponse>(
      AppSettings.API_GET_REPORTS + '/AllContact?',
      searchParam
    );
  }

  public GetMasterList(): Observable<RequestResponse> {
    const url = `${AppSettings.API_GET_LOAN_STATUS_TYPE}/GetMasterList`;
    return this.http.get<RequestResponse>(url);
  }

  async GetDataAccordingToMasterListIdOrTitle(viewList?: UserDefineList) {
    console.info('Call /Get list in the webapi List Controller');
    return await this.http
      .post<any>(
        AppSettings.API_GET_REPORTS + '/GetMasterListByIdOrTitle',
        viewList
      )
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async DeleteList(masterListId: string) {
    console.info('Call /Delete list in the webapi List Controller');
    return await this.http
      .delete<any>(AppSettings.API_GET_REPORTS + '/' + masterListId)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async SaveList(createUserDefinedList?: UserDefineList) {
    console.info('Call /Post saveList in the webapi List Controller');
    return await this.http
      .post<any>(
        AppSettings.API_GET_REPORTS + '/CreateList',
        createUserDefinedList
      )
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async EditList(userDefineList?: UserDefineList) {
    console.info('Call /Put editList in the webapi List Controller');
    return await this.http
      .put<any>(AppSettings.API_GET_REPORTS + '/EditList', userDefineList)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetUserPreferedGridColumns(preferenceKey: string) {
    console.info(
      'Call /GetUserPreferedGridColumns in the webapi messages Controller'
    );
    return await this.http
      .get<any>(AppSettings.API_GET_REPORTS + '/' + preferenceKey)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async AddUpdateUserPreferedGridColumns(tagVal: any) {
    return await this.http
      .post<any>(AppSettings.API_GET_REPORTS + '/UserPreference', {
        UserPreferenceKey: 'PeopleAndLoanGridCol',
        SaveAsSingleValue: false,
        PreferenceJson: tagVal,
      })
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }

  public GetLatestLoanStatusTypes(): Observable<RequestResponse> {
    const url = `${AppSettings.API_GET_LOAN_STATUS_TYPE}/GetLatestLoanStatusTypes`;
    return this.http.get<RequestResponse>(url);
  }

  getDataForRhsQuery(rhsQueryData: RhsQueryData[]) {
    let total = rhsQueryData?.length;
    let index = 0;
    rhsQueryData.forEach((qry: RhsQueryData) => {
      if (qry.cacheKey) {
        let cacheKey = qry.cacheKey;
        let item: RhsQueryData =
          this.storageHelper.GetDataFromStorage(cacheKey)?.value;
        if (item && item.cacheKey) {
          // checking item obj not values as value can be zero
          var d = rhsQueryData.find((x) => x.cacheKey == cacheKey);
          if (d) {
            d.jsonStr = item.jsonStr;
          }
        }
      }
    });
  }

  setDataForRhsQueryById(rhsQueryData: RhsQueryData[], storageKey: string = 'PL_STATUS') {
    
    this.storageHelper.SetDataInStorage(
      storageKey,
      'loading',
      this.expiredTimeInHour
    );
    let total = rhsQueryData?.length;
    let index = 0;
    rhsQueryData.forEach((qry: RhsQueryData) => {
      if (qry.cacheKey) {
        let cacheKey = qry.cacheKey;
        let item: RhsQueryData =
          this.storageHelper.GetDataFromStorage(cacheKey)?.value;
        index++;
        if (!item) {
          // checking item obj not values as value can be zero
          const url = `${AppSettings.API_GET_LOAN_STATUS_TYPE}/GetJSONByQuery`;
          
          this.http.get<RequestResponse>(`${AppSettings.API_GET_REPORTS}/Filter/${qry.id}`).subscribe(
            (response) => {
              if (response?.data) {
                let item = new RhsQueryData(
                  cacheKey,
                  qry.query,
                  response?.data?.toString()
                );
                this.storageHelper.SetDataInStorage(
                  cacheKey,
                  item,
                  this.expiredTimeInHour
                );
                console.log(`${index}: ${cacheKey} Loaded`);
              }
              if (index >= total) {
                this.storageHelper.SetDataInStorage(
                  //'PL_STATUS',
                  storageKey,
                  'loaded',
                  this.expiredTimeInHour
                );
              }
            },
            (err) => {
              this.storageHelper.SetDataInStorage(
                //'PL_STATUS',
                storageKey,
                '',
                this.expiredTimeInHour
              );
            }
          );

        } else {
          if (index >= total) {
            this.storageHelper.SetDataInStorage(
              //'PL_STATUS',
              storageKey,
              'loaded',
              this.expiredTimeInHour
            );
          }
        }
      }
    });
  }

  setDataForRhsQueryByCategory(rhsQueryData: RhsQueryData[], storageKey: string = 'PL_STATUS') {
    
    this.storageHelper.SetDataInStorage(
      storageKey,
      'loading',
      this.expiredTimeInHour
    );
    let total = rhsQueryData?.length;
    let index = 0;
    rhsQueryData.forEach((qry: RhsQueryData) => {
      if (qry.cacheKey) {
        let cacheKey = qry.cacheKey;
        let item: RhsQueryData =
          this.storageHelper.GetDataFromStorage(cacheKey)?.value;
        index++;
        if (!item) {
          // checking item obj not values as value can be zero
          const url = `${AppSettings.API_GET_REPORTS}/Filter/Category/${qry.queryKey}`
          
          this.http.get<RequestResponse>(url).subscribe(
            (response) => {
              if (response?.data) {
                let item = new RhsQueryData(
                  cacheKey,
                  qry.query,
                  response?.data?.toString()
                );
                this.storageHelper.SetDataInStorage(
                  cacheKey,
                  item,
                  this.expiredTimeInHour
                );
                console.log(`${index}: ${cacheKey} Loaded`);
              }
              if (index >= total) {
                this.storageHelper.SetDataInStorage(
                  //'PL_STATUS',
                  storageKey,
                  'loaded',
                  this.expiredTimeInHour
                );
              }
            },
            (err) => {
              this.storageHelper.SetDataInStorage(
                //'PL_STATUS',
                storageKey,
                '',
                this.expiredTimeInHour
              );
            }
          );

        } else {
          if (index >= total) {
            this.storageHelper.SetDataInStorage(
              //'PL_STATUS',
              storageKey,
              'loaded',
              this.expiredTimeInHour
            );
          }
        }
      }
    });
  }

  stringToHash(cacheKey: string): string {
    var hash = 0;
    if (cacheKey.length == 0) return hash.toString();

    for (var i = 0; i < cacheKey.length; i++) {
      var char = cacheKey.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return hash.toString();
  }

  public async getFilterJsonAsync(fileName: string): Promise<string> {
    let cacheKey = 'plf_' + this.stringToHash(fileName.toLowerCase().trim());
    let item = this.storageHelper.GetDataFromStorage(cacheKey);
    if (item?.value) { // as value can be zero 
      return Promise.resolve(item.value);
    } else {
      const url = `${AppSettings.API_GET_REPORTS}/DownloadListFilterFile?fileName=${fileName}`;
      try {
        const response = await this.http.post<RequestResponse>(url, null).toPromise();
        const data = response.data;
        this.storageHelper.SetDataInStorage(cacheKey, data, this.expiredTimeInHourForFilter);
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }

  public getFilterJson(fileName: string) {
    let data = '';
    let cacheKey = 'plf_' + this.stringToHash(fileName.toLowerCase().trim());
    let item = this.storageHelper.GetDataFromStorage(cacheKey);
    if (item?.value) {// as value canbe zero 
      return item.value;
    } else { //api/v1/Lists/DownloadListFilterFile?fileName=Loan.json
      const url = `${AppSettings.API_GET_REPORTS}/DownloadListFilterFile?fileName=` + fileName;
      this.http.post<RequestResponse>(url, null).toPromise().then((x) => {
        data = x.data;
        this.storageHelper.SetDataInStorage(cacheKey, data, this.expiredTimeInHourForFilter);
        return data;
      })
    }
  }
  public filterJsonLoaded(fileName: string) {
    let cacheKey = 'plf_' + this.stringToHash(fileName.toLowerCase().trim());
    let item = this.storageHelper.GetDataFromStorage(cacheKey);
    if (item?.value) {
      return true;
    } else {
      return false;
    }
  }


  public GetQuickFilters(preferenceKey: string) {
    return this.http
      .get<any>(AppSettings.API_GET_REPORTS + '/' + preferenceKey)
      .pipe(catchError(this.handleError));
  }

  public reSetDataPeriodicallyForTags() {
    let index = 0;
    let query = 'select TagId as Code, [Name] from dbo.Tag where TenantId=@TenantId and deleted=0 order by [Name]';
    let cacheKey = this.stringToHash(query);
    if (cacheKey && query) {
      this.storageHelper.ClearStorageByKey(cacheKey);//clear old
      const url = `${AppSettings.API_GET_LOAN_STATUS_TYPE}/Filter/Tags`;
      this.http.get<RequestResponse>(url).subscribe((response) => {
        if (response?.data) {
          let item = new RhsQueryData(cacheKey, query, response?.data?.toString());
          this.storageHelper.SetDataInStorage(cacheKey, item, 0, this.expiredTimeInMins);
          console.log(`${index}: ${cacheKey} Re Loaded `);
        }
      }, (err) => { 
        console.log(`Error in Re Loading: ${cacheKey}`); 
      });
    }
  }
}
