import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { RequestResponse } from 'src/models/RequestResponse'
import { AppSettings } from 'src/appSettings';
import { Observable, throwError, of } from 'rxjs';
import { StorageHelper } from 'src/helpers/StorageHelper';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  storageHelper: StorageHelper;
  constructor(private http: HttpClient) {
    this.storageHelper = new StorageHelper();
  }

  /**
   * Gets a collection of Campaign records.
   * @param requestResponse
   */
  // async GetCategories(requestResponse?: RequestResponse) {
  //   if ((requestResponse === undefined) || (requestResponse === null))
  //     requestResponse = new RequestResponse();

  //   console.info("Call /GetAllMessages in the webapi lookup Controller");
  //   return await this.http.get<RequestResponse>(AppSettings.API_GET_LOOKUP + "categories")
  //     .pipe(catchError(this.handleError)).toPromise();
  // }

  /**
   * Gets a collection of Campaign category records.
   * @param requestResponse
   */

  GetCampaignCategories(
    isPublishingDb: boolean = false
  ): Observable<RequestResponse> {
    let cacheKey = 'CategoriesKey';
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      return this.http
        .get<RequestResponse>(
          AppSettings.API_GET_LOOKUP +
            'GetCategoriesForCampaign?isPublishingDb=' +
            isPublishingDb
        )
        .pipe(
          tap((x) => this.storageHelper.SetDataInStorage(cacheKey, x, 4)),
          catchError(this.handleError)
        );
    }
  }
  GetContacts(): Observable<RequestResponse> {
    let cacheKey = 'ContactsKey';
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      return this.http
        .get<RequestResponse>(AppSettings.API_GET_LOOKUP + 'Contacts')
        .pipe(
          tap((x) => this.storageHelper.SetDataInStorage(cacheKey, x, 4)),
          catchError(this.handleError)
        );
    }
  }

  GetHolidays(): Observable<RequestResponse> {
    let cacheKey = 'HolidaysKey';
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      return this.http
        .get<RequestResponse>(AppSettings.API_GET_LOOKUP + 'GetHolidays')
        .pipe(
          tap((x) => this.storageHelper.SetDataInStorage(cacheKey, x, 4)),
          catchError(this.handleError)
        );
    }
  }
  GetTimezones(): Observable<RequestResponse> {
    let cacheKey = 'TimeZonesKey1';
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      return this.http
        .get<RequestResponse>(AppSettings.API_GET_LOOKUP + 'Timezones')
        .pipe(
          tap((x) => this.storageHelper.SetDataInStorage(cacheKey, x, 4)),
          catchError(this.handleError)
        );
    }
  }

  /**
   * Error processing.
   *
   * @param caught The caught error information.
   */
  private handleError(caught: any) {
    let str = caught?.error?.message ?? 'Error while connecting to server!';
    console.error(str);
    // throw error back to the calling method.
    return throwError(str);
  }
}
