<app-impersonation-bar></app-impersonation-bar>

<div class="container-section">
  <div class="d-flex align-items-center" style="min-height: 10vh">
    <div class="logo w-100 text-center text-success">
      <img src="assets/images/aduvo-logo.png" alt="">
    </div>
  </div>

  <div class="d-flex align-items-center middle-section">
    <div class="logo w-100 text-center text-success">
      <div class="welcomescreen justify-content-center align-items-center d-flex flex-column align-items-center justify-content-center">
        <h2>Welcome to Aduvo!</h2>
        <br>
        <button pButton pRipple class="p-button p-button-rounded" (click)="redirectToSetUpAccount()">
          <span class="intro-text">Let's Get Started</span>
        </button>
      </div>
      <div class="text-center mt-5">
        <img src="../../../assets/images/welcome-to-aduvo.svg" style="max-width:540px" alt="" class="welcome-image">
      </div>
    </div>
  </div>
</div>
