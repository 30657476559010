export class CalendarEvent {
  public tenantId: string = '00000000-0000-0000-0000-000000000000';
  public eventId: string = '00000000-0000-0000-0000-000000000000';
  public name: string = '';
  public displayName: string = '';
  public description: string = '';
  public eventType: string = '';
  public scheduleJSON: string = '';
  public listOfDates: string = '';
  public categoryType: string = '';

  public monthCode: string = '';
  public dayCode: number = 1;
  public ordinalCode: string = '';
  public weekDayCode: string = '';

  public published: boolean = false;
  public isPublishingDb: boolean = false;
  public dateUpdatedUTC: Date;
  /**
   * Constructor - all parameters are optional.
   * Only passed parameters are assigned to their corresponding property.
   *
   * @param TenantId
   * @param EventId
   * @param Name
   * @param DisplayName
   * @param Description
   * @param EventType
   * @param ScheduleJSON
   * @param Published
   */
  constructor(
    tenantId?: string,
    eventId?: string,
    name?: string,
    displayName?: string,
    description?: string,
    published?: boolean,
    eventType?: string,
    scheduleJSON?: string,
    dateUpdatedUTC?: Date,
    listOfDates?: string,
    categoryType?: string,
    monthCode?: string,
    dayCode?: number,
    ordinalCode?: string,
    weekDayCode?: string
  ) {
    if (tenantId !== undefined && tenantId !== null) this.tenantId = tenantId;
    if (tenantId !== undefined && tenantId !== null) this.tenantId = tenantId;
    if (name !== undefined && name !== null) this.name = name;
    if (displayName !== undefined && displayName !== null)
      this.displayName = displayName;
    if (description !== undefined && description !== null)
      this.description = description;
    if (eventType !== undefined && eventType !== null)
      this.eventType = eventType;
    if (scheduleJSON !== undefined && scheduleJSON !== null)
      this.scheduleJSON = scheduleJSON;
    if (published !== undefined && published !== null)
      this.published = published;
    if (dateUpdatedUTC !== undefined && dateUpdatedUTC !== null)
      this.dateUpdatedUTC = dateUpdatedUTC;
    if (eventType !== undefined && eventType !== null)
      this.eventType = eventType;
    if (listOfDates !== undefined && listOfDates !== null)
      this.listOfDates = listOfDates;
    if (categoryType !== undefined && categoryType !== null)
      this.categoryType = categoryType;
    if (monthCode !== undefined && monthCode !== null)
      this.monthCode = monthCode;
    if (dayCode !== undefined && dayCode !== null) this.dayCode = dayCode;
    if (ordinalCode !== undefined && ordinalCode !== null)
      this.ordinalCode = ordinalCode;
    if (weekDayCode !== undefined && weekDayCode !== null)
      this.weekDayCode = weekDayCode;
  }
}

export class CalendarEventCollection {
  Items: CalendarEvent[] = [];
}
export class AnnualMonthWeekDayJson {
  public name: string;
  public displayName: string;
  public method: string;
  public methodDisplay: string;
  public arguments: MonthWeekDayArguments;
  constructor() {}
}
export class MonthWeekDayArguments {
  arg1: string;
  arg2: string;
  arg3: string;
  constructor() {}
}
