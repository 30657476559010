<div class="all_Color" style="font-size: 1.1em; font-weight: 500">
  Campaign Category
</div>
<br />
<div style="width: 100%">
  <p-dropdown
    class="details-dialog"
    id="campaignCategory"
    [style]="{ width: '100%' }"
    autoWidth="false"
    appendTo="body"
    #campaignCategory
    [options]="campaignCategoryList"
    [(ngModel)]="campaignDetails.campaignCategoryId"
    optionLabel="item_text"
    optionValue="item_id"
    placeholder="Select Category"
  >
  </p-dropdown>
</div>
<br />
<div style="width: 680px; margin-left: -8%; padding: 0 !important">
  <div style="margin-top: 0.5em; background: #e0e0e0; height: 1px"></div>
  <div
    style="
      margin-bottom: 0.5em;
      background: var(--very-light-gray);
      height: 1px;
    "
  ></div>
</div>
<div
  class="all_Color"
  style="font-size: 1.1em; font-weight: 500; margin-top: 35px"
>
  Who Can Use This Campaign
</div>
<br />

<div class="all_Color" style="font-size: 1.1em; font-weight: 500">Users</div>
<div style="width: 100%">
  <ng-container *ngIf="isDisabled; else userDropDown">
    <ng-multiselect-dropdown
      id="divNoUser"
      [settings]="userDropdownSettings"
      [(ngModel)]="campaignDetails.tenantUserIdCSV"
      [disabled]="isDisabled"
      noDataAvailablePlaceholderText=".."
    >
    </ng-multiselect-dropdown>
  </ng-container>
  <ng-template #userDropDown>
    <ng-multiselect-dropdown
      [settings]="userDropdownSettings"
      [(ngModel)]="campaignDetails.tenantUserIdCSV"
      [data]="userList"
      (onDeSelect)="onItemDeSelect($event)"
      [disabled]="isDisabled"
      (onDeSelectAll)="onDeSelectAll($event)"
    >
    </ng-multiselect-dropdown>
  </ng-template>
</div>
<br />
<div style="width: 680px; margin-left: -8%; padding: 0 !important">
  <div style="margin-top: 0.5em; background: #e0e0e0; height: 1px"></div>
  <div
    style="
      margin-bottom: 0.5em;
      background: var(--very-light-gray);
      height: 1px;
    "
  ></div>
</div>
<div
  class="all_Color"
  style="font-size: 1.1em; font-weight: 500; margin-top: 35px"
>
  To List
</div>
<br />
<div style="width: 100%">
  <ng-multiselect-dropdown
    [settings]="dropdownSettings"
    [(ngModel)]="campaignDetails.toReportCSV"
    [data]="loanStatusTypeMasterList"
  >
  </ng-multiselect-dropdown>
  <!-- <p-dropdown class="details-dialog" id="loanStatusType" [style]="{'width':'100%'}" autoWidth="false" appendTo="body"
    [options]="loanStatusTypeMasterList" [(ngModel)]="campaignDetails.toReportCSV" optionLabel="item_text"
    optionValue="item_id" (onChange)="OnMasterListChange($event)" placeholder="Select">
  </p-dropdown> -->
</div>
<!-- <p-radioButton name="to" value=true [(ngModel)]="campaignDetails.isToReport" inputId="to1"></p-radioButton>
<label for="to1" style="margin:.5rem;">Report</label>
<p-dropdown id="ddlReport" appendTo="body" #ddlReport [editable]="false"
  [options]="reportList" [(ngModel)]="campaignDetails.toReportId">
  </p-dropdown>
<br />
<br />
<p-radioButton name="to" value=false [(ngModel)]="!campaignDetails.isToReport" inputId="to2"></p-radioButton>
<label for="to2" style="margin:.5rem;">Custom Conditions</label> -->
<br />
<br />
<div style="width: 680px; margin-left: -8%; padding: 0 !important">
  <div style="margin-top: 0.5em; background: #e0e0e0; height: 1px"></div>
  <div
    style="
      margin-bottom: 0.5em;
      background: var(--very-light-gray);
      height: 1px;
    "
  ></div>
</div>
<div
  class="all_Color"
  style="font-size: 1.1em; font-weight: 500; margin-top: 35px"
>
  To Recipients
</div>
<br />
<table width="100%">
  <tr width="100%">
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
      Contacts
    </td>
  </tr>
  <tr>
    <td>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [(ngModel)]="campaignDetails.toContactTypeIdCSV"
        [data]="masterContactTypes"
      >
      </ng-multiselect-dropdown>
    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">Users</td>
  </tr>
  <tr>
    <td>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [(ngModel)]="campaignDetails.toLoanAssignTypeIdCSV"
        [data]="loanAssignTypeRoles"
      >
      </ng-multiselect-dropdown>
    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
      Custom Address(es)&nbsp;&nbsp;
      <span style="font-size: 14px; color: #797979; font-style: italic">
        Separate with a comma and no space</span
      >
    </td>
  </tr>
  <tr>
    <td>
      <input
        pInputText
        type="text"
        [(ngModel)]="campaignDetails.toEmailCSV"
        (input)="validateMultipleToEmail()"
        (change)="validateMultipleToEmail()"
        style="width: 100% !important; resize: none; border: 1px solid #cccccc"
      />
      <span class="error-validation" *ngIf="isToEmailInValid"
        >*Email address not valid.</span
      >
    </td>
  </tr>
</table>
<br />
<div style="width: 680px; margin-left: -8%; padding: 0 !important">
  <div style="margin-top: 0.5em; background: #e0e0e0; height: 1px"></div>
  <div
    style="
      margin-bottom: 0.5em;
      background: var(--very-light-gray);
      height: 1px;
    "
  ></div>
</div>

<div
  class="all_Color"
  style="font-size: 1.1em; font-weight: 500; margin-top: 35px"
>
  CC
</div>
<br />
<table width="100%">
  <tr width="100%">
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
      Contacts
    </td>
  </tr>
  <tr>
    <td>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [data]="masterContactTypes"
        [(ngModel)]="campaignDetails.ccContactTypeIdCSV"
      >
      </ng-multiselect-dropdown>
    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">Users</td>
  </tr>
  <tr>
    <td>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [data]="loanAssignTypeRoles"
        [(ngModel)]="campaignDetails.ccLoanAssignTypeIdCSV"
      >
      </ng-multiselect-dropdown>
    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
      Custom Address(es) &nbsp;&nbsp;
      <span style="font-size: 14px; color: #797979; font-style: italic">
        Separate with a comma and no space</span
      >
    </td>
  </tr>
  <tr>
    <td>
      <input
        pInputText
        type="text"
        [(ngModel)]="campaignDetails.ccEmailCSV"
        (input)="validateMultipleCCEmail()"
        style="width: 100% !important; resize: none; border: 1px solid #cccccc"
      />
      <span class="error-validation" *ngIf="isCCEmailInValid"
        >*Email address not valid.</span
      >
    </td>
  </tr>
</table>
<br />
<div style="width: 680px; margin-left: -8%; padding: 0 !important">
  <div style="margin-top: 0.5em; background: #e0e0e0; height: 1px"></div>
  <div
    style="
      margin-bottom: 0.5em;
      background: var(--very-light-gray);
      height: 1px;
    "
  ></div>
</div>
<div
  class="all_Color"
  style="font-size: 1.1em; font-weight: 500; margin-top: 35px"
>
  BCC
</div>
<br />
<table width="100%">
  <tr width="100%">
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
      Contacts
    </td>
  </tr>
  <tr>
    <td>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [data]="masterContactTypes"
        [(ngModel)]="campaignDetails.bccContactTypeIdCSV"
      >
      </ng-multiselect-dropdown>
    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">Users</td>
  </tr>
  <tr>
    <td>
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [(ngModel)]="campaignDetails.bccLoanAssignTypeIdCSV"
        [data]="loanAssignTypeRoles"
      >
      </ng-multiselect-dropdown>
    </td>
  </tr>
  <tr>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
      Custom Address(es) &nbsp;&nbsp;
      <span style="font-size: 14px; color: #797979; font-style: italic">
        Separate with a comma and no space</span
      >
    </td>
  </tr>
  <tr>
    <td>
      <input
        style="width: 100% !important; resize: none; border: 1px solid #cccccc"
        pInputText
        type="text"
        [(ngModel)]="campaignDetails.bccEmailCSV"
        (input)="validateMultipleBCCEmail()"
      />
      <span class="error-validation" *ngIf="isBCCEmailInValid"
        >*Email address not valid.</span
      >
    </td>
  </tr>
</table>
<br />
<div style="width: 680px; margin-left: -8%; padding: 0 !important">
  <div style="margin-top: 0.5em; background: #e0e0e0; height: 1px"></div>
  <div
    style="
      margin-bottom: 0.5em;
      background: var(--very-light-gray);
      height: 1px;
    "
  ></div>
</div>
<div
  class="all_Color"
  style="font-size: 1.1em; font-weight: 500; margin-top: 35px"
>
 Who is it from?
</div>

<table width="100%">
  <tr class="field-radiobutton">
    <td width="5%">
      <p-radioButton
        name="who"
        value="0"
        [(ngModel)]="campaignDetails.isFromRole"
        inputId="from1"
        (onClick)="onRoleChange(campaignDetails.isFromRole)"
      ></p-radioButton>
    </td>
    <td width="95%">
      <div class="row align-items-center">
        <div class="col-2">
          <label class="all_Color" for="from1" style="margin: 0.5rem;cursor:pointer;"
            >Role</label
          >
        </div>
        <!-- removed  style="display: inline-block; width: 75%;" -->
        <div class="col-10">
          <p-dropdown
            class="details-dialog"
            [disabled]="campaignDetails.isFromRole != 0"
            id="ddlRoles"
            appendTo="body"
            #ddlRoles
            [editable]="false"
            [options]="loanAssignTypeRoles"
            [style]="{ width: '100%' }"
            [(ngModel)]="campaignDetails.fromLoanAssignTypeId"
            optionLabel="item_text"
            optionValue="item_id"
            placeholder="Select"
          >
          </p-dropdown>
        </div>
      </div>
    </td>
  </tr>
  <br />
  <tr>
    <td width="5%">
      <p-radioButton
        name="who"
        value="2"
        [(ngModel)]="campaignDetails.isFromRole"
        inputId="from2"
        (onClick)="onRoleChange(campaignDetails.isFromRole)"
      ></p-radioButton>
    </td>
    <td width="95%">
      <div>
        <label class="all_Color" for="from2" style="margin: 0.5rem;cursor:pointer;"
          >Contact Owner</label
        >
      </div>
    </td>
  </tr>
  <br />
  <tr>
    <td width="5%">
      <p-radioButton
        name="who"
        value="1"
        [(ngModel)]="campaignDetails.isFromRole"
        inputId="from3"
        (onClick)="onRoleChange(campaignDetails.isFromRole)"
      ></p-radioButton>
    </td>
    <td width="95%">
      <div>
        <label class="all_Color" for="from3" style="margin: 0.5rem;cursor:pointer;"
          >Custom Address</label
        >
      </div>
    </td>
  </tr>
</table>
<!-- 

<br />
<br />
<p-radioButton name="who" value=1 [(ngModel)]="campaignDetails.isFromRole" inputId="from2"></p-radioButton>
<label for="from2" style="margin:.5rem;">Custom Address</label> -->
<!-- <br /> -->

<div *ngIf="campaignDetails.isCustomRoleSelected">
  <table width="95%" style="margin-left: 36px">
    <tr>
      <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
        From Email
      </td>
    </tr>
    <tr>
      <td>
        <!-- <input pinputtext="" type="text" class="p-inputtext p-component" style="width: 30% !important;">
        <label width="2%">&nbsp;</label> -->
        <input
          style="border: 1px solid #cccccc"
          [disabled]="campaignDetails.isFromRole != 1"
          pinputtext=""
          type="text"
          (input)="validateEmailDialog()"
          [(ngModel)]="campaignDetails.fromEmailAddress"
          class="p-inputtext p-component"
          style="width: 100% !important"
        />
        <span class="error-validation" *ngIf="isFromEmailInValid"
          >*Email address not valid.</span
        >
      </td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td class="all_Color" style="font-size: 1.1em; font-weight: 500">
        From Name
      </td>
    </tr>
    <tr>
      <td>
        <input
          style="border: 1px solid #cccccc"
          [disabled]="campaignDetails.isFromRole != 1"
          pInputText
          type="text"
          [(ngModel)]="campaignDetails.fromEmailAddressName"
          style="width: 100% !important"
        />
      </td>
    </tr>
  </table>
</div>
<!-- <p-radioButton name="bcc" value=true [(ngModel)]="bccValue" inputId="from1"></p-radioButton>
<label for="from1" style="margin:.5rem;">Role</label>
<br />
<br />
<p-radioButton name="bcc" value=false [(ngModel)]="bccValue" inputId="from2"></p-radioButton>
<label for="from2" style="margin:.5rem;">Custom Address</label> -->
<hr />
<div class="mb-4" *ngIf="currentClientID === 'AduvoAdmin'">
  <div class="p-d-flex my-2 ">
    <p-checkbox
      [(ngModel)]="campaignDetails.isQuickStart"
      binary="true"
      inputId="chkQuickStart"
    >
    </p-checkbox>
    <label class="control-label">Aduvo Quick Start</label>
  </div>
  <div class="p-d-flex my-2">
    <p-checkbox
      [(ngModel)]="campaignDetails.isRecommended"
      binary="true"
      inputId="chkRecommended"
    >
    </p-checkbox>
    <label class="control-label">Aduvo Recommended</label>
  </div>
</div>
