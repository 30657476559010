import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { HtmlData, PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter, RhsQueryData, Scope } from 'src/models/PeopleAndLoanFilterOutput';
import { CommonHelperService } from 'src/services/commonHelper.service';
import { MergeTagsService } from 'src/services/mergeTags.service';
import { PeopleAndLoanService } from 'src/services/peopleandloans.service';
import { ToastService } from 'src/services/toast.service';
import { OverlayPanel } from 'primeng/overlaypanel';
//Constants
const dataColumnDisplayLST: string = 'Loan Status';//Loan Status Type
//const dataColumnDisplayAC_CT: string = 'All Contacts Contact Type'
const dataColumnDisplayAC_CT: string = 'Contact Type'
const dataColumnDisplayVeteran: string = 'Veteran'
const showLatestLoanHtml: string = '<span class="fw-bold">Show Latest Loan</span>';

@Component({
  selector: 'app-people-and-list-advanced-filter',
  templateUrl: './people-and-list-advanced-filter.component.html',
  styleUrls: ['./people-and-list-advanced-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterComponent implements OnInit, OnDestroy {

  public showFilterList: boolean;
  public isControlDisabled: boolean;
  public addButtonLabel: string;
  public selectedNodeDataType: string;
  public displayHtml: HtmlData[];
  public displayHtml5: HtmlData[];
  public removeFilterClicked: boolean;

  public selectedFilter: PeopleAndLoanFieldLookup;
  public filteredRecords: any[];
  public allFilterJSON: any | null;
  public distinctFilterArray: any | null;
  public operandDropDownList: any[];
  public quickFilterList: string[];

  //main object which will be used to create filter json.
  //will send an empty object and using eventEmitter onFilterCreated will give us the new object
  //we will override our initial object with the new object 
  public peopleAndLoanFilterData: PeopleAndLoanFilterData;
  public quickFilterLoader: boolean;
  public filterJSONLoader: boolean;
  public editNode: PeopleAndLoanEditTagData | null;

  private subscription: Array<Subscription>;
  private peopleAndLoanFilterEditData: PeopleAndLoanFilterData;
  private storageHelper: StorageHelper;
  private insertIndex: number;
  public showConditionBuilder: boolean;
  public hasJsonLoadError: boolean;
  public rhsQueryData: RhsQueryData[];
  public clientX: number;
  public clientY: number;
  public isSearchedOrQuickFieldCalled: boolean;
  public toggleFlag: boolean;
  public insertFilterFlag: boolean;

  //#region Input/Output Properties
  @Output() onFilterCreatedEvent = new EventEmitter<PeopleAndLoanFilterData>();

  @Input() get PeopleAndLoanFilterEditData(): PeopleAndLoanFilterData | null {
    return this.peopleAndLoanFilterEditData;
  }
  set PeopleAndLoanFilterEditData(val) {
    if (val) {
      //load in edit mode();
      this.peopleAndLoanFilterEditData = val;
      this.peopleAndLoanFilterData = JSON.parse(JSON.stringify(val))
      this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
      this.storageHelper.SetDataInStorage('PeopleAndLoan_Filter', this.peopleAndLoanFilterData, 1);
      this.loadInEditMode(val['quickFilterSelected']);
    }
  }

  @Input() get PeopleAndLoanFilterCanEditData(): boolean | null {
    return this.isControlDisabled;
  }
  set PeopleAndLoanFilterCanEditData(val) {
    this.isControlDisabled = !val;
  }
  @ViewChild("insideElement") insideElement;
  @ViewChild('op') op: OverlayPanel;
  @ViewChild('op2') op2: OverlayPanel;
  //#endregion

  constructor(
    private commonHelperService: CommonHelperService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private peopleAndLoanService: PeopleAndLoanService,
    private mergeTagsService: MergeTagsService,
    private router: Router,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.isControlDisabled = false;
    this.showFilterList = false;
    this.isControlDisabled = false;
    this.addButtonLabel = 'Add Filter';
    this.displayHtml = [];
    this.subscription = [];
    this.removeFilterClicked = false;
    this.selectedFilter = null;
    this.filteredRecords = [];
    this.storageHelper = new StorageHelper();
    this.distinctFilterArray = null;
    this.operandDropDownList = [];
    this.quickFilterList = [];
    this.quickFilterLoader = true;
    this.filterJSONLoader = true;
    this.insertIndex = 0;
    this.editNode = null;
    this.showConditionBuilder = false;
    this.hasJsonLoadError = false;
    this.rhsQueryData = [];
    this.clientX = 0;
    this.clientY = 0;
    this.isSearchedOrQuickFieldCalled = false;
    this.toggleFlag = true;
    this.insertFilterFlag = false;
  }

  ngOnInit(): void {
    this.hasJsonLoadError = true;
    this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
    this.storageHelper.SetDataInStorage('PeopleAndLoan_Filter', this.peopleAndLoanFilterData, 1);
    this.subscription = [];
    this.displayHtml = [];
    this.displayHtml5 = [];
    this.selectedNodeDataType = '';
    this.loadQuickFilters();
    this.loadFilterJSON();
  }

  private loadFilterJSON() {

    this.peopleAndLoanService.getFilterJsonAsync('all.json').then((loanJSON) => {

      let hasLoanLoaded = this.peopleAndLoanService.filterJsonLoaded('all.json');
      this.hasJsonLoadError = true;
      if (hasLoanLoaded && loanJSON) {//has been loaded parse and use 
        this.allFilterJSON = JSON.parse(loanJSON);
        this.hasJsonLoadError = false;
        this.processAllJson();
      }

      if (this.hasJsonLoadError) { //Exception has occurred retrying thrice in 3 seconds.
        let count: number = 0;
        let myInterval = setInterval((x) => {
          count++;
          try {
            this.allFilterJSON = JSON.parse(this.peopleAndLoanService.getFilterJson('all.json'));
            this.loadAndCacheRHSQueryData();
            this.hasJsonLoadError = false;
            this.cdr.detectChanges();
          } catch (e) {
            this.hasJsonLoadError = true;
          }

          if (!this.hasJsonLoadError || count > 3) {
            clearInterval(myInterval);
            if (count > 3) {
              console.log('error while loading filter Json.')
              this.router.navigateByUrl("dashboard");
            }
          }
        }, 3000);//3 seconds
      }
      //loaded in first call.
      if (!this.hasJsonLoadError) {
        this.processAllJson();
        this.loadAndCacheRHSQueryData();
        this.cdr.detectChanges();
      }

      //this.processAllJson();
      this.cdr.detectChanges();
    });


  }

  private loadAndCacheRHSQueryData() {
    const queryFilterData = this.mapQueriesAndRnoId(this.allFilterJSON['Model']);
    queryFilterData.forEach((filter) => {
      this.rhsQueryData.push(new RhsQueryData(filter.cacheKey, filter.query, '', filter.id));
    });
    this.peopleAndLoanService.setDataForRhsQueryById(this.rhsQueryData, 'PL_STATUS');
  }

  private mapQueriesAndRnoId(model: any) {

    const queryObjects = model.filter(x => x.LhsSelectInputControlSqlCmd || x.RhsSelectInputControlSqlCmd);
    const queryFilterData = queryObjects.map(x => {
      return {
        id: x.rno,
        query: x.LhsSelectInputControlSqlCmd === null ? x.RhsSelectInputControlSqlCmd : x.LhsSelectInputControlSqlCmd,
        cacheKey: this.peopleAndLoanService.stringToHash(x.LhsSelectInputControlSqlCmd === null ? x.RhsSelectInputControlSqlCmd : x.LhsSelectInputControlSqlCmd)
      };
    
    });
    console.log('queryFilterData', queryFilterData);

    return queryFilterData;
  }

  private processAllJson() {
    this.allFilterJSON = JSON.parse(this.peopleAndLoanService.getFilterJson('all.json'));
    this.createDistinctFilterArray();
    this.filterJSONLoader = false;
  }

  private createDistinctFilterArray() {
    if (this.allFilterJSON.Model.length) {
      const distinctThings = this.allFilterJSON.Model.filter(
        (thing, i, arr) => arr.findIndex(t => t.DataColumnDisplay === thing.DataColumnDisplay) === i
      );
      this.distinctFilterArray = distinctThings;
    }
  }

  //private loadQuickFilters() {
  //  this.subscription.push(
  //    this.mergeTagsService.getMostRecent12QuickFilterTags().subscribe(x => {
  //      let filterStr = x?.data?.replaceAll('#', "'");
  //      this.quickFilterList = filterStr?.split(",");
  //      let kIndex = this.quickFilterList.findIndex(x => x?.toLowerCase() == 'show latest loan');
  //      if (kIndex != -1) {
  //        this.quickFilterList.splice(kIndex, 1);//remove
  //        this.quickFilterList.push('Show Latest Loan');//add at the end
  //      }
  //      this.quickFilterLoader = false;
  //      this.cdr.detectChanges();
  //    }));
  //}

  private loadQuickFilters() {
    this.quickFilterList = ['Note Rate', 'Total Loan Amount', 'Contact Type: Borrowers', 'Contact Type: Realtors', 'Loan Officer', 'Loan Status', 'Show Latest Loan'];
    this.quickFilterLoader = false;
    this.cdr.detectChanges();
  }


  public addFilter() {
    this.showFilterList = true;//show+hide
    if (this.showFilterList) {
      this.selectedFilter = null;
      this.showConditionBuilder = false;
    }
  }

  /* have replaced the accept functionality with reject and vice versa same was done for marking it's label
     to change the location of buttons in design of confirm pop for jira ticket PV6UI-849*/
  public removeFilterClick(event: any, lookupId, id) {
    this.removeFilterClicked = true;
    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: 'Cancel',
      rejectLabel: ' Yes, Delete',
      message: "Are you sure you want to delete this filter?",
      accept: () => {
        this.removeFilterClicked = false;
      },
      reject: () => {
        //this.reAddFilter(); no need to readd as not using p-Chip close button.
        this.removeFilter(null, lookupId, id);
        this.toastService.showSuccess('A filter has been removed.');
      }
    });
  }

  public removeFilterClick1(event: any, lookupId, id) {
    this.removeFilterClicked = true;
    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: 'Cancel',
      rejectLabel: ' Yes, Delete',
      message: "Are you sure you want to delete this filter?",
      accept: () => {
        this.removeFilterClicked = false;
      },
      reject: () => {
        //this.reAddFilter(); no need to readd as not using p-Chip close button.
        this.removeFilter(null, lookupId, id);
        this.toastService.showSuccess('A filter has been removed.');
      }
    });
  }

  public removeFilter(event, eventLookupId = null, eventId = null) {//chips 
    //remove from array 
    let lookupId = '';
    let saveIndex = 0;

    if (eventLookupId && eventId) {
      lookupId = eventLookupId;
      saveIndex = eventId;
    } else {
      lookupId = event.value.lookupId;
      saveIndex = event.value.id;
    }

    if (eventLookupId == 'sll1') {
      let kIndex = this.displayHtml.findIndex(x => x.lookupId == 'sll1');
      if (kIndex !== -1) {
        this.displayHtml.splice(kIndex, 1);
        this.peopleAndLoanFilterData.scope.name = "all-loans";
      }
    }
    else {//special case of "all-loans"
      let andIndex = this.peopleAndLoanFilterData.PeopleLoanList.
        PeopleLoanListAndFilters.findIndex(x => x.peopleAndLoansFieldLookupId == lookupId &&
          x.id == saveIndex);
      //@@## todo also check for id other wise multiple data will be deleted.
      if (andIndex !== -1) {
        this.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.splice(andIndex, 1);
      }
    }
    this.processFilters();
    this.showConditionBuilder = false;
    this.showFilterList = false;
    this.selectedFilter = null;
    //this.toastService.showSuccess('A filter has been removed.');
  }

  public processFilters(quickFilterSelected: boolean = false) {
    console.info('335: processFilters',quickFilterSelected);
    this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
    this.storageHelper.SetDataInStorage('PeopleAndLoan_Filter', this.peopleAndLoanFilterData, 1);
    this.peopleAndLoanFilterData.DisplayHtml = '';

    //loop and show Create Chip data.+ calculate displayHtmlStr to save.
    let displayHtmlStr: string = '';
    this.displayHtml = [];
    if (this.peopleAndLoanFilterData.PeopleLoanList) {
      let andStr = '';
      if (this.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.length) {
        this.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.forEach(row => {
          let orStr = '';
          let lookupID = row.peopleAndLoansFieldLookupId;
          let typeCategory = row.group;
          let andIndex = row.id;
          let countN = 0;//@@## send count 

          let displayHtmlTmp = ''
          let defLookupIdTmp = ''
          if (row?.type?.toUpperCase() == 'LOANSTATUSTYPELIST') {//lhs list 3 control scenario
            let len = row.PeopleLoanListOrFilters?.length ?? 0;
            row.PeopleLoanListOrFilters.forEach((filter, idx) => {
              if (idx == 0) {
                displayHtmlTmp += orStr + filter.displayHtml;
                defLookupIdTmp = filter.peopleAndLoansTypeDefLookupId;
                let isList = this.filterIsList(row?.type?.toUpperCase());
                if (isList) {//only show if it is list type.
                  //countN = filter?.rhs[0]?.toString()?.split(",")?.length ?? 0;
                  countN = len;
                }
                displayHtmlStr += andStr + orStr + filter.displayHtml;
                // orStr = ' OR ';
                // andStr = '';
                //run only once
                let indexK = this.displayHtml?.length || 0;
                this.displayHtml.push(new HtmlData(displayHtmlTmp, lookupID, defLookupIdTmp, andIndex, typeCategory, countN - 1, indexK + 1));
              }
            });
          }
          else {
            row.PeopleLoanListOrFilters.forEach((filter, idx) => {
              displayHtmlTmp += orStr + filter.displayHtml;
              defLookupIdTmp = filter.peopleAndLoansTypeDefLookupId;
              let isList = this.filterIsList(row?.type?.toUpperCase());
              if (isList) {//only show if it is list type.
                countN = filter?.rhs[0]?.toString()?.split(",")?.length ?? 0;
              }

              // let defLookupId = filter.peopleAndLoansTypeDefLookupId;                    
              //  this.displayHtml.push(new HtmlData(displayHtmlTmp, lookupID, filter.peopleAndLoansTypeDefLookupId, andIndex, typeCategory));
              displayHtmlStr += andStr + orStr + filter.displayHtml;
              orStr = ' OR ';
              andStr = '';
            });
            let indexK = this.displayHtml?.length || 0;
            this.displayHtml.push(new HtmlData(displayHtmlTmp, lookupID, defLookupIdTmp, andIndex, typeCategory, countN - 1, indexK + 1));
            if (displayHtmlStr.length) {
              andStr = ' AND ';
            }
          }
        });
        this.setDisplayHtmlForLatestLoan();
      } else {
        this.setDisplayHtmlForLatestLoan();
      }
    }
    this.peopleAndLoanFilterData.DisplayHtml = displayHtmlStr;
    //send to save 
    
    this.peopleAndLoanFilterData['quickFilterSelected'] = quickFilterSelected;
    console.info('404: this.onFilterCreatedEvent.emit(this.peopleAndLoanFilterData);',this.peopleAndLoanFilterData);
    this.onFilterCreatedEvent.emit(this.peopleAndLoanFilterData);// to save by dialog save button 
    //rename button
    this.addButtonLabel = this.displayHtml.length ? '' : 'Add Filter';

    this.toggleFlag = this.displayHtml.length > 5 ? true : false;

    this.setHtml5(this.insertFilterFlag ? false : true);
    if (this.insertFilterFlag) {
      this.toggleFlag = false;
    }
  }

  setHtml5(set5: boolean) {
    this.displayHtml5 = [];

    if (set5) {
      let lenI = this.displayHtml.length > 5 ? 5 : this.displayHtml.length;
      for (let index = 0; index < lenI; index++) {
        this.displayHtml5.push(this.displayHtml[index]);
      }
    } else {//show all
      for (let index = 0; index < this.displayHtml.length; index++) {
        this.displayHtml5.push(this.displayHtml[index]);
      }
    }
  }

  private setDisplayHtmlForLatestLoan() {
    if (this.peopleAndLoanFilterData.scope.name == "latest") {
      let index = this.displayHtml?.length || 0;
      this.displayHtml.push(new HtmlData(showLatestLoanHtml, 'sll1', 'sll1', 999, '', 0, index + 1));
    }
  }

  public clearItem(autocomplete: any) {
    autocomplete.value = '';
    autocomplete.handleDropdownClick();
  }

  public searchFilters(event) {
    this.isSearchedOrQuickFieldCalled = true;
    this.filterJSONLoader = true;
    this.filteredRecords = null
    this.cdr.detectChanges();

    let query = event?.query?.toLowerCase().trim();
    if (query) {//to search all item
      if (!this.distinctFilterArray) {
        this.createDistinctFilterArray();
      }

      let arr = this.distinctFilterArray?.filter(x => x.DataColumnDisplay.toLowerCase().indexOf(query) > -1)
      if (arr?.length) {
        this.filteredRecords = arr;
      }
    }
    else {//to show all in dropdown
      if (this.allFilterJSON.Model) {
        this.filteredRecords = this.distinctFilterArray;
      }
    }

    this.filterJSONLoader = false;
    this.cdr.detectChanges();
  }

  public nodeSelect(event: any) {
    this.clearSelection();

    if (event?.DataColumnDisplay) {// in case of add  
      let isShowLatestLoan = event?.DataColumnDisplay == 'Show Latest Loan';

      let opCode = isShowLatestLoan ? 'EQTRUE' : 'BLANK';
      // if (event?.DataColumnDisplay == 'Veteran') {
      //   opCode = 'EQTRUE';
      // }

      let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == event.DataColumnDisplay)?.find(x => x.OpCode == opCode);//to do for other type 

      if (isShowLatestLoan) {
        this.operandDropDownList = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == this.selectedFilter?.PeopleAndLoansFieldLookupId)?.filter(x => x.OpCode == opCode);//to do for other type 
      } else {
        this.operandDropDownList = this.allFilterJSON.Model.filter(x => x.PeopleAndLoansFieldLookupId == operand?.PeopleAndLoansFieldLookupId);
        this.operandDropDownList = this.operandDropDownList.sort((a, b) => { return a.OpDisplay.toLowerCase() >= b.OpDisplay.toLowerCase() ? 1 : -1 });
      }

      if (operand) {
        // this.selectedFilter = null;
        this.selectedFilter = operand;
        this.selectedFilter.isAddFlag = 1;
      }
      else {
        this.toastService.showSuccess('filter PENDING @todo');//@@## todo
      }

      this.cdr.detectChanges();
      this.saveQuickFilters(event.DataColumnDisplay);
    }

    this.insertFilter(event)
  }


  public onQuickFilterSelect(dataColumnDisplay: string, evt: any, quickFilterSelected: boolean = false) {
    console.info('508: onQuickFilterSelect',quickFilterSelected);
    this.isSearchedOrQuickFieldCalled = true;
    this.clearSelection();
    let isShowLatestLoan = dataColumnDisplay == 'Show Latest Loan';
    //this.selectedFilter = null;
    if (dataColumnDisplay) {// in case of add  
      //this.selectedFilter = this.allFilterJSON.Model.find(x => x.DataType == title);
      let opCode = isShowLatestLoan ? 'EQTRUE' : 'EQ';
      if (dataColumnDisplay == dataColumnDisplayVeteran) {
        opCode = 'EQTRUE';
      }
      let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == dataColumnDisplay)?.find(x => x.OpCode == opCode);//to do for other type

      if (dataColumnDisplay === 'Contact Type: Borrowers' || dataColumnDisplay === 'Contact Type: Realtors') {
        operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == 'Contact Type')?.find(x => x.OpCode == opCode);
      }

      if (isShowLatestLoan) {
        this.operandDropDownList = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == dataColumnDisplay)?.filter(x => x.OpCode == opCode);//to do for other type 
      }
      else {
        this.operandDropDownList = this.allFilterJSON.Model.filter(x => x.PeopleAndLoansFieldLookupId == operand?.PeopleAndLoansFieldLookupId);
        this.operandDropDownList = this.operandDropDownList.sort((a, b) => { return a.OpDisplay.toLowerCase() >= b.OpDisplay.toLowerCase() ? 1 : -1 });
      }

      if (operand) {
        //this.selectedFilter = null;
        this.selectedFilter = operand;
        this.selectedFilter.isAddFlag = 1;
      }
      else {
        this.toastService.showSuccess('filter PENDING @todo');//@@## todo
      }

      this.cdr.detectChanges();
      //Save Quick filter async
      this.saveQuickFilters(dataColumnDisplay);
    }

    this.insertFilter(evt, quickFilterSelected);
  }

  private saveQuickFilters(fieldSubGroup: string) {
    this.subscription.push(
      this.mergeTagsService.setUserPreferenceByKey('MostRecent12QuickFilterTags', fieldSubGroup, false).subscribe(x => {
      }));
  }

  private insertFilter(evt, quickFilterSelected: boolean = false) {
    console.info('557: insertFilter',quickFilterSelected);
    if (this.selectedFilter) {
      let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
      if (savedFilter?.value) {
        let peopleAndLoanFilters: PeopleAndLoanFilterData;
        peopleAndLoanFilters = savedFilter.value;
        if (peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length) {
          let idx = Math.max(...peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.map(x => x.id));
          this.insertIndex = idx ?? 0;
        }
      }
      let isShowLatestLoan = this.selectedFilter?.DataColumnDisplay == 'Show Latest Loan';
      let isVeteran = this.selectedFilter?.DataColumnDisplay == dataColumnDisplayVeteran;

      //let displayHtml = "";
      //let peopleAndLoanFilters = new PeopleAndLoanFilterData();
      if (!this.peopleAndLoanFilterData) {
        this.peopleAndLoanFilterData = new PeopleAndLoanFilterData();//special case of a list which is empty
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
        this.storageHelper.SetDataInStorage('PeopleAndLoan_Filter', this.peopleAndLoanFilterData, 1);
      }

      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      let customQuickFilterValue = '';
      if (isShowLatestLoan) {
        this.peopleAndLoanFilterData.scope.name = "latest";
        this.peopleAndLoanFilterData.scope.LoanStatusTypes = [];
      }
      else {
        let rhsDropdownText = '';
        let rhsValue1 = '';
        let lhsHtml = '';

        //loop
        let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
        filter.rhs = [];
        filter.id = (this.insertIndex + 1);
        filter.operator = this.selectedFilter.OpCode;
        filter.lhs = '';

        //['Note Rate', 'Loan Amount', 'Contact Type: Borrowers', 'Contact Type: Realtors', 'Loan Officer', 'Loan Status', 'Show Latest Loan']
        lhsHtml = this.selectedFilter.DataColumnDisplay + rhsDropdownText
        if (this.selectedFilter.DataColumnDisplay == dataColumnDisplayLST) {
          filter.lhs = "LoanApplicationDate";
          rhsDropdownText = ' Application Taken Date';
          lhsHtml = rhsDropdownText;
        }

        if (evt.currentTarget?.innerHTML?.includes('Contact Type: Realtors')) {
          customQuickFilterValue = "'Buyer''s Agent', 'Seller''s Agent'";
          rhsValue1 = "Buyer's Agent, Seller's Agent";
          filter.operator = 'EQ';
          filter.rhs = ["Buyer's Agent", "Seller's Agent",null];
          let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == dataColumnDisplayAC_CT)?.find(x => x.OpCode == 'EQ');//to do for other type 
          if (operand) {
            this.selectedFilter = operand;
            this.selectedFilter.isAddFlag = 1;
          }
        }

        // PV6UI-1307 Contact Type, choose In Borrower as default.
        if (evt.currentTarget?.innerHTML?.includes('Contact Type: Borrowers')) {
          customQuickFilterValue = "'Borrower', 'Coborrower'";
          rhsValue1 = "Borrower, Coborrower";
          filter.operator = 'EQ';
          filter.rhs = ["Borrower","Coborrower",null];
          let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == dataColumnDisplayAC_CT)?.find(x => x.OpCode == 'EQ');//to do for other type 
          if (operand) {
            this.selectedFilter = operand;
            this.selectedFilter.isAddFlag = 1;
          }
        }

        else if (this.selectedFilter.DataColumnDisplay == dataColumnDisplayVeteran) {
          rhsValue1 = 'true';
          filter.operator = 'EQTRUE';
          filter.rhs = [rhsValue1];
          let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == dataColumnDisplayVeteran)?.find(x => x.OpCode == 'EQTRUE');//to do for other type 
          if (operand) {
            this.selectedFilter = operand;
            this.selectedFilter.isAddFlag = 1;
          }
        }

        if (rhsValue1 === '') {
          switch (this.selectedFilter.DataType) {
            case 'Currency':
              rhsValue1 = '0'
              break;
            case 'Text':
              rhsValue1 = '0'
              break;
            case 'Percent':
              rhsValue1 = '0'
              break;
            case 'Number':
              rhsValue1 = '0'
              break;
            case 'Date':
              let currentDate = new Date();
              rhsValue1 = currentDate.getMonth() + '/' + currentDate.getDay() + '/' + currentDate.getFullYear();
              break;
            case 'Boolean':
              rhsValue1 = 'true'
              break;

          }
        }

        filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
        let criteriaQuery = this.selectedFilter.Criteria;
        if (criteriaQuery) {
          criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
          criteriaQuery = criteriaQuery.replace('{{lhs}}', filter.lhs);
          criteriaQuery = criteriaQuery.replace('{{rhs1}}', customQuickFilterValue !== '' ? customQuickFilterValue : rhsValue1);
          filter.criteria = criteriaQuery;
        }

        let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
        if (htmlTemplate) {
          htmlTemplate = htmlTemplate.replace('{{lhs}}', lhsHtml);
          //htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay + rhsDropdownText);
          htmlTemplate = htmlTemplate.replace('{{rhs1}}', rhsValue1);//iss blank
          //let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
          filter.displayHtml = ' ' + htmlTemplate;
        }
        listAndFilters.PeopleLoanListOrFilters.push(filter);

        this.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
        //peopleAndLoanFilters.DisplayHtml = displayHtml;
      }
      let peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(true, this.peopleAndLoanFilterData);
      //peopleAndLoanFilterOutput.peopleAndLoanFilterData.PeopleLoanList.type ='';

      this.peopleAndLoanFilterData = JSON.parse(JSON.stringify(peopleAndLoanFilterOutput.peopleAndLoanFilterData));
      //this.processFilters();
      this.insertIndex++;
      this.showFilterList = false;
      this.insertFilterFlag = true;
      this.processFilters(quickFilterSelected);
      this.op2.hide();
      //open the currently added item
      if (!isShowLatestLoan && !isVeteran && customQuickFilterValue === '') //do not open in case of Show Latest Loan
      {

        this.showConditionBuilder = true;
        this.editFilterByAdd(listAndFilters.id, this.selectedFilter?.PeopleAndLoansFieldLookupId,
          this.selectedFilter.PeopleAndLoansTypeDefLookupId, this.selectedFilter?.FieldGroupName, evt);
      }

      // if (this.displayHtml.length > 5) {// for insert case
      //   this.toggleFlag = true;//to negate
      // }
      this.cdr.detectChanges();
      //   this.peopleAndLoanFilterData = JSON.parse(JSON.stringify(peopleAndLoanFilterOutput.peopleAndLoanFilterData));

      //this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)


      //peopleAndLoanFilters.DisplayHtml = displayHtml;

      //let peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(true, peopleAndLoanFilters);
      //peopleAndLoanFilterOutput.peopleAndLoanFilterData.PeopleLoanList.type ='';

      //this.peopleAndLoanFilterData = JSON.parse(JSON.stringify(peopleAndLoanFilterOutput.peopleAndLoanFilterData));
      //this.processFilters();

    }
    // if (this.selectedFilter) {
    //   let displayHtml = "";
    //   let peopleAndLoanFilters = new PeopleAndLoanFilterData();


    //   let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
    //   listAndFilters.PeopleLoanListOrFilters = [];//clear all
    //   listAndFilters.id =(this.insertIndex + 1);
    //   listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
    //   listAndFilters.group = this.selectedFilter?.FieldGroupName;
    //   listAndFilters.datasource = this.selectedFilter?.DataSource;
    //   listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
    //   listAndFilters.type = this.selectedFilter?.DataType;
    //   if (this.selectedFilter.FieldSubGroupName) {
    //     listAndFilters.subGroup =this.selectedFilter?.FieldSubGroupName;
    //   }
    //   listAndFilters.PeopleLoanListOrFilters = [];//clear all


    //   //loop
    //   let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
    //   filter.id = (this.insertIndex + 1);
    //   filter.operator = this.selectedFilter.OpCode;
    //   filter.lhs = '';
    //   filter.rhs = [''];
    //   filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
    //   let criteriaQuery = this.selectedFilter.Criteria;
    //   if (criteriaQuery) {
    //     criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
    //     criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
    //     filter.criteria = criteriaQuery;
    //   }

    //   let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
    //   if (htmlTemplate) {
    //     htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
    //     htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
    //     let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
    //     filter.displayHtml = ' ' + htmlTemplate;
    //   }
    //   listAndFilters.PeopleLoanListOrFilters.push(filter);

    //   peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
    //   //peopleAndLoanFilters.DisplayHtml = displayHtml;

    //   let peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(true, peopleAndLoanFilters);
    //   //peopleAndLoanFilterOutput.peopleAndLoanFilterData.PeopleLoanList.type ='';

    //   this.peopleAndLoanFilterData = JSON.parse(JSON.stringify(peopleAndLoanFilterOutput.peopleAndLoanFilterData));
    //   this.processFilters();
    //   this.insertIndex++;
    // }
  }

  public onFilterCreated(event: PeopleAndLoanFilterOutput) {
    console.info(event);
    if (event.isSuccess) {
      this.showFilterList = false;
      if (event.peopleAndLoanFilterData) {

        event.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.forEach(function (andFilter: PeopleLoanListAndFilter) {
          andFilter.PeopleLoanListOrFilters.forEach(function (filter: PeopleLoanListOrFilter){
            // If we're using a Between operator, we need to ensure that the order is correct. The smaller value needs to come first, such as between 1 and 10, not between 10 and 1
            if (filter.operator === 'BETWEEN') {
              if(filter.rhs[0] > filter.rhs[1]) {
              let temp = filter.rhs[0];
              filter.rhs[0] = filter.rhs[1];
              filter.rhs[1] = temp;
              }
              filter.criteria = filter.criteria.replace("between " + filter.rhs[1] + " and " + filter.rhs[0], "between " + filter.rhs[0] + " and " + filter.rhs[1]);
            }
          });
        });

        this.peopleAndLoanFilterData = JSON.parse(JSON.stringify(event.peopleAndLoanFilterData));

        this.selectedFilter = null;
        this.processFilters();
        this.loadQuickFilters();
      }
      this.op.hide();
    }
  }

  editFilter(event, evt) {//chips
    if (this.removeFilterClicked || event?.value?.lookupId == 'sll1' || event?.value?.html?.indexOf(dataColumnDisplayVeteran) > -1) {
      this.removeFilterClicked = false;
      return;
    }

    // this.clientX = event?.originalEvent?.clientX || 0;
    // this.clientY = event?.originalEvent?.clientY || 0;

    // if (this.clientX > 200 && this.clientX >150) {
    //   debugger;
    //   let left = event?.originalEvent.target.offsetLeft;
    //   let top = event?.originalEvent.target.offsetTop;
    //   console.log('clientX :');
    //   console.log( this.clientX);
    //   console.log('clientY :');
    //   console.log(this.clientY);
    //   console.log('rect.left :');
    //   console.log(left);
    //   console.log('rect.top :');
    //   console.log(top);
    //   this.clientX = left;
    //   this.clientY -= 300;
    // }

    this.editNode = null;
    let andIndex = event.value.id;
    let lookupId = event.value.lookupId;
    let defLookupId = event.value.peopleAndLoansTypeDefLookupId;
    let typeCategory = event.value.categoryType;//loan/people
    this.clearSelection();
    this.cancelFilter();
    this.selectedFilter = null;
    this.insertFilterFlag = true;
    if (event.value.lookupId == "sll1") {//special case  
      let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == 'Show Latest Loan')?.find(x => x.OpCode == 'EQTRUE');//to do for other type 
      if (operand) {
        this.selectedFilter = operand;
      }
    } else {
      //let operand = this.allFilterJSON.Model.find(x => x.PeopleAndLoansFieldLookupId == event.value.lookupId);
      //let operand = this.allFilterJSON.Model.find(x => x.PeopleAndLoansTypeDefLookupId == defLookupId);
      let operand = this.allFilterJSON.Model.find(x => x.PeopleAndLoansTypeDefLookupId == defLookupId &&
        x.PeopleAndLoansFieldLookupId == lookupId);

      if (operand) {
        this.selectedFilter = operand;
      }
    }
    this.openRecord(lookupId, defLookupId, typeCategory, andIndex, evt);
  }
  editFilter1(event: HtmlData, evt: any, isQuickFilter: boolean = false) {

    if(isQuickFilter)
    {
      return;
    }

    if (this.removeFilterClicked || event?.lookupId == 'sll1' || event?.html?.indexOf(dataColumnDisplayVeteran) > -1) {
      this.removeFilterClicked = false;
      return;
    }

    this.editNode = null;
    let andIndex = event.id;
    let lookupId = event.lookupId;
    let defLookupId = event.peopleAndLoansTypeDefLookupId;
    let typeCategory = event.categoryType;//loan/people
    this.clearSelection();
    this.cancelFilter();
    this.selectedFilter = null;
    this.insertFilterFlag = true;
    if (event.lookupId == "sll1") {//special case  
      let operand = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == 'Show Latest Loan')?.find(x => x.OpCode == 'EQTRUE');//to do for other type 
      if (operand) {
        this.selectedFilter = operand;
      }
    } else {
      //let operand = this.allFilterJSON.Model.find(x => x.PeopleAndLoansFieldLookupId == event.value.lookupId);
      //let operand = this.allFilterJSON.Model.find(x => x.PeopleAndLoansTypeDefLookupId == defLookupId);
      let operand = this.allFilterJSON.Model.find(x => x.PeopleAndLoansTypeDefLookupId == defLookupId &&
        x.PeopleAndLoansFieldLookupId == lookupId);

      if (operand) {
        this.selectedFilter = operand;
      }
    }
    //this.selectedFilter.isAddFlag = 1;
    this.openRecord(lookupId, defLookupId, typeCategory, andIndex, evt);
  }

  editFilterByAdd(id: any, lookup_Id: any, defLookup_Id: any, categoryType: any, evt: any) {//chips

    if (this.removeFilterClicked) {
      this.removeFilterClicked = false;
      return;
    }
    this.editNode = null;
    let andIndex = id;//event.value.id;
    let lookupId = lookup_Id;//event.value.lookupId;
    let defLookupId = defLookup_Id;
    let typeCategory = categoryType;//loan/people
    this.clearSelection();
    //this.selectedFilter = null;
    this.showFilterList = false;
    this.showConditionBuilder = false;
    this.cdr.detectChanges();
    setTimeout(() => {
      let lastElements = document.getElementsByClassName("last-child-chip");
      let target = lastElements[lastElements.length - 1];
      this.openRecord(lookupId, defLookupId, typeCategory, andIndex, evt, target);
    }, 500)

  }

  openRecord(lookupId: string, defLookupId: string, typeCategory: string, andIndex: number, evt: any, target: any = undefined) {
    // this.selectedNodeElement = null;
    let labelStr = '';
    this.showFilterList = true;
    // this.editNode = new PeopleAndLoanEditTagData(andIndex, lookupId,
    //   typeCategory.toLowerCase() + '_' + defLookupId, labelStr);
    this.editNode = new PeopleAndLoanEditTagData(andIndex, lookupId, defLookupId, labelStr);

    // console.log('EditNode::');
    // console.log(this.editNode);
    //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');
    this.storageHelper.SetDataInStorage('PeopleAndLoan_editNode', this.editNode, 1);
    this.cdr.detectChanges();
    let isShowLatestLoan = this.selectedFilter.DataColumnDisplay == 'Show Latest Loan';
    let opCode = isShowLatestLoan ? 'EQTRUE' : 'BLANK';
    if (isShowLatestLoan) {
      this.operandDropDownList = this.allFilterJSON.Model.filter(x => x.DataColumnDisplay == this.selectedFilter?.DataColumnDisplay)?.filter(x => x.OpCode == opCode);//to do for other type 
    }
    else {
      this.operandDropDownList = this.allFilterJSON.Model.filter(x => x.PeopleAndLoansFieldLookupId == this.selectedFilter?.PeopleAndLoansFieldLookupId);
      this.operandDropDownList = this.operandDropDownList.sort((a, b) => { return a.OpDisplay.toLowerCase() >= b.OpDisplay.toLowerCase() ? 1 : -1 });
    }
    this.selectedFilter.isAddFlag = 1;
    this.showFilterList = false;
    this.showConditionBuilder = true;
    this.op.toggle(evt, target);
    this.cdr.detectChanges();
  }


  public escapeStr(text: string) {
    return !text ? '' : text.replace("#", "'");
  }

  public loadInEditMode(isQuickFilter: boolean = false) {
    //Create Display Html
    this.displayHtml = [];
    if (this.peopleAndLoanFilterData.PeopleLoanList) {
      let andStr = '';
      if (this.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.length) {
        this.peopleAndLoanFilterData.PeopleLoanList.PeopleLoanListAndFilters.forEach(row => {
          let orStr = '';
          let lookupID = row.peopleAndLoansFieldLookupId;
          let typeCategory = row.group;
          let andIndex = row.id;
          let countN = 0;

          let displayHtmlTmp = ''
          let defLookupIdTmp = ''
          let flag = true;
          let displayHtmlStr: string = '';
          if (row?.type?.toUpperCase() == 'LOANSTATUSTYPELIST') {//lhs list 3 control scenario
            let len = row.PeopleLoanListOrFilters?.length ?? 0;
            row.PeopleLoanListOrFilters.forEach((filter, idx) => {
              if (idx == 0) {
                displayHtmlTmp += orStr + filter.displayHtml;
                defLookupIdTmp = filter.peopleAndLoansTypeDefLookupId;
                let isList = this.filterIsList(row?.type?.toUpperCase());
                if (isList) {//only show if it is list type.
                  //countN = filter?.rhs[0]?.toString()?.split(",")?.length ?? 0;
                  countN = len;
                }
                displayHtmlStr += andStr + orStr + filter.displayHtml;
                let indexK = this.displayHtml?.length || 0;
                const htmlData = new HtmlData(displayHtmlTmp, lookupID, defLookupIdTmp, andIndex, typeCategory, countN - 1, indexK + 1);
                htmlData.isQuickFilter = isQuickFilter;
                this.displayHtml.push(htmlData);
              }
            });
          }
          else {
            row.PeopleLoanListOrFilters.forEach(filter => {
              displayHtmlTmp += orStr + filter.displayHtml;
              defLookupIdTmp = filter.peopleAndLoansTypeDefLookupId;
              let isList = this.filterIsList(row?.type?.toUpperCase());
              if (isList) {//only show if it is list type.
                countN = filter?.rhs[0]?.toString()?.split(",")?.length ?? 0;
              }
              displayHtmlStr += andStr + orStr + filter.displayHtml;
              orStr = ' OR ';
              andStr = '';
            });
            let indexK = this.displayHtml?.length || 0;
            const htmlData = new HtmlData(displayHtmlTmp, lookupID, defLookupIdTmp, andIndex, typeCategory, countN - 1, indexK + 1);
            htmlData.isQuickFilter = isQuickFilter;
            this.displayHtml.push(htmlData);

            if (displayHtmlStr.length) {
              andStr = ' AND ';
            }
          }
        });
        this.setDisplayHtmlForLatestLoan();
      }
      else if (this.peopleAndLoanFilterData.scope.name == "latest") {
        this.setDisplayHtmlForLatestLoan();
      }
    }
    //this.peopleAndLoanFilterData.DisplayHtml = displayHtmlStr;
    this.addButtonLabel = this.displayHtml.length ? '' : 'Add Filter';
    if (this.insertFilterFlag) {
      this.toggleFlag = false;
    }
    else {
      this.toggleFlag = this.displayHtml.length > 5 ? true : false;
    }
    this.setHtml5(this.insertFilterFlag ? false : true);
    this.insertFilterFlag = false;//insert opens an Edit so reset the flag.
  }

  public cancelFilter() {
    this.selectedFilter = null;
    this.showFilterList = false;
    this.showConditionBuilder = false;
  }

  filterIsList(type: string) {
    return !(/\bTEXT\b|\bNUMBER\b|\bBOOLEAN\b|\bBIRTHDAY\b|\bNUMBER\b|\bCURRENCY\b|\bPERCENT\b/.test(type));
    // return !(/\bTEXT\b|\bNUMBER\b|\bBOOLEAN\b|\bBIRTHDAY\b|\bNUMBER\b|\bCURRENCY\b|\bPERCENT\b|\bTAGLIST\b/.test(type));
  }

  // toggleChips() {
  //   this.toggleFlag = !this.toggleFlag;
  // }
  toggleChips() {
    this.toggleFlag = !this.toggleFlag;
    this.setHtml5(this.toggleFlag);
    this.cdr.detectChanges();
  }

  // @HostListener('document:click', ['$event'])
  // DocumentClick(event: Event) {
  //   if (this.isSearchedOrQuickFieldCalled) {
  //     this.isSearchedOrQuickFieldCalled = false;
  //     return;
  //   }

  //   if (this.insideElement.nativeElement.contains(event.target)) {
  //     return;
  //   }

  //   var className = event?.target['className']?.toString().toLowerCase();
  //   if (className == 'col-sm-11' || className.indexOf('justify-content-between') > -1) {
  //     console.log('cancel1');
  //     this.cancelFilter();
  //     return;
  //   }

  //   if (
  //     className.indexOf('p-chips') > -1 ||
  //     className.indexOf('p-checkbox') > -1 ||
  //     className.indexOf('p-multiselect-') > -1 ||
  //     className.indexOf('p-button-label') > -1 ||
  //     className.indexOf('ng-star-inserted') > -1 ||
  //     className.indexOf('form-control') > -1
  //     //||this.elementRef.nativeElement.contains(event.target)
  //   ) {
  //     return;
  //   }

  //   console.log('cancel2 :' +className);
  //   this.cancelFilter();
  // }

  clearSelection() {
    this.operandDropDownList = [];
  }

  ngOnDestroy(): void {
    this.commonHelperService.UnSubscribeAllSubscription(this.subscription);
    this.subscription = null;
    this.displayHtml = null;

    this.selectedFilter = null;
    this.filteredRecords = null;
    this.allFilterJSON = null;
    this.quickFilterList = null;
    this.operandDropDownList = null;
    this.distinctFilterArray = null;
  }

}

