<p-dialog [(visible)]="isLoading" class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>
<div #target>
  <div [hidden]="importModel.tabIndex == 4" class="float-end">
    <i class="fa fa-times texttitle close" aria-hidden="true" (click)="closeMe()"
      [ngClass]="[importModel.tabIndex == 0  ? 'p-sticky' : 'p-abs']"></i>
  </div>

  <ng-container *ngIf="!importModel?.showPreview">
    <div class="row mx-0" [ngClass]="[importModel.tabIndex == 1  ? 'fixed-title ' : '']">
      <div class="row" *ngIf="importModel?.pageTitle[importModel.tabIndex]">
        <div class="col">
          <label class="page_title mt-2">{{importModel?.pageTitle[importModel.tabIndex]}}</label>
        </div>
      </div>

      <div class="row" *ngIf="importModel?.pageDesc[importModel.tabIndex]">
        <div class="col second-title mt-2 mb-2">
          {{importModel?.pageDesc[importModel.tabIndex]}}
        </div>
      </div>
    </div>
  </ng-container>

  <div [hidden]="importModel.tabIndex != 0">
    <div class="row ">
      <div class="col pb-3">
        <i class="pi pi-file texttitle"></i>&nbsp;<a class="ancher-links" [href]="contactsXls">Contacts (.xlsx)</a>
        <i class="pi pi-file texttitle"></i>&nbsp;<a class="ancher-links" [href]="loanXls">Loans (.xlsx)</a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span>
          <p-fileUpload chooseIcon="pi pi-upload" #listUploader name="file[]" accept=".xlsx" [customUpload]="true"
            (onRemove)="onRemoveFile($event)" (uploadHandler)="contactListUploadHandler($event)" maxFileSize="5242880"
            [auto]="true" chooseLabel="Upload .xlsx">

            <ng-template pTemplate="content">
              <div class="py-3"> Or drag and drop file here</div>
            </ng-template>
          </p-fileUpload>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="para-section mt-10" id="clickwrapContainer"></div>
      </div>
    </div>
  </div>

  <div [hidden]="importModel.tabIndex != 1">
    <div [hidden]="importModel?.showPreview">
      <div class="row fixed-row ">
        <div class="row mt-3 mb-3 ">
          <div class="col-6 texttitle">
            Import Field
          </div>
          <div class="col-6 texttitle p-15">
            Aduvo Field
          </div>
        </div>
      </div>
      <div *ngIf="items" class="row pd-111" [formGroup]="importFormGroup">
        <div class="col-12 importlist" formArrayName="items" *ngFor="let item of formDataCtrl.controls; let i = index">
          <div class="row p-mt-2 align-items-center" [formGroupName]="i">
            <div class="col-6 fieldlabel">
              {{ item.get('importField').value }}
            </div>
            <div class="col-6">
              <p-dropdown dataKey="aduvoFieldID" placeholder="Select" [options]="importModel?.aduvoFields"
                optionLabel="fieldName" (onChange)="fieldChanged($event,i)" [style]="{'width':'98%'}"
                formControlName="aduvoField" filterBy="fieldName" [filter]="true"
                [showClear]="item?.get('aduvoField')?.value"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="!importModel?.showPreview" class="p-35">
      <div class="col-12 page_title ms-0 fixed-subtitle">
        Review Field Mapping
      </div>
      <div style="position: relative;">
        <div class="col-12 texttitle fixsub mb-3 mt-2">
          The following fields are mapped successfully:
        </div>
        <!-- <div class="formimfosection"> -->
        <div class="row importinfomapped background-p"
          *ngFor="let item of importModel.mappedJsonResponse; let i = index">
          <div class="col-6">
            {{item.ExcelColumnHeader}} <i class="fa fa-arrow-right float-end mt-1"></i>
          </div>
          <div class="col-6">
            {{item.AduvoField}}
          </div>
        </div>
        <!-- </div> -->
      </div>
      <ng-container *ngIf="missingMandatoryStr">
        <div class="row">
          <div class="col-12 mb-3 mt-2 ms-2 texttitleerror">
            The following mandatory fields are not mapped, please map them first to continue:
          </div>
        </div>
        <div class="row importinfomapped background-p">
          <div class="col-12 ">
            {{missingMandatoryStr}}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="importModel?.unMappedHeaderArr?.length">
        <div class="row">
          <div class="col-12 texttitle mb-3 mt-5 ms-1">
            The following fields did NOT mapped successfully:
          </div>
        </div>
        <div class="row align-items-center mb-3 importinfomapped"
          *ngFor="let item of importModel?.unMappedHeaderArr; let i = index">
          <div class="col-6">
            {{ item }}
          </div>
          <div class="col-6">
            <p-dropdown dataKey="aduvoFieldID" placeholder="Select" [options]="importModel?.aduvoFields"
              [disabled]="true" optionLabel="fieldName" [style]="{'width':'98%'}"></p-dropdown>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div [hidden]="importModel.tabIndex != 2">
    <div class="col" *ngIf="this.importResponse != null && this.importResponse.importType == 'Contact'">
      <label class="page_title mt-2 ml-zero">Choose Default Contact Owner</label>
    </div>
    <div class="col" *ngIf="this.importResponse != null && this.importResponse.importType == 'LoanContact'">
      <label class="page_title mt-2 ml-zero">Choose Default Loan Officer</label>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <!-- <p-dropdown dataKey="tenantUserId" [options]="username" placeholder="Select" optionLabel="name"
          [style]="{'width':'98%'}"
          ></p-dropdown> -->
        <p-dropdown dataKey="companyUserId" [options]="userLists" placeholder="Select" optionLabel="name" [filter]="true"
          filterBy="name" class="tenantUser"  optionValue="companyUserId"  [(ngModel)]="this.importResponse != null && importResponse.defaultCompanyUserId" [style]="{'width':'98%'}"></p-dropdown>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 chiptag" *ngIf="this.importResponse != null && this.importResponse.importType == 'Contact'">
        Aduvo will assign the selected name as the contact's owner if missing from the import
      </div>
      <div class="col-12 chiptag" *ngIf="this.importResponse != null && this.importResponse.importType == 'LoanContact'">
        Aduvo will assign the selected name to imported loans when a loan officer is missing
      </div>
    </div>
  </div>

  <div [hidden]="importModel.tabIndex != 3">
    <div class="row">
      <div class="col-12 mt-4" id="pchip1">
        <p-chips id="tagsChip" [(ngModel)]="tags" separator="," styleClass="list_input" (keydown)="onChange($event)"
          (onAdd)="onAdd($event)">
          <ng-template let-item pTemplate="item">
            <div [innerHTML]="item"></div>
            <i class="fa fa-times ms-3" (click)="removeFilterClick($event, item)"></i>
          </ng-template>
        </p-chips>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 chiptag">
        Type and hit enter to create your new tag
      </div>
    </div>
  </div>
  <!-- <div *ngIf="dialogConfirmN == 1" class="text-center" >
     <i class="pi pi-spin pi-spinner importspin mt-5" style="font-size:2rem"></i>
    <p class="f-400">Aduvo is processing your import request. Please wait about 5-10 seconds unit it completes or click below to cancel</p>
    <button pButton pRipple class="p-button p-button-rounded mt-4">Cancel Import</button>
  </div> -->
  <div [hidden]="importModel.tabIndex != 4" class="text-center">
    <p class="f-400">Your list has been added to our review queue, You'll receive an email notification once we've
      reviewed.</p>
    <button pButton pRipple class="p-button p-button-rounded mt-4" (click)="closeMe()">Close</button>
  </div>
  <div class="row">
    <div class="row mt-4" [hidden]="importModel.tabIndex == 4"
      [ngClass]="[importModel.tabIndex == 1  ? 'fixed-button-group' : '']">
      <div class="col-4">
        <button *ngIf="importModel.tabIndex > 0 " pButton pRipple class="p-button p-button-rounded outline-btn"
          (click)="previous(target)">Back</button>
      </div>
      <div class="col-4">
      </div>
      <div class="col-4 text-end">
        <button pButton pRipple class="p-button p-button-rounded" (click)="next(target)"
          [hidden]="importModel.tabIndex == 2"
          [disabled]="!(!!saveModel?.uploadedExcelFile && isContractChecked)">Continue</button>

        <button pButton pRipple class="p-button p-button-rounded" (click)="next(target)"
          [hidden]="importModel.tabIndex == 0 || importModel.tabIndex == 1 || importModel.tabIndex == 3 "
          [disabled]="!userLists || userLists.length == 0 || this.importResponse != null && !importResponse.defaultCompanyUserId">Continue</button>
      </div>
    </div>
  </div>

  <!--
	*************************************************************************************
	Generic message when no user in selected list Dialogs 
	*************************************************************************************
  -->
	<p-dialog class="custom-alert custom-user-alert" [(visible)]="showMessageDialog" [resizable]="false"
	        [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700', 'z-index': '1000!important'}">
    <div class="pb-25 ptminus35">
      <span class="alert-header">{{ this.genericMessage }}</span>
    </div>
    <ng-template pTemplate="footer text-left">
      <div class="col text-left">
        <!-- <p-button [style]="{ 'margin-left': '0.5px' }" (click)="showMessageDialog = false" label="Continue"></p-button> -->
        <button pButton  (click)="showMessageDialog = false" label="Continue"></button>
      </div>
    </ng-template>
	</p-dialog>
