/** 
* Implementation of the FocusIT.DataContracts.Pulse.Message [DataContract].
*/
export class Message {

  public campaignMessageId:string ='00000000-0000-0000-0000-000000000000';
  public MessageId: string = '00000000-0000-0000-0000-000000000000';
  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public TemplateId: string = '00000000-0000-0000-0000-000000000000';
  public TemplateTitle: string = '';
  public ReportId: string = '00000000-0000-0000-0000-000000000000';
  public MessageTitle: string = '';
  public MessageSubject: string = '';
  public MessageCategoryName: string = '';
  public MessageCategoryId: number = 0;
  public ScheduleTypeName: string = '';
  public ScheduleTypeId: number = 0;
  public BccContactType: number = null;
  public CcContactType: number = null;
  public ContactType: number = 0;
  public MessageFrom: string = '';
  public MessageTypeName: string = '';
  public MessageTypeId: number = 0;
  public ScheduleDateUtc: Date = new Date();
  public ScheduleFrequency: string = '';
  public LoanOriginationSystem: number = 0;
  public CreatedBy: string = '';
  public CreatedOnUtc: Date = new Date();
  public ModifiedBy: string = '';
  public ModifiedOnUtc: Date = new Date();
  public Owner: string = '';
  public OwnerFullName: string = '';
  public Enabled: boolean = true;
  public Rules: string = '[]';
  public OptedInTenants: string = '';
  public IsDeleted: boolean = false;
  public SortOrder: number = 0;

  public DisplayEnabled: string = '';
  public Tooltip: string = '';
  public PreHeaderText :string = '';
  public QuickPickStatusField :string = '';
  public QuickPickStatusFieldId :string = '';
  public MessageStats: any;

  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param campaignMessageId
  * @param MessageId
  * @param TenantId
  * @param TemplateId
  * @param TemplateTitle
  * @param ReportId
  * @param MessageTitle
  * @param MessageSubject
  * @param MessageCategoryName
  * @param MessageCategoryId
  * @param ScheduleTypeName
  * @param ScheduleTypeId
  * @param BccContactType
  * @param CcContactType
  * @param ContactType
  * @param MessageFrom
  * @param MessageTypeName
  * @param MessageTypeId
  * @param ScheduleDateUtc
  * @param ScheduleFrequency
  * @param LoanOriginationSystem
  * @param CreatedBy
  * @param CreatedOnUtc
  * @param ModifiedBy
  * @param ModifiedOnUtc
  * @param Owner
  * @param OwnerFullName
  * @param Enabled
  * @param Rules
  * @param IsDeleted
  * @param SortOrder
  * @param MessageStats
  */
  constructor(
    campaignMessageId?: string,
    MessageId?: string,
    TenantId?: string,
    TemplateId?: string,
    TemplateTitle?: string,
    ReportId?: string,
    MessageTitle?: string,
    MessageSubject?: string,
    MessageCategoryName?: string,
    MessageCategoryId?: number,
    ScheduleTypeName?: string,
    ScheduleTypeId?: number,
    BccContactType?: number,
    CcContactType?: number,
    ContactType?: number,
    MessageFrom?: string,
    MessageTypeName?: string,
    MessageTypeId?: number,
    ScheduleDateUtc?: Date,
    ScheduleFrequency?: string,
    LoanOriginationSystem?: number,
    CreatedBy?: string,
    CreatedOnUtc?: Date,
    ModifiedBy?: string,
    ModifiedOnUtc?: Date,
    Owner?: string,
    OwnerFullName?: string,
    Enabled?: boolean,
    Rules?: string,
    OptedInTenants?: string,
    IsDeleted?: boolean,
    SortOrder?: number,
    PreHeaderText?:string,
    QuickPickStatusField?: string,
    MessageStats?: any,
    QuickPickStatusFieldId?: string) {
    if(campaignMessageId) this.campaignMessageId=campaignMessageId;
    if ((MessageId !== undefined) && (MessageId !== null)) this.MessageId = MessageId;
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
    if ((TemplateId !== undefined) && (TemplateId !== null)) this.TemplateId = TemplateId;
    if ((TemplateTitle !== undefined) && (TemplateTitle !== null)) this.TemplateTitle = TemplateTitle;
    if ((ReportId !== undefined) && (ReportId !== null)) this.ReportId = ReportId;
    if ((MessageTitle !== undefined) && (MessageTitle !== null)) this.MessageTitle = MessageTitle;
    if ((MessageSubject !== undefined) && (MessageSubject !== null)) this.MessageSubject = MessageSubject;
    if ((MessageCategoryName !== undefined) && (MessageCategoryName !== null)) this.MessageCategoryName = MessageCategoryName;
    if ((MessageCategoryId !== undefined) && (MessageCategoryId !== null)) this.MessageCategoryId = MessageCategoryId;
    if ((ScheduleTypeName !== undefined) && (ScheduleTypeName !== null)) this.ScheduleTypeName = ScheduleTypeName;
    if ((ScheduleTypeId !== undefined) && (ScheduleTypeId !== null)) this.ScheduleTypeId = ScheduleTypeId;
    if (BccContactType !== undefined) this.BccContactType = BccContactType;
    if (CcContactType !== undefined) this.CcContactType = CcContactType;
    if ((ContactType !== undefined) && (ContactType !== null)) this.ContactType = ContactType;
    if ((MessageFrom !== undefined) && (MessageFrom !== null)) this.MessageFrom = MessageFrom;
    if ((MessageTypeName !== undefined) && (MessageTypeName !== null)) this.MessageTypeName = MessageTypeName;
    if ((MessageTypeId !== undefined) && (MessageTypeId !== null)) this.MessageTypeId = MessageTypeId;
    if ((ScheduleDateUtc !== undefined) && (ScheduleDateUtc !== null)) this.ScheduleDateUtc = ScheduleDateUtc;
    if ((ScheduleFrequency !== undefined) && (ScheduleFrequency !== null)) this.ScheduleFrequency = ScheduleFrequency;
    if ((LoanOriginationSystem !== undefined) && (LoanOriginationSystem !== null)) this.LoanOriginationSystem = LoanOriginationSystem;
    if ((CreatedBy !== undefined) && (CreatedBy !== null)) this.CreatedBy = CreatedBy;
    if ((CreatedOnUtc !== undefined) && (CreatedOnUtc !== null)) this.CreatedOnUtc = CreatedOnUtc;
    if ((ModifiedBy !== undefined) && (ModifiedBy !== null)) this.ModifiedBy = ModifiedBy;
    if ((ModifiedOnUtc !== undefined) && (ModifiedOnUtc !== null)) this.ModifiedOnUtc = ModifiedOnUtc;
    if ((Owner !== undefined) && (Owner !== null)) this.Owner = Owner;
    if ((OwnerFullName !== undefined) && (OwnerFullName !== null)) this.OwnerFullName = OwnerFullName;
    if ((Enabled !== undefined) && (Enabled !== null)) this.Enabled = Enabled;
    if ((Rules !== undefined) && (Rules !== null)) this.Rules = Rules;
    if ((OptedInTenants !== undefined) && (OptedInTenants !== null)) this.OptedInTenants = OptedInTenants;
    if ((IsDeleted !== undefined) && (IsDeleted !== null)) this.IsDeleted = IsDeleted;
    if ((SortOrder !== undefined) && (SortOrder !== null)) this.SortOrder = SortOrder;
    if ((PreHeaderText !== undefined) && (PreHeaderText !== null)) this.PreHeaderText = PreHeaderText;
    if ((QuickPickStatusField !== undefined) && (QuickPickStatusField !== null)) this.QuickPickStatusField = QuickPickStatusField;
    if(MessageStats) this.MessageStats=MessageStats;
    if(QuickPickStatusFieldId) this.QuickPickStatusFieldId=QuickPickStatusFieldId;

    this.DisplayEnabled = ((this.Enabled) ? 'Yes' : 'No');
    if (this.MessageTypeId == 0)
      this.Tooltip = 'Email Message';
    else if (this.MessageTypeId == 1)
      this.Tooltip = 'SMS/Text Message';

  }
}

export class OptedInTenants {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';

  constructor(    
    TenantId?: string) {
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
  }
}

/**
* Collection of FocusIT.DataContracts.Pulse.Message [DataContract] objects.
*/
export class MessageCollection {
  Items: Message[] = [];
}

export class TextMessage {
  textMessageName: string;
  textMessageDescription: string;
  textMessageCategoryId: string;
  chkQuickStart: boolean;
  chkRecommended: boolean;
}