<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
               style="width:58.31%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-auto d-flex justify-content-center flex-column text-center permission-text">
    <h5>
      <!-- Do you collect permission to text your borrowers, realtors and loan contacts as part of
      your application process? -->
      <div class="para-section mt-10" id="clickwrapContainer"></div>
    </h5>
    <br>
    <!-- <p-calendar appendTo="body" [dateFormat]="dateFormat" [minDate]="tomorrowDate" id="otherEventDate-{{ i }}"
      inputId="icon" [formControlName]="i" [readonlyInput]="true" [showIcon]="true">
    </p-calendar> -->
    <div class="btn-group btn-group-toggle justify-content-center mt-4" data-toggle="buttons">
      <!-- <label class="btn btn-primary radiobtn-custom" [class.active]="selectedItem === 1">
        <input type="radio" name="options" id="option1" (change)="actionContent($event, 'YesRadio')"> Yes
      </label> -->
      <label class="btn btn-primary radiobtn-custom collectbtn" [class.active]="selectedItem === 0">
        <input type="radio" name="options" id="option2" (change)="actionContent($event, 'NoRadio')"> No, I don't collect this
      </label>
    </div>
    <div class="d-flex justify-content-center">
      <!-- <button class="p-button mx-2">Yes</button>
      <button class="p-button back-button-new  mx-2">No</button> -->
    </div>
  </div>

  <div class="footer-section">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded"
                (click)="redirectToBack()">
          <i class="fa fa-arrow-left"></i>&nbsp;Back
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end" (click)="redirectToOptIn()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
