<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0"
               aria-valuemax="100" style="width:50%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-middle">
    <div class="container-size">
      <div class="m-auto d-flex justify-content-center flex-column text-center send-query-text">
        <h5>Do you plan to send text messages?</h5>
        <div class="btn-group btn-group-toggle justify-content-center mt-5" data-toggle="buttons">
          <label class="btn btn-primary radiobtn-custom" [class.active]="selectedItem === 1">
            <input type="radio" name="options" id="option1" (change)="actionContent($event, 'YesRadio')"> Yes
          </label>
          <label class="btn btn-primary radiobtn-custom" [class.active]="selectedItem === 0">
            <input type="radio" name="options" id="option2" (change)="actionContent($event, 'NoRadio')"> No
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded font-16-bold"
                (click)="redirectToBack()">
          <i class="fa fa-arrow-left"></i>&nbsp;Back
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end font-16-bold" (click)="redirectToOptIn()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>
        <app-to-dashboard-button class="float-end"></app-to-dashboard-button>
      </div>
    </div>
  </div>
</div>
