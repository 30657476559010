import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestResponse } from 'src/models/RequestResponse'
import { AppSettings } from 'src/appSettings';
import { NGENTenant, NGENTenantCollection } from 'src/models/NGENTenant';

@Injectable()
export class OnBoardingService {
  constructor(private http: HttpClient) { }

  // ********************************************************************************
  // WEB API Methods
  // ********************************************************************************
  async GetLoggedOnUser() {
    return await this.http.get<any>(AppSettings.API_GET_ACCOUNT + '/get')
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async Get(tenantId: string) {
    console.info('Call /GET in the webapi Tenant Controller');
    return await this.http.get<RequestResponse>(AppSettings.API_GET_TENANT)
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  
  async OnBoardWelcomeTenantProfile(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Tenant Controller');
    return await this.http.put<RequestResponse>(AppSettings.API_GET_TENANT + '/OnBoardWelcome', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async OnBoardingAdminProfile(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Tenant Controller');
    return await this.http.put<RequestResponse>(AppSettings.API_GET_TENANT + '/OnBoardAdmin', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async OnBoardingCarrierProfile(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Tenant Controller');
    return await this.http.put<RequestResponse>(AppSettings.API_GET_TENANT + '/OnBoardCarrier', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async OnBoardUpdateSmsMarketing(smsMarketing: boolean) {
    console.info('Call /OnBoardUpdateSmsMarketing in the webapi Tenant Controller');
    return await this.http.put<any>(AppSettings.API_GET_TENANT + '/OnBoardUpdateSmsMarketing?smsMarketing=' + smsMarketing, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  
  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }

  // ********************************************************************************
  // Helper Methods
  // ********************************************************************************

  toCollectionInstance(data: any[]) {
    let result: NGENTenantCollection = new NGENTenantCollection();
    for (let x = 0; x < data.length; x++)
      result.Items.push(this.toInstance(data[x]));
    return result;
  }

  toInstance(data: any) {
    let result = new NGENTenant(
      data.tenantId,
      data.name,
      data.lenderId,
      data.website,
      data.addressLine1,
      data.city,
      data.state,
      data.zip,
      data.email,
      data.workPhone,
      data.fax,
      data.Suite,
      data.defaultReplyEmail,
      data.defaultSenderEmail,
      data.stateLicenses,
      data.nmls,
      data.faceBookUrl,
      data.twitterUrl,
      data.linkedInUrl,
      data.leadIntakeUrl,
      data.leadIntakeDescription,
      data.signature,
      data.logo,
      '',
      0,
      data.runMode,
      data.lenderId,
      data.dbConnectionString,
      data.timezoneId,
      data.sendgridApiKeyLoanStatus,
      data.sendgridApiKeyMarketing,
      data.ein,
      data.legalBusinessName,
      data.businessType,
      data.businessRegistrationType,
      data.businessRegionsOfOperation,
      data.legalBusinessStreet,
      data.legalBusinessCity,
      data.legalBusinessCountry,
      data.legalBusinessState,
      data.legalBusinessZip,
      data.authorizedContactFirstName,
      data.authorizedContactLastName,
      data.authorizedContactEmail,
      data.authorizedContactBusinessTitle,
      data.authorizedContactCountry,
      data.authorizedContactPhone,
      data.authorizedContactJobPosition,
      data.companyAdminEmail,
      data.companyAdminFirstName,
      data.companyAdminLastName,
      data.integrationType,
      data.encompassClientId,
      data.encompassClientSecret,
      data.twilioAccountSid,
      data.twilioAPIKey,
      data.twilioMessagingServiceLoanStatus,
      data.twilioMessagingServiceMarketing
    );
    return result;
  }
} 
