import { isUndefined } from 'util';

/**
* Implementation of the FocusIT.Common.Web.IO.PagingData [DataContract].
*/
export class PagingData {

  public totalRecords: number = 0;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public tableFirst: number = 0;
  public orderBy: string = null;
  public pagingRead: boolean = false;
  public returnRecord1: number = 0;

  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param totalRecords The total records that satisfy where clause criteria.
  * @param currentPage The current page of data desired/read.
  * @param pageSize The number of rows per page.
  * @param tableFirst The row offset of the first record on the page into the (overall) result set.
  * @param orderBy Specifies the ORDER BY clause.
  * @param pagingRead Instructs a controller to perform paging reads (default = false).
  */
  constructor(
    totalRecords?: number,
    currentPage?: number,
    pageSize?: number,
    tableFirst?: number,
    orderBy?: string,
    pagingRead?: boolean) {
    if ((totalRecords !== undefined) && (totalRecords !== null)) this.totalRecords = totalRecords;
    if ((currentPage !== undefined) && (currentPage !== null)) this.currentPage = currentPage;
    if ((pageSize !== undefined) && (pageSize !== null)) this.pageSize = pageSize;
    if ((tableFirst !== undefined) && (tableFirst !== null)) this.tableFirst = tableFirst;
    if (orderBy !== undefined) this.orderBy = orderBy;
    if (this.pagingRead !== undefined) this.pagingRead = pagingRead;
  }

  static totalPages(pagingData: PagingData): number {
    if ((pagingData == undefined) || (pagingData == null))
      return 0;
    if ((pagingData.totalRecords == undefined) || (pagingData.totalRecords == null) || (pagingData.totalRecords < 1))
      return 0;
    if ((pagingData.pageSize == undefined) || (pagingData.pageSize == null) || (pagingData.pageSize < 1))
      return 0;

    var remainder = pagingData.totalRecords % pagingData.pageSize;
    var pages = parseInt((pagingData.totalRecords / pagingData.pageSize).toString());

    if (remainder > 0)
      pages++;

    return pages;
  }

  /**
   * Selected page changed in the paginator.
   * Returns true for Lazy Load data and the page changed, false otherwise.
   *
   * @param pagingData PagingData - the page's paging information.
   * @param event The dispatched (onPage) or (onLazyLoad) event.
   * @param table The <p-table> 
   */
  static onPageChanged(pagingData, event, table): boolean {
    if (!isUndefined(event.globalFilter)) {
      return this.onLazyLoadPageChanged(pagingData, event);
    }
    PagingData.onNonLazyLoadPageChanged(pagingData, event, table);
    return false;
  }

  /**
  * Selected page changed in the paginator for Lazy Load data.
  * Returns true if the page changed, false otherwise.
  *
  * @param pagingData PagingData - the page's paging information.
  * @param event The dispatched (onLazyLoad) event.
  */
  static onLazyLoadPageChanged(pagingData, event): boolean {
    // event.first = First row offset
    // event.rows = Number of rows per page
    // event.sortField = Sort field in single sort
    // event.sortOrder = Sort order in single sort as number, 1 for asc and -1 for dec
    // event.multiSortMeta = SortMeta array in multiple sort

    // rows per page changed?
    if (event.rows != pagingData.pageSize) {
      let readRequired: boolean = true;

      if ((pagingData.pageSize >= pagingData.totalRecords) &&
        (event.rows >= pagingData.totalRecords)) {
        readRequired = false;
      }

      pagingData.pageSize = event.rows;
      //always go back to first page
      pagingData.first = 0;
      pagingData.currentPage = 1;

      // get data
      return readRequired;
    }
    else {
      // integer value of page number entered into text field
      let nCurrentPage = pagingData.currentPage;
      // can't have 0 or less
      if (nCurrentPage <= 0)
        nCurrentPage = 1;

      pagingData.first = event.first;
      let page = (event.first / pagingData.pageSize) + 1;
      if (page != pagingData.currentPage) {
        pagingData.currentPage = page;

        if (pagingData.currentPage != nCurrentPage) {
          return true;
        }
      }
    }

    return false;
  }

  /**
  * Selected page changed in the paginator.
  *
  * @param pagingData PagingData - the page's paging information.
  * @param event The dispatched (onPage) event.
  * @param table The <p-table>
  */
  static onNonLazyLoadPageChanged(pagingData, event, table): void {
    // event.first = First row offset
    // event.rows = Number of rows per page
    let first = event.first;
    if (pagingData.pageSize !== event.rows) {
      // keep pageSize in sync
      pagingData.pageSize = event.rows;

      // go back to first page (optional)
      pagingData.currentPage = 1;
      table.first = 0;
    }
    else {
      // calculate currentPage
      pagingData.currentPage = (first / pagingData.pageSize) + 1;
    }
  }

  /**
   * Goes to the user entered page.
   * Returns true for Lazy Load data and the page changed, false otherwise.
   *  
   * @param pagingData PagingData - the page's paging information.
   * @param table The <p-table>
   */
  static onGotoPage(pagingData, table): boolean {
    let first = table.first;
    let newFirst = first;

    // integer value of page number entered into text field
    let nCurrentPage = pagingData.currentPage;
    // can't have 0 or less
    if (nCurrentPage <= 0)
      nCurrentPage = 1;

    try {
      // calculate the row number of the first record on the page we are navigating to
      newFirst = ((nCurrentPage - 1) * pagingData.pageSize);
      // is it within range
      if ((newFirst >= 0) && (newFirst < table._totalRecords)) {
        first = newFirst;
        pagingData.currentPage = nCurrentPage;
      }
      else {
        // beyond the last available record
        let remainder = (table._totalRecords % pagingData.pageSize); // if greater than 0, last available page is not full
        let wholePages = ((table._totalRecords - remainder) / pagingData.pageSize); // number of full pages
        // calculate the first record number of last full page
        first = (wholePages * pagingData.pageSize);
        // is the last page less than full
        if (remainder > 0)
          pagingData.currentPage = wholePages + 1;
        else
          pagingData.currentPage = wholePages;
      }
    }
    catch (ex) {
      console.log(ex.toString());
    }

    let result: boolean = false;
    if (table.lazy) {
      if (((pagingData.currentPage - 1) * pagingData.pageSize) != table.first)
        result = true;
    }

    // set the value for the first record number on the page we are navigating to
    table.first = first;

    return result;
  }

  static onGotoDVPage(pagingData, table): boolean {
    let first = table.first;
    let newFirst = first;

    // integer value of page number entered into text field
    let nCurrentPage = pagingData.currentPage;
    // can't have 0 or less
    if (nCurrentPage <= 0)
      nCurrentPage = 1;

    try {
      // calculate the row number of the first record on the page we are navigating to
      newFirst = ((nCurrentPage - 1) * pagingData.pageSize);
      // is it within range
      if ((newFirst >= 0) && (newFirst < table.totalRecords)) {
        first = newFirst;
        pagingData.currentPage = nCurrentPage;
      }
      else {
        // beyond the last available record
        let remainder = (table.totalRecords % pagingData.pageSize); // if greater than 0, last available page is not full
        let wholePages = ((table.totalRecords - remainder) / pagingData.pageSize); // number of full pages
        // calculate the first record number of last full page
        first = (wholePages * pagingData.pageSize);
        // is the last page less than full
        if (remainder > 0)
          pagingData.currentPage = wholePages + 1;
        else
          pagingData.currentPage = wholePages;
      }
    }
    catch (ex) {
      console.log(ex.toString());
    }

    let result: boolean = false;
    if (table.lazy) {
      if (((pagingData.currentPage - 1) * pagingData.pageSize) != table.first)
        result = true;
    }

    // set the value for the first record number on the page we are navigating to
    table.first = first;

    return result;
  }
}
