/** 
* Implementation of the FocusIT.DataContracts.Pulse.Message [DataContract].
*/
export class MessageCampaign {

  public campaignId: string = '00000000-0000-0000-0000-000000000000';
  public ownerTenantUserId: string = '00000000-0000-0000-0000-000000000000';
  public templateId: string = '00000000-0000-0000-0000-000000000000';
  public messageTypeToSend: string = '';
  public subject: string = '';
  public scheduleType: string = '';
  public scheduleSubtype: string = '';
  public scheduleJson: string = '';
  public sentJson: string = '';
  public preHeaderText: string = '';
  public messageTemplateTitle: string = '';
  public templateTypeId: number;
  public dateStartUtc: Date = new Date();
  public dateEndUtc: Date = new Date();
  public isPublishingDb: boolean = false;

  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * @param campaignId
  * @param ownerTenantUserId
  * @param templateId
  * @param messageTypeToSend
  * @param subject
  * @param scheduleType
  * @param scheduleSubtype
  * @param scheduleJson
  * @param sentJson
  * @param preHeaderText
  * @param dateStartUtc
  * @param dateEndUtc
  * @param templateTypeId
  * @param messageTemplateTitle
  */
  constructor(
    campaignId?: string,
    ownerTenantUserId?: string,
    templateId?: string,
    messageTypeToSend?: string,
    subject?: string,
    scheduleType?: string,
    scheduleSubtype?: string,
    scheduleJson?: string,
    sentJson?: string,
    preHeaderText?: string,
    dateStartUtc?: Date,
    dateEndUtc?: Date,
    messageTemplateTitle?: string,
    templateTypeId?: number) {
    if (campaignId) this.campaignId = campaignId;
    if (ownerTenantUserId) this.ownerTenantUserId = ownerTenantUserId;
    if (templateId) this.templateId = templateId;
    if (messageTypeToSend) this.messageTypeToSend = messageTypeToSend;
    if (subject) this.subject = subject;
    if (scheduleType) this.scheduleType = scheduleType;
    if (scheduleSubtype) this.scheduleSubtype = scheduleSubtype;
    if (scheduleJson) this.scheduleJson = scheduleJson;
    if (sentJson) this.sentJson = sentJson;
    if (preHeaderText) this.preHeaderText = preHeaderText;
    if (dateStartUtc) this.dateStartUtc = dateStartUtc;
    if (dateEndUtc) this.dateEndUtc = dateEndUtc;
    if (templateTypeId) this.templateTypeId = templateTypeId;
    if (messageTemplateTitle) this.messageTemplateTitle = messageTemplateTitle;
    this.isPublishingDb = false;
  }
}

export class OptedInTenants {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';

  constructor(
    TenantId?: string) {
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
  }
}

/**
* Collection of FocusIT.DataContracts.Pulse.Message [DataContract] objects.
*/
export class MessageCampaignIdCollection {
  Items: MessageCampaign[] = [];
}

