import { Injectable } from '@angular/core';
import { FilterMatchMode } from 'primeng/api';
import { Table } from 'primeng/table';
import { AndOr, ContactType, DbType, Group, SqlComparison, NumericFilters, LoanStatusId, LoanPurposeId } from 'src/models/enumerations';
import { WhereClauseCriteria, WhereClauseCriteriaCollection } from 'src/models/WhereClauseCriteria';

/**
* Utility service for working data table filters.
*/
@Injectable()
export class FilterHelperService {

  // ********************************************************************************
  // Advanced DATE filtering constants for dates based upon today's date.
  // ********************************************************************************

  /** SQL constant for Today. */
  public static SQL_TODAY = "DATEFROMPARTS(DATEPART(year, GETDATE()), DATEPART(month, GETDATE()), DATEPART(day, GETDATE()))";
  /** SQL constant for the Starting day of this week. */
  public static SQL_START_THIS_WEEK = "DATEFROMPARTS(DATEPART(year, DATEADD(day, ((DATEPART(weekday, GETDATE())-1)*-1), GETDATE())),DATEPART(month, DATEADD(day, ((DATEPART(weekday, GETDATE())-1)*-1), GETDATE())),DATEPART(day, DATEADD(day, ((DATEPART(weekday, GETDATE())-1)*-1), GETDATE())))";
  /** SQL constant for the Ending day of this week. */
  public static SQL_END_THIS_WEEK = "DATEFROMPARTS(DATEPART(year, DATEADD(day, (7-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(month, DATEADD(day, (7-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(day, DATEADD(day, (7-DATEPART(weekday, GETDATE())), GETDATE())))";
  /** SQL constant for the Starting day of this month. */
  public static SQL_START_THIS_MONTH = "DATEFROMPARTS(DATEPART(year, GETDATE()), DATEPART(month, GETDATE()), 1)";
  /** SQL constant for the Ending day of this month. */
  public static SQL_END_THIS_MONTH = "EOMONTH(GETDATE())";
  /** SQL constant for the Starting day of next week. */
  public static SQL_START_NEXT_WEEK = "DATEFROMPARTS(DATEPART(year, DATEADD(day, (8-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(month, DATEADD(day, (8-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(day, DATEADD(day, (8-DATEPART(weekday, GETDATE())), GETDATE())))";
  /** SQL constant for the Ending day of next week. */
  public static SQL_END_NEXT_WEEK = "DATEFROMPARTS(DATEPART(year, DATEADD(day, (14-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(month, DATEADD(day, (14-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(day, DATEADD(day, (14-DATEPART(weekday, GETDATE())), GETDATE())))";
  /** SQL constant for the Starting day of next month. */
  public static SQL_START_NEXT_MONTH = "DATEFROMPARTS(DATEPART(year, DATEADD(month, 1, GETDATE())), DATEPART(month, DATEADD(month, 1, GETDATE())), 1)";
  /** SQL constant for the Ending day of next month. */
  public static SQL_END_NEXT_MONTH = "EOMONTH(DATEFROMPARTS(DATEPART(year, DATEADD(month, 1, GETDATE())), DATEPART(month, DATEADD(month, 1, GETDATE())), 1))";

  // ********************************************************************************
  // BIRTHDAY filtering constants for days of the year based upon today's date.
  // Days of the year, since for birthdays we cannot include any year specification.
  // ********************************************************************************

  /** SQL constant for today's birthdays. */
  public static SQL_ANYYEAR_TODAY = "DATEPART(dayofYear, GETDATE())";
  /** SQL constant for the Starting day of this week. */
  public static SQL_ANYYEAR_START_THIS_WEEK = "DATEPART(dayofYear, DATEFROMPARTS(DATEPART(year, DATEADD(day, ((DATEPART(weekday, GETDATE())-1)*-1), GETDATE())),DATEPART(month, DATEADD(day, ((DATEPART(weekday, GETDATE())-1)*-1), GETDATE())),DATEPART(day, DATEADD(day, ((DATEPART(weekday, GETDATE())-1)*-1), GETDATE()))))";
  /** SQL constant for the Ending day of this week. */
  public static SQL_ANYYEAR_END_THIS_WEEK = "DATEPART(dayofYear, DATEFROMPARTS(DATEPART(year, DATEADD(day, (7-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(month, DATEADD(day, (7-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(day, DATEADD(day, (7-DATEPART(weekday, GETDATE())), GETDATE()))))";
  /** SQL constant for this month's birthdays. */
  public static SQL_ANYYEAR_THIS_MONTH = "DATEPART(month, GETDATE())";
  /** SQL constant for the Starting day of next week. */
  public static SQL_ANYYEAR_START_NEXT_WEEK = "DATEPART(dayofYear, DATEFROMPARTS(DATEPART(year, DATEADD(day, (8-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(month, DATEADD(day, (8-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(day, DATEADD(day, (8-DATEPART(weekday, GETDATE())), GETDATE()))))";
  /** SQL constant for the Ending day of next week. */
  public static SQL_ANYYEAR_END_NEXT_WEEK = "DATEPART(dayofYear, DATEFROMPARTS(DATEPART(year, DATEADD(day, (14-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(month, DATEADD(day, (14-DATEPART(weekday, GETDATE())), GETDATE())),DATEPART(day, DATEADD(day, (14-DATEPART(weekday, GETDATE())), GETDATE()))))";
  /** SQL constant for next month's birthdays. */
  public static SQL_ANYYEAR_NEXT_MONTH = "IIF(DATEPART(month, GETDATE())+1 = 13, 1, DATEPART(month, GETDATE())+1)";

  constructor() { }

  // ********************************************************************************
  // Saved Filter Restoration (NGENReports table)
  // ********************************************************************************
  /**
   * Restores the filter settings from those that were saved.
   * 
   * @param filters Current table filters (created when the page is instantiated).
   * @param persistedFilters Saved filter specifications.
   * @param frozenColumns Frozen column array (always visible).
   * @param displayedColumns Displayed column array (initially visible, but may be toggled).
   * @param selectableColumns Optional column array (initially hidden, but may be toggled).
   */
  restoreFilters(filters: any, persistedFilters: string, frozenColumns: any, displayedColumns: any, selectableColumns: any) {
    if ((!filters) || (!persistedFilters))
      return filters;

    // set the filters from the selected report
    var savedFilters = JSON.parse(persistedFilters);

    // Frozen column array (always visible).
    if ((frozenColumns != undefined) && (frozenColumns != null)) {
      frozenColumns.forEach((item) => {
        if (item.isFilterable) {
          if (savedFilters[item.field]) {
            // Filter exists: restore it
            filters[item.field] = savedFilters[item.field];
          }
          else {
            // No Filter exists: create a default
            filters[item.field] = this.getDefaultFilter(item.filterType);
          }
        }
      });
    }

    // Displayed column array (initially visible, but may be toggled).
    if ((displayedColumns != undefined) && (displayedColumns != null)) {
      displayedColumns.forEach((item) => {
        if (item.isFilterable) {
          if (savedFilters[item.field]) {
            // Filter exists: restore it
            filters[item.field] = savedFilters[item.field];
          }
          else {
            // No Filter exists: create a default
            filters[item.field] = this.getDefaultFilter(item.filterType);
          }
        }
      });
    }

    // Optional column array (initially hidden, but may be toggled)
    if ((selectableColumns != undefined) && (selectableColumns != null)) {
      selectableColumns.forEach((item) => {
        if (item.isFilterable) {
          if (savedFilters[item.field]) {
            // Filter exists: restore it
            filters[item.field] = savedFilters[item.field];
          }
          else {
            // No Filter exists: create a default
            filters[item.field] = this.getDefaultFilter(item.filterType);
          }
        }
      });
    }

    return filters;
  }

  /**
   * Returns a default filter specification for the passed filter type.
   * ('text' filter match mode is 'contains')
   * 
   * @param filterType 'text' (default), 'boolean', 'numeric', or 'date'
   */
  getDefaultFilter(filterType: any) {
    if (!filterType)
      return [{ "value": null, "matchMode": FilterMatchMode.CONTAINS }];

    if (filterType == 'boolean')
      return [{ "value": null, "matchMode": FilterMatchMode.EQUALS }];

    if (filterType == 'numeric')
      return [{ "value": null, "matchMode": FilterMatchMode.EQUALS }];
        
    if (filterType == 'date')
      return [{ "value": null, "matchMode": FilterMatchMode.DATE_IS }];

    return [{ "value": null, "matchMode": FilterMatchMode.CONTAINS }];
  }

  // ********************************************************************************
  // Dropdown Items
  // ********************************************************************************
  /**
   * Gets the dropdown items for selecting contact types.
   *
   * @param includePulseUser When true, the Pulse User contact type is included (default = false).
   */
  getContactTypeDropdownItems(includePulseUser: boolean = false) {
    // initialize Contact 'Type' filter dropdown items
    var contactTypes = [
      { name: "Appraiser", code: ContactType.Appraiser },
      { name: "Borrower", code: ContactType.Borrower },
      { name: "Buyers Agent", code: ContactType.BuyersAgent },
      { name: "CoBorrower", code: ContactType.CoBorrower },
      { name: "Closing Agent", code: ContactType.ClosingAgent },
      { name: "Hazard Insurance", code: ContactType.HazardInsurance },
      { name: "Lead", code: ContactType.Lead },
      { name: "Loan Officer", code: ContactType.LoanOfficer },
      { name: "Loan Officer Assistant", code: ContactType.LoanOfficerAssistant },
      { name: "Processor", code: ContactType.Processor },
      { name: "Realtor", code: ContactType.Realtor },
      { name: "Referral", code: ContactType.Referral },
      { name: "Sellers Agent", code: ContactType.SellersAgent },
      { name: "Title Insurance", code: ContactType.TitleInsurance }
    ];

    if (includePulseUser) {
      contactTypes.push({ name: "Pulse User", code: ContactType.PulseUser });
    }

    return contactTypes;
  }

  /**
   * Gets the dropdown items for selecting a Yes/No value.
   */
  getYesNoDropdownItems() {
    return [
      { name: "No Filter", value: null },
      { name: "Yes", value: 1 },
      { name: "No", value: 0 },
    ];
  }

  /**
   * Gets the dropdown items for selecting a Message Template Type value.
   */
  getMessageTemplateTypeDropdownItems() {
    return [
      { name: "No Filter", value: null },
      { name: "Email", value: 0 },
      { name: "Text", value: 1 },
    ];
  }

  /**
   * Gets the dropdown items for selecting a Message Template Category value.
   */
  getMessageTemplateCategoryDropdownItems() {
    return [
      { name: "No Filter", value: null },
      { name: 'Holidays', value: 2 },
      { name: 'Lead', value: 1 },
      { name: 'Loan Anniversaries', value: 5 },
      { name: 'Newsletters', value: 4 },
      { name: 'Post Close', value: 3 },
      { name: 'Uncategorized', value: 0 },
    ];
  }

  /**
   * Gets the dropdown items for selecting multiple Message Template Category values.
   */
  getMessageCategoryFilterDropdownItems() {
    return [
      { name: 'Holidays', value: 2 },
      { name: 'Lead', value: 1 },
      { name: 'Loan Anniversaries', value: 5 },
      { name: 'Newsletters', value: 4 },
      { name: 'Post Close', value: 3 },
      { name: 'Uncategorized', value: 0 },
    ];
  }

  /**
   * Gets the dropdown items for selecting months.
   */
  getMonthsDropdownItems() {
    return [
      { name: "January", code: 1 },
      { name: "February", code: 2 },
      { name: "March", code: 3 },
      { name: "April", code: 4 },
      { name: "May", code: 5 },
      { name: "June", code: 6 },
      { name: "July", code: 7 },
      { name: "August", code: 8 },
      { name: "September", code: 9 },
      { name: "October", code: 10 },
      { name: "November", code: 11 },
      { name: "December", code: 12 },
    ];
  }

  /**
   * Gets the dropdown items for selecting a days.
   */
  getDaysDropdownItems() {
    var days: any[] = [];
    days.push({ name: "All Days", code: 0 });
    for (var x = 1; x < 32; x++) {
      //days.push({ name: x.toString(), code: x });
      days.push({ name: this.formatDay(x), code: x });
    }
    return days;
  }

  /**
   * Gets the dropdown items for selecting date comparison options.
   */
  getDateDropdownItems() {
    return [
      { name: 'No Filter', code: -1 },
      { name: 'Day', code: 0 },
      { name: 'After', code: 1 },
      { name: 'On or After', code: 2 },
      { name: 'Before', code: 3 },
      { name: 'On or Before', code: 4 },
      { name: 'Between', code: 5 },
      { name: 'Week', code: 6 },
      { name: 'Month', code: 7 },
      { name: 'Year', code: 8 },
    ];
  }

  /**
   * Gets the dropdown items for selecting Advanced Date comparison options.
   */
  getAdvancedDateDropdownItems() {
    return [
      { name: 'No Filter', code: -1 },
      { name: 'Today', code: 0 },
      { name: 'This Week', code: 1 },
      { name: 'This Month', code: 2 },
      { name: 'Next Week', code: 3 },
      { name: 'Next Month', code: 4 },
      { name: 'Next', code: 5 },
      { name: 'Previous', code: 6 },
      { name: 'Within', code: 7 },
      { name: 'Select Date(s)', code: 99 },
    ];
  }

  /**
   * Gets the dropdown items for selecting any year Date comparison options.
   */
  getAnyYearDropdownItems() {
    return [
      { name: 'No Filter', code: -1, tooltip: "Any birthday" },
      { name: 'Today', code: 0, tooltip: "Today's birthdays" },
      { name: 'This Week', code: 1, tooltip: "This week's birthdays" },
      { name: 'This Month', code: 2, tooltip: "This month's birthdays" },
      { name: 'Next Week', code: 3, tooltip: "Next week's birthdays" },
      { name: 'Next Month', code: 4, tooltip: "Next month's birthdays" },
      { name: 'Next', code: 5, tooltip: 'Birthdays within the next number of days' },
      { name: 'Previous', code: 6, tooltip: 'Birthdays within the previous number of days' },
      { name: 'Within', code: 7, tooltip: 'Birthdays within the specified number of days' },
      { name: 'Select Month(s)', code: 99, tooltip: 'Birthdays based upon the criteria specified below' },
    ];
  }

  /**
   * Gets the dropdown items for selecting Advanced Date Secondary comparison options.
   */
  getAdvancedSecondaryDateDropdownItems() {
    return [
      { name: 'Day', code: 0 },
      { name: 'After', code: 1 },
      { name: 'On or After', code: 2 },
      { name: 'Before', code: 3 },
      { name: 'On or Before', code: 4 },
      { name: 'Between', code: 5 },
      { name: 'Week', code: 6 },
      { name: 'Month', code: 7 },
      { name: 'Year', code: 8 },
    ];
  }

  /**
   * Gets the dropdown items for selecting numeric filters.
   */
  getNumericDropdownItems() {
    // initialize numeric filter dropdown items
    var result = [
      { name: "No Filter", code: null, value1: null, value2: null },
      { name: "Equals", code: NumericFilters.Equals, value1: null, value2: null },
      { name: "Not equals", code: NumericFilters.NotEqual, value1: null, value2: null },
      { name: "Less than", code: NumericFilters.LessThan, value1: null, value2: null },
      { name: "Less than or Equal to", code: NumericFilters.LessThanOrEqual, value1: null, value2: null },
      { name: "Greater than", code: NumericFilters.GreaterThan, value1: null, value2: null },
      { name: "Greater than or equal to", code: NumericFilters.GreaterThanOrEqual, value1: null, value2: null },
      { name: "Between", code: NumericFilters.Between, value1: null, value2: null },
    ];
    return result;
  }

  /**
   * Gets the dropdown items for selecting loan purpose (aka loan type).
   */
  getLoanPurposeDropdownItems() {
    var result = [
      { name: "None", code: LoanPurposeId.None }, // 0
      { name: "Purchase", code: LoanPurposeId.Purchase }, // 1
      { name: "Cash-Out Refinance", code: LoanPurposeId.CashOutRefinance }, // 2
      { name: "No Cash-Out Refinance", code: LoanPurposeId.NoCashOutRefinance }, // 3
      { name: "Construction", code: LoanPurposeId.Construction }, // 4
      { name: "Construction Permanent", code: LoanPurposeId.ConstructionPermanent }, // 5
      { name: "Other", code: LoanPurposeId.Other }, // 6
    ];
    return result;
  }

  /**
   * Gets the dropdown items for selecting All Loan statuses.
   */
  getLoanStatusDropdownItems() {
    // initialize Loan 'Status' filter dropdown items

    /************************
     Lending Pad Status Ids
     ************************
      Lead = 1,
      Processing = 33,
      PreQualify = 2,
      Registered = 32,
      PreApproval = 3,
      ApplicationTaken = 4,
      BrokerInitialSubmission = 30,
      Prospect = 27,
      InitialSubmission = 22,
      Approved = 24,
      Suspended = 25,
      PreDeny = 28,
      BrokerConditionSubmission = 31,
      ConditionSubmission = 23,
      ClearToClose = 26,
      Denied = 18,
      Withdrawn = 19,
      NotAccepted = 20,
      Incomplete = 21,
      Rescinded = 29,
      Closed = 12,
      Funded = 13,
      PostClosing = 14,
      InShipping = 15,
      Purchased = 17,
      Servicing = 16
    ************************/

    return [
      { name: 'Application Taken', code: LoanStatusId.ApplicationTaken }, // 4
      { name: 'Approved', code: LoanStatusId.Approved }, // 24
      { name: 'Broker Condition Submission', code: LoanStatusId.BrokerConditionSubmission }, // 31
      { name: 'Broker Initial Submission', code: LoanStatusId.BrokerInitialSubmission }, // 30
      //{ name: 'Canceled', code: LoanStatusId.Canceled }, // 34 (Reserved for future use)
      { name: 'Closed', code: LoanStatusId.Closed }, // 12
      { name: 'Clear To Close', code: LoanStatusId.ClearToClose }, // 26
      { name: 'Condition Submission', code: LoanStatusId.ConditionSubmission }, // 23
      { name: 'Denied', code: LoanStatusId.Denied }, // 18
      { name: 'Funded', code: LoanStatusId.Funded }, // 13
      { name: 'In Shipping', code: LoanStatusId.InShipping }, // 15
      { name: 'Initial Submission', code: LoanStatusId.InitialSubmission }, // 22
      { name: 'Incomplete', code: LoanStatusId.Incomplete }, // 21
      { name: 'Lead', code: LoanStatusId.Lead }, // 1
      { name: 'Not Accepted', code: LoanStatusId.NotAccepted }, // 20
      { name: 'Pre-Approved', code: LoanStatusId.PreApproval }, // 3
      { name: 'Pre-Denied', code: LoanStatusId.PreDeny }, // 28
      { name: 'Pre-Qualified', code: LoanStatusId.PreQualify }, // 2
      { name: 'Processing', code: LoanStatusId.Processing }, // 33
      { name: 'Prospect', code: LoanStatusId.Prospect }, // 27
      { name: 'Post Closing', code: LoanStatusId.PostClosing }, // 14
      { name: 'Purchased', code: LoanStatusId.Purchased }, // 17
      { name: 'Rescinded', code: LoanStatusId.Rescinded }, // 29
      { name: 'Registered', code: LoanStatusId.Registered }, // 32
      { name: 'Servicing', code: LoanStatusId.Servicing }, // 16
      { name: 'Suspended', code: LoanStatusId.Suspended }, // 25
      { name: 'Withdrawn', code: LoanStatusId.Withdrawn }, // 19
    ];
  }

  /**
   * Gets the dropdown items for selecting Active Loan statuses.
   */
  getActiveLoanStatusDropdownItems() {
    // initialize Loan 'Status' filter dropdown items
    return [
      { name: 'Lead', code: LoanStatusId.Lead }, // 1
      { name: 'Pre-Qualified', code: LoanStatusId.PreQualify }, // 2
      { name: 'Pre-Approved', code: LoanStatusId.PreApproval }, // 3
      { name: 'Post Closing', code: LoanStatusId.PostClosing }, // 14
      { name: 'In Shipping', code: LoanStatusId.InShipping }, // 15
      { name: 'Servicing', code: LoanStatusId.Servicing }, // 16
      { name: 'Purchased', code: LoanStatusId.Purchased }, // 17
      { name: 'Incomplete', code: LoanStatusId.Incomplete }, // 21
      { name: 'Initial Submission', code: LoanStatusId.InitialSubmission }, // 22
      { name: 'Condition Submission', code: LoanStatusId.ConditionSubmission }, // 23
      { name: 'Approved', code: LoanStatusId.Approved }, // 24
      { name: 'Suspended', code: LoanStatusId.Suspended }, // 25
      { name: 'Clear To Close', code: LoanStatusId.ClearToClose }, // 26
      { name: 'Prospect', code: LoanStatusId.Prospect }, // 27
      { name: 'Pre-Denied', code: LoanStatusId.PreDeny }, // 28
      { name: 'Broker Initial Submission', code: LoanStatusId.BrokerInitialSubmission }, // 30
      { name: 'Broker Condition Submission', code: LoanStatusId.BrokerConditionSubmission }, // 31
      { name: 'Registered', code: LoanStatusId.Registered }, // 32
      { name: 'Processing', code: LoanStatusId.Processing } // 33
    ];
  }

  /**
   * Gets the dropdown items for selecting Closed Loan statuses.
   */
  getClosedLoanStatusDropdownItems() {
    // initialize Loan 'Status' filter dropdown items
    return [
      { name: 'Closed', code: LoanStatusId.Closed }, // 12
      { name: 'Funded', code: LoanStatusId.Funded }, // 13
    ];
  }

  /**
   * Gets the dropdown items for selecting Lead/Prospect Loan statuses.
   */
  getLeadLoanStatusDropdownItems() {
    // initialize Loan 'Status' filter dropdown items
    return [
      { name: 'Lead', code: LoanStatusId.Lead }, // 1
      { name: 'Prospect', code: LoanStatusId.Prospect }, // 27
    ];
  }

  // ********************************************************************************
  //  Custom Dropdown Filter
  // ********************************************************************************
  /**
   * Determines if the custom Dropdown filter is set.
   *  
   * @param filter
   * @param selections 
   * @returns true if the filter is set, false otherwise.
   */
  isDropdownFilterSet(filter: any, selections: any[]) {
    if ((filter != null) &&
      ((selections.length != null) && (selections.length > 0))) {
      return true;
    }
    return false;
  }

  /**
   * Gets the custom Dropdown filter chip tooltip.
   *
   * @param filter
   * @param selections
   * @returns tooltip text.
   */
  getDropdownFilterTooltip(filter: any, selections: any[]) {
    var tooltip: string = '';
    if (this.isDropdownFilterSet(filter, selections)) {
      for (var x = 0; x < selections.length; x++) {
        if (x > 0)
          tooltip += ', ';
        tooltip += selections[x].name;
      }
    }
    return tooltip;
  }

  // ********************************************************************************
  //  Contact Type Filter
  // ********************************************************************************
  /**
   * Determines if the custom ContactType multi-select filter is set.
   *  
   * @param filter
   * @param selections 
   * @returns true if the filter is set, false otherwise.
   */
  isContactTypesFilterSet(filter: any, selections: any[]) {
    if ((filter != null) &&
      ((selections.length != null) && (selections.length > 0))) {
      return true;
    }
    return false;
  }

  /**
   * Gets the custom ContactType multi-select filter chip tooltip.
   *
   * @param filter
   * @param selections
   * @returns tooltip text.
   */
  getContactTypesTooltip(filter: any, selections: any[]) {
    var tooltip: string = '';
    if (this.isContactTypesFilterSet(filter, selections)) {
      for (var x = 0; x < selections.length; x++) {
        if (x > 0)
          tooltip += ', ';
        tooltip += selections[x].name;
      }
    }
    return tooltip;
  }

  // ********************************************************************************
  //  Text Filter
  // ********************************************************************************
  /**
   * Determines if a single specification text filter is set.
   *
   * @param filter
   * @returns true if the filter is set, false otherwise.
   */
  isTextFilterSet(filter: any) {
    var result: boolean = false;
    if (filter != null) {
      result = true;
      for (var x = 0; x < filter.length; x++) {
        if ((filter[x].value == undefined) || (filter[x].value == null) || (filter[x].value.length == 0)) {
          result = false;
          break;
        }
      }
    }
    return result;
  }

  /**
   * Gets the text filter tooltip.
   *
   * @param filter
   * @returns tooltip text.
   */
  getTextFilterTooltip(filter: any) {
    var tooltip: string = "";
    if (this.isTextFilterSet(filter)) {
      var andOr = AndOr.AND;
      // default is equals
      var comparison = "";

      for (var x = 0; x < filter.length; x++) {
        if (filter[x].matchMode == FilterMatchMode.EQUALS) {
          comparison = "Equals";
        }
        else if (filter[x].matchMode == FilterMatchMode.NOT_EQUALS) {
          comparison = "Not equal to";
        }
        else if (filter[x].matchMode == FilterMatchMode.CONTAINS) {
          comparison = "Contains";
        }
        else if (filter[x].matchMode == FilterMatchMode.NOT_CONTAINS) {
          comparison = "Does not contain";
        }
        else if (filter[x].matchMode == FilterMatchMode.STARTS_WITH) {
          comparison = "Starts with";
        }
        else if (filter[x].matchMode == FilterMatchMode.ENDS_WITH) {
          comparison = "Ends with";
        }

        if (x > 0) {
          if (filter[x].operator == 'and')
            tooltip += " and " + comparison + " '" + filter[x].value.toString() + "'"; // Match All
          else
            tooltip += " or " + comparison + " '" + filter[x].value.toString() + "'"; // Match Any
        }
        else
          tooltip += comparison + " '" + filter[x].value.toString() + "'";
      }
    }
    return tooltip;
  }

  addTextFilter(criteriaCollection: WhereClauseCriteriaCollection, fieldName: string, filter: any[]) {
    var group = Group.NONE;
    var andOr = AndOr.AND;
    // default is equals
    var comparison = SqlComparison.EQ;

    if (filter.length > 1)
      group = Group.BEGIN;

    for (var x = 0; x < filter.length; x++) {
      let value: string = filter[x].value;
      if (value == null)
        continue;

      if (filter[x].matchMode == FilterMatchMode.EQUALS) {
        comparison = SqlComparison.EQ;
      }
      else if (filter[x].matchMode == FilterMatchMode.NOT_EQUALS) {
        comparison = SqlComparison.NOTEQ;
      }
      else if (filter[x].matchMode == FilterMatchMode.CONTAINS) {
        comparison = SqlComparison.LIKE;
        value = '%' + value + '%';
      }
      else if (filter[x].matchMode == FilterMatchMode.NOT_CONTAINS) {
        comparison = SqlComparison.NOTLIKE;
        value = '%' + value + '%';
      }
      else if (filter[x].matchMode == FilterMatchMode.STARTS_WITH) {
        comparison = SqlComparison.LIKE;
        value = value + '%';
      }
      else if (filter[x].matchMode == FilterMatchMode.ENDS_WITH) {
        comparison = SqlComparison.LIKE;
        value = '%' + value;
      }

      if (x > 0) {
        group = Group.NONE;
        if (filter[x].operator == 'and')
          andOr = AndOr.AND; // Match All
        else
          andOr = AndOr.OR; // Match Any
        if (x == (filter.length -1))
          group = Group.END;
      }

      // create and return where clause criteria
      var criteria = new WhereClauseCriteria(fieldName, value, DbType.NVarChar, comparison, andOr, group);
      // add where clause criteria
      criteriaCollection.Items.push(criteria);
    }
  }

  // ********************************************************************************
  //  Numeric Filter
  // ********************************************************************************
  /**
   * Gets the numeric filter tooltip.
   *
   * @param filter
   * @param prefix The text to prepend to the criteria value.
   * @param suffix The text to append to the criteria value.
   * 
   * @returns tooltip text.
   */
  getNumericFilterTooltip(filter: any, prefix: string = '', suffix: string = '') {
    var tooltip: string = '';
    if ((filter != null) &&
        (filter[0] != null) &&
        (filter[0].value != null) &&
        ((filter[0].value.code != null) && (filter[0].value.code != null)) &&
        ((filter[0].value.value1 != undefined) && (filter[0].value.value1 != null))) {
      tooltip += filter[0].value.name;
      tooltip += ' ';
      if (filter[0].value.name == 'Between') {
        tooltip += (prefix + this.formatNumber(filter[0].value.value1) + suffix);
        tooltip += ' and ';
        tooltip += (prefix + this.formatNumber(filter[0].value.value2) + suffix);
      }
      else {
        tooltip += (prefix + this.formatNumber(filter[0].value.value1) + suffix);
      }
    }
    return tooltip;
  }

  /**
   * Formats a number by inserting commas.
   *
   * @param value
   */
  formatNumber(value: number) {
    if ((value == undefined) || (value == null))
      return '';
    var result = '';
    var temp = value.toString();
    // if there are decimal places, we only want 2
    if (temp.indexOf('.') >= 0) {
      result = temp.substring(temp.indexOf('.'));
      if (result.length > 3) {
        result = result.substring(0, 3);
      }
      temp = temp.substring(0, temp.indexOf('.'));
    }
    var c = 1; // current character index
    for (var x = temp.length - 1; x >= 0; x--) {
      result = temp[x] + result;
      // insert a comma every third digit
      if ((x > 0) && ((c % 3) == 0))
        result = ',' + result;
      c++;
    }
    return result;
  }


  /**
   * Determines if a numeric filter is set.
   *
   * @param filter
   * @returns true if the filter is set, false otherwise.
   */
  isNumericFilterSet(filter: any) {
    if ((filter != null) &&
        ((filter.code != null) && (filter.code != 0)) &&
        ((filter.value1 != undefined) && (filter.value1 != null))) {
      if (filter.name == 'Between') {
        if (((filter.value2 != undefined) && (filter.value2 != null)) &&
            (filter.value2 >= filter.value1))
          return true;
      }
      else
        return true;
    }
    return false;
  }

  /**
   * Adds the where clause condition for a 'text' filter.
   * (default database column data type is decimal)
   * 
   * @param criteriaCollection
   * @param fieldName
   * @param value
   * @param code
   * @param dbType SQL Server data type (default = decimal)
   */
  addNumericFilter(criteriaCollection: WhereClauseCriteriaCollection, fieldName: string, value1: any, value2: any, code: NumericFilters, dbType: DbType = DbType.Decimal) {
    // default is equals
    var comparison = SqlComparison.EQ;
    var group = Group.NONE;
    if ((value1 == undefined) || (value1 == null)) {
      return;
    }
    else {
      // Process chosen comparison (excludes default of equals)
      if (code != NumericFilters.Equals) {
        if (code == NumericFilters.NotEqual) {
          comparison = SqlComparison.NOTEQ;
        }
        else if (code == NumericFilters.GreaterThan) {
          comparison = SqlComparison.GT;
        }
        else if (code == NumericFilters.GreaterThanOrEqual) {
          comparison = SqlComparison.GTEQ;
        }
        else if (code == NumericFilters.LessThan) {
          comparison = SqlComparison.LT;
        }
        else if (code == NumericFilters.LessThanOrEqual) {
          comparison = SqlComparison.LTEQ;
        }
        else if (code == NumericFilters.Between) {
          // must be specified and >= value1
          if ((value2 == undefined) || (value2 == null) || (value2 < value1)) {
            return;
          }
          comparison = SqlComparison.GTEQ;
          group = Group.BEGIN;
        }
      }

      // create and add where clause criteria
      var criteria = new WhereClauseCriteria(fieldName, value1, dbType, comparison, AndOr.AND, group);
      // add where clause criteria
      criteriaCollection.Items.push(criteria);

      // create and add second part of 'Between' where clause criteria
      if (code == NumericFilters.Between) {
        comparison = SqlComparison.LTEQ;
        group = Group.END;
        var criteria2 = new WhereClauseCriteria(fieldName, value2, dbType, comparison, AndOr.AND, group);
        // add where clause criteria
        criteriaCollection.Items.push(criteria2);
      }
    }
  }


  // ********************************************************************************
  //  Yes/No Filter
  // ********************************************************************************
  /**
   * Determines if the custom Yes/No filter is set.
   * 
   * @param filter
   * @returns true if the filter is set, false otherwise.
   */
  isYesNoFilterSet(filter: any) {
    if (filter != null) {
      if (filter.value != null) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the custom Yes/No filter chip tooltip.
   *
   * @param filter
   * @returns tooltip text.
   */
  getYesNoTooltip(filter: any) {
    var tooltip: string = '';
    if (this.isYesNoFilterSet(filter)) {
      if (filter.value == 1)
        tooltip = 'Yes';
      else
        tooltip = 'No';
    }
    return tooltip;
  }

  // ********************************************************************************
  //  Message Template Type Filter
  // ********************************************************************************
  /**
   * Determines if the custom Message Template Type filter is set.
   * 
   * @param filter
   * @returns true if the filter is set, false otherwise.
   */
  isMessageTemplateTypeFilterSet(filter: any) {
    if (filter != null) {
      if (filter[0].value != null) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the custom Message Template Type filter chip tooltip.
   *
   * @param filter
   * @returns tooltip text.
   */
  getMessageTemplateTypeTooltip(filter: any) {
    var tooltip: string = '';
    if (this.isMessageTemplateTypeFilterSet(filter)) {
      tooltip = filter[0].value.name;
    }
    return tooltip;
  }

  // ********************************************************************************
  //  Message Template Category Filter
  // ********************************************************************************
  /**
   * Determines if the custom Message Template Category filter is set.
   * 
   * @param filter
   * @returns true if the filter is set, false otherwise.
   */
  isMessageTemplateCategoryFilterSet(filter: any) {
    if (filter != null) {
      if (filter[0].value != null) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the custom Message Template Category filter chip tooltip.
   *
   * @param filter
   * @returns tooltip text.
   */
  getMessageTemplateCategoryTooltip(filter: any) {
    var tooltip: string = '';
    if (this.isMessageTemplateCategoryFilterSet(filter)) {
      tooltip = filter[0].value.name;
    }
    return tooltip;
  }

  /**
   * Gets the custom multi select Message Template Category filter chip tooltip.
   *
   * @param filter
   * @param selections
   * @returns tooltip text.
   */
  getMessageTemplateCategoriesTooltip(filter: any, selections: any[]) {
    var tooltip: string = '';
    if (this.isMessageTemplateCategoryFilterSet(filter)) {
      for (var x = 0; x < selections.length; x++) {
        if (x > 0)
          tooltip += ', ';
        tooltip += selections[x].name;
      }
    }
    return tooltip;
  }


  // ********************************************************************************
  //  Months and Day Filter
  // ********************************************************************************
  /**
   * Adds the where clause condition for a months and day filter.
   * A minimum of one month must be specified.
   *
   * @param criteriaCollection
   * @param months
   * @param day
   */
  addMonthsAndDayFilter(criteriaCollection: WhereClauseCriteriaCollection, fieldName: string, months: any[], day: number) {
    // filter selections (may be empty, if user manually unchecked all items and then clicked 'Apply').
    if (months.length > 0) {
      var group: Group = Group.NONE;
      // if month and day are specified group the two where clause conditions
      if ((day > 0) && (day < 32)) {
        group = Group.BEGIN;
      }

      this.addMonthsFilter(criteriaCollection, fieldName, months, group);

      if ((day > 0) && (day < 32)) {
        this.addDayFilter(criteriaCollection, fieldName, day, Group.END);
      }
    }
  }

  /**
   * Adds the where clause condition for a months filter.
   * A minimum of one month must be specified.
   *
   * @param criteriaCollection
   * @param months
   * @param day
   * @param group
   */
  addMonthsFilter(criteriaCollection: WhereClauseCriteriaCollection, fieldName: string, months: any[], group: Group = Group.NONE) {
    // filter selections (may be empty, if user manually unchecked all items and then clicked 'Apply').
    if (months.length > 0) {

      let value: any[] = [];
      for (var x = 0; x < months.length; x++) {
        value.push(months[x].code);
      }

      // create where clause criteria
      var criteria = new WhereClauseCriteria("MONTH(" + fieldName + ")", value, DbType.Int, SqlComparison.IN, AndOr.AND, group);
      // add where clause criteria
      criteriaCollection.Items.push(criteria);
    }
  }

  /**
   * Adds the where clause condition for a day filter.
   * The day must be between 1 and 31 inclusive.
   *
   * @param criteriaCollection
   * @param months
   * @param day
   * @param group
   */
  addDayFilter(criteriaCollection: WhereClauseCriteriaCollection, fieldName: string, day: number, group: Group = Group.NONE) {
    if ((day > 0) && (day < 32)) {
      // create where clause criteria
      var criteria = new WhereClauseCriteria("DAY(" + fieldName + ")", day, DbType.Int, SqlComparison.EQ, AndOr.AND, group);
      // add where clause criteria
      criteriaCollection.Items.push(criteria);
    }
  }

  /**
   * Determines if the custom Months and Day filter is set.
   *
   * @param filter
   * @param selections
   * @returns true if the filter is set, false otherwise.
   */
  isMonthsAndDayFilterSet(filter: any, selections: any[]) {
    if ((filter != null) &&
      ((selections != null) && (selections.length > 0))) {
      return true;
    }
    return false;
  }

  /**
   * Gets the the custom Months and Day filter chip tooltip.
   *
   * @param filter
   * @param selections
   * @returns tooltip text.
   */
  getMonthsAndDayTooltip(filter: any, selections: any[], selectedDay: any) {
    var tooltip: string = '';
    if ((filter != undefined) && (filter != null) &&
      (selections != undefined) && (selections != null) &&
      (selectedDay != undefined) && (selectedDay != null) &&
      (selections.length > 0) &&
      (selectedDay != null)) {

      if (selectedDay.code == 0)
        tooltip += 'All Days in ';
      else {
        tooltip += 'The ';

        var suffix = '';
        suffix = this.formatDay(selectedDay.code) + ' of ';
        tooltip += suffix;
      }

      for (var x = 0; x < selections.length; x++) {
        if (x > 0)
          tooltip += ', ';
        tooltip += selections[x].name;
      }
    }
    return tooltip;
  }

  /**
   * Formats a Date (mm/dd/yyyy)
   * 
   * @param date
   */
  formatDate(date: any) {
    if ((date == undefined) || (date == null))
      return '';

    var temp: any = new Date(date);
    if ((temp == undefined) || (temp == null))
      return '';

    return (temp.getMonth() + 1) + '/' + temp.getDate() + '/' + temp.getFullYear();
  }

  /**
   * Formats the month.
   * 0 = January, 11 = December
   * @param code
   */
  formatMonth(code: number) {
    switch (code) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
    }
    return '';
  }

  /**
   * Formats the day of the month.
   * 
   * @param code
   */
  formatDay(code: number) {
    let result = code.toString();
    var suffix = '';
    switch (code) {
      case 1:
      case 21:
      case 31:
        suffix = 'st'
        break;
      case 2:
      case 22:
        suffix = 'nd'
        break;
      case 3:
      case 23:
        suffix = 'rd'
        break;
      default:
        suffix = 'th'
        break;
    }
    return (result + suffix);
  }

  // ********************************************************************************
  //  Advanced Dual Calendar Date Filter
  // ********************************************************************************
  /**
   * Determines if a Advanced Dual Calendar Date Filter is set.
   * 
   * @param state
   */
  isAdvancedDateFilterSet(filter: any, comparison: any, secondaryComparison: any) {
    let code = -1;
    if (!filter)
      return false;

    if (comparison != null)
      code = comparison.code;
    if (code < 0) {
      return false;
    }

    if (code == 99)
      return this.isDateFilterSet(filter, secondaryComparison);

    return true;
  }

  /**
   * Adds Advanced Dual Calendar Date Filter criteria to the specified WhereClauseCriteriaCollection object.
   *
   * @param whereClauseCriteriaCollection
   * @param column
   * @param state
   */
  addAdvancedDateFilter(whereClauseCriteriaCollection: WhereClauseCriteriaCollection, column: string, state: AdvancedDateCriteriaState) {
    let code = -1;
    if (state.advancedComparison != null)
      code = state.advancedComparison.code;
    if (code < 0) {
      return;
    }

    let today = new Date();
    //// test date for year spanning
    //today = new Date(2019,11,27); // December 27th, 2019
    let logActualDates = false;

    let criteria1: WhereClauseCriteria = null;
    let criteria2: WhereClauseCriteria = null;

    switch (code) {
      case 0: // Today
        criteria1 = new WhereClauseCriteria(column, FilterHelperService.SQL_TODAY, DbType.DateTime, SqlComparison.EQ, AndOr.AND, Group.NONE);

        if (logActualDates) {
          console.log(this.formatDate(today));
        }
        break;
      case 1: // This Week
        criteria1 = new WhereClauseCriteria(column, FilterHelperService.SQL_START_THIS_WEEK, DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
        criteria2 = new WhereClauseCriteria(column, FilterHelperService.SQL_START_THIS_MONTH, DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

        if (logActualDates) {
          let date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
          let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));
          console.log(this.formatDate(date1));
          console.log(this.formatDate(date2));
        }
        break;
      case 2: // This Month
        {
          criteria1 = new WhereClauseCriteria(column, FilterHelperService.SQL_START_THIS_MONTH, DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, FilterHelperService.SQL_END_THIS_MONTH, DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

          if (logActualDates) {
            let date1 = new Date(today.getFullYear(), today.getMonth(), 1);
            let date2 = new Date(today.getFullYear(), (today.getMonth() + 1), -1);
            console.log(this.formatDate(date1));
            console.log(this.formatDate(date2));
          }
        }
        break;
      case 3: // Next Week
        {
          criteria1 = new WhereClauseCriteria(column, FilterHelperService.SQL_START_NEXT_WEEK, DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, FilterHelperService.SQL_END_NEXT_WEEK, DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

          if (logActualDates) {
            let date1 = new Date(today.getFullYear(), today.getMonth(), (today.getDate() - today.getDay()) + 7);
            let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + ((6 - today.getDay()) + 7));
            console.log(this.formatDate(date1));
            console.log(this.formatDate(date2));
          }
        }
        break;
      case 4: // Next Month
        {
          criteria1 = new WhereClauseCriteria(column, FilterHelperService.SQL_START_NEXT_MONTH, DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, FilterHelperService.SQL_END_NEXT_MONTH, DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

          if (logActualDates) {
            let date1 = new Date(today.getFullYear(), today.getMonth() + 1, 1);
            let date2 = new Date(today.getFullYear(), (today.getMonth() + 2), -1);
            console.log(this.formatDate(date1));
            console.log(this.formatDate(date2));
          }
        }
        break;
      case 5: // Next # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = state.advancedDays;

          criteria1 = new WhereClauseCriteria(column, FilterHelperService.SQL_TODAY, DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.getDeltaDaysCriteria(temp), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

          if (logActualDates) {
            let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp);
            console.log(this.formatDate(today));
            console.log(this.formatDate(date2));
          }
        }
        break;
      case 6: // Previous # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = state.advancedDays;

          criteria1 = new WhereClauseCriteria(column, this.getDeltaDaysCriteria(temp), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, FilterHelperService.SQL_TODAY, DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

          if (logActualDates) {
            let date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp);
            console.log(this.formatDate(date1));
            console.log(this.formatDate(today));
          }
        }
        break;
      case 7: // Within # of Days
        {
          let temp = 0;
          let temp2 = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN)) {
            temp = state.advancedDays;
            temp2 = (state.advancedDays * -1);
          }

          criteria1 = new WhereClauseCriteria(column, this.getDeltaDaysCriteria(temp2), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.getDeltaDaysCriteria(temp), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);

          if (logActualDates) {
            let date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp2);
            let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp);
            console.log(this.formatDate(date1));
            console.log(this.formatDate(date2));
          }
        }
        break;
      case 99: // Standard Date Filtering
        //this.addDateFilter(whereClauseCriteriaCollection, column, state.comparison, state.day1, state.day2);
        this.addDateFilter(whereClauseCriteriaCollection, column, state);
        break;
    }

    if (criteria1 != null) {
      whereClauseCriteriaCollection.Items.push(criteria1);
      if (logActualDates) {
        console.log(JSON.stringify(criteria1));
      }
    }
    if (criteria2 != null) {
      whereClauseCriteriaCollection.Items.push(criteria2);
      if (logActualDates) {
        console.log(JSON.stringify(criteria2));
      }
    }
  }

  /**
   * Gets the where clause (Value) for a day difference from today.
   * 
   * @param days The number of days (delta) from today.
   */
  getDeltaDaysCriteria(days: number) {
    return `DATEFROMPARTS(DATEPART(year, DATEADD(day, ${days}, GETDATE())), ` +
      `DATEPART(month, DATEADD(day, ${days}, GETDATE())), ` +
      `DATEPART(day, DATEADD(day, ${days}, GETDATE())))`;
  }

  /**
   * Gets the Advanced Dual Calendar Date Filter Tooltip text.
   * 
   * @param state
   */
  //getAdvancedDateCriteriaTooltip(filter: any, comparison: any, secondaryComparison: any, days: number, day1: Date, day2: Date) {
  getAdvancedDateCriteriaTooltip(filter: any, state: AdvancedDateCriteriaState) {
    let tooltip = '';

    if (!this.isDateFilterSet(filter, state.advancedComparison)) {
      return tooltip;
    }

    switch (state.advancedComparison.code) {
      case 0: // Today
      case 1: // This Week
      case 2: // This Month
      case 3: // Next Week
      case 4: // Next Month
        tooltip = state.advancedComparison.name;
        break;
      case 5: // Next # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = state.advancedDays;

          tooltip = 'Within the next ' + temp.toString() + ' days';
        }
        break;
      case 6: // Previous # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = state.advancedDays;

          tooltip = 'Within the previous ' + temp.toString() + ' days';
        }
        break;
      case 7: // Within # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = state.advancedDays;

          tooltip = 'Within plus or minus ' + temp.toString() + ' days';
          //tooltip = 'Within ' + temp.toString() + ' days';
        }
        break;
      case 99: // Standard Date Filtering
        tooltip = this.getDateCriteriaTooltip(filter, state);
        break;
    }

    //console.log(tooltip);
    return tooltip;
  }

  // ********************************************************************************
  //  Birthday Filter
  // ********************************************************************************
  /**
   * Determines if a Birthday Filter is set.
   * 
   * @param state
   */
  isBirthdayFilterSet(filter: any, state: BirthdayCriteriaState) {
    let code = -1;
    if (!filter)
      return false;

    if (!state)
      return false;

    if (state.advancedComparison != null)
      code = state.advancedComparison.code;
    if (code < 0) {
      return false;
    }

    if (code == 99) {
      if (state.selectedMonths.length > 0) {
        return true;
      }
      return false;
    }

    return true;
  }

  /**
   * Determines if a Birthday Filter is set.
   * 
   * @param state
   */
  getBirthdayTooltip(filter: any, state: BirthdayCriteriaState) {
    if (!this.isBirthdayFilterSet(filter, state))
      return '';

    let code = -1;
    if (state.advancedComparison != null)
      code = state.advancedComparison.code;
    if (code < 0) {
      return '';
    }

    let tooltip = '';
    if (code == 99) {
      return this.getMonthsAndDayTooltip(filter, state.selectedMonths, state.selectedDay);
    }
    else {
      switch (code) {
        case 0: // Today
        case 1: // This Week
        case 2: // This Month
        case 3: // Next Week
        case 4: // Next Month
          tooltip = state.advancedComparison.name;
          break;
        case 5: // Next # of Days
          {
            let temp = 0;
            if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
              temp = state.advancedDays;

            tooltip = 'Within the next ' + temp.toString() + ' days';
          }
          break;
        case 6: // Previous # of Days
          {
            let temp = 0;
            if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
              temp = state.advancedDays;

            tooltip = 'Within the previous ' + temp.toString() + ' days';
          }
          break;
        case 7: // Within # of Days
          {
            let temp = 0;
            if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
              temp = state.advancedDays;

            tooltip = 'Within plus or minus ' + temp.toString() + ' days';
            //tooltip = 'Within ' + temp.toString() + ' days';
          }
          break;
      }
    }

    //console.log(tooltip);
    return tooltip;
  }

  /**
   * Adds Birthday Filter criteria to the specified WhereClauseCriteriaCollection object.
   *
   * @param whereClauseCriteriaCollection
   * @param column
   * @param state
   */
  addBirthdayFilter(whereClauseCriteriaCollection: WhereClauseCriteriaCollection, column: string, state: BirthdayCriteriaState) {

    let code = -1;
    if (state.advancedComparison != null)
      code = state.advancedComparison.code;
    if (code < 0) {
      return;
    }

    if (code == 99) {
      this.addMonthsAndDayFilter(whereClauseCriteriaCollection, column, state.selectedMonths, state.selectedDay.code);
      return;
    }

    let today = new Date();
    //// test date for year spanning
    //today = new Date(2019,11,27); // December 27th, 2019
    let logActualDates = false;

    let criteria1: WhereClauseCriteria = null;
    let criteria2: WhereClauseCriteria = null;

    switch (code) {
      case 0: // Today
        criteria1 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", FilterHelperService.SQL_ANYYEAR_TODAY, DbType.Int, SqlComparison.EQ, AndOr.AND, Group.NONE);

        if (logActualDates) {
          console.log(this.dayOfYear(today));
        }
        break;
      case 1: // This Week
        let date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
        let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - today.getDay()));

        let andOr = AndOr.AND;
        if (this.dayOfYear(date1) > this.dayOfYear(date2)) {
          andOr = AndOr.OR;
        }

        criteria1 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", FilterHelperService.SQL_ANYYEAR_START_THIS_WEEK, DbType.Int, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
        criteria2 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", FilterHelperService.SQL_ANYYEAR_END_THIS_WEEK, DbType.Int, SqlComparison.LTEQ, andOr, Group.END);

        if (logActualDates) {
          console.log(this.dayOfYear(date1));
          console.log(this.dayOfYear(date2));
        }
        break;
      case 2: // This Month
        {
          criteria1 = new WhereClauseCriteria("MONTH(" + column + ")", FilterHelperService.SQL_ANYYEAR_THIS_MONTH, DbType.Int, SqlComparison.GTEQ, AndOr.AND, Group.NONE);

          if (logActualDates) {
            console.log(today.getMonth() + 1);
          }
        }
        break;
      case 3: // Next Week
        {
          let date1 = new Date(today.getFullYear(), today.getMonth(), (today.getDate() - today.getDay()) + 7);
          let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + ((6 - today.getDay()) + 7));

          let andOr = AndOr.AND;
          if (this.dayOfYear(date1) > this.dayOfYear(date2)) {
            andOr = AndOr.OR;
          }

          criteria1 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", FilterHelperService.SQL_ANYYEAR_START_NEXT_WEEK, DbType.Int, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", FilterHelperService.SQL_ANYYEAR_END_NEXT_WEEK, DbType.Int, SqlComparison.LTEQ, andOr, Group.END);

          if (logActualDates) {
            console.log(this.dayOfYear(date1));
            console.log(this.dayOfYear(date2));
          }
        }
        break;
      case 4: // Next Month
        {
          let month = today.getMonth() + 2;
          if (month > 12)
            month = 1;

          criteria1 = new WhereClauseCriteria("MONTH(" + column + ")", FilterHelperService.SQL_ANYYEAR_NEXT_MONTH, DbType.Int, SqlComparison.EQ, AndOr.AND, Group.NONE);

          if (logActualDates) {
            console.log(month);
          }
        }
        break;
      case 5: // Next # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = state.advancedDays;

          let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp);

          let andOr = AndOr.AND;
          if (this.dayOfYear(today) > this.dayOfYear(date2)) {
            andOr = AndOr.OR;
          }

          criteria1 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", this.dayOfYear(today), DbType.Int, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", this.dayOfYear(date2), DbType.Int, SqlComparison.LTEQ, andOr, Group.END);

          if (logActualDates) {
            console.log(this.dayOfYear(today));
            console.log(this.dayOfYear(date2));
          }
        }
        break;
      case 6: // Previous # of Days
        {
          let temp = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN))
            temp = (state.advancedDays * -1);

          let date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp);

          let andOr = AndOr.AND;
          if (this.dayOfYear(date1) > this.dayOfYear(today)) {
            andOr = AndOr.OR;
          }

          criteria1 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", this.dayOfYear(date1), DbType.Int, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", this.dayOfYear(today), DbType.Int, SqlComparison.LTEQ, andOr, Group.END);

          if (logActualDates) {
            console.log(this.dayOfYear(date1));
            console.log(this.dayOfYear(today));
          }
        }
        break;
      case 7: // Within # of Days
        {
          let temp = 0;
          let temp2 = 0;
          if ((state.advancedDays !== undefined) && (state.advancedDays !== null) && (state.advancedDays !== NaN)) {
            temp = state.advancedDays;
            temp2 = (state.advancedDays * -1);
          }

          let date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp2);
          let date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + temp);

          let andOr = AndOr.AND;
          if (this.dayOfYear(date1) > this.dayOfYear(today)) {
            andOr = AndOr.OR;
          }

          criteria1 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", this.dayOfYear(date1), DbType.Int, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria("DATEPART(dayofYear, " + column + ")", this.dayOfYear(date2), DbType.Int, SqlComparison.LTEQ, andOr, Group.END);

          if (logActualDates) {
            console.log(this.dayOfYear(date1));
            console.log(this.dayOfYear(date2));
          }
        }
        break;
    }

    if (criteria1 != null) {
      whereClauseCriteriaCollection.Items.push(criteria1);
      if (logActualDates) {
        console.log(JSON.stringify(criteria1));
      }
    }
    if (criteria2 != null) {
      whereClauseCriteriaCollection.Items.push(criteria2);
      if (logActualDates) {
        console.log(JSON.stringify(criteria2));
      }
    }
  }

  /**
   * Gets the day of the year for the specified date.
   * 
   * @param date
   */
  dayOfYear(date: Date) {
    if ((date == undefined) || (date == null))
      return 0;
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }

  /**
   * Gets the date for the specified year and day of the year,
   * 
   * @param year
   * @param day
   */
  fromDayOfYear(year: number, day: number) {
    if ((year == undefined) || (year == null))
      return new Date(0, 0, 0); // EPOC (min date)

    if ((day == undefined) || (day == null))
      return new Date(0, 0, 0); // EPOC (min date)

    let result: Date = new Date(year, 0, day);
    return result;
  }

  // ********************************************************************************
  //  Dual Calendar Date Filter
  // ********************************************************************************
  /**
   * Determines if a Dual Calendar Date Filter is set.
   * 
   * @param state
   */
  isDateFilterSet(filter: any, comparison: any) {
    let code = -1;
    if (!filter)
      return false;

    if (comparison != null)
      code = comparison.code;
    if (code < 0) {
      return false;
    }

    return true;
  }

  /**
   * Adds Dual Calendar Date Filter criteria to the specified WhereClauseCriteriaCollection object.
   *
   * @param whereClauseCriteriaCollection
   * @param column
   * @param state
   */
  addDateFilter(whereClauseCriteriaCollection: WhereClauseCriteriaCollection, column: string, state: DateCriteriaState) {
    let code = -1;
    if (state.comparison != null)
      code = state.comparison.code;
    if (code < 0) {
      return false;
    }

    let criteria1: WhereClauseCriteria = null;
    let criteria2: WhereClauseCriteria = null;

    switch (code) {
      case 0: // Selected Date
        criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.EQ, AndOr.AND, Group.NONE);
        break;
      case 1: // After
        criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.GT, AndOr.AND, Group.NONE);
        break;
      case 2: // On or After
        criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.NONE);
        break;
      case 3: // Before
        criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.LT, AndOr.AND, Group.NONE);
        break;
      case 4: // On or Before
        criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.NONE);
        break;
      case 5: // Between
        if (state.day1.valueOf() <= state.day2.valueOf()) {
          criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.formatDate(state.day2), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);
        }
        else {
          criteria1 = new WhereClauseCriteria(column, this.formatDate(state.day2), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.formatDate(state.day1), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);
        }
        break;
      case 6: // Week of
        {
          let date1 = new Date(state.day1.getFullYear(), state.day1.getMonth(), state.day1.getDate() - state.day1.getDay());
          let date2 = new Date(state.day1.getFullYear(), state.day1.getMonth(), state.day1.getDate() + (6 - state.day1.getDay()));

          criteria1 = new WhereClauseCriteria(column, this.formatDate(date1), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.formatDate(date2), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);
        }
        break;
      case 7: // Month of
        {
          let date1 = new Date(state.day1.getFullYear(), state.day1.getMonth(), 1);
          let date2 = new Date(state.day1.getFullYear(), (state.day1.getMonth() + 1), 1);
          criteria1 = new WhereClauseCriteria(column, this.formatDate(date1), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.formatDate(date2), DbType.DateTime, SqlComparison.LT, AndOr.AND, Group.END);
        }
        break;
      case 8: // Year of
        {
          let date1 = new Date(state.day1.getFullYear(), 0, 1);
          let date2 = new Date(state.day1.getFullYear(), 11, 31);

          criteria1 = new WhereClauseCriteria(column, this.formatDate(date1), DbType.DateTime, SqlComparison.GTEQ, AndOr.AND, Group.BEGIN);
          criteria2 = new WhereClauseCriteria(column, this.formatDate(date2), DbType.DateTime, SqlComparison.LTEQ, AndOr.AND, Group.END);
        }
        break;
    }

    if (criteria1 != null) {
      whereClauseCriteriaCollection.Items.push(criteria1);
      //console.log(JSON.stringify(criteria1));
    }
    if (criteria2 != null) {
      whereClauseCriteriaCollection.Items.push(criteria2);
      //console.log(JSON.stringify(criteria2));
    }
  }

  /**
   * Gets the Dual Calendar Date Filter Tooltip text.
   * 
   * @param state
   */
  //getDateCriteriaTooltip(filter: any, comparison: any, day1: Date, day2: Date) {
  getDateCriteriaTooltip(filter: any, state: DateCriteriaState) {
    let tooltip = '';

    if (!this.isDateFilterSet(filter, state.comparison)) {
      return tooltip;
    }

    let code = state.comparison.code;

    switch (code) {
      case 0: // Selected Date
        tooltip = 'On ' + this.formatMonth(state.day1.getMonth()) + ' ' + this.formatDay(state.day1.getDate()) + ', ' + state.day1.getFullYear().toString();
        break;

      case 1: // After
        tooltip = 'After ' + this.formatMonth(state.day1.getMonth()) + ' ' + this.formatDay(state.day1.getDate()) + ', ' + state.day1.getFullYear().toString();
        break;

      case 2: // On or After
        tooltip = 'On or After ' + this.formatMonth(state.day1.getMonth()) + ' ' + this.formatDay(state.day1.getDate()) + ', ' + state.day1.getFullYear().toString();
        break;

      case 3: // Before
        tooltip = 'Before ' + this.formatMonth(state.day1.getMonth()) + ' ' + this.formatDay(state.day1.getDate()) + ', ' + state.day1.getFullYear().toString();
        break;

      case 4: // On or Before
        tooltip = 'On or Before ' + this.formatMonth(state.day1.getMonth()) + ' ' + this.formatDay(state.day1.getDate()) + ', ' + state.day1.getFullYear().toString();
        break;

      case 5: // Between
        {
          let date1 = new Date(state.day1);
          let date2 = new Date(state.day2);
          if (state.day1.valueOf() > state.day2.valueOf()) {
            date1 = new Date(state.day2);
            date2 = new Date(state.day1);
          }

          let oneWeek = new Date((date1.getMonth() + 1) + '/' + (date1.getDate() + 6) + '/' + date1.getFullYear());
          if ((date1.getDay() == 0) &&
              (date2.getDay() == 6) &&
              (oneWeek.valueOf() == date2.valueOf())) {
            tooltip = 'The Week of ';
          }
          else {
            tooltip = 'From ';
          }
          tooltip += this.formatMonth(state.day1.getMonth()) + ' ' + this.formatDay(state.day1.getDate()) + ', ' + state.day1.getFullYear().toString() + ' thru ' +
            this.formatMonth(state.day2.getMonth()) + ' ' + this.formatDay(state.day2.getDate()) + ', ' + state.day2.getFullYear().toString();
        }
        break;

      case 6: // Week of
        {
          let date1 = new Date(state.day1.getFullYear(), state.day1.getMonth(), state.day1.getDate() - state.day1.getDay());
          let date2 = new Date(state.day1.getFullYear(), state.day1.getMonth(), state.day1.getDate() + (6 - state.day1.getDay()));

          tooltip = 'The Week of ' +
            this.formatMonth(date1.getMonth()) + ' ' + this.formatDay(date1.getDate()) + ', ' + date1.getFullYear().toString() + ' thru ' +
            this.formatMonth(date2.getMonth()) + ' ' + this.formatDay(date2.getDate()) + ', ' + date2.getFullYear().toString();
        }
        break;

      case 7: // Month of
        {
          tooltip = 'The Month of ' + this.formatMonth(state.day1.getMonth()) + ' ' + state.day1.getFullYear().toString();
          let date1 = new Date(state.day1.getFullYear(), state.day1.getMonth(), 1);
          let date2 = new Date(state.day1.getFullYear(), (state.day1.getMonth() + 1), 1);
        }
        break;

      case 8: // Year of
        {
          tooltip = 'The Year of ' + state.day1.getFullYear().toString();
          let date1 = new Date(state.day1.getFullYear(), 0, 1);
          let date2 = new Date(state.day1.getFullYear(), 11, 31);
        }
        break;
    }

    //console.log(tooltip);
    return tooltip;
  }


  //// ********************************************************************************
  ////  Loan StatusDate specific Dual Calendar Date Filter
  //// ********************************************************************************
  ///**
  // * Adds Loan StatusDate specific Dual Calendar Date Filter criteria to the specified WhereClauseCriteriaCollection object.
  // *
  // * @param whereClauseCriteriaCollection
  // * @param state
  // */
  //addStatusDateCriteria(whereClauseCriteriaCollection: WhereClauseCriteriaCollection, filter: any, status: any, comparison: any, day1: Date, day2: Date) {
  //  if (!whereClauseCriteriaCollection)
  //    return;

  //  if (!this.isDateFilterSet(filter, comparison)) {
  //    return;
  //  }

  //  let criteria3: WhereClauseCriteria = null;

  //  if ((status != null) && (status.code > 0)) {
  //    criteria3 = new WhereClauseCriteria('LoanStatusId', status.code, DbType.Int, SqlComparison.EQ, AndOr.AND, Group.NONE);
  //  }

  //  this.addDateFilter(whereClauseCriteriaCollection, 'StatusDate', comparison, day1, day2);

  //  if (criteria3 != null) {
  //    whereClauseCriteriaCollection.Items.push(criteria3);
  //    //console.log(JSON.stringify(criteria3));
  //  }
  //}


  // ********************************************************************************
  //  Helpers
  // ********************************************************************************
  /**
   * Removes mask characters from a phone number
   * 
   * @param phone
   */
  unmaskPhone(phone: string) {
    if ((phone == null) || (phone.trim().length == 0))
      return '';
    var temp = phone;
    // while loops are just in case someone masks a masked phone number
    while (temp.indexOf('(') >= 0)
      temp = temp.replace(')', '');
    while (temp.indexOf(')') >= 0)
      temp = temp.replace(')', '');
    while (temp.indexOf(' ') >= 0)
      temp = temp.replace(' ', '');
    while (temp.indexOf('-') >= 0)
      temp = temp.replace('-', '');
    return temp;
  }

  /**
   * Converts the Loan Purpose (description) text filter to one that (may) match a database value.
   * (removes dashes and spaces)
   * 
   * @param displayLoanPurposeDesc
   */
  displayLoanPurposeDescToDatabaseValue(displayLoanPurposeDesc: string) {
    if ((displayLoanPurposeDesc == null) || (displayLoanPurposeDesc.trim().length == 0))
      return '';
    var temp = displayLoanPurposeDesc;
    while (temp.indexOf('-') >= 0)
      temp = temp.replace('-', '');
    while (temp.indexOf(' ') >= 0)
      temp = temp.replace(' ', '');
    return temp;
  }
}

export class YesNoCriteriaState {
  filterHelperService: FilterHelperService = new FilterHelperService();

  options: any[];
  yesNo: any = null;

  constructor() {
    this.options = this.filterHelperService.getYesNoDropdownItems();
    this.yesNo = this.options[0];
  }
}

export class MessageTemplateTypeCriteriaState {
  filterHelperService: FilterHelperService = new FilterHelperService();

  options: any[];
  value: any = null;

  constructor() {
    this.options = this.filterHelperService.getMessageTemplateTypeDropdownItems();
    this.value = this.options[0];
  }
}

export class MessageTemplateCategoryCriteriaState {
  filterHelperService: FilterHelperService = new FilterHelperService();

  options: any[];
  value: any = null;

  constructor() {
    this.options = this.filterHelperService.getMessageTemplateCategoryDropdownItems();
    this.value = this.options[0];
  }
}

/**
 * Dual Calendar Date Filter state object.
 */
export class DateCriteriaState {
  filterHelperService: FilterHelperService = new FilterHelperService();

  comparisons: any[] = [];
  //comparison: any = { name: 'No Filter', code: -1 };
  comparison: any;
  day1: Date = new Date();
  day2: Date = new Date();

  constructor() {
    this.comparisons = this.filterHelperService.getDateDropdownItems();
    this.comparison = this.comparisons[0];
  }
}

/**
 * Advanced Dual Calendar Date Filter state object.
 */
export class AdvancedDateCriteriaState extends DateCriteriaState {
  advancedDateComparisons: any[] = [];

  advancedComparison: any;
  advancedDays: number = null; // Next/Previous number of days

  constructor() {
    super();
    this.advancedDateComparisons = this.filterHelperService.getAdvancedDateDropdownItems();
    this.advancedComparison = this.advancedDateComparisons[0];
  }
}

/**
 * Birthday Filter state object.
 */
export class BirthdayCriteriaState {
  filterHelperService: FilterHelperService = new FilterHelperService();
  advancedDateComparisons: any[] = [];
  months: any[] = [];
  days: any[] = [];

  //advancedComparison: any = { name: 'No Filter', code: -1 }; // comparison selection
  advancedComparison: any;
  advancedDays: number = null; // Next/Previous number of days
  selectedMonths: any[] = [];
  //selectedDay: any = { name: "All Days", code: 0 };
  selectedDay: any;
  daySelectDisabled: boolean = true;

  constructor() {
    this.advancedDateComparisons = this.filterHelperService.getAnyYearDropdownItems();
    this.months = this.filterHelperService.getMonthsDropdownItems()
    this.days = this.filterHelperService.getDaysDropdownItems()

    this.advancedComparison = this.advancedDateComparisons[0];
    this.selectedDay = this.days[0];
  }
}

///**
// * Loan StatusDate specific Dual Calendar Date Filter
// */
//export class StatusDateCriteriaState extends DateCriteriaState {
//  status: any = null;
//}


