<div class="row" [formGroup]="holidayForm">
<div class="p-col-12" style="padding-left: 45px;">
    <div class="row mt-3 mb-2">
      <div class="p-col-6">
        <label>Holiday</label>
      </div>
      <div class="p-col-6"></div>
    </div>
    <div class="row">
      <div class="p-col-6">
        <p-dropdown
          [options]="holidays"
          optionLabel="name"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          resetFilterOnHide="true"
          formControlName="holiday"
          optionValue="holidayEventId"
          appendTo="body"
        >
          <ng-template let-holiday pTemplate="item">
            <div class="holiday-item">
              <div>{{ holiday.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-6"></div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px;">
    <div class="row">
      <div class="p-col-5">
        <label>Time</label>&nbsp;
        <span *ngIf="messageType == 2 && allowTCPAForTextMessages==true" [title]="" class="fa fa-info-circle info-icon info"
              pTooltip="{{timeToolTipe}}" tooltipPosition="top" tooltipZIndex="9999999" style="width:100px;font-weight: 500!important;color:#BCC3CB!important;font-size: 14px;">
       </span>
      </div>

      <div class="p-col-6" style="margin-left:15px;">
        <label>Time Zone</label>
      </div>
    </div>
    <div class="row">
      <div class="p-col-2" style="width: 14% !important;padding-right: 7.5px;">
        <p-dropdown
          [options]="hours"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          placeholder="Hour"
          formControlName="hour"
          optionValue="code"
          appendTo="body"
          (onChange)="OnHourChange($event)"
        >
          <ng-template let-hour pTemplate="item">
            <div class="hour-item">
              <div>{{ hour.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-1 align-self-center justify-self-center p-0" style="width:1%;color: #797979;margin-bottom: 20px;">:</div>
      <div class="p-col-2" style="width: 14% !important;padding-right: 7.5px;padding-left: 7.5px;">
        <p-dropdown
          [options]="minutes"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          placeholder="Minute"
          formControlName="minute"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-minute pTemplate="item">
            <div class="minute-item">
              <div>{{ minute.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-2" style="width: 15% !important;padding-right: 7.5px;padding-left: 7.5px;">
        <p-dropdown
          [options]="meridiems"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          placeholder="Meridiem"
          formControlName="meridiem"
          optionValue="code"
          appendTo="body"
          [disabled]="messageType == 2 && allowTCPAForTextMessages==true"
        >
          <ng-template let-meridiem pTemplate="item">
            <div class="meridiem-item">
              <div>{{ meridiem.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-5" style="padding-right: 7.5px;padding-left: 7.5px;">
        <p-dropdown
          [options]="timeZones"
          optionLabel="displayName"
          [filter]="true"
          filterBy="displayName"
          [showClear]="true"
          resetFilterOnHide="true"
          placeholder="Time Zone"
          formControlName="timeZone"
          optionValue="timezoneId"
          appendTo="body"
        >
          <ng-template let-timeZone pTemplate="item">
            <div class="timeZone-item">
              <div>{{ timeZone.displayName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px;">
    <div class="row">
      <div class="p-col-3">
        <label>Starting Year</label>
      </div>

      <div class="p-col-3">
        <label>Ending</label>
      </div>
      <div class="p-col-6">&nbsp;</div>
    </div>
    <div class="row align-items-center">
      <div class="p-col-3">
        <p-dropdown
          [options]="startYears"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="startYear"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-startYear pTemplate="item">
            <div class="startYear-item">
              <div>{{ startYear.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="p-col-3">
        <p-dropdown
          [options]="endYears"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="yearOption"
          optionValue="code"
          appendTo="body"
          (onChange)="OnOptionChange($event)"
        >
          <ng-template let-yearOption pTemplate="item">
            <div class="yearOption-item">
              <div>{{ yearOption.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-1 align-self-baseline">
        <input
        style="text-align: center;color: #797979;"
          *ngIf="isAfter"
          type="text"
          maxlength="2"
          name="txtOccurrence"
          id="txtOccurrence"
          placeholder=""
          min="1"
          max="2"
          class="form-control"
          formControlName="txtOccurrence"
        />
      </div>
      <div class="p-col-2 fw-normal align-self-start mt-2" style="color: #797979;">
        <label *ngIf="isAfter" for="txtOccurrence" class="col-form-label"
          >Occurrences</label
        >
      </div>
      <div class="p-col-3">
        <span 
          class="text-danger"
          *ngIf="
            holidayForm.get('txtOccurrence').dirty &&
            holidayForm.get('txtOccurrence').errors &&
            holidayForm.get('txtOccurrence').errors.occurrenceRange
          "
          >Please provide number from 1 to 20 only.</span
        >
      </div>
    </div>
  </div>
</div>
