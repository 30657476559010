import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ToastService } from 'src/services/toast.service';
import { RequestResponse } from 'src/models/RequestResponse';
import { PagingData } from 'src/models/PagingData';
import {
  WhereClauseCriteria,
  WhereClauseCriteriaCollection,
} from 'src/models/WhereClauseCriteria';
import { AndOr, DbType, Group, SqlComparison } from 'src/models/enumerations';
import { MessageTemplateEditorState } from 'src/models/state/messageTemplateEditor.state';
import { BodyHistorySmsMessage, MessageTemplate } from 'src/models/MessageTemplate';
import { MergeTagsService } from 'src/services/mergeTags.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';

declare var ExternalMergeTags: any;
import { ExternalMergeTags } from 'src/scripts/mergeTags';
declare var ExternalImageLibrary: any;
import { ExternalImageLibrary } from 'src/scripts/imageLibrary';
import { ImageLibraryService } from 'src/services/imageLibrary.service';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LookupService } from 'src/services/lookup.service';
import { SMSService } from 'src/services/sms.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MessageCampaign } from 'src/models/MessageCampaign';
import { MessagesService } from 'src/services/messages.service';
import { environment } from 'src/environments/environment';
import { NewTextMessageEditorComponent } from './newTextMessageEditor/create-new-text-message-editor.component';
import { TextMessage } from 'src/models/Message';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { Subject } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'textMessageEditor-root',
  templateUrl: './textMessageEditor.component.html',
  styleUrls: ['./textMessageEditor.component.scss'],
  providers: [DynamicDialogRef],
})
export class textMessageEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  // title = 'textMessageEditor';

  // allows [tenantId] = '...' to be added to the selector and passed in
  // @Input() tenantId: string;

  // true delivers the event asynchronously, false delivers synchronously.
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true);
  // @Output() onBackBtnClick: EventEmitter<any> = new EventEmitter<any>(true);

  // property for the control with #dataTableId to be accessed with propertyName dataTable
  // Uncomment the following line if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
  // @ViewChild('dataTableId', { static: false }) dataTable: Table;
  @ViewChild('textMessage', { static: false }) textMessage: HTMLTextAreaElement;
  @ViewChild('smsFormBody', { static: false }) smsFormBody: ElementRef;

  @ViewChild('newTextMessageEditor', { static: false })
  newTextMessageEditorComponent: NewTextMessageEditorComponent;
  selectedNewTextMessageClone: TextMessage = new TextMessage();
  // #region Properties

  // used for showing/hiding spinner, progress bar or skeleton.
  loadingPage: boolean = false;
  public selectedStepIndex: number;
  // Component state
  editorState: MessageTemplateEditorState = new MessageTemplateEditorState();

  // Merge Tags
  mergeTagMenuItems: MenuItem[] = [];
  userToken: UserToken;

  categories = [
    { name: 'Holidays', code: '3FA85F64-5717-4562-B3FC-2C963F66AFA6' },
  ];

  showEmojiBoard = false;
  currentClientID: string;
  // #endregion

  // #region Constructor
  form: any;
  private editSMS: number;
  isLoading = false;
  //dialogService: any;
  messageCategoryList: any;
  isFormSubmitted = false;
  showCancelEdit = false;
  isSMSBtnDisabled: boolean = false;
  editableMessage: string = 'False';
  autoSave: boolean = false;
  isPublishedClicked: boolean = false;
  readonly smsMaxLength: number = 160;
  public smsMsgBody: string[] = [];
  public showSendTestSMSDialog: boolean = false;
  public smsSentSuccess: boolean = false;
  public toNumber = new FormControl('');
  public SMSInProgress: boolean = false;
  public buildYourOwnCampaignID: string = '';
  public buildYourOwnCancelUrl = '';
  public changeCampaignMessageID: string = '';
  public messageName: string = '';
  public IsEdit: number = 0;
  private previousUrl: string;
  private storageHelper: StorageHelper;
  public isUndoRedoClicked: boolean = false;
  public isUndoDisable: boolean = true;
  public isRedoDisable: boolean = true;
  public bodyHistorySmsObject = new BodyHistorySmsMessage();

  public destroyed = new Subject<any>();
  private isEdited: boolean;
  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   */
  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    private mergeTagsService: MergeTagsService,
    private imageLibraryService: ImageLibraryService,
    private messageTemplatesService: MessageTemplatesService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userTokenService: UserTokenService,
    public dialogService: DialogService,
    private lookupService: LookupService,
    private SMSService: SMSService,
    private messagesService: MessagesService
  ) {
    this.selectedStepIndex = 0;
    this.messageName = 'Create New Text Message';
    this.IsEdit = 0;
    if (this.editorState.messageTemplate.title === '') {
      this.editableMessage = 'True';
    }
    this.isEdited = false;
  }

  // #endregion

  // #region Component Initialization

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    this.storageHelper = new StorageHelper();
    let backURL = this.storageHelper.GetDataFromStorage(
      'contentLibrarybackURL'
    ); //fetch
    if (backURL?.value) {
      this.previousUrl = backURL?.value;
      //   //console.log('backURL?.value:' + backURL?.value);
    }

    this.isLoading = true;
    this.buildYourOwnCampaignID = '';
    this.isPublishedClicked = false;
    this.changeCampaignMessageID = '';
    this.currentClientID = environment.clientId;
    this.activatedRoute.queryParams.subscribe((data) => {
      if (data) {
        this.buildYourOwnCampaignID = data?.cmpid ?? '';
        this.changeCampaignMessageID = data?.change ?? '';

        this.buildYourOwnCancelUrl = '';
        if (this.buildYourOwnCampaignID) {
          this.buildYourOwnCancelUrl =
            '/marketing/campaigns/edit/' +
            this.buildYourOwnCampaignID +
            '/default';
        }
      }
    });

    this.userToken = this.userTokenService.getUser();
    // @ts-ignore
    // this.editorState.messageTemplate.body = ['test1', 'test2'];
    this.form = this.fb.group({
      smsBody: this.fb.array([]),
    });

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.editorState = new MessageTemplateEditorState();
      this.editorState.messageTemplate = new MessageTemplate();
      this.form = this.fb.group({
        smsBody: this.fb.array([]),
      });
      this.messageName = 'Create New Text Message';
      this.IsEdit = 0;
      if (this.editorState.messageTemplate.title === '') {
        this.editableMessage = 'True';
      }
      this.setControlStates();
      this.selectedStepIndex = 0;
    });

    // obtain data for the page
    //await Promise.all([
    this.getMergeTagsData();
    this.getLast12AndPopularMergeTagsData();
    this.getImageLibraryThumbnailHtml(); /*])
      .then(val => {
        //debugger;
        setTimeout(() => { this.isLoading = false; }, 1000);
      });*/
    this.isLoading = false;
    this.lookupService.GetCampaignCategories().subscribe(
      (result) => {
        if (result && result.data) {
          this.messageCategoryList = result.data.map((obj) => {
            return {
              item_id: obj.categoryId,
              item_text: obj.categoryName,
            };
          });
        }
      },
      (error) => {
        this.toastService.showError(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  async onSubmitStepFirst() {
    this.selectedStepIndex = 0;
    if (this.editorState.messageTemplate) {
      this.newTextMessageEditorComponent.textMessageFirstFormGroup.patchValue({
        txtMessageName: this.editorState.messageTemplate.title,
        messageCategory: this.editorState.messageTemplate.categoryId,
        txtMessageDescription: this.editorState.messageTemplate.description,
        chkQuickStart: this.editorState.messageTemplate.isQuickStart,
        chkRecommended: this.editorState.messageTemplate.isRecommended,
      });
    }
  }

  async onSubmitStepSecond() {
    if (this.newTextMessageEditorComponent.textMessageFirstFormGroup.valid) {
      this.selectedStepIndex = 1;
    }
  }
  async getImageLibraryThumbnailHtml() {
    //debugger;
    if (!ExternalImageLibrary.thumbnails) {
      let thumbnails = await this.imageLibraryService.getLibraryImages(
        new RequestResponse(
          new PagingData(10000, 1, 10000, 0, 'DateUpdatedUTC DESC', false),
          null,
          null
        ),
        true
      );
      if (thumbnails.status == 200) {
        var thumbnailHtml = thumbnails.data
          .map(
            (x) =>
              '<div class="thumbnail libraryThumbnail"><img class="libraryImage" src="' +
              (x.tinyURL ?? x.imageURL) +
              '"></div>'
          )
          .join('');

        ExternalImageLibrary.thumbnails = thumbnailHtml;
        ExternalImageLibrary.updateThumbnails(thumbnailHtml);
      } else {
        this.toastService.showError(thumbnails.message);
      }
    }
  }
  onTextMessageExit() {
    let validateChanges = false;
    if (
      this.selectedNewTextMessageClone.textMessageName ==
      this.editorState.messageTemplate.title &&
      this.selectedNewTextMessageClone.textMessageDescription ==
      this.editorState.messageTemplate.description &&
      this.selectedNewTextMessageClone.textMessageCategoryId ==
      this.editorState.messageTemplate.categoryId
    ) {
      validateChanges = true;
    }

    if (validateChanges) {
      this.onTextMessageCancelEdit();
    } else {
      this.showCancelEdit = true;
    }
  }
  onCancelNewTextMessageEvent() {
    this.selectedNewTextMessageClone =
      this.newTextMessageEditorComponent.selectedNewTextMessage;
  }
  onTextMessageSaveAndExit() {
    if (this.newTextMessageEditorComponent.textMessageFirstFormGroup.valid) {
      this.onSave();
      this.onTextMessageCancelEdit();
    } else {
      this.showCancelEdit = false;
      this.newTextMessageEditorComponent.onNextClick();
    }
  }
  onTextMessageSaveAndContinue() {
    if (this.newTextMessageEditorComponent.textMessageFirstFormGroup.valid) {
      this.showCancelEdit = false;
      this.selectedStepIndex = 1;
    } else {
      this.showCancelEdit = false;
      this.newTextMessageEditorComponent.onNextClick();
    }
  }
  onTextMessageCancelEdit() {
    let returnUrl = this.messageTemplatesService.returnUrl;
    if (returnUrl) {
      this.messageTemplatesService.resetReturnUrl();
      window.location.href = returnUrl;
    }
    else if (this.previousUrl) {
      this.storageHelper.ClearStorageByKey('contentLibrarybackURL');
      this.router.navigateByUrl(this.previousUrl);
    }
    else if (this.buildYourOwnCancelUrl)
      this.router.navigate([this.buildYourOwnCancelUrl]);
    else this.router.navigate(['/marketing/content-library-filter/All']);
    //else this.router.navigate(['/marketing/template-library']);
  }
  // onTextMessageCancelEdit() {
  //   let returnUrl = this.messageTemplatesService.returnUrl;
  //   if (this.previousUrl) {
  //     this.storageHelper.ClearStorageByKey('contentLibrarybackURL');
  //     this.router.navigateByUrl(this.previousUrl);
  //   } else if (returnUrl) {
  //     this.messageTemplatesService.resetReturnUrl();
  //     window.location.href = returnUrl;
  //   } else if (this.buildYourOwnCancelUrl)
  //     this.router.navigate([this.buildYourOwnCancelUrl]);
  //   else this.router.navigate(['/marketing/content-library-filter/All']);
  //   //else this.router.navigate(['/marketing/template-library']);
  // }
  onSaveNewTextMessageEvent() {
    this.selectedNewTextMessageClone = this.newTextMessageEditorComponent.selectedNewTextMessage;
    this.selectedStepIndex = 1;
    this.messageName = this.selectedNewTextMessageClone.textMessageName;
    this.editorState.messageTemplate.title = this.selectedNewTextMessageClone.textMessageName;
    this.editorState.messageTemplate.description = this.selectedNewTextMessageClone.textMessageDescription;
    this.editorState.messageTemplate.categoryId = this.selectedNewTextMessageClone.textMessageCategoryId;
    this.editorState.messageTemplate.isQuickStart = this.selectedNewTextMessageClone.chkQuickStart;
    this.editorState.messageTemplate.isRecommended = this.selectedNewTextMessageClone.chkRecommended;
  }

  /**
   * Occurs after view initialization
   */
  async ngAfterViewInit(): Promise<void> {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    ///marketing/template-library/sms?cmpid=e0c247d4-00b4-4c8c-87c3-4798dcc86d7a&type=build
    if (
      this.router.url.endsWith('/sms') ||
      this.router.url.startsWith('/marketing/template-library/sms?cmpid=')
    ) {
      setTimeout(() => {
        this.setControlStates();
      }, 10);
    }
  }

  get smsBody() {
    return this.form.controls.smsBody as FormArray;
  }

  addSMS(msg = '', count) {
    Array.from(Array(count).keys()).forEach(() => {
      const sms = this.fb.group({
        body: [msg, Validators.required],
      });
      this.smsBody.push(sms);
    });
  }

  async getMergeTagsData() {
    if (
      ExternalMergeTags.allTags === undefined ||
      ExternalMergeTags.allTags.length === 0
    ) {
      const mergeTagData = await this.mergeTagsService.getMergeTagsData(
        true,
        true,
        true,
        false
      );
      ExternalMergeTags.allTags = mergeTagData.data;
    }
  }

  async getLast12AndPopularMergeTagsData() {
    let popularMergeTagData = (
      await this.mergeTagsService.getPopularMergeTagsData(
        true,
        true,
        true,
        false
      )
    ).data;
    //ExternalMergeTags.popularTags = await this.mergeTagsService.getFinalMergeTagsToDisplay(popularMergeTagData);
    this.mergeTagsService
      .getFinalMergeTagsToDisplay(popularMergeTagData)
      .then((x) => {
        ExternalMergeTags.changePopularTags(x);
      });
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {
    this.IsEdit = 0;
    if (this.editorState.messageTemplate) {
      if (this.editorState.messageTemplate.title != '') {
        this.IsEdit = 1;
      }
      this.newTextMessageEditorComponent.textMessageFirstFormGroup.patchValue({
        txtMessageName: this.editorState.messageTemplate.title,
        messageCategory: this.editorState.messageTemplate.categoryId,
        txtMessageDescription: this.editorState.messageTemplate.description,
        chkQuickStart: this.editorState.messageTemplate.isQuickStart,
        chkRecommended: this.editorState.messageTemplate.isRecommended,
      });
    }
    const temp = this.editorState.messageTemplate.body;
    let tempHistory = '';
    if (this.editorState.messageTemplate.bodyHistory) {
      tempHistory = this.editorState.messageTemplate.bodyHistory;
    }
    // @ts-ignore
    // this.editorState.messageTemplate.body = teArray(temp.substring(2, temp.length - 2));
    if (temp === '') {
      this.addSMS('', 1);
    } else {
      const smsArray = JSON.parse(temp);
      smsArray.forEach((msg: string, index) => {
        if (msg === 'Text STOP to end') {
          return false;
        } else {
          if (msg.endsWith(' Text STOP to end'))
            this.addSMS(msg.substring(0, msg.length - 17), 1);
          else this.addSMS(msg, 1);
          return true;
        }
      });
      if (tempHistory.length > 0) {
        const smsHistoryArray = JSON.parse(tempHistory);
        this.bodyHistorySmsObject = smsHistoryArray;
        //For Undo Button display
        if (this.bodyHistorySmsObject.previous.length > 0) {
          this.isUndoDisable = false;
        } else {
          this.isUndoDisable = true;
        }
        //For Redo Button display
        if (this.bodyHistorySmsObject.next.length > 0) {
          this.isRedoDisable = false;
        } else {
          this.isRedoDisable = true;
        }
      }
      else {
        this.isRedoDisable = true;
        this.isUndoDisable = true;
      }
    }
    this.isLoading = false;
    this.setTestSMSDisable();
  }

  /**
   *
   * @param char
   */
  ins(char: any) {
    this.insertEmoji(char);
  }

  /**
   *
   * @param emoji
   */
  insertEmoji(emoji: any) {
    console.log(this.smsFormBody);
    const smsInput: any = this.smsFormBody.nativeElement.querySelector(
      '.sms-input-' + this.editSMS
    );
    let temp: any = smsInput.value;

    let caretPos = smsInput.selectionStart;
    caretPos = caretPos + emoji.length;
    const prefix = temp.substr(0, smsInput.selectionStart);
    const suffix = temp.substr(smsInput.selectionEnd);
    temp = prefix + emoji + suffix;

    smsInput.focus();

    // if (temp.length > 459) {
    //   return;
    // }

    if (temp.length > this.smsMaxLength) {
      this.toastService.showError(
        'Max character limit has been reached for SMS Body.'
      );
      return;
    }
    smsInput.value = temp;
    this.smsBody.controls[this.editSMS].get('body').setValue(temp);
    // this.editorState.messageTemplate.body = temp;

    smsInput.selectionStart = caretPos;
    smsInput.selectionEnd = caretPos;
    this.setTestSMSDisable();
  }

  async loadTemplate(template: MessageTemplate) {
    if (this.editorState.loadingPage) {
      return;
    }

    let result = false;

    try {
      this.editorState.loadingPage = true;

      // this.editorState.selectedCategory = this.editorState.categories.find(x => x.name === template.categoryDescription);

      this.editorState.messageTemplate = template;

      // Added Pulse specific TenantId and TemplateId arguments.
      // stripo.loadTemplate(
      // stripo.initPlugin,
      // this.editorState.messageTemplate.TemplateHTML,
      // this.editorState.messageTemplate.TemplateCSS,
      // this.editorState.messageTemplate.TenantId,
      // this.editorState.messageTemplate.TemplateId);
      this.editorState.restorationTemplate = JSON.parse(
        JSON.stringify(this.editorState.messageTemplate)
      ); // must be a copy

      if (this.editorState.messageTemplate.title !== '') {
        this.editableMessage = 'False';
      }

      this.setControlStates();

      // Give time for the plugin to render.
      setTimeout(() => {
        this.editorState.loadingPage = false;
      }, 2000);

      result = true;
    } catch (message: any) {
      this.editorState.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }

    return result;
  }

  // #endregion

  // #region Event Handlers

  /**
   * Validates input fields in real-time.
   *
   * @param input the control that triggered the event
   */
  realTimeValidate(input: any) {
    this.editorState.templateDesignFormState.validate(input);
    this.editorState.errors = this.editorState.templateDesignFormState.errors;
    if (this.editorState.errors.length > 0) {
      this.editorState.showErrors = true;
    }
  }

  /**
   * 'Save' button click event handler.
   */
  async onSave() {
    this.isFormSubmitted = true;

    if (this.form.invalid) {
      const f = this.form.controls.smsBody as FormArray;
      for (let i = 0; i < f.controls.length; i++) {
        const g = f.controls[i] as FormGroup;
        g.controls.body.markAsDirty();
      }
    }

    if (
      this.editorState.messageTemplate.title === '' ||
      !this.editorState.messageTemplate.categoryId ||
      this.form.invalid
    ) {
      if (this.isPublishedClicked) {
        this.editorState.messageTemplate.published = false;
        this.isPublishedClicked = false;
      }
      return;
    }
    var ref;
    if (this.autoSave) {
      //Publish Template and Auto Save
      this.autoSave = false;
      ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message:
            'Please confirm you want to activate template ' +
            this.editorState.messageTemplate.title,
          successButtonText: 'Yes, I want to activate',
          cancelButtonText: 'Continue Editing',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          this.doSave();
        } else if (this.currentClientID === 'AduvoAdmin') {
          this.editorState.messageTemplate.published = false;
          this.isPublishedClicked = false;
        }
      });
    } else {
      // Save template
      this.doSave();
    }
  }

  /**
   * Saves the template.
   */
  async doSave() {
    try {
      const webAPI: RequestResponse = new RequestResponse();

      this.isLoading = true;
      this.editorState.haveTemplate = false;
      this.editorState.haveCompiledEmail = false;
      this.editorState.haveEmail = false;
      this.editorState.loadingPage = true;
      this.editorState.saving = true;
      this.editorState.showErrors = false;
      const smsBody: string[] = [];
      this.form.value.smsBody.forEach((sms) => {
        smsBody.push(sms.body);
      });

      if (smsBody[smsBody.length - 1].length > 142)
        smsBody.push('Text STOP to end');
      else
        smsBody[smsBody.length - 1] =
          smsBody[smsBody.length - 1] + ' Text STOP to end';
      if (this.editorState.messageTemplate.templateId != '00000000-0000-0000-0000-000000000000') {
        this.IsEdit = 1;
      }
      // Chek for if no change in body 
      let isChangeInBody = true;
      let newCurrentBody = JSON.stringify(smsBody);
      let oldCurrentBody = this.editorState.messageTemplate.body;
      if (newCurrentBody == oldCurrentBody) {
        isChangeInBody = false;
      }
      if (
        this.IsEdit == 1 &&
        isChangeInBody == true &&
        this.isUndoRedoClicked == false &&
        this.editorState.messageTemplate.body.length > 0
      ) {
        let oldBody = this.editorState.messageTemplate.body;
        let oldSmsArray = JSON.parse(oldBody);
        if (smsBody.length != 0) {
          this.bodyHistorySmsObject.next = [];
          this.isRedoDisable = true;
          if (smsBody.length > 0) {
            this.bodyHistorySmsObject.previous = [];
            oldSmsArray.forEach((msg: string, index) => {
              this.bodyHistorySmsObject.previous.push(msg);
            });
            this.isUndoDisable = false;
          }
        }
      }
      if (this.isUndoRedoClicked == false && isChangeInBody == true) {
        this.editorState.messageTemplate.bodyHistory = JSON.stringify(
          this.bodyHistorySmsObject
        );
        this.editorState.messageTemplate.body = JSON.stringify(smsBody);
      } else {
        this.isUndoRedoClicked = false;
      }
      if (this.editorState.messageTemplate.bodyHistory === null) {
        this.editorState.messageTemplate.bodyHistory = '';
      }
      this.editorState.messageTemplate.tenantId = this.userToken.TenantId;

      // type and category
      this.editorState.messageTemplate.templateTypeId = 2; // this.editorState.selectedTemplateType.code;
      // this.editorState.messageTemplate.CategoryId = this.editorState.selectedCategory.code;
      // this.editorState.messageTemplate.CategoryDescription = this.editorState.selectedCategory.name;

      // Owner set only for initial save - it never should change after that.
      if (
        this.editorState.messageTemplate.templateId == null ||
        this.editorState.messageTemplate.templateId ===
        '00000000-0000-0000-0000-000000000000'
      ) {
        this.editorState.messageTemplate.owner = this.userToken.TenantUserId;
        this.editorState.messageTemplate.ownerFullName =
          this.userToken.getFullName();
        this.editorState.messageTemplate.createdBy =
          this.userToken.TenantUserId;
        this.editorState.messageTemplate.createdOnUTC = new Date();
      }

      this.editorState.messageTemplate.modifiedBy = this.userToken.TenantUserId;

      // Set specific controller request data.
      // webAPI.data = JSON.stringify(this.editorState.messageTemplate);
      if (!this.editorState.messageTemplate.categoryDescription) {
        this.editorState.messageTemplate.categoryDescription = 'Uncategorized';
        this.messageCategoryList.forEach((categories, index) => {
          if (
            this.editorState.messageTemplate.categoryId == categories.item_id
          ) {
            this.editorState.messageTemplate.categoryDescription =
              categories.item_text;
          }
        });
      }

      if (this.currentClientID !== 'AduvoAdmin')
        this.editorState.messageTemplate.published = true;

      // create or save the template
      var response = await this.messageTemplatesService.SaveTemplate(
        this.editorState.messageTemplate
      );
      if (response.status == 200) {
        // convert the 'data' property of the RequestResponse object's 'data' property JSON string value to an object.
        // RequestResponse.dataToObject(response); // Required - do not remove.
        // sync data after created/saved
        this.editorState.messageTemplate = response.data;

        // create template thumbnail in background
        var responseThumbnail =
          await this.messageTemplatesService.CreateTemplateThumbnailURL(
            this.editorState.messageTemplate.templateId,
            this.editorState.messageTemplate.isPublishingDb
          );

        this.editorState.restorationTemplate = JSON.parse(
          JSON.stringify(this.editorState.messageTemplate)
        ); // must be a copy
        this.editorState.refreshRequired = true;
        this.toastService.showSuccess(
          'Template ' +
          this.editorState.messageTemplate.title +
          ' saved successfully.'
        );
        this.IsEdit = 1;
        //----------------if build your own template

        let returnUrl = this.messageTemplatesService.returnUrl;
        if (returnUrl) {
          this.messageTemplatesService.resetReturnUrl();
          window.location.href = returnUrl;
        }

        if (
          this.buildYourOwnCampaignID &&
          this.currentClientID !== 'AduvoAdmin'
        ) {
          this.CreatNewCampaignMesage();
        }

        if (this.currentClientID === 'AduvoAdmin') {
          if (this.isPublishedClicked && this.buildYourOwnCampaignID) {
            this.CreatNewCampaignMesage();
            this.isPublishedClicked = false;
          } else if (this.buildYourOwnCampaignID) {
            this.BuildYourOwnTemplateFlow();
          }
        }
        //--------------
      } else {
        if (this.currentClientID === 'AduvoAdmin' && this.isPublishedClicked) {
          this.editorState.messageTemplate.published =
            !this.editorState.messageTemplate.published;
        }
        this.toastService.showError(response.message);
        this.isPublishedClicked = false;
      }
    } catch (message: any) {
      console.error(message);
      this.toastService.showError(message);
    } finally {
      this.isLoading = false;
      this.editorState.loadingPage = false;
      this.editorState.saving = false;
      this.isPublishedClicked = false;
      this.isEdited = false;
    }
  }

  /**
   * 'Preview' button click event handler.
   */
  onPreviewBtnClick() { }
  onUndoClicked(): void {
    this.isRedoDisable = false;
    this.isUndoRedoClicked = true;
    let oldBodyCurrent = this.editorState.messageTemplate.body;
    let oldBodyHistory = this.editorState.messageTemplate.bodyHistory;
    if (oldBodyCurrent != '') {
      let oldSmsArrayCurrent = JSON.parse(oldBodyCurrent);
      let oldSmsArrayHistory = JSON.parse(oldBodyHistory);
      if (oldSmsArrayHistory.previous.length != 0) {
        oldSmsArrayHistory.next = [];
        oldSmsArrayCurrent.forEach((msg: string, index) => {
          oldSmsArrayHistory.next.push(msg);
        });
        oldSmsArrayCurrent = [];
        oldSmsArrayHistory.previous.forEach((msg: string, index) => {
          oldSmsArrayCurrent.push(msg);
        });
        oldSmsArrayHistory.previous = [];
        if (oldSmsArrayHistory.previous.length == 0) {
          this.isUndoDisable = true;
        }
        this.editorState.messageTemplate.bodyHistory =
          JSON.stringify(oldSmsArrayHistory);
        this.editorState.messageTemplate.body =
          JSON.stringify(oldSmsArrayCurrent);

        const f = this.form.controls.smsBody as FormArray;
        f.clear();
        oldSmsArrayCurrent.forEach((msg: string, index) => {
          if (msg === 'Text STOP to end') {
            return false;
          } else {
            if (msg.endsWith(' Text STOP to end'))
              this.addSMS(msg.substring(0, msg.length - 17), 1);
            else this.addSMS(msg, 1);
            return true;
          }
        });
        //  for (let i = 0; i < this.editorState.messageTemplate.body.length; i++) {
        //    const g = f.controls[i] as FormGroup;
        //    if (oldSmsArrayCurrent[i].endsWith(' Text STOP to end')) {
        //      g.controls.body.patchValue(
        //        oldSmsArrayCurrent[i].substring(
        //          0,
        //          oldSmsArrayCurrent[i].length - 17
        //        )
        //      );
        //    } else {
        //      g.controls.body.patchValue(oldSmsArrayCurrent[i]);
        //    }
        //  }
      }
    }
  }
  onRedoClicked(): void {
    this.isUndoRedoClicked = true;
    let oldBodyCurrent = this.editorState.messageTemplate.body;
    let oldBodyHistory = this.editorState.messageTemplate.bodyHistory;
    if (oldBodyCurrent != '') {
      let oldSmsArrayCurrent = JSON.parse(oldBodyCurrent);
      let oldSmsArrayHistory = JSON.parse(oldBodyHistory);
      if (oldSmsArrayHistory.next.length != 0) {
        oldSmsArrayHistory.previous = [];
        oldSmsArrayCurrent.forEach((msg: string, index) => {
          oldSmsArrayHistory.previous.push(msg);
        });
        oldSmsArrayCurrent = [];
        oldSmsArrayHistory.next.forEach((msg: string, index) => {
          oldSmsArrayCurrent.push(msg);
        });
        oldSmsArrayHistory.next = [];
        if (oldSmsArrayHistory.next.length == 0) {
          this.isRedoDisable = true;
        }
        this.editorState.messageTemplate.bodyHistory =
          JSON.stringify(oldSmsArrayHistory);
        this.editorState.messageTemplate.body =
          JSON.stringify(oldSmsArrayCurrent);
      }
      const f = this.form.controls.smsBody as FormArray;
      f.clear();
      oldSmsArrayCurrent.forEach((msg: string, index) => {
        if (msg === 'Text STOP to end') {
          return false;
        } else {
          if (msg.endsWith(' Text STOP to end'))
            this.addSMS(msg.substring(0, msg.length - 17), 1);
          else this.addSMS(msg, 1);
          return true;
        }
      });
      if (oldSmsArrayHistory.previous.length > 0) {
        this.isUndoDisable = false;
      } else {
        this.isUndoDisable = true;
      }
    }

  }
  async onPublishChange(messageTemplate: MessageTemplate) {
    this.isFormSubmitted = true;

    if (this.form.invalid) {
      const f = this.form.controls.smsBody as FormArray;
      for (let i = 0; i < f.controls.length; i++) {
        const g = f.controls[i] as FormGroup;
        g.controls.body.markAsDirty();
      }
    }

    if (
      this.editorState.messageTemplate.title === '' ||
      !this.editorState.messageTemplate.categoryId ||
      this.form.invalid
    ) {
      if (this.editorState.messageTemplate.published) {
        setTimeout(() => (messageTemplate.published = false), 0);
        //this.cdRef.detectChanges();
        // this.appRef.tick();
        return false;
      }
    }

    this.isPublishedClicked = true;

    if (
      this.editorState.messageTemplate.templateId != null &&
      this.editorState.messageTemplate.templateId !=
      '00000000-0000-0000-0000-000000000000'
    ) {
      // old template and is being published

      if (this.editorState.messageTemplate.published) {
        var ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              'Please confirm you want to activate template ' +
              messageTemplate.title,
            successButtonText: 'Yes, I want to activate',
            cancelButtonText: 'Continue Editing',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.messageTemplatesService
              .setPublished(
                messageTemplate.templateId,
                messageTemplate.published
              )
              .subscribe((response) => {
                if (response.status == 200) {
                  this.toastService.showSuccess(
                    messageTemplate.published
                      ? 'Template Activated'
                      : 'Template Deactivated'
                  );

                  //publish and then save template
                  this.doSave();
                } else {
                  this.toastService.showError(
                    (messageTemplate.published
                      ? 'Error Activating! '
                      : 'Error Deactivating! ') + response.message
                  );
                  messageTemplate.published = !messageTemplate.published;
                  this.isPublishedClicked = false;
                }
              });
          } else {
            messageTemplate.published = false;
            this.isPublishedClicked = false;
          }
        });
      } else {
        this.messageTemplatesService
          .setPublished(messageTemplate.templateId, messageTemplate.published)
          .subscribe((response) => {
            if (response.status == 200) {
              this.toastService.showSuccess(
                messageTemplate.published
                  ? 'Template Activated'
                  : 'Template Deactivated'
              );
            } else {
              this.toastService.showError(
                (messageTemplate.published
                  ? 'Error Activating! '
                  : 'Error Deactivating! ') + response.message
              );
              messageTemplate.published = !messageTemplate.published;
            }
            this.isPublishedClicked = false;
          });
      }
    } else {
      // Auto save and publish template
      this.autoSave = true;
      this.onSave();
    }
  }

  async onRestoreBtnClick() {
    this.editorState.showErrors = false;
    setTimeout(() => {
      this.loadTemplate(this.editorState.restorationTemplate);
    }, 10);
  }

  /**
   * 'Cancel' of 'X' clicked on the preview dialog.
   */
  onClosePreviewDialog() {
    this.editorState.showPreviewDialog = false;
    this.editorState.previewing = false;
  }

  onMergeTagRequested(index?: number) {
    this.editSMS = index;
    ExternalMergeTags.open(this.onMergeTagSelected.bind(this));
  }

  onMergeTagSelected(tagVal) {
    this.insertEmoji(tagVal);
    this.mergeTagsService.updateLastUsedMergeTag(tagVal);
  }

  onEmojiClick($event: any) {
    this.editSMS = $event.index;
    console.log($event);
    this.insertEmoji($event.emoji);
  }

  onImageRequested(index?: number) {
    this.editSMS = index;
    ExternalImageLibrary.openLibrary(
      this.onImageSelectCallback.bind(this),
      function () { },
      this.onUploadImageFileCallback.bind(this),
      false
    );
  }

  onImageSelectCallback(selectedImage) {
    this.insertEmoji(selectedImage.url);
    ExternalImageLibrary.close();
  }

  async onUploadImageFileCallback(selectedFile: any) {
    this.isLoading = true;
    this.editorState.loadingPage = true;
    let fileNamewithoutExt;
    let fileName = selectedFile.name;
    var lastDotPosition = fileName.lastIndexOf('.');

    if (lastDotPosition === -1) fileNamewithoutExt = fileName;
    else fileNamewithoutExt = fileName.substr(0, lastDotPosition);

    let imageModel = {
      fileImage: selectedFile,
      imageId: '00000000-0000-0000-0000-000000000000',
      imageURL: '',
      title: fileNamewithoutExt,
      width: `0`,
      height: `0`,
      avatar: 'false',
      published: 'true',
      enabled: 'true',
      deleted: 'false',
      imageSize: '0',
      imageLength: `${selectedFile.size}`,
    };

    const formData: FormData = new FormData();
    formData.append('fileImage', imageModel.fileImage);
    formData.append('imageId', imageModel.imageId);
    formData.append('imageURL', imageModel.imageURL);
    formData.append('title', imageModel.title);
    formData.append('width', imageModel.width);
    formData.append('height', imageModel.height);
    formData.append('avatar', imageModel.avatar);
    formData.append('published', imageModel.published);
    formData.append('enabled', imageModel.enabled);
    formData.append('deleted', imageModel.deleted);
    formData.append('imageSize', imageModel.imageSize);
    formData.append('imageLength', imageModel.imageLength);

    // create or save the Image
    var response = await this.imageLibraryService.SaveLibraryImage(formData);
    if (response.status != 200) {
      this.toastService.showError(response.message);
    } else {
      ExternalImageLibrary.thumbnails = undefined;
      JSON.parse(
        JSON.stringify(
          this.imageLibraryService.toCollectionInstance(
            response.data,
            this.userToken
          )
        )
      );
      this.toastService.showSuccess(
        "Image '" + fileNamewithoutExt + "' saved successfully."
      );

      //set the file tinyurl
      this.insertEmoji(response.data.tinyURL);
    }
    ExternalImageLibrary.close();
    this.isLoading = false;
    this.editorState.loadingPage = false;
  }

  setTestSMSDisable() {
    this.isSMSBtnDisabled = false;
    this.form.value.smsBody.forEach((sms) => {
      if (sms.body.trim() == '') {
        this.isSMSBtnDisabled = true;
      }
    });
  }

  openSendSMSDialog() {
    this.smsMsgBody = [];
    this.form.value.smsBody.forEach((sms) => {
      this.smsMsgBody.push(sms.body);
    });
    if (this.smsMsgBody[this.smsMsgBody.length - 1].length > 142)
      this.smsMsgBody.push('Text STOP to end');
    else
      this.smsMsgBody[this.smsMsgBody.length - 1] =
        this.smsMsgBody[this.smsMsgBody.length - 1] + ' Text STOP to end';
    this.showSendTestSMSDialog = true;
  }

  onCloseSendTestSMSDialog() {
    this.showSendTestSMSDialog = false;
    this.smsSentSuccess = false;
    this.SMSInProgress = false;
    this.toNumber.setValue('');
  }

  sendTestSMS() {
    if (this.toNumber.value && this.smsMsgBody.length) {
      this.SMSInProgress = true;
      this.SMSService.SendSms(this.smsMsgBody, this.toNumber.value).subscribe(
        (response: RequestResponse) => {
          if (response?.data) {
            this.smsSentSuccess = true;
            this.toastService.showSuccess('Test Sms sent successfully.');
          } else {
            this.smsSentSuccess = false;
            this.toastService.showError(response.message);
            this.toNumber.setValue('');
          }
          this.SMSInProgress = false;
        },
        (err) => {
          this.SMSInProgress = false;
        }
      );
    } else {
      this.smsSentSuccess = false;
      this.toastService.showError('Please enter a correct number');
    }
  }

  sendTestSMSAnother() {
    this.smsSentSuccess = false;
    this.SMSInProgress = false;
    this.toNumber.setValue('');
  }

  onPencilClicked(event: any) {
    this.editableMessage = 'True';
  }

  onMessageTextKeyDown(event: any) {
    if (event.key === 'Enter' || event.key === 'Escape') {
      this.editableMessage = 'False';
    }
  }

  BuildYourOwnTemplateFlow() {
    if (this.buildYourOwnCampaignID) {
      this.dialogService.dialogComponentRefMap.forEach((dialog) => {
        dialog.destroy();
      });
      //show confirm to activate
      var confirmRef = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: `Please confirm you want to activate template ${this.editorState.messageTemplate.title}`,
          successButtonText: 'Yes, I want to activate',
          cancelButtonText: 'Cancel',
        },
      });

      confirmRef.onClose.subscribe(async (result: boolean) => {
        if (result) {
          //if publish yes then publish and crete new CampaignMessage
          this.messageTemplatesService
            .setPublished(this.editorState.messageTemplate.templateId, true)
            .subscribe((response) => {
              if (response.status == 200) {
                this.toastService.showSuccess('Template Activated.');
                this.CreatNewCampaignMesage();
              } else {
                this.toastService.showError(
                  'Error Activating! ' + response.message
                );
              }
            });
        }
      });
    } else {
      this.router.navigate(['/marketing/template-library']);
    }
  }

  private async CreatNewCampaignMesage() {
    if (this.changeCampaignMessageID) {
      let messageModel = {
        campaignId: this.buildYourOwnCampaignID,
        campaignMessageId: this.changeCampaignMessageID,
        templateId: this.editorState.messageTemplate.templateId,
        scheduleJson: '',
        sentJson: '',
        dateStartUtc: null,
        dateEndUtc: null,
        preHeaderText: this.editorState.messageTemplate.preHeaderText,
        isPublishingDb: false,
        subject: this.editorState.messageTemplate.emailSubject + '.',
      };
      var response = await this.messagesService.UpdateCampaignMessage(
        messageModel
      );
      this.RedirectToCampaign();
      // this.router.navigate([
      //   'marketing/campaigns/edit/' + this.buildYourOwnCampaignID + '/default/' + this.editorState.messageTemplate.templateId,
      // ]);
    } else {
      //Add
      let messageModel = new MessageCampaign();
      messageModel.campaignId = this.buildYourOwnCampaignID;
      messageModel.templateId = this.editorState.messageTemplate.templateId;
      messageModel.subject = this.editorState.messageTemplate.emailSubject;
      messageModel.preHeaderText =
        this.editorState.messageTemplate.preHeaderText;
      messageModel.isPublishingDb = false;

      //save tCampaignMessage
      this.messagesService
        .SelectCampaignMessageRxjs(messageModel)
        .subscribe((retResponse) => {
          this.RedirectToCampaign();
          // this.router.navigate([
          //   'marketing/campaigns/edit/' + this.buildYourOwnCampaignID + '/default/' + messageModel.templateId,
          // ]);
        });
    }
  }
  private RedirectToCampaign() {
    this.router.navigate([
      'marketing/campaigns/edit/' +
      this.buildYourOwnCampaignID +
      '/default/' +
      this.changeCampaignMessageID,
    ]);
  }
  onValueChanged() {
    this.isEdited = true;
  }

  onExit() {
    //show dialog only if something has change.
    if (this.isEdited) {
      this.showCancelEdit = true;
    } else {
      //exit
      this.onTextMessageCancelEdit();
    }
  }
}
