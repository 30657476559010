<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:15px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="row p-mt-2 align-items-center" [formGroupName]="i">
      <!-- <div class="col-auto">
        <label style="font-size: 16px; line-height: 16px; color: #2e2e2e;font-weight: 400;">{{ item.get('label').value
          }}</label>
      </div> -->
      <div class="col-12 mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" [style]="{'width':'100%'}" formControlName="operandDropDown"
          filterBy="name" [filter]="true" filterBy="OpDisplay"></p-dropdown>
      </div>
      <div class="col-12 mb-3">
        <!-- <input maxlength="50" class="form-control" pInputText type="text" id="txtRHSValue{{ i }}"
          formControlName="txtRHSValue" *ngIf="item.get('operandDropDown').value?.RhsInputControl1"
          [ngClass]="{ 'is-invalid': item.get('txtRHSValue')?.errors}">
        <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;"
          *ngIf="item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue')?.errors">
          Required.
        </div> -->
        <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1">
          <ng-container *ngIf="isLoading; else userDropDown">
            <i class="pi pi-spin pi-spinner" style="font-size: 25px; margin-top: 10px;"></i>
          </ng-container>
          <ng-template #userDropDown>
            <div class="d-flex">
              <i class="pi pi-reply replytosection col-1 me-2 ms-2"></i>
              <div class="col-11">
                <p-multiSelect #txtRHSValue1  id="txtRHSValue{{ i }}" formControlName="txtRHSValue" placeholder="Select"
                  [showToggleAll]="true" [options]="rhsDropdown" dataKey="code" optionLabel="name" class="col-10"
                  [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue')?.errors}"
                  [style]="{'width':'99%', 'border-radius' : '20px'}" selectedItemsLabel="{0} items selected"
                  (onChange)="onListChange($event, i)" [virtualScroll]="true">
                  <p-footer>
                    <div class="flex justify-content-between py-3 px-4">
                         <p-button label="Done" [style]="{'width':'100%'}" (click)="close();"
                              styleClass="p-button-rounded"></p-button>
                    </div>
               </p-footer></p-multiSelect>
              </div>
            </div>
            <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;"
              *ngIf="item.get('txtRHSValue')?.touched && item.get('txtRHSValue')?.touched && item.get('operandDropDown').value && item.get('txtRHSValue')?.errors">
              Required.
            </div>
          </ng-template>
        </ng-container>
      </div>
      <!-- <div class="col-1">
        <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button mt-2"
          (click)="deleteRow(i)">
          <i class="pi pi-trash"></i>
        </span>
      </div> -->
    </div>
  </div>
  <!-- <div class="row mt-3" style="padding:15px 30px;">
    <div class="col-12 btn-no-radius">
      <p-button (click)="addMore()" icon="pi pi-plus" label=""></p-button>
    </div>
  </div> 
  <hr class="m-0">-->
  <div class="row" style="padding:15px 30px;">
    <div class="col-12 text-right">
      <!-- <a (click)="cancelFilter()" class="link-text"
        style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;font-weight: normal; line-height:34px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a> -->
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" label="Apply"></p-button>
    </div>
  </div>
</div>