import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { ToastService } from '../../../../../src/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from '../../../../../src/services/campaigns.service';
import { DropDownItem, TenantUserItem } from '../../../../../src/models/TenantUser';
import { LookupService } from '../../../../../src/services/lookup.service';
import { UserManagementService } from '../../../../../src/services/userManagement.service';
import { UserTokenService } from '../../../../../src/services/user-token.service';
import { UserToken } from '../../../../../src/models/UserToken';
import { LoansService } from '../../../../services/Loans.service';
import { MessageTemplateDialogModel } from '../../../../models/MessageTemplate';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CampaignAddVM, TemplateHtmlData, UpdateMsgModel } from '../../../../models/Campaign';
import { environment } from '../../../../../src/environments/environment';
import { MessagesService } from 'src/services/messages.service';

@Component({
  selector: 'app-create-campaign-wizard',
  templateUrl: './create-campaign-wizard.component.html',
  styleUrls: ['./create-campaign-wizard.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCampaignWizardComponent implements OnInit, OnDestroy {
  private sub: Array<Subscription>;
  private userToken: UserToken;
  private generatedCampaignId: string;
  private sendedToListId: string = '';
  public steps: MenuItem[];
  public selectedStepIndex: number;
  public skipPageClicked: boolean;
  public loadingPage: boolean;
  public isSkipDisable: boolean;
  public campaignCategoryList: DropDownItem[];
  public userList: TenantUserItem[];
  public selectedUserListLabel: string;
  public toSelectedMasterContactLabel: string;
  public ccSelectedMasterContactLabel: string;
  public bccSelectedMasterContactLabel: string;
  public toSelectedLoanAssignTypeRolesLabel: string;
  public ccSelectedLoanAssignTypeRolesLabel: string;
  public bccSelectedLoanAssignTypeRolesLabel: string;
  public loanStatusTypeMasterList: DropDownItem[];
  public masterContactTypes: DropDownItem[];
  public loanAssignTypeRoles: DropDownItem[];
  public messageTemplateDialogModel: MessageTemplateDialogModel;
  public campaignFormGroup: FormGroup | null;
  public items: FormArray | null;
  public campaignAddVM: CampaignAddVM;
  public selectedCurrentUsers: TenantUserItem[];
  public defaultLabel: string = 'Select';
  public isWhoCanDisabled: boolean;
  public currentClientID: string;
  public isCalledFromWizard: boolean;
  public sortByList: any[];
  public selectItemsForWizard: TemplateHtmlData[]; //used only if called from create wizard
  public isMessageSchedule: boolean = false;
  public ShowMessageScheduleTab: boolean = false;
  public selectedLabel: string;
  public selectedItem: any;
  public validationString: string = '';
  public showValidateCampaignDialog: boolean = false;
  public isFromEmailInValid: boolean = false;
  public isToEmailInValid: boolean = false;
  public isCCEmailInValid: boolean = false;
  public isBCCEmailInValid: boolean = false;
  private selectedMessagesList: any;
  public noMessageFound: boolean = true;
  public crudInProgress: boolean;
  public prevUpdateObject: any;
  public updateMsgModel: UpdateMsgModel;//called from create wizard
  public showTestModeDialog: boolean;
  public userRoleFoundInToRecipientList: boolean;
  public userRoleNameFoundInToRecipientListMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private toastService: ToastService,
    private router: Router,
    private campaignsService: CampaignsService,
    private lookupService: LookupService,
    private userManagementService: UserManagementService,
    private userTokenService: UserTokenService,
    private cdr: ChangeDetectorRef,
    private loanService: LoansService,
    private messagesService: MessagesService
  ) {
    this.selectedStepIndex = 0;
    this.generatedCampaignId = '';
    this.campaignCategoryList = [];
    this.userList = [];
    this.selectedUserListLabel = '{0} items selected';
    this.toSelectedMasterContactLabel = '{0} items selected';
    this.ccSelectedMasterContactLabel = '{0} items selected';
    this.bccSelectedMasterContactLabel = '{0} items selected';

    this.toSelectedLoanAssignTypeRolesLabel = '{0} items selected';
    this.messageTemplateDialogModel = new MessageTemplateDialogModel();
    this.campaignFormGroup = null;
    this.sub = new Array<Subscription>();
    this.campaignAddVM = new CampaignAddVM();
    this.selectedCurrentUsers = [];
    this.isWhoCanDisabled = false;
    this.sortByList = [
      { label: 'Last Updated', value: 'LastUpdated' },
      { label: 'Template / Campaign Name', value: 'Name' },
    ];
    this.selectItemsForWizard = [];
    this.crudInProgress = false;
    this.prevUpdateObject = null;

    this.showTestModeDialog = false;

    this.userRoleFoundInToRecipientList = false;
    this.userRoleNameFoundInToRecipientListMessage = "";
    this.isCalledFromWizard = false;
    this.updateMsgModel = new UpdateMsgModel();
    this.updateMsgModel.isCalledFromWizard = 1;  //called from create wizard 1 / Message Selection 2
  }

  ngOnInit(): void {
    this.currentClientID = environment.clientId;

    if (this.activatedRoute.snapshot.paramMap.get('listId')) {
      this.sendedToListId = this.activatedRoute.snapshot.paramMap.get('listId');
    }

    this.userToken = this.userTokenService.getUser();
    let isCompanyUserWithMarketing = this.userToken.isCompanyUserWithMarketing();

    if (this.userToken.isCompanyAdmin() || this.userToken.isMarketingAdmin()) { //select All
      this.selectedUserListLabel = 'All Users';
    }

    if (environment.clientId === 'AduvoAdmin') {
      this.isWhoCanDisabled = true;
    } else if (isCompanyUserWithMarketing) {
      this.isWhoCanDisabled = true;
    } else {
      this.isWhoCanDisabled = false;
    }
    this.defaultLabel = environment.clientId === 'AduvoAdmin' ? 'All Users' : 'Select';

    forkJoin([
      this.lookupService.GetCampaignCategories(false),
      this.campaignsService.getUserList(),
      this.loanService.GetLoanStatusTypeMasterList(false),
      this.loanService.GetMasterContactTypes(false),
      this.loanService.GetLoanAssignTypeRoles(false),
    ]).subscribe(
      ([
        campCatData,
        allUserList,
        loanStatusTypeMasterList,
        masterContactTypeList,
        loanAssignTypeRoles,
      ]) => {
        if (campCatData?.data) {
          campCatData.data.map((itm) => {
            this.campaignCategoryList.push(
              new DropDownItem(itm.categoryId, itm.categoryName)
            );
          });
        }

        //load User list
        if (allUserList?.data) {
          let isPublishDb: boolean = false;
          if (environment.clientId === 'AduvoAdmin') {  //publishDb Site
            isPublishDb = true;
          }

          this.userList = allUserList.data.map((obj) => {
            let flag: boolean = true; //set disabled for all (publishDb site)

            if (!isPublishDb) { //not publishDB than either select/deselect
              flag = obj.tenantUserId == this.userToken.TenantUserId && isCompanyUserWithMarketing;
            }

            return {
              item_id: obj.tenantUserId,
              item_text: obj.name,
              isDisabled: flag,
            };
          });

          this.userList.sort((a, b) => {
            return a.item_text.toLowerCase() >= b.item_text.toLowerCase()
              ? 1
              : -1;
          });

          if (isPublishDb) {
            this.selectedCurrentUsers = [];
            this.selectedUserListLabel = 'All Users';
            //this.defaultLabel = 'All Users';
            this.defaultLabel = environment.clientId === 'AduvoAdmin' ? 'All Users' : 'Select';
          }
          else {

            if (this.userToken.isCompanyAdmin() || this.userToken.isMarketingAdmin()) {
              this.userList.map((x) => {
                this.selectedCurrentUsers.push(x);
              });
            }
            else {
              let recs = this.userList.filter(
                (x) => x.item_id == this.userToken.TenantUserId
              );
              this.selectedCurrentUsers.push(...recs);
            }
          }
        }

        if (loanStatusTypeMasterList?.data) {
          this.loanStatusTypeMasterList = loanStatusTypeMasterList.data.map(
            (obj) => {
              return {
                item_id: obj.masterListId,
                item_text: obj.title,
                item_is_contact: obj.isContactOnly,
              };
            }
          );
        }

        if (masterContactTypeList?.data) {
          this.masterContactTypes = masterContactTypeList.data.map((obj) => {
            return {
              item_id: obj.masterContactTypeId,
              item_text: obj.name,
            };
          });
        }

        if (loanAssignTypeRoles?.data) {
          this.loanAssignTypeRoles = loanAssignTypeRoles?.data.map((obj) => {
            return {
              item_id: obj.loanAssignTypeId,
              item_text: obj.name,
            };
          });
          this.loanAssignTypeRoles = this.loanAssignTypeRoles.sort((a, b) => {
            return a.item_text.toLowerCase() >= b.item_text.toLowerCase() ? 1 : -1;
          });
        }

        this.generateForm(); //end of fetch data.
        this.loadingPage = false;
        this.cdr.detectChanges();
      }
    );
  }

  setTab(index: number) {
    if (this.generatedCampaignId) {
      this.selectedStepIndex = index;

      if (!this.isCalledFromWizard) {
        this.isCalledFromWizard = this.selectedStepIndex == 2 ? true : false;//isCalledFromWizard is local variable
        this.updateMsgModel.isCalledFromWizard = 1;//called from create wizard 1 / Message Selection 2
      }

      this.showCrudLoader(true);// show loader

      this.createOrUpdate();

      this.cdr.detectChanges();
    }
  }

  public async SkipToCampaignEditor() {
    try {
      this.skipPageClicked = true;
      this.isSkipDisable = true;
      window.scrollTo(0, 0);

      this.validateSingleEmail();
      if (this.isFromEmailInValid) {
        this.campaignAddVM.fromEmail = '';
      }
      this.validateMultipleToEmail();
      if (this.isToEmailInValid) {
        this.campaignAddVM.toRecipientCustomAddress = '';
      }
      this.validateMultipleCCEmail();
      if (this.isCCEmailInValid) {
        this.campaignAddVM.ccRecipientCustomAddress = '';
      }
      this.validateMultipleBCCEmail();
      if (this.isBCCEmailInValid) {
        this.campaignAddVM.bccRecipientCustomAddress = '';
      }

      if (this.generatedCampaignId) {
        this.updateCampaign(true);//redirectToEditPage :true
      }
      else if (this.campaignAddVM.campaignName) {  //form has been partially filled than use current info otherwise save default
        this.createOrUpdate(true);
      }
      else {//direct skip
        var response = await this.campaignsService.CreateNewCampaign(this.sendedToListId);
        this.router.navigate([
          '/marketing/campaigns/edit/' + response.data + '/default',
        ]);
      }

      this.skipPageClicked = false;
      this.isSkipDisable = false;

    } catch (strError: any) {
      this.skipPageClicked = false;
      this.isSkipDisable = false;
      this.toastService.showError(strError);
    }
  }

  onUserListChange(event: any) {
    if (event.value.length == this.userList.length) {
      this.selectedUserListLabel = 'All Users';
    } else {
      this.selectedUserListLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onToMasterContactTypesChange(event: any) {
    if (event.value.length == this.masterContactTypes.length) {
      this.toSelectedMasterContactLabel = 'All ';
    } else {
      this.toSelectedMasterContactLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onCCMasterContactTypesChange(event: any) {
    if (event.value.length == this.masterContactTypes.length) {
      this.ccSelectedMasterContactLabel = 'All ';
    } else {
      this.ccSelectedMasterContactLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onBCCMasterContactTypesChange(event: any) {
    if (event.value.length == this.masterContactTypes.length) {
      this.bccSelectedMasterContactLabel = 'All ';
    } else {
      this.bccSelectedMasterContactLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onToLoanAssignTypeRolesChange(event: any) {
    if (event.value.length == this.loanAssignTypeRoles.length) {
      this.toSelectedLoanAssignTypeRolesLabel = 'All ';
    } else {
      this.toSelectedLoanAssignTypeRolesLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onCCLoanAssignTypeRolesChange(event: any) {
    if (event.value.length == this.loanAssignTypeRoles.length) {
      this.ccSelectedLoanAssignTypeRolesLabel = 'All ';
    } else {
      this.ccSelectedLoanAssignTypeRolesLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onBCCLoanAssignTypeRolesChange(event: any) {
    if (event.value.length == this.loanAssignTypeRoles.length) {
      this.bccSelectedLoanAssignTypeRolesLabel = 'All ';
    } else {
      this.bccSelectedLoanAssignTypeRolesLabel = '{0} items selected';
    }
    this.cdr.detectChanges();
  }

  onRoleChange(role) {
    // if (role == 1) {
    //   this.campaignDetails.isCustomRoleSelected = true;
    // } else {
    //   this.campaignDetails.isCustomRoleSelected = false;
    // }
  }

  QuickStartChange() {
    this.campaignAddVM.isQuickStart = this.campaignFormGroup.value.items[0].isQuickStart;
    this.cdr.detectChanges();
  }

  RecommendedChange() {
    this.campaignAddVM.isRecommended =
      this.campaignFormGroup.value.items[0].isRecommended;
    this.cdr.detectChanges();
  }

  public skip() {
    this.selectedStepIndex = this.selectedStepIndex >= 4 ? 4 : this.selectedStepIndex + 1; //this button will not shown on last screen as it will show diffrent page.

    if (!this.isCalledFromWizard) {
      this.isCalledFromWizard = this.selectedStepIndex == 2 ? true : false;
      this.updateMsgModel.isCalledFromWizard = 1;//called from create wizard 1 / Message Selection 2
    }

    this.createOrUpdate();
  }

  public prev() {

    if (this.selectedStepIndex === 3) {
      this.ShowMessageScheduleTab = false;
    }
    this.selectedStepIndex -= 1; //this button will not shown on last screen as it will show different page.

    this.createOrUpdate();
  }

  public next() {
    this.selectedStepIndex = this.selectedStepIndex >= 4 ? 4 : this.selectedStepIndex + 1; //this button will not shown on last screen as it will show diffrent page.

    if (!this.isCalledFromWizard) {//to load once only when selected 
      this.isCalledFromWizard = this.selectedStepIndex == 2 ? true : false;
      this.updateMsgModel.isCalledFromWizard = 1;//called from create wizard 1 / Message Selection 2
    }

    this.createOrUpdate();
  }

  private createOrUpdate(redirect: boolean = false) {

    window.scrollTo(0, 0);

    this.showCrudLoader(true); // show loader

    if (!this.generatedCampaignId) {
      this.createCampaign(redirect);
    } else {
      this.updateCampaign();
    }

    this.setComponentVisibility();
  }

  private setComponentVisibility() {
    if (this.selectedStepIndex == 3 && this.generatedCampaignId) {
      //update selected message template
      if (this.selectItemsForWizard.length > 0) {
        this.noMessageFound = false;
      } else {
        this.noMessageFound = true;
      }
    }
    else if (this.selectedStepIndex == 4 && this.generatedCampaignId) {
      this.isMessageSchedule = this.hasAnyScheduleIsSet();

      let userRoleNameFoundInToRecipientList = '';
      this.userRoleFoundInToRecipientList = false;
      let isPublishDbRecord = false;
      if (this.currentClientID == 'AduvoAdmin') {
        isPublishDbRecord = true;
      }

      let campaignBlock = {
        toRecipientUsers: (this.campaignAddVM.toRecipientUsers || []).join(',')
      };

      this.loanService.GetLoanAssignTypeRoles(isPublishDbRecord).subscribe(
        (res) => {
        if (res.data) {
          if(campaignBlock.toRecipientUsers) {
            let toRecipientList = campaignBlock.toRecipientUsers.split(',');
    
            res.data.forEach((item1) => {
              const index = toRecipientList.findIndex(obj => obj === item1.loanAssignTypeId)
              if (index > -1) {
                this.userRoleFoundInToRecipientList = true;
                userRoleNameFoundInToRecipientList += item1.name + ", ";
              }
            })
            var index = userRoleNameFoundInToRecipientList.lastIndexOf(", ");
            if(index > 0) {
              userRoleNameFoundInToRecipientList = userRoleNameFoundInToRecipientList.substring(0, index);
            }
          }
        }
        },
        (err) => {
        }
      );

      if(this.userRoleFoundInToRecipientList) {
        this.userRoleNameFoundInToRecipientListMessage = "Please be aware that users such as Loan Officers placed in the 'To Recipients' field will only be processed for Status Quick Pick messages.";
      } else {
        this.userRoleNameFoundInToRecipientListMessage = '';
      }
    }
    this.cdr.detectChanges();
  }

  private generateForm() {
    this.unSubscribeAllSubs();

    this.campaignFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems()),
    });

    this.items = this.formDataCtrl;
    this.sub.push(
      this.campaignFormGroup.valueChanges
        .pipe(
          debounceTime(500),//delay and  stop unnecessary propagation.
          distinctUntilChanged()
        )
        .subscribe((data) => {
          if (data.items) {
            this.campaignAddVM.campaignName = data.items[0].campaignName;
            this.campaignAddVM.campaignCategory = data.items[0].campaignCategory;
            this.campaignAddVM.campaignDesc = data.items[0].campaignDesc;
            this.campaignAddVM.tenantUserIdList = data.items[0].whoCanUseList
              ?.map((x) => x.item_id)
              ?.join(); //tenantUserIdCSV
            this.campaignAddVM.toList = data.items[0].toList
              ?.map((x) => x.item_id)
              ?.join(',');
            this.campaignAddVM.toRecipientContacts = data.items[0].toRecipientContacts;
            this.campaignAddVM.toRecipientUsers = data.items[0].toRecipientUsers;
            this.campaignAddVM.toRecipientCustomAddress = data.items[0].toRecipientCustomAddress;
            this.campaignAddVM.ccRecipientContacts = data.items[0].CCRecipientContacts;
            this.campaignAddVM.ccRecipientUsers = data.items[0].CCRecipientUsers;
            this.campaignAddVM.ccRecipientCustomAddress = data.items[0].CCRecipientCustomAddress;
            this.campaignAddVM.bccRecipientContacts = data.items[0].BCCRecipientContacts;
            this.campaignAddVM.bccRecipientUsers = data.items[0].BCCRecipientUsers;
            this.campaignAddVM.bccRecipientCustomAddress = data.items[0].BCCRecipientCustomAddress;
            this.campaignAddVM.fromLoanAssignTypeId = data.items[0].fromRole; 
            this.campaignAddVM.fromEmail = data.items[0].fromEmail;
            this.campaignAddVM.fromName = data.items[0].fromName;
            this.campaignAddVM.isFromRole = data.items[0].isFromRole;
            this.campaignAddVM.isQuickStart = data.items[0].isQuickStart;
            this.campaignAddVM.isRecommended = data.items[0].isRecommended;
          }
          //console.log(this.campaignAddVM);
        })
    );
  }

  public get formDataCtrl(): FormArray {
    const formArray = this.campaignFormGroup?.get('items') as FormArray;
    return formArray;
  }

  private createItems(): FormGroup[] {
    const formGroupArr: FormGroup[] = [];
    let selectedListItems = this.loanStatusTypeMasterList.filter(
      (x) => x.item_id == this.sendedToListId
    );
    formGroupArr.push(
      this.formBuilder.group({
        campaignName: new FormControl('', [Validators.required]),
        campaignCategory: new FormControl(''),
        campaignDesc: new FormControl(''),
        isQuickStart: new FormControl(false),
        isRecommended: new FormControl(false),
        whoCanUseList: new FormControl(
          this.selectedCurrentUsers
          //disabled: (this.selectedCurrentUsers.length === 0 || this.isWhoCanDisabled),
        ),
        toList: new FormControl(selectedListItems),
        toRecipientContacts: new FormControl(''),
        toRecipientUsers: new FormControl(''),
        toRecipientCustomAddress: new FormControl(''),
        CCRecipientContacts: new FormControl(''),
        CCRecipientUsers: new FormControl(''),
        CCRecipientCustomAddress: new FormControl(''),
        BCCRecipientContacts: new FormControl(''),
        BCCRecipientUsers: new FormControl(''),
        BCCRecipientCustomAddress: new FormControl(''),

        isFromRole: new FormControl('0'), //for radio button swapping
        fromRole: new FormControl(''), //fromLoanAssignTypeId
        fromEmail: new FormControl(''),
        fromName: new FormControl(''),
      })
    );
    return formGroupArr;
  }

  private createCampaign(redirect: boolean = false) {
    //this.createCampaignWizardInProgress = true;
    let saveObj = this.returnSaveObject();
    let request = { json: JSON.stringify(saveObj) };
    this.campaignsService
      .createCampaignViaWizard(request)
      .subscribe((response) => {
        //Case of error.
        if (response.data == '00000000-0000-0000-0000-000000000000' && response.message) {
          this.toastService.showError(response.message);
          this.selectedStepIndex = 0; //go back to first page.
          this.cdr.detectChanges();
        }
        else if (response?.data) {
          this.generatedCampaignId = response.data;
          if (this.selectedStepIndex == 2) {
            //message template page.
            this.messageTemplateDialogModel = new MessageTemplateDialogModel();
            this.messageTemplateDialogModel.campaignId = response.data; //setting Campaign id in message.
            //this.createCampaignWizardInProgress = false;
            this.cdr.detectChanges();
          }
          else {
            this.toastService.showSuccess('Campaign Created Successfully!');
            // if (this.selectedStepIndex >= 4 || (this.selectedStepIndex <= 3 && this.generatedCampaignId)) {
            //   this.router.navigate(['/marketing/campaigns/edit/' + response.data + '/default',]);
            // }
          }

          if (redirect) {
            this.router.navigate(['/marketing/campaigns/edit/' + this.generatedCampaignId + '/default',]);
          }
        }
        else {
          this.toastService.showError('Error while creating campaign, please try again!');
        }

        this.showCrudLoader(false);
      });
  }

  private showCrudLoader(flag: boolean) {
    this.crudInProgress = flag;
    this.cdr.detectChanges();
  }

  private returnSaveObject() {
    if (this.userList?.length == this.campaignAddVM.tenantUserIdList?.length) {
      this.campaignAddVM.isAllUsers = true;
    }
    else {
      this.campaignAddVM.isAllUsers = false;
    }

    let saveObj = {
      campaignId: this.generatedCampaignId,//update case only
      templateCSV: this.selectItemsForWizard?.map((x) => x.id).join(','),//update case only
      campaignName: this.campaignAddVM.campaignName,
      campaignCategory: this.campaignAddVM.campaignCategory,
      campaignDesc: this.campaignAddVM.campaignDesc,
      tenantUserIdList: this.campaignAddVM.tenantUserIdList,
      toList: this.campaignAddVM.toList,
      toRecipientContacts: (this.campaignAddVM.toRecipientContacts || []).join(','),
      toRecipientUsers: (this.campaignAddVM.toRecipientUsers || []).join(','),
      toRecipientCustomAddress: this.campaignAddVM.toRecipientCustomAddress,

      ccRecipientContacts: (this.campaignAddVM.ccRecipientContacts || []).join(','),
      ccRecipientUsers: (this.campaignAddVM.ccRecipientUsers || []).join(','),
      ccRecipientCustomAddress: this.campaignAddVM.ccRecipientCustomAddress,

      bccRecipientContacts: (this.campaignAddVM.bccRecipientContacts || []).join(','),
      bccRecipientUsers: (this.campaignAddVM.bccRecipientUsers || []).join(','),
      bccRecipientCustomAddress: this.campaignAddVM.bccRecipientCustomAddress,

      isFromRole: this.campaignAddVM.isFromRole,
      fromLoanAssignTypeId: this.campaignAddVM.fromLoanAssignTypeId,
      fromEmail: this.campaignAddVM.fromEmail,
      fromName: this.campaignAddVM.fromName,
      isRecommended: this.campaignAddVM.isRecommended,
      isQuickStart: this.campaignAddVM.isQuickStart,
      isAllUsers: this.campaignAddVM.isAllUsers
    };

    if (saveObj.isFromRole == '1' || saveObj.isFromRole == '2') { // Custom Address
      saveObj.fromLoanAssignTypeId = '';
    } else if (saveObj.isFromRole == '0') {
      saveObj.fromEmail = saveObj.fromName = '';
    }
    return saveObj;
  }

  private getCampaignMessages() {
    try {
      this.messagesService.GetCampaignMessagesObs(this.generatedCampaignId)
        .subscribe((result) => {
          if (result?.data) {
            this.selectedMessagesList = null;
            this.selectedMessagesList = Object.assign({}, result);
            let flag = this.selectedMessagesList?.data?.length > 0 ? true : false;
            this.ShowMessageScheduleTab = flag;
            this.noMessageFound = !flag;
            this.showCrudLoader(false);// hide loader
          }
        }, err => {
          this.toastService.showError('Error while fetching Campaign Messages.');
          this.showCrudLoader(false);// hide loader
        });
    } catch (message: any) {
      this.toastService.showError(message);
      this.showCrudLoader(false);// hide loader
    }
  }

  getCreateTemplate(templateModel: any) {
    window.scrollTo(0, 0);
    if (templateModel) {
      //redirect
      this.router.navigate([
        '/marketing/campaigns/edit/' + this.generatedCampaignId + '/default',
      ]);
    }
  }

  onItemsSelectForWizardEvent(event: TemplateHtmlData[]) {
    this.selectItemsForWizard = [];
    if (event.length) {
      this.selectItemsForWizard = event;
    }
  }

  updateCampaign(redirectToEditPage: boolean = false) {
    if (!this.generatedCampaignId) { return; }

    let saveObj = this.returnSaveObject();

    if (this.prevUpdateObject && JSON.stringify(this.prevUpdateObject) === JSON.stringify(saveObj)) {
      console.log('no update required.')
      if (this.selectedStepIndex == 3 && this.generatedCampaignId && this.selectItemsForWizard.length) {
        this.ShowMessageScheduleTab = false;
        this.selectedMessagesList = null;
        this.cdr.detectChanges();
        this.getCampaignMessages();
      } else {
        this.showCrudLoader(false);// hide loader
      }
      if (redirectToEditPage) {
        setTimeout(() => {
          this.router.navigate(['/marketing/campaigns/edit/' + this.generatedCampaignId + '/default',]);
        }, 2000);
      }
      return; // no need to proceed.
    }

    let request = { json: JSON.stringify(saveObj) };
    this.campaignsService
      .updateCampaignViaWizard(request)
      .subscribe((resp) => {
        if (resp.data) {
          this.prevUpdateObject = saveObj;

          if (this.selectedStepIndex == 3 && this.generatedCampaignId && this.selectItemsForWizard.length) {
            this.ShowMessageScheduleTab = false;
            this.selectedMessagesList = null;
            this.cdr.detectChanges();
            this.getCampaignMessages();
          } else {
            this.showCrudLoader(false);// hide loader
          }

          if (redirectToEditPage) {
            setTimeout(() => {
              this.router.navigate(['/marketing/campaigns/edit/' + this.generatedCampaignId + '/default',]);
            }, 2000);
          }
          console.log('Campaign Updated.');
        }
      });
  }

  hasAnyScheduleIsSet() {
    let isScheduleSet = false;
    let items = this.selectedMessagesList?.data?.filter(x => x.scheduleType.length > 0);
    isScheduleSet = items?.length ? true : false;
    return isScheduleSet;
  }

  actionContent(event, label) {
    if (event.target.checked) {
      this.selectedLabel = label;
    }
    if (label == 'YesRadio') {
      this.selectedItem = 1;
      this.onActivating();
    } else {
      this.selectedItem = 0;
      this.onContinueEditing();
    }
  }

  onContinueEditing() {
    this.showValidateCampaignDialog = false;
    this.router.navigate([
      '/marketing/campaigns/edit/' + this.generatedCampaignId + '/default',
    ]);
  }

  onActivating() {
    var startText = '\n';
    var text = '';

    if (!this.isMessageSchedule) {
      text += ' <li> At least one scheduled message </li> ';
    }

    let campaignBlock = {
      campaignName: this.campaignAddVM.campaignName,
      campaignCategory: this.campaignAddVM.campaignCategory,
      campaignDesc: this.campaignAddVM.campaignDesc,
      tenantUserIdList: this.campaignAddVM.tenantUserIdList, // whoCanUseList
      toList: this.campaignAddVM.toList,
      toRecipientContacts: (this.campaignAddVM.toRecipientContacts || []).join(','), //[]
      toRecipientUsers: (this.campaignAddVM.toRecipientUsers || []).join(','), //[];
      toRecipientCustomAddress: this.campaignAddVM.toRecipientCustomAddress, //string csv
      ccRecipientContacts: (this.campaignAddVM.ccRecipientContacts || []).join(','), //[];
      ccRecipientUsers: (this.campaignAddVM.ccRecipientUsers || []).join(','), //[];
      ccRecipientCustomAddress: this.campaignAddVM.ccRecipientCustomAddress,
      bccRecipientContacts: (this.campaignAddVM.bccRecipientContacts || []).join(','), //[];
      bccRecipientUsers: (this.campaignAddVM.bccRecipientUsers || []).join(','), //string[];
      bccRecipientCustomAddress: this.campaignAddVM.bccRecipientCustomAddress, //string;
      isFromRole: this.campaignAddVM.isFromRole,
      fromLoanAssignTypeId: this.campaignAddVM.fromLoanAssignTypeId,
      fromEmail: this.campaignAddVM.fromEmail,
      fromName: this.campaignAddVM.fromName,
      isRecommended: this.campaignAddVM.isRecommended,
      isQuickStart: this.campaignAddVM.isQuickStart,
      isAllUsers: this.campaignAddVM.isAllUsers
    };

    if (!campaignBlock.campaignCategory) {
      text += '<li> Campaign Category </li> ';
    }

    if (!campaignBlock.fromLoanAssignTypeId && !campaignBlock.fromEmail &&
      !campaignBlock.fromName && (campaignBlock.isFromRole !== "2")) {
      text += ' <li> From </li> ';
    }

    if (!campaignBlock.toList) {
      text += '<li> To list </li> ';
    }

    if (!campaignBlock.toRecipientContacts && !campaignBlock.toRecipientUsers && !campaignBlock.toRecipientCustomAddress) {
      text += ' <li> To recipients </li>  ';
    }

    let commonRecipientContactStringList = '';
    if (campaignBlock.toRecipientContacts != '') {
      commonRecipientContactStringList = campaignBlock.toRecipientContacts + ',';
    }
    if (campaignBlock.ccRecipientContacts != '') {
      commonRecipientContactStringList += campaignBlock.ccRecipientContacts + ',';
    }
    let commonRecipientContactList = commonRecipientContactStringList.split(',');
    let commonRecipientContactFound = this.toFindDuplicatesExists(commonRecipientContactList);

    let commonRecipientUserStringList = '';
    if (campaignBlock.toRecipientUsers != '') {
      commonRecipientUserStringList = campaignBlock.toRecipientUsers + ',';
    }
    if (campaignBlock.ccRecipientUsers != '') {
      commonRecipientUserStringList += campaignBlock.ccRecipientUsers + ',';
    }
    if (campaignBlock.bccRecipientUsers != '') {
      commonRecipientUserStringList += campaignBlock.bccRecipientUsers + ',';
    }

    let commonRecipientUserList = commonRecipientUserStringList.split(',');
    let commonRecipientUserFound = this.toFindDuplicatesExists(commonRecipientUserList);

    let commonRecipientCustomStringList = '';
    if (campaignBlock.toRecipientCustomAddress != '') {
      commonRecipientCustomStringList = campaignBlock.toRecipientCustomAddress + ',';
    }
    if (campaignBlock.ccRecipientCustomAddress != '') {
      commonRecipientCustomStringList += campaignBlock.ccRecipientCustomAddress + ',';
    }
    if (campaignBlock.bccRecipientCustomAddress != '') {
      commonRecipientCustomStringList += campaignBlock.bccRecipientCustomAddress + ',';
    }
    //if (campaignBlock.fromEmail != '') {
    //  commonRecipientCustomStringList += campaignBlock.fromEmail + ',';
    //}

    let commonRecipientCustomList = commonRecipientCustomStringList.split(',');
    let commonRecipientCustomFound = this.toFindDuplicatesExists(commonRecipientCustomList);

    if (commonRecipientContactFound || commonRecipientUserFound || commonRecipientCustomFound) {
      text += ' <li> Each role may only appear once within the To, CC, and BCC fields</li>  ';
    }

    if (text) {
      this.validationString = startText + text;
      this.validationString = this.validationString.slice(0, -2);
      this.showValidateCampaignDialog = true;
    } else {
      this.validationString = '';
      this.showValidateCampaignDialog = false;
      if (this.isMessageSchedule) {
        this.activateCampaign(this.generatedCampaignId);
      }
    }
  }

  toFindDuplicatesExists(arry) {
    let toMap = {};
    let resultToReturn = false;
    for (let i = 0; i < arry.length; i++) {
      if (toMap[arry[i]]) {
        resultToReturn = true;
        // terminate the loop
        break;
      }
      toMap[arry[i]] = true;
    }
    return resultToReturn;
  }

  async activateCampaign(campaignId: string) {
    this.loadingPage = true;
    try {
      let isPublishDbRecord = false;
      if (this.currentClientID == 'AduvoAdmin') {
        isPublishDbRecord = true;
      }
      await this.campaignsService.TogglePublishedCampaign(campaignId, isPublishDbRecord);
      var response = await this.campaignsService.Get();

      var runMode;
      response.data.forEach(element => {
        runMode = element.runMode
      });

      if (runMode === "TEST" && this.currentClientID != 'AduvoAdmin') {
        this.showTestModeDialog = true;
      } else {
        this.showTestModeDialog = false;
        this.ClickOk();
      }
      this.cdr.detectChanges();
      this.toastService.showSuccess('Campaign Activated');
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  validateSingleEmail() {
    let isValid = false;
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.campaignAddVM.fromEmail != '') {
      isValid = singleEmailRegularExpression.test(
        String(this.campaignAddVM.fromEmail).toLowerCase()
      );
      if (!isValid) {
        this.isFromEmailInValid = true;
      } else {
        this.isFromEmailInValid = false;
      }
    } else {
      this.isFromEmailInValid = false;
    }
  }

  validateMultipleEmails(emailIds: string) {
    let isValid = false;
    let isAllEmailValid = true;
    //emailIds = emailIds.replace(/\s/g, '').toLowerCase();
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailIds && emailIds.length > 0) {
      let emails = emailIds
        .split(';')
        .map((e) => e.split(','))
        .reduce((acc, cur) => cur.concat(acc), [])
        .filter((e) => !!e);
      if (emails && emails.length > 0) {
        for (var x = 0; x < emails.length; x++) {
          isValid = singleEmailRegularExpression.test(
            String(emails[x]).toLowerCase()
          );
          if (!isValid) {
            isAllEmailValid = false;
          }
        }
      } else {
        isAllEmailValid = false;
      }
    } else {
      isAllEmailValid = false;
    }
    return isAllEmailValid;
  }

  validateMultipleToEmail() {
    let isValid = false;

    if (this.campaignAddVM.toRecipientCustomAddress != '') {
      isValid = this.validateMultipleEmails(this.campaignAddVM.toRecipientCustomAddress);
      if (!isValid) {
        this.isToEmailInValid = true;
      } else {
        this.isToEmailInValid = false;
      }
    } else {
      this.isToEmailInValid = false;
    }
  }

  validateMultipleCCEmail() {
    let isValid = false;

    if (this.campaignAddVM.ccRecipientCustomAddress != '') {
      isValid = this.validateMultipleEmails(this.campaignAddVM.ccRecipientCustomAddress);
      if (!isValid) {
        this.isCCEmailInValid = true;
      } else {
        this.isCCEmailInValid = false;
      }
    } else {
      this.isCCEmailInValid = false;
    }
  }

  validateMultipleBCCEmail() {
    let isValid = false;

    if (this.campaignAddVM.bccRecipientCustomAddress != '') {
      isValid = this.validateMultipleEmails(this.campaignAddVM.bccRecipientCustomAddress);
      if (!isValid) {
        this.isBCCEmailInValid = true;
      } else {
        this.isBCCEmailInValid = false;
      }
    } else {
      this.isBCCEmailInValid = false;
    }
  }

  async onCloseScheduleDialog() {
    this.isMessageSchedule = this.hasAnyScheduleIsSet();
  }

  private unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach((element) => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  ngOnDestroy(): void {
    this.unSubscribeAllSubs();
 
    this.campaignAddVM = null; //clear
    this.selectItemsForWizard = [];
    this.prevUpdateObject = null;

    this.steps = null;
    this.selectedStepIndex = null;
    this.skipPageClicked = null;
    this.loadingPage = null;
    this.isSkipDisable = null;
    this.campaignCategoryList = null;
    this.userList = null;
    this.selectedUserListLabel = null;
    this.toSelectedMasterContactLabel = null;
    this.ccSelectedMasterContactLabel = null;
    this.bccSelectedMasterContactLabel = null;
    this.toSelectedLoanAssignTypeRolesLabel = null;
    this.ccSelectedLoanAssignTypeRolesLabel = null;
    this.bccSelectedLoanAssignTypeRolesLabel = null;
    this.loanStatusTypeMasterList = null;
    this.masterContactTypes = null;
    this.loanAssignTypeRoles = null;
    this.messageTemplateDialogModel = null;
    this.campaignFormGroup = null;
    this.items = null;
    this.selectedCurrentUsers = null;
    this.defaultLabel = null;
    this.isWhoCanDisabled = null;
    this.currentClientID = null;
    this.isCalledFromWizard = null;
    this.sortByList = null;
    this.isMessageSchedule = null;
    this.ShowMessageScheduleTab = null;
    this.selectedLabel = null;
    this.selectedItem = null;
    this.validationString = null;
    this.showValidateCampaignDialog = null;
    this.isFromEmailInValid = null;
    this.isToEmailInValid = null;
    this.isCCEmailInValid = null;
    this.isBCCEmailInValid = null;
    this.selectedMessagesList = null;
    this.noMessageFound = null;
    this.crudInProgress = null;
    this.updateMsgModel = null;

  }

  GoToCompanyProfile() {
    this.router.navigate(['/company-profile']);
  }

  ClickOk() {
    this.router.navigate(['marketing/content-library-filter/All']);
  }
}
