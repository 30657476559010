import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MultiSelect } from 'primeng/multiselect';
import { pipe, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter, RhsDropdown, RhsDropdownItem, RhsDropdownItem2, RhsQueryData } from '../../../models/PeopleAndLoanFilterOutput';
import { PeopleAndLoanService } from '../../../services/peopleandloans.service';

@Component({
  selector: 'app-people-and-list-advanced-filter-text-list',
  templateUrl: './people-and-list-filter-text-list.component.html',
  styleUrls: ['./people-and-list-filter-text-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterTextListComponent implements OnInit, OnDestroy {
  public selectedOperand: any | null;
  public textFormGroup: FormGroup | null;
  public items: FormArray | null;
  public disableApplyFilter: boolean;
  public isLoading: boolean;
  public rhsDropdown: RhsDropdownItem2[];

  private dropdownLoaded$ = new Subject<number>();

  @Input() OperandDropDownList: any | null;
  @Input() get SelectedFilter(): any | null {
    return this.selectedFilter;
  }
  set SelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      this.textFormGroup = null;
      this.items = null;
      this.loadByQueryKey(this.SelectedFilter?.FieldSubGroupName);//@@##todo this is async which is a issue  
    }
  }

  @Output() onCreateFilterEvent = new EventEmitter<PeopleAndLoanFilterOutput>();
  @Output() onCancelFilterEvent = new EventEmitter<boolean>();

  private sub: Array<Subscription>;
  private selectedFilter: PeopleAndLoanFieldLookup | null;
  private peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput;
  private tmpPeopleAndLoanFilters: PeopleAndLoanFilterData;
  private peopleAndLoanFilters: PeopleAndLoanFilterData;
  private storageHelper: StorageHelper;
  private editNode: PeopleAndLoanEditTagData | null;
  private dropdownQuery: any;
  private insertIndex: number;

  @ViewChild('txtRHSValue1', { static: false }) multiSelect: MultiSelect;
  close() {
    this.multiSelect.overlayVisible = false;
    //this.multiSelect.close(null);
    this.cdr.detectChanges();
  }

  constructor(public formBuilder: FormBuilder, private peopleAndLoanService: PeopleAndLoanService,
    private cdr: ChangeDetectorRef) {
    this.textFormGroup = null;
    this.items = null;
    this.sub = new Array<Subscription>();
    this.peopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(false, null);
    this.storageHelper = new StorageHelper();
    this.disableApplyFilter = true;
    this.isLoading = false;
    this.dropdownQuery = new Map([
      ['LoanOfficer', "select code, name from [dbo].[udf_PeopleLoans_LoanCompanyUser](@TenantId, \"Loan Officer\")"],
      ['Underwriter', "select code, name from [dbo].[udf_PeopleLoans_LoanCompanyUser](@TenantId, \"Underwriter\")"],
      ['FileStarter', "select code, name from [dbo].[udf_PeopleLoans_LoanCompanyUser](@TenantId, \"File Starter\")"],
      ['LoanProcessor', "select code, name from [dbo].[udf_PeopleLoans_LoanCompanyUser](@TenantId, \"Loan Processor\")"],
      ['ContactOwner', "select code, name from [dbo].[udf_PeopleLoans_ContactOwner](@TenantId)"]
    ]);
    this.rhsDropdown = [];
    this.insertIndex = 0;
  }

  ngOnInit(): void {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //this.loadByQueryKey(this.SelectedFilter?.FieldSubGroupName);
    this.sub.push(this.dropdownLoaded$.subscribe((response: any) => {
      this.generateForm();
    }));

  }

  operandChanged(event, index) {//to hide show textbox and remove validations.
    if (index > -1) {
      this.isLoading = false;
      this.selectedFilter = event?.value;
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(event.value.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now

     // this.loadByQueryKey(event.value.FieldSubGroupName);

    }
  }

  operandChangedNew(index) {//to hide show textbox and remove validations.
    if (index > -1) {
      //this.loadByQueryKey(this.SelectedFilter?.FieldSubGroupName);

      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now

    }
  }

  private loadByQueryKey(queryKey: string) {
    //let queryKey = this.SelectedFilter?.FieldSubGroupName; //FieldSubGroupName//queryKey=LoanOfficer,Underwriter...
    this.isLoading = true;
    this.cdr.detectChanges();
    this.loadAndCacheQueryData(queryKey);
    this.loadRhsDropdown(queryKey);
  }

  private async loadRhsDropdown(queryKey: string) {
    let index = 0;
    this.rhsDropdown = [];
    let myInterval = setInterval((x) => {
      let loadingStatus = this.storageHelper.GetDataFromStorage(queryKey)?.value;
      if (loadingStatus == "loaded") {
        let queryStr = this.dropdownQuery.get(queryKey.replace(" ", ""));
        if (queryStr) {
          let cacheKey = this.peopleAndLoanService.stringToHash(queryStr);
          let item: RhsQueryData = this.storageHelper.GetDataFromStorage(cacheKey)?.value;
          if (item && item?.jsonStr) {
            let RhsDropdownItemArr: RhsDropdownItem2[] = JSON.parse(item?.jsonStr);
            this.rhsDropdown.push(...RhsDropdownItemArr);
          }
          this.isLoading = false;
          this.callDropDownLoadedObservable();
          this.cdr.detectChanges();
          clearInterval(myInterval);
        }
      }
      // console.log("LoadRhsDropdown index::" + index);
      if (index > 9)//assuming no data in 15 sec.
      {
        this.isLoading = false;
        this.callDropDownLoadedObservable();
        clearInterval(myInterval);
      }
      index++;
    }, 600);//  }, 1000);
  }

  private generateForm() {
    this.unSubscribeAllSubs();

    this.textFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });
    this.cdr.detectChanges();


    if (this.SelectedFilter?.isAddFlag) {
      this.operandChangedNew(0);
    }


    let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();

    this.items = this.formDataCtrl;
    this.sub.push(
      this.textFormGroup.valueChanges.pipe(
        //debounceTime(500),//delay and  stop unnecessary propagation.
        distinctUntilChanged()
      ).subscribe(item => {
        listAndFilters.PeopleLoanListOrFilters = [];//clear all
        let idx = 0;
        listAndFilters.id = idx + 1;
        listAndFilters.peopleAndLoansFieldLookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId;

        //Changed json fetching first record value
        if (item?.items?.length) {
          listAndFilters.group = item.items[0].operandDropDown.FieldGroupName;
          listAndFilters.datasource = item.items[0].operandDropDown.DataSource;
          listAndFilters.datacolumn = item.items[0].operandDropDown.DataColumn;
          listAndFilters.type = item.items[0].operandDropDown.DataType;
          if (item.items[0].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
            listAndFilters.subGroup = item.items[0].operandDropDown.FieldSubGroupName;
          }
        }

        // this.existingListOrFilters = [];
        // let newIndex = -1;
        // let andFilterIndex = -1;
        // let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
        // if (savedFilter?.value) {
        //   let obj = JSON.parse(JSON.stringify(savedFilter.value));
        //   andFilterIndex = obj?.PeopleLoanList?.PeopleLoanListAndFilters?.length ?? -1;
        //   if (obj && andFilterIndex) {

        //     obj.PeopleLoanList.PeopleLoanListAndFilters.forEach(laf => {
        //       if (laf.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
        //         this.existingListOrFilters = laf.PeopleLoanListOrFilters;
        //         return false;//break;
        //       }
        //     });
        //     newIndex = this.existingListOrFilters.length + 1;
        //   }
        //   if (andFilterIndex != -1) {
        //     listAndFilters.id = andFilterIndex + 1;
        //   }
        // }
        //loop and add/remove validation as per dropdown value.
        item.items.forEach((element, index) => {
          if (index == 0) {
            listAndFilters.PeopleLoanListOrFilters = [];//clear all
          }
          //let rhsValue = element.txtRHSValue.replace(/[^\w\s]/gi, '');//removing special symbol
          let rhsCodeValue1, rhsNameValue1 = '';
          if (element?.txtRHSValue?.length) {//if arr
            //loop and remove 
            let arrRhs = JSON.parse(JSON.stringify(element?.txtRHSValue));
            for (let index = 0; index < arrRhs?.length; index++) {
              arrRhs[index].name = arrRhs[index]?.name?.replace(/'/g, "#");//single quote will break our json.
            }
            rhsCodeValue1 = "'" + arrRhs?.map(z => z.code).join("','") + "'";
            //rhsNameValue1 = arrRhs?.map(z => z.name).join("' or '").replace(/'/g, '');
            rhsNameValue1 = arrRhs?.length ? arrRhs[0].name : '';//take first item only so we can show +1 
          }


          let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
          filter.id = (index + 1);
          filter.operator = element.operandDropDown.OpCode;
          filter.lhs = '';
          filter.rhs = [rhsCodeValue1];
          filter.peopleAndLoansTypeDefLookupId = element.operandDropDown.PeopleAndLoansTypeDefLookupId;

          let criteriaQuery = element.operandDropDown.Criteria;
          if (criteriaQuery) {
            criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
            //criteriaQuery = criteriaQuery.replace('{{rhs1}}', rhsCodeValue1);
            criteriaQuery = criteriaQuery.replace('{{rhs1}}', rhsCodeValue1);
            filter.criteria = criteriaQuery;
          }

          let htmlTemplate = element.operandDropDown.DisplayHtmlTemplate;
          if (htmlTemplate) {
            htmlTemplate = htmlTemplate.replace('{{lhs}}', element.operandDropDown.DataColumnDisplay);
            htmlTemplate = htmlTemplate.replace('{{rhs1}}', rhsNameValue1);
            // let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
            // filter.displayHtml = prefix + htmlTemplate;
            filter.displayHtml = htmlTemplate;
          }
          listAndFilters.PeopleLoanListOrFilters.push(filter)
        });
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
        // if (this.existingListOrFilters?.length) {
        //   this.existingListOrFilters.forEach(filter => {
        //     listAndFilters.PeopleLoanListOrFilters.push(filter)
        //   });
        // }

        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters);

        //validate and show apply button
        if (!this.textFormGroup?.valid) {
          this.disableApplyFilter = true;
        } else {
          this.disableApplyFilter = false;
        }
        
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
      })
    );
  }

  loadAndCacheQueryData(queryKey: string) {
    let rhsDataStatus = this.storageHelper.GetDataFromStorage(queryKey)?.value;
    if (rhsDataStatus == "loaded") {
      console.log("data has been loaded in storage.");
      this.callDropDownLoadedObservable();
    }
    else {
      let queryStr = this.dropdownQuery.get(queryKey?.replace(" ", ""));
      if (queryStr) {
        let rhsQueryData: RhsQueryData[] = [];
        let cacheKey = this.peopleAndLoanService.stringToHash(queryStr);
        rhsQueryData.push(new RhsQueryData(cacheKey, queryStr, '',0,queryKey?.replace(" ", "")));
        this.peopleAndLoanService.setDataForRhsQueryByCategory(rhsQueryData, queryKey);
      }
    }
  }

  applyFilter() {
    if (this.textFormGroup?.valid) {//if json has been received.
      this.validateAndEmitSuccessJson();

    } else {
      this.emitFailureJson();
    }
  }

  private createItems(): FormGroup[] {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //edit filter from input or observable.
    const formGroupArr: FormGroup[] = [];

    //Edit CASE  
    let editNodeTmp = this.storageHelper.GetDataFromStorage('PeopleAndLoan_editNode');//fetch 
    if (editNodeTmp?.value?.peopleAndLoansFieldLookupId &&
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length > 0) {
      this.editNode = editNodeTmp.value;
      let andIndex = this.editNode?.andIndex;
      let lookupId = this.editNode?.peopleAndLoansFieldLookupId;
      //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
      //this.loadByQueryKey(this.SelectedFilter?.FieldSubGroupName);


      let toIterate = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.
        filter(x => x.id == andIndex && x.peopleAndLoansFieldLookupId == lookupId);

      let lblValue = editNodeTmp?.value?.labelStr;
      toIterate[0].PeopleLoanListOrFilters.forEach((itm) => {
        // if (!lblValue) {
        //   lblValue = itm.displayHtml.substring(0, itm.displayHtml.indexOf("</b>"))
        //   lblValue = lblValue.replace("<b class=\"lhs\">", "").trim();
        // }
        let selectedDropdown = this.OperandDropDownList.find(x => x.PeopleAndLoansTypeDefLookupId == itm.peopleAndLoansTypeDefLookupId);

        //this.operandChangedNew(0);

        let validatorOrOpts = [
          Validators.required,
          Validators.maxLength(50)
        ];

        let selectedDropdown2 = [];
        let rhsValue = '';
        if (itm?.rhs?.length) {
          rhsValue = itm.rhs[0];
          if (rhsValue) {
            let rhsDropDownStr = rhsValue.toString().replace(/'/g, "");
            let arr1 = rhsDropDownStr.split(",");
            selectedDropdown2 = this.rhsDropdown.filter(x => arr1.findIndex(y => y == x.code) > -1);
          }
        }

        //conditional validation
        if (selectedDropdown.OpCode == 'NOTBLANK' || selectedDropdown.OpCode == 'BLANK') {
          validatorOrOpts = [];//no validation
        }

        formGroupArr.push(
          this.formBuilder.group({
            label: new FormControl(lblValue),
            operandDropDown: new FormControl(selectedDropdown),
            txtRHSValue: new FormControl(selectedDropdown2, validatorOrOpts)
            //txtRHSValue: new FormControl(rhsValue, validatorOrOpts)
          })
        );
      });
    }
    else {
      let selectedDropdown = null;
      if (this.SelectedFilter?.isAddFlag) {//insert as blank
        selectedDropdown = this.OperandDropDownList.find(x => x.OpCode == 'BLANK');
      }

      formGroupArr.push(
        this.formBuilder.group({
          label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
          operandDropDown: new FormControl(selectedDropdown),
          txtRHSValue: new FormControl('', [Validators.required])
        })
      );
    }
    return formGroupArr;
  }

  // private createItems(): FormGroup[] {
  //   const formGroupArr: FormGroup[] = [];
  //   formGroupArr.push(
  //     this.formBuilder.group({
  //       label: new FormControl(this.SelectedFilter?.label),
  //       operandDropDown: new FormControl({ value: '' }),
  //       txtRHSValue: new FormControl('', [Validators.required, Validators.maxLength(5)])
  //     })
  //   );
  //   return formGroupArr;
  // }

  get formDataCtrl(): FormArray {
    const formArray = this.textFormGroup?.get('items') as FormArray;
    return formArray;
  }

  deleteRow(index) {
    let k = this.textFormGroup?.get('items') as FormArray
    k.removeAt(index);
  }

  addMore() {
    let newRow = this.formBuilder.group({
      label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
      //operandDropDown: new FormControl({ value: '' }),
      operandDropDown: new FormControl('', [Validators.required]),
      txtRHSValue: new FormControl('', [Validators.required])
    });
    (<FormArray>this.textFormGroup?.get('items')).push(newRow);
  }

  validateAndEmitSuccessJson() {
    let len = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length;
    if (len) {
      if (this.editNode?.peopleAndLoansFieldLookupId) {//if it is edit
        let andIndexRemove =
          this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.findIndex(x =>
            x.id == this.editNode?.andIndex &&
            x.peopleAndLoansFieldLookupId == this.editNode.peopleAndLoansFieldLookupId);

        if (andIndexRemove !== -1) {
          this.peopleAndLoanFilters.PeopleLoanList.
            PeopleLoanListAndFilters.splice(andIndexRemove, 1);
        }
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = this.editNode?.andIndex;

      } else {
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = len + 1
      }

      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.forEach(ele => {
      //   if (ele.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
      //     ele.isDeleted = true;//set existing deleted.
      //   }
      // });
      // let tm = this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => x.isDeleted == false);

      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);

    }
    else {//add without any logic 
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);
    }
    this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];//reset 

    // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters =
    //   this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => !x.isDeleted);
    this.peopleAndLoanFilterOutput.isSuccess = true;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = this.peopleAndLoanFilters;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  emitFailureJson() {
    this.peopleAndLoanFilterOutput.isSuccess = false;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = null;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  cancelFilter() {
    this.onCancelFilterEvent.emit(true);
  }

  onListChange(event: any, index: number) {
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode) && !(event.value?.length)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }

      lookupControl?.updateValueAndValidity();//validate right now
      this.cdr.detectChanges();
    }
  }

  unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  setAndEmit() {
    if (this.selectedFilter) {
      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      //loop
      let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
      filter.id = (this.insertIndex + 1);
      filter.operator = this.selectedFilter.OpCode;
      filter.lhs = '';
      filter.rhs = [''];
      filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
      let criteriaQuery = this.selectedFilter.Criteria;
      if (criteriaQuery) {
        criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
        criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
        filter.criteria = criteriaQuery;
      }

      let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
      if (htmlTemplate) {
        htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
        htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
        let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
        filter.displayHtml = ' ' + htmlTemplate;
      }
      listAndFilters.PeopleLoanListOrFilters.push(filter);
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
      this.insertIndex++;
      this.validateAndEmitSuccessJson();
    }
  }

  callDropDownLoadedObservable() {
    let random = Math.floor(Math.random() * 1000);
    this.dropdownLoaded$.next(random);
  }


  ngOnDestroy(): void {
    this.selectedOperand = null;
    this.OperandDropDownList = null;
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.peopleAndLoanFilters.PeopleLoanList = new PeopleLoanList();//clean all json
    this.unSubscribeAllSubs();
  }

}
