import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettings } from 'src/appSettings';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { ContentLibraryOperation, ContentLibraryType } from '../../src/models/ContentLibraryModel';
import { RequestResponse } from 'src/models/RequestResponse';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class ContentLibraryService {
  private storageHelper: StorageHelper;
  private contentLibraryOperationSubject = new Subject<ContentLibraryOperation>();


  constructor(private http: HttpClient, private commonService: CommonService
  ) {
    this.storageHelper = new StorageHelper();
  }

  getContentLibraryData(requestType: ContentLibraryType, searchStr: string = '', hardReload: boolean = false): Observable<RequestResponse> {
    let cacheKey = "CLib_" + this.commonService.stringToHash(requestType);
    if (hardReload) { // if any operation happened than clear the cache.
      this.storageHelper.ClearStorageByKey(cacheKey);
    }
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      let request = 'searchValue=' + searchStr + '&requestType=' + requestType;
      const baseUrl = `${AppSettings.API_GET_ContentLibrary}/GetContentLibraryData?` + request;
      return this.http.get<RequestResponse>(baseUrl)
        .pipe(tap(x => {
          // if ((requestType == ContentLibraryType.AduvoQuickStart || requestType == ContentLibraryType.AduvoRecommended) &&
          //   x && x.data && x.status == 200) {
          //   this.storageHelper.SetDataInStorage(cacheKey, x, 0, 5);//set for 5 minutes
          // }
          if (x && x.data && x.status == 200) {
            this.storageHelper.SetDataInStorage(cacheKey, x, 0, 5);//set for 5 minutes
          }
        }), catchError(this.handleError));
    }
  }

  getContentDataByFilter(requestResponse: RequestResponse): Observable<RequestResponse> {
    let orderBy: string = requestResponse?.paging?.orderBy ?? '';
    if (orderBy) {
      let orderDetails = orderBy.split(' ');
      if (orderDetails.length > 0) {
        orderBy =
          '&sortColumn=' + orderDetails[0] + '&sortOrder=' + orderDetails[1];
      }
    }

    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      orderBy: requestResponse?.paging?.orderBy ?? '',
      filterJSON: requestResponse?.searchInfo ?? ''
    }

    return this.http.post<RequestResponse>(AppSettings.API_GET_ContentLibrary + "/GetListByFilterAsync", searchModel)
      .pipe(catchError(this.handleError));

  }


  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }


  getContentLibraryOwnerFilter(requestResponse: RequestResponse){

    let searchModel = {
      filterJSON: requestResponse?.searchInfo ?? ''
    }

    return this.http.post<RequestResponse>(AppSettings.API_GET_ContentLibrary + "/GetOwnerFilters", searchModel)
      .pipe(catchError(this.handleError));
  }

  getContentLibraryRecipientFilter(requestResponse: RequestResponse){

    let searchModel = {
      filterJSON: requestResponse?.searchInfo ?? ''
    }

    return this.http.post<RequestResponse>(AppSettings.API_GET_ContentLibrary + "/GetRecipientFilters", searchModel)
      .pipe(catchError(this.handleError));
  }

  setContentLibraryOperation(contentLibraryOperation: ContentLibraryOperation) {
    this.contentLibraryOperationSubject.next(contentLibraryOperation);
  }

  getContentLibraryOperation(): Observable<ContentLibraryOperation> {
    return this.contentLibraryOperationSubject.asObservable();
  }


  getConfirmedUserList(standardUserWithMarketing, currentClientID): Observable<RequestResponse> {
    if (standardUserWithMarketing || currentClientID == 'AduvoAdmin') {
      return of(null);
    }

    let cacheKey = "userList_ContentLib";
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      const baseUrl = `${AppSettings.API_GET_ContentLibrary}/GetConfirmedUserList`;
      return this.http.get<RequestResponse>(baseUrl)
        .pipe(tap(x => {
          if (x && x.data && x.status == 200) {
            this.storageHelper.SetDataInStorage(cacheKey, x, 5);//set for 5 hours
          }
        }), catchError(this.handleError));
    }
  }

  public cleanContentLibraryCache(cacheNames: string[]) {
    cacheNames.forEach(name => {
      //let cacheKey1 = "CLib_" + this.commonService.stringToHash(ContentLibraryType.AduvoQuickStart);
      let cacheKey = "CLib_" + this.commonService.stringToHash(name);
      this.storageHelper.ClearStorageByKey(cacheKey);
    });
  }

}

