import { PagingData } from './PagingData';

/**
* Implementation of the FocusIT.Common.Web.IO.RequestResponse [DataContract].
*/
export class RequestResponse {

  public paging: PagingData = new PagingData();
  public pageInfo: PagingData = new PagingData();

  public data: any;
  public message: string;

  public searchInfo: string;
  
  public status: number;

  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  *
  * @param paging
  * @param data
  */
  constructor(
    paging?: PagingData,
    pageInfo?: PagingData,
    data?: any,
    message?: string,
    searchInfo?: string) {
      if ((paging !== undefined) && (paging !== null)) this.paging = paging;
      if ((pageInfo !== undefined) && (pageInfo !== null)) this.pageInfo = pageInfo;
      this.data = data;
      if ((message !== undefined) && (message !== null)) this.message = message;
      if(searchInfo) this.searchInfo=searchInfo;
  }
}
