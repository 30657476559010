import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IContactTagVM } from 'src/app/models/ContactTagVM';
import { AppSettings } from 'src/appSettings';
import { ICompany } from 'src/models/Comapny';
import { NGENMasterContact, NGENMasterContactCollection } from 'src/models/NGENMasterContact';
import { NGENTeam } from 'src/models/NGENTeam';
import { RequestResponse } from 'src/models/RequestResponse';
 
@Injectable()
export class ContactsService {

  lastImage: any = null;

  constructor(private http: HttpClient) { }


  // ********************************************************************************
  // WEB API Methods
  // ********************************************************************************

  /** 
   * Retrieves all necessary data for this component. 
   */
  async getData(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /POST in the webapi Contacts Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS, requestResponse)
        .pipe(catchError(this.handleError)).toPromise();
  } 

  /** 
   * Creates a Contact. 
   */
  async Create(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /Create in the webapi Contacts Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS + "/Create", requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Creates many Contacts from contact upload. 
   */
  async CreateBulk(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /BulkCreate in the webapi Contacts Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS + "/BulkCreate", requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Saves a Contact. 
   */
  async Save(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /Save in the webapi Contacts Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS + "/Save", requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Retrieve the collection of potential Owners for new Contact Creation.
   */
  async GetOwners(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /GetOwners in the webapi Contacts Controller");
    //todo: Gaurav: not sure why we need this. So commenting for now
    
    // return this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS + "/GetOwners", requestResponse)
    //   .pipe(catchError(this.handleError)).toPromise();
    requestResponse.data="[]";
    return requestResponse;
  }

  /** 
   * Updates the Enabled (Opted In/Out indicator) column for a record in the NGENMasterContact table.
   */
  async UpdateEnabled(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /UpdateEnabled in the webapi Contacts Controller");
    return this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS + "/UpdateEnabled", requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Retrieves the Contact Information for the currently logged on user.
   */
  async GetLoggedOnUser() {

    console.info("Call /GetLoggedOnUser in the webapi Contacts Controller");
    return await this.http.get<RequestResponse>(AppSettings.API_GET_CONTACTS + "/GetLoggedOnUser")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
  * Retrieves a PulseUser contact by user name.
  * @param username.
  */
  async GetByUsername(username: string) {
    return await this.http.get<NGENMasterContact>(AppSettings.API_GET_CONTACTS + "/GetByUsername?username=" + username)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
  * Retrieves a Contact by ContactId.
  * @param contactId.
  */
  async GetByContactId(contactId: string) {
    return await this.http.get<NGENMasterContact>(AppSettings.API_GET_CONTACTS + "/GetByContactId?contactId=" + contactId)
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetByMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId)
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetCompanyByMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Company ")
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetLatestLoanByMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId+ "/LatestLoanInformation")
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetCompanyLoanStatisticsByMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId+ "/Company/LoanStatistics")
      .pipe(catchError(this.handleError)).toPromise();
  }

  async PutByMasterContactId(masterContactId: string, email: string, mobilePhone: string, workPhone: string, homePhone: string, 
    dateOfBirth: string, gender: string, veteran: number | null, firstName: string, lastName: string) {
    return await this.http.put<any>(AppSettings.API_CONTACTS + "/" + masterContactId,{
      email,
      mobilePhone,
      workPhone,
      homePhone,
      dateOfBirth,
      gender,
      veteran,
      firstName,
      lastName
    })
      .pipe(catchError(this.handleError)).toPromise();
  }

  async PutCompanyByTenantId(tenantId: string, data: ICompany){
    return await this.http.put<any>(AppSettings.API_CONTACTS + "/Company/" + tenantId,data)
    .pipe(catchError(this.handleError)).toPromise();
  }

  async GetMessagesForMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Messages")
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetMessagesStatisticsForMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Messages/Statistics")
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetEmailStatusForMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Email/Status")
      .pipe(catchError(this.handleError)).toPromise();
  }

  async PatchToggleEmailStatusMasterContactId(masterContactId: string) {
    return await this.http.patch<any>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Email/Status/Toggle",{})
      .pipe(catchError(this.handleError)).toPromise();
  }

  async GetSmsStatusForMasterContactId(masterContactId: string) {
    return await this.http.get<any>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Sms/Status")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
  * Retrieves the teams the user is in.
  * @param username The username.
  */
  async GetContactTeamAssignments(username: string) {
    return await this.http.get<NGENTeam[]>(AppSettings.API_GET_CONTACTS + "/GetContactTeamAssignments?username=" + username)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Update a user's password. 
   */
  async UpdatePassword(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /UpdatePassword in the webapi Accounts Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_CONTACTS + "/UpdatePassword", requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /**
  * Error processing.
  * 
  * @param caught The caught error information.
  */
  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }

  // ********************************************************************************
  // Helper Methods
  // ********************************************************************************

  /**
   * Combines the specified first and last names and sets the contact's Name property.
   *
   * @param contact
   * @param firstName
   * @param lastName
   * @param middleName Only relevant when First and Last names have been specified as well.
   */
  setName(contact: NGENMasterContact, firstName: string, lastName: string, middleName?: string) {
    // No NGENMasterContact
    if ((contact == undefined) || (contact == null))
      return;

    // No First and Last Name
    if (((firstName == undefined) || (firstName == null)) &&
      ((lastName == undefined) || (lastName == null)))
      return;

    // Last Name only
    if ((firstName == undefined) || (firstName == null)) {
      contact.Name = lastName;
      return;
    }

    // First Name only
    if ((lastName == undefined) || (lastName == null)) {
      contact.Name = firstName;
      return;
    }

    // current middle name
    var middle = '';
    // set to current value if we have one
    if ((contact.MiddleName != undefined) && (contact.MiddleName != null)) {
      middle = contact.MiddleName;
    }

    // Middle Name passed, use it
    if ((middleName != undefined) && (middleName != null)) {
      middle = middleName;
    }

    // put the name together
    contact.Name = firstName;
    if (middle.length > 0)
      contact.Name = contact.Name + ' ' + middle;
    if (lastName.length > 0)
      contact.Name = contact.Name + ' ' + lastName;
  }

  ///**
  // * Parses the contact's Name and sets the FirstName and LastName properties.
  // * 
  // * @param contact
  // */
  //setFirstAndLastName(contact: NGENMasterContact) {
  //  // No NGENMasterContact
  //  if ((contact == undefined) || (contact == null))
  //    return;

  //  // No Name
  //  if ((contact.Name == undefined) || (contact.Name == null)) {
  //    return;
  //  }

  //  // Compute First and Last Name properties
  //  try {
  //    if (contact.Name.length > 0) {
  //      var n = contact.Name.indexOf(" ");
  //      if (n > 0) {
  //        contact.FirstName = contact.Name.substring(0, n);
  //        contact.LastName = contact.Name.substring(n + 1);
  //      }
  //    }
  //  }
  //  catch { }
  //}

  toCollectionInstance(data: any[]) {
    let result: NGENMasterContactCollection = new NGENMasterContactCollection();
    for (let x = 0; x < data.length; x++)
      result.Items.push(this.toInstance(data[x]));
    return result;
  }

  toInstance(data: any) {
    let result = new NGENMasterContact(
      data.TenantId,
      data.ContactTypes,
      data.Name,
      data.Email,
      data.MobilePhone,
      data.WorkPhone,
      data.Fax,
      data.HomePhone,
      data.Street,
      data.City,
      data.State,
      data.Zip,
      data.BirthDate,
      data.SSN,
      data.Veteran,
      data.Signature,
      data.Picture,
      data.ReferralSource,
      data.Enabled,
      data.LoanOriginationSystem,
      data.CreatedDateUTC,
      data.LastModifiedDateUTC,
      data.Hash,
      data.Username,
      data.Password,
      data.Permissions,
      data.Owner,
      data.AccountVerified,
      data.FirstName,
      data.LastName,
      data.MiddleName,
      data.DisplayContactType,
      data.CompanyName,
      data.UserSettings,
      data.NameSuffix,
      data.Gender,
      data.StateLicenses,
      data.NmlsId,
      data.FacebookURL,
      data.TwitterURL,
      data.LinkedInURL,
      data.LeadIntakeURL,
      data.LeadIntakeDescription,
      data.ContactId
    );
    return result;
  }

  GetContactTagsByMasterContactId(masterContactId: string) {
    return this.http.get<Array<IContactTagVM>>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Tags")
      .pipe(catchError(this.handleError)).toPromise();
  }

  PutContactTagsByMasterContactId(masterContactId: string, tags: Array<string>) {
    return this.http.put<Array<IContactTagVM>>(AppSettings.API_CONTACTS + "/" + masterContactId + "/Tags", tags)
      .pipe(catchError(this.handleError)).toPromise();
  }

}
