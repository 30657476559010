<div>
  <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
  <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div>
  <div style="height:1rem;"></div>

  <div id="textMessagePreviewDialogDiv" class="content" role="main" style="padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 1rem;">
    <div class="cellPhoneDiv">
      <textarea pInputTextarea class="cellPhoneText" readonly="readonly">{{messageText}}</textarea>
    </div>
  </div>
</div>
