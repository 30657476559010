import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettings } from 'src/appSettings';
import { RequestResponse } from 'src/models/RequestResponse';

@Injectable()
export class NotificationService {

  constructor(private http: HttpClient) {
  }

  GetActivity() {
    console.info('Call /getactivity in the webapi notificationController');
    return this.http.get<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/getactivity')
          .pipe(catchError(this.handleError));
  }

  GetNotification() {
    console.info('Call /notification in the webapi notificationController');
    return this.http.get<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/getnotification')
          .pipe(catchError(this.handleError));
  }

  async GetAllNotificationList(requestResponse?: RequestResponse) {
    console.info('Call /post All list in the webapi notificationController');
    if (requestResponse === undefined || requestResponse === null) {
      requestResponse = new RequestResponse();
    }
    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      searchValue: requestResponse.data?.length > 0 ? requestResponse.data : '',
      orderBy: requestResponse.paging.orderBy,
    };
    return await this.http.post<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/allNotificationList', searchModel)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  async UpdateNotification() {
    console.info('Call /put in the webapi notificationController');
    return await this.http.put<any>(AppSettings.API_GET_NOTIFICATION, null)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  async DeleteNotification(notificationId: string) {
    console.info('Call /delete in the webapi notificationController');
    return await this.http.delete<any>(AppSettings.API_GET_NOTIFICATION + '/' + notificationId)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  async CreateNotification(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /addnotification in the webapi notificationController');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/addnotification', requestResponse)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  async AddCompany(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /createtenant in the webapi notificationController');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/createtenant', requestResponse)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  async EditCompany(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /updatetenant in the webapi notificationController');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_NOTIFICATION + '/updatetenant', requestResponse)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  async DownloadCSVErrorFile(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /downloadCSVErrorFile in the webapi notificationController');
    return await this.http.post<any>(AppSettings.API_GET_NOTIFICATION + '/downloadCSVErrorFile', requestResponse)
          .pipe(catchError(this.handleError))
          .toPromise();
  }

  private handleError(caught: any) {
    console.error(caught?.error?.message);
    return throwError(caught?.error?.message ?? 'Error while connecting to server!');
  }
}
