import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-sms-body-textarea',
  templateUrl: './sms-body-textarea.component.html',
  styleUrls: ['./sms-body-textarea.component.scss']
})
export class SmsBodyTextareaComponent implements OnInit {
  @Input() smsBodyForm: AbstractControl;
  @Input() index: number;
  @Output() onEmojiClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMergeTagRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImageRequested: EventEmitter<any> = new EventEmitter<any>();
  @Output() onValueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  isEmojiMartVisible = false;

  form: FormGroup;
  readonly smsMaxLength: number = 160;

  constructor(private toastService: ToastService) { }

  ngOnInit(): void {
    this.form = this.smsBodyForm as FormGroup;
    this.form.valueChanges.pipe(
      debounceTime(1000),//delay and  stop unnecessary propagation.
      distinctUntilChanged()
    ).subscribe(x => {
      //console.log('change2');
      this.onValueChanged.emit(true);
    });

    // this.form.valueChanges.subscribe(x => { 
    //   if (x?.body?.trim().length > 0) {
    //     this.hasValueInTextBox.emit(true);
    //   }else{
    //     this.hasValueInTextBox.emit(false);
    //   }
    // });
  }
  textAreaEmpty() {
    if (this.form.controls.body.value.trim() == '') {
      this.form.setValue({ body: '' });
    }
  }
  get parentForm() {
    return this.form.parent as FormArray;
  }

  emojiClicked($event: any) {
    let emojiLength = $event?.emoji?.native?.length ?? 0;
    if (this.form.controls['body'].value.length + emojiLength > this.smsMaxLength) {
      this.toastService.showError('Max character limit has been reached for SMS Body.');
      return;
    }
    this.onEmojiClick.emit({ emoji: $event.emoji.native, index: this.index });
  }

  // getMaxLength() {
  //   const smsBody = this.form.parent.value;
  //   return this.getLength(smsBody, this.index);
  // }

  // getLength(smsBody, index) {
  //   switch (index) {
  //     case 0: {
  //       if (smsBody.length === 1) {
  //         return 144;
  //       } else {
  //         return 160;
  //       }
  //     }
  //     case 1: {
  //       if (smsBody.length === 2) {
  //         return 144;
  //       } else {
  //         return 160;
  //       }
  //     }
  //     case 2: {
  //       if (smsBody.length === 3) {
  //         return 144;
  //       } else {
  //         return 160;
  //       }
  //     }
  //   }
  // }

  deleteInput() {
    //console.log(this.form);
    const t = this.form.parent as FormArray;
    t.removeAt(this.index);
  }
}
