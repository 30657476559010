export enum ContentLibraryType {
 MyContent = "MyContent",
 ActiveContent = "ActiveContent",
 AduvoQuickStart = "AduvoQuickStart",
 AduvoRecommended = "AduvoRecommended",
 test = "test"
}

export class ContentLibraryModel {
 public id: string;
 public isCampaign: boolean;
 public title: string;
 public description: string;
 public msgCount: number;
 public htmlImageSrc: string;
 public templateTypeCount: number;
 public published: boolean;
 public recipients: string;
 public sender: string;
 public updatedDateUtc: string;
 public updatedDateUtc_Converted: Date;
 public ownerImageSrc: string;
 public ownedBy: string;
 public isPublishDB: boolean;
 public activationMode: string;
 public activatedByTenantUserId: string;
 public requestType: string;//not from db
 public aduvoFlag: string;//aduvo-content/quickstart/recommended 
 public isDummy: boolean;//aduvo-content/quickstart/recommended 
 public ownerIntials: string;
 constructor() {
  this.id = "";
  this.title = "";
  this.description = "";
  this.aduvoFlag = '';
  this.isDummy = false;
 }
}

export class ContentLibraryOperation {
 constructor(public requestType: string, public hardReload: boolean, public changedId: string = '') {

 }
}

export class ContentLibraryFilterModel {
 public ownerID: string;
 //
 public aduvoContent: boolean;
 public myContent: boolean;
 public activeContent: boolean;
 public companyContent: boolean;
 //category
 public categoryCSV: string;
 //Featured
 public aduvoQuickStart: boolean;
 public aduvoRecommended: boolean;
 //boolean
 public singleMessage: boolean;
 public campaign: boolean;
 //Message Type
 public email: boolean;
 public textMessage: boolean;
 //Campaign Status
 public active: boolean;
 public inactive: boolean;

 public recipients: string;
 public sender: string;

 public searchStr: string;
 public orderBy: string;
 public senderCustomAddress: string;

 public isCalledFromWizard: number;//called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4
 public campaignID: string;// in case of aad and replace only
 public campaignMessageId: string;// in case of aad and replace only

 //public isReplaceOrAdd: number;
 public messageTypeToSend: string;

 constructor() {
  this.ownerID = '';
  this.categoryCSV = '';
  this.recipients = '';
  this.sender = '';
  this.searchStr = '';
  this.orderBy = '';
  this.myContent = false;
  this.senderCustomAddress = '';
  this.activeContent = false;
  this.aduvoContent = false;
  this.companyContent = false;
  this.isCalledFromWizard = 0;//called from create wizard 1 / Message Selection 2/ Replace Message 3/Add Message From Editor 4
  // this.isReplaceOrAdd = 0;
  this.messageTypeToSend = '';
  this.campaignID = null;
  this.campaignMessageId = null;
 }
}
