import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { campaignManagementComponent } from '../components/marketing/campaigns/campaignManagement.component';
import { designLibraryComponent } from '../components/marketing/designlibrary/designLibrary.component';
import { imageLibraryComponent } from '../components/marketing/designlibrary/imageLibrary.component';
import { messageTemplateEditorComponent } from 'src/components/marketing/designlibrary/messageTemplateEditor.component';
import { Dashboard } from '../components/dashboard/dashboard.component';
import { contactsComponent } from '../components/contacts/contacts.component';
import { campaignEditorComponent } from '../components/marketing/campaigns/campaignEditor.component';
import { NotFoundComponent } from '../components/errors/NotFound/not-found.component';
import { userManagementComponent } from '../components/accounts/usermanagement/userManagement.component';
import { AuthorizationFailedComponent } from 'src/components/errors/Authorization/AuthorizationFailed.component';
import { textMessageEditorComponent } from '../components/marketing/designlibrary/textMessageEditor.component';
import { SubmitATicketComponent } from '../screens/submit-a-ticket/submit-a-ticket.component';
import { OnboardingComponent } from './onboarding/onboarding/onboarding.component';
import { BookTimeComponent } from './onboarding/book-time/book-time.component';
import { CarrierFormComponent } from './onboarding/carrier-form/carrier-form.component';
import { WelcomeFormComponent } from './onboarding/welcome-form/welcome-form.component';
import { SendQueryComponent } from './onboarding/send-query/send-query.component';
import { CtaScreenComponent } from './onboarding/cta-screen/cta-screen.component';
import { AddAdminComponent } from './onboarding/add-admin/add-admin.component';
import { PermissionCollectComponent } from './onboarding/permission-collect/permission-collect.component';
import { FirstScreenComponent } from './onboarding/first-screen/first-screen.component';
import { OptInComponent } from './onboarding/opt-in/opt-in.component';
import { OptInEmailComponent } from './onboarding/opt-in-email/opt-in-email.component';
import { AddIndividualComponent } from './onboarding/add-individual/add-individual.component';
import { SetUpAccountComponent } from './onboarding/set-up-account/set-up-account.component';
import { AduvoAdminGuard } from './aduvo-admin.guard';
import { IronCladGuard } from './iron-clad.guard';
import { ClickWrapComponent } from './click-wrap/click-wrap.component';
import { userProfileComponent } from './usermanagement/userProfile.component';
import { userEditorComponent } from './usermanagement/userEditor.component';
import { CreateCampaignWizardComponent } from '../components/marketing/campaigns/create-campaign-wizard/create-campaign-wizard.component';
import { ContentLibraryFilterComponent } from 'src/components/marketing/campaigns/content-library-filter/content-library-filter.component';
import { ContentLibraryComponent } from 'src/components/marketing/campaigns/content-library/content-library.component';
import { SelectPlanComponent } from './onboarding/select-plan/select-plan.component';
import { DashboardAnalytics } from 'src/components/dashboard/analytics/analytics.component';
import { LoadingComponent } from 'src/components/errors/Loading/loading.component';
import { ClientManagerComponent } from 'src/components/accounts/client-manager/client-manager.component';
import { ClientEditorComponent } from 'src/components/accounts/client-editor/client-editor.component';

const routes: Routes = [
  {
    path: '',
    component: LoadingComponent,
    canActivate: [IronCladGuard]
  },
  {
    path: 'dashboard',
    component: Dashboard,
    canActivate: [IronCladGuard]
  },
  {
    path: 'marketing/campaigns',
    component: campaignManagementComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/campaigns/create/new',
    component: CreateCampaignWizardComponent,
    canActivate: [IronCladGuard],
    runGuardsAndResolvers: 'always',
  }, //build your own
  {
    path: 'marketing/campaigns/create/new/:listId',
    component: CreateCampaignWizardComponent,
    canActivate: [IronCladGuard],
    runGuardsAndResolvers: 'always',
  }, //build your own
  {
    path: 'marketing/campaigns/edit/:id/:type/:id2',
    component: campaignEditorComponent,
    canActivate: [IronCladGuard],
  }, //build your own
  {
    path: 'marketing/campaigns/edit/:id/:type',
    component: campaignEditorComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/MyContent',
    component: ContentLibraryFilterComponent,
    data: { id: 'mycontent' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/ActiveContent',
    component: ContentLibraryFilterComponent,
    data: { id: 'activecontent' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/AduvoQuickStart',
    component: ContentLibraryFilterComponent,
    data: { id: 'aduvoquickstart' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/AduvoRecommended',
    component: ContentLibraryFilterComponent,
    data: { id: 'aduvorecommended' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/All',
    component: ContentLibraryFilterComponent,
    data: { id: 'all' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/Explore',
    component: ContentLibraryFilterComponent,
    data: { id: 'explore' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library-filter/Category',
    component: ContentLibraryFilterComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-library',
    component: ContentLibraryFilterComponent,
    data: { id: 'all' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'people-and-loans/person',
    loadChildren: () =>
      import('../app/person/person.module').then(
        (x) => x.PersonModule
      ),
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/all-contacts',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 0 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/active-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 1 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/active-leads',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 10 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/closed-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 2 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/birthdays-this-week',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 3 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/loans-anniversaries-this-week',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 4 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/purchase-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 5 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/other-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 6 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/cancelled-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 7 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/refi-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 8 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/lists',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans-list.module').then(
        (x) => x.PeopleAndLoansListModule
      ),
    data: { id: 9 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/application-phase-loans',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 11 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/converted-from-lead-to-loan',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 12 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'people-and-loans/:id',
    loadChildren: () =>
      import('../app/people-and-loans/people-and-loans.module').then(
        (x) => x.PeopleAndLoansModule
      ),
    data: { id: 100 },
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'account/my-profile',
    loadChildren: () =>
      import('../app/usermanagement/userProfile.module').then(
        (x) => x.UserProfileModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/change-password',
    loadChildren: () =>
      import('../app/usermanagement/changePassword.module').then(
        (x) => x.ChangePasswordModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/manage-users/add',
    loadChildren: () =>
      import('../app/usermanagement/userEditor.module').then(
        (x) => x.UserEditorModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/manage-users/edit',
    loadChildren: () =>
      import('../app/usermanagement/userEditor.module').then(
        (x) => x.UserEditorModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/my-profile',
    component: userProfileComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/manage-users/add',
    component: userEditorComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/manage-users/edit',
    component: userEditorComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'welcome-form',
    component: WelcomeFormComponent
  },
  {
    path: 'send-query',
    component: SendQueryComponent
  },
  {
    path: 'analytics-dashboard',
    component: DashboardAnalytics
  },
  {
    path: 'cta-screen',
    component: CtaScreenComponent
  },
  {
    path: 'carrier-form',
    component: CarrierFormComponent
  },
  {
    path: 'book-time',
    component: BookTimeComponent
  },
  {
    path: 'select-plan',
    component: SelectPlanComponent
  },
  {
    path: 'add-admin',
    component: AddAdminComponent
  },
  {
    path: 'permit-collect',
    component: PermissionCollectComponent,
  },
  {
    path: 'welcome-screen',
    component: FirstScreenComponent
  },
  {
    path: 'opt-in',
    component: OptInComponent
  },
  {
    path: 'opt-in-email',
    component: OptInEmailComponent
  },
  {
    path: 'set-up-account',
    component: SetUpAccountComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'add-individual',
    component: AddIndividualComponent
  },
  {
    path: 'subscribe-text',
    loadChildren: () =>
      import('../app/onboarding/subscription-text/subscription-text.module').then(
        (x) => x.SubscriptionTextModule
      ),
  },
  {
    path: 'account/manage-users',
    component: userManagementComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/client-manager',
    component: ClientManagerComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'account/client-editor/:tenantId',
    component: ClientEditorComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'company-profile',
    loadChildren: () =>
      import('../app/companyprofile/companyProfile.module').then(
        (x) => x.CompanyProfileModule
      ),
    canActivate: [AduvoAdminGuard, IronCladGuard],
  },
  {
    path: 'company-profile/add',
    loadChildren: () =>
      import('../app/companyprofile/addCompanyProfile.module').then(
        (x) => x.AddCompanyProfileModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/image-library',
    component: imageLibraryComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/content-image-library',
    loadChildren: () =>
      import('../app/imagelibrary/imageLibrary.module').then(
        (m) => m.ImageLibraryModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/template-library',
    component: designLibraryComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'notauthorized',
    component: AuthorizationFailedComponent
  },
  {
    path: 'marketing/template-library/email',
    component: messageTemplateEditorComponent,
    data: { operation: 'email' },
    canActivate: [IronCladGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'marketing/template-library/sms/:cmpid/:type',
    component: textMessageEditorComponent,
    data: { operation: 'sms' },
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/template-library/sms',
    component: textMessageEditorComponent,
    data: { operation: 'sms' },
    canActivate: [IronCladGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'marketing/template-library/:id',
    component: messageTemplateEditorComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/template-library/edit/:id',
    component: messageTemplateEditorComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'submit-a-ticket',
    component: SubmitATicketComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/contact-events',
    loadChildren: () =>
      import('../app/calendarevent/calendarevent.module').then(
        (m) => m.CalendareventModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'marketing/holidays',
    loadChildren: () =>
      import('../app/calendarevent/calendarevent.module').then(
        (m) => m.CalendareventModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: 'submit-a-ticket',
    component: SubmitATicketComponent,
    canActivate: [IronCladGuard],
  },
  {
    path: 'clickwrap',
    component: ClickWrapComponent
  },
  {
    path: 'notificationCenter',
    loadChildren: () =>
      import('../app/notificationCenter/notificationCenter.module').then(
        (x) => x.NotificationCenterModule
      ),
    canActivate: [IronCladGuard],
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top', enableTracing: false })],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule { }
export const ArrayOfComponents = [campaignManagementComponent, contactsComponent, Dashboard, imageLibraryComponent, designLibraryComponent];
