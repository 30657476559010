import { Datetime } from "src/models/Datetime";

export class EmailStats {
  public Emails: EmailItem[] = [];

  constructor(data: string) {
    var obj = JSON.parse(data);

    if (!obj.hasOwnProperty('Emails'))
      return;

    for (var i = 0; i < obj.Emails.length; i++) {
      this.Emails.push(EmailItem.fromDynamic(obj.Emails[i]));
    }
  }

  GetEmailsForUser(userId: string): EmailItem[] {
    var returnValue: EmailItem[];

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].SenderID === userId) returnValue.push(this.Emails[i]);
    }
      
    return returnValue;
  }

  GetEmailsForMessage(messageId: string): EmailItem[] {
    var returnValue: EmailItem[];

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].MessageID === messageId) returnValue.push(this.Emails[i]);
    }

    return returnValue;
  }

  GetDelivered(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].Delivered) count++;
    }

    return count;
  }

  GetUndelivered(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (!this.Emails[i].Delivered) count++;
    }

    return count;
  }

  GetOpened(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].Opened) count++;
    }

    return count;
  }

  GetUnopened(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (!this.Emails[i].Opened) count++;
    }

    return count;
  }

  GetClicked(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].Clicked) count++;
    }

    return count;
  }

  GetUnclicked(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (!this.Emails[i].Clicked) count++;
    }

    return count;
  }
  GetSent(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].SendDate!=null) count++;
    }

    return count;
  }
  GetSpamReported(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].SpamReported) count++;
    }

    return count;
  }

  GetNotSpamReported(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (!this.Emails[i].SpamReported) count++;
    }

    return count;
  }

  GetOptedOut(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (this.Emails[i].OptedOut) count++;
    }

    return count;
  }

  GetNotOptedOut(): number {
    var count: number = 0;

    for (var i = 0; i < this.Emails.length; i++) {
      if (!this.Emails[i].OptedOut) count++;
    }

    return count;
  }

  GetTotalEmails():number{
    return this.Emails.length;
  }
}

class EmailItem {
  public CampaignID: string = '00000000-0000-0000-0000-000000000000';
  public CustomerID: string = '00000000-0000-0000-0000-000000000000';
  public SenderID: string = '00000000-0000-0000-0000-000000000000';
  public MessageID: string = '00000000-0000-0000-0000-000000000000';
  public UniqueID: string = '00000000-0000-0000-0000-000000000000';
  public To: string = '';
  public From: string = '';
  public Body: string = '';
  public Subject: string = '';
  public SendDate: Datetime;
  public Opened: boolean = false;
  public Clicked: boolean = false;
  public SpamReported: boolean = false;
  public Delivered: boolean = false;
  public OptedOut: boolean = false;

  constructor(campaignId: string, customerId: string, senderId: string, messageId: string,
    uniqueId: string, to: string, from: string, body: string, subject: string,
    sendDate: string, opened: boolean, clicked: boolean, spamReported: boolean, delivered: boolean, optedOut: boolean) {

    this.CampaignID = campaignId;
    this.CustomerID = customerId;
    this.SenderID = senderId;
    this.MessageID = messageId;
    this.UniqueID = uniqueId;
    this.To = to;
    this.From = from;
    this.Body = body;
    this.Subject = subject;
    this.Opened = opened;
    this.Clicked = clicked;
    this.SpamReported = spamReported;
    this.Delivered = delivered;
    this.OptedOut = optedOut;
  }

  public static fromDynamic(email: any) {
    return new EmailItem(email.CampaignID, email.CustomerID, email.SenderID, email.MessageID,
                          email.UniqueID, email.To, email.From, email.Body, email.Subject,
                          email.SendDate, email.Opened, email.Clicked, email.SpamReported,
                          email.Delivered, email.OptedOut);
  }
}
