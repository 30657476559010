<div class="message-editor-main">
  <div class="content table-search-row second-header-fixed">
    <div class="text-wrapper">
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header title-main-26">
          <label *ngIf="
              selectedStepIndex == 0 && editorState?.messageTemplate?.title
            ">{{ editorState?.messageTemplate?.title }}</label>
          <label *ngIf="
              selectedStepIndex == 0 && !editorState?.messageTemplate?.title
            ">Create New Text Message</label>
          <label *ngIf="selectedStepIndex == 1">{{
            editorState?.messageTemplate?.title
            }}</label>
        </div>
        <div class="d-flex justify-content-between align-items-center fs-5">
          <div *ngIf="
              selectedStepIndex == 1 &&
              currentClientID === 'AduvoAdmin' &&
              editorState.messageTemplate.published
            " class="d-flex align-items-center" [style]="{ 'margin-top': '8px' }">
            <div class="me-3 mb-1 text-dark">Active</div>
            <p-inputSwitch [(ngModel)]="editorState.messageTemplate.published"
              (onChange)="onPublishChange(editorState.messageTemplate)"></p-inputSwitch>
          </div>
          <div *ngIf="
              selectedStepIndex == 1 &&
              currentClientID === 'AduvoAdmin' &&
              !editorState.messageTemplate.published
            " class="d-flex align-items-center" [style]="{ 'margin-top': '8px' }">
            <div class="me-3 mb-1 text-dark">Inactive</div>
            <p-inputSwitch [(ngModel)]="editorState.messageTemplate.published"
              (onChange)="onPublishChange(editorState.messageTemplate)"></p-inputSwitch>
          </div>
          <span role="button" class="font-size-16" (click)="onSubmitStepFirst()"
            [ngClass]="[selectedStepIndex == 0 ? 'text-dark fw-bold' : '']">
            Details
          </span>
          <i class="pi pi-angle-right mx-2"></i>
          <div class="d-flex justify-content-between align-items-center"
            [ngClass]="[selectedStepIndex == 1 ? 'text-dark fw-bold' : '']">
            <div role="button" (click)="onSubmitStepSecond()" class="mx-2 font-size-16">
              Content
            </div>
            <div>
              <button *ngIf="selectedStepIndex == 1" type="button" class="ms-5" pButton="pButton" label="Save"
                (click)="onSave()"></button>
              <button *ngIf="selectedStepIndex == 1" type="button" class="p-button-secondary" pButton="pButton"
                label="Exit" (click)="onExit()"
                [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="selectedStepIndex != 0">
    <app-new-text-message-editor #newTextMessageEditor [selectedNewTextMessage]="selectedNewTextMessageClone"
      [stepIndex]="selectedStepIndex" (onSaveNewTextMessageClick)="onSaveNewTextMessageEvent()"
      (onCancelNewTextMessageClick)="onTextMessageExit()" (onValueChanged)="onValueChanged()">
    </app-new-text-message-editor>
  </div>

  <div [hidden]="selectedStepIndex != 1" class="text-wrapper grid-section_new">
    <div class="text-wrapper" style="text-align: right;">
      <!-- <span *ngIf="isUndoEnable==true"
        title="undo"
        id="btnUndo"
        class="p-column-filter-menu-button"
        (click)="onUndoClicked()"
        style="cursor: pointer; box-shadow: unset;color: blue;"
      >
        <i class="pi pi-replay"></i>
          &nbsp;Undo
      </span>
      <span *ngIf="isUndoEnable==false"
        title="undo"
        id="btnUndo"
        class="p-column-filter-menu-button"
        style="color:lightgray; box-shadow: unset;"
      >
        <i class="pi pi-replay"></i>
          &nbsp;Undo
      </span>
       &nbsp;| &nbsp;
      <span *ngIf="isRedoEnable==true"
        title="redo"
        id="btnRedo"
        class="p-column-filter-menu-button"
        (click)="onRedoClicked()"
        style="cursor: pointer; box-shadow: unset;color: blue;"
      >
        <i class="pi pi-refresh"></i>
        &nbsp;Redo
      </span>
       <span *ngIf="isRedoEnable==false"
        title="redo"
        id="btnRedo"
        class="p-column-filter-menu-button"
        style="color: lightgray; box-shadow: unset;"
      >
        <i class="pi pi-refresh"></i>
        &nbsp;Redo
      </span> -->
      <div class="header">
        <div class="textbutton-container">
      <button [disabled]="isUndoDisable" pButton type="button" id="undoButton" class="p-button-text btn-hover-icon"  (click)="onUndoClicked()">
        <i class="undoicon" aria-hidden="true"></i> Undo</button>
      </div>
      <button [disabled]="isRedoDisable" pButton type="button" id="redoButton" class="p-button-text btn-hover-icon" (click)="onRedoClicked()">
        <i class="redoicon" aria-hidden="true"></i> Redo</button>
        <hr style="width: 116%; margin-left: -105px;color: #ced4da;margin-top:.25rem;">
      </div>
    </div>
    <div class="message-details-form-container">
      <div class="d-flex message-details-form mb-4">
        <div style="width: 550px" class="me-3">
          <div style="line-height: 1;" class="fs-4 fw-bold text-dark mb-2 flex flex-column justify-content-start">
            Create Your Message
          </div>

          <form [formGroup]="form" #smsFormBody class="mb-2">
            <ng-container formArrayName="smsBody">
              <app-sms-body-textarea *ngFor="let smsBodyForm of smsBody.controls; let i = index"
                [smsBodyForm]="smsBodyForm" [index]="i" (onEmojiClick)="onEmojiClick($event)"
                (onMergeTagRequested)="onMergeTagRequested(i)" (onImageRequested)="onImageRequested(i)"
                (onValueChanged)="onValueChanged()"
                >
              </app-sms-body-textarea>
            </ng-container>
          </form>
          <div *ngIf="smsBody.controls.length < 3">
            <span class="font-size-15 fw-bold">Expand to:</span>
            <span class="mx-3 add-message-links" role="button" *ngIf="smsBody.controls.length === 1"
              (click)="addSMS('', 1)">
              2 messages (320 characters)
            </span>
            <span class="mx-3 add-message-links" role="button" *ngIf="
                smsBody.controls.length === 1 || smsBody.controls.length === 2
              " (click)="addSMS('', 3 - smsBody.controls.length)">
              3 messages (480 characters)
            </span>
          </div>

          <button [disabled]="
              form.invalid ||
              editorState.messageTemplate.title == '' ||
              editorState.messageTemplate.categoryId == ''
            " pButton pRipple type="button" label="Send Test Text Message" (click)="openSendSMSDialog()"
            class="p-button-rounded mt-2"></button>
          <!--          <div class="d-flex align-items-center">-->
          <!--            <p-button label="Send a test" class="me-3 fst-i"></p-button>-->
          <!--            <div class="fst-italic fs-6">Will send to (###) ###-####, the number set on account.</div>-->
          <!--          </div>-->
        </div>
        <div class="flex-fill text-center">
          <div class="sms-preview-container" style="
              background-image: url(../../../assets/images/phone-frame.svg);
            ">
            <div class="sms-preview-block">
              <div class="sms-block" *ngFor="let smsBodyForm of smsBody.controls; last as isLast" [ngClass]="[
                  smsBodyForm.get('body').value === '' ? 'd-none' : ''
                ]">
                <span>{{ smsBodyForm.get("body").value }}</span>
                <span *ngIf="isLast && smsBodyForm.get('body').value.length <= 142">&nbsp;Text STOP to end</span>
              </div>
              <div class="sms-block" *ngIf="
                  smsBody.controls.length >= 1 &&
                  smsBody.controls[smsBody.controls.length - 1].get('body')
                    .value.length > 142
                ">
                Text STOP to end
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="isLoading" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<!--<div id="textMessageEditorDiv">-->
<!--  <div style="padding: 4rem;">-->
<!--    <table width="100%">-->
<!--      <tr>-->
<!--        <td valign="top">-->
<!--          <p-card >-->
<!--            &lt;!&ndash; MERGE TAGS *********************************************************************************-->
<!--            <table width="100%">-->
<!--              <tr>-->
<!--                <td>-->
<!--                  <p-slideMenu #mergeTagSlideMenu class="mergeTagSlideMenu" [model]="mergeTagMenuItems" [popup]="true" [viewportHeight]="277" [menuWidth]="375"></p-slideMenu>-->
<!--                  <button type="button" class="p-button-secondary" pButton iconPos="right" icon="pi pi-angle-down" label="Merge Tags" (click)="mergeTagSlideMenu.toggle($event)"></button>-->
<!--                </td>-->
<!--              </tr>-->
<!--            </table>-->
<!--            <table width="100%">-->
<!--              <tr>-->
<!--                <td>-->
<!--                  <br />-->
<!--                  <div style="width: 100%; background: var(&#45;&#45;light-gray); height: 1px;"></div>-->
<!--                  <div style="width: 100%; background: var(&#45;&#45;very-light-gray); height: 1px;"></div>-->
<!--                  <br />-->
<!--                </td>-->
<!--              </tr>-->
<!--            </table>-->
<!--            ************************************************************************************************* &ndash;&gt;-->
<!--          </p-card>-->
<!--        </td>-->
<!--        <td width="16px">&nbsp;</td>-->
<!--        <td width="100%" align="center">-->
<!--          <p-card>-->
<!--            <div class="cellPhoneDiv">-->
<!--              <textarea #textMessage [maxlength]="459" class="cellPhoneText p-inputtext" [(ngModel)]="editorState.messageTemplate.body" style="margin-left: unset !important;" placeholder="Enter text here."></textarea>-->
<!--            </div>-->
<!--          </p-card>-->
<!--        </td>-->
<!--      </tr>-->
<!--    </table>-->
<!--  </div>-->
<!--</div>-->
<!---->
<!--
*************************************************************************************
Text Message Preview Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" #textPreview [resizable]="false" (onHide)="onClosePreviewDialog()"
  [(visible)]="editorState.showPreviewDialog" [modal]="true" [baseZIndex]="10000" [style]="{ 'font-weight': '700' }">
  <textMessagePreviewDialog-root #textPreviewDialog [messageText]="editorState.messageTemplate.body">
  </textMessagePreviewDialog-root>
  <p-header>
    <!-- <i class="pi pi-eye" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="dialog-head">Preview</span>
  </p-header>
  <ng-template pTemplate="footer">
    <!-- <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
    <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div> -->
    <div style="margin-top: 1.4rem">
      <p-button (click)="onClosePreviewDialog()" label="Cancel" styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<!-- <p-dialog class="custom-alert custom-modal" id="delete_campaign" #cancelSavingDialog [(visible)]="showCancelEdit"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <p class="confirm-sms">
      Your changes to the {{ editorState.messageTemplate.title }} will be lost.
      Do you want to continue?
    </p>
  </div>

  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button (onClick)="onTextMessageCancelEdit()" [style]="{ 'margin-right': '17.5px' }" label="Yes"
        styleClass="p-button-rounded"></p-button>
      <p-button (click)="showCancelEdit = false" [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }"
        styleClass="p-button-secondary">Cancel</p-button>
     <a (click)="showDeleteCampaignDialog = false;" style="color:#666666;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;" styleClass="p-button-secondary">Cancel</a> 
    </div>
  </ng-template>
</p-dialog> -->

<!-- TEST SMS Template -->
<p-dialog class="custom-dialog" [resizable]="false" (onHide)="onCloseSendTestSMSDialog()"
  [(visible)]="showSendTestSMSDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{ width: '62.5em', 'font-weight': '700' }">
  <p-header>
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="dialog-head">Send Test Text Message</span>
  </p-header>
  <div *ngIf="!smsSentSuccess">
    <table width="100%">
      <tr>
        <td valign="middle" class="div-label" width="10px">
          <span class="email-label">To Mobile Number</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input style="width: 60%" pInputText id="txtNumber" type="tel" [formControl]="toNumber" maxlength="20" />
        </td>
      </tr>
    </table>
  </div>
  <div class="isEmailResponse">
    <div class="success-message" style="text-align: center; font-weight: 400; color: green" *ngIf="smsSentSuccess">
      <p>SMS was sent successfully!</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a (click)="onCloseSendTestSMSDialog()" style="
          color: #2e2e2e;
          cursor: pointer;
          margin: 0px 17.5px;
          text-decoration: underline;
          font-weight: 400;
        " styleClass="p-button-secondary">Cancel</a>

      <p-button *ngIf="!smsSentSuccess" (onClick)="sendTestSMS()" [style]="{ 'margin-left': '17.5px' }" [disabled]="
          smsMsgBody?.length == 0 || !toNumber?.value || SMSInProgress
        " label="Send SMS"></p-button>
      <p-button *ngIf="smsSentSuccess" (click)="sendTestSMSAnother()" label="Send Another"
        [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }" styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>
<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="SMSInProgress" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<p-dialog class="custom-alert custom-modal" id="delete_campaign" #cancelSavingDialog [(visible)]="showCancelEdit"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <p class="confirm-sms">
      What would you like to do with the changes to
      {{ editorState.messageTemplate.title }}?
    </p>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <div *ngIf="IsEdit==1">
        <p-button (onClick)="onTextMessageSaveAndExit()" [style]="{ 'margin-right': '17.5px' }" label="Save and Exit"
          styleClass="p-button-rounded"></p-button>
      </div>
      <div *ngIf="IsEdit==0">
        <p-button (onClick)="onTextMessageSaveAndContinue()" [style]="{ 'margin-right': '17.5px' }"
          label="Save and Continue" styleClass="p-button-rounded"></p-button>
      </div>
      <p-button (onClick)="onTextMessageCancelEdit()" [style]="{ 'margin-right': '17.5px' }" label="Exit and discard"
        styleClass="p-button-secondary"></p-button>
      <p-button (click)="showCancelEdit = false" [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }"
        styleClass="p-button-secondary">Cancel</p-button>
      <!-- <a (click)="showDeleteCampaignDialog = false;" style="color:#666666;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;" styleClass="p-button-secondary">Cancel</a> -->
    </div>
  </ng-template>
</p-dialog>