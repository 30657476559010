import { Injectable } from '@angular/core'; 
import { WhereClauseCriteria, WhereClauseCriteriaCollection } from 'src/models/WhereClauseCriteria';
import { AndOr, Group, LoanStatusId, SqlComparison } from '../models/enumerations';
import { ContactTypeService } from './contactType.service';
import { FilterHelperService } from './filterHelper.service';
import { USAStatesService } from './usstates.service';
 
@Injectable() 
export class WhereClauseService {

  // #region Constants

  // Source specific when there is no criteria.
  readonly C_CONTACT_DEFAULT = 'All Contacts based upon your role within the Company'
  readonly C_LOAN_DEFAULT = 'All Loans based upon your role within the Company'

  // Contact specific names (Enabled column replacement).
  readonly C_OPTED_IN = 'Opted&nbsp;In';
  readonly C_OPTED_OUT = 'Opted&nbsp;Out';

  // LOWER CASE CONSTANTS
  readonly C_AND = 'and';
  readonly C_OR = 'or';
  readonly C_ANY = 'any';
  readonly C_NOT = 'not';
  readonly C_IS = 'is';
  readonly C_IN = 'in';
  readonly C_ON = 'on';
  readonly C_AFTER = 'after';
  readonly C_BEFORE = 'before';
  readonly C_OF = 'of';
  readonly C_NOTHING = 'nothing';
  readonly C_ANYTHING = 'anything';
  readonly C_EQUAL = 'equal';
  readonly C_EQUALS = 'equals';
  readonly C_LESS = 'less';
  readonly C_GREATER = 'greater';
  readonly C_THAN = 'than';
  readonly C_BETWEEN = 'between';
  readonly C_DOES = 'does';
  readonly C_LIKE = 'like';
  readonly C_BEGIN = 'begin';
  readonly C_BEGINS = 'begins';
  readonly C_START = 'start';
  readonly C_STARTS = 'starts';
  readonly C_END = 'end';
  readonly C_ENDS = 'ends';
  readonly C_WITH = 'with';
  readonly C_WHERE = 'where';
  readonly C_CONTAIN = 'contain';
  readonly C_CONTAINS = 'contains';
  readonly C_ARE = 'are';
  readonly C_ARE_NOT = 'are&nbsp;not';
  readonly C_THAT_ARE = 'that&nbsp;are';
  readonly C_THAT_ARE_NOT = 'that&nbsp;are&nbsp;not';
  readonly C_FROM = 'from';
  readonly C_THRU = 'thru';
  readonly C_TO = 'to';
  readonly C_WEEK = 'week';
  readonly C_MONTH = 'month';
  readonly C_YEAR = 'year';
  readonly C_DAYS = 'days';
  readonly C_TODAY = 'today';
  readonly C_THIS = 'this';
  readonly C_NEXT = 'next';
  readonly C_PREVIOUS = 'previous';
  readonly C_WITHIN = 'within';
  readonly C_PLUS = 'plus';
  readonly C_MINUS = 'minus';
  readonly C_PLUS_OR_MINUS = 'plus&nbsp;or&nbsp;minus';

  //// UPPER CASECONSTANTS
  //readonly C_AND = 'AND';
  //readonly C_OR = 'OR';
  //readonly C_ANY = 'ANY';
  //readonly C_NOT = 'NOT';
  //readonly C_IS = 'IS';
  //readonly C_IN = 'IN';
  //readonly C_ON = 'ON';
  //readonly C_AFTER = 'AFTER';
  //readonly C_BEFORE = 'BEFORE';
  //readonly C_OF = 'OF';
  //readonly C_NOTHING = 'NOTHING';
  //readonly C_ANYTHING = 'ANYTHING';
  //readonly C_EQUAL = 'EQUAL';
  //readonly C_EQUALS = 'EQUALS';
  //readonly C_LESS = 'LESS';
  //readonly C_GREATER = 'GREATER';
  //readonly C_THAN = 'THAN';
  //readonly C_BETWEEN = 'BETWEEN';
  //readonly C_DOES = 'DOES';
  //readonly C_LIKE = 'LIKE';
  //readonly C_BEGIN = 'BEGIN';
  //readonly C_BEGINS = 'BEGINS';
  //readonly C_START = 'START';
  //readonly C_STARTS = 'STARTS';
  //readonly C_END = 'END';
  //readonly C_ENDS = 'ENDS';
  //readonly C_WITH = 'WITH';
  //readonly C_WHERE = 'WHERE';
  //readonly C_CONTAIN = 'CONTAIN';
  //readonly C_CONTAINS = 'CONTAINS';
  //readonly C_ARE = 'ARE';
  //readonly C_ARE_NOT = 'ARE&nbsp;NOT';
  //readonly C_THAT_ARE = 'THAT&nbsp;ARE';
  //readonly C_THAT_ARE_NOT = 'THAT&nbsp;ARE&nbsp;NOT';
  //readonly C_FROM = 'FROM';
  //readonly C_THRU = 'THRU';
  //readonly C_TO = 'TO';
  //readonly C_WEEK = 'WEEK';
  //readonly C_MONTH = 'MONTH';
  //readonly C_YEAR = 'YEAR';
  //readonly C_DAYS = 'DAYS';
  //readonly C_TODAY = 'TODAY';
  //readonly C_THIS = 'THIS';
  //readonly C_NEXT = 'NEXT';
  //readonly C_PREVIOUS = 'PREVIOUS';
  //readonly C_WITHIN = 'WITHIN';
  //readonly C_PLUS = 'PLUS';
  //readonly C_MINUS = 'MINUS';
  //readonly C_PLUS_OR_MINUS = 'PLUS&nbsp;or&nbsp;MINUS';

  // #endregion

  // #region Formatting Options

  // Default formatting is Labels for Values and bold unformatted Names.
  bold: boolean = false;   // use bold text when formatting
  labels: boolean = true;  // use labels when formatting

  formatConstants: boolean = false;          // indicates that constants are to be formatted
  boldUnformattedConstants: boolean = false; // indicates that when constants are not formatted, the constant is bold text

  formatNames: boolean = false;              // indicates that names are to be formatted
  boldUnformattedNames: boolean = true;      // indicates that when names are not formatted, the name is bold text

  formatValues: boolean = true;              // indicates that values are to be formatted
  boldUnformattedValues: boolean = false;    // indicates that when values are not formatted, the value is bold text

  // #endregion

  // #region Constructor

  constructor(private contactTypeService: ContactTypeService,
    private filterHelperService: FilterHelperService,
    private statesService: USAStatesService) {
  } 

  // #endregion

  // #region Column name translation

  /**
   * Translates WhereClauseCritera ParameterName property value (typically a DB column name) to a readable name.
   * 
   * @param parameterName WhereClauseCritera ParameterName property value.
   */
  translateParameterName(parameterName: String) {
    if ((parameterName == undefined) || (parameterName == null) || (parameterName.length == 0))
      return '';

    if (parameterName.toLowerCase() == 'mobilephone')
      return 'Mobile Phone';
    else if (parameterName.toLowerCase() == 'homephone')
      return 'Home Phone';
    else if (parameterName.toLowerCase() == 'workphone')
      return 'Work Phone';
    else if (parameterName.toLowerCase() == 'birthdate')
      return 'Birthday';
    else if (parameterName.toLowerCase() == 'companyname')
      return 'Company';



    else if (parameterName.toLowerCase() == 'loannumber')
      return 'Loan Number';
    else if (parameterName.toLowerCase() == 'loanstatus')
      return 'Status';
    else if (parameterName.toLowerCase() == 'loanstatusdate')
      return 'Status Date';
    else if (parameterName.toLowerCase() == 'loanstatusid')
      return 'Status';
    else if (parameterName.toLowerCase() == 'loanpurposedesc')
      return 'Loan Type';
    else if (parameterName.toLowerCase() == 'loanpurposeid')
      return 'Loan Type';
    //else if (parameterName.toLowerCase() == 'ratelock')
    //  return 'Rate Lock';
    else if (parameterName.toLowerCase() == 'ratelockexpirationdate')
      return 'Lock Exp';
    else if (parameterName.toLowerCase() == 'purchaseprice')
      return 'Purchase Price';
    else if (parameterName.toLowerCase() == 'noterate')
      return 'Rate';
    else if (parameterName.toLowerCase() == 'totalloanamount')
      return 'Total Loan Amount';
    else if (parameterName.toLowerCase() == 'baseloanamount')
      return 'Base Loan Amount';
    else if (parameterName.toLowerCase() == 'estimatedclosingdate')
      return 'Est Close';

    else if (parameterName.toLowerCase() == 'subjectpropertystreet')
      return 'Property Address';
    else if (parameterName.toLowerCase() == 'subjectpropertycity')
      return 'Property City';
    else if (parameterName.toLowerCase() == 'subjectpropertystate')
      return 'Property State';
    else if (parameterName.toLowerCase() == 'subjectpropertyzip')
      return 'Property Zip';


    else if (parameterName.toLowerCase() == 'borrowername')
      return 'Borrower';
    else if (parameterName.toLowerCase() == 'borroweremail')
      return 'Borrower Email';
    else if (parameterName.toLowerCase() == 'coborrowername')
      return 'CoBorrower';
    else if (parameterName.toLowerCase() == 'coborroweremail')
      return 'CoBorrower Email';
    else if (parameterName.toLowerCase() == 'loanofficername')
      return 'Loan Officer';
    //else if (parameterName.toLowerCase() == 'loanofficeremail')
    //  return 'Loan Officer Email';
    else if (parameterName.toLowerCase() == 'loanprocessorname')
      return 'Processor';
    //else if (parameterName.toLowerCase() == 'loanprocessoremail')
    //  return 'Processor Email';

    else if (parameterName.toLowerCase() == 'buyersagentname')
      return 'Buyers Agent';
    else if (parameterName.toLowerCase() == 'sellersagentname')
      return 'Sellers Agent';
    else if (parameterName.toLowerCase() == 'closingagentname')
      return 'Closing Agent';
    else if (parameterName.toLowerCase() == 'titlecompanyagentname')
      return 'Title Agent';
    //else if (parameterName.toLowerCase() == 'titlecompany')
    //  return 'Title Company';
    else if (parameterName.toLowerCase() == 'hazardinsurancecompanyagentname')
      return 'Hazard Insurance Agent';
    //else if (parameterName.toLowerCase() == 'hazardinsurancecompany')
    //  return 'Hazard Insurance Company';

    return parameterName;
  }

  // #endregion

  // #region Contact Specific

  /**
   * Gets the where clause description text for the 'Contacts' list.
   * 
   * @param defaultCriteria
   * @param completeCriteria
   * @param globalFilterCriteria
   */
  getContactListWhereClauseDescription(defaultCriteria: WhereClauseCriteriaCollection, completeCriteria: WhereClauseCriteriaCollection, globalFilterCriteria: WhereClauseCriteriaCollection) {

    if ((defaultCriteria == undefined) || (defaultCriteria == null) ||
        (completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length == 0))
      return '';

    // FIRST remove default criteria
    let criteria = this.removeDefaultCriteria(defaultCriteria, completeCriteria);

    // NEXT REMOVE Keyword Search Criteria
    if ((globalFilterCriteria != undefined) && (globalFilterCriteria != null) && (globalFilterCriteria.Items.length > 0)) {
      for (let x = 0; x < (globalFilterCriteria.Items.length); x++) {
        criteria.Items.pop();
      }
    }

    var contactTypeDescriptions = this.makeContactTypeFilterDescription(criteria, 'ContactTypes');
    var criteriaDescription = this.makeOptedInFilterDescription(criteria);
    criteriaDescription += this.makeVeteranFilterDescription(criteria);
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'Name');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'City');
    //criteriaDescription += this.makeContainsFilterDescription(criteria, 'State');
    criteriaDescription += this.makeStateFilterDescription(criteria, 'State');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'Zip');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'CompanyName');
    //criteriaDescription += this.makeMonthAndDayFilterDescription(criteria, 'BirthDate');
    criteriaDescription += this.makeBirthdayFilterDescription(criteria, 'BirthDate');

    // ALWAYS LAST
    criteriaDescription += this.makeGlobalKeyFilterDescription(criteria, globalFilterCriteria);

    if (criteriaDescription.indexOf(this.formatConstant(this.C_AND)) == 0) {
      criteriaDescription = (this.formatConstant(this.C_WHERE) + criteriaDescription.substring(this.formatConstant(this.C_AND).length, criteriaDescription.length));
    }
    else if (criteriaDescription.indexOf(this.formatConstant(this.C_OR)) == 0) {
      criteriaDescription = (this.formatConstant(this.C_WHERE) + criteriaDescription.substring(this.formatConstant(this.C_OR).length, criteriaDescription.length));
    }

    if (contactTypeDescriptions.length == 0) {
      if (criteriaDescription.length > 0)
        contactTypeDescriptions = this.formatName('Contacts');
      else
        contactTypeDescriptions = this.C_CONTACT_DEFAULT;
    }

    let lastNbspIndex = criteriaDescription.lastIndexOf("&nbsp;");
    if (lastNbspIndex == (criteriaDescription.length - 6))
      return (contactTypeDescriptions + criteriaDescription.substring(0, lastNbspIndex) + '.');

    return ((contactTypeDescriptions + criteriaDescription).trim() + '.');
  }

  /**
   * Gets the Opted In (Enabled) criteria description.
   * 
   * Sample criteria item value:
   * { "ParameterName": "Enabled", "Value": 1, "AndOr": 0, "SqlDbType": 2, "Comparison": 0, "Group": 0 }
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   */
  makeOptedInFilterDescription(criteria: WhereClauseCriteriaCollection) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === 'Enabled');
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (value == null)
      return '';

    if (value == 0)
      return (this.formatConstant(this.C_THAT_ARE) + this.formatValue(this.C_OPTED_OUT)) + '&nbsp;';
    return (this.formatConstant(this.C_THAT_ARE) + this.formatValue(this.C_OPTED_IN)) + '&nbsp;';
  }

  /**
   * Gets the Veteran criteria description.
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   */
  makeVeteranFilterDescription(criteria: WhereClauseCriteriaCollection) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === 'Veteran');
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (value == null)
      return '';

    let andOrValue = item.AndOr;
    if (value == 0)
      return (this.translateAndOr(andOrValue) + this.formatConstant(this.C_ARE) + this.formatValue('Not Veterans')) + '&nbsp;';
    return (this.translateAndOr(andOrValue) + this.formatConstant(this.C_ARE) + this.formatValue('Veterans')) + '&nbsp;';
  }

  // #endregion

  // #region Loan Specific

  /**
   * Gets the where clause description text for the 'Loans' list.
   * 
   * @param defaultCriteria
   * @param completeCriteria
   * @param globalFilterCriteria
   */
  getLoanListWhereClauseDescription(defaultCriteria: WhereClauseCriteriaCollection, completeCriteria: WhereClauseCriteriaCollection, globalFilterCriteria: WhereClauseCriteriaCollection) {

    if ((defaultCriteria == undefined) || (defaultCriteria == null) ||
      (completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length == 0))
      return '';

    // FIRST remove default criteria
    let criteria = this.removeDefaultCriteria(defaultCriteria, completeCriteria);

    // NEXT REMOVE Keyword Search Criteria
    if ((globalFilterCriteria != undefined) && (globalFilterCriteria != null) && (globalFilterCriteria.Items.length > 0)) {
      for (let x = 0; x < (globalFilterCriteria.Items.length); x++) {
        criteria.Items.pop();
      }
    }

    //var contactTypeDescriptions = this.makeContactTypeFilterDescription(criteria, 'ContactTypes');

    var criteriaDescription = this.makeContainsFilterDescription(criteria, 'LoanNumber');
    criteriaDescription += this.makeLoanStatusFilterDescription(criteria, 'LoanStatusId');
    //criteriaDescription += this.makeDateFilterDescription(criteria, 'LoanStatusDate');
    criteriaDescription += this.makeAdvancedDateFilterDescription(criteria, 'LoanStatusDate');
    criteriaDescription += this.makeLoanPurposeFilterDescription(criteria, 'LoanPurposeId');
    //criteriaDescription += this.makeDateFilterDescription(criteria, 'EstimatedClosingDate');
    criteriaDescription += this.makeAdvancedDateFilterDescription(criteria, 'EstimatedClosingDate');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'BorrowerName');
    criteriaDescription += this.makeNumericFilterDescription(criteria, 'TotalLoanAmount','$');

    criteriaDescription += this.makeContainsFilterDescription(criteria, 'SubjectPropertyCity');
    //criteriaDescription += this.makeContainsFilterDescription(criteria, 'SubjectPropertyState');
    criteriaDescription += this.makeStateFilterDescription(criteria, 'SubjectPropertyState');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'SubjectPropertyZip');

    criteriaDescription += this.makeNumericFilterDescription(criteria, 'BaseLoanAmount', '$');
    //criteriaDescription += this.makeDateFilterDescription(criteria, 'RateLockExpirationDate');
    criteriaDescription += this.makeAdvancedDateFilterDescription(criteria, 'RateLockExpirationDate');
    criteriaDescription += this.makeNumericFilterDescription(criteria, 'NoteRate', '', '%');

    criteriaDescription += this.makeContainsFilterDescription(criteria, 'CoBorrowerName');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'LoanOfficerName');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'LoanProcessorName');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'BuyersAgentName');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'SellersAgentName');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'ClosingAgentName');
    //criteriaDescription += this.makeContainsFilterDescription(criteria, 'TitleCompany');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'TitleCompanyAgentName');
    //criteriaDescription += this.makeContainsFilterDescription(criteria, 'HazardInsuranceCompany');
    criteriaDescription += this.makeContainsFilterDescription(criteria, 'HazardInsuranceCompanyAgentName');

    // ALWAYS LAST
    criteriaDescription += this.makeGlobalKeyFilterDescription(criteria, globalFilterCriteria);

    if (criteriaDescription.indexOf(this.formatConstant(this.C_AND)) == 0) {
      criteriaDescription = (this.formatConstant(this.C_WHERE) + criteriaDescription.substring(this.formatConstant(this.C_AND).length, criteriaDescription.length));
    }
    else if (criteriaDescription.indexOf(this.formatConstant(this.C_OR)) == 0) {
      criteriaDescription = (this.formatConstant(this.C_WHERE) + criteriaDescription.substring(this.formatConstant(this.C_OR).length, criteriaDescription.length));
    }

    // All Statuses
    if (criteriaDescription.length == 0)
      criteriaDescription = 'based upon your role within the Company';
    criteriaDescription = this.formatName('Loans') + criteriaDescription;

    let lastNbspIndex = criteriaDescription.lastIndexOf("&nbsp;");
    if (lastNbspIndex == (criteriaDescription.length - 6))
      return criteriaDescription.substring(0, lastNbspIndex) + '.';

    return (criteriaDescription.trim() + '.');
  }

  /**
   * Removes any default criteria from the all criteria specification and returns the resultant criteria's JSON representation.
   * 
   * @param defaultCriteria
   * @param completeCriteria
   */
  removeDefaultLoanCriteriaToJson(defaultCriteria: WhereClauseCriteriaCollection, completeCriteria: WhereClauseCriteriaCollection) {
    return JSON.stringify(this.removeDefaultLoanCriteria(defaultCriteria, completeCriteria));
  }

  /**
   * Removes any default criteria from the all criteria specification and returns the resultant criteria.
   *
   * @param defaultCriteria
   * @param completeCriteria
   */
  removeDefaultLoanCriteria(defaultCriteria: WhereClauseCriteriaCollection, completeCriteria: WhereClauseCriteriaCollection) {
    let result: WhereClauseCriteriaCollection = new WhereClauseCriteriaCollection();
    if ((defaultCriteria == undefined) || (defaultCriteria == null) ||
      (completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length == 0))
      return result;

    for (let x = defaultCriteria.Items.length; x < completeCriteria.Items.length; x++) {
      result.Items.push(completeCriteria.Items[x]);
    }

    return result;
  }

  /**
   * Determines if the report is an Active Loans report.
   * 
   *   NOT ApplicationTaken, Closed, Denied, Funded, NotAccepted, Rescinded, or Withdrawn.
   * 
   * @param completeCriteria
   */
  isActiveLoanReport(completeCriteria: WhereClauseCriteriaCollection) {
    var activeStatusIds = [1, 2, 3, 14, 15, 16, 17, 21, 22, 23, 24, 25, 26, 27, 28, 30, 31, 32, 33];

    if ((completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length < 1))
      return false;

    if (completeCriteria.Items[0].ParameterName == 'TenantId') {
      if (completeCriteria.Items.length < 2)
        return false;

      if (completeCriteria.Items[1].Comparison != SqlComparison.IN)
        return false;

      var value = completeCriteria.Items[1].Value;
      if (value.length != activeStatusIds.length)
        return false;
      for (var x = 0; x < value.length; x++) {
        if (value[x] != activeStatusIds[x])
          return false;
      }
      return true;
    }

    if (completeCriteria.Items[0].Comparison != SqlComparison.IN)
      return false;

    var value = completeCriteria.Items[0].Value;
    if (value.length != activeStatusIds.length)
      return false;
    for (var x = 0; x < value.length; x++) {
      if (value[x] != activeStatusIds[x])
        return false;
    }
    return true;
  }

  /**
   * Determines if the report is an Active Leads report.
   *
   *   Lead or Prospect
   *   
   * @param completeCriteria
   */
  isActiveLeadReport(completeCriteria: WhereClauseCriteriaCollection) {
    var activeLeadStatusIds = [1, 27];

    if ((completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length < 1))
      return false;

    if (completeCriteria.Items[0].ParameterName == 'TenantId') {
      if (completeCriteria.Items.length < 2)
        return false;

      if (completeCriteria.Items[1].Comparison != SqlComparison.IN)
        return false;

      var value = completeCriteria.Items[1].Value;
      if (value.length != activeLeadStatusIds.length)
        return false;
      for (var x = 0; x < value.length; x++) {
        if (value[x] != activeLeadStatusIds[x])
          return false;
      }
      return true;
    }

    if (completeCriteria.Items[0].Comparison != SqlComparison.IN)
      return false;

    var value = completeCriteria.Items[0].Value;
    if (value.length != activeLeadStatusIds.length)
      return false;
    for (var x = 0; x < value.length; x++) {
      if (value[x] != activeLeadStatusIds[x])
        return false;
    }
    return true;
  }

  /**
   * Determines if the report is a Closed Loans report.
   *
   *   Closed or Funded
   *     
   * @param completeCriteria
   */
  isClosedLoanReport(completeCriteria: WhereClauseCriteriaCollection) {
    var closedStatusIds = [12, 13];

    if ((completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length < 1))
      return false;

    if (completeCriteria.Items[0].ParameterName == 'TenantId') {
      if (completeCriteria.Items.length < 2)
        return false;

      if (completeCriteria.Items[1].Comparison != SqlComparison.IN)
        return false;

      var value = completeCriteria.Items[1].Value;
      if (value.length != closedStatusIds.length)
        return false;
      for (var x = 0; x < value.length; x++) {
        if (value[x] != closedStatusIds[x])
          return false;
      }
      return true;
    }

    if (completeCriteria.Items[0].Comparison != SqlComparison.IN)
      return false;

    var value = completeCriteria.Items[0].Value;
    if (value.length != closedStatusIds.length)
      return false;
    for (var x = 0; x < value.length; x++) {
      if (value[x] != closedStatusIds[x])
        return false;
    }
    return true;
  }

  // #endregion

  //***************************************************************************************
  // Common - Not List Specific
  //*************************************************************************************** 
  /**
   * Removes any default criteria from the all criteria specification and returns the resultant criteria's JSON representation.
   * 
   * @param defaultCriteria
   * @param completeCriteria
   */
  removeDefaultCriteriaToJson(defaultCriteria: WhereClauseCriteriaCollection, completeCriteria: WhereClauseCriteriaCollection) {
    return JSON.stringify(this.removeDefaultCriteria(defaultCriteria, completeCriteria));
  }

  /**
   * Removes any default criteria from the all criteria specification and returns the resultant criteria.
   * 
   * @param defaultCriteria
   * @param completeCriteria
   */
  removeDefaultCriteria(defaultCriteria: WhereClauseCriteriaCollection, completeCriteria: WhereClauseCriteriaCollection) {
    let result: WhereClauseCriteriaCollection = new WhereClauseCriteriaCollection();
    if ((defaultCriteria == undefined) || (defaultCriteria == null) ||
        (completeCriteria == undefined) || (completeCriteria == null) || (completeCriteria.Items.length == 0))
      return result;

    // FIRST remove default criteria
    for (let x = defaultCriteria.Items.length; x < completeCriteria.Items.length; x++) {
      result.Items.push(completeCriteria.Items[x]);
    }

    return result;
  }

  /**
   * Formats a constant value (ie AND, OR, etc.)
   * @param value
   */
  formatConstant(value: any) {
    if (!this.formatConstants) {
      if (this.boldUnformattedConstants)
        return '<b>' + value + '</b>&nbsp;';
      return value + ' ';
    }

    if ((this.bold) || (this.labels))
      return this.doFormat(value) + '&nbsp;';

    return this.doFormat(value) + '&nbsp;';
  }

  /**
   * Formats the criteria (typically a column) name.
   * @param value
   */
  formatName(value: any, trailingSpace = true) {
    if (!this.formatNames) {
      if (this.boldUnformattedNames) {
        if (trailingSpace)
          return '<b>' + value + '</b>&nbsp;';
        return '<b>' + value + '</b>';
      }

      if (trailingSpace)
        return value + ' ';
      return value;
    }

    if (trailingSpace) {
      if ((this.bold) || (this.labels))
        return this.doFormat(value) + '&nbsp;';
      else
        return this.doFormat(value) + ' ';
    }

    return this.doFormat(value);
  }

  /**
   * Formats the criteria value.
   * @param value
   */
  formatValue(value: any) {
    if (!this.formatValues) {
      if (this.boldUnformattedValues)
        return '<b>' + value + '</b>';
      return value;
    }

    return this.doFormat(value);
  }

  /**
   * Formats the passed value.
   * @param value
   */
  doFormat(value: any) {
    if (this.labels)
      if (this.bold)
        return this.makeBoldLabel(value)
      else
        return this.makeLabel(value)

    if (this.bold)
      return this.makeBold(value)

    return value;
  }

  /**
   * Generates bold text.
   * <b>value</b>
   * 
   * @param value
   */
  makeBold(value: any) {
    // bold text
    return "<b>" + value.replaceAll(' ', '&nbsp;') + "</b>";
  }

  /**
   * Generates a bold text HTML label.
   * <label class='wcd'><b>value</b></label>
   * 
   * @param value
   */
  makeBoldLabel(value: any) {
    // bold labels
    return "<label class='wcd'><b>" + value.replaceAll(' ', '&nbsp;') + "</b></label>";
  }

  /**
   * Generates an HTML label.
   * <label class='wcd'>value</label>
   * 
   * @param value
   */
  makeLabel(value: any) {
    // labels
    return "<label class='wcd'>" + value.replaceAll(' ', '&nbsp;') + "</label>";

    // In theme.css
    /* Looks like a PrimeNG Chip */
    /*.wcd {
      border - radius: .75em .75em;
      padding: .25em .5em .25em .5em;
      color: var(--text - color);
      background - color: var(--light - gray);
      height: 1.5em;
    }*/

    // Commented in theme.css
    /* Ugly dark labels per Demo screen design */
    /*.wcd {
      padding: .25em;
      border-radius: 5px 5px;
      color: white;
      background-color: #808080;
      height: 1.5em;
    }*/
  }

  /**
   * Gets the 'Contains' criteria description.
   * 
   * Sample criteria value:
   * { "ParameterName": "Name", "Value": "%o%", "AndOr": 0, "SqlDbType": 12, "Comparison": 10, "Group": 0 }
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeContainsFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (value == null)
      return '';

    let andOrValue = item.AndOr;
    let phrase = this.translateAndOr(andOrValue);

    phrase += this.formatName(this.translateParameterName(parameterName));

    let comparison = this.translateComparison(item.Comparison);
    if ((item.Comparison == SqlComparison.LIKE) || (item.Comparison == SqlComparison.NOTLIKE))
        comparison = this.translateLike(comparison, value);

    phrase += (comparison + this.formatValue("'" + this.translateLikeValue(value) + "'"));

    return phrase + '&nbsp;';
  }

  /**
   * Gets the Advanced date criteria description.
   *
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeAdvancedDateFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (value == null)
      return '';

    // second half of a between or day difference
    if (item.Group == Group.END)
      return '';

    let andOrValue = item.AndOr;
    let phrase = this.translateAndOr(andOrValue);

    phrase += this.formatName(this.translateParameterName(parameterName));
    phrase += 'is ';

    if (value == FilterHelperService.SQL_TODAY) {
      if (item.Group == Group.NONE) {
        // Today
        return phrase += this.formatValue(this.C_TODAY) + '&nbsp;';
      }
      else {
        // Next # of Days
        let item2 = criteria.Items.find(x => (x.ParameterName === parameterName && x.Comparison == SqlComparison.LTEQ && x.Group == Group.END));
        if (item2) {
          // skip over: [DATEFROMPARTS(DATEPART(year, DATEADD(day, ]
          var days = item2.Value.substring(42);
          days = days.split(',')[0];

          return phrase += 'within the ' + this.formatConstant(this.C_NEXT) + this.formatValue(days) + '&nbsp;' + this.formatConstant(this.C_DAYS);
        }
      }
    }
    else if (value == FilterHelperService.SQL_START_THIS_WEEK) {
      // This Week
      return phrase += this.formatValue(this.C_THIS + '&nbsp;' + this.C_WEEK) + '&nbsp;';
    }
    else if (value == FilterHelperService.SQL_START_NEXT_WEEK) {
      // Next Week
      return phrase += this.formatValue(this.C_NEXT + '&nbsp;' + this.C_WEEK) + '&nbsp;';
    }
    else if (value == FilterHelperService.SQL_START_THIS_MONTH) {
      // This Month
      return phrase += this.formatValue(this.C_THIS + '&nbsp;' + this.C_MONTH) + '&nbsp;';
    }
    else if (value == FilterHelperService.SQL_START_NEXT_MONTH) {
      // Next Month
      return phrase += this.formatValue(this.C_NEXT + '&nbsp;' + this.C_MONTH) + '&nbsp;';
    }
    else if (value.indexOf('DATEFROMPARTS(DATEPART(year, DATEADD(') == 0)
    {
      let item2 = criteria.Items.find(x => (x.ParameterName === parameterName && x.Comparison == SqlComparison.LTEQ && x.Group == Group.END));
      if (item2) {
        // skip over: [DATEFROMPARTS(DATEPART(year, DATEADD(day, ]
        var days1 = item.Value.substring(42);
        days1 = (parseInt(days1.split(',')[0]) * -1).toString();
        var days2 = item2.Value.substring(42);
        days2 = days2.split(',')[0];

        if (item2.Value == FilterHelperService.SQL_TODAY) {
          // Previous # of Days
          return phrase += 'within the ' + this.formatConstant(this.C_PREVIOUS) + this.formatValue(days1) + '&nbsp;' + this.formatConstant(this.C_DAYS);
        }
        else { //if (item2.Value.indexOf('DATEFROMPARTS(DATEPART(year, DATEADD(') == 0) {
          // Within # of Days
          return phrase += 'within ' + this.formatConstant(this.C_PLUS) + 'or ' + this.formatConstant(this.C_MINUS) + this.formatValue(days2) + '&nbsp;' + this.formatConstant(this.C_DAYS);
          //return phrase += 'within ' + this.formatValue(days) + '&nbsp;' + this.formatConstant(this.C_DAYS);
          //return 'within ' + this.formatConstant(this.C_PLUS_OR_MINUS) + this.formatValue(days) + this.formatConstant(this.C_DAYS);
        }
      }
    }

    return this.makeDateFilterDescription(criteria, parameterName);
  }


  /**
   * Gets the Birthday criteria description.
   *
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeBirthdayFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let criteria1: WhereClauseCriteria = null;
    let criteria2: WhereClauseCriteria = null;
    criteria.Items.forEach((item) => {
      if (item.ParameterName.indexOf(parameterName) > 3) {
        if (criteria1 == null)
          criteria1 = item;
        else
          criteria2 = item;
      }
    });

    if (criteria1 == null)
      return '';

    if ((criteria1.ParameterName.toLowerCase().indexOf('month(') == 0) &&
        (criteria1.Comparison == SqlComparison.IN)) {
      return this.makeMonthAndDayFilterDescription(criteria, parameterName);
    }

    let today = new Date();
    let andOrValue = criteria1.AndOr;
    let phrase = this.translateAndOr(andOrValue);

    phrase += this.formatName(this.translateParameterName(parameterName));
    phrase += 'is ';

    if (criteria1.Value.toString().toLowerCase() == FilterHelperService.SQL_ANYYEAR_TODAY.toLowerCase()) {
      // Today
      return phrase += this.formatValue(this.C_TODAY) + '&nbsp;';
    }
    else if (criteria1.Value.toString().toLowerCase() == FilterHelperService.SQL_ANYYEAR_START_THIS_WEEK.toLowerCase()) {
      // This Week
      return phrase += this.formatValue(this.C_THIS + '&nbsp;' + this.C_WEEK) + '&nbsp;';
    }
    else if (criteria1.Value.toString().toLowerCase() == FilterHelperService.SQL_ANYYEAR_START_NEXT_WEEK.toLowerCase()) {
      // Next Week
      return phrase += this.formatValue(this.C_NEXT + '&nbsp;' + this.C_WEEK) + '&nbsp;';
    }
    else if (criteria1.Value.toString().toLowerCase() == FilterHelperService.SQL_ANYYEAR_THIS_MONTH.toLowerCase()) {
      // This Month
      return phrase += this.formatValue(this.C_THIS + '&nbsp;' + this.C_MONTH) + '&nbsp;';
    }
    else if (criteria1.Value.toString().toLowerCase() == FilterHelperService.SQL_ANYYEAR_NEXT_MONTH.toLowerCase()) {
      // Next Month
      return phrase += this.formatValue(this.C_NEXT + '&nbsp;' + this.C_MONTH) + '&nbsp;';
    }
    else {
      if (criteria1.ParameterName.toLowerCase().indexOf('datepart(dayofyear, ' + parameterName.toLowerCase() + ')') == 0) {
        let logActualDates = false;

        let todayDayOfYear = this.filterHelperService.dayOfYear(today);
        let days = Math.abs(criteria2.Value - criteria1.Value);
        let day1 = criteria1.Value;
        let day2 = criteria2.Value;
        if (logActualDates) {
          console.log(today);
          console.log(todayDayOfYear);
          console.log(day1);
          console.log(day2);
        }

        if (criteria1.Value == todayDayOfYear) {
          if (day1 > day2) {
            // moved into the next year
            days = (this.filterHelperService.dayOfYear(new Date(today.getFullYear(), 11, 31)) - day1) + day2;
          }
          if (logActualDates)
            console.log(days);

          // Next # of Days
          return phrase += 'within the ' + this.formatConstant(this.C_NEXT) + this.formatValue(days.toString()) + '&nbsp;' + this.formatConstant(this.C_DAYS);
        }
        else if (criteria2.Value == todayDayOfYear) {
          if (day1 > day2) {
            // moved into the last year
            days = (this.filterHelperService.dayOfYear(new Date(today.getFullYear() - 1, 11, 31)) - day1) + day2;
          }
          if (logActualDates)
            console.log(days);

          // Previous # of Days
          return phrase += 'within the ' + this.formatConstant(this.C_PREVIOUS) + this.formatValue(days.toString()) + '&nbsp;' + this.formatConstant(this.C_DAYS);
        }

        if (todayDayOfYear < day1) {
          // day1 is for last year
          days = (this.filterHelperService.dayOfYear(new Date(today.getFullYear() - 1, 11, 31)) - day1) + day2;
        }
        else if (todayDayOfYear > day2) {
          // day2 is for next year
          days = (this.filterHelperService.dayOfYear(new Date(today.getFullYear(), 11, 31)) - day1) + day2;
        }

        if (logActualDates)
          console.log('Total: ' + days.toString());

        // that number of days specified by the user is half of the total number of days
        days /= 2;

        if (logActualDates)
          console.log(days);

        // ToDo: check for This Week and Next Week

        return phrase += 'within ' + this.formatConstant(this.C_PLUS) + 'or ' + this.formatConstant(this.C_MINUS) + this.formatValue(days.toString()) + '&nbsp;' + this.formatConstant(this.C_DAYS);
      }
    }
    return '';
  }

  /**
   * Gets the date criteria description.
   *
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeDateFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (value == null)
      return '';

    // second half of a between
    if (item.Group == Group.END)
      return '';

    let date1 = new Date(item.Value);

    let andOrValue = item.AndOr;
    let phrase = this.translateAndOr(andOrValue);

    phrase += this.formatName(this.translateParameterName(parameterName));

    if (item.Group == Group.BEGIN) {
      let item2 = criteria.Items.find(x => (x.ParameterName === parameterName && x.Comparison == SqlComparison.LTEQ && x.Group == Group.END));
      if (item2) {
        let date2 = new Date(item2.Value);
        let oneWeek = new Date((date1.getMonth() + 1) + '/' + (date1.getDate() + 6) + '/' + date1.getFullYear());

        // YEAR SPECIFICATION
        if ((date1.getMonth() == 0) && (date1.getDate() == 1) &&
            (date2.getMonth() == 11) && (date2.getDate() == 31) &&
            (date1.getFullYear() == date1.getFullYear())) {
          // Jan. 1st and Dec. 31st of the same year
          phrase += 'for the ' + this.formatConstant(this.C_YEAR) + 'of ';
          phrase += this.formatValue(date1.getFullYear().toString());
        } else if ((date1.getDay() == 0) &&
                   (date2.getDay() == 6) &&
                   (oneWeek.valueOf() == date2.valueOf())) {
          // first date is a Sun.
          // second date is a Sat.
          // first date plus 6 days equals the second date
          phrase += 'for the ' + this.formatConstant(this.C_WEEK) + 'of ';
          phrase += this.formatValue(this.formatDateFilterValue(date1)) + ' ';
          phrase += this.formatConstant(this.C_THRU);
          phrase += this.formatValue(this.formatDateFilterValue(date2));
        } else {
          // BETWEEN (inclusive)
          phrase += this.formatConstant(this.C_FROM);
          phrase += (this.formatValue(this.formatDateFilterValue(date1)) + ' ');
          phrase += this.formatConstant(this.C_THRU);
          phrase += this.formatValue(this.formatDateFilterValue(date2));
        }
      }
      else {
        // MONTH SPECIFICATION
        item2 = criteria.Items.find(x => (x.ParameterName === parameterName && x.Comparison == SqlComparison.LT && x.Group == Group.END));
        phrase += 'for the ' + this.formatConstant(this.C_MONTH) + 'of ';
        phrase += this.formatValue(this.filterHelperService.formatMonth(date1.getMonth()) + '&nbsp;' + date1.getFullYear().toString());
      }
    }
    else {
      let comparison = this.translateDateComparison(item.Comparison);
      phrase += (comparison + this.formatValue(this.formatDateFilterValue(date1)));
    }

    return phrase + '&nbsp;';
  }

  formatDateFilterValue(value: Date) {
    return (this.filterHelperService.formatMonth(value.getMonth()) + '&nbsp;' +
      this.filterHelperService.formatDay(value.getDate()) + ',&nbsp;' +
      value.getFullYear().toString());
  }

  /**
   * Gets the numeric criteria description.
   *
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   * @param prefix The text to prepend to the criteria value.
   * @param suffix The text to append to the criteria value.
   */
  makeNumericFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string, prefix: string = '', suffix: string = '') {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (value == null)
      return '';

    // second half of a between
    if (item.Group == Group.END)
      return '';

    let andOrValue = item.AndOr;
    let phrase = this.translateAndOr(andOrValue);

    phrase += this.formatName(this.translateParameterName(parameterName));

    if (item.Group == Group.BEGIN) {
      let item2 = criteria.Items.find(x => (x.ParameterName === parameterName && x.Comparison == SqlComparison.LTEQ && x.Group == Group.END));
      phrase += this.formatConstant(this.C_BETWEEN);
      phrase += (this.formatValue(prefix + this.formatNumber(value) + suffix) + '&nbsp;');
      phrase += this.formatConstant(this.C_AND);
      phrase += this.formatValue(prefix + this.formatNumber(item2.Value) + suffix);
    }
    else {
      let comparison = this.translateComparison(item.Comparison);
      phrase += (comparison + this.formatValue(prefix + this.formatNumber(value) + suffix));
    }

    return phrase + '&nbsp;';
  }

  /**
   * Formats a number by inserting commas.
   *
   * @param value
   */
  formatNumber(value: number) {
    if ((value == undefined) || (value == null))
      return '';
    var result = '';
    var temp = value.toString();
    // if there are decimal places, we only want 2
    if (temp.indexOf('.') >= 0) {
      result = temp.substring(temp.indexOf('.'));
      if (result.length > 3) {
        result = result.substring(0, 3);
      }
      temp = temp.substring(0, temp.indexOf('.'));
    }
    var c = 1; // current character index
    for (var x = temp.length - 1; x >= 0; x--) {
      result = temp[x] + result;
      // insert a comma every third digit
      if ((x > 0) && ((c % 3) == 0))
        result = ',' + result;
      c++;
    }
    return result;
  }

  // #region Dropdowns

  /**
   * Gets the Contact Type criteria description.
   * 
   * Sample criteria item value:
   * { "ParameterName": "ContactTypes", "Value": 90429407939, "AndOr": 0, "SqlDbType": 0, "Comparison": 15, "Group": 0 }
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeContactTypeFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (!value || value == 0)
      return '';

    let contactTypeDescriptions = this.contactTypeService.Descriptions(value);
    if (!contactTypeDescriptions || contactTypeDescriptions.length == 0)
      return '';

    let result = '';
    let parts: string[] = contactTypeDescriptions.split(",");
    for (let x = 0; x < parts.length; x++) {
      if (x > 0)
        result += ', ';
      result += this.formatValue(parts[x].trim());
    }

    return result + '&nbsp;' + this.formatName('Contacts');
  }

  /**
   * Gets the State criteria description.
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeStateFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (!value || value == 0)
      return '';

    let andOrValue = item.AndOr;

    let phrase = '';
    for (let x = 0; x < value.length; x++) {
      if (x > 0) {
        if (x == (value.length - 1))
          if (item.Comparison == SqlComparison.NOTIN)
            phrase += ', ' + this.formatConstant(this.C_AND); // ', ' + this.FormatConstant(this.C_AND); // ', and ';
          else
            phrase += ', ' + this.formatConstant(this.C_OR); // ', ' + this.FormatConstant(this.C_OR); // ', or ';
        else
          phrase += ', ';
      }
      phrase += this.formatValue(value[x].trim());
    }

    let verb = this.C_IS;
    if (item.Comparison == SqlComparison.NOTIN)
      verb += '&nbsp;' + this.C_NOT;

    return this.translateAndOr(andOrValue) + this.formatName(this.translateParameterName(parameterName)) + this.formatConstant(verb) + phrase + '&nbsp;';
  }

  /**
   * Gets the Loan Status criteria description.
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeLoanStatusFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (!value || value.length == undefined || value.length == 0)
      return '';

    let loanTypes = this.filterHelperService.getLoanStatusDropdownItems();
    if (!loanTypes || loanTypes.length == 0)
      return '';

    let andOrValue = item.AndOr;
    //let phrase = this.translateAndOr(andOrValue);
    let phrase = '';

    for (let x = 0; x < value.length; x++) {
      if (x > 0) {
        if (x == (value.length-1))
          if (item.Comparison == SqlComparison.NOTIN)
            phrase += ', ' + this.formatConstant(this.C_AND); // ', ' + this.FormatConstant(this.C_AND); // ', and ';
          else
            phrase += ', ' + this.formatConstant(this.C_OR); // ', ' + this.FormatConstant(this.C_OR); // ', or ';
        else
          phrase += ', ';
      }
      let val: any = loanTypes.find(y => y.code == value[x]).name;
      phrase += this.formatValue(val.replaceAll(' ', '&nbsp;'));
    }

    let verb = this.C_IS;
    if (item.Comparison == SqlComparison.NOTIN)
      verb +=  '&nbsp;' + this.C_NOT;

    return this.translateAndOr(andOrValue) + this.formatName(this.translateParameterName(parameterName)) + this.formatConstant(verb) + phrase + '&nbsp;';
  }

  /**
   * Gets the Loan Purpose (Type) criteria description.
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeLoanPurposeFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let item = criteria.Items.find(x => x.ParameterName === parameterName);
    if ((item == undefined) || (item == null))
      return '';
    let value = item.Value;
    if (!value || value.length == 0)
      return '';

    let loanTypes = this.filterHelperService.getLoanPurposeDropdownItems();
    if (!loanTypes || loanTypes.length == 0)
      return '';

    let andOrValue = item.AndOr;
    //let phrase = this.translateAndOr(andOrValue);
    let phrase = '';

    for (let x = 0; x < value.length; x++) {
      if (x > 0) {
        if (x == (value.length - 1))
          if (item.Comparison == SqlComparison.NOTIN)
            phrase += ', ' + this.formatConstant(this.C_AND); // ', ' + this.FormatConstant(this.C_AND); // ', and ';
          else
            phrase += ', ' + this.formatConstant(this.C_OR); // ', ' + this.FormatConstant(this.C_OR); // ', or ';
        else
          phrase += ', ';
      }
      let val: any = loanTypes.find(y => y.code == value[x]).name;
      phrase += this.formatValue(val.replaceAll(' ', '&nbsp;'));
    }

    let verb = this.C_IS;
    if (item.Comparison == SqlComparison.NOTIN)
      verb += '&nbsp;' + this.C_NOT;

    return this.translateAndOr(andOrValue) + this.formatName(this.translateParameterName(parameterName)) + this.formatConstant(verb) + phrase + '&nbsp;';
  }

  // #endregion

  // #region Dates

  /**
   * Gets the Month(s) and optional Day of the month criteria description.
   * 
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param parameterName The name of the criteria value to process.
   */
  makeMonthAndDayFilterDescription(criteria: WhereClauseCriteriaCollection, parameterName: string) {
    if ((criteria == undefined) || (criteria == null) || (criteria.Items.length == 0))
      return '';

    let months = criteria.Items.find(x => x.ParameterName === ('MONTH(' + parameterName +')'));
    if ((!months) || (months.Value == null) || (months.Value.length == 0))
      return '';
    let monthNames = this.translateMonths(months.Value);
    let day = criteria.Items.find(x => x.ParameterName === ('DAY(' + parameterName + ')'));

    let andOrValue = months.AndOr;
    let phrase = this.translateAndOr(andOrValue);
    phrase += this.formatName(this.translateParameterName(parameterName));

    if ((!day) || (day.Value == null)) {
      phrase += (this.formatConstant(this.C_IS) + this.formatConstant(this.C_IN));
    }
    else {
      phrase += (this.formatConstant(this.C_IS) + this.formatConstant(this.C_ON) + 'the ');
      phrase += this.translateDay(parseInt(day.Value));
      phrase += ' of ';
    }
    phrase += monthNames;

    return phrase + '&nbsp;';
  }

  /**
   * Translates and array of month numbers (January = 1) to a readable string.
   * 
   * @param value Array of month numbers.
   */
  translateMonths(value: any) {
    let phrase = '';
    if ((value == undefined) || (value == null))
      return phrase;

    var months: string[] = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    for (let x = 0; x < value.length; x++) {
      if (x > 0) {
        if (value.length == 2)
          phrase += ' or ';
        else if (x == value.length - 1)
          phrase += ', or ';
        else
          phrase += ', ';
      }

      phrase += this.formatValue(months[value[x]]);
    }

    return phrase;
  }

  /**
   * Tranlates a day into a readable string like 1st, 2nd, 10th, etc.
   * 
   * @param value Day of the month.
   */
  translateDay(value: any) {
    if ((value == undefined) || (value == null))
      return '';

    var suffix = '';
    switch (value) {
      case 1:
      case 21:
      case 31:
        suffix = 'st'
        break;
      case 2:
      case 22:
        suffix = 'nd'
        break;
      case 3:
      case 23:
        suffix = 'rd'
        break;
      default:
        suffix = 'th'
        break;
    }
    return this.formatValue(value.toString() + suffix);
  }

  // #endregion

  // #region Keyword Search Criteria

  /**
   * Gets the Keyword search criteria description.
   *
   * Sample globalFilterCriteria argument:
   * { "Items": [
   *   { "ParameterName": "Name", "Value": "%111%", "AndOr": 0, "SqlDbType": 12, "Comparison": 10, "Group": 1 },
   *   { "ParameterName": "Email", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "MobilePhone", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "WorkPhone", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "HomePhone", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "Fax", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "Street", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "City", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "State", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "Zip", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "BirthDate", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 0 },
   *   { "ParameterName": "CompanyName", "Value": "%111%", "AndOr": 1, "SqlDbType": 12, "Comparison": 10, "Group": 2 }
   * ] }
   *
   * @param criteria User entered criteria (excludes default criteria and search keyword criteria).
   * @param globalFilterCriteria Search keyword criteria.
   */
  makeGlobalKeyFilterDescription(criteria: WhereClauseCriteriaCollection, globalFilterCriteria: WhereClauseCriteriaCollection) {
    if ((!criteria) || (!globalFilterCriteria) || (globalFilterCriteria.Items.length == 0))
      return '';

    let phrase = '';
    try {
      let value: String = '';
      let added = 0;
      for (let x = 0; x < globalFilterCriteria.Items.length; x++) {
        if (x == 0) {
          if (!globalFilterCriteria.Items[x].Value)
            return '';
          value = globalFilterCriteria.Items[x].Value.toString();
          if (value.trim().length == 0)
            return '';
          value = value.substring(1, value.length - 1);
          phrase = this.translateAndOr(globalFilterCriteria.Items[0].AndOr);
        }

        // is there user specified criteria for this field
        let existing = criteria.Items.find(c => c.ParameterName === globalFilterCriteria.Items[x].ParameterName);
        if ((existing != undefined) && (existing != null))
          continue;
        // could be month/optional day criteria
        existing = criteria.Items.find(c => c.ParameterName === 'MONTH('+ globalFilterCriteria.Items[x].ParameterName + ')');
        if ((existing != undefined) && (existing != null))
          continue;

        if (added > 0) {
          phrase += ', ';
        }
        added++;
        phrase += this.formatName(this.translateParameterName(globalFilterCriteria.Items[x].ParameterName), false);
      }

      if (added > 0) {
        phrase += "&nbsp;" + this.formatConstant(this.C_CONTAINS) + this.formatValue("'" + value + "'");
        let commaIndex = phrase.lastIndexOf(",");
        if (commaIndex > 0) {
          phrase = phrase.substring(0, commaIndex) + '&nbsp;or' + phrase.substring(commaIndex + 1, phrase.length);
        }
      }
      else {
        phrase = '';
      }
    }
    catch {
      return '';
    }

    return phrase;
  }

  // #endregion

  // #region Translation Methods

  /**
   * Translates and formats WhereClauseCritera AndOr values to a readable name.
   * 
   * @param value
   */
  translateAndOr(value: number) {
    if ((value == undefined) || (value == null))
      return this.formatConstant(this.C_AND);

    if (value == 0)
      return this.formatConstant(this.C_AND);
    return this.formatConstant(this.C_OR);
  }

  /**
   * Translates and formats WhereClauseCritera LIKE and NOTLIKE Comparison values to a readable name.
   * 
   * Translates LIKE to CONTAINS, STARTS WITH, and ENDS WITH based upon the value passed.
   * Translates NOT LIKE ... to DOES NOT CONTAIN, DOES NOT START WITH, and DOES NOT END WITH based upon the value passed.
   * (the value is not contained in returned string - see translateLikeValue)
   *
   * @param comparisonDescription
   * @param value
   */
  translateLike(comparisonDescription: string, value: string) {
    if ((comparisonDescription == undefined) || (comparisonDescription == null) || (comparisonDescription.length == 0))
      return '';
    if ((value == undefined) || (value == null) || (value.length == 0))
      return comparisonDescription;

    if (comparisonDescription.indexOf(this.formatConstant(this.C_NOT) + this.formatConstant(this.C_LIKE)) >= 0) {
      let prefix = (this.formatConstant(this.C_DOES) + this.formatConstant(this.C_NOT));
      if ((value[0] == '%') && (value[value.length - 1] == '%')) {
        return (prefix + this.formatConstant(this.C_CONTAIN));
      }
      else if (value[0] == '%') {
        return (prefix + this.formatConstant(this.C_END) + this.formatConstant(this.C_WITH));
      }
      else if (value[value.length - 1] == '%') {
        return (prefix + this.formatConstant(this.C_START) + this.formatConstant(this.C_WITH));
      }
    }
    else if (comparisonDescription.indexOf(this.formatConstant(this.C_LIKE)) >= 0) {
      if ((value[0] == '%') && (value[value.length - 1] == '%')) {
        return (this.formatConstant(this.C_CONTAINS));
      }
      else if (value[0] == '%') {
        return (this.formatConstant(this.C_ENDS) + this.formatConstant(this.C_WITH));
      }
      else if (value[value.length - 1] == '%') {
        return (this.formatConstant(this.C_STARTS) + this.formatConstant(this.C_WITH));
      }
    }

    return comparisonDescription;
  }

  /**
   * Removes starting and ending '%' characters.
   *
   * @param value
   */
  translateLikeValue(value: string) {
    if ((value == undefined) || (value == null) || (value.length == 0))
      return '';

    let result = value;
    if (result[result.length - 1] == '%')
      result = result.substring(0, value.length - 1);
    if (result[0] == '%')
      result = result.substring(1);

    return result;
  }

  /**
   * Translates and formats WhereClauseCritera Comparison values to a readable name.
   * 
   * @param comparison WhereClauseCritera Comparison property value.
   */
  translateComparison(comparison: number) {
    if ((comparison == undefined) || (comparison == null))
      return '';

    //  + (this.labels ? '&nbsp;' : '') + 
    let comparisonDescription = 'is '
    switch (comparison) {
      /**
      * Equals
      */
      case SqlComparison.EQ:
        comparisonDescription += (this.formatConstant(this.C_EQUAL) + 'to ');
        break;
      /**
      * Not Equal
      */
      case SqlComparison.NOTEQ:
        comparisonDescription += (this.formatConstant(this.C_NOT) + this.formatConstant(this.C_EQUAL) + 'to ');
        break;
      /**
      * Less Than
      */
      case SqlComparison.LT:
        comparisonDescription += (this.formatConstant(this.C_LESS) + this.formatConstant(this.C_THAN));
        break;
      /**
      * Greater Than
      */
      case SqlComparison.GT:
        comparisonDescription += (this.formatConstant(this.C_GREATER) + this.formatConstant(this.C_THAN));
        break;
      /**
      * Less Than or Equals
      */
      case SqlComparison.LTEQ:
        comparisonDescription += (this.formatConstant(this.C_LESS) + this.formatConstant(this.C_THAN) + 'or ' + this.formatConstant(this.C_EQUAL) + 'to ');
        break;
      /**
      * Greater Than or Equals
      */
      case SqlComparison.GTEQ:
        comparisonDescription += (this.formatConstant(this.C_GREATER) + this.formatConstant(this.C_THAN) + 'or ' + this.formatConstant(this.C_EQUAL) + 'to ');
        break;
      /**
      * Is Null
      */
      case SqlComparison.ISNULL:
        comparisonDescription += (this.formatConstant(this.C_IS) + this.formatConstant(this.C_NOTHING));
        break;
      /**
      * Is Not Null
      */
      case SqlComparison.ISNOTNULL:
        comparisonDescription += (this.formatConstant(this.C_IS) + this.formatConstant(this.C_ANYTHING));
        break;
      /**
      * In
      */
      case SqlComparison.IN:
        comparisonDescription += this.formatConstant(this.C_IN);
        break;
      /**
      * Not In
      */
      case SqlComparison.NOTIN:
        comparisonDescription += (this.formatConstant(this.C_NOT) + this.formatConstant(this.C_IN));
        break;
      /**
      * Like
      */
      case SqlComparison.LIKE:
        comparisonDescription += this.formatConstant(this.C_LIKE);
        break;
      /**
      * Not Like
      */
      case SqlComparison.NOTLIKE:
        comparisonDescription += (this.formatConstant(this.C_NOT) + this.formatConstant(this.C_LIKE));
        break;

      default:
        comparisonDescription = '';
    }

    return comparisonDescription;
  }


  /**
   * Translates and formats WhereClauseCritera Comparison values to a readable name.
   * 
   * @param comparison WhereClauseCritera Comparison property value.
   */
  translateDateComparison(comparison: number) {
    if ((comparison == undefined) || (comparison == null))
      return '';

    //  + (this.labels ? '&nbsp;' : '') + 
    //let comparisonDescription = 'is '
    let comparisonDescription = ''
    switch (comparison) {
      /**
      * Equals
      */
      case SqlComparison.EQ:
        comparisonDescription += (this.formatConstant(this.C_ON));
        break;
      /**
      * Less Than
      */
      case SqlComparison.LT:
        comparisonDescription += (this.formatConstant(this.C_BEFORE));
        break;
      /**
      * Greater Than
      */
      case SqlComparison.GT:
        comparisonDescription += (this.formatConstant(this.C_AFTER));
        break;
      /**
      * Less Than or Equals
      */
      case SqlComparison.LTEQ:
        comparisonDescription += (this.formatConstant(this.C_ON) +  'or ' + this.formatConstant(this.C_BEFORE));
        break;
      /**
      * Greater Than or Equals
      */
      case SqlComparison.GTEQ:
        comparisonDescription += (this.formatConstant(this.C_ON) + 'or ' + this.formatConstant(this.C_AFTER));
        break;
      /**
      * Is Null
      */
      case SqlComparison.ISNULL:
        comparisonDescription += (this.formatConstant(this.C_IS) + this.formatConstant(this.C_NOTHING));
        break;
      /**
      * Is Not Null
      */
      case SqlComparison.ISNOTNULL:
        comparisonDescription += (this.formatConstant(this.C_IS) + this.formatConstant(this.C_ANYTHING));
        break;
      /**
      * In
      */
      case SqlComparison.IN:
        comparisonDescription += this.formatConstant(this.C_IN);
        break;
      /**
      * Not In
      */
      case SqlComparison.NOTIN:
        comparisonDescription += (this.formatConstant(this.C_NOT) + this.formatConstant(this.C_IN));
        break;
      /**
      * Like
      */
      case SqlComparison.LIKE:
        comparisonDescription += this.formatConstant(this.C_LIKE);
        break;
      /**
      * Not Like
      */
      case SqlComparison.NOTLIKE:
        comparisonDescription += (this.formatConstant(this.C_NOT) + this.formatConstant(this.C_LIKE));
        break;

      default:
        comparisonDescription = '';
    }

    return comparisonDescription;
  }

  // #endregion
} 
