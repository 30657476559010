import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookupService } from '../../../../../../src/services/lookup.service';
import { CalendarAndTimeZoneService } from '../../../../../app/services/CalendarAndTimeZoneService';
import { ContactEventVM, DropDownList, HolidayEventVM, TimezoneVM } from '../../../../../models/LookupModel';
import { CMSComponentData, ContactStatusData, HolidayStatusData, SavedContactQuickPick, SavedHolidayQuickPick } from '../../../../../models/MessageScheduleData';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-one-time-contact-quick-pick',
  templateUrl: './campaign-message-schedule-contact-quick-pick.component.html',
  styleUrls: ['./campaign-message-schedule-contact-quick-pick.component.scss'],
})
export class CampaignMessageScheduleContactQuickPickComponent
  implements OnInit
{
  oneTimeContactForm: FormGroup | any = null;
  public ContactEventList: ContactEventVM[];
  public TimezoneList: TimezoneVM[];
  public HourList: any[];
  public MinList: any[];
  public AMPMList: any[];
  public campaignMessageId: string;
  public CMSComponentData: CMSComponentData;
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public ContactQuickPickData: ContactStatusData;
  userTimeZoneId: any;
  isFormValueChange = false;
  userToken: UserToken;
  nextScheduleNotification: string = '';
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  constructor(
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private userTokenService: UserTokenService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(1);
    this.ContactQuickPickData = new ContactStatusData();
    this.userToken = this.userTokenService.getUser();
    this.messageType =
      this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe =
      'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
  }

  ngOnInit(): void {
    this.HourList = this.calendarAndTimeZoneService.GetHours();
    this.MinList = this.calendarAndTimeZoneService.GetMinutes();
    this.AMPMList = this.calendarAndTimeZoneService.GetMeridiems();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;
    let campaignMessageId =
      this.campaignMessageScheduleDataService.campaignMessageId;

    forkJoin(
      this.lookupService.GetContacts(),
      this.lookupService.GetTimezones()
    ).subscribe(([contactresponse, timezonesresponse]) => {
      this.TimezoneList = timezonesresponse.data;
      this.ContactEventList = contactresponse.data;
      this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
      let contactEventId = this.ContactEventList[0].contactEventId;
      if (this.userTimeZoneId && contactEventId) {
        let defaultObject =
          this.calendarAndTimeZoneService.getDefaultDateTimeObject(
            this.userTimeZoneId,
            this.TimezoneList
          );
        this.ContactQuickPickData.Hour = defaultObject.defaultHour;
        this.ContactQuickPickData.Minute = defaultObject.defaultMinute;
        (this.ContactQuickPickData.AMPM =
          this.messageType == 2 && this.allowTCPAForTextMessages
            ? this.calendarAndTimeZoneService.getMeridiem(
                defaultObject.defaultHour
              )
            : defaultObject.defaultMeridiem),
          (this.ContactQuickPickData.ContactEventId = contactEventId);
        this.ContactQuickPickData.TimezoneId = defaultObject.defaultTimeZoneId;
      }
      let savedObject = new SavedContactQuickPick();
      if (
        scheduleDataJSON &&
        campaignMessageId &&
        scheduleDataJSON != 'No Schedule' &&
        scheduleType == 'OneTime' &&
        ScheduleSubtype == 'ContactQuickPick'
      ) {
        savedObject = scheduleDataJSON ? JSON.parse(scheduleDataJSON)[0] : {};
        if (savedObject) {
          this.ContactQuickPickData.ContactEventId =
            savedObject?.ContactEventId?.toLowerCase();
          this.ContactQuickPickData.TimezoneId =
            savedObject?.TimezoneId?.toLowerCase();

          let dateTimeHelper = new DateTimeHelper();
          var time = dateTimeHelper.Get12HourTimeComponent(savedObject.Time);
          if (time) {
            this.ContactQuickPickData.Hour = parseInt(time.hours);
            this.ContactQuickPickData.Minute = parseInt(time.minutes);
            this.ContactQuickPickData.AMPM = time.AmPM;
          }
        }
      }
      this.createForm();
    });
  }

  createForm() {
    this.oneTimeContactForm = this.formBuilder.group({
      hour: [this.ContactQuickPickData.Hour],
      minute: [this.ContactQuickPickData.Minute],
      meridiem: [this.ContactQuickPickData.AMPM],
      timeZone: [this.ContactQuickPickData.TimezoneId, Validators.required],
      contact: [this.ContactQuickPickData.ContactEventId, Validators.required],
    });
    this.oneTimeContactForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;
      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.fillFormData();
    }
  }
  fillFormData() {
    this.ContactQuickPickData.ContactEventId =
      this.oneTimeContactForm.value.contact;
    let selectedContact = this.ContactEventList.find(
      (x) =>
        x.contactEventId.toLowerCase() === this.oneTimeContactForm.value.contact
    );
    this.ContactQuickPickData.scheduleJson = selectedContact.scheduleJSON;
    this.ContactQuickPickData.TimezoneId =
      this.oneTimeContactForm.value.timeZone;
    let hr = Number(this.oneTimeContactForm.value.hour);
    let mi = Number(this.oneTimeContactForm.value.minute);
    let mer = this.oneTimeContactForm.value.meridiem;
    this.ContactQuickPickData.Hour = hr;
    this.ContactQuickPickData.Minute = mi;
    this.ContactQuickPickData.AMPM = mer;
    this.doEmit();
  }
  private doEmit() {
    this.CMSComponentData.componentValueJSON = this.ContactQuickPickData;
    this.CMSComponentData.campaignMessageId =
      this.campaignMessageScheduleDataService.campaignMessageId;
    this.CMSComponentData.componentNumber = 10;
    if (
      this.ContactQuickPickData.ContactEventId &&
      this.ContactQuickPickData.TimezoneId
    ) {
      this.ContactQuickPickData.TimezoneInfoId = this.TimezoneList.find(
        (x) =>
          x.timezoneId.toLowerCase() === this.ContactQuickPickData.TimezoneId
      )?.timezoneInfoId;
      let timeOffset = this.TimezoneList.find(
        (x) =>
          x.timezoneId.toLowerCase() === this.ContactQuickPickData.TimezoneId
      )?.timeZoneOffsetInHours;

      this.nextScheduleNotification =
        this.calendarAndTimeZoneService.getNextScheduleMessage(
          this.ContactQuickPickData.TimezoneInfoId,
          timeOffset,
          this.ContactQuickPickData.scheduleJson,
          this.ContactQuickPickData.Hour,
          this.ContactQuickPickData.Minute,
          0,
          this.ContactQuickPickData.AMPM
        );
      //if all values are set
      this.SubmitEvent.emit(this.CMSComponentData);
    }
  }
  OnHourChange(event) {
    if (this.messageType == 2 && this.allowTCPAForTextMessages) {
      let selectedHour = this.oneTimeContactForm.value.hour;
      this.oneTimeContactForm.patchValue({
        meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
      });
    }
  }
  private ClearObject() {
    this.ContactEventList = [];
    this.TimezoneList = [];
    this.HourList = [];
    this.MinList = [];
    this.AMPMList = [];
  }
}
