import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import { LySliderChange, STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import { STYLES as CROPPER_STYLES, LyImageCropper, ImgCropperConfig, ImgCropperEvent, ImgCropperErrorEvent } from '@alyle/ui/image-cropper';
import { AppSettings } from 'src/appSettings';
import { ToastService } from 'src/services/toast.service';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl`{
      ${cropper.root} {
        max-width: 320px
        height: 250px
      }
    }`,
    sliderContainer: lyl`{
      position: relative
      ${slider.root} {
        width: 75%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl`{
      padding: 1em
      
    }`,
    cropResult: lyl `{
      border-radius: 50%
    }`
  };
};

@Component({
  templateUrl: './cropper-dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ],
  styleUrls: ['./cropper-dialog.scss']
})

export class CropperDialog implements WithStyles, AfterViewInit {
  readonly classes = this.sRenderer.renderSheet(STYLES, 'root');
  ready: boolean;
  scale: number;
  minScale: number;

  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    width: 150,
    height: 150,
    // type: 'image/png',
    keepAspectRatio: true,
    responsiveArea: true,
    round: true,
    output: {
      width: 66,
      height: 66
    },
    resizableArea: true
  };

  constructor(
    @Inject(LY_DIALOG_DATA) private event: Event,
    readonly sRenderer: StyleRenderer,
    public dialogRef: LyDialogRef,
    private toastService: ToastService
  ) { }

  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.event);
    });
  }

  onCropped(e: ImgCropperEvent) {
    console.log('cropped img: ', e);
  }

  onLoaded(e: ImgCropperEvent) {
    // even if someone set the mode to multiple, we only allow one image
    if (e.size > AppSettings.MAX_IMAGE_SIZE) {
      this.cropper.clean();
      this.toastService.showError(AppSettings.IMAGE_SIZE_ERROR_MSG);
      return;
    }
    console.log('img loaded', e);
  }
  
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }

  onSliderInput(event: LySliderChange) {
    this.scale = event.value as number;
  }
}
