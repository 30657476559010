import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class CommonHelperService {

  constructor() { }


  public UnSubscribeAllSubscription(sub: Array<Subscription>) {
    if (sub?.length) {
      sub.forEach((element) => {
        if (element) {
          element.unsubscribe();
        }
      });
      sub = [];
    }
  }
}
