import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookupService } from '../../../../../../src/services/lookup.service';
import { CalendarAndTimeZoneService } from '../../../../../app/services/CalendarAndTimeZoneService';
import { DropDownList, HolidayEventVM, TimezoneVM } from '../../../../../models/LookupModel';
import { CMSComponentData, HolidayStatusData, SavedHolidayQuickPick } from '../../../../../models/MessageScheduleData';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-one-time-holiday-quick-pick',
  templateUrl: './campaign-message-schedule-holiday-quick-pick.component.html',
  styleUrls: ['./campaign-message-schedule-holiday-quick-pick.component.scss'],
})
export class CampaignMessageScheduleHolidayQuickPickComponent
  implements OnInit
{
  oneTimeHolidayForm: FormGroup | any = null;
  public HolidayEventList: HolidayEventVM[];
  public TimezoneList: TimezoneVM[];
  public HourList: any[];
  public MinList: any[];
  public AMPMList: any[];
  public campaignMessageId: string;
  public CMSComponentData: CMSComponentData;
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public HolidayQuickPickData: HolidayStatusData;
  userTimeZoneId: any;
  isFormValueChange = false;
  userToken: UserToken;
  nextScheduleNotification: string = '';
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  constructor(
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private userTokenService: UserTokenService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(1);
    this.HolidayQuickPickData = new HolidayStatusData();
    this.userToken = this.userTokenService.getUser();
    this.messageType =
      this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe =
      'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
  }

  ngOnInit(): void {
    this.HourList = this.calendarAndTimeZoneService.GetHours();
    this.MinList = this.calendarAndTimeZoneService.GetMinutes();
    this.AMPMList = this.calendarAndTimeZoneService.GetMeridiems();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;
    let campaignMessageId =
      this.campaignMessageScheduleDataService.campaignMessageId;

    forkJoin(
      this.lookupService.GetHolidays(),
      this.lookupService.GetTimezones()
    ).subscribe(([holidayresponse, timezonesresponse]) => {
      this.TimezoneList = timezonesresponse.data;
      this.HolidayEventList = holidayresponse.data;
      this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
      let holidayEventId = this.HolidayEventList[0].holidayEventId;
      if (this.userTimeZoneId && holidayEventId) {
        let defaultObject =
          this.calendarAndTimeZoneService.getDefaultDateTimeObject(
            this.userTimeZoneId,
            this.TimezoneList
          );
        this.HolidayQuickPickData.Hour = defaultObject.defaultHour;
        this.HolidayQuickPickData.Minute = defaultObject.defaultMinute;
        (this.HolidayQuickPickData.AMPM =
          this.messageType == 2 && this.allowTCPAForTextMessages
            ? this.calendarAndTimeZoneService.getMeridiem(
                defaultObject.defaultHour
              )
            : defaultObject.defaultMeridiem),
          (this.HolidayQuickPickData.HolidayEventId = holidayEventId);
        this.HolidayQuickPickData.TimezoneId = defaultObject.defaultTimeZoneId;
      }
      let savedObject = new SavedHolidayQuickPick();
      if (
        scheduleDataJSON &&
        campaignMessageId &&
        scheduleDataJSON != 'No Schedule' &&
        scheduleType == 'OneTime' &&
        ScheduleSubtype == 'HolidayQuickPick'
      ) {
        savedObject = scheduleDataJSON ? JSON.parse(scheduleDataJSON)[0] : {};
        if (savedObject) {
          this.HolidayQuickPickData.HolidayEventId =
            savedObject?.HolidayEventId?.toLowerCase();
          this.HolidayQuickPickData.TimezoneId =
            savedObject?.TimezoneId?.toLowerCase();

          let dateTimeHelper = new DateTimeHelper();
          var time = dateTimeHelper.Get12HourTimeComponent(savedObject.Time);
          if (time) {
            this.HolidayQuickPickData.Hour = parseInt(time.hours);
            this.HolidayQuickPickData.Minute = parseInt(time.minutes);
            this.HolidayQuickPickData.AMPM = time.AmPM;
          }
        }
      }
      this.createForm();
    });
  }

  createForm() {
    this.oneTimeHolidayForm = this.formBuilder.group({
      hour: [this.HolidayQuickPickData.Hour],
      minute: [this.HolidayQuickPickData.Minute],
      meridiem: [this.HolidayQuickPickData.AMPM],
      timeZone: [this.HolidayQuickPickData.TimezoneId, Validators.required],
      holiday: [this.HolidayQuickPickData.HolidayEventId, Validators.required],
    });
    this.oneTimeHolidayForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;
      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.fillFormData();
    }
  }
  fillFormData() {
    this.HolidayQuickPickData.HolidayEventId =
      this.oneTimeHolidayForm.value.holiday;
    let selectedHoliday = this.HolidayEventList.find(
      (x) =>
        x.holidayEventId.toLowerCase() === this.oneTimeHolidayForm.value.holiday
    );
    this.HolidayQuickPickData.scheduleJson = selectedHoliday.scheduleJSON;
    this.HolidayQuickPickData.TimezoneId =
      this.oneTimeHolidayForm.value.timeZone;
    let hr = Number(this.oneTimeHolidayForm.value.hour);
    let mi = Number(this.oneTimeHolidayForm.value.minute);
    let mer = this.oneTimeHolidayForm.value.meridiem;
    this.HolidayQuickPickData.Hour = hr;
    this.HolidayQuickPickData.Minute = mi;
    this.HolidayQuickPickData.AMPM = mer;
    this.doEmit();
  }
  private doEmit() {
    this.CMSComponentData.componentValueJSON = this.HolidayQuickPickData;
    this.CMSComponentData.campaignMessageId =
      this.campaignMessageScheduleDataService.campaignMessageId;
    this.CMSComponentData.componentNumber = 1;
    if (
      this.HolidayQuickPickData.HolidayEventId &&
      this.HolidayQuickPickData.TimezoneId
    ) {
      this.HolidayQuickPickData.TimezoneInfoId = this.TimezoneList.find(
        (x) =>
          x.timezoneId.toLowerCase() === this.HolidayQuickPickData.TimezoneId
      )?.timezoneInfoId;
      let timeOffset = this.TimezoneList.find(
        (x) =>
          x.timezoneId.toLowerCase() === this.HolidayQuickPickData.TimezoneId
      )?.timeZoneOffsetInHours;

      this.nextScheduleNotification =
        this.calendarAndTimeZoneService.getNextScheduleMessage(
          this.HolidayQuickPickData.TimezoneInfoId,
          timeOffset,
          this.HolidayQuickPickData.scheduleJson,
          this.HolidayQuickPickData.Hour,
          this.HolidayQuickPickData.Minute,
          0,
          this.HolidayQuickPickData.AMPM
        );
      //if all values are set
      this.SubmitEvent.emit(this.CMSComponentData);
    }
  }
  OnHourChange(event) {
     if (this.messageType == 2 && this.allowTCPAForTextMessages) {
       let selectedHour = this.oneTimeHolidayForm.value.hour;
       this.oneTimeHolidayForm.patchValue({
         meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
       });
     }
  }
  private ClearObject() {
    this.HolidayEventList = [];
    this.TimezoneList = [];
    this.HourList = [];
    this.MinList = [];
    this.AMPMList = [];
  }
}
