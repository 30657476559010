import { timestamp } from 'rxjs/operators';

export class SearchParam {
  public pageNum: number;
  public pageSize: number;
  public reportType: number = 0;
  public sortColumn: string = '';
  public sortOrder: string = '';
  public filterStr: string = '';
  public filterJson: string = '';
  public timeStamp: string = '';

  constructor(
    pageNum: number,
    pageSize: number,
    reportType: number = 0,
    sortColumn: string = '',
    sortOrder: string = '',
    filterStr: string = '',
    filterJson: string = '',
    timeStamp: string = ''
  ) {
    if (pageNum !== undefined && pageNum !== null) this.pageNum = pageNum;
    if (pageSize !== undefined && pageSize !== null) this.pageSize = pageSize;
    if (reportType !== undefined && reportType !== null) this.reportType = reportType;
    if (sortColumn !== undefined && sortColumn !== null) this.sortColumn = sortColumn;
    if (sortOrder !== undefined && sortOrder !== null) this.sortOrder = sortOrder;
    if (filterStr !== undefined && filterStr !== null) this.filterStr = filterStr;
    if (filterJson !== undefined && filterJson !== null) this.filterJson = filterJson;
    this.timeStamp = new Date().getTime().toString();
  }
}
