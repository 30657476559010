<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" style="display:none;">

  <symbol id="file-contract" width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.0392 29H3C1.89543 29 1 28.1046 1 27V3C1 1.89543 1.89543 1 3 1H14.9229C15.4864 1 16.0237 1.23771 16.4028 1.65465L24.7146 10.7976C25.0554 11.1725 25.2412 11.6627 25.2345 12.1693L25.039 27.0263C25.0246 28.1205 24.1335 29 23.0392 29Z"
      stroke="white" stroke-width="2" />
    <path
      d="M23.9557 10.9362H16.8068C16.3328 10.9362 15.8782 10.7479 15.543 10.4127C15.2078 10.0775 15.0195 9.62294 15.0195 9.14893V2"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.89453 7.0603H9.95836" stroke="white" stroke-width="2" stroke-linecap="round" />
    <path d="M5.19043 10.6354H10.2543" stroke="white" stroke-width="2" stroke-linecap="round" />
    <path
      d="M4 23.6219C4.88167 23.8657 6.82637 23.8999 7.55186 22.086C8.45872 19.8187 9.29 16.308 10.4236 19.965C11.5571 23.6219 11.2549 23.9145 12.3129 22.086C13.3709 20.2575 14.8067 21.1352 15.5624 22.3786C16.3181 23.6219 16.1019 23.4436 18.5957 23.4436C20.0851 23.4436 20.6808 23.4436 20.6808 23.4436"
      stroke="white" stroke-width="2" stroke-linecap="round" />
  </symbol>
  <symbol id="closing-icon" width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1345 18.0714H3C1.61929 18.0714 0.5 16.9521 0.5 15.5714V3C0.5 1.61929 1.61929 0.5 3 0.5H8.73241C9.43678 0.5 10.1085 0.797132 10.5823 1.31832L15.0845 6.27083C15.5105 6.73941 15.7428 7.35219 15.7345 7.98541L15.6342 15.6043C15.6162 16.9721 14.5023 18.0714 13.1345 18.0714Z"
      stroke="#C2C2C2" />
    <path
      d="M14.8298 6.76995H10.4042C10.1108 6.76995 9.82939 6.65339 9.6219 6.4459C9.41442 6.23841 9.29785 5.957 9.29785 5.66357V1.23804"
      stroke="#C2C2C2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.03003 4.37061H6.16478" stroke="#C2C2C2" stroke-linecap="round" />
    <path d="M3.21313 6.58386H6.34789" stroke="#C2C2C2" stroke-linecap="round" />
    <path
      d="M2.47607 14.6231C3.02187 14.774 4.22573 14.7951 4.67484 13.6723C5.23623 12.2687 5.75084 10.0954 6.45257 12.3592C7.15431 14.6231 6.96718 14.8042 7.62213 13.6723C8.27708 12.5404 9.16595 13.0837 9.63377 13.8534C10.1016 14.6231 9.96771 14.5127 11.5115 14.5127C12.4335 14.5127 12.8023 14.5127 12.8023 14.5127"
      stroke="#C2C2C2" stroke-linecap="round" />
  </symbol>
</svg>
<div class="content table-search-row second-header-fixed mb-1">
  <table class="text-wrapper">
    <tr>
      <td align="left" valign="middle" width="40%" class="page-header">
        <i class="pi pi-bell"
          style="margin-right:0.5rem; margin-left:.5rem; font-weight: 600; color: #2e2e2e;font-size:22px!important;"></i><span
          style="margin-left: 0.2em;">Reminders</span>
      </td>
      <td valign="middle" align="right" class="reminders">
        <i *ngIf="(birthDaysThisWeekLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        <a routerLink="../people-and-loans/birthdays-this-week" class="dashboard text-hover-under"><span
            *ngIf="!(birthDaysThisWeekLoading)" class="analytics">{{birthDaysThisWeek | number}}</span>
          <span class="description rt-space">Birthdays this week</span>
        </a>
        <i *ngIf="(loanAniversariesThisWeekLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        <a routerLink="../people-and-loans/loans-anniversaries-this-week" class="dashboard text-hover-under"><span
            *ngIf="!(loanAniversariesThisWeekLoading)" class="analytics ">{{loanAniversariesThisWeek | number}}</span>
          <span class="description">Loan anniversaries this week</span></a>
      </td>
    </tr>
  </table>
</div>

<div #analyticsDiv id="analyticsDiv" class="text-wrapper pt-7 grid-section-analytics">
  <div>
    <div class="p-grid nested-grid" *ngIf="!showMarketingAdminOnly">
      <div class="p-col-7 analytics-left header"><i class="pi pi-user"
          style="margin-left:0; font-weight: 600; font-size: 22px;"></i><span>Leads</span>
      </div>
      <div class="p-col-5 analytics-text">
        <div class=" pr-0 analytics-right pb-3 year-dropdown" style="float:right; margin-top: -10px;">
          <p-dropdown [editable]="false" [options]="timeframes" [(ngModel)]="selectedTimeframe" optionValue="code"
            id="lastDays" optionLabel="name" (onChange)="onTimeframeSelectionChanged($event)"></p-dropdown>
        </div>
        <div style="float:right;">
          <!-- showRefreshBtn::{{showRefreshBtn}} Actualtime|{{ lastRefreshedTime | date : 'H:mm:ss' }}| -->
          <ng-container *ngIf="showRefreshBtn">
            Last refreshed at {{ lastRefreshedTime | date : 'shortTime' }}
            <a (click)="refreshNow()"
              style="cursor: pointer;margin: 0px 17.5px; color:#6e11f2;text-decoration: none;font-weight: 500;"
              styleClass="p-button-secondary">Refresh Now</a>
          </ng-container>
        </div>
      </div>
      <div class="col analytics-left mt-4">
        <div class="p-grid">

          <div class="p-col position-relative">
            <p-panel routerLink="../people-and-loans/active-leads"
              [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'ProspectStatusDate', operand:'GTE'}">
              <p-header>
                <i *ngIf="(activeLeadsLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <span *ngIf="!(activeLeadsLoading)">{{activeLeads | number}}</span>
              </p-header>
              <p class="p-m-0 dashboard-subtitle">
                Active Leads
              </p>
            </p-panel>
          </div>
          <div class="p-col position-relative">
            <p-panel routerLink="../people-and-loans/converted-from-lead-to-loan"
              [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'ProspectStatusDate,LoanApplicationDate', operand:'GTE'}">
              <p-header>
                <i *ngIf="(convertedFromLeadToLoanLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <!-- <a *ngIf="!(convertedFromLeadToLoanLoading)"
                  routerLink="../lists/loans/status:converted">{{convertedFromLeadToLoan}}</a> -->
                <span *ngIf="!(convertedFromLeadToLoanLoading)">{{convertedFromLeadToLoan | number}}</span>
              </p-header>
              <p class="p-m-0 dashboard-subtitle">
                Converted from Lead to Loan
              </p>
            </p-panel>
          </div>
          <div class="p-col position-relative">
            <p-panel routerLink="../people-and-loans/application-phase-loans"
              [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'LoanApplicationDate', operand:'GTE'}">
              <p-header>
                <i *ngIf="(applicationPhaseLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <!-- <a *ngIf="!(applicationPhaseLoading)"
                  routerLink="../lists/loans/status:application">{{applicationPhase | number}}</a> -->
                <span *ngIf="!(applicationPhaseLoading)">{{applicationPhase | number}}</span>
              </p-header>
              <p class="p-m-0 dashboard-subtitle">
                Application Phase
              </p>
            </p-panel>
          </div>
          <div class="p-col-12 analytics-left header">

            <i class="fa-light fa-circle-check"
              style="margin-right: .5rem; margin-left:0; font-size: 22px !important; font-weight:500; color: #2e2e2e;"></i>


            <span style="margin-left: 5px !important;">Closing Activity</span>
          </div>
          <div class="p-col-6">
            <p-panel class="closing ph-headerIcon" routerLink="../people-and-loans/cancelled-loans"
              [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'LoanCancelledDate,LoanDeniedDate', operand:'GTE'}">
              <p-header>
                <i *ngIf="(cancelledLoansLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <span *ngIf="!(cancelledLoansLoading)">{{cancelledLoans
                  | number}}</span>
                <!-- <span *ngIf="!(cancelledLoansLoading)">{{cancelledLoans}}</span> -->
              </p-header>
              <p class="p-m-0 dashboard-subtitle">
                Cancelled Loans
              </p>
            </p-panel>
          </div>
          <div class="p-col-6">
            <p-panel class="closing ph-headerIcon" routerLink="../people-and-loans/closed-loans"
              [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'LoanClosedDate,LoanFundedDate', operand:'GTE'}">
              <p-header>
                <i *ngIf="(closedLoansLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <span *ngIf="!(closedLoansLoading)">{{closedLoans |
                  number}}</span>
              </p-header>
              <p class="p-m-0 dashboard-subtitle">
                Closed Loans
              </p>
            </p-panel>
          </div>
        </div>
      </div>
      <div class="col-3 analytics-right mt-4">
        <p-card>
          <div class="header">
            <i class="fa-light fa-file-contract fa-2x" style="margin-left: .5rem;"></i>
            <br />
            <h6 style="margin-top:1rem">Loans</h6>
          </div>
          <table style="width:100%;">
            <tr>
              <td>
                <i *ngIf="(activeLoansLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; display:table"></i>
                <a *ngIf="!(activeLoansLoading)" routerLink="../people-and-loans/active-loans"
                  [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'FileStartedDate', operand:'GTE'}">
                  <font class="analyticsFont">{{activeLoans | number}}</font>
                </a>
                <a routerLink="../people-and-loans/active-loans"
                  [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'FileStartedDate', operand:'GTE'}">
                  Active Loans
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <i *ngIf="(refiLoansLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; display:table"></i>
                <!-- <a *ngIf="!(refiLoansLoading)" routerLink="../lists/loans/status:refi">
                  <font class="analyticsFont">{{refiLoans}}</font>
                </a>
                <a routerLink="../lists/loans/status:refi">Refi Loans</a> -->
                <a *ngIf="!(refiLoansLoading)" routerLink="../people-and-loans/refi-loans"
                  [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'FileStartedDate', operand:'GTE'}">
                  <font class="analyticsFont">{{refiLoans | number}}</font>
                </a>
                <span>Refi Loans</span>
              </td>
            </tr>
            <tr>
              <td>
                <i *ngIf="(purchaseLoansLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; display:table"></i>
                <!-- <a *ngIf="!(purchaseLoansLoading)" routerLink="../lists/loans/status:purchase">
                  <font class="analyticsFont">{{purchaseLoans | number}}</font>
                </a>
                <a routerLink="../lists/loans/status:purchase">Purchase Loans</a> -->
                <span *ngIf="!(purchaseLoansLoading)">
                  <a *ngIf="!(purchaseLoansLoading)" routerLink="../people-and-loans/purchase-loans"
                    [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'FileStartedDate', operand:'GTE'}">
                    <font class="analyticsFont">{{purchaseLoans | number}}</font>
                  </a>
                </span>
                <span>Purchase Loans</span>
              </td>
            </tr>
            <tr>
              <td>
                <i *ngIf="(otherLoansLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; display:table"></i>
                <!-- <a *ngIf="!(otherLoansLoading)" routerLink="../lists/loans/status:other">
                  <font class="analyticsFont">{{otherLoans | number}}</font>
                </a>
                <a routerLink="../lists/loans/status:other">Other Loans</a> -->
                <a *ngIf="!(otherLoansLoading)" routerLink="../people-and-loans/other-loans"
                  [queryParams]="{dayrange: selectedTimeframe, loanstatusfield:'FileStartedDate', operand:'GTE'}">
                  <font class="analyticsFont">{{otherLoans | number}}</font>
                </a>
                <span>Other Loans</span>
              </td>
            </tr>
          </table>
        </p-card>
      </div>
      <div class="col-3 analytics-right mt-4" *ngIf="showDeliveryRates">
        <p-card>
          <div class="header">
            <i class="fa-light fa-envelope" style="margin-left: .5rem; font-size: 28px; font-weight: 500;"></i>
            <br />
            <h6 style="margin-top:1rem">Marketing</h6>
          </div>
          <table style="width:100%;">
            <tr>
              <td>
                <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; display:table"></i>
                <font *ngIf="(!marketingDataLoading)" class="analyticsFont">{{marketingData?.statusAlertSentCount |
                  number}}
                </font>Status alerts sent
              </td>
            </tr>
            <tr>
              <td>
                <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; padding-bottom: 5px; display:table"></i>
                <font *ngIf="(!marketingDataLoading)" class="analyticsFont">{{marketingData?.statusTextSentCount |
                  number}}
                </font>Status texts sent
              </td>
            </tr>
            <tr>
              <!-- <td>
                <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 45px; margin-bottom: 10px; display:table"></i>
                <font *ngIf="(!marketingDataLoading)" class="analyticsFont">{{marketingData?.marketingEmailSentCount | number}} 
                </font>
                <font class="soon-analytics">Coming soon
                </font>Marketing emails sent
              </td> -->
              <td>
                <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; padding-bottom: 5px; display:table"></i>
                <font *ngIf="(!marketingDataLoading)" class="analyticsFont">{{marketingData?.marketingEmailSentCount |
                  number}}
                </font>Marketing emails sent
              </td>
            </tr>
            <tr>
              <!-- <td>
                <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 45px; margin-bottom: 10px; display:table"></i>
                <font *ngIf="(!marketingDataLoading)" class="analyticsFont">{{marketingData?.marketingTextSentCount | number}}
                </font>
                <font class="soon-analytics">Coming soon
                </font>Marketing texts sent
              </td> -->
              <td>
                <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner"
                  style="font-size: 48px; margin-bottom: 10px; padding-bottom: 5px; display:table"></i>
                <font *ngIf="(!marketingDataLoading)" class="analyticsFont">{{marketingData?.marketingTextSentCount |
                  number}}
                </font>Marketing texts sent
              </td>
            </tr>
          </table>
        </p-card>
      </div>
    </div>
    <div class="p-grid" *ngIf="showMarketingAdminOnly">
      <div class="p-col-8 analytics-left header"><i class="fa-light fa-envelope"
          style="margin-left:0;color: #2e2e2e;font-size:22px!important; font-weight: 500;"></i><span>Marketing</span>
      </div>
      <div class="p-col-3 analytics-text">
        <ng-container *ngIf="showRefreshBtn">
          Last refreshed at {{ lastRefreshedTime | date : 'shortTime' }}
          <!-- Last refreshed at {{ lastRefreshedTime | date : 'H:mm:ss' }} (to test) -->
          <a (click)="refreshNow()"
            style="cursor: pointer;margin: 0px 17.5px; color:#2e2e2e;text-decoration: underline;font-weight: 500;"
            styleClass="p-button-secondary">Refresh Now</a>
        </ng-container>
      </div>
      <div class="p-col-1 analytics-right pb-3 pr-0">
        <p-dropdown [editable]="false" [options]="timeframes" [(ngModel)]="selectedTimeframe" optionValue="code"
          id="lastDays" optionLabel="name" (onChange)="onTimeframeSelectionChanged($event)"></p-dropdown>
      </div>
      <div class="p-col-3">
        <p-panel>
          <p-header>
            <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <!-- <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/campaigns/status:emails">{{marketingData?.statusAlertSentCount | number}}</a> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.statusAlertSentCount | number}}</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Status Alerts Sent
          </p>
        </p-panel>
      </div>
      <div class="p-col-3">
        <p-panel>
          <p-header>
            <i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <!-- <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/messages/status:texts">{{marketingData?.statusTextSentCount | number}}</a> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.statusTextSentCount | number}}</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Status Texts Sent
          </p>
        </p-panel>
      </div>
      <div class="p-col-3">
        <p-panel>
          <p-header
            class="soon-analytics"><!--<i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/campaigns/marketing:emails">{{marketingData?.marketingEmailSentCount | number}}</a> -->
            <!-- <span>Coming soon</span> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.marketingEmailSentCount | number}}</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Marketing Emails Sent
          </p>
        </p-panel>
      </div>
      <div class="p-col-3 pr-0">
        <p-panel class="last-divcard">
          <p-header class="soon-analytics">
            <!--<i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/campaigns/marketing:texts">{{marketingData?.marketingTextSentCount | number}}</a>-->
            <!-- <span>Coming soon</span> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.marketingTextSentCount | number}}</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Marketing Texts Sent
          </p>
        </p-panel>
      </div>
      <div class="p-col-3">
        <p-panel class="closing">
          <p-header><i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <!-- <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/messages/status:opened">{{marketingData?.openedPercent}}%</a> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.openedPercent}}%</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Opened
          </p>
        </p-panel>
      </div>
      <div class="p-col-3">
        <p-panel class="closing">
          <p-header><i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <!-- <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/messages/status:click">{{marketingData?.clickedPercent}}%</a> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.clickedPercent}}%</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Clicked
          </p>
        </p-panel>
      </div>
      <div class="p-col-3 tooltip_width"
        pTooltip="Emails that weren't able to be delivered because the email address is permanently unavailable, an email server outage is occurring, or the recipient's inbox is full."
        tooltipPosition="top">
        <p-panel class="closing">
          <p-header><i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <!-- <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/messages/status:spam">{{marketingData?.bouncedPercent}}%</a> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.bouncedPercent}}%</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Bounced
          </p>
        </p-panel>
      </div>
      <div class="p-col-3 pr-0">
        <p-panel class="closing last-divcard">
          <p-header><i *ngIf="(marketingDataLoading)" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <!-- <a *ngIf="(!marketingDataLoading)"
              routerLink="../lists/messages/status:deliverable">{{marketingData?.optedOutPercent}}%</a> -->
            <span *ngIf="(!marketingDataLoading)">{{marketingData?.optedOutPercent}}%</span>
          </p-header>
          <p class="p-m-0 dashboard-subtitle">
            Opted Out
          </p>
        </p-panel>
      </div>
    </div>
  </div>
</div>
<br />

<!-- Spinner while loading the page
 <p-dialog #loginSpinner class="pulseLoadingDialog"
          [draggable]="false" [resizable]="false" [(visible)]="activeLeadsLoading" [modal]="false"
          [closeOnEscape]="false" closeable="false" [showHeader]="false"
          [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem;"></i>
</p-dialog> -->
