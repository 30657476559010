<!--
*************************************************************************************
Toast Support (MessageService)
****************************************************************************************
-->
<p-toast position="bottom-right" sticky="true" [baseZIndex]="999999"></p-toast>

<div class="container-fluid viewport-view" (BeforeUnloadEvent)="onBeforeUnload($event)" *ngIf="isSessionAlive">
  <!--  <i class="fa fa-message"></i>-->
  <div class="content-section">
    <div class="header-container d-flex align-items-center" *ngIf="!clickwrap && !hideHeaderFooter">
      <div *ngIf="user?.original_userfullname"
        style="width: 100%;background: yellow;display: block;position: absolute;top: 0px;text-align: center; z-index: 999999 !important;">
        <table>
          <tr>
            <td style="padding-left:255px">
              Logged in as {{user.original_userfullname}}. Impersonating: {{user.getFullName()}} [{{user.TenantName}}].
              Role: {{role}}
            </td>

            <td style="padding-left:25px">
              <div role="button" (click)="changeImpersonatedUser()"><u> Switch Tenant</u></div>
            </td>
          </tr>
        </table>
      </div>
      <div class="header-main text-wrapper d-flex align-items-center py-2" style="margin-top: 20px;">
        <div class="logo">
          <a href="/dashboard" role="button">
            <img *ngIf="currentClientID === 'Aduvo'" src="assets/images/aduvo-logo.png"
              style="width:84px; margin-left: 5px;" alt="" />
            <img *ngIf="currentClientID === 'AduvoAdmin'" src="assets/images/aduvo-admin-logo.png"
              style="width:84px; margin-left: 5px;" alt="" />
          </a>
        </div>
        <div class="d-flex menu-items">
          <div class="dropdown mx-3" *ngIf="currentClientID === 'Aduvo'">
            <a class="menu-header font-weight-bold" [routerLink]="['/dashboard']" routerLinkActive="active"
              [ngClass]="{'active': homeRLA.isActive || dashboardRLA.isActive}">
              Dashboard
            </a>
            <a class="menu-header" [routerLink]="['/']" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}" #homeRLA="routerLinkActive"></a>
            <a class="menu-header" [routerLink]="['/dashboard']" routerLinkActive="active"
              #dashboardRLA="routerLinkActive"></a>
          </div>
          <div class="dropdown mx-4" *ngIf="user?.hasMarketingAccess() && currentClientID === 'AduvoAdmin'">
            <span class="dropdown-toggle menu-header font-weight-bold" [ngClass]="{'active': contentRLA.isActive || quickStartRLA.isActive || aduvoRecommendedRLA.isActive ||
                imageRLA.isActive || contactEvtRLA.isActive || holidayEvtRLA.isActive}" id="marketing-dropdown"
              data-bs-toggle="dropdown" aria-expanded="false">
              Marketing
            </span>
            <ul class="dropdown-menu" aria-labelledby="marketing-dropdown">
              <li style="border-bottom: 1px solid #ced4da;">
                <a (click)="clearCache()" class="dropdown-item" [routerLink]="['/marketing/content-library-filter/All']"
                  routerLinkActive="active" #contentRLA="routerLinkActive" href="javascript:void(0)">Content Library</a>
              </li>
              <li>
                <a (click)="clearCache()" class="dropdown-item"
                  [routerLink]="['/marketing/content-library-filter/AduvoQuickStart']" routerLinkActive="active"
                  #quickStartRLA="routerLinkActive">Aduvo Quick Start</a>
              </li>
              <li style="border-bottom: 1px solid #ced4da;">
                <a (click)="clearCache()" class="dropdown-item"
                  [routerLink]="['/marketing/content-library-filter/AduvoRecommended']" routerLinkActive="active"
                  #aduvoRecommendedRLA="routerLinkActive">Aduvo Recommended</a>
              </li>
              <li style="border-bottom: 1px solid #ced4da;">
                <a class="dropdown-item" [routerLink]="['/marketing/content-image-library']" routerLinkActive="active"
                  #imageRLA="routerLinkActive">Image Library</a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/marketing/contact-events']" routerLinkActive="active"
                  #contactEvtRLA="routerLinkActive">Contact Events</a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="['/marketing/holidays']" routerLinkActive="active"
                  #holidayEvtRLA="routerLinkActive">Holidays</a>
              </li>
            </ul>
          </div>
          <div class="dropdown mx-4" *ngIf="user?.hasMarketingAccess() && currentClientID !== 'AduvoAdmin'">
            <span class="dropdown-toggle menu-header font-weight-bold" [ngClass]="{'active': contentRLA.isActive || myContentRLA.isActive || activeContentRLA.isActive ||
                quickStartRLA.isActive || aduvoRecommendedRLA.isActive ||
                imageRLA.isActive}" id="marketing-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
              Marketing
            </span>
            <ul class="dropdown-menu py-0 header-menu" aria-labelledby="marketing-dropdown">
              <!-- <li><a class="dropdown-item" [routerLink]="['/marketing/campaigns']" routerLinkActive="active"
                  #campaignRLA="routerLinkActive">Campaigns</a></li>
              <li ><a  class="dropdown-item" [routerLink]="['/marketing/template-library']" routerLinkActive="active"
                  #templateRLA="routerLinkActive">Template Library</a></li> -->
              <li style="border-bottom: 1px solid #ced4da;">
                <!-- <a class="dropdown-item" [routerLink]="['/marketing/content-library']" routerLinkActive="active"
                  #contentRLA="routerLinkActive" href="javascript:void(0)">Content Library</a> -->
                <a (click)="clearCache()" class="dropdown-item" [routerLink]="['/marketing/content-library-filter/All']"
                  routerLinkActive="active" #contentRLA="routerLinkActive" href="javascript:void(0)">
                  Content
                  Library
                </a>
              </li>
              <li>
                <a (click)="clearCache()" class="dropdown-item"
                  [routerLink]="['/marketing/content-library-filter/MyContent']" routerLinkActive="active"
                  #myContentRLA="routerLinkActive">
                  My
                  Content
                </a>
              </li>
              <li>
                <a (click)="clearCache()" class="dropdown-item"
                  [routerLink]="['/marketing/content-library-filter/ActiveContent']" routerLinkActive="active"
                  #activeContentRLA="routerLinkActive">Active Content</a>
              </li>
              <li>
                <a (click)="clearCache()" class="dropdown-item"
                  [routerLink]="['/marketing/content-library-filter/AduvoQuickStart']" routerLinkActive="active"
                  #quickStartRLA="routerLinkActive">Aduvo Quick Start</a>
              </li>
              <li style="border-bottom: 1px solid #ced4da;">
                <a (click)="clearCache()" class="dropdown-item"
                  [routerLink]="['/marketing/content-library-filter/AduvoRecommended']" routerLinkActive="active"
                  #aduvoRecommendedRLA="routerLinkActive">Aduvo Recommended</a>
              </li>
              <!-- <li style="border-bottom: 1px solid #ced4da;">
                <a class="dropdown-item" [routerLink]="['/marketing/content-library-filter/All']"
                  routerLinkActive="active" #allContentRLA="routerLinkActive">All Content</a>
              </li> -->
              <li>
                <a class="dropdown-item" [routerLink]="['/marketing/content-image-library']" routerLinkActive="active"
                  #imageRLA="routerLinkActive">Image Library</a>
              </li>
            </ul>
          </div>
          <div class="dropdown mx-4" *ngIf="currentClientID === 'Aduvo'">
            <span class="dropdown-toggle menu-header font-weight-bold" id="people&loans-dropdown" data-bs-toggle="dropdown"
              aria-expanded="false"
              [ngClass]="{'active': allContactRLA.isActive || activeLoanRLA.isActive || closedLoanRLA.isActive  || listRLA.isActive || isActiveList}">
              People & Loans
            </span>
            <ul class="dropdown-menu py-0" aria-labelledby="people&loans-dropdown">
              <!-- <li *ngFor="let menu of reports">
                <a class="dropdown-item" [routerLink]="[menu.routerLink]" routerLinkActive="active">{{menu.label}}</a>
              </li>  -->
              <li>
                <a class="dropdown-item flex" [routerLink]="['people-and-loans/all-contacts']" routerLinkActive="active"
                  #allContactRLA="routerLinkActive"><span style="margin: auto auto auto 0px;">All Contacts</span></a>
              </li>
              <hr />
              <label style="padding-left: 15px; color: #797979;">Lists</label>
              <li>
                <a class="dropdown-item flex" [routerLink]="['people-and-loans/active-loans']" routerLinkActive="active"
                  #activeLoanRLA="routerLinkActive">︎<span style="margin: auto auto auto 0px;">Active Loans</span></a>
              </li>
              <li>
                <a class="dropdown-item flex" [routerLink]="['people-and-loans/closed-loans']" routerLinkActive="active"
                  #closedLoanRLA="routerLinkActive"><span style="margin: auto auto auto 0px;">Closed Loans</span></a>
              </li>
              <li>
                <a class="dropdown-item flex" [routerLink]="['people-and-loans/lists']" routerLinkActive="active"
                  #listRLA="routerLinkActive"><span style="margin: auto auto auto 0px;">All Lists  </span></a>
              </li>
              <!-- <li><a class="dropdown-item" [routerLink]="['people-and-loans/birthdays-this-week']" routerLinkActive="active" #birthRLA="routerLinkActive">Birthdays this week</a></li>
              <li><a class="dropdown-item" [routerLink]="['people-and-loans/loans-anniversaries-this-week']" routerLinkActive="active" #anniversaryRLA="routerLinkActive">Loan Anniversaries this week</a></li> -->
            </ul>
          </div>
          <div class="dropdown mx-3" *ngIf="currentClientID === 'AduvoAdmin'">
            <a class="menu-header font-weight-bold" [routerLink]="['/account/manage-users']" routerLinkActive="router-link-active">
              Manage Users
            </a>
          </div>
          <div class="dropdown mx-3" *ngIf="currentClientID === 'AduvoAdmin'">
            <a class="menu-header font-weight-bold" [routerLink]="['/account/client-manager']" routerLinkActive="router-link-active">
              Manage Clients
            </a>
          </div>
          <div class="dropdown mx-4" *ngIf="currentClientID === 'AduvoAdmin'">
            <span class="dropdown-toggle menu-header font-weight-bold" id="report-dropdown" data-bs-toggle="dropdown"
              aria-expanded="false">
              Reports
            </span>
            <ul class="dropdown-menu" aria-labelledby="marketing-dropdown">
              <li>
                <a class="dropdown-item" (click)="CreateAuditReport()" routerLinkActive="active">
                  Tenant User
                  Activity
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="ms-auto d-flex align-items-center">
          <div class="dropdown mx-4" *ngIf="user?.hasMarketingAccess() && currentClientID === 'Aduvo'">
            <button class="btn btn-outline-primary dropdown-toggle create-btn rounded-pill create-new-button" type="button"
              id="create-new-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <span>Create New</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="create-new-dropdown">
              <!-- <li><a class="dropdown-item" (click)="CreateNewCampaign()">Campaign</a></li>  -->
              <li><a class="dropdown-item" [routerLink]="['/marketing/campaigns/create/new']">Campaign</a></li>
              <li><a class="dropdown-item" [routerLink]="['/marketing/template-library/email']">Email</a></li>
              <li><a class="dropdown-item" [routerLink]="['/marketing/template-library/sms']">SMS Text Message</a></li>
              <li><a class="dropdown-item" (click)="onImportContactsClick()">Import Contacts</a></li>
            </ul>

            <!-- <ul class="dropdown-menu" aria-labelledby="create-new-dropdown">

              <label style="padding-left: 15px; color: #797979;">Contact</label>
              <li><a class="dropdown-item" [routerLink]=" ">Lead</a></li>
              <li><a class="dropdown-item" [routerLink]=" ">Realtor</a></li>
              <li><a class="dropdown-item" [routerLink]=" ">Other Contact</a></li>
              <li><a class="dropdown-item" [routerLink]=" ">Upload Contact</a></li>
              <hr />
              <label style="padding-left: 15px; color: #797979;">Marketing</label>
              <li><a class="dropdown-item" [routerLink]=" ">Campaign</a></li>
              <li><a class="dropdown-item" [routerLink]=" ">Template</a></li>
            </ul> -->
          </div>

          <!-- Bell Icon -->
          <div class="dropdown mx-4" *ngIf="currentClientID === 'Aduvo'">
            <button type="button" id="notification-dropdown" data-bs-toggle="dropdown" aria-expanded="false"
              class="transparent-btn">
              <div>
                <i class="fal fa-bell fa-lg me-2" style="color: #5241a4; font-size: 2em; cursor: pointer;"></i>
                <span class="button__badge" *ngIf="showBellNotification"></span>
              </div>
            </button>
            <div class="dropdown-menu" aria-labelledby="notification-dropdown" style="width: 300px;">
              <div *ngFor="let message of messageList; let i = index">
                <span>
                  <a class="dropdown-item word-wrap text-color-lite" style="cursor: default;"
                    *ngIf="!message.item_masterlistId">{{ message.item_text }}</a>
                  <a class="dropdown-item word-wrap text-color-lite" style="cursor: default;"
                    *ngIf="message.item_masterlistId">{{ message.item_text }}
                    <span class="text-decoration-underline text-color-lite" style="cursor: pointer;"
                      (click)="viewListClick(message.item_masterlistId)">Click here to view the list.</span></a>
                </span>
              </div>
              <span><a class="dropdown-item" (click)="navigateToNotificationCenter()">See All Notifications</a></span>
            </div>
          </div>

          <div class="me-4" style="height: 35px; border-right: 1px solid #dee2e6;"
            *ngIf="user?.hasMarketingAccess() && currentClientID === 'Aduvo'"></div>
          <!-- <p-tieredMenu #createContactMenu [popup]="true" [model]="createContactTypes"></p-tieredMenu> -->
          <div class="d-flex align-items-center" style="width: 60px;">
            <!-- <p-avatar icon="pi pi-user" shape="circle" size="large" (click)="avatarMenu.toggle($event)"></p-avatar> -->
            <!--          <i class="fa fa-caret-down blue" (click)="avatarMenu.toggle($event)"></i>-->
            <div class="dropdown" style="height: 80px;">
              <span class="dropdown-toggle menu-header avatar-dropdown-menu-header" id="avatar-menu-dropdown"
                data-bs-toggle="dropdown" aria-expanded="false">
                <p-avatar *ngIf="!userLogo" shape="circle" size="large" class="logonname" [label]="userNameInitials">
                </p-avatar>
                <p-avatar *ngIf="userLogo" [image]='userLogo' shape="circle" size="large"></p-avatar>
              </span>
              <ul class="dropdown-menu" aria-labelledby="avatar-menu-dropdown">
                <li>
                  <a class="dropdown-item" [routerLink]="['/account/my-profile']"
                    routerLinkActive="router-link-active">My Profile</a>
                </li>
                <li *ngIf="user?.isCompanyAdmin() && currentClientID === 'Aduvo'">
                  <a class="dropdown-item" [routerLink]="['/company-profile']"
                    routerLinkActive="router-link-active">Company Profile</a>
                </li>
                <li *ngIf="currentClientID === 'AduvoAdmin'">
                  <a class="dropdown-item" [routerLink]="['/company-profile/add']"
                    routerLinkActive="router-link-active">Add Company</a>
                </li>
                <!-- <li *ngIf="currentClientID === 'AduvoAdmin'">
                  <a class="dropdown-item" [routerLink]="['/manage-tenant']"
                    routerLinkActive="router-link-active">Manage Tenants</a>
                </li> -->
                <li *ngIf="user?.isCompanyAdmin()">
                  <a class="dropdown-item" [routerLink]="['/account/manage-users']"
                    routerLinkActive="router-link-active">Manage Users</a>
                </li>
                <li *ngIf="currentClientID === 'AduvoAdmin'">
                  <a class="dropdown-item" [routerLink]="['/account/client-manager']"
                    routerLinkActive="router-link-active">Manage Clients</a>
                </li>
                <li *ngIf="currentClientID === 'DISABLED'">
                  <!-- Set to 'Aduvo' to enable. Disabled until azure key vault is implemented for Zendesk credentials -->
                  <a class="dropdown-item" [routerLink]="['/submit-a-ticket']" routerLinkActive="active">Submit
                    Ticket</a>
                </li>
                <li>
                  <a class="dropdown-item" role='button' (click)="onLogOutClick('')">Log Out</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="logo text-center" *ngIf="clickwrap && !hideHeaderFooter">
      <img *ngIf="currentClientID === 'Aduvo'" src="assets/images/aduvo-logo.png" style="width:84px; margin-top: 15%;"
        alt="" />

    </div>

    <!--  <div style="width: 100%; background: var(&#45;&#45;light-gray); height: 1px;"></div>-->
    <!--  <div style="width: 100%; background: var(&#45;&#45;very-light-gray); height: 1px;"></div>-->
    <!-- Navigation -->
    <div class="content">
      <!--
        (onExploreMore)="onExploreMore($event)" is added to define the event that is emitted
        by the Dashboard component as defined in the dashboard.component.ts file by:
        @Output() onExploreMore: EventEmitter<Event> = new EventEmitter<Event>(true);
        The Application component (direct parent of the dashboard) implements the onExploreMore($event) method
        that receives and processes the event.
      -->
      <!--<dashboard *ngSwitchCase="'Dashboard'" [app]="this" [tabMenu]="tabMenu" (onExploreMore)="onExploreMore($event)"></dashboard>-->
      <!--<activeMessages-root *ngSwitchCase="'ActiveMessages'"></activeMessages-root>-->
      <!--<allMessages-root *ngSwitchCase="'AllMessages'"></allMessages-root>-->

      <router-outlet></router-outlet>
      <!--<reportManagement-root *ngSwitchCase="'ReportManagement'"></reportManagement-root>

      <reportEditor-root *ngSwitchCase="'AllContacts'" [(reportManagementPagesState)]="reportPageState" (onRefreshAllReports)="onRefreshAllReports($event)"></reportEditor-root>
      <reportEditor-root *ngSwitchCase="'ActiveContacts'" [(reportManagementPagesState)]="reportPageState" (onRefreshAllReports)="onRefreshAllReports($event)"></reportEditor-root>
      <reportEditor-root *ngSwitchCase="'ActiveLeads'" [(reportManagementPagesState)]="reportPageState" (onRefreshAllReports)="onRefreshAllReports($event)"></reportEditor-root>
      <reportEditor-root *ngSwitchCase="'ActiveLoans'" [(reportManagementPagesState)]="reportPageState" (onRefreshAllReports)="onRefreshAllReports($event)"></reportEditor-root>
      <reportEditor-root *ngSwitchCase="'ClosedLoans'" [(reportManagementPagesState)]="reportPageState" (onRefreshAllReports)="onRefreshAllReports($event)"></reportEditor-root>

      <userProfile-root *ngSwitchCase="'UserProfile'" (onLogOutClick)="onLogOutClick($event)" (onTokenTimeout)="onTokenTimeout()"></userProfile-root>
      <companyProfile-root *ngSwitchCase="'CompanyProfile'"></companyProfile-root>
      <userManagement-root *ngSwitchCase="'UserManagement'"></userManagement-root>
      <teamManagement-root *ngSwitchCase="'TeamManagement'"></teamManagement-root>
      <billing-root *ngSwitchCase="'Billing'"></billing-root>-->
      <!-- Sandboxes - // URGENT: These will be removed for production -->
      <!--<mike-root *ngSwitchCase="'Mike'"></mike-root>
      <neal-root *ngSwitchCase="'Neal'"></neal-root>
      <peter-root *ngSwitchCase="'Peter'"></peter-root>
      <robert-root *ngSwitchCase="'Robert'"></robert-root>-->
    </div>
  </div>

  <div class="footer-section" *ngIf="!hideHeaderFooter">
    <div class="footer-text">
      <p style="float: left;">&#169;{{currentYear}} focusIT, Inc. All Rights Reserved. </p>
      <ul>
        <li>
          <a href="https://vault.pactsafe.io/s/35a595ee-bb50-40e1-bfe2-60f8c0075d9d/legal.html#template-r4ks7ec0b"
            class="text-hover" target="_blank">Privacy Statement</a>
        </li>
        <li>
          <a href="https://vault.pactsafe.io/s/35a595ee-bb50-40e1-bfe2-60f8c0075d9d/legal.html#template-doc4w1mje"
            class="text-hover" target="_blank">Terms of Use</a>
        </li>
        <li>
          <a href="https://vault.pactsafe.io/s/35a595ee-bb50-40e1-bfe2-60f8c0075d9d/legal.html#template-lu7plhbi1"
            class="text-hover" target="_blank">Terms and Conditions</a>
        </li>
      </ul>
    </div>
    <div class="clearfix"></div>
  </div>
  <!--
  *************************************************************************************
  Data Validation Error Dialog
  *************************************************************************************
  -->

</div>

<p-dialog class="pulseErrorDialog" position="right" [(visible)]="showErrors"
  [style]="{width: '32em', 'font-weight': '700'}" [baseZIndex]="10000">
  <p-header>
    <i class="pi pi-exclamation-circle" style="font-size:1.3rem; margin-right:0.5rem;"></i>
    <span class="dialog-head">Data Validation Errors</span>
  </p-header>
  <div style="width: 97.5%; background: var(--light-gray); height: 1px;"></div>
  <div style="width: 97.5%; background: var(--very-light-gray); height: 1px;"></div>
  <div *ngIf="(errors.length > 0)">
    The information specified is not valid and cannot be saved.<br /><br />
    <p-table [value]="errors">
      <ng-template pTemplate="body" let-error>
        <tr>
          <td style="color:crimson; font-weight:bold; padding:.2rem .2rem;">{{error}}</td>
        </tr>
      </ng-template>
    </p-table>
    <br />
    Please correct all information.
  </div>
  <div *ngIf="(errors.length < 1)">
    <br />
    <i class="pi pi-check" style="color: mediumseagreen;"></i>
    <label style="color:mediumseagreen; padding-left:.5rem;">All Data is valid and may be saved.</label>
    <br />
  </div>
</p-dialog>

<p-dialog #loginSpinner class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="showSpinner"
  [modal]="true" [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem;"></i>
</p-dialog>

<!--
*************************************************************************************
Confirm Dialogs
*************************************************************************************
-->
<p-confirmDialog header="Warning" icon="pi pi-exclamation-triangle" [baseZIndex]="11000"></p-confirmDialog>
<p-dialog class="custom-dialog" id="change_tenant" #changeTenantDialog [(visible)]="showChangeTenantDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <p-header>
    <span class="dialog-head">Switch Tenant</span>
  </p-header>
  <div class="modal-content">
    <div class="mb-4 row">
      <label class="col-md-8 pl-0 col-form-label text-dark">Select Tenant</label>
      <span class="alert-header pl-0" style="font-size: 14px !important; color:#797a7b!important;"> New Tenant </span>
    </div>
    <div class="mb-2 row">
      <label class="col-md-12 pl-0 col-form-label text-dark">Select User</label>
      <span class="alert-header pl-0" style="font-size: 14px !important; color:#797a7b!important;"> New User </span>
    </div>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="text-right">
      <a (click)="showChangeTenantDialog = false" class="link-text"
        style="color:#2e2e2e;cursor: pointer;margin-right:17.5px; line-height:34px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button (click)="impersonateUser()" [style]="{'margin-left': '17.5px'}" label="Impersonate User"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Import Contact Dialogs
*************************************************************************************
-->
<!--  -->
<p-dialog *ngIf="isSessionAlive" #importDialog [(visible)]="showImportContactDialog" [resizable]="false" [modal]="true"
  [showHeader]="false" [style]="{  'max-height': '90%','max-width': '90%', 'width':'55em','font-weight': '700' }"
  [baseZIndex]="10000" (onHide)="closeImportDialog()">
  <app-contact-and-loan-import (onCloseEvent)="closeMe()" *ngIf="showImportContactDialog"></app-contact-and-loan-import>
</p-dialog>