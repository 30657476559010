import { AppSettings } from '../appSettings';

/** 
* Implementation of the Subscriber Activity.
*/
export class SubscriberActivity {

  public Email: string = '';
  public FName: string = '';
  public LName: string = '';
  public Link: string = '';
  public Timestamp: Date = null;
  public Requests: number = 0;
  public Opens: number = 0;
  public Clicks: number = 0;
  public SpamReports: number = 0;
  public Delivered: number = 0;
  public ModifiedBy: string = '';
  public ModifiedOnUtc: Date = null;

  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param email
  * @param fName
  * @param lName
  * @param link
  * @param timestamp
  * @param requests
  * @param opens
  * @param clicks
  * @param spamReports
  * @param delivered
  * @param ModifiedBy
  * @param ModifiedOnUtc
  */
  constructor(
    email?: string,
    fName?: string,
    lName?: string,
    link?: string,
    timestamp?: Date,
    requests?: number,
    opens?: number,
    clicks?: number,
    spamReports?: number,
    delivered?: number,
    modifiedBy?: string,
    modifiedOnUtc?: Date) {
    if ((email !== undefined) && (email !== null)) this.Email = email;
    if ((fName !== undefined) && (fName !== null)) this.FName = fName;
    if ((lName !== undefined) && (lName !== null)) this.LName = lName;
    if ((link !== undefined) && (link !== null)) this.Link = link;
    if ((timestamp !== undefined)) this.Timestamp = timestamp;
    if ((requests !== undefined) && (requests !== null)) this.Requests = requests;
    if ((opens !== undefined) && (opens !== null)) this.Opens = opens;
    if ((clicks !== undefined) && (clicks !== null)) this.Clicks = clicks;
    if ((spamReports !== undefined) && (spamReports !== null)) this.SpamReports = spamReports;
    if ((delivered !== undefined) && (delivered !== null)) this.Delivered = delivered;
    if ((modifiedBy !== undefined) && (modifiedBy !== null)) this.ModifiedBy = modifiedBy;
    if ((modifiedOnUtc !== undefined)) this.ModifiedOnUtc = modifiedOnUtc;
  }
}

/**
* Collection of  objects.
*/
export class SubscriberActivityCollection {
  Items: SubscriberActivity[] = [];
}

