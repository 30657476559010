export interface ICampaignMessageLog {
    campaignTrackingId: string; // Using string for Guid in TypeScript
    templateId: string;
    campaignId: string;
    campaignMessageId: string;
    recipientName: string;
    recipientEmail: string;
    recipientMobilePhone: string;
    messageName: string;
    messageSentDate: Date;
    messageType: number; // Using number for 'short' in TypeScript
    delivered: boolean;
    deliveredDate?: Date;
    dropped: boolean;
    droppedDate?: Date;
    bounced: boolean;
    bouncedDate?: Date;
    blocked: boolean;
    blockedDate?: Date;
    opened: boolean;
    openedDate?: Date;
    clicked: boolean;
    clickedDate?: Date;
    spamReported: boolean;
    spamReportedDate?: Date;
    unsubscribed: boolean;
    unsubscribedDate?: Date;
    isPublishDB: boolean;
    hasDroppedMessage: boolean;
    droppedMessage: string;
}

export class CampaignMessageLog implements ICampaignMessageLog {
    campaignTrackingId: string;
    templateId: string;
    campaignId: string;
    campaignMessageId: string;
    recipientName: string;
    recipientEmail: string;
    recipientMobilePhone: string;
    messageName: string;
    messageSentDate: Date;
    messageType: number;
    delivered: boolean;
    deliveredDate?: Date;
    dropped: boolean;
    droppedDate?: Date;
    bounced: boolean;
    bouncedDate?: Date;
    blocked: boolean;
    blockedDate?: Date;
    opened: boolean;
    openedDate?: Date;
    clicked: boolean;
    clickedDate?: Date;
    spamReported: boolean;
    spamReportedDate?: Date;
    unsubscribed: boolean;
    unsubscribedDate?: Date;
    isPublishDB: boolean;
    hasDroppedMessage: boolean;
    droppedMessage: string;

    constructor(data: ICampaignMessageLog) {
        this.campaignTrackingId = data.campaignTrackingId;
        this.templateId = data.templateId;
        this.campaignId = data.campaignId;
        this.campaignMessageId = data.campaignMessageId;
        this.recipientName = data.recipientName;
        this.recipientEmail = data.recipientEmail;
        this.recipientMobilePhone = data.recipientMobilePhone;
        this.messageName = data.messageName;
        this.messageSentDate = data.messageSentDate;
        this.messageType = data.messageType;
        this.delivered = data.delivered;
        this.deliveredDate = data.deliveredDate;
        this.dropped = data.dropped;
        this.droppedDate = data.droppedDate;
        this.bounced = data.bounced;
        this.bouncedDate = data.bouncedDate;
        this.blocked = data.blocked;
        this.blockedDate = data.blockedDate;
        this.opened = data.opened;
        this.openedDate = data.openedDate;
        this.clicked = data.clicked;
        this.clickedDate = data.clickedDate;
        this.spamReported = data.spamReported;
        this.spamReportedDate = data.spamReportedDate;
        this.unsubscribed = data.unsubscribed;
        this.unsubscribedDate = data.unsubscribedDate;
        this.isPublishDB = data.isPublishDB;
        this.hasDroppedMessage = data.hasDroppedMessage;
        this.droppedMessage = data.droppedMessage;
    }
}
