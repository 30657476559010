import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { of, Subject, Subscription } from 'rxjs';
import { PagingData } from 'src/models/PagingData';
import { RequestResponse } from 'src/models/RequestResponse';
import { Router } from '@angular/router';
import { ToastService } from 'src/services/toast.service';
import { PeopleAndLoanService } from '../../../src/services/peopleandloans.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from 'src/components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { StorageHelper } from '../../helpers/StorageHelper';
import { PeopleAndLoanFilterData } from '../../models/PeopleAndLoanFilterOutput';
import { UserDefineList } from '../models/UserDefineList';
import { SearchParam } from '../models/SearchParam';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
//import {ConfirmPopupModule} from 'primeng/confirmpopup';
//import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-people-and-loans-list',
  templateUrl: './people-and-loans-list.component.html',
  styleUrls: ['./people-and-loans-list.component.scss']
})

export class PeopleAndLoansListComponent implements OnInit, OnDestroy {
  public loadingPage: boolean = false;
  public subscription: Array<Subscription> = [];
  public reportType: number = 0;
  public reportData: any;
  public createListReportType: number = 0;

  @ViewChild('PeopleLoanDataView', { static: false }) dataTable: Table;
  @ViewChild('CreateListTableId', { static: false }) createListDataTable: Table;

  public webAPI: RequestResponse;
  public createListWebAPI: RequestResponse;

  sortField = "Name";
  sortOrder = "ASC";
  searchKeyword: string = '';
  newListName: string = '';
  showCreateNewDialog: boolean = false;
  showGrid: boolean = false;
  allList = [];
  createListSortField = "Name";
  createListSortOrder = "ASC";
  createList = [];
  listNameExists: boolean = false;
  listNameRequired: boolean = false;
  storageHelper: StorageHelper;
  jsonStr: string;
  createUserDefinedList: UserDefineList = new UserDefineList();
  viewSelectedList: UserDefineList = new UserDefineList();
  noRecordFound: boolean = false;
  public showCustomMsg: boolean = false;
  private searchParam$ = new Subject<SearchParam>();
  private searchObservable: any;
  public canDeleteList: boolean = false;
  public disableCreateNew: boolean;
  user: UserToken;
  public pageIndex: number = 0;
  constructor(
    private peopleAndLoanService: PeopleAndLoanService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    public dialogService: DialogService,
    private router: Router,
    private userTokenService: UserTokenService,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
  ) {
    this.storageHelper = new StorageHelper();
    this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
    this.jsonStr = '';
    this.showCustomMsg = false;
    this.disableCreateNew = true;
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.id) {
      this.reportType = +this.activatedRoute.snapshot.data.id;
    }

    this.user = this.userTokenService.getUser();

    //set data in storage.
    let loanJSON = (this.peopleAndLoanService.getFilterJson('all.json'));
    loanJSON = '';
    // let loanJSON = (this.peopleAndLoanService.getFilterJson('Loan.json'));
    // let peopleJSON = (this.peopleAndLoanService.getFilterJson('People.json'));
    // loanJSON = peopleJSON = '';

    this.disableCreateNew = this.deactivateCreateNew()//sets first time runs every 5 second
    if (this.disableCreateNew) {//still disabled then wait & recheck again. 
      let myInterval = setInterval((x) => {
        //check 
        this.disableCreateNew = this.deactivateCreateNew();
        if (!this.disableCreateNew) {
          clearInterval(myInterval);
        }
      }, 5000);
    }


    this.webAPI = new RequestResponse();
    this.webAPI.paging.currentPage = 1; // will be set to -1 =0
    this.webAPI.paging.pageSize = 12;
    this.createListWebAPI = new RequestResponse();
    this.createListWebAPI.paging.currentPage = 1; // will be set to -1 =0
    this.createListWebAPI.paging.pageSize = 25;

    //search on demand
    this.searchObservable = this.searchParam$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchParams => {
        this.loadingPage = true;
        if (this.showCreateNewDialog) {
          return this.peopleAndLoanService.GetAllContact(searchParams);
        }
        else {
          return this.peopleAndLoanService.GetAllList(searchParams);
        }

      }),
      catchError(err => of(null))
    ).subscribe((response: RequestResponse) => {
      if (!this.jsonStr && !this.showCreateNewDialog) {
        if (response?.status == 200 && response?.message == '') {
          this.createListWebAPI.paging.totalRecords =
            response.pageInfo.totalRecords;
          // assign returned object to class variable.
          this.createListWebAPI.data = response.data; // Required - do not remove.
          this.createList = this.createListWebAPI.data;
          //this.createList.slice();
          this.loadingPage = false;
        } else {
          this.toastService.showError(response?.message ?? 'Some error occured, please try again!');
        }
      } else {
        if (response && response.status == 200 && response?.message == '') {
          // assign returned object to class variable.
          this.createListWebAPI.data = response.data; // Required - do not remove.
          this.createListWebAPI.paging.totalRecords = this.createListWebAPI.data.totalContactsCount;
          this.createList = this.createListWebAPI.data.contacts;
          this.loadingPage = false;
        } else {
          this.loadingPage = false;
          this.toastService.showError(response?.message ?? 'Some error occured, please try again!');
        }
      }
    }, err => {
      this.loadingPage = false;
      console.log(err);
      this.toastService.showError('Some error occured, please try again!');
    });
  }

  private deactivateCreateNew() {
    let flag1 = this.peopleAndLoanService.filterJsonLoaded('all.json');
    if (flag1) {//has been loaded return false;
      return false;
      //this.cdr.detectChanges();
    } else { return true; }// set disabled.
    // let flag1 = this.peopleAndLoanService.filterJsonLoaded('Loan.json');
    // let flag2 = this.peopleAndLoanService.filterJsonLoaded('People.json');
    // if (flag1 && flag2) {//has been loaded return false;
    //   return false;
    //   //this.cdr.detectChanges();
    // } else { return true; }// set disabled.
  }

  async refreshData() {
    this.loadingPage = true;
    try {
      let currentPage = this.webAPI.paging.currentPage - 1;
      let pageSize = this.webAPI.paging.pageSize;
      let searchParam = new SearchParam(currentPage, pageSize, this.reportType, this.sortField, this.sortOrder, this.searchKeyword)
      this.subscription.push(
        this.peopleAndLoanService.GetAllList(searchParam).subscribe(
          (response) => {
            if (response.status == 200 && response?.message == '') {
              this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;

              if (response.pageInfo.totalRecords < 1) {
                this.noRecordFound = true;
              }

              // assign returned object to class variable.
              this.webAPI.data = response.data; // Required - do not remove.

              for (let x = 0; x < this.webAPI.data.length; x++) {
                if (this.user.isCompanyAdmin() || this.user.isMarketingAdmin()) {
                  this.webAPI.data[x].canEdit = true;
                  if (this.webAPI.data[x].predefinedList == 0) {
                    this.webAPI.data[x].canDelete = true;
                  }
                } else if ((this.user.isCompanyUserWithMarketing() || this.user.isCompanyUser()) && (this.user.TenantUserId == this.webAPI.data[x].ownerTenantUserId)) {
                  this.webAPI.data[x].canEdit = true;
                  if (this.webAPI.data[x].predefinedList == 0) {
                    this.webAPI.data[x].canDelete = true;
                  }
                } else {
                  this.webAPI.data[x].canEdit = false;
                  this.webAPI.data[x].canDelete = false;
                }
              }
              this.allList = this.webAPI.data;
              console.log(this.allList);
              this.loadingPage = false;
            } else {
              this.toastService.showError(response.message);
            }
          },
          (err) => {
            this.toastService.showError('Error : ' + err);
            this.loadingPage = false;
          }
        )
      );
    }
    catch (strError: any) {
      this.webAPI.paging.totalRecords = 0;
      this.toastService.showError(strError);
      this.loadingPage = false;
    }
  }

  onPageChanged(event) {
    this.webAPI.paging.pageSize = event.rows;
    this.webAPI.paging.currentPage = event.first / event.rows + 1;
    setTimeout(() => {
      this.refreshData();
    });
  }

  onGotoPage(event) {
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onGotoDVPage(this.webAPI.paging, this.dataTable)) {
      setTimeout(() => {
        this.refreshData();
      });
    }
  }

  onGlobalFilterChange() {
    if (this.searchKeyword.length == 0) {
      this.noRecordFound = false;
      this.onApplyGlobalFilter();
    }
  }

  async onApplyGlobalFilter() {
    setTimeout(() => {
      this.webAPI.paging.currentPage = 1;//so it will be sent as -1 =0 later
      this.pageIndex = 0;
      this.dataTable.first = 0;
      this.refreshData();
    });
  }

  parsedText(text) {
    if (text == null || text == '') {
      // do nothing as there is no text which can be displayed in tooltip
    } else {
      const dom = new DOMParser().parseFromString(
        '<!doctype html><body>' + text,
        'text/html');
      const decodedString = dom.body.textContent;
      return decodedString;
    }
  }

  viewListClick(record: any) {
    try {
      this.viewSelectedList.listId = record.masterListId;
      this.viewSelectedList.listTitle = record.title;
      this.viewSelectedList.tenantId = record.tenantId;

      switch (record.title) {
        case 'All Contacts': {
          this.router.navigate(['people-and-loans/all-contacts'], {
            state: {
              viewList: true,
              predefinedList: 1,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Active Leads': {
          this.router.navigate(['people-and-loans/active-leads'], {
            state: {
              viewList: true,
              predefinedList: 1,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Active Loans': {
          this.router.navigate(['people-and-loans/active-loans'], {
            state: {
              viewList: true,
              predefinedList: 1,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Closed Loans': {
          this.router.navigate(['people-and-loans/closed-loans'], {
            state: {
              viewList: true,
              predefinedList: 1,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Birthdays This Week': {
          this.router.navigate(['people-and-loans/birthdays-this-week'], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Cancelled Loans': {
          this.router.navigate(['people-and-loans/cancelled-loans'], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Loan Anniversaries This Week': {
          this.router.navigate(
            ['people-and-loans/loans-anniversaries-this-week'],
            {
              state: {
                viewList: true,
                predefinedList: record.predefinedList,
                viewSelectedList: this.viewSelectedList,
                canEditList: record.canEdit,
              },
            }
          );
          break;
        }
        case 'Refi Loans': {
          this.router.navigate(['people-and-loans/refi-loans'], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Purchase Loans': {
          this.router.navigate(['people-and-loans/purchase-loans'], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Other Loans': {
          this.router.navigate(['people-and-loans/other-loans'], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'List': {
          this.router.navigate(['people-and-loans/lists'], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        case 'Converted from Lead to Loan': {
          this.router.navigate(
            ['people-and-loans/converted-from-lead-to-loan'],
            {
              state: {
                viewList: true,
                predefinedList: 1,
                viewSelectedList: this.viewSelectedList,
                canEditList: record.canEdit,
              },
            }
          );
          break;
        }
        case 'Application Phase Loans': {
          this.router.navigate(['people-and-loans/application-phase-loans'], {
            state: {
              viewList: true,
              predefinedList: 1,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
        default: {
          this.router.navigate(['people-and-loans/' + record.masterListId], {
            state: {
              viewList: true,
              predefinedList: record.predefinedList,
              viewSelectedList: this.viewSelectedList,
              canEditList: record.canEdit,
            },
          });
          break;
        }
      }
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  deleteListClick(record: any) {
    if (this.loadingPage) return;
    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message: "Please confirm you want to delete '" + record.title + "'?",
        successButtonText: 'Yes, I want to delete',
        cancelButtonText: 'Cancel',
      },
    });
    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        //Actual logic to perform upon confirmation
        this.DeleteList(record);
      }
    });
  }

  async DeleteList(record: any) {
    this.loadingPage = true;
    try {
      var response = await this.peopleAndLoanService.DeleteList(record.masterListId);
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      }
      else if (response.status == 200) {
        let mes = "List is deleted";
        this.toastService.showSuccess(mes);
      }
      this.refreshData();
    }
    catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async CreateNewList() {
    try {
      this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
      this.newListName = "";
      this.jsonStr = "";
      this.createList = [];
      this.createListWebAPI.data = null
      this.createListWebAPI.paging.totalRecords = 0;
      this.listNameRequired = false;
      this.listNameExists = false;
      this.showCreateNewDialog = true;
      this.createListRefreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  // async createListRefreshData() {
  //   this.loadingPage = true;
  //   try {
  //     let currentPage = this.createListWebAPI.paging.currentPage - 1;
  //     let pageSize = this.createListWebAPI.paging.pageSize;
  //     let searchParam = new SearchParam(currentPage, pageSize, this.createListReportType, this.createListSortField, this.createListSortOrder)

  //     if (this.jsonStr == "") {
  //       this.subscription.push(
  //         this.peopleAndLoanService.GetReports(searchParam).subscribe((response) => {
  //           if (response.status == 200 && response?.message == "") {
  //             this.createListWebAPI.paging.totalRecords = response.pageInfo.totalRecords;
  //             // assign returned object to class variable.
  //             this.createListWebAPI.data = response.data; // Required - do not remove.
  //             this.createList = this.createListWebAPI.data;
  //             //this.createList.slice();
  //             this.loadingPage = false;
  //           } else {
  //             this.toastService.showError(response.message);
  //           }
  //         },
  //           err => {
  //             this.toastService.showError("Error : " + err);
  //             this.loadingPage = false;
  //           }
  //         )
  //       );
  //     }
  //     else {
  //       searchParam.sortColumn = searchParam.sortColumn == "TYPE" ? "type" : searchParam.sortColumn;
  //       searchParam.filterJson = this.jsonStr;
  //       this.subscription.push(
  //         this.peopleAndLoanService.GetReportsListResult(searchParam).subscribe((response) => {
  //           if (response.status == 200 && response?.message == "") {
  //             // assign returned object to class variable.
  //             this.createListWebAPI.data = response.data; // Required - do not remove.
  //             this.createListWebAPI.paging.totalRecords = this.createListWebAPI.data.totalContactsCount;
  //             this.createList = this.createListWebAPI.data.contacts;
  //             //this.createList.slice();
  //             this.loadingPage = false;
  //           } else {
  //             this.toastService.showError(response.message);
  //           }
  //         },
  //           err => {
  //             this.toastService.showError("Error : " + err);
  //             this.loadingPage = false;
  //           }
  //         )
  //       );
  //     }
  //   }
  //   catch (strError: any) {
  //     this.createListWebAPI.paging.totalRecords = 0;
  //     this.toastService.showError(strError);
  //     this.loadingPage = false;
  //   }
  // }

  async createListRefreshData() {
    this.loadingPage = true;
    try {
      let currentPage = this.createListWebAPI.paging.currentPage - 1;
      let pageSize = this.createListWebAPI.paging.pageSize;
      let searchParam = new SearchParam(currentPage, pageSize, this.createListReportType, this.createListSortField, this.createListSortOrder)
      if (this.jsonStr) {
        searchParam.sortColumn = searchParam.sortColumn == "TYPE" ? "type" : searchParam.sortColumn;
        searchParam.filterJson = this.jsonStr;
      }
      else {
        searchParam.filterJson = "DEFAULT";
      }
      this.searchParam$.next(searchParam);
    }
    catch (strError: any) {
      this.createListWebAPI.paging.totalRecords = 0;
      this.toastService.showError(strError);
      this.loadingPage = false;
    }
  }

  async onCreateListPageChanged(event: LazyLoadEvent) {
    this.createListWebAPI.paging.pageSize = event.rows;
    this.createListWebAPI.paging.currentPage = event.first / event.rows + 1;
    this.createListSortField = event?.sortField?.toLowerCase() ?? '';
    //this.createListSortField = this.createListSortField == "mastercontacttypename" ? "TYPE" : this.createListSortField;
    this.createListSortOrder = ((event?.sortOrder == -1) ? 'DESC' : 'ASC');

    setTimeout(() => {
      this.createListRefreshData();
    });
  }

  onCreateListGotoPage(event) {
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onGotoPage(this.createListWebAPI.paging, this.createListDataTable)) {
      setTimeout(() => {
        this.createListRefreshData();
      });
    }
  }

  listNameChange() {
    const input = document.getElementById('newListName') as HTMLInputElement | null;
    const newListName = input?.value;

    if (newListName == "" || newListName === undefined) {
      this.listNameRequired = true;
      this.listNameExists = false;
    }
    else {
      this.listNameRequired = false;
      this.listNameExists = false;
    }
  }

  saveCreateNewList() {
    if (this.newListName == "" || this.newListName === undefined) {
      this.listNameRequired = true;
      return;
    }
    //if (!this.jsonStr) {
    //  this.toastService.showError("No filter(s) Created, Can't Save");
    //  return;
    //}

    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message: "Please confirm you want to save '" + this.newListName + "'?",
        successButtonText: 'Yes, I want to save',
        cancelButtonText: 'Cancel',
      },
    });
    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        //Actual logic to perform upon confirmation
        this.SaveList();
      }
    });
  }

  async SaveList() {
    this.loadingPage = true;
    try {
      this.createUserDefinedList.listTitle = this.newListName;
      this.createUserDefinedList.filterJson = this.jsonStr;
      if (this.jsonStr == '' || this.jsonStr == null) {
        this.createUserDefinedList.isContactOnlyList = false;
      }
      else {
        let peopleAndLoanFilterEditData = JSON.parse(this.jsonStr);
        let isLoanFilter =
          peopleAndLoanFilterEditData?.PeopleLoanList?.PeopleLoanListAndFilters?.findIndex(
            (x) => x.group == 'Loan'
          );
        this.createUserDefinedList.isContactOnlyList =
          (isLoanFilter ?? -1) > -1 ? false : true;

      }

      var response = await this.peopleAndLoanService.SaveList(this.createUserDefinedList);
      this.loadingPage = false;
      if (response.status == 0) {
        if (response.message == "List name already exists in the system.") {
          this.listNameExists = true;
        }
        this.toastService.showError(response.message);
      }
      else if (response.status == 200) {
        let mes = "'" + this.newListName + "'" + " list is created";
        this.toastService.showSuccess(mes);
        this.refreshData();
        this.showCreateNewDialog = false;
      }
    }
    catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  cancelCreateNewList() {
    this.showCustomMsg = false;
    this.showCreateNewDialog = false;
    this.storageHelper.ClearStorageByKey('PeopleAndLoan_Filter');
    this.loadingPage = false;
    //this.refreshData();//can be change so a refresh can be removed.
  }

  onFilterCreatedEvent(event: PeopleAndLoanFilterData) {
    if (event) {
      this.emptyGrid();
      this.jsonStr = JSON.stringify(event, (key, value) => {
        // if matched return value "undefined"
        if (key === "isDeleted") { //remove extra key
          return undefined;
        }
        // else return the value itself
        return value;
      });

      let peopleAndLoanFilterEditData: PeopleAndLoanFilterData;
      peopleAndLoanFilterEditData = JSON.parse(JSON.stringify(event));
      this.showCustomMsg = peopleAndLoanFilterEditData.PeopleLoanList.PeopleLoanListAndFilters.length > 0 ||
        peopleAndLoanFilterEditData.scope.LoanStatusTypes.length > 0;

      // let isLoanFilter = peopleAndLoanFilterEditData.PeopleLoanList.PeopleLoanListAndFilters.
      //   findIndex((x) => x.group == "Loan");

      // this.contactOnlyList = isLoanFilter > -1 ? false : true;

      this.createListRefreshData();
      console.log(this.jsonStr);
    }
  }

  emptyGrid() {
    this.createListWebAPI.data = null;
    this.createList = []
    this.createListWebAPI.paging.totalRecords = 0;
  }

  emptyAllGrid() {
    this.allList = [];
  }

  clearSubscription(subscription: Array<Subscription>) {
    subscription.forEach((subs: Subscription) => {
      if (subs) {
        subs.unsubscribe();
      }
    });
  }

  cleanUp() {
    if (this.subscription) {
      this.clearSubscription(this.subscription);
      this.subscription = [];
    }
    if (this.searchObservable) {
      this.searchObservable.unsubscribe();
      this.searchObservable = null;
    }
  }

  ngOnDestroy(): void {
    this.cleanUp();
    this.jsonStr = '';
    this.createListWebAPI.data = null;
    this.createList = []
    this.allList = [];
  }
}
