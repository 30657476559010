<p-menu 
    [style]="{width: 'max-content'}" 
    [showTransitionOptions]="'0ms'" 
    [hideTransitionOptions]="'0ms'" 
    #downloadMenu 
    [popup]="true" 
    [model]="downloadMenuItems">
</p-menu>

<p-table #dataTableId class="pulseTable subscriberActivityTable" [value]="webAPI.data.Items" frozenWidth="1px"
  [columns]="displayedColumns" [globalFilterFields]="globalFilterFields" [rows]="10"
  [rowsPerPageOptions]="[10,15,20,25,30,40,50,75,100]" [paginator]="webAPI.paging.totalRecords > 0"
  [showCurrentPageReport]="true" [totalRecords]="webAPI.paging.totalRecords" [loading]="loadingPage" [lazy]="true"
  (onLazyLoad)="onPageChanged($event)" [scrollable]="true" [virtualScroll]="false" (onFilter)="onFilter($event)"
  [sortOrder]="sortOrder" [sortField]="sortField" (onSort)="onSort($event)">
  <ng-template pTemplate="caption">
      <div class="p-d-inline-block" style="align-content:stretch; position:relative; width: 100%;">
          <span style="float: right" valign="middle">
              <span>See activity for:</span>&nbsp;
              <p-dropdown id="ddlActivityList" class="activityList" *ngIf="loadingPage===false" 
              [options]="activityList" 
              [(ngModel)]="selectedActivity" 
              (onChange)="onSearchActivityChanged($event)"></p-dropdown>
          </span>
      </div>
      <div class="p-d-inline-block" style="align-content:stretch; position:relative; width: 100%; margin-top: 10px;">
        <p-dropdown id="ddlStatList" class="statList" *ngIf="loadingPage===false" 
            [options]="statList" 
            [(ngModel)]="selectedStat" 
            (onChange)="onStatsChanged($event)"></p-dropdown>&nbsp;&nbsp;
        <button pButton pRipple type="button"
            style="margin-left:.5em; min-height: 25px; max-height: 25px;" 
            class="p-button-secondary" 
            (click)="downloadMenu.toggle($event)">
          Export&nbsp;
          <i class="pi pi-angle-down" style="color:var(--primary-color-pulse);"></i>
        </button>&nbsp;&nbsp;
        <button pButton type="button" (click)="onOptOutClick($event)" class="p-button-secondary" style="min-height: 25px; max-height: 25px;">
          Opt Out&nbsp;&nbsp;<i class="pi pi-ban"></i>
        </button>&nbsp;&nbsp;
        <button pButton type="button" (click)="onShowAddToListDialog($event)" class="p-button-secondary" style="min-height: 25px; max-height: 25px;">
          Add to List
        </button>
        <span style="float: right" valign="middle">
          <span class="p-input-icon-left p-ml-auto p-float-label" style="margin-right: 20px;">
            <input [(ngModel)]="searchKeyword" pInputText type="text" (input)="onGlobalFilterChange($event)"
                style="padding: 10px; border-radius: 23px; width: 27.5em; max-height: 25px; min-height: 25px; margin-right: -10px" />
            <label style="padding-left:1.35em; color:var(--medium-gray); font-weight: 100; font-size: 14px">
                Filter by email address or name
            </label>
          </span>
          <button type="button" pButton pRipple icon="fal fa-search" pTooltip="Execute Search"
              tooltipPosition="bottom" showDelay=500 (click)="onApplyGlobalFilter()"
              class="p-button-secondary" [disabled]="keywordDisabled"
              style="border: none; padding-right: 10px; margin-right: 10px; margin-left: -45px; top: -5px; color: var(--medium-gray) !important; padding: 0rem 0 0px 0; min-height: 10px;">
          </button>
        </span>
    </div>
</ng-template>
  <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
          <col style="width: 3em; min-width: 3rem; max-width: 3rem">
          <col style="{{col.style}}" *ngFor="let col of columns">
          <col />
          <col style="width:3rem; min-width:3rem; max-width:3rem;">
      </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
      <tr style="background-color: var(--primary-dark-blue);">
          <td style="padding-left: 10px;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </td>
          <ng-container *ngFor="let col of columns" style="margin: 0; padding: 0;">
              <!-- No Sort, No Filter -->
              <td *ngIf="col.isSortable !== true && (col.isFilterable !== true)"
                  style="padding:1rem 0.95rem 0.5rem 0.95rem; vertical-align: top;color: var(--primary-color-text)"><b>{{col.header}}</b></td>
              <!-- Sortable, No Filter -->
              <td *ngIf="col.isSortable === true && (col.isFilterable !== true)" pSortableColumn="{{col.field}}"
                  style="padding:1rem 0.95rem 0.5rem 0.95rem; vertical-align: top;color: var(--primary-color-text)">
                  <span>
                      <b>{{col.header}}</b>
                      <p-sortIcon field="{{col.field}}" style="color: var(--primary-color-text) !important"></p-sortIcon>
                  </span>
              </td>
              <!-- No Sort, Filterable -->
              <td *ngIf="(col.isSortable !== true) && (col.isFilterable === true)"
                  style="padding:1rem 0.95rem 0.5rem 0.95rem; vertical-align: top;color: var(--primary-color-text)">
                  <span>
                      <b>{{col.header}}</b>
                  </span>
                  <!-- <span class="p-d-flex p-ai-sm-end" style="margin-top:-24px;"> -->
                      <!-- Use the following when there is no custom filter specification being implemented -->
                      <!-- <p-columnFilter class="p-ml-auto" display="menu" matchMode="contains" type="{{col.filterType}}"
                          field="{{col.field}}" maxConstraints="{{col.maxConstraints}}"
                          [showOperator]="col.showOperator" [operator]="col.operator" hideOnClear="true">
                      </p-columnFilter> -->
                  <!-- </span> -->
              </td>
              <!-- Sortable, Filterable -->
              <td *ngIf="(col.isSortable === true) && (col.isFilterable === true)" pSortableColumn="{{col.field}}"
                  style="padding:1rem 0.95rem 0.5rem 0.95rem; vertical-align: top;color: var(--primary-color-text)">
                  <span>
                      <b>{{col.header}}</b>
                      <p-sortIcon field="{{col.field}}"  style="color: var(--primary-color-text) !important"></p-sortIcon>
                  </span>
                  <!-- <span class="p-d-flex p-ai-sm-end" style="margin-top:-24px;"> -->

                      <!-- Use the following when there is no custom filter specification being implemented -->
                      <!-- <p-columnFilter class="p-ml-auto"
                          display="menu" matchMode="contains" type="{{col.filterType}}" field="{{col.field}}"
                          maxConstraints="{{col.maxConstraints}}" [showOperator]="col.showOperator"
                          [operator]="col.operator" hideOnClear="true"></p-columnFilter> -->
                  <!-- </span> -->
              </td>
          </ng-container>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-record let-rowIndex="rowIndex" let-columns="columns"
      let-selections="currentSelections">
      <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="record"
          (click)="onRowClicked($event, record)" style="cursor:pointer">
          <td style="padding:0.95rem .75rem !important; width: 1.5em; min-width:1.5em; max-width:1.5em">
              <p-tableCheckbox [value]="record" (click)="onRowCheckboxClick($event, record)" class="pulseCheckbox">
              </p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" pTooltip="{{row.Name}}" tooltipPosition="bottom" showDelay=500 style="padding:1rem 0.95rem 0.5rem 0.95rem !important">
              <span *ngIf="col.dataType !== 'Date'">{{row[col.field]}}</span>
              <span *ngIf="col.dataType === 'Date'">{{row[col.field] | date:'EE, MMM dd, YYYY HH:MM aa' }}</span>
          </td>
      </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft" let-state>
      <label style="font-weight:400;">{{webAPI.paging.totalRecords}}&nbsp;results</label>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
      <div>
          <label style="margin-top: 1px; margin-right:8px; font-weight:400;">Go to Page</label>
          <input type="text" pInputText pKeyFilter="int" [(ngModel)]="webAPI.paging.currentPage" width="3"
              maxlength="3" style="text-align: center; width: 4rem; margin-right:8px; border-radius: 20px;" (change)="onGotoPage()">
          <!-- <button pButton pRipple type="button" iconPos="right" icon="pi pi-caret-right"
              class="p-button-secondary-rounded" style="margin-right: 0px;" (click)="onGotoPage()"></button> -->
      </div>
  </ng-template>
</p-table>

<!--
*************************************************************************************
Add to list Dialog
*************************************************************************************
-->
<p-dialog #addToList [resizable]="false" (onHide)="onCloseAddToListDialog()" [(visible)]="showAddToListDialog" [modal]="true" [baseZIndex]="10000"
          [style]="{'width': '33em','font-weight': '700'}">
  <p-header>
    <span class="dialog-head">Add to List</span>
  </p-header>
  <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
  <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div>
  <!-- <Dialog-root ></Dialog-root> -->
  <ng-template pTemplate="footer">
    <!-- <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
    <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div> -->
    <div style="margin-top: 1.4rem;">
      <p-button (click)="onCloseAddToListDialog()" label="Cancel" styleClass="p-button-secondary"></p-button>
      <p-button (click)="onCloseAaddToListDialog()" label="Save"></p-button>
    </div>
  </ng-template>
</p-dialog>