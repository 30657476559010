import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { PeopleAndLoansListRouterModule } from "./people-and-loans-list-routing.module";
import { PeopleAndLoansListComponent } from "./people-and-loans-list.component";
import { AccountsService } from '../services/accounts.service';
import { PeopleAndListFilterComponent } from "./people-and-list-filter/people-and-list-filter.component";
import { TreeModule } from 'primeng/tree';
import { PeopleAndListFilterTextComponent } from './people-and-list-filter/people-and-list-filter-text/people-and-list-filter-text.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { PeopleAndListFilterNumericComponent } from "./people-and-list-filter/people-and-list-filter-numeric/people-and-list-filter-numeric.component";
import { PeopleAndListFilterBooleanComponent } from "./people-and-list-filter/people-and-list-filter-boolean/people-and-list-filter-boolean.component";
import { PeopleAndListFilterDateComponent } from "./people-and-list-filter/people-and-list-filter-date/people-and-list-filter-date.component";
import { CalendarModule } from "primeng/calendar";
import { InputNumberModule } from "primeng/inputnumber";
import { PeopleAndListFilterGenericListComponent } from './people-and-list-filter/people-and-list-filter-generic-list/people-and-list-filter-generic-list.component';
import { PeopleAndListFilterLoanStatusTypeGenericListComponent } from './people-and-list-filter/people-and-list-filter-loan-status-type-generic-list/people-and-list-filter-loan-status-type-generic-list.component';
import { TooltipModule } from "primeng/tooltip";
import { ConfirmationService } from "primeng/api";
import { PeopleAndListFilterTextListComponent } from "./people-and-list-filter/people-and-list-filter-text-list/people-and-list-filter-text-list.component";
import { PeopleAndListFilterCurrencyComponent } from "./people-and-list-filter/people-and-list-filter-currency/people-and-list-filter-currency.component";
import { PeopleAndListFilterPercentageComponent } from "./people-and-list-filter/people-and-list-filter-percentage/people-and-list-filter-percentage.component";
import { PeopleAndListAdvancedFilterComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PeopleAndListAdvancedFilterTextComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-text/people-and-list-advanced-filter-text.component';
import { PeopleAndListAdvancedFilterNumericComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-numeric/people-and-list-filter-numeric.component';
import { PeopleAndListAdvancedFilterBooleanComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-boolean/people-and-list-filter-boolean.component';
import { PeopleAndListAdvancedFilterDateComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-date/people-and-list-filter-date.component';
import { PeopleAndListAdvancedFilterGenericListComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-generic-list/people-and-list-filter-generic-list.component';
import { PeopleAndListAdvancedFilterLoanStatusTypeGenericListComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-loan-status-type-generic-list/people-and-list-filter-loan-status-type-generic-list.component';
import { PeopleAndListAdvancedFilterTextListComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-text-list/people-and-list-filter-text-list.component';
import { PeopleAndListAdvancedFilterCurrencyComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-currency/people-and-list-filter-currency.component';
import { PeopleAndListAdvancedFilterPercentageComponent } from '../people-and-list-advanced-filter/people-and-list-advanced-filter-percentage/people-and-list-filter-percentage.component';
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
@NgModule({
  declarations: [
    PeopleAndLoansListComponent,
    PeopleAndListFilterComponent,
    PeopleAndListFilterTextComponent,
    PeopleAndListFilterNumericComponent,
    PeopleAndListFilterBooleanComponent,
    PeopleAndListFilterDateComponent,
    PeopleAndListFilterGenericListComponent,
    PeopleAndListFilterLoanStatusTypeGenericListComponent,
    PeopleAndListFilterTextListComponent,
    PeopleAndListFilterCurrencyComponent,
    PeopleAndListFilterPercentageComponent,

    PeopleAndListAdvancedFilterComponent,
    PeopleAndListAdvancedFilterTextComponent,
    PeopleAndListAdvancedFilterNumericComponent,
    PeopleAndListAdvancedFilterBooleanComponent,
    PeopleAndListAdvancedFilterDateComponent,
    PeopleAndListAdvancedFilterGenericListComponent,
    PeopleAndListAdvancedFilterLoanStatusTypeGenericListComponent,
    PeopleAndListAdvancedFilterTextListComponent,
    PeopleAndListAdvancedFilterCurrencyComponent,
    PeopleAndListAdvancedFilterPercentageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    OverlayPanelModule,
    TableModule,
    PeopleAndLoansListRouterModule,
    TreeModule,
    MultiSelectModule,
    CheckboxModule,
    ChipsModule,
    CalendarModule,
    InputNumberModule,
    TooltipModule, 
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule
  ],
  providers: [AccountsService, ConfirmationService],
  exports: [
    PeopleAndLoansListComponent,
    PeopleAndListFilterComponent,
    PeopleAndListFilterTextComponent,
    PeopleAndListFilterNumericComponent,
    PeopleAndListFilterBooleanComponent,
    PeopleAndListFilterDateComponent,
    PeopleAndListFilterGenericListComponent,
    PeopleAndListFilterTextListComponent,
    PeopleAndListFilterCurrencyComponent,
    PeopleAndListFilterPercentageComponent,


    PeopleAndListAdvancedFilterComponent,
    PeopleAndListAdvancedFilterTextComponent,
    PeopleAndListAdvancedFilterNumericComponent,
    PeopleAndListAdvancedFilterBooleanComponent,
    PeopleAndListAdvancedFilterDateComponent,
    PeopleAndListAdvancedFilterGenericListComponent,
    PeopleAndListAdvancedFilterLoanStatusTypeGenericListComponent,
    PeopleAndListAdvancedFilterTextListComponent,
    PeopleAndListAdvancedFilterCurrencyComponent,
    PeopleAndListAdvancedFilterPercentageComponent,

  ],
})
export class PeopleAndLoansListModule {}
