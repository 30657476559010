<app-filters *ngIf="showFilters" [columns]="columns" [hideSearchBar]="hideSearchBar" (updateTable)="updateFilters()" [searchModel]="searchModel"></app-filters>
<p-table [value]="data" [columns]="columns" [totalRecords]="searchModel.totalRecords" [lazy]="true"
    [(selection)]="selectedRows" [lazyLoadOnInit]="false" [rows]="searchModel.pageSize" [paginator]="searchModel.totalRecords > 1"
    [rowsPerPageOptions]="[5,10,25, 50, 100]" (onLazyLoad)="internalOnPageChanged($event)"
    [showCurrentPageReport]="true">
    <ng-template pTemplate="caption">
        <p-button label="Export" *ngIf="showExport" styleClass="p-button-outlined secondary-button ml-0 mr-auto"
            (click)="exportData.toggle($event)"></p-button>
        <p-overlayPanel #exportData>
            <p-button label="Download Selected" [disabled]="selectedRows.length === 0" styleClass="p-button-outlined secondary-button ml-0 mr-auto"
            (click)="downloadData(false)"></p-button>
            <p-button label="Download All" styleClass="p-button-outlined secondary-button ml-0 mr-auto"
            (click)="downloadData(true)"></p-button>
        </p-overlayPanel>
        <div class="my-auto total-messages ml-auto mr-2">{{searchModel.totalRecords}} {{totalCountLabel}}</div>
        <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" class="groundSelect mx-2 column-select"
            optionValue="field" selectedItemsLabel="{0} columns selected" [autoZIndex]="false" [baseZIndex]="10"
            [autoWidth]="false" placeholder="Choose Columns" defaultLabel="Show / hide columns" resetFilterOnHide="true"
            (onChange)="onColumnSelectionChange($event)" [style]="{
            'border-radius': '25px',
            color: 'grey',
            'padding-left': '10px',
            'min-width': '170px'
          }"></p-multiSelect>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <th class="p-3" *ngIf="showExport">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let column of columns;">
            <ng-container *ngIf="column.display">
                <th class="p-3" *ngIf="column.isSortable; else notSortable" pSortableColumn="{{column.field}}">
                    {{column.header}} <p-sortIcon field="{{column.field}}"></p-sortIcon>
                </th>
                <ng-template #notSortable>
                    <th class="p-3">
                        {{column.header}}
                    </th>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-row>
        <tr>
            <td *ngIf="showExport">
                <p-tableCheckbox [value]="row"></p-tableCheckbox>
            </td>
            <ng-container *ngTemplateOutlet="tabletTemplate; context: { $implicit: row }"></ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
        <div class="my-auto total-messages">{{searchModel.totalRecords}} {{totalCountLabel}}</div>
    </ng-template>
    <ng-template pTemplate="paginatorright">
        <span class="p-input-icon-right search-input-group go-to-page-group">
            <div class="my-auto total-messages">Go to page </div>
            <input class="search-input rounded-pill p-inputtext w-100 pr-1" [min]="1" [max]="searchModel.maxPage"
                [ngModel]="searchModel.pageNum" (change)="onPageNumChange($event)" type="number" />
        </span>
    </ng-template>
</p-table>