<p-dialog [(visible)]="crudInProgress" class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>
<!-- removing  class second-header-fixed -->
<div class="content table-search-row second-header-fixed">
  <div class="text-wrapper" id="container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title-main text-black col-4">
        <label *ngIf="selectedStepIndex == 0 && campaignAddVM?.campaignName">{{ campaignAddVM?.campaignName }}</label>
        <label *ngIf="selectedStepIndex == 0 && !campaignAddVM?.campaignName">Create New Campaign</label>
        <label *ngIf="selectedStepIndex > 0">{{ campaignAddVM?.campaignName }}</label>
      </div>

      <div class="col-5 d-flex justify-content-end">
        <!-- added class flex container -->
        <div class="d-flex justify-content-between align-items-center fs-5">
          <span role="button" class="font-size-16" (click)="setTab(0)"
            [ngClass]="[selectedStepIndex == 0 ? 'text-dark fw-bold' : '']">
            Details
          </span>
          <i class="pi pi-chevron-right mx-2"></i>
          <div class="d-flex justify-content-between align-items-center"
            [ngClass]="[selectedStepIndex == 1 ? 'text-dark fw-bold' : '']">
            <div role="button" (click)="setTab(1)" class="mx-2 font-size-16">
              Recipients
            </div>
          </div>
          <i class="pi pi-chevron-right mx-2"></i>
          <div class="d-flex justify-content-between align-items-center"
            [ngClass]="[selectedStepIndex == 2 ? 'text-dark fw-bold' : '']">
            <div role="button" (click)="setTab(2)" class="mx-2 font-size-16">
              Content
            </div>
          </div>
          <i class="pi pi-chevron-right mx-2"></i>
          <div class="d-flex justify-content-between align-items-center"
            [ngClass]="[selectedStepIndex == 3 ? 'text-dark fw-bold' : '']">
            <div role="button" (click)="setTab(3)" class="mx-2 font-size-16">
              Schedule
            </div>
          </div>
          <i class="pi pi-chevron-right mx-2"></i>
          <div class="d-flex justify-content-between align-items-center"
            [ngClass]="[selectedStepIndex == 4 ? 'text-dark fw-bold' : '']">
            <div role="button" (click)="setTab(4)" class="mx-2 font-size-16">
              Activate
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end col-3">
        <!-- class="btn btn-outline-primary create-btn rounded-pill font-size-16" removed pButton-->
        <button class="btn btn-outline-primary skip-campaign-btn rounded-pill font-size-16 header-button"
          [disabled]="isSkipDisable" type="button" (click)="SkipToCampaignEditor()">
          <i class="pi text-white"></i> Skip To Campaign Editor
          <i class="fa fa-arrow-right fa-1" style="margin-left: 10px !important"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="skipPageClicked || loadingPage" class="row">
  <div class="col-6 col-6 text-right" style="position: absolute;">
    <i class="pi pi-spin pi-spinner loader"></i>
  </div>
  <div class="col-6"></div>
</div>
<div *ngIf="!items?.length" align="center">
  <i class="pi pi-spin pi-spinner loader"></i>
</div>
<div *ngIf="items" [formGroup]="campaignFormGroup" class="head-pad-top">
  <div formArrayName="items" *ngFor="let item of formDataCtrl?.controls; let i = index">
    <div [formGroupName]="i">
      <div [hidden]="selectedStepIndex != 0" class="text-wrapper pb-5">
        <div class="text-wrapper grid-section-details" id="grid">
          <div class="d-flex justify-content-center message-details-form">
            <div class="mt-2 step-one-form">
              <!-- removed class row -->
              <div class="mb-4">
                <!-- removed class col-6 -->
                <div class="page_title mt-2">Details</div>
                <!-- removed class col-12 label -->
                <div class="form-label">
                  Campaign name* &nbsp;
                  <label class="text-black-50 fst-italic" style="font-size: 16px">For internal use only</label>
                </div>
                <!-- replaced col-12 with mb-4 -->
                <div class="mb-4">
                  <input formControlName="campaignName" id="campaignName" pInputText type="text" maxlength="100"
                    style="width: 100% !important; border: 1px solid #cccccc" />

                  <span class=" " style="color: #fc5151; font-size: 16px; font-weight: normal" *ngIf="
                      item.get('campaignName')?.touched &&
                      item.get('campaignName')?.errors
                    ">
                    Required.
                  </span>
                </div>
                <div class="form-label">Campaign Category</div>
                <div class="mb-4 ground">
                  <p-dropdown formControlName="campaignCategory" id="campaignCategory" class="groundDropdown"
                    [style]="{ width: '100%', border: '1px solid #cccccc' }" autoWidth="false" [autoZIndex]="false"
                    #campaignCategory [options]="campaignCategoryList" optionLabel="item_text" optionValue="item_id"
                    placeholder="Select Category">
                  </p-dropdown>
                </div>
                <div class="form-label">Campaign Description</div>
                <div class="mb-4">
                  <textarea formControlName="campaignDesc" id="txtCampaignDescription" pInputTextarea type="text"
                    rows="6" maxlength="255" class="campaignDesc"></textarea>
                </div>
                <div class="mb-4" *ngIf="currentClientID === 'AduvoAdmin'">
                  <div class="p-d-flex my-2">
                    <p-checkbox formControlName="isQuickStart" binary="true" id="isQuickStart"
                      (onChange)="QuickStartChange()">
                    </p-checkbox>
                    <label class="control-label">Aduvo Quick Start</label>
                  </div>
                  <div class="p-d-flex my-2">
                    <p-checkbox formControlName="isRecommended" binary="true" id="isRecommended"
                      (onChange)="RecommendedChange()">
                    </p-checkbox>
                    <label class="control-label">Aduvo Recommended</label>
                  </div>
                </div>
                <hr class="mb-4" />
                <div class="form-label">Who Can Use This Campaign?</div>
                <div class="mb-4 ground">
                  <p-multiSelect [disabled]="isWhoCanDisabled" formControlName="whoCanUseList" class="groundSelect"
                    [style]="{
                    width: '100%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" panelStyleClass="OverrideIndex" dataKey="item_id" optionLabel="item_text" [autoZIndex]="false"
                    [baseZIndex]="2" [options]="userList" [defaultLabel]="defaultLabel"
                    selectedItemsLabel="{{ selectedUserListLabel }}" (onChange)="onUserListChange($event)">
                  </p-multiSelect>
                </div>

                <hr class="mb-4" />
                <div class="mb-4 form-label message-details-form">Who is it from?</div>
                <div class="row mb-2 align-items-center step-one-form">
                  <div class="col-auto form-label">
                    <p-radioButton class="mb-1" formControlName="isFromRole" name="isFromRole" value="0"
                      inputId="frmRole"></p-radioButton>
                    <label for="frmRole" style="padding-left: 10px;cursor:pointer;">Role</label>
                  </div>
                  <div class="mb-4 col-auto ground" style="width: 89%">
                    <p-dropdown [disabled]="item.get('isFromRole')?.value != 0" formControlName="fromRole"
                      [style]="{ width: '104%', border: '1px solid #cccccc' }" class="details-dialog groundDropdown"
                      [autoZIndex]="false" id="ddlRoles" [editable]="false" [options]="loanAssignTypeRoles"
                      optionLabel="item_text" optionValue="item_id" placeholder="Select">
                    </p-dropdown>
                  </div>
                </div>
                <div class="mb-2 row step-one-form">
                  <div class="col-1 form-label" style="width: 30px">
                    <p-radioButton formControlName="isFromRole" name="isFromRole" value="2"
                      inputId="frmContactOwner"></p-radioButton>
                  </div>
                  <label for="frmContactOwner" class="form-label col-10"
                    style="cursor:pointer;width:96%;margin-top: 3px">Contact Owner</label>
                </div>
                <div class="mb-2 row step-one-form">
                  <div class="col-1" style="width: 30px">
                    <p-radioButton formControlName="isFromRole" name="isFromRole" value="1"
                      inputId="frmCustomAddress"></p-radioButton>
                  </div>
                  <label for="frmCustomAddress" class="form-label col-10;" style="cursor:pointer;width:96%;">Custom
                    Address</label>
                </div>
                <div class="row mb-2 step-one-form" *ngIf="item.get('isFromRole')?.value == 1">
                  <div class="mb-4 col-12" style="margin-left: 4%">
                    <div class="col-12 form-label">From Email</div>
                    <div class="mb-4 col-12">
                      <input formControlName="fromEmail" pinputtext type="text" class="p-inputtext p-component"
                        (input)="validateSingleEmail()" maxlength="100"
                        style="width: 98.5% !important; border: 1px solid #cccccc" />
                      <span class="error-validation" *ngIf="isFromEmailInValid">*Email address not
                        valid.</span>
                    </div>
                    <div class="col-12 form-label">From Name</div>
                    <div class="col-12">
                      <input formControlName="fromName" pInputText type="text" maxlength="100"
                        style="width: 98.5% !important; border: 1px solid #cccccc" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="selectedStepIndex != 1" class="text-wrapper pb-5">
        <div class="text-wrapper grid-section-details" id="grid">
          <div class="d-flex justify-content-center message-details-form">
            <div class="mt-2 step-one-form">
              <div class="mb-4">
                <div class="page_title mt-2">Recipients</div>
                <div class="label">To List</div>
                <div class="mb-5 ground">
                  <p-multiSelect formControlName="toList" class="groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" panelStyleClass="OverrideIndex" dataKey="item_id" optionLabel="item_text" [autoZIndex]="false"
                    [baseZIndex]="1" [options]="loanStatusTypeMasterList" defaultLabel="Select">
                  </p-multiSelect>
                </div>
                <hr />
                <div class="label">To Recipients</div>

                <div class="form-label">Contacts</div>
                <!--   -->
                <div class="mb-4 ground">
                  <p-multiSelect formControlName="toRecipientContacts" class="pl-5 mt-4 groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" panelStyleClass="OverrideIndex" [autoZIndex]="false" [baseZIndex]="1"
                    [options]="masterContactTypes" defaultLabel="Select" optionLabel="item_text" optionValue="item_id"
                    selectedItemsLabel="{{ toSelectedMasterContactLabel }}"
                    (onChange)="onToMasterContactTypesChange($event)">
                  </p-multiSelect>
                  <!-- [style]="{'width':'180px', 'margin-top':'10px'}" -->
                </div>
                <div class="form-label">Users</div>
                <!-- removing  -->
                <div class="mb-4 ground">
                  <p-multiSelect formControlName="toRecipientUsers" class="pl-5 mt-4 groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" [autoZIndex]="false" [baseZIndex]="1" [options]="loanAssignTypeRoles" defaultLabel="Select"
                    optionLabel="item_text" optionValue="item_id" selectedItemsLabel="{{
                      toSelectedLoanAssignTypeRolesLabel
                    }}" (onChange)="onToLoanAssignTypeRolesChange($event)">
                  </p-multiSelect>
                </div>
                <div class="form-label">
                  Custom Address(es)&nbsp;&nbsp;
                  <span style="font-size: 14px; color: #797979; font-style: italic">
                    Separate with a comma and no space</span>
                </div>

                <div class="mb-5">
                  <input formControlName="toRecipientCustomAddress" pInputText type="text" maxlength="100"
                    (input)="validateMultipleToEmail()" (change)="validateMultipleToEmail()"
                    style="width: 98.3% !important; resize: none; border: 1px solid #cccccc;" />
                  <span class="error-validation" *ngIf="isToEmailInValid">*Email address not
                    valid.</span>
                </div>
                <hr />
                <div class="label">CC</div>
                <div class="form-label">Contacts</div>
                <!--  -->
                <div class="mb-4 ground">
                  <p-multiSelect formControlName="CCRecipientContacts" class="pl-5 mt-4 groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" panelStyleClass="OverrideIndex" [autoZIndex]="false" [baseZIndex]="1"
                    [options]="masterContactTypes" defaultLabel="Select" optionLabel="item_text" optionValue="item_id"
                    selectedItemsLabel="{{ ccSelectedMasterContactLabel }}"
                    (onChange)="onCCMasterContactTypesChange($event)">
                  </p-multiSelect>
                </div>
                <div class="form-label">Users</div>
                <div class="mb-4 ground">
                  <p-multiSelect formControlName="CCRecipientUsers" class="pl-5 mt-4 groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" [options]="loanAssignTypeRoles" defaultLabel="Select" optionLabel="item_text"
                    optionValue="item_id" selectedItemsLabel="{{
                      ccSelectedLoanAssignTypeRolesLabel
                    }}" (onChange)="onCCLoanAssignTypeRolesChange($event)">
                  </p-multiSelect>
                </div>
                <div class="form-label">
                  Custom Address(es)&nbsp;&nbsp;
                  <span style="font-size: 14px; color: #797979; font-style: italic">Separate with a comma and no
                    space</span>
                </div>
                <div class="mb-5">
                  <input formControlName="CCRecipientCustomAddress" pInputText type="text" maxlength="100"
                    (input)="validateMultipleCCEmail()" style="width: 98.5% !important; border: 1px solid #cccccc" />
                  <span class="error-validation" *ngIf="isCCEmailInValid">*Email address not
                    valid.</span>
                </div>
                <hr />

                <div class="label">BCC</div>
                <div class="form-label">Contacts</div>
                <div class="mb-4 ground">
                  <p-multiSelect formControlName="BCCRecipientContacts" class="pl-5 mt-4 groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" panelStyleClass="OverrideIndex" [options]="masterContactTypes" defaultLabel="Select"
                    optionLabel="item_text" optionValue="item_id"
                    selectedItemsLabel="{{ bccSelectedMasterContactLabel }}"
                    (onChange)="onBCCMasterContactTypesChange($event)">
                  </p-multiSelect>
                </div>
                <div class="form-label">Users</div>
                <div class="mb-4 ground">
                  <p-multiSelect formControlName="BCCRecipientUsers" id="BCCRecipientUsers"
                    class="pl-5 mt-4 groundSelect" [style]="{
                    width: '98.3%',
                    border: '1px solid #cccccc',
                    padding: '5px 5px'
                  }" panelStyleClass="OverrideIndex" [autoZIndex]="false" [baseZIndex]="1"
                    [options]="loanAssignTypeRoles" defaultLabel="Select" optionLabel="item_text" optionValue="item_id"
                    selectedItemsLabel="{{
                      bccSelectedLoanAssignTypeRolesLabel
                    }}" (onChange)="onBCCLoanAssignTypeRolesChange($event)">
                  </p-multiSelect>
                </div>
                <div class="form-label">
                  Custom Address(es)&nbsp;&nbsp;
                  <span style="font-size: 14px !important ;color: #797979;font-style: italic;">Separate with a comma and
                    no space</span>
                </div>
                <div class="mb-5">
                  <input formControlName="BCCRecipientCustomAddress" pInputText type="text" maxlength="100"
                    (input)="validateMultipleBCCEmail()"
                    style="width: 98.3% !important;resize: none;border: 1px solid #cccccc;" />
                  <span class="error-validation" *ngIf="isBCCEmailInValid">*Email address not
                    valid.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- removed style="padding-bottom: 100px;" -->
      <!-- <div [hidden]="selectedStepIndex != 2" class="text-wrapper" style="margin-top: 70px; border :1px solid green;"> -->
      <div [hidden]="selectedStepIndex != 2" class="text-wrapper pb-5" style="margin-top:105px; ">
        <!-- <div class="row d-flex align-items-center flex-nowrap">
          <div class="col-sm-6">
            <span class="p-input-icon-left p-float-label">
              <input maxlength="250" [(ngModel)]="searchKeyword" class="w-250 search-input rounded-pill" pInputText
                type="text" (keyup.enter)="onApplyGlobalFilter()" (input)="onGlobalFilterChange()"
                placeholder="Search by keyword" />
            </span>
            <span type="button" pButton pRipple icon="fal fa-search search-fa" pTooltip="Execute Search"
              tooltipPosition="bottom" showDelay="500" (click)="onApplyGlobalFilter()"
              class="p-button-secondary execute-search" style="margin-top: 5px; position: absolute; border: none;margin-left:280px">
            </span>
          </div>
          <div class="camp-content-sort col-sm-6">
            <p-dropdown [options]="sortByList" optionLabel="label" [autoZIndex]="false" [baseZIndex]="1"
              optionValue="value" placeholder="Sort by" class="content-sort"
              (onChange)="onSortBySelectionChange($event)">
            </p-dropdown>
          </div>
        </div> -->

        <div [hidden]="selectedStepIndex != 2" class="text-wrapper mtcover">
          <app-content-library-filter *ngIf="isCalledFromWizard" [UpdateMsgModel]="updateMsgModel"
            [hideAllAndCampaignTab]="isCalledFromWizard"
            (onItemsSelectForWizardEvent)="onItemsSelectForWizardEvent($event)"></app-content-library-filter>
        </div>
      </div>

      <div [hidden]="selectedStepIndex != 3" class="text-wrapper pb-5" style="margin-top: 70px">
        <div class="text-wrapper grid-section-details">
          <div class="d-flex justify-content-center message-details-form">
            <div class="mt-2 step-one-form">
              <div class="mb-4">
                <div class="page_title">Schedule Your Messages
                  <i *ngIf="!ShowMessageScheduleTab && noMessageFound==false" class="pi pi-spin pi-spinner"
                    style="font-size:2rem"></i>
                </div>
                <div *ngIf="noMessageFound">There are no messages in this campaign.</div>
                <app-content-message-schedule *ngIf="ShowMessageScheduleTab" [selectedCampaignId]="generatedCampaignId"
                  [selectedCampaignMessageList]="selectedMessagesList"
                  (onCloseScheduleDialog)="onCloseScheduleDialog()">
                </app-content-message-schedule>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedStepIndex==4"
        class="m-auto d-flex justify-content-center flex-column text-center permission-text active-wrapper">
        <h2 class="textcolor">
          Would you like to activate this campaign?
        </h2>
        <br>
        <div class="" data-toggle="buttons">
          <label class="btn btn-primary rbutton mt-2" [class.active]="selectedItem === 1">
            <input type="radio" name="options" id="option1" (change)="actionContent($event, 'YesRadio')" class="hidden">
            Yes, I want to activate </label>
          <br />
          <label class="btn btn-outline-primary rounded-pill mt-4 outline-btn" [class.active]="selectedItem === 0">
            <input type="radio" name="options" id="option2" (change)="actionContent($event, 'NoRadio')" class="hidden">
            Continue Editing </label>
        </div>
        <div class="d-flex justify-content-center">
        </div>
      </div>

      <div class="createbuttoncontainer" *ngIf="selectedStepIndex !== 4">
        <div class="col-12 d-flex text-wrapper justify-content-between ">
          <div>
            <p-button
              [disabled]="loadingPage || isFromEmailInValid || isToEmailInValid || isCCEmailInValid || isBCCEmailInValid"
              (onClick)="prev()" class="mx-1" label="Previous" *ngIf="selectedStepIndex > 0 && selectedStepIndex != 4">
            </p-button>
          </div>
          <div class="text-right">
            <p-button class="mx-1"
              [disabled]="loadingPage || item.get('campaignName')?.errors || 
                              isFromEmailInValid || isToEmailInValid || isCCEmailInValid || isBCCEmailInValid || isMessageSchedule"
              (onClick)="skip()" *ngIf="selectedStepIndex != 4" styleClass="p-button-link"
              label="Skip This Step"></p-button>

            <p-button [disabled]="loadingPage || item.get('campaignName')?.errors || 
                              isFromEmailInValid || isToEmailInValid || isCCEmailInValid || isBCCEmailInValid"
              (onClick)="next()" label="Next" *ngIf="selectedStepIndex >= 0 && selectedStepIndex != 4"></p-button>
          </div>
          <!--
          <div *ngIf="selectedStepIndex==4"
            class="m-auto d-flex justify-content-center flex-column text-center permission-text active-wrapper">
            <h2 class="textcolor">
              Would you like to activate this campaign?
            </h2>
            <br>
            <p class="" hidden>
              Message will be sent to 503 contacts.
            </p>
            <p class="" *ngIf="userRoleFoundInToRecipientList">
              {{userRoleNameFoundInToRecipientListMessage}}
            </p>
            <div class="" data-toggle="buttons">
              <label class="btn btn-primary rbutton mt-2" [class.active]="selectedItem === 1">
                <input type="radio" name="options" id="option1" (change)="actionContent($event, 'YesRadio')"
                  class="hidden">
                Yes, I want to
                activate </label>
              <br />
              <label class="btn btn-outline-primary rounded-pill mt-4 outline-btn" [class.active]="selectedItem === 0">
                <input type="radio" name="options" id="option2" (change)="actionContent($event, 'NoRadio')"
                  class="hidden">
                Continue
                Editing </label>
            </div>
          </div>-->

        </div>
      </div>

      <!--Validation Campaign dialog -->
      <p-dialog class="custom-alert" #validateCampaignDialog [(visible)]="showValidateCampaignDialog"
        [resizable]="false" [modal]="true" [style]="{'font-size':'16px' ,'padding':'0px !important'}"
        (onHide)="onContinueEditing()">
        <div>
          <span class="fw-bold mb-4" style="font-size: 20px;"> The following are incomplete and required to activate
            this
            campaign: </span>
          <ul [innerHTML]="validationString" style="padding: 2% 8%;"></ul>
        </div>
        <div class="">
        </div>
        <ng-template pTemplate="footer text-left">
          <div class="col text-left d-flex">
            <p-button class="pt-2" (click)="onContinueEditing()" [style]="{'margin-right': '17.5px'}" label="Close"
              styleClass="p-button-rounded">
            </p-button>
          </div>
        </ng-template>
      </p-dialog>

      <!--
    *************************************************************************************
    Test Mode Alert Dialogs 
    *************************************************************************************
    -->
      <p-dialog class="custom-alert custom-user-alert" [(visible)]="showTestModeDialog" [resizable]="false"
        [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700'}">
        <div class="pb-25">
          <span class="alert-header">Your campaign has been activated, but you're currently in TEST mode.</span>
        </div>
        <div class="pb-35">
          <span class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;">
            At this time, messages will be sent to loan officer only. To begin sending to your contacts, change the
            Marketing Mode switch in your company profile from Test to Live.
          </span>
        </div>
        <ng-template pTemplate="footer text-left">
          <div class="col text-left">
            <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="GoToCompanyProfile()"
              label="Go To Company Profile" styleClass="p-button-rounded"></p-button>
            <p-button [style]="{ 'margin-left': '17.5px' }" (click)="ClickOk()" label="Ok"
              styleClass="p-button-secondary"></p-button>
          </div>
        </ng-template>
      </p-dialog>
