import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})

export class AddAdminComponent implements OnInit {
  public selectedLabel: string;
  public selectedItem: any;
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";

  constructor(private router: Router) {
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
   }

  ngOnInit(): void {
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/welcome-form']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToOptIn(): void {
    let navigateTo;
    let navigateFrom = '/add-admin';

    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    
    if (this.selectedLabel == "YesRadio") {
      navigateTo = '/add-individual';
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
    }
    else if (this.selectedLabel == "NoRadio") {      
      navigateTo = '/send-query';
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
    }    
  }

  actionContent(event, label) {
    if (event.target.checked) {
      this.selectedLabel = label
    }
    if(label == "YesRadio") {
      this.selectedItem = 1;
    } else {
      this.selectedItem = 0;
    }
  }
}
