import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter } from '../../../models/PeopleAndLoanFilterOutput';
import { PeopleAndLoanService } from '../../../services/peopleandloans.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-people-and-list-advanced-filter-currency',
  templateUrl: './people-and-list-filter-currency.component.html',
  styleUrls: ['./people-and-list-filter-currency.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterCurrencyComponent implements OnInit, OnDestroy {
  public selectedOperand: any | null;
  public textFormGroup: FormGroup | null;
  public items: FormArray | null;
  public disableApplyFilter: boolean;

  private sub: Array<Subscription>;
  private selectedFilter: PeopleAndLoanFieldLookup | null;
  private peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput;
  private tmpPeopleAndLoanFilters: PeopleAndLoanFilterData;
  private peopleAndLoanFilters: PeopleAndLoanFilterData;
  private storageHelper: StorageHelper;
  private editNode: PeopleAndLoanEditTagData | null;
  private insertIndex: number;

  @Input() OperandDropDownList: any | null;
  @Input() get SelectedFilter(): any | null {
    return this.selectedFilter;
  }
  set SelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      this.textFormGroup = null;
      this.items = null;
      this.generateForm();
    }
  }

  @Output() onCreateFilterEvent = new EventEmitter<PeopleAndLoanFilterOutput>();
  @Output() onCancelFilterEvent = new EventEmitter<boolean>();


  constructor(public formBuilder: FormBuilder, private peopleAndLoanService: PeopleAndLoanService,
    private cdr: ChangeDetectorRef) {
    this.textFormGroup = null;
    this.items = null;
    this.sub = new Array<Subscription>();
    this.peopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(false, null);
    this.storageHelper = new StorageHelper();
    this.disableApplyFilter = true;
    this.insertIndex = 0;
  }

  ngOnInit(): void {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
  }

  operandChanged(event, index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue1;
      const lookupControl2 = k.controls[index]['controls'].txtRHSValue2;

      lookupControl?.clearValidators();//clear Validators
      lookupControl.reset();
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(event.value.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      if (lookupControl2) {
        lookupControl2?.reset();
        lookupControl2?.clearValidators();//clear Validators
        if (/\BETWEEN\b/.test(event.value.OpCode)) {
          lookupControl2?.setValidators([Validators.required]);//reset Validators
        }
        lookupControl2?.updateValueAndValidity();//validate right now
      }

      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  operandChangedNew(index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  private generateForm() {
    this.unSubscribeAllSubs();

    this.textFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });

    this.cdr.detectChanges();

    if (this.SelectedFilter?.isAddFlag) {
      this.operandChangedNew(0);
    }

    let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();

    this.items = this.formDataCtrl;
    this.sub.push(
      this.textFormGroup.valueChanges.pipe(
        //debounceTime(500),//delay and  stop unnecessary propagation.
        distinctUntilChanged()
      ).subscribe(item => {
        listAndFilters.PeopleLoanListOrFilters = [];//clear all
        let idx = 0;
        listAndFilters.id = idx + 1;
        listAndFilters.peopleAndLoansFieldLookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId;

        //Changed json fetching first record value
        if (item?.items?.length) {
          listAndFilters.group = item.items[0].operandDropDown.FieldGroupName;
          listAndFilters.datasource = item.items[0].operandDropDown.DataSource;
          listAndFilters.datacolumn = item.items[0].operandDropDown.DataColumn;
          listAndFilters.type = item.items[0].operandDropDown.DataType;
          if (item.items[0].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
            listAndFilters.subGroup = item.items[0].operandDropDown.FieldSubGroupName;
          }
        }

        // this.existingListOrFilters = [];
        // let newIndex = -1;
        // let andFilterIndex = -1;
        // let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
        // if (savedFilter?.value) {
        //   let obj = JSON.parse(JSON.stringify(savedFilter.value));
        //   andFilterIndex = obj?.PeopleLoanList?.PeopleLoanListAndFilters?.length ?? -1;
        //   if (obj && andFilterIndex) {

        //     obj.PeopleLoanList.PeopleLoanListAndFilters.forEach(laf => {
        //       if (laf.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
        //         this.existingListOrFilters = laf.PeopleLoanListOrFilters;
        //         return false;//break;
        //       }
        //     });
        //     newIndex = this.existingListOrFilters.length + 1;
        //   }
        //   if (andFilterIndex != -1) {
        //     listAndFilters.id = andFilterIndex + 1;
        //   }
        // }
        //loop and add/remove validation as per dropdown value.
        item.items.forEach((element, index) => {
          if (index == 0) {
            listAndFilters.PeopleLoanListOrFilters = [];//clear all
          }
          let rhsValue1 = element?.txtRHSValue1 ?? '';//removing special symbol
          let rhsValue2 = element?.txtRHSValue2 ?? '';//removing special symbol
          let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
          filter.id = (index + 1);
          filter.operator = element.operandDropDown.OpCode;
          filter.lhs = '';
          filter.rhs = [rhsValue1, rhsValue2];
          filter.peopleAndLoansTypeDefLookupId = element.operandDropDown.PeopleAndLoansTypeDefLookupId;

          let criteriaQuery = element.operandDropDown.Criteria;
          if (criteriaQuery) {
            criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
            criteriaQuery = criteriaQuery.replace('{{rhs1}}', rhsValue1);
            criteriaQuery = criteriaQuery.replace('{{rhs2}}', rhsValue2);
            filter.criteria = criteriaQuery;
          }

          let htmlTemplate = element.operandDropDown.DisplayHtmlTemplate;
          if (htmlTemplate) {
            //convert to  $###,### 
            rhsValue1 = formatNumber(Number(element?.txtRHSValue1), 'en-US', '1.2-2');
            rhsValue2 = formatNumber(Number(element?.txtRHSValue2), 'en-US', '1.2-2');

            htmlTemplate = htmlTemplate.replace('{{lhs}}', element.operandDropDown.DataColumnDisplay);
            htmlTemplate = htmlTemplate.replace('{{rhs1}}', rhsValue1);
            htmlTemplate = htmlTemplate.replace('{{rhs2}}', rhsValue2);
            let prefix ='';// listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
            filter.displayHtml = prefix + htmlTemplate;
          }
          listAndFilters.PeopleLoanListOrFilters.push(filter)
        });
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
        // if (this.existingListOrFilters?.length) {
        //   this.existingListOrFilters.forEach(filter => {
        //     listAndFilters.PeopleLoanListOrFilters.push(filter)
        //   });
        // }

        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters);

        //validate and show apply button
        if (!this.textFormGroup?.valid) {
          this.disableApplyFilter = true;
        } else {
          this.disableApplyFilter = false;
        }
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');
      })
    );
  }
  applyFilter() {
    if (this.textFormGroup?.valid) {//if json has been received.
      this.validateAndEmitSuccessJson();

    } else {
      this.emitFailureJson();
    }
  }

  private createItems(): FormGroup[] {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //edit filter from input or oberabel.
    const formGroupArr: FormGroup[] = [];

    //Edit CASE  
    let editNodeTmp = this.storageHelper.GetDataFromStorage('PeopleAndLoan_editNode');//fetch 
    if (editNodeTmp?.value?.peopleAndLoansFieldLookupId &&
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length > 0) {
      this.editNode = editNodeTmp.value;
      let andIndex = this.editNode?.andIndex;
      let lookupId = this.editNode?.peopleAndLoansFieldLookupId;
      //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean

      let toIterate = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.
        filter(x => x.id == andIndex && x.peopleAndLoansFieldLookupId == lookupId);

      let lblValue = editNodeTmp?.value?.labelStr;
      toIterate[0].PeopleLoanListOrFilters.forEach((itm) => {
        // if (!lblValue) {
        //   lblValue = itm.displayHtml.substring(0, itm.displayHtml.indexOf("</b>"))
        //   lblValue = lblValue.replace("<b class=\"lhs\">", "").trim();
        // }
        let validatorOrOpts = [
          Validators.required,
          Validators.maxLength(50)
        ];

        let selectedDropdown = this.OperandDropDownList.find(x => x.PeopleAndLoansTypeDefLookupId == itm.peopleAndLoansTypeDefLookupId);

        let rhsValue1 = '';
        let rhsValue2 = '';
        if (itm?.rhs?.length > 0) {
          rhsValue1 = itm.rhs[0] ?? '0';
        }
        if (itm?.rhs?.length > 1) {
          rhsValue2 = itm.rhs[1] ?? '0';
        }
        //conditional validation
        if (selectedDropdown.OpCode == 'NOTBLANK' || selectedDropdown.OpCode == 'BLANK') {
          validatorOrOpts = [];//no validation
        }

        formGroupArr.push(
          this.formBuilder.group({
            label: new FormControl(lblValue),
            operandDropDown: new FormControl(selectedDropdown),
            txtRHSValue1: new FormControl(rhsValue1, validatorOrOpts),
            txtRHSValue2: new FormControl(rhsValue2)
          })
        );
      });
    }
    else {
      let selectedDropdown = null;
      if (this.SelectedFilter?.isAddFlag) {//insert as blank
        selectedDropdown = this.OperandDropDownList.find(x => x.OpCode == 'BLANK');
      }

      formGroupArr.push(
        this.formBuilder.group({
          label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
          //operandDropDown: new FormControl({ value: '' })
          operandDropDown: new FormControl(selectedDropdown),
          txtRHSValue1: new FormControl('', [Validators.required, Validators.maxLength(50)]),
          txtRHSValue2: new FormControl('',)
        })
      );
    }
    return formGroupArr;
  }

  // private createItems(): FormGroup[] {
  //   const formGroupArr: FormGroup[] = [];
  //   formGroupArr.push(
  //     this.formBuilder.group({
  //       label: new FormControl(this.SelectedFilter?.label),
  //       operandDropDown: new FormControl({ value: '' }),
  //       txtRHSValue: new FormControl('', [Validators.required, Validators.maxLength(5)])
  //     })
  //   );
  //   return formGroupArr;
  // }

  get formDataCtrl(): FormArray {
    const formArray = this.textFormGroup?.get('items') as FormArray;
    return formArray;
  }

  deleteRow(index) {
    let k = this.textFormGroup?.get('items') as FormArray
    k.removeAt(index);
  }

  addMore() {
    let newRow = this.formBuilder.group({
      label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
      operandDropDown: new FormControl('', [Validators.required]),
      txtRHSValue1: new FormControl(''),
      txtRHSValue2: new FormControl('')
    });
    (<FormArray>this.textFormGroup?.get('items')).push(newRow);
  }

  validateAndEmitSuccessJson() {
    let len = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length;
    if (len) {
      if (this.editNode?.peopleAndLoansFieldLookupId) {//if it is edit
        let andIndexRemove =
          this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.findIndex(x =>
            x.id == this.editNode?.andIndex &&
            x.peopleAndLoansFieldLookupId == this.editNode.peopleAndLoansFieldLookupId);

        if (andIndexRemove !== -1) {
          this.peopleAndLoanFilters.PeopleLoanList.
            PeopleLoanListAndFilters.splice(andIndexRemove, 1);
        }
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = this.editNode?.andIndex;

      } else {
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = len + 1
      }

      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.forEach(ele => {
      //   if (ele.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
      //     ele.isDeleted = true;//set existing deleted.
      //   }
      // });
      // let tm = this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => x.isDeleted == false);

      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);

    }
    else {//add without any logic 
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);
    }
    this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];//reset 

    // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters =
    //   this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => !x.isDeleted);
    this.peopleAndLoanFilterOutput.isSuccess = true;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = this.peopleAndLoanFilters;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  emitFailureJson() {
    this.peopleAndLoanFilterOutput.isSuccess = false;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = null;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  cancelFilter() {
    this.onCancelFilterEvent.emit(true);
  }

  setAndEmit() {
    if (this.selectedFilter) {
      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      //loop
      let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
      filter.id = (this.insertIndex + 1);
      filter.operator = this.selectedFilter.OpCode;
      filter.lhs = '';
      filter.rhs = [''];
      filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
      let criteriaQuery = this.selectedFilter.Criteria;
      if (criteriaQuery) {
        criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
        criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
        filter.criteria = criteriaQuery;
      }

      let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
      if (htmlTemplate) {
        htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
        htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
        let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
        filter.displayHtml = ' ' + htmlTemplate;
      }
      listAndFilters.PeopleLoanListOrFilters.push(filter);
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
      this.insertIndex++;
      this.validateAndEmitSuccessJson();
    }
  }

  unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  ngOnDestroy(): void {
    this.selectedOperand = null;
    this.OperandDropDownList = null;
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.peopleAndLoanFilters.PeopleLoanList = new PeopleLoanList();//clean all json
    //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
    this.unSubscribeAllSubs();
  }

}
