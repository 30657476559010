import { Injectable } from '@angular/core';

/**
* Utility service for 64-bit integer bitwise operations.
* 
* JavaScript will cast all of its numeric values into signed 32-bit integers to do bitwise operations.
* Bitwise operations on values greater than what a 32-bit integer can represent will result in 0.
*/
@Injectable()
export class BitwiseService {

  /**
  * Default constructor
  */
  constructor() { }

  /**
   * Performs a bitwise AND (&) operation on a 64 bit integer.
   *
   * JavaScript will cast all of its numeric values into signed 32-bit integers to do bitwise operations.
   * Bitwise operations on values greater than what a 32-bit integer can represent will result in 0.
   *
   * @param value
   * @param andValue
   */
  AND(value: number, andValue: number) {
    var hiMask = 0x80000000;
    var lowMask = 0x7fffffff;

    //console.log('------------------------------------');
    //console.log('AND: ' + (value / hiMask) + ' - may result in value < 1');
    //console.log('AND: ' + ~(value / hiMask) + ' - always negative integer - ((valueHiDWord + 1)*-1)');
    //console.log('AND: ' + ~~(value / hiMask) + ' - valueHiDWord - positive integer - ((resultFromAbove + 1)*-1)');
    //console.log('------------------------------------');

    // get hi DWORD (32-bit integer)
    var valueHiDWord = ~~(value / hiMask);
    var andValueHiDWord = ~~(andValue / hiMask);

    // get low DWORD (32-bit integer)
    var valueLowDWord = value & lowMask;
    var andValueLowDWord = andValue & lowMask;

    // perform operation on 32-bit integers
    var hiDWord = valueHiDWord & andValueHiDWord;
    var lowDWord = valueLowDWord & andValueLowDWord;

    // reconstruct 64-bit integer
    return (hiDWord * hiMask) + lowDWord;
  }

  /**
   * Performs a bitwise OR (|) operation on a 64 bit integer.
   *
   * JavaScript will cast all of its numeric values into signed 32-bit integers to do bitwise operations.
   * Bitwise operations on values greater than what a 32-bit integer can represent will result in 0.
   *
   * @param value
   * @param orValue
   */
  OR(value: number, orValue: number) {
    var hiMask = 0x80000000;
    var lowMask = 0x7fffffff;

    //console.log('------------------------------------');
    //console.log('OR: ' + (value / hiMask) + ' - may result in value < 1');
    //console.log('OR: ' + ~(value / hiMask) + ' - always negative integer - ((valueHiDWord + 1)*-1)');
    //console.log('OR: ' + ~~(value / hiMask) + ' - valueHiDWord - positive integer - ((resultFromAbove + 1)*-1)');
    //console.log('------------------------------------');

    // get hi DWORD (32-bit integer)
    var valueHiDWord = ~~(value / hiMask);
    var orValueHiDWord = ~~(orValue / hiMask);

    // get low DWORD (32-bit integer)
    var valueLowDWord = value & lowMask;
    var orValueLowDWord = orValue & lowMask;

    // perform operation on 32-bit integers
    var hiDWord = valueHiDWord | orValueHiDWord;
    var lowDWord = valueLowDWord | orValueLowDWord;

    // reconstruct 64-bit integer
    return (hiDWord * hiMask) + lowDWord;
  }


  /**
   * Performs a bitwise XOR (^) operation on a 64 bit integer.
   * 
   * JavaScript will cast all of its numeric values into signed 32-bit integers to do bitwise operations.
   * Bitwise operations on values greater than what a 32-bit integer can represent will result in 0.
   *
   * @param value
   * @param xorValue
   */
  XOR(value: number, xorValue: number) {
    var hiMask = 0x80000000;
    var lowMask = 0x7fffffff;

    //console.log('------------------------------------');
    //console.log('XOR: ' + (value / hiMask) + ' - may result in value < 1');
    //console.log('XOR: ' + ~(value / hiMask) + ' - always negative integer - ((valueHiDWord + 1)*-1)');
    //console.log('XOR: ' + ~~(value / hiMask) + ' - valueHiDWord - positive integer - ((resultFromAbove + 1)*-1)');
    //console.log('------------------------------------');

    // get hi DWORD (32-bit integer)
    var valueHiDWord = ~~(value / hiMask);
    var orValueHiDWord = ~~(xorValue / hiMask);

    // get low DWORD (32-bit integer)
    var valueLowDWord = value & lowMask;
    var orValueLowDWord = xorValue & lowMask;

    // perform operation on 32-bit integers
    var hiDWord = valueHiDWord ^ orValueHiDWord;
    var lowDWord = valueLowDWord ^ orValueLowDWord;

    // reconstruct 64-bit integer
    return (hiDWord * hiMask) + lowDWord;
  }

  /**
   * Returns the Twos Compliment (~) of a 64 bit integer.
   *
   * JavaScript will cast all of its numeric values into signed 32-bit integers to do bitwise operations.
   * Bitwise operations on values greater than what a 32-bit integer can represent will result in 0.
   *
   * @param value
   */
  NOT(value: number) {
    var hiMask = 0x80000000;
    var lowMask = 0x7fffffff;

    //console.log('------------------------------------');
    //console.log('TC: ' + (value / hiMask) + ' - may result in value < 1');
    //console.log('TC: ' + ~(value / hiMask) + ' - always negative integer - ((valueHiDWord + 1)*-1)');
    //console.log('TC: ' + ~~(value / hiMask) + ' - valueHiDWord - positive integer - ((resultFromAbove + 1)*-1)');
    //console.log('------------------------------------');

    // get hi DWORD (32-bit integer)
    var valueHiDWord = ~~(value / hiMask);
    // get low DWORD (32-bit integer)
    var valueLowDWord = value & lowMask;

    // perform operation on 32-bit integers
    valueHiDWord = 0xFFFFFFFF - valueHiDWord;
    valueLowDWord = 0xFFFFFFFF - valueLowDWord;

    // reconstruct 64-bit integer
    return valueHiDWord + valueLowDWord;
  }
}
