export const userRoles = [
    'Borrower',
    'Coborrower',
    'Loan Officer',
    'Loan Processor',
    'Loan Assistant',
    'Underwriter',
    'Closer',
    'Funder',
    'Post Closer',
    'Lock Desk',
    'Accounting',
    'Lender',
    'Appraiser',
    'Escrow Company',
    'Title Insurance Company',
    'Buyer`s Attorney',
    'Seller`s Attorney',
    'Buyer`s Agent',
    'Seller` Agent',
    'Real Estate Broker Buyer',
    'Real Estate Broker Seller',
    'Seller 1',
    'Seller 2',
    'Seller 3',
    'Seller 4',
    'Custom Agent 1',
    'Custom Agent 2',
    'Custom Agent 3',
    'Custome Agent 4',
    'Custom Agent 5',
    'Notary',
    'Builder',
    'Hazard Insurance',
    'Mortgage Insurance',
    'Surveyor',
    'Flood Insurance',
    'Credit Company',
    'Servicing',
    'Doc Signing',
    'Warehouse',
    'Financial Planner',
    'Investor',
    'Broker',
    'Closing Agent',
    'Property Tax',
    'Doc Preparer',
    'Settlement Agent',    
]

export const report = [
    'Active Contacts',
    'Active Loans',
    'Active Leads',
    'Closed Loans'    
]