import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { OnBoardingSetupCarrierTenant } from 'src/models/NGENTenant';
import { OnBoardingService } from '../../../services/onBoarding.service';
import { ToastService } from 'src/services/toast.service';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { USAStatesService } from 'src/services/usstates.service';

@Component({
  selector: 'app-carrier-form',
  templateUrl: './carrier-form.component.html',
  styleUrls: ['./carrier-form.component.scss']
})

export class CarrierFormComponent implements OnInit, AfterViewInit {
  companyForm2: FormGroup | any = null;
  public loadingPage: boolean = false;
  public usStates: any[] = [];
  public selectedState: any;
  public businessTypeList: any = [];
  public selectedBusinessType: string;
  // businessRegistrationTypeList: any = [];
  public selectedBusinessRegistrationType: string;
  // businessRegionOfOperationList: any = [];
  public selectedBusinessRegionsOfOperation: string;
  public isValidAuthorizedContactEmail: boolean;
  public tenant: OnBoardingSetupCarrierTenant = new OnBoardingSetupCarrierTenant();
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";
  public tenantId: any = '00000000-0000-0000-0000-000000000000';

  constructor(
    private router: Router,
    private usStatesService: USAStatesService,
    private formBuilder: FormBuilder,
    private onBoardingService: OnBoardingService,
    private toastService: ToastService,
  ) {
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
    this.usStates = usStatesService.getUSAStateCodesAndNames();
    this.selectedState = null;
    this.businessTypeList = [{ label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Partnership", value: "Partnership" },
    { label: "Corporation", value: "Corporation" },
    { label: "Co-operative", value: "Co-operative" },
    { label: "Limited Liability Corporation", value: "Limited Liability Corporation" },
    { label: "Non-Profit Corporation", value: "Non-Profit Corporation" },
    ];
    this.selectedBusinessType = "";

    // this.businessRegistrationTypeList = [ { label: "USA - EIN", value: "USA - EIN" },
    //                           { label: "Canada - CCN", value: "Canada - CCN" },
    //                           { label: "Britain - Company Number", value: "Britain - Company Number" },
    //                           { label: "Australia - ACN", value: "Australia - ACN" },
    //                           { label: "India - Corporate Identity Number, VAT Number", value: "India - Corporate Identity Number, VAT Number" },
    //                           { label: "Israel - Registration Number", value: "Israel - Registration Number" },
    // ];

    this.selectedBusinessRegistrationType = "USA - EIN";

    // this.businessRegionOfOperationList = [ { label: "Africa", value: "Africa" },
    //                           { label: "Asia", value: "Asia" },
    //                           { label: "Europe", value: "Europe" },
    //                           { label: "Latin America", value: "Latin America" },
    //                           { label: "USA", value: "USA" },
    //                           { label: "Canada", value: "Canada" },
    // ];

    this.selectedBusinessRegionsOfOperation = "USA";
    this.isValidAuthorizedContactEmail = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await this.loadCompany();
  }

  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  }

  setControlStates() { }

  createForm() {
    return new Promise((resolve, reject) => {
      this.companyForm2 = this.formBuilder.group({
        ein: [null, [Validators.required]],
        businessType: [null, [Validators.required]],
        legalBusinessName: [null, [Validators.required]],
        legalBusinessStreet: [null, [Validators.required]],
        legalBusinessCity: [null, [Validators.required]],
        legalBusinessCountry: 'United States',
        legalBusinessState: [null, [Validators.required]],
        legalBusinessZip: [null, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/s)]],
        authorizedContactFirstName: [null, [Validators.required]],
        authorizedContactLastName: [null, [Validators.required]],
        authorizedContactBusinessTitle: [null, [Validators.required]],
        authorizedContactEmail: [null, [Validators.required]],
        authorizedContactJobPosition: [null, [Validators.required]],
        authorizedContactCountry: 'United States',
        authorizedContactPhone: [null, [Validators.required, Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]]
      });
      resolve(true);
    });
  }

  async loadCompany() {
    await this.getData();
    if (this.tenant) {
      this.companyForm2.patchValue({
        ein: this.tenant.EIN,
        businessType: this.tenant.BusinessType,
        legalBusinessName: this.tenant.LegalBusinessName,
        legalBusinessStreet: this.tenant.LegalBusinessStreet,
        legalBusinessCity: this.tenant.LegalBusinessCity,
        legalBusinessCountry: this.tenant.LegalBusinessCountry === null || this.tenant.LegalBusinessCountry === 'null' ? 'United States' : this.tenant.LegalBusinessCountry,
        legalBusinessState: this.tenant.LegalBusinessState,
        legalBusinessZip: this.tenant.LegalBusinessZip,
        authorizedContactFirstName: this.tenant.AuthorizedContactFirstName,
        authorizedContactLastName: this.tenant.AuthorizedContactLastName,
        authorizedContactBusinessTitle: this.tenant.AuthorizedContactBusinessTitle,
        authorizedContactEmail: this.tenant.AuthorizedContactEmail,
        authorizedContactJobPosition: this.tenant.AuthorizedContactJobPosition,
        authorizedContactCountry: this.tenant.AuthorizedContactCountry === null || this.tenant.AuthorizedContactCountry === 'null' ? 'United States' : this.tenant.AuthorizedContactCountry,
        authorizedContactPhone: this.formatNumber(this.tenant.AuthorizedContactPhone)
      });      
      this.validateAuthorizedContactEmail();
    }
  }

  async getData() {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      var response = await this.onBoardingService.Get(this.tenantId);
      // assign returned object to class variable.
      this.tenant.EIN = response.data.ein;
      this.tenant.BusinessType = response.data.businessType;
      this.tenant.LegalBusinessName = response.data.legalBusinessName;
      this.tenant.LegalBusinessStreet = response.data.legalBusinessStreet;
      this.tenant.LegalBusinessCity = response.data.legalBusinessCity;
      this.tenant.LegalBusinessCountry = response.data.legalBusinessCountry;
      this.tenant.LegalBusinessState = response.data.legalBusinessState;
      this.tenant.LegalBusinessZip = response.data.legalBusinessZip;
      this.tenant.AuthorizedContactFirstName = response.data.authorizedContactFirstName;
      this.tenant.AuthorizedContactLastName = response.data.authorizedContactLastName;
      this.tenant.AuthorizedContactBusinessTitle = response.data.authorizedContactBusinessTitle;
      this.tenant.AuthorizedContactEmail = response.data.authorizedContactEmail;
      this.tenant.AuthorizedContactJobPosition = response.data.authorizedContactJobPosition;
      this.tenant.AuthorizedContactCountry = response.data.authorizedContactCountry;
      this.tenant.AuthorizedContactPhone = response.data.authorizedContactPhone;      

      // when data is retrieved, set this.loadingPage to false.
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  validateAuthorizedContactEmail() {
    if (this.companyForm2.value.authorizedContactEmail == "") {
      this.isValidAuthorizedContactEmail = true;
    }
    else {
      this.isValidAuthorizedContactEmail = this.validateEmail(this.companyForm2.value.authorizedContactEmail);
    }
  }

  validateEmail(emailAddress: any) {
    let isValid = false;
    const singleEmailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = singleEmailRegularExpression.test(String(emailAddress).toLowerCase());
    return isValid;
  }

  authorizedContactPhoneFormat(event: any) {//returns (###) ###-####
    let input: any = this.companyForm2.controls['authorizedContactPhone'].value;
    input = this.formatNumber(input);    
    this.companyForm2.controls['authorizedContactPhone'].setValue(input);
  }

  formatNumber(input) {
    if(input == '' || input == null || input === undefined) {
    } else {
      input = input.replace(/\D/g,'').substring(0,10); //Strip everything but 1st 10 digits
      var size = input.length;
      if (size>0) {input="("+input}
      if (size>3) {input=input.slice(0,4)+") "+input.slice(4)}
      if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
    }    
    return input;
  }

  async saveCompany() {
    this.loadingPage = true;
    try {
      let viewModel = new OnBoardingSetupCarrierTenant(
        this.tenant.TenantId,
        this.companyForm2.value.ein,        
        this.companyForm2.value.businessType,
        this.selectedBusinessRegistrationType,
        this.selectedBusinessRegionsOfOperation,
        this.companyForm2.value.legalBusinessName,
        this.companyForm2.value.legalBusinessStreet,
        this.companyForm2.value.legalBusinessCity,
        this.companyForm2.value.legalBusinessCountry,
        this.companyForm2.value.legalBusinessState,
        this.companyForm2.value.legalBusinessZip,
        this.companyForm2.value.authorizedContactFirstName,
        this.companyForm2.value.authorizedContactLastName,        
        this.companyForm2.value.authorizedContactBusinessTitle,
        this.companyForm2.value.authorizedContactEmail,
        this.companyForm2.value.authorizedContactJobPosition,
        this.companyForm2.value.authorizedContactCountry,
        this.companyForm2.value.authorizedContactPhone
      );      

      const formData: FormData = new FormData();
      formData.append('ein', viewModel.EIN);      
      formData.append('businessType', viewModel.BusinessType);
      formData.append('businessRegistrationType', viewModel.BusinessRegistrationType);
      formData.append('businessRegionsOfOperation', viewModel.BusinessRegionsOfOperation);
      formData.append('legalBusinessName', viewModel.LegalBusinessName);
      formData.append('legalBusinessStreet', viewModel.LegalBusinessStreet);
      formData.append('legalBusinessCity', viewModel.LegalBusinessCity);
      formData.append('legalBusinessCountry', viewModel.LegalBusinessCountry);
      formData.append('legalBusinessState', viewModel.LegalBusinessState);
      formData.append('legalBusinessZip', viewModel.LegalBusinessZip);
      formData.append('authorizedContactFirstName', viewModel.AuthorizedContactFirstName);
      formData.append('authorizedContactLastName', viewModel.AuthorizedContactLastName);      
      formData.append('authorizedContactBusinessTitle', viewModel.AuthorizedContactBusinessTitle);
      formData.append('authorizedContactEmail', viewModel.AuthorizedContactEmail);
      formData.append('authorizedContactJobPosition', viewModel.AuthorizedContactJobPosition);
      formData.append('authorizedContactCountry', viewModel.AuthorizedContactCountry);
      formData.append('authorizedContactPhone', viewModel.AuthorizedContactPhone);

      var response = await this.onBoardingService.OnBoardingCarrierProfile(formData);
      this.loadingPage = false;
      if (response.status == 200) {

      }
      this.loadingPage = false;
      //this.toastService.showSuccess(response.message);
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {   
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/add-admin'] or ['/add-individual']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToPermitCollect(): void {
    if (this.companyForm2.valid && this.isValidAuthorizedContactEmail) {
      this.saveCompany();
      let navigateTo = '/permit-collect';
      let navigateFrom = '/carrier-form';

      if (this.navigationStack === undefined) {
        this.navigationStack = [];
      }
      
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.companyForm2.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    //debugger;
    return invalid;
  }
}
