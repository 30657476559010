<div class="dialog-content">
  <div class=" position-relative">
    <div class="fw-bold text-dark" style=" font-size:22px;margin-bottom:25px">{{data.message}}</div>
    <div class="fw-bold pb-2">{{ data.secondLineMessage }}</div>
    <div class="ti-text" style=" font-size:20px; overflow: hidden;">
      <ul class="fw-bold " [innerHTML]="data.innerHtmlMessage">
      </ul>
    </div>
    <i class="pi pi-times close-icon" role="button" (click)="ref.close(false)"></i>
  </div>
</div>
<div class="pb-3"></div>
<div class="d-flex align-items-center">
  <button pButton pRipple type="button" [label]="data.successButtonText" class="p-button-rounded"
    [style]="{'margin-right': '17.5px'}" style="margin-right: 17.5px;" (click)="ref.close(true)"></button>
  <p-button styleClass="p-button-secondary" type="button" [label]="data.cancelButtonText"
    [style]="{'cursor': 'pointer','margin-left': '17.5px'}" (click)="ref.close(false)"></p-button>
  <!--  <a pRipple style="color:#666666; font-weight:500; cursor: pointer;margin: 0px 17.5px;text-decoration: underline;"(click)="ref.close(false)"> Cancel</a> -->
</div>