import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CompanyObserverService {
  constructor() {}

  private defaultSenderEmailSubject = new BehaviorSubject<string>('');

  setdefaultSenderEmail(email_address: string) {
    this.defaultSenderEmailSubject.next(email_address);
  }

  getdefaultSenderEmail(): Observable<string> {
    return this.defaultSenderEmailSubject.asObservable();
  }
}
