<div id="manageUsersDiv" role="main">
  <div class="content table-search-row d-flex flex-row">
    <table class="text-wrapper">
      <tr>
        <td class="flex flex-row">
          <div class="ml-0 mr-0 flex">
            <label id="lblPageHeader" class="px-1 my-auto">Clients</label>
          </div>
          <div class="ml-0 mr-0">
            <span class="p-input-icon-left p-float-label">
              <input id="contentFilterSrchInput" maxlength="250" [(ngModel)]="searchKeyword"
                 class="search-input rounded-pill" pInputText type="text"
                (keyup.enter)="applySearch()"
                [placeholder]="'Search by keyword'" />
            </span>
          </div>
          <div class="ml-0 mr-0 flex">
            <span type="button" pButton pRipple icon="fal fa-search search-fa" pTooltip="Execute Search"
            tooltipPosition="bottom" showDelay="500" (click)="applySearch()"
            class="p-button-secondary execute-search s-icon my-auto cursor-pointer"></span>
          </div>
          <div class="ml-0 mr-auto pl-4 flex">
            <span class="my-auto">You currently have <span class="primary-color-font font-bold">{{searchModel.totalRecords}}</span> clients</span>
          </div>
          <div class="ml-auto mr-0">
            <button pButton icon="pi pi-plus text-white" label="Create New Client" class="header-button"
              (click)="addNewUser('/company-profile/add')"></button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
<div class="text-wrapper" *ngIf="tenants.length > 0">
  <app-custom-grid class="mx-4" [data]="tenants" 
  [columns]="columns" 
  [searchModel]="searchModel"
  (onPageChanged)="onPageChangedParent($event)"
  [showFilters]="true"
  (updatedFilters)="updateTable()"
  [showExport]="false">
  <ng-template #tabletTemplate let-row>
    <ng-container *ngFor="let col of columns;let i = index">
      <td *ngIf="col.display">
        <ng-container [ngSwitch]="col.field">
          <ng-container *ngSwitchDefault>
            <span *ngIf="col.field === 'companyName'" class="aduvo-purple-text cursor-pointer" routerLink="../client-editor/{{row['tenantId']}}">{{ row[col.field] }}</span>
            <span *ngIf="col.field !== 'companyName'">{{ row[col.field] }}</span>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-template>
</app-custom-grid>
</div>
