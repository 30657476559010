import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { Observable, throwError } from 'rxjs'; 
import { catchError, tap } from 'rxjs/operators'; 
import { RequestResponse } from 'src/models/RequestResponse' 
import { PagingData } from 'src/models/PagingData'; 
import { AppSettings } from 'src/appSettings'; 
 
@Injectable() 
export class EmailStatsService { 
  constructor(private http: HttpClient) { } 
 
  /**  
   * Retrieves all necessary data for calling component. 
   *  
   * @param requestResponse - optional in oder to specify specific service information. 
   */ 
  async getEmailStatsForCampaign(requestResponse?: RequestResponse) { 
    // Angular does not support posting a body inside the /GET verb. 
    // https://github.com/angular/angular/issues/9927 
 
    // if we were not passed a RequestResponse object, use the default. 
    if ((requestResponse === undefined) || (requestResponse === null)) 
      requestResponse = new RequestResponse(); 
 
    console.log("Calling the default /POST WebApi 'emailStats' Controller action."); 
    return await this.http.post<RequestResponse>(AppSettings.API_GET_EMAIL_STATS_CAMPAIGN, requestResponse)
      .pipe(catchError(this.handleError)).toPromise(); 
  } 
 
  /** 
  * Error processing. 
  *  
  * @param caught The caught error information. 
  */ 
  private handleError(caught: any) { 
    console.error(caught.error.message); 
    // throw error back to the calling method. 
    return throwError(caught.error.message); 
  } 
} 
