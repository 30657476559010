import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterTypes } from 'src/components/custom-grid/enums/filter-types';
import { SearchModel } from 'src/components/custom-grid/models/search-model';
import { BillingService } from 'src/services/billingService';

@Component({
  selector: 'app-client-manager',
  templateUrl: './client-manager.component.html',
  styleUrls: ['./client-manager.component.scss']
})
export class ClientManagerComponent implements OnInit {

  searchTerm: string = '';
  searchModel: SearchModel = new SearchModel({
    pageNum: 1,
    pageSize: 10,
  });
  columns: Array<any> = [
    {
      field: 'companyName',
      header: 'Name',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: [],
      filterTruples: [] 
    },
    {
      field: 'lenderId',
      header: 'Lender ID',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'loanOriginationSystem',
      header: 'Loan Origination System',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: [],
      filterTruples: [] 
    },
    {
      field: 'status',
      header: 'Status',
      isSortable: true,
      isFilterable: true,
      filterType: FilterTypes.TEXT,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'messagesSent',
      header: 'Message Count',
      isSortable: true,
      isFilterable: false,
      filterType: FilterTypes.NUMBER,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: [],
      filterTruples: [] 
    },
    {
      field: 'users',
      header: 'User Count',
      isSortable: true,
      isFilterable: false,
      filterType: FilterTypes.NUMBER,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    },
    {
      field: 'published',
      header: 'Active Campaigns',
      isSortable: true,
      isFilterable: false,
      filterType: FilterTypes.NUMBER,
      showOperator: false,
      display: true,
      filterValues: [],
      filterOptions: []
    }
  ]
  tenants: Array<any> = new Array<any>()
  searchKeyword: string = '';
  orderBy: string = 'CompanyName ASC';
  constructor(private billingService: BillingService, private router: Router) { }

  ngOnInit(): void {

    this.billingService.GetAllTenantsByFilterValues().then(response => {

      this.columns.forEach(column => {
        const columnName = column.field.toUpperCase();
        const columnValues =  response.data.filter(s => s.columnName.toUpperCase() === columnName);
        if(columnValues.length > 0){
          column.filterOptions = columnValues.map(s => {
            return {field: s.value, value: s.value};
          });
        }
      })

      this.searchModel.filterStr = JSON.stringify({
        "companyName": "",
        "clientId": "",
        "users": null,
        "status": "",
        "los": 0,
        "msgsSent": null,
        "orderBy": this.orderBy
      });

      this.populateData();

    }).catch(error => {
      console.error(error);
    }).finally(() => {
      console.log('Load Finished');
    });


  }

  addNewUser(url: string){
    this.router.navigate([`${url}`]);
  }

  updateTable(){

    this.applyFilters();
    this.searchModel.pageNum = 1;
    this.populateData();
  }

  onPageChangedParent($event: any){

    const pageNumber = $event.first !== undefined ? Math.floor($event.first / this.searchModel.pageSize) + 1 : this.searchModel.pageNum;
    this.searchModel.pageNum = $event.first === 1 ? 1 : pageNumber;
    if ($event.sortField == null || $event.sortField === "")
      this.orderBy = "CompanyName ASC"
    else
      this.orderBy = `${$event.sortField} ${$event.sortOrder === 1 ? 'ASC' : 'DESC'}`;
    this.applyFilters();
    this.populateData();

  }

  applySearch(){
    this.searchModel.filterStr = JSON.stringify({
      "companyName": this.searchKeyword,
      "clientId": this.columns.find(s => s.field === 'lenderId').filterValues.join(','),
      "users": null,
      "status": this.columns.find(s => s.field === 'status').filterValues.join(','),
      "los": this.columns.find(s => s.field === 'loanOriginationSystem').filterValues.join(','),
      "msgsSent": null,
      "orderBy": this.orderBy
    });
    this.searchModel.pageNum = 1;
    this.populateData();
  }

  private applyFilters(){
    this.searchModel.filterStr = JSON.stringify({
      "companyName": this.columns.find(s => s.field === 'companyName').filterValues.join(','),
      "clientId": this.columns.find(s => s.field === 'lenderId').filterValues.join(','),
      "users": null,
      "status": this.columns.find(s => s.field === 'status').filterValues.join(','),
      "los": this.columns.find(s => s.field === 'loanOriginationSystem').filterValues.join(','),
      "msgsSent": null,
      "orderBy": this.orderBy
    });
  }

  private populateData(){
    this.billingService.GetAllTenantsByFilter(this.searchModel).then(response => {
      this.searchModel.totalRecords = response.pageInfo.totalRecords;
      this.tenants = response.data;
      this.tenants = [...this.tenants];
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      console.log('Load Finished');
    });
  }
}
