import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestResponse } from 'src/models/RequestResponse'
import { AppSettings } from 'src/appSettings';
import { NGENMasterRecord, NGENMasterRecordCollection } from 'src/models/NGENMasterRecord';
import { StorageHelper } from 'src/helpers/StorageHelper';

@Injectable()
export class LoansService {
  storageHelper: StorageHelper;
  constructor(private http: HttpClient) {
    this.storageHelper = new StorageHelper();
  }

  /**
   * Retrieves all necessary data for this component. 
   */
  async getData(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /POST in the webapi Contacts Controller");
    return await this.http.post<RequestResponse>('AppSettings.API_URL' + '/Loans', requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }


  // getLoanAssignTypeRoleList(isPublishingDb: boolean = false): Observable<RequestResponse> {
  //   return this.http.get<RequestResponse>(AppSettings.API_GET_LOAN_STATUS_TYPE +
  //     "/GetLoanAssignTypeRoles?pageNum=-1&isPublishingDb=" + isPublishingDb)
  //     .pipe(catchError(this.handleError));
  // }


  /**
  * Gets a collection of Campaign records.  
  * @param requestResponse
  */
  GetLoanStatusType(isPublishingDb: boolean = false): Observable<RequestResponse> {
    let cacheKey = Number(isPublishingDb).toString() + 'LoanStatusKey'; //storing for 0,1 as key, for publishDb and tenantdb
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      console.info("Call /LoanStatusType in the webapi campaign Controller");
      return this.http.get<RequestResponse>(AppSettings.API_GET_LOAN_STATUS_TYPE + "/list?pageNum=-1&isPublishingDb=" + isPublishingDb)
        .pipe(tap(x => {
            if (x && x.data && x.status == 200) {
              x.data = x.data.sort((a, b) => {
                if (a.displayName < b.displayName) {
                  return -1;
                }
              });
              this.storageHelper.SetDataInStorage(cacheKey, x, 4);
            }
          }), catchError(this.handleError));
    }
  }

  /**
  * Gets a collection of LoanStatusType MasterList records.  
  * @param requestResponse
  */
  GetLoanStatusTypeMasterList(isPublishingDb: boolean = false): Observable<RequestResponse> {
    let cacheKey = Number(isPublishingDb).toString() + 'LoanStatusTypeAllMasterDataKey1'; //storing for 0,1 as key, for publishDb and tenantdb
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      console.info("Call /GetLoanStatusTypeMasterList in the webapi campaign Controller");

      return this.http.get<RequestResponse>(
        AppSettings.API_GET_LOAN_STATUS_TYPE + "/GetMasterList?isPublishingDb=" + isPublishingDb)
        .pipe(tap(x => {
          if (x && x.data && x.status == 200) this.storageHelper.SetDataInStorage(cacheKey, x, .0002)
        }),
          catchError(this.handleError));
    }
  }

  /**
  * Gets a collection of LoanStatusType Contact Types records.  
  * @param requestResponse
  */
  GetMasterContactTypes(isPublishingDb: boolean = false): Observable<RequestResponse> {
    let cacheKey = Number(isPublishingDb).toString() + 'MasterContactTypesKey'; //storing for 0,1 as key, for publishDb and tenantdb

    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      console.info("Call /GetMasterContactTypes in the webapi campaign Controller");
      return this.http.get<RequestResponse>(
        AppSettings.API_GET_LOAN_STATUS_TYPE + "/GetMasterContactTypes?isPublishingDb=" + isPublishingDb)
        .pipe(tap(x => {
          if (x && x.data && x.status == 200) this.storageHelper.SetDataInStorage(cacheKey, x, 4)
        }),
          catchError(this.handleError));
    }
  }

  /**
  * Gets a collection of LoanAssign Type Roles records.  
  * @param requestResponse
  */
  GetLoanAssignTypeRoles(isPublishingDb: boolean = false): Observable<RequestResponse> {
    let cacheKey = Number(isPublishingDb).toString() + 'LoanAssignTypesKey'; //storing for 0,1 as key, for publishDb and tenantdb

    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      return this.http.get<RequestResponse>(
        AppSettings.API_GET_LOAN_STATUS_TYPE + "/GetLoanAssignTypeRoles?isPublishingDb=" + isPublishingDb)
        .pipe(tap(x => {
          if (x && x.status == 200 && x.data) this.storageHelper.SetDataInStorage(cacheKey, x, 4);
        }),
          catchError(this.handleError));
    }
  }


  /**
  * Error processing.
  * 
  * @param caught The caught error information.
  */
  private handleError(caught: any) {
    if ((caught) && (caught.error) && (caught.error.message)) {
      console.error(caught.error.message);
      return throwError(caught.error.message);
    }
    if ((caught) && (caught.error)) {
      caught.error = "Unknown exception";
      return throwError(caught);
    }
    return throwError("Unknown exception");
  }




  toCollectionInstance(data: any[]) {
    let result: NGENMasterRecordCollection = new NGENMasterRecordCollection();
    for (let x = 0; x < data.length; x++)
      result.Items.push(this.toInstance(data[x]));
    return result;
  }

  toInstance(data: any) {
    let result = new NGENMasterRecord(
      data.TenantId,
      data.LoanId,
      data.PulseCreatedDateUTC,
      data.PulseLastModifiedDateUTC,
      data.LoanNumber,
      data.LoanOriginationSystem,
      data.CreatedDate,
      data.LastModifiedDate,
      data.LoanStatusId,
      data.LoanStatus,
      data.LoanStatusDate,
      data.BaseLoanAmount,
      data.TotalLoanAmount,
      data.Term,
      data.NoteRate,
      data.DownPaymentAmount,
      data.DownPaymentPercentage,
      data.LockTerm,
      data.LoanEstimateType,
      data.LoanProgram,
      data.ClosingCostScenarioName,
      data.BorrowerFirstName,
      data.BorrowerLastName,
      data.BorrowerMiddleName,
      data.BorrowerNameSuffix,
      data.BorrowerNickname,
      data.BorrowerBirthDate,
      data.BorrowerSSN,
      data.BorrowerEquifaxCreditScore,
      data.BorrowerExperianCreditScore,
      data.BorrowerMinimumFICOScore,
      data.BorrowerTransUnionCreditScore,
      data.BorrowerEmail,
      data.BorrowerMobilePhone,
      data.BorrowerWorkPhone,
      data.BorrowerFax,
      data.BorrowerHomePhone,
      data.BorrowerStreet,
      data.BorrowerCity,
      data.BorrowerState,
      data.BorrowerZip,
      data.BorrowerOwns,
      data.BorrowerRents,
      data.BorrowerHash,
      data.CoBorrowerFirstName,
      data.CoBorrowerLastName,
      data.CoBorrowerMiddleName,
      data.CoBorrowerNameSuffix,
      data.CoBorrowerNickname,
      data.CoBorrowerBirthDate,
      data.CoBorrowerSSN,
      data.CoBorrowerEquifaxCreditScore,
      data.CoBorrowerExperianCreditScore,
      data.CoBorrowerMinimumFICOScore,
      data.CoBorrowerTransUnionCreditScore,
      data.CoBorrowerEmail,
      data.CoBorrowerMobilePhone,
      data.CoBorrowerWorkPhone,
      data.CoBorrowerFax,
      data.CoBorrowerHomePhone,
      data.CoBorrowerStreet,
      data.CoBorrowerCity,
      data.CoBorrowerState,
      data.CoBorrowerZip,
      data.CoBorrowerOwns,
      data.CoBorrowerRents,
      data.CoBorrowerHash,
      data.AppraisedValue,
      data.PurchasePrice,
      data.SubjectPropertyStreet,
      data.SubjectPropertyCity,
      data.SubjectPropertyCounty,
      data.SubjectPropertyState,
      data.SubjectPropertyZip,
      data.SubjectPropertyTypeId,
      data.SubjectPropertyTypeDesc,
      data.LoanOfficerName,
      data.LoanOfficerFirstName,
      data.LoanOfficerLastName,
      data.LoanOfficerEmail,
      data.LoanOfficerMobilePhone,
      data.LoanOfficerWorkPhone,
      data.LoanOfficerFax,
      data.LoanOfficerNmlsId,
      data.LoanOfficerHash,
      data.LoanProcessorName,
      data.LoanProcessorFirstName,
      data.LoanProcessorLastName,
      data.LoanProcessorEmail,
      data.LoanProcessorMobilePhone,
      data.LoanProcessorWorkPhone,
      data.LoanProcessorFax,
      data.LoanProcessorHash,
      data.SellersAgentName,
      data.SellersAgentFirstName,
      data.SellersAgentLastName,
      data.SellersAgentCompany,
      data.SellersAgentStreet,
      data.SellersAgentCity,
      data.SellersAgentState,
      data.SellersAgentZip,
      data.SellersAgentEmail,
      data.SellersAgentMobilePhone,
      data.SellersAgentWorkPhone,
      data.SellersAgentFax,
      data.SellersAgentHash,
      data.BuyersAgentName,
      data.BuyersAgentFirstName,
      data.BuyersAgentLastName,
      data.BuyersAgentCompany,
      data.BuyersAgentStreet,
      data.BuyersAgentCity,
      data.BuyersAgentState,
      data.BuyersAgentZip,
      data.BuyersAgentEmail,
      data.BuyersAgentMobilePhone,
      data.BuyersAgentWorkPhone,
      data.BuyersAgentFax,
      data.BuyersAgentHash,
      data.ClosingAgentName,
      data.ClosingAgentFirstName,
      data.ClosingAgentLastName,
      data.ClosingAgentCompany,
      data.ClosingAgentStreet,
      data.ClosingAgentCity,
      data.ClosingAgentState,
      data.ClosingAgentZip,
      data.ClosingAgentEmail,
      data.ClosingAgentMobilePhone,
      data.ClosingAgentWorkPhone,
      data.ClosingAgentFax,
      data.ClosingAgentHash,
      data.ShipperName,
      data.ShipperFirstName,
      data.ShipperLastName,
      data.ShipperEmail,
      data.ShipperMobilePhone,
      data.ShipperWorkPhone,
      data.ShipperFax,
      data.ShipperHash,
      data.UnderwriterName,
      data.UnderwriterFirstName,
      data.UnderwriterLastName,
      data.UnderwriterEmail,
      data.UnderwriterMobilePhone,
      data.UnderwriterWorkPhone,
      data.UnderwriterFax,
      data.UnderwriterHash,
      data.DocPreparerName,
      data.DocPreparerFirstName,
      data.DocPreparerLastName,
      data.DocPreparerEmail,
      data.DocPreparerMobilePhone,
      data.DocPreparerWorkPhone,
      data.DocPreparerFax,
      data.DocPreparerHash,
      data.ProspectStatusDate,
      data.LoanSubmittedDate,
      data.LoanSuspensionDate,
      data.DocsBackDate,
      data.DocsOrderedDate,
      data.DocsSentDate,
      data.FloodCertificationOrdered,
      data.FloodCertificationReceived,
      data.HMDACompletedStatusDate,
      data.LoanApplicationDate,
      data.LoanApprovedDate,
      data.LoanCancelledDate,
      data.LoanClosedDate,
      data.LoanDeniedDate,
      data.LoanDocsDrawnDate,
      data.LoanFundedDate,
      data.PreliminaryReportOrderedDate,
      data.PreliminaryReportReceivedDate,
      data.ResubmitStatusDate,
      data.SentToProcessing,
      data.TILDisclosure_GFEOrderedDate,
      data.TILDisclosure_GFEReceivedDate,
      data.AppraisalReportOrderedDate,
      data.AppraisalReportReceivedDate,
      data.ClosingDocumentsOrderedDate,
      data.ClosingDocumentsReceivedDate,
      data.ClearToCloseDate,
      data.LoanRecordedDate,
      data.CDDateIssued,
      data.RateLockDate,
      data.RateLockExpirationDate,
      data.RateLockConfirmedDate,
      data.EstimatedClosingDate,
      data.FirstPaymentDate,
      data.FundByDate,
      data.LoanTypeId,
      data.LoanTypeDesc,
      data.OccupancyTypeId,
      data.OccupancyTypeDesc,
      data.OccupancyTypeCode,
      data.LoanPurposeId,
      data.LoanPurposeDesc,
      data.LoanPurposeCode,
      data.LenderCompanyName,
      data.LenderName,
      data.LenderFirstName,
      data.LenderLastName,
      data.LenderEmail,
      data.LenderWorkPhone,
      data.LenderFax,
      data.LenderHash,
      data.OriginatingOrganizationName,
      data.OriginatingOrganizationNmlsId,
      data.OriginatingOrganizationStreet,
      data.OriginatingOrganizationCity,
      data.OriginatingOrganizationState,
      data.OriginatingOrganizationZip,
      data.OriginatorName,
      data.OriginatorEmail,
      data.OriginatorPhone,
      data.OriginatorNmlsId,
      data.OriginatorHash,
      data.ConversationLog,
      data.AppraisalCompanyAgentName,
      data.AppraisalCompanyAgentFirstName,
      data.AppraisalCompanyAgentLastName,
      data.AppraisalCompany,
      data.AppraisalCompanyStreet,
      data.AppraisalCompanyCity,
      data.AppraisalCompanyState,
      data.AppraisalCompanyZip,
      data.AppraisalCompanyEmail,
      data.AppraisalCompanyMobilePhone,
      data.AppraisalCompanyWorkPhone,
      data.AppraisalCompanyFax,
      data.AppraisalCompanyHash,
      data.FloodInsuranceCompanyAgentName,
      data.FloodInsuranceCompanyAgentFirstName,
      data.FloodInsuranceCompanyAgentLastName,
      data.FloodInsuranceCompany,
      data.FloodInsuranceCompanyStreet,
      data.FloodInsuranceCompanyCity,
      data.FloodInsuranceCompanyState,
      data.FloodInsuranceCompanyZip,
      data.FloodInsuranceCompanyEmail,
      data.FloodInsuranceCompanyMobilePhone,
      data.FloodInsuranceCompanyWorkPhone,
      data.FloodInsuranceCompanyFax,
      data.FloodInsuranceCompanyHash,
      data.HazardInsuranceCompanyAgentName,
      data.HazardInsuranceCompanyAgentFirstName,
      data.HazardInsuranceCompanyAgentLastName,
      data.HazardInsuranceCompany,
      data.HazardInsuranceCompanyStreet,
      data.HazardInsuranceCompanyCity,
      data.HazardInsuranceCompanyState,
      data.HazardInsuranceCompanyZip,
      data.HazardInsuranceCompanyEmail,
      data.HazardInsuranceCompanyMobilePhone,
      data.HazardInsuranceCompanyWorkPhone,
      data.HazardInsuranceCompanyFax,
      data.HazardInsuranceCompanyHash,
      data.TitleCompanyAgentName,
      data.TitleCompanyAgentFirstName,
      data.TitleCompanyAgentLastName,
      data.TitleCompany,
      data.TitleCompanyStreet,
      data.TitleCompanyCity,
      data.TitleCompanyState,
      data.TitleCompanyZip,
      data.TitleCompanyEmail,
      data.TitleCompanyMobilePhone,
      data.TitleCompanyWorkPhone,
      data.TitleCompanyFax,
      data.TitleCompanyHash,
      data.BorrowerName,
      data.CoBorrowerName,
      data.LoanOfficerAssistantName,
      data.LoanOfficerAssistantFirstName,
      data.LoanOfficerAssistantLastName,
      data.LoanOfficerAssistantEmail,
      data.LoanOfficerAssistantMobilePhone,
      data.LoanOfficerAssistantWorkPhone,
      data.LoanOfficerAssistantFax,
      data.LoanOfficerAssistantHash
    );
    return result;
  }

} 
