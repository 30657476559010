import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ToastService } from '../../../../../src/services/toast.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CampaignsService } from '../../../../../src/services/campaigns.service';
import {
  DropDownItem,
  TenantUserItem,
} from '../../../../../src/models/TenantUser';
import { LookupService } from '../../../../../src/services/lookup.service';
import { UserManagementService } from '../../../../../src/services/userManagement.service';
import { UserTokenService } from '../../../../../src/services/user-token.service';
import { UserToken } from '../../../../../src/models/UserToken';
import { LoansService } from '../../../../services/Loans.service';
import { MessageTemplateDialogModel } from '../../../../models/MessageTemplate';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { CampaignAddVM } from '../../../../models/Campaign';
import { StorageHelper } from '../../../../helpers/StorageHelper';
import { CommonService } from '../../../../services/common.service';
import { environment } from '../../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MergeTagsService } from 'src/services/mergeTags.service';
import { ImageLibraryService } from 'src/services/imageLibrary.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MessagesService } from 'src/services/messages.service';
import { SMSService } from 'src/services/sms.service';
//import { MessageTemplateEditorState } from 'src/models/state/messageTemplateEditor.state';
import { RequestResponse } from 'src/models/RequestResponse';
import { PagingData } from 'src/models/PagingData';
import { TextMessage } from 'src/models/Message';
@Component({
  selector: 'app-new-text-message-editor',
  templateUrl: './create-new-text-message-editor.component.html',
  styleUrls: ['./create-new-text-message-editor.component.scss'],
})
export class NewTextMessageEditorComponent implements OnInit, OnDestroy {
  @Output() onSaveNewTextMessageClick: EventEmitter<string> = new EventEmitter();
  @Output() onCancelNewTextMessageClick: EventEmitter<string> = new EventEmitter();
  @Input() stepIndex: number;
  @Input() selectedNewTextMessage: TextMessage;
  @Output() onValueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public textMessageFirstFormGroup: FormGroup | any = null;
  public loadingPage: boolean;

  public messageCategoryList: DropDownItem[];
  public isFirstPageSubmitted = false;
  userToken: UserToken;
  // editorState: MessageTemplateEditorState = new MessageTemplateEditorState();
  currentClientID: string;

  public destroyed = new Subject<any>();
  public changeCount: number;

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    private userTokenService: UserTokenService,
    public dialogService: DialogService,
    private lookupService: LookupService,
    private http: HttpClient
  ) {
    var dataw = this.selectedNewTextMessage;
    this.stepIndex = 0;
    this.messageCategoryList = [];
    this.changeCount = 0;
  }

  ngOnInit(): void {
    this.currentClientID = environment.clientId;
    this.loadingPage = false;
    this.userToken = this.userTokenService.getUser();
    this.lookupService.GetCampaignCategories().subscribe(
      (result) => {
        if (result && result.data) {
          this.messageCategoryList = result.data.map((obj) => {
            return {
              item_id: obj.categoryId,
              item_text: obj.categoryName,
            };
          });
        }
      },
      (error) => {
        this.toastService.showError(error);
      }
    );
    this.createForm();

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.stepIndex = 0;
      this.isFirstPageSubmitted = false;
      this.createForm();
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  createForm() {
    this.textMessageFirstFormGroup = this.formBuilder.group({
      messageCategory: [null, [Validators.required]],
      chkQuickStart: [false],
      chkRecommended: [false],
      txtMessageName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(64),
        ],
      ],
      txtMessageDescription: [null],
    });

    this.textMessageFirstFormGroup.valueChanges.pipe(
      debounceTime(1000),//delay and  stop unnecessary propagation.
      distinctUntilChanged()
    ).subscribe(x => {
      //console.log('change1');
      if (this.changeCount > 0) {
        this.onValueChanged.emit(true);
      }
      this.changeCount += 1;
    });
  }
  async onNextClick(): Promise<void> {
    this.isFirstPageSubmitted = true;
    if (this.textMessageFirstFormGroup.valid) {
      this.selectedNewTextMessage.textMessageName =
        this.textMessageFirstFormGroup.value.txtMessageName;
      this.selectedNewTextMessage.textMessageDescription =
        this.textMessageFirstFormGroup.value.txtMessageDescription;
      this.selectedNewTextMessage.textMessageCategoryId =
        this.textMessageFirstFormGroup.value.messageCategory;
      this.selectedNewTextMessage.chkQuickStart =
        this.textMessageFirstFormGroup.value.chkQuickStart;
      this.selectedNewTextMessage.chkRecommended =
        this.textMessageFirstFormGroup.value.chkRecommended;
      this.onSaveNewTextMessageClick.emit();
      //this.router.navigate(['/marketing/template-library/sms2']);
    }
  }
  async onExitClick(): Promise<void> {
    this.selectedNewTextMessage.textMessageName =
      this.textMessageFirstFormGroup.value.txtMessageName;
    this.selectedNewTextMessage.textMessageDescription =
      this.textMessageFirstFormGroup.value.txtMessageDescription;
    this.selectedNewTextMessage.textMessageCategoryId =
      this.textMessageFirstFormGroup.value.messageCategory;
    this.selectedNewTextMessage.chkQuickStart =
      this.textMessageFirstFormGroup.value.chkQuickStart;
    this.selectedNewTextMessage.chkRecommended =
      this.textMessageFirstFormGroup.value.chkRecommended;
    this.onCancelNewTextMessageClick.emit();
  }
}
