export interface  TenantUser {
    tenantUserId: string
    aspNetUserId: string
    name: string
    userType: string
    emailId: string
    phoneNumber: string
    losUser: string
    losRole: string
    losStatus: string
}
export class TenantUserItem {
    item_id: string;
    item_text: string;
    isDisabled: boolean;
}

export class DropDownItem {
    constructor(public item_id = '',
        public item_text = '',
        public isDisabled = false) {
    }
}


export interface SelectItemGroup {
    label: string;
    value?: any;
    items: SelectItem[];
}
export interface SelectItem<T = any> {
    label?: string;
    value: T;
    styleClass?: string;
    icon?: string;
    title?: string;
    disabled?: boolean;
}