import { Injectable } from '@angular/core';
import { OidcSecurityService, TokenHelperService } from 'angular-auth-oidc-client';
import { UserToken } from '../models/UserToken';
import  jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UserTokenService {
  private user: UserToken;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    public tokenHelperService: TokenHelperService
  ) { 
    this.oidcSecurityService.userData$.subscribe((userData) => {
      this.user = Object.assign(new UserToken(), userData);
      //console.log("User Data", this.user);
    });
  }

  public getUser(): UserToken{
    return this.user;
  }

  public getAuthTime(): number {
    //debugger;
    return this.tokenHelperService.getPayloadFromToken(this.oidcSecurityService.getIdToken(), false).auth_time;
  }
}
