/** 
* Implementation of the FocusIT.DataContracts.Pulse.NGENTenant [DataContract].
*/
export class NGENTenant {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public Name: string = '';
  public CompanyCode: string = '';
  public WebSite: string = '';
  public Street: string = '';
  public City: string = '';
  public State: string = '';
  public Zip: string = '';
  public Email: string = '';
  public WorkPhone: string = '';
  public Fax: string = '';
  public Suite: string = '';
  public DefaultReplyEmail: string = '';
  public DefaultSenderEmail: string = '';
  public StateLicenses: string = '';
  public NmlsId: string = '';
  public FaceBookURL: string = '';
  public TwitterURL: string = '';
  public LinkedInURL: string = '';
  public LeadIntakeURL: string = '';
  public LeadIntakeDescription: string = '';
  public Signature: string = '';
  public Logo: string = '';
  public SendGridApiKey: string = '';
  public LoanOriginationSystem: number = 0;
  public RunMode: string = '';
  public LenderId: string = '';
  public DBConnectionString: string = '';
  public TimezoneId: string = '';
  public SendgridApiKeyLoanStatus: string = '';
  public SendgridApiKeyMarketing: string = '';
  public TwilioAccountSid: string = '';
  public TwilioAPIKey: string = '';
  public TwilioMessagingServiceSid: string = '';
  public TwilioMessagingServiceSidMarketing: string = '';
  public EIN: string = '';
  public LegalBusinessName: string = '';
  public BusinessType: string = '';
  public BusinessRegistrationType: string = '';
  public BusinessRegionsOfOperation: string = '';
  public LegalBusinessStreet: string = '';
  public LegalBusinessCity: string = '';
  public LegalBusinessCountry: string = '';
  public LegalBusinessState: string = '';
  public LegalBusinessZip: string = '';
  public AuthorizedContactFirstName: string = '';
  public AuthorizedContactLastName: string = '';
  public AuthorizedContactEmail: string = '';
  public AuthorizedContactBusinessTitle: string = '';
  public AuthorizedContactCountry: string = '';
  public AuthorizedContactPhone: string = '';
  public AuthorizedContactJobPosition: string = '';
  public CompanyAdminEmail: string = '';
  public CompanyAdminFirstName: string = '';
  public CompanyAdminLastName: string = '';
  public CompanyAdminNMLS: string = '';
  public CompanyAdminMobilePhone: string = '';
  public CompanyAdminWorkPhone: string = '';
  public IntegrationType: string = '';
  public EncompassClientId: string = '';
  public EncompassClientSecret: string = '';
  public Address2: string = '';
  public TwilioEnabled: boolean = false;
  public LosVendorId: string = '';

  constructor(
    TenantId?: string,
    Name?: string,
    CompanyCode?: string,
    WebSite?: string,
    Street?: string,
    City?: string,
    State?: string,
    Zip?: string,
    Email?: string,
    WorkPhone?: string,
    Fax?: string,
    Suite?: string,
    DefaultReplyEmail?: string,
    DefaultSenderEmail?: string,
    StateLicenses?: string,
    NmlsId?: string,
    FaceBookURL?: string,
    TwitterURL?: string,
    LinkedInURL?: string,
    LeadIntakeURL?: string,
    LeadIntakeDescription?: string,
    Signature?: string,
    Logo?: string,
    SendGridApiKey?: string,
    LoanOriginationSystem?: number,
    RunMode?: string,
    LenderId?: string,
    DBConnectionString?: string,
    TimezoneId?: string,
    SendgridApiKeyLoanStatus?: string,
    SendgridApiKeyMarketing?: string,
    EIN?: string,
    LegalBusinessName?: string,
    BusinessType?: string,
    BusinessRegistrationType?: string,
    BusinessRegionsOfOperation?: string,
    LegalBusinessStreet?: string,
    LegalBusinessCity?: string,
    LegalBusinessCountry?: string,
    LegalBusinessState?: string,
    LegalBusinessZip?: string,
    AuthorizedContactFirstName?: string,
    AuthorizedContactLastName?: string,
    AuthorizedContactEmail?: string,
    AuthorizedContactBusinessTitle?: string,
    AuthorizedContactCountry?: string,
    AuthorizedContactPhone?: string,
    AuthorizedContactJobPosition?: string,
    CompanyAdminEmail?: string,
    CompanyAdminFirstName?: string,
    CompanyAdminLastName?: string,
    CompanyAdminNMLS?: string,
    CompanyAdminMobilePhone?: string,
    CompanyAdminWorkPhone?: string,
    IntegrationType?: string,
    EncompassClientId?: string,
    EncompassClientSecret?: string,
    TwilioAccountSid?: string,
    TwilioAPIKey?: string,
    TwilioMessagingServiceSid?: string,
    TwilioMessagingServiceSidMarketing?: string,
    LosVendorId?:string,
    Address2?: string,
    TwilioEnabled?: boolean
  ) {
    if (TenantId !== undefined && TenantId !== null) this.TenantId = TenantId;
    if (Name !== undefined && Name !== null) this.Name = Name;
    if (CompanyCode !== undefined && CompanyCode !== null) this.CompanyCode = CompanyCode;
    if (WebSite !== undefined && WebSite !== null) this.WebSite = WebSite;
    if (Street !== undefined && Street !== null) this.Street = Street;
    if (City !== undefined && City !== null) this.City = City;
    if (State !== undefined && State !== null) this.State = State;
    if (Zip !== undefined && Zip !== null) this.Zip = Zip;
    if (Email !== undefined && Email !== null) this.Email = Email;
    if (WorkPhone !== undefined && WorkPhone !== null) this.WorkPhone = WorkPhone;
    if (Fax !== undefined && Fax !== null) this.Fax = Fax;
    if (Suite !== undefined && Suite !== null) this.Suite = Suite;
    if (DefaultReplyEmail !== undefined && DefaultReplyEmail !== null) this.DefaultReplyEmail = DefaultReplyEmail;
    if (DefaultSenderEmail !== undefined && DefaultSenderEmail !== null) this.DefaultSenderEmail = DefaultSenderEmail;
    if (StateLicenses !== undefined && StateLicenses !== null) this.StateLicenses = StateLicenses;
    if (NmlsId !== undefined && NmlsId !== null) this.NmlsId = NmlsId;
    if (FaceBookURL !== undefined && FaceBookURL !== null) this.FaceBookURL = FaceBookURL;
    if (TwitterURL !== undefined && TwitterURL !== null) this.TwitterURL = TwitterURL;
    if (LinkedInURL !== undefined && LinkedInURL !== null) this.LinkedInURL = LinkedInURL;
    if (LeadIntakeURL !== undefined && LeadIntakeURL !== null) this.LeadIntakeURL = LeadIntakeURL;
    if (LeadIntakeDescription !== undefined && LeadIntakeDescription !== null) this.LeadIntakeDescription = LeadIntakeDescription;
    if (Signature !== undefined && Signature !== null) this.Signature = Signature;
    if (Logo !== undefined && Logo !== null) this.Logo = Logo;
    if (SendGridApiKey !== undefined && SendGridApiKey !== null) this.SendGridApiKey = SendGridApiKey;
    if (LoanOriginationSystem !== undefined && LoanOriginationSystem !== null) this.LoanOriginationSystem = LoanOriginationSystem;
    if (RunMode !== undefined && RunMode !== null) this.RunMode = RunMode;
    if (LenderId !== undefined && LenderId !== null) this.LenderId = LenderId;
    if (DBConnectionString !== undefined && DBConnectionString !== null) this.DBConnectionString = DBConnectionString;
    if (TimezoneId !== undefined && TimezoneId !== null) this.TimezoneId = TimezoneId;
    if (SendgridApiKeyLoanStatus !== undefined && SendgridApiKeyLoanStatus !== null) this.SendgridApiKeyLoanStatus = SendgridApiKeyLoanStatus;
    if (SendgridApiKeyMarketing !== undefined && SendgridApiKeyMarketing !== null) this.SendgridApiKeyMarketing = SendgridApiKeyMarketing;
    if (EIN !== undefined && EIN !== null) this.EIN = EIN;
    if (LegalBusinessName !== undefined && LegalBusinessName !== null) this.LegalBusinessName = LegalBusinessName;
    if (BusinessType !== undefined && BusinessType !== null) this.BusinessType = BusinessType;
    if (BusinessRegistrationType !== undefined && BusinessRegistrationType !== null) this.BusinessRegistrationType = BusinessRegistrationType;
    if (BusinessRegionsOfOperation !== undefined && BusinessRegionsOfOperation !== null) this.BusinessRegionsOfOperation = BusinessRegionsOfOperation;
    if (LegalBusinessStreet !== undefined && LegalBusinessStreet !== null) this.LegalBusinessStreet = LegalBusinessStreet;
    if (LegalBusinessCity !== undefined && LegalBusinessCity !== null) this.LegalBusinessCity = LegalBusinessCity;
    if (LegalBusinessCountry !== undefined && LegalBusinessCountry !== null) this.LegalBusinessCountry = LegalBusinessCountry;
    if (LegalBusinessState !== undefined && LegalBusinessState !== null) this.LegalBusinessState = LegalBusinessState;
    if (LegalBusinessZip !== undefined && LegalBusinessZip !== null) this.LegalBusinessZip = LegalBusinessZip;
    if (AuthorizedContactFirstName !== undefined && AuthorizedContactFirstName !== null) this.AuthorizedContactFirstName = AuthorizedContactFirstName;
    if (AuthorizedContactLastName !== undefined && AuthorizedContactLastName !== null) this.AuthorizedContactLastName = AuthorizedContactLastName;
    if (AuthorizedContactEmail !== undefined && AuthorizedContactEmail !== null) this.AuthorizedContactEmail = AuthorizedContactEmail;
    if (AuthorizedContactBusinessTitle !== undefined && AuthorizedContactBusinessTitle !== null) this.AuthorizedContactBusinessTitle = AuthorizedContactBusinessTitle;
    if (AuthorizedContactCountry !== undefined && AuthorizedContactCountry !== null) this.AuthorizedContactCountry = AuthorizedContactCountry;
    if (AuthorizedContactPhone !== undefined && AuthorizedContactPhone !== null) this.AuthorizedContactPhone = AuthorizedContactPhone;
    if (AuthorizedContactJobPosition !== undefined && AuthorizedContactJobPosition !== null) this.AuthorizedContactJobPosition = AuthorizedContactJobPosition;
    if (CompanyAdminEmail !== undefined && CompanyAdminEmail !== null) this.CompanyAdminEmail = CompanyAdminEmail;
    if (CompanyAdminFirstName !== undefined && CompanyAdminFirstName !== null) this.CompanyAdminFirstName = CompanyAdminFirstName;
    if (CompanyAdminLastName !== undefined && CompanyAdminLastName !== null) this.CompanyAdminLastName = CompanyAdminLastName;
    if (CompanyAdminNMLS !== undefined && CompanyAdminNMLS !== null) this.CompanyAdminNMLS = CompanyAdminNMLS;
    if (CompanyAdminMobilePhone !== undefined && CompanyAdminMobilePhone !== null) this.CompanyAdminMobilePhone = CompanyAdminMobilePhone;
    if (CompanyAdminWorkPhone !== undefined && CompanyAdminWorkPhone !== null) this.CompanyAdminWorkPhone = CompanyAdminWorkPhone;
    if (IntegrationType !== undefined && IntegrationType !== null) this.IntegrationType = IntegrationType;
    if (EncompassClientId !== undefined && EncompassClientId !== null) this.EncompassClientId = EncompassClientId;
    if (EncompassClientSecret !== undefined && EncompassClientSecret !== null) this.EncompassClientSecret = EncompassClientSecret;
    if (TwilioAccountSid !== undefined && TwilioAccountSid !== null) this.TwilioAccountSid = TwilioAccountSid;
    if (TwilioAPIKey !== undefined && TwilioAPIKey !== null) this.TwilioAPIKey = TwilioAPIKey;
    if (TwilioMessagingServiceSid !== undefined && TwilioMessagingServiceSid !== null) this.TwilioMessagingServiceSid = TwilioMessagingServiceSid;
    if (TwilioMessagingServiceSidMarketing !== undefined && TwilioMessagingServiceSidMarketing !== null) this.TwilioMessagingServiceSidMarketing = TwilioMessagingServiceSidMarketing;
    if (LosVendorId !== undefined && LosVendorId !== null) this.LosVendorId = LosVendorId;
    if (Address2 !== undefined && Address2 !== null) this.Address2 = Address2;
    if (TwilioEnabled !== undefined && TwilioEnabled !== null) this.TwilioEnabled = TwilioEnabled;
  }
}

/**
* Collection of FocusIT.DataContracts.Pulse.NGENTenant [DataContract] objects.
*/
export class NGENTenantCollection {
  Items: NGENTenant[] = [];
}

export class OnBoardTenant {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public Name: string = '';
  public Email: string = '';
  public AddressLine1: string = '';
  public AddressLine2: string = '';
  public City: string = '';
  public State: string = '';
  public Zip: string = '';
  public WorkPhone: string = '';
  public Fax: string = '';
  public NmlsId: string = '';
  public WebSite: string = '';
  public TimezoneId: string = '';
  public LosVendorId: string = '';

  /**
   * Constructor - all parameters are optional.
   * Only passed parameters are assigned to their corresponding property.
   *
   * @param TenantId
   * @param Name
   * @param Email   
   * @param AddressLine1
   * @param AddressLine2
   * @param City
   * @param State
   * @param Zip
   * @param WorkPhone
   * @param Fax
   * @param NmlsId
   * @param WebSite
   * @param TimezoneId   
   */
  constructor(
    TenantId?: string,
    Name?: string,
    Email?: string,
    AddressLine1?: string,
    AddressLine2?: string,
    City?: string,
    State?: string,
    Zip?: string,
    WorkPhone?: string,
    Fax?: string,
    NmlsId?: string,
    WebSite?: string,
    TimezoneId?: string,
    LosVendorId?: string
  ) {
    if (TenantId !== undefined && TenantId !== null) this.TenantId = TenantId;
    if (Name !== undefined && Name !== null) this.Name = Name;
    if (Email !== undefined && Email !== null) this.Email = Email;
    if (AddressLine1 !== undefined && AddressLine1 !== null) this.AddressLine1 = AddressLine1;
    if (AddressLine2 !== undefined && AddressLine2 !== null) this.AddressLine2 = AddressLine2;
    if (City !== undefined && City !== null) this.City = City;
    if (State !== undefined && State !== null) this.State = State;
    if (Zip !== undefined && Zip !== null) this.Zip = Zip;
    if (WorkPhone !== undefined && WorkPhone !== null) this.WorkPhone = WorkPhone;
    if (Fax !== undefined && Fax !== null) this.Fax = Fax;
    if (NmlsId !== undefined && NmlsId !== null) this.NmlsId = NmlsId;
    if (WebSite !== undefined && WebSite !== null) this.WebSite = WebSite;
    if (TimezoneId !== undefined && TimezoneId !== null) this.TimezoneId = TimezoneId;
    if (LosVendorId !== undefined && LosVendorId !== null) this.LosVendorId = LosVendorId;
  }
}

export class OnBoardingTenantCollection {
  Items: OnBoardTenant[] = [];
}

export class OnBoardSetupAdminTenant {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public CompanyAdminFirstName: string = '';
  public CompanyAdminLastName: string = '';
  public CompanyAdminEmail: string = '';
  public CompanyAdminEmailReadonly: string = '' ;

  /**
   * Constructor - all parameters are optional.
   * Only passed parameters are assigned to their corresponding property.
   *
   * @param TenantId
   * @param CompanyAdminFirstName
   * @param CompanyAdminEmail
   * @param CompanyAdminEmailReadonly
   */
  constructor(
    TenantId?: string,
    CompanyAdminFirstName?: string,
    CompanyAdminLastName?: string,
    CompanyAdminEmail?: string,
    CompanyAdminEmailReadonly?: string
  ) {
    if (TenantId !== undefined && TenantId !== null) this.TenantId = TenantId;
    if (CompanyAdminFirstName !== undefined && CompanyAdminFirstName !== null) this.CompanyAdminFirstName = CompanyAdminFirstName;
    if (CompanyAdminLastName !== undefined && CompanyAdminLastName !== null) this.CompanyAdminLastName = CompanyAdminLastName;
    if (CompanyAdminEmail !== undefined && CompanyAdminEmail !== null) this.CompanyAdminEmail = CompanyAdminEmail;
    if (CompanyAdminEmailReadonly !== undefined && CompanyAdminEmailReadonly !== null) this.CompanyAdminEmailReadonly = CompanyAdminEmailReadonly;
  }
}

export class OnBoardingSetupAdminCollection {
  Items: OnBoardSetupAdminTenant[] = [];
}

export class OnBoardingSetupCarrierTenant {
  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public EIN: string = '';  
  public BusinessType: string = '';
  public BusinessRegistrationType: string = '';
  public BusinessRegionsOfOperation: string = '';
  public LegalBusinessName: string = '';
  public LegalBusinessStreet: string = '';
  public LegalBusinessCity: string = '';
  public LegalBusinessCountry: string = '';
  public LegalBusinessState: string = '';
  public LegalBusinessZip: string = '';
  public AuthorizedContactFirstName: string = '';
  public AuthorizedContactLastName: string = '';
  public AuthorizedContactBusinessTitle: string = '';
  public AuthorizedContactEmail: string = '';
  public AuthorizedContactJobPosition: string = '';
  public AuthorizedContactCountry: string = '';
  public AuthorizedContactPhone: string = '';
  
  /**
   * Constructor - all parameters are optional.
   * Only passed parameters are assigned to their corresponding property.
   *
   * @param TenantId
   * @param EIN   
   * @param BusinessType
   * @param BusinessRegistrationType
   * @param BusinessRegionsOfOperation
   * @param LegalBusinessName
   * @param LegalBusinessStreet
   * @param LegalBusinessCity
   * @param LegalBusinessCountry
   * @param LegalBusinessState
   * @param LegalBusinessZip
   * @param AuthorizedContactFirstName
   * @param AuthorizedContactLastName
   * @param AuthorizedContactBusinessTitle
   * @param AuthorizedContactEmail
   * @param AuthorizedContactJobPosition
   * @param AuthorizedContactCountry
   * @param AuthorizedContactPhone
   */
  constructor(
    TenantId?: string,
    EIN?: string,    
    BusinessType?: string,
    BusinessRegistrationType?: string,
    BusinessRegionsOfOperation?: string,
    LegalBusinessName?: string,
    LegalBusinessStreet?: string,
    LegalBusinessCity?: string,
    LegalBusinessCountry?: string,
    LegalBusinessState?: string,
    LegalBusinessZip?: string,
    AuthorizedContactFirstName?: string,
    AuthorizedContactLastName?: string,    
    AuthorizedContactBusinessTitle?: string,
    AuthorizedContactEmail?: string,
    AuthorizedContactJobPosition?: string,
    AuthorizedContactCountry?: string,
    AuthorizedContactPhone?: string,    
  ) {
    if (TenantId !== undefined && TenantId !== null) this.TenantId = TenantId;
    if (EIN !== undefined && EIN !== null) this.EIN = EIN;
    if (LegalBusinessName !== undefined && LegalBusinessName !== null) this.LegalBusinessName = LegalBusinessName;
    if (BusinessType !== undefined && BusinessType !== null) this.BusinessType = BusinessType;
    if (BusinessRegistrationType !== undefined && BusinessRegistrationType !== null) this.BusinessRegistrationType = BusinessRegistrationType;
    if (BusinessRegionsOfOperation !== undefined && BusinessRegionsOfOperation !== null) this.BusinessRegionsOfOperation = BusinessRegionsOfOperation;
    if (LegalBusinessStreet !== undefined && LegalBusinessStreet !== null) this.LegalBusinessStreet = LegalBusinessStreet;
    if (LegalBusinessCity !== undefined && LegalBusinessCity !== null) this.LegalBusinessCity = LegalBusinessCity;
    if (LegalBusinessCountry !== undefined && LegalBusinessCountry !== null) this.LegalBusinessCountry = LegalBusinessCountry;
    if (LegalBusinessState !== undefined && LegalBusinessState !== null) this.LegalBusinessState = LegalBusinessState;
    if (LegalBusinessZip !== undefined && LegalBusinessZip !== null) this.LegalBusinessZip = LegalBusinessZip;
    if (AuthorizedContactFirstName !== undefined && AuthorizedContactFirstName !== null) this.AuthorizedContactFirstName = AuthorizedContactFirstName;
    if (AuthorizedContactLastName !== undefined && AuthorizedContactLastName !== null) this.AuthorizedContactLastName = AuthorizedContactLastName;
    if (AuthorizedContactEmail !== undefined && AuthorizedContactEmail !== null) this.AuthorizedContactEmail = AuthorizedContactEmail;
    if (AuthorizedContactBusinessTitle !== undefined && AuthorizedContactBusinessTitle !== null) this.AuthorizedContactBusinessTitle = AuthorizedContactBusinessTitle;
    if (AuthorizedContactCountry !== undefined && AuthorizedContactCountry !== null) this.AuthorizedContactCountry = AuthorizedContactCountry;
    if (AuthorizedContactPhone !== undefined && AuthorizedContactPhone !== null) this.AuthorizedContactPhone = AuthorizedContactPhone;
    if (AuthorizedContactJobPosition !== undefined && AuthorizedContactJobPosition !== null) this.AuthorizedContactJobPosition = AuthorizedContactJobPosition;    
  }
}

export class OnBoardingSetupCarrierTenantCollection {
  Items: OnBoardingSetupCarrierTenant[] = [];
}
