import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from '../../../../../../src/app/services/CalendarAndTimeZoneService';
import { formState } from 'src/helpers/formState.helper';
import {
  CMSComponentData,
  CustomDateData,
  SavedOneTimeCustomDate,
} from 'src/models/MessageScheduleData';
import { LookupService } from 'src/services/lookup.service';
import { ToastService } from 'src/services/toast.service';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-one-time-custom-date',
  templateUrl: './campaign-message-schedule-custom-date.component.html',
  styleUrls: ['./campaign-message-schedule-custom-date.component.scss'],
})
export class CampaignMessageScheduleCustomDateComponent implements OnInit {
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public CMSComponentData: CMSComponentData;
  customDateData: CustomDateData;
  @Input() ScheduleDateMessage: string;
  @Input() ScheduleTCPAForTextMessages: string;
  oneTimeCustomDateForm: FormGroup | any = null;
  validator: formState = new formState();
  isFormValueChange = false;
  isAfter = false;
  showTimeControls: boolean = true;
  hours: any[];
  minutes: any[];
  meridiems: any[];
  messageType: number;
  timeZones: any[];
  startDates: any[];
  dateFormat: string = 'mm/dd/yy';
  todayDate = new Date();
  deliverDate = new Date();
  userTimeZoneId: any;
  userToken: UserToken;
  defaultSendOn: string;
  public selectedHour: number;
  public selectedMinute: number;
  public selectedAMPM: string;
  public timeToolTipe: string;
  public currentClientID: string;
  public tcpaForTextMessages: string;
  allowTCPAForTextMessages: boolean = true;
  public disableDaysArray: string;

  constructor(
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private userTokenService: UserTokenService,
    public datepipe: DatePipe
  ) {
    this.currentClientID = environment.clientId;
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(2);
    this.customDateData = new CustomDateData();
    this.userToken = this.userTokenService.getUser();
    this.messageType = this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe = 'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';

    if(this.messageType == 2 && this.allowTCPAForTextMessages) {
      this.disableDaysArray = '[0,6]';
    } else {
      this.disableDaysArray = '';
    }
  }

  ngOnInit(): void {
    //this.tomorrowDate.setDate(this.todayDate.getDate());
    if (this.currentClientID === 'AduvoAdmin') {
      this.defaultSendOn = 'ATSPECIFICTIME';
      this.showTimeControls = false;
    } else {
      this.defaultSendOn = 'ATSPECIFICTIME';
      this.showTimeControls = true;
    }
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;

    forkJoin(this.lookupService.GetTimezones()).subscribe(
      ([timezonesresponse]) => {
        this.timeZones = timezonesresponse.data;
        this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
        let defaultObject =
          this.calendarAndTimeZoneService.getDefaultDateTimeObject(
            this.userTimeZoneId,
            this.timeZones
          );
        if (this.userTimeZoneId) {
          let defaultData = {
            hour: defaultObject.defaultHour,
            minute: defaultObject.defaultMinute,
            meridiem:
              this.messageType == 2 && this.allowTCPAForTextMessages
                ? this.calendarAndTimeZoneService.getMeridiem(
                    defaultObject.defaultHour
                  )
                : defaultObject.defaultMeridiem,
            timeZoneId: defaultObject.defaultTimeZoneId,
            deliverOnDate: defaultObject.defaultDate,
            sendOnTimeType: this.defaultSendOn,
          };
          let savedObject = new SavedOneTimeCustomDate();
          if (
            scheduleDataJSON &&
            scheduleDataJSON != 'No Schedule' &&
            scheduleType == 'OneTime' &&
            ScheduleSubtype == 'CustomDate'
          ) {
            savedObject = JSON.parse(scheduleDataJSON)[0];
            if (savedObject) {
              let isSendASAP = savedObject.SendASAP == '1' ? true : false;
              if (isSendASAP) {
                defaultData.sendOnTimeType = 'ASAP';
              } else {
                if (savedObject?.DeliverOn) {
                  defaultData.deliverOnDate = new Date(savedObject?.DeliverOn);
                  defaultData.deliverOnDate.setMinutes(
                    defaultData.deliverOnDate.getMinutes() +
                      defaultData.deliverOnDate.getTimezoneOffset()
                  );
                }
                defaultData.sendOnTimeType = 'ATSPECIFICTIME';
                defaultData.timeZoneId = savedObject?.TimezoneId?.toLowerCase();
                let dateTimeHelper = new DateTimeHelper();
                var time = dateTimeHelper.Get12HourTimeComponent(
                  savedObject.Time
                );
                if (time) {
                  defaultData.hour = parseInt(time.hours);
                  defaultData.minute = parseInt(time.minutes);
                  defaultData.meridiem = time.AmPM;
                }
              }
            }
          }
          this.createForm(defaultData);
        }
      }
    );
  }
  createForm(defaultData) {
    this.oneTimeCustomDateForm = this.formBuilder.group({
      hour: [defaultData.hour],
      minute: [defaultData.minute],
      meridiem: [defaultData.meridiem],
      timeZone: [defaultData.timeZoneId, [Validators.required]],
      deliverOnDate: [defaultData.deliverOnDate, [Validators.required]],
      sendOnTimeType: [defaultData.sendOnTimeType],
    });
    this.oneTimeCustomDateForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;
      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.fillFormData();
    }
  }

  fillFormData() {
    this.customDateData.timezoneId = this.oneTimeCustomDateForm.value.timeZone;
    let hr = Number(this.oneTimeCustomDateForm.value.hour);
    let mi = Number(this.oneTimeCustomDateForm.value.minute);
    let mer = this.oneTimeCustomDateForm.value.meridiem;

    if (this.oneTimeCustomDateForm.value.sendOnTimeType == 'ATSPECIFICTIME') {
      this.showTimeControls = true;
      this.customDateData.sendASAP = false;
      this.customDateData.deliverOn = this.datepipe.transform(
        this.oneTimeCustomDateForm.value.deliverOnDate,
        'yyyy-MM-dd'
      );
    } else {
      let defaultObject =
        this.calendarAndTimeZoneService.getDefaultDateTimeObjectByNoOfMinute(
          this.userTimeZoneId,
          this.timeZones,
          this.messageType == 2 ? 25 : 15
        );
      hr = Number(defaultObject.defaultHour);
      mi = Number(defaultObject.defaultMinute);
      mer = defaultObject.defaultMeridiem;
      // if(hr==12 && mi>=0 && mer=='AM'){
      //  defaultObject.defaultDate.setDate(defaultObject.defaultDate.getDate()+1);
      // }
      this.showTimeControls = false;
      this.customDateData.sendASAP = true;
      this.customDateData.timezoneId = defaultObject.defaultTimeZoneId;
      this.customDateData.deliverOn = this.datepipe.transform(
        defaultObject.defaultDate,
        'yyyy-MM-dd'
      );
    }

    this.customDateData.timezoneInfoId = this.timeZones.find(
      (x) => x.timezoneId.toLowerCase() === this.customDateData.timezoneId
    )?.timezoneInfoId;
    this.customDateData.timezoneOffset = this.timeZones.find(
      (x) => x.timezoneId.toLowerCase() === this.customDateData.timezoneId
    )?.currentTimeZoneOffsetInHours;

    this.customDateData.hour = hr;
    this.customDateData.minute = mi;
    this.customDateData.AMPM = mer;

    this.doEmit();
  }
  OnHourChange(event) {
    if (this.messageType == 2 && this.allowTCPAForTextMessages) {
      let selectedHour = this.oneTimeCustomDateForm.value.hour;
      this.oneTimeCustomDateForm.patchValue({
        meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
      });
    }
  }
  onSendOnTimeTypeChanged(event) {
    // if (this.messageType == 2){
    //   if(this.oneTimeCustomDateForm.value.sendOnTimeType == 'ATSPECIFICTIME'){
    //     this.ScheduleDateMessage = '';
    //   }
    // }
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.oneTimeCustomDateForm.valid;
    this.CMSComponentData.componentValueJSON = this.customDateData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
}
