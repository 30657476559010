/** 
* Implementation of the FocusIT.DataContracts.Pulse.NGENReport [DataContract].
*/
export class NGENReport {

  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public ReportId: string = '00000000-0000-0000-0000-000000000000';
  public Title: string = '';
  public ReportDescription: string = '';
  public CreatedDateUTC: Date = null;
  public LastModifiedDateUTC: Date = null;
  public SourceId: number = -1;
  public SourceDescription: string = '';
  public Owner: string = '';
  public OwnerFullName: string = '';
  public WhereClause: string = '';
  public WhereClauseDescription: string = '';
  public Filter: string = '';
  public SearchKeyword: string = '';
  public OrderBy: string = '';
  public SortColumn: string = '';
  public SortOrder: string = '';
  public Active: boolean = false;
  public Published: boolean = false;
  public AdditionalUsers: string = '';
  public AdditionalTeams: string = '';
  public AdditionalData: string = '';

  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param TenantId
  * @param ReportId
  * @param Title
  * @param ReportDescription
  * @param CreatedDateUTC
  * @param LastModifiedDateUTC
  * @param SourceId
  * @param SourceDescription
  * @param Owner
  * @param OwnerFullName
  * @param WhereClause
  * @param WhereClauseDescription
  * @param Filter
  * @param SearchKeyword
  * @param OrderBy
  * @param SortColumn
  * @param SortOrder
  * @param Active
  * @param Published
  * @param AdditionalUsers
  * @param AdditionalTeams
  * @param AdditionalData
  */
  constructor(
    TenantId?: string,
    ReportId?: string,
    Title?: string,
    ReportDescription?: string,
    CreatedDateUTC?: Date,
    LastModifiedDateUTC?: Date,
    SourceId?: number,
    SourceDescription?: string,
    Owner?: string,
    OwnerFullName?: string,
    WhereClause?: string,
    WhereClauseDescription?: string,
    Filter?: string,
    SearchKeyword?: string,
    OrderBy?: string,
    SortColumn?: string,
    SortOrder?: string,
    Active?: boolean,
    Published?: boolean,
    AdditionalUsers?: string,
    AdditionalTeams?: string,
    AdditionalData?: string) {
      if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
      if ((ReportId !== undefined) && (ReportId !== null)) this.ReportId = ReportId;
      if ((Title !== undefined) && (Title !== null)) this.Title = Title;
      if ((ReportDescription !== undefined) && (ReportDescription !== null)) this.ReportDescription = ReportDescription;
      if (CreatedDateUTC !== undefined) this.CreatedDateUTC = CreatedDateUTC;
      if (LastModifiedDateUTC !== undefined) this.LastModifiedDateUTC = LastModifiedDateUTC;
      if ((SourceId !== undefined) && (SourceId !== null)) this.SourceId = SourceId;
      if ((SourceDescription !== undefined) && (SourceDescription !== null)) this.SourceDescription = SourceDescription;
      if ((Owner !== undefined) && (Owner !== null)) this.Owner = Owner;
      if ((OwnerFullName !== undefined) && (OwnerFullName !== null)) this.OwnerFullName = OwnerFullName;
      if ((WhereClause !== undefined) && (WhereClause !== null)) this.WhereClause = WhereClause;
      if ((WhereClauseDescription !== undefined) && (WhereClauseDescription !== null)) this.WhereClauseDescription = WhereClauseDescription;
      if ((Filter !== undefined) && (Filter !== null)) this.Filter = Filter;
      if ((SearchKeyword !== undefined) && (SearchKeyword !== null)) this.SearchKeyword = SearchKeyword;
      if ((OrderBy !== undefined) && (OrderBy !== null)) this.OrderBy = OrderBy;
      if ((SortColumn !== undefined) && (SortColumn !== null)) this.SortColumn = SortColumn;
      if ((SortOrder !== undefined) && (SortOrder !== null)) this.SortOrder = SortOrder;
      if ((Active !== undefined) && (Active !== null)) this.Active = Active;
      if ((Published !== undefined) && (Published !== null)) this.Published = Published;
      if ((AdditionalUsers !== undefined) && (AdditionalUsers !== null)) this.AdditionalUsers = AdditionalUsers;
      if ((AdditionalTeams !== undefined) && (AdditionalTeams !== null)) this.AdditionalTeams = AdditionalTeams;
      if ((AdditionalData !== undefined) && (AdditionalData !== null)) this.AdditionalData = AdditionalData;
    }
}

/**
* Collection of FocusIT.DataContracts.Pulse.NGENReport [DataContract] objects.
*/
export class NGENReportCollection
{
  Items: NGENReport[] = [];
}

