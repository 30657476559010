import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation, ApplicationRef, ChangeDetectorRef, NgZone, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastService } from 'src/services/toast.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { MessageTemplate } from 'src/models/MessageTemplate';
import { RequestResponse } from 'src/models/RequestResponse';
import { MessageTemplateEditorState } from 'src/models/state/messageTemplateEditor.state';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { StripoService } from 'src/services/stripo.service';
import { ImageLibraryService } from 'src/services/imageLibrary.service';

//declare var notifications: any;
declare var stripo: any;
declare var ExternalMergeTags: any;
declare var ExternalImageLibrary: any;

//import { notifications } from 'src/scripts/notifications';
import { stripo } from 'src/scripts/stripo';
import { ExternalMergeTags } from 'src/scripts/mergeTags';
import { ExternalImageLibrary } from 'src/scripts/imageLibrary';
import { Router } from '@angular/router';
import { MergeTagsService } from 'src/services/mergeTags.service';
import { PagingData } from 'src/models/PagingData';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { CompanyObserverService } from 'src/services/companyObserver.service';
import { MessagesService } from 'src/services/messages.service';
import { MessageCampaign } from 'src/models/MessageCampaign';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

//******************************************************************************************
// Built and Tested with Stripo Version 20210730-1706_33bccf8  (Release 4.119 - August 2021)
// We are always using the latest release.
// https://stripo.email/releases/ - Monthly releases (rapid release, continuous bug fix)
//******************************************************************************************

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'stripo-root',
  templateUrl: './stripo.component.html',
  styleUrls: ['./stripo.component.scss'],
})

export class stripoComponent implements OnInit, AfterViewInit, OnDestroy {
  // true delivers the event asynchronously, false delivers synchronously.
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true);
  @Output() onCloseBtnClick: EventEmitter<any> = new EventEmitter<any>(true);

  // property for the control with #previewDialog to be accessed with previewDialog
  @ViewChild('previewDialog', { static: false }) previewDialog: Component;
  @Input() campaignNavigationData: any;
  @Input() isEditorOpen: string;

  @Output() saveStatusChange = new EventEmitter<boolean>();

  fromEmail: string = 'noreply@focusitinc.com';
  toEmail: string = '';
  emailSubject: string = '';
  preHeaderText: string = '';
  // #region Properties

  static instanceRef: any; // static version of this
  // Component state
  editorState: MessageTemplateEditorState = new MessageTemplateEditorState();
  isEmailDialogeValid = false;
  emailDialogeErrorMessage = '';
  isSendTestEmailRequesting = false;
  isEmailResponse = false;
  responseType: boolean;
  responseMessage: string;
  userToken: UserToken;
  subscription: Subscription;
  buildYourOwnCampaignID: string;
  isPublishedClicked: boolean = false;
  changeCampaignMessageID: string;
  currentClientID: string;
  public showValidateTagDialog: boolean = false;

  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   */
  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    private messageTemplatesService: MessageTemplatesService,
    private mergeTagsService: MergeTagsService,
    private userTokenService: UserTokenService,
    private stripoService: StripoService,
    private imageLibraryService: ImageLibraryService,
    private cdRef: ChangeDetectorRef,
    private zone: NgZone,
    public dialogService: DialogService,
    private router: Router,
    private companyObserverService: CompanyObserverService,
    private messagesService: MessagesService,
    @Inject(DOCUMENT) private document: any
  ) {
    stripoComponent.instanceRef = this;
    stripoComponent.instanceRef.editorState = this.editorState;
    this.buildYourOwnCampaignID = '';
    this.changeCampaignMessageID = '';
    this.isPublishedClicked = false;
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('stripofixed');
    if (stripo != null && stripo.templateId != null) {
      stripo.stop();
    }
  }

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    this.userToken = this.userTokenService.getUser();
    this.currentClientID = environment.clientId;
    this.subscription = this.companyObserverService
      .getdefaultSenderEmail()
      .subscribe((res) => {
        if (res.length > 0) {
          this.userToken.TenantDefaultSenderEmail = res;
        }
      });
    // obtain data for the page
    await this.getData();
  }

  /**
   * Occurs after view initialization
   */
  async ngAfterViewInit() {
    // await this.getLast12AndPopularMergeTagsData();
  }

  async getMergeTagsData() {
    if (
      ExternalMergeTags.allTags === undefined ||
      ExternalMergeTags.allTags.length === 0
    ) {
      const mergeTagData = await this.mergeTagsService.getMergeTagsData(
        true,
        true,
        true,
        false
      );
      ExternalMergeTags.allTags = mergeTagData.data;
    }
  }

  async getLast12AndPopularMergeTagsData() {
    let popularMergeTagData = (
      await this.mergeTagsService.getPopularMergeTagsData(
        true,
        true,
        true,
        false
      )
    ).data;
    // ExternalMergeTags.popularTags =
    //   await this.mergeTagsService.getFinalMergeTagsToDisplay(
    //     popularMergeTagData
    //   );
    this.mergeTagsService.getFinalMergeTagsToDisplay(popularMergeTagData).then(x => {
      ExternalMergeTags.changePopularTags(x);
    });
  }

  async getImageLibraryThumbnailHtml() {
    //debugger;
    if (!ExternalImageLibrary.thumbnails) {
      let thumbnails = await this.imageLibraryService.getLibraryImages(
        new RequestResponse(
          new PagingData(10000, 1, 10000, 0, 'DateUpdatedUTC DESC', false),
          null,
          null
        ),
        true
      );
      if (thumbnails.status == 200) {
        var thumbnailHtml = thumbnails.data
          .map(
            (x) =>
              '<div class="thumbnail libraryThumbnail"><img class="libraryImage" src="' +
              (x.tinyURL ?? x.imageURL) +
              '"></div>'
          )
          .join('');

        ExternalImageLibrary.thumbnails = thumbnailHtml;
        ExternalImageLibrary.updateThumbnails(thumbnailHtml);
      } else {
        this.toastService.showError(thumbnails.message);
      }
    }

    ExternalImageLibrary.onUploadFileCallback = this.onUploadImageFileCallback.bind(this);
  }

  async onUploadImageFileCallback(selectedFile: any) {
    this.editorState.loadingPage = true;
    let fileNamewithoutExt;
    let fileName = selectedFile.name;
    var lastDotPosition = fileName.lastIndexOf(".");

    if (lastDotPosition === -1)
      fileNamewithoutExt = fileName;
    else
      fileNamewithoutExt = fileName.substr(0, lastDotPosition);

    let imageModel = {
      fileImage: selectedFile,
      imageId: '00000000-0000-0000-0000-000000000000',
      imageURL: '',
      title: fileNamewithoutExt,
      width: `0`,
      height: `0`,
      avatar: 'false',
      published: 'true',
      enabled: 'true',
      deleted: 'false',
      imageSize: '0',
      imageLength: `${selectedFile.size}`,
    };

    const formData: FormData = new FormData();
    formData.append('fileImage', imageModel.fileImage);
    formData.append('imageId', imageModel.imageId);
    formData.append('imageURL', imageModel.imageURL);
    formData.append('title', imageModel.title);
    formData.append('width', imageModel.width);
    formData.append('height', imageModel.height);
    formData.append('avatar', imageModel.avatar);
    formData.append('published', imageModel.published);
    formData.append('enabled', imageModel.enabled);
    formData.append('deleted', imageModel.deleted);
    formData.append('imageSize', imageModel.imageSize);
    formData.append('imageLength', imageModel.imageLength);

    // create or save the Image
    var response = await this.imageLibraryService.SaveLibraryImage(formData);
    if (response.status != 200) {
      this.toastService.showError(response.message);
    } else {
      ExternalImageLibrary.thumbnails = undefined;
      JSON.parse(JSON.stringify(this.imageLibraryService.toCollectionInstance(response.data, this.userToken)));
      this.toastService.showSuccess("Image '" + fileNamewithoutExt + "' saved successfully.");

      //get the file tinyurl
      let libraryImageClickedCallback = {
        originalName: fileNamewithoutExt,
        url: response.data.tinyURL,
      };

      ExternalImageLibrary.imageSelectCallback(libraryImageClickedCallback);
      stripo.clearAvatar(libraryImageClickedCallback.url);
    }
    ExternalImageLibrary.close();
    this.editorState.loadingPage = false;
  }

  async loadTemplate(template: MessageTemplate) {
    if (this.editorState.loadingPage) {
      return;
    }
    let result = false;

    try {
      let timeoutDelay = 4000;

      this.editorState.loadingPage = true;

      // if we don't have a Stripo token - get initialization information.
      // if ((stripo.token === undefined) ||
      //   (stripo.token === null) ||
      //   (stripo.token === '')) {
      //   timeoutDelay += 1500;
      //   await this.getStripoInitializationData();

      // }

      stripo.tokenFunction = this.stripoService.getNewTenantPluginToken.bind(
        this.stripoService
      );

      //this.editorState.selectedCategory = this.editorState.categories.find(x => x.name === template.categoryDescription);

      // this.getImageLibraryThumbnailHtml();

      if (template === undefined || template === null || template.body === '') {
        await this.getDefaultTemplate();
      } else {
        // setting these flags to false insures that the the data to be saved (Body, TemplateHTML, and TemplateCSS)
        // is refreshed from the plugin before the actual save operation is performed.
        this.editorState.haveTemplate = false;
        this.editorState.haveEmail = false;
        this.editorState.haveCompiledEmail = false;

        this.editorState.messageTemplate = template;
      }

      // Added Pulse specific TenantId and TemplateId arguments.
      stripo.loadTemplate(
        stripo.initPlugin,
        this.editorState.messageTemplate.templateHtml,
        this.editorState.messageTemplate.templateCss,
        this.editorState.messageTemplate.tenantId,
        this.editorState.messageTemplate.templateId
      );
      this.editorState.restorationTemplate = JSON.parse(
        JSON.stringify(this.editorState.messageTemplate)
      ); // must be a copy

      // Give time for the plugin to render.
      setTimeout(() => {
        this.editorState.loadingPage = false;
      }, timeoutDelay);

      result = true;
    } catch (message: any) {
      this.editorState.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
    return result;
  }

  async loadDefaultTemplate() {
    await this.getDefaultTemplate();
  }

  // #endregion

  // #region Data Acquisition

  /**
   * Retrieves all necessary data for this component.
   */
  async getData() {
    // try {
    //   // Stripo plugin initialization
    //   await this.getStripoInitializationData();
    // } catch (message: any) {
    //   console.error(message);
    //   this.toastService.showError(message);
    // }

    // obtain data for the page
    // await Promise.all([
    this.getMergeTagsData();
    this.getLast12AndPopularMergeTagsData();
    this.getImageLibraryThumbnailHtml(); //])
    //.then(val => {
    //debugger;
    // setTimeout(() => { this.isLoading = false; }, 1000);
    //});
  }

  // #endregion

  // #region Stripo Plugin Methods

  /**
   * Retrieves compiled and compressed HTML code that is ready to be sent out to clients.
   */
  getCompiledEmail() {
    stripo.getCompiledEmail(this.getCompiledEmailCallback, true, this);
  }

  getAllDataSaved() {
    return stripo.getAllDataSaved();
  }

  /**
   * Stripo getCompiledEmail API callback that receives the compiled and compressed HTML code that is ready to be sent out to clients.
   *
   * @param error
   * @param html
   */
  getCompiledEmailCallback(error, html) {
    stripoComponent.instanceRef.editorState.compiledEmail = html;
    stripoComponent.instanceRef.editorState.haveCompiledEmail = true;
    if (
      stripoComponent.instanceRef.editorState.saving &&
      stripoComponent.instanceRef.editorState.haveTemplate &&
      stripoComponent.instanceRef.editorState.haveCompiledEmail
    ) {
      stripoComponent.instanceRef.doSave();
    } else if (
      stripoComponent.instanceRef.editorState.previewing &&
      stripoComponent.instanceRef.editorState.haveTemplate &&
      stripoComponent.instanceRef.editorState.haveCompiledEmail
    ) {
      const dlg: any = stripoComponent.instanceRef.previewDialog;
      dlg.initializeDialog(
        stripoComponent.instanceRef.replaceMergeTags(stripoComponent.instanceRef.editorState.compiledEmail),
        stripoComponent.instanceRef.editorState.templateWidth,
        stripoComponent.instanceRef.editorState.templateHeight
      );
      stripoComponent.instanceRef.editorState.loadingPage = false;
      stripoComponent.instanceRef.editorState.showPreviewDialog = true;

    }
  }

  /**
   * Retrieves the HTML, CSS internal extra styles, width and height for the email template.
   */
  getTemplate() {
    stripo.getTemplate(this.getTemplateCallback);
  }

  /**
   * Stripo getTemplate API callback that receives the HTML, CSS internal extra styles, width and height for the email template.
   *
   * @param html
   * @param css
   * @param width
   * @param height
   */
  getTemplateCallback(html, css, width, height) {
    stripoComponent.instanceRef.editorState.templateHtml = html;
    stripoComponent.instanceRef.editorState.templateCss = css;
    stripoComponent.instanceRef.editorState.templateWidth = width;
    stripoComponent.instanceRef.editorState.templateHeight = height;
    stripoComponent.instanceRef.editorState.haveTemplate = true;
    if (
      stripoComponent.instanceRef.editorState.saving &&
      stripoComponent.instanceRef.editorState.haveTemplate &&
      stripoComponent.instanceRef.editorState.haveCompiledEmail
    ) {
      stripoComponent.instanceRef.doSave();
    } else if (
      stripoComponent.instanceRef.editorState.previewing &&
      stripoComponent.instanceRef.editorState.haveTemplate &&
      stripoComponent.instanceRef.editorState.haveCompiledEmail
    ) {
      stripoComponent.instanceRef.doPreview();
    }
  }

  /**
   * Retrieves the HTML, CSS code, width and height for the compiled email.
   */
  getEmail() {
    stripo.getEmail(this.getEmailCallback);
  }

  /**
   * Stripo getEmail API callback that receives the HTML, CSS code, width and height for the compiled email.
   *
   * @param html
   * @param css
   * @param width
   * @param height
   */
  getEmailCallback(html, css, width, height) {
    stripoComponent.instanceRef.editorState.haveEmail = true;
    stripoComponent.instanceRef.editorState.emailHtml = html;
    stripoComponent.instanceRef.editorState.emailCss = css;
    stripoComponent.instanceRef.editorState.emailWidth = width;
    stripoComponent.instanceRef.editorState.emailHeight = height;
  }

  /**
   * Returns the Stripo plugin version.
   */
  getVersion() {
    let result = stripo.getVersion();
    console.log(result);
    return result;
  }

  /**
   * Returns the Stripo email title/subject line.
   */
  getTitle() {
    let result = stripo.getTitle();
    console.log(result);
    return result;
  }

  /**
   * Sets the Stripo email title/subject line.
   *
   * @param {any} title
   */
  setTitle(title: any) {
    stripo.setTitle(title);
  }

  /**
   * Returns the Stripo email’s hidden preheader.
   */
  getHiddenPreHeader() {
    console.log(stripo.getHiddenPreHeader());
  }

  /**
   * Sets the Stripo email’s hidden preheader.
   *
   * @param {any} hiddenPreHeader
   */
  setHiddenPreHeader(hiddenPreHeader: any) {
    stripo.setHiddenPreHeader(hiddenPreHeader);
  }

  /**
   * Returns the Stripo email's Gmail Promo Annotations’ microdata inserted into the HTML code of the email template in the editor.
   *
   * @returns null or object specifying:
   *   logo: 'https://logo',
   *   description: 'description',
   *   discountCode: 'discountCode',
   *   availabilityStarts: '2019-06-26T04:04:00+00:00',
   *   availabilityEnds: '2019-06-30T04:04:00+00:00',
   *   image: 'https://image'
   */
  getGoogleAnnotations() {
    let result = stripo.getGoogleAnnotations();
    console.log(result);
    return result;
  }

  /**
   * Inserts/updates the Stripo email's Gmail Promo Annotations’ microdata in HTML code of the opened email template in the editor.
   *
   * @param {any} googleAnnotations null or object specifying:
   *   logo: 'https://logo',
   *   description: 'description',
   *   discountCode: 'discountCode',
   *   availabilityStarts: '2019-06-26T04:04:00+00:00',
   *   availabilityEnds: '2019-06-30T04:04:00+00:00',
   *   image: 'https://image'
   */
  setGoogleAnnotations(googleAnnotations: any) {
    stripo.setGoogleAnnotations(googleAnnotations);
  }

  // #endregion

  // #region Event Handlers

  /**
   * Validates input fields in real-time.
   *
   * @param input the control that triggered the event
   */
  realTimeValidate(input: any) {
    this.editorState.templateDesignFormState.validate(input);
    this.editorState.errors = this.editorState.templateDesignFormState.errors;
    if (this.editorState.errors.length > 0) this.editorState.showErrors = true;
  }

  /**
   * 'Save' button click event handler.
   */
  //async onSave(campaignID: string = '') {
  async onSave() {
    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message:
          'Are you sure you want to save the ' +
          this.editorState.messageTemplate.title +
          ' template?',
        successButtonText: 'Yes, I want to save',
        cancelButtonText: 'Cancel',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        this.editorState.haveTemplate = false;
        this.editorState.haveCompiledEmail = false;
        this.editorState.haveEmail = false;
        this.editorState.loadingPage = true;
        this.editorState.saving = true;
        this.editorState.showErrors = false;
        this.getTemplate();
        this.getCompiledEmail();
      }
    });
  }

  /**
   * Saves the template.
   */
  async doSave() {
    try {
      this.editorState.messageTemplate.tenantId = this.userToken.TenantId;
      // type and category
      //this.editorState.messageTemplate.categoryId = this.editorState.selectedCategory.code;
      //this.editorState.messageTemplate.categoryDescription = this.editorState.selectedCategory.name;
      this.editorState.messageTemplate.modifiedBy = this.userToken.TenantUserId;
      this.editorState.messageTemplate.body = this.editorState.compiledEmail;
      this.editorState.messageTemplate.templateHtml = this.editorState.templateHtml;
      this.editorState.messageTemplate.templateCss = this.editorState.templateCss;
      this.editorState.messageTemplate.width = this.editorState.templateWidth;
      this.editorState.previewWidth = this.editorState.templateWidth + 30;
      this.editorState.messageTemplate.height = this.editorState.templateHeight;
      this.editorState.messageTemplate.bodyHistory = '';
      // create or save the template
      // Owner set only for initial save - it never should change after that.
      if (this.editorState.messageTemplate.templateId == null || this.editorState.messageTemplate.templateId == '00000000-0000-0000-0000-000000000000') {
        this.editorState.messageTemplate.templateId = '00000000-0000-0000-0000-000000000000';
        this.editorState.messageTemplate.owner = this.userToken.TenantUserId;
        this.editorState.messageTemplate.ownerFullName = this.userToken.getFullName();
        this.editorState.messageTemplate.createdBy = this.userToken.TenantUserId;
      }

      var response = await this.messageTemplatesService.SaveTemplate(this.editorState.messageTemplate);

      // sync data after created/saved
      if (response.data == null) {
        if (this.currentClientID === "AduvoAdmin" && this.isPublishedClicked) {
          this.editorState.messageTemplate.published = !this.editorState.messageTemplate.published;
        }
        if (response.status == 404) {
          this.showValidateTagDialog = true;
        } else {
          this.showValidateTagDialog = false;
          console.error(response.message);
          this.isPublishedClicked = false;
          this.saveStatusChange.emit(false);
          //this.ref.tick();
          this.cdRef.detectChanges();
          this.toastService.showError(response.message);
        }
        this.editorState.loadingPage = false;
      } else {
        // create template thumbnail in background
        var responseThumbnail =  await this.messageTemplatesService.CreateTemplateThumbnailURL(response.data.templateId, response.data.isPublishingDb);

        this.editorState.messageTemplate = response.data;
        this.editorState.restorationTemplate = JSON.parse(
          JSON.stringify(this.editorState.messageTemplate)
        );
        //this.router.navigate(['/marketing/template-library']);

        this.saveStatusChange.emit(true);

        this.editorState.refreshRequired = true;
        if (this.isSendTestEmailRequesting) {
          // Set specific controller request data.

          this.sendtestEmail(this.editorState.messageTemplate);
        } else if (
          this.campaignNavigationData &&
          this.campaignNavigationData.id
        ) {
          //----------------if build  your own
          this.editorState.loadingPage = true;
          this.cdRef.detectChanges();
          this.toastService.showSuccess(`Template ${this.editorState.messageTemplate.title} saved successfully.`);

          if (this.buildYourOwnCampaignID && this.currentClientID !== "AduvoAdmin") {
            this.CreatNewCampaignMesage();
          }

          if (this.currentClientID === "AduvoAdmin") {
            if (this.isPublishedClicked && this.buildYourOwnCampaignID) {//publishSave
              this.CreatNewCampaignMesage();
              this.isPublishedClicked = false;
            } else if (this.editorState.messageTemplate.published) {
              this.BuildYourOwnTemplateFlow();
            } else if (!this.isPublishedClicked && !this.editorState.messageTemplate.published
              && this.buildYourOwnCampaignID) {
              this.BuildYourOwnTemplateFlow();//normal save
            }
          }
          this.editorState.loadingPage = false;

        } else {
          this.editorState.loadingPage = false;
          //this.ref.tick();
          this.cdRef.detectChanges();
          this.toastService.showSuccess(
            "Template '" +
            this.editorState.messageTemplate.title +
            "' saved successfully."
          );
        }
        let returnUrl = this.messageTemplatesService.returnUrl;
        if (returnUrl) {
          this.messageTemplatesService.resetReturnUrl();
          window.location.href = returnUrl;
        }
      }
    } catch (message: any) {
      this.editorState.loadingPage = false;
      //this.ref.tick();
      this.cdRef.detectChanges();
      //console.error(message);
      this.toastService.showError(message);
    } finally {
      this.editorState.saving = false;
      this.isSendTestEmailRequesting = false;
      this.isPublishedClicked = false;
    }
  }

  private BuildYourOwnTemplateFlow() {
    if (this.buildYourOwnCampaignID) {


      this.dialogService.dialogComponentRefMap.forEach(dialog => {
        dialog.destroy();
      });
      //show confirm to activate
      var confirmRef = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: `Please confirm you want to activate template ${this.editorState.messageTemplate.title}`,
          successButtonText: 'Yes, I want to activate',
          cancelButtonText: 'Cancel',
        },
      });

      confirmRef.onClose.subscribe(async (result: boolean) => {
        if (result) {
          //if publish yes then publish and crete new CampaignMessage
          this.messageTemplatesService.setPublished(
            this.editorState.messageTemplate.templateId, true
          ).subscribe(response => {
            if (response.status == 200) {
              this.toastService.showSuccess('Template Activated.');
              this.CreatNewCampaignMesage();
            } else {
              this.toastService.showError('Error Activating! ' + response.message
              );
            }
          });
        }
      });

    }
    else {
      //----------------
      //this.ref.tick();
      this.cdRef.detectChanges();
      this.router.navigate([
        '/campaigns/edit/' + this.campaignNavigationData.id + '/build',
      ]);
    }
  }

  private CreatNewCampaignMesage() {
    if (this.changeCampaignMessageID) {
      let messageModel = {
        campaignId: this.buildYourOwnCampaignID,
        campaignMessageId: this.changeCampaignMessageID,
        templateId: this.editorState.messageTemplate.templateId,
        scheduleJson: '',
        sentJson: '',
        dateStartUtc: null,
        dateEndUtc: null,
        preHeaderText: this.editorState.messageTemplate.preHeaderText,
        isPublishingDb: false,
        subject: this.editorState.messageTemplate.emailSubject + '.'
      }
      var response = this.messagesService.UpdateCampaignMessage(
        messageModel
      ).then(() => {
        this.RedirectToCampaign();
      })
      // this.router.navigate([
      //   'marketing/campaigns/edit/' + this.buildYourOwnCampaignID + '/default/' + this.editorState.messageTemplate.templateId,
      // ]);
    }
    else {//Add
      let messageModel = new MessageCampaign();
      messageModel.campaignId = this.buildYourOwnCampaignID;
      messageModel.templateId = this.editorState.messageTemplate.templateId;
      messageModel.subject = this.editorState.messageTemplate.emailSubject;
      messageModel.preHeaderText = this.editorState.messageTemplate.preHeaderText;
      messageModel.isPublishingDb = false;

      //save tCampaignMessage
      this.messagesService.SelectCampaignMessageRxjs(messageModel).subscribe((retResponse) => {
        this.RedirectToCampaign();
        // this.router.navigate([
        //   'marketing/campaigns/edit/' + this.buildYourOwnCampaignID + '/default/' + messageModel.templateId,
        // ]);
      });
    }
  }
  private RedirectToCampaign() {
    this.router.navigate([
      'marketing/campaigns/edit/' + this.buildYourOwnCampaignID + '/default/' + this.changeCampaignMessageID,
    ]);
  }

  /**
   * 'Preview' button click event handler.
   */
  onPreviewBtnClick() {
    this.editorState.haveTemplate = false;
    this.editorState.haveCompiledEmail = false;
    this.editorState.loadingPage = true;
    this.editorState.previewing = true;

    this.getTemplate();
    this.getCompiledEmail();

    this.editorState.showPreviewDialog = false;
  }

  /**
   * 'Restore' button click event handler.
   */
  async onRestoreBtnClick() {
    this.editorState.showErrors = false;
    setTimeout(() => {
      this.loadTemplate(this.editorState.restorationTemplate);
    }, 10);
  }

  /**
   * Displays the template in the preview dialog.
   */
  doPreview() {
    this.editorState.loadingPage = true;
    const dlg: any = this.previewDialog;

    if (this.editorState.haveCompiledEmail) {
      dlg.initializeDialog(
        this.replaceMergeTags(this.editorState.compiledEmail),
        this.editorState.templateWidth,
        this.editorState.templateHeight
      );
      this.editorState.loadingPage = false;
      this.editorState.showPreviewDialog = true;
    } else {
      this.getCompiledEmail();
    }
  }

  /**
   * 'Cancel' of 'X' clicked on the preview dialog.
   */
  onClosePreviewDialog() {
    this.editorState.showPreviewDialog = false;
    this.editorState.previewing = false;
    this.onCloseBtnClick.emit(event);
  }

  /**
   * Loads the shared template for testing/demonstaration purposes.
   * (temporary - will be replaced/modified in the future)
   */
  async getDefaultTemplate() {
    // setting these flags to false insures that the the data to be saved (Body, TemplateHTML, and TemplateCSS)
    // is refreshed from the plugin before the actual save operation is performed.
    this.editorState.haveTemplate = false;
    this.editorState.haveEmail = false;
    this.editorState.haveCompiledEmail = false;

    var response = await this.messageTemplatesService.getDefaultTemplate();
    if (response.status == 200) {
      response.data.templateId = '00000000-0000-0000-0000-000000000000';

      this.editorState.messageTemplate = response.data;
      this.editorState.restorationTemplate = JSON.parse(
        JSON.stringify(this.editorState.messageTemplate)
      ); // must be a copy
    } else {
      if (response.message)
        console.log(response.message);
      this.toastService.showError("Unable to retrieve default template");
    }
  }

  async openSendEmailDialoge() {
    this.emailSubject = this.editorState.messageTemplate.emailSubject;
    this.preHeaderText = this.editorState.messageTemplate.preHeaderText;
    this.isEmailResponse = false;
    this.fromEmail = this.userToken.TenantDefaultSenderEmail;
    this.toEmail = '';
    this.validateEmailDialog();
  }

  async openEmailDialogueFromDesignLib(emailSubject, preHeaderText, isPublishingDb) {
    this.editorState.messageTemplate.emailSubject = emailSubject;
    this.editorState.messageTemplate.preHeaderText = preHeaderText;
    this.editorState.messageTemplate.isPublishingDb = isPublishingDb;

    this.emailSubject = this.editorState.messageTemplate.emailSubject;
    this.preHeaderText = this.editorState.messageTemplate.preHeaderText;
    this.isEmailResponse = false;
    this.fromEmail = this.userToken.TenantDefaultSenderEmail;
    this.toEmail = '';
    this.validateEmailDialog();
  }

  async onSendEmail() {
    if (
      !this.isEmailDialogeValid ||
      this.fromEmail == '' ||
      this.toEmail == '' ||
      this.emailSubject == ''
    ) {
      return;
    }

    if (this.isEditorOpen === "true") {
      this.editorState.haveTemplate = false;
      this.editorState.haveCompiledEmail = false;
      this.editorState.haveEmail = false;
      this.editorState.loadingPage = true;
      this.editorState.saving = true;
      this.editorState.showErrors = false;
      this.isSendTestEmailRequesting = true;
      this.getTemplate();
      this.getCompiledEmail();
    } else {
      try {
        this.editorState.loadingPage = true;
        this.isSendTestEmailRequesting = true;
        this.sendtestEmail(this.editorState.messageTemplate);
      } finally {
        this.isSendTestEmailRequesting = false;
      }
    }
  }

  /**
   * 'Cancel' of 'X' clicked on the preview dialog.
   */
  onCloseSendTestEmailDialog() {
    this.editorState.showSendTestEmailDialog = false;
    this.editorState.previewing = false;
    this.isEmailResponse = false;
    this.responseMessage = '';
    this.responseType = false;
    this.toEmail = '';
    this.onCloseBtnClick.emit(event);
  }

  validateMultipleEmails(emailIds: string) {
    let isValid = false;
    let isAllEmailvalid = true;
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailIds && emailIds.length > 0) {
      let emails = emailIds
        .split(';')
        .map((e) => e.split(','))
        .reduce((acc, cur) => cur.concat(acc), [])
        .filter((e) => !!e);
      if (emails && emails.length > 0) {
        for (var x = 0; x < emails.length; x++) {
          isValid = singleEmailRegularExpression.test(emails[x]);
          if (!isValid) {
            isAllEmailvalid = false;
          }
        }
      } else {
        isAllEmailvalid = false;
      }
    } else {
      isAllEmailvalid = false;
    }
    return isAllEmailvalid;
  }

  validateEmailDialog() {
    let isValid = false;
    let errorMessage: string = '';
    let toEmails;
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var multipleEmailRegularExpression =
      /^(\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}[,;])*\w([-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{2,4}$/;
    this.isEmailDialogeValid = true;
    this.emailDialogeErrorMessage = '';

    isValid = singleEmailRegularExpression.test(
      String(this.fromEmail).toLowerCase()
    );
    if (!isValid) {
      errorMessage += '*From email address is not valid; ';
      this.isEmailDialogeValid = false;
    }

    isValid = this.validateMultipleEmails(
      this.toEmail.replace(/\s/g, '').toLowerCase()
    );
    if (!isValid && this.toEmail != '') {
      errorMessage += '*To email address is not valid; ';
      this.isEmailDialogeValid = false;
    } else if (this.toEmail.trim() == '') {
      this.isEmailDialogeValid = false;
    }

    if (this.emailSubject.trim() == '') {
      errorMessage += '*Subject is not valid; ';
      this.isEmailDialogeValid = false;
    }
    this.emailDialogeErrorMessage = errorMessage;
  }

  sendtestEmail(messageTemplate: MessageTemplate) {
    // Send email template
    try {
      const request = {
        templateId: messageTemplate.templateId,
        fromEmail: this.fromEmail,
        toEmails: this.toEmail,
        subject: this.emailSubject,
        preHeaderText: this.preHeaderText,
        isPublishingDb: messageTemplate.isPublishingDb, //set later
      };
      this.messageTemplatesService.SendTestEmail(request).subscribe(
        (response) => {
          this.editorState.loadingPage = false;
          // convert the 'data' property of the RequestResponse object's 'data' property JSON string value to an object.
          if (response) {
            if (response && response.data) {
              this.responseType = response.data;
              this.responseMessage = 'Email was sent successfully!';
            } else {
              this.responseType = false;
              this.responseMessage = 'Failed to send email.';
            }
            this.isEmailResponse = true;
            this.cdRef.detectChanges();
          }
        },
        (err) => {
          this.editorState.loadingPage = false;
          this.responseType = false;
          this.responseMessage = 'Failed to send email.';
          this.isEmailResponse = true;
          this.cdRef.detectChanges();
        }
      );
    } catch (errorMessage) {
      this.editorState.loadingPage = false;
      this.responseType = false;
      this.responseMessage = 'Failed to send email.';
      this.isEmailResponse = true;
      this.cdRef.detectChanges();
    }
  }

  onSendAnother() {
    this.zone.run(() => {
      this.isEmailResponse = false;
      this.responseType = undefined;
      this.responseMessage = '';
      this.toEmail = '';
      this.fromEmail = this.userToken.TenantDefaultSenderEmail;
      this.emailSubject = this.editorState.messageTemplate.emailSubject;
      this.preHeaderText = this.editorState.messageTemplate.preHeaderText;
      //this.ref.tick();
      this.validateEmailDialog();
    });
  }

  replaceMergeTags(body: string) {
    var tags = ExternalMergeTags.allTags;
    //var body="swegfre*{abc:def}**{abc:def1}*";
    var matches = body.match(/\*\{[a-z0-9]*:[a-z0-9]*\}\*/mgi)
    matches.forEach(match => {
      var tag = tags.find(x => x.tag === match);
      if (tag) {
        body = body.replace(match, this.GetFormattedMergeTag(tag));
      }
    });

    var tag = tags.find(x => x.tag === "*{Unsubscribe}*");
      if (tag) {
        body = body.replace("*{Unsubscribe}*", this.GetFormattedMergeTag(tag));
      }
    
    return body;
  }

  GetFormattedMergeTag(tag: any): string {
    if (!tag.previewValue)
      return "";

    if (tag.valueType == "Image" && tag.tag.includes('Picture') && (tag.tag.includes("*{Loan:") || tag.tag.includes("*{Contact:"))) {
      return "<img style='border-radius: 50%;' src='" + tag.previewValue + "'></img>";
    }
    else if (tag.valueType == "Image") {
      return "<img src='" + tag.previewValue + "'></img>";
    } else if (tag.valueType == "Email") {
      return "<a href='mailto:" + tag.previewValue + "'>" + tag.previewValue + "</a>";
    }
    else if (tag.valueType == "URL") {
      return "<a href='" + tag.previewValue + "'>" + tag.previewValue + "</a>";
    }
    return tag.previewValue;
  }

  onValidateTag() {
    this.showValidateTagDialog = false;
    this.router.navigate(['/marketing/content-library']);
  }

  // #endregion
}
