import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';

@Component({
  selector: 'app-opt-in',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss']
})

export class OptInComponent implements OnInit {
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";

  constructor(private router: Router) {
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
  }

  ngOnInit(): void {
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {  
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/carrier-form'] or ['/permit-collect']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToOptInEmail(): void {
    let navigateTo = '/select-plan';
    let navigateFrom = '/opt-in';

    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }

    this.navigationStack.push(navigateFrom);
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    this.router.navigate([navigateTo]);
  }
}
