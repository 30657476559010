<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
            style="width:66.66%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="carrier-form position-relative container-middle">
    <div class="container-size">
      <div class="m-auto d-flex justify-content-center flex-column text-center mt-4">
        <h5 class="title-onboarding">
          Great! The major wireless carriers require us to collect a little
          more info before we can turn on texting.
        </h5>
      </div>
      <div class="m-auto">
        <div class="mt-5">
          <form [formGroup]="companyForm2" class="form-container">
            <div class="row mb-4 ">
              <div class="col-6">
                <label for="ein" class="form-label fw-bold">EIN<span class="text-primary font-weight-bold">
                    *</span></label>
                <input type="text" name="ein" id="ein" maxlength="32" placeholder="EIN" class="form-control"
                  formControlName="ein" [ngClass]="{
                          'is-invalid':
                            companyForm2.get('ein').invalid &&
                            (companyForm2.submitted),
                          'is-valid':
                            companyForm2.get('ein').valid &&
                            (companyForm2.submitted)
                        }" />
              </div>
              <div class="p-field p-col-6 dropsection pt-0">
                <label for="businessType" class="form-label w-100 fw-bold">Business Type<span
                    class="text-primary font-weight-bold"> *</span></label>
                <p-dropdown [options]="businessTypeList" class="w-100" formControlName="businessType" 
                            optionLabel="label" optionValue="value"
                            class="groundDropdown" [autoZIndex]="false" placeholder="Select a business type">
                </p-dropdown>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label for="legalBusinessName" class="form-label fw-bold">Business Name <span
                    class="text-primary font-weight-bold"> *</span></label>
                <input type="text" name="legalBusinessName" id="legalBusinessName" maxlength="64"
                  placeholder="Business Name" class="form-control" formControlName="legalBusinessName" [ngClass]="{
                          'is-invalid':
                            companyForm2.get('legalBusinessName').invalid &&
                            (companyForm2.submitted),
                          'is-valid':
                            companyForm2.get('legalBusinessName').valid &&
                            (companyForm2.submitted)
                        }" />
              </div>
              <div class="col-6">
                <label for="legalBusinessStreet" class="form-label fw-bold">Address <span
                    class="text-primary font-weight-bold"> *</span></label>
                <input type="text" name="legalBusinessStreet" id="legalBusinessStreet" maxlength="128"
                  placeholder="Address" class="form-control" formControlName="legalBusinessStreet" [ngClass]="{
                          'is-invalid':
                            companyForm2.get('legalBusinessStreet').invalid &&
                            (companyForm2.submitted),
                          'is-valid':
                            companyForm2.get('legalBusinessStreet').valid &&
                            (companyForm2.submitted)
                        }" />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-6">
                <label for="legalBusinessCity" class="form-label fw-bold">City<span
                    class="text-primary font-weight-bold"> *</span>
                </label>
                <input type="text" name="legalBusinessCity" id="legalBusinessCity" maxlength="64" placeholder="City"
                  class="form-control" formControlName="legalBusinessCity" [ngClass]="{
                          'is-invalid':
                            companyForm2.get('legalBusinessCity').invalid &&
                            (companyForm2.submitted),
                          'is-valid':
                            companyForm2.get('legalBusinessCity').valid &&
                            (companyForm2.submitted)
                        }" />
              </div>
              <div class="col-6">
                <label for="legalBusinessCountry" class="form-label fw-bold">Country<span
                    class="text-primary font-weight-bold"> *</span></label>
                <input type="text" readonly="true" value="United States" name="legalBusinessCountry"
                  id="legalBusinessCountry" maxlength="64" placeholder="Country" class="form-control"
                  formControlName="legalBusinessCountry" [ngClass]="{
                          'is-invalid':
                            companyForm2.get('legalBusinessCountry').invalid &&
                            (companyForm2.submitted),
                          'is-valid':
                            companyForm2.get('legalBusinessCountry').valid &&
                            (companyForm2.submitted)
                        }" />
              </div>
            </div>
            <div class="row mb-4 ">
              <div class="p-field p-col-6 pt-0 dropsection">
                <label for="legalBusinessState" class="form-label fw-bold">State<span
                    class="text-primary font-weight-bold"> *</span></label>
                <p-dropdown [options]="usStates" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true"
                  placeholder="Select a state" formControlName="legalBusinessState" optionValue="abbreviation"
                  [autoZIndex]="false" class="w-100 groundDropdown">
                  <ng-template let-state pTemplate="item">
                    <div class="state-item">
                      <div>{{ state.name }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="col-6">
                <label for="legalBusinessZip" class="form-label fw-bold">Zip<span class="text-primary font-weight-bold">
                    *</span></label>
                <input type="text" name="legalBusinessZip" id="legalBusinessZip" maxlength="10" placeholder="Zip"
                  class="form-control" formControlName="legalBusinessZip" [ngClass]="{
                      'is-invalid':
                        companyForm2.get('legalBusinessZip').invalid &&
                        (companyForm2.submitted),
                      'is-valid':
                        companyForm2.get('legalBusinessZip').valid &&
                        (companyForm2.submitted)
                    }" />
                <span class="text-danger" *ngIf="
                      companyForm2.get('legalBusinessZip').invalid &&
                      (companyForm2.get('legalBusinessZip').dirty ||
                        companyForm2.get('legalBusinessZip').touched ||
                        companyForm2.submitted) &&
                      companyForm2.get('legalBusinessZip').errors?.pattern
                    ">Business zip code is invalid.</span>
              </div>
            </div>
          </form>
          <div class="mt-3">
            <span class="title-form">
              AUTHORIZED CONTACT INFO
              <hr class="hr-title">
            </span><br>
            <form [formGroup]="companyForm2" class="form-container">
              <div class="row mb-4 ">
                <div class="col-6">
                  <label for="authorizedContactFirstName" class="form-label fw-bold">First Name<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input type="text" name="authorizedContactFirstName" id="authorizedContactFirstName" maxlength="32"
                    placeholder="First Name" class="form-control" formControlName="authorizedContactFirstName"
                    [ngClass]="{
                              'is-invalid':
                                companyForm2.get('authorizedContactFirstName').invalid &&
                                (companyForm2.submitted),
                              'is-valid':
                                companyForm2.get('authorizedContactFirstName').valid &&
                                (companyForm2.submitted)
                            }" />
                </div>
                <div class="col-6">
                  <label for="authorizedContactLastName" class="form-label fw-bold">Last Name<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input type="text" name="authorizedContactLastName" id="authorizedContactLastName" maxlength="32"
                    placeholder="Last Name" class="form-control" formControlName="authorizedContactLastName" [ngClass]="{
                            'is-invalid':
                              companyForm2.get('authorizedContactLastName').invalid &&
                              (companyForm2.submitted),
                            'is-valid':
                              companyForm2.get('authorizedContactLastName').valid &&
                              (companyForm2.submitted)
                          }" />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-6">
                  <label for="authorizedContactBusinessTitle" class="form-label fw-bold">Business Title<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input type="text" name="authorizedContactBusinessTitle" id="authorizedContactBusinessTitle"
                    maxlength="32" placeholder="Business Title" class="form-control"
                    formControlName="authorizedContactBusinessTitle" [ngClass]="{
                                  'is-invalid':
                                    companyForm2.get('authorizedContactBusinessTitle').invalid &&
                                    (companyForm2.submitted),
                                  'is-valid':
                                    companyForm2.get('authorizedContactBusinessTitle').valid &&
                                    (companyForm2.submitted)
                                }" />
                </div>
                <div class="col-6">
                  <label for="authorizedContactEmail" class="form-label fw-bold">Email<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input type="text" name="authorizedContactEmail" id="authorizedContactEmail" placeholder="Email"
                    maxlength="128" class="form-control" (input)="validateAuthorizedContactEmail()"
                    formControlName="authorizedContactEmail" [ngClass]="{
                          'is-invalid':
                            companyForm2.get('authorizedContactEmail').invalid &&
                            (companyForm2.submitted),
                          'is-valid':
                            companyForm2.get('authorizedContactEmail').valid &&
                            (companyForm2.submitted)
                        }" />
                  <span class="text-danger" *ngIf="!isValidAuthorizedContactEmail">Authorized contact email address
                    isinvalid</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-6">
                  <label for="authorizedContactJobPosition" class="form-label fw-bold">Job Position<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input type="text" name="authorizedContactJobPosition" id="authorizedContactJobPosition"
                    maxlength="32" placeholder="Job Position" class="form-control"
                    formControlName="authorizedContactJobPosition" [ngClass]="{
                              'is-invalid':
                                companyForm2.get('authorizedContactJobPosition').invalid &&
                                (companyForm2.submitted),
                              'is-valid':
                                companyForm2.get('authorizedContactJobPosition').valid &&
                                (companyForm2.submitted)
                            }" />
                </div>
                <div class="col-6">
                  <label for="authorizedContactCountry" class="form-label fw-bold">Country<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input readonly="true" value="United States" type="text" name="authorizedContactCountry"
                    id="authorizedContactCountry" maxlength="64" placeholder="Country" class="form-control"
                    formControlName="authorizedContactCountry" [ngClass]="{
                            'is-invalid':
                              companyForm2.get('authorizedContactCountry').invalid &&
                              (companyForm2.submitted),
                            'is-valid':
                              companyForm2.get('authorizedContactCountry').valid &&
                              (companyForm2.submitted)
                          }" />
                </div>
              </div>
              <div class="row mb-4 ">
                <div class="col-6">
                  <label for="authorizedContactPhone" class="col-form-label fw-bold">Phone<span
                      class="text-primary font-weight-bold"> *</span></label>
                  <input type="text" name="authorizedContactPhone" id="authorizedContactPhone" placeholder="Phone"
                    maxlength="25" class="form-control" formControlName="authorizedContactPhone" (input)="authorizedContactPhoneFormat($event)" [ngClass]="{
                        'is-invalid':
                          companyForm2.get('authorizedContactPhone').invalid &&
                          (companyForm2.submitted),
                        'is-valid':
                          companyForm2.get('authorizedContactPhone').valid &&
                          (companyForm2.submitted)
                      }" />
                  <span class="text-danger" *ngIf="
                        companyForm2.get('authorizedContactPhone').invalid &&
                        (companyForm2.get('authorizedContactPhone').dirty ||
                          companyForm2.get('authorizedContactPhone').touched ||
                          companyForm2.submitted) &&
                        companyForm2.get('authorizedContactPhone').errors?.pattern
                      ">Authorized contact phone is invalid.</span>
                  <span class="text-danger" *ngIf="
                        companyForm2.get('authorizedContactPhone').invalid &&
                        (companyForm2.get('authorizedContactPhone').dirty ||
                          companyForm2.get('authorizedContactPhone').touched ||
                          companyForm2.submitted) &&
                        companyForm2.get('authorizedContactPhone').errors?.maxlength
                      ">Max length of authorized contact phone is 25 digits.</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded"
          (click)="redirectToBack()">
          <i class="fa fa-arrow-left"></i>&nbsp;Back
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end" (click)="redirectToPermitCollect()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
          [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>