<div *ngIf="items" class="row loan-status-type-new filter_selectcontainer_advance" [formGroup]="textFormGroup">
     <div class="col-12" style="padding:15px 30px;" formArrayName="items"
          *ngFor="let item of formDataCtrl.controls; let i = index">
          <div class="row p-mt-2 flex-column" [formGroupName]="i">
               <!-- <div class="col-auto">
                    <label
                         style="padding-top:12px;font-size: 16px; line-height: 16px; color: #2e2e2e;font-weight: 400;">{{
                         item.get('label').value }}</label>
               </div> -->
               <div class="col-12 mb-3">
                    <!-- <p-dropdown [options]="rhsDropdownItem" [formControlName]="'txtLhsValue'" placeholder="Select"
                         optionLabel="Name" optionValue="Code" [style]="{'width':'98.5%'}" filterBy="Name"
                         [filter]="true" filterBy="Name"
                         [inputStyleClass]="{ 'is-invalid': item.get('txtLhsValue')?.errors}">
                    </p-dropdown> -->
                    <p-multiSelect  #txtLhsValue1  id="txtLhsValue{{ i }}" [options]="rhsDropdownItem" formControlName="txtLhsValue"
                         [showToggleAll]="true" optionLabel="Name" optionValue="Code" filterBy="Name" [filter]="true"
                         defaultLabel="Select" [inputStyleClass]="{ 'is-invalid': item.get('txtLhsValue')?.errors}"
                         (onChange)="onListChange($event, index)" [style]="{'width':'98.5%'}"
                         selectedItemsLabel="{0} items selected" [virtualScroll]="true">
                         <p-footer>
                              <div class="flex justify-content-between py-3 px-4">
                                   <p-button label="Done" [style]="{'width':'100%'}" (click)="close();"
                                        styleClass="p-button-rounded"></p-button>
                              </div>
                         </p-footer>
                    </p-multiSelect>
                    <div style="color:#FC5151; font-size:11px;font-weight:normal; margin-left: 75px;"
                         *ngIf="item.get('txtLhsValue')?.touched && item.get('operandDropDown').value?.LhsInputControl && item.get('txtLhsValue')?.errors">
                         Required.
                    </div>
               </div>
               <div class="col-12 d-flex justify-content-around mb-2 ">
                    <i class="pi pi-reply replytosection col-1 me-2 ms-2 mt-4"></i>
                    <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" class="col-11"
                         optionLabel="OpDisplay" (onChange)="operandChanged($event,i)" [style]="{'width':'96%'}"
                         formControlName="operandDropDown" [filter]="true" filterBy="OpDisplay">
                    </p-dropdown>
               </div>
              

                    <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1">
                         <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1 == 'Calendar'">
                              <div class="d-flex col-12 ">
                                   <i class="pi pi-reply replytosubsection col-1 me-2"></i>
                                   <p-calendar *ngIf="item.get('operandDropDown').value?.RhsInputControl1 == 'Calendar'"
                                        dateFormat="mm/dd/yy" id="txtRHSValue1{{ i }}"
                                        [style]="{'border-radius': '25px'}"
                                        [formControlName]="'txtRHSValue1'" [readonlyInput]="true" [showIcon]="false"
                                        [inputId]="'icon'" class="p-calendar-new-class ms-5 ">
                                   </p-calendar>
                              </div>
                              <div style="color:#FC5151; font-size:11px; font-weight:normal; margin-left: 95px; bottom: 70px;"
                                   *ngIf="item.get('txtRHSValue1')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue1')?.errors">
                                   Required.
                              </div>
                         </ng-container>


                         <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl1 == 'Textbox'">
                              <container-element
                                   [ngSwitch]="item.get('operandDropDown').value?.OpDisplay?.toLowerCase()">
                                   <!-- <p-calendar *ngSwitchCase="'month is'" view="month" dateFormat="M"
                                        id="txtRHSValue1{{ i }}" [formControlName]="'txtRHSValue1'"
                                        [readonlyInput]="true" [showIcon]="false" [inputId]="monthpicker">
                                   </p-calendar> -->
                                   <div class="d-flex" *ngSwitchCase="'month is'">
                                        <i class="pi pi-reply replytosubsection me-4 col-2"></i>
                                        <p-dropdown placeholder="Select Month" [options]="months" optionLabel="name"
                                             optionValue="name" id="txtRHSValue1{{ i }}"
                                             [style]="{'border-radius': '25px','width':'100%'}"
                                             [formControlName]="'txtRHSValue1'" filterBy="name" [filter]="false"
                                             class="col-9 "
                                             [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}">
                                        </p-dropdown>
                                   </div>
                                   <div class="d-flex" *ngSwitchCase="'year is'">
                                        <i class="pi pi-reply replytosubsection me-3 col-2"></i>
                                        <p-dropdown placeholder="Select Year" [options]="YearList" optionLabel="item_id"
                                             optionValue="item_id" id="txtRHSValue1{{ i }}"
                                             [style]="{'border-radius': '25px','width':'90%'}"
                                             [formControlName]="'txtRHSValue1'" filterBy="item_id" [filter]="true"
                                             class="col-9"
                                             [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}">
                                        </p-dropdown>
                                   </div>
                                   <div class="d-flex " *ngSwitchDefault>
                                        <i class="pi pi-reply replytosubsection me-3 col-2"></i>
                                        <p-inputNumber mode="decimal" [inputId]="withoutgrouping" class="rounded"
                                             [useGrouping]="false" [showButtons]="false" [maxlength]="10"
                                             id="txtRHSValue2{{ i }}" [formControlName]="'txtRHSValue1'"
                                             [style]="{'width':'265px'}"
                                             [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}"
                                             [min]="1">
                                        </p-inputNumber>
                                   </div>
                                   <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;"
                                        *ngIf="item.get('txtRHSValue2')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue1')?.errors">
                                        Required.
                                   </div>
                              </container-element>
                         </ng-container>
                    </ng-container>
             
               <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
                    <ng-container *ngIf="item.get('operandDropDown').value?.RhsInputControl2 =='Calendar'">
                         <div class=" d-flex col-12 mt-2">
                              <i class="pi pi-reply replytosubsection col-1 me-2"></i>
                              <p-calendar dateFormat="mm/dd/yy" id="txtRHSValue2{{ i }}" class="txtRHSValue2 ms-5"
                                   [style]="{'border-radius': '25px'}" [formControlName]="'txtRHSValue2'"
                                   [readonlyInput]="true" [showIcon]="false" [inputId]="'icon'">
                              </p-calendar>
                         </div>
                         <div style="color:#FC5151; font-size:11px;margin-left: 95px;bottom: 65px;position: absolute;
                              font-weight:normal;"
                              *ngIf="item.get('operandDropDown').value?.RhsInputControl2 && item.get('txtRHSValue2')?.errors">
                              Required.
                         </div>
                    </ng-container>
               </ng-container>


               <!-- <div class="col-1">
                    <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button"
                         (click)="deleteRow(i)">
                         <i class="pi pi-trash"></i>
                    </span>
               </div> -->
          </div>
     </div>
     <!-- <div class="row" style="padding:15px 30px;">
          <div class="col-12 btn-no-radius">
               <p-button (click)="addMore()" icon="pi pi-plus" label=""></p-button>
          </div>
     </div> -->
     <!-- <hr class="m-0"> -->
     <div class="row" style="padding:15px 30px;">
          <div class="col-12 text-center">
               <!-- <a (click)="cancelFilter()" class="link-text"
                    style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;font-weight: normal; line-height:34px;text-decoration: underline;"
                    styleClass="p-button-secondary">Cancel</a> -->
               <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" label="Apply"></p-button>
          </div>
     </div>
</div>
<!-- [style]="{'margin-left': '17.5px'}" -->
<!-- [style]="{'margin-left': '17.5px','color':'#2e2e2e','cursor':'pointer','font-weight':'normal','line-height':'34px','text-decoration':'underline','background':'transparent','border':'none'}" -->