import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core'; 
import { HttpClient } from '@angular/common/http'; 
import { ConfirmationService, MessageService } from 'primeng/api'; 
import { RequestResponse } from 'src/models/RequestResponse'; 
import { PagingData } from 'src/models/PagingData'; 
//import { someService } from 'src/services/some.service'; 
 
@Component({ 
    selector: 'AuthorizationFailed-root', 
    templateUrl: './AuthorizationFailed.component.html', 
    styleUrls: ['./AuthorizationFailed.component.scss'] 
}) 
export class AuthorizationFailedComponent implements OnInit, AfterViewInit { 
  //title = 'AuthorizationFailed'; 
 
  // allows [message] = "..." to be added to the selector
  @Input() message: string;
 
  // true delivers the event asynchronously, false delivers synchronously. 
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true); 
 
  // property for the control with #dataTableId to be accessed with propertyName dataTable
  // Uncomment the following line if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
  // @ViewChild('dataTableId', { static: false }) dataTable: Table;

  // used for showing/hiding spinner, progress bar or skeleton.
  loadingPage: boolean = false; 
  // Standard Request/Response object. Includes Paging support if necessary 
  webAPI: RequestResponse = new RequestResponse();
  // controller action
  //services: someService = new someService(this.http);
  
  /** 
   * Constructor 
   *  
   * @param http HTTPClient required to call actions in any .ts file in the services folder. 
   * @param confirmationService ConfirmationService required for <p-confirmDialog> 
   * @param messageService MessageService required for <p-toast> 
   */ 
  constructor(private http: HttpClient, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService) { 
 
  } 
 
  //*************************************************************************************** 
  // Component Initialization 
  //*************************************************************************************** 
  /** Occurs upon component initialization */ 
  async ngOnInit(): Promise<void> { 
 
    // obtain data for the page 
    this.getData(this.webAPI.paging.currentPage);
  } 
 
  /** Occurs after view initialization */ 
  ngAfterViewInit(): void { 
  } 
 
  //*************************************************************************************** 
  // Data Acquisition 
  //*************************************************************************************** 
  /** 
   * Retrieves all necessary data for this component. 
   * 
   * @param: currentPage - paging support. 
   */ 
  getData(currentPage) { 
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true; 
 
    //// code to acquire data via a Web API call.
    //// returned object is a RequestResponse object.
    //this.webAPI.data = '{}'; //  Required - Clear data from last request or set your specific controller request data.
    //this.services.getData(this.webAPI).subscribe(response => {
    //  // assign returned object to class variable.
    //  this.webAPI = response; // Required - do not remove.
    //  // convert the 'data' property of the RequestResponse object's 'data' property JSON string value to an object.
    //  RequestResponse.dataToObject(this.webAPI); // Required - do not remove.

    //  // when data is retrieved, set this.loadingPage to false. 
    //  this.loadingPage = false;
    //});
 
    // REMOVE this when acquiring data via a Web API call. 
    this.loadingPage = false; 
  } 
 
  //*************************************************************************************** 
  // Paging Support
  //*************************************************************************************** 
  /**
   * Selected page changed in the paginator
   * @param event
   */
  onPageChanged(event) {
    // Uncomment the following line if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //PagingData.onPageChanged(this.webAPI.paging, event, this.dataTable);
  }

  /**
   *  Goes to the user entered page.
   */
  onGotoPage() {
    // Uncomment the following line if you are using a p-table control identified by #dataTableId to render data that includes a paginator.
    //PagingData.onGotoPage(this.webAPI.paging, this.dataTable);
  }

  //*************************************************************************************** 
  // Methods and Event Handlers 
  //*************************************************************************************** 
 
} 
 
