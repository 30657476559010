import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface ConfirmModalData {
  message: string;
  secondLineMessage: string;
  innerHtmlMessage: string;
  successButtonText: string;
  cancelButtonText: string;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})

export class ConfirmModalComponent implements OnInit {

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) {
  }

  data: ConfirmModalData;

  ngOnInit(): void {
    console.log(this.config.data);
    this.data = this.config.data;
  }
}
