import { formState } from 'src/helpers/formState.helper';
import { MessageTemplate } from '../MessageTemplate';

/**
* Message Template Editor state management object
*
* Built and Tested with Stripo Version 20210730-1706_33bccf8
*/
export class MessageTemplateEditorState {

  /** The MessageTempale that will be persisted into database. */
  public messageTemplate: MessageTemplate = new MessageTemplate();
  /** The MessageTempale that will be used to 'Restore' the template while editting. */
  public restorationTemplate: MessageTemplate = new MessageTemplate();

  /** Indicates that the list of templates must be refreshed when the 'Back' button is clicked. */
  public refreshRequired: boolean = false;

  /** Editor form state. */
  public templateDesignFormState: TemplateDesignFormState = new TemplateDesignFormState();
  /** Data validation errors array. */
  public errors: string[] = [];
  /** Used for showing/hiding Error dialog. */
  public showErrors: boolean = false;

  /** Used for showing/hiding spinner, progress bar or skeleton. */
  public loadingPage: boolean = false;
  /** Indicates that the user has clicked the 'Save' button.
   * The save will be performed once the Stipo callbacks for obtaining
   * the template and compiled email have completed. */
  public saving: boolean = false;

  /** Indicates that the user has selected the 'Preview' ellipse menu option.
   * The preview will be performed once the Stipo callbacks for obtaining
   * the template and compiled email have completed. */
  public previewing: boolean = false;
  /** Used for showing/hiding the Preview dialog. */
  public showPreviewDialog: boolean = false;
  /** Calculated width of the template in displayed in the Preview dialog. */
  public previewWidth: number = 0;

  public showSendTestEmailDialog: boolean = false;

  /** Template Types. */
  public templateTypes: any[] = [
    { name: 'Email', code: 1 },
    { name: 'Text', code: 2 },
  ];
  /** The selected template Type. */
  /** Template Categories. */
  // public categories: any[] = [
  //     { name: 'Holidays', code: 2 },
  //     { name: 'Lead', code: 1 },
  //     { name: 'Loan Anniversaries', code: 5 },
  //     { name: 'Newsletters', code: 4 },
  //     { name: 'Post Close', code: 3 },
  //     { name: 'Uncategorized', code: 0 },
  //   ];
  /** The selected template Category. */
  public selectedCategory: any;

  /**********************************************
   * Properties retrieved from the Stripo plugin.
   **********************************************/

  /** Indicates whether or not the compiled template content from Stripo is available. */
  public haveCompiledEmail: boolean = false;
  /** Indicates whether or not the template's HTML and CSS content from Stripo is available. */
  public haveTemplate: boolean = false;
  /** Indicates whether or not the email content from Stripo is available . */
  public haveEmail: boolean = false;

  /** The compiled template content from Stripo. */
  public compiledEmail: string = '';
  /** The template HTML content from Stripo. */
  public templateHtml: string = '';
  /** The template CSS content from Stripo. */
  public templateCss: string = '';
  /** The width of the template from Stripo. */
  public templateWidth: number = 0;
  /** The height of the template from Stripo. */
  public templateHeight: number = 0;

  /** The email HTML content from Stripo. */
  public emailHtml: string = '';
  /** The email CSS content from Stripo. */
  public emailCss: string = '';
  /** The width of the email from Stripo. */
  public emailWidth: number = 0;
  /** The height of the email from Stripo. */
  public emailHeight: number = 0;
  // Show Test Sms link
  //public ShowTestSMS: boolean = false;
}


/**
* Stipo Plugin Editor form state (used for field validation).
*/
export class TemplateDesignFormState extends formState {
  // blocks calls to validate controls until we are sure controls are initialized.
  canValidate: boolean = false;

  /**
   *  Validate the Template Design form
   *
   *  @param input the control that triggered the event
   *  @returns the control style class for decorating the control
   */
  public validate(input: any) {
    let style: string = TemplateDesignFormState.inputStyleValid;

    if (!this.canValidate) {
      // delay flipping flag, so the calling thread can finish,
      // otherwise a non-fatal exception is thrown
      setTimeout(() => {
        this.canValidate = true;
      }, 10);
      return style;
    }

    // Textboxes
    switch (input.id) {
      case "txtTemplateName":
        if (!super.validateTemplateName(input.value)) {
          return TemplateDesignFormState.inputStyleInvalid;
        }
        break;
      case "txtTemplateEmailSubject":
        if (!super.validateTemplateEmailSubject(input.value)) {
          return TemplateDesignFormState.inputStyleInvalid;
        }
        break;
      case "txtTemplateEmailPreviewText":
        if (!super.validateTemplateEmailPreviewText(input.value)) {
          return TemplateDesignFormState.inputStyleInvalid;
        }
        break;
    }

    return style;
  }
}
