/**
* Specifies whether a System.DateTime object represents a local time, a Coordinated
* Universal Time (UTC), or is not specified as either local time or UTC.
*/
export enum DateTimeKind {

  /**
  * The time represented is not specified as either local time or Coordinated Universal
  * Time (UTC).
  */
  Unspecified = 0,

  /**
  * The time represented is UTC.
  */
  Utc = 1,

  /**
  * The time represented is local time.
  */
  Local = 2,
}


/**
* Enumeration of template type flags.
*/
export enum TemplateType {
  /**
  * Email template type.
  */
  Email = 0,

  /**
  * Text template type.
  */
  Text = 2,
}

/**
* Enumeration of contact type flags.
*/
export enum ContactType {
  /**
  * No contact type.
  */
  None = 0,

  /**
  * Borrower contact type.
  * LendingPad Loan Contact
  */
  Borrower = 1,

  /**
  * CoBorrower contact type.
  * LendingPad Loan Contact
  */
  CoBorrower = 2,

  /**
  * Lender contact type.
  */
  Lender = 4,

  /**
  * Partner contact type.
  */
  Partner = 8,

  /**
  * Builder contact type.
  */
  Builder = 16,

  /**
  * Buyers Attorney contact type.
  */
  BuyersAttorney = 32,

  /**
  * Buyers Agent contact type.
  * LendingPad Loan Contact
  */
  BuyersAgent = 64,

  /**
  * Closing Agent contact type.
  * LendingPad Loan Contact
  */
  ClosingAgent = 128,

  /**
  * Flood Insurance contact type.
  */
  FloodInsurance = 256,

  /**
  * Hazard Insurance contact type.
  * LendingPad Loan Contact
  */
  HazardInsurance = 512,

  /**
  * Investor contact type.
  */
  Investor = 1024,

  /**
  * Mortgage Broker contact type.
  */
  MortgageBroker = 2048,

  /**
  * Mortgage Insurance contact type.
  */
  MortgageInsurance = 4096,

  /**
  * Real Estate Broker Buyer contact type.
  */
  RealEstateBrokerBuyer = 8192,

  /**
  * Title Insurance contact type.
  * Possible LendingPad Loan Contact (TitleCompany?)
  */
  TitleInsurance = 16384,

  /**
  * Real Estate Broker Seller contact type.
  */
  RealEstateBrokerSeller = 32768,

  /**
  * Appraiser contact type.
  * LendingPad Loan Contact
  */
  Appraiser = 65536,

  /**
  * Referral contact type.
  */
  Referral = 131072,

  /**
  * Sellers Attorney contact type.
  */
  SellersAttorney = 262144,

  /**
  * Property Tax contact type.
  */
  PropertyTax = 524288,

  /**
  * Surveyor contact type.
  */
  Surveyor = 1048576,

  /**
  * Custom Agent #1 contact type.
  */
  CustomAgent1 = 2097152,

  /**
  * Custom Agent #2 contact type.
  */
  CustomAgent2 = 4194304,

  /**
  * Executive contact type.
  */
  Executive = 8388608,

  /**
  * Branch Manager contact type.
  */
  BranchManager = 16777216,

  /**
  * Loan Officer contact type.
  * LendingPad Loan Contact
  */
  LoanOfficer = 33554432,

  /**
  * Loan Officer Assistant contact type.
  * LendingPad Loan Contact
  */
  LoanOfficerAssistant = 67108864,

  /**
  * Processor contact type.
  * LendingPad Loan Contact
  */
  Processor = 134217728,

  /**
  * Underwriter contact type.
  */
  Underwriter = 268435456,

  /**
  * Document Preparer contact type.
  */
  DocumentPreparer = 536870912,

  /**
  * Shipper contact type.
  */
  Shipper = 1073741824,

  /**
  * Junior Processor contact type.
  */
  JuniorProcessor = 2147483648,

  /**
  * Sellers Agent contact type.
  * LendingPad Loan Contact
  */
  SellersAgent = 4294967296,

  /**
  * Prospect contact type.
  */
  Prospect = 8589934592,

  /**
  * Lead contact type. 
  */
  Lead = 17179869184,

  /**
  * User who can log into Pulse contact type.
  */
  PulseUser = 34359738368,

  /**
  * Realtor contact type.
  */
  Realtor = 68719476736,

  /**
  * Reserved for future use.
  */
  //Unused = 137438953472,

  /**
  * Reserved for future use.
  */
  //Unused = 274877906944,

  /**
  * Reserved for future use.
  */
  //Unused = 549755813888,

  /**
  * Reserved for future use.
  */
  //Unused = 1099511627776,

  /**
  * Reserved for future use.
  */
  //Unused = 2199023255552,

  /**
  * Reserved for future use.
  */
  //Unused = 4398046511104,

  /**
  * Reserved for future use.
  */
  //Unused = 8796093022208,

  /**
  * Reserved for future use.
  */
  //Unused = 17592186044416,

  /**
  * Reserved for future use.
  */
  //Unused = 35184372088832,

  /**
  * Reserved for future use.
  */
  //Unused = 70368744177664,

  /**
  * Reserved for future use.
  */
  //Unused = 140737488355328,

  /**
  * Reserved for future use.
  */
  //Unused = 281474976710656,

  /**
  * Reserved for future use.
  */
  //Unused = 562949953421312,

  /**
  * Reserved for future use.
  */
  //Unused = 1125899906842624,

  /**
  * Reserved for future use.
  */
  //Unused = 2251799813685248,

  /**
  * Reserved for future use.
  */
  //Unused = 4503599627370496,

  /**
  * Reserved for future use.
  */
  //Unused = 9007199254740992,

  /**
  * Reserved for future use.
  */
  //Unused = 18014398509481984,

  /**
  * Reserved for future use.
  */
  //Unused = 36028797018963968,

  /**
  * Reserved for future use.
  */
  //Unused = 72057594037927936,

  /**
  * Reserved for future use.
  */
  //Unused = 144115188075855872,

  /**
  * Reserved for future use.
  */
  //Unused = 288230376151711744,

  /**
  * Reserved for future use.
  */
  //Unused = 576460752303423488,

  /**
  * Reserved for future use.
  */
  //Unused = 1152921504606846976,

  /**
  * Reserved for future use.
  */
  //Unused = 2305843009213693952,

  /**
  * Reserved for future use.
  */
  //Unused = 4611686018427387904,

  /**
  * Reserved for future use.
  */
  //Unused = -9223372036854775808,
}

/**
* Enumeration of loan status ids.
*/
export enum LoanStatusId {
  /**
  * None
  */
  None = 0,

  /**
  * Lead
  */
  Lead = 1,

  /**
  * PreQualify
  */
  PreQualify = 2,

  /**
  * PreApproval
  */
  PreApproval = 3,

  /**
  * Application Taken
  */
  ApplicationTaken = 4,

  /**
  * Closed
  */
  Closed = 12,

  /**
  * Funded
  */
  Funded = 13,

  /**
  * Post Closing
  */
  PostClosing = 14,

  /**
  * In Shipping
  */
  InShipping = 15,

  /**
  * Servicing
  */
  Servicing = 16,

  /**
  * Purchased
  */
  Purchased = 17,

  /**
  * Denied
  */
  Denied = 18,

  /**
  * Withdrawn
  */
  Withdrawn = 19,

  /**
  * Not Accepted
  */
  NotAccepted = 20,

  /**
  * Incomplete
  */
  Incomplete = 21,

  /**
  * Initial Submission
  */
  InitialSubmission = 22,

  /**
  * Condition Submission
  */
  ConditionSubmission = 23,

  /**
  * Approved
  */
  Approved = 24,

  /**
  * Suspended
  */
  Suspended = 25,

  /**
  * Clear To Close
  */
  ClearToClose = 26,

  /**
  * Prospect
  */
  Prospect = 27,

  /**
  * PreDeny
  */
  PreDeny = 28,

  /**
  * Rescinded
  */
  Rescinded = 29,

  /**
  * Broker Initial Submission
  */
  BrokerInitialSubmission = 30,

  /**
  * Broker Condition Submission
  */
  BrokerConditionSubmission = 31,

  /**
  * Registered
  */
  Registered = 32,

  /**
  * Processing
  */
  Processing = 33,

  /***
  * Canceled (Reserved for future use)
  */
  //Canceled = 34
}

/**
* Enumeration of loan purpose ids
*/
export enum LoanPurposeId {
  /**
  * None
  */
  None = 0,

  /**
  * Purchase
  */
  Purchase = 1,

  /**
  * Cash-Out Refinance
  */
  CashOutRefinance = 2,

  /**
  * No Cash-Out Refinance
  */
  NoCashOutRefinance = 3,

  /**
  * Construction
  */
  Construction = 4,

  /**
  * Construction Permanent
  */
  ConstructionPermanent = 5,

  /**
  * Other
  */
  Other = 6,
}

/**
* Enumeration of permission group flags.
*/
export enum PermissionGroup {

  // None = 0,
  // SystemAdmin = 1,
  // CompanyAdmin = 2,
  // MarketingAdmin = 4,
  // User = 8,
  // Marketing = 16
  
  FocusITSystemAdmin = 0,
  FocusITUser = 1,
  CompanyAdmin = 2,
  CompanyUser = 3,
  CompanyMarketingAdmin = 4,
  CompanyUserWithMarketing = 5

  /**
  * Team Administrator
  */
  //TeamAdmin = 8,

  /**
  * Single User Administrator.
  */
  //SingleUserAdmin = 16,

  /**
  * Loan Officer with Marketing.
  */
  //LoanOfficerWithMarketing = 32,

  /**
  * Loan Officer without Marketing.
  */
  //LoanOfficerWithoutMarketing = 64,

  /**
  * Processor with Marketing.
  */
  //ProcessorWithMarketing = 128,

  /**
  * Processor without Marketing.
  */
  //ProcessorWithoutMarketing = 256,


  /**
  * Loan Officer Assistant.
  */
  //LoanOfficerAssistant = 1024,
}

/**
* Enumeration used to indicate the criteria is either an 'AND' or an 'OR' condition.
*/
export enum AndOr {
  /**
  * AND
  */
  AND = 0,

  /**
  * OR
  */
  OR
}

/**
* Enumeration used to indicate the grouping of evaluation/SQL where clause criteria.
*/
export enum Group {
  /**
  * None - No parentheses are injected.
  */
  NONE = 0,

  /**
  * BEGIN - indicates the starting point (first condition) of grouped criteria.
  * Injects a left parenthesis '(' into the WHERE clause between the AND/OR and the criteria.
  */
  BEGIN,

  /**
  * END - indicates the ending point (last condition) of grouped criteria.
  * Injects a right parenthesis ')' into the WHERE clause after the criteria.
  */
  END,

  /**
  * BOTH - indicates the criteria is both a beginning and ending group.
  * Injects a left parenthesis '(' into the WHERE clause between the AND/OR and the criteria.
  * Injects a right parenthesis ')' into the WHERE clause after the criteria.
  */
  BOTH
}

/**
* Enumeration used for numeric filter specifications.
*/
export enum NumericFilters {
  /**
  * Equals
  */
  Equals = 0,

  /**
  * Not Equal
  */
  NotEqual,

  /**
  * Less Than
  */
  LessThan,

  /**
  * Greater Than
  */
  GreaterThan,

  /**
  * Less Than or Equals
  */
  LessThanOrEqual,

  /**
  * Greater Than or Equals
  */
  GreaterThanOrEqual,

  /**
  * Between
  */
  Between
}

/**
* Enumeration used to indicate how an SQL where clause criteria is to be evaluated.
*/
export enum SqlComparison {
  /**
  * Equals
  */
  EQ = 0,

  /**
  * Not Equal
  */
  NOTEQ,

  /**
  * Less Than
  */
  LT,

  /**
  * Greater Than
  */
  GT,

  /**
  * Less Than or Equals
  */
  LTEQ,

  /**
  * Greater Than or Equals
  */
  GTEQ,

  /**
  * Is Null
  */
  ISNULL,

  /**
  * Is Not Null
  */
  ISNOTNULL,

  /**
  * In
  */
  IN,

  /**
  * Not In
  */
  NOTIN,

  /**
  * Like
  */
  LIKE,

  /**
  * Not Like
  */
  NOTLIKE,

  /**
  * Exists
  * SqlParameter.Value contains a syntactically SELECT statement.
  * Parentheses are automatically added around the SELECT statement.
  */
  EXISTS,

  /**
  * Not Exists
  * SqlParameter.Value contains a syntactically SELECT statement.
  * Parentheses are automatically added around the SELECT statement.
  */
  NOTEXISTS,

  /**
  * Bitwise All.
  * The result of a bitwise AND operation is EQUAL to the criteria's value.
  * Column value may have additional bits set to 1.
  */
  BITWISEALL,

  /**
  * Bitwise Any.
  * The result of a bitwise AND operation is NOT EQUAL to the criteria's value.
  * Column value may have additional bits set to 1.
  */
  BITWISEANY,

  /**
  * Bitwise Not All (same as BITWISENOTANY).
  * The result of a bitwise AND operation is EQUAL to 0.
  * Column value may have additional bits set to 1.
  */
  BITWISENOTALL,

  /**
  * Bitwise Not Any (same as BITWISENOTALL).
  * The result of a bitwise AND operation is EQUAL to 0.
  * Column value may have additional bits set to 1.
  */
  BITWISENOTANY = BITWISENOTALL,
}

/**
* Specifies SQL Server-specific data type of a field, property, for use in a System.Data.SqlClient.SqlParameter.
*/
export enum DbType {

  /**
  * System.Int64. A 64-bit signed integer.
  */
  BigInt = 0,

  /**
  * System.Array of type System.Byte. A fixed-length stream of binary data ranging
  * between 1 and 8,000 bytes.
  */
  Binary = 1,

  /**
  * System.Boolean. An unsigned numeric value that can be 0, 1, or null.
  */
  Bit = 2,

  /**
  * System.String. A fixed-length stream of non-Unicode characters ranging between
  * 1 and 8,000 characters.
  */
  Char = 3,

  /**
  * System.DateTime. Date and time data ranging in value from January 1, 1753 to
  * December 31, 9999 to an accuracy of 3.33 milliseconds.
  */
  DateTime = 4,

  /**
  * System.Decimal. A fixed precision and scale numeric value between -10 38 -1 and
  * 10 38 -1.
  */
  Decimal = 5,

  /**
  * System.Double. A floating point number within the range of -1.79E +308 through
  * 1.79E +308.
  */
  Float = 6,

  /**
  * System.Array of type System.Byte. A variable-length stream of binary data ranging
  * from 0 to 2 31 -1 (or 2,147,483,647) bytes.
  */
  Image = 7,

  /**
  * System.Int32. A 32-bit signed integer.
  */
  Int = 8,

  /**
  * System.Decimal. A currency value ranging from -2 63 (or -9,223,372,036,854,775,808)
  * to 2 63 -1 (or +9,223,372,036,854,775,807) with an accuracy to a ten-thousandth
  * of a currency unit.
  */
  Money = 9,

  /**
  * System.String. A fixed-length stream of Unicode characters ranging between 1
  * and 4,000 characters.
  */
  NChar = 10,

  /**
  * System.String. A variable-length stream of Unicode data with a maximum length
  * of 2 30 - 1 (or 1,073,741,823) characters.
  */
  NText = 11,

  /**
  * System.String. A variable-length stream of Unicode characters ranging between
  * 1 and 4,000 characters. Implicit conversion fails if the string is greater than
  * 4,000 characters. Explicitly set the object when working with strings longer
  * than 4,000 characters. Use System.Data.SqlDbType.NVarChar when the database column
  * is nvarchar(max).
  */
  NVarChar = 12,

  /**
  * System.Single. A floating point number within the range of -3.40E +38 through
  * 3.40E +38.
  */
  Real = 13,

  /**
  * System.Guid. A globally unique identifier (or GUID).
  */
  UniqueIdentifier = 14,

  /**
  * System.DateTime. Date and time data ranging in value from January 1, 1900 to
  * June 6, 2079 to an accuracy of one minute.
  */
  SmallDateTime = 15,

  /**
  * System.Int16. A 16-bit signed integer.
  */
  SmallInt = 16,

  /**
  * System.Decimal. A currency value ranging from -214,748.3648 to +214,748.3647
  * with an accuracy to a ten-thousandth of a currency unit.
  */
  SmallMoney = 17,

  /**
  * System.String. A variable-length stream of non-Unicode data with a maximum length
  * of 2 31 -1 (or 2,147,483,647) characters.
  */
  Text = 18,

  /**
  * System.Array of type System.Byte. Automatically generated binary numbers, which
  * are guaranteed to be unique within a database. timestamp is used typically as
  * a mechanism for version-stamping table rows. The storage size is 8 bytes.
  */
  Timestamp = 19,

  /**
  * System.Byte. An 8-bit unsigned integer.
  */
  TinyInt = 20,

  /**
  * System.Array of type System.Byte. A variable-length stream of binary data ranging
  * between 1 and 8,000 bytes. Implicit conversion fails if the byte array is greater
  * than 8,000 bytes. Explicitly set the object when working with byte arrays larger
  * than 8,000 bytes.
  */
  VarBinary = 21,

  /**
  * System.String. A variable-length stream of non-Unicode characters ranging between
  * 1 and 8,000 characters. Use System.Data.SqlDbType.VarChar when the database column
  * is varchar(max).
  */
  VarChar = 22,

  /**
  * System.Object. A special data type that can contain numeric, string, binary,
  * or date data as well as the SQL Server values Empty and Null, which is assumed
  * if no other type is declared.
  */
  Variant = 23,

  /**
  * An XML value. Obtain the XML as a string using the System.Data.SqlClient.SqlDataReader.GetValue(System.Int32)
  * method or System.Data.SqlTypes.SqlXml.Value property, or as an System.Xml.XmlReader
  * by calling the System.Data.SqlTypes.SqlXml.CreateReader method.
  */
  Xml = 25,

  /**
  * A SQL Server user-defined type (UDT).
  */
  Udt = 29,

  /**
  * A special data type for specifying structured data contained in table-valued
  * parameters.
  */
  Structured = 30,

  /**
  * Date data ranging in value from January 1,1 AD through December 31, 9999 AD.
  */
  Date = 31,

  /**
  * Time data based on a 24-hour clock. Time value range is 00:00:00 through 23:59:59.9999999
  * with an accuracy of 100 nanoseconds. Corresponds to a SQL Server time value.
  */
  Time = 32,

  /**
  * Date and time data. Date value range is from January 1,1 AD through December
  * 31, 9999 AD. Time value range is 00:00:00 through 23:59:59.9999999 with an accuracy
  * of 100 nanoseconds.
  */
  DateTime2 = 33,

  /**
  * Date and time data with time zone awareness. Date value range is from January
  * 1,1 AD through December 31, 9999 AD. Time value range is 00:00:00 through 23:59:59.9999999
  * with an accuracy of 100 nanoseconds. Time zone value range is -14:00 through
  * +14:00.
  */
  DateTimeOffset = 34
}

/**
* Enumeration used to indicate how a data value condition is to be evaluated.
*/
export enum DataComparison {
  /**
  * Equals
  */
  Equals = 0,
  /**
  * Equals
  */
  EQ = Equals,

  /**
  * Not Equal
  */
  NotEqual,
  /**
  * Not Equal
  */
  NOTEQ = NotEqual,

  /**
  * Less Than
  */
  LessThan,
  /**
  * Less Than
  */
  LT = LessThan,

  /**
  * Greater Than
  */
  GreaterThan,
  /**
  * Greater Than
  */
  GT = GreaterThan,

  /**
  * Less Than or Equals
  */
  LessThanOrEqual,
  /**
  * Less Than or Equals
  */
  LTEQ= LessThanOrEqual,

  /**
  * Greater Than or Equals
  */
  GreaterThanOrEqual,
  /**
  * Greater Than or Equals
  */
  GTEQ = GreaterThanOrEqual,

  /**
  * Begins With (does not apply to numeric or date-time data)
  */
  BeginsWith,

  /**
  * Ends With (does not apply to numeric or date-time data)
  */
  EndsWith,

  /**
  * Contains (does not apply to numeric or date-time data)
  */
  Contains,

  /**
  * Delta (applies only to numeric data)
  * The computed delta for Single, Double and Decimal is rounded to the nearest integer.
  * The delta is computed as a long integer.
  */
  Delta,

  /**
  * Today (applies only to date-time data)
  */
  Today,

  /**
  * Months are Equal (applies only to date-time data)
  */
  Month,

  /**
  * Days are Equal (applies only to date-time data)
  */
  Day,

  /**
  * Years are Equal (applies only to date-time data)
  */
  Year,

  /**
  * Month and Day are Equal (applies only to date-time data)
  */
  MonthAndDay,

  /**
  * Month and Year are Equal (applies only to date-time data)
  */
  MonthAndYear,

  /**
  * Day of Year are Equal (applies only to date-time data)
  */
  DayOfYear,

  /**
  * Date Difference in Days (applies only to date-time data)
  */
  DateDifference,

  /**
  * Does Not Contain (does not apply to numeric or date-time data)
  */
  NotContains,

  /**
  * Between (applies only to numeric and date-time data)
  */
  Between,

  /**
  * In (applies only to collections)
  */
  In,
  /**
  * IN (applies only to collections)
  */
  IN = In,

  /**
  * Not In (applies only to collections)
  */
  NotIn,
  /**
  * NOT IN (applies only to collections)
  */
  NOTIN = NotIn,

  /**
  * This Week (applies only to date-time data)
  */
  ThisWeek,

  /**
  * This Month (applies only to date-time data)
  */
  ThisMonth,

  /**
  * Next Week (applies only to date-time data)
  */
  NextWeek,

  /**
  * Next Month (applies only to date-time data)
  */
  NextMonth,

  /**
  * Within the Next Number of Days (applies only to date-time data)
  */
  NextNumberOfDays,

  /**
  * Within the Previous Number of Days (applies only to date-time data)
  */
  PreviousNumberOfDays,

  /**
  * Within Plus and Minus the Number of Days (applies only to date-time data)
  */
  WithinNumberOfDays,

  /**
  * Any Change or Difference
  */
  AnyChange = 100,
}

/**
 * Enumeration used to indicate how a Rule compares strings.
 * (aligns with .NET StringComparison for backend processing)
 */
export enum StringComparison {
  /**
   * Compare strings using culture-sensitive sort rules and the current culture.
   */
  CurrentCulture = 0,

  /**
   * Compare strings using culture-sensitive sort rules, the current culture, and
   * ignoring the case of the strings being compared.
   */
  CurrentCultureIgnoreCase = 1,

  /**
   * Compare strings using culture-sensitive sort rules and the invariant culture.
   */
  InvariantCulture = 2,

  /**
   * Compare strings using culture-sensitive sort rules, the invariant culture, and
   * ignoring the case of the strings being compared.
   */
  InvariantCultureIgnoreCase = 3,

  /**
   * Compare strings using ordinal (binary) sort rules.
   */
  Ordinal = 4,

  /**
   * Compare strings using ordinal (binary) sort rules and ignoring the case of the
   * strings being compared.
   */
  OrdinalIgnoreCase = 5
}

/**
* Enumeration of subscription type flags.
*/
export enum SubscriptionType {
  /**
  * limited access type.
  */
   LimitedAccess = 1,

  /**
  * premium access type.
  */
   PremiumAccess = 2,
}

/**
* Enumeration of billing owner flags.
*/
export enum BillingOwner {
  /**
  * individual owner.
  */
   Individual = 1,

  /**
  *  team owner.
  */
   Team = 2,

  /**
  * Company owner.  
  */
   Company = 3,     
}