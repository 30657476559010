<div *ngIf="showEmailEditor" class="email-editor-main">
  <div class="content table-search-row second-header-fixed">
    <div class="text-wrapper">
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header title-main-26">
          <label
            *ngIf="isFirstStepVisible && editorState?.messageTemplate?.title">{{editorState?.messageTemplate?.title}}</label>
          <label *ngIf="isFirstStepVisible && !editorState?.messageTemplate?.title">Create New Email</label>
          <label *ngIf="isSecondStepVisible">{{editorState?.messageTemplate?.title}}</label>
        </div>
        <div class="d-flex justify-content-between align-items-center fs-5">
          <span role="button" class="font-size-16" (click)="gotoStep1()"
            [ngClass]="[isFirstStepVisible ? 'text-dark fw-bold' : '']">
            Details
          </span>
          <i class="pi pi-angle-right mx-2"></i>
          <div class="d-flex justify-content-between align-items-center"
            [ngClass]="[isSecondStepVisible ? 'text-dark fw-bold' : '']">
            <div role="button" (click)="onSubmitStepFirst()" class="mx-2 font-size-16">
              Content
            </div>
            <div>
              <button *ngIf="isSecondStepVisible" type="button" class="ms-5" pButton=pButton label="Save"
                (click)="onSave()"></button>
              <button *ngIf="isSecondStepVisible" type="button" class="p-button-secondary" pButton=pButton label="Exit"
                (click)="onExit()" [style]="{'cursor': 'pointer','margin-left': '17.5px'}"></button>
            </div>
            <!--                    [disabled]="((editorState.errors.length > 0) || (editorState.messageTemplate.title === '' || (editorState.messageTemplate.body === '')) ? 'disabled' : '')"-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-wrapper grid-section-email">
    <div class="d-flex justify-content-center message-details-form">
      <div *ngIf="isFirstStepVisible" class="mt-5 step-one-form">
        <div class="mb-4">
          <label class="form-label fw-bold">Message Name*</label>
          <div class="pb-2 text-secondary fst-italic">For internal use only</div>
          <input class="form-control" [class.ng-invalid]="isFormSubmitted && validationError.messageName"
            [(ngModel)]="editorState.messageTemplate.title" pInputText required maxlength="255"
            aria-describedby="message-name-error">
          <small id="message-name-error" class="p-error block fs-6" *ngIf="validationError.messageName">
            Message name must not be empty.
          </small>
        </div>
        <div class="mb-4">
          <label class="form-label fw-bold">Message Description</label>
          <div class="pb-2 text-secondary fst-italic">For internal use only</div>
          <textarea class="form-control"
            [(ngModel)]="editorState.messageTemplate.description" pInputTextarea  maxlength="255"
          ></textarea>
        </div>
        <div class="mb-4">
          <label class="form-label">Message Category*</label>
          <div class="w-75">
            <!-- <p-dropdown [options]="categories" [(ngModel)]="editorState.selectedCategory" optionLabel="name" placeholder="Please Select" [showClear]="true"></p-dropdown> -->
            <p-dropdown class="groundDropdown" [class.invalid-dropdown]="isFormSubmitted && validationError.messageCategory"
              [required]="true" id="messageCategory" [style]="{'width':'100%'}" autoWidth="false" [autoZIndex]="false" 
              #messageCategory [options]="messageCategoryList" [(ngModel)]="editorState.messageTemplate.categoryId"
              optionLabel="item_text" optionValue="item_id" placeholder="Select">
            </p-dropdown>
            <small id="message-category-error" class="p-error block fs-6" *ngIf="validationError.messageCategory">
              Message category must not be empty.
            </small>
          </div>
        </div>
         <div *ngIf="currentClientID === 'AduvoAdmin'" class="mb-4 ">
          <div class="p-d-flex my-2">
              <p-checkbox [(ngModel)]="editorState.messageTemplate.isQuickStart" binary="true" inputId="chkQuickStart">
              </p-checkbox>
              <label class="control-label">Aduvo Quick Start</label>
            </div>
            <div class="p-d-flex my-2">
              <p-checkbox [(ngModel)]="editorState.messageTemplate.isRecommended" binary="true" inputId="chkRecommended">
              </p-checkbox>
              <label class="control-label">Aduvo Recommended</label>
            </div>
         </div>
        <div class="mb-4">
          <label class="form-label fw-bold">Subject Line*</label>
          <div class="position-relative input-icon">
            <input class="form-control" type="text" pInputText #txtTemplateEmailSubject id="txtTemplateEmailSubject"
              [class.ng-invalid]="isFormSubmitted && validationError.subjectLine" maxlength="500"
              [(ngModel)]="editorState.messageTemplate.emailSubject" aria-describedby="subject-line-error" />
            <div class="subject-line-input-icons" style="cursor: pointer;">
              <i class="pi pi-tag fs-5 me-2" (click)="onEmailSubjectMergeTagRequested()"></i>
              <i class="fa-regular fs-5 fa-face-smile mx-2" (click)="op.toggle($event)"></i>
            </div>
            <small id="subject-line-error" *ngIf="validationError.subjectLine" class="p-error block fs-6">
              Subject Line must not be empty.
            </small>
          </div>
          <p-overlayPanel #op [showCloseIcon]="false" [styleClass]="'emoji-mart-overlay'">
            <emoji-mart title="Pick your emoji…" #emojiMart emoji="point_up" [darkMode]="false"
              (emojiClick)="emojiClicked($event)">
            </emoji-mart>
          </p-overlayPanel>
        </div>
        <div class="mb-4">
          <label class="form-label fw-bold">Preview Text</label>
          <div class="position-relative input-icon">
            <input class="form-control" id="txtTemplateEmailPreviewText" #txtTemplateEmailPreviewText maxlength="255"
              type="text" pInputText [(ngModel)]="editorState.messageTemplate.preHeaderText" />
            <div class="subject-line-input-icons" style="cursor: pointer;">
              <i class="pi pi-tag fs-5 me-2" (click)="onPreviewTextMergeTagRequested()"></i>
              <i class="fa-regular fs-5 fa-face-smile mx-2" (click)="opPreview.toggle($event)"></i>
            </div>
          </div>
          <p-overlayPanel #opPreview [showCloseIcon]="false" [styleClass]="'emoji-mart-overlay'">
            <emoji-mart title="Pick your emoji…" #emojiMartPreview emoji="point_up" [darkMode]="false"
              (emojiClick)="emojiClickedPreview($event)">
            </emoji-mart>
          </p-overlayPanel>
        </div>
        <div class="mb-4">
          <button pButton pRipple (click)="onSubmitStepFirst()" label="Next: Content" class="p-button-rounded"></button>
          <p-button (click)="onExit()" [style]="{'cursor': 'pointer','margin-left':'17.5px'}"
            styleClass="p-button-secondary">Cancel</p-button>
        </div>

      </div>
      <div [ngClass]="[isSecondStepVisible ? 'd-flex' : 'd-none']" class="flex-column w-100">
        <div class="align-items-center d-flex justify-content-center header-one campaign-editor-buttons" style="border-bottom: 1px solid #E5E5E5;   justify-content: flex-end!important; /*position: fixed;*/
        background-color: white; padding: 0 100px 0px 300px;">
          <button pButton type="button" id="codeEditor" class="p-button-text btn-hover-icon">
            <i class="far fa-code mx-2"></i> HTML Editor</button>
          <button pButton type="button" id="undoButton" class="p-button-text btn-hover-icon">
            <i class="undoicon" aria-hidden="true"></i> Undo</button>
          <button pButton type="button" id="redoButton" class="p-button-text btn-hover-icon">
            <i class="redoicon" aria-hidden="true"></i> Redo</button>
          <span id="changeHistoryContainer" style="display: none;">Last change: <a id="changeHistoryLink"></a></span>
          <span *ngIf="currentClientID === 'AduvoAdmin'">Active &nbsp;</span>
          <p-inputSwitch id="inputSwitchPublic" *ngIf="currentClientID === 'AduvoAdmin'" class="inputSwithActive"
            [(ngModel)]="editorState.messageTemplate.published"
            (onChange)="onPublishChange(editorState.messageTemplate)"></p-inputSwitch>
            <!-- removed   padding-left: 25px; causing lot space the needed -->
          <button pButton type="button" style="   padding-right: 20px;"
            class="p-button-text btn-hover-icon" (click)="templateEditor.onPreviewBtnClick()">
            <i class="pi pi-eye mx-2"></i> Preview Email
          </button>
          <button pButton type="button" style="padding-left: 0; padding-right:0;" class="p-button-text btn-hover-icon"
            (click)="onSendTestBtnClick()">
            <i class="sendicon" aria-hidden="true"></i> Send Test Email
          </button>
        </div>
        <div class="w-100 helloclass" style="background: var(--very-light-gray);
        ;">
          <stripo-root #templateEditor isEditorOpen="true" *ngIf="showEmailEditor"
            (saveStatusChange)="saveStatusChanged($event)" [campaignNavigationData]="campaignNavigationData">
          </stripo-root>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
*************************************************************************************
Send Test Email Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" [(visible)]="showTestEmailWizardVisible" [style]="{width: '32em', 'font-weight': '700'}"
  [baseZIndex]="10000">
  <p-header>
    <i class="pi pi-exclamation-circle"></i>&nbsp;
    <span class="dialog-head">Data Validation Errors</span>
  </p-header>
  <div style="width: 97.5%; background: var(--light-gray); height: 1px;"></div>
  <div style="width: 97.5%; background: var(--very-light-gray); height: 1px;"></div>
  <div *ngIf="(editorState.errors.length > 0)">
    The information specified is not valid and cannot be saved.<br /><br />
    <p-table [value]="editorState.errors">
      <ng-template pTemplate="body" let-error>
        <tr>
          <td style="color:crimson; font-weight:bold; padding:.2rem .2rem;">{{error}}</td>
        </tr>
      </ng-template>
    </p-table>
    <br />Please correct all information.
  </div>
  <div *ngIf="(editorState.errors.length < 1)">
    <br />
    <i class="pi pi-check" style="color: mediumseagreen;"></i>
    <label style="color:mediumseagreen; padding-left:.5rem;">All Data is valid and may be saved.</label>
    <br />
  </div>
</p-dialog>

<!--
*************************************************************************************
Data Validation Error Dialog
*************************************************************************************
-->
<p-dialog class="pulseErrorDialog" position="left" [(visible)]="editorState.showErrors"
  [style]="{width: '32em', 'font-weight': '700'}" [baseZIndex]="10000">
  <p-header>
    <i class="pi pi-exclamation-circle"></i>&nbsp;
    <span class="dialog-head">Data Validation Errors</span>
  </p-header>
  <div style="width: 97.5%; background: var(--light-gray); height: 1px;"></div>
  <div style="width: 97.5%; background: var(--very-light-gray); height: 1px;"></div>
  <div *ngIf="(editorState.errors.length > 0)">
    The information specified is not valid and cannot be saved.<br /><br />
    <p-table [value]="editorState.errors">
      <ng-template pTemplate="body" let-error>
        <tr>
          <td style="color:crimson; font-weight:bold; padding:.2rem .2rem;">{{error}}</td>
        </tr>
      </ng-template>
    </p-table>
    <br />Please correct all information.
  </div>
  <div *ngIf="(editorState.errors.length < 1)">
    <br />
    <i class="pi pi-check" style="color: mediumseagreen;"></i>
    <label style="color:mediumseagreen; padding-left:.5rem;">All Data is valid and may be saved.</label>
    <br />
  </div>
</p-dialog>

<textMessageEditor-root #textMessageEditor *ngIf="showTextMessageEditor"></textMessageEditor-root>
<p-dialog class="custom-alert custom-modal" id="delete_campaign" #cancelSavingDialog [(visible)]="showCancelEdit"
  [resizable]="false" [modal]="true" [style]="{'width': '52.5em','font-weight': '700'}">
  <div class="pb-25">
    <p class="confirm-sms">What would you like to do with the changes to {{editorState.messageTemplate.title}}? </p>
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button (onClick)="onSaveAndExit()" [style]="{'margin-right': '17.5px'}" label="Save and Exit"
        styleClass="p-button-rounded"></p-button>
      <p-button (onClick)="onCancelEdit()" [style]="{'margin-right': '17.5px'}" label="Exit and discard"
        styleClass="p-button-secondary"></p-button>
      <p-button (click)="showCancelEdit = false;" [style]="{'cursor': 'pointer','margin-left':'17.5px'}"
        styleClass="p-button-secondary">Cancel</p-button>
      <!-- <a (click)="showDeleteCampaignDialog = false;" style="color:#666666;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;" styleClass="p-button-secondary">Cancel</a> -->
    </div>
  </ng-template>
</p-dialog>