import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { environment } from 'src/environments/environment';
import { BillingService } from '../../../services/billingService';
import { ToastService } from 'src/services/toast.service';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';

declare var _ps: any;

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})

export class SelectPlanComponent implements OnInit {
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";
  public user: UserToken;
  public isValidTestEmail: boolean = true;
  public showPermissionYesMessage: boolean = false;
  public showPermissionNoMessage: boolean = false;
  public showSubscriptionContract: boolean = false;
  public showManageSubscription: boolean = false;

  constructor(
    private router: Router,
    private BillingService: BillingService,
    private toastService: ToastService,
    private userTokenService: UserTokenService
  ) {
    this.getCompanyAdditionalDetail();
    window['_ps'] = null;
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.navigationStack = stack?.value;
    this.user = this.userTokenService.getUser();

    if (window['_ps'] == null || window['_ps'] == undefined) {
      (function (w, d, s, c, f, n, t, g, a, b, l) {
        // Defines the global _ps object and initializes the _ps() function
        // that will queue commands until the Ironclad Clickwrap Library is ready.
        w['PactSafeObject'] = n;
        w[n] = w[n] || function () {
          (w[n].q = w[n].q || []).push(arguments)
        },

          // Defines the event functions for the global _ps object.
          w[n].on = function () {
            (w[n].e = w[n].e || []).push(arguments)
          },
          w[n].once = function () {
            (w[n].eo = w[n].eo || []).push(arguments)
          },
          w[n].off = function () {
            (w[n].o = w[n].o || []).push(arguments)
          },

          // Marks the time that the script is inserted.
          w[n].t = 1 * new Date().getTime(),
          w[n].l = 0;

        // Inserts a new script element to load the Ironclad Clickwrap Library JS file (ps.js).
        a = d.createElement(s);
        b = d.getElementsByTagName(s)[0];
        a.async = 1;
        a.src = c;

        // Marks that the script has started loading or failed to load.
        a.onload = a.onreadystatechange = function () { w[n].l = 1 };
        a.onerror = a.onabort = function () { w[n].l = 0 };
        b.parentNode.insertBefore(a, b);

        // Retry loading the script from a fallback location after 4 seconds.
        setTimeout(function () {
          if (!w[n].l && !w[n].loaded) {
            w[n].error = 1;
            a = d.createElement(s);
            a.async = 1;
            a.src = f;
            a.onload = a.onreadystatechange = function () { w[n].l = 1 };
            a.onerror = a.onabort = function () { w[n].l = 0 };
            b.parentNode.insertBefore(a, b);

            // Log the loading error via beacon.
            l = function (u, e) {
              try {
                e = d.createElement('img');
                e.src = 'https://d3r8bdci515tjv.cloudfront.net/error.gif?t=' + w[n].t + '&u=' + encodeURIComponent(u);
                d.getElementsByTagName('body')[0].appendChild(e);
              }
              catch (x) { }
            };
            l(c);

            // Call the optional error callback function after a second failed attempt.
            setTimeout(function () {
              if (!w[n].l && !w[n].loaded) {
                w[n].error = 1;
                if (g && 'function' == typeof g) {
                  g.call(this);
                }
                l(f);
              }
            }, t);
          }
        }, t);
      })(window, document, 'script', '//vault.pactsafe.io/ps.min.js', '//d3l1mqnl5xpsuc.cloudfront.net/ps.min.js', '_ps', 4000, function optionalErrorCallback() { alert('Unable to load the JS Library.') });

      // Creates a Site object with the default configuration.
      _ps('create', environment.ironcladSiteId);
    }
  }

  async ngOnInit(): Promise<void> {

    var groupKey = "embedded-recurringpayments";
    _ps("load", groupKey, { container_selector: "clickwrapContainer", signer_id: this.user.email, display_all: true });
    _ps("set", "signer_id", this.user.email);
    _ps("send", "updated", { custom_data: { first_name: this.user.given_name, last_name: this.user.family_name, company_name: this.user.TenantName, lender_id: this.user.TenantId } });

    // Submits the form once the contracts have been accepted.
    _ps.on('valid', function (parameters, group) {
      // do nothing
    }.bind(this));

    /******************************
     * Tracking acceptance and getting the contract
     * Acceptance of a Clickwrap Group, by default, is sent when your signer checks a checkbox or clicks the “I Agree” button. To send 
     * acceptance outside of that default workflow, you can follow the below syntax:
     * Note: Sending acceptance like this requires a callback
    *******************************/
    _ps(groupKey, "agreed", {
      // The event_callback function will be invoked once the "send" is complete.
      event_callback: function (err, eventType, clickwrapGroup, payload) {
        if (err) {
          console.log(err);
        }
        // The send is complete and acceptance was captured successfully.
      }.bind(this)
    });
  }

  getCompanyAdditionalDetail() {
    this.BillingService.GetCompanyAdditionalDetail().subscribe(
      (res) => {
        if (res.data) {
          if (res.data.subscribed) {
            if (this.user.role == "Company Admin" || this.user.role == "Marketing Admin") {
              this.showManageSubscription = true;
              this.showSubscriptionContract = false;
            }
            else {
              this.showSubscriptionContract = true;
              this.showManageSubscription = false;
              this.router.navigate(['/marketing/content-library-filter/All']);
              this.storageHelper.SetDataInStorage("onBoardingCompleted", "true", 20);
            }
          } else {
            this.showSubscriptionContract = true;
          }
        }
      },
      (err) => {
      }
    );
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/opt-in-email']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToBookTime(): void {
    var element = <HTMLInputElement>document.getElementById("ps-contract-checkbox-271114");
    var contractChecked = false;
    if (element) {
      contractChecked = element.checked;
    }

    if (contractChecked) {
      let navigateTo = '/subscribe-text';
      let navigateFrom = '/select-plan';
      if (this.navigationStack === undefined) {
        this.navigationStack = [];
      }
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
      window['_ps'] = null;
    } else {
      this.toastService.showError("Please accept Recurring Payments Acknowledgement.");
    }
  }

  redirectToCTAScreenDirectly(): void {
    let navigateTo = '/cta-screen';
    let navigateFrom = '/book-time';
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    this.navigationStack.push(navigateFrom);
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    this.router.navigate([navigateTo]);
  }

  manageSubscription() {
    this.BillingService.GetGetStripeConfigDetail().subscribe(
      (response) => {
        if (response.data) {
          var customerNavigateUrl = response.data.customerNavigateUrl;
          window.open(customerNavigateUrl, "_blank");
        }
      });
  }
}
