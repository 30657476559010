<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size mt-3">
    <div class="logo text-center">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20"
               aria-valuemin="0" aria-valuemax="100" style="width:33.33%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="add-individual-form h-100 position-relative container-middle">
    <div class="container-size">
      <div class="m-auto d-flex justify-content-center flex-column text-center mt-5">
        <h5 class="title-onboarding">Great! Let's add that individual now.</h5>
      </div>
      <div class="m-auto">
        <div class="mt-5 row">
          <div class="col-6">
            <form [formGroup]="companyForm3" class="form-container">
              <div class="row mb-4 ">
                <div class="col-12">
                  <label for="companyAdminFirstName" class="form-label fw-bold">First Name</label>
                  <input type="text" name="companyAdminFirstName" id="companyAdminFirstName" maxlength="64"
                         placeholder="Admin First Name" class="form-control" formControlName="companyAdminFirstName" [ngClass]="{
                        'is-invalid':
                          companyForm3.get('companyAdminFirstName').invalid &&
                          (companyForm3.submitted),
                        'is-valid':
                          companyForm3.get('companyAdminFirstName').valid &&
                          (companyForm3.submitted)
                      }" />
                  <span class="text-danger" *ngIf="
                      companyForm3.get('companyAdminFirstName').invalid &&
                      (companyForm3.get('companyAdminFirstName').dirty ||
                      companyForm3.get('companyAdminFirstName').touched ||
                      companyForm3.submitted) &&
                      companyForm3.get('companyAdminFirstName').errors?.required
                    ">First name cannot be blank</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <label for="companyAdminLastName" class="form-label fw-bold">Last Name</label>
                  <input type="text" name="companyAdminLastName" id="companyAdminLastName" maxlength="64"
                         placeholder="Admin Last Name" class="form-control" formControlName="companyAdminLastName" [ngClass]="{
                          'is-invalid':
                            companyForm3.get('companyAdminLastName').invalid &&
                            (companyForm3.submitted),
                          'is-valid':
                            companyForm3.get('companyAdminLastName').valid &&
                            (companyForm3.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                        companyForm3.get('companyAdminLastName').invalid &&
                        (companyForm3.get('companyAdminLastName').dirty ||
                        companyForm3.get('companyAdminLastName').touched ||
                        companyForm3.submitted) &&
                        companyForm3.get('companyAdminLastName').errors?.required
                      ">Last name cannot be blank</span>
                </div>
              </div>
              <div class="row mb-4 ">
                <div class="col-12">
                  <label for="companyAdminEmail" class="form-label fw-bold">Email</label>
                  <input type="text" name="companyAdminEmail" id="companyAdminEmail" maxlength="128"
                         placeholder="Email" class="form-control" formControlName="companyAdminEmail" [ngClass]="{
                      'is-invalid':
                        companyForm3.get('companyAdminEmail').invalid &&
                        (companyForm3.submitted),
                      'is-valid':
                        companyForm3.get('companyAdminEmail').valid &&
                        (companyForm3.submitted)
                    }" />
                  <span class="text-danger" *ngIf="
                      companyForm3.get('companyAdminEmail').invalid &&
                      (companyForm3.get('companyAdminEmail').dirty ||
                      companyForm3.get('companyAdminEmail').touched ||
                      companyForm3.submitted) &&
                      companyForm3.get('companyAdminEmail').errors?.required
                    ">Email address cannot be blank</span>
                  <span class="text-danger" *ngIf="
                      companyForm3.get('companyAdminEmail').invalid &&
                      (companyForm3.get('companyAdminEmail').dirty ||
                        companyForm3.get('companyAdminEmail').touched ||
                        companyForm3.submitted) &&
                        companyForm3.get('companyAdminEmail').errors?.email
                    ">Email address is invalid.</span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-6">
            <!-- <p class="para-section mt-4">
              Client has appointed the individual listed above as an additional Administrator and acknowledges
              that additional Administrator has the actual authority to see all of Client's data imported into
              the ADUVO Application. Client may revoke Administrator's authority, at any time for no reason
              or for cause, by removing them within ADUVO user management.
            </p> -->
            <div class="para-section mt-10" id="clickwrapContainer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded"
                (click)="redirectToBack()">
          <i class="fa fa-arrow-left"></i>&nbsp;Back
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end" (click)="redirectToAddAdmin()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
          [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>
