import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter } from '../../../models/PeopleAndLoanFilterOutput';
import { PeopleAndLoanService } from '../../../services/peopleandloans.service';
@Component({
  selector: 'app-people-and-list-advanced-filter-boolean',
  templateUrl: './people-and-list-filter-boolean.component.html',
  styleUrls: ['./people-and-list-filter-boolean.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterBooleanComponent implements OnInit, OnDestroy {
  //* TEXT datatype has RhsInputControl1 only so create seprate comp for number.
  public selectedOperand: any | null;
  public textFormGroup: FormGroup | null;
  public items: FormArray | null;
  public selectedOpCode: string;
  private sub: Array<Subscription>;
  private selectedFilter: PeopleAndLoanFieldLookup | null;
  private peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput;
  @Input() OperandDropDownList: any | null;
  private tmpPeopleAndLoanFilters: PeopleAndLoanFilterData;
  private peopleAndLoanFilters: PeopleAndLoanFilterData;
  @Input() get SelectedFilter(): any | null {
    return this.selectedFilter;
  }
  set SelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      this.generateForm();
    }
  }
  @Output() onCreateFilterEvent = new EventEmitter<PeopleAndLoanFilterOutput>();
  @Output() onCancelFilterEvent = new EventEmitter<boolean>();
  //private existingListOrFilters: PeopleLoanListOrFilter[];
  private storageHelper: StorageHelper;
  public disableApplyFilter: boolean;
  private editNode: PeopleAndLoanEditTagData | null;
  private insertIndex: number;

  constructor(public formBuilder: FormBuilder, private peopleAndLoanService: PeopleAndLoanService) {
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.sub = new Array<Subscription>();
    this.peopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(false, null);
    //this.existingListOrFilters = [];
    this.storageHelper = new StorageHelper();
    this.disableApplyFilter = true;
    this.selectedOpCode = '';
    this.insertIndex = 0;
  }

  ngOnInit(): void {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
  }

  operandChanged(event, index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;
      this.selectedOpCode = event.value.OpCode;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b|\bEQTRUE\b|\bNOTEQTRUE\b/.test(event.value.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  operandChangedNew(index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  private generateForm() {
    this.unSubscribeAllSubs();

    this.textFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });

    if (this.SelectedFilter?.isAddFlag) {
      this.operandChangedNew(0);
    }

    let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();

    this.items = this.formDataCtrl;
    this.sub.push(
      this.textFormGroup.valueChanges.pipe(
        //debounceTime(500),//delay and  stop unnecessary propogation.
        distinctUntilChanged()
      ).subscribe(item => {
        listAndFilters.PeopleLoanListOrFilters = [];//clear all
        let idx = 0;
        listAndFilters.id = idx + 1;
        listAndFilters.peopleAndLoansFieldLookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId;

        //Changed json fetchiing first rrecord value
        if (item?.items?.length) {
          listAndFilters.group = item.items[0].operandDropDown.FieldGroupName;
          listAndFilters.datasource = item.items[0].operandDropDown.DataSource;
          listAndFilters.datacolumn = item.items[0].operandDropDown.DataColumn;
          listAndFilters.type = item.items[0].operandDropDown.DataType;
          if (item.items[0].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
            listAndFilters.subGroup = item.items[0].operandDropDown.FieldSubGroupName;
          }
        }

        //this.existingListOrFilters = [];
        // let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
        // if (savedFilter?.value) {
        //   let andFilterIndex = -1;
        //   let obj = JSON.parse(JSON.stringify(savedFilter.value));
        //   andFilterIndex = obj?.PeopleLoanList?.PeopleLoanListAndFilters?.length ?? -1;
        //   if (andFilterIndex) {
        //     listAndFilters.id = andFilterIndex + 1;
        //   }
        // }
        //loop and add/remove validation as per dropdown value.
        item.items.forEach((element, index) => {
          if (index == 0) {
            listAndFilters.PeopleLoanListOrFilters = [];//clear all
          }
          if (this.selectedFilter.DataColumnDisplay == 'Show Latest Loan') {
            this.tmpPeopleAndLoanFilters.scope.name = "latest";
            this.tmpPeopleAndLoanFilters.scope.LoanStatusTypes = [];
          }
          else {
            let rhsValue = element.txtRHSValue.replace(/[^\w\s]/gi, '');//removing special symbol
            let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
            filter.id = (index + 1);
            filter.operator = element.operandDropDown.OpCode;
            filter.lhs = '';
            filter.rhs = [rhsValue];
            filter.peopleAndLoansTypeDefLookupId = element.operandDropDown.PeopleAndLoansTypeDefLookupId;
            //filter.peopleAndLoansFieldLookupId_ = this.SelectedFilter?.key;

            let criteriaQuery = element.operandDropDown.Criteria;
            if (criteriaQuery) {
              criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
              criteriaQuery = criteriaQuery.replace('{{rhs1}}', rhsValue);
              filter.criteria = criteriaQuery;
            }

            let htmlTemplate = element.operandDropDown.DisplayHtmlTemplate;
            if (htmlTemplate) {
              htmlTemplate = htmlTemplate.replace('{{lhs}}', element.operandDropDown.DataColumnDisplay);
              htmlTemplate = htmlTemplate.replace('{{rhs1}}', rhsValue);
              let prefix = '';//listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
              filter.displayHtml = prefix + htmlTemplate;
            }
            listAndFilters.PeopleLoanListOrFilters.push(filter)
          }
        });
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
        // if (this.existingListOrFilters?.length) {
        //   this.existingListOrFilters.forEach(filter => {
        //     listAndFilters.PeopleLoanListOrFilters.push(filter)
        //   });
        // }

        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters);

        //validate and show apply button
        if (!this.textFormGroup?.valid) {
          this.disableApplyFilter = true;
        } else {
          this.disableApplyFilter = false;
        }
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
      })
    );
  }
  applyFilter() {
    if (this.textFormGroup?.valid) {//if json has been received.
      this.validateAndEmitSuccessJson();

    } else {
      this.emitFailureJson();
    }
  }

  private createItems(): FormGroup[] {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //edit filter from input or observable.
    const formGroupArr: FormGroup[] = [];

    //Edit CASE  
    let editnodetmp = this.storageHelper.GetDataFromStorage('PeopleAndLoan_editNode');//fetch 
    if (editnodetmp?.value?.peopleAndLoansFieldLookupId &&
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length > 0) {
      this.editNode = editnodetmp.value;
      let andIndex = this.editNode?.andIndex;
      let lookupId = this.editNode?.peopleAndLoansFieldLookupId;
      //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean

      if (lookupId != 'sll1') {
        let toIterate = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.
          filter(x => x.id == andIndex && x.peopleAndLoansFieldLookupId == lookupId);

        let lblValue = editnodetmp?.value?.labelStr;
        toIterate[0].PeopleLoanListOrFilters.forEach((itm) => {
          // if (!lblValue) {
          //   lblValue = itm.displayHtml.substring(0, itm.displayHtml.indexOf("</b>"))
          //   lblValue = lblValue.replace("<b class=\"lhs\">", "").trim();
          // }
          let selectedDropdown = this.OperandDropDownList.find(x => x.PeopleAndLoansTypeDefLookupId == itm.peopleAndLoansTypeDefLookupId);

          let rhsValue = '';
          if (itm?.rhs?.length) {
            rhsValue = itm.rhs[0];
          }

          formGroupArr.push(
            this.formBuilder.group({
              label: new FormControl(lblValue),
              operandDropDown: new FormControl(selectedDropdown),
              txtRHSValue: new FormControl(rhsValue)
            })
          );
        });
      }  
    }
    else {
      let selectedDropdown = null;
      if (this.SelectedFilter?.isAddFlag) {//insert as blank
        if (this.SelectedFilter?.DataColumnDisplay == 'Show Latest Loan') {
          selectedDropdown = this.OperandDropDownList.find(x => x.OpCode == 'EQTRUE');
        } else {
          selectedDropdown = this.OperandDropDownList.find(x => x.OpCode == 'EQTRUE');
        }
      }

      formGroupArr.push(
        this.formBuilder.group({
          label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
          operandDropDown: new FormControl(selectedDropdown),
          txtRHSValue: new FormControl('')
        })
      );
    }
    return formGroupArr;
  }

  get formDataCtrl(): FormArray {
    const formArray = this.textFormGroup?.get('items') as FormArray;
    return formArray;
  }

  deleteRow(index) {
    let k = this.textFormGroup?.get('items') as FormArray
    k.removeAt(index);
  }

  addMore() {
    let newrow = this.formBuilder.group({
      label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
      operandDropDown: new FormControl('', [Validators.required]),
      //operandDropDown: new FormControl({ value: '' }),
      txtRHSValue: new FormControl('', [Validators.required])
    });
    (<FormArray>this.textFormGroup?.get('items')).push(newrow);
  }

  validateAndEmitSuccessJson() {
    console.log('validateAndEmitSuccessJson-EMITed fromm here:');
    let len = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length;
    if (len) {
      if (this.editNode?.peopleAndLoansFieldLookupId) {//if it is edit
        let andIndexRemove =
          this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.findIndex(x =>
            x.id == this.editNode?.andIndex &&
            x.peopleAndLoansFieldLookupId == this.editNode.peopleAndLoansFieldLookupId);

        if (andIndexRemove !== -1) {
          this.peopleAndLoanFilters.PeopleLoanList.
            PeopleLoanListAndFilters.splice(andIndexRemove, 1);
        }
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = this.editNode?.andIndex;

      } else {
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = len + 1
      }
      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.forEach(ele => {
      //   if (ele.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
      //     ele.isDeleted = true;//set existing deleted.
      //   }
      // });
      // let tm = this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => x.isDeleted == false);

      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
      //   ...tm); 

      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);

    }
    else {//add without any logic 
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);
    }
    this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];//reset 

    // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters =
    //   this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => !x.isDeleted);
    this.peopleAndLoanFilterOutput.isSuccess = true;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = this.peopleAndLoanFilters;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  emitFailureJson() {
    this.peopleAndLoanFilterOutput.isSuccess = false;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = null;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  cancelFilter() {
    this.onCancelFilterEvent.emit(true);
  }

  setAndEmit() {
    if (this.selectedFilter) {
      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      //loop
      let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
      filter.id = (this.insertIndex + 1);
      filter.operator = this.selectedFilter.OpCode;
      filter.lhs = '';
      filter.rhs = [''];
      filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
      let criteriaQuery = this.selectedFilter.Criteria;
      if (criteriaQuery) {
        criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
        criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
        filter.criteria = criteriaQuery;
      }

      let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
      if (htmlTemplate) {
        htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
        htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
        let prefix = listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
        filter.displayHtml = ' ' + htmlTemplate;
      }
      listAndFilters.PeopleLoanListOrFilters.push(filter);
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
      this.insertIndex++;
      this.validateAndEmitSuccessJson();
    }
  }

  unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  ngOnDestroy(): void {
    this.selectedOperand = null;
    this.OperandDropDownList = null;
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.peopleAndLoanFilters.PeopleLoanList = new PeopleLoanList();//clean all json
    //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
    this.unSubscribeAllSubs();
  }

}
