<div class="text-wrapper row filters-row p-3" style="min-height: 74px;gap: .5rem;">
    <div class="flex w-auto">
        <button pButton type="button" class="btn-custom my-auto d-flex flex-row" (click)="op.toggle($event)">
            <span class="pi pi-plus" [ngClass]="{'mr-2': selectedFilterColumns.length < 1, 'm-auto': selectedFilterColumns.length > 0}" aria-hidden="true"></span>
            <span *ngIf="selectedFilterColumns.length < 1">Add Filter</span>
        </button>
    </div>
    <div class="w-auto my-auto custom-grid-filter" *ngFor="let selectedFilterColumn of selectedFilterColumns">
        <ng-container *ngIf="selectedFilterColumn.filterType === FilterTypes.TEXT">
            <p-multiSelect [displaySelectedLabel]="true" [options]="selectedFilterColumn.filterOptions"
                [(ngModel)]="selectedFilterColumn.filterValues" (onChange)="onFilterChange()" optionLabel="field"
                optionValue="value" [placeholder]="selectedFilterColumn.header">
                <ng-template let-value pTemplate="selectedItems">
                    <div [ngSwitch]="selectedFilterColumn.filterValues.length">
                        <div *ngSwitchCase="0">
                            <span class="font-bold">Select {{selectedFilterColumn.header}}</span>
                        </div>
                        <div *ngSwitchCase="1">
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            <span>{{selectedFilterColumn.filterValues[0] | capitalize}}</span>
                        </div>
                        <div *ngSwitchDefault>
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            {{selectedFilterColumn.filterValues[0] | capitalize}}
                            <p-badge [value]="selectedFilterColumn.filterValues.length"></p-badge>
                        </div>
                    </div>
                </ng-template>
            </p-multiSelect>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
        </ng-container>
        <ng-container *ngIf="selectedFilterColumn.filterType === FilterTypes.SINGLESELECT">
            <p-multiSelect [selectionLimit]="1" [displaySelectedLabel]="true"
                [options]="selectedFilterColumn.filterOptions" [(ngModel)]="selectedFilterColumn.filterValues"
                (onChange)="onFilterChange()" optionLabel="field" optionValue="value"
                [placeholder]="selectedFilterColumn.header">
                <ng-template let-value pTemplate="selectedItems">
                    <div [ngSwitch]="selectedFilterColumn.filterValues.length">
                        <div *ngSwitchCase="0">
                            <span class="font-bold">Select {{selectedFilterColumn.header}}</span>
                        </div>
                        <div *ngSwitchCase="1">
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            <span>{{selectedFilterColumn.filterValues[0] | capitalize}}</span>
                        </div>
                        <div *ngSwitchDefault>
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            {{selectedFilterColumn.filterValues[0] | capitalize}}
                            <p-badge [value]="selectedFilterColumn.filterValues.length"></p-badge>
                        </div>
                    </div>
                </ng-template>
            </p-multiSelect>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
        </ng-container>
        <ng-container *ngIf="selectedFilterColumn.filterType === FilterTypes.KEYPAIR">
            <p-multiSelect [displaySelectedLabel]="true" [options]="selectedFilterColumn.filterOptions"
                [(ngModel)]="selectedFilterColumn.filterValues" (onChange)="onFilterChange()" optionLabel="field"
                optionValue="value" [placeholder]="selectedFilterColumn.header">
                <ng-template let-value pTemplate="selectedItems">
                    <div [ngSwitch]="selectedFilterColumn.filterValues.length">
                        <div *ngSwitchCase="0">
                            <span class="font-bold">Select {{selectedFilterColumn.header}}</span>
                        </div>
                        <div *ngSwitchCase="1">
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            <span>{{selectedFilterColumn.filterValues[0].field }}</span>
                        </div>
                        <div *ngSwitchDefault>
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            {{selectedFilterColumn.filterValues[0].field }}
                            <p-badge [value]="selectedFilterColumn.filterValues.length"></p-badge>
                        </div>
                    </div>
                </ng-template>
            </p-multiSelect>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
        </ng-container>
        <ng-container *ngIf="selectedFilterColumn.filterType === FilterTypes.ENUM">
            <p-multiSelect [displaySelectedLabel]="true" [options]="selectedFilterColumn.filterOptions"
                [(ngModel)]="selectedFilterColumn.filterValues" (onChange)="onFilterChange()" optionLabel="field"
                optionValue="value" [placeholder]="selectedFilterColumn.header">
                <ng-template let-value pTemplate="selectedItems">
                    <div [ngSwitch]="selectedFilterColumn.filterValues.length">
                        <div *ngSwitchCase="0">
                            <span class="font-bold">Select {{selectedFilterColumn.header}}</span>
                        </div>
                        <div *ngSwitchCase="1">
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            <span>{{selectedFilterColumn.filterValues[0].label}}</span>
                        </div>
                        <div *ngSwitchDefault>
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            {{selectedFilterColumn.filterValues[0].label}}
                            <p-badge [value]="selectedFilterColumn.filterValues.length"></p-badge>
                        </div>
                    </div>
                </ng-template>
            </p-multiSelect>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
        </ng-container>
        <div class="flex-row" *ngIf="selectedFilterColumn.filterType === FilterTypes.DATE">
            <div class="custom-grid-date-filter" (click)="dateSelector.toggle($event)"
                [ngSwitch]="selectedFilterColumn.filterValues.length">
                <ng-container *ngSwitchCase="0">
                    <span class="font-bold">Select {{selectedFilterColumn.header}}</span>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                    <span>{{selectedFilterColumn.filterValues[0] | capitalize}}</span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                    {{selectedFilterColumn.filterValues[0] | capitalize}}
                    <p-badge [value]="selectedFilterColumn.filterValues.length"></p-badge>
                </ng-container>
                <span class="date-chevron pi-chevron-down ml-3 mr-2"></span>
            </div>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
            <p-overlayPanel #dateSelector>
                <div class="flex-column">
                    <div class="m-2 font-bold">Is Between</div>
                    <div class="flex-row">
                        <p-calendar [(ngModel)]="selectedFilterColumn.beginDate"></p-calendar>
                        <div class="m-2 font-bold">And</div><p-calendar
                            [(ngModel)]="selectedFilterColumn.endDate"></p-calendar>
                    </div>
                    <div>
                        <p-button styleClass="m-2" label="Save" (click)="onFilterChange(true)"></p-button>
                    </div>
                </div>
            </p-overlayPanel>
        </div>
        <ng-container *ngIf="selectedFilterColumn.filterType === FilterTypes.BOOLEAN">
            <p-multiSelect [displaySelectedLabel]="true" [options]="selectedFilterColumn.filterOptions"
                [(ngModel)]="selectedFilterColumn.filterValues" (onChange)="onFilterChange()" optionLabel="field"
                optionValue="value" [placeholder]="selectedFilterColumn.header">
                <ng-template let-value pTemplate="selectedItems">
                    <div [ngSwitch]="selectedFilterColumn.filterValues.length">
                        <div *ngSwitchCase="0">
                            <span class="font-bold">Select {{selectedFilterColumn.header}}</span>
                        </div>
                        <div *ngSwitchCase="1">
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            <span>{{selectedFilterColumn.filterValues[0] }}</span>
                        </div>
                        <div *ngSwitchDefault>
                            <span class="font-bold">{{selectedFilterColumn.header}}: </span>
                            <p-badge [value]="selectedFilterColumn.filterValues.length"></p-badge>
                        </div>
                    </div>
                </ng-template>
            </p-multiSelect>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
        </ng-container>
        <ng-container *ngIf="selectedFilterColumn.filterType === FilterTypes.SELECTABLE">
            <div class="m-auto font-bold mx-3">
                {{selectedFilterColumn.header}}
            </div>
            <p-button icon="pi pi-times" (click)="removeFilter(selectedFilterColumn)"
                styleClass="p-button-danger p-button-outlined filter-button"></p-button>
        </ng-container>
    </div>

</div>
<p-overlayPanel #op styleClass="filter-panel">
    <div class="flex flex-column grid-filters-overlay">
        <span class="p-input-icon-right search-input-group" *ngIf="!hideSearchBar">
            
                <p-autoComplete [(ngModel)]="selectedItem" [suggestions]="suggestions" (onSelect)="onSuggestionSelected($event)"
                    (completeMethod)="suggestionSearch($event)" placeholder="Search filters"></p-autoComplete>
                    <i class="pi pi-search mr-4"></i>
        </span>
        <div class="filter-search-label mt-2">Quick Filters</div>
        <div class="p-2 text-center flex-wrap">
            <ng-container *ngFor="let column of columns">
                <p-chip label="{{column.header}}" *ngIf="column | displayFilterOption:selectedFilterColumns"
                    styleClass="m-2 px-3 pointer-cursor" (click)="selectFilter(column);op.hide()"></p-chip>
            </ng-container>
        </div>
    </div>
</p-overlayPanel>