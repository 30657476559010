/** 
* Implementation of the FocusIT.DataContracts.Pulse.MessageTemplate [DataContract].
*/
export class MessageTemplate {
  public tenantId: string = '00000000-0000-0000-0000-000000000000';
  public templateId: string = '00000000-0000-0000-0000-000000000000';
  public title: string = '';
  public description: string = '';
  public templateTypeId: number = 1;
  public categoryId: string = '';
  public categoryDescription: string = null;

  public owner: string = '';
  public ownerFullName: string = '';
  public ownerRoleId: string = '';

  public createdOnUTC: Date = null;
  public createdBy: string = '';
  public modifiedOnUTC: Date = null;
  public modifiedBy: string = '';
  public deletedOnUTC: Date = null;
  public deletedBy: string;

  public body: string = '';
  public loanOriginationSystem: number = 0;
  public templateHtml: string = '';
  public templateCss: string = '';
  public width: number = null;
  public height: number = null;
  public published: boolean = false;
  public emailSubject: string = '';
  public preHeaderText: string = '';
  public isPublishingDb: boolean = false;
  public isContactOnly: boolean = false;
  public previewEmailSubject: string = '';
  public previewPreHeaderText: string = '';
  public previewBody: string = '';
  public isQuickStart: boolean = false;
  public isRecommended: boolean = false;
  public bodyHistory: string = '';
  /**
   * Constructor - all parameters are optional.
   * Only passed parameters are assigned to their corresponding property.
   *
   * @param TenantId
   * @param TemplateId
   * @param SourceId
   * @param SourceDescription
   * @param Title
   * @param Description
   * @param TemplateTypeId
   * @param TemplateTypeDescription
   * @param CategoryId
   * @param CategoryDescription
   * @param CreatedOnUTC
   * @param CreatedBy
   * @param ModifiedOnUTC
   * @param ModifiedBy
   * @param DeletedOnUTC
   * @param DeletedBy
   * @param Owner
   * @param OwnerFullName
   * @param Body
   * @param LoanOriginationSystem
   * @param TemplateHTML
   * @param TemplateCSS
   * @param Width
   * @param Height
   * @param Published
   * @param EmailSubject
   * @param PreHeaderText
   * @param AdditionalUsers
   * @param AdditionalTeams
   * @param PreviewEmailSubject
   * @param PreviewPreHeaderText
   * @param PreviewBody
   */
  constructor(
    TenantId?: string,
    TemplateId?: string,
    Title?: string,
    Description?: string,
    TemplateTypeId?: number,
    CategoryId?: string,
    CategoryDescription?: string,
    CreatedOnUTC?: Date,
    CreatedBy?: string,
    ModifiedOnUTC?: Date,
    ModifiedBy?: string,
    DeletedOnUTC?: Date,
    DeletedBy?: string,
    Owner?: string,
    OwnerFullName?: string,
    Body?: string,
    LoanOriginationSystem?: number,
    TemplateHTML?: string,
    TemplateCSS?: string,
    Width?: number,
    Height?: number,
    Published?: boolean,
    EmailSubject?: string,
    PreHeaderText?: string,
    isPublishingDb?: boolean,
    isContactOnly?: boolean,
    PreviewEmailSubject?: string,
    PreviewPreHeaderText?: string,
    PreviewBody?: string,
    isQuickStart?: boolean,
    isRecommended?: boolean,
    BodyHistory?: string
  ) {
    if (TenantId !== undefined && TenantId !== null) this.tenantId = TenantId;
    if (TemplateId !== undefined && TemplateId !== null)
      this.templateId = TemplateId;
    if (Title !== undefined && Title !== null) this.title = Title;
    if (Description !== undefined && Description !== null)
      this.description = Description;
    if (TemplateTypeId !== undefined && TemplateTypeId !== null)
      this.templateTypeId = TemplateTypeId;
    if (CategoryId !== undefined && CategoryId !== null)
      this.categoryId = CategoryId;
    if (CategoryDescription !== undefined && CategoryDescription !== null)
      this.categoryDescription = CategoryDescription;
    if (CreatedOnUTC !== undefined) this.createdOnUTC = CreatedOnUTC;
    if (CreatedBy !== undefined && CreatedBy !== null)
      this.createdBy = CreatedBy;
    if (ModifiedOnUTC !== undefined) this.modifiedOnUTC = ModifiedOnUTC;
    if (ModifiedBy !== undefined && ModifiedBy !== null)
      this.modifiedBy = ModifiedBy;
    if (DeletedOnUTC !== undefined) this.deletedOnUTC = DeletedOnUTC;
    if (DeletedBy !== undefined && DeletedBy !== null)
      this.deletedBy = DeletedBy;
    if (Owner !== undefined && Owner !== null) this.owner = Owner;
    if (OwnerFullName !== undefined && OwnerFullName !== null)
      this.ownerFullName = OwnerFullName;
    if (Body !== undefined && Body !== null) this.body = Body;
    if (LoanOriginationSystem !== undefined && LoanOriginationSystem !== null)
      this.loanOriginationSystem = LoanOriginationSystem;
    if (TemplateHTML !== undefined && TemplateHTML !== null)
      this.templateHtml = TemplateHTML;
    if (TemplateCSS !== undefined && TemplateCSS !== null)
      this.templateCss = TemplateCSS;
    if (Width !== undefined) this.width = Width;
    if (Height !== undefined) this.height = Height;
    if (Published !== undefined && Published !== null)
      this.published = Published;
    if (isPublishingDb !== undefined && isPublishingDb !== null)
      this.isPublishingDb = isPublishingDb;
    if (isContactOnly !== undefined && isContactOnly !== null)
      this.isContactOnly = isContactOnly;
    if (EmailSubject !== undefined && EmailSubject !== null)
      this.emailSubject = EmailSubject;
    this.preHeaderText = PreHeaderText ?? '';
    if (PreviewEmailSubject !== undefined && PreviewEmailSubject !== null)
      this.previewEmailSubject = PreviewEmailSubject;
    if (PreviewPreHeaderText !== undefined && PreviewPreHeaderText !== null)
      this.previewPreHeaderText = PreviewPreHeaderText;
    if (PreviewBody !== undefined && PreviewBody !== null)
      this.previewBody = PreviewBody;
    if (isQuickStart !== undefined && isQuickStart !== null)
      this.isQuickStart = isQuickStart;
    if (isRecommended !== undefined && isRecommended !== null)
      this.isRecommended = isRecommended;
    if (BodyHistory !== undefined && BodyHistory !== null)
      this.bodyHistory = BodyHistory;
  }
}

/**
* Collection of FocusIT.DataContracts.Pulse.MessageTemplate [DataContract] objects.
*/
export class MessageTemplateCollection {
  Items: MessageTemplate[] = [];
}

export class TestEmailRequest {
  templateId: string;
  fromEmail: string;
  toEmails: string;
  subject: string;
  preHeaderText?: string;
  isPublishingDb: boolean;

  constructor() {
    this.isPublishingDb = false;
  }

}

export class MessageTemplateDialogModel {
  public campaignId: string;
  public campaignMessageId: string;
  public currentMessageType: number;
  public oldMessageTemplateTitle: string;
  public oldMessageTemplateId: string;
  public isChangeMessage: boolean;
  public resetPaging: boolean;

  constructor() {
    this.currentMessageType = 0;
    this.campaignId = '';
    this.campaignMessageId = '';
    this.oldMessageTemplateTitle = '';
    this.oldMessageTemplateId = '';
    this.isChangeMessage = false;
    this.resetPaging = false;
  }
}
export class BodyHistorySmsMessage {
  public previous: string[];
  public next: string[];
  constructor() {
    this.previous = [];
    this.next = [];
  }
}
