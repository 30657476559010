<app-filters [columns]="filters" (updateTable)="updateReport($event)" [hideSearchBar]="true">
</app-filters>
<div class="w-100 flex flex-row">
    <div class="border-right-2 col-6 left-panel">
        <div>
            <i class="fa-regular fa-envelope fa-xl"></i>Email
        </div>
        <div>
            <p-card *ngFor="let email of emailData" (click)="loadFilteredMessages(MessageTypes.EMAIL,email.filterType)" [styleClass]="'campaign-report-card cursor-pointer'">
                <div class="p-4 report-stats" [style.background-color]="email.backgroundColor">
                    <div class="m-auto">{{email.label}}</div> 
                    <div class="m-auto">
                        <span class="m-0">{{email.value}}</span>
                        <span *ngIf="email.valueType === ValueType.PERCENTAGE">%</span>
                    </div> 
                </div>
            </p-card>
        </div>
    </div>
    <div class="col-6 right-panel">
        <div>
            <i class="fa-sharp fa-regular fa-comment-dots fa-xl"></i>SMS
        </div>
        <div>
            <p-card *ngFor="let sms of smsData" (click)="loadFilteredMessages(MessageTypes.SMS,sms.filterType)" [styleClass]="'campaign-report-card cursor-pointer'">
                <div class="p-4 report-stats" [style.background-color]="sms.backgroundColor">
                    <div class="m-auto">{{sms.label}}</div> 
                    <div class="m-auto">
                        <span class="m-0">{{sms.value}}</span>
                        <span *ngIf="sms.valueType === ValueType.PERCENTAGE">%</span>
                    </div> 
                </div>
            </p-card>
        </div>
    </div>
</div>