import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingService } from 'src/services/billingService';

@Component({
  selector: 'app-to-dashboard-button',
  templateUrl: './to-dashboard-button.component.html',
  styleUrls: ['./to-dashboard-button.component.scss']
})
export class ToDashboardButtonComponent implements OnInit {

  public isOnboardingComplete: boolean = false;
  
  constructor(private billingService: BillingService, private router: Router) { }

  ngOnInit(): void {
    this.billingService.GetCompanyAdditionalDetail().subscribe(result => {
      this.isOnboardingComplete = result.data.onboarded;
    });
  }

  backToDashboard(){
    this.router.navigate(['/dashboard']);
  }

}
