import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettings } from 'src/appSettings';
import { StorageHelper } from 'src/helpers/StorageHelper';
import { PulseUser } from 'src/models/PulseUser';
import { RequestResponse } from 'src/models/RequestResponse';

@Injectable()
export class UserManagementService {
  private storageHelper: StorageHelper;
  constructor(private http: HttpClient) {
    this.storageHelper = new StorageHelper();
  }

  async GetAllUsers(requestResponse?: RequestResponse) {
    if (requestResponse === undefined || requestResponse === null) {
      requestResponse = new RequestResponse();
    }
    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      searchValue: requestResponse.data?.length > 0 ? requestResponse.data : '',
      orderBy: requestResponse.paging.orderBy,
    };

    return await this.http
      .post<RequestResponse>(AppSettings.API_GET_PULSE_USERS + '/allUsersList', searchModel)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetUserList() {
    console.info('Call /usersList in the webapi userManagement Controller');
    return await this.http
      .get<RequestResponse>(AppSettings.API_GET_PULSE_USERS + '/usersList?pageNum=-1')
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  GetAllUserList(publishTenantID: string = ''): Observable<RequestResponse> {
    let cacheKey = publishTenantID + 'UserListKey'; //storing for 0,1 as key, for publishDb and tenantdb
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      //console.info('Call /usersList in the webapi userManagement Controller');
      let qstr = publishTenantID ? "&publishTenantID=" + publishTenantID : '';
      return this.http
        .get<RequestResponse>(AppSettings.API_GET_PULSE_USERS + '/usersList?pageNum=-1' + qstr)
        .pipe(tap(x => {
          if (x && x.data && x.status == 200) this.storageHelper.SetDataInStorage(cacheKey, x, 1);
        }), catchError(this.handleError));
    }
  }

  GetAllConfirmedUserList(): Observable<RequestResponse> {
    let cacheKey = "userList_ContentLib";
    let data = this.storageHelper.GetDataFromStorage(cacheKey);
    if (data?.value) {
      return of(data.value);
    } else {
      const baseUrl = `${AppSettings.API_GET_ContentLibrary}/GetConfirmedUserList`;
      return this.http.get<RequestResponse>(baseUrl)
        .pipe(tap(x => {
          if (x && x.data && x.status == 200) {
            this.storageHelper.SetDataInStorage(cacheKey, x, 5);//set for 5 hours
          }
        }), catchError(this.handleError));
    }
  }

  async CreateUser(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();

    console.info('Call /Save in the webapi TenantUser Controller');
    return await this.http
      .post<RequestResponse>(AppSettings.API_GET_PULSE_USERS, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async EditUser(tenantUserId: string, requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();

    console.info('Call /Update in the webapi TenantUser Controller');
    return await this.http
      .put<RequestResponse>(AppSettings.API_GET_PULSE_USERS + '/?tenantUserId=' + tenantUserId, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetUser(tenantUserId: string) {
    console.info('Call /GetUser in the webapi userManagement Controller');
    return await this.http
      .get<any>(AppSettings.API_GET_PULSE_USERS + '/get/' + tenantUserId)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetUserEmailConfirmed(tenantUserId: string) {
    console.info('Call /GetUser in the webapi userManagement Controller');
    return await this.http
      .get<any>(
        AppSettings.API_GET_PULSE_USERS + '/getEmailConfirmed/' + tenantUserId
      )
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async DeleteUser(tenantUserId: string) {
    console.info('Call /DeleteUser in the webapi userManagement Controller');
    return await this.http
      .delete<any>(AppSettings.API_GET_PULSE_USERS + '/' + tenantUserId)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async RemoveFromPulse(companyUserId: string, tenantUserId: string) {
    console.info('Call /DeleteUser in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/removeFromPulse?companyUserId=' + companyUserId + '&tenantUserId=' + tenantUserId, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async AddToPulse(companyUserId: string, tenantId: string, requestResponse?: any) {
    console.info('Call /addToPulse in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/addToPulse?companyUserId=' + companyUserId + '&tenantId=' + tenantId, requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async Reactivate(companyUserId: string, emailId: string, reactivateUser: boolean) {
    console.info('Call /reactivate in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/reactivate?companyUserId=' + companyUserId + '&emailId=' + emailId + '&reactivateUser=' + reactivateUser, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async SendConfirmation(tenantUserId: string) {
    console.info('Call /sendConfirmation in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/sendConfirmation?tenantUserId=' + tenantUserId, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async ResetPassword(tenantUserId: string) {
    console.info('Call /restPassword in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/resetPassword?isReset=true&tenantUserId=' + tenantUserId, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async ResetTwoFactor(tenantUserId: string) {
    console.info('Call /resetTwoFactor in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/resetTwoFactor?isTFA=true&tenantUserId=' + tenantUserId, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async ChangeUserEmail(tenantUserId: string, newEmailAddress: string, losUser: string, companyUserId: string) {
    console.info('Call /ChangeUserEmail in the webapi userManagement Controller');
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/changeEmail?tenantUserId=' + tenantUserId + '&newEmailId=' + newEmailAddress +
                                                                '&losUser=' + losUser + '&companyUserId=' + companyUserId, null)      
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetAuditLogReportUsers() {
    return await this.http
      .post<any>(AppSettings.API_GET_PULSE_USERS + '/auditLogUserActivityReportFile', null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async RemoveUsers(requestResponse?: any) {    
    console.info('Call /Update in the webapi remove user userManagement Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_PULSE_USERS + '/removeUserList', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async EditCompanyUser(requestResponse?: any) {
    console.info('Call /editcompanyuser in the webapi userManagement Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_PULSE_USERS + '/editcompanyuser', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Error processing.
   *
   * @param caught The caught error information.
   */
  private handleError(caught: any) {
    console.error(caught?.error?.message);
    return throwError(caught?.error?.message ?? 'Error while connecting to server!');
  }
}
