<div class="container-section book-time-text">
  <div class="container-size mt-3">
    <div class="logo text-center">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
            style="width:91.63%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-auto d-flex justify-content-center flex-column text-center permission-text cal-outer ">
    <h5>Finally let's book a time with our product specialist to finish your setup.</h5>
    <h6 class="dateselect">Select a Date & Time</h6>
    <h6>{{ showFullDate }}</h6>

    <div class="row">
      <div class="col mt-3">
        <p-calendar class="max-w-full" [(ngModel)]="date" [inline]="true" [showWeek]="true"
          (onSelect)="onDateSelect($event)"></p-calendar>
        <div class="mt-3">
          <p-dropdown [options]="timezoneList" optionLabel="name" [filter]="true" filterBy="name" [ngModel]="timezoneId"
            (onChange)="onTimezoneChange($event)" [showClear]="true" placeholder="Select Timezone" optionValue="code">
          </p-dropdown>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="btn-group d-block btn-group-toggle justify-content-center" data-toggle="buttons">
          <label class="btn btn-primary radiobtn-custom-cal" [class.active]="selectedItem === 1">
            <input type="radio" name="options" id="option1" (change)="actionContent($event, 'Radio1')"> 9:30am
          </label>
          <label class="btn btn-primary radiobtn-custom-cal" [class.active]="selectedItem === 2">
            <input type="radio" name="options" id="option2" (change)="actionContent($event, 'Radio2')"> 10:00am
          </label>
          <label class="btn btn-primary radiobtn-custom-cal" [class.active]="selectedItem === 3">
            <input type="radio" name="options" id="option1" (change)="actionContent($event, 'Radio3')"> 10:30am
          </label>
          <label class="btn btn-primary radiobtn-custom-cal" [class.active]="selectedItem === 4">
            <input type="radio" name="options" id="option2" (change)="actionContent($event, 'Radio4')"> 2:30pm
          </label>
          <label class="btn btn-primary radiobtn-custom-cal" [class.active]="selectedItem === 5">
            <input type="radio" name="options" id="option1" (change)="actionContent($event, 'Radio5')"> 3:00pm
          </label>
          <label class="btn btn-primary radiobtn-custom-cal" [class.active]="selectedItem === 6">
            <input type="radio" name="options" id="option2" (change)="actionContent($event, 'Radio6')"> 3:30pm
          </label>
        </div>
      </div>
    </div>
  </div>

  <button pButton pRipple class="p-button p-button-rounded float-center mt-2" (click)="redirectToCTAScreen()">Save Date
    &amp; Time</button>

  <button (click)="redirectToCTAScreenDirectly()" class="btn-skip ml-4">Skip this step, I'll do it myself</button>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>