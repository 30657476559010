import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettings } from 'src/appSettings';
import { SearchModel } from 'src/components/custom-grid/models/search-model';
import { RequestResponse } from 'src/models/RequestResponse';

@Injectable()
export class BillingService {
  constructor(private http: HttpClient) {
  }

  GetAllTenants(searchModel: SearchModel){

    let params = new HttpParams();

    Object.keys(searchModel).forEach(function (key) {
      if (searchModel[key] != null) {
        params = params.append(key, searchModel[key]);
      }
    });

    return this.http.post<RequestResponse>(AppSettings.API_GET_BILLING + '/getalltenants',{
      pageNum: searchModel.pageNum,
      pageSize: searchModel.pageSize
    })
    .pipe(catchError(this.handleError))
    .toPromise();
  }

  GetAllTenantsByFilter(searchModel: SearchModel){

    let params = new HttpParams();

    Object.keys(searchModel).forEach(function (key) {
      if (searchModel[key] != null) {
        params = params.append(key, searchModel[key]);
      }
    });

    return this.http.post<RequestResponse>(AppSettings.API_GET_BILLING + '/getalltenantsbyfilters',{
      pageNum: searchModel.pageNum,
      pageSize: searchModel.pageSize,
      filterStr: searchModel.filterStr,
    })
    .pipe(catchError(this.handleError))
    .toPromise();
  }

  GetAllTenantsByFilterValues(){

    return this.http.get<RequestResponse>(AppSettings.API_GET_BILLING + '/getalltenantsbyfiltersvalues')
    .pipe(catchError(this.handleError))
    .toPromise();
  }

  async GetPremiumUserBillingDetails() {
    console.info('Call /getpremiumuserbillingdetails in the webapi billingController Controller');
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/getpremiumuserbillingdetails')
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  GetSubscriptionType() {
    console.info('Call /subscriptionType in the webapi billingController Controller');
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/getsubscriptiontype')
      .pipe(catchError(this.handleError));
  }

  GetBillingOwner() {
    console.info('Call /subscriptionType in the webapi billingController Controller');
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/getbillingowner')
      .pipe(catchError(this.handleError));
  }

  Getusersubscriptiondetail(tenantUserId) {
    console.info('Call /subscriptionType in the webapi billingController Controller');
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/getusersubscriptiondetail?TenantUserId=' + tenantUserId)
      .pipe(catchError(this.handleError));
  }

  updateSubscription(requestResponse?: any) {
    if (!requestResponse)
      requestResponse = new RequestResponse();

    console.info('Call /Update in the webapi TenantUser Controller');
    return this.http
      .post<RequestResponse>(AppSettings.API_GET_BILLING + '/updatesubscription', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  resetPasswords(requestResponse?: any) {
    if (!requestResponse)
      requestResponse = new RequestResponse();

    console.info('Call /Update in the webapi reset password Controller');
    return this.http
      .post<RequestResponse>(AppSettings.API_GET_BILLING + '/resetPassword', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async GetAllUsers(requestResponse?: RequestResponse) {
    if (requestResponse === undefined || requestResponse === null) {
      requestResponse = new RequestResponse();
    }
    let searchModel = {
      pageNum: requestResponse.paging.currentPage - 1,
      pageSize: requestResponse.paging.pageSize,
      searchValue: requestResponse.data?.length > 0 ? requestResponse.data : '',
      orderBy: requestResponse.paging.orderBy,
    };

    return await this.http
      .post<RequestResponse>(AppSettings.API_GET_BILLING + '/allUsersList', searchModel)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  UpdatePassword(requestResponse: any) {
    if (!requestResponse)
      requestResponse = new RequestResponse();

    console.info('Call /Update in the webapi reset password Controller');
    return this.http
      .put<RequestResponse>(AppSettings.API_GET_BILLING + '/changepassword', requestResponse)
      .pipe(catchError(this.handleError))
  }

  saveAdditionalDetails(requestResponse: any) {
    if (!requestResponse)
      requestResponse = new RequestResponse();

    console.info('Call /Update in the webapi reset password Controller');
    return this.http
      .post<RequestResponse>(AppSettings.API_GET_BILLING + '/savetenantuseradditionaldetail', requestResponse)
      .pipe(catchError(this.handleError))
  }

  GetAdditionalDetails(tenantUserId) {
    console.info('Call /subscriptionType in the webapi billingController Controller');
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/gettenantuseradditionaldetail?TenantUserId=' + tenantUserId)
      .pipe(catchError(this.handleError));
  }

  GetCompanyAdditionalDetail() {
    console.info('Call /subscriptionType in the webapi billingController Controller');
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/getcompanyadditionaldetail')
      .pipe(catchError(this.handleError));
  }

  saveCompanyAdditionalDetails(requestResponse: any) {
    if (!requestResponse)
      requestResponse = new RequestResponse();

    console.info('Call /Update in the webapi reset password Controller');
    return this.http
      .post<RequestResponse>(AppSettings.API_GET_BILLING + '/savecompanyadditionaldetail', requestResponse)
      .pipe(catchError(this.handleError))
  }

  GetTenantById(tenantId: string) {
    return this.http
      .get<RequestResponse>(AppSettings.API_GET_BILLING + '/Tenant/Edit/' + tenantId)
      .pipe(catchError(this.handleError));
  }

  PutTenant(tenantId: string, data: any) {
    return this.http
      .put<RequestResponse>(AppSettings.API_GET_BILLING + '/Tenant/Edit/' + tenantId,data)
      .pipe(catchError(this.handleError));
  }

 async SaveonboardingCalendarDetail(data: any) {
    console.info('Call /Post in the webapi save date and time Controller');
    return await this.http
      .post<RequestResponse>(AppSettings.API_GET_BILLING + '/saveonboardingcalendardetail', data)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async SaveCompanySMSSetting(smsSetting: boolean) {
    console.info('Call /updatecompanysmssetting in the webapi billing Controller');
    return await this.http.put<any>(AppSettings.API_GET_BILLING + '/updatecompanysmssetting?smsSetting=' + smsSetting, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async SaveCompanySMSBranding(smsBranding: boolean) {
    console.info('Call /updatecompanysmssetting in the webapi billing Controller');
    return await this.http.put<any>(AppSettings.API_GET_BILLING + '/updatecompanysmsbranding?smsBranding=' + smsBranding, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async SaveCompanyOnBoarding(onBoarding: boolean) {
    console.info('Call /updatecompanyonboarding in the webapi billing Controller');
    return await this.http.put<any>(AppSettings.API_GET_BILLING + '/updatecompanyonboarding?onBoarding=' + onBoarding, null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async AddCompany(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Billing Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_BILLING + '/createtenant', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async EditCompany(requestResponse?: any) {
    if (requestResponse === undefined || requestResponse === null)
      requestResponse = new RequestResponse();
    console.info('Call /Save in the webapi Billing Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_BILLING + '/updatetenant', requestResponse)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async CreateStripeCustomer() {
    console.info('Call /create stripe customer in the webapi stripe Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_STRIPE + '/createstripecustomer', null)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async CreateStripeTenantUserCustomer(data: any) {
    console.info('Call /create stripe tenant user customer in the webapi stripe Controller');
    return await this.http.post<RequestResponse>(AppSettings.API_GET_STRIPE + '/createstripetenantusercustomer', data)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  GetGetStripeConfigDetail() {
    console.info('Call /GetStripeConfigDetail in the webapi stripeController');
    return this.http.get<RequestResponse>(AppSettings.API_GET_STRIPE + '/getstripeconfigdetail')
      .pipe(catchError(this.handleError));
  }

  async DeleteTenantUsersFromBillingOwner(data: any) {
    console.info('Call /DeleteTenantUsersFromBillingOwner in the webapi billing Controller');
    return await this.http.post<any>(AppSettings.API_GET_BILLING + '/deletetenantusersfrombillingowner', data)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  /**
   * Error processing.
   *
   * @param caught The caught error information.
   */
  private handleError(caught: any) {
    console.error(caught?.error?.message);
    return throwError(caught?.error?.message ?? 'Error while connecting to server!');
  }
}
