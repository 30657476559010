<!--
*************************************************************************************
Image Preview Dialog
*************************************************************************************
-->
<div>
  <!-- <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
  <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div>
  <div style="height:.85em;"></div> -->

  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12" id="imagePreview">
      <table width="100%">
        <tr>
          <td align="center">
            <img #avatarImage [src]="(imageSrc)" [hidden]="(!selectedImage.Avatar)" [style]="{'min-width':'14rem', 'min-height':'14rem', 'max-width':'14rem', 'max-height':'14rem', 'border-radius':'50%', 'object-fit':'cover'}" />
            <img #rectangleImage [src]="(imageSrc)" [hidden]="(selectedImage.Avatar)" [style]="{'min-width':'2rem','min-height':'2rem', 'width':'350px', 'object-fit':'contain'}" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
