/** 
* Implementation of the FocusIT.DataContracts.Pulse.NGENMasterRecord [DataContract].
*/
export class NGENMasterRecord {

  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public LoanId: string = '00000000-0000-0000-0000-000000000000';
  public PulseCreatedDateUTC: Date = null;
  public PulseLastModifiedDateUTC: Date = null;
  public LoanNumber: string = '';
  public LoanOriginationSystem: number = 0;
  public CreatedDate: Date = null;
  public LastModifiedDate: Date = null;
  public LoanStatusId: number = null;
  public LoanStatus: string = '';
  public LoanStatusDate: Date = null;
  public BaseLoanAmount: number = 0.0;
  public TotalLoanAmount: number = 0.0;
  public Term: number = null;
  public NoteRate: number = null;
  public DownPaymentAmount: number = 0.0;
  public DownPaymentPercentage: number = 0.0;
  public LockTerm: number = null;
  public LoanEstimateType: string = '';
  public LoanProgram: string = '';
  public ClosingCostScenarioName: string = '';
  public BorrowerFirstName: string = '';
  public BorrowerLastName: string = '';
  public BorrowerMiddleName: string = '';
  public BorrowerNameSuffix: string = '';
  public BorrowerNickname: string = '';
  public BorrowerBirthDate: Date = null;
  public BorrowerSSN: string = '';
  public BorrowerEquifaxCreditScore: number = 0;
  public BorrowerExperianCreditScore: number = 0;
  public BorrowerMinimumFICOScore: number = 0;
  public BorrowerTransUnionCreditScore: number = 0;
  public BorrowerEmail: string = '';
  public BorrowerMobilePhone: string = '';
  public BorrowerWorkPhone: string = '';
  public BorrowerFax: string = '';
  public BorrowerHomePhone: string = '';
  public BorrowerStreet: string = '';
  public BorrowerCity: string = '';
  public BorrowerState: string = '';
  public BorrowerZip: string = '';
  public BorrowerOwns: boolean = false;
  public BorrowerRents: boolean = false;
  public BorrowerHash: string = '';
  public CoBorrowerFirstName: string = '';
  public CoBorrowerLastName: string = '';
  public CoBorrowerMiddleName: string = '';
  public CoBorrowerNameSuffix: string = '';
  public CoBorrowerNickname: string = '';
  public CoBorrowerBirthDate: Date = null;
  public CoBorrowerSSN: string = '';
  public CoBorrowerEquifaxCreditScore: number = 0;
  public CoBorrowerExperianCreditScore: number = 0;
  public CoBorrowerMinimumFICOScore: number = 0;
  public CoBorrowerTransUnionCreditScore: number = 0;
  public CoBorrowerEmail: string = '';
  public CoBorrowerMobilePhone: string = '';
  public CoBorrowerWorkPhone: string = '';
  public CoBorrowerFax: string = '';
  public CoBorrowerHomePhone: string = '';
  public CoBorrowerStreet: string = '';
  public CoBorrowerCity: string = '';
  public CoBorrowerState: string = '';
  public CoBorrowerZip: string = '';
  public CoBorrowerOwns: boolean = false;
  public CoBorrowerRents: boolean = false;
  public CoBorrowerHash: string = '';
  public AppraisedValue: number = 0.0;
  public PurchasePrice: number = 0.0;
  public SubjectPropertyStreet: string = '';
  public SubjectPropertyCity: string = '';
  public SubjectPropertyCounty: string = '';
  public SubjectPropertyState: string = '';
  public SubjectPropertyZip: string = '';
  public SubjectPropertyTypeId: number = null;
  public SubjectPropertyTypeDesc: string = '';
  public LoanOfficerName: string = '';
  public LoanOfficerFirstName: string = '';
  public LoanOfficerLastName: string = '';
  public LoanOfficerEmail: string = '';
  public LoanOfficerMobilePhone: string = '';
  public LoanOfficerWorkPhone: string = '';
  public LoanOfficerFax: string = '';
  public LoanOfficerNmlsId: string = '';
  public LoanOfficerHash: string = '';
  public LoanProcessorName: string = '';
  public LoanProcessorFirstName: string = '';
  public LoanProcessorLastName: string = '';
  public LoanProcessorEmail: string = '';
  public LoanProcessorMobilePhone: string = '';
  public LoanProcessorWorkPhone: string = '';
  public LoanProcessorFax: string = '';
  public LoanProcessorHash: string = '';
  public SellersAgentName: string = '';
  public SellersAgentFirstName: string = '';
  public SellersAgentLastName: string = '';
  public SellersAgentCompany: string = '';
  public SellersAgentStreet: string = '';
  public SellersAgentCity: string = '';
  public SellersAgentState: string = '';
  public SellersAgentZip: string = '';
  public SellersAgentEmail: string = '';
  public SellersAgentMobilePhone: string = '';
  public SellersAgentWorkPhone: string = '';
  public SellersAgentFax: string = '';
  public SellersAgentHash: string = '';
  public BuyersAgentName: string = '';
  public BuyersAgentFirstName: string = '';
  public BuyersAgentLastName: string = '';
  public BuyersAgentCompany: string = '';
  public BuyersAgentStreet: string = '';
  public BuyersAgentCity: string = '';
  public BuyersAgentState: string = '';
  public BuyersAgentZip: string = '';
  public BuyersAgentEmail: string = '';
  public BuyersAgentMobilePhone: string = '';
  public BuyersAgentWorkPhone: string = '';
  public BuyersAgentFax: string = '';
  public BuyersAgentHash: string = '';
  public ClosingAgentName: string = '';
  public ClosingAgentFirstName: string = '';
  public ClosingAgentLastName: string = '';
  public ClosingAgentCompany: string = '';
  public ClosingAgentStreet: string = '';
  public ClosingAgentCity: string = '';
  public ClosingAgentState: string = '';
  public ClosingAgentZip: string = '';
  public ClosingAgentEmail: string = '';
  public ClosingAgentMobilePhone: string = '';
  public ClosingAgentWorkPhone: string = '';
  public ClosingAgentFax: string = '';
  public ClosingAgentHash: string = '';
  public ShipperName: string = '';
  public ShipperFirstName: string = '';
  public ShipperLastName: string = '';
  public ShipperEmail: string = '';
  public ShipperMobilePhone: string = '';
  public ShipperWorkPhone: string = '';
  public ShipperFax: string = '';
  public ShipperHash: string = '';
  public UnderwriterName: string = '';
  public UnderwriterFirstName: string = '';
  public UnderwriterLastName: string = '';
  public UnderwriterEmail: string = '';
  public UnderwriterMobilePhone: string = '';
  public UnderwriterWorkPhone: string = '';
  public UnderwriterFax: string = '';
  public UnderwriterHash: string = '';
  public DocPreparerName: string = '';
  public DocPreparerFirstName: string = '';
  public DocPreparerLastName: string = '';
  public DocPreparerEmail: string = '';
  public DocPreparerMobilePhone: string = '';
  public DocPreparerWorkPhone: string = '';
  public DocPreparerFax: string = '';
  public DocPreparerHash: string = '';
  public ProspectStatusDate: Date = null;
  public LoanSubmittedDate: Date = null;
  public LoanSuspensionDate: Date = null;
  public DocsBackDate: Date = null;
  public DocsOrderedDate: Date = null;
  public DocsSentDate: Date = null;
  public FloodCertificationOrdered: Date = null;
  public FloodCertificationReceived: Date = null;
  public HMDACompletedStatusDate: Date = null;
  public LoanApplicationDate: Date = null;
  public LoanApprovedDate: Date = null;
  public LoanCancelledDate: Date = null;
  public LoanClosedDate: Date = null;
  public LoanDeniedDate: Date = null;
  public LoanDocsDrawnDate: Date = null;
  public LoanFundedDate: Date = null;
  public PreliminaryReportOrderedDate: Date = null;
  public PreliminaryReportReceivedDate: Date = null;
  public ResubmitStatusDate: Date = null;
  public SentToProcessing: Date = null;
  public TILDisclosure_GFEOrderedDate: Date = null;
  public TILDisclosure_GFEReceivedDate: Date = null;
  public AppraisalReportOrderedDate: Date = null;
  public AppraisalReportReceivedDate: Date = null;
  public ClosingDocumentsOrderedDate: Date = null;
  public ClosingDocumentsReceivedDate: Date = null;
  public ClearToCloseDate: Date = null;
  public LoanRecordedDate: Date = null;
  public CDDateIssued: Date = null;
  public RateLockDate: Date = null;
  public RateLockExpirationDate: Date = null;
  public RateLockConfirmedDate: Date = null;
  public EstimatedClosingDate: Date = null;
  public FirstPaymentDate: Date = null;
  public FundByDate: Date = null;
  public LoanTypeId: number = null;
  public LoanTypeDesc: string = '';
  public OccupancyTypeId: number = null;
  public OccupancyTypeDesc: string = '';
  public OccupancyTypeCode: string = '';
  public LoanPurposeId: number = null;
  public LoanPurposeDesc: string = '';
  public LoanPurposeCode: string = '';
  public LenderCompanyName: string = '';
  public LenderName: string = '';
  public LenderFirstName: string = '';
  public LenderLastName: string = '';
  public LenderEmail: string = '';
  public LenderWorkPhone: string = '';
  public LenderFax: string = '';
  public LenderHash: string = '';
  public OriginatingOrganizationName: string = '';
  public OriginatingOrganizationNmlsId: string = '';
  public OriginatingOrganizationStreet: string = '';
  public OriginatingOrganizationCity: string = '';
  public OriginatingOrganizationState: string = '';
  public OriginatingOrganizationZip: string = '';
  public OriginatorName: string = '';
  public OriginatorEmail: string = '';
  public OriginatorPhone: string = '';
  public OriginatorNmlsId: string = '';
  public OriginatorHash: string = '';
  public ConversationLog: string = '';
  public AppraisalCompanyAgentName: string = '';
  public AppraisalCompanyAgentFirstName: string = '';
  public AppraisalCompanyAgentLastName: string = '';
  public AppraisalCompany: string = '';
  public AppraisalCompanyStreet: string = '';
  public AppraisalCompanyCity: string = '';
  public AppraisalCompanyState: string = '';
  public AppraisalCompanyZip: string = '';
  public AppraisalCompanyEmail: string = '';
  public AppraisalCompanyMobilePhone: string = '';
  public AppraisalCompanyWorkPhone: string = '';
  public AppraisalCompanyFax: string = '';
  public AppraisalCompanyHash: string = '';
  public FloodInsuranceCompanyAgentName: string = '';
  public FloodInsuranceCompanyAgentFirstName: string = '';
  public FloodInsuranceCompanyAgentLastName: string = '';
  public FloodInsuranceCompany: string = '';
  public FloodInsuranceCompanyStreet: string = '';
  public FloodInsuranceCompanyCity: string = '';
  public FloodInsuranceCompanyState: string = '';
  public FloodInsuranceCompanyZip: string = '';
  public FloodInsuranceCompanyEmail: string = '';
  public FloodInsuranceCompanyMobilePhone: string = '';
  public FloodInsuranceCompanyWorkPhone: string = '';
  public FloodInsuranceCompanyFax: string = '';
  public FloodInsuranceCompanyHash: string = '';
  public HazardInsuranceCompanyAgentName: string = '';
  public HazardInsuranceCompanyAgentFirstName: string = '';
  public HazardInsuranceCompanyAgentLastName: string = '';
  public HazardInsuranceCompany: string = '';
  public HazardInsuranceCompanyStreet: string = '';
  public HazardInsuranceCompanyCity: string = '';
  public HazardInsuranceCompanyState: string = '';
  public HazardInsuranceCompanyZip: string = '';
  public HazardInsuranceCompanyEmail: string = '';
  public HazardInsuranceCompanyMobilePhone: string = '';
  public HazardInsuranceCompanyWorkPhone: string = '';
  public HazardInsuranceCompanyFax: string = '';
  public HazardInsuranceCompanyHash: string = '';
  public TitleCompanyAgentName: string = '';
  public TitleCompanyAgentFirstName: string = '';
  public TitleCompanyAgentLastName: string = '';
  public TitleCompany: string = '';
  public TitleCompanyStreet: string = '';
  public TitleCompanyCity: string = '';
  public TitleCompanyState: string = '';
  public TitleCompanyZip: string = '';
  public TitleCompanyEmail: string = '';
  public TitleCompanyMobilePhone: string = '';
  public TitleCompanyWorkPhone: string = '';
  public TitleCompanyFax: string = '';
  public TitleCompanyHash: string = '';

  public BorrowerName: string = '';
  public CoBorrowerName: string = '';

  public LoanOfficerAssistantName: string = '';
  public LoanOfficerAssistantFirstName: string = '';
  public LoanOfficerAssistantLastName: string = '';
  public LoanOfficerAssistantEmail: string = '';
  public LoanOfficerAssistantMobilePhone: string = '';
  public LoanOfficerAssistantWorkPhone: string = '';
  public LoanOfficerAssistantFax: string = '';
  public LoanOfficerAssistantHash: string = '';

  public DisplayLoanPurposeDesc: string = '';
  public DisplayPurchasePrice: string = '';
  public DisplayLoanAmount: string = '';
  public DisplayNoteRate: string = '';
  public DisplayBaseLoanAmount: string = '';
  public DisplayTotalLoanAmount: string = '';
  public DisplayDownPaymentAmount: string = '';
  public DisplayDownPaymentPercentage: string = '';

  public DisplayPulseCreatedDateUTC: string = '';
  public DisplayPulseLastModifiedDateUTC: string = '';
  public DisplayCreatedDate: string = '';
  public DisplayLastModifiedDate: string = '';
  public DisplayLoanStatusDate: string = '';
  public DisplayBorrowerBirthDate: string = '';
  public DisplayCoBorrowerBirthDate: string = '';
  public DisplayProspectStatusDate: string = '';
  public DisplayLoanSubmittedDate: string = '';
  public DisplayLoanSuspensionDate: string = '';
  public DisplayDocsBackDate: string = '';
  public DisplayDocsOrderedDate: string = '';
  public DisplayDocsSentDate: string = '';
  public DisplayFloodCertificationOrdered: string = '';
  public DisplayFloodCertificationReceived: string = '';
  public DisplayHMDACompletedStatusDate: string = '';
  public DisplayLoanApplicationDate: string = '';
  public DisplayLoanApprovedDate: string = '';
  public DisplayLoanCancelledDate: string = '';
  public DisplayLoanClosedDate: string = '';
  public DisplayLoanDeniedDate: string = '';
  public DisplayLoanDocsDrawnDate: string = '';
  public DisplayLoanFundedDate: string = '';
  public DisplayPreliminaryReportOrderedDate: string = '';
  public DisplayPreliminaryReportReceivedDate: string = '';
  public DisplayResubmitStatusDate: string = '';
  public DisplaySentToProcessing: string = '';
  public DisplayTILDisclosure_GFEOrderedDate: string = '';
  public DisplayTILDisclosure_GFEReceivedDate: string = '';
  public DisplayAppraisalReportOrderedDate: string = '';
  public DisplayAppraisalReportReceivedDate: string = '';
  public DisplayClosingDocumentsOrderedDate: string = '';
  public DisplayClosingDocumentsReceivedDate: string = '';
  public DisplayClearToCloseDate: string = '';
  public DisplayLoanRecordedDate: string = '';
  public DisplayCDDateIssued: string = '';
  public DisplayRateLockDate: string = '';
  public DisplayRateLockExpirationDate: string = '';
  public DisplayRateLockConfirmedDate: string = '';
  public DisplayEstimatedClosingDate: string = '';
  public DisplayFirstPaymentDate: string = '';
  public DisplayFundByDate: string = '';


  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param TenantId
  * @param LoanId
  * @param PulseCreatedDateUTC
  * @param PulseLastModifiedDateUTC
  * @param LoanNumber
  * @param LoanOriginationSystem
  * @param CreatedDate
  * @param LastModifiedDate
  * @param LoanStatusId
  * @param LoanStatus
  * @param LoanStatusDate
  * @param BaseLoanAmount
  * @param TotalLoanAmount
  * @param Term
  * @param NoteRate
  * @param DownPaymentAmount
  * @param DownPaymentPercentage
  * @param LockTerm
  * @param LoanEstimateType
  * @param LoanProgram
  * @param ClosingCostScenarioName
  * @param BorrowerFirstName
  * @param BorrowerLastName
  * @param BorrowerMiddleName
  * @param BorrowerNameSuffix
  * @param BorrowerNickname
  * @param BorrowerBirthDate
  * @param BorrowerSSN
  * @param BorrowerEquifaxCreditScore
  * @param BorrowerExperianCreditScore
  * @param BorrowerMinimumFICOScore
  * @param BorrowerTransUnionCreditScore
  * @param BorrowerEmail
  * @param BorrowerMobilePhone
  * @param BorrowerWorkPhone
  * @param BorrowerFax
  * @param BorrowerHomePhone
  * @param BorrowerStreet
  * @param BorrowerCity
  * @param BorrowerState
  * @param BorrowerZip
  * @param BorrowerOwns
  * @param BorrowerRents
  * @param BorrowerHash
  * @param CoBorrowerFirstName
  * @param CoBorrowerLastName
  * @param CoBorrowerMiddleName
  * @param CoBorrowerNameSuffix
  * @param CoBorrowerNickname
  * @param CoBorrowerBirthDate
  * @param CoBorrowerSSN
  * @param CoBorrowerEquifaxCreditScore
  * @param CoBorrowerExperianCreditScore
  * @param CoBorrowerMinimumFICOScore
  * @param CoBorrowerTransUnionCreditScore
  * @param CoBorrowerEmail
  * @param CoBorrowerMobilePhone
  * @param CoBorrowerWorkPhone
  * @param CoBorrowerFax
  * @param CoBorrowerHomePhone
  * @param CoBorrowerStreet
  * @param CoBorrowerCity
  * @param CoBorrowerState
  * @param CoBorrowerZip
  * @param CoBorrowerOwns
  * @param CoBorrowerRents
  * @param CoBorrowerHash
  * @param AppraisedValue
  * @param PurchasePrice
  * @param SubjectPropertyStreet
  * @param SubjectPropertyCity
  * @param SubjectPropertyCounty
  * @param SubjectPropertyState
  * @param SubjectPropertyZip
  * @param SubjectPropertyTypeId
  * @param SubjectPropertyTypeDesc
  * @param LoanOfficerName
  * @param LoanOfficerFirstName
  * @param LoanOfficerLastName
  * @param LoanOfficerEmail
  * @param LoanOfficerMobilePhone
  * @param LoanOfficerWorkPhone
  * @param LoanOfficerFax
  * @param LoanOfficerNmlsId
  * @param LoanOfficerHash
  * @param LoanProcessorName
  * @param LoanProcessorFirstName
  * @param LoanProcessorLastName
  * @param LoanProcessorEmail
  * @param LoanProcessorMobilePhone
  * @param LoanProcessorWorkPhone
  * @param LoanProcessorFax
  * @param LoanProcessorHash
  * @param SellersAgentName
  * @param SellersAgentFirstName
  * @param SellersAgentLastName
  * @param SellersAgentCompany
  * @param SellersAgentStreet
  * @param SellersAgentCity
  * @param SellersAgentState
  * @param SellersAgentZip
  * @param SellersAgentEmail
  * @param SellersAgentMobilePhone
  * @param SellersAgentWorkPhone
  * @param SellersAgentFax
  * @param SellersAgentHash
  * @param BuyersAgentName
  * @param BuyersAgentFirstName
  * @param BuyersAgentLastName
  * @param BuyersAgentCompany
  * @param BuyersAgentStreet
  * @param BuyersAgentCity
  * @param BuyersAgentState
  * @param BuyersAgentZip
  * @param BuyersAgentEmail
  * @param BuyersAgentMobilePhone
  * @param BuyersAgentWorkPhone
  * @param BuyersAgentFax
  * @param BuyersAgentHash
  * @param ClosingAgentName
  * @param ClosingAgentFirstName
  * @param ClosingAgentLastName
  * @param ClosingAgentCompany
  * @param ClosingAgentStreet
  * @param ClosingAgentCity
  * @param ClosingAgentState
  * @param ClosingAgentZip
  * @param ClosingAgentEmail
  * @param ClosingAgentMobilePhone
  * @param ClosingAgentWorkPhone
  * @param ClosingAgentFax
  * @param ClosingAgentHash
  * @param ShipperName
  * @param ShipperFirstName
  * @param ShipperLastName
  * @param ShipperEmail
  * @param ShipperMobilePhone
  * @param ShipperWorkPhone
  * @param ShipperFax
  * @param ShipperHash
  * @param UnderwriterName
  * @param UnderwriterFirstName
  * @param UnderwriterLastName
  * @param UnderwriterEmail
  * @param UnderwriterMobilePhone
  * @param UnderwriterWorkPhone
  * @param UnderwriterFax
  * @param UnderwriterHash
  * @param DocPreparerName
  * @param DocPreparerFirstName
  * @param DocPreparerLastName
  * @param DocPreparerEmail
  * @param DocPreparerMobilePhone
  * @param DocPreparerWorkPhone
  * @param DocPreparerFax
  * @param DocPreparerHash
  * @param ProspectStatusDate
  * @param LoanSubmittedDate
  * @param LoanSuspensionDate
  * @param DocsBackDate
  * @param DocsOrderedDate
  * @param DocsSentDate
  * @param FloodCertificationOrdered
  * @param FloodCertificationReceived
  * @param HMDACompletedStatusDate
  * @param LoanApplicationDate
  * @param LoanApprovedDate
  * @param LoanCancelledDate
  * @param LoanClosedDate
  * @param LoanDeniedDate
  * @param LoanDocsDrawnDate
  * @param LoanFundedDate
  * @param PreliminaryReportOrderedDate
  * @param PreliminaryReportReceivedDate
  * @param ResubmitStatusDate
  * @param SentToProcessing
  * @param TILDisclosure_GFEOrderedDate
  * @param TILDisclosure_GFEReceivedDate
  * @param AppraisalReportOrderedDate
  * @param AppraisalReportReceivedDate
  * @param ClosingDocumentsOrderedDate
  * @param ClosingDocumentsReceivedDate
  * @param ClearToCloseDate
  * @param LoanRecordedDate
  * @param CDDateIssued
  * @param RateLockDate
  * @param RateLockExpirationDate
  * @param RateLockConfirmedDate
  * @param EstimatedClosingDate
  * @param FirstPaymentDate
  * @param FundByDate
  * @param LoanTypeId
  * @param LoanTypeDesc
  * @param OccupancyTypeId
  * @param OccupancyTypeDesc
  * @param OccupancyTypeCode
  * @param LoanPurposeId
  * @param LoanPurposeDesc
  * @param LoanPurposeCode
  * @param LenderCompanyName
  * @param LenderName
  * @param LenderFirstName
  * @param LenderLastName
  * @param LenderEmail
  * @param LenderWorkPhone
  * @param LenderFax
  * @param LenderHash
  * @param OriginatingOrganizationName
  * @param OriginatingOrganizationNmlsId
  * @param OriginatingOrganizationStreet
  * @param OriginatingOrganizationCity
  * @param OriginatingOrganizationState
  * @param OriginatingOrganizationZip
  * @param OriginatorName
  * @param OriginatorEmail
  * @param OriginatorPhone
  * @param OriginatorNmlsId
  * @param OriginatorHash
  * @param ConversationLog
  * @param AppraisalCompanyAgentName
  * @param AppraisalCompanyAgentFirstName
  * @param AppraisalCompanyAgentLastName
  * @param AppraisalCompany
  * @param AppraisalCompanyStreet
  * @param AppraisalCompanyCity
  * @param AppraisalCompanyState
  * @param AppraisalCompanyZip
  * @param AppraisalCompanyEmail
  * @param AppraisalCompanyMobilePhone
  * @param AppraisalCompanyWorkPhone
  * @param AppraisalCompanyFax
  * @param AppraisalCompanyHash
  * @param FloodInsuranceCompanyAgentName
  * @param FloodInsuranceCompanyAgentFirstName
  * @param FloodInsuranceCompanyAgentLastName
  * @param FloodInsuranceCompany
  * @param FloodInsuranceCompanyStreet
  * @param FloodInsuranceCompanyCity
  * @param FloodInsuranceCompanyState
  * @param FloodInsuranceCompanyZip
  * @param FloodInsuranceCompanyEmail
  * @param FloodInsuranceCompanyMobilePhone
  * @param FloodInsuranceCompanyWorkPhone
  * @param FloodInsuranceCompanyFax
  * @param FloodInsuranceCompanyHash
  * @param HazardInsuranceCompanyAgentName
  * @param HazardInsuranceCompanyAgentFirstName
  * @param HazardInsuranceCompanyAgentLastName
  * @param HazardInsuranceCompany
  * @param HazardInsuranceCompanyStreet
  * @param HazardInsuranceCompanyCity
  * @param HazardInsuranceCompanyState
  * @param HazardInsuranceCompanyZip
  * @param HazardInsuranceCompanyEmail
  * @param HazardInsuranceCompanyMobilePhone
  * @param HazardInsuranceCompanyWorkPhone
  * @param HazardInsuranceCompanyFax
  * @param HazardInsuranceCompanyHash
  * @param TitleCompanyAgentName
  * @param TitleCompanyAgentFirstName
  * @param TitleCompanyAgentLastName
  * @param TitleCompany
  * @param TitleCompanyStreet
  * @param TitleCompanyCity
  * @param TitleCompanyState
  * @param TitleCompanyZip
  * @param TitleCompanyEmail
  * @param TitleCompanyMobilePhone
  * @param TitleCompanyWorkPhone
  * @param TitleCompanyFax
  * @param TitleCompanyHash
  * @param BorrowerName
  * @param CoBorrowerName
  * @param LoanOfficerAssistantName
  * @param LoanOfficerAssistantFirstName
  * @param LoanOfficerAssistantLastName
  * @param LoanOfficerAssistantEmail
  * @param LoanOfficerAssistantMobilePhone
  * @param LoanOfficerAssistantWorkPhone
  * @param LoanOfficerAssistantFax
  * @param LoanOfficerAssistantHash) {
  */
  constructor(
    TenantId?: string,
    LoanId?: string,
    PulseCreatedDateUTC?: Date,
    PulseLastModifiedDateUTC?: Date,
    LoanNumber?: string,
    LoanOriginationSystem?: number,
    CreatedDate?: Date,
    LastModifiedDate?: Date,
    LoanStatusId?: number,
    LoanStatus?: string,
    LoanStatusDate?: Date,
    BaseLoanAmount?: number,
    TotalLoanAmount?: number,
    Term?: number,
    NoteRate?: number,
    DownPaymentAmount?: number,
    DownPaymentPercentage?: number,
    LockTerm?: number,
    LoanEstimateType?: string,
    LoanProgram?: string,
    ClosingCostScenarioName?: string,
    BorrowerFirstName?: string,
    BorrowerLastName?: string,
    BorrowerMiddleName?: string,
    BorrowerNameSuffix?: string,
    BorrowerNickname?: string,
    BorrowerBirthDate?: Date,
    BorrowerSSN?: string,
    BorrowerEquifaxCreditScore?: number,
    BorrowerExperianCreditScore?: number,
    BorrowerMinimumFICOScore?: number,
    BorrowerTransUnionCreditScore?: number,
    BorrowerEmail?: string,
    BorrowerMobilePhone?: string,
    BorrowerWorkPhone?: string,
    BorrowerFax?: string,
    BorrowerHomePhone?: string,
    BorrowerStreet?: string,
    BorrowerCity?: string,
    BorrowerState?: string,
    BorrowerZip?: string,
    BorrowerOwns?: boolean,
    BorrowerRents?: boolean,
    BorrowerHash?: string,
    CoBorrowerFirstName?: string,
    CoBorrowerLastName?: string,
    CoBorrowerMiddleName?: string,
    CoBorrowerNameSuffix?: string,
    CoBorrowerNickname?: string,
    CoBorrowerBirthDate?: Date,
    CoBorrowerSSN?: string,
    CoBorrowerEquifaxCreditScore?: number,
    CoBorrowerExperianCreditScore?: number,
    CoBorrowerMinimumFICOScore?: number,
    CoBorrowerTransUnionCreditScore?: number,
    CoBorrowerEmail?: string,
    CoBorrowerMobilePhone?: string,
    CoBorrowerWorkPhone?: string,
    CoBorrowerFax?: string,
    CoBorrowerHomePhone?: string,
    CoBorrowerStreet?: string,
    CoBorrowerCity?: string,
    CoBorrowerState?: string,
    CoBorrowerZip?: string,
    CoBorrowerOwns?: boolean,
    CoBorrowerRents?: boolean,
    CoBorrowerHash?: string,
    AppraisedValue?: number,
    PurchasePrice?: number,
    SubjectPropertyStreet?: string,
    SubjectPropertyCity?: string,
    SubjectPropertyCounty?: string,
    SubjectPropertyState?: string,
    SubjectPropertyZip?: string,
    SubjectPropertyTypeId?: number,
    SubjectPropertyTypeDesc?: string,
    LoanOfficerName?: string,
    LoanOfficerFirstName?: string,
    LoanOfficerLastName?: string,
    LoanOfficerEmail?: string,
    LoanOfficerMobilePhone?: string,
    LoanOfficerWorkPhone?: string,
    LoanOfficerFax?: string,
    LoanOfficerNmlsId?: string,
    LoanOfficerHash?: string,
    LoanProcessorName?: string,
    LoanProcessorFirstName?: string,
    LoanProcessorLastName?: string,
    LoanProcessorEmail?: string,
    LoanProcessorMobilePhone?: string,
    LoanProcessorWorkPhone?: string,
    LoanProcessorFax?: string,
    LoanProcessorHash?: string,
    SellersAgentName?: string,
    SellersAgentFirstName?: string,
    SellersAgentLastName?: string,
    SellersAgentCompany?: string,
    SellersAgentStreet?: string,
    SellersAgentCity?: string,
    SellersAgentState?: string,
    SellersAgentZip?: string,
    SellersAgentEmail?: string,
    SellersAgentMobilePhone?: string,
    SellersAgentWorkPhone?: string,
    SellersAgentFax?: string,
    SellersAgentHash?: string,
    BuyersAgentName?: string,
    BuyersAgentFirstName?: string,
    BuyersAgentLastName?: string,
    BuyersAgentCompany?: string,
    BuyersAgentStreet?: string,
    BuyersAgentCity?: string,
    BuyersAgentState?: string,
    BuyersAgentZip?: string,
    BuyersAgentEmail?: string,
    BuyersAgentMobilePhone?: string,
    BuyersAgentWorkPhone?: string,
    BuyersAgentFax?: string,
    BuyersAgentHash?: string,
    ClosingAgentName?: string,
    ClosingAgentFirstName?: string,
    ClosingAgentLastName?: string,
    ClosingAgentCompany?: string,
    ClosingAgentStreet?: string,
    ClosingAgentCity?: string,
    ClosingAgentState?: string,
    ClosingAgentZip?: string,
    ClosingAgentEmail?: string,
    ClosingAgentMobilePhone?: string,
    ClosingAgentWorkPhone?: string,
    ClosingAgentFax?: string,
    ClosingAgentHash?: string,
    ShipperName?: string,
    ShipperFirstName?: string,
    ShipperLastName?: string,
    ShipperEmail?: string,
    ShipperMobilePhone?: string,
    ShipperWorkPhone?: string,
    ShipperFax?: string,
    ShipperHash?: string,
    UnderwriterName?: string,
    UnderwriterFirstName?: string,
    UnderwriterLastName?: string,
    UnderwriterEmail?: string,
    UnderwriterMobilePhone?: string,
    UnderwriterWorkPhone?: string,
    UnderwriterFax?: string,
    UnderwriterHash?: string,
    DocPreparerName?: string,
    DocPreparerFirstName?: string,
    DocPreparerLastName?: string,
    DocPreparerEmail?: string,
    DocPreparerMobilePhone?: string,
    DocPreparerWorkPhone?: string,
    DocPreparerFax?: string,
    DocPreparerHash?: string,
    ProspectStatusDate?: Date,
    LoanSubmittedDate?: Date,
    LoanSuspensionDate?: Date,
    DocsBackDate?: Date,
    DocsOrderedDate?: Date,
    DocsSentDate?: Date,
    FloodCertificationOrdered?: Date,
    FloodCertificationReceived?: Date,
    HMDACompletedStatusDate?: Date,
    LoanApplicationDate?: Date,
    LoanApprovedDate?: Date,
    LoanCancelledDate?: Date,
    LoanClosedDate?: Date,
    LoanDeniedDate?: Date,
    LoanDocsDrawnDate?: Date,
    LoanFundedDate?: Date,
    PreliminaryReportOrderedDate?: Date,
    PreliminaryReportReceivedDate?: Date,
    ResubmitStatusDate?: Date,
    SentToProcessing?: Date,
    TILDisclosure_GFEOrderedDate?: Date,
    TILDisclosure_GFEReceivedDate?: Date,
    AppraisalReportOrderedDate?: Date,
    AppraisalReportReceivedDate?: Date,
    ClosingDocumentsOrderedDate?: Date,
    ClosingDocumentsReceivedDate?: Date,
    ClearToCloseDate?: Date,
    LoanRecordedDate?: Date,
    CDDateIssued?: Date,
    RateLockDate?: Date,
    RateLockExpirationDate?: Date,
    RateLockConfirmedDate?: Date,
    EstimatedClosingDate?: Date,
    FirstPaymentDate?: Date,
    FundByDate?: Date,
    LoanTypeId?: number,
    LoanTypeDesc?: string,
    OccupancyTypeId?: number,
    OccupancyTypeDesc?: string,
    OccupancyTypeCode?: string,
    LoanPurposeId?: number,
    LoanPurposeDesc?: string,
    LoanPurposeCode?: string,
    LenderCompanyName?: string,
    LenderName?: string,
    LenderFirstName?: string,
    LenderLastName?: string,
    LenderEmail?: string,
    LenderWorkPhone?: string,
    LenderFax?: string,
    LenderHash?: string,
    OriginatingOrganizationName?: string,
    OriginatingOrganizationNmlsId?: string,
    OriginatingOrganizationStreet?: string,
    OriginatingOrganizationCity?: string,
    OriginatingOrganizationState?: string,
    OriginatingOrganizationZip?: string,
    OriginatorName?: string,
    OriginatorEmail?: string,
    OriginatorPhone?: string,
    OriginatorNmlsId?: string,
    OriginatorHash?: string,
    ConversationLog?: string,
    AppraisalCompanyAgentName?: string,
    AppraisalCompanyAgentFirstName?: string,
    AppraisalCompanyAgentLastName?: string,
    AppraisalCompany?: string,
    AppraisalCompanyStreet?: string,
    AppraisalCompanyCity?: string,
    AppraisalCompanyState?: string,
    AppraisalCompanyZip?: string,
    AppraisalCompanyEmail?: string,
    AppraisalCompanyMobilePhone?: string,
    AppraisalCompanyWorkPhone?: string,
    AppraisalCompanyFax?: string,
    AppraisalCompanyHash?: string,
    FloodInsuranceCompanyAgentName?: string,
    FloodInsuranceCompanyAgentFirstName?: string,
    FloodInsuranceCompanyAgentLastName?: string,
    FloodInsuranceCompany?: string,
    FloodInsuranceCompanyStreet?: string,
    FloodInsuranceCompanyCity?: string,
    FloodInsuranceCompanyState?: string,
    FloodInsuranceCompanyZip?: string,
    FloodInsuranceCompanyEmail?: string,
    FloodInsuranceCompanyMobilePhone?: string,
    FloodInsuranceCompanyWorkPhone?: string,
    FloodInsuranceCompanyFax?: string,
    FloodInsuranceCompanyHash?: string,
    HazardInsuranceCompanyAgentName?: string,
    HazardInsuranceCompanyAgentFirstName?: string,
    HazardInsuranceCompanyAgentLastName?: string,
    HazardInsuranceCompany?: string,
    HazardInsuranceCompanyStreet?: string,
    HazardInsuranceCompanyCity?: string,
    HazardInsuranceCompanyState?: string,
    HazardInsuranceCompanyZip?: string,
    HazardInsuranceCompanyEmail?: string,
    HazardInsuranceCompanyMobilePhone?: string,
    HazardInsuranceCompanyWorkPhone?: string,
    HazardInsuranceCompanyFax?: string,
    HazardInsuranceCompanyHash?: string,
    TitleCompanyAgentName?: string,
    TitleCompanyAgentFirstName?: string,
    TitleCompanyAgentLastName?: string,
    TitleCompany?: string,
    TitleCompanyStreet?: string,
    TitleCompanyCity?: string,
    TitleCompanyState?: string,
    TitleCompanyZip?: string,
    TitleCompanyEmail?: string,
    TitleCompanyMobilePhone?: string,
    TitleCompanyWorkPhone?: string,
    TitleCompanyFax?: string,
    TitleCompanyHash?: string,
    BorrowerName?: string,
    CoBorrowerName?: string,
    LoanOfficerAssistantName?: string,
    LoanOfficerAssistantFirstName?: string,
    LoanOfficerAssistantLastName?: string,
    LoanOfficerAssistantEmail?: string,
    LoanOfficerAssistantMobilePhone?: string,
    LoanOfficerAssistantWorkPhone?: string,
    LoanOfficerAssistantFax?: string,
    LoanOfficerAssistantHash?: string) {
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
    if ((LoanId !== undefined) && (LoanId !== null)) this.LoanId = LoanId;
    if (PulseCreatedDateUTC !== undefined) this.PulseCreatedDateUTC = PulseCreatedDateUTC;
    if (PulseLastModifiedDateUTC !== undefined) this.PulseLastModifiedDateUTC = PulseLastModifiedDateUTC;
    if ((LoanNumber !== undefined) && (LoanNumber !== null)) this.LoanNumber = LoanNumber;
    if ((LoanOriginationSystem !== undefined) && (LoanOriginationSystem !== null)) this.LoanOriginationSystem = LoanOriginationSystem;
    if (CreatedDate !== undefined) this.CreatedDate = CreatedDate;
    if (LastModifiedDate !== undefined) this.LastModifiedDate = LastModifiedDate;
    if (LoanStatusId !== undefined) this.LoanStatusId = LoanStatusId;
    if ((LoanStatus !== undefined) && (LoanStatus !== null)) this.LoanStatus = LoanStatus;
    if (LoanStatusDate !== undefined) this.LoanStatusDate = LoanStatusDate;
    if ((BaseLoanAmount !== undefined) && (BaseLoanAmount !== null)) this.BaseLoanAmount = BaseLoanAmount;
    if ((TotalLoanAmount !== undefined) && (TotalLoanAmount !== null)) this.TotalLoanAmount = TotalLoanAmount;
    if (Term !== undefined) this.Term = Term;
    if (NoteRate !== undefined) this.NoteRate = NoteRate;
    if ((DownPaymentAmount !== undefined) && (DownPaymentAmount !== null)) this.DownPaymentAmount = DownPaymentAmount;
    if ((DownPaymentPercentage !== undefined) && (DownPaymentPercentage !== null)) this.DownPaymentPercentage = DownPaymentPercentage;
    if (LockTerm !== undefined) this.LockTerm = LockTerm;
    if ((LoanEstimateType !== undefined) && (LoanEstimateType !== null)) this.LoanEstimateType = LoanEstimateType;
    if ((LoanProgram !== undefined) && (LoanProgram !== null)) this.LoanProgram = LoanProgram;
    if ((ClosingCostScenarioName !== undefined) && (ClosingCostScenarioName !== null)) this.ClosingCostScenarioName = ClosingCostScenarioName;
    if ((BorrowerFirstName !== undefined) && (BorrowerFirstName !== null)) this.BorrowerFirstName = BorrowerFirstName;
    if ((BorrowerLastName !== undefined) && (BorrowerLastName !== null)) this.BorrowerLastName = BorrowerLastName;
    if ((BorrowerMiddleName !== undefined) && (BorrowerMiddleName !== null)) this.BorrowerMiddleName = BorrowerMiddleName;
    if ((BorrowerNameSuffix !== undefined) && (BorrowerNameSuffix !== null)) this.BorrowerNameSuffix = BorrowerNameSuffix;
    if ((BorrowerNickname !== undefined) && (BorrowerNickname !== null)) this.BorrowerNickname = BorrowerNickname;
    if (BorrowerBirthDate !== undefined) this.BorrowerBirthDate = BorrowerBirthDate;
    if ((BorrowerSSN !== undefined) && (BorrowerSSN !== null)) this.BorrowerSSN = BorrowerSSN;
    if ((BorrowerEquifaxCreditScore !== undefined) && (BorrowerEquifaxCreditScore !== null)) this.BorrowerEquifaxCreditScore = BorrowerEquifaxCreditScore;
    if ((BorrowerExperianCreditScore !== undefined) && (BorrowerExperianCreditScore !== null)) this.BorrowerExperianCreditScore = BorrowerExperianCreditScore;
    if ((BorrowerMinimumFICOScore !== undefined) && (BorrowerMinimumFICOScore !== null)) this.BorrowerMinimumFICOScore = BorrowerMinimumFICOScore;
    if ((BorrowerTransUnionCreditScore !== undefined) && (BorrowerTransUnionCreditScore !== null)) this.BorrowerTransUnionCreditScore = BorrowerTransUnionCreditScore;
    if ((BorrowerEmail !== undefined) && (BorrowerEmail !== null)) this.BorrowerEmail = BorrowerEmail;
    if ((BorrowerMobilePhone !== undefined) && (BorrowerMobilePhone !== null)) this.BorrowerMobilePhone = BorrowerMobilePhone;
    if ((BorrowerWorkPhone !== undefined) && (BorrowerWorkPhone !== null)) this.BorrowerWorkPhone = BorrowerWorkPhone;
    if ((BorrowerFax !== undefined) && (BorrowerFax !== null)) this.BorrowerFax = BorrowerFax;
    if ((BorrowerHomePhone !== undefined) && (BorrowerHomePhone !== null)) this.BorrowerHomePhone = BorrowerHomePhone;
    if ((BorrowerStreet !== undefined) && (BorrowerStreet !== null)) this.BorrowerStreet = BorrowerStreet;
    if ((BorrowerCity !== undefined) && (BorrowerCity !== null)) this.BorrowerCity = BorrowerCity;
    if ((BorrowerState !== undefined) && (BorrowerState !== null)) this.BorrowerState = BorrowerState;
    if ((BorrowerZip !== undefined) && (BorrowerZip !== null)) this.BorrowerZip = BorrowerZip;
    if ((BorrowerOwns !== undefined) && (BorrowerOwns !== null)) this.BorrowerOwns = BorrowerOwns;
    if ((BorrowerRents !== undefined) && (BorrowerRents !== null)) this.BorrowerRents = BorrowerRents;
    if ((BorrowerHash !== undefined) && (BorrowerHash !== null)) this.BorrowerHash = BorrowerHash;
    if ((CoBorrowerFirstName !== undefined) && (CoBorrowerFirstName !== null)) this.CoBorrowerFirstName = CoBorrowerFirstName;
    if ((CoBorrowerLastName !== undefined) && (CoBorrowerLastName !== null)) this.CoBorrowerLastName = CoBorrowerLastName;
    if ((CoBorrowerMiddleName !== undefined) && (CoBorrowerMiddleName !== null)) this.CoBorrowerMiddleName = CoBorrowerMiddleName;
    if ((CoBorrowerNameSuffix !== undefined) && (CoBorrowerNameSuffix !== null)) this.CoBorrowerNameSuffix = CoBorrowerNameSuffix;
    if ((CoBorrowerNickname !== undefined) && (CoBorrowerNickname !== null)) this.CoBorrowerNickname = CoBorrowerNickname;
    if (CoBorrowerBirthDate !== undefined) this.CoBorrowerBirthDate = CoBorrowerBirthDate;
    if ((CoBorrowerSSN !== undefined) && (CoBorrowerSSN !== null)) this.CoBorrowerSSN = CoBorrowerSSN;
    if ((CoBorrowerEquifaxCreditScore !== undefined) && (CoBorrowerEquifaxCreditScore !== null)) this.CoBorrowerEquifaxCreditScore = CoBorrowerEquifaxCreditScore;
    if ((CoBorrowerExperianCreditScore !== undefined) && (CoBorrowerExperianCreditScore !== null)) this.CoBorrowerExperianCreditScore = CoBorrowerExperianCreditScore;
    if ((CoBorrowerMinimumFICOScore !== undefined) && (CoBorrowerMinimumFICOScore !== null)) this.CoBorrowerMinimumFICOScore = CoBorrowerMinimumFICOScore;
    if ((CoBorrowerTransUnionCreditScore !== undefined) && (CoBorrowerTransUnionCreditScore !== null)) this.CoBorrowerTransUnionCreditScore = CoBorrowerTransUnionCreditScore;
    if ((CoBorrowerEmail !== undefined) && (CoBorrowerEmail !== null)) this.CoBorrowerEmail = CoBorrowerEmail;
    if ((CoBorrowerMobilePhone !== undefined) && (CoBorrowerMobilePhone !== null)) this.CoBorrowerMobilePhone = CoBorrowerMobilePhone;
    if ((CoBorrowerWorkPhone !== undefined) && (CoBorrowerWorkPhone !== null)) this.CoBorrowerWorkPhone = CoBorrowerWorkPhone;
    if ((CoBorrowerFax !== undefined) && (CoBorrowerFax !== null)) this.CoBorrowerFax = CoBorrowerFax;
    if ((CoBorrowerHomePhone !== undefined) && (CoBorrowerHomePhone !== null)) this.CoBorrowerHomePhone = CoBorrowerHomePhone;
    if ((CoBorrowerStreet !== undefined) && (CoBorrowerStreet !== null)) this.CoBorrowerStreet = CoBorrowerStreet;
    if ((CoBorrowerCity !== undefined) && (CoBorrowerCity !== null)) this.CoBorrowerCity = CoBorrowerCity;
    if ((CoBorrowerState !== undefined) && (CoBorrowerState !== null)) this.CoBorrowerState = CoBorrowerState;
    if ((CoBorrowerZip !== undefined) && (CoBorrowerZip !== null)) this.CoBorrowerZip = CoBorrowerZip;
    if ((CoBorrowerOwns !== undefined) && (CoBorrowerOwns !== null)) this.CoBorrowerOwns = CoBorrowerOwns;
    if ((CoBorrowerRents !== undefined) && (CoBorrowerRents !== null)) this.CoBorrowerRents = CoBorrowerRents;
    if ((CoBorrowerHash !== undefined) && (CoBorrowerHash !== null)) this.CoBorrowerHash = CoBorrowerHash;
    if ((AppraisedValue !== undefined) && (AppraisedValue !== null)) this.AppraisedValue = AppraisedValue;
    if ((PurchasePrice !== undefined) && (PurchasePrice !== null)) this.PurchasePrice = PurchasePrice;
    if ((SubjectPropertyStreet !== undefined) && (SubjectPropertyStreet !== null)) this.SubjectPropertyStreet = SubjectPropertyStreet;
    if ((SubjectPropertyCity !== undefined) && (SubjectPropertyCity !== null)) this.SubjectPropertyCity = SubjectPropertyCity;
    if ((SubjectPropertyCounty !== undefined) && (SubjectPropertyCounty !== null)) this.SubjectPropertyCounty = SubjectPropertyCounty;
    if ((SubjectPropertyState !== undefined) && (SubjectPropertyState !== null)) this.SubjectPropertyState = SubjectPropertyState;
    if ((SubjectPropertyZip !== undefined) && (SubjectPropertyZip !== null)) this.SubjectPropertyZip = SubjectPropertyZip;
    if (SubjectPropertyTypeId !== undefined) this.SubjectPropertyTypeId = SubjectPropertyTypeId;
    if ((SubjectPropertyTypeDesc !== undefined) && (SubjectPropertyTypeDesc !== null)) this.SubjectPropertyTypeDesc = SubjectPropertyTypeDesc;
    if ((LoanOfficerName !== undefined) && (LoanOfficerName !== null)) this.LoanOfficerName = LoanOfficerName;
    if ((LoanOfficerFirstName !== undefined) && (LoanOfficerFirstName !== null)) this.LoanOfficerFirstName = LoanOfficerFirstName;
    if ((LoanOfficerLastName !== undefined) && (LoanOfficerLastName !== null)) this.LoanOfficerLastName = LoanOfficerLastName;
    if ((LoanOfficerEmail !== undefined) && (LoanOfficerEmail !== null)) this.LoanOfficerEmail = LoanOfficerEmail;
    if ((LoanOfficerMobilePhone !== undefined) && (LoanOfficerMobilePhone !== null)) this.LoanOfficerMobilePhone = LoanOfficerMobilePhone;
    if ((LoanOfficerWorkPhone !== undefined) && (LoanOfficerWorkPhone !== null)) this.LoanOfficerWorkPhone = LoanOfficerWorkPhone;
    if ((LoanOfficerFax !== undefined) && (LoanOfficerFax !== null)) this.LoanOfficerFax = LoanOfficerFax;
    if ((LoanOfficerNmlsId !== undefined) && (LoanOfficerNmlsId !== null)) this.LoanOfficerNmlsId = LoanOfficerNmlsId;
    if ((LoanOfficerHash !== undefined) && (LoanOfficerHash !== null)) this.LoanOfficerHash = LoanOfficerHash;
    if ((LoanProcessorName !== undefined) && (LoanProcessorName !== null)) this.LoanProcessorName = LoanProcessorName;
    if ((LoanProcessorFirstName !== undefined) && (LoanProcessorFirstName !== null)) this.LoanProcessorFirstName = LoanProcessorFirstName;
    if ((LoanProcessorLastName !== undefined) && (LoanProcessorLastName !== null)) this.LoanProcessorLastName = LoanProcessorLastName;
    if ((LoanProcessorEmail !== undefined) && (LoanProcessorEmail !== null)) this.LoanProcessorEmail = LoanProcessorEmail;
    if ((LoanProcessorMobilePhone !== undefined) && (LoanProcessorMobilePhone !== null)) this.LoanProcessorMobilePhone = LoanProcessorMobilePhone;
    if ((LoanProcessorWorkPhone !== undefined) && (LoanProcessorWorkPhone !== null)) this.LoanProcessorWorkPhone = LoanProcessorWorkPhone;
    if ((LoanProcessorFax !== undefined) && (LoanProcessorFax !== null)) this.LoanProcessorFax = LoanProcessorFax;
    if ((LoanProcessorHash !== undefined) && (LoanProcessorHash !== null)) this.LoanProcessorHash = LoanProcessorHash;
    if ((SellersAgentName !== undefined) && (SellersAgentName !== null)) this.SellersAgentName = SellersAgentName;
    if ((SellersAgentFirstName !== undefined) && (SellersAgentFirstName !== null)) this.SellersAgentFirstName = SellersAgentFirstName;
    if ((SellersAgentLastName !== undefined) && (SellersAgentLastName !== null)) this.SellersAgentLastName = SellersAgentLastName;
    if ((SellersAgentCompany !== undefined) && (SellersAgentCompany !== null)) this.SellersAgentCompany = SellersAgentCompany;
    if ((SellersAgentStreet !== undefined) && (SellersAgentStreet !== null)) this.SellersAgentStreet = SellersAgentStreet;
    if ((SellersAgentCity !== undefined) && (SellersAgentCity !== null)) this.SellersAgentCity = SellersAgentCity;
    if ((SellersAgentState !== undefined) && (SellersAgentState !== null)) this.SellersAgentState = SellersAgentState;
    if ((SellersAgentZip !== undefined) && (SellersAgentZip !== null)) this.SellersAgentZip = SellersAgentZip;
    if ((SellersAgentEmail !== undefined) && (SellersAgentEmail !== null)) this.SellersAgentEmail = SellersAgentEmail;
    if ((SellersAgentMobilePhone !== undefined) && (SellersAgentMobilePhone !== null)) this.SellersAgentMobilePhone = SellersAgentMobilePhone;
    if ((SellersAgentWorkPhone !== undefined) && (SellersAgentWorkPhone !== null)) this.SellersAgentWorkPhone = SellersAgentWorkPhone;
    if ((SellersAgentFax !== undefined) && (SellersAgentFax !== null)) this.SellersAgentFax = SellersAgentFax;
    if ((SellersAgentHash !== undefined) && (SellersAgentHash !== null)) this.SellersAgentHash = SellersAgentHash;
    if ((BuyersAgentName !== undefined) && (BuyersAgentName !== null)) this.BuyersAgentName = BuyersAgentName;
    if ((BuyersAgentFirstName !== undefined) && (BuyersAgentFirstName !== null)) this.BuyersAgentFirstName = BuyersAgentFirstName;
    if ((BuyersAgentLastName !== undefined) && (BuyersAgentLastName !== null)) this.BuyersAgentLastName = BuyersAgentLastName;
    if ((BuyersAgentCompany !== undefined) && (BuyersAgentCompany !== null)) this.BuyersAgentCompany = BuyersAgentCompany;
    if ((BuyersAgentStreet !== undefined) && (BuyersAgentStreet !== null)) this.BuyersAgentStreet = BuyersAgentStreet;
    if ((BuyersAgentCity !== undefined) && (BuyersAgentCity !== null)) this.BuyersAgentCity = BuyersAgentCity;
    if ((BuyersAgentState !== undefined) && (BuyersAgentState !== null)) this.BuyersAgentState = BuyersAgentState;
    if ((BuyersAgentZip !== undefined) && (BuyersAgentZip !== null)) this.BuyersAgentZip = BuyersAgentZip;
    if ((BuyersAgentEmail !== undefined) && (BuyersAgentEmail !== null)) this.BuyersAgentEmail = BuyersAgentEmail;
    if ((BuyersAgentMobilePhone !== undefined) && (BuyersAgentMobilePhone !== null)) this.BuyersAgentMobilePhone = BuyersAgentMobilePhone;
    if ((BuyersAgentWorkPhone !== undefined) && (BuyersAgentWorkPhone !== null)) this.BuyersAgentWorkPhone = BuyersAgentWorkPhone;
    if ((BuyersAgentFax !== undefined) && (BuyersAgentFax !== null)) this.BuyersAgentFax = BuyersAgentFax;
    if ((BuyersAgentHash !== undefined) && (BuyersAgentHash !== null)) this.BuyersAgentHash = BuyersAgentHash;
    if ((ClosingAgentName !== undefined) && (ClosingAgentName !== null)) this.ClosingAgentName = ClosingAgentName;
    if ((ClosingAgentFirstName !== undefined) && (ClosingAgentFirstName !== null)) this.ClosingAgentFirstName = ClosingAgentFirstName;
    if ((ClosingAgentLastName !== undefined) && (ClosingAgentLastName !== null)) this.ClosingAgentLastName = ClosingAgentLastName;
    if ((ClosingAgentCompany !== undefined) && (ClosingAgentCompany !== null)) this.ClosingAgentCompany = ClosingAgentCompany;
    if ((ClosingAgentStreet !== undefined) && (ClosingAgentStreet !== null)) this.ClosingAgentStreet = ClosingAgentStreet;
    if ((ClosingAgentCity !== undefined) && (ClosingAgentCity !== null)) this.ClosingAgentCity = ClosingAgentCity;
    if ((ClosingAgentState !== undefined) && (ClosingAgentState !== null)) this.ClosingAgentState = ClosingAgentState;
    if ((ClosingAgentZip !== undefined) && (ClosingAgentZip !== null)) this.ClosingAgentZip = ClosingAgentZip;
    if ((ClosingAgentEmail !== undefined) && (ClosingAgentEmail !== null)) this.ClosingAgentEmail = ClosingAgentEmail;
    if ((ClosingAgentMobilePhone !== undefined) && (ClosingAgentMobilePhone !== null)) this.ClosingAgentMobilePhone = ClosingAgentMobilePhone;
    if ((ClosingAgentWorkPhone !== undefined) && (ClosingAgentWorkPhone !== null)) this.ClosingAgentWorkPhone = ClosingAgentWorkPhone;
    if ((ClosingAgentFax !== undefined) && (ClosingAgentFax !== null)) this.ClosingAgentFax = ClosingAgentFax;
    if ((ClosingAgentHash !== undefined) && (ClosingAgentHash !== null)) this.ClosingAgentHash = ClosingAgentHash;
    if ((ShipperName !== undefined) && (ShipperName !== null)) this.ShipperName = ShipperName;
    if ((ShipperFirstName !== undefined) && (ShipperFirstName !== null)) this.ShipperFirstName = ShipperFirstName;
    if ((ShipperLastName !== undefined) && (ShipperLastName !== null)) this.ShipperLastName = ShipperLastName;
    if ((ShipperEmail !== undefined) && (ShipperEmail !== null)) this.ShipperEmail = ShipperEmail;
    if ((ShipperMobilePhone !== undefined) && (ShipperMobilePhone !== null)) this.ShipperMobilePhone = ShipperMobilePhone;
    if ((ShipperWorkPhone !== undefined) && (ShipperWorkPhone !== null)) this.ShipperWorkPhone = ShipperWorkPhone;
    if ((ShipperFax !== undefined) && (ShipperFax !== null)) this.ShipperFax = ShipperFax;
    if ((ShipperHash !== undefined) && (ShipperHash !== null)) this.ShipperHash = ShipperHash;
    if ((UnderwriterName !== undefined) && (UnderwriterName !== null)) this.UnderwriterName = UnderwriterName;
    if ((UnderwriterFirstName !== undefined) && (UnderwriterFirstName !== null)) this.UnderwriterFirstName = UnderwriterFirstName;
    if ((UnderwriterLastName !== undefined) && (UnderwriterLastName !== null)) this.UnderwriterLastName = UnderwriterLastName;
    if ((UnderwriterEmail !== undefined) && (UnderwriterEmail !== null)) this.UnderwriterEmail = UnderwriterEmail;
    if ((UnderwriterMobilePhone !== undefined) && (UnderwriterMobilePhone !== null)) this.UnderwriterMobilePhone = UnderwriterMobilePhone;
    if ((UnderwriterWorkPhone !== undefined) && (UnderwriterWorkPhone !== null)) this.UnderwriterWorkPhone = UnderwriterWorkPhone;
    if ((UnderwriterFax !== undefined) && (UnderwriterFax !== null)) this.UnderwriterFax = UnderwriterFax;
    if ((UnderwriterHash !== undefined) && (UnderwriterHash !== null)) this.UnderwriterHash = UnderwriterHash;
    if ((DocPreparerName !== undefined) && (DocPreparerName !== null)) this.DocPreparerName = DocPreparerName;
    if ((DocPreparerFirstName !== undefined) && (DocPreparerFirstName !== null)) this.DocPreparerFirstName = DocPreparerFirstName;
    if ((DocPreparerLastName !== undefined) && (DocPreparerLastName !== null)) this.DocPreparerLastName = DocPreparerLastName;
    if ((DocPreparerEmail !== undefined) && (DocPreparerEmail !== null)) this.DocPreparerEmail = DocPreparerEmail;
    if ((DocPreparerMobilePhone !== undefined) && (DocPreparerMobilePhone !== null)) this.DocPreparerMobilePhone = DocPreparerMobilePhone;
    if ((DocPreparerWorkPhone !== undefined) && (DocPreparerWorkPhone !== null)) this.DocPreparerWorkPhone = DocPreparerWorkPhone;
    if ((DocPreparerFax !== undefined) && (DocPreparerFax !== null)) this.DocPreparerFax = DocPreparerFax;
    if ((DocPreparerHash !== undefined) && (DocPreparerHash !== null)) this.DocPreparerHash = DocPreparerHash;
    if (ProspectStatusDate !== undefined) this.ProspectStatusDate = ProspectStatusDate;
    if (LoanSubmittedDate !== undefined) this.LoanSubmittedDate = LoanSubmittedDate;
    if (LoanSuspensionDate !== undefined) this.LoanSuspensionDate = LoanSuspensionDate;
    if (DocsBackDate !== undefined) this.DocsBackDate = DocsBackDate;
    if (DocsOrderedDate !== undefined) this.DocsOrderedDate = DocsOrderedDate;
    if (DocsSentDate !== undefined) this.DocsSentDate = DocsSentDate;
    if (FloodCertificationOrdered !== undefined) this.FloodCertificationOrdered = FloodCertificationOrdered;
    if (FloodCertificationReceived !== undefined) this.FloodCertificationReceived = FloodCertificationReceived;
    if (HMDACompletedStatusDate !== undefined) this.HMDACompletedStatusDate = HMDACompletedStatusDate;
    if (LoanApplicationDate !== undefined) this.LoanApplicationDate = LoanApplicationDate;
    if (LoanApprovedDate !== undefined) this.LoanApprovedDate = LoanApprovedDate;
    if (LoanCancelledDate !== undefined) this.LoanCancelledDate = LoanCancelledDate;
    if (LoanClosedDate !== undefined) this.LoanClosedDate = LoanClosedDate;
    if (LoanDeniedDate !== undefined) this.LoanDeniedDate = LoanDeniedDate;
    if (LoanDocsDrawnDate !== undefined) this.LoanDocsDrawnDate = LoanDocsDrawnDate;
    if (LoanFundedDate !== undefined) this.LoanFundedDate = LoanFundedDate;
    if (PreliminaryReportOrderedDate !== undefined) this.PreliminaryReportOrderedDate = PreliminaryReportOrderedDate;
    if (PreliminaryReportReceivedDate !== undefined) this.PreliminaryReportReceivedDate = PreliminaryReportReceivedDate;
    if (ResubmitStatusDate !== undefined) this.ResubmitStatusDate = ResubmitStatusDate;
    if (SentToProcessing !== undefined) this.SentToProcessing = SentToProcessing;
    if (TILDisclosure_GFEOrderedDate !== undefined) this.TILDisclosure_GFEOrderedDate = TILDisclosure_GFEOrderedDate;
    if (TILDisclosure_GFEReceivedDate !== undefined) this.TILDisclosure_GFEReceivedDate = TILDisclosure_GFEReceivedDate;
    if (AppraisalReportOrderedDate !== undefined) this.AppraisalReportOrderedDate = AppraisalReportOrderedDate;
    if (AppraisalReportReceivedDate !== undefined) this.AppraisalReportReceivedDate = AppraisalReportReceivedDate;
    if (ClosingDocumentsOrderedDate !== undefined) this.ClosingDocumentsOrderedDate = ClosingDocumentsOrderedDate;
    if (ClosingDocumentsReceivedDate !== undefined) this.ClosingDocumentsReceivedDate = ClosingDocumentsReceivedDate;
    if (ClearToCloseDate !== undefined) this.ClearToCloseDate = ClearToCloseDate;
    if (LoanRecordedDate !== undefined) this.LoanRecordedDate = LoanRecordedDate;
    if (CDDateIssued !== undefined) this.CDDateIssued = CDDateIssued;
    if (RateLockDate !== undefined) this.RateLockDate = RateLockDate;
    if (RateLockExpirationDate !== undefined) this.RateLockExpirationDate = RateLockExpirationDate;
    if (RateLockConfirmedDate !== undefined) this.RateLockConfirmedDate = RateLockConfirmedDate;
    if (EstimatedClosingDate !== undefined) this.EstimatedClosingDate = EstimatedClosingDate;
    if (FirstPaymentDate !== undefined) this.FirstPaymentDate = FirstPaymentDate;
    if (FundByDate !== undefined) this.FundByDate = FundByDate;
    if (LoanTypeId !== undefined) this.LoanTypeId = LoanTypeId;
    if ((LoanTypeDesc !== undefined) && (LoanTypeDesc !== null)) this.LoanTypeDesc = LoanTypeDesc;
    if (OccupancyTypeId !== undefined) this.OccupancyTypeId = OccupancyTypeId;
    if ((OccupancyTypeDesc !== undefined) && (OccupancyTypeDesc !== null)) this.OccupancyTypeDesc = OccupancyTypeDesc;
    if ((OccupancyTypeCode !== undefined) && (OccupancyTypeCode !== null)) this.OccupancyTypeCode = OccupancyTypeCode;
    if (LoanPurposeId !== undefined) this.LoanPurposeId = LoanPurposeId;
    if ((LoanPurposeDesc !== undefined) && (LoanPurposeDesc !== null)) this.LoanPurposeDesc = LoanPurposeDesc;
    if ((LoanPurposeCode !== undefined) && (LoanPurposeCode !== null)) this.LoanPurposeCode = LoanPurposeCode;
    if ((LenderCompanyName !== undefined) && (LenderCompanyName !== null)) this.LenderCompanyName = LenderCompanyName;
    if ((LenderName !== undefined) && (LenderName !== null)) this.LenderName = LenderName;
    if ((LenderFirstName !== undefined) && (LenderFirstName !== null)) this.LenderFirstName = LenderFirstName;
    if ((LenderLastName !== undefined) && (LenderLastName !== null)) this.LenderLastName = LenderLastName;
    if ((LenderEmail !== undefined) && (LenderEmail !== null)) this.LenderEmail = LenderEmail;
    if ((LenderWorkPhone !== undefined) && (LenderWorkPhone !== null)) this.LenderWorkPhone = LenderWorkPhone;
    if ((LenderFax !== undefined) && (LenderFax !== null)) this.LenderFax = LenderFax;
    if ((LenderHash !== undefined) && (LenderHash !== null)) this.LenderHash = LenderHash;
    if ((OriginatingOrganizationName !== undefined) && (OriginatingOrganizationName !== null)) this.OriginatingOrganizationName = OriginatingOrganizationName;
    if ((OriginatingOrganizationNmlsId !== undefined) && (OriginatingOrganizationNmlsId !== null)) this.OriginatingOrganizationNmlsId = OriginatingOrganizationNmlsId;
    if ((OriginatingOrganizationStreet !== undefined) && (OriginatingOrganizationStreet !== null)) this.OriginatingOrganizationStreet = OriginatingOrganizationStreet;
    if ((OriginatingOrganizationCity !== undefined) && (OriginatingOrganizationCity !== null)) this.OriginatingOrganizationCity = OriginatingOrganizationCity;
    if ((OriginatingOrganizationState !== undefined) && (OriginatingOrganizationState !== null)) this.OriginatingOrganizationState = OriginatingOrganizationState;
    if ((OriginatingOrganizationZip !== undefined) && (OriginatingOrganizationZip !== null)) this.OriginatingOrganizationZip = OriginatingOrganizationZip;
    if ((OriginatorName !== undefined) && (OriginatorName !== null)) this.OriginatorName = OriginatorName;
    if ((OriginatorEmail !== undefined) && (OriginatorEmail !== null)) this.OriginatorEmail = OriginatorEmail;
    if ((OriginatorPhone !== undefined) && (OriginatorPhone !== null)) this.OriginatorPhone = OriginatorPhone;
    if ((OriginatorNmlsId !== undefined) && (OriginatorNmlsId !== null)) this.OriginatorNmlsId = OriginatorNmlsId;
    if ((OriginatorHash !== undefined) && (OriginatorHash !== null)) this.OriginatorHash = OriginatorHash;
    if ((ConversationLog !== undefined) && (ConversationLog !== null)) this.ConversationLog = ConversationLog;
    if ((AppraisalCompanyAgentName !== undefined) && (AppraisalCompanyAgentName !== null)) this.AppraisalCompanyAgentName = AppraisalCompanyAgentName;
    if ((AppraisalCompanyAgentFirstName !== undefined) && (AppraisalCompanyAgentFirstName !== null)) this.AppraisalCompanyAgentFirstName = AppraisalCompanyAgentFirstName;
    if ((AppraisalCompanyAgentLastName !== undefined) && (AppraisalCompanyAgentLastName !== null)) this.AppraisalCompanyAgentLastName = AppraisalCompanyAgentLastName;
    if ((AppraisalCompany !== undefined) && (AppraisalCompany !== null)) this.AppraisalCompany = AppraisalCompany;
    if ((AppraisalCompanyStreet !== undefined) && (AppraisalCompanyStreet !== null)) this.AppraisalCompanyStreet = AppraisalCompanyStreet;
    if ((AppraisalCompanyCity !== undefined) && (AppraisalCompanyCity !== null)) this.AppraisalCompanyCity = AppraisalCompanyCity;
    if ((AppraisalCompanyState !== undefined) && (AppraisalCompanyState !== null)) this.AppraisalCompanyState = AppraisalCompanyState;
    if ((AppraisalCompanyZip !== undefined) && (AppraisalCompanyZip !== null)) this.AppraisalCompanyZip = AppraisalCompanyZip;
    if ((AppraisalCompanyEmail !== undefined) && (AppraisalCompanyEmail !== null)) this.AppraisalCompanyEmail = AppraisalCompanyEmail;
    if ((AppraisalCompanyMobilePhone !== undefined) && (AppraisalCompanyMobilePhone !== null)) this.AppraisalCompanyMobilePhone = AppraisalCompanyMobilePhone;
    if ((AppraisalCompanyWorkPhone !== undefined) && (AppraisalCompanyWorkPhone !== null)) this.AppraisalCompanyWorkPhone = AppraisalCompanyWorkPhone;
    if ((AppraisalCompanyFax !== undefined) && (AppraisalCompanyFax !== null)) this.AppraisalCompanyFax = AppraisalCompanyFax;
    if ((AppraisalCompanyHash !== undefined) && (AppraisalCompanyHash !== null)) this.AppraisalCompanyHash = AppraisalCompanyHash;
    if ((FloodInsuranceCompanyAgentName !== undefined) && (FloodInsuranceCompanyAgentName !== null)) this.FloodInsuranceCompanyAgentName = FloodInsuranceCompanyAgentName;
    if ((FloodInsuranceCompanyAgentFirstName !== undefined) && (FloodInsuranceCompanyAgentFirstName !== null)) this.FloodInsuranceCompanyAgentFirstName = FloodInsuranceCompanyAgentFirstName;
    if ((FloodInsuranceCompanyAgentLastName !== undefined) && (FloodInsuranceCompanyAgentLastName !== null)) this.FloodInsuranceCompanyAgentLastName = FloodInsuranceCompanyAgentLastName;
    if ((FloodInsuranceCompany !== undefined) && (FloodInsuranceCompany !== null)) this.FloodInsuranceCompany = FloodInsuranceCompany;
    if ((FloodInsuranceCompanyStreet !== undefined) && (FloodInsuranceCompanyStreet !== null)) this.FloodInsuranceCompanyStreet = FloodInsuranceCompanyStreet;
    if ((FloodInsuranceCompanyCity !== undefined) && (FloodInsuranceCompanyCity !== null)) this.FloodInsuranceCompanyCity = FloodInsuranceCompanyCity;
    if ((FloodInsuranceCompanyState !== undefined) && (FloodInsuranceCompanyState !== null)) this.FloodInsuranceCompanyState = FloodInsuranceCompanyState;
    if ((FloodInsuranceCompanyZip !== undefined) && (FloodInsuranceCompanyZip !== null)) this.FloodInsuranceCompanyZip = FloodInsuranceCompanyZip;
    if ((FloodInsuranceCompanyEmail !== undefined) && (FloodInsuranceCompanyEmail !== null)) this.FloodInsuranceCompanyEmail = FloodInsuranceCompanyEmail;
    if ((FloodInsuranceCompanyMobilePhone !== undefined) && (FloodInsuranceCompanyMobilePhone !== null)) this.FloodInsuranceCompanyMobilePhone = FloodInsuranceCompanyMobilePhone;
    if ((FloodInsuranceCompanyWorkPhone !== undefined) && (FloodInsuranceCompanyWorkPhone !== null)) this.FloodInsuranceCompanyWorkPhone = FloodInsuranceCompanyWorkPhone;
    if ((FloodInsuranceCompanyFax !== undefined) && (FloodInsuranceCompanyFax !== null)) this.FloodInsuranceCompanyFax = FloodInsuranceCompanyFax;
    if ((FloodInsuranceCompanyHash !== undefined) && (FloodInsuranceCompanyHash !== null)) this.FloodInsuranceCompanyHash = FloodInsuranceCompanyHash;
    if ((HazardInsuranceCompanyAgentName !== undefined) && (HazardInsuranceCompanyAgentName !== null)) this.HazardInsuranceCompanyAgentName = HazardInsuranceCompanyAgentName;
    if ((HazardInsuranceCompanyAgentFirstName !== undefined) && (HazardInsuranceCompanyAgentFirstName !== null)) this.HazardInsuranceCompanyAgentFirstName = HazardInsuranceCompanyAgentFirstName;
    if ((HazardInsuranceCompanyAgentLastName !== undefined) && (HazardInsuranceCompanyAgentLastName !== null)) this.HazardInsuranceCompanyAgentLastName = HazardInsuranceCompanyAgentLastName;
    if ((HazardInsuranceCompany !== undefined) && (HazardInsuranceCompany !== null)) this.HazardInsuranceCompany = HazardInsuranceCompany;
    if ((HazardInsuranceCompanyStreet !== undefined) && (HazardInsuranceCompanyStreet !== null)) this.HazardInsuranceCompanyStreet = HazardInsuranceCompanyStreet;
    if ((HazardInsuranceCompanyCity !== undefined) && (HazardInsuranceCompanyCity !== null)) this.HazardInsuranceCompanyCity = HazardInsuranceCompanyCity;
    if ((HazardInsuranceCompanyState !== undefined) && (HazardInsuranceCompanyState !== null)) this.HazardInsuranceCompanyState = HazardInsuranceCompanyState;
    if ((HazardInsuranceCompanyZip !== undefined) && (HazardInsuranceCompanyZip !== null)) this.HazardInsuranceCompanyZip = HazardInsuranceCompanyZip;
    if ((HazardInsuranceCompanyEmail !== undefined) && (HazardInsuranceCompanyEmail !== null)) this.HazardInsuranceCompanyEmail = HazardInsuranceCompanyEmail;
    if ((HazardInsuranceCompanyMobilePhone !== undefined) && (HazardInsuranceCompanyMobilePhone !== null)) this.HazardInsuranceCompanyMobilePhone = HazardInsuranceCompanyMobilePhone;
    if ((HazardInsuranceCompanyWorkPhone !== undefined) && (HazardInsuranceCompanyWorkPhone !== null)) this.HazardInsuranceCompanyWorkPhone = HazardInsuranceCompanyWorkPhone;
    if ((HazardInsuranceCompanyFax !== undefined) && (HazardInsuranceCompanyFax !== null)) this.HazardInsuranceCompanyFax = HazardInsuranceCompanyFax;
    if ((HazardInsuranceCompanyHash !== undefined) && (HazardInsuranceCompanyHash !== null)) this.HazardInsuranceCompanyHash = HazardInsuranceCompanyHash;
    if ((TitleCompanyAgentName !== undefined) && (TitleCompanyAgentName !== null)) this.TitleCompanyAgentName = TitleCompanyAgentName;
    if ((TitleCompanyAgentFirstName !== undefined) && (TitleCompanyAgentFirstName !== null)) this.TitleCompanyAgentFirstName = TitleCompanyAgentFirstName;
    if ((TitleCompanyAgentLastName !== undefined) && (TitleCompanyAgentLastName !== null)) this.TitleCompanyAgentLastName = TitleCompanyAgentLastName;
    if ((TitleCompany !== undefined) && (TitleCompany !== null)) this.TitleCompany = TitleCompany;
    if ((TitleCompanyStreet !== undefined) && (TitleCompanyStreet !== null)) this.TitleCompanyStreet = TitleCompanyStreet;
    if ((TitleCompanyCity !== undefined) && (TitleCompanyCity !== null)) this.TitleCompanyCity = TitleCompanyCity;
    if ((TitleCompanyState !== undefined) && (TitleCompanyState !== null)) this.TitleCompanyState = TitleCompanyState;
    if ((TitleCompanyZip !== undefined) && (TitleCompanyZip !== null)) this.TitleCompanyZip = TitleCompanyZip;
    if ((TitleCompanyEmail !== undefined) && (TitleCompanyEmail !== null)) this.TitleCompanyEmail = TitleCompanyEmail;
    if ((TitleCompanyMobilePhone !== undefined) && (TitleCompanyMobilePhone !== null)) this.TitleCompanyMobilePhone = TitleCompanyMobilePhone;
    if ((TitleCompanyWorkPhone !== undefined) && (TitleCompanyWorkPhone !== null)) this.TitleCompanyWorkPhone = TitleCompanyWorkPhone;
    if ((TitleCompanyFax !== undefined) && (TitleCompanyFax !== null)) this.TitleCompanyFax = TitleCompanyFax;
    if ((TitleCompanyHash !== undefined) && (TitleCompanyHash !== null)) this.TitleCompanyHash = TitleCompanyHash;

    if ((BorrowerName !== undefined) && (BorrowerName !== null)) this.BorrowerName = BorrowerName;
    if ((CoBorrowerName !== undefined) && (CoBorrowerName !== null)) this.CoBorrowerName = CoBorrowerName;

    if ((LoanOfficerAssistantName !== undefined) && (LoanOfficerAssistantName !== null)) this.LoanOfficerAssistantName = LoanOfficerAssistantName;
    if ((LoanOfficerAssistantFirstName !== undefined) && (LoanOfficerAssistantFirstName !== null)) this.LoanOfficerAssistantFirstName = LoanOfficerAssistantFirstName;
    if ((LoanOfficerAssistantLastName !== undefined) && (LoanOfficerAssistantLastName !== null)) this.LoanOfficerAssistantLastName = LoanOfficerAssistantLastName;
    if ((LoanOfficerAssistantEmail !== undefined) && (LoanOfficerAssistantEmail !== null)) this.LoanOfficerAssistantEmail = LoanOfficerAssistantEmail;
    if ((LoanOfficerAssistantMobilePhone !== undefined) && (LoanOfficerAssistantMobilePhone !== null)) this.LoanOfficerAssistantMobilePhone = LoanOfficerAssistantMobilePhone;
    if ((LoanOfficerAssistantWorkPhone !== undefined) && (LoanOfficerAssistantWorkPhone !== null)) this.LoanOfficerAssistantWorkPhone = LoanOfficerAssistantWorkPhone;
    if ((LoanOfficerAssistantFax !== undefined) && (LoanOfficerAssistantFax !== null)) this.LoanOfficerAssistantFax = LoanOfficerAssistantFax;
    if ((LoanOfficerAssistantHash !== undefined) && (LoanOfficerAssistantHash !== null)) this.LoanOfficerAssistantHash = LoanOfficerAssistantHash;

    this.DisplayLoanPurposeDesc = this.getDisplayLoanPurposeDesc(this.LoanPurposeId);

    this.DisplayPurchasePrice = this.getDisplayMoney(this.PurchasePrice);
    this.DisplayNoteRate = this.getDisplayPercentage(this.NoteRate);
    this.DisplayBaseLoanAmount = this.getDisplayMoney(this.BaseLoanAmount);
    this.DisplayTotalLoanAmount = this.getDisplayMoney(this.TotalLoanAmount);
    this.DisplayDownPaymentAmount = this.getDisplayMoney(this.DownPaymentAmount);
    this.DisplayDownPaymentPercentage = this.getDisplayPercentage(this.DownPaymentPercentage);


    this.DisplayPulseCreatedDateUTC = this.toDisplayDate(this.PulseCreatedDateUTC);
    this.DisplayPulseLastModifiedDateUTC = this.toDisplayDate(this.PulseLastModifiedDateUTC);
    this.DisplayCreatedDate = this.toDisplayDate(this.CreatedDate);
    this.DisplayLastModifiedDate = this.toDisplayDate(this.LastModifiedDate);
    this.DisplayLoanStatusDate = this.toDisplayDate(this.LoanStatusDate);
    this.DisplayBorrowerBirthDate = this.toDisplayDate(this.BorrowerBirthDate);
    this.DisplayCoBorrowerBirthDate = this.toDisplayDate(this.CoBorrowerBirthDate);

    this.DisplayProspectStatusDate = this.toDisplayDate(this.ProspectStatusDate);
    this.DisplayLoanSubmittedDate = this.toDisplayDate(this.LoanSubmittedDate);
    this.DisplayLoanSuspensionDate = this.toDisplayDate(this.LoanSuspensionDate);
    this.DisplayDocsBackDate = this.toDisplayDate(this.DocsBackDate);
    this.DisplayDocsOrderedDate = this.toDisplayDate(this.DocsOrderedDate);
    this.DisplayDocsSentDate = this.toDisplayDate(this.DocsSentDate);
    this.DisplayFloodCertificationOrdered = this.toDisplayDate(this.FloodCertificationOrdered);
    this.DisplayFloodCertificationReceived = this.toDisplayDate(this.FloodCertificationReceived);
    this.DisplayHMDACompletedStatusDate = this.toDisplayDate(this.HMDACompletedStatusDate);
    this.DisplayLoanApplicationDate = this.toDisplayDate(this.LoanApplicationDate);
    this.DisplayLoanApprovedDate = this.toDisplayDate(this.LoanApprovedDate);
    this.DisplayLoanCancelledDate = this.toDisplayDate(this.LoanCancelledDate);
    this.DisplayLoanClosedDate = this.toDisplayDate(this.LoanClosedDate);
    this.DisplayLoanDeniedDate = this.toDisplayDate(this.LoanDeniedDate);
    this.DisplayLoanDocsDrawnDate = this.toDisplayDate(this.LoanDocsDrawnDate);
    this.DisplayLoanFundedDate = this.toDisplayDate(this.LoanFundedDate);
    this.DisplayPreliminaryReportOrderedDate = this.toDisplayDate(this.PreliminaryReportOrderedDate);
    this.DisplayPreliminaryReportReceivedDate = this.toDisplayDate(this.PreliminaryReportReceivedDate);
    this.DisplayResubmitStatusDate = this.toDisplayDate(this.ResubmitStatusDate);
    this.DisplaySentToProcessing = this.toDisplayDate(this.SentToProcessing);
    this.DisplayTILDisclosure_GFEOrderedDate = this.toDisplayDate(this.TILDisclosure_GFEOrderedDate);
    this.DisplayTILDisclosure_GFEReceivedDate = this.toDisplayDate(this.TILDisclosure_GFEReceivedDate);
    this.DisplayAppraisalReportOrderedDate = this.toDisplayDate(this.AppraisalReportOrderedDate);
    this.DisplayAppraisalReportReceivedDate = this.toDisplayDate(this.AppraisalReportReceivedDate);
    this.DisplayClosingDocumentsOrderedDate = this.toDisplayDate(this.ClosingDocumentsOrderedDate);
    this.DisplayClosingDocumentsReceivedDate = this.toDisplayDate(this.ClosingDocumentsReceivedDate);
    this.DisplayClearToCloseDate = this.toDisplayDate(this.ClearToCloseDate);
    this.DisplayLoanRecordedDate = this.toDisplayDate(this.LoanRecordedDate);
    this.DisplayCDDateIssued = this.toDisplayDate(this.CDDateIssued);
    this.DisplayRateLockDate = this.toDisplayDate(this.RateLockDate);
    this.DisplayRateLockExpirationDate = this.toDisplayDate(this.RateLockExpirationDate);
    this.DisplayRateLockConfirmedDate = this.toDisplayDate(this.RateLockConfirmedDate);
    this.DisplayEstimatedClosingDate = this.toDisplayDate(this.EstimatedClosingDate);
    this.DisplayFirstPaymentDate = this.toDisplayDate(this.FirstPaymentDate);
    this.DisplayFundByDate = this.toDisplayDate(this.FundByDate);
  }

  /**
  * Gets the full name of the borrower/coborrower using FirstName, MiddleName, LastName and NameSuffix values.
  *
  * @returns The borrower/coborrower's full name.
  */
  getFullName(first: string, middle: string, last: string, suffix: string = null) {
    var result: string = '';

    result += (((first == undefined) || (first == null)) ? '' : first).trim();
    result += (' ' + (((middle == undefined) || (middle == null)) ? '' : middle));
    result = result.trim();
    result += (' ' + (((last == undefined) || (last == null)) ? '' : last));
    result = result.trim();
    result += (' ' + (((suffix == undefined) || (suffix == null)) ? '' : suffix));
    result = result.trim();

    if ((result == null) || (result.length == 0))
      result = '';

    return result;
  }

  /**
   * Formats a number by adding a '%' to the end of its string representation.
   * 
   * @param value
   */
  getDisplayPercentage(value: number) {
    return ((value == null) ? null : value.toString() + '%');
  }

  /**
   * Formats a number by adding a '$' to the beginning of its string representation and inserting commas.
   *
   * @param value
   */
  getDisplayMoney(value: number) {
    if ((value == undefined) || (value == null))
      return '';
    var result = '';
    var temp = value.toString();
    // if there are decimal places, we only want 2
    if (temp.indexOf('.') >= 0) {
      result = temp.substring(temp.indexOf('.'));
      if (result.length > 3) {
        result = result.substring(0, 3);
      }
      temp = temp.substring(0, temp.indexOf('.'));
    }
    var c = 1; // current character index
    for (var x = temp.length - 1; x >= 0; x--) {
      result = temp[x] + result;
      // insert a comma every third digit
      if ((x > 0) && ((c % 3) == 0))
        result = ',' + result;
      c++;
    }
    // add the dollar sign
    result = '$' + result;
    return result;
  }

  /**
   * Gets the description for the specified LoanPurposeId.
   *
   * @param value
   */
  getDisplayLoanPurposeDesc(loanPurposeId: number) {

    if (loanPurposeId == null)
      return null;

    var result = 'Other';
    switch (loanPurposeId) {
      case 1:
        result = 'Purchase';
        break;
      case 2:
        result = 'Cash-Out Refinance';
        break;
      case 3:
        result = 'No Cash-Out Refinance';
        break;
      case 4:
        result = 'Construction';
        break;
      case 5:
        result = 'Construction Permanent';
        break;
      case 6:
        result = 'Other';
        break;
      default:
        break;
    }

    return result;
  }


  toDisplayDate(value: any) {

    if ((value == undefined) || (value == null))
      return '';

    var date = new Date(value);

    var month = '00' + (date.getMonth() + 1);
    month = month.substring(month.length - 2, month.length);
    var day = '00' + date.getDate();
    day = day.substring(day.length - 2, day.length);

    return (month + '/' + day + '/' + date.getFullYear().toString());
  }

  toLongDisplayDate(value: any) {

    if ((value == undefined) || (value == null))
      return '';

    var months: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    var date = new Date(value);
    var result = '';
    result = months[date.getMonth()];
    result += ' ';
    result += date.getDate();

    var suffix = '';
    switch (date.getDate()) {
      case 1:
      case 21:
      case 31:
        suffix = 'st, '
        break;
      case 2:
      case 22:
        suffix = 'nd, '
        break;
      case 3:
      case 23:
        suffix = 'rd, '
        break;
      default:
        suffix = 'th, '
        break;
    }
    result += suffix;

    result += ' ';
    result += date.getFullYear();

    return result;
  }
}

/**
* Collection of FocusIT.DataContracts.Pulse.NGENMasterRecord [DataContract] objects.
*/
export class NGENMasterRecordCollection
{
  Items: NGENMasterRecord[] = [];
}

