import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core'; 
import { HttpClient } from '@angular/common/http'; 
import { ConfirmationService, MessageService } from 'primeng/api'; 
import { ToastService } from 'src/services/toast.service'; 
import { Campaign } from 'src/models/Campaign';
 
@Component({ 
    encapsulation: ViewEncapsulation.None, 
    selector: 'campaignDescriptionDialog-root', 
    templateUrl: './campaignDescriptionDialog.component.html', 
    styleUrls: ['./campaignDescriptionDialog.component.scss'] 
}) 
export class campaignDescriptionDialogComponent implements OnInit, AfterViewInit { 
  @Input() campaign = new Campaign(); 
 
  // true delivers the event asynchronously, false delivers synchronously. 
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true); 

  /** 
   * Constructor 
   *  
   * @param http HTTPClient required to call actions in any .ts file in the services folder. 
   * @param confirmationService ConfirmationService required for <p-confirmDialog>.
   * @param messageService MessageService required for <p-toast>.
   * @param toastService Common <p-toast> display methods.
   * @param permissionGroupService Service for working with permission groups.
   * @param userSessionService Service for user session information.
   */ 
  constructor(private http: HttpClient, 
    private confirmationService: ConfirmationService, 
    private messageService: MessageService,
    private toastService: ToastService,
    ) { 
  }

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> { 
    // obtain data for the page 
  } 
 
  /**
  * Occurs after view initialization
  */ 
  ngAfterViewInit(): void { 
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  } 
 
  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {
  }

} 
 
