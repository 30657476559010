import { Pipe, PipeTransform } from '@angular/core';
import { FilterTypes } from '../enums/filter-types';

@Pipe({
  name: 'displayFilterOption'
})
export class DisplayFilterOptionPipe implements PipeTransform {

  transform(column: any, selectedColumns: Array<any>): boolean {

    if(column.isFilterable === false){
      return false;
    }
    if(column.filterType === FilterTypes.TEXT && column.filterOptions.length < 2){
      return false;
    }

    if(column.filterType === FilterTypes.DATE && selectedColumns.find((c: any) => c.field === column.field) === undefined){
      return true;
    }

    if(selectedColumns.find((c: any) => c.field === column.field) !== undefined){

      return false;
    }

    return column.display;
  }

}
