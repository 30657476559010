export class UserDefineList {
  public tenantId: string = '00000000-0000-0000-0000-000000000000';
  public listId: string = '00000000-0000-0000-0000-000000000000';
  public listTitle: string = '';
  public filterJson: string = '';
  public isContactOnlyList: boolean;
  constructor(
    tenantId?: string,
    listId?: string,
    listTitle?: string,
    filterJson?: string
  ) {
    if (tenantId !== undefined && tenantId !== null) this.tenantId = tenantId;
    if (listId !== undefined && listId !== null) this.listId = tenantId;
    if (listTitle !== undefined && listTitle !== null) this.listTitle = listTitle;
    if (filterJson !== undefined && filterJson !== null) this.filterJson = filterJson;
    this.isContactOnlyList = false;
  }
}