import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { StorageHelper } from 'src/helpers/StorageHelper';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})

export class LoadingComponent implements OnInit {
  public currentClientID: string;
  public loadingPage: boolean;  
  private storageHelper: StorageHelper;

  constructor(private router: Router) {
    this.storageHelper = new StorageHelper();
    this.currentClientID = environment.clientId;
    if (this.currentClientID === 'AduvoAdmin') {
      this.loadingPage = false;
    } else {
      this.loadingPage = true;
    }
  }

  ngOnInit(): void {
    if (this.storageHelper.GetDataFromStorage("loadingComponentLoadedFirstTime")) {
      let loadedFirstTime = this.storageHelper.GetDataFromStorage("loadingComponentLoadedFirstTime").value;
      if(loadedFirstTime == 'true') {
        let route = window.location.pathname;
        if (route && route == '/') {
          this.router.navigateByUrl('/dashboard');
        }
      }
    }
  }
}
