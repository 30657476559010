<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:15px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="row align-items-center" [formGroupName]="i">
      <!-- <div class="col-auto">
       <label style="font-size: 16px; line-height: 16px; color: #2e2e2e;font-weight: 400;">{{ item.get('label').value
         }}</label>
     </div> -->
      <div class="col-12 mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" [style]="{'width':'100%'}" formControlName="operandDropDown"
          filterBy="name" [filter]="true" filterBy="OpDisplay"></p-dropdown>
      </div>
      <div class="col-12">
        <div *ngIf="item.get('operandDropDown').value?.RhsInputControl1" class="d-flex justify-content-around ">
        <i class="pi pi-reply replytosection col-1 me-2 ms-2"></i>
  <div class="col-11">
    <input maxlength="50" class="form-control rounded" pInputText type="text" id="txtRHSValue{{ i }}"
    formControlName="txtRHSValue" [style]="{'width':'98%'}" 
    [ngClass]="{ 'is-invalid': item.get('txtRHSValue')?.errors}">
  </div>
        </div>
        <div style="color:#FC5151; font-size:11px;position:absolute;font-weight:normal;margin-left:85px;"
          *ngIf="item.get('txtRHSValue')?.touched && item.get('operandDropDown').value?.RhsInputControl1 && item.get('txtRHSValue')?.errors">
          Required.
        </div>
      </div>
      <!-- <div class="col-auto">
       <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button mt-2"
         (click)="deleteRow(i)">
         <i class="pi pi-trash"></i>
       </span>
     </div> -->
    </div>
  </div>
  <!-- <div class="row mt-3" style="padding:15px 30px;">
   <div class="col-12 btn-no-radius">
     <p-button (click)="addMore()" icon="pi pi-plus" label=""></p-button>
   </div>
 </div> -->
  <!-- <hr class="m-0"> -->
  <div class="row" style="padding:15px 30px;">
    <div class="col-12 text-right">
      <!-- <a (click)="cancelFilter()" class="link-text"
        style="color:#2e2e2e;cursor: pointer;margin-right:17.5px;font-weight: normal; line-height:34px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a> -->
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()" label="Apply"></p-button>
    </div>
  </div>
</div>