<div class="row mt-3 mb-2" [formGroup]="monthlyForm">
  <div class="p-col-12" style="padding-left: 45px;">
    <div class="row align-items-baseline">
      <div class="p-col-2 fw-normal">
        <p-radioButton
          (onClick)="OnAnnualChange($event)"
          name="annual"
          value="AMD"
          label="On the"
          formControlName="annual"
        ></p-radioButton>
      </div>
      <div class="p-col-2" style="margin-left: -5%; padding-left: 7.5px;">
        <p-dropdown
          [options]="days"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          placeholder="Select day"
          formControlName="day"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-day pTemplate="item">
            <div class="day-item">
              <div>{{ day.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-3 fw-normal" style="margin-left: -4px; margin-right: 7.5px;">day of every month</div>
      <div class="p-col-4"></div>
    </div>
    <div class="row align-items-baseline">
      <div class="p-col-2 fw-normal">
        <p-radioButton
          (onClick)="OnAnnualChange($event)"
          name="annual"
          value="AMWD"
          label="On the"
          formControlName="annual"
        ></p-radioButton>
      </div>
      <div class="p-col-2" style="margin-left: -5%;">
        <p-dropdown
          [options]="weeks"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="week"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-week pTemplate="item">
            <div class="week-item">
              <div>{{ week.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-3" style="margin-left: -4.5px;">
        <p-dropdown
          [options]="weekdays"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="weekday"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-weekday pTemplate="item">
            <div class="weekday-item">
              <div>{{ weekday.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-2 fw-normal" style="margin-left: -4px;">of every month</div>
      <div class="p-col-2"></div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px;">
    <div class="row">
      <div class="p-col-5">
        <label>Time</label>&nbsp;
        <span *ngIf="messageType == 2 && allowTCPAForTextMessages==true" [title]="" class="fa fa-info-circle info-icon info"
              pTooltip="{{timeToolTipe}}" tooltipPosition="top" tooltipZIndex="9999999" style="width:100px;font-weight: 500!important;color:#BCC3CB!important;font-size: 14px;">
       </span>
      </div>
      <div class="p-col-6" style="margin-left: 15px;">
        <label>Time Zone</label>
      </div>
    </div>
    <div class="row">
      <div class="p-col-2" style="width: 14% !important; padding-right: 7.5px;">
        <p-dropdown
          [options]="hours"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          placeholder="Hour"
          formControlName="hour"
          optionValue="code"
          appendTo="body"
          (onChange)="OnHourChange($event)"
        >
          <ng-template let-hour pTemplate="item">
            <div class="hour-item">
              <div>{{ hour.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-1 align-self-center justify-self-center p-0" style="width: 1%; color: #797979; margin-bottom: 20px;">:</div>
      <div class="p-col-2" style="width: 14% !important; padding-right: 7.5px; padding-left: 7.5px;">
        <p-dropdown
          [options]="minutes"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          placeholder="Minute"
          formControlName="minute"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-minute pTemplate="item">
            <div class="minute-item">
              <div>{{ minute.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-2" style="width: 15% !important; padding-right: 7.5px; padding-left: 7.5px;">
        <p-dropdown
          [options]="meridiems"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="meridiem"
          optionValue="code"
          appendTo="body"
          [disabled]="messageType == 2 && allowTCPAForTextMessages==true"
        >
          <ng-template let-meridiem pTemplate="item">
            <div class="meridiem-item">
              <div>{{ meridiem.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-5" style="padding-right: 7.5px; padding-left: 7.5px;">
        <p-dropdown
          [options]="timeZones"
          optionLabel="displayName"
          [filter]="true"
          filterBy="displayName"
          [showClear]="true"
          resetFilterOnHide="true"
          placeholder="Time Zone"
          formControlName="timeZone"
          optionValue="timezoneId"
          appendTo="body"
        >
          <ng-template let-timeZone pTemplate="item">
            <div class="timeZone-item">
              <div>{{ timeZone.displayName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px;">
    <div class="row">
      <div class="p-col-3">
        <label>Starting</label>
      </div>

      <div class="p-col-3">
        <label>Ending</label>
      </div>
      <div class="p-col-6">&nbsp;</div>
    </div>
    <div class="row">
      <div class="p-col-3">
        <p-calendar
          id="startDate"
          placeholder="mm/dd/yyyy"
          [dateFormat]="dateFormat"
          [minDate]="tomorrowDate"
          formControlName="startDate"
          [readonlyInput]="true"
          [showIcon]="true"
          [disabledDays]="disableDaysArray"
        >
        </p-calendar>
      </div>

      <div class="p-col-3">
        <p-dropdown
          [options]="dateOptions"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="dateOption"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-dateOption pTemplate="item">
            <div class="dateOption-item">
              <div>{{ dateOption.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div *ngIf="isAfter" class="p-col-3">
        <p-calendar
          id="endDate"
          placeholder="mm/dd/yyyy"
          [dateFormat]="dateFormat"
          [minDate]="tomorrowAfterDate"
          formControlName="endDate"
          [readonlyInput]="true"
          [defaultDate]="tomorrowAfterDate"
          [showIcon]="true"
          [disabledDays]="disableDaysArray"
        >
        </p-calendar>
      </div>
      <div *ngIf="isAfter" class="p-col-3">
        <span class="text-danger" *ngIf="monthlyForm.errors">{{monthlyForm.errors?.dates}}</span>
      </div>
    </div>
  </div>
</div>
