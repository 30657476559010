<div id="recommendationsDiv" class="content text-wrapper" role="main">
  <p-card>
    <table width="100%" cellspacing="0" style="margin-top:-1em">
      <tr>
        <td colspan="7">
          <i class="pi pi-user" style="margin-left: .5rem; margin-right:.5rem"></i><b>Recommended For You</b>
          <!-- <font style="margin-left:0.75em; font-style:normal; font-weight: bold; font-size: 0.75em; opacity:0.6">Quick start your marketing campaigns using these pre-made templates.</font> -->
        </td>
        <td colspan="3" align="right">
          <button pButton *ngIf="loadingPage===true" [disabled]="true" type="button" label="Explore More" class="p-button-outlined"></button>
          <button pButton [routerLink]="'/marketing/campaigns'" *ngIf="loadingPage===false" type="button" label="Explore More" class="p-button-secondary" ></button>
        </td>
      </tr>
      <tr><td height="8px"></td></tr>
    </table>

    <div *ngIf="campaigns.length < 1 ">
      &nbsp;&nbsp;No recommendations are currently available for your tenant.
    </div>

    <!-- Skeleton - displayed while getting data -->
    <recommendationsSkeleton-root *ngIf="loadingPage"></recommendationsSkeleton-root>

    <!-- Actual - displayed once we have data -->
    <p-carousel [value]="campaigns" [numVisible]="4" [numScroll]="4" [circular]="false" *ngIf="!loadingPage && campaigns.length > 0">
      <ng-template let-campaign pTemplate="item">
        <div class="product-item" style="padding:1em; height: 100%;">
          <div class="product-item-content" style="padding: 10px; border-radius:4px; box-shadow:0 0 5px #e2e2e2; border-style: solid !important; border-width: 1px !important; border-color: #f1f1f1 !important;height: 100%;">
            <table width="100%" *ngIf="campaign.CampaignId !=='00000000-0000-0000-0000-000000000000'">
              <tr>
                <td><i class="pul pul-campaign"></i>&nbsp;<font style="font-weight:500;">Campaign</font></td>
                <td align="right" width="25%">
                  <a [routerLink]="" (click)="onHide(campaign)"><i class="ico ico-eye-blocked"><font style="font-family: var(--font-family-pulse); font-weight: 400;">&nbsp;<u>Hide</u></font></i></a>
              </tr>
              <tr><td colspan="2" height="4px"></td></tr>
              <tr><td align="center" valign="middle" colspan="2" height="150px" style="background-color:#f4f4f4;"><i class="pi pi-image" style="font-size:3em;"></i></td></tr>
              <tr><td colspan="2" height="4px"></td></tr>
              <tr>
                <td><b>{{campaign.Name}}</b></td>
                <td valign="top" align="right"><p-inputSwitch [disabled]="campaign.CampaignId ==='00000000-0000-0000-0000-000000000000'" (onChange)="onOptInEnabled(campaign)"></p-inputSwitch></td>
              </tr>
              <tr><td colspan="2" height="4px"></td></tr>
              <tr><td colspan="2"><font style="font-family:var(--font-family-pulse); font-size:.8em; font-weight:600">{{campaign?.Template?.Body}}</font></td></tr>
            </table>
          </div>
        </div>
      </ng-template>
    </p-carousel>

  </p-card>
</div>
