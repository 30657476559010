<div id="manageUsersDiv" role="main">
    <div class="content table-search-row d-flex flex-row">
      <table class="text-wrapper">
        <tr>
          <td class="flex flex-row">
            <div class="ml-0 mr-0 flex">
              <label id="lblPageHeader" class="px-1 my-auto">{{name}}</label>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
<div class="text-wrapper mt-4">
    <div class="row" *ngIf="tenantForm">
        <form [formGroup]="tenantForm" class="row">
            <div class="col-4">
                <label for="lenderId">Lender Id</label><br />
                <span class="p-input-icon-right">
                    <input id="lenderId" type="text" formControlName="lenderId" pInputText />
                </span><br />
                <label for="enabled">Enabled</label><br />
                <span class="p-input-icon-right">
                    <p-dropdown id="enabled" formControlName="enabled" [options]="[ {label: 'Yes', value: true},{label: 'No', value: false}]"
                        aria-describedby="enabled" optionLabel="label" optionValue="value"></p-dropdown>
                </span><br />
                <label for="deleted">Deleted</label><br />
                <span class="p-input-icon-right">
                    <p-dropdown id="deleted" formControlName="deleted" [options]="[ {label: 'Yes', value: true},{label: 'No', value: false}]"
                        aria-describedby="deleted" optionLabel="label" optionValue="value"></p-dropdown>
                </span><br />
                <label for="pointCentralSubscriptionId">Point Central Subscription ID</label><br />
                <span class="p-input-icon-right">
                    <input id="pointCentralSubscriptionId" type="text" formControlName="pointCentralSubscriptionId" pInputText />
                </span><br />
                <label for="fulfillmentUtcTime">Fulfillment UTC Time</label><br />
                <span class="p-input-icon-right">
                    <p-calendar 
                        inputId="fulfillmentUtcTime" 
                        formControlName="fulfillmentUtcTime" 
                        [showTime]="true" 
                        [hourFormat]="12" ></p-calendar>
                </span><br />
                <label for="loanOriginationSystem">Loan Origination System</label><br />
                <span class="p-input-icon-right">
                    <p-dropdown id="loanOriginationSystem" formControlName="loanOriginationSystem" 
                        [options]="loadOriginationSystemOptions"
                        aria-describedby="loanOriginationSystem" optionLabel="label" optionValue="value"></p-dropdown>
                </span><br />
            </div>
            <div class="col-8 keys-and-sid-inputs">
                <label for="sendgridApiKeyLoanStatus">Sendgrid API Key Loan Status</label><br />
                <span class="p-input-icon-right w-100">
                    <input id="sendgridApiKeyLoanStatus" class="w-100" [type]="sendgridApiKeyLoanStatusShow ? 'text' : 'password'" formControlName="sendgridApiKeyLoanStatus" pInputText />
                    <i class="pi pi-eye input-icon cursor-pointer" *ngIf="!sendgridApiKeyLoanStatusShow" (click)="sendgridApiKeyLoanStatusShow = true"></i>
                    <i class="pi pi-eye-slash input-icon cursor-pointer" *ngIf="sendgridApiKeyLoanStatusShow" (click)="sendgridApiKeyLoanStatusShow = false"></i>
                </span><br />
                <label for="sendgridApiKeyMarketing">Sendgrid API Key Marketing</label><br />
                <span class="p-input-icon-right w-100">
                    <input id="sendgridApiKeyMarketing" class="w-100" [type]="sendgridApiKeyMarketingShow ? 'text' : 'password'" formControlName="sendgridApiKeyMarketing" pInputText />
                    <i class="pi pi-eye input-icon cursor-pointer" *ngIf="!sendgridApiKeyMarketingShow" (click)="sendgridApiKeyMarketingShow = true"></i>
                    <i class="pi pi-eye-slash input-icon cursor-pointer" *ngIf="sendgridApiKeyMarketingShow" (click)="sendgridApiKeyMarketingShow = false"></i>
                </span><br />
                <label for="twilioAccountSid">Twilio Account SID</label><br />
                <span class="p-input-icon-right w-100">
                    <input id="twilioAccountSid" class="w-100" type="text" formControlName="twilioAccountSid" pInputText />
                </span><br />
                <label for="twilioAPIKey">Twilio API Key</label><br />
                <span class="p-input-icon-right w-100">
                    <input id="twilioAPIKey" class="w-100" [type]="twilioAPIKeyShow ? 'text' : 'password'" formControlName="twilioAPIKey" pInputText />
                    <i class="pi pi-eye input-icon cursor-pointer" *ngIf="!twilioAPIKeyShow" (click)="twilioAPIKeyShow = true"></i>
                    <i class="pi pi-eye-slash input-icon cursor-pointer" *ngIf="twilioAPIKeyShow" (click)="twilioAPIKeyShow = false"></i>
                </span><br />
                <label for="twilioMessagingServiceSid">Twilio Messaging Service SID</label><br />
                <span class="p-input-icon-right w-100">
                    <input id="twilioMessagingServiceSid" class="w-100"  type="text" formControlName="twilioMessagingServiceSid" pInputText />
                </span><br />
                <label for="twilioMessagingServiceSidMarketing">Twilio Messaging Service SID Marketing</label><br />
                <span class="p-input-icon-right w-100">
                    <input id="twilioMessagingServiceSidMarketing" class="w-100" type="text" formControlName="twilioMessagingServiceSidMarketing" pInputText />
                </span><br />
            </div>
        </form>
    </div>
</div>

<div class="flex flex-row w-full sticky-save-footer" *ngIf="isFormChanged">
    <div class="mr-2">
        <ng-container>
            <p-button label="Save Changes" class="header-button" (onClick)="save()" [disabled]="tenantForm.invalid"></p-button>
        </ng-container>
    </div>
    <div class="flex-column justify-content-center">
        <span class="m-auto cancel-text cursor-pointer" (click)="resetForm()">Cancel</span>
    </div>
</div>