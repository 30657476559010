import { Roles } from 'src/app/auth/roles';
import { PermissionGroup } from '../models/enumerations';

/**
* Utility class for working with the PermissionGroup flag enumeration.
* Since the PermissionGroup enumeration can be used as flags, the methods in this class are intended to aid in their implementation.
*/
export class PermissionGroupHelper {

  /**
  * Default constructor
  */
  constructor() { }

  /**
  * Determines if the passed permission group flag(s) includes any of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are tested for inclusion.
  * @returns True if any of the specified permission group flag(s) are included, false otherwise.
  */
  public isAny(permissionGroup: number, permissionGroups: number): boolean { return ((permissionGroup & permissionGroups) != 0); }

  /**
  * Determines if the passed permission group flag(s) includes any of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are tested for inclusion.
  * @returns True if any of the specified permission group flag(s) are included, false otherwise.
  */
  public IsAny(permissionGroup: number, permissionGroups: PermissionGroup[]): boolean {
    if ((permissionGroups == null) || (permissionGroups.length == 0))
      return false;
    permissionGroups.forEach(function (type) {
      //if ((permissionGroup & type) != 0)
      if ((permissionGroup & type) != 0)
        return true;
    });

    return false;
  }

  /**
  * Determines if the passed permission group flag(s) includes all of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are tested for inclusion.
  * @returns True if any of the specified permission group flag(s) are included, false otherwise.
  */
  public isAll(permissionGroup: number, permissionGroups: number): boolean { return ((permissionGroup & permissionGroups) != 0); }


  /**
  * Determines if the passed permission group flag(s) includes all of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are tested for inclusion.
  * @returns True if any of the specified permission group flag(s) are included, false otherwise.
  */
  public IsAll(permissionGroup: number, permissionGroups: PermissionGroup[]): boolean {
    if ((permissionGroups == null) || (permissionGroups.length == 0))
      return false;
    permissionGroups.forEach(function (type) {
      //if ((permissionGroup & type) == 0)
      if ((permissionGroup & type) == 0)
        return false;
    });

    return true;
  }

  /**
  * Adds all of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are to be added.
  * @returns Updated permission group flag(s).
  */
  public add(permissionGroup: number, permissionGroups: number): number { return (permissionGroup | permissionGroups); }

  /**
  * Adds all of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are to be added.
  * @returns Updated permission group flag(s).
  */
  public Add(permissionGroup: number, permissionGroups: PermissionGroup[]): number {
    let result: number = permissionGroup;
    if ((permissionGroups == null) || (permissionGroups.length == 0))
      return result;

    permissionGroups.forEach(function (type) {
      //result = (result | type);
      result = this.OR(result, type);
    });

    return result;
  }

  /**
  * Removes all of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are to be added.
  * @returns Updated permission group flag(s).
  */
  public remove(permissionGroup: number, permissionGroups: number): number { return (permissionGroup & ~permissionGroups); }

  /**
  * Removes all of the specified permission group flag(s).
  * @param permissionGroup Permission group flag(s).
  * @param permissionGroups Permission group flag(s) that are to be added.
  * @returns Updated permission group flag(s).
  */
  public Remove(permissionGroup: number, permissionGroups: PermissionGroup[]): number {
    let result: number = permissionGroup;
    if ((permissionGroups == null) || (permissionGroups.length == 0))
      return result;

    permissionGroups.forEach(function (type) {
      result = (result & ~type);
    });

    return result;
  }

  /**
  * Parses the passed contact type flags into an array of PermissionGroup enumeration values.
  * @param permissionGroup Permission group flag(s) to be parsed.
  * @returns List of PermissionGroup enumeration values.
  */
  public Parse(permissionGroup: number): PermissionGroup[] {
    let result: PermissionGroup[] = [];

    if (this.IsSystemAdmin(permissionGroup))
      result.push(PermissionGroup.FocusITSystemAdmin); // SystemAdmin);

    if (this.IsCompanyAdmin(permissionGroup))
      result.push(PermissionGroup.CompanyAdmin);

    if (this.IsMarketingAdmin(permissionGroup))
      result.push(PermissionGroup.CompanyMarketingAdmin); // MarketingAdmin);

    if (this.IsUser(permissionGroup))
      result.push(PermissionGroup.CompanyUser); // User);

    if (this.HasMarketing(permissionGroup))
      result.push(PermissionGroup.CompanyUserWithMarketing); // Marketing);

    return result;
  }

  // ********************************************************************************
  // Individual Contact Type Determination
  // ********************************************************************************

  /**
  * Determines if the passed permission group flag(s) includes SystemAdmin.
  * @param permissionGroup Permission group flag(s).
  * @returns True if the permission group flag(s) includes SystemAdmin, false otherwise.
  */
  public IsSystemAdmin(permissionGroup: number) {
    if (permissionGroup == PermissionGroup.FocusITSystemAdmin) {
      return true;
    }
    return false;
  }

  /**
  * Determines if the passed permission group flag(s) includes CompanyAdmin.
  * @param permissionGroup Permission group flag(s).
  * @returns True if the permission group flag(s) includes CompanyAdmin, false otherwise. 
  */
  public IsCompanyAdmin(permissionGroup: number) {
    if (permissionGroup == PermissionGroup.CompanyAdmin) {
      return true;
    }
    return false;
  }

  /**
  * Determines if the passed permission group flag(s) includes MarketingAdmin.
  * @param permissionGroup Permission group flag(s).
  * @returns True if the permission group flag(s) includes MarketingAdmin, false otherwise. 
  */
  public IsMarketingAdmin(permissionGroup: number) {
    if (permissionGroup == PermissionGroup.CompanyMarketingAdmin) {
      return true;
    }
    return false;
  }

  /**
  * Determines if the passed permission group flag(s) includes User.
  * @param permissionGroup Permission group flag(s).
  * @returns True if the permission group flag(s) includes LoanOfficerAssistant, false otherwise.
  */
  public IsUser(permissionGroup: number) {
    if (permissionGroup == PermissionGroup.CompanyUser) {
      return true;
    }
    return false;
  }

  /**
  * Determines if the passed permission group flag(s) includes Marketing.
  * @param permissionGroup Permission group flag(s).
  * @returns True if the permission group flag(s) includes LoanOfficerAssistant, false otherwise.
  */
  public HasMarketing(permissionGroup: number) {
    if (permissionGroup == PermissionGroup.CompanyUserWithMarketing) {
      return true;
    }
    return false;
  }

  public CanUseMarketing(permissions: number): boolean {
    var permissionGroupHelper = new PermissionGroupHelper();
    return permissionGroupHelper.HasMarketing(permissions) || permissionGroupHelper.IsCompanyAdmin(permissions)
      || permissionGroupHelper.IsMarketingAdmin(permissions) || permissionGroupHelper.IsSystemAdmin(permissions);
  }

  // ********************************************************************************
  // End Individual Contact Type Determination
  // ********************************************************************************

  // ********************************************************************************
  // Add Individual Contact Type
  // ********************************************************************************

  /**
  * Adds the SystemAdmin contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public AddSystemAdmin(permissionGroup: number): number { return (permissionGroup | PermissionGroup.FocusITSystemAdmin); }

  /**
  * Adds the CompanyAdmin contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public AddCompanyAdmin(permissionGroup: number): number { return (permissionGroup | PermissionGroup.CompanyAdmin); }

  /**
  * Adds the MarketingAdmin contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public AddMarketingAdmin(permissionGroup: number): number { return (permissionGroup | PermissionGroup.CompanyMarketingAdmin); }

  /**
  * Adds the User contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public AddUser(permissionGroup: number): number { return (permissionGroup | PermissionGroup.CompanyUser); }

  /**
  * Adds the Marketing contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public AddMarketing(permissionGroup: number): number { return (permissionGroup | PermissionGroup.CompanyUserWithMarketing); }

  // ********************************************************************************
  // End Add Individual Contact Type
  // ********************************************************************************


  // ********************************************************************************
  // Remove Individual Contact Type
  // ********************************************************************************

  /**
  * Removes the SystemAdmin contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public RemoveSystemAdmin(permissionGroup: number): number { return (permissionGroup & ~PermissionGroup.FocusITSystemAdmin); }

  /**
  * Removes the CompanyAdmin contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public RemoveCompanyAdmin(permissionGroup: number): number { return (permissionGroup & ~PermissionGroup.CompanyAdmin); }

  /**
  * Removes the MarketingAdmin contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public RemoveMarketingAdmin(permissionGroup: number): number { return (permissionGroup & ~PermissionGroup.CompanyMarketingAdmin); }

  /**
  * Removes the User contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public RemoveUser(permissionGroup: number): number { return (permissionGroup & ~PermissionGroup.CompanyUser); }

  /**
  * Removes the Marketing contact type flag.
  * @param permissionGroup Permission group flag(s).
  * @returns Updated permission group flag(s).
  */
  public RemoveMarketing(permissionGroup: number): number { return (permissionGroup & ~PermissionGroup.CompanyUserWithMarketing); }


  // ********************************************************************************
  // End Remove Individual Contact Type
  // ********************************************************************************

  // ********************************************************************************
  // Conversion To String
  // ********************************************************************************

  /**
  * Returns a comma separated string of the passed contact type flag descriptions.
  * TODO: Place these in order of precedence.
  * @param permissionGroup Permission group flag(s) to be parsed.
  * @returns Comma separated string of the passed contact type flags descriptions.
  */
  public Descriptions(permissionGroup: number): string {
    let result: string = '';

    if (this.IsSystemAdmin(permissionGroup))
      result += (((result.length > 0) ? ', ' : '') + Roles.FocusITSystemAdmin);

    if (this.IsCompanyAdmin(permissionGroup))
      result += (((result.length > 0) ? ', ' : '') + 'Company Administrator');

    if (this.IsUser(permissionGroup))
      result += (((result.length > 0) ? ', ' : '') + 'Standard User');

    if (this.HasMarketing(permissionGroup))
      result += (((result.length > 0) ? ', ' : '') + 'Standard User with Marketing');

    if (this.IsMarketingAdmin(permissionGroup))
      result += (((result.length > 0) ? ', ' : '') + 'Marketing Administrator');

    else result += (((result.length > 0) ? ', ' : '') + Roles.FocusITUser);

    return result;
  }

  /**
  * Returns the display string of the passed permission group flag(s).
  * The display string is the first description returned by the Descriptions() method.
  * Result depends upon the order of precedence defined in Descriptions() method.
  * @param permissionGroup Permission group flag(s) to be parsed.
  * @param addEllipse Adds '...' to the end of the string indicating the contact has multiple types defined (default = false).
  * @returns Display string of the passed permission group flag(s).
  */
  public DisplayString(permissionGroup: number, addEllipse: boolean = false): string {
    let descriptions: string = this.Descriptions(permissionGroup);
    let result: string = '';

    var parts = descriptions.split(',');
    if (parts.length > 0)
      result = parts[0];

    if ((parts.length > 1) && ((addEllipse === undefined) || (addEllipse)))
      result += "...";

    return result;
  }

  // ********************************************************************************
  // End Conversion To String
  // ********************************************************************************

}
