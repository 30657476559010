import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';

@Component({
  selector: 'app-impersonation-bar',
  templateUrl: './impersonation-bar.component.html',
  styleUrls: ['./impersonation-bar.component.scss']
})
export class ImpersonationBarComponent implements OnInit {

  user: UserToken;
  role: string;
  constructor(public oidcSecurityService: OidcSecurityService, private userTokenService: UserTokenService) { 

    this.user = this.userTokenService.getUser();
    if (this.user.role == "Company Admin") {
      this.role = "Company Administrator";
    } else if (this.user.role == "Marketing Admin") {
      this.role = "Marketing Administrator";
    } else if (this.user.role == "Company User") {
      this.role = "Standard User";
    } else if (this.user.role == "Company User with Marketing") {
      this.role = "Standard User With Marketing";
    } else {
      this.role = this.user.role;
    }
  }

  ngOnInit(): void {
  }

  
  changeImpersonatedUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.oidcSecurityService.authorize({ customParams: { 'exchange_style': 'impersonation' } })
  }

}
