
export class LoanStatusTypes {
  name: string
  code: string
}

export class PeopleAndLoanFilterOutput {
  constructor(public isSuccess: boolean, public peopleAndLoanFilterData: PeopleAndLoanFilterData | null) {

  }

}
//--- for json
export class PeopleAndLoanFilterData {
  scope: Scope
  PeopleLoanList: PeopleLoanList
  DisplayHtml: String;
  constructor() {
    this.scope = new Scope();
    this.PeopleLoanList = new PeopleLoanList();
    this.DisplayHtml = '';
  }
}

export class Scope {
  name: string
  LoanStatusTypes: string[]
  constructor() {
    this.name = 'all-loans';//latest/all-loans
    this.LoanStatusTypes = [];
  }
}

export class PeopleLoanList {
  type: string
  PeopleLoanListAndFilters: PeopleLoanListAndFilter[]
  constructor() {
    this.type = '';
    this.PeopleLoanListAndFilters = [];
  }
}

export class PeopleLoanListAndFilter {
  id: number;
  peopleAndLoansFieldLookupId: string;
  isDeleted: boolean
  group: string
  subGroup: string;
  datasource: string
  datacolumn: string
  type: string

  PeopleLoanListOrFilters: PeopleLoanListOrFilter[];
  constructor() {
    this.id = 0;
    this.PeopleLoanListOrFilters = [];
    this.isDeleted = false;
    this.subGroup = '';
  }
}

export class PeopleLoanListOrFilter {
  id: number
  lhs: any
  operator: string
  rhs: string[]
  criteria: string
  displayHtml: string
  peopleAndLoansTypeDefLookupId: string;
  //peopleAndLoansFieldLookupId_: string;
}

export class HtmlData {
  constructor(public html: string, public lookupId: string, public peopleAndLoansTypeDefLookupId: string,
    public id: number, public categoryType: string, public countN: number = 0, public index: number = 0, public isQuickFilter: boolean = false) { }
}

export class RhsQueryData {
  constructor(public cacheKey: string, public query: string, public jsonStr: string = '', public id: number = 0, public queryKey: string = '') {
  }
}
export class RhsDropdownItem {
  //CriteriaStr is optional
  constructor(public Code: string, public Name: string, public CriteriaStr: string = '') {
  }
}
export class RhsDropdownItem2 {
  //CriteriaStr is optional
  constructor(public code: string, public name: string, public criteriaStr: string = '') {
  }
}
export class RhsDropdown {
  constructor(public lookupId: string, public rhsDropdownItem: RhsDropdownItem[]) {
  }
}

export class PeopleAndLoanEditTagData {
  constructor(public andIndex: number = 0,
    public peopleAndLoansFieldLookupId: string = '',
    public defLookupId: string = '',
    public labelStr: string = '') {
    //defLookupId can be empty
  }
}


export class PeopleAndLoanEditTagData111 {
  constructor(public andIndex: number = 0,
    public peopleAndLoansFieldLookupId: string = '',
    public defLookupId: string = '',
    public labelStr: string = '') {
    //defLookupId can be empty
  }
}


export class PeopleAndLoanQueryParam {
  constructor(public dateRange: string,
    public loanStatusField: string,
    public operand: string) {
      
  }
}


export class PeopleAndLoanFieldLookup {
  rno: number
  PeopleAndLoansFieldLookupId: string
  PeopleAndLoansTypeDefDataType: string
  FieldGroupName: string
  FieldSubGroupName: any
  DataColumn: string
  DataSource: string
  DataColumnDisplay: string
  PeopleAndLoansTypeDefLookupId: string
  DataType: string
  OpCode: string
  OpDisplay: string
  LhsInputControl: any
  LhsSelectInputControlSqlCmd: any
  RhsInputControl1: string
  RhsInputControl1Mask: string
  RhsInputControl2: any
  RhsInputControl2Mask: any
  RhsSelectInputControlSqlCmd: any
  DisplayHtmlTemplate: string
  Criteria: string
  isAddFlag: number

  constructor() {
    this.isAddFlag = 0;//1: Add (add as blank)
  }
}



