import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { OnBoardSetupAdminTenant } from 'src/models/NGENTenant';
import { ToastService } from 'src/services/toast.service';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { OnBoardingService } from '../../../services/onBoarding.service';
import { environment } from 'src/environments/environment';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';

declare var _ps: any;

@Component({
  selector: 'app-add-individual',
  templateUrl: './add-individual.component.html',
  styleUrls: ['./add-individual.component.scss']
})

export class AddIndividualComponent implements OnInit, AfterViewInit {
  companyForm3: FormGroup | any = null;  
  public loadingPage: boolean = false;
  public isValidCompanyAdminEmail: boolean;
  public user: UserToken;
  public tenant: OnBoardSetupAdminTenant = new OnBoardSetupAdminTenant();
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private onBoardingService: OnBoardingService,
    private userTokenService: UserTokenService
  ) {
      window['_ps'] = null;
      this.user = this.userTokenService.getUser();
      this.storageHelper = new StorageHelper();
      let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
      this.navigationStack = stack?.value;

      if (window['_ps'] == null || window['_ps'] == undefined) {
        (function (w, d, s, c, f, n, t, g, a, b, l) {
          // Defines the global _ps object and initializes the _ps() function
          // that will queue commands until the Ironclad Clickwrap Library is ready.
          w['PactSafeObject'] = n;
          w[n] = w[n] || function () {
            (w[n].q = w[n].q || []).push(arguments)
          },
  
          // Defines the event functions for the global _ps object.
          w[n].on = function () {
            (w[n].e = w[n].e || []).push(arguments)
          },
          w[n].once = function () {
            (w[n].eo = w[n].eo || []).push(arguments)
          },
          w[n].off = function () {
            (w[n].o = w[n].o || []).push(arguments)
          },
  
          // Marks the time that the script is inserted.
          w[n].t = 1 * new Date().getTime(),
          w[n].l = 0;
  
          // Inserts a new script element to load the Ironclad Clickwrap Library JS file (ps.js).
          a = d.createElement(s);
          b = d.getElementsByTagName(s)[0];
          a.async = 1;
          a.src = c;
  
          // Marks that the script has started loading or failed to load.
          a.onload = a.onreadystatechange = function () { w[n].l = 1 };
          a.onerror = a.onabort = function () { w[n].l = 0 };
          b.parentNode.insertBefore(a, b);
  
          // Retry loading the script from a fallback location after 4 seconds.
          setTimeout(function () {
            if (!w[n].l && !w[n].loaded) {
              w[n].error = 1;
              a = d.createElement(s);
              a.async = 1;
              a.src = f;
              a.onload = a.onreadystatechange = function () { w[n].l = 1 };
              a.onerror = a.onabort = function () { w[n].l = 0 };
              b.parentNode.insertBefore(a, b);
  
              // Log the loading error via beacon.
              l = function (u, e) {
                try {
                  e = d.createElement('img');
                  e.src = 'https://d3r8bdci515tjv.cloudfront.net/error.gif?t=' + w[n].t + '&u=' + encodeURIComponent(u);
                  d.getElementsByTagName('body')[0].appendChild(e);
                }
                catch (x) { }
              };
              l(c);
  
              // Call the optional error callback function after a second failed attempt.
              setTimeout(function () {
                if (!w[n].l && !w[n].loaded) {
                  w[n].error = 1;
                  if (g && 'function' == typeof g) {
                    g.call(this);
                  }
                  l(f);
                }
              }, t);
            }
          }, t);
        })(window, document, 'script', '//vault.pactsafe.io/ps.min.js', '//d3l1mqnl5xpsuc.cloudfront.net/ps.min.js', '_ps', 4000, function optionalErrorCallback() { alert('Unable to load the JS Library.') });
  
        // Creates a Site object with the default configuration.
        _ps('create', environment.ironcladSiteId);
      }
  }

  ngOnInit(): void {
    this.createForm();

    var groupKey = "embedded-additionaladmin";
    _ps("load", groupKey, { container_selector: "clickwrapContainer", signer_id: this.user.email, display_all: true });
    _ps("set", "signer_id", this.user.email);
    _ps("send", "updated", { custom_data: { first_name: this.user.given_name, last_name: this.user.family_name, company_name: this.user.TenantName, lender_id: this.user.TenantId } });

    // Submits the form once the contracts have been accepted.
    _ps.on('valid', function (parameters, group) {
      // do nothing
    }.bind(this));

    /******************************
     * Tracking acceptance and getting the contract
     * Acceptance of a Clickwrap Group, by default, is sent when your signer checks a checkbox or clicks the “I Agree” button. To send 
     * acceptance outside of that default workflow, you can follow the below syntax:
     * Note: Sending acceptance like this requires a callback
    *******************************/
    _ps(groupKey, "agreed", {
      // The event_callback function will be invoked once the "send" is complete.
      event_callback: function (err, eventType, clickwrapGroup, payload) {
        if (err) {
          console.log(err);
        }
        // The send is complete and acceptance was captured successfully.
      }.bind(this)
    });
  }

  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => { this.setControlStates(); }, 10);
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() { }

  createForm() {
    return new Promise((resolve, reject) => {
      this.companyForm3 = this.formBuilder.group({
        companyAdminFirstName: [null, [Validators.required]],
        companyAdminLastName: [null, [Validators.required]],
        companyAdminEmail: [null, [Validators.required]],
      });
      resolve(true);
    });
  }

  async saveAccount() {
    this.loadingPage = true;
    try {
      let viewModel = new OnBoardSetupAdminTenant(
        this.tenant.TenantId,        
        this.companyForm3.value.companyAdminFirstName,
        this.companyForm3.value.companyAdminLastName,
        this.companyForm3.value.companyAdminEmail
      );

      const formData: FormData = new FormData();      
      formData.append('companyAdminFirstName', viewModel.CompanyAdminFirstName);
      formData.append('companyAdminLastName', viewModel.CompanyAdminLastName);
      formData.append('companyAdminEmail', viewModel.CompanyAdminEmail);
      formData.append('companyAdminEmailReadonly', 'false');

      var response = await this.onBoardingService.OnBoardingAdminProfile(formData);
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      }
      else if (response.status == 200) {
        let mes = "User was created and emailed the instructions to " + viewModel.CompanyAdminEmail;
        this.toastService.showSuccess(mes);
        let navigateTo = '/add-admin';
        let navigateFrom = '/add-individual';
        this.navigationStack.push(navigateFrom);
        this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
        this.router.navigate([navigateTo]);
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  validateCompanyAdminEmail() {
    if (this.companyForm3.value.companyAdminEmail == "") {
      this.isValidCompanyAdminEmail = true;
    }
    else {
      this.isValidCompanyAdminEmail = this.validateEmail(this.companyForm3.value.companyAdminEmail);
    }
  }

  validateEmail(emailAddress: any) {
    let isValid = false;
    const singleEmailRegularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = singleEmailRegularExpression.test(String(emailAddress).toLowerCase());
    return isValid;
  }

  redirectToBack(): void {
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    if(this.navigationStack.length > 0) {      
      var navigateBack = this.navigationStack.pop();
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      //navigate back to ['/add-admin'] or ['/send-query']
      this.router.navigate([navigateBack]);
    }
  }

  redirectToAddAdmin(): void {
    var element = <HTMLInputElement>document.getElementById("ps-contract-checkbox-281053");
    var contractChecked = false;
    if(element) {
      contractChecked = element.checked;
    }

    if(contractChecked) {
      if (this.companyForm3.valid) {
        this.validateCompanyAdminEmail();
  
        if (this.isValidCompanyAdminEmail) {
          this.saveAccount();
        } else {
          this.toastService.showError("Email address is invalid");
        }
      }
    } else {
      this.toastService.showError("Please accept Additional Admin Acknowledgement Confirmation.");
    }
  }
}
