import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from '../../../../../../src/app/services/CalendarAndTimeZoneService';
import { CalendarEventService } from 'src/app/services/CalendarEventService';
import { formState } from 'src/helpers/formState.helper';
import { CMSComponentData, RepeatingYearlyCustomData } from '../../../../../../src/models/MessageScheduleData';
import { LookupService } from 'src/services/lookup.service';
import { ToastService } from 'src/services/toast.service';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-repeating-custom-date',
  templateUrl: 'campaign-message-schedule-repeating-custom-date.component.html',
})
export class CampaignMessageScheduleRepeatingCustomDateComponent
  implements OnInit
{
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public CMSComponentData: CMSComponentData;
  public repeatingYearlyCustomData: RepeatingYearlyCustomData;

  customDateForm: FormGroup | any = null;
  validator: formState = new formState();
  isFormValueChane = false;
  isAfter = false;
  isMonthChange = false;
  hours: any[];
  minutes: any[];
  meridiems: any[];
  months: any[];
  weeks: any[];
  days: any[];
  daysArray: any[];
  weekdays: any[];
  timeZones: any[];
  startYears: any[];
  endYearOptions: any[];
  selectedMonth: number;
  todayDate = new Date();
  userTimeZoneId: any;
  userToken: UserToken;
  defaultDate: number;
  defaultWeek: string;
  defaultMonth: string;
  defaultWeekDay: string;
  defaultMonthAMWD: string;
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  constructor(
    private http: HttpClient,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private userTokenService: UserTokenService,
    public datePipe: DatePipe
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(4);
    this.repeatingYearlyCustomData = new RepeatingYearlyCustomData();
    this.selectedMonth = 1;
    this.userToken = this.userTokenService.getUser();
    this.messageType =
      this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe =
      'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
  }

  ngOnInit(): void {
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;

    this.weeks = this.calendarAndTimeZoneService.GetWeeks();
    this.weekdays = this.calendarAndTimeZoneService.GetWeekDays();
    this.months = this.calendarAndTimeZoneService.GetMonths();
    this.days = this.calendarAndTimeZoneService.getListOfDays(
      this.selectedMonth
    );
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    this.startYears = this.calendarAndTimeZoneService.GetStartYears();
    this.endYearOptions = this.calendarAndTimeZoneService.GetEndYears();
    forkJoin(this.lookupService.GetTimezones()).subscribe(
      ([timezonesresponse]) => {
        this.timeZones = timezonesresponse.data;
        this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
        let dateTimeHelper = new DateTimeHelper();
        let defaultObject =
          this.calendarAndTimeZoneService.getDefaultDateTimeObject(
            this.userTimeZoneId,
            this.timeZones
          );
        //this.todayDate.setDate(this.todayDate.getDate() +15);
        if (defaultObject.defaultDate.getDate() == this.days.length) {
          this.defaultDate = 0;
        } else {
          this.defaultDate = defaultObject.defaultDate.getDate();
        }
        if (
          defaultObject.defaultDate.getDay() == 7 ||
          defaultObject.defaultDate.getDay() == 0
        ) {
          this.defaultWeekDay = '0';
        } else {
          this.defaultWeekDay = (
            defaultObject.defaultDate.getDay() + 1
          ).toString();
        }
        this.defaultMonth = (
          defaultObject.defaultDate.getMonth() + 1
        ).toString();
        this.defaultMonthAMWD = (
          defaultObject.defaultDate.getMonth() + 1
        ).toString();
        //this.defaultWeek = this.datePipe.transform(this.todayDate, 'W');

        this.defaultWeek = this.calendarAndTimeZoneService
          .getOrdinal(defaultObject.defaultDate)
          .toString();
        let defaultData = {
          //campaignScheduleEventRepeatingYearlyCustomId: '',
          day: this.defaultDate,
          week: this.defaultWeek,
          repeatingMonthlyType: 'AMD',
          month: this.defaultMonth,
          monthAMWD: this.defaultMonthAMWD,
          timezoneId: defaultObject.defaultTimeZoneId,
          weekday: this.defaultWeekDay,
          endingAfterNoccurrences: 0,
          endYearOption: 'NEVER',
          startingYear: this.todayDate.getFullYear(),
          hour: defaultObject.defaultHour,
          min: defaultObject.defaultMinute,
          amPM:
            this.messageType == 2 && this.allowTCPAForTextMessages
              ? this.calendarAndTimeZoneService.getMeridiem(
                  defaultObject.defaultHour
                )
              : defaultObject.defaultMeridiem,
          time: '',
          txtOccurrence: '1',
        };
        let savedObject = new RepeatingYearlyCustomData();
        if (
          scheduleDataJSON &&
          scheduleDataJSON != 'No Schedule' &&
          scheduleType == 'Repeating' &&
          ScheduleSubtype == 'YearlyCustom'
        ) {
          savedObject = JSON.parse(scheduleDataJSON)[0];
          if (savedObject) {
            //defaultData.campaignScheduleEventRepeatingYearlyCustomId = savedObject.campaignScheduleEventRepeatingYearlyCustomId;
            defaultData.repeatingMonthlyType = savedObject.repeatingMonthlyType;
            defaultData.timezoneId = savedObject.timezoneId;
            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              defaultData.hour = parseInt(time.hours);
              defaultData.min = parseInt(time.minutes);
              defaultData.amPM = time.AmPM;
            }
            //defaultData.time = savedObject.time;
            defaultData.startingYear = parseInt(savedObject.startingYear);
            defaultData.txtOccurrence =
              savedObject.endingAfterNoccurrences == ''
                ? defaultData.txtOccurrence
                : savedObject.endingAfterNoccurrences;

            if (savedObject.repeatingMonthlyType == 'AMD') {
              defaultData.day = parseInt(
                savedObject?.onTheXofEveryMonthOrdinal ?? '1'
              );
              defaultData.weekday = defaultData.weekday;
              defaultData.month = savedObject.month;
              defaultData.week = defaultData.week;
              defaultData.monthAMWD = defaultData.monthAMWD;
            } else {
              defaultData.weekday = savedObject.weekday;
              defaultData.week = savedObject.everyXweekdayOfEveryMonthOrdinal;
              defaultData.monthAMWD = savedObject.month;
              defaultData.month = defaultData.month;
            }

            defaultData.endYearOption =
              savedObject.endingAfterNoccurrences == '' ? 'NEVER' : 'AFTER';
          }
        }

        this.createForm(defaultData);
      }
    );
  }
  //this.repeatingYearlyCustomData.endingAfterNoccurrences ==0 nEVER ELSE AFTER
  createForm(defaultData) {
    this.customDateForm = this.formBuilder.group({
      annual: [defaultData.repeatingMonthlyType],
      month: [defaultData.month],
      monthAMWD: [defaultData.monthAMWD],
      day: [defaultData.day],
      week: [defaultData.week],
      weekday: [defaultData.weekday],
      hour: [defaultData.hour],
      minute: [defaultData.min],
      meridiem: [defaultData.amPM],
      timeZone: [defaultData.timezoneId, Validators.required],
      startYear: [defaultData.startingYear],
      endYearOption: [defaultData.endYearOption],
      txtOccurrence: [
        defaultData.txtOccurrence,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          this.calendarAndTimeZoneService.occurrenceRangeValidator,
        ],
      ],
    });
    this.customDateForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChane = true;

      if (
        this.customDateForm.value.annual == 'AMD' &&
        this.repeatingYearlyCustomData.month != this.customDateForm.value.month
      ) {
        this.isMonthChange = true;
        this.selectedMonth = this.customDateForm.value.month;
      }
      if (this.isMonthChange == true) {
        this.isMonthChange = false;
        let selectedDay = Number(this.customDateForm.value.day);

        this.days = this.calendarAndTimeZoneService.getListOfDays(
          this.selectedMonth
        );
        if (this.selectedMonth == 2 && this.days.length <= selectedDay) {
          this.customDateForm.value.day = 1;
        } else {
          this.customDateForm.value.day =
            this.days.length < selectedDay ? 0 : selectedDay;
        }
      }
      this.fillFormData();
    });
    if (this.isFormValueChane == false) {
      this.fillFormData();
    }
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.customDateForm.valid;
    this.CMSComponentData.componentNumber = 4;
    this.CMSComponentData.componentValueJSON = this.repeatingYearlyCustomData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
  fillFormData() {
    this.repeatingYearlyCustomData.campaignMessageId = '';
    this.repeatingYearlyCustomData.repeatingMonthlyType =
      this.customDateForm.value.annual;
    this.repeatingYearlyCustomData.month =
      this.customDateForm.value.annual == 'AMD'
        ? this.customDateForm.value.month
        : this.customDateForm.value.monthAMWD;
    this.repeatingYearlyCustomData.weekday = this.customDateForm.value.weekday;
    this.repeatingYearlyCustomData.onTheXofEveryMonthOrdinal =
      this.customDateForm.value.day.toString();
    this.repeatingYearlyCustomData.everyXweekdayOfEveryMonthOrdinal =
      this.customDateForm.value.week;
    this.repeatingYearlyCustomData.timezoneId =
      this.customDateForm.value.timeZone;
    this.repeatingYearlyCustomData.timezoneInfoId = this.timeZones.find(
      (x) =>
        x.timezoneId.toLowerCase() === this.repeatingYearlyCustomData.timezoneId
    )?.timezoneInfoId;
    let hr = Number(this.customDateForm.value.hour);
    let mi = Number(this.customDateForm.value.minute);
    let mer = this.customDateForm.value.meridiem;
    this.repeatingYearlyCustomData.time =
      this.calendarAndTimeZoneService.getTimeSpan(hr, mi, mer);

    if (this.customDateForm.value.startYear) {
      this.repeatingYearlyCustomData.startingYear =
        this.customDateForm.value.startYear.toString();
    }
    if (this.customDateForm.value.endYearOption == 'NEVER') {
      this.isAfter = false;
      this.repeatingYearlyCustomData.endingAfterNoccurrences = '0';
      this.repeatingYearlyCustomData.dateOption = 'NEVER';
    } else {
      this.isAfter = true;
      this.repeatingYearlyCustomData.dateOption = 'AFTER';
      this.repeatingYearlyCustomData.endingAfterNoccurrences =
        this.customDateForm.value.txtOccurrence.toString();
    }
    this.doEmit();
  }
  OnAnnualChange(event) {
    let selectedValue = this.customDateForm.value.annual;
  }
  OnOptionChange(event) {
    let selectedValue = this.customDateForm.value.endYearOption;
    if (selectedValue == 'NEVER') {
      this.customDateForm.patchValue({
        txtOccurrence: '1',
      });
      // this.customDateForm.get('txtOccurrence').clearValidators(); // Clear All Validators
      // this.customDateForm.get('txtOccurrence').updateValueAndValidity();
    }
  }
  OnHourChange(event) {
   if (this.messageType == 2 && this.allowTCPAForTextMessages) {
     let selectedHour = this.customDateForm.value.hour;
     this.customDateForm.patchValue({
       meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
     });
   }
  }
}
