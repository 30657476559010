import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { CMS_Data } from "../models/MessageScheduleData";
@Injectable({ providedIn: 'root' })
export class CommunicationService {
    // Observable string sources
    //private CMS_UpdateSource = new ReplaySubject<CMS_Data>(1);
    private CMS_UpdateSource = new Subject<CMS_Data>();
    // Observable string streams
    CMS_Schedule$ = this.CMS_UpdateSource.asObservable();
    // Service message commands
    CMS_Update(data: CMS_Data) {
        this.CMS_UpdateSource.next(data);
    }

    // //Subject to open Campaign Wizard
    // private Campaign_OpenWizard = new BehaviorSubject<boolean>(false);
    // // Observable string streams
    // Campaign_OpenWizard$ = this.Campaign_OpenWizard.asObservable();
    // // Service message commands
    // CampaignOpenWizard(flag: boolean) {
    //     this.Campaign_OpenWizard.next(flag);
    // }

}