<div
  style="font-weight: 500; color: #2e2e2e; padding: 10px 0px 20px 25px"
  [formGroup]="oneTimeCustomDateForm"
>
  <div class="row" *ngIf="currentClientID !== 'AduvoAdmin'">
    <div class="col-4">
      <p-radioButton
        name="sendOnTimeType"
        value="ATSPECIFICTIME"
        label="At a specific date and time"
        formControlName="sendOnTimeType"
        (onClick)="onSendOnTimeTypeChanged($event)" 
      ></p-radioButton>
    </div>
    <div class="col-8">
      <p-radioButton
        name="sendOnTimeType"
        value="ASAP"
        label="As soon as possible"
        formControlName="sendOnTimeType"
        (onClick)="onSendOnTimeTypeChanged($event)" 
      ></p-radioButton>
    </div>
  </div>
  <div class="row" *ngIf="!showTimeControls">
    <div class="col-4"></div>
    <div class="col-8 asap-msg" *ngIf="messageType == 1">
      Large lists can take up to 2 hours to process
    </div>
     <div class="col-8 asap-msg" *ngIf="messageType == 2" >
      The soonest SMS messages will send is 30 minutes. Large lists can take up to 2 hours to send.
    </div>
  </div>
  <div class="row" *ngIf="showTimeControls">
    <div class="col-12">
      <div class="row mt-3 mb-2">
        <div class="col">Deliver On</div>
      </div>
      <div class="row" id="date-msg-schedule" style="width: 27.5%">
        <div class="col">
          <p-calendar
            id="deliverOnDate"
            placeholder="mm/dd/yyyy"
            [dateFormat]="dateFormat"
            [minDate]="todayDate"
            formControlName="deliverOnDate"
            [readonlyInput]="true"
            [showIcon]="true"
            appendTo="body"
            [disabledDays]="disableDaysArray" 
          >
          </p-calendar>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-5">
          Time
          <span
            *ngIf="messageType == 2 && allowTCPAForTextMessages == true"
            [title]=""
            class="fa fa-info-circle info-icon info"
            pTooltip="{{ timeToolTipe }}"
            tooltipPosition="top"
            tooltipZIndex="9999999"
            style="width: 100px"
          >
          </span>
        </div>
        <div class="col-6" style="margin-left: 12px">Time Zone</div>
      </div>
      <div class="row mb-2" id="holiday-time-schedule">
        <div class="col-2" style="width: 14% !important; padding-right: 7.5px">
          <p-dropdown
            [options]="hours"
            optionLabel="name"
            [filter]="false"
            filterBy="name"
            [showClear]="false"
            placeholder="Hour"
            formControlName="hour"
            optionValue="code"
            appendTo="body"
            (onChange)="OnHourChange($event)"
          >
            <ng-template let-hour pTemplate="item">
              <div class="hour-item">
                <div>{{ hour.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div
          class="col-1 align-self-center justify-self-center p-0"
          style="width: 1%; color: #797979"
        >
          :
        </div>
        <div
          class="col-2"
          style="
            width: 14% !important;
            padding-right: 7.5px;
            padding-left: 7.5px;
          "
        >
          <p-dropdown
            [options]="minutes"
            optionLabel="name"
            [filter]="false"
            filterBy="name"
            [showClear]="false"
            placeholder="Minute"
            formControlName="minute"
            optionValue="code"
            appendTo="body"
          >
            <ng-template let-minute pTemplate="item">
              <div class="minute-item">
                <div>{{ minute.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div
          class="col-2"
          style="
            width: 15% !important;
            padding-right: 7.5px;
            padding-left: 7.5px;
          "
        >
          <p-dropdown
            [options]="meridiems"
            optionLabel="name"
            [filter]="false"
            filterBy="name"
            [showClear]="false"
            formControlName="meridiem"
            optionValue="code"
            appendTo="body"
            [disabled]="messageType == 2 && allowTCPAForTextMessages == true"
          >
            <ng-template let-meridiem pTemplate="item">
              <div class="meridiem-item">
                <div>{{ meridiem.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="col-5" style="padding-right: 7.5px; padding-left: 7.5px">
          <p-dropdown
            [options]="timeZones"
            optionLabel="displayName"
            [filter]="true"
            filterBy="displayName"
            [showClear]="true"
            resetFilterOnHide="true"
            placeholder="Time Zone"
            formControlName="timeZone"
            optionValue="timezoneId"
            appendTo="body"
          >
            <ng-template let-timeZone pTemplate="item">
              <div class="timeZone-item">
                <div>{{ timeZone.displayName }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showTimeControls">
    <div class="col-12">
      <span class="text-danger">{{ ScheduleDateMessage }}</span>
    </div>
  </div>
   <div class="row" *ngIf="!showTimeControls">
    <div class="col-12">
      <span class="text-danger">{{ ScheduleTCPAForTextMessages }}</span>
    </div>
  </div>
</div>
