import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageTemplate } from 'src/models/MessageTemplate';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-make-a-copy',
  templateUrl: './make-a-copy.component.html',
  styleUrls: ['./make-a-copy.component.scss'],
})
export class MakeACopyComponent implements OnInit {
  makeACopyForm: FormGroup | any = null;
  @Input() title: String;
  @Input() selectedMessageTemplate: MessageTemplate;
  @Input() display: Boolean;
  @Output() onCloseDialog: EventEmitter<string> = new EventEmitter();
  @Output() onSaveMakeACopyClick: EventEmitter<string> = new EventEmitter();
  isFormSubmitted = false;
  alertMessageCopyTemplateName: boolean = false;
  alertMessageCopyTemplateNameString: string = "";
  saveClicked: boolean = false;

  constructor(
    private toastService: ToastService,
    private formBuilder: FormBuilder
  ) {
    this.initializeDialog();
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }

  createForm() {
    return new Promise((resolve, reject) => {
      this.makeACopyForm = this.formBuilder.group({ txtTitle: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(64),],], });
      resolve(true);
    });
  }

  async initializeDialog() {
    var title = '';
    if (this.title) {
      title = `Copy of ${this.title}`;
    }
    setTimeout(() => {
      this.makeACopyForm.patchValue({ txtTitle: title, });
      this.makeACopyForm.get('txtTitle').updateValueAndValidity();
    });
  }

  onClose() {
    this.resetForm();
    this.onCloseDialog.emit();
  }

  async onSaveClick(): Promise<void> {
    this.isFormSubmitted = true;
    if (this.makeACopyForm.valid) {
      this.saveClicked = true;
      this.selectedMessageTemplate.title = this.makeACopyForm.value.txtTitle;
      this.onSaveMakeACopyClick.emit();
      this.resetForm();
    } else {
      this.saveClicked = false;
      this.toastService.showError('Please validate all data..');
    }
  }

  resetForm() {
    this.isFormSubmitted = false;
    if (this.makeACopyForm) {
      this.makeACopyForm.markAsPristine();
      this.makeACopyForm.markAsUntouched();
    }
    this.saveClicked = false;
  }
}
