import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PagingData } from 'src/models/PagingData';
import { RequestResponse } from 'src/models/RequestResponse';
import { Router } from '@angular/router';
import { ToastService } from 'src/services/toast.service';
import { PeopleAndLoanService } from 'src/services/peopleandloans.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from 'src/components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { UserDefineList } from '../models/UserDefineList';
import { SearchParam } from '../models/SearchParam';
import { PeopleAndLoansListEditComponent } from '../dialogs/people-loan-list-edit-dialog.component';
import { PeopleAndLoanFilterData, PeopleAndLoanQueryParam, PeopleLoanListAndFilter, PeopleLoanListOrFilter } from '../../models/PeopleAndLoanFilterOutput';
import { LoanDetail } from '../models/Loan';
import { Datetime } from 'src/models/Datetime';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-people-and-loans',
  templateUrl: './people-and-loans.component.html',
  styleUrls: ['./people-and-loans.component.scss'],
})
export class PeopleAndLoansComponent implements OnInit, OnDestroy {
  public loadingPage: boolean = false;
  public subscription: Array<Subscription> = [];
  public reportType: number = 0;
  public reportData: any;
  public webAPI: RequestResponse;

  @ViewChild('PeopleLoanTableId', { static: false }) dataTable: Table;
  @ViewChild('editListDialog', { static: false })
  peopleAndLoansListEditComponent: PeopleAndLoansListEditComponent;

  // columns that are always displayed
  frozenColumns: any[];
  displayedColumns: any[];
  selectedColumns: any = [];
  globalFilterFields: any[] = [];
  sortField = 'Name';
  sortOrder = 'ASC';
  orderBy: string = 'Name ASC'; //to be remove
  defaultOrderBy = 'Name ASC'; //to be remove
  reportTitleArr: string[];
  reportTitle: string = '';
  viewList: boolean = false;
  redirectedViewList: boolean = false;
  masterListId: string;
  predefinedList: number = 1;
  canEditList: boolean = false;
  sendCampaignDialogTitle: string;
  confirmationMessageSendCampaign: string;
  showListEditDialog: boolean = false;
  selectedList: UserDefineList = new UserDefineList();
  selectedListClone: UserDefineList = new UserDefineList();
  viewSelectedList: UserDefineList = new UserDefineList();
  newSelectedList: UserDefineList = new UserDefineList();
  dialogListEditTitle = 'List';
  // Horizontal scroll position
  currentHScrollPos: number = 0;
  ColumnsList: any[];
  selectedContactID: any; //guid
  searchKeyword: string = '';
  showHideButtonText: string = 'Hide Filters';
  isListChangeSaved: boolean = true;
  jsonStr: string;
  peopleAndLoanFilterEditData: PeopleAndLoanFilterData | null;
  loadedFirst: boolean;
  isEdit: boolean = false;
  filterJsonOnLoad: string;
  isFilterJsonBlank: boolean = false;
  preferenceList: any = '';
  rearrangeGridColumnList: any = [];
  private searchParam$ = new Subject<SearchParam>();
  private searchObservable: any;
  public isFilterButtonShow: boolean = true;
  public isExpanded: boolean = false;
  public ownerName: string = '';
  public listUpdatedDate: string = '';
  public contactOnlyList: boolean = true;
  public hasConditionChange: boolean;
  public pageIndex: number = 0;
  public showImportDialog: boolean;
  //public isStandardUserWithMarketing: boolean;
  public isSendACampaign: boolean;
  public peopleAndLoanQueryParam: PeopleAndLoanQueryParam;
  private loanJSON: any;
  private peopleLoanListOrFilterByQueryString: PeopleLoanListOrFilter[];
  constructor(
    public dialogService: DialogService,
    private peopleAndLoanService: PeopleAndLoanService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.canEditList = true;

    //to fix bug just remove comment this line ( iif view is disabled.)
    if (this.router.getCurrentNavigation().extras.state) {
      this.viewList = this.router.getCurrentNavigation().extras.state.viewList;
      this.predefinedList =
        this.router.getCurrentNavigation().extras.state.predefinedList;
      this.viewSelectedList =
        this.router.getCurrentNavigation().extras.state.viewSelectedList;
      this.canEditList =
        this.router.getCurrentNavigation().extras.state.canEditList;
    }

    // initialize displayed column definitions either from DB or default
    this.getPreferredColumns();

    // initialize global filter field definitions
    this.globalFilterFields = this.getGlobalFilterFieldDefinitions();
    this.frozenColumns = this.getFrozenColumnDefinitions();

    // initialize selectable column definitions (drop down contents)
    this.ColumnsList = this.getSelectableColumnDefinitions();
    this.ColumnsList.sort((a, b) => (a.header < b.header ? -1 : 1));
    this.reportTitleArr = this.setReportTitles();
    this.jsonStr = '';
    this.peopleAndLoanFilterEditData = null;
    this.loadedFirst = true;
    this.hasConditionChange = false;
    this.pageIndex = 0;
    this.showImportDialog = false;
    //this.isStandardUserWithMarketing =  this.userTokenService.getUser().isCompanyUserWithMarketing();
    this.isSendACampaign = false;
    this.peopleAndLoanQueryParam = null;
    this.peopleLoanListOrFilterByQueryString = [];
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.id) {
      this.reportType = +this.activatedRoute.snapshot.data.id;
      this.peopleAndLoanQueryParam = null;
    }

    //pre-set data in session storage.
    let loanJSON = this.peopleAndLoanService.getFilterJson('all.json');
    let hasLoanLoaded = this.peopleAndLoanService.filterJsonLoaded('all.json');
    if (hasLoanLoaded && loanJSON) {//has been loaded parse and use 
      this.loanJSON = JSON.parse(loanJSON);
      loanJSON = '';
    }
    /*
      let loanJSON = this.peopleAndLoanService.getFilterJson('Loan.json');
      let peopleJSON = this.peopleAndLoanService.getFilterJson('People.json');
  
      let hasLoanLoaded = this.peopleAndLoanService.filterJsonLoaded('Loan.json');
  
      if (hasLoanLoaded && loanJSON) {//has been loaded parse and use 
        this.loanJSON = JSON.parse(loanJSON);
      }
      //loanJSON = peopleJSON = '';
  */
    this.activatedRoute.paramMap.subscribe((params) => {
      var paramListId = params.get('id');
      if (paramListId != null) {
        this.viewSelectedList.listId = paramListId;
      }
      if (this.reportType == 100) {
        this.redirectedViewList = true;
        this.predefinedList = 0;
      }
    });

    this.webAPI = new RequestResponse();
    this.webAPI.paging.currentPage = 1; // will be set to -1 =0
    this.webAPI.paging.pageSize = 25;
    this.webAPI.paging.orderBy = this.defaultOrderBy;
    if (this.reportType >= 0 && this.reportType <= 12) {
      this.reportTitle = this.reportTitleArr[this.reportType];
      // set the below one to fetch masterlistid according to title when redirected directly from menu
      if (this.viewList == false) {
        this.viewSelectedList.listTitle = this.reportTitle;
      }
      //this.getPreferredColumns();
      this.getColumnDefinitions();
    } else {
      if (this.viewList && this.predefinedList == 1) {
        const titleArr = [
          { reportType: 'All Contacts' },
          { reportType: 'Active Loans' },
          { reportType: 'Closed Loans' },
          { reportType: 'Birthdays This Week' },
          { reportType: 'Loan Anniversaries This Week' },
          { reportType: 'Purchase Loans' },
          { reportType: 'Other Loans' },
          { reportType: 'Cancelled Loans' },
          { reportType: 'Refi Loans' },
          { reportType: 'All Lists' },
          { reportType: 'Active Leads' },
          { reportType: 'Application Phase Loans' },
          { reportType: 'Converted from Lead to Loan' },
        ];

        this.reportType = titleArr.findIndex(
          (x) => x.reportType === this.reportTitle
        );
        //this.getPreferredColumns();
        this.getColumnDefinitions();
      } else if (
        (this.viewList && this.predefinedList == 0) ||
        this.redirectedViewList
      ) {
        //this.getPreferredColumns();
        this.getColumnDefinitions();
      } else {
        this.toastService.showError('Invalid report type');
      }
    }


    this.searchObservable = this.searchParam$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchParams) => {
          this.loadingPage = true;
          return this.peopleAndLoanService.GetAllContact(searchParams).pipe(catchError(error => of(null)));
        }),
        catchError((err) => of(null))
      )
      .subscribe(
        (newResponse: RequestResponse) => {
          if (
            newResponse &&
            newResponse.status == 200 &&
            newResponse?.message == ''
          ) {
            if (this.searchKeyword != '') {
              this.webAPI.paging.totalRecords = newResponse.data.contacts.length;
            } else {
              this.webAPI.paging.totalRecords =
                newResponse.pageInfo.totalRecords;
            }
            // assign returned object to class variable.
            this.webAPI.data = newResponse.data.contacts; // Required - do not remove.
          } else {
            this.toastService.showError(
              newResponse?.message ?? 'Some error occurred. Please try again!'
            );
          }

          this.loadingPage = false;
        },
        (err) => {
          this.toastService.showError('Error ::AllContact :' + err);
          this.loadingPage = false;
        },
        () => console.log('searchObservable:: HTTP request completed.')
      );

    this.peopleAndLoanService.reSetDataPeriodicallyForTags();
  }

  getData() {
    this.isExpanded = false;
    this.dataTable.expandedRowKeys = {};
    this.refreshDataAccordingToMasterListIdOrTitle();
  }

  async refreshDataAccordingToMasterListIdOrTitle() {
    this.loadingPage = true;
    try {
      var response =
        await this.peopleAndLoanService.GetDataAccordingToMasterListIdOrTitle(
          this.viewSelectedList
        );
      if (response.status == 0) {
        this.toastService.showError(response.message);
      } else if (response.status == 200) {
        this.viewSelectedList.listId = response.data.masterListId;
        this.viewSelectedList.listTitle = response.data.title;
        this.viewSelectedList.tenantId = response.data.tenantId;
        this.ownerName = response.data.ownerName;
        this.listUpdatedDate = response.data.dateUpdatedUTC;
        if (!this.masterListId) {
          this.masterListId = response.data.masterListId;
        }
        this.reportTitle = response.data.title;
        let currentPage = this.webAPI.paging.currentPage - 1;
        let pageSize = this.webAPI.paging.pageSize;
        let searchParams = new SearchParam(
          currentPage,
          pageSize,
          this.reportType,
          this.sortField,
          this.sortOrder,
          this.searchKeyword
        );
        this.filterJsonOnLoad = '';
        if (response.data.whereJson || this.viewSelectedList.filterJson) {
          if (
            this.viewSelectedList.filterJson != '' &&
            response.data.whereJson != this.viewSelectedList.filterJson
          ) {
            searchParams.filterJson = this.viewSelectedList.filterJson;
          } else {
            searchParams.filterJson = response.data.whereJson.toString();
          }

          if (response.data.whereJson) {
            //load in edit mode();
            if (this.loadedFirst) {
              //load from db once then it will be edit by component.
              this.peopleAndLoanFilterEditData = JSON.parse(
                response.data.whereJson
              );

              //add logic here add in this.peopleAndLoanFilterEditData
              this.setChipByQueryString();

              if (this.peopleAndLoanQueryParam) {//if querystring is there
                searchParams.filterJson = JSON.stringify(this.peopleAndLoanFilterEditData);//set json
              }



              this.loadedFirst = false;
            }
          }

          this.filterJsonOnLoad = searchParams.filterJson;
          this.searchParam$.next(searchParams);
        } else {
          //default to all contacts if no filters are applied.
          searchParams.reportType = 0;
          this.getReportResults(searchParams);
        }
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  onRowExpand() {
    this.isExpanded = true;
  }

  onRowCollapse() {
    this.isExpanded = false;
  }

  rowExpand(row: any, rowIndex: number) {
    if (this.isExpanded) {
      let currentPage = this.webAPI.paging.currentPage - 1;
      let pageSize = this.webAPI.paging.pageSize;
      let currentRowIndex = rowIndex - pageSize * currentPage;
      var arrIds = row.loanIds;
      var loanDetailList: LoanDetail[] = [];
      this.webAPI.data[currentRowIndex].loanList[0].loanNumber = null;
      if (arrIds == undefined) {
        //do nothing
      } else {
        this.peopleAndLoanService.GetLoanDetail(arrIds).subscribe(
          (response) => {
            if (response.status == 200 && response?.message == '') {
              loanDetailList = response.data;
              loanDetailList.forEach((element) => {
                this.webAPI.data[currentRowIndex].loanList.map((x, i) => {
                  if (x.loanId == element.loanId) {
                    if (
                      element.loanStatus != null &&
                      element.loanStatus != ''
                    ) {
                      element.loanStatus = element.loanStatus.replace(
                        ' Date',
                        ''
                      );
                    } else {
                      element.loanStatus = '';
                    }
                    this.webAPI.data[currentRowIndex].loanList[i] = element;
                  }
                });
              });
            } else {
              this.toastService.showError(response.message);
            }
          },
          (err) => {
            this.toastService.showError('Error : ' + err);
          }
        );
      }
    }
  }

  getReportResults(searchParams: SearchParam) {
    this.subscription.push(
      this.peopleAndLoanService.GetAllContact(searchParams).subscribe(
        (response) => {
          if (response.status == 200 && response?.message == '') {
            this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;
            this.webAPI.data = response.data.contacts;
            this.loadingPage = false;
          } else {
            this.toastService.showError(response.message);
          }
        },
        (err) => {
          this.toastService.showError('Error : ' + err);
          this.loadingPage = false;
        }
      )
    );
  }

  getColumnDefinitions(): Array<any> {
    // always display frozen and default columns
    let results: any[] = [];
    results = results.concat(this.getFirstColumnDefinitions());
    results = results.concat(this.getDefaultColumnDefinitions());
    //results = results.concat(this.getFrozenColumnDefinitions());
    results = results.concat(this.getLastColumnDefinitions());
    return results;
  }

  getFirstColumnDefinitions(): Array<any> {
    let results: any[] = [
      {
        field: 'name',
        header: 'Name',
        isSortable: true,
        isFilterable: true,
        filterType: 'text',
        showOperator: false,
      },
    ];
    return results;
  }

  // async functionToClear() {
  //   this.selectedColumns = [];
  //   this.preferenceList = '';
  //   var response1 = await this.peopleAndLoanService.AddUpdateUserPreferedGridColumns(this.preferenceList);
  //   if (response1.status == 0) {
  //     this.toastService.showError(response1.message);
  //   } else if (response1.status == 200) {
  //     this.preferenceList = '';
  //     this.displayedColumns = this.getFrozenColumnDefinitions();
  //     this.rearrangeGridColumnList = [];
  //   }
  // }

  /**
   * Gets the array of default column definitions that are not selectable and always exported.
   */
  getDefaultColumnDefinitions(): Array<any> {
    let results = [
      {
        field: 'dateOfBirth',
        header: 'Birthday',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'masterContactTypeName',
        header: 'Contact Type',
        isSortable: true,
        isFilterable: true,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'email',
        header: 'Email',
        isSortable: true,
        isFilterable: true,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'mobilePhone',
        header: 'Mobile Phone',
        isSortable: false,
        isFilterable: true,
        filterType: 'text',
        showOperator: false,
      },
    ];
    return results;
  }

  getFrozenColumnDefinitions(): Array<any> {
    let results: any[] = [];
    results = results.concat(this.getFirstColumnDefinitions());
    results = results.concat(this.getLastColumnDefinitions());
    return results;
  }

  getAllOptionalColumnDefinitions(): Array<any> {
    let results: any[] = [];
    results = results.concat(this.getDefaultColumnDefinitions());
    results = results.concat(this.getOptionalColumnDefinitions());
    return results;
  }

  /**
   * Gets the array of last default column definitions that are not selectable and always exported.
   */
  getLastColumnDefinitions(): Array<any> {
    let results = [
      {
        field: 'loanCount',
        header: 'Loan Count',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
    ];
    return results;
  }

  getOptionalColumnDefinitions(): Array<any> {
    let results = [
      {
        field: 'street',
        header: 'Address Line',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'city',
        header: 'City',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'company',
        header: 'Company',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'ownerName',
        header: 'Contact Owner',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'homePhone',
        header: 'Home Phone',
        isSortable: false,
        isFilterable: true,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'state',
        header: 'State',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'veteranStatus',
        header: 'Veteran',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
      {
        field: 'workPhone',
        header: 'Work Phone',
        isSortable: false,
        isFilterable: true,
        filterType: 'text',
        showOperator: false,
      },

      // {
      //   field: 'fax',
      //   header: 'Fax',
      //   isSortable: false,
      //   isFilterable: false,
      //   filterType: 'text',
      //   showOperator: false,
      // },

      {
        field: 'zip',
        header: 'Zip',
        isSortable: false,
        isFilterable: false,
        filterType: 'text',
        showOperator: false,
      },
    ];
    return results;
  }

  /**
   * Gets the array of all column definitions that are selectable.
   */
  getSelectableColumnDefinitions(): Array<any> {
    // always display default columns
    let results = this.getDefaultColumnDefinitions();
    // append optional column definitions
    // these columns are returned, but have never been previously displayed
    results = results.concat(this.getOptionalColumnDefinitions());
    return results;
  }

  /**
   *  Called when column selections have changed.
   *
   * @param event
   */
  async onColumnSelectionChange(event: any) {
    //this.loadingPage = true;
    this.preferenceList = '';

    event.value.forEach((element) => {
      if (element != 'name' && element != 'loanCount') {
        var selObj = this.getAllOptionalColumnDefinitions();
        let newFilterObj = selObj.filter((x) => x.field == element);
        if (newFilterObj && newFilterObj.length > 0) {
          this.preferenceList +=
            newFilterObj[0].field + ':' + newFilterObj[0].header + ',';
        }
      }
    });
    if (this.preferenceList.length > 0) {
      this.preferenceList = this.preferenceList.substr(
        0,
        this.preferenceList.length - 1
      );
      this.displayedColumns = this.rearrangeGridColumns();
      this.rearrangeGridColumnList = [];
    } else {
      this.displayedColumns = this.getFrozenColumnDefinitions();
      this.rearrangeGridColumnList = [];
    }
    var response =
      await this.peopleAndLoanService.AddUpdateUserPreferedGridColumns(
        this.preferenceList
      );
    if (response.status == 0) {
      this.toastService.showError(response.message);
    } else if (response.status == 200) {
      this.toastService.showSuccess('User Preferences changed successfully.');
    }
  }

  async getPreferredColumns() {
    //this.loadingPage = true;
    var response = await this.peopleAndLoanService.GetUserPreferedGridColumns(
      'PeopleAndLoanGridCol'
    );
    if (response.status == 0) {
      this.toastService.showError(response.message);
      //this.loadingPage = false;
    } else if (response.status == 200) {
      if (
        response.data.preferenceJson != null &&
        response.data.preferenceJson != ''
      ) {
        this.preferenceList = response.data.preferenceJson;
        this.displayedColumns = this.rearrangeGridColumns();
        this.displayedColumns.forEach((element) => {
          if (element.field != 'name' && element.field != 'loanCount') {
            this.selectedColumns = this.selectedColumns.concat(element.field);
          }
        });
        this.rearrangeGridColumnList = [];
        //this.loadingPage = false;
      } else {
        if (
          response.data.userPreferenceKey != null &&
          response.data.userPreferenceKey != ''
        ) {
          this.displayedColumns = this.getFrozenColumnDefinitions();
          this.selectedColumns = [];
        } else {
          this.displayedColumns = this.getColumnDefinitions();
          this.displayedColumns.forEach((element) => {
            if (element.field != 'name' && element.field != 'loanCount') {
              this.selectedColumns = this.selectedColumns.concat(element.field);
            }
          });
        }
        //this.loadingPage = false;
      }
    } else if (
      response.status == 500 &&
      response.message == 'No Record Found'
    ) {
      this.displayedColumns = this.getColumnDefinitions();
      this.displayedColumns.forEach((element) => {
        if (element.field != 'name' && element.field != 'loanCount') {
          this.preferenceList += element.field + ':' + element.header + ',';
        }
      });
      //add default grid selection to user preference
      this.preferenceList = this.preferenceList.substr(
        0,
        this.preferenceList.length - 1
      );
      var response1 =
        await this.peopleAndLoanService.AddUpdateUserPreferedGridColumns(
          this.preferenceList
        );
      if (response1.status == 0) {
        this.toastService.showError(response1.message);
        //this.loadingPage = false;
      } else if (response1.status == 200) {
        this.preferenceList = '';
        //this.loadingPage = false;
      }
    }
  }

  rearrangeGridColumns(): Array<any> {
    //add first column as name
    this.rearrangeGridColumnList = this.rearrangeGridColumnList.concat(
      this.getFirstColumnDefinitions()
    );
    //add user selected columns
    if (this.preferenceList.length > 0) {
      let preferenceListArray = this.preferenceList.split(',');
      let userPreferredColumList: any[] = [];
      preferenceListArray.forEach((element) => {
        // 0 index for field id and 1 index for header
        var fieldHeader = element.split(':');
        if (
          fieldHeader[0] == 'masterContactTypeName' ||
          fieldHeader[0] == 'email'
        ) {
          userPreferredColumList = [
            {
              field: fieldHeader[0],
              header: fieldHeader[1],
              isSortable: true,
              isFilterable: true,
              filterType: 'text',
              showOperator: false,
            },
          ];
        } else {
          userPreferredColumList = [
            {
              field: fieldHeader[0],
              header: fieldHeader[1],
              isSortable: false,
              isFilterable: false,
              filterType: 'text',
              showOperator: false,
            },
          ];
        }

        //add user preferred selected columns
        this.rearrangeGridColumnList = this.rearrangeGridColumnList.concat(
          userPreferredColumList
        );
      });
    }
    //add last column as loanCount
    this.rearrangeGridColumnList = this.rearrangeGridColumnList.concat(
      this.getLastColumnDefinitions()
    );

    //this.preferenceList = '';
    return this.rearrangeGridColumnList;
  }
  // /**
  //  *  Called when column selections have changed.
  //  *
  //  * @param event
  //  */
  // async onColumnSelectionChange(event: any) {
  //   this.loadingPage = true;
  //   // when the (all items) checkbox sets or clears all selections
  //   // we must make sure frozen columns are displayed
  //   if (event.value.length == 0 || event.value[0].field != 'name') {
  //     setTimeout(() => {
  //       this.dataTable.columns = this.frozenColumns.concat(event.value);
  //     }, 10);
  //   }

  //   event.value.forEach((element) => {
  //     if (element.field != 'name' && element.field != 'loanCount') {
  //       this.preferenceList += element.field + ':' + element.header + ',';
  //     }
  //   });

  //   //remove last , from the string
  //   this.preferenceList = this.preferenceList.substr(
  //     0,
  //     this.preferenceList.length - 1
  //   );
  //   var response =
  //     await this.peopleAndLoanService.AddUpdateUserPreferedGridColumns(
  //       this.preferenceList
  //     );
  //   if (response.status == 0) {
  //     this.toastService.showError(response.message);
  //   } else if (response.status == 200) {
  //     this.displayedColumns = this.rearrangeGridColumns();
  //     this.rearrangeGridColumnList = [];
  //   }
  //   this.loadingPage = false;
  //   debugger;
  // }

  // rearrangeGridColumns(): Array<any> {
  //   let preferenceListArray = this.preferenceList.split(',');
  //   let userPreferedColumList: any[] = [];

  //   //add first column as name
  //   this.rearrangeGridColumnList = this.rearrangeGridColumnList.concat(
  //     this.getFirstFrozenColumnDefinitions()
  //   );

  //   //add user selected columns
  //   preferenceListArray.forEach((element) => {
  //     // 0 index for field id and 1 index for header
  //     var fieldHeader = element.split(':');
  //     userPreferedColumList = [
  //       {
  //         field: fieldHeader[0],
  //         header: fieldHeader[1],
  //         isSortable: false,
  //         isFilterable: false,
  //         filterType: 'text',
  //         showOperator: false,
  //       },
  //     ];

  //     //add user prefered selected columns
  //     this.rearrangeGridColumnList = this.rearrangeGridColumnList.concat(
  //       userPreferedColumList
  //     );
  //   });

  //   //add last column as loanCount
  //   this.rearrangeGridColumnList = this.rearrangeGridColumnList.concat(
  //     this.getLastFrozenColumnDefinitions()
  //   );

  //   let itemslist = [];
  //   this.rearrangeGridColumnList.forEach((element) => {
  //     if (element.field != 'name' && element.field != 'loanCount') {
  //       itemslist.push(element);
  //     }
  //   });

  //   //debugger;
  //   this.multiSelectDisplayedColumns = itemslist;
  //   this.preferenceList = '';
  //   return this.rearrangeGridColumnList;
  // }

  /**
   * Gets the array of global filter field names.
   * */
  getGlobalFilterFieldDefinitions() {
    let results = [
      'Name',
      'Type',
      'Email',
      'MobilePhone',
      'WorkPhone',
      'HomePhone',
    ];
    return results;
  }

  onPageChanged(event) {
    this.webAPI.paging.pageSize = event.rows;
    this.webAPI.paging.currentPage = event.first / event.rows + 1;
    this.sortField = event?.sortField?.toLowerCase() ?? '';
    this.sortField =
      this.sortField == 'contact type'
        ? 'masterContactTypeName'
        : this.sortField;
    this.sortOrder = event?.sortOrder == -1 ? 'DESC' : 'ASC';
    setTimeout(() => {
      this.getData();
    });
  }

  onGotoPage(event) {
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
      setTimeout(() => {
        this.getData();
      });
    }
  }

  onRowCheckboxClick(contactId: any) {
    this.selectedContactID = contactId;
  }

  onShowButtonClick() {
    this.isFilterButtonShow = true;
  }

  onHideButtonClick() {
    this.isFilterButtonShow = false;
  }

  private setReportTitles() {
    let titleArr = [
      'All Contacts',
      'Active Loans',
      'Closed Loans',
      'Birthdays This Week',
      'Loan Anniversaries This Week',
      'Purchase Loans',
      'Other Loans',
      'Cancelled Loans',
      'Refi Loans',
      'List',
      'Active Leads',
      'Application Phase Loans',
      'Converted from Lead to Loan'
    ];
    return titleArr;
  }

  onGlobalFilterChange() {
    if (this.searchKeyword.length == 0) this.onApplyGlobalFilter();
  }

  async onApplyGlobalFilter() {
    this.webAPI.paging.currentPage = 1;
    this.pageIndex = 0;
    setTimeout(() => {
      this.getData();
    });
  }

  SendCampaign() {
    try {
      let saveAs: boolean = (this.hasConditionChange && this.predefinedList == 1);
      if (saveAs) {
        this.onSaveAsNewListBtnClick();
        this.isSendACampaign = true;
      }
      else {
        this.SendCampaignSave();
      }
    }
    catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  private SendCampaignSave() {
    this.confirmationMessageSendCampaign = this.reportTitle;
    if (!this.isListChangeSaved) {
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message: `Please confirm you want to save ${this.confirmationMessageSendCampaign} ?`,
          successButtonText: 'Yes, I want to save',
          cancelButtonText: 'Cancel',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          this.SaveList();
          this.doSave();
        }
      });
    } else {
      this.doSave();
    }
  }

  async doSave() {
    // var response = await this.campaignsService.CreateNewCampaign(
    //   this.viewSelectedList.listId
    // );
    // this.router.navigate([
    //   '/marketing/campaigns/edit/' + response.data + '/default',
    // ]);
    this.router.navigate([
      '/marketing/campaigns/create/new/' + this.viewSelectedList.listId,
    ]);
  }

  async SaveList() {
    this.loadingPage = true;
    this.hasConditionChange = false;
    try {
      this.newSelectedList.listId = this.masterListId;
      this.newSelectedList.listTitle = this.reportTitle;
      this.newSelectedList.filterJson = this.jsonStr;
      if (this.jsonStr == '' || this.jsonStr == null) {
        this.newSelectedList.isContactOnlyList = false;
      } else {
        let peopleAndLoanFilterEditData = JSON.parse(this.jsonStr);
        let isLoanFilter =
          peopleAndLoanFilterEditData?.PeopleLoanList?.PeopleLoanListAndFilters?.findIndex(
            (x) => x.group == 'Loan'
          );
        this.newSelectedList.isContactOnlyList =
          (isLoanFilter ?? -1) > -1 ? false : true;
        peopleAndLoanFilterEditData = null;
      }
      var response = await this.peopleAndLoanService.EditList(
        this.newSelectedList
      );
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      } else if (response.status == 200) {
        this.listUpdatedDate = Datetime.utcNow.toString();
        let mes = "List '" + this.reportTitle + "'" + ' updated successfully';
        this.toastService.showSuccess(mes);
      }
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async onShowListEditDialog() {
    if (this.predefinedList == 0) {
      this.dialogListEditTitle = 'List';
      this.isEdit = true;
      this.selectedListClone.listId = this.masterListId;
      this.selectedListClone.listTitle = this.reportTitle;
      await this.peopleAndLoansListEditComponent.initializeDialog();
      setTimeout(() => {
        this.showListEditDialog = true;
      }, 100);
    }
  }

  async onSaveAsNewListBtnClick() {
    this.dialogListEditTitle = 'Save As New List';
    this.isEdit = false;
    await this.peopleAndLoansListEditComponent.clearDialog();
    this.selectedListClone = new UserDefineList();
    setTimeout(() => {
      this.showListEditDialog = true;
    }, 100);
  }

  async onSaveListClick() {
    var response;
    if (this.isEdit) {
      response = await this.peopleAndLoanService.EditList(
        this.selectedListClone
      );
    } else {
      if (this.isListChangeSaved) {
        this.selectedListClone.filterJson = this.filterJsonOnLoad;
      } else {
        this.selectedListClone.filterJson = this.jsonStr;
      }
      response = await this.peopleAndLoanService.SaveList(
        this.selectedListClone
      );
    }

    if (response.data == true) {
      this.listUpdatedDate = Datetime.utcNow.toString();
      let mes = "List '" + this.selectedListClone.listTitle + "'" + ' saved successfully';
      this.toastService.showSuccess(mes);
      this.hasConditionChange = false;

      if (this.dialogListEditTitle == 'Save As New List') {
        let newMasterListId = response.message;
        this.masterListId = newMasterListId;
        this.viewSelectedList.listId = newMasterListId;
        this.viewSelectedList.listTitle = this.selectedListClone.listTitle;
        this.viewSelectedList.filterJson = this.selectedListClone.filterJson;
        this.router.navigate(['people-and-loans/' + newMasterListId], {
          state: {
            viewList: true,
            predefinedList: 0,
            viewSelectedList: this.viewSelectedList,
            canEditList: true,
          },
        });
      }
      this.reportTitle = this.selectedListClone.listTitle;
      this.onCloseListEditDialog();


      if (this.isSendACampaign) {
        this.isSendACampaign = false;
        this.doSave();
      }

    } else {
      this.toastService.showError(response.message);
    }
  }

  async onCloseListEditDialog() {
    setTimeout(() => {
      this.showListEditDialog = false;
      this.getData();
    }, 100);
  }

  onFilterCreatedEvent(event: PeopleAndLoanFilterData) {
    // console.log('onFilterCreatedEvent Emitted:: ');
    // console.log(event);
    if (event) {
      this.hasConditionChange = true;
      this.emptyGrid();
      this.isExpanded = false;
      this.peopleAndLoanFilterEditData = JSON.parse(JSON.stringify(event));
      this.isListChangeSaved = false;
      console.log('JSON received to save!!!');
      this.jsonStr = JSON.stringify(event, (key, value) => {
        // if matched return value "undefined"
        if (key === 'isDeleted') {
          //remove extra key
          return undefined;
        }
        // else return the value itself
        return value;
      });
      //console.log(this.jsonStr);

      this.isFilterJsonBlank =
        this.peopleAndLoanFilterEditData?.PeopleLoanList.PeopleLoanListAndFilters.length == 0
        && this.peopleAndLoanFilterEditData?.scope.LoanStatusTypes.length == 0;

      this.viewSelectedList.filterJson = this.jsonStr;
      let currentPage = 0;
      let pageSize = this.webAPI.paging.pageSize;
      let searchParams = new SearchParam(
        currentPage,
        pageSize,
        this.reportType,
        this.sortField,
        this.sortOrder,
        this.searchKeyword,
        this.jsonStr,
        new Date().getTime().toString()
      );
      //searchParams.filterJson = this.jsonStr;
      this.searchParam$.next(searchParams);
    }
  }

  private setChipByQueryString() {
    this.peopleAndLoanQueryParam = null;
    try {
      let dayRange = parseInt(this.activatedRoute.snapshot.queryParams.dayrange);
      let loanStatusField = this.activatedRoute.snapshot.queryParams.loanstatusfield;
      let operand = this.activatedRoute.snapshot.queryParams.operand;
      if (dayRange > 5) {
        return;
      }

      if (dayRange && loanStatusField && operand) {
        let dateRange: string = this.getDateStr(dayRange);//Last 7 days:1 /Last 30 days:2 /Last 12 months:3 /Year to date:4/ All:5
        this.peopleAndLoanQueryParam = new PeopleAndLoanQueryParam(dateRange, loanStatusField, operand?.toUpperCase());
        let index = (this.peopleAndLoanFilterEditData?.PeopleLoanList?.PeopleLoanListAndFilters?.length + 500) ?? 99;
        let dataType, fieldGroupName;
        if (loanStatusField == 'LosCreatedDate') {
          dataType = 'Date'
          fieldGroupName = 'Loan';
        } else {
          dataType = 'LoanStatusTypeList';
          fieldGroupName = 'LoanStatus';
        }

        let itm = this.loanJSON.Model.find(x => x.FieldGroupName == fieldGroupName
          && x.PeopleAndLoansTypeDefDataType == dataType
          && x.OpCode == this.peopleAndLoanQueryParam.operand
        );

        let arr = loanStatusField?.split(",");
        if (arr?.length) {
          index += 1;
          let lookupID = itm.PeopleAndLoansFieldLookupId;
          let defLookupID = itm.PeopleAndLoansTypeDefLookupId;
          let dataColumn = itm.DataColumn;

          let listAndFilter = new PeopleLoanListAndFilter();
          listAndFilter.id = index;
          listAndFilter.subGroup = itm.FieldSubGroupName;
          listAndFilter.peopleAndLoansFieldLookupId = lookupID;
          listAndFilter.group = itm.FieldGroupName;
          listAndFilter.datasource = itm.DataSource;
          listAndFilter.datacolumn = dataColumn;
          listAndFilter.type = itm.DataType;

          let uniqueIndex = this.peopleAndLoanFilterEditData.PeopleLoanList.PeopleLoanListAndFilters[0].PeopleLoanListOrFilters.length + 500;//to keep and index unique

          arr.forEach((loanStatusField, idx) => {
            uniqueIndex += 1;
            let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
            filter.id = uniqueIndex;
            filter.operator = this.peopleAndLoanQueryParam.operand;
            filter.lhs = loanStatusField;
            filter.rhs = [this.peopleAndLoanQueryParam.dateRange];
            filter.peopleAndLoansTypeDefLookupId = defLookupID;
            filter.criteria = null;
            filter.displayHtml = null;

            let criteriaQuery = itm.Criteria;
            let htmlTemplate = itm.DisplayHtmlTemplate;

            if (criteriaQuery) {
              criteriaQuery = criteriaQuery.replace('{{lhs}}', loanStatusField);
              criteriaQuery = criteriaQuery.replace('{{DataColumn}}', dataColumn);
              criteriaQuery = criteriaQuery.replace('{{rhs1}}', dateRange);
              criteriaQuery = criteriaQuery.replace('{{rhs2}}', '');
              filter.criteria = criteriaQuery;
            }

            if (htmlTemplate) {
              //show display text here.
              // let displayText = this.rhsDropdownItem.find(x => x.Code == lhsValue)?.Name ?? '';
              let displayText = loanStatusField;
              if (displayText) {
                displayText = displayText.replace('Loan', '');
                displayText = displayText.replace('Date', ' Date');
                displayText = displayText.replace('Status', ' Status');
              }

              htmlTemplate = htmlTemplate.replace('{{lhs}}', displayText);
              htmlTemplate = htmlTemplate.replace('{{rhs1}}', dateRange);
              htmlTemplate = htmlTemplate.replace('{{rhs2}}', '');
              let prefix = listAndFilter.subGroup ? listAndFilter.subGroup + ' ' : '';
              filter.displayHtml = prefix + htmlTemplate;
            }
            listAndFilter.PeopleLoanListOrFilters.push(filter);
          });

          this.peopleAndLoanFilterEditData.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilter);
        }
      }
    } catch (error) {
      this.peopleAndLoanQueryParam = null;
    }
  }

  getDateStr(range: number): string {
    if (range >= 1 && range <= 4)//Last 7 days:1 /Last 30 days:2 /Last 12 months:4 /Year to date:5/ All:0
    {
      let dayRange = 0;
      if (range == 1) {
        dayRange = 6
      } else if (range == 2) {
        dayRange = 29
      } else if (range == 4) {
        dayRange = 364
      }
      let date = new Date();
      date.setDate(date.getDate() - dayRange); //-- minus 7 days
      return this.datePipe.transform(date, 'MM/dd/yyyy')?.toString();
    } else if (range == 5) { //Year to date
      let day = new Date(new Date().getFullYear(), 0, 1);
      return this.datePipe.transform(day, 'MM/dd/yyyy')?.toString();
    }
  }


  private emptyGrid() {
    this.webAPI.paging.totalRecords = 0;
    this.webAPI.data = null;
    this.loadingPage = false;
  }

  public importContactLoans() {
    this.selectedListClone.listId = this.masterListId;
    this.selectedListClone.listTitle = this.reportTitle;
    //Open Import Dialog
    this.showImportDialog = true;

  }
  public closeImportDialog() {
    //Close Import Dialog
    this.showImportDialog = false;
  }


  private clearSubscription(subscription: Array<Subscription>) {
    subscription.forEach((subs: Subscription) => {
      if (subs) {
        subs.unsubscribe();
      }
    });
  }

  closeMe() {
    this.showImportDialog = false;
  }

  cleanUp() {
    if (this.subscription) {
      this.clearSubscription(this.subscription);
      this.subscription = [];
    }
    if (this.searchObservable) {
      this.searchObservable.unsubscribe();
      this.searchObservable = null;
    }

    this.emptyGrid();
    this.jsonStr = '';
    this.filterJsonOnLoad = '';
    this.peopleAndLoanFilterEditData = null;
    this.ColumnsList = null
    this.rearrangeGridColumnList = null

    this.loanJSON = null;
    this.selectedList = null;
    this.selectedListClone = null;
    this.viewSelectedList = null;
    this.newSelectedList = null;

  }

  ngOnDestroy(): void {
    this.cleanUp();
  }
}
