import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RequestResponse } from 'src/models/RequestResponse';
import { WhereClauseCriteriaCollection } from 'src/models/WhereClauseCriteria';
import { UserAnalyticsService } from 'src/services/userAnalytics.service';
import { NGENDashboardAnalytics } from 'src/models/NGENDashboardAnalytics';
import { ToastService } from 'src/services/toast.service';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { AnalyticsTypes, StorageHelper } from '../../../helpers/StorageHelper';
import { environment } from '../../../environments/environment';

/**
 * DashboardAnalytics component.
 * Selector: <dashboard-analytics>
 * 
 * Inputs: None
 * Outputs: None.
  *
 * @author: michael.sylvies@focusitinc.com
 * @version: 1.0
 * @since: 2021-01-10
*/
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'dashboard-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class DashboardAnalytics implements OnInit, OnDestroy {
  birthDaysThisWeek: number;
  birthDaysThisWeekLoading: boolean;
  loanAniversariesThisWeek: number;
  loanAniversariesThisWeekLoading: boolean;
  activeLeads: number;
  activeLeadsLoading: boolean;
  activeLoans: number;
  activeLoansLoading: boolean;
  convertedFromLeadToLoan: number;
  convertedFromLeadToLoanLoading: boolean;
  applicationPhase: number;
  applicationPhaseLoading: boolean;
  cancelledLoans: number;
  cancelledLoansLoading: boolean;
  closedLoans: number;
  closedLoansLoading: boolean;
  refiLoans: number;
  refiLoansLoading: boolean;
  purchaseLoans: number;
  purchaseLoansLoading: boolean;
  otherLoans: number;
  otherLoansLoading: boolean;
  marketingDataLoading: boolean;
  marketingData: any;

  // timeframes (7, 14, 30, 90)
  timeframes: any[];
  selectedTimeframe: number;

  // Expanding/Collapsing support
  closingActivityCollapsed: boolean = false;
  closingActivityStyle: string = 'visibility: visible;';
  leadsCollapsed: boolean = false;
  leadsStyle: string = 'visibility: visible;';
  activeLoansCollapsed: boolean = false;
  activeLoansStyle: string = 'visibility: visible;';
  marketingStatsCollapsed: boolean = false;
  marketingStatsStyle: string = 'visibility: visible;';
  deliveryRatesCollapsed: boolean = false;
  deliveryRatesStyle: string = 'visibility: visible;';
  remindersCollapsed: boolean = false;
  remindersStyle: string = 'visibility: visible;';
  initialized: boolean = false;
  showDeliveryRates: boolean = false;
  showMarketingAdminOnly: boolean = false;
  tileWidth: string = '18%'
  dateGathered: string = '';
  userToken: UserToken;
  storageHelper: StorageHelper;
  readonly expiredtimeInHour: number;
  lastRefreshedTime: any;
  itemCreatedTime: any;
  isRefresh: boolean;
  showRefreshBtn: boolean;
  myTimeoutIDs: any[];
  myIntervalIDs: any[];
  elapsedAfterMinutes: number;  

  /**
   * Constructor
   * 
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>
   * @param messageService MessageService required for <p-toast>
   * @param logging LoggingServices required for logging client messages in the servers application log. 
   */
  constructor(
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userAnalyticsService: UserAnalyticsService,
    private toastService: ToastService,
    private userTokenService: UserTokenService,
    private changeRef: ChangeDetectorRef    
  ) {
    this.timeframes = [
      { name: 'Last 7 days', code: 1 },
      //{ name: 'Last 14 Days', code: '14' },
      { name: 'Last 30 days', code: 2 },
      { name: 'Last 12 months', code: 4 },
      { name: 'Year to date', code: 5 },
      { name: 'All', code: 0 }
    ];

    this.selectedTimeframe = this.timeframes[0].code;
    this.storageHelper = new StorageHelper();
    this.expiredtimeInHour = environment.SessionStorageExpiredTimeInHour;
    this.lastRefreshedTime = '';
    this.isRefresh = false;
    this.showRefreshBtn = false;
    this.myTimeoutIDs = [];
    this.myIntervalIDs = [];
    this.elapsedAfterMinutes = 5;//5
  }

  ngOnDestroy(): void {
    //console.log(' ngOnDestroy called');
    this.clearInterval();
    this.clearTimeout();
  }

  /** Occurs upon component initialization */
  async ngOnInit(): Promise<void> {
    this.userToken = this.userTokenService.getUser();
    this.initializeDisplay();
    // obtain component data
    this.getData();
  }

  /**
   * Initializes the display based upon the user's permissions.
   */
  initializeDisplay() {
    /*
    let userSession = this.userSessionService.getUserSession();
    let permissionGroupHelper = new PermissionGroupHelper();
    if (permissionGroupHelper.IsMarketingAdmin(userSession.permissions)) {
      this.showDeliveryRates = true;
      this.tileWidth = '14%'
      if (permissionGroupHelper.isAny(userSession.permissions,
        PermissionGroup.SystemAdmin |
        PermissionGroup.CompanyAdmin)) {
        this.showMarketingAdminOnly = false;
      }
      else {
        this.showMarketingAdminOnly = true;
      }
    }
    else {
      this.showDeliveryRates = false;
      this.tileWidth = '18%'
      this.showMarketingAdminOnly = false;
    }*/
    if (this.userToken?.hasMarketingAccess()) {
      this.showDeliveryRates = true;
      this.tileWidth = '14%'
      if (this.userToken.isMarketingAdmin()) {
        this.showMarketingAdminOnly = true;
      }
      else {
        this.showMarketingAdminOnly = false;
      }
    } else {
      this.showDeliveryRates = false;
      this.tileWidth = '18%'
      this.showMarketingAdminOnly = false;
    }
    this.initialized = true;
  }

  // #endregion

  // #region Data Acquisition

  /**
   * Retrieves all necessary data for this component.
   *
   * @param: currentPage - paging support.
   */
  getData() {
    // start the progress bar/spinner or display a skeleton.
    this.birthDaysThisWeekLoading = true;
    let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.BirthDaysThisWeek);
    if (item) {// checking item obj not values as value can be zero
      this.birthDaysThisWeek = item.value;
      this.birthDaysThisWeekLoading = false;
    }
    else {
      this.userAnalyticsService.getBirthdaysThisWeek().subscribe(x => {
        this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.BirthDaysThisWeek, x.data, this.expiredtimeInHour);
        this.birthDaysThisWeek = x.data;
        this.birthDaysThisWeekLoading = false;
      }, (err) => {
        this.toastService.showError(err);
        this.birthDaysThisWeekLoading = false;
      });
    }

    try {
      this.loanAniversariesThisWeekLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.LoanAniversariesThisWeek);
      if (item) {// as value canbe zero
        this.loanAniversariesThisWeek = item.value;
        this.loanAniversariesThisWeekLoading = false;
      }
      else {
        this.userAnalyticsService.getLoanAniversariesThisWeek().subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.LoanAniversariesThisWeek, x.data, this.expiredtimeInHour);
          this.loanAniversariesThisWeek = x.data;
          this.loanAniversariesThisWeekLoading = false;
        },
          (err) => {
            this.toastService.showError(err);
            this.loanAniversariesThisWeekLoading = false;
          });
      }
    }
    catch (ex) {
      this.loanAniversariesThisWeekLoading = false;
      this.toastService.showError(ex);
    }
    this.onTimeframeSelectionChanged(null);
  }

  getMarketingDataByTimeline() {
    try {
      this.marketingDataLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.MarketingData);
      if (item) {// as value canbe zero
        this.marketingData = item.value;
        this.marketingDataLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getMarketingStats(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.MarketingData, x.data, this.expiredtimeInHour);
          this.marketingData = x.data;
          this.marketingDataLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.marketingDataLoading = false;
        });
      }
    }
    catch (ex) {
      this.marketingDataLoading = false;
      this.toastService.showError(ex);
    }
  }

  getDataByTimeline() {
    try {
      this.activeLeadsLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ActiveLeads);
      if (item) {// as value canbe zero
        this.activeLeads = item.value;
        this.activeLeadsLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getActiveLeads(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ActiveLeads, x.data, this.expiredtimeInHour);
          this.activeLeads = x.data;
          this.activeLeadsLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.activeLeadsLoading = false;
        });
      }
    }
    catch (ex) {
      this.activeLeadsLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.activeLoansLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ActiveLoans);
      if (item) {// as value canbe zero
        this.activeLoans = item.value;
        this.activeLoansLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getActiveLoans(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ActiveLoans, x.data, this.expiredtimeInHour);
          this.activeLoans = x.data;
          this.activeLoansLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.activeLoansLoading = false;
        });
      }
    }
    catch (ex) {
      this.activeLoansLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.convertedFromLeadToLoanLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ConvertedFromLeadToLoan);
      if (item) {// as value canbe zero
        this.convertedFromLeadToLoan = item.value;
        this.convertedFromLeadToLoanLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getConvertedFromLeadToLoan(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ConvertedFromLeadToLoan, x.data, this.expiredtimeInHour);
          this.convertedFromLeadToLoan = x.data;
          this.convertedFromLeadToLoanLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.convertedFromLeadToLoanLoading = false;
        });
      }
    }
    catch (ex) {
      this.convertedFromLeadToLoanLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.applicationPhaseLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ApplicationPhase);
      if (item) {// as value canbe zero
        this.applicationPhase = item.value;
        this.applicationPhaseLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getApplicationPhase(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ApplicationPhase, x.data, this.expiredtimeInHour);
          this.applicationPhase = x.data;
          this.applicationPhaseLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.applicationPhaseLoading = false;
        });
      }
    }
    catch (ex) {
      this.applicationPhaseLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.cancelledLoansLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.CancelledLoans);
      if (item) {// as value canbe zero
        this.cancelledLoans = item.value;
        this.cancelledLoansLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getCancelledLoans(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.CancelledLoans, x.data, this.expiredtimeInHour);
          this.cancelledLoans = x.data;
          this.cancelledLoansLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.cancelledLoansLoading = false;
        });
      }
    }
    catch (ex) {
      this.cancelledLoansLoading = false;
      this.toastService.showError(ex);
    }

    try {
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ClosedLoans);
      if (item) {// as value canbe zero
        this.closedLoans = item.value;
        this.closedLoansLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.closedLoansLoading = true;
        this.userAnalyticsService.getClosedLoans(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.ClosedLoans, x.data, this.expiredtimeInHour);
          this.closedLoans = x.data;
          this.closedLoansLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.closedLoansLoading = false;
        });
      }
    }
    catch (ex) {
      this.closedLoansLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.refiLoansLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.RefiLoans);
      if (item) {// as value canbe zero
        this.refiLoans = item.value;
        this.refiLoansLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getRefiLoans(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.RefiLoans, x.data, this.expiredtimeInHour);
          this.refiLoans = x.data;
          this.refiLoansLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.refiLoansLoading = false;
        });
      }
    }
    catch (ex) {
      this.refiLoansLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.purchaseLoansLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.PurchaseLoans);
      if (item) {// as value canbe zero
        this.purchaseLoans = item.value;
        this.purchaseLoansLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getPurchaseLoans(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.PurchaseLoans, x.data, this.expiredtimeInHour);
          this.purchaseLoans = x.data;
          this.purchaseLoansLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.purchaseLoansLoading = false;
        });
      }
    }
    catch (ex) {
      this.purchaseLoansLoading = false;
      this.toastService.showError(ex);
    }

    try {
      this.otherLoansLoading = true;
      let item = this.storageHelper.GetDataFromStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.OtherLoans);
      if (item) {// as value canbe zero
        this.otherLoans = item.value;
        this.otherLoansLoading = false;
        this.itemCreatedTime = item.AddedTime;
      }
      else {
        this.userAnalyticsService.getOtherLoans(this.selectedTimeframe).subscribe(x => {
          this.storageHelper.SetDataInStorage(this.selectedTimeframe.toString() + "_" + AnalyticsTypes.OtherLoans, x.data, this.expiredtimeInHour);
          this.otherLoans = x.data;
          this.otherLoansLoading = false;
          this.itemCreatedTime = new Date().getTime();
        }, (err) => {
          this.toastService.showError(err);
          this.otherLoansLoading = false;
        });
      }
    }
    catch (ex) {
      this.otherLoansLoading = false;
      this.toastService.showError(ex);
    }
  }
  // #region Event Handlers

  /**
   * Toggles the expansion and collapsing of 'Closing Activity' metrics.
   */
  onToggleClosingActivity() {
    if (this.closingActivityCollapsed) {
      this.closingActivityStyle = 'visibility: visible;';
    }
    else {
      this.closingActivityStyle = 'visibility: collapse;';
    }
    this.closingActivityCollapsed = !this.closingActivityCollapsed;
  }

  /**
   * Toggles the expansion and collapsing of 'Leads' metrics.
   */
  onToggleLeads() {
    if (this.leadsCollapsed) {
      this.leadsStyle = 'visibility: visible;';
    }
    else {
      this.leadsStyle = 'visibility: collapse;';
    }
    this.leadsCollapsed = !this.leadsCollapsed;
  }

  /**
   * Toggles the expansion and collapsing of 'Active Loans' metrics.
   */
  onToggleActiveLoans() {
    if (this.activeLoansCollapsed) {
      this.activeLoansStyle = 'visibility: visible;';
    }
    else {
      this.activeLoansStyle = 'visibility: collapse;';
    }
    this.activeLoansCollapsed = !this.activeLoansCollapsed;
  }

  /**
   * Toggles the expansion and collapsing of 'Marketing' metrics.
   */
  onToggleMarketingStats() {
    if (this.marketingStatsCollapsed) {
      this.marketingStatsStyle = 'visibility: visible;';
    }
    else {
      this.marketingStatsStyle = 'visibility: collapse;';
    }
    this.marketingStatsCollapsed = !this.marketingStatsCollapsed;
  }

  /**
   * Toggles the expansion and collapsing of 'Delivery Rates' metrics.
   */
  onToggleDeliveryRates() {
    if (this.deliveryRatesCollapsed) {
      this.deliveryRatesStyle = 'visibility: visible;';
    }
    else {
      this.deliveryRatesStyle = 'visibility: collapse;';
    }
    this.deliveryRatesCollapsed = !this.deliveryRatesCollapsed;
  }

  /**
   * Toggles the expansion and collapsing of 'Reminders' metrics.
   */
  onToggleReminders() {
    if (this.remindersCollapsed) {
      this.remindersStyle = 'visibility: visible;';
    }
    else {
      this.remindersStyle = 'visibility: collapse;';
    }
    this.remindersCollapsed = !this.remindersCollapsed;
  }

  /**
   * Called when the timeframe dropdown selection changes.
   * 
   * @param event
   */
  onTimeframeSelectionChanged(event: any) {

    this.itemCreatedTime = '';
    this.showRefreshBtn = false;
    this.lastRefreshedTime = '';
    this.changeRef.detectChanges();

    if (this.showDeliveryRates) {
      this.getMarketingDataByTimeline();
    }
    if (!this.showMarketingAdminOnly)
      this.getDataByTimeline();

    if (this.isRefresh) {
      this.toastService.showSuccess('Dashboard has been refreshed Successfully.');
      this.showRefreshBtn = false;
      this.itemCreatedTime = new Date().getTime();
      this.isRefresh = false;
      // setTimeout(() => { //if close button i called reactivate it in 5 minutes
      //   this.itemCreatedTime = new Date().getTime();
      //   this.ShowLastRefreshedTimeConditionally();
      // }, 1000 * 60 * this.elapsedAfterMinutes);
    }
    this.showLastRefreshedTimeConditionally();
    this.refreshDashboardEveryHour();
  }

  clearTimeout() {
    if (this.myTimeoutIDs?.length) {
      for (let index = 0; index < this.myTimeoutIDs.length; index++) {
        const element = this.myTimeoutIDs[index];
        if (element) {
          clearTimeout(element);
        }
      }
    }
  }

  clearInterval() {
    if (this.myIntervalIDs?.length) {
      for (let index = 0; index < this.myIntervalIDs.length; index++) {
        const element = this.myIntervalIDs[index];
        if (element) {
          clearInterval(element);
        }
      }
    }
  }

  refreshDashboardEveryHour() {
    //check every minute if time 60 minute has gone
    let myInterval = setInterval((x) => {
      //console.log('refreshDashboardEveryHour called.');
      let elapsedAfterMins = 60;//60
      let time = new Date().getTime() - this.itemCreatedTime;
      let diffInMinutes = Math.floor((time / 1000) / 60);//convert remaining time to minutes
      if (diffInMinutes >= elapsedAfterMins) {
        this.showRefreshBtn = true;
        this.lastRefreshedTime = this.itemCreatedTime;
        this.CleanCacheAndReloadData();//clear cache and realod.
      }
    }, 1000 * 60)//runs every minutes 1000 * 60  
    this.myIntervalIDs.push(myInterval);
  }

  showLastRefreshedTimeConditionally() {
    this.clearTimeout();
    this.showRefreshBtn = false;
    this.lastRefreshedTime = '';
    this.changeRef.detectChanges();

    if (this.itemCreatedTime) {//if load time has been set
      let time = new Date().getTime() - this.itemCreatedTime;
      let diffInMinutes = Math.floor((time / 1000) / 60); //convert remaining time to minutes
      if (diffInMinutes >= this.elapsedAfterMinutes) {  //show only if 5 minutes has elapased
        this.lastRefreshedTime = this.itemCreatedTime;
        this.showRefreshBtn = true;
        this.changeRef.detectChanges();
        this.clearTimeout();
      }
      else {
        this.showRefreshBtn = false;
        this.lastRefreshedTime = '';
        this.changeRef.detectChanges();

        let timeout = setTimeout((x) => {//call self after 5 seconds
          //console.log('check every 10 second until time condition is fullfilled. ');
          this.showLastRefreshedTimeConditionally();
        }, 10000);//10 seconds
        this.myTimeoutIDs.push(timeout);
        // //Show after 5 minutes elpased
        // let timeout = setTimeout((x) => {
        //   this.lastRefreshedTime = this.itemCreatedTime;
        //   this.showRefreshBtn = true;
        //   this.isRefresh = false;
        //   this.changeRef.detectChanges();
        // }, 1000 * 60 * this.elapsedAfterMinutes);

        // this.myTimeoutIDs.push(timeout);
      }
    }
    else {//wait for the load time to be set
      this.showRefreshBtn = false;
      this.lastRefreshedTime = '';
      this.changeRef.detectChanges();

      let timeout = setTimeout((x) => {//call self after 5 seconds
        //console.log('2. check every 10 second until time condition is fullfilled. ');
        this.showLastRefreshedTimeConditionally();
      }, 10000);//10 seconds
      this.myTimeoutIDs.push(timeout);
    }
  }

  formatLink(num: any) {
    return 0;
  }

  refreshNow() {
    this.isRefresh = true;
    this.lastRefreshedTime = '';
    this.showRefreshBtn = false;
    this.CleanCacheAndReloadData();
  }

  CleanCacheAndReloadData() {
    //Clean cache for elected only 
    let code = this.selectedTimeframe.toString()
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.BirthDaysThisWeek);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.LoanAniversariesThisWeek);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.MarketingData);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.ActiveLeads);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.ActiveLoans);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.ConvertedFromLeadToLoan);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.ApplicationPhase);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.CancelledLoans);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.ClosedLoans);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.RefiLoans);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.PurchaseLoans);
    this.storageHelper.ClearStorageByKey(code + "_" + AnalyticsTypes.OtherLoans);
    this.clearTimeout();
    this.itemCreatedTime = new Date().getTime();
    this.getData();//load data
  }
}
