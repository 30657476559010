import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from "primeng/dropdown";
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { PeopleAndLoansRouterModule } from "./people-and-loans-routing.module";
import { PeopleAndLoansComponent } from "./people-and-loans.component";
import { AccountsService } from '../services/accounts.service';
import { PeopleAndLoansListEditComponent } from "../dialogs/people-loan-list-edit-dialog.component";
import { PeopleAndLoansListModule } from "./people-and-loans-list.module";
//import { ContactAndLoanImportComponent } from '../contact-and-loan/contact-and-loan-import/contact-and-loan-import.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ContactAndLoanModule } from '../contact-and-loan/contact-and-loan-module';

@NgModule({
  //declarations: [PeopleAndLoansComponent, PeopleAndLoansListEditComponent, ContactAndLoanImportComponent],
  declarations: [PeopleAndLoansComponent, PeopleAndLoansListEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    MultiSelectModule,
    OverlayPanelModule,
    TableModule,
    PeopleAndLoansRouterModule,
    PeopleAndLoansListModule,
    CheckboxModule,
    ChipModule,
    ChipsModule, 
    ContactAndLoanModule,
   
  ],
  providers: [AccountsService],
  //exports: [PeopleAndLoansComponent, PeopleAndLoansListEditComponent, ContactAndLoanImportComponent, PeopleAndLoansListModule],
  exports: [PeopleAndLoansComponent, PeopleAndLoansListEditComponent, PeopleAndLoansListModule],
})
export class PeopleAndLoansModule { }
