import { Component, OnInit } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { LookupService } from 'src/services/lookup.service';
import { BillingService } from 'src/services/billingService';
import { OnBoardingService } from '../../../services/onBoarding.service';
import { ToastService } from 'src/services/toast.service';

@Component({
  selector: 'app-book-time',
  templateUrl: './book-time.component.html',
  styleUrls: ['./book-time.component.scss']
})
export class BookTimeComponent implements OnInit {
  public loadingPage: boolean = false;
  public selectedLabel: string;
  public selectedItem: any;
  public date: Date[];
  public showFullDate: string;
  public time: string;
  public timezoneId: string;
  public timezoneList: any[] = [];
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";
  public tenantId: any = '00000000-0000-0000-0000-000000000000';
  
  constructor(
    private router: Router,
    private lookupService: LookupService,
    private billingService: BillingService,
    private onBoardingService: OnBoardingService,
    private toastService: ToastService
  ) {
    this.date = null;
    this.showFullDate = '';
    this.time = '';
    this.timezoneId = '';
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.billingService.SaveCompanyOnBoarding(true);
    this.navigationStack = stack?.value;
    this.lookupService.GetTimezones().subscribe(result => {
      if (result && result.data) {
        this.timezoneList = result.data.map((obj) => {
          return { code: obj.timezoneId, name: obj.displayName }
        });
      }
    });
   }

   async ngOnInit(): Promise<void> {
    //await new Promise(f => setTimeout(f, 5000));
    await this.getData();
  }

  async getData() {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      var response = await this.onBoardingService.Get(this.tenantId);
      // assign returned object to class variable.      
      this.timezoneId = response.data.timezoneId;
      // when data is retrieved, set this.loadingPage to false.
      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  onTimezoneChange(event) {
    this.timezoneId = event.value;
  }

  redirectToCTAScreen(): void {
    let selectedDateFound = false;
    let selectedTimeFound = false;

    if ((this.date != undefined || this.date != null)) {
      selectedDateFound = true;
    } 

    if(this.time != '') {
      selectedTimeFound = true;
    }

    if(selectedDateFound && selectedTimeFound) {
      let data = {
        OnboardingDate: this.date.toString(),
        OnboardingTime: this.time,
        TimeZoneInfoId: this.timezoneId
      }

      this.billingService.SaveonboardingCalendarDetail(data);
      let navigateTo = '/cta-screen';
      let navigateFrom = '/book-time';
      if (this.navigationStack === undefined) {
        this.navigationStack = [];
      }
      this.navigationStack.push(navigateFrom);
      this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
      this.router.navigate([navigateTo]);
    }
  }

  redirectToCTAScreenDirectly(): void {
    let navigateTo = '/cta-screen';
    let navigateFrom = '/book-time';
    if (this.navigationStack === undefined) {
      this.navigationStack = [];
    }
    this.navigationStack.push(navigateFrom);
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    this.router.navigate([navigateTo]);
  }

  onDateSelect(event) {
    var Wday: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const month = event.toLocaleString('default', { month: 'long' });
    const day = Wday[event.getDay()];
    const date = event.getDate();
    this.showFullDate = day + ', ' + month + ' ' + date;
  }

  actionContent(event, label) {
    if (event.target.checked) {
      this.selectedLabel = label
    }
    if (label == "Radio1") {
      this.selectedItem = 1;
      this.time = '9:30am';
    } else if (label == "Radio2") {
      this.selectedItem = 2;
      this.time = '10:00am';
    } else if (label == "Radio3") {
      this.selectedItem = 3;
      this.time = '10:30am';
    } else if (label == "Radio4") {
      this.selectedItem = 4;
      this.time = '2:30pm';
    } else if (label == "Radio5") {
      this.selectedItem = 5;
      this.time = '3:00pm';
    } else if (label == "Radio6") {
      this.selectedItem = 6;
      this.time = '3:30pm';
    }
  }
}
