import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { BillingService } from 'src/services/billingService';

@Component({
  selector: 'app-cta-screen',
  templateUrl: './cta-screen.component.html',
  styleUrls: ['./cta-screen.component.scss']
})

export class CtaScreenComponent implements OnInit {
  public navigationStack: string[] = [];
  public storageHelper: StorageHelper;
  readonly onBoardingNavigationCacheKey: string = "onBoardingNavigationStack";

  constructor(private router: Router,
    private billingService: BillingService) { 
    this.storageHelper = new StorageHelper();
    let stack = this.storageHelper.GetDataFromStorage(this.onBoardingNavigationCacheKey);
    this.billingService.SaveCompanyOnBoarding(true);
    this.navigationStack = stack?.value;
  }

  ngOnInit(): void {
  }

  redirectToDashboard(): void {
    let navigateTo = '/marketing/content-library-filter/All';
    let navigateFrom = '/cta-screen';
    this.navigationStack.push(navigateFrom);
    this.storageHelper.SetDataInStorage(this.onBoardingNavigationCacheKey, this.navigationStack, 3);
    this.router.navigate([navigateTo]);
    this.storageHelper.ClearStorageByKey("bookTimeRoute");
    this.storageHelper.SetDataInStorage("dashboardRoute", "dashboard", 0.5);
    this.storageHelper.SetDataInStorage("onBoardingCompleted", "true", 20);
  }
}
