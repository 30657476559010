<!-- loadingPage: {{loadingPage}} -->
<div *ngIf="loadingPage" class="row">
  <div class="col-auto">
    <i class="pi pi-spin pi-spinner" style="font-size:2rem"></i>
  </div>
</div>

<!--
*************************************************************************************
Data Table
*************************************************************************************
-->
<div class="py-3 mb-5">
  <p-dataView #messagesTableId [value]="webAPI.data" emptyMessage=" ">
    <ng-template let-record let-i="rowIndex" pTemplate="listItem">
      <div class="col-12 mb-3">
        <div class="msg-outline">
          <span class="msg-count"> Message {{i+1}} </span>
          <div class="flex flex-column xl:flex-row xl:align-items-start p-2">
            <div class="d-flex justify-content-between">
              <div class="col-7">
                <div class="d-flex">
                  <i class="fa-regular fa-envelope facomment fa-fw message-dialog-icon"
                    *ngIf="record.templateTypeId == 1"></i>
                  <i class="fa-regular fa-comment-lines fa-fw message-dialog-icon facomment"
                    *ngIf="record.templateTypeId == 2"></i>
                  <div class="ms-3 fw-bold">
                    {{ record.messageTemplateTitle }}
                  </div>
                </div>
                <div class="flex flex-column sm:align-items-start gap-3">
                  <!-- <div class="text-2xl font-bold text-900">
                {{ record.scheduleDataJSON }}
              </div> -->
                  <div class="detail-text">
                    <ng-container #noLoader *ngIf="record.ScheduleMessage; else noSchedule">
                      <div [innerHTML]="record.ScheduleMessage"></div>
                    </ng-container>
                    <ng-template #noSchedule> Schedule not set </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="right">
                  <div class="gap-3 sm:gap-2">
                    <button
                      pButton
                      *ngIf="record.scheduleType.length === 0"
                      type="button"
                      (click)="onShowMessageScheduleDialog(record)"
                    >
                      Set Schedule
                    </button>
                    <button
                      pButton
                      class="outline-btn"
                      *ngIf="record.scheduleType.length > 0"
                      type="button"
                      (click)="onShowMessageScheduleDialog(record)"
                    >
                      Edit Schedule
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>
  <div *ngIf="noRecordFound">
    <span> No records found</span>
  </div>
</div>
<!--
*************************************************************************************
Campaign Message Schedule Dialog
*************************************************************************************
-->
<app-campaign-message-schedule
  (DialogCloseEvent)="DialogCloseEvent($event)"
  *ngIf="messageScheduleData.showMessageScheduleDialog"
  [MessageScheduleData]="messageScheduleData"
>
</app-campaign-message-schedule>
