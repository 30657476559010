<app-impersonation-bar></app-impersonation-bar>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0"
               aria-valuemax="100" style="width:66.64%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-auto d-flex justify-content-center flex-column text-center opt-in-email-text">
    <h5>
      No problem, to comply with wireless regulations we'll send all SMS opt-in requests
      via email.
    </h5>
  </div>

  <div class="footer-section">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded"
                (click)="redirectToBack()">
          <i class="fa fa-arrow-left"></i>&nbsp;Back
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end"
                (click)="redirectToSubscribeText()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
