import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserToken } from 'src/models/UserToken';
import { ToastService } from 'src/services/toast.service';
import { UserTokenService } from 'src/services/user-token.service';
import { ResponseTicket, SubmitTicketState } from '../../models/ResponseTicket';
import { ZenDeskService } from '../../services/zendesk.service';

@Component({
  selector: 'app-submit-a-ticket',
  templateUrl: './submit-a-ticket.component.html',
  styleUrls: ['./submit-a-ticket.component.scss']
})
export class SubmitATicketComponent implements OnInit {

  formGroup: FormGroup;
  isSuccessScreenVisible = false;
  userToken: UserToken;
  file: File;
  public submitTicketState: SubmitTicketState;
  responseTicket: ResponseTicket;
  fileName: string;
  isFormSubmitted = false;
  constructor(
    private fb: FormBuilder,
    private userTokenService: UserTokenService,
    private zenDeskService: ZenDeskService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.submitTicketState = SubmitTicketState.Start;
    this.userToken = this.userTokenService.getUser();
    this.createForm();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  createForm() {
    let tenantName = this.userToken.TenantName ?? '';
    let phoneNumber = this.userToken.phone_number ?? '';
    let firstName = this.userToken.given_name;
    let lastName = this.userToken.family_name;
    this.formGroup = this.fb.group({
      firstName: [{ value: firstName, disabled: true }, Validators.required],
      lastName: [{ value: lastName, disabled: true }, Validators.required],
      phoneNumber: [null, [Validators.pattern(/(^\d{10}$)|(^\d{3}[-]\d{3}[-]\d{4}$)|(^\d{3}[.]\d{3}[.]\d{4}$)|(^(\()\d{3}(\))[\s]\d{3}-\d{4}$)/s), Validators.maxLength(25)]],
      email: [{ value: this.userToken.email, disabled: true }, Validators.required],
      companyName: [{ value: tenantName, disabled: true }],
      subject: ['', Validators.required],
      description: ['', Validators.required],

      fileUpload: ['']
    });
  }

  onSubmit() {
    this.isFormSubmitted = true;
    if (this.formGroup.invalid) {
      return;
    }
    // Todo: Add API call form submit ticket
    let name = this.formGroup.controls['firstName'].value ?? '';
    let lastName = this.formGroup.controls['lastName'].value ?? '';
    let phoneNumber = this.formGroup.controls['phoneNumber'].value ?? '';
    let email = this.formGroup.controls['email'].value ?? '';
    let comments = this.formGroup.controls['description'].value ?? '';
    let companyName = this.formGroup.controls['companyName'].value ?? '';
    let subject = this.formGroup.controls['subject'].value ?? '';
    let fullName = name + ' ' + lastName;
    if (companyName) {
      comments += "\r\n Company Name: " + companyName;
    }
    if (phoneNumber) {
      comments += "\r\n PhoneNumber: " + phoneNumber;
    }


    // Create form data
    const formData = new FormData();
    if (this.file) {
      // Store form name as "file" with file data
      formData.append("attachment", this.file, this.file.name);
    }

    this.zenDeskService.CreateZenDeskTicket(subject, encodeURI(comments), email, fullName, formData).subscribe((data: ResponseTicket) => {
      this.responseTicket = data;
      if (this.responseTicket.ticketID == 0) {
        this.toastService.showError(this.responseTicket.message);
      } else {
        this.submitTicketState = SubmitTicketState.Success;

        // Move to success Screen
        this.isSuccessScreenVisible = true;
      }
    });
  }
  public onFileSelected(event) {
    if (event) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
    }
  }
  isSuccess(): boolean {
    return this.submitTicketState == SubmitTicketState.Success;
  }
  isStart(): boolean {
    return this.submitTicketState == SubmitTicketState.Start;
  }
  isFailure(): boolean {
    return this.submitTicketState == SubmitTicketState.Failure;
  }
}
