<div class="Holiday" style="font-weight: 500; color: #2e2e2e; padding: 10px 0px 20px 25px;" [formGroup]="oneTimeHolidayForm" >
  <div class="row my-3">
    <div class="col">Holiday</div>
  </div>
  <div class="row" id="holiday-msg-schedule">
    <div class="col-7">
      <p-dropdown
        [options]="HolidayEventList"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [showClear]="true"
        resetFilterOnHide="true"
        formControlName="holiday"
        optionValue="holidayEventId"
        appendTo="body"
      >
        <ng-template let-holiday pTemplate="item">
          <div class="holiday-item">
            <div>{{ holiday.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="row mt-4 mb-3">
    <div class="col-5">Time&nbsp;
      <span *ngIf="messageType == 2 && allowTCPAForTextMessages==true" [title]="" class="fa fa-info-circle info-icon info" 
              pTooltip="{{timeToolTipe}}" tooltipPosition="top" tooltipZIndex="9999999">
       </span>
    </div>
    <div class="col-6" style="margin-left:12px;">Time Zone</div>
  </div>
  <div class="row mb-2" id="holiday-time-schedule">
    <div class="col-2" style="width: 14% !important;padding-right: 7.5px;">
      <p-dropdown
        [options]="HourList"
        optionLabel="name"
        [filter]="false"
        filterBy="name"
        [showClear]="false"
        placeholder="Hour"
        formControlName="hour"
        optionValue="code"
        appendTo="body"
        (onChange)="OnHourChange($event)"
      >
        <ng-template let-hour pTemplate="item">
          <div class="hour-item">
            <div>{{ hour.name }}</div>
          </div>
        </ng-template>
      </p-dropdown> 
    </div>
    <div class="col-1 align-self-center justify-self-center p-0" style="width:1%;color: #797979;">:</div>
    <div class="col-2" style="width: 14% !important;padding-right: 7.5px;padding-left: 7.5px;">
      <p-dropdown
        [options]="MinList"
        optionLabel="name"
        [filter]="false"
        filterBy="name"
        [showClear]="false"
        placeholder="Minute"
        formControlName="minute"
        optionValue="code"
        appendTo="body"
      >
        <ng-template let-minute pTemplate="item">
          <div class="minute-item">
            <div>{{ minute.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-2" style="width: 15% !important;padding-right: 7.5px;padding-left: 7.5px;">
      <p-dropdown
        [options]="AMPMList"
        optionLabel="name"
        [filter]="false"
        filterBy="name"
        [showClear]="false"
        placeholder="Meridiem"
        formControlName="meridiem"
        optionValue="code"
        appendTo="body"
        [disabled]="messageType == 2 && allowTCPAForTextMessages==true"
      >
        <ng-template let-meridiem pTemplate="item">
          <div class="meridiem-item">
            <div>{{ meridiem.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-5" style="padding-right: 7.5px;padding-left: 7.5px;">
      <p-dropdown
        [options]="TimezoneList"
        optionLabel="displayName"
        [filter]="true"
        filterBy="displayName"
        [showClear]="true"
        resetFilterOnHide="true"
        placeholder="Time Zone"
        formControlName="timeZone"
        optionValue="timezoneId"
        appendTo="body"
      >
        <ng-template let-timeZone pTemplate="item">
          <div class="timeZone-item">
            <div>{{ timeZone.displayName }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
       <span >{{nextScheduleNotification}}</span>
    </div>
  </div>
</div>
