import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class CampaignMessageScheduleDataService {
  public campaignMessageId: string;
  public campaignMessageType: number;
  public scheduleType: string;
  public scheduleSubtype: string;
  public scheduleDataJSON: any;
  public dialogTitle: string;
  public isReload: boolean;
  public tabIndex: number;
  public componentIndex: number;

  constructor() {
    this.campaignMessageId = '';
    this.scheduleType = '';
    this.scheduleSubtype = '';
    this.scheduleDataJSON = '';
    this.dialogTitle = '';
    this.isReload = false;
    this.tabIndex = -1;
    this.componentIndex = -1;
    this.campaignMessageType=1;
  }

  Dispose() {
    this.scheduleType = '';
    this.scheduleType = '';
    this.scheduleSubtype = '';
    this.scheduleDataJSON = '';
    this.dialogTitle = '';
    this.campaignMessageId = '';
  }
}