import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 
import { Observable, throwError } from 'rxjs'; 
import { catchError, tap } from 'rxjs/operators'; 
import { RequestResponse } from 'src/models/RequestResponse' 
import { AppSettings } from 'src/appSettings'; 
import { NGENReport } from 'src/models/NGENReport';
import { MenuItem } from 'primeng/api';
 
@Injectable() 
export class ReportsService { 
  constructor(private http: HttpClient) { } 

  /** Gets the reports the calling user has access to. */
  async getReports() {
    console.info("Call /GetAllReports in the webapi Reports Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_REPORTS + '/GetAllReports', new RequestResponse())
      .pipe(catchError(this.handleError)).toPromise();
  }

  /**  
   * Retrieves all necessary data for calling component. 
   *  
   * @param requestResponse - optional in oder to specify specific service information. 
   */ 
  async getAllReports(requestResponse?: RequestResponse) { 
    // Angular does not support posting a body inside the /GET verb. 
    // https://github.com/angular/angular/issues/9927 
 
    // if we were not passed a RequestResponse object, use the default. 
    if ((requestResponse === undefined) || (requestResponse === null)) 
      requestResponse = new RequestResponse(); 
 
    console.info("Call /GetAllReports in the webapi Reports Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_REPORTS + '/GetAllReports', requestResponse) 
      .pipe(catchError(this.handleError)).toPromise(); 
  }

  /** 
   * Saves a Report. 
   */
  async saveReport(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /SaveReport in the webapi Reports Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_REPORTS + "/SaveReport", requestResponse)
      .pipe(catchError(this.handleError)).toPromise();
  }


  /** 
   * Deletes a Report by Id. 
   */
  async deleteReport(reportId: string) {
    console.info("Call /DeleteReport in the webapi Reports Controller");
    return await this.http.delete<any>(AppSettings.API_GET_REPORTS + "/DeleteReport?reportId=" + reportId)
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the 'Pulse' owned All Loans report. 
   */
  async getPulseAllContactsReport() {
    console.info("Call /GetPulseAllContactsReport in the webapi Reports Controller");
    return await this.http.get<NGENReport>(AppSettings.API_GET_REPORTS + "/GetPulseAllContactsReport")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the 'Pulse' owned Active Contacts report. 
   */
  async getPulseActiveContactsReport() {
    console.info("Call /GetPulseActiveContactsReport in the webapi Reports Controller");
    return await this.http.get<NGENReport>(AppSettings.API_GET_REPORTS + "/GetPulseActiveContactsReport")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the 'Pulse' owned Active Loans report. 
   */
  async getPulseActiveLoansReport() {
    console.info("Call /GetPulseActiveLoansReport in the webapi Reports Controller");
    return await this.http.get<NGENReport>(AppSettings.API_GET_REPORTS + "/GetPulseActiveLoansReport")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the 'Pulse' owned Active Leads report. 
   */
  async getPulseActiveLeadsReport() {
    console.info("Call /GetPulseActiveLeadsReport in the webapi Reports Controller");
    return await this.http.get<NGENReport>(AppSettings.API_GET_REPORTS + "/GetPulseActiveLeadsReport")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the 'Pulse' owned Closed Loans report. 
   */
  async getPulseClosedLoansReport() {
    console.info("Call /GetPulseClosedLoansReport in the webapi Reports Controller");
    return await this.http.get<NGENReport>(AppSettings.API_GET_REPORTS + "/GetPulseClosedLoansReport")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the Loan ownership menu items.
   */
  async GetLoanOwnershipMenuItems(addUnassigned: boolean = false) {
    console.info("Call /GetLoanOwnershipMenuItems in the webapi Reports Controller");
    return await this.http.get<any>(AppSettings.API_GET_REPORTS + "/GetLoanOwnershipMenuItems?addUnassigned=" + addUnassigned.valueOf().toString())
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the Contact ownership menu items.
   */
  async GetContactOwnershipMenuItems() {
    console.info("Call /GetContactOwnershipMenuItems in the webapi Reports Controller");
    return await this.http.get<any>(AppSettings.API_GET_REPORTS + "/GetContactOwnershipMenuItems")
      .pipe(catchError(this.handleError)).toPromise();
  }

  /** 
   * Gets the Messages associated with the specified Report.
   */
  async GetReportMessages(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb. 
    // https://github.com/angular/angular/issues/9927 

    // if we were not passed a RequestResponse object, use the default. 
    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /GetMessageReports in the webapi Reports Controller");
    return await this.http.post<RequestResponse>(AppSettings.API_GET_REPORTS + '/GetReportMessages', requestResponse)
      .pipe(catchError(this.handleError)).toPromise(); 
  }


  /** 
  * Error processing. 
  *  
  * @param caught The caught error information. 
  */ 
  private handleError(caught: any) { 
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  } 
} 

/**
 * Interface for Owner filtering menu items.
 * teamId and tooltip properties added
 */
export interface OwnerMenuItem extends MenuItem {
  teamId?: string; // custom Property
  tooltip?: string; // custom Property
}
