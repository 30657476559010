import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { PeopleAndLoanEditTagData, PeopleAndLoanFieldLookup, PeopleAndLoanFilterData, PeopleAndLoanFilterOutput, PeopleLoanList, PeopleLoanListAndFilter, PeopleLoanListOrFilter, RhsDropdown, RhsDropdownItem, RhsQueryData } from '../../../models/PeopleAndLoanFilterOutput';
import { PeopleAndLoanService } from '../../../services/peopleandloans.service';
import { ToastService } from '../../../services/toast.service';
import { DropDownItem } from '../../../models/TenantUser'
import { CalendarAndTimeZoneService } from '../../services/CalendarAndTimeZoneService';
import { MultiSelect } from 'primeng/multiselect';
@Component({
  selector: 'app-people-and-list-advanced-filter-loan-status-type-generic-list',
  templateUrl: './people-and-list-filter-loan-status-type-generic-list.component.html',
  styleUrls: ['./people-and-list-filter-loan-status-type-generic-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleAndListAdvancedFilterLoanStatusTypeGenericListComponent implements OnInit, OnDestroy {
  //* TEXT datatype has RhsInputControl1 only so create seprate comp for number.
  public selectedOperand: any | null;
  public textFormGroup: FormGroup | null;
  public items: FormArray | null;
  private sub: Array<Subscription>;
  private selectedFilter: PeopleAndLoanFieldLookup | null;

  private peopleAndLoanFilterOutput: PeopleAndLoanFilterOutput;
  @Input() OperandDropDownList: any | null;
  private tmpPeopleAndLoanFilters: PeopleAndLoanFilterData;
  private peopleAndLoanFilters: PeopleAndLoanFilterData;
  @Input() get SelectedFilter(): any | null {
    return this.selectedFilter;
  }
  set SelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      this.generateForm();
    }
  }
  @Output() onCreateFilterEvent = new EventEmitter<PeopleAndLoanFilterOutput>();
  @Output() onCancelFilterEvent = new EventEmitter<boolean>();
  //private existingListOrFilters: PeopleLoanListOrFilter[];
  private storageHelper: StorageHelper;
  public disableApplyFilter: boolean;
  //public rhsDropdown: RhsDropdown[];
  public rhsDropdownItem: RhsDropdownItem[];
  public YearList: DropDownItem[];
  public months: any[];
  private editNode: PeopleAndLoanEditTagData | null;
  private insertIndex: number;
  @ViewChild('txtLhsValue1', { static: false }) multiSelect: MultiSelect;
  close() {
    this.multiSelect.overlayVisible = false;
    //this.multiSelect.close(null);
    this.cdr.detectChanges();
  }

  constructor(public formBuilder: FormBuilder, private toastService: ToastService,
    private peopleAndLoanService: PeopleAndLoanService, private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService) {
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.sub = new Array<Subscription>();
    this.peopleAndLoanFilterOutput = new PeopleAndLoanFilterOutput(false, null);
    //this.existingListOrFilters = [];
    this.storageHelper = new StorageHelper();
    this.disableApplyFilter = true;
    //this.rhsDropdown = [];
    this.rhsDropdownItem = [];
    this.months = this.calendarAndTimeZoneService.GetMonths();
    this.insertIndex = 0;
  }

  ngOnInit(): void {
    this.getYears();
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
  }

  getYears() {
    this.YearList = [];
    for (var x = 1985; x < 2050; x++) {
      this.YearList.push({ item_id: x.toString(), item_text: x.toString(), isDisabled: false });
    }
  }

  // getRhsDropDownData(lookupId) {
  //   if (lookupId && this.rhsDropdown.length) {
  //     let arr = this.rhsDropdown.filter((x) => x.lookupId == lookupId);
  //     if (arr?.length) {
  //       return arr[0].rhsDropdownItem;
  //     }
  //   }
  // }

  operandChanged(event, index) {//to hide show textbox and remove validations.
    if (index > -1) {
      // let query = event?.value?.LhsSelectInputControlSqlCmd ?? '';
      // let lookupId = event?.value?.PeopleAndLoansFieldLookupId ?? '';
      // //to fill Rhs
      // if (query) {
      //   //generate cache key
      //   let cacheKey = this.peopleAndLoanService.stringToHash(query);
      //   //fetch data and fill dropdown 
      //   let item: RhsQueryData = this.storageHelper.GetDataFromStorage(cacheKey)?.value;
      //   if (item && item?.jsonStr) {
      //     if (!this.rhsDropdown.find(x => x.lookupId == lookupId)) {//if not already set
      //       let RhsDropdownItemArr: RhsDropdownItem[] = JSON.parse(item?.jsonStr);
      //       let rhsDropdown = new RhsDropdown(lookupId, RhsDropdownItemArr);
      //       this.rhsDropdown.push(rhsDropdown)
      //     }
      //   }
      // }


      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtLhsValue;
      const lookupControl1 = k.controls[index]['controls'].txtRHSValue1;
      const lookupControl2 = k.controls[index]['controls'].txtRHSValue2;

      lookupControl?.clearValidators();//clear Validators
      lookupControl1?.clearValidators();//clear Validators
      lookupControl2?.clearValidators();//clear Validators

      lookupControl1?.reset();
      lookupControl2?.reset();

      // if (!/\bBLANK\b|\bNOTBLANK\b/.test(event.value.OpCode)) {
      //   lookupControl?.setValidators([Validators.required]);//reset Validators
      // }
      lookupControl?.setValidators([Validators.required]);//reset Validators


      if (event.value.RhsInputControl1) {
        lookupControl1?.setValidators([Validators.required]);//reset Validators
      }

      if (event.value.RhsInputControl2) {
        lookupControl2?.setValidators([Validators.required]);//reset Validators
      }

      // if (event.value.RhsInputControl1 == 'Textbox' &&
      //   /\NEXTNDAYS\b|\bYEAR\b|\bDAYAGO\b/.test(event.value.OpCode)) {
      //   lookupControl1.setValue('1');
      // }
      if (event.value.RhsInputControl1 == 'Textbox' &&
        /\NEXTNDAYS\b|\bDAYAGO\b/.test(event.value.OpCode)) {
        //lookupControl1?.setValue('1');
        lookupControl1?.reset();
        lookupControl1?.setValue(1);
      }

      lookupControl?.updateValueAndValidity();//validate right now
      lookupControl1?.updateValueAndValidity();//validate right now
      lookupControl2?.updateValueAndValidity();//validate right now
      this.cdr.detectChanges();
      this.disableApplyFilter = !this.textFormGroup?.valid;

    }
  }

  operandChangedNew(index) {//to hide show textbox and remove validations.
    if (index > -1) {
      let k = this.textFormGroup?.get('items') as FormArray
      const lookupControl = k.controls[index]['controls'].txtRHSValue;

      lookupControl?.clearValidators();//clear Validators
      if (!/\bBLANK\b|\bNOTBLANK\b/.test(this.selectedFilter.OpCode)) {
        lookupControl?.setValidators([Validators.required]);//reset Validators
      }
      lookupControl?.updateValueAndValidity();//validate right now
    }
  }

  private generateForm() {
    this.unSubscribeAllSubs();
    this.fillLhsDropDown();

    this.textFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });

    if (this.SelectedFilter?.isAddFlag) {
      this.operandChangedNew(0);
    }

    let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();

    this.items = this.formDataCtrl;
    this.sub.push(
      this.textFormGroup.valueChanges.pipe(
        //debounceTime(500),//delay and  stop unnecessary propogation.
        distinctUntilChanged()
      ).subscribe(item => {
        listAndFilters.PeopleLoanListOrFilters = [];//clear all
        let idx = 0;
        listAndFilters.id = idx + 1;
        listAndFilters.peopleAndLoansFieldLookupId = this.SelectedFilter?.PeopleAndLoansFieldLookupId;

        //Changed json fetchiing first rrecord value
        if (item?.items?.length) {
          listAndFilters.group = item.items[0].operandDropDown.FieldGroupName;
          listAndFilters.datasource = item.items[0].operandDropDown.DataSource;
          listAndFilters.datacolumn = item.items[0].operandDropDown.DataColumn;
          listAndFilters.type = item.items[0].operandDropDown.DataType;
          if (item.items[0].operandDropDown.hasOwnProperty('FieldSubGroupName')) {
            listAndFilters.subGroup = item.items[0].operandDropDown.FieldSubGroupName;
          }
        }

        //this.existingListOrFilters = [];
        // let newIndex = -1;
        // let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
        // if (savedFilter?.value) {
        //   let andFilterIndex = -1;
        //   let obj = JSON.parse(JSON.stringify(savedFilter.value));
        //   andFilterIndex = obj?.PeopleLoanList?.PeopleLoanListAndFilters?.length ?? -1;
        //   if (obj && andFilterIndex) {
        //     if (andFilterIndex != -1) {
        //       listAndFilters.id = andFilterIndex + 1;
        //     }
        //     obj.PeopleLoanList.PeopleLoanListAndFilters.forEach(laf => {
        //       if (laf.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
        //         this.existingListOrFilters = laf.PeopleLoanListOrFilters;
        //         return false;//break;
        //       }
        //     });
        //     newIndex = this.existingListOrFilters.length + 1;
        //   }
        // }
        //loop and add/remove validation as per dropdown value.
        item.items.forEach((element, index) => {
          if (index == 0) {
            listAndFilters.PeopleLoanListOrFilters = [];//clear all
          }
          let lhsValueArr = element?.txtLhsValue;//removing special symbol

          if (lhsValueArr?.length) {
            lhsValueArr.forEach(lhsVal => {
              //#region Loop1
              //let lhsValue = element?.txtLhsValue;//removing special symbol
              let lhsValue = lhsVal;//removing special symbol
              let rhsValue1 = element?.txtRHSValue1;//removing special symbol
              let rhsValue2 = element?.txtRHSValue2;//removing special symbol 
              let rhsValue1MonthNumber = 0;

              if (element.operandDropDown.OpDisplay == 'month is') { //convert month to number to be saved.
                rhsValue1 = rhsValue1?.toLocaleString('en-US', {
                  month: 'long',
                });
                rhsValue1MonthNumber = this.getMonthNumberFromName(rhsValue1?.toLocaleString('en-US', {
                  month: 'long',
                }));
              }
              else if (element.operandDropDown.OpDisplay == 'year is') {
                if (rhsValue1?.toString()?.length > 4) {
                  rhsValue1 = rhsValue1?.toString()?.substr(0, 4);
                }
              }
              else {
                if (element.operandDropDown.RhsInputControl1 == 'Calendar') {
                  if (element?.txtRHSValue1) {
                    let dateStr = this.datePipe.transform(element?.txtRHSValue1, 'MM/dd/yyyy')?.toString()
                    rhsValue1 = dateStr ?? '';
                  }
                  if (element?.txtRHSValue2) {
                    let dateStr = this.datePipe.transform(element?.txtRHSValue2, 'MM/dd/yyyy')?.toString()
                    rhsValue2 = dateStr ?? '';
                  }
                }
              }

              let rhsArr: string[] = [];
              if (rhsValue1) rhsArr.push(rhsValue1);
              if (rhsValue2) rhsArr.push(rhsValue2);

              let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
              filter.id = (index + 1);
              filter.operator = element.operandDropDown.OpCode;
              filter.lhs = lhsValue;
              filter.rhs = rhsArr;
              filter.peopleAndLoansTypeDefLookupId = element.operandDropDown.PeopleAndLoansTypeDefLookupId;

              let criteriaQuery = element.operandDropDown.Criteria;
              if (criteriaQuery) {
                criteriaQuery = criteriaQuery.replace('{{lhs}}', lhsValue);
                criteriaQuery = criteriaQuery.replace('{{DataColumn}}', element.operandDropDown.DataColumn);
                criteriaQuery = criteriaQuery.replace('{{rhs1}}', rhsValue1MonthNumber || rhsValue1);
                criteriaQuery = criteriaQuery.replace('{{rhs2}}', rhsValue2);
                filter.criteria = criteriaQuery;
              }

              let htmlTemplate = element.operandDropDown.DisplayHtmlTemplate;
              if (htmlTemplate) { //show display text here.
                if (index == 0) {
                  let displayText = this.rhsDropdownItem.find(x => x.Code == lhsValue)?.Name ?? '';
                  //htmlTemplate = htmlTemplate.replace('{{lhs}}', (this.selectedFilter?.DataColumnDisplay || '') + " " + displayText);//to add loan status in chip
                  htmlTemplate = htmlTemplate.replace('{{lhs}}', displayText);
                  htmlTemplate = htmlTemplate.replace('{{rhs1}}', rhsValue1);
                  htmlTemplate = htmlTemplate.replace('{{rhs2}}', rhsValue2);
                  let prefix = '';// listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
                  filter.displayHtml = prefix + htmlTemplate;
                }
              }
              listAndFilters.PeopleLoanListOrFilters.push(filter)

              //#endregion 

              index = index + 1;
            });

          }

        });
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters);
        //validate and show apply button
        if (!this.textFormGroup?.valid) {
          this.disableApplyFilter = true;
        } else {
          this.disableApplyFilter = false;
        }
        this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
      })
    );
  }

  applyFilter() {
    if (this.textFormGroup?.valid) {//if json has been received.
      this.validateAndEmitSuccessJson();

    } else {
      this.emitFailureJson();
    }
  }

  private createItems(): FormGroup[] {
    let savedFilter = this.storageHelper.GetDataFromStorage('PeopleAndLoan_Filter');
    this.peopleAndLoanFilters = savedFilter.value;
    this.tmpPeopleAndLoanFilters = JSON.parse(JSON.stringify(savedFilter.value));
    //edit filter from input or oberabel.
    const formGroupArr: FormGroup[] = [];

    //Edit CASE  
    let editNodeTmp = this.storageHelper.GetDataFromStorage('PeopleAndLoan_editNode');//fetch 
    if (editNodeTmp?.value?.peopleAndLoansFieldLookupId &&
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length > 0) {
      this.editNode = editNodeTmp.value;
      let andIndex = this.editNode?.andIndex;
      let lookupId = this.editNode?.peopleAndLoansFieldLookupId;
      //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean

      let toIterate = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.
        filter(x => x.id == andIndex && x.peopleAndLoansFieldLookupId == lookupId);

      let lblValue = editNodeTmp?.value?.labelStr;
      let indexItr = 0;
      let lshValueArr = toIterate[0]?.PeopleLoanListOrFilters?.map(x => x.lhs);

      toIterate[0].PeopleLoanListOrFilters.forEach((itm) => {
        // if (!lblValue) {
        //   lblValue = itm.displayHtml.substring(0, itm.displayHtml.indexOf("</b>"))
        //   lblValue = lblValue.replace("<b class=\"lhs\">", "").trim();
        // }
        if (indexItr == 0) {
          indexItr++;//txtLhsValue
          let selectedDropdown = this.OperandDropDownList.find(x => x.PeopleAndLoansTypeDefLookupId == itm.peopleAndLoansTypeDefLookupId);

          let lhsValue1: any;
          let rhsValue1: any;
          let rhsValue2: any;
          if (itm?.rhs?.length > 0) {
            rhsValue1 = itm.rhs[0];
          }
          if (itm?.rhs?.length > 1) {
            rhsValue2 = itm.rhs[1];
          }

          let selectedLHSDropdown = null;

          if (lshValueArr?.length) {
            lhsValue1 = itm.lhs;
            selectedLHSDropdown = this.rhsDropdownItem.filter(x => lshValueArr.indexOf(x.Code) > -1).map(a => a.Code);
            // selectedLHSDropdown = this.rhsDropdownItem.filter(x => x.Code == lhsValue1).map(a => a.Code);
          }

          let validatorOrOpts = [
            Validators.required
          ];

          // let opDisplay = selectedDropdown.OpDisplay;
          // if (opDisplay == 'month is') {
          //   rhsValue1 = new Date(rhsValue1 + "1, 2023");//to only get month
          // } else if (opDisplay == 'year is') {

          // }
          // else {
          //   rhsValue1 = new Date(rhsValue1);//new Date(new Date(rhsValue1));
          //   rhsValue2 = new Date(rhsValue2 ?? '');
          // }

          //conditional validation
          if (selectedDropdown.OpCode == 'NOTBLANK' || selectedDropdown.OpCode == 'BLANK') {
            validatorOrOpts = [];//no validation
          }


          formGroupArr.push(
            this.formBuilder.group({
              label: new FormControl(lblValue),
              operandDropDown: new FormControl(selectedDropdown),
              txtLhsValue: new FormControl(selectedLHSDropdown, [Validators.required]),//Select,  select from lhsQuery{ value: '' }
              //txtLhsValue: new FormControl('', validatorOrOpts),//Select,  select from lhsQuery
              txtRHSValue1: new FormControl(rhsValue1),
              txtRHSValue2: new FormControl(rhsValue2),
            })
          );

        }
      });
    }
    else {
      let selectedDropdown = null;
      let selectedLHSDropdown = null;
      if (this.SelectedFilter?.isAddFlag) {//insert as blank
        selectedDropdown = this.OperandDropDownList.find(x => x.OpCode == 'BLANK');
        selectedLHSDropdown = this.rhsDropdownItem.find(x => x.Code == 'LoanApplicationDate');
      }


      formGroupArr.push(
        this.formBuilder.group({
          label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
          operandDropDown: new FormControl(selectedDropdown, [Validators.required]),//required due to lhsvalue
          txtLhsValue: new FormControl(selectedLHSDropdown, [Validators.required]),//Select,  select from lhsQuery
          txtRHSValue1: new FormControl(''),
          txtRHSValue2: new FormControl(''),
        })
      );
    }
    return formGroupArr;
  }

  // private createItems(): FormGroup[] {
  //   const formGroupArr: FormGroup[] = [];
  //   formGroupArr.push(
  //     this.formBuilder.group({
  //       label: new FormControl(this.SelectedFilter?.label),
  //       operandDropDown: new FormControl({ value: '' }),
  //       txtLhsValue: new FormControl('', [Validators.required]),//Select,  select from lhsQuery
  //       txtRHSValue1: new FormControl(''),
  //       txtRHSValue2: new FormControl(''),
  //     })
  //   );
  //   return formGroupArr;
  //   // this.MoreThanStart(this.textFormGroup?.value.txtRHSValue1)
  // }

  addMore() {
    let newrow = this.formBuilder.group({
      label: new FormControl(this.SelectedFilter?.DataColumnDisplay),
      //operandDropDown: new FormControl({ value: '' }),
      operandDropDown: new FormControl('', [Validators.required]),
      txtLhsValue: new FormControl('', [Validators.required]),//Select,  select from lhsQuery
      txtRHSValue1: new FormControl(''),
      txtRHSValue2: new FormControl(''),
    });
    (<FormArray>this.textFormGroup?.get('items')).push(newrow);
  }

  // higherThan(field_name): ValidatorFn {

  //   return (control: AbstractControl): { [key: string]: any } => {

  //     const input = control.value;

  //     const isHigher = input < control?.root?.value?.items[field_name];

  //     return isHigher ? null : { 'higherThan': { isHigher: isHigher } };
  //   };
  // }
  public MoreThanStart(end: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return parseInt(control?.value) < end ? { 'moreThan': true } : {};
    };
  }

  get formDataCtrl(): FormArray {
    const formArray = this.textFormGroup?.get('items') as FormArray;
    return formArray;
  }

  deleteRow(index) {
    let k = this.textFormGroup?.get('items') as FormArray
    k.removeAt(index);
  }



  validateAndEmitSuccessJson() {
    //console.log('validateAndEmitSuccessJson-EMITed fromm here:');
    let len = this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.length;
    if (len) {
      if (this.editNode?.peopleAndLoansFieldLookupId) {//if it is edit
        let andIndexRemove =
          this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.findIndex(x =>
            x.id == this.editNode?.andIndex &&
            x.peopleAndLoansFieldLookupId == this.editNode.peopleAndLoansFieldLookupId);

        if (andIndexRemove !== -1) {
          this.peopleAndLoanFilters.PeopleLoanList.
            PeopleLoanListAndFilters.splice(andIndexRemove, 1);
        }
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = this.editNode?.andIndex;

      } else {
        this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters[0].id = len + 1
      }

      // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.forEach(ele => {
      //   if (ele.peopleAndLoansFieldLookupId == this.SelectedFilter?.key) {
      //     ele.isDeleted = true;//set existing deleted.
      //   }
      // });
      // let tm = this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => x.isDeleted == false);

      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);

    }
    else {//add without any logic 
      this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(
        ...this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters);
    }
    this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];//reset 

    // this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters =
    //   this.peopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.filter(x => !x.isDeleted);
    this.peopleAndLoanFilterOutput.isSuccess = true;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = this.peopleAndLoanFilters;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  emitFailureJson() {
    this.peopleAndLoanFilterOutput.isSuccess = false;
    this.peopleAndLoanFilterOutput.peopleAndLoanFilterData = null;
    this.onCreateFilterEvent.emit(this.peopleAndLoanFilterOutput);
  }

  cancelFilter() {
    this.onCancelFilterEvent.emit(true);
  }

  getMonthNumberFromName(monthName) {
    return new Date(`${monthName} 1, 2022`).getMonth() + 1;
  }

  private fillLhsDropDown() {
    if (this.OperandDropDownList?.length) {
      let query = this.OperandDropDownList[0]?.LhsSelectInputControlSqlCmd ?? '';
      if (query) {
        //generate cache key
        let cacheKey = this.peopleAndLoanService.stringToHash(query);

        //fetch data and fill dropdown 
        let item: RhsQueryData = this.storageHelper.GetDataFromStorage(cacheKey)?.value;
        if (item && item?.jsonStr) {
          this.rhsDropdownItem = [];
          this.rhsDropdownItem = JSON.parse(item?.jsonStr);
          this.cdr.detectChanges();
          //this.rhsDropdownItem = this.rhsDropdownItem.sort((a, b) => { return a.Name.toLowerCase() >= b.Name.toLowerCase() ? 1 : -1 });
        }
      }
    }
  }


  setAndEmit() {
    if (this.selectedFilter) {
      let listAndFilters: PeopleLoanListAndFilter = new PeopleLoanListAndFilter();
      listAndFilters.PeopleLoanListOrFilters = [];//clear all
      listAndFilters.id = (this.insertIndex + 1);
      listAndFilters.peopleAndLoansFieldLookupId = this.selectedFilter?.PeopleAndLoansFieldLookupId;
      listAndFilters.group = this.selectedFilter?.FieldGroupName;
      listAndFilters.datasource = this.selectedFilter?.DataSource;
      listAndFilters.datacolumn = this.selectedFilter?.DataColumn;
      listAndFilters.type = this.selectedFilter?.DataType;
      if (this.selectedFilter.FieldSubGroupName) {
        listAndFilters.subGroup = this.selectedFilter?.FieldSubGroupName;
      }
      listAndFilters.PeopleLoanListOrFilters = [];//clear all

      //loop
      let filter: PeopleLoanListOrFilter = new PeopleLoanListOrFilter();
      filter.id = (this.insertIndex + 1);
      filter.operator = this.selectedFilter.OpCode;
      filter.lhs = '';
      filter.rhs = [''];
      filter.peopleAndLoansTypeDefLookupId = this.selectedFilter.PeopleAndLoansTypeDefLookupId;
      let criteriaQuery = this.selectedFilter.Criteria;
      if (criteriaQuery) {
        criteriaQuery = criteriaQuery.replace('{{DataColumn}}', this.selectedFilter.DataColumn);
        criteriaQuery = criteriaQuery.replace('{{rhs1}}', '');
        filter.criteria = criteriaQuery;
      }

      let htmlTemplate = this.selectedFilter.DisplayHtmlTemplate;
      if (htmlTemplate) {
        htmlTemplate = htmlTemplate.replace('{{lhs}}', this.selectedFilter.DataColumnDisplay);
        htmlTemplate = htmlTemplate.replace('{{rhs1}}', "");//iss blank
        let prefix = '';//listAndFilters?.subGroup ? listAndFilters?.subGroup + ' ' : '';
        filter.displayHtml = ' ' + htmlTemplate;
      }
      listAndFilters.PeopleLoanListOrFilters.push(filter);
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters = [];
      this.tmpPeopleAndLoanFilters.PeopleLoanList.PeopleLoanListAndFilters.push(listAndFilters)
      this.insertIndex++;
      this.validateAndEmitSuccessJson();
    }
  }

  onListChange(event: any, index) {
    if (index > -1) {
      // let k = this.textFormGroup?.get('items') as FormArray
      // const lookupControl = k.controls[index]['controls'].txtLhsValue;
      // lookupControl?.updateValueAndValidity();//validate right now
      // this.cdr.detectChanges();
    }
  }

  unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }

  ngOnDestroy(): void {
    this.selectedOperand = null;
    this.OperandDropDownList = null;
    this.SelectedFilter = null;
    this.textFormGroup = null;
    this.items = null;
    this.peopleAndLoanFilters.PeopleLoanList = new PeopleLoanList();//clean all json
    //this.storageHelper.ClearStorageByKey('PeopleAndLoan_editNode');//and clean
    this.unSubscribeAllSubs();
  }

}
