<!--
*************************************************************************************
Page Heading and Buttons
*************************************************************************************
-->
<div class="content table-search-row second-header-fixed sub-header" role="main">
  <div class="text-wrapper row">
    <div class="col-md-3 pl-0 flex flex-column justify-content-center">
      <div>
        <label id="lblPageHeader" style="line-height: 1;">{{ user.FirstName }} {{ user.LastName }}</label>
        <br *ngIf="user.Email !== ''" />
        <label *ngIf="user.Email !== ''">{{ user.Email }}</label>
      </div>
    </div>
    <div class="col-md-6 mt-2" style="float: left">
      <label class="page-head-3">{{ role }}</label>
    </div>
  </div>
</div>

<!--
*************************************************************************************
User Editor Form
*************************************************************************************
-->

<!-- <div class="col-md-8 col-xl-6 mx-auto t-grid"> -->
<div class="text-wrapper py-1 mb-3 grid-section">
  <form [formGroup]="companyForm" (ngSubmit)="onSaveButtonClick()">
    <div>
      <div class="row">
        <div class="col-sm-3">
          <p-accordion class="custom-accordion" expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-down">
            <p-accordionTab header="Contact Details">
              <div class="side-detail mb-0 py-0">
                <div class="p-fluid p-formgrid p-grid py-2">
                  <div style="width: 6rem;">
                    <div class="p-col" style="text-align: left">
                      <img style="border-radius: 50%;"
                        [src]="imageSrc == null || imageCleared ? '/assets/images/RoundImageTemplate.png' : imageSrc"
                        onerror="this.onerror=null; this.src='/assets/images/RoundImageTemplate.png'"
                        class="pulseProfileEditAvatar"
                        [style]="{width: '5rem', height: '5rem', 'border-radius': '50%', 'object-fit': 'cover', border: '2px solid #0066ff'}" />
                      <span>
                        <input #_fileInput type="file" (change)="openCropperDialog($event)" accept="image/*" hidden>
                      </span>
                    </div>
                  </div>
                  <div class="p-field p-col-12 p-md-7">
                    <div class="p-col p-ai-xl-center" style="text-align: center">
                      <div>
                        <p-button class="mt-4" ly-button color="primary" type="button" (click)="_fileInput.click()">
                          Upload a Photo
                        </p-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-col-12 py-0">
                  <label for="firstName" class="col-form-label">First Name</label>
                  <input type="text" name="firstName" id="firstName" maxlength="64" placeholder="First Name"
                    class="form-control" formControlName="firstName" [ngClass]="{
                          'is-invalid':
                            companyForm.get('firstName').invalid &&
                            (companyForm.submitted),
                          'is-valid':
                            companyForm.get('firstName').valid &&
                            (companyForm.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm.get('firstName').invalid &&
                          (companyForm.get('firstName').dirty ||
                            companyForm.get('firstName').touched ||
                            companyForm.submitted) &&
                          companyForm.get('firstName').errors?.required
                        ">First name cannot be blank</span>
                  <span class="text-danger" *ngIf="
                          companyForm.get('firstName').invalid &&
                          (companyForm.get('firstName').dirty ||
                            companyForm.get('firstName').touched ||
                            companyForm.submitted) &&
                          companyForm.get('firstName').errors?.minlength
                        ">First name should contain at least 2 characters</span>
                </div>
                <div class="p-col-12 py-0">
                  <label for="lastName" class="col-form-label">Last Name</label>
                  <input type="text" name="lastName" id="lastName" maxlength="64" placeholder="Last Name"
                    class="form-control" formControlName="lastName" [ngClass]="{
                          'is-invalid':
                            companyForm.get('lastName').invalid &&
                            (companyForm.submitted),
                          'is-valid':
                            companyForm.get('lastName').valid &&
                            (companyForm.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm.get('lastName').invalid &&
                          (companyForm.get('lastName').dirty ||
                            companyForm.get('lastName').touched ||
                            companyForm.submitted) &&
                          companyForm.get('lastName').errors?.required
                        ">Last name cannot be blank</span>
                  <span class="text-danger" *ngIf="
                          companyForm.get('lastName').invalid &&
                          (companyForm.get('lastName').dirty ||
                            companyForm.get('lastName').touched ||
                            companyForm.submitted) &&
                          companyForm.get('lastName').errors?.minlength
                        ">Last name should contain at least 2 characters</span>
                </div>
                <div class="p-col-12 py-0">
                  <label for="nmls" class="col-form-label">NMLS</label>
                  <input type="text" name="nmls" id="nmls" maxlength="12" placeholder="NMLS" class="form-control"
                    formControlName="nmls" maxlength="12" [ngClass]="{
                          'is-invalid':
                            companyForm.get('nmls').invalid &&
                            (companyForm.submitted),
                          'is-valid':
                            companyForm.get('nmls').valid &&
                            (companyForm.submitted)
                        }" />
                </div>
                <div class="p-col-12 py-0">
                  <label for="workPhone" class="col-form-label">Work Phone</label>
                  <input type="text" name="workPhone" id="workPhone" class="form-control" formControlName="workPhone"
                    maxlength="25" (input)="workPhoneFormat($event)" [ngClass]="{
                          'is-invalid':
                            companyForm.get('workPhone').invalid &&
                            (companyForm.submitted),
                          'is-valid':
                            companyForm.get('workPhone').valid &&
                            (companyForm.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm.get('workPhone').invalid &&
                          (companyForm.get('workPhone').dirty ||
                            companyForm.get('workPhone').touched ||
                            companyForm.submitted) &&
                          companyForm.get('workPhone').errors?.pattern
                        ">Work Phone is invalid.</span>
                  <span class="text-danger" *ngIf="
                          companyForm.get('workPhone').invalid &&
                          (companyForm.get('workPhone').dirty ||
                            companyForm.get('workPhone').touched ||
                            companyForm.submitted) &&
                          companyForm.get('workPhone').errors?.maxlength
                        ">Max length of Work Phone is 25 digits.</span>
                </div>
                <div class="p-col-12 py-0">
                  <label for="mobilePhone" class="col-form-label">Mobile Phone</label>
                  <input type="text" name="mobilePhone" id="mobilePhone" class="form-control" maxlength="25"
                    formControlName="mobilePhone" (input)="mobilePhoneFormat($event)" [ngClass]="{
                          'is-invalid':
                            companyForm.get('mobilePhone').invalid &&
                            (companyForm.submitted),
                          'is-valid':
                            companyForm.get('mobilePhone').valid &&
                            (companyForm.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm.get('mobilePhone').invalid &&
                          (companyForm.get('mobilePhone').dirty ||
                            companyForm.get('mobilePhone').touched ||
                            companyForm.submitted) &&
                          companyForm.get('mobilePhone').errors?.pattern
                        ">Mobile Phone is invalid.</span>
                  <span class="text-danger" *ngIf="
                          companyForm.get('mobilePhone').invalid &&
                          (companyForm.get('mobilePhone').dirty ||
                            companyForm.get('mobilePhone').touched ||
                            companyForm.submitted) &&
                          companyForm.get('mobilePhone').errors?.maxlength
                        ">Max length of Mobile Phone is 25 digits.</span>
                </div>
                <div class="p-col-12 py-0">
                  <label for="fax" class="col-form-label">Fax</label>
                  <input type="text" name="fax" id="fax" class="form-control" maxlength="25" formControlName="fax"
                    (input)="faxFormat($event)" [ngClass]="{
                          'is-invalid':
                            companyForm.get('fax').invalid &&
                            (companyForm.submitted),
                          'is-valid':
                            companyForm.get('fax').valid &&
                            (companyForm.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm.get('fax').invalid &&
                          (companyForm.get('fax').dirty ||
                            companyForm.get('fax').touched ||
                            companyForm.submitted) &&
                          companyForm.get('fax').errors?.pattern
                        ">Fax is invalid.</span>
                  <span class="text-danger" *ngIf="
                          companyForm.get('fax').invalid &&
                          (companyForm.get('fax').dirty ||
                            companyForm.get('fax').touched ||
                            companyForm.submitted) &&
                          companyForm.get('fax').errors?.maxlength
                        ">Max length of fax is 25 digits.</span>
                </div>
                <div class="p-field p-col-12 p-md-3 d-flex sticky-footer" *ngIf="companyForm.dirty">
                  <p-button (click)="onSaveButtonClick()" label="Save"></p-button>
                  <a (click)="onCancel()"
                    style="color:#2e2e2e;cursor: pointer;font-weight: 400; margin: 7px 25px;text-decoration: underline;"
                    styleClass="p-button-secondary">Cancel</a>
                  <p-confirmDialog header="Save Confirmation" icon="pi pi-question-circle"></p-confirmDialog>
                </div>
            </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div class="col-sm-9">

          <div class="card-body">
          </div>

          <div class="profileinput">
            <!--Premium details-->
            <div *ngIf="isShowSubscription">
              <div class="card rounded-1 editor-card-header-text mb-4">
                <div class="justify-content-between align-items-center">
                  <div *ngIf="billingOwner=='Individual'">
                    <span class="text-white cam-msg-haeder-text">Subscription
                      <span style="float:right;">
                        <button pButton type="button" label="Manage your Subscription" (click)="manageSubscription()">
                        </button>
                      </span>
                    </span>
                  </div>
                  <div *ngIf="billingOwner!='Individual'">
                    <span class="text-white cam-msg-haeder-text">Subscription</span>
                    <p class="text-white font-weight-normal">Your Premium subscription is covered by {{companyName}}.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4 hidden>Marketing Mode</h4>
            <div hidden class="mb-4 current-email">
              Current email/SMS marketing mode
              <span style="float: right;" class="d-flex"><i pTooltip="All text messages will be sent to the current email and 
                mobile phone number of the contact owner." tooltipPosition="top"
                  class="pi pi-exclamation-circle mr-1 mt-two"></i><span class="test-span"> <b>Test </b></span>
                <p-inputSwitch for="testMode" formControlName="testMode" class="inputSwitchActive"
                  [(ngModel)]="isTestMode">
                </p-inputSwitch>
              </span>
            </div>

            <div class="Signature-panel">
              <h4>Email Signature</h4>
              <span style="float:right;">
                <button pButton [ngClass]="{'active': isSimpleEmailEditor}" type="button"
                  class="editor-btn editor-btn-left" (click)="isSimpleEmailEditor=true" label="Simple">
                </button>
                <button pButton [ngClass]="{'active': !isSimpleEmailEditor}" type="button"
                  class="editor-btn editor-btn-right" (click)="isSimpleEmailEditor=false" label="HTML">
                </button>
              </span>
            </div>

            <p-editor [(ngModel)]="emailSignatureHtml" formControlName="emailSignatureHtml" *ngIf="isSimpleEmailEditor"
              [style]="{'min-height':'160px'}">
              <ng-template pTemplate="footer">
                <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                  <button type="button" value="ordered" class="ql-list" aria-label="Ordered List"></button>
                  <button type="button" value="bullet" class="ql-list" aria-label="Unordered List"></button>
                  <button aria-label="Insert Image" type="button" class="ql-image"></button>
                  <button aria-label="Insert Link" type="button" class="ql-link"></button>
                </span>
              </ng-template>
            </p-editor>

            <textarea [(ngModel)]="emailSignatureText" formControlName="emailSignatureText" rows="5" for
              class="form-control" style="min-height:200px;" *ngIf="!isSimpleEmailEditor"></textarea>

            <h4 hidden class="mt-4 mb-0">Default Sending Address</h4>

            <div hidden class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-4 p-md-3 pl-10">
                <p-dropdown placeholder="ACMEMortgage (Company)">
                </p-dropdown>
              </div>
              <div class="p-field p-col-4 pt-3 p-col-3 px-0">
                <span class="font11"> Select Default Address:</span>
                <p>1735 Vista del Cerro, San Diego, CA 92154</p>
              </div>
            </div>

            <h4 class="mt-3">Password</h4>
            <div class="p-field p-col-12 px-0 buttonwrapper pt-1">
              <input type="password" readonly="true" value="Password" name="legalBusinessStreet" maxlength="128"
                class="form-control background-none" />
              <a (click)="showChangePasswordArea()" class="link-text inside-item" style="
              color: #7424f2;
              cursor: pointer;
              margin-left: 17.5px;
              line-height: 34px;
              text-decoration: underline; background-color:transparent" styleClass="p-button-secondary">Change Password
              </a>
            </div>

            <div *ngIf="isChangePasswordVisible" class="eyesection">
              <div class="p-field p-col-6 form-group inner-addon right-addon px-0">
                <label for="currentPassword" class="col-form-label">Current Password</label>
                <div class="input-group col-6 mx-auto">
                  <input pInputPassword formControlName="currentPassword" name="currentPassword"
                    [type]="currentShowPassword ? 'text' : 'password'" class="form-control pwd-control"
                    id="password-field" [(ngModel)]="oldPassword" (input)="onInput()">
                  <div>
                    <i alt="show" class="far fa-eye eye-show" (click)="currentShowPassword = !currentShowPassword"
                      [class.hide]="currentShowPassword"></i>
                    <i alt="hide" class="far fa-eye-slash eye-hide" (click)="currentShowPassword = !currentShowPassword"
                      [class.hide]="!currentShowPassword"></i>
                  </div>
                </div>
              </div>
              <!-- <div class="p-field p-col-6">
                <label for="currentPassword" class="col-form-label">Current Password</label>
                <input pInputPassword type="password" [toggleMask]="true" formControlName="currentPassword" [(ngModel)]="oldPassword" (input)="onInput()"/>
              </div> -->
              <div class="p-fluid p-formgrid p-grid">
                <div class=" p-field p-col-6 form-group inner-addon right-addon pr-0">
                  <label for="newPassword" class="col-form-label">New Password</label>
                  <div class="input-group col-6 mx-auto">
                    <input pInputPassword formControlName="newPassword" name="newPassword"
                      [type]="newShowPassword ? 'text' : 'password'" class="form-control pwd-control"
                      id="password-field" [(ngModel)]="newPassword1" (input)="onInput()">
                    <div>
                      <i alt="show" class="far fa-eye eye-show" (click)="newShowPassword = !newShowPassword"
                        [class.hide]="newShowPassword"></i>
                      <i alt="hide" class="far fa-eye-slash eye-hide" (click)="newShowPassword = !newShowPassword"
                        [class.hide]="!newShowPassword"></i>
                    </div>
                  </div>
                </div>
                <div class=" p-field p-col-6 form-group inner-addon right-addon">
                  <label for="confirmPassword" class="col-form-label">Confirm New Password</label>
                  <div class="input-group col-6 mx-auto">
                    <input pInputPassword formControlName="confirmPassword" name="confirmPassword"
                      [type]="confirmShowPassword ? 'text' : 'password'" class="form-control pwd-control"
                      id="password-field" [(ngModel)]="newPassword2" (input)="onInput()">
                    <div>
                      <i alt="show" class="far fa-eye eye-show" (click)="confirmShowPassword = !confirmShowPassword"
                        [class.hide]="confirmShowPassword"></i>
                      <i alt="hide" class="far fa-eye-slash eye-hide"
                        (click)="confirmShowPassword = !confirmShowPassword" [class.hide]="!confirmShowPassword"></i>
                    </div>
                  </div>
                </div>
                <!-- <div class="p-field p-col-6">
                  <label for="newPassword" class="col-form-label">New Password</label>
                  <input pInputPassword type="password" [toggleMask]="true" formControlName="newPassword" [(ngModel)]="newPassword1" (input)="onInput()"/>
                </div> -->
                <!-- <div class="p-field p-col-6">
                  <label for="confirmPassword" class="col-form-label">Confirm New Password</label>
                  <input pInputPassword type="password" [toggleMask]="true" formControlName="confirmPassword" [(ngModel)]="newPassword2" (input)="onInput()"/>                 
                </div> -->
              </div>
              <div class="requirements-text py-1">
                <b> Password Requirements:</b>
                <ul style="list-style-type: none;" class="password-requirements-text">
                  <li><div [ngClass]="{'text-success': passwordIsCorrectLength && oldPasswordIsCorrectLength}"
                      style="font-weight:400;">- Must be at least 8 characters long</div></li>
                  <li><div [ngClass]="{'text-success': passwordContainsUpper}" style="font-weight:400; ">- Must have
                      at least one uppercase character</div></li>
                  <li><div [ngClass]="{'text-success': passwordContainsLower}" style="font-weight:400; ">- Must have
                      at least one lowercase character</div></li>
                  <li><div [ngClass]="{'text-success': passwordContainsNumber}" style="font-weight:400;">- Must have
                      at least one number</div></li>
                  <li><div [ngClass]="{'text-success': passwordContainsSpecial}" style="font-weight:400;">- Must have
                      at least one special character: !$@%^&*</div></li>
                  <li><div [ngClass]="{'text-success': newPasswordsMatch}" style="font-weight:400;">- Passwords must
                      match</div></li>
                </ul>
              </div>
              <p-button (onClick)="onSavePasswordClick()" label="Save New Password"
                [disabled]="!isPasswordValid"></p-button>
              <a class="link-text" (click)="hideChangePasswordArea()" style="
                color: #2e2e2e;
                cursor: pointer;
                margin-left: 17.5px;
                line-height: 34px;
                text-decoration: underline;" styleClass="p-button-secondary">Cancel
              </a>
              <div class="mt-4 gray-section mb-100 p-4">
                <div class="font-weight-bold unknown-password-text">Don't know your current password?</div>
                <div>if you forgot your password, reset it here and we'll send you an email to create a new one.</div>
                <p-button (onClick)="onResetPassword()" styleClass="p-button-secondary mt-3"
                  label="Reset Password"></p-button>
              </div>
            </div>

            <h4 hidden class="mt-4 mb-3">Marketing Preferences</h4>
            <div hidden>
              <span class="d-flex">
                <p-inputSwitch formControlName="marketingPreference" class="inputSwitchActive"
                  [(ngModel)]="isMarketingPreference">
                </p-inputSwitch>
                When new campaigns are available, activate them immediately.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<!--
*************************************************************************************
Reset Password  Confirmation Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" #removeFromPulseDialog [(visible)]="showResetPasswordDialog"
  [resizable]="false" [modal]="true" [style]="{ width: '52.5em', 'font-weight': '700' }">
  <div class="pb-25">
    <span class="alert-header">{{ this.resetPasswordMessage }}</span>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="resetPasswordConfirmation()"
        label="Yes, I want to reset password" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showResetPasswordDialog = false" label="No"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>