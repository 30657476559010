import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core'; 
import { HttpClient } from '@angular/common/http'; 
import { ToastService } from 'src/services/toast.service'; 
import { ImageLibrary } from 'src/models/ImageLibrary';
 
@Component({ 
    encapsulation: ViewEncapsulation.None, 
    selector: 'imagePreviewDialog-root', 
    templateUrl: './imagePreviewDialog.component.html', 
    styleUrls: ['./imagePreviewDialog.component.scss'] 
}) 
export class imagePreviewDialogComponent implements OnInit, AfterViewInit { 

  // allows [selectedImage] = '...' to be added to the selector and passed in
  @Input() selectedImage: ImageLibrary;


  // @ViewChild('myControlId', { static: boolean }) propertyName: type
  @ViewChild('avatarImage', { static: false }) avatarImage: HTMLImageElement;
  @ViewChild('rectangleImage', { static: false }) rectangleImage: HTMLImageElement;
 
  imageSrc: string = null;

  // #region Constructor

  /** 
   * Constructor 
   *  
   * @param toastService Common <p-toast> display methods.
   */ 
  constructor(private http: HttpClient, 
    private toastService: ToastService
    ) { 
 
  }

  // #endregion
 
  // #region Component Initialization

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> { 
  } 
 
  /**
  * Occurs after view initialization
  */ 
  ngAfterViewInit(): void { 
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  } 
 
  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {
  }

  // #endregion

  // #region Methods

  /**
   * Initializes the dialog.
   */
  async initializeDialog() {
    setTimeout(() => {
      this.imageSrc = null;
      if (this.selectedImage.ImageURL.length > 0)
        this.imageSrc = this.selectedImage.ImageURL;
      
      
      this.avatarImage.src = this.imageSrc;
      this.rectangleImage.src = this.imageSrc;
    }, 10);
  }

  /**
   * Resets the dialog.
   */
  async clearDialog() {

    this.imageSrc = '';
    this.imageSrc = '/assets/images/RectangleImageTemplate.jpg';

    setTimeout(() => {
      this.avatarImage.src = '/assets/images/RoundImageTemplate.png';
      this.rectangleImage.src = '/assets/images/RectangleImageTemplate.jpg';
    }, 10);
  }

  // #endregion
} 
 
