import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampaignDetailBlockObject, CampaignPreviewOperationObject } from '../../../../models/Campaign';
import { CampaignsService } from '../../../../services/campaigns.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-detail-block',
  templateUrl: './campaign-detail-block.component.html',
  styleUrls: ['./campaign-detail-block.component.scss'],
})
export class CampaignDetailBlockComponent implements OnInit {
  @Input() campaignDetail: CampaignDetailBlockObject;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter();
  currentClientID: string;
  public isEmail: boolean;
  public showValidationFlag: boolean;
  public ischkRecommendedDisabled: boolean = true;
  public ischkQuickStartDisabled: boolean = true;
  constructor(private campaignsService: CampaignsService) {
    this.isEmail = true;
    this.showValidationFlag = false;
  }

  ngOnInit(): void {
    this.currentClientID = environment.clientId;
    this.campaignsService.campaignPreviewOperaion.subscribe(
      (data: CampaignPreviewOperationObject): void => {
        if (data && this.campaignDetail) {
          //set only if preview mode else show everything
          if (this.campaignDetail.isPreviewMode) {
            this.isEmail = data.templateTypeId == 1 ? true : false;
          }
        }
      }
    );
  }

  onShowDetailsDialog() {
    //notifyParent parent comp to load dialog.
    this.notifyParent.emit('ShowDetailsDialog');
  }

  public updateValidationFlag(validationFlag: boolean) {
    this.showValidationFlag = validationFlag;
  }
}
