<p-dialog
  class="custom-dialog"
  id="campaignMessageScheduleDialog"
  #campaignMessageScheduleDialog
  [resizable]="false"
  [closable]="true"
  (onHide)="onCloseMessageScheduleDialog()"
  [(visible)]="MessageScheduleData.showMessageScheduleDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [breakpoints]="{ '960px': '75vw' }"
  [responsive]="true"
  [style]="{ 'max-width': '800px', width: '100vw', 'font-weight': '700' }"
>
  <p-header>
    <span class="dialog-head">{{ dialogTitle }}</span>
  </p-header>
  <div class="container-fluid p-0">
    <div class="container p-0">
      <div style="padding-bottom:20px;">
        <div class="row">
          <div class="col" style="font-size: 20px; color: #2e2e2e">
            Schedule
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2 me-2">
            <p-button
              label="One-Time"
              [ngClass]="{ tabselected: tabIndex != 0 }"
              id="oneTime"
              (onClick)="ShowSchedule(0)"
            >
              <i
                class="fa-regular fa-paper-plane"
              ></i>
            </p-button>
          </div>
          <div class="col-2" style="margin-left: 30px">
            <p-button
              label="Repeating"
              [ngClass]="{ tabselected: tabIndex != 1 }"
              id="RepeaTing"
              (onClick)="ShowSchedule(1)"
            >
              <i
                class="fa-sharp fa-regular fa-repeat"
              ></i>
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <hr style="width: 116%; position: relative; left: -8%" />
    <div class="container p-0">
      <div id="divOneTimeComponenets" *ngIf="tabIndex === 0">
        <div class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="StatusQuickPick"
              inputId="rbtn1"
              (onClick)="ShowComp(0)"
            ></p-radioButton>
            <label for="rbtn1" style="font-weight: 500;cursor: pointer; margin-left: 10px"
              >Status Quick Pick</label
            >
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="HolidayQuickPick"
              inputId="rbtn2"
              (onClick)="ShowComp(1)"
            ></p-radioButton>
            <label for="rbtn2" style="font-weight: 500;cursor: pointer; margin-left: 10px"
              >Holiday Quick Pick</label
            >
            <br />
          </div>
        </div>
        <!--<div class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="ContactQuickPick"
              inputId="rbtn4"
              (onClick)="ShowComp(10)"
            ></p-radioButton>
            <label for="rbtn4" style="font-weight: 500;cursor: pointer; margin-left: 10px"
              >Contact Quick Pick</label
            >
            <br />
          </div>
        </div>-->
        <div class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="CustomDate"
              inputId="rbtn3"
              (onClick)="ShowComp(2)"
            >
            </p-radioButton>
            <label for="rbtn3" style="font-weight: 500;cursor: pointer; margin-left: 10px"
              >Custom Date</label
            >
            <br />
          </div>
        </div>
        <div
          class="row"
          id="allComponnets"
          class="mt-4"
          style="background-color: #f5f5f5"
        >
          <div
            class="col"
            *ngIf="currentComponent == 0"
            style="font-weight: 500; color: #2e2e2e; padding: 20px 0px"
          >
            <cms-one-time-status-quick-pick
              (SubmitEvent)="ComponentResponse($event)"
            >
            </cms-one-time-status-quick-pick>
          </div>
        </div>
        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 10"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-one-time-contact-quick-pick
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-one-time-contact-quick-pick>
          </div>
        </div>
        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 1"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-one-time-holiday-quick-pick
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-one-time-holiday-quick-pick>
          </div>
        </div>
        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 2"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-one-time-custom-date
              [ScheduleDateMessage]="DeliveryDateMessage"
              [ScheduleTCPAForTextMessages]="TCPAForTextMessages"
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-one-time-custom-date>
          </div>
        </div>
      </div>
      <div
        id="divRepeatingComponenets"
        style="padding-top: 20px"
        *ngIf="tabIndex === 1"
      >
        <div class="row">
          <div class="col-4">
            <label class="mb-3">Timeframe</label>
            <p-dropdown
              [options]="timeFrames"
              class="mb-4"
              appendTo="body"
              [(ngModel)]="selectedTimeframe"
              optionLabel="name"
              optionValue="code"
              [showClear]="false"
              [filter]="false"
              (onChange)="OnTimeFrameChange($event)"
            ></p-dropdown>
          </div>
        </div>
        <div *ngIf="selectedTimeframe == 'YL'" class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="RepeatingCustomDate"
              inputId="rbtn4"
              (onClick)="ShowComp(4)"
            >
            </p-radioButton>
            <label for="rbtn4" style="font-weight: 500;cursor: pointer; margin-left: 10px"
              >Custom Date</label
            >
          </div>
        </div>
        <div *ngIf="selectedTimeframe == 'YL'" class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="RepeatingHolidayQuickPick"
              inputId="rbtn5"
              (onClick)="ShowComp(5)"
            ></p-radioButton>
            <label for="rbtn5" style="font-weight: 500; cursor: pointer;margin-left: 10px"
              >Holiday Quick Pick</label
            >
          </div>
        </div>
        <div *ngIf="selectedTimeframe == 'YL'" class="row">
          <div class="col">
            <br />
            <p-radioButton
              name="group1"
              [(ngModel)]="scheduleTypeValue"
              value="RepeatingContactQuickPick"
              inputId="rbtn6"
              (onClick)="ShowComp(6)"
            ></p-radioButton>
            <label for="rbtn6" style="font-weight: 500;cursor: pointer; margin-left: 10px"
              >Contact Quick Pick</label
            >
          </div>
        </div>
        <br />
        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 4 && selectedTimeframe == 'YL'"
            class="col align-items-center"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-repeating-custom-date
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-repeating-custom-date>
          </div>
        </div>

        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 5 && selectedTimeframe == 'YL'"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-repeating-holiday
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-repeating-holiday>
          </div>
        </div>

        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 6 && selectedTimeframe == 'YL'"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-repeating-contact
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-repeating-contact>
          </div>
        </div>

        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 7 && selectedTimeframe == 'WL'"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-repeating-weekly
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-repeating-weekly>
          </div>
        </div>
        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 8 && selectedTimeframe == 'ML'"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-repeating-monthly
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-repeating-monthly>
          </div>
        </div>
        <div class="row" style="background-color: #f5f5f5">
          <div
            *ngIf="currentComponent == 9 && selectedTimeframe == 'DL'"
            class="col align-items-center justify-content-start"
            style="font-weight: 500; color: #2e2e2e"
          >
            <cms-repeating-daily
              (SubmitEvent)="ComponentResponse($event)"
            ></cms-repeating-daily>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col"></div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a
        [routerLink]=""
        style="margin-right: 17.5px; color: #2e2e2e; font-weight: 400"
        (click)="onCancelMessageScheduleDialog()"
        >Cancel</a
      >
      <p-button
        [style]="{ 'margin-left': '17.5px' }"
        [disabled]="DisableSave"
        (onClick)="onSaveMessageScheduleDialog()"
        label="Save"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
