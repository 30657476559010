import { AppSettings } from '../appSettings';
import { Datetime } from './Datetime';

/** 
* Implementation of the FocusIT.DataContracts.Pulse.ImageLibrary [DataContract].
*/
export class ImageLibrary {

  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public ImageId: string = '00000000-0000-0000-0000-000000000000';
  public Title: string = '';
  //public Picture: any[] = [];
  public Picture: Array<number> = [];
  public Width: number = 0;
  public Height: number = 0;
  public Avatar: boolean = false;
  public Owner: string = '';
  public OwnerFullName: string = '';
  public Published: boolean = false;
  public AdditionalUsers: string = '';
  public AdditionalTeams: string = '';
  public AvatarDisplay: string = 'No';
  public PrivateDisplay: string = 'No';
  public ImageURL: string = '';
  public ImageType: string = '';
  public ImageSize: string = '';
  public ImageLength: number = 0;
  public DateUpdatedUTC: Date ;
  public OwnerRoleId: string = '';
  public IsPublishingDb: boolean = false;

  public CanEdit: boolean;
  public CanDelete: boolean;
  
  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param TenantId
  * @param ImageId
  * @param Title
  * @param Picture
  * @param Width
  * @param Height
  * @param Avatar
  * @param Owner
  * @param OwnerFullName
  * @param Private
  * @param AdditionalUsers
  * @param AdditionalTeams
  * @param ImageURL
  */
  constructor(
    TenantId?: string,
    ImageId?: string,
    Title?: string,
    Picture?: Array<number>,
    Width?: number,
    Height?: number,
    Avatar?: boolean,
    Owner?: string,
    OwnerFullName?: string,
    Published?: boolean,
    AdditionalUsers?: string,
    AdditionalTeams?: string,
    ImageType?: string,
    ImageLength?: number,
    ImageSize?: string,
    CreatedOnUtc?:string,
    ModifiedOnUtc?:Date,
    ModifiedBy?: string,
    ImageURL?:string,
    OwnerRoleId?:string,
    IsPublishingDb?:boolean,
    ){
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
    if ((ImageId !== undefined) && (ImageId !== null)) this.ImageId = ImageId;
    if ((Title !== undefined) && (Title !== null)) this.Title = Title;
    if ((Picture !== undefined) && (Picture !== null)) this.Picture = Picture;
    if ((Width !== undefined) && (Width !== null)) this.Width = Width;
    if ((Height !== undefined) && (Height !== null)) this.Height = Height;
    if ((Avatar !== undefined) && (Avatar !== null)) this.Avatar = Avatar;
    if ((Owner !== undefined) && (Owner !== null)) this.Owner = Owner;
    if ((OwnerFullName !== undefined) && (OwnerFullName !== null)) this.OwnerFullName = OwnerFullName;
    if ((Published !== undefined) && (Published !== null)) this.Published = Published;
    if ((AdditionalUsers !== undefined) && (AdditionalUsers !== null)) this.AdditionalUsers = AdditionalUsers;
    if ((AdditionalTeams !== undefined) && (AdditionalTeams !== null)) this.AdditionalTeams = AdditionalTeams;
    if ((ImageURL !== undefined) && (ImageURL !== null)) this.ImageURL = ImageURL;
    if ((ImageType !== undefined) && (ImageType !== null)) this.ImageType = ImageType;
    if ((ImageSize !== undefined) && (ImageSize !== null)) this.ImageSize = ImageSize;
    if ((ImageLength !== undefined) && (ImageLength !== null)) this.ImageLength = ImageLength;
    if ((OwnerRoleId !== undefined) && (OwnerRoleId !== null)) this.OwnerRoleId = OwnerRoleId;
    if ((ModifiedOnUtc !== undefined) && (ModifiedOnUtc !== null)) this.DateUpdatedUTC = ModifiedOnUtc;
    if (this.Avatar)
      this.AvatarDisplay = 'Yes';
    if (this.Published)
      this.PrivateDisplay = 'Yes';
    if ((IsPublishingDb !== undefined) && (IsPublishingDb !== null)) this.IsPublishingDb = IsPublishingDb;
  }
}

/**
* Collection of FocusIT.DataContracts.Pulse.ImageLibrary [DataContract] objects.
*/
export class ImageLibraryCollection {
  Items: ImageLibrary[] = [];
}


