import { Roles } from '../app/auth/roles';

export class UserToken {
    public TenantId: string;
    public TenantName: string;
    public TenantUserId: string;
    public TenantDefaultSenderEmail: string;
    public TimezoneId: string;
    public email: string;
    public email_verified: boolean;
    public family_name: string;
    public given_name: string;
    public middle_name: string;
    public picture: string; 
    public role: string;
    public sub: string;
    public phone_number: string;
    public original_tenant: string;
    public original_tenantuserId: string;
    public original_userfullname: string;
    public auth_time: number;
    public amr: string;    

    public getUserInitials(): string
    {
        return this.given_name?.charAt(0) + this.family_name?.charAt(0);
    }

    public getFullName(): string
    {
        return (this.given_name??'') + 
            (this.middle_name?' ' + this.middle_name:'') + 
            (this.family_name?' ' + this.family_name:'');
    }

    public isMarketingAdmin():boolean{
        return this.role === Roles.CompanyMarketingAdmin;
    }

    public isCompanyAdmin(): boolean{
        return this.role === Roles.CompanyAdmin;
    }

    public isFocusITUser(): boolean{
      return this.role === Roles.FocusITUser
            || this.role ===  Roles.FocusITSystemAdmin;
    }

    public isCompanyUserWithMarketing(): boolean{
        return this.role === Roles.CompanyUserWithMarketing;
    }

    public isCompanyUser(): boolean{
        return this.role === Roles.CompanyUser;
    }

    public hasMarketingAccess(): boolean{
        return this.role === Roles.CompanyAdmin 
            || this.role === Roles.CompanyMarketingAdmin
            || this.role === Roles.CompanyUserWithMarketing
            || this.role === Roles.FocusITSystemAdmin
            || this.role === Roles.FocusITUser;
    }
}
