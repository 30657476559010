<div [formGroup]="peopleLoanListForm" (ngSubmit)="onSaveClick()">
  <p-dialog [resizable]="false" [(visible)]="display" [modal]="true" class="custom-dialog" [style]="{
      'max-width': '90%',
      'max-height': '90%',
      'min-width': '53em',
      'font-weight': '700'
    }" [baseZIndex]="10000" (onHide)="onClose()">
    <p-header>
      <span class="dialog-head">{{ title }}</span>
    </p-header>
    <div class="dialog-content">
      <div class="control-div p-col-12 p-md-12 row">
        <div class="p-col-10">
          <label for="txtName" class="me-3 mb-3">List Name<span class="text-danger"> *</span></label>
          <input type="text" name="txtName" id="txtName" maxlength="100" placeholder="List Name"
            class="form-control" formControlName="txtName"
            [class.ng-invalid]="(isFormSubmitted || peopleLoanListForm.get('txtName').touched) && peopleLoanListForm.get('txtName').invalid" />
          <span class="text-danger"
            *ngIf="(isFormSubmitted || peopleLoanListForm.get('txtName').touched) && peopleLoanListForm.get('txtName').errors?.required">Please
            enter list name</span>
          <span class="text-danger" *ngIf="(isFormSubmitted || peopleLoanListForm.get('txtName').touched) && 
              peopleLoanListForm.get('txtName').errors?.minlength">List name should contain at least 2
            characters</span>
        </div>
      </div>
     
    </div>
    <ng-template pTemplate="footer">
      <a (click)="onClose()"
        style="color:#2e2e2e;cursor: pointer;font-weight: 400; margin: 0px 17.5px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button [style]="{'margin-left': '17.5px'}" label="Save" (click)="onSaveClick()"></p-button>
    </ng-template>
  </p-dialog>
</div>