import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterTypes } from 'src/components/custom-grid/enums/filter-types';
import { SearchModel } from 'src/components/custom-grid/models/search-model';
import { CampaignsService } from 'src/services/campaigns.service';


export interface ICampaignUsageModel {
  tenantId: string;
  name: string;
  campaignId: string;
  deleted: boolean;
  dateCreatedUTC: Date;
  emailsSent: number;
  textMessagesSent: number;
}

export class CampaignUsageModel implements ICampaignUsageModel {
  tenantId: string;
  name: string;
  campaignId: string;
  deleted: boolean;
  dateCreatedUTC: Date;
  emailsSent: number;
  textMessagesSent: number;
  constructor(data: ICampaignUsageModel) {
    this.tenantId = data.tenantId;
    this.name = data.name;
    this.campaignId = data.campaignId;
    this.deleted = data.deleted;
    this.dateCreatedUTC = data.dateCreatedUTC;
    this.emailsSent = data.emailsSent;
    this.textMessagesSent = data.textMessagesSent;
  }
}

@Component({
  selector: 'app-campaign-usage',
  templateUrl: './campaign-usage.component.html',
  styleUrls: ['./campaign-usage.component.scss']
})
export class CampaignUsageComponent implements OnInit {

  searchModel: SearchModel;
  columns: Array<any>;
  campaignMessageViewModels: Array<CampaignUsageModel> = new Array<CampaignUsageModel>();
  campaignId: string;
  constructor(private campaignService: CampaignsService, private activatedRoute: ActivatedRoute) {



    this.activatedRoute.params.subscribe(params => {
      this.campaignId = params['id'];
      this.searchModel = new SearchModel({
        pageNum: 1,
        pageSize: 10,
        campaignId: this.campaignId
      });

      this.campaignService.GetUsageTenantIds(this.campaignId)
        .then(response => {

          const columns = [
            {
              field: 'tenantId',
              header: 'Tenant Id',
              isSortable: false,
              isFilterable: true,
              filterType: FilterTypes.TEXT,
              display: true,
              filterValues: [],
              filterOptions: []
            },
            {
              field: 'name',
              header: 'Name',
              isSortable: false,
              isFilterable: false,
              filterType: FilterTypes.TEXT,
              display: true,
              filterValues: [],
              filterOptions: []
            },
            //{
            //  field: 'deleted',
            //  header: 'Deleted',
            //  isSortable: true,
            //  isFilterable: true,
            //  filterType: FilterTypes.BOOLEAN,
            //  display: true,
            //  filterValues: [],
            //  filterOptions: [{ field: 'true', value: true }, { field: 'false', value: false }]
            //},
            {
              field: 'dateCreatedUTC',
              header: 'Date Activated',
              isSortable: true,
              isFilterable: true,
              filterType: FilterTypes.DATE,
              display: true,
              filterValues: [],
              filterOptions: [],
              beginDate: null,
              endDate: null
            },
            {
              field: 'emailsSent',
              header: 'Emails Sent',
              isSortable: true,
              isFilterable: false,
              filterType: FilterTypes.TEXT,
              display: true,
              filterValues: [],
              filterOptions: []
            },
            {
              field: 'textMessagesSent',
              header: 'SMS Sent',
              isSortable: true,
              isFilterable: false,
              filterType: FilterTypes.TEXT,
              display: true,
              filterValues: [],
              filterOptions: []
            },
          ]

          response.data.forEach(tenantId => {

            columns[0].filterOptions.push({
              field: tenantId,
              value: tenantId
            });

          });


          this.columns = [...columns];
          this.updateTable(0);

        });


    });



  }

  ngOnInit(): void {


  }

  updatedFilters() {
    this.updateTable(0);
  }

  onPageChangedParent($event: any) {
    this.searchModel.sortColumn = $event.sortField;
    this.searchModel.sortOrder = $event.sortOrder;
    this.updateTable($event.first);
  }

  updateTable(first: number) {

    let filters = this.createFilters();
    filters = this.createDateTypeFilter(filters);
    filters = this.createSort(filters);
    this.searchModel.filterStr = JSON.stringify(filters);
    const pageNumber = Math.floor(first / this.searchModel.pageSize) + 1;
    this.searchModel.pageNum = first === 0 ? 1 : pageNumber;
    this.campaignService.GetCampaignUsageByCampaignId(this.searchModel).then((response) => {
      this.campaignMessageViewModels.length = 0;

      response.data.forEach(campaignUsageViewModel => {
        this.campaignMessageViewModels.push(new CampaignUsageModel({
          campaignId: campaignUsageViewModel.campaignId,
          name: campaignUsageViewModel.name,
          dateCreatedUTC: campaignUsageViewModel.dateCreatedUTC,
          deleted: campaignUsageViewModel.deleted,
          tenantId: campaignUsageViewModel.tenantId,
          emailsSent: campaignUsageViewModel.emailsSent,
          textMessagesSent: campaignUsageViewModel.textMessagesSent
        } as ICampaignUsageModel));
      });

      this.campaignMessageViewModels = [...this.campaignMessageViewModels]

      this.searchModel.totalRecords = response.pageInfo.totalRecords;


    });
  }

  createFilters() {
    const columnsWithFilters = this.columns.filter((column) => column.filterValues.length > 0);

    let filters = columnsWithFilters.reduce((acc, column) => {

      acc[column.field] = column.filterValues.join(',');
      return acc;

    }, {});

    return filters;
  }

  createDateTypeFilter(filters: any) {

    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based in JavaScript
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const milliseconds = Math.floor(date.getMilliseconds() / 10).toString().padStart(2, '0'); // Get first 2 digits of milliseconds

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    const columnWithDateFilter = this.columns.find((column) => column.filterType === FilterTypes.DATE);

    if (columnWithDateFilter.beginDate !== null) {
      filters['beginDateCreated'] = formatDate(columnWithDateFilter.beginDate);
    }

    if (columnWithDateFilter.endDate !== null) {
      filters['endDateCreated'] = formatDate(columnWithDateFilter.endDate);
    }

    return filters;
  }

  createSort(filters: any) {

    if (this.searchModel.sortColumn !== undefined && this.searchModel.sortOrder !== undefined) {

      const sortOrder = this.searchModel.sortOrder.toString() === '-1' ? 'DESC' : 'ASC';
      let sortColumn = ''
      switch (this.searchModel.sortColumn) {
        case 'tenantId':
          sortColumn = 'TenantId';
          break;
        case 'deleted':
          sortColumn = 'Deleted';
          break;
        case 'dateCreatedUTC':
          sortColumn = 'DateCreatedUTC';
          break;
        case 'emailsSent':
          sortColumn = 'EmailsSent';
          break;
        case 'testMessagesSent':
          sortColumn = 'TextMessagesSent';
          break;
      }

      filters['orderBy'] = `${sortColumn} ${sortOrder}`;

    }

    return filters;

  }

}
