import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { Campaign } from 'src/models/Campaign';
import { PagingData } from 'src/models/PagingData';
import { RequestResponse } from 'src/models/RequestResponse';
import { reportManagementPagesState } from 'src/models/state/reportManagementPages.state';
import { SubscriberActivity, SubscriberActivityCollection } from 'src/models/SubscriberActivity';
import { FilterHelperService } from 'src/services/filterHelper.service';
 
@Component({ 
  encapsulation: ViewEncapsulation.None,
  selector: 'subscriberActivity-root', 
    templateUrl: './subscriberActivity.component.html', 
    styleUrls: ['./subscriberActivity.component.scss'] 
}) 
export class subscriberActivityComponent implements OnInit, AfterViewInit { 
  //title = 'reportCampaigns'; 
 
  // allows [reportManagementPagesState] = '...' to be added to the selector and passed in
  @Input() reportManagementPagesState: reportManagementPagesState;
 
  // true delivers the event asynchronously, false delivers synchronously. 
  // @Output() eventName: EventEmitter<CommandEvent> = new EventEmitter<CommandEvent>(true); 
  @ViewChild('dataTableId', { static: false }) dataTable: Table;
  @ViewChild('downloadMenu', { static: false }) downloadMenu: Menu;

  // property for the control with  #myControlId to be accessed with this.propertyName 
  // @ViewChild('myControlId', { static: boolean }) propertyName: type 
 
  // additional columns that are initially displayed, but may be de-selected
  displayedColumns: any[];
  
  globalFilterFields: any[] = [];

  // select statement order by clause (passed to service/controller)
  orderBy: string = 'Email ASC';
  defaultOrderBy = 'Email ASC';
  sortLabel: string = "Email Ascending";
  sortField = "Email";
  sortOrder = 1;
  
  // Horizontal scroll position
  currentHScrollPos: number = 0;

  statList: string[] = ['All', 'Request', 'Open', 'Click', 'SpamReports', 'Delivered'];
  Requests: number = 0;
  Opens: number = 0;
  Clicks: number = 0;
  SpamReports: number = 0;
  Delivered: number = 0;
  selectedStat: string = 'All';

  activityList: string[] = ['Full Campaign'];
  selectedActivity: string = 'Full Campaign';

  // Download Contacts menu items
  downloadMenuItems: any[];

  showAddToListDialog: boolean = false;

  // used for showing/hiding progress bar 
  loadingPage: boolean = false; 
 
  // Standard Request/Response object. Includes Paging support if necessary 
  webAPI: RequestResponse = new RequestResponse();
 
  // Paging support if necessary 
  totalRecords: number = 0; 
  // current page being displayed 
  currentPage: number = 1; 
  txtCurrentPage: string = '1'; 
  // number of rows per page (initialize to one of the values in the 'rowsPerPageOptions' paginator property) 
  pageSize: number = 10; 
  tableFirst: number = 0; 
  // End Paging support 
 
  /** 
   * Constructor 
   *  
   * @param http HTTPClient required to call actions in any .ts file in the services folder. 
   * @param confirmationService ConfirmationService required for <p-confirmDialog> 
   * @param messageService MessageService required for <p-toast> 
   */ 
  constructor(private http: HttpClient, 
    private confirmationService: ConfirmationService, 
    private filterHelperService: FilterHelperService,
    private messageService: MessageService) { 
 
    // initialize global filter field definitions
    this.globalFilterFields = this.getGlobalFilterFieldDefinitions();
    // initialize displayed column definitions
    this.displayedColumns = this.getColumnDefinitions();

    // initialize 'Download Contacts' menu items
    this.downloadMenuItems = [
      { label: 'Export Selected Rows', command: (event) => { this.onDownloadSelectedRowsAndColumns(); } },
    ];
  } 
 
  //*************************************************************************************** 
  // Component Initialization 
  //*************************************************************************************** 
  /** Occurs upon component initialization */ 
  async ngOnInit(): Promise<void> { 
    // obtain data for the page 
    this.getData(this.currentPage); 
  } 
 
  /** Occurs after view initialization */ 
  ngAfterViewInit(): void { 
    if(this.reportManagementPagesState==null){
      this.reportManagementPagesState = new reportManagementPagesState();
      this.reportManagementPagesState.showEditor=false;
      this.reportManagementPagesState.showErrors = false;
    }
  } 
 
  //*************************************************************************************** 
  // Data Acquisition 
  //*************************************************************************************** 
  /** 
   * Retrieves all necessary data for this component. 
   * 
   * @param: currentPage - paging support. 
   */ 
  getData(currentPage) { 
    // start the progress bar/spinner 
    this.loadingPage = true; 
 
    let subscriberActivity: SubscriberActivityCollection = new SubscriberActivityCollection();
    subscriberActivity.Items.push(
      {
        Email: 'jDoe@test.com',
        FName: 'Jane',
        LName: 'Doe',
        Link: '',
        Timestamp: new Date(),
        Requests: 1,
        Opens: 1,
        Clicks: 1,
        SpamReports: 0,
        Delivered: 0,
        ModifiedBy: 'Admin',
        ModifiedOnUtc: new Date()
      });
    subscriberActivity.Items.push(
        {
          Email: 'jDoe2@test.com',
          FName: 'John',
          LName: 'Doe',
          Link: '',
          Timestamp: new Date(),
          Requests: 0,
          Opens: 1,
          Clicks: 1,
          SpamReports: 1,
          Delivered: 0,
          ModifiedBy: 'Admin',
          ModifiedOnUtc: new Date()
        });

    this.buildStatList(subscriberActivity.Items);

    this.webAPI.data = subscriberActivity;
    this.webAPI.paging.totalRecords = 2;
    this.webAPI.paging.currentPage = 1;

    // when data is retrieved, set this.loadingPage to false. 
    this.loadingPage = false; 
  } 

  buildStatList(Items: SubscriberActivity[]){
    // init stat list
    this.Requests = 0;
    this.Opens = 0;
    this.Clicks = 0;
    this.SpamReports = 0;
    this.Delivered = 0;

    Items.map(value => {
      if (value.Requests > 0){
        this.Requests += 1;
      }
      if (value.Opens > 0){
        this.Opens += 1;
      }
      if (value.Clicks > 0){
        this.Clicks += 1;
      }
      if (value.SpamReports > 0){
        this.SpamReports += 1;
      }
      if (value.Delivered > 0){
        this.Delivered += 1;
      }
    });
    this.statList = ['All(' + Items.length + ')', 'Requests(' + this.Requests + ')', 'Open(' + this.Opens + ')', 'Clicks(' + this.Clicks + ')', 'SpamReports(' + this.SpamReports + ')', 'Delivered(' + this.Delivered + ')'];
  }

  /**
   *  Goes to the user entered page.
   */
   onGotoPage() {
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
      this.refreshData();
    }
  }
  
    /**
   * Retrieves data applying any sorting and filtering, and scrolls the
   * list to its previous horizontal scroll position.
   */
    async refreshData() {
      this.currentHScrollPos = this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft;
      await this.getData(this.webAPI.paging.currentPage);
      setTimeout(() => {
        this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft = this.currentHScrollPos;
      }, 10);
    }
  
  //*************************************************************************************** 
  // Methods and Event Handlers 
  //*************************************************************************************** 
 
  // #region Column Definitions

  /**
   * Gets the array of initial column definitions to display.
   */
  getColumnDefinitions(): Array<any> {
    // always display frozen and default columns
    let results: any[] = [];
    results = results.concat(this.getFrozenColumnDefinitions());
    results = results.concat(this.getDefaultColumnDefinitions());
    return results;
  }

  /**
   * Gets the array of default column definitions that are not selectable and always exported.
   */
  getFrozenColumnDefinitions(): Array<any> {
    let results = [
      { field: 'Email', header: 'EMAIL ADDRESS', style: 'width:26rem; min-width:26rem;', isSortable: true, isFilterable: false },
      { field: 'FName', header: 'FIRST NAME', style: 'width:18rem; min-width:18rem;', isSortable: true, isFilterable: true, filterType: 'text', showOperator: false },
      { field: 'LName', header: 'LAST NAME', style: 'width:25rem; min-width:25rem;', isSortable: true, isFilterable: true, filterType: 'text', showOperator: false },
      { field: 'Link', header: 'LINK', style: 'width:20rem; min-width:20rem;', isSortable: false, isFilterable: false },
      { field: 'Timestamp', header: 'TIMESTAMP', style: 'width:20rem; min-width:20rem;', isSortable: false, isFilterable: false, dataType: 'Date' },
    ];
    return results;
  }

  /**
   * Gets the array of default column definitions that are selectable.
   * Only the selected columns will be exported.
   */
  getDefaultColumnDefinitions(): Array<any> {
    let results = [
    ];
    return results;
  }

  /**
   * Gets the array of all column definitions that are selectable.
   */
  getSelectableColumnDefinitions(): Array<any> {
    // always display default columns
    let results = this.getDefaultColumnDefinitions();

    return results;
  }

  // #endregion

  onStatsChanged(event){
    let value = event.value.split('(');
    let tempList = [];
    switch(value[0]){
      case 'Requests':
        this.refreshData();
        tempList = this.webAPI.data.Items.filter(item => item.Requests > 0);
        this.webAPI.data.Items = tempList;
        break;
      case 'Open':
        this.refreshData();
        tempList = this.webAPI.data.Items.filter(item => item.Opens > 0);
        this.webAPI.data.Items = tempList;
        break;
      case 'Clicks':
        this.refreshData();
        tempList = this.webAPI.data.Items.filter(item => item.Clicks > 0);
        this.webAPI.data.Items = tempList;
        break;
      case 'SpamReports':
        this.refreshData();
        tempList = this.webAPI.data.Items.filter(item => item.SpamReports > 0);
        this.webAPI.data.Items = tempList;
        break;
      case 'Delivered':
        this.refreshData();
        tempList = this.webAPI.data.Items.filter(item => item.Delivered > 0);
        this.webAPI.data.Items = tempList;
        break;
      default:
        this.refreshData();
    }
  }

    /**
   * Gets the array of global filter field names.
   * */
  getGlobalFilterFieldDefinitions() {
    let results = [
      'Email',
      'FName',
      'LName'
    ];
    return results;
  }

  onSearchActivityChanged(event: any) {
  }

  onRowClicked(event, record: SubscriberActivity){

  }

  onRowCheckboxClick(event, record: SubscriberActivity){

  }

  onPageChanged(event){

  }

  onFilter(event){
    console.log(event, event.filters, event.filters.FName);

    let tempList = [];
    this.refreshData();

    event.filters.FName.map(val => {
      console.log('inside filter fname', val);
      if (val.value != undefined && val.value !== '' && val.value != null){
        console.log('inside filter fname2', val);
        tempList = this.webAPI.data.Items.filter(item => item.FName.indexOf(val.value) >= 0);
        this.webAPI.data.Items = tempList;
        console.log('inside filter fname2', this.webAPI.data.Items);
      }
    })
    event.filters.LName.map(val => {
      console.log('inside filter lname', val);
      if (val.value != undefined && val.value !== '' && val.value != null){
        console.log('inside filter lname2', val);
        tempList = this.webAPI.data.Items.filter(item => item.LName.indexOf(val.value) >= 0);
        this.webAPI.data.Items = tempList;
        console.log('inside filter lname2', this.webAPI.data.Items);
      }
    });
  }

  onSort(event){
    if (event.order > 0){
      this.webAPI.data.Items.sort((a,b) => a[event.field].localeCompare(b[event.field]))
    }
    else {
      this.webAPI.data.Items.sort((a,b) => b[event.field].localeCompare(a[event.field]))
    }
  }

  onGlobalFilterChange(event){
    if (event.target.value === undefined || event.target.value === ''){
      this.refreshData();
      return;
    }
    let tempList = [];
    tempList = this.webAPI.data.Items.filter(item => 
        item.Email.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0 
        || item.FName.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0 
        || item.LName.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0);
    this.webAPI.data.Items = tempList;
  }

  onApplyGlobalFilter(){
    // *************************************************************************************
    // global filter applys to the entire result set (includes column filters)
    // *************************************************************************************
    this.dataTable._filter();
  }

  onDownloadSelectedRowsAndColumns() {
    //console.log(JSON.stringify(this.dataTable.columns));
    this.dataTable.exportCSV({ selectionOnly: true });
  }
  
  onOptOutClick(event){
    this.confirmationService.confirm({
      message:
        "<br />" +
        "&nbsp;Are you sure you want to opt out for selected subscribers?<br />",
      accept: () => {
        //this.onOptOutConfirmed(event);
      }
    });
  }

  async onShowAddToListDialog() {
    setTimeout(() => {
      this.showAddToListDialog = true;
    }, 100);
  }

  async onCloseAddToListDialog() {
    this.showAddToListDialog = false;
  }
}  
