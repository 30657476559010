<div [formGroup]="makeACopyForm" (ngSubmit)="onSaveClick()">
  <p-dialog [resizable]="false" [(visible)]="display" [modal]="true" class="custom-dialog" [style]="{
      'max-width': '90%',
      'max-height': '90%',
      'min-width': '53em',
      'font-weight': '700'
    }" [baseZIndex]="10000" (onHide)="onClose()">
    <p-header>
      <!-- <i class="pi pi-eye" style="font-size: 1.3rem; margin-right: 0.5rem"></i> -->
      <span class="dialog-head">Copy Template</span>
    </p-header>
    <div class="dialog-content">
      <!--   <div class="dialog-title">Copy From {{ title }}</div>
      <hr /> -->
      <div class="control-div p-col-12 p-md-12 row">
        <div class="p-col-10">
          <label for="txtTitle" class="me-3 mb-3">New Template Name</label>
          <input
            type="text"
            name="txtTitle"
            id="txtTitle"
            maxlength="64"
            placeholder="Template Name [Copy]"
            class="form-control"
            formControlName="txtTitle"
            [class.ng-invalid]="(isFormSubmitted || makeACopyForm.get('txtTitle').touched) && makeACopyForm.get('txtTitle').invalid"
          />
          <span
            class="text-danger"
            *ngIf="(isFormSubmitted || makeACopyForm.get('txtTitle').touched) && makeACopyForm.get('txtTitle').errors?.required"
            >Please enter template name</span
          >
          <span
            class="text-danger"
            *ngIf="(isFormSubmitted || makeACopyForm.get('txtTitle').touched) && 
              makeACopyForm.get('txtTitle').errors?.minlength"
            >Template name should contain at least 2 characters</span
          >
          <!-- <label for="txtTitle" class="me-3 my-3">New Template Name</label>
          <input type="text" name="txtTitle" id="txtTitle" maxlength="64" placeholder="[Copy] Template Name"
            class="form-control" formControlName="txtTitle"
            [class.ng-invalid]="(isFormSubmitted || makeACopyForm.get('txtTitle').touched) && makeACopyForm.get('txtTitle').invalid" />
          <span class="text-danger"
            *ngIf="(isFormSubmitted || makeACopyForm.get('txtTitle').touched) && makeACopyForm.get('txtTitle').errors?.required">Please
            enter template name</span>
          <span class="text-danger" *ngIf="(isFormSubmitted || makeACopyForm.get('txtTitle').touched) && 
              makeACopyForm.get('txtTitle').errors?.minlength">Template name should contain at least 2
            characters</span> -->

            <span class="text-danger" *ngIf="alertMessageCopyTemplateName">{{alertMessageCopyTemplateNameString}}</span>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <a (click)="onClose()"
        style="color:#2e2e2e;cursor: pointer;font-weight: 400; margin: 0px 17.5px;text-decoration: underline;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button [style]="{'margin-left': '17.5px'}" label="Save New Template" (click)="onSaveClick()" [disabled]="(saveClicked)"></p-button>
    </ng-template>
  </p-dialog>
</div>