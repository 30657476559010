import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formState } from 'src/helpers/formState.helper';
import { ToastService } from 'src/services/toast.service';
import { UserDefineList } from '../models/UserDefineList';

@Component({
  selector: 'people-loan-list-edit-dialog',
  templateUrl: './people-loan-list-edit-dialog.component.html',
  styleUrls: ['./people-loan-list-edit-dialog.component.scss'],
})
export class PeopleAndLoansListEditComponent implements OnInit {
  peopleLoanListForm: FormGroup | any = null;
  @Input() title: String;
  @Input() edited: Boolean;
  @Input() display: Boolean;
  @Input() selectedList: UserDefineList;
  @Output() onCloseDialog: EventEmitter<string> = new EventEmitter();
  @Output() onSaveListClick: EventEmitter<string> = new EventEmitter();
  isFormSubmitted = false;
  validator: formState = new formState();

  constructor(
    private toastService: ToastService,
    private formBuilder: FormBuilder
  ) {
    this.initializeDialog();
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }

  async initializeDialog() {
    setTimeout(() => {
      this.validator.errors = [];
      if (this.edited) {
        this.peopleLoanListForm.patchValue({
          txtName: this.selectedList.listTitle,
        });
        this.peopleLoanListForm.get('txtName').updateValueAndValidity();
      }
    });
  }

  createForm() {
    return new Promise((resolve, reject) => {
      this.peopleLoanListForm = this.formBuilder.group({
        txtName: [
          null,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(100),
          ],
        ],
      });
      resolve(true);
    });
  }

  onClose() {
    this.resetForm();
    this.onCloseDialog.emit();
  }

  async onSaveClick(): Promise<void> {
    this.isFormSubmitted = true;
    if (this.peopleLoanListForm.valid) {
      this.selectedList.listTitle = this.peopleLoanListForm.value.txtName;
      this.onSaveListClick.emit();
      this.resetForm();
    } else {
      this.toastService.showError('Please validate all data..');
    }
  }

  async clearDialog() {
    this.validator.errors = [];
    this.peopleLoanListForm.patchValue({ txtName: '', txtDisplayName: '' });
    this.peopleLoanListForm.get('txtName').updateValueAndValidity();
  }

  resetForm() {
    this.isFormSubmitted = false;
    if (this.peopleLoanListForm) {
      this.peopleLoanListForm.markAsPristine();
      this.peopleLoanListForm.markAsUntouched();
    }
  }
}