<div class="row mx-0" style="overflow:hidden;" id="modalfilter">
  <div class="rgtbrd" style="padding-top: 8px; padding-bottom: 8px; min-height: 74px;" #insideElement>
    <div class="d-flex">
      <div>
        <i *ngIf="hasJsonLoadError" class="pi pi-spin pi-spinner"
          style="font-size: 25px; margin-top: 10px; margin-right: 4px;"></i>
        <button pButton pRipple type="button" [label]="displayHtml5.length > 0 ? '' : addButtonLabel" icon="pi pi-plus"
          class="btn-custom mr-1" style="margin-left:.5em;" (click)="op2.toggle($event);addFilter()"
          [disabled]="isControlDisabled || hasJsonLoadError">
        </button>
      </div>
      <div id="chip-Section" class="people-and-list-advenced-filter-chip-section">
        <div class="chip-main-class" [ngClass]="{ 'show-more-height': toggleFlag}">
          <p-confirmPopup></p-confirmPopup>
          <p-chips class="d-none"></p-chips>
          <div id="disID">
            <div class="division list_none float-left p-chips p-component">
              <ul class="p-chips-multiple-container"><!-- p-inputtext   -->
                <li *ngFor="let item of displayHtml5; let i = index;let last = last;" class="p-chips-token" 
                  (click)="editFilter1(item, $event,(item.html.indexOf('Contact Type') > 0 && item.isQuickFilter === true))" 
                  [ngClass]="{'last-child-chip': last}">

                    <div [innerHTML]="item?.html" >
                    </div>
  
                    <div *ngIf="item?.countN > 0" class="chipcount">+{{item.countN}}</div>
  
                    <ng-container *ngIf="(item?.lookupId != 'sll1' && item?.html?.indexOf('Veteran') == -1); else divEdit01">
                      <ng-container *ngIf="!(item.html.indexOf('Contact Type') > 0 && item.isQuickFilter === true); else divEdit01">
                        <i class="fa fa-angle-down chip-background"></i>
                      </ng-container>
                    </ng-container>

                  

                  <ng-template #divEdit01>
                    <i class="fa chip-background"></i>
                  </ng-template>
                  <i class="fa fa-times chip-background" (click)="removeFilterClick1($event, item.lookupId, item.id)"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="col-2 d-flex flex-column justify-content-center mr-0 ml-auto">
        <span class="mt-auto mb-0 d-flex flex-row justify-content-end">
          <a *ngIf="displayHtml.length > 5" type="button" class="p-button-rounded show-more" (click)="toggleChips()">
            {{ toggleFlag ? "Show All ("+ (displayHtml.length -5) +" more) " : "Show Less Filters" }}
          </a>
        </span>
      </div>
    </div>
  </div>

</div>

<p-overlayPanel #op>
  <ng-template pTemplate="content">
    <div class="row" *ngIf="showConditionBuilder">
      <!-- clientX::{{clientX}} ||clientY:{{clientY}}  -->
      <!-- [ngStyle]="{'left': clientX.toString()+'px', 'top': clientY.toString()+'px'}"
  style="background-color: red; border: 1px solid green; position: absolute;"-->

      <div class="col-12">
        <ng-container *ngIf="selectedFilter?.DataColumnDisplay">
          <!-- DataColumnDisplay:: {{selectedFilter?.DataColumnDisplay}} || DataType:: {{selectedFilter?.DataType}} -->
          <input id="hidDataType" type="hidden" [value]="selectedFilter?.DataType">
          <container-element [ngSwitch]="selectedFilter?.DataType?.toLowerCase()">
            <app-people-and-list-advanced-filter-text *ngSwitchCase="'text'" [OperandDropDownList]="operandDropDownList"
              [SelectedFilter]="selectedFilter" (onCreateFilterEvent)="onFilterCreated($event)"
              (onCancelFilterEvent)="cancelFilter($event)"></app-people-and-list-advanced-filter-text>

            <app-people-and-list-advanced-filter-numeric *ngSwitchCase="'number'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)" (onCancelFilterEvent)="cancelFilter($event)">
            </app-people-and-list-advanced-filter-numeric>

            <app-people-and-list-advanced-filter-boolean *ngSwitchCase="'boolean'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)" (onCancelFilterEvent)="cancelFilter($event)">
            </app-people-and-list-advanced-filter-boolean>

            <app-people-and-list-advanced-filter-date *ngSwitchCase="'birthday'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)" (onCancelFilterEvent)="cancelFilter($event)">
            </app-people-and-list-advanced-filter-date>

            <app-people-and-list-advanced-filter-loan-status-type-generic-list *ngSwitchCase="'loanstatustypelist'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)" (onCancelFilterEvent)="cancelFilter($event)">
            </app-people-and-list-advanced-filter-loan-status-type-generic-list>

            <app-people-and-list-advanced-filter-text-list *ngSwitchCase="'textlist'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)" (onCancelFilterEvent)="cancelFilter($event)">
            </app-people-and-list-advanced-filter-text-list>

            <app-people-and-list-advanced-filter-currency *ngSwitchCase="'currency'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)"
              (onCancelFilterEvent)="cancelFilter($event)"></app-people-and-list-advanced-filter-currency>

            <app-people-and-list-advanced-filter-percentage *ngSwitchCase="'percent'"
              [OperandDropDownList]="operandDropDownList" [SelectedFilter]="selectedFilter"
              (onCreateFilterEvent)="onFilterCreated($event)"
              (onCancelFilterEvent)="cancelFilter($event)"></app-people-and-list-advanced-filter-percentage>

            <app-people-and-list-advanced-filter-generic-list *ngIf="selectedFilter?.DataType?.toLowerCase() === 'loanoccupancytypelist' || 
                   selectedFilter?.DataType?.toLowerCase() === 'loanpurposetypelist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'loansubjectpropertytypelist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'loantypelist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'mastercontacttypelist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'programtypelist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'usstatelist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'contactownerlist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'closingcostscenariolist' ||
                   selectedFilter?.DataType?.toLowerCase() === 'locktermlist'  ||
                   selectedFilter?.DataType?.toLowerCase() === 'taglist' " [OperandDropDownList]="operandDropDownList"
              [SelectedFilter]="selectedFilter" (onCreateFilterEvent)="onFilterCreated($event)"
              (onCancelFilterEvent)="cancelFilter($event)">
            </app-people-and-list-advanced-filter-generic-list>

          </container-element>
        </ng-container>
      </div>

    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #op2>
  <div *ngIf="showFilterList" class="row p-paginator-rpp-options position-relative showFilterList_1">
    <div class="filter-back">
      <div class="row">
        <div class="col-9">
          <p-autoComplete #autocomplete [(ngModel)]="selectedFilter" name="search" id="searchAdvFilter" [showEmptyMessage]="true"
            [suggestions]="filteredRecords" (completeMethod)="searchFilters($event)" field="DataColumnDisplay"
            class="QuickFiltersearch" [dropdown]="true" placeholder="Search filters" (onSelect)="nodeSelect($event)"
            dropdownIcon="pi pi-search" delay="500" (onfocus)="autocomplete.show()"
            (onClear)="clearItem(autocomplete)" (completeOnFocus)="true"
            (onFocus)="!autocomplete.value && autocomplete.handleDropdownClick()"
            [dropdown]="true">
            <ng-template let-filter pTemplate="item">
              <div class="filter-item">
                <div>{{filter.DataColumnDisplay}}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
        <div class="col-3">
          <i *ngIf="filterJSONLoader" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </div>
      <div class="row">
        <div class="col QuicksubText">
          <strong>Quick Filters</strong>
          <i *ngIf="quickFilterLoader" class="pi pi-spin pi-spinner ms-4" style="font-size: 2rem"></i>
        </div>
      </div>
      <ul class="row FilterList" *ngIf="quickFilterList?.length">
        <li class="FilterListitem mb-2" *ngFor="let title of quickFilterList; let i = index">
          <button pButton pRipple type="button" [label]="title" class="p-button-rounded invertedbutton"
            styleClass="QuickFilterPill" (click)="onQuickFilterSelect(title, $event, true)">
          </button>
        </li>
      </ul>
    </div>
  </div>
</p-overlayPanel>
