import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, OidcConfigService, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

export function configureAuth(oidcConfigService: OidcConfigService): () => Promise<any> {
    return () =>
      oidcConfigService.withConfig({
        stsServer: environment.TokenAuthority,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.clientId,
        scope:
          'offline_access openid profile TM Dashboard Campaigns ImageLibrary TemplateLibrary Loans Lookup Master CalendarEvent Billing ContentLibrary Contact Notification', // 'openid profile offline_access ' + your scopes
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 35,
        logLevel: LogLevel.Debug,
        tokenRefreshInSeconds: 15,
        refreshTokenRetryInSeconds: 15,
      });
}

@NgModule({
    imports: [AuthModule.forRoot()],
    exports: [AuthModule],
    providers: [
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAuth,
            deps: [OidcConfigService],
            multi: true,
        },
    ],
})
export class AuthConfigModule {}
