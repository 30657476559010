<div class="notification-zone"></div>
<!--Plugin containers -->
<div id="stripoSettingsContainer"></div>
<div id="stripoPreviewContainer"></div>

<!--
*************************************************************************************
Preview Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" #preview id="preview" [resizable]="false" (onHide)="onClosePreviewDialog()"
  [(visible)]="editorState.showPreviewDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '700px','font-weight': '700'}">
  <p-header>
    <!-- <i class="pi pi-eye" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="dialog-head">Preview</span>
  </p-header>
  <previewDialog-root #previewDialog [compiledEmail]="editorState.compiledEmail"
    [templateWidth]="editorState.templateWidth" [templateHeight]="editorState.templateHeight"></previewDialog-root>
  <!-- <ng-template pTemplate="footer">
    <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
    <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div>
    <div style="margin-top: 1.4rem;">
      <p-button (click)="onClosePreviewDialog()" label="Cancel" styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template> -->
</p-dialog>

<!-- Spinner while loading the page -->
<!-- <p-dialog class="pulseLoadingDialog"
          [draggable]="false" [resizable]="false" [(visible)]="editorState.loadingPage" [modal]="true"
          [closeOnEscape]="false" closeable="false" [showHeader]="false"
          [baseZIndex]="10000">
<div class="pulseLoadingtext">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem;"></i>
  <span class="previewspintext"> Loading Your Preview </span>
  <span class="previewspinsubtext"> Please wait while we prepare the email. </span>
</div>
</p-dialog> -->

<!--
*************************************************************************************
Send test email Dialog
*************************************************************************************
-->
<p-dialog class="custom-dialog" #sendEmail [resizable]="false" (onHide)="onCloseSendTestEmailDialog()"
  [(visible)]="editorState.showSendTestEmailDialog" [modal]="true" [baseZIndex]="10000"
  [style]="{'width': '62.5em','font-weight': '700'}">
  <p-header>
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="dialog-head">Send Test Email</span>
  </p-header>
  <div *ngIf="!isEmailResponse">
    <table width="100%">
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">From</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtFromEmail id="txtFromEmail" (input)="validateEmailDialog()" type="text" style="width: 20rem;"
            [(ngModel)]="fromEmail" [disabled]="true" pInputText>
        </td>
      </tr>
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">To</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtTpEmail id="txtToEmail" type="text" (input)="validateEmailDialog()" style="width: 20rem;"
            [(ngModel)]="toEmail" pInputText>
        </td>
      </tr>
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">Subject Line</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtEmailSubject id="txtSubject" type="text" (input)="validateEmailDialog()" style="width: 100%;"
            [(ngModel)]="emailSubject" pInputText>
        </td>
      </tr>
      <tr>
        <td valign="middle" class="div-label" width="100px">
          <span class="email-label">Preview Text</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input #txtEmailPreviewText id="txtPreheaderText" type="text" style="width: 100%;" [(ngModel)]="preHeaderText"
            maxlength="255" pInputText>
        </td>
      </tr>
    </table>
  </div>
  <div class="isEmailResponse">
    <div class="failed-message" *ngIf="!responseType">
      <p>{{responseMessage}}</p>
    </div>
    <div class="success-message" style="text-align: center;font-weight: 400;color: green;" *ngIf="responseType">
      <p>{{responseMessage}}</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <!-- <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
    <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div> -->
    <div class="d-flex align-items-center">
      <a (click)="onCloseSendTestEmailDialog()"
        style="cursor: pointer;margin: 0px 17.5px;text-decoration: underline;font-weight: 500;"
        styleClass="p-button-secondary">Cancel</a>
      <p-button *ngIf="!isEmailResponse" (click)="onSendEmail()"
        [disabled]=" !isEmailDialogeValid || fromEmail=='' || toEmail=='' || emailSubject ==''"
        [style]="{'margin-left': '17.5px'}" label="Send Email"></p-button>
      <p-button [style]="{'margin-left': '17.5px'}" *ngIf="isEmailResponse" (click)="onSendAnother()"
        label="Send Another"></p-button>
    </div>
  </ng-template>
  <div class="invalid" [hidden]="isEmailDialogeValid">
    <p style="color: red;">{{emailDialogeErrorMessage}}</p>
  </div>
</p-dialog>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="editorState.loadingPage"
  [modal]="true" [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <ng-container *ngIf="editorState.previewing; else previewLoaderDiv">
    <div class="pulseLoadingtext">
      <i class="pi pi-spin pi-spinner" style="font-size: 5rem; color: white;"></i>
      <span class="previewspintext"> Loading Your Preview </span>
      <span class="previewspinsubtext"> Please wait while we prepare the email. </span>
    </div>
  </ng-container>
  <ng-template #previewLoaderDiv>
    <i class="pi pi-spin pi-spinner" style="font-size: 5rem;"></i>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"></p-confirmDialog>

<!--Merge Tag Validation Dialog-->
<p-dialog class="custom-alert" [(visible)]="showValidateTagDialog" [resizable]="false" [modal]="true"
  [style]="{ 'font-size': '16px', padding: '0px !important','width': '650px' }">
  <div>
    <span class="fw-bold mb-4" style="font-size: 20px">
      Oops... something went wrong. We could not save your template. Please try again.
    </span>
  </div>
  <div class=""></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex">
      <p-button class="pt-2 mt-5" (click)="onValidateTag()" [style]="{ 'margin-right': '17.5px' }" label="Continue"
        styleClass="p-button-rounded">
      </p-button>
    </div>
  </ng-template>
</p-dialog>