<div class="row" [formGroup]="customDateForm">
  <div class="p-col-12" style="padding-left: 45px">
    <div class="row justify-content-start align-items-baseline mt-3" >
      <div class="p-col-2 fw-normal">
        <p-radioButton
              name="annual"
              formControlName="annual"
              value="AMD"
              inputId="rbtnAMD"
              (onClick)="OnAnnualChange($event)"
            ></p-radioButton>
            <label for="rbtnAMD" style="cursor: pointer;"
              >On the</label
            >
        <!-- <p-radioButton
          (onClick)="OnAnnualChange($event)"
          name="annual"
          value="AMD"
          label="On the"
          formControlName="annual"
        ></p-radioButton> -->
      </div>
      <div class="p-col-2" style="margin-left: -5%;padding-left: 7.5px;">
        <p-dropdown
          [options]="days"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="day"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-day pTemplate="item">
            <div class="day-item">
              <div>{{ day.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-2 fw-normal" style="margin-left:-4px;margin-right: 7.5px;">day of every</div>
      <div class="p-col-3" style="margin-left:-4.8%;padding-left: 7.5px;">
        <p-dropdown
          [options]="months"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="month"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-month pTemplate="item">
            <div class="month-item">
              <div>{{ month.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="row justify-content-start align-items-baseline">
      <div class="p-col-2 fw-normal">
        <p-radioButton
              name="annual"
              formControlName="annual"
              value="AMWD"
              inputId="rbtnAMWD"
              (onClick)="OnAnnualChange($event)"
            ></p-radioButton>
            <label for="rbtnAMWD" style="cursor: pointer;"
              >On the</label
            >
        <!-- <p-radioButton
          (onClick)="OnAnnualChange($event)"
          name="annual"
          value="AMWD"
          label="On the"
          formControlName="annual"
        ></p-radioButton> -->
      </div>
      <div class="p-col-2" style="margin-left: -5%;">
        <p-dropdown
          [options]="weeks"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="week"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-week pTemplate="item">
            <div class="week-item">
              <div>{{ week.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-3" style="margin-left:-4.5px;">
        <p-dropdown
          [options]="weekdays"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="weekday"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-weekday pTemplate="item">
            <div class="weekday-item">
              <div>{{ weekday.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-2 fw-normal" style="margin-left: -4px;">of every</div>
      <div class="p-col-3" style="margin-left:-7.6%;padding-left: 7.5px;">
        <p-dropdown
          [options]="months"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="monthAMWD"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-month pTemplate="item">
            <div class="month-item">
              <div>{{ month.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px">
    <div class="row">
      <div class="p-col-5">
        <label>Time</label>&nbsp;
        <span *ngIf="messageType == 2 && allowTCPAForTextMessages==true" [title]="" class="fa fa-info-circle info-icon info"
              pTooltip="{{timeToolTipe}}" tooltipPosition="top" tooltipZIndex="9999999" style="width:100px;font-weight: 500!important;color:#BCC3CB!important;font-size: 14px;">
       </span>
      </div>

      <div class="p-col-6" style="margin-left:15px;">
        <label>Time Zone</label>
      </div>
    </div>
    <div class="row justify-content-start align-items-baseline">
      <div class="p-col-2" style="width: 14% !important;padding-right: 7.5px;">
        <p-dropdown
          [options]="hours"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="hour"
          optionValue="code"
          appendTo="body"
          (onChange)="OnHourChange($event)"
        >
          <ng-template let-hour pTemplate="item">
            <div class="hour-item">
              <div>{{ hour.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div _ngcontent-enc-c137="" class="col-1 align-self-center justify-self-center p-0" style="width: 1%; color: #797979;margin-bottom: 20px;;">:</div>
      <div class="p-col-2" style="width: 14% !important;padding-right: 7.5px;padding-left: 7.5px;">
        <p-dropdown
          [options]="minutes"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="minute"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-minute pTemplate="item">
            <div class="minute-item">
              <div>{{ minute.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-2" style="width: 15% !important;padding-right: 7.5px;padding-left: 7.5px;">
        <p-dropdown
          [options]="meridiems"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="meridiem"
          optionValue="code"
          appendTo="body"
          [disabled]="messageType == 2 && allowTCPAForTextMessages==true"
        >
          <ng-template let-meridiem pTemplate="item">
            <div class="meridiem-item">
              <div>{{ meridiem.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-5" style="padding-right: 7.5px;padding-left: 7.5px;">
        <p-dropdown
          [options]="timeZones"
          optionLabel="displayName"
          [filter]="true"
          filterBy="displayName"
          [showClear]="true"
          resetFilterOnHide="true"
          formControlName="timeZone"
          optionValue="timezoneId"
          appendTo="body"
        >
          <ng-template let-timeZone pTemplate="item">
            <div class="timeZone-item">
              <div>{{ timeZone.displayName }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div style="width:95%;margin: 0 auto;margin-top: -10px;">
    <hr style="border-top:3px solid #fff;opacity: 1;"/>
  </div>
  <div class="p-col-12" style="padding-left: 45px">
    <div class="row">
      <div class="p-col-3">
        <label>Starting Year</label>
      </div>

      <div class="p-col-3">
        <label>Ending</label>
      </div>
      <div class="p-col-6">&nbsp;</div>
    </div>
    <div class="row justify-content-start align-items-center">
      <div class="p-col-3">
        <p-dropdown
          [options]="startYears"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="startYear"
          optionValue="code"
          appendTo="body"
        >
          <ng-template let-startYear pTemplate="item">
            <div class="startYear-item">
              <div>{{ startYear.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div class="p-col-3">
        <p-dropdown
          [options]="endYearOptions"
          optionLabel="name"
          [filter]="false"
          filterBy="name"
          [showClear]="false"
          formControlName="endYearOption"
          optionValue="code"
          appendTo="body"
          (onChange)="OnOptionChange($event)"
        >
          <ng-template let-endYearOption pTemplate="item">
            <div class="endYearOption-item">
              <div>{{ endYearOption.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="p-col-1 align-self-baseline">
        <input
        style="text-align: center;color: #797979;"
          *ngIf="isAfter"
          type="text"
          maxlength="2"
          name="txtOccurrence"
          id="txtOccurrence"
          placeholder=""
          min="1"
          max="2"
          class="form-control"
          formControlName="txtOccurrence"
        />
      </div>
      <div class="p-col-2 fw-normal align-self-start mt-2" style="color: #797979;">
        <label *ngIf="isAfter" for="txtOccurrence" class="col-form-label fw-normal">Occurrences</label>
      </div>
      <div class="p-col-3">
        <span
          class="text-danger"
          *ngIf="
            customDateForm.get('txtOccurrence').dirty &&
            customDateForm.get('txtOccurrence').errors &&
            customDateForm.get('txtOccurrence').errors.occurrenceRange
          "
          >Please provide number from 1 to 20 only.</span
        >
      </div>
    </div>
    <!-- <div class="row">
      <div class="p-col-12">
        <span class="text-danger">Error date time</span>
      </div>
    </div> -->
  </div>
</div>
