import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../src/services/toast.service';
import {
  CampaignDetailBlockObject,
  CampaignPreviewOperationObject,
  CampaignPublishOperaionObject,
} from '../../../../models/Campaign';
import { CampaignPreviewDetails } from '../../../../models/CampaignPreviewDetails';
import { CampaignsService } from '../../../../services/campaigns.service';
import { MessagesService } from '../../../../services/messages.service';
import { CampaignMessagePreviewVM } from '../../../../models/CampaignMessage';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../../../../../src/components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { UserTokenService } from 'src/services/user-token.service';
import { ContentLibraryService } from 'src/services/contentLibrary.service';
import { ContentLibraryOperation } from 'src/models/ContentLibraryModel';
import {
  RepeatingDailyData,
  RepeatingMonthlyData,
  RepeatingWeeklyScheduleData,
  RepeatingYearlyContactData,
  RepeatingYearlyCustomData,
  RepeatingYearlyHolidayData,
  SavedContactQuickPick,
  SavedHolidayQuickPick,
  SavedOneTimeCustomDate,
  StatusQuickPickData,
} from 'src/models/MessageScheduleData';
import { LoansService } from 'src/services/Loans.service';
import { LookupService } from 'src/services/lookup.service';
import { CalendarAndTimeZoneService } from 'src/app/services/CalendarAndTimeZoneService';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-campaign-preview',
  templateUrl: './campaign-preview.component.html',
  styleUrls: ['./campaign-preview.component.scss'],
  providers: [DynamicDialogRef, DynamicDialogConfig],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CampaignPreviewComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() CampaignPreviewData: CampaignPreviewDetails;
  @Output() DialogCloseEvent: EventEmitter<string> = new EventEmitter();
  public campaignBlockDetail: CampaignDetailBlockObject;
  @ViewChild('iframePreview', { static: false }) iframePreview: any;

  public isVisible: boolean;
  public campaignId: string;
  public campaignName: string;
  public isPublishDbRecord: boolean;
  public totalCount: number;
  public templateTypeCnt: number;
  public campaignIDs: CampaignMessagePreviewVM[];
  public isCampaignPublished: boolean;
  public currentMessageIndex: number;
  public isLoading: boolean;
  public campaignIsActive: boolean;
  public validationString: string = '';
  public showValidateCampaignDialog: boolean = false;
  public isCompanyUserWithMarketing: boolean; //isStandardUserWithMarketing
  public isCampaign: boolean;
  public activationMode: string;
  public activatedByTenantUserId: string;
  public showTestModeDialog: boolean;
  public userRoleFoundInToRecipientList: boolean;
  public userRoleNameFoundInToRecipientListMessage: string;
  public showactivateDialog: boolean;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private toastService: ToastService,
    private campaignsService: CampaignsService,
    private messagesService: MessagesService,
    private router: Router,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private userTokenService: UserTokenService,
    private contentLibraryService: ContentLibraryService,
    private loanService: LoansService,
    private lookupService: LookupService,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private datePipe: DatePipe
  ) {
    this.isVisible = false;
    this.campaignBlockDetail = new CampaignDetailBlockObject();
    this.campaignBlockDetail.isPreviewMode = true;
    this.totalCount = 0;
    this.templateTypeCnt = 0;
    this.isCampaignPublished = false; //to be set later
    this.currentMessageIndex = 1; //first message
    this.isLoading = false;
    this.campaignIsActive = false;
    this.isCompanyUserWithMarketing = false;
    this.isCampaign = false;
    this.activationMode = '';
    this.activatedByTenantUserId = '';
    this.showTestModeDialog = false;
    this.userRoleFoundInToRecipientList = false;
    this.userRoleNameFoundInToRecipientListMessage = "";
    this.showactivateDialog = false;
  }

  ngOnInit(): void {
    if (this.CampaignPreviewData?.campaignId) {
      this.isLoading = true;
      //Load campaign Details.
      this.campaignId = this.CampaignPreviewData?.campaignId;
      this.campaignName = this.CampaignPreviewData?.campaignName;
      this.isCampaign = this.CampaignPreviewData?.isCampaign;
      this.activationMode = this.CampaignPreviewData?.activationMode;
      this.activatedByTenantUserId =
        this.CampaignPreviewData?.activatedByTenantUserId;

      forkJoin(
        this.campaignsService.getCampaignBlockDetails(this.campaignId),
        this.messagesService.getCampaignMessagesForPreview(this.campaignId),
        this.messagesService.GetCampaignMessagesObs(this.campaignId),
        this.lookupService.GetHolidays(),
        this.lookupService.GetContacts(),
        this.loanService.GetLoanStatusType(false),
        this.lookupService.GetTimezones()
      ).subscribe(
        ([
          blockDetailsResponse,
          previewDataResponse,
          campaignMessageResponse,
          holidaysResponse,
          contactResponse,
          loanStatusTypeResponse,
          timezonesresponse,
        ]) => {
          if (blockDetailsResponse.data) {
            this.campaignBlockDetail.isPreviewMode = true;

            this.campaignBlockDetail.fromValue =
              blockDetailsResponse.data.fromValue;
            this.campaignBlockDetail.bccData =
              blockDetailsResponse.data.bccValue;
            this.campaignBlockDetail.ccData = blockDetailsResponse.data.ccValue;
            this.campaignBlockDetail.toRecipientData =
              blockDetailsResponse.data.toValue;
            this.campaignBlockDetail.whoCanUsers =
              blockDetailsResponse.data.whoCanUseCSV;
            this.campaignBlockDetail.campaignCategory =
              blockDetailsResponse.data.categoryName;
            this.campaignBlockDetail.toList = blockDetailsResponse.data.toLists;
            this.campaignBlockDetail.isPublishCampaign =
              blockDetailsResponse.data.isPublishCampaign;
            if (environment.clientId === 'AduvoAdmin') {
              this.campaignBlockDetail.isQuickStart =
                blockDetailsResponse.data.isQuickStart;
              this.campaignBlockDetail.isRecommended =
                blockDetailsResponse.data.isRecommended;
            }
            if (this.campaignBlockDetail.isPublishCampaign) {
              //publishdb
              this.campaignIsActive = blockDetailsResponse.data.isPublished;
            } else {
              this.campaignIsActive = blockDetailsResponse.data.isPublished;
            }

            this.isCampaignPublished =
              blockDetailsResponse.data.isCampaignPublished; //fetch published status
            this.campaignBlockDetail.trimAllString();
            this.campaignBlockDetail.isLoading = false; //Hide block comp loader.
            this.isVisible = true;
            this.campaignBlockDetail.isAllUsersWhoCanUseThisCampaign =
              blockDetailsResponse.data.isAllUsersWhoCanUseThisCampaign;
          }

          if (previewDataResponse.data) {
            this.totalCount = previewDataResponse?.pageInfo?.totalRecords ?? 0;
            this.templateTypeCnt =
              previewDataResponse?.pageInfo?.returnRecord1 ?? 0;
            this.campaignIDs = previewDataResponse?.data;
            this.campaignIDs.forEach((element) => {
              let messageText = '';
              let temMessage = campaignMessageResponse.data.find(
                (x) =>
                  x.campaignMessageId.toLowerCase() ===
                  element.campaignMessageId
              );

              element.scheduleType = temMessage.scheduleType;
              element.scheduleSubtype = temMessage.scheduleSubtype;
              if (temMessage?.scheduleDataJSON) {

                if (temMessage.scheduleType === 'OneTime') {
                  if (temMessage.scheduleSubtype === 'StatusQuickPick') {
                    let savedObject: StatusQuickPickData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    element.messageText = savedObject;
                    let status =
                      loanStatusTypeResponse.data.find(
                        (x) => x.loanStatusTypeId == savedObject.loanStatusTypeId
                      )?.displayName ?? '';
                    let dayType = '';
                    let periodUnit = '';
                    let timeType = '';
                    let offset = 'before';
                    if (savedObject.offsetTypeId) {
                      if (savedObject.offsetTypeId == 'AFTER') {
                        offset = 'after';
                      }
                      if (savedObject.day === '1') {
                        periodUnit = `${savedObject.periodUnit.toLowerCase()}`;
                      } else {
                        periodUnit = `${savedObject.periodUnit.toLowerCase()}s`;
                      }
                      if (savedObject.sendOndayTypeId == 'ANYDAY') {
                        dayType = 'anyday';
                      } else {
                        dayType = 'a weekday';
                      }
                      if (savedObject.time) {
                        let timeZoneDisplayName =
                          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                            savedObject.timezoneId.toLowerCase(),
                            timezonesresponse.data
                          );
                        let time =
                          this.calendarAndTimeZoneService.get12HourTimeString(
                            savedObject.time
                          );
                        let timeString = `${time} ${timeZoneDisplayName}`;
                        timeType = timeString;
                      } else {
                        timeType = 'anytime';
                      }
                      if (savedObject.periodUnit == 'NODELAY') {
                        messageText = `Wait to send message <span>One Time </span>with <span>no delay ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
                      } else if (savedObject.periodUnit == 'DAY') {
                        messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
                      } else if (savedObject.periodUnit == 'MONTH') {
                        messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
                      } else if (savedObject.periodUnit == 'WEEK') {
                        messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
                      } else if (savedObject.periodUnit == 'YEAR') {
                        messageText = `Wait to send message <span>One Time </span>when <span>${savedObject.day} ${periodUnit} ${offset} ${status}</span> on <span>${dayType}</span> at <span>${timeType}</span>`;
                      }
                    }
                    else {
                      messageText = `Wait to send message <span>One Time </span>when <span>${status}</span>`;
                    }
                  } else if (temMessage.scheduleSubtype === 'HolidayQuickPick') {
                    let savedObject: SavedHolidayQuickPick = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let calanderDisplayName =
                      this.calendarAndTimeZoneService.getHolidayDisplayName(
                        savedObject.HolidayEventId.toLowerCase(),
                        holidaysResponse.data
                      );
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.TimezoneId.toLowerCase(),
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.Time
                      );
                    messageText = `Send messages on <span>${calanderDisplayName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                  } else if (temMessage.scheduleSubtype === 'ContactQuickPick') {
                    let savedObject: SavedContactQuickPick = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let calanderDisplayName =
                      this.calendarAndTimeZoneService.getContactEventDisplayName(
                        savedObject.ContactEventId,
                        contactResponse.data
                      );
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.TimezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.Time
                      );
                    messageText = `Send messages on <span>${calanderDisplayName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                  } else if (temMessage.scheduleSubtype === 'CustomDate') {
                    let savedObject: SavedOneTimeCustomDate = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.TimezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.Time
                      );
                    var dateStr = this.datePipe.transform(
                      savedObject.DeliverOn,
                      'MM/dd/yyyy'
                    );
                    messageText = `Send messages on <span>${dateStr}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                  }
                } else if (temMessage.scheduleType === 'Repeating') {
                  if (temMessage.scheduleSubtype === 'Monthly') {
                    let savedObject: RepeatingMonthlyData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.timezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.time
                      );
                    let days = this.calendarAndTimeZoneService.getOrdinalNumber(
                      savedObject.ordinalName
                    );

                    // repeating -> Montlhy -> day of the month (1,30) ->amd Ordianlname,weekday =null|0
                    // repeating-> first ,weekday of everymonth. -> amwd Ordinalname|weekday
                    if (savedObject.repeatingMonthlyType.toLowerCase() == 'amd') {
                      //Send messages every {{Nth}} day of every month at {{HH:MM AMPM}}{{TIMEZONE}}
                      messageText = `Send messages every <span>${days}</span> day of every month at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                    } else if (
                      savedObject.repeatingMonthlyType.toLowerCase() == 'amwd'
                    ) {
                      let weekNumber = Number(savedObject.ordinalName);
                      let weekDays = 'Last';
                      if (weekNumber != 5) {
                        weekDays =
                          this.calendarAndTimeZoneService.getOrdinalNumber(
                            weekNumber
                          );
                      }
                      // Send messages every {{Nth Weekday}} of every {{Month}} at {{HH:MM AMPM}}{{TIMEZONE}}
                      let weekName =
                        this.calendarAndTimeZoneService.weekdays.find(
                          (x) => x.code == savedObject.weekday
                        )?.name ?? '-';
                      messageText = `Send messages every <span>${weekDays}</span> <span>${weekName}</span> of every month at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                    }
                  } else if (temMessage.scheduleSubtype === 'YearlyCustom') {
                    let savedObject: RepeatingYearlyCustomData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.timezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.time
                      );
                    let monthName =
                      this.calendarAndTimeZoneService.months.find(
                        (x) => x.code == savedObject.month
                      )?.name ?? '-';

                    if (savedObject.repeatingMonthlyType.toLowerCase() == 'amd') {
                      let days = this.calendarAndTimeZoneService.getOrdinalNumber(
                        savedObject.onTheXofEveryMonthOrdinal
                      );
                      messageText = `Send messages on the <span>${days}</span> day of every <span>${monthName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                    } else if (
                      savedObject.repeatingMonthlyType.toLowerCase() == 'amwd'
                    ) {
                      let weekNumber = Number(
                        savedObject.everyXweekdayOfEveryMonthOrdinal
                      );
                      let weekDays = 'Last';
                      if (weekNumber != 5) {
                        weekDays =
                          this.calendarAndTimeZoneService.getOrdinalNumber(
                            weekNumber
                          );
                      }
                      let weekName =
                        this.calendarAndTimeZoneService.weekdays.find(
                          (x) => x.code == savedObject.weekday
                        )?.name ?? '-';
                      messageText = `Send messages on the <span>${weekDays}</span> <span>${weekName}</span>
                                    of every <span>${monthName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                    }
                  } else if (temMessage.scheduleSubtype === 'YearlyContact') {
                    //`Send messages on the contact's {{Calendar Event Display Name}} at {{HH:MM AMPM}} {{TIMEZONE}} `
                    let savedObject: RepeatingYearlyContactData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.timezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.time
                      );
                    let calanderDisplayName =
                      this.calendarAndTimeZoneService.getContactEventDisplayName(
                        savedObject.contactEventId.toLowerCase(),
                        contactResponse.data
                      );

                    messageText = `Send messages on the contact's <span>${calanderDisplayName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                  } else if (temMessage.scheduleSubtype === 'WeeklyWeekday') {
                    let savedObject: RepeatingWeeklyScheduleData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let msg = '';
                    let cnt = savedObject?.scheduleDetail?.length;
                    if (savedObject && cnt > 0) {
                      let timeZoneDisplayName =
                        this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                          savedObject.scheduleDetail[0].timezoneId.toLowerCase(),
                          timezonesresponse.data
                        );
                      let time =
                        this.calendarAndTimeZoneService.get12HourTimeString(
                          savedObject.scheduleDetail[0].time
                        );

                      if (cnt === 1) {
                        let weekName =
                          this.calendarAndTimeZoneService.weekdays.find(
                            (x) => x.code == savedObject.scheduleDetail[0].weekday
                          )?.name ?? '-';
                        msg = `Send messages every <span>${weekName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                        // Send messages every {{Weekday}} at {{HH:MM AMPM}}{{TIMEZONE}} [Cancel messages button]
                      } else if (cnt > 1) {
                        // If there are two or more weekly schedules:
                        // Send messages multiple weekly schedules at {{HH:MM AMPM}}{{TIMEZONE}} [Cancel messages button]
                        //msg = `Send messages multiple weekly schedules at ${savedObject.scheduleDetail[0].weekday} at ${time} ${ timeZoneDisplayName }`;
                        let postFix = 'Send messages every ';
                        let preFix = '';
                        for (
                          let index = 0;
                          index < savedObject.scheduleDetail.length;
                          index++
                        ) {
                          var schedule = savedObject.scheduleDetail[index];
                          if (schedule) {
                            let weekName =
                              this.calendarAndTimeZoneService.weekdays.find(
                                (x) => x.code == schedule.weekday
                              )?.name ?? '-';
                            let timeZoneDisplayName =
                              this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                                schedule.timezoneId.toLowerCase(),
                                timezonesresponse.data
                              );
                            let time =
                              this.calendarAndTimeZoneService.get12HourTimeString(
                                schedule.time
                              );
                            msg += `${postFix} ${preFix} <span>${weekName}</span> at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                            preFix = ' and every ';
                            postFix = '';
                          }
                        }
                      }
                    }
                    messageText = msg;
                  } else if (temMessage.scheduleSubtype === 'YearlyHoliday') {
                    let savedObject: RepeatingYearlyHolidayData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.timezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.time
                      );
                    let calanderDisplayName =
                      this.calendarAndTimeZoneService.getHolidayDisplayName(
                        savedObject.holidayEventId.toLowerCase(),
                        holidaysResponse.data
                      );

                    let endYearMsg = '';
                    if (savedObject.endingYear) {
                      endYearMsg = ` | Ending Year <span>${savedObject.endingYear}</span>`;
                    }
                    messageText = `Send messages on <span>${calanderDisplayName}</span> (Starting Year <span>${savedObject.startingYear}</span>${endYearMsg}) at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                  } else if (temMessage.scheduleSubtype === 'DailyEvent') {
                    let savedObject: RepeatingDailyData = JSON.parse(
                      temMessage.scheduleDataJSON
                    )[0];
                    let timeZoneDisplayName =
                      this.calendarAndTimeZoneService.getTimeZoneDisplayName(
                        savedObject.timezoneId,
                        timezonesresponse.data
                      );
                    let time =
                      this.calendarAndTimeZoneService.get12HourTimeString(
                        savedObject.time
                      );
                    messageText = `Send messages daily at <span>${time}</span><span>${timeZoneDisplayName}</span>`;
                  }
                }
              }
              element.quickPickStatusFieldText = messageText;
            });
            this.cdr.detectChanges();
            this.fetchMessageBody(); //loading for first mesage only
          }

          this.ShowLoader(false);
        },
        (err) => {
          this.ShowLoader(false);
        }
      );
    } else {
      this.toastService.showError('Invalid Campaign');
      this.DialogCloseEvent.emit('close'); //auto close
    }
  }

  /** Occurs after view initialization */
  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  }

  /** Sets control states based upon permissions/current data values. */
  setControlStates() {
    let session = this.userTokenService.getUser();
    this.isCompanyUserWithMarketing = session.isCompanyUserWithMarketing();
  }

  onCloseCampaignPreviewDialog() {
    this.DialogCloseEvent.emit('close');
  }

  onCustomize() {
    this.router.navigate([
      '/marketing/campaigns/edit/' + this.campaignId + '/default',
    ]);
  }

  async onSwitchUnPublished() {
    try {
      this.isLoading = true;
      this.GetCampaignMsg();
    } catch (message: any) {
      this.ShowLoader(false);
      this.toastService.showError(message);
    }
  }

  async GetCampaignMsg() {
    var startText = ' \n';
    var text = '';
    var msgScheduled = false;
    var messages: any;

    let userRoleNameFoundInToRecipientList = '';
    this.userRoleFoundInToRecipientList = false;
    this.loanService.GetLoanAssignTypeRoles(this.campaignBlockDetail.isPublishCampaign).subscribe(
      (res) => {
        if (res.data) {
          if (this.campaignBlockDetail.toRecipientData) {
            let toRecipientList = this.campaignBlockDetail.toRecipientData.split(', ');

            res.data.forEach((item1) => {
              const index = toRecipientList.findIndex(obj => obj === item1.name)
              if (index > -1) {
                this.userRoleFoundInToRecipientList = true;
                userRoleNameFoundInToRecipientList += item1.name + ", ";
              }
            })
            var index = userRoleNameFoundInToRecipientList.lastIndexOf(", ");
            if (index > 0) {
              userRoleNameFoundInToRecipientList = userRoleNameFoundInToRecipientList.substring(0, index);
            }
          }
        }
      },
      (err) => {
      }
    );

    await this.messagesService
      .GetCampaignMessagesObs(this.campaignId)
      .subscribe((result) => {
        if (result && result.data) {
          messages = result.data;
          messages.forEach((item, index) => {
            if (
              (item.scheduleType != '' || item.scheduleSubtype != '') &&
              msgScheduled == false
            ) {
              msgScheduled = true;
            }
          });

          if (!msgScheduled) {
            text += ' <li> At least one scheduled message </li> ';
          }
        }

        if (!this.campaignBlockDetail.campaignCategory) {
          text += ' <li> Campaign Category </li> ';
        }

        if (!this.campaignBlockDetail.fromValue) {
          text += '  <li>  From </li> ';
        }

        if (!this.campaignBlockDetail.toList) {
          text += ' <li> To list </li> ';
        }

        if (!this.campaignBlockDetail.toRecipientData) {
          text += ' <li> To recipients </li> ';
        }

        let singleList = ''
        //if(this.campaignBlockDetail.fromValue) {
        //  singleList = this.campaignBlockDetail.fromValue + ','
        //}
        if (this.campaignBlockDetail.toRecipientData) {
          singleList += this.campaignBlockDetail.toRecipientData + ','
        }
        if (this.campaignBlockDetail.ccData) {
          singleList += this.campaignBlockDetail.ccData + ','
        }
        if (this.campaignBlockDetail.bccData) {
          singleList += this.campaignBlockDetail.bccData + ','
        }
        let commonInList = singleList.split(',');
        let commonFoundInList = this.toFindDuplicatesExists(commonInList);

        if (commonFoundInList) {
          text += " <li> Each role may only appear once within the To, CC, and BCC fields</li>  ";
        }

        if (this.userRoleFoundInToRecipientList) {
          this.userRoleNameFoundInToRecipientListMessage = "Please be aware that users such as Loan Officers placed in the 'To Recipients' field will only be processed for Status Quick Pick messages.";
        } else {
          this.userRoleNameFoundInToRecipientListMessage = '';
        }

        if (text) {
          this.campaignIsActive = false;
          this.validationString = startText + text;
          this.validationString = this.validationString.slice(0, -2);
          this.showValidateCampaignDialog = true;
          this.ShowLoader(false);
        } else {
          this.showValidateCampaignDialog = false;
          if (msgScheduled) {
            if (this.campaignIsActive) {
              this.showactivateDialog = true;
              this.ShowLoader(false);
            } else {
              //in case of unpublish
              this.publishCamp();
            }
          }
        }
      });
  }

  toFindDuplicatesExists(arry) {
    let toMap = {};
    let resultToReturn = false;
    for (let i = 0; i < arry.length; i++) {
      if (toMap[arry[i]]) {
        resultToReturn = true;
        // terminate the loop
        break;
      }
      toMap[arry[i]] = true;
    }
    return resultToReturn;
  }

  onActivateDialog() {
    this.isLoading = false;
    this.showactivateDialog = false;
    this.publishCamp();
  }

  onCancelActivateDialog() {
    this.campaignIsActive = false;
    this.showactivateDialog = false;
  }

  private async publishCamp() {
    this.PublishCampaign();
  }

  private async PublishCampaign() {
    this.isLoading = true;
    var response = await this.campaignsService.Get();

    var runMode;
    response.data.forEach(element => {
      runMode = element.runMode
    });

    var resp = await this.campaignsService.TogglePublishedCampaign(this.campaignId, this.campaignBlockDetail.isPublishCampaign);
    if (resp.status == 200) {
      if (resp.message == "P") {
        this.campaignIsActive = true;
      } else {
        this.campaignIsActive = false;
      }
      this.toastService.showSuccess(`Campaign ${this.campaignIsActive ? 'Activated' : 'Deactivated'}`);
      let campaignObj: CampaignPublishOperaionObject = new CampaignPublishOperaionObject();
      campaignObj.campaingId = this.campaignId;
      campaignObj.isPublished = this.campaignIsActive;
      if (runMode === "TEST" && this.campaignIsActive && environment.clientId != 'AduvoAdmin') {
        this.showTestModeDialog = true;
      } else {
        this.showTestModeDialog = false;
      }
      this.cdr.detectChanges();
      this.campaignsService.campaignPublishSubject.next(campaignObj);
      this.ShowLoader(false);
      this.contentLibraryService.setContentLibraryOperation(new ContentLibraryOperation('All', false, ''));
    }
  }

  onChangePrevMessage() {
    if (this.currentMessageIndex - 1 < 1) {
      this.currentMessageIndex = this.totalCount; //to Last message
    } else {
      this.currentMessageIndex = this.currentMessageIndex - 1;
    }
    this.fetchMessageBody();
  }

  onChangeNextMessage() {
    if (this.currentMessageIndex + 1 > this.totalCount) {
      this.currentMessageIndex = 1; //to first
    } else {
      this.currentMessageIndex = this.currentMessageIndex + 1;
    }
    this.fetchMessageBody();
  }

  fetchMessageBody() {
    let currentIndex = this.currentMessageIndex - 1;
    if (this.totalCount > 0) {
      if (!this.campaignIDs[currentIndex].body) {
        this.isLoading = true;
        //fetch from db otherwise cached one will be used.
        let campaignMessageId =
          this.campaignIDs[currentIndex].campaignMessageId;
        this.messagesService
          .getMessageBodyHtml(this.campaignId, campaignMessageId)
          .subscribe(
            (resp) => {
              if (resp.data) {
                if (this.campaignIDs[currentIndex].templateTypeId == 1) {
                  //EMAIL
                  this.campaignIDs[currentIndex].body = resp?.data?.body;
                  this.campaignIDs[currentIndex].emailSubject =
                    resp?.data?.emailSubject;
                  this.campaignIDs[currentIndex].preHeaderText =
                    resp?.data?.preHeaderText;

                  //load in iframe
                  setTimeout(() => {
                    var frame = this.iframePreview.nativeElement;
                    frame.srcdoc = this.campaignIDs[currentIndex].body;
                    this.ShowLoader(false);
                  }, 0);
                } else {
                  //SMS
                  if (resp?.data?.body) {
                    this.campaignIDs[currentIndex].body = JSON.parse(
                      resp?.data?.body
                    );
                  }
                }
              }
              this.setDetailBlockVisibility(currentIndex);
              this.ShowLoader(false);
            },
            (err) => {
              this.setDetailBlockVisibility(currentIndex);
              this.ShowLoader(false);
            }
          );
      } else {
        setTimeout(() => {
          var frame = this.iframePreview.nativeElement;
          frame.srcdoc = this.campaignIDs[currentIndex].body;
        }, 0);
        this.setDetailBlockVisibility(currentIndex);
        this.ShowLoader(false);
      }
    } else {
      this.setDetailBlockVisibility(currentIndex);
      this.ShowLoader(false);
    }
  }

  private ShowLoader(flag: boolean = true) {
    this.isLoading = flag;
    this.cdr.detectChanges();
  }

  setDetailBlockVisibility(currentIndex: number) {
    let campaignPreviewOp = new CampaignPreviewOperationObject();
    campaignPreviewOp.templateTypeId =
      this.campaignIDs[currentIndex].templateTypeId;
    this.campaignsService.campaignPreviewOperaion.next(campaignPreviewOp);
  }

  ngOnDestroy(): void {
    this.campaignBlockDetail.Clear();
    this.iframePreview = null;
  }

  GoToCompanyProfile() {
    this.router.navigate(['/company-profile']);
  }
}
