<div ly-dialog-content [lyPx]="0" style="overflow:hidden">
  <div class="header" >
    <label id="lblPageHeader" >Crop Photo</label> 
    <span class="pi pi-times pointer" (click)="dialogRef.close()"></span>   
  </div>
 
  <div *ngIf="ready" class="padding30">
    <!-- <button (click)="cropper.zoomIn()" ly-button appearance="icon"><ly-icon>zoom_in</ly-icon></button>
    <button (click)="cropper.zoomOut()" ly-button appearance="icon"><ly-icon>zoom_out</ly-icon></button>
    <button (click)="cropper.center()" ly-button appearance="icon"><ly-icon>filter_center_focus</ly-icon></button>
    <button (click)="cropper.rotate(-90)" ly-button appearance="icon"><ly-icon>rotate_90_degrees_ccw</ly-icon></button>
    <button (click)="cropper.fit()" ly-button>Fit</button>
    <button (click)="cropper.fitToScreen()" ly-button>Fit to screen</button>
    <button (click)="cropper.setScale(1)" ly-button>1:1</button> -->
    <button (click)="cropper.clean()" class="float-end primary corp-img-btn " ><span class="fa fa-picture-o me-1 "></span>Replace Image</button>
  </div>
  <div class="crop-padding">
  <ly-img-cropper [config]="myConfig" [(scale)]="scale" (ready)="ready = true" (cleaned)="ready = false" (minScale)="minScale = $event"
                  (cropped)="onCropped($event)" (loaded)="onLoaded($event)" (error)="onError($event)">
    <p-button  color="primary" type="button" class="pointer">Upload a Photo</p-button>
  </ly-img-cropper>
  </div>
</div>
<div [className]="classes.sliderContainer" *ngIf="ready" >
  <div [class]="classes.slider" color="primary"  >
    <ly-slider  [thumbVisible]="false" [min]="minScale" [max]="1" class="mt-5 "  color="primary"  [(ngModel)]="scale" (input)="onSliderInput($event)" [step]="0.000001" cdkFocusInitial></ly-slider>
  </div>
</div>

<div container  [lyJustifyContent]="'flex-end'" ly-dialog-actions *ngIf="ready" class="padding30" >
  <!-- <button class="me-2 button-link"  (click)="dialogRef.close()"><span>Cancel</span></button> -->
  <p-button ly-button  (click)="dialogRef.close(cropper.crop())">Save</p-button>
</div>