import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MenuItem, MessageService, PrimeIcons } from 'primeng/api';
import { ToastService } from 'src/services/toast.service';
import { ContactsService } from 'src/services/contacts.service';
import { FilterHelperService } from 'src/services/filterHelper.service';
import { ContactTypeService } from 'src/services/contactType.service';
import { BitwiseService } from 'src/services/bitwise.service';
import { RequestResponse } from 'src/models/RequestResponse';
import { PagingData } from 'src/models/PagingData';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { Group } from 'src/models/enumerations';
import { WhereClauseCriteria, WhereClauseCriteriaCollection } from 'src/models/WhereClauseCriteria';
import { AndOr, DbType, SqlComparison } from 'src/models/enumerations';
import { NGENMasterContact } from 'src/models/NGENMasterContact';
import { WhereClauseService } from 'src/services/whereClause.service';
import { NGENReport } from 'src/models/NGENReport';
import { Message, MessageCollection } from 'src/models/Message';
import { ReportsService } from 'src/services/reports.service';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { reportManagementPagesState } from 'src/models/state/reportManagementPages.state';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'sentMessageLog-root',
  templateUrl: './sentMessageLog.component.html',
  styleUrls: ['./sentMessageLog.component.scss']
})
export class sentMessageLogComponent implements OnInit, AfterViewInit {
  // Shared reports state
  @Input() reportManagementPagesState: reportManagementPagesState;

  @ViewChild('dataTableId', { static: false }) dataTable: Table;

  @Output() onReportLinkClick: EventEmitter<any> = new EventEmitter<any>();

  // #region Properties

  // used for showing/hiding spinner, progress bar or skeleton.
  loadingPage: boolean = false;
  // Standard Request/Response object. Includes Paging support if necessary
  webAPI: RequestResponse = new RequestResponse();
  totalPages: number = 0;

  // where clause criteria for global filters (never directly passed to service/controller - used for building where clause descriptions)
  globalFilterCriteria: WhereClauseCriteriaCollection = new WhereClauseCriteriaCollection();

  // Horizontal scroll position
  currentHScrollPos: number = 0;

  requestOption: number = 0;

  specifyingFilter: boolean = false;

  // Global Keyword filter
  globalFilterFields: any[] = [];
  searchKeyword: string = '';
  keywordDisabled: boolean = false;
  ngenReport: NGENReport = new NGENReport()

  showMessagePreviewDialog: boolean = false;
  // #endregion

  // #region Constructor

  /**
   * Constructor
   *
   * @param http HTTPClient required to call actions in any .ts file in the services folder.
   * @param confirmationService ConfirmationService required for <p-confirmDialog>
   * @param messageService MessageService required for <p-toast>
   */
  constructor(private http: HttpClient,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastService: ToastService,
    private filterHelperService: FilterHelperService,
    private bitwiseService: BitwiseService,
    private contactTypeService: ContactTypeService
  ) {

    this.ngenReport.OwnerFullName = 'Pulse';
    this.ngenReport.CreatedDateUTC = new Date('5/27/2021');

    // initialize global filter field definitions
    this.globalFilterFields = this.getGlobalFilterFieldDefinitions();
  }

  // #endregion

  // #region Component Initialization

  /**
   * Occurs upon component initialization
   */
  async ngOnInit(): Promise<void> {
    // obtain data for the page
    //this.webAPI.paging.orderBy = this.defaultOrderBy;
    this.webAPI.paging.totalRecords = 0;
    this.getData(this.webAPI.paging.currentPage);
  }

  /**
   * Occurs after view initialization
   */
  ngAfterViewInit(): void {
    // allow ngAfterViewInit() to complete before setting control states
    // otherwise a non-fatal exception is thrown
    setTimeout(() => {
      this.setControlStates();
    }, 10);
  }

  /**
   * Sets control states based upon permissions/current data values.
   */
  setControlStates() {
  }

  // #endregion

  // #region Data Acquisition

  /**
   * Retrieves all necessary data for this component.
   *
   * @param: currentPage - paging support.
   */
  async getData(currentPage) {
    if (this.loadingPage)
      return;

    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;

    try {
      // code to acquire data via a Web API call.
      // returned object is a RequestResponse object.
      this.webAPI.data = []; //  Required - Clear data from last request or set your specific controller request data.
      //this.webAPI.data = JSON.stringify(this.allCriteria);
      //this.webAPI.requestOption = this.requestOption;
      this.webAPI.paging.pagingRead = true; // Required for lazy loading of data (default gets all records).

    //   var response = await this.contactsService.getData(this.webAPI);
    //   // assign returned object to class variable.
    //   this.webAPI = response; // Required - do not remove.
    //   this.totalPages = PagingData.totalPages(this.webAPI.paging);

    //   // convert the 'data' property of the RequestResponse object's 'data' property JSON string value to an object.
    //   RequestResponse.dataToObject(this.webAPI); // Required - do not remove.

    //   this.webAPI.data = JSON.parse(JSON.stringify(this.contactsService.toCollectionInstance(this.webAPI.data)));

    let messages: MessageCollection = new MessageCollection();

    let message: Message = new Message();
    message.MessageTitle = 'Application Received';
    message.MessageSubject = '[BorrowerLastName] Application Received';
    message.MessageTypeName = 'Email';
    message.MessageTypeId = 0;
    message.CreatedBy = 'Admin';
    message.CreatedOnUtc = new Date();
    messages.Items.push(message);

    message = new Message();
    message.MessageTitle = 'Application Reminder';
    message.MessageSubject = '[Name], in case you missed our email, we wanted to resend your...';
    message.MessageTypeName = 'Text';
    message.MessageTypeId = 1;
    message.CreatedBy = 'Admin';
    message.CreatedOnUtc = new Date();
    messages.Items.push(message);

    message = new Message();
    message.MessageTitle = 'Application Sent';
    message.MessageSubject = '[BorrowerLastName] Application Sent';
    message.MessageTypeName = 'Email';
    message.MessageTypeId = 0;
    message.CreatedBy = 'Admin';
    message.CreatedOnUtc = new Date();
    messages.Items.push(message);

    message = new Message();
    message.MessageTitle = 'Application Reminder';
    message.MessageSubject = '[Name], in case you missed our email, we wanted to resend your...';
    message.MessageTypeName = 'Text';
    message.MessageTypeId = 1;
    message.CreatedBy = 'Admin';
    message.CreatedOnUtc = new Date();
    messages.Items.push(message);

    this.webAPI.data = messages;
    this.webAPI.paging.totalRecords = 4;
    this.webAPI.paging.currentPage = 1;


      // when data is retrieved, set this.loadingPage to false.
      this.loadingPage = false;

    } catch (message: any) {
      this.webAPI.paging.totalRecords = 0;
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  // #endregion

  // #region Paging Support

  /**
   * Selected page changed in the paginator
   * @param event
   */
  onPageChanged(event) {
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onPageChanged(this.webAPI.paging, event, this.dataTable)) {
      this.refreshData();
    }
  }

  /**
   *  Goes to the user entered page.
   */
  onGotoPage() {
    // Uncomment the following if you are using a p-table control that includes a paginator.
    if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
      this.refreshData();
    }
  }

  /**
   * Retrieves data applying any sorting and filtering, and scrolls the
   * list to its previous horizontal scroll position.
   */
  async refreshData() {
    this.currentHScrollPos = this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft;
    await this.getData(this.webAPI.paging.currentPage);
    setTimeout(() => {
      this.dataTable.scrollableViewChild.scrollBodyViewChild.nativeElement.scrollLeft = this.currentHScrollPos;
    }, 10);
  }

  // #endregion

  /**
   * Gets the array of initial column definitions to display.
   */
   getColumnDefinitions(): Array<any> {
    // always display frozen and default columns
    let results: any[] = [];
    results = results.concat(this.getFrozenColumnDefinitions());
    return results;
  }

  /**
   * Gets the array of default column definitions that are not selectable and always exported.
   */
  getFrozenColumnDefinitions(): Array<any> {
    let results = [
      { field: 'Name', header: 'Name', style: 'width:26rem; min-width:26rem;', isSortable: true, isFilterable: true, filterType: 'text', showOperator: false },
    ];
    return results;
  }


  /**
   * Gets the array of global filter field names.
   * */
  getGlobalFilterFieldDefinitions() {
    let results = [
      'MessageTitle',
      'MessageSubject'
    ];
    return results;
  }

  /**
   * Called when the global filter value changes.
   * (called on every keystroke)
   */
  onGlobalFilterChange() {
    // we don't want to apply the filter until we say so (filter's add-on button is clicked)
    this.specifyingFilter = true;
    this.dataTable.filters.global = ({ "value": this.searchKeyword, matchMode: "contains" });
    this.specifyingFilter = false;
  }

  /**
   * Applies the global filter to lazy loaded data.
   */
  async onApplyGlobalFilter() {
    // *************************************************************************************
    // global filter applys to the entire result set (includes column filters)
    // *************************************************************************************
    this.dataTable._filter();

    //// *************************************************************************************
    //// global filter applys to only the current retrieved records
    //// not sure why anyone would want this for lazy loaded data, but here it is
    //// *************************************************************************************
    //this.dataTable.lazy = false;
    //this.specifyingFilter = true;
    //this.dataTable._filter();
    //this.dataTable.lazy = true;
    //this.specifyingFilter = false;
  }

  /**
   * Called when filter information is cleared, specified, and applied.
   *
   * @param event
   */
  onFilter(event: any) {
    //console.log(JSON.stringify(event));

    // event trigger by a selection change in the Contact Type filer dropdown
    // do not process until 'Clear' or 'Apply' is clicked.
    if (this.specifyingFilter) {
      //console.log('BAIL!!!');
      this.specifyingFilter = false;
      return;
    }

    // retrieve data (back to first page)
    this.dataTable.first = 0;
    this.webAPI.paging.currentPage = 1;
    this.refreshData();
  }

  /**
   * Generates the this.globalFilterCriteria WhereClauseCriteriaCollection value.
   */
  makeGlobalFilterCriteria() {
  }

  /**
   * Called when clear filter button is clicked.
   *
   * @param event
   */
  onClearFilters(event: any) {
    this.specifyingFilter = true;

    // this.selectedTypes = [];
    // this.allCriteria = new WhereClauseCriteriaCollection();
    // this.allCriteria.Items = this.allCriteria.Items.concat(this.defaultCriteria.Items); // ALWAYS!!!
    this.globalFilterCriteria = new WhereClauseCriteriaCollection();

    this.searchKeyword = '';
    this.dataTable.filters.global = null;
    this.dataTable.filters.Name[0].value = null;
    this.dataTable.filters.ContactTypeDescriptions[0].value = null;

    this.specifyingFilter = false;

    // retrieve data (back to first page)
    this.dataTable.first = 0;
    this.webAPI.paging.currentPage = 1;
    this.refreshData();
  }

  /**
   * Called when a filter 'Chip' is removed.
   *
   * @param event
   */
  onRemoveFilter(label: any) {
    try {
      if (this.dataTable == null)
        return;

      var filters = this.dataTable.filters;
      if (filters == null)
        return;

      if (label == "Name") {
        if (filters.Name != null)
          filters.Name[0].value = null;
      }
    } catch { }

    var a = JSON.stringify(this.dataTable.filters);
    var b = a.substring(1);
    var c = b.substring(0, b.length - 1);
    var json = '{ "filters": { ' + c + ' } }';
    //console.log(json);
    this.onFilter(JSON.parse(json));
  }

  // #endregion

  onMessagePreviewClick(event){
    this.showMessagePreviewDialog = true;
  }

  onCloseMessagePreviewDialog(){
    this.showMessagePreviewDialog = false;
  }
}
