import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { AppSettings } from '../appSettings';
import { RequestResponse } from '../models/RequestResponse';

@Injectable({ providedIn: 'root' })
export class SMSService {
  constructor(private http: HttpClient) {
  }

  public SendSms(SMSBody: String[], ToNumber: String, FromNumber: String =''): Observable<RequestResponse> {
    return this.http.post<RequestResponse>(AppSettings.API_GET_MESSAGE_TEMPLATES + '/SendSms', {
      SMSBody: SMSBody, ToNumber: ToNumber, FromNumber: FromNumber  
    });
  }
}
