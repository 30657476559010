<!--
*************************************************************************************
Page Heading and Buttons
*************************************************************************************
-->
<div id="imageLibraryDiv" class="content" role="main">
  <div class="content table-search-row second-header-fixed">
    <table class="text-wrapper">
      <tr>
        <td class="p-d-flex align-items-center">
          <label id="lblPageHeader">Image Library</label>
          <span class="p-input-icon-left p-float-label">
            <input
              [(ngModel)]="searchKeyword"
              class="search-input rounded-pill"
              pInputText
              type="text"
              (keyup.enter)="onApplyGlobalFilter()"
              (input)="onGlobalFilterChange()"
              placeholder="Search by keyword"
            />
            <!-- <label class="search-label">Search by Name</label> -->
          </span>
          <span
            type="button"
            pButton
            pRipple
            icon="fal fa-search search-fa"
            pTooltip="Execute Search"
            tooltipPosition="bottom"
            showDelay="500"
            (click)="onApplyGlobalFilter()"
            class="p-button-secondary execute-search"
          ></span>
          <div class="input-group search-field position-relative p-ml-4">
            <!-- <input
              [(ngModel)]="searchKeyword"
              class="form-control rounded-pill"
              pInputText
              placeholder="Search by Name"
              type="text"
              (keyup.enter)="onApplyGlobalFilter()"
              (input)="onGlobalFilterChange()"
            /> -->
            <!-- <i
              class="fa-light fa-magnifying-glass position-absolute"
              (click)="onApplyGlobalFilter()"
              data-bs-toggle="tooltip"
              title="Execute Search"
            ></i> -->
          </div>
        </td>
        <td align="right">
          <button
            pButton
            icon="pi pi-upload text-white"
            label="Upload Image"
            (click)="onAddImageBtnClick($event)"
            class="header-button"
            [disabled]="loadingPage === true ? 'disabled' : addImageDisabled"
          ></button>
        </td>
      </tr>
    </table>
  </div>
</div>
<!--
*************************************************************************************
Data Table
*************************************************************************************
-->
<div class="text-wrapper py-3 mb-5 grid-section">
  <p-menu
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
    #ellipseMenu
    class="groundmenu"
    [popup]="true"
    [model]="contextMenuItems"
  ></p-menu>
  <p-table
    class="pulseTable"
    #libraryTableId
    [value]="webAPI.data.Items"
    [lazy]="true"
    (onLazyLoad)="loadImages($event)"
    [rows]="25"
    [rowsPerPageOptions]="[25, 50, 100]"
    [paginator]="webAPI.paging.totalRecords > 0"
    [totalRecords]="webAPI.paging.totalRecords"
    [loading]="loadingPage"
    [scrollable]="false"
    scrollWidth="100%"
    scrollHeight="100%"
    scrollDirection="both"
    [resizableColumns]="true"
    [style]="{ width: '100%' }"
    [virtualScroll]="false"
    sortField="DateUpdatedUTC"
    [sortOrder]="-1"
    [showCurrentPageReport]="true"
     [(first)]="pageIndex"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th pSortableColumn="Title">
          Name <p-sortIcon field="Title"></p-sortIcon>
        </th>
        <th pSortableColumn="ImageType">
          FILE TYPE <p-sortIcon field="ImageType"></p-sortIcon>
        </th>
        <th pSortableColumn="ImageSize">
          FILE SIZE <p-sortIcon field="ImageSize"></p-sortIcon>
        </th>
        <th pSortableColumn="OwnerFullName">
          OWNER <p-sortIcon field="OwnerFullName"></p-sortIcon>
        </th>
        <th pSortableColumn="DateUpdatedUTC">
          UPDATED <p-sortIcon field="DateUpdatedUTC"></p-sortIcon>
        </th>
        <!-- <th>ACTIVE</th> -->
        <th></th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-row
      let-record
      let-rowIndex="rowIndex"
      let-columns="columns"
    >
      <tr>
        <td *ngIf="!record.Avatar" (click)="onRowEdit($event, record)">
          <a>
            <label>
              <img
                [src]="record.ImageURL"
                [style]="{
                  'min-height': '30px',
                  'max-height': '30px',
                  'min-width': '30px',
                  'max-width': '60px'
                }"
              />
            </label>
          </a>
        </td>
        <td *ngIf="record.Avatar" (click)="onRowEdit($event, record)">
          <a>
            <label>
              <img
                [src]="record.ImageURL"
                class="libraryImageAvatar"
                [style]="{
                  'min-height': '30px',
                  'max-height': '30px',
                  'min-width': '30px',
                  'max-width': '30px'
                }"
              />
            </label>
          </a>
        </td>
        <td
          class="title-column imageGridName"
          (click)="onRowEdit($event, record)"
        >
          <a>
            <label> {{ record.Title }} </label></a
          >
        </td>
        <td class="p-body-colum">{{ record.ImageType }}</td>
        <td class="p-body-colum">{{ record.ImageSize }}</td>
        <td class="p-body-colum">{{ record.OwnerFullName }}</td>
        <td class="p-body-colum">
          {{
            record.DateUpdatedUTC | date : "EEEE, LLL dd, YYYY At hh:mm aaaa"
          }}
        </td>
        <!-- <td class="p-body-colum">
          <p-inputSwitch *ngIf="!row['CanEdit']" disabled="true" class="disable-toggle" [(ngModel)]="record.Published"
            (onChange)="onPublishChange(row, $event)"></p-inputSwitch>
          <p-inputSwitch *ngIf="row['CanEdit']" [(ngModel)]="record.Published"
            (onChange)="onPublishChange(row, $event)"></p-inputSwitch>
        </td> -->
        <td class="table-ellipse">
          <button
            type="button"
            aria-haspopup="true"
            class="p-column-filter-menu-button table-ellipse-button"
            (focus)="ellipseMenu.hide()"
            (click)="onEllipseClick($event, record)"
          >
            <span class="pi pi-ellipsis-h"></span>
          </button>
        </td>
        <!-- <td>{{ record.IsPublishingDb }}</td> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="6">No records found</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft" let-state>
      <label style="font-weight: 400"
        >{{ webAPI.paging.totalRecords }}
        {{ webAPI.paging.totalRecords > 1 ? "results" : "result" }}</label
      >
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
      <div>
        <label style="margin-top: 1px; margin-right: 8px; font-weight: 400"
          >Go to Page</label
        >
        <input
          type="text"
          pInputText
          pKeyFilter="int"
          [(ngModel)]="webAPI.paging.currentPage"
          width="3"
          maxlength="3"
          style="
            text-align: center;
            width: 4rem;
            margin-right: 8px;
            border-radius: 20px;
          "
          (change)="onGotoPage()"
        />
      </div>
    </ng-template>
  </p-table>
</div>
<!--
*************************************************************************************
Confirm Dialogs
*************************************************************************************
-->
<p-confirmDialog
  
  icon="pi pi-exclamation-triangle"
  [baseZIndex]="11000"
></p-confirmDialog>
<p-dialog
  class="custom-alert"
  id="delete_image"
  #deleteImageDialog
  [(visible)]="showDeleteImageDialog"
  [resizable]="false"
  [modal]="true"
  [style]="{ width: '52.5em', 'font-weight': '700' }"
>
  <div class="pb-25">
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="alert-header"
      >Please confirm you want to delete {{ selectedImage?.Title }}</span
    >
  </div>
  <div class="pb-35"></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex align-items-center">
      <p-button
        [style]="{ 'margin-right': '17.5px' }"
        class=""
        (click)="deleteImage()"
        label="Yes, I want to delete"
        styleClass="p-button-rounded"
      ></p-button>
      <p-button
        (click)="showDeleteImageDialog = false"
        styleClass="p-button-secondary"
        label="Cancel"
        [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }"
      ></p-button>
      <!--   <a
        (click)="showDeleteImageDialog = false"
        styleClass="p-button-secondary"
        style="color:#666666;cursor: pointer;margin-left:17.5px;text-decoration: underline;"
      >Cancel</a> -->
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Image Preview Dialog
*************************************************************************************
-->
<p-dialog
  class="custom-dialog"
  id="previewDialog_ID"
  [resizable]="false"
  [(visible)]="showImagePreviewDialog"
  [modal]="true"
  [style]="{
    'max-width': '90%',
    'max-height': '90%',
    'min-width': '40em',
    'font-weight': '700'
  }"
  [baseZIndex]="10000"
>
  <p-header>
    <!-- <i class="pi pi-eye" style="font-size: 1.3rem; margin-right: 0.5rem"></i> -->
    <span class="dialog-head">Preview</span>
  </p-header>
  <imagePreviewDialog-root
    #imagePreviewDialog
    [selectedImage]="selectedImage"
  ></imagePreviewDialog-root>
</p-dialog>
<!--
*************************************************************************************
Image Upload/Edit Dialog
*************************************************************************************
-->
<imageUploadDialog-root
  #imageUploadDialog
  [selectedImage]="selectedImageClone"
  [display]="showImageUploadDialog"
  [title]="dialogTitle"
  (onCloseDialog)="onCloseImageUploadDialog()"
  (onSaveImageClick)="doSave()"
  [edited]="isEdit"
>
</imageUploadDialog-root>
