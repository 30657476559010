export class DateTimeHelper {
 
 public Get12HourTimeComponent(timeStr: string) {//HH:MM AMPM
  let time = {
   hours: '00',
   minutes: '00',
   AmPM: 'AM'
  };

  if (timeStr?.length >= 6) {
   var timeArr = timeStr.substring(0, 6).split(":");//[HH:MM]
   if (parseInt(timeArr[0]) >= 12) {
    if(parseInt(timeArr[0]) == 12) {
        time.hours = (parseInt(timeArr[0])).toString();//PM
    }
    else {
        time.hours = (parseInt(timeArr[0]) - 12).toString();//PM
    }    
    time.AmPM = "PM";
   } else {
    if(parseInt(timeArr[0]) == 0) {
        time.hours = '12';//AM
    }
    else {
        time.hours = (parseInt(timeArr[0])).toString();//AM
    }    
    time.AmPM = "AM";
   }
   time.minutes = (parseInt(timeArr[1])).toString();
  }
  return time;
 }
}