import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ArgumentOutOfRangeError, forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from '../../../../../../src/app/services/CalendarAndTimeZoneService';
import { formState } from 'src/helpers/formState.helper';
import { CMSComponentData, RepeatingWeeklyData, RepeatingWeeklyScheduleData, RepeatingWeeklyWeekdayData, RepeatingYearlyHolidayData } from '../../../../../../src/models/MessageScheduleData';
import { LookupService } from 'src/services/lookup.service';
import { ToastService } from 'src/services/toast.service';
import { CampaignMessageScheduleDataService } from '../../../../../services/campaignMessageSchedule.Data.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-repeating-weekly',
  templateUrl: 'campaign-message-schedule-repeating-weekly.component.html',
})
export class CampaignMessageScheduleRepeatingWeeklyComponent implements OnInit {
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public CMSComponentData: CMSComponentData;
  repeatingWeeklyData: RepeatingWeeklyData;

  weeklyForm: FormGroup | any = null;
  validator: formState = new formState();
  isFormValueChane = false;
  isAfter = false;
  hours: any[];
  minutes: any[];
  meridiems: any[];
  weekdays: any[];
  timeZones: any[];
  startDates: any[];
  endDates: any[];
  dateOptions: any[];
  dateFormat: string = 'mm/dd/yy';
  todayDate = new Date();
  tomorrowDate = new Date();
  tomorrowAfterDate = new Date();
  userTimeZoneId: any;
  userToken: UserToken;
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  public disableDaysArray: string;

  constructor(
    private http: HttpClient,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    public datepipe: DatePipe,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private userTokenService: UserTokenService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(7);
    this.repeatingWeeklyData = new RepeatingWeeklyData();
    this.userToken = this.userTokenService.getUser();
    this.messageType = this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe = 'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
    if(this.messageType == 2 && this.allowTCPAForTextMessages) {
      this.disableDaysArray = '[0,6]';
    } else {
      this.disableDaysArray = '';
    }
  }

  ngOnInit(): void {
    this.tomorrowDate.setDate(this.todayDate.getDate());
    this.tomorrowAfterDate.setDate(this.todayDate.getDate() + 1);
    this.weekdays = this.calendarAndTimeZoneService.GetWeekDays();
    this.dateOptions = this.calendarAndTimeZoneService.GetDateOption();
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let scheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;

    forkJoin(this.lookupService.GetTimezones()).subscribe(
      ([timezonesresponse]) => {
        this.timeZones = timezonesresponse.data;
        this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
        if (this.userTimeZoneId) {
          let defaultObject =
            this.calendarAndTimeZoneService.getDefaultDateTimeObject(
              this.userTimeZoneId,
              this.timeZones
            );
          let defaultEndDate = new Date();
          defaultEndDate.setDate(defaultObject.defaultDate.getDate() + 1);
          let defaultData1 = {
            weekday: '1',
            hour: defaultObject.defaultHour,
            minute: defaultObject.defaultMinute,
            meridiem:
              this.messageType == 2 && this.allowTCPAForTextMessages
                ? this.calendarAndTimeZoneService.getMeridiem(
                    defaultObject.defaultHour
                  )
                : defaultObject.defaultMeridiem,
            timeZone: defaultObject.defaultTimeZoneId,
            startDate: defaultObject.defaultDate,
            endDate: defaultEndDate,
            dateOption: 'NEVER',
            otherDays: this.formBuilder.array([]),
          };
          let savedObject = new RepeatingWeeklyScheduleData();
          let dateTimeHelper = new DateTimeHelper();
          if (
            scheduleDataJSON &&
            scheduleDataJSON != 'No Schedule' &&
            scheduleType == 'Repeating' &&
            scheduleSubtype == 'WeeklyWeekday'
          ) {
            savedObject = JSON.parse(scheduleDataJSON)[0];
            if (savedObject) {
              defaultData1.startDate = new Date(savedObject.dateStart);
              defaultData1.startDate.setMinutes(
                defaultData1.startDate.getMinutes() +
                  defaultData1.startDate.getTimezoneOffset()
              );

              if (savedObject.dateEnd) {
                defaultData1.endDate = new Date(savedObject.dateEnd);
                defaultData1.endDate.setMinutes(
                  defaultData1.endDate.getMinutes() +
                    defaultData1.endDate.getTimezoneOffset()
                );
                defaultData1.dateOption = 'ON'; //show dropdown
              }
              if (savedObject.scheduleDetail.length > 0) {
                this.createForm(defaultData1);
                for (
                  let index = 0;
                  index < savedObject.scheduleDetail.length;
                  index++
                ) {
                  var time = dateTimeHelper.Get12HourTimeComponent(
                    savedObject.scheduleDetail[index].time
                  );
                  this.otherDayControl.push(
                    new FormGroup({
                      otherWeekDay: new FormControl(
                        savedObject.scheduleDetail[index].weekday
                      ),
                      otherHour: new FormControl(parseInt(time.hours)),
                      otherMinute: new FormControl(parseInt(time.minutes)),
                      otherMeridiem: new FormControl(time.AmPM),
                      otherTimeZone: new FormControl(
                        savedObject.scheduleDetail[index].timezoneId
                      ),
                    })
                  );
                }
              }
            }
          } else {
            this.createForm(defaultData1);
            this.otherDayControl.clear();
            this.addOtherDayFormGroup();
          }
        }
      }
    );
  }
  private AddSavedDayFormGroup(): FormGroup {
    let defaultObject =
      this.calendarAndTimeZoneService.getDefaultDateTimeObject(
        this.userTimeZoneId,
        this.timeZones
      );
    return new FormGroup({
      otherWeekDay: new FormControl('1'),
      otherHour: new FormControl(defaultObject.defaultHour),
      otherMinute: new FormControl(defaultObject.defaultMinute),
      otherMeridiem: new FormControl(defaultObject.defaultMeridiem),
      otherTimeZone: new FormControl(defaultObject.defaultTimeZoneId),
    });
  }
  createForm(defaultData1) {
    this.weeklyForm = this.formBuilder.group(
      {
        startDate: [defaultData1.startDate, Validators.required],
        endDate: [defaultData1.endDate, Validators.required],
        dateOption: [defaultData1.dateOption],
        otherDays: this.formBuilder.array(
          [],
          this.calendarAndTimeZoneService.CustomeDuplicateValidators()
        ),
      },
      {
        validator: this.calendarAndTimeZoneService.CustomeDateValidators(
          'startDate',
          'endDate',
          'dateOption'
        ),
      }
    );

    //this.otherDayControl.push(this.createOtherDayFormGroup());
    this.weeklyForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChane = true;
      this.repeatingWeeklyData.campaignMessageId = '';
      this.fillListOfOtherDays();
      this.fillOtherData();
      this.doEmit();
    });
    if (this.isFormValueChane == false) {
      this.repeatingWeeklyData.campaignMessageId = '';
      this.fillListOfOtherDays();
      this.fillOtherData();
      this.doEmit();
    }
  }
  fillOtherData() {
    this.repeatingWeeklyData.dateStart = this.datepipe.transform(
      this.weeklyForm.value.startDate,
      'MM/dd/yyyy'
    );
    this.repeatingWeeklyData.dateOption = this.weeklyForm.value.dateOption;
    if (this.weeklyForm.value.dateOption == 'NEVER') {
      this.isAfter = false;
      let calculateDate = new Date(this.weeklyForm.value.startDate);
      calculateDate.setFullYear(calculateDate.getFullYear() + 9);
      this.repeatingWeeklyData.dateEnd = this.datepipe.transform(
        calculateDate,
        'MM/dd/yyyy'
      );
    } else {
      this.isAfter = true;
      let calculateDate = new Date(this.weeklyForm.value.endDate);

      this.repeatingWeeklyData.dateEnd = this.datepipe.transform(
        calculateDate,
        'MM/dd/yyyy'
      );
    }
  }
  fillListOfOtherDays() {
    this.repeatingWeeklyData.weeklyWeekdayList = [];
    let objWeeklyweekday = new RepeatingWeeklyWeekdayData();
    for (
      let index = 0;
      index < this.weeklyForm.value.otherDays.length;
      index++
    ) {
      let objWeeklyweekday = new RepeatingWeeklyWeekdayData();
      let hr = Number(this.weeklyForm.value.otherDays[index].otherHour);
      let mi = Number(this.weeklyForm.value.otherDays[index].otherMinute);
      let mer = this.weeklyForm.value.otherDays[index].otherMeridiem;
      objWeeklyweekday.time = this.calendarAndTimeZoneService.getTimeSpan(
        hr,
        mi,
        mer
      );
      objWeeklyweekday.timezoneId =
        this.weeklyForm.value.otherDays[index].otherTimeZone;
      objWeeklyweekday.timezoneInfoId = this.timeZones.find(
        (x) => x.timezoneId.toLowerCase() === objWeeklyweekday.timezoneId
      )?.timezoneInfoId;
      objWeeklyweekday.weekday =
        this.weeklyForm.value.otherDays[index].otherWeekDay.toString();
      this.repeatingWeeklyData.weeklyWeekdayList.push(objWeeklyweekday);
    }
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.weeklyForm.valid;
    this.CMSComponentData.componentValueJSON = this.repeatingWeeklyData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
  private createOtherDayFormGroup(): FormGroup {
    let defaultObject =
      this.calendarAndTimeZoneService.getDefaultDateTimeObject(
        this.userTimeZoneId,
        this.timeZones
      );
    defaultObject.defaultMeridiem =
      this.messageType == 2 && this.allowTCPAForTextMessages
        ? this.calendarAndTimeZoneService.getMeridiem(defaultObject.defaultHour)
        : defaultObject.defaultMeridiem;
    return new FormGroup({
      otherWeekDay: new FormControl('1'),
      otherHour: new FormControl(defaultObject.defaultHour),
      otherMinute: new FormControl(defaultObject.defaultMinute),
      otherMeridiem: new FormControl(defaultObject.defaultMeridiem),
      otherTimeZone: new FormControl(defaultObject.defaultTimeZoneId),
    });
  }
  get otherDayControl() {
    return this.weeklyForm.get('otherDays') as FormArray;
  }
  addOtherDayFormGroup() {
    this.otherDayControl.push(this.createOtherDayFormGroup());
  }
  removeOtherDay(i: number) {
    this.otherDayControl.removeAt(i);
  }
  OnHourChange(i: number) {
    if (this.messageType == 2 && this.allowTCPAForTextMessages) {
      let selectedHour = this.weeklyForm.value.otherDays[i].otherHour;

      let otherday = this.weeklyForm?.get('otherDays') as FormArray;
      const meridiemControl = otherday.controls[i]['controls'].otherMeridiem;
      meridiemControl.patchValue(
        this.calendarAndTimeZoneService.getMeridiem(selectedHour)
      );
    }
  }
}