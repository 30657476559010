import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr'
import { environment } from 'src/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SignalrService {
  hubUrl: string;
  connection: any;
  private hubSource = new BehaviorSubject('default message');
  public hubMessage = this.hubSource.asObservable();

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.hubUrl = environment.signalRHubUrl + '/hubs/notification';
  }

  // public async initiateSignalrConnection(): Promise<void> {
  //   this.oidcSecurityService.checkAuth().subscribe(async (isAuthenticated) => {
  //     if (isAuthenticated && !this.connection) {
  //       if (this.oidcSecurityService.getToken() == null){
  //         setTimeout(async () => {
  //          await this.StartConnection();
  //         }, 1500);
  //       }else{
  //         await this.StartConnection();
  //       }
  //     } else if (!isAuthenticated && this.connection) {
  //       this.connection.stop();
  //       this.connection == null;
  //       console.log(`SignalR connection stopped`);
  //     }
  //   });
  // }

  async StartConnection() {
    try {
      this.connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Trace)
        .withUrl(this.hubUrl, {
          accessTokenFactory: () => this.oidcSecurityService.getToken(),
          // skipNegotiation: true,
          //transport: signalR.HttpTransportType.WebSockets,
          //withCredentials: true
        })
        .withAutomaticReconnect()
        .build();

      await this.connection.start();
      this.setSignalrClientMethods();
      console.log(`SignalR connection success! connectionId: ${this.connection.connectionId}`);
    }
    catch (error) {
      console.log(`SignalR connection error: ${error}`);
    }
  }

  private setSignalrClientMethods(): void {
    this.connection.on('NewNotification', (message: string) => {
      this.hubSource.next(message);
    });
  }
}
