<div id="designLibraryDiv" class="content" role="main">
  <!--
  *************************************************************************************
  Page Heading and Buttons
  *************************************************************************************
  -->
  <div class="content table-search-row second-header-fixed">
    <div class="text-wrapper">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <label id="lblPageHeader">Template Library</label>
          <span class="p-input-icon-left p-ml-auto p-float-label">
            <input
              [(ngModel)]="searchKeyword"
              pInputText
              type="text"
              (keyup.enter)="onApplyGlobalFilter()"
              (input)="onGlobalFilterChange()"
              class="search-input rounded-pill"
              placeholder="Search by keyword"
            />
            <!-- <label class="search-label">Search by Name</label> -->
          </span>
          <span
            type="button"
            pButton
            pRipple
            icon="fal fa-search search-fa"
            pTooltip="Execute Search"
            tooltipPosition="bottom"
            showDelay="500"
            (click)="onApplyGlobalFilter()"
            class="p-button-secondary execute-search"
          ></span>
        </div>
        <div style="position: relative" class="droppos">
          <div class="dropdown ">
          <button
            pButton
            class="text-white"
            type="button"
           id="createTemplate"
            class="header-button"
            data-bs-toggle="dropdown" aria-expanded="false"
          >
            <i class="fa-light fa-pen"></i>
            &nbsp;&nbsp;Create a Template&nbsp;&nbsp;
            <img
              class="btn-close-white"
              src="../../../assets/images/blue-triangle.svg"
              alt=""
            />
           
          </button>
          <ul class="dropdown-menu" aria-labelledby="createTemplate">
            <li><a class="dropdown-item" [routerLink]="['/marketing/template-library/email']">Email</a></li>
            <li><a class="dropdown-item" [routerLink]="['/marketing/template-library/sms']">Text</a></li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  *************************************************************************************
  Data Table
  *************************************************************************************
  -->
  <div class="text-wrapper py-3 grid-section">
    <div class="d-flex">
      <div
        class="d-flex flex-column flex-wrap template-library-filters mt-2"
        style="height: fit-content"
      >
        <div
          role="button"
          (click)="loadAllCategoryTemplates($event)"
          [class.active]="selectedCategoryItem === -1"
        >
          Browse All
        </div>
        <div
          role="button"
          *ngFor="let category of messageCategoryList; let index = index"
          [class.active]="selectedCategoryItem == index"
          (click)="loadCategoryTemplates($event, category, index)"
        >
          <span> {{ category.item_text }} </span>
        </div>
      </div>

      <div role="button" class="flex-fill" id="TemplateLibrary">
        <p-menu
          #ellipseMenu
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          [popup]="true"
          appendTo="body"
          [autoZIndex]="false" [baseZIndex]="1" 
          [model]="contextMenuItems"
        ></p-menu>
        <!-- <p-menu
          #messageTypeMenu
          class="menufixed"
          id="messageFixed"
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          [popup]="true"
          appendTo="createBtn"
          [model]="templateTypeMenuItems"
        ></p-menu> -->
        <p-table
          #templateTableId
          class="pulseTable"
          [value]="templateList"
          [lazy]="true"
          (onLazyLoad)="loadTemplates($event)"
          [rows]="webAPI.paging.pageSize"
          [rowsPerPageOptions]="[25, 50, 100]"
          [paginator]="webAPI.paging.totalRecords > 0"
          [totalRecords]="webAPI.paging.totalRecords"
          [loading]="loadingPage"
          [scrollable]="false"
          scrollWidth="100%"
          scrollHeight="100%"
          scrollDirection="both"
          [resizableColumns]="true"
          [style]="{ width: '100%' }"
          [virtualScroll]="false"
          [sortField]="newSortField" 
          [sortOrder]="newSortOrder"
          [showCurrentPageReport]="true"
          [(first)]="pageIndex"
        >
          <ng-template pTemplate="header">
            <tr>
              <th
                style="max-width: 450px; min-width: 450px"
                pSortableColumn="title"
              >
                Name <p-sortIcon field="title"></p-sortIcon>
              </th>
              <th
                style="max-width: 200px; min-width: 100px"
                pSortableColumn="templateTypeId"
              >
                FORMAT <p-sortIcon field="templateTypeId"></p-sortIcon>
              </th>
              <th
                style="max-width: 300px; min-width: 200px"
                pSortableColumn="ownerFullName"
              >
                OWNER <p-sortIcon field="ownerFullName"></p-sortIcon>
              </th>
              <th
                style="max-width: 300px; min-width: 100px"
                *ngIf="currentClientID === 'AduvoAdmin'"
              >
                ACTIVE
              </th>
              <!-- <th style="max-width:200px;min-width:200px;">ACTIONS</th> -->
              <th style="max-width: 100px; min-width: 100px"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-row
            let-record
            let-rowIndex="rowIndex"
            let-columns="columns"
          >
            <tr>
              <td *ngIf="currentClientID === 'Aduvo'" (click)="onPreview(row)">
                {{ record.title }}
              </td>
              <td
                *ngIf="currentClientID === 'AduvoAdmin'"
                (click)="onEdit(row)"
              >
                {{ record.title }}
              </td>
              <td *ngIf="record.templateTypeId === 1">
                <i class="fa-regular fa-envelope"></i>
              </td>
              <td *ngIf="record.templateTypeId === 2">
                <i class="fa-regular fa-comment-lines fa-fw"></i>
              </td>
              <td>{{ record.ownerFullName }}</td>
              <td class="p-body-colum" *ngIf="currentClientID === 'AduvoAdmin'">
                <p-inputSwitch
                  *ngIf="!row['canEdit']"
                  disabled="true"
                  class="disable-toggle"
                  [(ngModel)]="record.published"
                  (onChange)="onPublishChange(row, $event)"
                ></p-inputSwitch>
                <p-inputSwitch
                  *ngIf="row['canEdit']"
                  [(ngModel)]="record.published"
                  (onChange)="onPublishChange(row, $event)"
                ></p-inputSwitch>
              </td>
              <!-- <td>
                <button pButton pRipple type="button" *ngIf="row['canEdit']" label="Edit" (click)="onEdit(row)"
                  style="float: left" class="float-left"></button>
                <a (click)="onCopy(row)" class="p-ml-3"><i class="pi pi-clone mt-2"></i></a>
                <a *ngIf="row['canDelete']" class="p-ml-3" (click)="onDelete(row)"><i class="pi pi-trash"></i></a>
              </td> -->
              <td class="table-ellipse">
                <button
                  type="button"
                  aria-haspopup="true"
                  #ellipsis
                  class="p-column-filter-menu-button table-ellipse-button"
                  (focus)="ellipseMenu.hide()"
                  (click)="onEllipseClick($event, record)"
                >
                  <span class="pi pi-ellipsis-h"></span>
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No records found</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft" let-state>
            <label style="font-weight: 400"
              >{{ webAPI.paging.totalRecords }}
              {{ webAPI.paging.totalRecords > 1 ? "results" : "result" }}</label
            >
          </ng-template>
          <ng-template pTemplate="paginatorright" let-state>
            <div>
              <label
                style="margin-top: 1px; margin-right: 8px; font-weight: 400"
                >Go to Page</label
              >
              <input
                type="text"
                pInputText
                pKeyFilter="int"
                [(ngModel)]="webAPI.paging.currentPage"
                width="3"
                maxlength="3"
                style="
                  text-align: center;
                  width: 4rem;
                  margin-right: 8px;
                  border-radius: 20px;
                "
                (change)="onGotoPage()"
              />
            </div>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <!--
  *************************************************************************************
  Preview Dialog
  *************************************************************************************
  -->
  <p-dialog
    class="custom-dialog"
    #preview
    id="preview"
    [resizable]="false"
    (onHide)="onClosePreviewDialog()"
    [(visible)]="showPreviewDialog"
    [modal]="true"
    [baseZIndex]="10000"
    [style]="{ width: '768px', 'font-weight': '700' }"
  >
    <previewDialog-root
      #previewDialog
      [compiledEmail]="'Test body'"
      [templateWidth]="616"
      [templateHeight]="936"
    >
    </previewDialog-root>
    <p-header>
      <!-- <i class="pi pi-eye" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
      <span class="dialog-head">{{ messageTitle }}</span>
    </p-header>
    <!-- <ng-template pTemplate="footer">
      <div style="width: 100%; background: var(--light-gray); height: 1px;"></div>
      <div style="width: 100%; background: var(--very-light-gray); height: 1px;"></div>
      <div >
        <p-button (onClick)="onClosePreviewDialog()" style="color:#666666;cursor: pointer;margin: 0px 17.5px;text-decoration: underline;" styleClass="p-button-secondary"></p-button>
      </div>
    </ng-template> -->
  </p-dialog>

  <!--
  *************************************************************************************
  Text Message Preview Dialog
  *************************************************************************************
  -->
  <!-- <p-dialog class="custom-dialog" #textPreview [resizable]="false" (onHide)="onClosePreviewDialog()" [(visible)]="showTextPreviewDialog"
    [modal]="true" [baseZIndex]="10000" [style]="{ 'font-weight': '700' }">
    <textMessagePreviewDialog-root #textPreviewDialog [messageText]="selectedTemplate.body">
    </textMessagePreviewDialog-root>
    <p-header>
      <i class="pi pi-eye" style="font-size: 1.3rem; margin-right: 0.5rem"></i>
      <span class="dialog-head">Preview</span>
    </p-header>
    <ng-template pTemplate="footer">
      <div style="margin-top: 1.4rem">
        <p-button (click)="onClosePreviewDialog()" label="Cancel" styleClass="p-button-secondary"></p-button>
      </div>
    </ng-template>
  </p-dialog> -->

  <!--
*************************************************************************************
Data Validation Error Dialog
*************************************************************************************
-->
  <p-dialog
    class="pulseErrorDialog"
    position="right"
    [(visible)]="showErrors"
    [style]="{ width: '32em', 'font-weight': '700' }"
    [baseZIndex]="11000"
  >
    <p-header>
      <i
        class="pi pi-exclamation-circle"
        style="font-size: 1.3rem; margin-right: 0.5rem"
      ></i>
      <span class="dialog-head">Data Validation Errors</span>
    </p-header>
    <div style="width: 97.5%; background: var(--light-gray); height: 1px"></div>
    <div
      style="width: 97.5%; background: var(--very-light-gray); height: 1px"
    ></div>
    <div *ngIf="errors.length > 0">
      The information specified is not valid and cannot be saved.<br /><br />
      <p-table [value]="errors">
        <ng-template pTemplate="body" let-error>
          <tr>
            <td
              style="color: crimson; font-weight: bold; padding: 0.2rem 0.2rem"
            >
              {{ error }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <br />
      Please correct all information.
    </div>
    <div *ngIf="errors.length < 1">
      <br />
      <i class="pi pi-check" style="color: mediumseagreen"></i>
      <label style="color: mediumseagreen; padding-left: 0.5rem"
        >All Data is valid and may be saved.</label
      >
      <br />
    </div>
  </p-dialog>
</div>
<!--
*************************************************************************************
Make A Copy Dialogs
*************************************************************************************
-->
<app-make-a-copy
  #makeACopyDialog
  [display]="showMakeACopyDialog"
  (onCloseDialog)="onCloseMakeACopyDialog()"
  [selectedMessageTemplate]="selectedTemplateClone"
  (onSaveMakeACopyClick)="onSaveMakeACopy()"
  [title]="dialogTitle"
>
</app-make-a-copy>

<!--
*************************************************************************************
Confirm Dialogs
*************************************************************************************
-->
<p-confirmDialog
  header="Warning"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<stripo-root
  #templateEditor
  isEditorOpen="false"
  *ngIf="showEmailEditor"
  (onCloseBtnClick)="onClose('closedClicked')"
></stripo-root>

<!-- TEST SMS Template -->
<p-dialog
  class="custom-dialog"
  [resizable]="false"
  (onHide)="onCloseSendTestSMSDialog()"
  [(visible)]="showSendTestSMSDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [style]="{ width: '62.5em', 'font-weight': '700' }"
>
  <p-header>
    <!-- <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i> -->
    <span class="dialog-head">Send Test Text Message</span>
  </p-header>
  <div *ngIf="!smsSentSuccess">
    <table width="100%">
      <tr>
        <td valign="middle" class="div-label" width="10px">
          <span class="email-label">To Mobile Number</span>
        </td>
        <td valign="middle" class="div-text" width="200px">
          <input
            style="width: 60%"
            pInputText
            id="txtNumber"
            type="tel"
            [formControl]="toNumber"
            maxlength="20"
          />
        </td>
      </tr>
    </table>
  </div>
  <div class="isEmailResponse">
    <div
      class="success-message"
      style="text-align: center; font-weight: 400; color: green"
      *ngIf="smsSentSuccess"
    >
      <p>SMS was sent successfully!</p>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-center">
      <a
        (click)="onCloseSendTestSMSDialog()"
        style="
          color: #2e2e2e;
          cursor: pointer;
          margin: 0px 17.5px;
          text-decoration: underline;
          font-weight: 400;
        "
        styleClass="p-button-secondary"
        >Cancel</a
      >

      <p-button
        *ngIf="!smsSentSuccess"
        (onClick)="sendTestSMS()"
        [style]="{ 'margin-left': '17.5px' }"
        [disabled]="
          smsMsgBody?.length == 0 || !toNumber?.value || SMSInProgress
        "
        label="Send SMS"
      ></p-button>
      <p-button
        *ngIf="smsSentSuccess"
        (click)="sendTestSMSAnother()"
        label="Send Another"
        [style]="{ cursor: 'pointer', 'margin-left': '17.5px' }"
        styleClass="p-button-secondary"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
SMS Preview Dialog
*************************************************************************************
-->
<p-dialog
  class="custom-dialog"
  #preview
  id="smspreview"
  [resizable]="false"
  (onHide)="onClosePreviewDialog()"
  [(visible)]="showTextPreviewDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [style]="{ width: '800px', height: '640px', 'font-weight': '500' }"
>
  <p-header>
    <span class="dialog-head">{{ messageTitle }}</span>
  </p-header>

  <div class="flex-fill" style="padding: 0px !important">
    <div class="row">
      <div class="col-sm-5" style="background-color: #f5f5f5">
        <div style="padding: 50px">
          <ul class="list-group" style="border-radius: 0">
            <li class="list-group-item">
              <span class="dialog-head">Message Summary </span>
            </li>
            <li class="list-group-item">
              <span class="fade-txt">Message Category</span> <br />
              <span style="font-size: 16px">{{ messageCategory }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-7" style="padding: 50px">
        <div
          class="sms-preview-container"
          style="background-image: url(../../../assets/images/phone-frame.svg)"
        >
          <div class="sms-preview-block">
            <div
              class="sms-block"
              *ngFor="let smsBodyForm of smsMsgBody; last as isLast"
            >
              <span>{{ smsBodyForm }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
