<div style="margin: 15px 50px 10px 50px;">
    <p-table #tblMessageHistory
             [value]="webAPI.data.Items" class="pulseTable MessageHistory"
             [globalFilterFields]="globalFilterFields"
             [rows]="10" [rowsPerPageOptions]="[10,15,20,25,30,40,50,75,100]"
             [paginator]="webAPI.paging.totalRecords > 0" [showCurrentPageReport]="true"
             [totalRecords]="webAPI.paging.totalRecords"
             [loading]="loadingPage" [lazy]="true" (onLazyLoad)="onPageChanged($event)"
             [scrollable]="false" [virtualScroll]="false"
             (onFilter)="onFilter($event)">
    
      <ng-template pTemplate="body" let-row >
        <tr>
          <td class="listHistory">
            <div class="p-timeline-event ng-star-inserted">
                <div class="p-timeline-event-opposite" style="width: 10%;"></div>
                <div class="p-timeline-event-separator">
                    <span class="custom-marker shadow-2 timelineCardColor ng-star-inserted">
                        <i *ngIf="row['MessageTypeId'] === 0" ng-reflect-ng-class="fa-regular fa-envelope" class="fa-regular fa-envelope"></i>
                        <i *ngIf="row['MessageTypeId'] === 1" ng-reflect-ng-class="fa-regular fa-comment-lines fa-fw" class="fa-regular fa-comment-lines fa-fw"></i>
                    </span>
                    <div class="p-timeline-event-connector ng-star-inserted"></div>
                </div>
                <div class="p-timeline-event-content">
                    <div style="margin-top: 10px; margin-bottom: 10px;">
                        <div style="margin-top: 10px; margin-bottom: 10px;">
                            <span class="messageTitleName">{{row['MessageTitle']}} &nbsp;
                              <a [routerLink]="" (click)="onMessagePreviewClick($event)"><i class="pi pi-eye"></i></a>
                            </span>
                            <span class="contact-field-title" style="float: right;">Sent {{row['CreatedOnUtc'] | date:'MM/dd/YYYY'}} at {{row['CreatedOnUtc'] | date:'HH:MM aa'}}</span>
                        </div>
                        <div *ngIf="row['MessageTypeId'] === 0" class="contact-field-title">Subject Line </div>
                        <div *ngIf="row['MessageTypeId'] === 1" class="contact-field-title">Message </div>
                        <div class="campaignGridSubName" style="padding-top: 5px; padding-bottom: 10px;"> {{row['MessageSubject']}} </div>
                        <div style="margin: 10px 0;">
                            <table class="stats-sub-table">
                                <tr>
                                    <td *ngIf="row['MessageTypeId'] === 0">
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0 Opened</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Opened</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Clicked</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Bounced</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Opted Out</span>                                    
                                    </td>
                                    <td *ngIf="row['MessageTypeId'] === 1">
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Sent</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Delivered</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Blocked by Carrier</span>
                                        <span class="spanStats" style="padding: 5px; margin-right: 15px;">0% Sent to Bad Number</span>                                    
                                    </td>
                                  </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </td>
        </tr>
      </ng-template>
    
        <ng-template pTemplate="paginatorleft" let-state>
            <label style="font-weight:400;">{{webAPI.paging.totalRecords}}&nbsp;results</label>
        </ng-template>
        <ng-template pTemplate="paginatorright" let-state>
            <div>
                <label style="margin-top: 1px; margin-right:8px; font-weight:400;">Go to Page</label>
                <input type="text" pInputText pKeyFilter="int" [(ngModel)]="webAPI.paging.currentPage" width="3" maxlength="3"
                    style="text-align: center; width: 4rem; margin-right:8px; border-radius: 23px;" (change)="onGotoPage()">
                <!-- <button pButton pRipple type="button" iconPos="right" icon="pi pi-caret-right"
                    class="p-button-secondary-rounded" style="margin-right: 0px;" (click)="onGotoPage()"></button> -->
            </div>
        </ng-template>
    
    </p-table>
</div>

<!--
*************************************************************************************
Message Preview Dialog
*************************************************************************************
-->
<p-dialog id="messagePreviewDialog" 
    #messagePreviewDialog 
    [resizable]="false" 
    (onHide)="onCloseMessagePreviewDialog()" 
    [(visible)]="showMessagePreviewDialog" 
    [modal]="true" 
    [baseZIndex]="10000"
    [style]="{'width': '40em','font-weight': '700'}">
  <p-header>
  </p-header>
  <div style="border: 1px solid var(--light-gray); height: 300px;">
    <h2 style="text-align: center;">Message Preview</h2>
  </div>
  <ng-template pTemplate="footer">
  </ng-template>
</p-dialog>
