<p-dialog class="custom-dialog" #campaignPreviewDialog [resizable]="false" (onHide)="onCloseCampaignPreviewDialog()"
  [(visible)]="isVisible" [modal]="true" class="custom-dialog body-dialogContent"
  [style]="{ width: '80%', overflow: 'auto', 'z-index': '1000!important' }">
  <p-header class="hello">
    <span class="dialog-head">{{ campaignName }}</span>
  </p-header>
  <div id="divCampaignPreviewDialog" *ngIf="campaignId">
    <div class="row mx-0">
      <div class="col-4" style="background-color: #f5f5f5; padding: 50px 25px">
        <div class="row">
          <div class="col-6" style="display: flex; justify-content: left">
            <i *ngIf="templateTypeCnt == 2 || templateTypeCnt == 3"
              class="fa-regular fa-comment-lines fa-fw comment-icon" title="SMS/Text Messages"></i>
            &nbsp;&nbsp;
            <i *ngIf="templateTypeCnt == 1 || templateTypeCnt == 3" class="fa-regular fa-envelope envelope-icon"
              title="Email Messages"></i>
            &nbsp;&nbsp;

            <span>{{ totalCount }} Message{{ totalCount > 1 ? "s" : "" }}</span>
          </div>
          <div class="col-6" style="display: flex; justify-content: right; padding-right: 10px">
            <span class="font-weight-bold">
              {{ campaignIsActive ? "Active" : "Inactive" }}&nbsp;</span>
            <!-- <p-inputSwitch id="activate" class="inputSwithActive" (onChange)="onSwitchUnPublished()"
              [(ngModel)]="campaignIsActive">
            </p-inputSwitch> -->
            <ng-container *ngIf="
                isCompanyUserWithMarketing &&
                  activationMode == 'admin-mandatory';
                else showPublishDiv
              ">
              <p-inputSwitch disabled id="inputSwithActive" class="inputSwithActive" [(ngModel)]="campaignIsActive">
              </p-inputSwitch>
            </ng-container>
            <ng-template #showPublishDiv>
              <p-inputSwitch *ngIf="isCampaign" id="activate" class="inputSwithActive"
                (onChange)="onSwitchUnPublished()" [(ngModel)]="campaignIsActive">
              </p-inputSwitch>
            </ng-template>
          </div>
        </div>
        <app-campaign-detail-block (notifyParent)="openShowDetailsDialog($event)"
          [campaignDetail]="campaignBlockDetail"></app-campaign-detail-block>
      </div>
      <div class="col-8 modal-right-panel">
        <div class="row" *ngIf="totalCount > 1">
          <div class="col-3 pl-0" style="justify-content: left">
            <a href="javascript:void(0)" *ngIf="isLoading" style="margin-top: 16px; color: gray"
              class="ancher-links">Prev Message</a>
            <a href="javascript:void(0)" *ngIf="!isLoading" style="margin-top: 16px"
              (click)="onChangePrevMessage($event)" class="ancher-links linkprev"><i class="fa fa-long-arrow-left"
                aria-hidden="true"></i> Prev
              Message</a>
          </div>
          <div class="col-6" *ngIf="totalCount" style="display: flex; justify-content: center">
            Message {{ currentMessageIndex }} of
            {{ totalCount }}
          </div>
          <div class="col-6" *ngIf="!totalCount" style="display: flex; justify-content: center">
            Message 0 of 0
          </div>
          <div class="col-3" style="text-align: right">
            <a href="javascript:void(0)" *ngIf="isLoading" style="margin-top: 16px; color: gray"
              class="ancher-links">Next Message</a>
            <a href="javascript:void(0)" *ngIf="!isLoading" style="margin-top: 16px"
              (click)="onChangeNextMessage($event)" class="ancher-links linkprev">Next Message
              <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
          </div>
        </div>
        <!-- *ngIf="!isLoading"  -->
        <div class="row" style="padding-top: 10px">
          <div class="col" *ngIf="totalCount">
            <ng-container *ngIf="
                campaignIDs[currentMessageIndex - 1].templateTypeId == 1;
                else comp2
              ">
              <div class="row">
                <div class="col auto-header hd-top">
                  {{ campaignIDs[currentMessageIndex - 1].title }}
                </div>
              </div>

              <div class="row">
                <div class="col pl-0">
                  <span class="subject-text">Subject Line: </span>
                  {{ campaignIDs[currentMessageIndex - 1].emailSubject }}
                </div>
              </div>
              <div class="row">
                <div class="col pl-0">
                  <span class="subject-text">Preview Text:</span>
                  {{ campaignIDs[currentMessageIndex - 1].preHeaderText }}
                </div>
              </div>
              <div class="row">
                <div class="col auto-header">
                  <span class="subject-text">Schedule: </span>
                  <ng-container *ngIf="
                      !campaignIDs[currentMessageIndex - 1]
                        ?.quickPickStatusFieldText;
                      else ScheduleDiv
                    ">
                    (No Schedule)
                  </ng-container>
                  <ng-template #ScheduleDiv>
                    <p [innerHTML]="
                        campaignIDs[currentMessageIndex - 1]
                          .quickPickStatusFieldText
                      "></p>
                  </ng-template>
                </div>
              </div>
              <div class="row content">
                <div id="previewDialogDiv" class="content" role="main" style="margin: 0.5rem">
                  <iframe #iframePreview name="iframePreview"
                    onload="this.style.height=(this.contentDocument.body.scrollHeight) +'px';"
                    style="overflow: hidden; height: 100%; width: 100%"></iframe>
                </div>
              </div>
            </ng-container>
            <ng-template #comp2>
              <div class="row">
                <div class="col auto-header hd-top">
                  {{ campaignIDs[currentMessageIndex - 1].title }}
                </div>
              </div>

              <div class="row" id="msgSchedule">
                <div class="col auto-header">
                  <strong>Schedule: </strong>
                  <ng-container *ngIf="
                      !campaignIDs[currentMessageIndex - 1]
                        ?.quickPickStatusFieldText;
                      else ScheduleDiv
                    ">
                    (No Schedule)
                  </ng-container>
                  <ng-template #ScheduleDiv>
                    <p [innerHTML]="
                        campaignIDs[currentMessageIndex - 1]
                          .quickPickStatusFieldText
                      "></p>
                  </ng-template>
                </div>
              </div>

              <div class="row">
                <div id="smsPrev" class="sms-preview-container" style="
                    background-image: url(../../../assets/images/phone-frame.svg);
                  ">
                  <div class="sms-preview-block">
                    <div class="sms-block" *ngFor="
                        let smsBodyForm of campaignIDs[currentMessageIndex - 1]
                          ?.body
                      ">
                      <span>{{ smsBodyForm }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div *ngIf="!totalCount" style="display: flex; justify-content: center">
            No Messages(s) available
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end align-items-center footermodal">
      <a href="javascript:void(0)" (click)="onCloseCampaignPreviewDialog()" style="
          color: #2e2e2e;
          cursor: pointer;
          margin-right: 17.5px;
          text-decoration: underline;
          font-weight: 400;
        " styleClass="p-button-secondary">Cancel</a>
      <p-button *ngIf="!campaignBlockDetail.isPublishCampaign" (onClick)="onCustomize()"
        [style]="{ 'margin-left': '17.5px' }" label="Customize This Campaign">
      </p-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="isLoading" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>

<!--Validation Campaign dialog-->
<p-dialog class="custom-alert" #validateCampaignDialog [(visible)]="showValidateCampaignDialog" [resizable]="false"
  [modal]="true" [style]="{ 'font-size': '16px', padding: '0px !important' }">
  <!-- span changed to div -->
  <div>
    <span class="fw-bold mb-4" style="font-size: 20px">The following are incomplete and required to activate this
      campaign:
    </span>
    <ul [innerHTML]="validationString" style="padding: 2% 8%"></ul>
  </div>
  <div class=""></div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left d-flex">
      <p-button class="pt-2" (click)="showValidateCampaignDialog = false" [style]="{ 'margin-right': '17.5px' }"
        label="Close" styleClass="p-button-rounded">
      </p-button>
    </div>
  </ng-template>
</p-dialog>

<!--
*************************************************************************************
Activate Dialog
*************************************************************************************
-->
<p-dialog class="custom-alert custom-modal" id="activateDialog" class="custom-alert custom-modal"
  #activateDialog [resizable]="false" [(visible)]="showactivateDialog" [modal]="true"
  [style]="{'width': '52.5em','font-weight': '700'}" (onHide)="onCancelActivateDialog()">
  <p-header>
  </p-header>
  <div class="pb-25">
    <p class="confirm-sms">Please confirm you want to activate {{campaignName}} </p>
  </div>
  <div class="pb-35" *ngIf="userRoleFoundInToRecipientList">{{ userRoleNameFoundInToRecipientListMessage }}</div>
  <div class="d-flex align-items-center">
    <p-button [style]="{'margin-right': '17.5px'}" (onClick)="onActivateDialog()"
      label="Yes, I want to activate">
    </p-button>
    <div>
      <p-button (click)="onCancelActivateDialog()" label="Cancel" styleClass="p-button-secondary"></p-button>
    </div>
  </div>
</p-dialog>

<!--
*************************************************************************************
Test Mode Alert Dialogs 
*************************************************************************************
-->
<p-dialog class="custom-alert custom-user-alert" [(visible)]="showTestModeDialog" [resizable]="false" [modal]="true"
  [style]="{ width: '52.5em', 'font-weight': '700'}">
  <div class="pb-25">
    <span class="alert-header">Your campaign has been activated, but you're currently in TEST mode.</span>
  </div>
  <div class="pb-35">
    <span class="user-menu font-weight-normal" style="max-height:150px; overflow-y: auto;">
      At this time, messages will be sent to loan officer only. To begin sending to your contacts, change the 
      Marketing Mode switch in your company profile from Test to Live.
    </span>
  </div>
  <ng-template pTemplate="footer text-left">
    <div class="col text-left">
      <p-button [style]="{ 'margin-right': '17.5px' }" class="" (click)="GoToCompanyProfile()"
        label="Go To Company Profile" styleClass="p-button-rounded"></p-button>
      <p-button [style]="{ 'margin-left': '17.5px' }" (click)="showTestModeDialog = false" label="Ok"
        styleClass="p-button-secondary"></p-button>
    </div>
  </ng-template>
</p-dialog>