import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RequestResponse } from 'src/models/RequestResponse'
import { AppSettings } from 'src/appSettings';
import * as internal from 'events';

@Injectable()
export class UserAnalyticsService {
  constructor(private http: HttpClient) { }

  /**
   * Retrieves all necessary data for this component. 
   */
  getData(requestResponse?: RequestResponse) {
    // Angular does not support posting a body inside the /GET verb.
    // https://github.com/angular/angular/issues/9927

    if ((requestResponse === undefined) || (requestResponse === null))
      requestResponse = new RequestResponse();

    console.info("Call /POST in the webapi Contacts Controller");
    return this.http.post<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS, requestResponse)
      .pipe(catchError(this.handleError));
  }

  getBirthdaysThisWeek() {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/birthdaythisweek',)
      .pipe(catchError(this.handleError));
  }

  getLoanAniversariesThisWeek() {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/loananniversarythisweek',)
      .pipe(catchError(this.handleError));
  }

  getActiveLeads(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/activeLeads?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getActiveLoans(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/activeLoans?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getConvertedFromLeadToLoan(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/convertedFromLeadToLoan?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getApplicationPhase(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/applicationPhase?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getCancelledLoans(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/cancelledLoans?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getClosedLoans(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/closedLoans?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getRefiLoans(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/refiLoans?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getPurchaseLoans(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/purchasedLoans?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  getOtherLoans(timeFrame: number) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/otherLoans?dateRange=' + timeFrame,)
      .pipe(catchError(this.handleError));
  }

  // getCampaignStats(campaignId: string,campaignMessageType: number, timeFrame: number = 5) {
  //   return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/campaignstats?campaignId=' + campaignId + '&campaignMessageType=' + campaignMessageType)
  //     .pipe(catchError(this.handleError));
  // }

  getCampaignStats(campaignId: string,campaignMessageType: number, timeFrame: number = 5): Observable<RequestResponse> {    
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/campaignstats?campaignId=' + campaignId + '&campaignMessageType=' + campaignMessageType)
      .pipe(catchError(this.handleError));
  }

  getCampaignMessageStats(campaignMessageId: string, isEmail: boolean, timeFrame: number = 5) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/campaignmessagestats?campaignmessageId=' + campaignMessageId + '&isEmail=' + (isEmail ? 'true' : 'false')) //+ '&dateRange=' + timeFrame)
      .pipe(catchError(this.handleError));
  }

  getMarketingStats(timeFrame: number = 5) {
    return this.http.get<RequestResponse>(AppSettings.API_GET_DASHBOARD_ANALYTICS + '/marketingstats?dateRange=' + timeFrame)
      .pipe(catchError(this.handleError));
  }

  /**
  * Error processing.
  * 
  * @param caught The caught error information.
  */
  private handleError(caught: any) {
    if (caught) {
      if (caught.error) {
        console.error(caught.error.message);
        return throwError(caught.error.message);
      }
    }
    if (caught) {
      return throwError(caught);
    }
  }
  
}  
