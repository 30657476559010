export class ImportModel {
  constructor() {
    this.tabIndex = 0;

    this.pageTitle = [
      'Import Contacts & Loans',
      'Import Contacts & Loans',
      '',
      'Add tags to the contacts of this list',
      ''];
    this.pageDesc = [
      'Start with an import template:',
      'Map the fields in your Excel file to Aduvo fields:',
      '',
      ''
    ];
    this.aduvoFields = [];
    this.showPreview = false;
    this.mappedJsonResponse = new Array<MappedJsonResponse>();
    this.separatorExp = /,| /;
    this.masterAduvoFields = [];
    this.unMappedHeaderArr = [];
  }

  public tabIndex: number;
  public pageTitle: string[];
  public pageDesc: string[];
  public aduvoFields: AduvoFieldModel[];
  public showPreview: boolean;
  public mappedJsonResponse: Array<MappedJsonResponse>;
  public separatorExp: RegExp;
  public masterAduvoFields: AduvoFieldModel[];
  public unMappedHeaderArr: string[];

}

export class AduvoFieldModel {
  constructor() {

  }
  // constructor(public aduvoFieldID: string, public fileType: string, public fieldName: string,
  //   public isMandatory: string, public fieldHeaderName: string, public validationJSON: string) {
  // }
  public aduvoFieldID: string;
  public fileType: string;
  public fieldName: string;
  public isMandatory: string;
  public fieldHeaderNameToMap: string;
  public validationJSON: string;
}


export class ImportSaveModel {
  constructor() {
    this.uploadedExcelFile = null;
    this.confirmFlag = false;
  }

  public uploadedExcelFile: any;
  public confirmFlag: boolean;
}
//response from uploaded excel.
export class ImportResponse {
  constructor() {
    this.importBatchId = null;
    this.tags = [];
  }

  public activityId: string;
  public importBatchId: string;
  public uploadedFileURL: string;
  public headerJSON: string; //excelColumnFields
  public importType: string; //importType
  public rowCount: string; //rowCount
  public tags: string[]; //tags
  public defaultCompanyUserId: string;
}

export class MappedJsonResponse {
  // constructor(public ExcelColumnHeader: string, public AduvoField: string, public FieldHeaderNameToMap: string, public ExcelColumn: number = 0) {
  // }
  constructor(public ExcelColumnHeader: string, public AduvoField: string, public FieldHeaderNameToMap: string, public ExcelColumn: number = 0) {
  }
}


