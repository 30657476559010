import { FilterTypes } from "../enums/filter-types";

export interface ISearchModel {
    campaignId?: string;
    pageNum: number;
    pageSize: number;
    totalRecords?: number;
    filterStr?: string;
    searchStr?: string;
    sortColumn?: string;
    sortOrder?: string;
}

export class SearchModel implements ISearchModel {
    campaignId: string;
    pageNum: number;
    pageSize: number;
    totalRecords?: number;
    filterStr?: string;
    searchStr?: string;
    sortColumn?: string;
    sortOrder?: string;
    constructor(data: ISearchModel) {
        this.campaignId = data.campaignId ?? data.campaignId;
        this.pageNum = data.pageNum;
        this.pageSize = data.pageSize;
        this.totalRecords = data.totalRecords;
        this.filterStr = data.filterStr;
        this.sortColumn = data.sortColumn;
        this.sortOrder = data.sortOrder;
        this.searchStr = data.searchStr;
    }

    get maxPage() {
        const maxPageNumber = Math.ceil(this.totalRecords / this.pageSize);

        return maxPageNumber;
    }

    set maxPage(value) {
        return;
    }

    generateSearchString(columns: Array<any>) {

        const columnsWithFilters = columns.filter((column) => column.filterValues.length > 0);

        let filters = columnsWithFilters.reduce((acc, column) => {
            
            if (column.filterType === FilterTypes.ENUM) {
                acc[column.field] = column.filterValues.map((filterValue) => filterValue.value).join(',');
                return acc;
            }

            acc[column.field] = column.filterValues.join(',');
            return acc;

        }, {});

        return JSON.stringify(filters);
    }

    formatDate(date: Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based in JavaScript
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const milliseconds = Math.floor(date.getMilliseconds() / 10).toString().padStart(2, '0'); // Get first 2 digits of milliseconds
  
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }


}