import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation, 
  Inject
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ToastService } from 'src/services/toast.service';
import { MessageTemplateEditorState } from '../../../models/state/messageTemplateEditor.state';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { MessageTemplate } from '../../../models/MessageTemplate';
import { stripoComponent } from './stripo.component';
import { textMessageEditorComponent } from './textMessageEditor.component';
import { ActivatedRoute, Router } from '@angular/router';

declare var ExternalMergeTags: any;
import { ExternalMergeTags } from 'src/scripts/mergeTags';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MergeTagsService } from 'src/services/mergeTags.service';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
import { LookupService } from 'src/services/lookup.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { StorageHelper } from 'src/helpers/StorageHelper';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'messageTemplateEditor-root',
  templateUrl: './messageTemplateEditor.component.html',
  styleUrls: ['./messageTemplateEditor.component.scss'],
  providers: [DynamicDialogRef],
})
export class messageTemplateEditorComponent {
  messageCategoryList: any;
  showCancelEdit = false;
  currentClientID: string;
  private previousUrl: string;
  public urlState: string;

  constructor(
    private mergeTagsService: MergeTagsService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private messageTemplatesService: MessageTemplatesService,
    public dialogService: DialogService,
    private lookupService: LookupService,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    this.previousUrl = '';
    if (this.router.getCurrentNavigation().extras.state) {
      this.urlState = this.router.getCurrentNavigation().extras.state.Mode;
    }
  }

  @Output() onBackBtnClick: EventEmitter<any> = new EventEmitter<any>(true);

  @ViewChild('templateEditor', { static: false })
  templateEditor: stripoComponent;
  @ViewChild('textMessageEditor', { static: false })
  textMessageEditor: textMessageEditorComponent;
  @ViewChild('txtTemplateEmailSubject', { static: false })
  txtTemplateEmailSubject: HTMLTextAreaElement;
  @ViewChild('txtTemplateEmailPreviewText', { static: false })
  txtTemplateEmailPreviewText: HTMLTextAreaElement;

  campaignNavigationData: any;
  operationType: string;
  loadingPage: boolean = false;

  // Component state
  editorState: MessageTemplateEditorState = new MessageTemplateEditorState();
  showEmailEditor: boolean = false;
  showTextMessageEditor: boolean = false;
  showTestEmailWizardVisible: boolean = false;
  templateId: string;
  autoSave: boolean = false;
  isFirstStepVisible = true;
  isSecondStepVisible = false;

  items: MenuItem[] = [{ label: 'Appearance' }, { label: 'Content' }];
  activeItem: MenuItem;

  isFormSubmitted = false;
  validationError = {
    messageName: false,
    subjectLine: false,
    messageCategory: false,
  };
  buildYourOwn = false;
  campaignId = '';
  buildYourOwnCancelUrl = '';
  changeCampaignMessageID: string = '';
  originalTitle: string;
  originalSubject: string;
  originalPreHeader: string;
  originalCategory: string;
  storageHelper: StorageHelper;

  async ngOnInit(): Promise<void> {

    this.storageHelper = new StorageHelper();
    let backURL = this.storageHelper.GetDataFromStorage(
      'contentLibrarybackURL'
    );//fetch 
    if (backURL?.value) {
      if (this.previousUrl == '') {
        this.previousUrl = backURL?.value;
      }
      //console.log('backURL?.value:' + backURL?.value);
    }

    let currentUrl = this.router?.url;
    if (currentUrl) {
      this.storageHelper.ClearStorageByKey("currentURL");
      this.storageHelper.SetDataInStorage("currentURL", currentUrl)
    }

    this.currentClientID = environment.clientId;
    this.activatedRoute.queryParams.subscribe((data) => {
      this.campaignNavigationData = data;
      this.campaignId = '';
      this.changeCampaignMessageID = '';
      if (this.campaignNavigationData.type === 'build') {
        this.buildYourOwn = true;
        this.campaignId = data?.id ?? '';
        this.changeCampaignMessageID = data?.change ?? '';
        if (this.campaignId) {
          this.buildYourOwnCancelUrl =
            '/marketing/campaigns/edit/' + this.campaignId + '/default';
        }
        this.showEmailEditor = false;
        this.showTextMessageEditor = false;
        this.createEmailMessageDesign();
      }
    });

    this.operationType = this.activatedRoute.snapshot.data.operation;
    if (this.operationType) {
      this.showEmailEditor = false;
      this.showTextMessageEditor = false;
      if (this.operationType === 'email') {
        await this.createEmailMessageDesign();
      } else if (this.operationType === 'sms') {
        await this.createTextMessageDesign();
      }
    }

    this.activatedRoute.paramMap.subscribe(async (params) => {
      if (params) {
        const id = params.get('id');
        if (id === 'email') {
          this.operationType = 'email';
          await this.createEmailMessageDesign();
        } else if (id === 'sms') {
          this.operationType = 'sms';
          await this.createTextMessageDesign();
        } else {
          this.templateId = id ?? '';
          if (this.templateId) {
            await this.getData();
          }
        }
      }
    });

    // await Promise.all([
    await this.getMergeTagsData();
    await this.getLast12AndPopularMergeTagsData();

    this.lookupService.GetCampaignCategories().subscribe(
      (result) => {
        if (result && result.data) {
          this.messageCategoryList = result.data.map((obj) => {
            return {
              item_id: obj.categoryId,
              item_text: obj.categoryName,
            };
          });
        }
      },
      (error) => {
        this.toastService.showError(error);
      }
    );

    // this.lookupService.GetCampaignCategories().subscribe(result => {
    //   if (result && result.data) {
    //     this.messageCategoryList = result.data.map((obj) => {
    //       return {
    //         item_id: obj.categoryId, item_text: obj.categoryName
    //       };
    //     });
    //   }
    // }, error => {
    //   this.toastService.showError(error);
    // });
  }

  async getData(): Promise<void> {
    try {
      this.loadingPage = true;
      const template = (
        await this.messageTemplatesService.GetTemplateById(this.templateId)
      )?.data;
      await this.loadTemplate(template);
    } catch (exception) {
      this.toastService.showError(exception);
    } finally {
      this.loadingPage = false;
    }
  }

  async createEmailMessageDesign() {
    const template = new MessageTemplate();
    template.templateTypeId = 1;
    //template.categoryDescription = 'Uncategorized';
    await this.loadTemplate(template);
    // give time for the Stripo Template View to clear;
    this.showEmailEditor = true;
    this.showTextMessageEditor = false;
  }

  async createTextMessageDesign() {
    const template = new MessageTemplate();
    template.templateTypeId = 2;
    //template.categoryDescription = 'Uncategorized';

    this.showEmailEditor = false;
    this.showTextMessageEditor = true;
    await this.loadTemplate(template);
  }

  async loadTemplate(template: MessageTemplate) {
    if (this.editorState.loadingPage) {
      return;
    }

    var result: any;
    this.editorState.messageTemplate = template;
    this.editorState.restorationTemplate = JSON.parse(JSON.stringify(template)); // must be a copy
    this.originalTitle = template.title;
    this.originalSubject = template.emailSubject;
    this.originalPreHeader = template.preHeaderText;
    this.originalCategory = template.categoryId;

    if (template.templateTypeId === 1) {
      this.showTextMessageEditor = false;
      this.showEmailEditor = true;
      setTimeout(async () => {
        result = await this.templateEditor.loadTemplate(template);
      }, 100);
    } else if (template.templateTypeId === 2) {
      this.showEmailEditor = false;
      this.showTextMessageEditor = true;
      setTimeout(
        async () =>
          (result = await this.textMessageEditor.loadTemplate(template)),
        1000
      );
    } else {
      this.toastService.showError('Invalid template type');
    }

    return result;
  }

  realTimeValidate() {
  }

  onBack(event: any) {
    if (this.editorState.messageTemplate.templateTypeId === 1) {
      this.editorState.refreshRequired =
        this.templateEditor.editorState.refreshRequired;
    } else {
      this.editorState.refreshRequired =
        this.textMessageEditor.editorState.refreshRequired;
    }

    this.onBackBtnClick.emit(event);

    setTimeout(() => {
      this.editorState.refreshRequired = false;
      this.templateEditor.editorState.refreshRequired = false;
      this.textMessageEditor.editorState.refreshRequired = false;
    }, 1000);
  }

  async onRestoreBtnClick() {
    this.editorState.showErrors = false;
    setTimeout(() => {
      if (this.editorState.messageTemplate.templateTypeId === 1) {
        this.editorState.messageTemplate =
          this.templateEditor.editorState.restorationTemplate;
        this.templateEditor.onRestoreBtnClick();
      } else {
        this.editorState.messageTemplate =
          this.textMessageEditor.editorState.restorationTemplate;
        this.textMessageEditor.onRestoreBtnClick();
      }
      // tslint:disable-next-line:max-line-length
      // this.editorState.selectedCategory = this.editorState.categories.find(x => x.name === this.editorState.messageTemplate.categoryDescription);
    }, 10);
  }

  async onSendTestBtnClick() {
    this.templateEditor.editorState.messageTemplate.title =
      this.editorState.messageTemplate.title;
    this.templateEditor.editorState.messageTemplate.emailSubject =
      this.editorState.messageTemplate.emailSubject;
    this.templateEditor.editorState.messageTemplate.preHeaderText =
      this.editorState.messageTemplate.preHeaderText;
    if (this.currentClientID === 'AduvoAdmin')
      this.templateEditor.editorState.messageTemplate.published =
        this.editorState.messageTemplate.published;
    else this.templateEditor.editorState.messageTemplate.published = true;
    this.templateEditor.openSendEmailDialoge();
    this.templateEditor.editorState.showSendTestEmailDialog = true;
  }

  async getMergeTagsData() {
    if (
      ExternalMergeTags.allTags === undefined ||
      ExternalMergeTags.allTags.length === 0
    ) {
      const mergeTagData = await this.mergeTagsService.getMergeTagsData(
        true,
        true,
        true,
        false
      );
      ExternalMergeTags.allTags = mergeTagData.data;
    }
  }

  async getLast12AndPopularMergeTagsData() {
    let popularMergeTagData = (
      await this.mergeTagsService.getPopularMergeTagsData(
        true,
        true,
        true,
        false
      )
    ).data;
    //ExternalMergeTags.popularTags = await this.mergeTagsService.getFinalMergeTagsToDisplay(popularMergeTagData);
    this.mergeTagsService
      .getFinalMergeTagsToDisplay(popularMergeTagData)
      .then((x) => {
        ExternalMergeTags.changePopularTags(x);
      });
  }

  async onMergeTagRequested() {
    this.textMessageEditor.onMergeTagRequested();
  }

  async onEmailSubjectMergeTagRequested() {
    ExternalMergeTags.open(this.onEmailSubjectMergeTagSelected.bind(this));
  }

  async onPreviewTextMergeTagRequested() {
    ExternalMergeTags.open(this.onPreviewTextMergeTagSelected.bind(this));
  }

  onEmailSubjectMergeTagSelected(tagVal) {
    const control: any = this.txtTemplateEmailSubject;
    let temp: any = '' + (this.editorState.messageTemplate.emailSubject ?? '');

    let caretPos = control.nativeElement.selectionStart;
    caretPos = caretPos + tagVal.length;
    const prefix = temp.substr(0, control.nativeElement.selectionStart);
    const suffix = temp.substr(control.nativeElement.selectionEnd);
    temp = prefix + tagVal + suffix;

    control.nativeElement.focus();

    if (temp.length > 459) {
      return;
    }

    control.nativeElement.value = temp;
    this.editorState.messageTemplate.emailSubject = temp;

    control.nativeElement.selectionStart = caretPos;
    control.nativeElement.selectionEnd = caretPos;

    this.mergeTagsService.updateLastUsedMergeTag(tagVal);
  }

  onPreviewTextMergeTagSelected(tagVal) {
    const control: any = this.txtTemplateEmailPreviewText;
    let temp: any = '' + (this.editorState.messageTemplate.preHeaderText ?? '');

    let caretPos = control.nativeElement.selectionStart;
    caretPos = caretPos + tagVal.length;
    const prefix = temp.substr(0, control.nativeElement.selectionStart);
    const suffix = temp.substr(control.nativeElement.selectionEnd);
    temp = prefix + tagVal + suffix;

    control.nativeElement.focus();

    if (temp.length > 459) {
      return;
    }

    control.nativeElement.value = temp;
    this.editorState.messageTemplate.preHeaderText = temp;

    control.nativeElement.selectionStart = caretPos;
    control.nativeElement.selectionEnd = caretPos;

    this.mergeTagsService.updateLastUsedMergeTag(tagVal);
  }

  saveStatusChanged(saveStatus: boolean) {
    if (saveStatus) {
      if (
        (this.editorState.messageTemplate.templateId == null ||
          this.editorState.messageTemplate.templateId ==
          '00000000-0000-0000-0000-000000000000') &&
        this.templateEditor.editorState.messageTemplate.templateId != null &&
        this.templateEditor.editorState.messageTemplate.templateId !=
        '00000000-0000-0000-0000-000000000000'
      )
        this.editorState.messageTemplate.templateId =
          this.templateEditor.editorState.messageTemplate.templateId;
    } else {
      this.editorState.messageTemplate.published =
        this.templateEditor.editorState.messageTemplate.published;
    }
  }

  async onPublishChange(messageTemplate: MessageTemplate) {
    if (this.editorState.errors.length > 0) {
      this.editorState.showErrors = true;
      this.templateEditor.isPublishedClicked = false;
      if (messageTemplate.published) {
        setTimeout(() => (messageTemplate.published = false), 0);
        return false;
      }
    }
    this.editorState.showErrors = false;
    this.templateEditor.isPublishedClicked = true;

    if (
      this.templateEditor.editorState.messageTemplate.templateId != null &&
      this.templateEditor.editorState.messageTemplate.templateId !=
      '00000000-0000-0000-0000-000000000000'
    )
      this.editorState.messageTemplate.templateId =
        this.templateEditor.editorState.messageTemplate.templateId;

    if (
      this.editorState.messageTemplate.templateId != null &&
      this.editorState.messageTemplate.templateId !=
      '00000000-0000-0000-0000-000000000000'
    ) {
      // old template and is being published
      if (this.editorState.messageTemplate.published) {
        const ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              'Please confirm you want to activate template ' +
              messageTemplate.title,
            successButtonText: 'Yes, I want to activate',
            cancelButtonText: 'Continue Editing',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.messageTemplatesService
              .setPublished(
                messageTemplate.templateId,
                messageTemplate.published
              )
              .subscribe((response) => {
                if (response.status == 200) {
                  this.toastService.showSuccess(
                    messageTemplate.published
                      ? 'Template Activated'
                      : 'Template Deactivated'
                  );

                  //publish then save the template
                  this.templateEditor.editorState.haveTemplate = false;
                  this.templateEditor.editorState.haveCompiledEmail = false;
                  this.templateEditor.editorState.haveEmail = false;
                  this.templateEditor.editorState.loadingPage = true;
                  this.templateEditor.editorState.saving = true;
                  this.templateEditor.editorState.showErrors = false;
                  this.templateEditor.editorState.messageTemplate.published = messageTemplate.published;

                  this.templateEditor.getTemplate();
                  this.templateEditor.getCompiledEmail();

                } else {
                  this.toastService.showError(
                    (messageTemplate.published
                      ? 'Error Activating! '
                      : 'Error Deactivating! ') + response.message
                  );
                  messageTemplate.published = !messageTemplate.published;
                  this.templateEditor.isPublishedClicked = false;
                  return false;
                }
              });
          } else {
            messageTemplate.published = false;
            this.templateEditor.isPublishedClicked = false;
          }
        });
      } else {
        this.messageTemplatesService
          .setPublished(messageTemplate.templateId, messageTemplate.published)
          .subscribe((response) => {
            if (response.status === 200) {
              this.toastService.showSuccess(
                messageTemplate.published
                  ? 'Template Activated'
                  : 'Template Deactivated'
              );
            } else {
              this.toastService.showError(
                (messageTemplate.published
                  ? 'Error Activating! '
                  : 'Error Deactivating! ') + response.message
              );
              messageTemplate.published = !messageTemplate.published;
            }
          });
        this.templateEditor.isPublishedClicked = false;
      }
    } else {
      // Auto save and publish template
      this.autoSave = true;
      this.onSave();
    }
  }

  async onSubmitStepFirst() {
    this.isFormSubmitted = true;
    this.validationError.messageName =
      this.editorState.messageTemplate.title.trim() === '';
    this.validationError.subjectLine =
      this.editorState.messageTemplate.emailSubject.trim() === '';
    this.validationError.messageCategory =
      !this.editorState.messageTemplate.categoryId;
    if (
      this.validationError.messageName ||
      this.validationError.subjectLine ||
      this.validationError.messageCategory
    ) {
      return;
    }
    this.isFirstStepVisible = false;
    this.isSecondStepVisible = true;
    this.document.body.classList.add('stripofixed');
    // setTimeout(() => {
    //     var iframe = this.document.querySelector('iframe.stripo-preview-frame');
    //     if (iframe && !iframe.classList.contains('stripo-preview-frame-full'))
    //       iframe.classList.add('stripo-preview-frame-full');
    // }, 1000);
  }

  onCodeEditorClicked() {

  }

  async gotoStep1() {
    this.isFirstStepVisible = true;
    this.isSecondStepVisible = false
    this.document.body.classList.remove('stripofixed');
  }

  emojiClicked($event: any) {
    this.onEmailSubjectMergeTagSelected($event.emoji.native);
  }

  emojiClickedPreview($event: any) {
    this.onPreviewTextMergeTagSelected($event.emoji.native);
  }


  async onSave() {
    
    if (this.editorState.errors.length > 0) {
      if (this.templateEditor.isPublishedClicked) {
        this.editorState.messageTemplate.published = false;
        this.templateEditor.isPublishedClicked = false;
      }
      this.editorState.showErrors = true;
      return;
    }

    this.editorState.showErrors = false;

    if (this.editorState.messageTemplate.templateTypeId === 1) {
      this.templateEditor.editorState.messageTemplate.categoryId =
        this.editorState.messageTemplate.categoryId;

      this.templateEditor.editorState.messageTemplate.categoryDescription =
        'Uncategorized';
      this.messageCategoryList.forEach((categories) => {
        if (this.editorState.messageTemplate.categoryId == categories.item_id) {
          this.templateEditor.editorState.messageTemplate.categoryDescription =
            categories.item_text;
        }
      });

      this.templateEditor.editorState.messageTemplate.title =
        this.editorState.messageTemplate.title;
      this.templateEditor.editorState.messageTemplate.description =
        this.editorState.messageTemplate.description;
      this.templateEditor.editorState.messageTemplate.emailSubject =
        this.editorState.messageTemplate.emailSubject;
      this.templateEditor.editorState.messageTemplate.preHeaderText =
        this.editorState.messageTemplate.preHeaderText;
      this.templateEditor.editorState.messageTemplate.isQuickStart =
        this.editorState.messageTemplate.isQuickStart;
      this.templateEditor.editorState.messageTemplate.isRecommended =
        this.editorState.messageTemplate.isRecommended;
      //changed as published by default
      if (this.currentClientID === 'AduvoAdmin')
        this.templateEditor.editorState.messageTemplate.published =
          this.editorState.messageTemplate.published;
      else this.templateEditor.editorState.messageTemplate.published = true;
      this.templateEditor.buildYourOwnCampaignID = this.campaignId ?? '';
      this.templateEditor.changeCampaignMessageID =
        this.changeCampaignMessageID ?? '';

      var ref;
      if (this.autoSave) {
        //Publish Template and Auto Save
        this.autoSave = false;
        ref = this.dialogService.open(ConfirmModalComponent, {
          width: '650px',
          contentStyle: { 'max-height': '500px', overflow: 'auto' },
          styleClass: 'confirm-modal',
          baseZIndex: 10000,
          data: {
            message:
              'Please confirm you want to activate template ' +
              this.editorState.messageTemplate.title,
            successButtonText: 'Yes, I want to activate',
            cancelButtonText: 'Continue Editing',
          },
        });

        ref.onClose.subscribe(async (result: boolean) => {
          if (result) {
            this.templateEditor.editorState.haveTemplate = false;
            this.templateEditor.editorState.haveCompiledEmail = false;
            this.templateEditor.editorState.haveEmail = false;
            this.templateEditor.editorState.loadingPage = true;
            this.templateEditor.editorState.saving = true;
            this.templateEditor.editorState.showErrors = false;
            this.templateEditor.getTemplate();
            this.templateEditor.getCompiledEmail();
          } else if (this.currentClientID === 'AduvoAdmin') {
            this.editorState.messageTemplate.published = false;
            this.templateEditor.isPublishedClicked = false;
          }
        });
      } else {
        // Save template
        this.templateEditor.editorState.haveTemplate = false;
        this.templateEditor.editorState.haveCompiledEmail = false;
        this.templateEditor.editorState.haveEmail = false;
        this.templateEditor.editorState.loadingPage = true;
        this.templateEditor.editorState.saving = true;
        this.templateEditor.editorState.showErrors = false;
        this.templateEditor.getTemplate();
        this.templateEditor.getCompiledEmail();
      }

    } else {
      await this.textMessageEditor.onSave();
    }

  }

  onSaveAndExit() {
    if (
      this.editorState.messageTemplate.title.trim() != '' &&
      this.editorState.messageTemplate.emailSubject.trim() != '' &&
      this.editorState.messageTemplate.categoryId != ''
    ) {
      this.onSave();
      this.onCancelEdit();
      // this.onExit();
    }
    else {
      this.toastService.showError('Please enter all require inputs.');
    }

  }

  onExit() {
    let validateChanges = false;
    if (
      this.originalTitle == this.editorState.messageTemplate.title &&
      this.originalSubject == this.editorState.messageTemplate.emailSubject &&
      this.originalPreHeader ==
      this.editorState.messageTemplate.preHeaderText &&
      this.originalCategory == this.editorState.messageTemplate.categoryId
    ) {
      validateChanges = true;
    }

    try {
      if (this.templateEditor?.getAllDataSaved() && validateChanges) {
        this.onCancelEdit();
      } else {
        this.showCancelEdit = true;
      }
    } catch (e) {
      this.showEmailEditor =false;
      this.onCancelEdit();
    }
  }

  onCancelEdit() {
    let returnUrl = this.messageTemplatesService.returnUrl;
    if (this.previousUrl) {
      this.storageHelper.ClearStorageByKey('contentLibrarybackURL');
      if (this.urlState == 'CE') {
        this.messageTemplatesService.resetReturnUrl();
        window.location.href = returnUrl;
      }
      else {
        this.router.navigateByUrl(this.previousUrl);
      }

    }
    else if (returnUrl) {
      this.messageTemplatesService.resetReturnUrl();
      window.location.href = returnUrl;
    } else if (this.buildYourOwnCancelUrl)
      this.router.navigate([this.buildYourOwnCancelUrl]);
    else
      this.router.navigate(['/marketing/content-library-filter/All']);

  }
}
