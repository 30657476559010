import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ProfileObserverService {
  constructor() {}
  
  private imageSubject = new BehaviorSubject<string>('');

  setProfileImage(logo_url: string) {
    this.imageSubject.next(logo_url);
  }

  getProfileImage(): Observable<string> {
    return this.imageSubject.asObservable();
  }
}