import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  MessageScheduleData,
  RepeatingMonthlyData,
  RepeatingYearlyCustomData,
  RepeatingYearlyHolidayData,
  RepeatingYearlyContactData,
  RepeatingWeeklyData,
  RepeatingWeeklyScheduleData,
  RepeatingDailyData,
  StatusQuickPickData,
  SavedContactQuickPick,
} from '../../../../../src/models/MessageScheduleData';
import { CampaignsService } from '../../../../../src/services/campaigns.service';
import {
  CMSComponentData,
  CMS_Data,
  SavedHolidayQuickPick,
  SavedOneTimeCustomDate,
} from '../../../../models/MessageScheduleData';
import { CampaignMessageScheduleDataService } from '../../../../services/campaignMessageSchedule.Data.service';
import { CommunicationService } from '../../../../services/communication.service';
import { ToastService } from '../../../../services/toast.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from '../../designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { LookupService } from 'src/services/lookup.service';
import { forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from 'src/app/services/CalendarAndTimeZoneService';
import { DatePipe } from '@angular/common';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { DateTimeHelper } from '../../../../helpers/DateTimeHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-message-schedule',
  templateUrl: './campaign-message-schedule.component.html',
  styleUrls: ['./campaign-message-schedule.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CampaignMessageScheduleComponent implements OnInit {
  @Output() DialogCloseEvent = new EventEmitter<boolean>();
  private _MessageScheduleData: MessageScheduleData;
  @Input()
  set MessageScheduleData(val: MessageScheduleData) {
    if (val) {
      this._MessageScheduleData = val;
      // this.StatusQuickPickData.campaignMessageId = this._MessageScheduleData.campaignMessageId;
      // this.StatusQuickPickData.loanStatusList = this._MessageScheduleData.loanStatusList;
    }
  }

  get MessageScheduleData(): MessageScheduleData {
    return this._MessageScheduleData;
  }
  //public loanStatusTypeId: string;//change name QuickPickStatusFieldId
  public status: string;
  public tabIndex: number;
  public currentComponent: number;
  public DisableSave: boolean = false;
  public DeliveryDateMessage: string = '';
  public TCPAForTextMessages: string = '';
  public OutputCMSComponentData: CMSComponentData;
  public scheduleTypeValue: string;
  public campaignMessageId: string;
  public timeFrames: any[];
  selectedTimeframe: string;
  public dialogTitle: string;
  public confirmationMessage: string;
  constructor(
    private campaignsService: CampaignsService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private communicationService: CommunicationService,
    public dialogService: DialogService,
    private toastService: ToastService,
    private lookupService: LookupService,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    public datePipe: DatePipe
  ) {
    this.timeFrames = [
      { name: 'Daily', code: 'DL' },
      { name: 'Weekly', code: 'WL' },
      { name: 'Monthly', code: 'ML' },
      { name: 'Yearly', code: 'YL' },
    ];

    // this.timeFrames.sort((a, b) => {
    //   return a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1;
    // });
    this.selectedTimeframe = 'YL';
    this.tabIndex = 0;
    this.scheduleTypeValue = 'StatusQuickPick'; //SQP,HQP,CD
    this.currentComponent = -1; //only load later
    this.OutputCMSComponentData = new CMSComponentData(0); // this will have response from components
  }

  ngOnInit(): void {
    this.campaignMessageId =
      this.campaignMessageScheduleDataService.campaignMessageId;
    this.tabIndex = this.campaignMessageScheduleDataService.tabIndex ?? 0;
    this.currentComponent =
      this.campaignMessageScheduleDataService.componentIndex ?? 0;
    this.dialogTitle = this.campaignMessageScheduleDataService.dialogTitle;
    this.scheduleTypeValue =
      this.campaignMessageScheduleDataService.scheduleSubtype; //StatusQuickPick/HolidayQuickPick/CustomDate
    this.selectedTimeframe = 'YL';

    if (!this.scheduleTypeValue) {
      if (this.tabIndex == 0) {
        this.scheduleTypeValue = 'StatusQuickPick';
      } else if (this.tabIndex == 1) {
        this.scheduleTypeValue = 'RepeatingCustomDate'; //todo
      }
    }

    //set repeating component
    if (this.tabIndex == 1) {
      if (this.currentComponent == 4) {
        this.scheduleTypeValue = 'RepeatingCustomDate';
      } else if (this.currentComponent == 5) {
        this.scheduleTypeValue = 'RepeatingHolidayQuickPick';
      } else if (this.currentComponent == 6) {
        this.scheduleTypeValue = 'RepeatingContactQuickPick';
      } else if (this.currentComponent == 7) {
        this.selectedTimeframe = 'WL';
      } else if (this.currentComponent == 8) {
        this.selectedTimeframe = 'ML';
      } else if (this.currentComponent == 9) {
        this.selectedTimeframe = 'DL';
      }
    }
  }

  ShowSchedule(tabindex: number) {
    //open first component
    this.tabIndex = tabindex;
    this.currentComponent = 0; //reset child component

    if (tabindex == 0) {
      this.scheduleTypeValue = 'StatusQuickPick';
    } else if (tabindex == 1) {
      this.scheduleTypeValue = 'RepeatingCustomDate'; //todo
      this.currentComponent = 4;
      this.selectedTimeframe = 'YL';
    }
  }

  async onCancelMessageScheduleDialog() {
    this._MessageScheduleData.showMessageScheduleDialog = false;
    this.DialogCloseEvent.emit(true);
  }
  async OnTimeFrameChange(event) {
    let selectedValue = event.value;
    this.selectedTimeframe = selectedValue;
    if (selectedValue == 'ML') {
      this.currentComponent = 8;
    } else if (selectedValue == 'DL') {
      this.currentComponent = 9;
    } else if (selectedValue == 'WL') {
      this.currentComponent = 7;
    } else {
      this.scheduleTypeValue = 'RepeatingCustomDate';
      this.currentComponent = 4;
    }
  }
  async onCloseMessageScheduleDialog() {
    this._MessageScheduleData.showMessageScheduleDialog = false;
    this.OutputCMSComponentData.componentValueJSON = '';
    this.DialogCloseEvent.emit(true);
  }
  async ShowComp(index: number) {
    if (index != this.currentComponent) {
      this.OutputCMSComponentData.componentValueJSON = '';
    }
    this.currentComponent = index;
  }
  public ComponentResponse(event: CMSComponentData) {
    if (event && event.componentNumber > -1 && event.componentValueJSON) {
      this.OutputCMSComponentData = event;
    }
  }
  public isSameSchedule() {
    let isExists = false;
    let oldScheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let oldScheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let oldScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;
    if (oldScheduleDataJSON != null) {
      if (oldScheduleSubtype == 'ContactQuickPick') {
        let savedObject = new SavedContactQuickPick();
        savedObject = JSON.parse(oldScheduleDataJSON)[0];
        this.OutputCMSComponentData.componentValueJSON.oldContactEventId =
          savedObject.ContactEventId;
      } else if (oldScheduleSubtype == 'HolidayQuickPick') {
        let savedObject = new SavedHolidayQuickPick();
        savedObject = JSON.parse(oldScheduleDataJSON)[0];
        this.OutputCMSComponentData.componentValueJSON.oldHolidayEventId =
          savedObject.HolidayEventId;
      } else if (oldScheduleSubtype == 'YearlyContact') {
        let savedObject = new RepeatingYearlyContactData();
        savedObject = JSON.parse(oldScheduleDataJSON)[0];
        this.OutputCMSComponentData.componentValueJSON.oldContactEventId =
          savedObject.contactEventId;
      } else if (oldScheduleSubtype == 'YearlyHoliday') {
        let savedObject = new RepeatingYearlyHolidayData();
        savedObject = JSON.parse(oldScheduleDataJSON)[0];
        this.OutputCMSComponentData.componentValueJSON.oldHolidayEventId =
          savedObject.holidayEventId;
      }
      this.OutputCMSComponentData.componentValueJSON.scheduleMode = 'EDIT';
      this.OutputCMSComponentData.componentValueJSON.oldScheduleType =
        oldScheduleType;
      this.OutputCMSComponentData.componentValueJSON.oldScheduleSubType =
        oldScheduleSubtype;
    }
    var newScheduleSubType = '';
    if (this.OutputCMSComponentData?.componentValueJSON) {
      let newScheduleDataJSON = this.OutputCMSComponentData?.componentValueJSON;
      if (this.OutputCMSComponentData.componentNumber == 0) {
        if (oldScheduleSubtype == 'StatusQuickPick') {
          let savedObject = new StatusQuickPickData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];
            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
            }
            if (
              newScheduleDataJSON.loanStatusTypeId ==
                savedObject.loanStatusTypeId &&
              newScheduleDataJSON.Hour == savedHour &&
              newScheduleDataJSON.Minute == savedMinute &&
              newScheduleDataJSON.AMPM == savedAMPM &&
              newScheduleDataJSON.TimezoneId == savedObject.timezoneId &&
              newScheduleDataJSON.day == savedObject.day &&
              newScheduleDataJSON.periodUnit == savedObject.periodUnit &&
              newScheduleDataJSON.offsetTypeId == savedObject.offsetTypeId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 1) {
        if (oldScheduleSubtype == 'HolidayQuickPick') {
          let savedObject = new SavedHolidayQuickPick();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.Time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
            }

            if (
              newScheduleDataJSON.HolidayEventId ==
                savedObject.HolidayEventId &&
              newScheduleDataJSON.Hour == savedHour &&
              newScheduleDataJSON.Minute == savedMinute &&
              newScheduleDataJSON.AMPM == savedAMPM &&
              newScheduleDataJSON.TimezoneId == savedObject.TimezoneId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 10) {
        if (oldScheduleSubtype == 'ContactQuickPick') {
          let savedObject = new SavedContactQuickPick();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.Time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
            }

            if (
              newScheduleDataJSON.ContactEventId ==
                savedObject.ContactEventId &&
              newScheduleDataJSON.Hour == savedHour &&
              newScheduleDataJSON.Minute == savedMinute &&
              newScheduleDataJSON.AMPM == savedAMPM &&
              newScheduleDataJSON.TimezoneId == savedObject.TimezoneId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 2) {
        if (oldScheduleSubtype == 'CustomDate') {
          let savedObject = new SavedOneTimeCustomDate();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.Time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
            }

            if (
              newScheduleDataJSON.deliverOn == savedObject.DeliverOn &&
              newScheduleDataJSON.hour == savedHour &&
              newScheduleDataJSON.minute == savedMinute &&
              newScheduleDataJSON.AMPM == savedAMPM &&
              newScheduleDataJSON.timezoneId == savedObject.TimezoneId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 8) {
        if (oldScheduleSubtype == 'Monthly') {
          let savedObject = new RepeatingMonthlyData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
              if (savedAMPM == 'PM') {
                let timeIds = savedObject.time.split(':');
                savedHour = timeIds[0];
              }
            }

            var savedStartDate;
            if (savedObject.dateStart) {
              let ids = savedObject.dateStart.split('-');
              //mm/dd/yyyy format
              savedStartDate = ids[1] + '/' + ids[2] + '/' + ids[0];
            }

            var savedEndDate;
            if (savedObject.dateEnd === undefined) {
              savedEndDate = newScheduleDataJSON.dateEnd;
            } else {
              let ids = savedObject.dateEnd.split('-');
              //mm/dd/yyyy format
              savedEndDate = ids[1] + '/' + ids[2] + '/' + ids[0];
            }

            if (newScheduleDataJSON.repeatingMonthlyType == 'AMD') {
              newScheduleDataJSON.weekday = savedObject.weekday;
            }

            var savedEndingCriteria = savedObject.endingCriteria;

            if (
              newScheduleDataJSON.repeatingMonthlyType ==
                savedObject.repeatingMonthlyType.toUpperCase() &&
              newScheduleDataJSON.dateOption == savedEndingCriteria &&
              newScheduleDataJSON.time.hours == savedHour &&
              newScheduleDataJSON.time.minutes == savedMinute &&
              newScheduleDataJSON.timezoneId == savedObject.timezoneId &&
              newScheduleDataJSON.dateStart == savedStartDate &&
              newScheduleDataJSON.dateEnd == savedEndDate &&
              newScheduleDataJSON.ordinalName == savedObject.ordinalName &&
              newScheduleDataJSON.weekday == savedObject.weekday
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 9) {
        if (oldScheduleSubtype == 'DailyEvent') {
          let savedObject = new RepeatingDailyData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
              if (savedAMPM == 'PM') {
                let timeIds = savedObject.time.split(':');
                savedHour = timeIds[0];
              }
            }

            var savedStartDate;
            if (savedObject.dateStart) {
              let ids = savedObject.dateStart.split('-');
              //mm/dd/yyyy format
              savedStartDate = ids[1] + '/' + ids[2] + '/' + ids[0];
            }

            var savedEndDate;
            var savedEndingCriteria: string;
            if (savedObject.dateEnd === undefined) {
              savedEndDate = newScheduleDataJSON.dateEnd;
              savedEndingCriteria = 'NEVER';
            } else {
              let ids = savedObject.dateEnd.split('-');
              //mm/dd/yyyy format
              savedEndDate = ids[1] + '/' + ids[2] + '/' + ids[0];
              savedEndingCriteria = 'ON';
            }
            if (
              newScheduleDataJSON.dateOption == savedEndingCriteria &&
              newScheduleDataJSON.time.hours == savedHour &&
              newScheduleDataJSON.time.minutes == savedMinute &&
              newScheduleDataJSON.timezoneId == savedObject.timezoneId &&
              newScheduleDataJSON.dateStart == savedStartDate &&
              newScheduleDataJSON.dateEnd == savedEndDate
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 4) {
        if (oldScheduleSubtype == 'YearlyCustom') {
          let savedObject = new RepeatingYearlyCustomData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
              if (savedAMPM == 'PM') {
                let timeIds = savedObject.time.split(':');
                savedHour = timeIds[0];
              }
            }
            var noOfOccurence = '0';
            if (savedObject.endingAfterNoccurrences == '') {
              noOfOccurence = '0';
            } else {
              noOfOccurence = savedObject.endingAfterNoccurrences;
            }

            if (newScheduleDataJSON.repeatingMonthlyType == 'AMD') {
              newScheduleDataJSON.everyXweekdayOfEveryMonthOrdinal =
                savedObject.everyXweekdayOfEveryMonthOrdinal;
              newScheduleDataJSON.weekday = savedObject.weekday;
            }

            var savedStartingYear = parseInt(savedObject.startingYear);
            if (
              newScheduleDataJSON.repeatingMonthlyType ==
                savedObject.repeatingMonthlyType &&
              newScheduleDataJSON.startingYear == savedStartingYear &&
              newScheduleDataJSON.time.hours == savedHour &&
              newScheduleDataJSON.time.minutes == savedMinute &&
              newScheduleDataJSON.timezoneId == savedObject.timezoneId &&
              newScheduleDataJSON.everyXweekdayOfEveryMonthOrdinal ==
                savedObject.everyXweekdayOfEveryMonthOrdinal &&
              newScheduleDataJSON.month == savedObject.month &&
              newScheduleDataJSON.weekday == savedObject.weekday &&
              newScheduleDataJSON.endingAfterNoccurrences == noOfOccurence
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 6) {
        if (oldScheduleSubtype == 'YearlyContact') {
          let savedObject = new RepeatingYearlyContactData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];
            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
              if (savedAMPM == 'PM') {
                let timeIds = savedObject.time.split(':');
                savedHour = timeIds[0];
              }
            }

            var savedStartingYear = parseInt(savedObject.startingYear);

            var savedEndingYear;
            if (newScheduleDataJSON.dateOption == 'NEVER') {
              if (savedObject.endingYear === undefined) {
                savedEndingYear = parseInt(newScheduleDataJSON.endingYear);
              } else {
                savedEndingYear = parseInt(savedObject.endingYear);
              }
            } else {
              savedEndingYear = parseInt(savedObject.endingYear);
            }

            if (
              newScheduleDataJSON.contactEventId ==
                savedObject.contactEventId &&
              newScheduleDataJSON.startingYear == savedStartingYear &&
              newScheduleDataJSON.endingYear == savedEndingYear &&
              newScheduleDataJSON.time.hours == savedHour &&
              newScheduleDataJSON.time.minutes == savedMinute &&
              newScheduleDataJSON.timezoneId == savedObject.timezoneId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 7) {
        if (oldScheduleSubtype == 'WeeklyWeekday') {
          let savedObject = new RepeatingWeeklyScheduleData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];
            this.OutputCMSComponentData.componentValueJSON.scheduleMode =
              'EDIT';
            this.OutputCMSComponentData.componentValueJSON.oldScheduleType =
              oldScheduleType;
            this.OutputCMSComponentData.componentValueJSON.oldScheduleSubType =
              oldScheduleSubtype;
            var savedStartDate;
            var savedEndDate;
            var dateOption = 'ON';
            if (savedObject.dateStart) {
              let ids = savedObject.dateStart.split('-');
              //mm/dd/yyyy format
              savedStartDate = ids[1] + '/' + ids[2] + '/' + ids[0];
            }
            if (savedObject.dateEnd) {
              let ids = savedObject.dateEnd.split('-');
              savedEndDate = ids[1] + '/' + ids[2] + '/' + ids[0];
            } else {
              dateOption = 'NEVER';
              let calculateDate = new Date(savedStartDate);
              calculateDate.setFullYear(calculateDate.getFullYear() + 9);
              savedEndDate = this.datePipe.transform(
                calculateDate,
                'MM/dd/yyyy'
              );
            }
            var isOtherFiledSame = false;
            if (
              newScheduleDataJSON.dateOption == dateOption &&
              newScheduleDataJSON.dateStart == savedStartDate &&
              newScheduleDataJSON.dateEnd == savedEndDate
            ) {
              isOtherFiledSame = true;
            }
            let isListSame = this.allElementExistsInWeekly(
              newScheduleDataJSON.weeklyWeekdayList,
              savedObject.scheduleDetail
            );
            if (isOtherFiledSame == true && isListSame == true) {
              return true;
            }
          }
        }
      } else if (this.OutputCMSComponentData.componentNumber == 5) {
        if (oldScheduleSubtype == 'YearlyHoliday') {
          let savedObject = new RepeatingYearlyHolidayData();
          if (oldScheduleDataJSON != null) {
            savedObject = JSON.parse(oldScheduleDataJSON)[0];

            let dateTimeHelper = new DateTimeHelper();
            var savedHour;
            var savedMinute;
            var savedAMPM;

            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              savedHour = parseInt(time.hours);
              savedMinute = parseInt(time.minutes);
              savedAMPM = time.AmPM;
              if (savedAMPM == 'PM') {
                let timeIds = savedObject.time.split(':');
                savedHour = timeIds[0];
              }
            }

            var savedStartingYear = parseInt(savedObject.startingYear);

            var savedEndingYear;
            if (newScheduleDataJSON.dateOption == 'NEVER') {
              if (savedObject.endingYear === undefined) {
                savedEndingYear = parseInt(newScheduleDataJSON.endingYear);
              } else {
                savedEndingYear = parseInt(savedObject.endingYear);
              }
            } else {
              savedEndingYear = parseInt(savedObject.endingYear);
            }

            if (
              newScheduleDataJSON.holidayEventId ==
                savedObject.holidayEventId &&
              newScheduleDataJSON.startingYear == savedStartingYear &&
              newScheduleDataJSON.endingYear == savedEndingYear &&
              newScheduleDataJSON.time.hours == savedHour &&
              newScheduleDataJSON.time.minutes == savedMinute &&
              newScheduleDataJSON.timezoneId == savedObject.timezoneId
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }
    return isExists;
  }
  private allElementExistsInWeekly(newData: any[], oldData: any[]): boolean {
    var existsCounter = 0;
    for (const item of oldData) {
      let dateTimeHelper = new DateTimeHelper();
      var savedHour;
      var savedMinute;
      var savedAMPM;

      var time = dateTimeHelper.Get12HourTimeComponent(item.time);
      if (time) {
        savedHour = parseInt(time.hours);
        savedMinute = parseInt(time.minutes);
        savedAMPM = time.AmPM;
        if (savedAMPM == 'PM') {
          let timeIds = item.time.split(':');
          savedHour = timeIds[0];
        }
      }
      // let itemtime = this.calendarAndTimeZoneService.getTimeSpan(
      //   savedHour,
      //   savedMinute,
      //   savedAMPM
      // );
      var verifyItem = newData.find(
        (x) =>
          x.timezoneId === item.timezoneId &&
          x.weekday === item.weekday &&
          x.time.hours == savedHour &&
          x.time.minutes == savedMinute
      );
      if (verifyItem === undefined) {
        return false;
      } else {
        existsCounter = existsCounter + 1;
      }
    }
    if (existsCounter == newData.length) {
      return true;
    }
    return false;
  }
  generateMessage() {
    forkJoin(
      this.lookupService.GetContacts(),
      this.lookupService.GetTimezones(),
      this.lookupService.GetHolidays()
    ).subscribe(([contactresponse, timezonesresponse, holidayresponse]) => {
      let timeZones = timezonesresponse.data;
      let contacts = contactresponse.data;
      let holidays = holidayresponse.data;

      if (this.OutputCMSComponentData.componentNumber == 1) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.TimezoneId.toLowerCase(),
            timeZones
          );

        let time = this.calendarAndTimeZoneService.Get12HourTime(
          this.OutputCMSComponentData.componentValueJSON.Hour,
          this.OutputCMSComponentData.componentValueJSON.Minute,
          this.OutputCMSComponentData.componentValueJSON.AMPM
        );
        let holidayDisplayName =
          this.calendarAndTimeZoneService.getHolidayDisplayName(
            this.OutputCMSComponentData.componentValueJSON.HolidayEventId.toLowerCase(),
            holidays
          );
        this.confirmationMessage = `on the ${holidayDisplayName} schedule at ${time} ${timeZoneDisplayName}`;
      } else if (this.OutputCMSComponentData.componentNumber == 10) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.TimezoneId.toLowerCase(),
            timeZones
          );

        let time = this.calendarAndTimeZoneService.Get12HourTime(
          this.OutputCMSComponentData.componentValueJSON.Hour,
          this.OutputCMSComponentData.componentValueJSON.Minute,
          this.OutputCMSComponentData.componentValueJSON.AMPM
        );
        let contactDisplayName =
          this.calendarAndTimeZoneService.getContactEventDisplayName(
            this.OutputCMSComponentData.componentValueJSON.ContactEventId.toLowerCase(),
            contacts
          );
        this.confirmationMessage = `on the ${contactDisplayName} schedule at ${time} ${timeZoneDisplayName}`;
      } else if (this.OutputCMSComponentData.componentNumber == 2) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.timezoneId.toLowerCase(),
            timeZones
          );

        let time = this.calendarAndTimeZoneService.Get12HourTime(
          this.OutputCMSComponentData.componentValueJSON.hour,
          this.OutputCMSComponentData.componentValueJSON.minute,
          this.OutputCMSComponentData.componentValueJSON.AMPM
        );
        var dateStr = this.datePipe.transform(
          this.OutputCMSComponentData.componentValueJSON.deliverOn,
          'MM/dd/yyyy'
        );
        this.confirmationMessage = `on ${dateStr} at ${time} ${timeZoneDisplayName}`;
      } else if (this.OutputCMSComponentData.componentNumber == 4) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.timezoneId.toLowerCase(),
            timeZones
          );
        let time = this.calendarAndTimeZoneService.GetHourTime(
          this.OutputCMSComponentData.componentValueJSON.time.hours,
          this.OutputCMSComponentData.componentValueJSON.time.minutes
        );
        let monthName =
          this.calendarAndTimeZoneService.months.find(
            (x) =>
              x.code == this.OutputCMSComponentData.componentValueJSON.month
          )?.name ?? '-';
        if (
          this.OutputCMSComponentData.componentValueJSON.repeatingMonthlyType.toLowerCase() ==
          'amd'
        ) {
          let days = this.calendarAndTimeZoneService.getOrdinalNumber(
            this.OutputCMSComponentData.componentValueJSON
              .onTheXofEveryMonthOrdinal
          );
          this.confirmationMessage = `on the ${days} day of every ${monthName} at ${time} ${timeZoneDisplayName}`;
        } else {
          let weekNumber = Number(
            this.OutputCMSComponentData.componentValueJSON
              .everyXweekdayOfEveryMonthOrdinal
          );
          let weekDays = 'Last';
          if (weekNumber != 5) {
            weekDays =
              this.calendarAndTimeZoneService.getOrdinalNumber(weekNumber);
          }

          let weekName =
            this.calendarAndTimeZoneService.weekdays.find(
              (x) =>
                x.code == this.OutputCMSComponentData.componentValueJSON.weekday
            )?.name ?? '-';
          this.confirmationMessage = `on the ${weekDays} ${weekName} of every ${monthName} at ${time} ${timeZoneDisplayName}`;
        }
      } else if (this.OutputCMSComponentData.componentNumber == 5) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.timezoneId.toLowerCase(),
            timeZones
          );
        let time = this.calendarAndTimeZoneService.GetHourTime(
          this.OutputCMSComponentData.componentValueJSON.time.hours,
          this.OutputCMSComponentData.componentValueJSON.time.minutes
        );
        let holidayDisplayName =
          this.calendarAndTimeZoneService.getHolidayDisplayName(
            this.OutputCMSComponentData.componentValueJSON.holidayEventId.toLowerCase(),
            holidays
          );
        this.confirmationMessage = `on ${holidayDisplayName} at ${time} ${timeZoneDisplayName}`;
      } else if (this.OutputCMSComponentData.componentNumber == 6) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.timezoneId.toLowerCase(),
            timeZones
          );
        let time = this.calendarAndTimeZoneService.GetHourTime(
          this.OutputCMSComponentData.componentValueJSON.time.hours,
          this.OutputCMSComponentData.componentValueJSON.time.minutes
        );
        let calanderDisplayName =
          this.calendarAndTimeZoneService.getContactEventDisplayName(
            this.OutputCMSComponentData.componentValueJSON.contactEventId.toLowerCase(),
            contacts
          );
        this.confirmationMessage = `on ${calanderDisplayName} at ${time} ${timeZoneDisplayName}`;
      } else if (this.OutputCMSComponentData.componentNumber == 7) {
        let cnt =
          this.OutputCMSComponentData.componentValueJSON?.weeklyWeekdayList
            ?.length;
        if (cnt === 1) {
          let timeZoneDisplayName =
            this.calendarAndTimeZoneService.getTimeZoneDisplayName(
              this.OutputCMSComponentData.componentValueJSON.weeklyWeekdayList[0].timezoneId.toLowerCase(),
              timeZones
            );
          let time = this.calendarAndTimeZoneService.GetHourTime(
            this.OutputCMSComponentData.componentValueJSON.weeklyWeekdayList[0]
              .time.hours,
            this.OutputCMSComponentData.componentValueJSON.weeklyWeekdayList[0]
              .time.minutes
          );
          let weekName =
            this.calendarAndTimeZoneService.weekdays.find(
              (x) =>
                x.code ==
                this.OutputCMSComponentData.componentValueJSON
                  .weeklyWeekdayList[0].weekday
            )?.name ?? '-';
          this.confirmationMessage = `every ${weekName} at ${time} ${timeZoneDisplayName}`;
        } else {
          this.confirmationMessage = `to multiple weekly schedules`;
        }
      } else if (this.OutputCMSComponentData.componentNumber == 8) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.timezoneId.toLowerCase(),
            timeZones
          );
        let time = this.calendarAndTimeZoneService.GetHourTime(
          this.OutputCMSComponentData.componentValueJSON.time.hours,
          this.OutputCMSComponentData.componentValueJSON.time.minutes
        );

        if (
          this.OutputCMSComponentData.componentValueJSON.repeatingMonthlyType.toLowerCase() ==
          'amd'
        ) {
          let days = this.calendarAndTimeZoneService.getOrdinalNumber(
            this.OutputCMSComponentData.componentValueJSON.ordinalName
          );
          this.confirmationMessage = `on the ${days} day of every month at ${time} ${timeZoneDisplayName}`;
        } else {
          let weekNumber = Number(
            this.OutputCMSComponentData.componentValueJSON.ordinalName
          );
          let weekDays = 'Last';
          if (weekNumber != 5) {
            weekDays =
              this.calendarAndTimeZoneService.getOrdinalNumber(weekNumber);
          }
          let weekName =
            this.calendarAndTimeZoneService.weekdays.find(
              (x) =>
                x.code == this.OutputCMSComponentData.componentValueJSON.weekday
            )?.name ?? '-';
          this.confirmationMessage = `on the ${weekDays} ${weekName} of the month at ${time} ${timeZoneDisplayName}`;
        }
      } else if (this.OutputCMSComponentData.componentNumber == 9) {
        let timeZoneDisplayName =
          this.calendarAndTimeZoneService.getTimeZoneDisplayName(
            this.OutputCMSComponentData.componentValueJSON.timezoneId.toLowerCase(),
            timeZones
          );
        let time = this.calendarAndTimeZoneService.GetHourTime(
          this.OutputCMSComponentData.componentValueJSON.time.hours,
          this.OutputCMSComponentData.componentValueJSON.time.minutes
        );
        this.confirmationMessage = `daily at ${time} ${timeZoneDisplayName}`;
      }
    });
  }
  public isScheduleDateValid() {
    let time = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    let Hour = parseInt(this.OutputCMSComponentData.componentValueJSON.hour);
    time.minutes = parseInt(
      this.OutputCMSComponentData.componentValueJSON.minute
    );
    if (this.OutputCMSComponentData.componentValueJSON.AMPM == 'AM') {
      time.hours = Hour;
    } else {
      if (Hour === 12) {
        time.hours = Hour;
      } else {
        time.hours = 12 + Hour;
      }
    }
    let currentTimeZoneDateTime =
      this.calendarAndTimeZoneService.getCountryDateTime(
        this.OutputCMSComponentData.componentValueJSON.timezoneOffset
      );
    let currentHour = currentTimeZoneDateTime.getHours() + 2;
    if (this.OutputCMSComponentData.componentNumber == 2) {
      if (this.OutputCMSComponentData.componentValueJSON.sendASAP) {
        currentHour = currentTimeZoneDateTime.getHours();
      }
    }

    let currentTimeZoneDateTimeByTwoHrs = new Date(
      currentTimeZoneDateTime.getFullYear(),
      currentTimeZoneDateTime.getMonth(),
      currentTimeZoneDateTime.getDate(),
      currentHour,
      0,
      0
    );
    let deliveryDateTime = new Date(
      this.OutputCMSComponentData.componentValueJSON.deliverOn
    );
    let scheduleDateTime = new Date(
      deliveryDateTime.getUTCFullYear(),
      deliveryDateTime.getUTCMonth(),
      deliveryDateTime.getUTCDate(),
      time.hours,
      time.minutes,
      time.seconds
    );
    if (
      this.OutputCMSComponentData.componentNumber == 2 &&
      environment.AllowTCPAForTextMessages == true &&
      this.campaignMessageScheduleDataService.campaignMessageType == 2
    ) {
      if (this.OutputCMSComponentData.componentValueJSON.sendASAP) {
        if (
          scheduleDateTime.getHours() > 20 ||
          (scheduleDateTime.getHours() == 20 &&
            scheduleDateTime.getMinutes() > 0) ||
          scheduleDateTime.getHours() < 8 ||
          (scheduleDateTime.getHours() == 12 &&
            this.OutputCMSComponentData.componentValueJSON.AMPM == 'AM')
        ) {
          this.TCPAForTextMessages = `The TCPA (Telephone Consumer Production Act) stipulates that text messages may only be sent between 8AM and 8PM`;
          return false;
        }
      }
    }
    if (
      currentTimeZoneDateTimeByTwoHrs > scheduleDateTime &&
      this.campaignMessageScheduleDataService.campaignMessageType == 2
    ) {
      this.DeliveryDateMessage = `You cannot schedule before  ${this.datePipe.transform(
        currentTimeZoneDateTimeByTwoHrs,
        'M/d/yyyy hh:mm a'
      )}  ${this.OutputCMSComponentData.componentValueJSON.timezoneInfoId}`;
      return false;
    }
    return true;
  }
  async onSaveMessageScheduleDialog() {
    if (this.OutputCMSComponentData.isFormValid) {
      let isScheduleValid = this.isScheduleDateValid();
      if (isScheduleValid) {
        this.DeliveryDateMessage = '';
        this.TCPAForTextMessages='';
        let isExists = this.isSameSchedule();
        if (isExists) {
          this.DisableSave = false;
          this.close();
          //this.toastService.showError('Schedule already exists in the system.');
        } else {
          if (this.OutputCMSComponentData.componentNumber == 0) {
            //Status Quick Pick
            this.DisableSave = true;
            this.withoutDialogSave();
          } else {
            this.generateMessage();
            var ref = this.dialogService.open(ConfirmModalComponent, {
              width: '650px',
              contentStyle: { 'max-height': '500px', overflow: 'auto' },
              styleClass: 'confirm-modal',
              baseZIndex: 10000,
              data: {
                message: `Do you want to schedule ‘${this.dialogTitle}’ ${this.confirmationMessage}?`,
                successButtonText: 'Yes, I want to save',
                cancelButtonText: 'Cancel',
              },
            });

            ref.onClose.subscribe(async (result: boolean) => {
              if (result) {
                this.DisableSave = true;
                this.doSave();
              }
            });
          }
        }
      } else {
        this.DisableSave = false;
        //this.toastService.showError('Please validate all data.');
      }
    } else {
      this.DisableSave = false;
      this.toastService.showError('Please validate all data.');
    }
  }
  async withoutDialogSave() {
    //  let time = {
    //    hours: 0,
    //    minutes: 0,
    //    seconds: 0,
    //  };
    //  let Hour = parseInt(this.OutputCMSComponentData.componentValueJSON.Hour);
    //  time.minutes = parseInt(
    //    this.OutputCMSComponentData.componentValueJSON.Minute
    //  );
    //  if (this.OutputCMSComponentData.componentValueJSON.AMPM == 'AM') {
    //    time.hours = Hour;
    //  } else {
    //    time.hours = 12 + Hour;
    //  }
    this.OutputCMSComponentData.componentValueJSON.Time =
      this.OutputCMSComponentData.componentValueJSON.time;
    this.OutputCMSComponentData.componentValueJSON.CampaignMessageId =
      this.campaignMessageId;
    this.campaignsService
      .AddEditOnetimeStatusQuickPick(
        this.OutputCMSComponentData.componentValueJSON
      )
      .subscribe(
        (data) => {
          this.toastService.showSuccess(
            'One time Status Quick Pick saved Successfully!'
          );

          this.close();
        },
        (err) => {
          this.toastService.showError(
            'Error while saving Onetime StatusQuickPick'
          );
        }
      );
  }
  async doSave() {
    if (this.OutputCMSComponentData?.componentValueJSON) {
      if (this.OutputCMSComponentData.componentNumber == 1) {
        //Holiday Quick Pick
        let time = {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        let Hour = parseInt(
          this.OutputCMSComponentData.componentValueJSON.Hour
        );
        time.minutes = parseInt(
          this.OutputCMSComponentData.componentValueJSON.Minute
        );
        if (this.OutputCMSComponentData.componentValueJSON.AMPM == 'AM') {
          time.hours = Hour;
        } else {
          time.hours = 12 + Hour;
        }

        this.campaignsService
          .AddEditOnetimeHolidayQuickPick(
            this.campaignMessageId,
            this.OutputCMSComponentData.componentValueJSON.HolidayEventId,
            time,
            this.OutputCMSComponentData.componentValueJSON.TimezoneId,
            this.OutputCMSComponentData.componentValueJSON.TimezoneInfoId,
            this.OutputCMSComponentData.componentValueJSON.scheduleJson,
            this.OutputCMSComponentData.componentValueJSON.scheduleMode,
            this.OutputCMSComponentData.componentValueJSON.oldScheduleType,
            this.OutputCMSComponentData.componentValueJSON.oldScheduleSubType,
            this.OutputCMSComponentData.componentValueJSON.oldHolidayEventId,
            this.OutputCMSComponentData.componentValueJSON.oldContactEventId
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.toastService.showError(
                  'No event schedule between date range..'
                );
                this.DisableSave = false;
              } else {
                this.toastService.showSuccess(
                  'One time Holiday Quick Pick saved Successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.toastService.showError(
                'Error while saving Onetime Holiday Quick Pick'
              );
              this.DisableSave = false;
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 10) {
        //Contact Quick Pick
        let time = {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        let Hour = parseInt(
          this.OutputCMSComponentData.componentValueJSON.Hour
        );
        time.minutes = parseInt(
          this.OutputCMSComponentData.componentValueJSON.Minute
        );
        if (this.OutputCMSComponentData.componentValueJSON.AMPM == 'AM') {
          time.hours = Hour;
        } else {
          time.hours = 12 + Hour;
        }
        this.OutputCMSComponentData.componentValueJSON.Time = time;
        this.OutputCMSComponentData.componentValueJSON.CampaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditOnetimeContactQuickPick(
            this.OutputCMSComponentData.componentValueJSON
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.toastService.showError(
                  'No event schedule between date range..'
                );
                this.DisableSave = false;
              } else {
                this.toastService.showSuccess(
                  'One time Contact Quick Pick saved Successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.toastService.showError(
                'Error while saving Onetime Contact Quick Pick'
              );
              this.DisableSave = false;
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 2) {
        //Custom Date
        let time = {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        let Hour = parseInt(
          this.OutputCMSComponentData.componentValueJSON.hour
        );
        time.minutes = parseInt(
          this.OutputCMSComponentData.componentValueJSON.minute
        );
        if (this.OutputCMSComponentData.componentValueJSON.AMPM == 'AM') {
          time.hours = Hour;
        } else {
          time.hours = 12 + Hour;
        }

        this.campaignsService
          .AddEditOnetimeCustomDate(
            this.campaignMessageId,
            this.OutputCMSComponentData.componentValueJSON.deliverOn,
            time,
            this.OutputCMSComponentData.componentValueJSON.timezoneId,
            this.OutputCMSComponentData.componentValueJSON.timezoneInfoId,
            this.OutputCMSComponentData.componentValueJSON.oldScheduleType,
            this.OutputCMSComponentData.componentValueJSON.scheduleMode,
            this.OutputCMSComponentData.componentValueJSON.sendASAP,
            this.OutputCMSComponentData.componentValueJSON.oldScheduleSubType,
            this.OutputCMSComponentData.componentValueJSON.oldHolidayEventId,
            this.OutputCMSComponentData.componentValueJSON.oldContactEventId
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected One time custom date schedule does not lie with in the selected range'
                );
              } else {
                this.toastService.showSuccess(
                  'One time Custom Date saved Successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving One time Custom Date'
              );
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 4) {
        this.OutputCMSComponentData.componentValueJSON.campaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditRepeatingYearlyCustom(
            this.OutputCMSComponentData.componentValueJSON
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected yearly custom date schedule does not lie with in the selected range'
                );
              } else {
                this.toastService.showSuccess(
                  'Repeating yearly custom event saved successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving repeating yearly custom event'
              );
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 5) {
        this.OutputCMSComponentData.componentValueJSON.campaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditRepeatingYearlyHoliday(
            this.OutputCMSComponentData.componentValueJSON
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected yearly holiday schedule does not lie with in the selected range'
                );
              } else {
                this.toastService.showSuccess(
                  'Repeating yearly holiday event saved successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving repeating yearly holiday event'
              );
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 6) {
        this.OutputCMSComponentData.componentValueJSON.campaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditRepeatingYearlyContact(
            this.OutputCMSComponentData.componentValueJSON
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected yearly contact schedule does not lie with in the selected range'
                );
              } else {
                this.toastService.showSuccess(
                  'Repeating yearly contact event saved successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving repeating yearly contact event'
              );
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 7) {
        this.OutputCMSComponentData.componentValueJSON.campaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditRepeatingWeekly(
            this.OutputCMSComponentData.componentValueJSON
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected weekday(s) does not lie with in the date range'
                );
              } else {
                this.toastService.showSuccess(
                  'Repeating weekly event saved successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving repeating weekly event'
              );
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 8) {
        this.OutputCMSComponentData.componentValueJSON.campaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditRepeatingMonthly(
            this.OutputCMSComponentData.componentValueJSON
          )
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected schedule does not lie with in the date range'
                );
              } else {
                this.toastService.showSuccess(
                  'Repeating monthly event saved successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving repeating monthly event'
              );
            }
          );
      } else if (this.OutputCMSComponentData.componentNumber == 9) {
        this.OutputCMSComponentData.componentValueJSON.campaignMessageId =
          this.campaignMessageId;
        this.campaignsService
          .AddEditRepeatingDaily(this.OutputCMSComponentData.componentValueJSON)
          .subscribe(
            (data) => {
              if (data.message == 'NO_SCHEDULE' && data.status == 500) {
                this.DisableSave = false;
                this.toastService.showError(
                  'Selected schedule does not lie with in the date range'
                );
              } else {
                this.toastService.showSuccess(
                  'Repeating daily event saved successfully!'
                );
                this.close();
              }
            },
            (err) => {
              this.DisableSave = false;
              this.toastService.showError(
                'Error while saving repeating daily event'
              );
            }
          );
      }
    }
  }
  async close() {
    let cmsData = new CMS_Data(this.campaignMessageId, '');
    cmsData.isReload = true;
    this.communicationService.CMS_Update(cmsData);
    this.DialogCloseEvent.emit(true);
    setTimeout(() => {
      this.MessageScheduleData.showMessageScheduleDialog = false;
    }, 100);
  }
}
