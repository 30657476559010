import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CalendarAndTimeZoneService } from '../../../../../../src/app/services/CalendarAndTimeZoneService';
import { formState } from '../../../../../../src/helpers/formState.helper';
import { CMSComponentData, RepeatingYearlyHolidayData } from '../../../../../../src/models/MessageScheduleData';
import { CampaignMessageScheduleDataService } from '../../../../../../src/services/campaignMessageSchedule.Data.service';
import { LookupService } from '../../../../../../src/services/lookup.service';
import { ToastService } from '../../../../../../src/services/toast.service';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { UserToken } from 'src/models/UserToken';
import { UserTokenService } from 'src/services/user-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-repeating-holiday',
  templateUrl: 'campaign-message-schedule-repeating-holiday.component.html',
})
export class CampaignMessageScheduleRepeatingHolidayComponent
  implements OnInit
{
  @Output() SubmitEvent = new EventEmitter<CMSComponentData>();
  public CMSComponentData: CMSComponentData;
  repeatingYearlyHolidayData: RepeatingYearlyHolidayData;

  holidayForm: FormGroup | any = null;
  validator: formState = new formState();
  isFormValueChange = false;
  isAfter = false;
  hours: any[];
  minutes: any[];
  meridiems: any[];
  holidays: any[];
  timeZones: any[];
  startYears: any[];
  endYears: any[];
  todayDate = new Date();
  userTimeZoneId: any;
  userToken: UserToken;
  messageType: number;
  public timeToolTipe: string;
  allowTCPAForTextMessages: boolean = true;
  constructor(
    private http: HttpClient,
    private calendarAndTimeZoneService: CalendarAndTimeZoneService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private campaignMessageScheduleDataService: CampaignMessageScheduleDataService,
    private userTokenService: UserTokenService
  ) {
    this.allowTCPAForTextMessages = environment.AllowTCPAForTextMessages;
    this.CMSComponentData = new CMSComponentData(5);
    this.repeatingYearlyHolidayData = new RepeatingYearlyHolidayData();
    this.userToken = this.userTokenService.getUser();
    this.messageType =
      this.campaignMessageScheduleDataService.campaignMessageType;
    this.timeToolTipe =
      'FCC’s regulations do not allow solicitation outside of normal operating hours, please select a time between 8AM and 8PM';
  }

  ngOnInit(): void {
    this.hours = this.calendarAndTimeZoneService.GetHours();
    this.minutes = this.calendarAndTimeZoneService.GetMinutes();
    this.meridiems = this.calendarAndTimeZoneService.GetMeridiems();
    this.startYears = this.calendarAndTimeZoneService.GetStartYears();
    this.endYears = this.calendarAndTimeZoneService.GetEndYears();
    let scheduleDataJSON =
      this.campaignMessageScheduleDataService.scheduleDataJSON;
    let scheduleType = this.campaignMessageScheduleDataService.scheduleType;
    let ScheduleSubtype =
      this.campaignMessageScheduleDataService.scheduleSubtype;

    forkJoin(
      this.lookupService.GetHolidays(),
      this.lookupService.GetTimezones()
    ).subscribe(([holidayresponse, timezonesresponse]) => {
      this.timeZones = timezonesresponse.data;
      this.holidays = holidayresponse.data;
      this.userTimeZoneId = this.userToken.TimezoneId.toLowerCase();
      let holidayEventId = this.holidays[0].holidayEventId;
      let defaultObject =
        this.calendarAndTimeZoneService.getDefaultDateTimeObject(
          this.userTimeZoneId,
          this.timeZones
        );
      if (this.userTimeZoneId && holidayEventId) {
        let defaultData = {
          hour: defaultObject.defaultHour,
          minute: defaultObject.defaultMinute,
          meridiem:
            this.messageType == 2 && this.allowTCPAForTextMessages
              ? this.calendarAndTimeZoneService.getMeridiem(
                  defaultObject.defaultHour
                )
              : defaultObject.defaultMeridiem,
          timeZoneId: defaultObject.defaultTimeZoneId,
          startYear: this.todayDate.getFullYear(),
          endingYear: 0,
          holidayEventId: holidayEventId,
          yearOption: 'NEVER',
          txtOccurrence: 1,
        };
        let savedObject = new RepeatingYearlyHolidayData();
        if (
          scheduleDataJSON &&
          scheduleDataJSON != 'No Schedule' &&
          scheduleType == 'Repeating' &&
          ScheduleSubtype == 'YearlyHoliday'
        ) {
          savedObject = JSON.parse(scheduleDataJSON)[0];
          if (savedObject) {
            let dateTimeHelper = new DateTimeHelper();
            var time = dateTimeHelper.Get12HourTimeComponent(savedObject.time);
            if (time) {
              defaultData.hour = parseInt(time.hours);
              defaultData.minute = parseInt(time.minutes);
              defaultData.meridiem = time.AmPM;
            }
            defaultData.timeZoneId = savedObject.timezoneId;
            defaultData.holidayEventId = savedObject.holidayEventId;
            defaultData.startYear = parseInt(savedObject.startingYear);
            defaultData.endingYear = parseInt(savedObject.endingYear);
            if (savedObject.endingYear) {
              defaultData.yearOption = 'AFTER';
              defaultData.txtOccurrence =
                defaultData.endingYear == defaultData.startYear
                  ? 1
                  : defaultData.endingYear - defaultData.startYear + 1;
            }
          }
        }

        this.createForm(defaultData);
      }
    });
  }
  createForm(defaultData) {
    this.holidayForm = this.formBuilder.group({
      hour: [defaultData.hour],
      minute: [defaultData.minute],
      meridiem: [defaultData.meridiem],
      timeZone: [defaultData.timeZoneId, Validators.required],
      startYear: [defaultData.startYear],
      yearOption: [defaultData.yearOption],
      txtOccurrence: [
        defaultData.txtOccurrence,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          this.calendarAndTimeZoneService.occurrenceRangeValidator,
        ],
      ],
      holiday: [defaultData.holidayEventId, Validators.required],
    });
    this.holidayForm.valueChanges.subscribe((controlValue) => {
      this.isFormValueChange = true;

      this.fillFormData();
    });
    if (this.isFormValueChange == false) {
      this.fillFormData();
    }
  }
  OnOptionChange(event) {
    let selectedValue = this.holidayForm.value.yearOption;
    if (selectedValue == 'NEVER') {
      this.holidayForm.patchValue({
        txtOccurrence: '1',
      });
    }
  }
  doEmit() {
    this.CMSComponentData.isFormValid = this.holidayForm.valid;
    this.CMSComponentData.componentValueJSON = this.repeatingYearlyHolidayData;
    this.SubmitEvent.emit(this.CMSComponentData);
  }
  fillFormData() {
    this.repeatingYearlyHolidayData.campaignMessageId = '';
    this.repeatingYearlyHolidayData.holidayEventId =
      this.holidayForm.value.holiday;
    let selectedHoliday = this.holidays.find(
      (x) => x.holidayEventId.toLowerCase() === this.holidayForm.value.holiday
    );
    this.repeatingYearlyHolidayData.scheduleJson = selectedHoliday.scheduleJSON;
    this.repeatingYearlyHolidayData.timezoneId =
      this.holidayForm.value.timeZone;
    this.repeatingYearlyHolidayData.timezoneInfoId = this.timeZones.find(
      (x) =>
        x.timezoneId.toLowerCase() ===
        this.repeatingYearlyHolidayData.timezoneId
    )?.timezoneInfoId;
    let hr = Number(this.holidayForm.value.hour);
    let mi = Number(this.holidayForm.value.minute);
    let mer = this.holidayForm.value.meridiem;
    this.repeatingYearlyHolidayData.time =
      this.calendarAndTimeZoneService.getTimeSpan(hr, mi, mer);
    this.repeatingYearlyHolidayData.startingYear =
      this.holidayForm.value.startYear.toString();
    this.repeatingYearlyHolidayData.dateOption =
      this.holidayForm.value.yearOption;
    if (this.holidayForm.value.yearOption == 'NEVER') {
      this.isAfter = false;
      let calculateYear =
        Number(this.repeatingYearlyHolidayData.startingYear) + 9;
      this.repeatingYearlyHolidayData.endingYear = calculateYear.toString();
    } else {
      this.isAfter = true;
      let calculateYear =
        Number(this.repeatingYearlyHolidayData.startingYear) +
        Number(this.holidayForm.value.txtOccurrence) -
        1;
      this.repeatingYearlyHolidayData.endingYear = calculateYear.toString();
    }
    this.doEmit();
  }
  OnHourChange(event) {
     if (this.messageType == 2 && this.allowTCPAForTextMessages) {
       let selectedHour = this.holidayForm.value.hour;
       this.holidayForm.patchValue({
         meridiem: this.calendarAndTimeZoneService.getMeridiem(selectedHour),
       });
     }
  }
}