import { Component, Input, OnInit } from '@angular/core';
import { EmailStats } from '../../../../models/EmailStats';
@Component({
  selector: 'campaignStatsOverview-root',
  templateUrl: './stats-overview.component.html',
  styleUrls: ['./stats-overview.component.scss']
})
export class StatsOverviewComponent implements OnInit {
  @Input() emailStats = new EmailStats("{}");
  deliveredPercent:number=0;
  openedPercent: number=0;
  clickedPercent: number=0;
  undeliveredPercent: number= 0;
  optedOutPercent: number=0;
  constructor() { }

  /** Occurs upon component initialization */
  async ngOnInit(): Promise<void> {
    // obtain data for the page 

    this.deliveredPercent = this.emailStats.GetTotalEmails()>0 ? this.emailStats.GetDelivered()/this.emailStats.GetTotalEmails() : 0;
    this.openedPercent = this.emailStats.GetTotalEmails()>0 ? this.emailStats.GetOpened()/this.emailStats.GetTotalEmails() : 0;
    this.clickedPercent= this.emailStats.GetTotalEmails()>0 ? this.emailStats.GetClicked()/this.emailStats.GetTotalEmails() : 0;
    this.undeliveredPercent= this.emailStats.GetTotalEmails()>0 ? this.emailStats.GetUndelivered()/this.emailStats.GetTotalEmails() : 0;
    this.optedOutPercent= this.emailStats.GetTotalEmails()>0 ? this.emailStats.GetOptedOut()/this.emailStats.GetTotalEmails() : 0;


  }
  async getData(campaignId: any) {

  }

}
