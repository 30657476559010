import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { AduvoFieldModel, ImportModel, ImportResponse, ImportSaveModel, MappedJsonResponse } from 'src/models/ContactAndLoanImportModel';
import { RequestResponse } from 'src/models/RequestResponse';
import { ContactAndLoanService } from 'src/services/contactAndLoan.service';
import { ToastService } from 'src/services/toast.service';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/services/notificationService';
import { CampaignsService } from '../../../../src/services/campaigns.service'
import { UserDefineList } from '../../models/UserDefineList';
import { PeopleAndLoanService } from 'src/services/peopleandloans.service';

declare var _ps: any;
const maxStepIndex: number = 4;

@Component({
  selector: 'app-contact-and-loan-import',
  templateUrl: './contact-and-loan-import.component.html',
  styleUrls: ['./contact-and-loan-import.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactAndLoanImportComponent implements OnInit, OnDestroy {
  public importModel: ImportModel;
  public saveModel: ImportSaveModel;
  public isLoading: boolean;
  public user: UserToken;
  public importResponse: ImportResponse;
  public stepOneSaved: boolean;
  public stepTwoSaved: boolean;
  public importFormGroup: FormGroup | null;
  public items: FormArray | null;
  public tags: string[];
  public contactsXls: string;
  public loanXls: string;
  public isContractChecked: boolean;
  public missingMandatoryField: AduvoFieldModel[];
  public missingMandatoryStr: string;
  public dialogConfirmN: number;
  public userLists: any;
  private sub: Array<Subscription>;
  private systemTag: string;
  private inUsedFieldFlag: boolean;
  private tmpFlag: boolean;
  public showMessageDialog: boolean;
  public genericMessage: string;

  @Input() selectedList: UserDefineList;
  @Output() onCloseEvent = new EventEmitter<boolean>();
  @ViewChild('listUploader', { static: false }) listUploader: FileUpload;
  username: any;

  constructor(
    public formBuilder: FormBuilder,
    private toastService: ToastService,
    private contactAndLoanService: ContactAndLoanService,
    private userTokenService: UserTokenService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private campaignsService: CampaignsService,
    private peopleAndLoanService: PeopleAndLoanService,
  ) {
    window['_ps'] = null;
    this.user = this.userTokenService.getUser();
    this.importFormGroup = null;

    this.importModel = new ImportModel();//to bind
    this.saveModel = new ImportSaveModel();//to save  
    this.importResponse = new ImportResponse();//to get save response

    this.isLoading = false;
    this.stepOneSaved = false;
    this.stepTwoSaved = false;
    this.isContractChecked = false;
    this.sub = new Array<Subscription>();
    this.tags = [];

    this.systemTag = 'IMPORT_' + this.datePipe.transform(new Date(), 'yyyy-MM-dd-h-mm') + "_1"; //Import_yyyy-mm-dd-HH-MM_N
    this.tags.push(this.systemTag); //to add if removed
    this.inUsedFieldFlag = false;
    this.setClickWrap();
    this.contactsXls = environment.ContactImportTemplateXls;
    this.loanXls = environment.LoanImportTemplateXls;
    this.tmpFlag = false;
    this.missingMandatoryField = [];
    this.missingMandatoryStr = '';
    this.dialogConfirmN = 0;
    this.showMessageDialog = false;
  }

  ngOnInit(): void {
    this.getAduvoFields();//retrieve Aduvo field
    this.initializeClickWrap();
    this.getUsers();
  }

  getUsers() {
    this.campaignsService.getCompanyUserList().subscribe(
      (x: any) => {
        this.userLists = x.data;
        this.userLists.sort((a: { name: string; }, b: { name: any; }) => {
          return a.name.localeCompare(b.name);
        });
        //console.log(this.userName);
      },
      (error: any) => {
        console.error("Error fetching user data:", error);
      }
    );
  }

  //#region Steps
  async contactListUploadHandler(event: any) {
    // are any files selected
    if ((event.files == null) || (event.files.length == 0)) {
      return;
    }

    // even if someone set the mode to multiple, we only allow one file
    if (event.files[0].size > 5242880) {
      this.listUploader.clear();
      this.toastService.showError("The size of csv cannot exceed 5mb.");
      return;
    }
    this.importModel.mappedJsonResponse = [];
    this.saveModel.uploadedExcelFile = event?.files[0];
    //this.saveModel.confirmFlag = false;
    this.stepOneSaved = false;
    this.stepTwoSaved = false;
  }

  onRemoveFile(event: any) {
    this.saveModel.uploadedExcelFile = null;
    this.saveModel.confirmFlag = false;
    this.importResponse = new ImportResponse();//to get save response
    this.stepOneSaved = false;
    this.stepTwoSaved = false;
    this.importModel.aduvoFields = [];
    this.importModel.mappedJsonResponse = [];
  }
  //#endregion Step 1

  //#region Public
  public next(el: HTMLElement) {
    if (this.importModel.tabIndex > maxStepIndex) {
      return;
    }
    el?.scrollIntoView();
    var element = <HTMLInputElement>document.getElementById("ps-contract-checkbox-283501");
    var contractChecked = false;
    if (element) {
      contractChecked = element.checked;
    }

    if (this.importModel.tabIndex == 0) {
      if (!contractChecked) {
        this.showError("Please accept Import Contact & Loans Acknowledgment.");
        return;
      }

      if (!this.stepOneSaved) {
        this.setLoader(true);
        //Save file to azure and get header list of excel file. 
        let data = {
          tenantId: this.user.TenantId,
          tenantUserId: this.user.TenantUserId,
          contactOrLoanFile: this.saveModel.uploadedExcelFile
        }

        this.contactAndLoanService.importContactsAndLoans(data).subscribe((resp: RequestResponse) => {
          this.genericMessage = "";
          this.showMessageDialog = false;
          this.importResponse = resp.data;
          if (this.importResponse?.importBatchId) {
            this.stepOneSaved = true;

            this.importModel.aduvoFields = [];
            //get contact or loan fields
            this.importModel.aduvoFields = this.importModel.masterAduvoFields.filter(x => x.fileType.toLowerCase() == this.importResponse.importType.toLowerCase());
            this.generateForm();
            this.setNext();
          } else {
            if(resp.status == 403) {
              this.genericMessage = this.saveModel.uploadedExcelFile.name + " does not have any rows to import. Please check the file has data and try again.";
            } else {
              this.genericMessage = this.saveModel.uploadedExcelFile.name + " is not a valid Aduvo Contacts & Loans file. Please try again.";
            }
            
            this.showMessageDialog = true;
            //this.showError(this.genericMessage);
            this.setLoader(false);
          }
        }, err => {
          this.showError("Error while import, try again");
        });
      }
      else {
        this.importModel.showPreview = false;
        this.stepTwoSaved = false;
        this.setNext();
        return;
      }
    }

    //do not merge these statements
    if (this.importModel.tabIndex == 1 && this.importModel.showPreview) {
      //this.importModel.showPreview = false;
      //if error than show and do not save

      //check all mandatory field and show error message.
      let missingCnt = 0;
      let mandatoryFields = this.importModel.aduvoFields.filter(x => x.isMandatory);
      this.missingMandatoryField = [];

      if (mandatoryFields?.length) {
        let selected = this.importModel.mappedJsonResponse.map(z => z.AduvoField);
        this.missingMandatoryField = mandatoryFields.filter(x => selected.indexOf(x.fieldName) < 0);
        missingCnt = this.missingMandatoryField?.length ?? 0;
      }

      if (missingCnt) {
        this.importModel.showPreview = true;//keep preview open
        this.missingMandatoryStr = this.missingMandatoryField.map(x => x.fieldName).join(" ,");
        this.toastService.showError('( ' + this.missingMandatoryStr + ' ) ' + (missingCnt > 1 ? 'are' : 'is') + ' Mandatory Field(s), Please map to continue.');
        return;
      }

      this.importModel.showPreview = false;
      this.saveJson();
      return;
    }
    else if (this.importModel.tabIndex == 1) {
      if (this.inUsedFieldFlag) {
        this.toastService.showError('Field already added, Please select a different one.');
        return;
      }
      // //check all mandatory field and show error message.
      // let mandatoryFields = this.importModel.aduvoFields.filter(x => x.isMandatory);
      // if (mandatoryFields?.length) {
      //   let selected = this.importModel.mappedJsonResponse.map(z => z.AduvoField);
      //   let missing = mandatoryFields.filter(x => selected.indexOf(x.fieldName) < 0);
      //   let missingCnt = missing?.length ?? 0;
      //   if (missingCnt) {
      //     this.toastService.showError('( ' + missing.map(x => x.fieldName).join(" ,") + ' ) ' + (missingCnt > 1 ? 'are' : 'is') + ' Mandatory Field(s), Please map to continue.');
      //     return;
      //   }
      // }

      if (this.importModel.mappedJsonResponse.length && !this.stepTwoSaved) {
        this.importModel.showPreview = true;

        //check all mandatory field and show error message.
        let missingCnt = 0;
        let mandatoryFields = this.importModel.aduvoFields.filter(x => x.isMandatory);
        this.missingMandatoryField = []; this.missingMandatoryStr = '';

        if (mandatoryFields?.length) {
          let selected = this.importModel.mappedJsonResponse.map(z => z.AduvoField);
          this.missingMandatoryField = mandatoryFields.filter(x => selected.indexOf(x.fieldName) < 0);
          missingCnt = this.missingMandatoryField?.length ?? 0;
        }

        if (missingCnt) {
          this.importModel.showPreview = true;//keep preview open
          this.missingMandatoryStr = this.missingMandatoryField.map(x => x.fieldName).join(", ");
          this.toastService.showError('(' + this.missingMandatoryStr + ') ' + (missingCnt > 1 ? 'are' : 'is') + ' Mandatory Field(s), please map them first to continue.');
          return;
        }

        // let arr = JSON.parse(this.importResponse.headerJSON);
        // if (this.importModel.mappedJsonResponse.length < arr.length && !this.importModel.showPreview) {
        //   this.importModel.showPreview = true;
        // }
        // else {
        //   this.saveJson();
        // }
      } else if (this.importModel.mappedJsonResponse.length == 0) {
        this.showError("please select some mappings to proceed.");
      } else {
        this.setNext();
      }
    }

    else if (this.importModel.tabIndex == 2) {
      //todo: call api to update the owner
      this.contactAndLoanService.updateMappingJson(this.importResponse?.importBatchId, '', '', '', this.importResponse.defaultCompanyUserId).subscribe((resp: RequestResponse) => {
        this.setLoader(false);
        //this.closeMe();
        this.setNext();
      });
    }

    else if (this.importModel.tabIndex == 3) {
      //save tags amd show message
      if (this.tags.length && !this.tmpFlag) {
        this.tmpFlag = true;
        this.contactAndLoanService.updateMappingJson(this.importResponse?.importBatchId, '', '', JSON.stringify(this.tags), this.importResponse.defaultCompanyUserId).subscribe((resp: RequestResponse) => {
          this.selectedList = new UserDefineList();

          //debugger;
          // var tagId = "";
          // var tagIdString = "";
          // var firstTagName = this.tags[0].toString();

          // this.selectedList.filterJson = `{"scope": {"name": "all-loans", "LoanStatusTypes": []}, "PeopleLoanList": { "type": "", "PeopleLoanListAndFilters": [{ "id": 1, "PeopleLoanListOrFilters": [{ "id": 1, "operator": "EQ", "lhs": "", "rhs": ["${tagId}"], "peopleAndLoansTypeDefLookupId": "56B04149-EB22-4A65-8BE3-062662CA9DC0", "criteria": "(a.TagId IN (${tagIdString}))", "displayHtml": "<b class=\\"lhs\\">Tag</b> in <b class=\\"rhs\\">${firstTagName}</b>" }], "subGroup": null, "peopleAndLoansFieldLookupId": "31733835-F613-4B90-9D74-B5A391A2B3C2", "group": "People", "datasource": "vw_PeopleLoans_Query_ContactTag", "datacolumn": "a.TagId", "type": "TagList" }]}, "DisplayHtml": "<b class=\\"lhs\\">Tag</b> in <b class=\\"rhs\\">${firstTagName}</b>"}`;
          // this.selectedList.listTitle = this.tags[0].toString();
          //this.SaveList();

          let msg;
          if (this.importResponse?.importType == "LoanContact") {
            msg = "Your loan import request " + this.tags[0].toString() + " is in progress. You'll receive an email notification after the import is complete."
          } else if (this.importResponse?.importType == "Contact") {
            msg = "Your contact import request " + this.tags[0].toString() + " is in progress. You'll receive an email notification after the import is complete."
          } else {
            msg = "Some error occurred while importing file. Please upload file again.";
          }

          let notificationData = {
            activityId: this.importResponse?.activityId,
            importBatchId: this.importResponse?.importBatchId,
            message: msg
          }
          this.notificationService.CreateNotification(notificationData);
          //show success and close dialog.
          this.toastService.showSuccess(msg);

          this.setLoader(false);
          this.setNext();
        });
      }
    }
  }

  // async SaveList() {
  //   var response = await this.peopleAndLoanService.SaveList(this.selectedList);
  //   if (response.data == true) {
  //     let newMasterListId = response.message;

  //     this.contactAndLoanService.updateMappingJson(this.importResponse?.importBatchId, '', '', '', '', newMasterListId).subscribe((resp: RequestResponse) => {        
  //       let msg;
  //       if (this.importResponse?.importType == "LoanContact") {
  //         msg = "Your loan import request " + this.tags[0].toString() + " is in progress. You'll receive an email notification after the import is complete."
  //       } else if (this.importResponse?.importType == "Contact") {
  //         msg = "Your contact import request " + this.tags[0].toString() + " is in progress. You'll receive an email notification after the import is complete."
  //       } else {
  //         msg = "Some error occurred while importing file. Please upload file again.";
  //       }

  //       let notificationData = {
  //         activityId: this.importResponse?.activityId,
  //         importBatchId: this.importResponse?.importBatchId,
  //         message: msg
  //       }
  //       this.notificationService.CreateNotification(notificationData);
  //       //show success and close dialog.
  //       this.toastService.showSuccess(msg);
  //       this.setLoader(false);
  //     });
  //   }
  // }

  get formDataCtrl(): FormArray {
    const formArray = this.importFormGroup?.get('items') as FormArray;
    return formArray;
  }

  public previous(el: HTMLElement) {
    el?.scrollIntoView();
    if (this.importModel.tabIndex == 1 && this.importModel.showPreview) {
      this.importModel.showPreview = false;
    } else {
      this.importModel.showPreview = false;
      this.importModel.tabIndex = this.importModel.tabIndex < 0 ? 0 : this.importModel.tabIndex - 1; //goto previous page.
    }
    this.setLoader(false);
  }

  public fieldChanged(event, index) {//to hide show textbox and remove validations.
    this.inUsedFieldFlag = false;
    if (event?.value?.fieldName && this.importModel.mappedJsonResponse.find(x => x.AduvoField == event.value.fieldName)) {
      this.toastService.showError('Field already added, Please select a different one.');
      // event.originalEvent.stopImmediatePropagation()
      // event.originalEvent.preventDefault();
      this.inUsedFieldFlag = true;
      return;
    }
    // if (index > -1) {
    //   let k = this.importFormGroup?.get('items') as FormArray
    //   const lookupControl = k.controls[index]['controls'].aduvoField;
    //   //lookupControl?.clearValidators();//clear Validators
    //   lookupControl?.updateValueAndValidity();//validate right now
    //   this.cdr.detectChanges();
    // }
  }

  public closeMe() {
    this.onCloseEvent.emit(true);
  }

  public removeFilterClick(event: any, txt: string) {
    if (txt != this.systemTag) {//can't delete system tag.
      this.setLoader(true);

      let idx = this.tags.findIndex(x => x == txt);
      if (idx > -1) {
        this.tags.splice(idx, 1);
      }
      this.setLoader(false);
    }
  }

  public onChange(event) {
    if ((event.keyCode == 8 || event.keyCode == 46)) {
      let item = this.tags.find(z => z == this.systemTag);
      if (!item) {
        this.tags.push(this.systemTag); //to add if removed
      }
      //return false
    }
  }

  public onAdd(event) {
    if (event?.value) {
      let arr = this.tags.filter(z => z.toLowerCase() == event?.value?.toLowerCase());
      if (arr?.length > 1) {//duplicate exists
        let idx = this.tags.findIndex(z => z.toLowerCase() == arr[1]?.toLowerCase());
        this.tags.splice(idx, 1);
        this.toastService.showError("You can not add duplicate tags, Please try a unique tag Name!");
        return false;
      }
    }
  }

  //#endregion Public

  //#region private
  private saveJson() {
    this.setLoader(true);
    if (!this.stepTwoSaved) {
      let json = JSON.stringify(this.importModel.mappedJsonResponse, function (key, val) {
        if (key !== "FieldHeaderNameToMap")
          return val;
      });
      this.contactAndLoanService.updateMappingJson(this.importResponse?.importBatchId, json,
        this.importResponse.importType).subscribe((resp: RequestResponse) => {
          this.stepTwoSaved = true;
          this.setNext();
        });
    }
  }

  private generateForm() {
    this.unSubscribeAllSubs();
    this.importFormGroup = this.formBuilder.group({
      items: this.formBuilder.array(this.createItems())
    });
    let excelColumns = JSON.parse(this.importResponse.headerJSON);
    this.items = this.formDataCtrl;
    this.sub.push(
      this.importFormGroup.valueChanges.pipe(
        debounceTime(500),//delay and  stop unnecessary propagation.
        distinctUntilChanged()
      ).subscribe(item => {
        this.importModel.mappedJsonResponse = new Array<MappedJsonResponse>();
        this.importModel.unMappedHeaderArr = JSON.parse(this.importResponse.headerJSON);
        item.items.forEach((el) => {
          if (el?.aduvoField?.fieldName) {

            let index: number = excelColumns.findIndex(x => x == el.importField);
            index = index < 0 ? 0 : (index + 1);
            this.importModel.mappedJsonResponse.push(
              new MappedJsonResponse(el.importField, el.aduvoField.fieldName, el.aduvoField.fieldHeaderNameToMap, index))
            this.stepTwoSaved = false;
            //console.log(this.importModel.mappedJsonResponse);
            let idx = this.importModel.unMappedHeaderArr.findIndex((x) => x == el.importField);
            if (idx > -1) {
              this.importModel.unMappedHeaderArr.splice(idx, 1);
            }
          }
        });
      })
    );
  }

  private createItems(): FormGroup[] {
    const formGroupArr: FormGroup[] = [];
    let excelColumns = JSON.parse(this.importResponse.headerJSON);

    if (excelColumns?.length) {// crate object if 
      this.importModel.mappedJsonResponse = new Array<MappedJsonResponse>();
      this.importModel.unMappedHeaderArr = JSON.parse(this.importResponse.headerJSON);

      excelColumns.forEach((excelCol) => {
        let toMap: AduvoFieldModel = this.importModel.aduvoFields.find(x => x.fieldHeaderNameToMap?.toLowerCase() == excelCol?.toLowerCase());
        formGroupArr.push(
          this.formBuilder.group({
            importField: new FormControl(excelCol),
            aduvoField: new FormControl(toMap)
          })
        );

        if (toMap?.aduvoFieldID) {
          let index: number = excelColumns.findIndex(x => x == excelCol);
          index = index < 0 ? 0 : (index + 1);
          this.importModel.mappedJsonResponse.push(new MappedJsonResponse(excelCol, toMap.fieldName, toMap.fieldHeaderNameToMap, index))
          let idx = this.importModel.unMappedHeaderArr.findIndex((x) => x == excelCol);
          if (idx > -1) {
            this.importModel.unMappedHeaderArr.splice(idx, 1);
          }
        }
      });
    }
    return formGroupArr;
  }

  private setClickWrap() {
    if (window['_ps'] == null || window['_ps'] == undefined) {
      (function (w, d, s, c, f, n, t, g, a, b, l) {
        // Defines the global _ps object and initializes the _ps() function
        // that will queue commands until the Ironclad Clickwrap Library is ready.
        w['PactSafeObject'] = n;
        w[n] = w[n] || function () {
          (w[n].q = w[n].q || []).push(arguments);
        },

          // Defines the event functions for the global _ps object.
          w[n].on = function () {
            (w[n].e = w[n].e || []).push(arguments);
          },
          w[n].once = function () {
            (w[n].eo = w[n].eo || []).push(arguments);
          },
          w[n].off = function () {
            (w[n].o = w[n].o || []).push(arguments);
          },

          // Marks the time that the script is inserted.
          w[n].t = 1 * new Date().getTime(),
          w[n].l = 0;

        // Inserts a new script element to load the Ironclad Clickwrap Library JS file (ps.js).
        a = d.createElement(s);
        b = d.getElementsByTagName(s)[0];
        a.async = 1;
        a.src = c;

        // Marks that the script has started loading or failed to load.
        a.onload = a.onreadystatechange = function () { w[n].l = 1; };
        a.onerror = a.onabort = function () { w[n].l = 0; };
        b.parentNode.insertBefore(a, b);

        // Retry loading the script from a fallback location after 4 seconds.
        setTimeout(function () {
          if (!w[n].l && !w[n].loaded) {
            w[n].error = 1;
            a = d.createElement(s);
            a.async = 1;
            a.src = f;
            a.onload = a.onreadystatechange = function () { w[n].l = 1; };
            a.onerror = a.onabort = function () { w[n].l = 0; };
            b.parentNode.insertBefore(a, b);

            // Log the loading error via beacon.
            l = function (u, e) {
              try {
                e = d.createElement('img');
                e.src = 'https://d3r8bdci515tjv.cloudfront.net/error.gif?t=' + w[n].t + '&u=' + encodeURIComponent(u);
                d.getElementsByTagName('body')[0].appendChild(e);
              }
              catch (x) { }
            };
            l(c);

            // Call the optional error callback function after a second failed attempt.
            setTimeout(function () {
              if (!w[n].l && !w[n].loaded) {
                w[n].error = 1;
                if (g && 'function' == typeof g) {
                  g.call(this);
                }
                l(f);
              }
            }, t);
          }
        }, t);
      })(window, document, 'script', '//vault.pactsafe.io/ps.min.js', '//d3l1mqnl5xpsuc.cloudfront.net/ps.min.js', '_ps', 4000, function optionalErrorCallback() { alert('Unable to load the JS Library.'); });

      // Creates a Site object with the default configuration.
      _ps('create', environment.ironcladSiteId);
    }
  }

  private initializeClickWrap() {
    var groupKey = "embedded-recurringpayments-copy";
    _ps("load", groupKey, { container_selector: "clickwrapContainer", signer_id: this.user.email, display_all: true });
    _ps("set", "signer_id", this.user.email);
    _ps("send", "updated", { custom_data: { first_name: this.user.given_name, last_name: this.user.family_name, company_name: this.user.TenantName, lender_id: this.user.TenantId } });

    // Submits the form once the contracts have been accepted.
    _ps.on('valid', function (parameters, group) {
      this.isContractChecked = true;
      this.cdr.detectChanges();
    }.bind(this));

    // call once the contracts have been unchecked.
    _ps.on('invalid', function (parameters, group) {
      this.isContractChecked = false;
      this.cdr.detectChanges();
    }.bind(this));

    /******************************
     * Tracking acceptance and getting the contract
     * Acceptance of a Clickwrap Group, by default, is sent when your signer checks a checkbox or clicks the “I Agree” button. To send
     * acceptance outside of that default workflow, you can follow the below syntax:
     * Note: Sending acceptance like this requires a callback
    *******************************/
    _ps(groupKey, "agreed", {
      // The event_callback function will be invoked once the "send" is complete.
      event_callback: function (err, eventType, clickwrapGroup, payload) {
        if (err) {
          console.log(err);
        }
        // The send is complete and acceptance was captured successfully.
      }.bind(this)
    });
  }

  private getAduvoFields() {
    this.isLoading = true;
    this.contactAndLoanService.getAduvoFields().subscribe((resp: RequestResponse) => {
      if (resp?.status == 200 && resp?.data) {
        this.importModel.masterAduvoFields = resp.data;
      }
      this.setLoader(false);
    }, (err) => {
      this.setLoader(false);
      this.toastService.showError("Error occurred!");
    });
  }

  private setLoader(isLoading: boolean) {
    this.isLoading = isLoading;
    this.cdr.detectChanges();
  }

  private showError(msg: String) {
    this.toastService.showError(msg ? msg : 'Error while import.');
    if (this.importModel.tabIndex == 0) {
      //this.listUploader.clear();
      this.saveModel.confirmFlag = false;
    }
    this.setLoader(false);
  }

  private setNext() {
    this.importModel.tabIndex = this.importModel.tabIndex >= maxStepIndex ? maxStepIndex : this.importModel.tabIndex + 1; //goto next page.    
    this.setLoader(false);
  }

  private unSubscribeAllSubs() {
    if (this.sub?.length) {
      this.sub.forEach(element => {
        if (element) {
          element.unsubscribe();
        }
      });
      this.sub = [];
    }
  }
  //#endregion private

  ngOnDestroy(): void {
    this.unSubscribeAllSubs();
    this.importModel = null;
    this.saveModel = null;
    this.listUploader = null;
  }
}
