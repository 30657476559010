

<!--
*************************************************************************************
Campaigns Management Grid
*************************************************************************************
-->
<div #campaignsGrid [style]="!campaignManagementPagesState.showEditor ? 'display:block' : 'display:none'">
  <campaignGrid-root [campaignManagementPagesState]="campaignManagementPagesState" (onShowNewCampaignDialog)="onShowNewCampaignDialog($event)"></campaignGrid-root>
</div>

<!--
*************************************************************************************
Campaign Editor Form
*************************************************************************************
-->
<!-- <div #campaignEditor *ngIf="campaignManagementPagesState.showEditor">
  <campaignEditor-root [(campaignManagementPagesState)]="campaignManagementPagesState"></campaignEditor-root>
</div> -->

<!--
*************************************************************************************
Create Campaign Dialog
*************************************************************************************
-->
<!-- <p-dialog #createCampaignDialog [(visible)]="showNewCampaignWizardVisible"
          [resizable]="false" [modal]="true"
          [style]="{'width': '102.5em','font-weight': '700'}">
  <p-header>
    <i class="pi pi-send" style="font-size:1.3rem; margin-right:0.5rem;"></i>
    <span class="dialog-head">New Campaign</span>
  </p-header>
  <div class="card-hr"></div>
  <createCampaign-root></createCampaign-root>
  <div class="card-hr"></div>
  <ng-template pTemplate="footer">-->
    <!-- <p-button label="Back" icon="pi pi-angle-left" styleClass="p-button-secondary" (onClick)="onCreateCampaignDialogPrevClicked()" *ngIf="marketingCampaignsState.createCampaignWizardState.currentStep.step > 1 && !marketingCampaignsState.createCampaignWizardState.templatePreviewVisible && !marketingCampaignsState.createCampaignWizardState.templateReportVisible"></p-button> -->
    <!-- <p-button label="Next" icon="pi pi-angle-right" iconPos="right" (onClick)="onCreateCampaignDialogNextClicked()" *ngIf="marketingCampaignsState.createCampaignWizardState.currentStep.step < 4 && !marketingCampaignsState.createCampaignWizardState.templatePreviewVisible && !marketingCampaignsState.createCampaignWizardState.templateReportVisible" [disabled]="!marketingCampaignsState.createCampaignWizardState.validateSteps()"></p-button> -->
    <!-- <p-button label="Save" icon="pi pi-save" (onClick)="onCreateCampaignDialogSaveClicked()" *ngIf="marketingCampaignsState.createCampaignWizardState.currentStep.step === 4" [disabled]="!marketingCampaignState.createCampaignWizardState.validateSteps()"></p-button> -->
  <!--  <p-button (click)="onCloseNewCampaignDialog()" label="Cancel" styleClass="p-button-secondary"></p-button>
  </ng-template>
</p-dialog> -->