<!-- Skeleton - displayed while getting data -->
<p-carousel [value]="skeletonData" [numVisible]="4" [numScroll]="4" [circular]="false">
  <ng-template let-skeletonData pTemplate="item">
    <div class="product-item" style="padding-right:1em;">
      <div class="product-item-content" style="padding: 8px; box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); border-style: solid !important; border-width: 2px !important; border-color: lightgray !important;">
        <div style="margin-top:-1.5em">
          <div class="p-d-flex p-jc-between p-mt-3">
            <div class="p-d-flex p-mt-3">
              <p-skeleton width="1rem" height="1rem"></p-skeleton>&nbsp;<p-skeleton width="5rem" height="1rem"></p-skeleton>
            </div>
            <div class="p-d-flex p-mt-3">
              <p-skeleton width="1rem" height="1rem"></p-skeleton>&nbsp;<p-skeleton width="3rem" height="1rem"></p-skeleton>
            </div>
          </div>
          <p-skeleton width="100%" height="150px"></p-skeleton>
          <div class="p-d-flex p-jc-between p-mt-3">
            <p-skeleton width="10rem" height="2rem"></p-skeleton>
            <p-skeleton width="3rem" height="1rem"></p-skeleton>
          </div>
          <div style="margin-top:.5rem">
            <p-skeleton width="100%" height="50px"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-carousel>


