import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { formState } from 'src/helpers/formState.helper';
import { PagingData } from 'src/models/PagingData';
import { RequestResponse } from 'src/models/RequestResponse';
import { Table } from 'primeng/table';
import { UserToken } from 'src/models/UserToken';
import { ToastService } from 'src/services/toast.service';
import { UserTokenService } from 'src/services/user-token.service';
import { UserManagementService } from 'src/services/userManagement.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageHelper } from '../../../helpers/StorageHelper';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmModalComponent } from 'src/components/marketing/designlibrary/dialogs/confirm-modal/confirm-modal.component';
import { BillingOwner, SubscriptionType } from 'src/models/enumerations';
import { BillingService } from 'src/services/billingService';
import { forkJoin } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'userGrid-root',
  templateUrl: './userGrid.component.html',
  styleUrls: ['./userManagement.component.scss'],
})
export class userGridComponent extends formState implements OnInit {
  @ViewChild('manageUserTableId', { static: false }) dataTable: Table;
  @ViewChild('ellipseMenu', { static: false }) ellipseMenu: Menu;

  webAPI: RequestResponse = new RequestResponse();
  selectedUser: any;
  userList = [];

  public loadingPage: boolean = false;
  showErrors: boolean = false;

  sortField = 'name';
  sortOrder = -1;
  defaultOrderBy = 'name ASC';
  addUserDisabled: string = 'disabled';
  contextMenuItems: MenuItem[];
  searchKeyword: string = '';
  refreshRequired: boolean = false;
  userToken: UserToken;
  showDeleteDialog: boolean = false;
  showRemoveFromPulseDialog: boolean = false;
  showResetPasswordDialog: boolean = false;
  showMultiRemoveUserDialog: boolean = false;
  showMultiRemoveLOSUserDialog = false;
  deleteMessage: string = '';
  resetPasswordMessage: string = '';
  removeMessage: string = '';
  showResetTFADialog: boolean = false;
  showChangeEmailDialog: boolean = false;
  emailChangeFail: boolean = false;
  isValidChangeEmail: boolean = false;
  currentEmailId: string = '';
  changeUserEmailAddress: string = '';
  blankEmailAddress: string = '';
  currentClientID: string;
  storageHelper: StorageHelper;
  public pageIndex: number = 0;
  public subscriptionType = SubscriptionType;
  public billingOwner = BillingOwner;
  showUpdateSubscriptionDialog = false;
  selectedSubscriptionType;
  selectedBillingOwner;
  billingOwners: any;
  subscriptionTypes: any;
  isSubscriptionDialogInitiate = true;
  selectedRows: any[];
  isMultiUserPasswordResetRequest = false;
  isMultiUserRemoveRequest = false;
  isMultiLOSUserRemoveRequest = false;
  limitedSubscriptionTypId = '';
  companyBillingOwnerId = '';
  public showGenericMessageDialog: boolean = false;
  public genericMessage: string = '';
  public showPremiumUserValidationDialog: boolean = false;
  public premiumUserValidationMessage: string = '';
  public isMultiUserSelected: boolean = false;
  public nmlsUserString: string = "";

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private userTokenService: UserTokenService,
    private userManagementService: UserManagementService,
    private router: Router,
    public dialogService: DialogService,
    private billingService: BillingService
  ) {
    super();
    this.contextMenuItems = this.getContextMenuItems();
    this.webAPI.paging.pageSize = 25;
    this.webAPI.paging.orderBy = this.defaultOrderBy;
    this.currentClientID = environment.clientId;
    this.storageHelper = new StorageHelper();
  }

  async ngOnInit(): Promise<void> {
    this.userToken = this.userTokenService.getUser();
    this.webAPI = new RequestResponse();
    this.loadingPage = true;
    this.loadBillingData();
  }

  getContextMenuItems(): MenuItem[] {
    let result = [
      {
        label: 'Edit',
        //icon: 'fa fa-pencil pencil',
        command: () => this.onEdit(event),
      },
      {
        label: 'Delete',
        //icon: 'pi pi-trash',
        command: () => this.onDelete(event),
      },
      {
        label: 'Reset Password',
        //icon: 'pi pi-key',
        command: () => this.onResetPassword(event),
      },
      {
        label: 'Add to Aduvo',
        //icon: 'pi pi-user-plus',
        command: () => this.onAddToPulse(event),
      },
      {
        label: 'Remove from Aduvo',
        //icon: 'pi pi-user-minus',
        command: () => this.onRemoveFromPulse(event),
      },
      {
        label: 'Resend Confirmation',
        //icon: 'pi pi-send',
        command: () => this.onSendConfirmation(event),
      },
      {
        label: 'Reset Two Factor',
        //icon: 'pi pi-undo',
        command: () => this.onRsetTwoFactor(event),
      },
      {
        label: 'Reactivate',
        //icon: 'pi pi-user-plus',
        command: () => this.onReactivate(event),
      },
      {
        label: 'Change Email',
        //icon: 'fa fa-pencil pencil',
        command: () => this.onChangeEmail(event),
      },
      {
        label: 'Update Subscription',
        //icon: 'fa fa-pencil pencil',
        command: () => this.onUpdateSubscription(event),
      },
    ];
    return result;
  }

  AddNewUser(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  onGlobalFilterChange() {
    if (this.searchKeyword.length == 0) this.onApplyGlobalFilter();
  }

  async onApplyGlobalFilter() {
    this.webAPI.paging.currentPage = 1;
    this.pageIndex = 0;
    setTimeout(() => {
      this.refreshData();
    });
  }

  async getData() {
    // start the progress bar/spinner or display a skeleton.
    this.loadingPage = true;
    try {
      this.webAPI.data = [];
      if (this.searchKeyword.length > 0) {
        let columnValue = this.searchKeyword;
        this.webAPI.data = columnValue;
      }
      this.webAPI.paging.pagingRead = true;
      // assign returned object to class variable.
      var response = await this.billingService.GetAllUsers(this.webAPI);

      this.webAPI, false; // Required - do not remove.
      this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;
      this.webAPI.data = response.data;
      this.userList = this.webAPI.data;
      this.userList.slice();
      this.loadingPage = false;
    } catch (message: any) {
      this.webAPI.paging.totalRecords = 0;
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async loadUsers(event: LazyLoadEvent) {
    let orderBy = this.defaultOrderBy;
    if (event.sortField == null || event.sortField == undefined) return;

    this.sortField = event.sortField.toString();
    this.sortOrder = event.sortOrder;
    orderBy = this.setSort(this.sortField, this.sortOrder);

    this.webAPI.paging.currentPage = event.first / event.rows + 1; // First row offset
    this.webAPI.paging.pageSize = event.rows; //Number of rows per page
    this.webAPI.paging.orderBy = orderBy;

    setTimeout(() => {
      this.refreshData();
    });
  }

  setSort(columnName: any, order: any) {
    var orderBy = this.defaultOrderBy;
    this.sortOrder = order;

    if (columnName == null || columnName == undefined) return;
    // set sort chip label and order by clause
    if (order == -1) {
      orderBy = columnName + ' DESC';
    } else {
      orderBy = columnName + ' ASC';
    }
    return orderBy;
  }

  onGotoPage() {
    if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
      this.refreshData();
    }
  }

  async onCreateUserClick(event: any) { }

  async onEllipseClick(event: any, user: any) {
    //debugger;
    this.ellipseMenu.toggle(event);
    this.selectedUser = user;
    let userType = this.selectedUser.userType.toUpperCase();
    this.contextMenuItems[0].visible = false; //Edit
    this.contextMenuItems[1].visible = false; //Delete
    this.contextMenuItems[2].visible = false; //Reset Password
    this.contextMenuItems[3].visible = false; //Add to Aduvo
    this.contextMenuItems[4].visible = false; //Remove from Aduvo
    this.contextMenuItems[5].visible = false; //Send Confirmation
    this.contextMenuItems[6].visible = false; //Reset TFA
    this.contextMenuItems[7].visible = false; //Reactivate
    this.contextMenuItems[8].visible = true; //Change Email
    this.contextMenuItems[9].visible = false; //Change Email
    if (userType == 'ADD TO ADUVO') {
      this.contextMenuItems[3].visible = true;
    } else if (userType == 'REACTIVATE') {
      this.contextMenuItems[7].visible = true;
    } else {
      this.contextMenuItems[0].visible = true;
      this.contextMenuItems[6].visible = this.selectedUser.isTwoFactorEnabled;
      this.contextMenuItems[2].visible = this.selectedUser.isEmailConfirmed;
      this.contextMenuItems[5].visible = !this.selectedUser.isEmailConfirmed;
      this.contextMenuItems[9].visible = true;
      if (this.selectedUser.losUser.toUpperCase() == 'YES') {
        this.contextMenuItems[4].visible = true;
      } else {
        this.contextMenuItems[1].visible = true;
      }
    }
  }

  async refreshData() {
    await this.getData();
  }

  onDelete(event: any) {
    this.deleteMessage =
      "Do you want to delete '" + this.selectedUser.name + "'?";
    this.showDeleteDialog = true;
  }

  async deleteUser() {
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      let response = await this.userManagementService.DeleteUser(this.selectedUser.tenantUserId);
      let deleteUserId = [];
      deleteUserId.push({ 'tenantUserId': this.selectedUser.tenantUserId });
      await this.billingService.DeleteTenantUsersFromBillingOwner(deleteUserId);
      this.storageHelper.ClearUserList(this.selectedUser?.tenantId);
      this.storageHelper.ClearUserList('');
      this.showDeleteDialog = false;
      this.loadingPage = false;
      this.refreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  onRemoveFromPulse(event: any) {
    this.deleteMessage = "Please confirm you want to remove '" + this.selectedUser.name + "' from Aduvo?";
    this.showRemoveFromPulseDialog = true;
  }

  async removeFromPulse() {
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      let response = await this.userManagementService.RemoveFromPulse(this.selectedUser.companyUserId, this.selectedUser.tenantUserId);

      let data = [];
      data.push({ 'tenantUserId': this.selectedUser.tenantUserId, 'companyUserId': this.selectedUser.companyUserId, 'isRemoveFromAduvo': true });
      await this.userManagementService.EditCompanyUser(data);

      let deleteUserId = [];
      deleteUserId.push({ 'tenantUserId': this.selectedUser.tenantUserId });
      await this.billingService.DeleteTenantUsersFromBillingOwner(deleteUserId);
      this.showRemoveFromPulseDialog = false;
      this.loadingPage = false;
      this.refreshData();
      this.toastService.showSuccess('User removed from Aduvo');
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  onDeleteUsers() {
    if (this.selectedRows && this.selectedRows.length) {
      this.showMultiRemoveUserDialog = false;
      this.isMultiUserRemoveRequest = false;
      this.showGenericMessageDialog = false;
      let isAvailable = false;
      this.selectedRows.forEach(element => {
        if (element.losUser.toUpperCase() == "NO") {
          isAvailable = true;
          return;
        }
      });
      if (isAvailable) {
        this.showMultiRemoveUserDialog = true;
        this.isMultiUserRemoveRequest = true;
        this.removeMessage = "Do you want to delete the following user(s)?";
      }
      else {
        this.showGenericMessageDialog = true;
        this.genericMessage = "No user found to remove from the selected user(s).";
      }
    }
  }

  onRemoveFromAduvoUsers() {
    if (this.selectedRows && this.selectedRows.length) {
      this.showMultiRemoveLOSUserDialog = false;
      this.isMultiLOSUserRemoveRequest = false;
      this.showGenericMessageDialog = false;
      let isAvailable = false;
      this.selectedRows.forEach(element => {
        if (element.losUser.toUpperCase() == "YES") {
          isAvailable = true;
          return;
        }
      });
      if (isAvailable) {
        this.showMultiRemoveLOSUserDialog = true;
        this.isMultiLOSUserRemoveRequest = true;
        this.removeMessage = "Do you want to remove the following user(s) from Aduvo?";
      }
      else {
        this.showGenericMessageDialog = true;
        this.genericMessage = "No Aduvo user found to remove from the selected user(s).";
      }
    }
  }

  async RemoveConfirmation() {
    let userIds = [];
    let deleteUserIds = [];
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      if (this.isMultiUserRemoveRequest) {
        if (this.selectedRows) {
          this.selectedRows.forEach(element => {
            if (element.losUser.toUpperCase() == "NO") {
              userIds.push({ 'tenantUserId': element.tenantUserId, 'companyUserId': '', 'isRemoveFromAduvo': false });
              deleteUserIds.push({ 'tenantUserId': element.tenantUserId });
            }
          });
          let data = userIds;
          await this.userManagementService.RemoveUsers(data);

          let deleteData = deleteUserIds;
          await this.billingService.DeleteTenantUsersFromBillingOwner(deleteData);
        }
      }
      this.showMultiRemoveUserDialog = false;
      this.loadingPage = false;
      this.refreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async RemoveLOSUserConfirmation() {
    let userIds = [];
    let deleteUserIds = [];
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      if (this.isMultiLOSUserRemoveRequest) {
        if (this.selectedRows) {
          this.selectedRows.forEach(element => {
            if (element.losUser.toUpperCase() == "YES") {
              userIds.push({ 'tenantUserId': element.tenantUserId, 'companyUserId': element.companyUserId, 'isRemoveFromAduvo': true });
              deleteUserIds.push({ 'tenantUserId': element.tenantUserId });
            }
          });
          let data = userIds;
          await this.userManagementService.RemoveUsers(data);
          await this.userManagementService.EditCompanyUser(data);

          let deleteData = deleteUserIds;
          await this.billingService.DeleteTenantUsersFromBillingOwner(deleteData);
        }
      }
      this.showMultiRemoveLOSUserDialog = false;
      this.loadingPage = false;
      this.refreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  onRsetTwoFactor(event: any) {
    this.showResetTFADialog = true;
  }

  async resetRsetTwoFactorConfirmation() {
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      let response = await this.userManagementService.ResetTwoFactor(
        this.selectedUser.tenantUserId
      );
      if (response.status == 200) {
        this.toastService.showSuccess('Reset two factor flag successfully');
      } else {
        this.toastService.showError('Error updating two factor flag!');
      }
      this.showResetTFADialog = false;
      this.loadingPage = false;
      this.refreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  onResetPassword(event: any) {
    this.isMultiUserPasswordResetRequest = false;
    this.selectedRows = [];
    this.showResetPasswordDialog = true;
    this.resetPasswordMessage = "Do you want to reset the password of '" + this.selectedUser.name + "'?";
  }

  async resetPasswordConfirmation() {
    let userIds = [];
    let response;
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      if (this.isMultiUserPasswordResetRequest) {
        if (this.selectedRows) {
          this.selectedRows.map(s => {
            userIds.push({ 'tenantUserId': s.tenantUserId });
          });

          let data = userIds;
          response = await this.billingService.resetPasswords(data);
        }
      } else {
        response = await this.userManagementService.ResetPassword(
          this.selectedUser.tenantUserId
        );
      }

      if (response.status == 200) {
        let successMessage = '';
        if (this.isMultiUserPasswordResetRequest) {
          successMessage = 'Instructions to reset the password were sent to selected user(s).';
        } else {
          successMessage = 'Instructions to reset the password were sent to ' + this.selectedUser.emailId + '.';
        }
        this.toastService.showSuccess(successMessage);
      } else {
        this.toastService.showError('Reset password email sent fail');
      }
      this.showResetPasswordDialog = false;
      this.loadingPage = false;
      this.refreshData();
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  onRowEdit(event: any) {
    this.router.navigate(['account/manage-users/edit'], {
      state: {
        tenantUserKey: event.tenantUserId,
        tenantId: event.tenantId,
        companyUserId: event.companyUserId,
        name: event.name,
        emailId: event.emailId,
        phoneNumber: event.phoneNumber,
        losUser: event.losUser,
        companyUserFax: event.companyUserFax,
        companyUserWorkPhone: event.companyUserWorkPhone,
        companyUserNmls: event.companyUserNmls,
        companyUserTenantId: event.companyUserTenantId,
        companyUserTenantUserId: event.companyUserTenantUserId,
      },
    });
  }

  onRowReactivate(event: any, user: any) {
    const myArray = user.name.split(',');
    let firstName = myArray[1].trim();
    let lastName = myArray[0].replace(',', '');

    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message:
          "Are you sure you want '" +
          firstName +
          ' ' +
          lastName +
          "' reactivated?",
        successButtonText: 'Yes',
        cancelButtonText: 'No',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        //Actual logic to perform upon confirmation
        this.ReactivateUser(event, user);
      }
    });
  }

  onEdit(event: any) {
    this.router.navigate(['account/manage-users/edit'], {
      state: {
        tenantUserKey: this.selectedUser.tenantUserId,
        companyUserId: this.selectedUser.companyUserId,
        name: this.selectedUser.name,
        emailId: this.selectedUser.emailId,
        phoneNumber: this.selectedUser.phoneNumber,
        losUser: this.selectedUser.losUser,
        companyUserFax: event.companyUserFax,
        companyUserWorkPhone: event.companyUserWorkPhone,
        companyUserNmls: event.companyUserNmls,
        companyUserTenantId: event.companyUserTenantId,
        companyUserTenantUserId: event.companyUserTenantUserId,
      },
    });
  }

  async onSendConfirmation(event: any) {
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      let response = await this.userManagementService.SendConfirmation(
        this.selectedUser.tenantUserId
      );
      this.loadingPage = false;
      this.refreshData();
      let successMessage =
        'Email confirmation was sent to ' + this.selectedUser.emailId + '.';
      this.toastService.showSuccess(successMessage);
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onAddToPulse(event: any) {
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      this.router.navigate(['account/manage-users/add'], {
        state: {
          tenantUserKey: this.selectedUser.tenantUserId,
          tenantId: this.selectedUser.tenantId,
          companyUserId: this.selectedUser.companyUserId,
          name: this.selectedUser.name,
          emailId: this.selectedUser.emailId,
          phoneNumber: this.selectedUser.phoneNumber,
          losUser: this.selectedUser.losUser,
          companyUserFax: this.selectedUser.companyUserFax,
          companyUserWorkPhone: this.selectedUser.companyUserWorkPhone,
          companyUserNmls: this.selectedUser.companyUserNmls,
          companyUserTenantId: this.selectedUser.companyUserTenantId,
          companyUserTenantUserId: this.selectedUser.companyUserTenantUserId,
        },
      });

      this.loadingPage = false;
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  async onReactivate(event: any) {
    const myArray = this.selectedUser.name.split(',');
    let firstName = myArray[1].trim();
    let lastName = myArray[0].replace(',', '');

    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message:
          "Are you sure you want '" +
          firstName +
          ' ' +
          lastName +
          "' reactivated?",
        successButtonText: 'Yes',
        cancelButtonText: 'No',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        //Actual logic to perform upon confirmation
        this.ReactivateUser(event, this.selectedUser);
      }
    });
  }

  async ReactivateUser(event: any, user: any) {
    this.loadingPage = true;
    try {
      this.selectedUser = user;
      var response = await this.userManagementService.Reactivate(
        this.selectedUser.companyUserId,
        this.selectedUser.emailId,
        true
      );
      this.loadingPage = false;
      if (response.status == 0) {
        this.toastService.showError(response.message);
      } else if (response.status == 200) {
        let mes =
          'User was reactivated and emailed the instructions to ' +
          this.selectedUser.emailId;
        this.toastService.showSuccess(mes);
      }
      this.refreshData();
      //clear cache
      this.storageHelper.ClearUserList(this.selectedUser.tenantId);
    } catch (message: any) {
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  onChangeEmail(event: any) {
    this.emailChangeFail = false;
    this.blankEmailAddress = '';

    const myArray = this.selectedUser.name.split(',');
    let firstName = myArray[1].trim();
    let lastName = myArray[0].replace(',', '');

    this.changeUserEmailAddress =
      'Change ' + firstName + ' ' + lastName + ' Email Address';
    this.currentEmailId = this.selectedUser.emailId;
    this.showChangeEmailDialog = true;
    this.validateUserChangeEmail();
  }

  async onUpdateSubscription(event: any) {
    if (this.selectedUser.companyUserNmls == '' || this.selectedUser.companyUserNmls == null || this.selectedUser.companyUserNmls === undefined) {
      this.isMultiUserSelected = false;
      this.showPremiumUserValidationDialog = true;
      this.premiumUserValidationMessage = this.selectedUser.name + "'s profile does not include all of the information required to meet compliance standards. Please ensure that their First Name, Last Name, and NMLS number have been set under their user profile.";
    } else {
      this.isSubscriptionDialogInitiate = true;
      this.showUpdateSubscriptionDialog = true;
      this.selectedRows = [this.selectedUser];
      this.selectedSubscriptionType = this.limitedSubscriptionTypId;
      this.selectedBillingOwner = this.companyBillingOwnerId;
      if (this.selectedUser) {
        let subscriptionTypeobj = this.subscriptionTypes.find(t => t.name === this.selectedUser.subscriptionType);
        if (subscriptionTypeobj) {
          this.selectedSubscriptionType = subscriptionTypeobj.lookupid;
        }
        let billingOwnerobj = this.billingOwners.find(t => t.name === this.selectedUser.billingOwner);
        if (billingOwnerobj) {
          this.selectedBillingOwner = billingOwnerobj.lookupid;
        }
      }
    }
  }

  onResetPasswords() {
    if (this.selectedRows && this.selectedRows.length) {
      this.showResetPasswordDialog = false;
      this.isMultiUserPasswordResetRequest = false;
      this.showGenericMessageDialog = false;
      let isAvailable = false;
      this.selectedRows.forEach(element => {
        if (element.isEmailConfirmed) {
          isAvailable = true;
          return;
        }
      });
      if (isAvailable) {
        this.showResetPasswordDialog = true;
        this.isMultiUserPasswordResetRequest = true;
        this.resetPasswordMessage = "Do you want to reset the password of following user(s)?";
      }
      else {
        this.showGenericMessageDialog = true;
        this.genericMessage = "Email is not confirmed for the selected user(s).";
      }
    }
  }

  onChangeSubscription() {
    this.nmlsUserString = "";
    if (this.selectedRows && this.selectedRows.length) {
      let blankNmlsFound = false;
      let countUsers = 0;
      let blankNmlsUserName = '';
      this.selectedRows.forEach(element => {
        if ((element.companyUserNmls == "" || element.companyUserNmls == null || element.companyUserNmls === undefined)) {
          blankNmlsFound = true;
          blankNmlsUserName = element.name;
          this.nmlsUserString += '<li>' + element.name + '</li>';
          countUsers += 1;
        }
      });

      if (blankNmlsFound) {
        this.showPremiumUserValidationDialog = true;
        if(countUsers == 1 && this.selectedRows.length == 1) {
          this.isMultiUserSelected = false;
          this.premiumUserValidationMessage = blankNmlsUserName + "'s profile does not include all of the information required to meet compliance standards. Please ensure that their First Name, Last Name, and NMLS number have been set under their user profile.";
        } else {
          this.isMultiUserSelected = true;
          this.premiumUserValidationMessage = "Some of the selected users' profiles do not include all of the information required to meet compliance standards. Please ensure that the listed users' First Name, Last Name, and NMLS numbers have been set under their user profiles.";
        }
      } else {
        this.isSubscriptionDialogInitiate = true;
        this.showUpdateSubscriptionDialog = true;
        this.selectedSubscriptionType = this.limitedSubscriptionTypId;
        this.selectedBillingOwner = this.companyBillingOwnerId;
        if (this.selectedRows && this.selectedRows.length == 1) {
          let subscriptionTypeobj = this.subscriptionTypes.find(t => t.name === this.selectedRows[0].subscriptionType);
          if (subscriptionTypeobj) {
            this.selectedSubscriptionType = subscriptionTypeobj.lookupid;
          }
          if (this.selectedRows[0].billingOwner) {
            let billingOwnerobj = this.billingOwners.find(t => t.name === this.selectedRows[0].billingOwner);
            if (billingOwnerobj) {
              this.selectedBillingOwner = billingOwnerobj.lookupid;
            }
          }
        }
      }
    }
  }

  validateUserChangeEmail() {
    const input = document.getElementById(
      'newEmailAddress'
    ) as HTMLInputElement | null;
    const newEmailAddress = input?.value;

    if (newEmailAddress == '' || newEmailAddress === undefined) {
      this.isValidChangeEmail = true;
    } else {
      this.isValidChangeEmail = this.validateEmail(newEmailAddress);
    }
  }

  validateEmail(emailAddress: any) {
    let isValid = false;
    const singleEmailRegularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = singleEmailRegularExpression.test(
      String(emailAddress).toLowerCase()
    );
    return isValid;
  }

  async saveChangeEmail() {
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {
      const input = document.getElementById(
        'newEmailAddress'
      ) as HTMLInputElement | null;
      const newEmailAddress = input?.value;

      if (
        this.isValidChangeEmail &&
        newEmailAddress != '' &&
        newEmailAddress != undefined
      ) {
        let response = await this.userManagementService.ChangeUserEmail(
          this.selectedUser.tenantUserId,
          newEmailAddress,
          this.selectedUser.losUser,
          this.selectedUser.companyUserId
        );

        if (response.status == 0) {
          this.emailChangeFail = true;
          this.loadingPage = false;
          this.toastService.showError(response.message);
        } else if (response.status == 200) {
          this.emailChangeFail = false;
          let mes;
          if (this.selectedUser.isEmailConfirmed) {
            mes = 'User email changed';
          } else {
            mes =
              'User email changed and emailed the instructions to ' +
              newEmailAddress;
          }
          this.toastService.showSuccess(mes);
          this.blankEmailAddress = '';
          this.showChangeEmailDialog = false;
          this.loadingPage = false;
          this.refreshData();
        }
      } else {
        this.loadingPage = false;
        this.toastService.showError('Please validate all data..');
      }
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  loadBillingData() {
    forkJoin(
      this.billingService.GetBillingOwner(),
      this.billingService.GetSubscriptionType()
    ).subscribe(
      (res) => {
        let billingOwnerResponse = res[0];
        let subscriptionTypeResponse = res[1];
        if (billingOwnerResponse.data && subscriptionTypeResponse.data) {
          this.subscriptionTypes = subscriptionTypeResponse.data;
          this.billingOwners = billingOwnerResponse.data;
          this.limitedSubscriptionTypId = this.getLimitedSubscriptionType();
          this.companyBillingOwnerId = this.getCompanyBillingOwner();
        }
      },
      (err) => {
      }
    );
  }

  selectRow(checkValue) {
    if (checkValue) {
      this.selectedRows = this.userList.filter(x => x.tenantUserId !== '00000000-0000-0000-0000-000000000000');
    } else {
      this.selectedRows = [];
    }
  }

  isDisplayBillingOwner() {
    let result = false
    if (this.subscriptionTypes && this.selectedSubscriptionType) {
      let subscriptionType = this.subscriptionTypes.find(t => t.lookupid === this.selectedSubscriptionType);
      if (subscriptionType && subscriptionType.name == "Premium") {
        result = true;
      }
    }
    return result;
  }

  isLimitedSubscriptionType(subscriptionType) {
    let result = false;
    if (this.subscriptionTypes && subscriptionType) {
      let subscriptionTypeobj = this.subscriptionTypes.find(t => t.lookupid === subscriptionType);
      if (subscriptionTypeobj && subscriptionTypeobj.name == "Limited") {
        result = true;
      }
    }
    return result;
  }

  getLimitedSubscriptionType() {
    let result = '';
    if (this.subscriptionTypes) {
      let subscriptionTypeobj = this.subscriptionTypes.find(t => t.name == 'Limited');
      if (subscriptionTypeobj) {
        result = subscriptionTypeobj.lookupid;
      }
    }
    return result;
  }

  getCompanyBillingOwner() {
    let result = '';
    if (this.billingOwners) {
      let billingOwnerobj = this.billingOwners.find(t => t.name == 'Company');
      if (billingOwnerobj) {
        result = billingOwnerobj.lookupid;
      }
    }
    return result;
  }

  isIndividualBillingOwner(billingOwner) {
    let result = false;
    if (this.billingOwners && billingOwner) {
      let billingOwnerobj = this.billingOwners.find(t => t.lookupid === billingOwner);
      if (billingOwnerobj && billingOwnerobj.name == "Individual") {
        result = true;
      }
    }
    return result;
  }

  async updateSubscription() {
    let userIds = [];
    let data: any;
    if (this.loadingPage) return;
    this.loadingPage = true;
    try {

      if (this.selectedSubscriptionType && this.selectedRows) {
        this.selectedRows.map(s => {
          userIds.push({ 'tenantUserId': s.tenantUserId });
        });
        if (this.isLimitedSubscriptionType(this.selectedSubscriptionType)) {
          data = {
            'subscriptionAccessTypeId': this.selectedSubscriptionType,
            TenantUserIds: userIds
          };
        } else {
          data = {
            'BillingOwner': this.selectedBillingOwner, 'subscriptionAccessTypeId': this.selectedSubscriptionType,
            TenantUserIds: userIds
          };
        }

        let response = await this.billingService.updateSubscription(data);

        if (response.status == 0) {
          this.loadingPage = false;
          this.toastService.showError(response.message);
        } else if (response.status == 200) {
          this.toastService.showSuccess('User(s) subscription updated successfully');

          let createSubscriptionOfSelectedUser = [];
          this.selectedRows.forEach((item) => {
            let data = {
              email: item.emailId,
              tenantuserid: item.tenantUserId,
              tenantusername: item.name
            }
            createSubscriptionOfSelectedUser.push(data);
          });

          await this.billingService.CreateStripeTenantUserCustomer(createSubscriptionOfSelectedUser);

          if (this.isIndividualBillingOwner(this.selectedBillingOwner)) {
            this.isSubscriptionDialogInitiate = false;
          }
          else {
            this.showUpdateSubscriptionDialog = false;
            this.selectedRows = [];
          }
          this.loadingPage = false;
          this.refreshData();
        }
      } else {
        this.loadingPage = false;
        this.toastService.showError('Please validate all data..');
      }
    } catch (message: any) {
      this.showUpdateSubscriptionDialog = false
      this.selectedRows = [];
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
    this.loadingPage = false;
  }

  closeAfterSubscriptionEmailSent() {
    this.showUpdateSubscriptionDialog = false
    this.selectedRows = [];
  }
}