import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PeopleAndLoansComponent } from "./people-and-loans.component";

const routes: Routes = [
    { path:"", component: PeopleAndLoansComponent }
];

@NgModule({
    exports: [RouterModule],
    imports:[RouterModule.forChild(routes)]
})

export class PeopleAndLoansRouterModule{}
