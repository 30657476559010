<div *ngIf="items" class="row filter_selectcontainer_advance" [formGroup]="textFormGroup">
  <div class="col-12" style="padding:15px 30px;" formArrayName="items"
    *ngFor="let item of formDataCtrl.controls; let i = index">
    <div class="row p-mt-2 align-items-center" [formGroupName]="i">
      <!-- <div class="col-2">
        <label style="font-size: 16px; line-height: 16px; color: #2e2e2e;font-weight: 400;">{{ item.get('label').value
          }}</label>
      </div> -->
      <div class="col-12 mb-2">
        <p-dropdown dataKey="OpCode" placeholder="Select" [options]="OperandDropDownList" optionLabel="OpDisplay"
          (onChange)="operandChanged($event,i)" [style]="{'width':'98%'}" formControlName="operandDropDown"
          filterBy="name" [filter]="true" filterBy="OpDisplay"></p-dropdown>
      </div>

        <!-- [ngClass]="{ 'InputControl2': item.get('operandDropDown').value?.RhsInputControl2}" -->
        <div class="col-12 " *ngIf="item.get('operandDropDown').value?.RhsInputControl1" >
          <div class="col-6 mb-2 d-flex" >
          <i class="pi pi-reply replytosection col-2 me-2 ms-2"></i>
          <p-inputNumber [min]="1" inputId="integeronly" [maxlength]="25" id="txtRHSValue1{{ i }}"
            [style]="{'border-radius': '25px'}" [formControlName]="'txtRHSValue1'"
            *ngIf="item.get('operandDropDown').value?.RhsInputControl1"
            [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors }" >
          </p-inputNumber>
        
        <!-- <input pInputText pKeyFilter="int" maxlength="50" min="1" class="form-control" pInputText type="number"
          id="txtRHSValue1{{ i }}" formControlName="txtRHSValue1"
          *ngIf="item.get('operandDropDown').value?.RhsInputControl1"
          [ngClass]="{ 'is-invalid': item.get('txtRHSValue1')?.errors}"> -->
        <div class="requiredStyle" *ngIf="item.get('txtRHSValue1')?.touched 
          && item.get('operandDropDown').value?.RhsInputControl1 
          && item.get('txtRHSValue1')?.errors">
          Required.
        </div>
      </div>
      </div>
      <div class="col-12 mb-2 d-flex" *ngIf="item.get('operandDropDown').value?.RhsInputControl2">
        <!-- removed [style]="{'width':'200px'}"  -->

        <i class="pi pi-reply replytosection col-1 me-2 ms-2"></i>
        <p-inputNumber [min]="1" inputId="integeronly" [maxlength]="25" id="txtRHSValue2{{ i }}" class="rounded"
          [formControlName]="'txtRHSValue2'" [style]="{'border-radius': '25px'}"
          [inputStyleClass]="{ 'is-invalid': item.get('txtRHSValue2')?.errors}">
        </p-inputNumber>
      
      <!-- <input pInputText pKeyFilter="int" maxlength="50" min="1" class="form-control" pInputText type="number"
          id="txtRHSValue2{{ i }}" formControlName="txtRHSValue2"
          *ngIf="item.get('operandDropDown').value?.RhsInputControl2"
          [ngClass]="{ 'is-invalid': item.get('txtRHSValue2')?.errors}"> -->
      <div class="requiredStyle" *ngIf="item.get('txtRHSValue2')?.touched 
        && item.get('operandDropDown').value?.RhsInputControl2 
        && item.get('txtRHSValue2')?.errors">
        Required.
      </div>
        </div>
    
      <!-- <div *ngIf="item.get('txtRHSValue2').hasError('higherThan')">Max should be greater than min</div> -->
    </div>
    <!-- <div class="col-auto">
        <span title="remove" *ngIf="items.length > 1" id="btnDeleteRow" class="p-column-filter-menu-button mt-2"
          (click)="deleteRow(i)">
          <i class="pi pi-trash"></i>
        </span>
      </div> -->

  </div>
  <!-- <div class="row mt-3" style="padding:15px 30px;">
    <div class="col-12">
      <p-button (click)="addMore()" class="radius4" icon="pi pi-plus" label=""></p-button>
    </div>
  </div> -->
  <!-- <hr class="m-0"> -->
  <div class="row" style="padding:15px 30px;">
    <div class="col-12 text-right">
      <!-- <a (click)="cancelFilter()" class="link-text cancelStyle" styleClass="p-button-secondary">Cancel</a> -->
      <p-button [disabled]="disableApplyFilter" (onClick)="applyFilter()"  label="Apply"></p-button>
    </div>
  </div>
</div>