<div *ngIf="user?.original_userfullname"
  style="width: 100%;background: yellow;display: block;position: absolute;top: 0px;text-align: center; z-index: 999999 !important;">
  <table>
    <tr>
      <td style="padding-left:255px">
        Logged in as {{user.original_userfullname}}. Impersonating: {{user.getFullName()}} [{{user.TenantName}}].
        Role: {{role}}
      </td>

      <td style="padding-left:25px">
        <div role="button" (click)="changeImpersonatedUser()"><u> Switch Tenant</u></div>
      </td>
    </tr>
  </table>
</div>
<div class="container-section">
  <div class="container-size">
    <div class="logo text-center mt-3">
      <img src="assets/images/aduvo-logo.png" alt="">
      <div class="progress-wrapper my-4">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"
            style="width:16.66%">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="setup-form position-relative container-middle">
    <div class="container-size">
      <div class="welcome-form h-100 position-relative">
        <div class="m-auto d-flex justify-content-center flex-column text-center mt-4">
          <h5 class="title-onboarding">Welcome to Aduvo!</h5>
          <h5 class="title-onboarding">Let's get you started on your new subscription.</h5>
          <hr class="hr-onboarding" />
          <p class="para-section">First let's gather some company info.</p>
        </div>
        <div class="m-auto">
          <div class="mt-5">
            <form [formGroup]="companyForm1" class="form-container">
              <div class="row mb-4 ">
                <div class="col-6">
                  <label for="companyName" class="form-label fw-bold">Company Name </label>
                  <input type="text" name="companyName" id="companyName" maxlength="255" placeholder="Company Name"
                    class="form-control" formControlName="companyName" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('companyName').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('companyName').valid &&
                            (companyForm1.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm1.get('companyName').invalid &&
                          (companyForm1.get('companyName').dirty ||
                            companyForm1.get('companyName').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('companyName').errors?.required
                        ">Company name cannot be blank</span>
                  <span class="text-danger" *ngIf="
                          companyForm1.get('companyName').invalid &&
                          (companyForm1.get('companyName').dirty ||
                            companyForm1.get('companyName').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('companyName').errors?.minlength
                        ">Company name should contain at least 2 characters</span>
                </div>
                <div class="col-6">
                  <label for="nmls" class="form-label fw-bold">NMLS#</label>
                  <input type="text" name="nmls" id="nmls" maxlength="12" placeholder="NMLS" class="form-control"
                    formControlName="nmls" maxlength="12" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('nmls').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('nmls').valid &&
                            (companyForm1.submitted)
                        }" />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-6">
                  <label for="companyEmail" class="form-label fw-bold">Company Email Address</label>
                  <input type="text" name="companyEmail" id="companyEmail" maxlength="128"
                    placeholder="Company Email Address" class="form-control" (input)="validateCompanyEmail()"
                    formControlName="companyEmail" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('companyEmail').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('companyEmail').valid &&
                            (companyForm1.submitted)
                        }" />
                  <span class="text-danger" *ngIf="!isValidCompanyEmail">Company email address is invalid</span>
                  <span class="text-danger" *ngIf="
                          companyForm1.get('companyEmail').invalid &&
                          (companyForm1.get('companyEmail').dirty ||
                            companyForm1.get('companyEmail').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('companyEmail').errors?.required
                        ">Company email cannot be blank</span>
                </div>
                <div class="col-6">
                  <label for="website" class="form-label fw-bold">Company Website</label>
                  <input type="text" name="website" id="website" maxlength="255" placeholder="Website"
                    class="form-control" formControlName="website" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('website').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('website').valid &&
                            (companyForm1.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm1.get('website').invalid &&
                          (companyForm1.get('website').dirty ||
                            companyForm1.get('website').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('website').errors?.pattern
                        ">Website URL is invalid.</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-6">
                  <label for="addressLine1" class="form-label fw-bold">Address Line 1</label>
                  <input type="text" name="addressLine1" id="addressLine1" maxlength="128" placeholder="Address Line1"
                    class="form-control" formControlName="addressLine1" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('addressLine1').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('addressLine1').valid &&
                            (companyForm1.submitted)
                        }" />
                </div>
                <div class="col-6">
                  <label for="addressLine2" class="form-label fw-bold">Address Line 2</label>
                  <input type="text" name="addressLine2" id="addressLine2" maxlength="128" placeholder="Address Line2"
                    class="form-control" formControlName="addressLine2" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('addressLine2').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('addressLine2').valid &&
                            (companyForm1.submitted)
                        }" />
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-6">
                  <label for="city" class="form-label fw-bold">City</label>
                  <input type="text" name="city" id="city" maxlength="50" placeholder="City" class="form-control"
                    formControlName="city" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('city').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('city').valid &&
                            (companyForm1.submitted)
                        }" />
                </div>
                <div class="col">
                  <label for="state" class="form-label fw-bold w-100">State</label>
                  <p-dropdown [options]="usStates" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true"
                    placeholder="Select a state" formControlName="state" optionValue="abbreviation" [autoZIndex]="false"
                    class="groundDropdown">
                    <ng-template let-state pTemplate="item">
                      <div class="state-item">
                        <div>{{ state.name }}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="col">
                  <label for="zip" class="form-label fw-bold">Zip Code</label>
                  <input type="text" name="zip" id="zip" maxlength="10" placeholder="Zip" class="form-control"
                    formControlName="zip" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('zip').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('zip').valid &&
                            (companyForm1.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm1.get('zip').invalid &&
                          (companyForm1.get('zip').dirty ||
                            companyForm1.get('zip').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('zip').errors?.pattern
                        ">Zip code is invalid.</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-6">
                  <label for="workPhone" class="form-label fw-bold">Work Phone</label>
                  <input type="text" name="workPhone" id="workPhone" placeholder="Work phone" class="form-control"
                    maxlength="25" formControlName="workPhone" (input)="workPhoneFormat($event)" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('workPhone').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('workPhone').valid &&
                            (companyForm1.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm1.get('workPhone').invalid &&
                          (companyForm1.get('workPhone').dirty ||
                            companyForm1.get('workPhone').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('workPhone').errors?.pattern
                        ">Work Phone is invalid.</span>
                  <span class="text-danger" *ngIf="
                          companyForm1.get('workPhone').invalid &&
                          (companyForm1.get('workPhone').dirty ||
                            companyForm1.get('workPhone').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('workPhone').errors?.maxlength
                        ">Max length of Work Phone is 25 digits.</span>
                </div>
                <div class="col-6">
                  <label for="fax" class="form-label fw-bold">Fax</label>
                  <input type="text" name="fax" id="fax" placeholder="Fax" class="form-control" maxlength="25"
                    formControlName="fax" (input)="faxFormat($event)" [ngClass]="{
                          'is-invalid':
                            companyForm1.get('fax').invalid &&
                            (companyForm1.submitted),
                          'is-valid':
                            companyForm1.get('fax').valid &&
                            (companyForm1.submitted)
                        }" />
                  <span class="text-danger" *ngIf="
                          companyForm1.get('fax').invalid &&
                          (companyForm1.get('fax').dirty ||
                            companyForm1.get('fax').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('fax').errors?.pattern
                        ">Fax number is invalid.</span>
                  <span class="text-danger" *ngIf="
                          companyForm1.get('fax').invalid &&
                          (companyForm1.get('fax').dirty ||
                            companyForm1.get('fax').touched ||
                            companyForm1.submitted) &&
                          companyForm1.get('fax').errors?.maxlength
                        ">Max length of Fax number is 25 digits.</span>
                </div>
              </div>
              <div class="row mb-4 ">
                <div class="col-6">
                  <label for="timezoneId" class="form-label fw-bold w-100">Timezone</label>
                  <p-dropdown [options]="timezoneList" optionLabel="name" [filter]="true" filterBy="name"
                    [showClear]="true" placeholder="Select Timezone" formControlName="timezoneId" optionValue="code">
                  </p-dropdown>
                </div>
                <div class="col-6">
                  <label for="losVendorId" class="form-label fw-bold">LOS Vendor Id</label>
                  <input type="text" name="losVendorId" id="losVendorId" placeholder="LOS Vendor Id"
                    class="form-control" formControlName="losVendorId"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section p-2">
    <div class="onboarding-bottom my-3 container-size">
      <div>
        <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded font-16-bold"
          (click)="redirectToBack()">
          <i class="fa fa-arrow-left"></i>&nbsp;Back
        </button>
        <button pButton pRipple class="p-button p-button-rounded float-end font-16-bold" (click)="redirectToAddAdmin()">
          Next
          &nbsp; <i class="fa fa-arrow-right"></i>
        </button>

        <app-to-dashboard-button class="float-end"></app-to-dashboard-button>
      </div>
    </div>
  </div>
</div>

<!-- Spinner while loading the page -->
<p-dialog class="pulseLoadingDialog" [draggable]="false" [resizable]="false" [(visible)]="loadingPage" [modal]="true"
  [closeOnEscape]="false" closeable="false" [showHeader]="false" [baseZIndex]="10000">
  <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
</p-dialog>