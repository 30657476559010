<div class="content">
  <dashboard-analytics *ngIf="currentClientID === 'Aduvo' && onboarded"></dashboard-analytics>
  <!--
    (onExploreMoreClicked)="onExploreMoreClicked($event)" is added to define the event that is emitted
    by this component as defined in the recommendations.component.ts file by:
    @Output() onExploreMoreClicked: EventEmitter<Event> = new EventEmitter<Event>(true);

    The Dashboard component (direct parent of recommendations) implements the onExploreMoreClicked($event) method
    that receives and processes the event.
  -->
  <recommendations-root *ngIf="showRecommendations" (onExploreMoreClicked)="onExploreMoreClicked($event)"></recommendations-root>
</div>
