import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactAndLoanImportComponent } from './contact-and-loan-import/contact-and-loan-import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';



@NgModule({
  declarations: [ContactAndLoanImportComponent],
  imports: [
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DropdownModule,
    OverlayPanelModule,
    TableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    FileUploadModule,
    MultiSelectModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
  ],
  exports: [ContactAndLoanImportComponent],
  entryComponents: [
    ContactAndLoanImportComponent
  ],
})
export class ContactAndLoanModule {

}
