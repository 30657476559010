import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettings } from 'src/appSettings';
import { VariableSubstitution } from 'src/models/VariableSubstitution';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {

  constructor(private http: HttpClient) { }


}
