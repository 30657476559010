<app-custom-grid [data]="campaignMessageLogs" [columns]="columns" [searchModel]="searchModel" *ngIf="columnsLoaded"
  (onPageChanged)="onPageChangedParent($event)"
  (updatedFilters)="updatedFilters()"
  (downloadAllEvent)="downloadAllEvent()"
  (filterBasedOnColumnSelect)="filterBasedOnColumnSelect($event)">
  <ng-template #tabletTemplate let-row>
      <td *ngFor="let col of columns;let i = index">
        <ng-container *ngIf="col.display">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'activity'">
              <p-chip styleClass="m-2 delivered-chip" *ngIf="row['delivered'] === true">
                <div class="p-2 cursor-pointer" [pTooltip]="row['deliveredDate'] | date:'EEE, MMM d, y, h:mm a z'">
                  Delivered</div>
              </p-chip>
              <p-chip styleClass="m-2 dropped-chip" *ngIf="row['dropped'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['droppedDate'] | date:'EEE, MMM d, y, h:mm a z'">Dropped
                </div>
              </p-chip>
              <p-chip styleClass="m-2 bounced-chip" *ngIf="row['bounced'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['bouncedDate'] | date:'EEE, MMM d, y, h:mm a z'">Bounced
                </div>
              </p-chip>
              <p-chip styleClass="m-2 blocked-chip" *ngIf="row['blocked'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['blockedDate'] | date:'EEE, MMM d, y, h:mm a z'">Blocked
                  by Carrier</div>
              </p-chip>
              <p-chip styleClass="m-2 opened-chip" *ngIf="row['opened'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['openedDate'] | date:'EEE, MMM d, y, h:mm a z'">Opened
                </div>
              </p-chip>
              <p-chip styleClass="m-2 clicked-chip" *ngIf="row['clicked'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['clickedDate'] | date:'EEE, MMM d, y, h:mm a z'">Clicked
                </div>
              </p-chip>
              <p-chip styleClass="m-2 spam-reported-chip" *ngIf="row['spamReported'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['spamReportedDate'] | date:'EEE, MMM d, y, h:mm a z'">
                  Reported as Spam</div>
              </p-chip>
              <p-chip styleClass="m-2 unsubscribed-chip" *ngIf="row['unsubscribed'] === true">
                <div class="p-1 cursor-pointer" [pTooltip]="row['unsubscribedDate'] | date:'EEE, MMM d, y, h:mm a z'">
                  Unsubscribed</div>
              </p-chip>
            </ng-container>
            <ng-container *ngSwitchCase="'messageType'">
              {{ row[col.field] | messageType }}
            </ng-container>
            <ng-container *ngSwitchCase="'messageName'">
              <div class="cursor-pointer aduvo-purple-text" (click)="showMessage(row['templateId'], row['isPublishDB'])">
                {{ row[col.field] }}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'messageSentDate'">
              {{ row[col.field] | date:'MM/dd/yyyy h:mm:ss a' }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span [ngClass]="{ 'aduvo-purple-text': col.field === 'recipientName' }">{{ row[col.field] }}</span>
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
  </ng-template>
</app-custom-grid>

<p-dialog class="custom-dialog" #preview id="smspreview" [resizable]="false" (onHide)="showSmsPreview = false"
  [(visible)]="showSmsPreview" [modal]="true" [baseZIndex]="10000"
  [style]="{ width: '800px', height: '640px', 'font-weight': '500' }">
  <p-header>
    <span class="dialog-head">{{ messageName }}</span>
  </p-header>

  <div class="flex-fill" style="padding: 0px !important">
    <div class="row">
      <div class="col-sm-5" style="background-color: #f5f5f5">
        <div style="padding: 50px">
          <ul class="list-group" style="border-radius: 0">
            <li class="list-group-item">
              <span class="dialog-head">Message Summary </span>
            </li>
            <li class="list-group-item">
              <span class="fade-txt">Message Category</span> <br />
              <span style="font-size: 16px">{{ messageCategory }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-7" style="padding: 50px">
        <div class="sms-preview-container" style="background-image: url(../../../../assets/images/phone-frame.svg)">
          <div class="sms-preview-block">
            <div class="sms-block" *ngFor="let smsBodyForm of smsMsgBody; last as isLast">
              <span>{{ smsBodyForm }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog class="custom-dialog" #preview id=" " [resizable]="false" (onHide)="showEmailPreview = false"
  [(visible)]="showEmailPreview" [modal]="true" [baseZIndex]="10000" [style]="{ width: '768px', 'font-weight': '700' }">
  <previewDialog-root #previewDialog [compiledEmail]="'Test body'" [templateWidth]="616" [templateHeight]="936">
  </previewDialog-root>
  <p-header>
    <span class="dialog-head">{{ messageName }}</span>
  </p-header>
</p-dialog>