export const environment = {
  production: true,
  clientId: 'Aduvo',
  TokenAuthority: 'https://login.aduvo.com',
  SecurityBaseURL: 'https://login.aduvo.com',
  CampaignBaseUrl: 'https://app-service-campaign.aduvo.com/api/v1/',
  DesignLibraryBaseUrl: 'https://app-service-templatelibrary.aduvo.com/api/v1/design/',
  ImageLibraryBaseUrl: 'https://app-service-imagelibrary.aduvo.com/api/v1/image/',
  ListLibraryBaseUrl: 'https://app-service-lists.aduvo.com/api/v1/lists/',
  TenantManagementBaseUrl: 'https://app-service-company.aduvo.com/api/v1/',
  DashboardAnalyticsBaseUrl: 'https://app-service-metrics.aduvo.com/api/v1/',
  LookupLibraryBaseUrl: 'https://app-service-lookup.aduvo.com/api/v1/lookup/',
  WebBaseUrl: 'https://app.aduvo.com',
  ZenDeskUrl: 'https://app-service-submitticket.aduvo.com/api/v1/SubmitTicket/createticket',
  CalendarEventBaseUrl: 'https://app-service-aduvo-calendarevent-prod-01.azurewebsites.net/api/v1/calendarevent/',
  BillingBaseUrl: 'https://app-service-aduvo-billing-prod-01.azurewebsites.net/api/v1/',
  Force2FA: true,
  SessionStorageExpiredTimeInHour: 1, //storageHelper expired timout in Hours
  SessionStorageExpiredTimeInHourForCampagin: 1, //storageHelper expired timout in Hours  for campaign master data
  SessionStorageExpiredTimeInHourForPeopleAndLoanRHSQuery: 12,
  idleTime: 21600,
  idleTimeOut: 0,
  gTagMeasurementId: 'G-Y9M70VNC3J',
  ironcladSiteId: '35a595ee-bb50-40e1-bfe2-60f8c0075d9d',
  AllowTCPAForTextMessages:true,
  ContentLibraryBaseUrl: 'https://app-service-contentlibrary.aduvo.com/api/v1/',
  signalRHubUrl: 'https://app-service-aduvo-notification-prod-01.azurewebsites.net',
  NotificationBaseUrl: 'https://app-service-aduvo-notification-prod-01.azurewebsites.net/api/v1/',
  ContactsBaseUrl: 'https://app-service-aduvo-contacts-prod-01.azurewebsites.net/api/v1/',
  SubscriptionBaseUrl: 'https://app-service-subscription.aduvo.com/api/v1/',

  LoanImportTemplateXls: "https://s3pulseimagesdev01.blob.core.windows.net/imports/AduvoLoanImports.xlsx",
  ContactImportTemplateXls: "https://s3pulseimagesdev01.blob.core.windows.net/imports/AduvoContactImports.xlsx"
};
