<div class="onboarding">
  <div class="content onboarding-top">
    <div class="text-wrapper">
      <div class="align-items-center d-flex justify-content-center">
        <div class="logo">
          <img src="assets/images/aduvo-logo.png" alt="" style="width: 84px; margin-left: 5px;">
        </div>
      </div>
      <div class="progress-wrapper">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="20"
               aria-valuemin="0" aria-valuemax="100" style="width:15%">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="onboarding-bottom mt-3 position-absolute">
    <div class="m-auto d-flex justify-content-between text-center align-items-center" style="width:600px; height: inherit;">
      <button pButton pRipple class="p-button back-button-new btn-outline-primary p-button-rounded"><i class="fa fa-arrow-left"></i>&nbsp;Back</button>
      <button pButton pRipple class="p-button p-button-rounded" (click)="nextForm()">Next &nbsp; <i class="fa fa-arrow-right"></i></button>
    </div>
  </div>
</div>
