<app-custom-grid 
    [data]="campaignMessageViewModels" 
    [columns]="columns" 
    [searchModel]="searchModel"
    [showExport]="false"
    [showFilters]="true"
    [totalCountLabel]="'Records'"
    (onPageChanged)="onPageChangedParent($event)"
    (updatedFilters)="updatedFilters()">
  <ng-template #tabletTemplate let-row>
    <td *ngFor="let col of columns;let i = index">
      <ng-container *ngIf="col.display">
        <span *ngIf="col.field === 'dateCreatedUTC'">
          {{ row[col.field] | date:'MM/dd/yyyy h:mm:ss a' }}
        </span>
        <span *ngIf="col.field !== 'dateCreatedUTC'">
          {{ row[col.field] }}
        </span>
      </ng-container>
    </td>
  </ng-template>
</app-custom-grid>
