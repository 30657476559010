<div [formGroup]="form" class="position-relative" style="height: 250px">
  <button pButton pRipple type="button" icon="fa fa-times" *ngIf="parentForm.controls.length > 1"
    class="p-button delete-icon" (click)="deleteInput()"></button>
  <div class="message-content position-relative">
    <textarea #textMessage [maxlength]="smsMaxLength" class="p-inputtext" [ngClass]="'sms-input-' + index"
      [class.ng-invalid]="form.controls['body'].dirty && form.controls['body'].errors?.required" formControlName="body"
      placeholder="Type your message here..."
      (change)="textAreaEmpty()"
      >
    </textarea>
    <div class="align-items-center border border-top-0 d-flex justify-content-between message-details p-3">
      <div class="fw-bold font-size-16 msg-length text-dark">{{form.controls['body'].value.length + '/' + smsMaxLength}}
        Characters
      </div>
      <div>
        <i role="button" class="pi pi-tag mx-2" (click)="onMergeTagRequested.emit()"></i>
        <i role="button" class="fa-regular fa-face-smile mx-2" (click)="op.toggle($event)"></i>
        <i role="button" class="pi pi-image mx-2" (click)="onImageRequested.emit()"></i> 
      </div>
    </div>
  </div>
  <div class="text-danger" style="margin-top: 52px;"
    *ngIf="form.controls['body'].dirty && form.controls['body'].errors?.required">SMS Body cannot be empty.
  </div>
  <div class="text-danger mt-2" *ngIf="form.controls['body'].dirty && form.controls['body'].errors?.maxlength">SMS
    Length cannot be more than {{smsMaxLength}}.
  </div>
</div>
<p-overlayPanel #op [showCloseIcon]="false" [styleClass]="'emoji-mart-overlay'">
  <emoji-mart title="Pick your emoji…" #emojiMart emoji="point_up" [darkMode]="false"
    (emojiClick)="emojiClicked($event)">
  </emoji-mart>
</p-overlayPanel>