import { ApplicationRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RequestResponse } from 'src/models/RequestResponse';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { PagingData } from 'src/models/PagingData';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/services/toast.service';
import { MessageTemplatesService } from 'src/services/messageTemplates.service';
import { MessageTemplate } from 'src/models/MessageTemplate';
import { Table } from 'primeng/table';
import { Menu } from 'primeng/menu';
import { Router } from '@angular/router';
import { UserTokenService } from 'src/services/user-token.service';
import { UserToken } from 'src/models/UserToken';
import { DialogService } from 'primeng/dynamicdialog';
import { MakeACopyComponent } from './dialogs/make-a-copy/make-a-copy.component';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
import { stripoComponent } from './stripo.component';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { SMSService } from 'src/services/sms.service';
import { LookupService } from 'src/services/lookup.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'designLibrary-root',
  templateUrl: './designLibrary.component.html',
  styleUrls: ['./designLibrary.component.scss'],
})
export class designLibraryComponent implements OnInit {
  public loadingPage: boolean = true;
  public subscription: Array<Subscription> = [];

  @ViewChild('templateTableId', { static: false }) dataTable: Table;
  @ViewChild('ellipseMenu', { static: false }) ellipseMenu: Menu;
  @ViewChild('previewDialog', { static: false }) previewDialog: Component;
  @ViewChild('textPreviewDialog', { static: false })
  textPreviewDialog: Component;
  @ViewChild('makeACopyDialog', { static: false })
  makeACopyDialog: MakeACopyComponent;

  @ViewChild('templateEditor', { static: false })
  templateEditor: stripoComponent;

  public webAPI: RequestResponse;
  dialogTitle: String;
  public newSortField = 'title';
  public newSortOrder = -1;
  defaultOrderBy = 'Title ASC';
  // row selections
  selectedTemplates: MessageTemplate[] = [];
  selectedTemplate: MessageTemplate = new MessageTemplate();
  selectedTemplateClone: MessageTemplate = new MessageTemplate();

  // Context (...) menu contents
  contextMenuItems: MenuItem[];
  showPreviewDialog: boolean = false;
  showTextPreviewDialog: boolean = false;
  showMakeACopyDialog: boolean = false;
  showTemplateEditor: boolean = false;
  //showTextMessageEditor: boolean = false;
  createDesignDisabled: string = 'disabled';
  templateTypeMenuItems: MenuItem[] = [];
  templateId: string;
  userToken: UserToken;
  currentClientID: string;
  searchKeyword: string = '';
  errors: any[] = [];
  showErrors: boolean = false;
  keywordDisabled: boolean = false;
  templateList = [];
  categoryId: string = '00000000-0000-0000-0000-000000000000';
  messageCategoryList: any;
  selectedCategoryItem: any = -1;

  showEmailEditor: boolean = false;
  messageTitle: string = '';
  messageCategory: string = '';

  readonly smsMaxLength: number = 160;
  public smsMsgBody: string[] = [];
  public showSendTestSMSDialog: boolean = false;
  public smsSentSuccess: boolean = false;
  public toNumber = new FormControl('');
  public SMSInProgress: boolean = false;
  public buildYourOwnCampaignID: string = '';
  public buildYourOwnCancelUrl = '';
  public changeCampaignMessageID: string = '';
  public pageIndex: number = 0;

  constructor(
    private toastService: ToastService,
    private messageTemplatesService: MessageTemplatesService,
    private userTokenService: UserTokenService,
    private router: Router,
    public dialogService: DialogService,
    private appRef: ApplicationRef,
    private SMSService: SMSService,
    private lookupService: LookupService
  ) {
    // initialize template type menu
    this.templateTypeMenuItems = this.getMessageTypeMenuItems();

    this.lookupService.GetCampaignCategories().subscribe((result) => {
      if (result && result.data) {
        this.messageCategoryList = result.data.map((obj) => {
          return {
            item_id: obj.categoryId,
            item_text: obj.categoryName,
          };
        });
      }
    });
  }

  ngOnInit(): void {
    this.userToken = this.userTokenService.getUser();
    this.currentClientID = environment.clientId;
    this.webAPI = new RequestResponse();

    if (sessionStorage.getItem("messageTemplateCurrentPageNumber")) {
      this.webAPI.paging.currentPage = parseInt(sessionStorage.getItem("messageTemplateCurrentPageNumber"));
    } else {
      this.webAPI.paging.currentPage = 1;
    }
    this.pageIndex = this.webAPI.paging.currentPage - 1;

    if (sessionStorage.getItem("messageTemplatePageSize")) {
      this.webAPI.paging.pageSize = parseInt(sessionStorage.getItem("messageTemplatePageSize"));
    } else {
      this.webAPI.paging.pageSize = 25;
    }
    if (sessionStorage.getItem("messageTemplateOrderBy")) {
      this.webAPI.paging.orderBy = sessionStorage.getItem("messageTemplateOrderBy");
      this.newSortField = this.webAPI.paging.orderBy.split(' ')[0];
      this.newSortOrder = this.webAPI.paging.orderBy.split(' ')[1] == 'ASC' ? 1 : -1;
    } else {
      this.webAPI.paging.orderBy = this.defaultOrderBy;
    }
    if (sessionStorage.getItem("messageTemplateSearchKeyword")) {
      this.searchKeyword = sessionStorage.getItem("messageTemplateSearchKeyword");
    }
    if (sessionStorage.getItem("messageTemplatePageIndex")) {
      this.pageIndex = parseInt(sessionStorage.getItem("messageTemplatePageIndex"));
    } else {
      this.pageIndex = 0;
    }
    if (sessionStorage.getItem("messageTemplateCategoryId")) {
      this.categoryId = sessionStorage.getItem("messageTemplateCategoryId");

      this.messageCategoryList.forEach((item, index) => {
        if (item.item_id == this.categoryId) {
          this.selectedCategoryItem = index;
        }
      });
    }

    this.lookupService.GetCampaignCategories().subscribe(
      (result) => {
        if (result && result.data) {
          this.messageCategoryList = result.data.map((obj) => {
            return {
              item_id: obj.categoryId,
              item_text: obj.categoryName,
            };
          });
        }
      },
      (error) => {
        this.toastService.showError(error);
      }
    );
  }

  async onEllipseClick(event: any, template: any) {
    this.contextMenuItems = this.getContextMenuItems(template);
    this.selectedTemplate = template;

    this.contextMenuItems[0].disabled = !template.canEdit;
    this.contextMenuItems[0].visible = template.canEdit;
    this.contextMenuItems[2].disabled = !template.canDelete;
    this.contextMenuItems[2].visible = template.canDelete;
    if (template.templateTypeId == 1) {
      this.contextMenuItems[4].disabled = true;
      this.contextMenuItems[4].visible = false;
      this.contextMenuItems[5].disabled = false;
      this.contextMenuItems[5].visible = true;
      this.showEmailEditor = true;
    } else {
      this.contextMenuItems[4].disabled = false;
      this.contextMenuItems[4].visible = true;
      this.contextMenuItems[5].disabled = true;
      this.contextMenuItems[5].visible = false;
      this.showEmailEditor = false;
    }
    this.ellipseMenu.toggle(event);
  }

  /** Gets the Context menu's items */
  getContextMenuItems(template: MessageTemplate): MenuItem[] {
    let result = [
      {
        label: 'Edit',
        //icon: 'fa fa-pencil pencil',
        command: () => this.onEdit(template),
        disabled: true,
        visible: true,
      },
      {
        label: 'Duplicate',
        //icon: 'pi pi-eye',
        command: () => this.onCopy(template),
      },
      {
        label: 'Delete',
        //icon: 'pi pi-trash',
        command: () => this.onDelete(template),
        disabled: true,
        visible: true,
      },
      {
        label: 'Preview',
        //icon: 'pi pi-trash',
        command: () => this.onPreview(template),
      },
      {
        label: 'Send Test Text Message',
        //icon: 'pi pi-trash',
        command: () => this.onSendSMS(template),
        disabled: true,
        visible: true,
      },
      {
        label: 'Send Test Email',
        //icon: 'pi pi-trash',
        command: () => this.onSendEmail(template),
        disabled: true,
        visible: true,
      },
    ];
    return result;
  }

  async onPreview(messageTemplate: MessageTemplate) {
    this.loadingPage = true;
    let template = await this.messageTemplatesService.GetTemplateById(
      messageTemplate.templateId,
      messageTemplate.isPublishingDb
    );
    this.messageTitle = messageTemplate.title;
    if (messageTemplate.templateTypeId == 1) {
      //Email Preview
      let dlg: any = this.previewDialog;
      dlg.initializeDialog(template.data.previewBody, 616, 936);
      this.showPreviewDialog = true;
    } else {
      //SMS Preview
      if (template.data.body === '') {
        //do nothing
      } else {
        this.smsMsgBody = JSON.parse(template.data.previewBody);
      }
      this.showTextPreviewDialog = true;
      this.messageCategoryList.forEach((item, index) => {
        if (item.item_id == messageTemplate.categoryId) {
          this.messageCategory = item.item_text;
        }
      });
    }
    this.loadingPage = false;
  }

  onClose(event: any) {
    this.showEmailEditor = false;
  }

  async onSendSMS(messageTemplate: MessageTemplate) {
    this.loadingPage = true;
    let template = await this.messageTemplatesService.GetTemplateById(
      messageTemplate.templateId,
      messageTemplate.isPublishingDb
    );
    if (template.data.previewBody === '') {
      //do nothing
    } else {
      this.smsMsgBody = JSON.parse(template.data.previewBody);
    }
    this.showSendTestSMSDialog = true;
    this.loadingPage = false;
  }

  onCloseSendTestSMSDialog() {
    this.showSendTestSMSDialog = false;
    this.smsSentSuccess = false;
    this.SMSInProgress = false;
    this.toNumber.setValue('');
  }

  sendTestSMS() {
    if (this.toNumber.value && this.smsMsgBody.length) {
      this.SMSInProgress = true;
      this.SMSService.SendSms(this.smsMsgBody, this.toNumber.value).subscribe(
        (response: RequestResponse) => {
          if (response?.data) {
            this.smsSentSuccess = true;
            this.toastService.showSuccess('Test Sms sent successfully.');
          } else {
            this.smsSentSuccess = false;
            this.toastService.showError(response.message);
            this.toNumber.setValue('');
          }
          this.SMSInProgress = false;
        },
        (err) => {
          this.SMSInProgress = false;
        }
      );
    } else {
      this.smsSentSuccess = false;
      this.toastService.showError('Please enter a correct number');
    }
  }

  sendTestSMSAnother() {
    this.smsSentSuccess = false;
    this.SMSInProgress = false;
    this.toNumber.setValue('');
  }

  // async onSendEmail(messageTemplate: MessageTemplate) {
  //   this.templateEditor.editorState.messageTemplate.title = messageTemplate.title;
  //   this.templateEditor.editorState.messageTemplate.emailSubject = messageTemplate.previewEmailSubject;
  //   this.templateEditor.editorState.messageTemplate.preHeaderText = messageTemplate.previewPreHeaderText;

  //   if (this.currentClientID === "AduvoAdmin")
  //     this.templateEditor.editorState.messageTemplate.published = messageTemplate.published;
  //   else
  //     this.templateEditor.editorState.messageTemplate.published = true

  //   this.templateEditor.loadTemplate(messageTemplate);
  //   this.templateEditor.openSendEmailDialoge();
  //   this.templateEditor.editorState.showSendTestEmailDialog = true;
  // }

  async onSendEmail(messageTemplate: MessageTemplate) {
    this.templateEditor.editorState.messageTemplate.templateId =
      messageTemplate.templateId;

    if (this.currentClientID === 'AduvoAdmin')
      this.templateEditor.editorState.messageTemplate.published =
        messageTemplate.published;
    else this.templateEditor.editorState.messageTemplate.published = true;

    // this.templateEditor.openEmailDialogueFromDesignLib(
    //   messageTemplate.emailSubject,
    //   messageTemplate.preHeaderText,
    //   this.templateEditor.editorState.messageTemplate.published
    // );
    this.templateEditor.openEmailDialogueFromDesignLib(
      messageTemplate.emailSubject,
      messageTemplate.preHeaderText,
      messageTemplate.isPublishingDb
    );
    this.templateEditor.editorState.showSendTestEmailDialog = true;
  }

  async refreshData() {
    this.loadingPage = true;
    try {
      // code to acquire data via a Web API call.
      // returned object is a RequestResponse object.
      this.webAPI.data = []; //  Required - Clear data from last request or set your specific controller request data.
      if (this.searchKeyword.length > 0) {
        let columnValue = this.searchKeyword;
        this.webAPI.data = columnValue;
      }
      this.webAPI.paging.pagingRead = true; // Required for lazy loading of data (default gets all records).
      // assign returned object to class variable.
      var response = await this.messageTemplatesService.GetTemplates(
        this.webAPI,
        false,
        false,
        0,
        this.categoryId
      ); // Required - do not remove.
      this.webAPI.paging.totalRecords = response.pageInfo.totalRecords;
      this.webAPI.data = response.data;
      for (let x = 0; x < this.webAPI.data.length; x++) {
        this.webAPI.data[x].createdOnUTC = this.getLocalDate(
          this.webAPI.data[x].createdOnUTC
        );
        this.webAPI.data[x].modifiedOnUTC = this.webAPI.data[x].modifiedOnUTC
          ? this.getLocalDate(this.webAPI.data[x].modifiedOnUTC)
          : null;
        this.webAPI.data[x].deletedOnUTC = this.getLocalDate(
          this.webAPI.data[x].deletedOnUTC
        );
        this.webAPI.data[x].canEdit = false;
        this.webAPI.data[x].canDelete = false;

        if (this.userToken.isCompanyAdmin() || this.userToken.isFocusITUser()) {
          if (this.webAPI.data[x].isPublishingDb != true) {
            this.webAPI.data[x].canEdit = true;
            this.webAPI.data[x].canDelete = true;
          }
        } else if (this.userToken.isMarketingAdmin()) {
          if (this.webAPI.data[x].ownerRoleId == 'COMPANYMARKETINGADMIN') {
            this.webAPI.data[x].canEdit = true;
            this.webAPI.data[x].canDelete = true;
          }
        } else if (this.userToken.isCompanyUserWithMarketing()) {
          if (this.webAPI.data[x].owner == this.userToken.TenantUserId) {
            this.webAPI.data[x].canEdit = true;
            this.webAPI.data[x].canDelete = true;
          }
        }
      }
      this.templateList = this.webAPI.data;
      // when data is retrieved, set this.loadingPage to false.
      this.loadingPage = false;
    } catch (message: any) {
      this.webAPI.paging.totalRecords = 0;
      this.loadingPage = false;
      this.toastService.showError(message);
    }
  }

  async loadTemplates(event: LazyLoadEvent) {
    let orderBy = this.defaultOrderBy;
    if (event.sortField == null || event.sortField == undefined) 
      return;

    if (sessionStorage.getItem("messageTemplateOrderBy")) {
      orderBy = sessionStorage.getItem("messageTemplateOrderBy");
      this.newSortField = orderBy.split(' ')[0];      
      this.newSortOrder = orderBy.split(' ')[1] == 'ASC' ? 1 : -1;
    } else {
      this.newSortField = event.sortField.toString();
      this.newSortOrder = event.sortOrder;
      orderBy = this.setSort(this.newSortField, this.newSortOrder);
    }    

    if (sessionStorage.getItem("messageTemplateCurrentPageNumber")) {
      this.webAPI.paging.currentPage = parseInt(sessionStorage.getItem("messageTemplateCurrentPageNumber"));
    } else {
      this.webAPI.paging.currentPage = event.first / event.rows + 1;
    }

    if (sessionStorage.getItem("messageTemplatePageSize")) {
      this.webAPI.paging.pageSize = parseInt(sessionStorage.getItem("messageTemplatePageSize"));
    } else {
      this.webAPI.paging.pageSize = event.rows;
    }

    if (sessionStorage.getItem("messageTemplatePageIndex")) {
      this.pageIndex = parseInt(sessionStorage.getItem("messageTemplatePageIndex"));
    } else {
      this.pageIndex = 0;
    }

    if (sessionStorage.getItem("messageTemplateCategoryId")) {
      this.categoryId = sessionStorage.getItem("messageTemplateCategoryId");

      this.messageCategoryList.forEach((item, index) => {
        if (item.item_id == this.categoryId) {
          this.selectedCategoryItem = index;
        }
      });
    }

    this.webAPI.paging.orderBy = orderBy;
    sessionStorage.removeItem("messageTemplateTotalRecords");
    sessionStorage.removeItem("messageTemplateCurrentPageNumber");
    sessionStorage.removeItem("messageTemplateOrderBy");
    sessionStorage.removeItem("messageTemplatePageSize");
    sessionStorage.removeItem("messageTemplatePageIndex");
    sessionStorage.removeItem("messageTemplateSearchKeyword");
    sessionStorage.removeItem("messageTemplateCategoryId");

    setTimeout(() => { this.refreshData(); });
  }
  /**
   *
   * @param columnName
   * @param order
   */
  setSort(columnName: any, order: any) {
    var orderBy = this.defaultOrderBy;
    this.newSortOrder = order;

    if (columnName == null || columnName == undefined) return;
    // set sort chip label and order by clause
    if (order == -1) {
      orderBy = columnName + ' DESC';
    } else {
      orderBy = columnName + ' ASC';
    }
    return orderBy;
  }

  onGotoPage() {
    if (PagingData.onGotoPage(this.webAPI.paging, this.dataTable)) {
      setTimeout(() => {
        this.refreshData();
      });
    }
  }

  getLocalDate(the_date: Date) {
    if (the_date == undefined || the_date == null) return the_date;
    // The database is storing dates without the 'Z' suffix so typescript/javascript thinks these dates are
    // local dates. We need to manually append the 'Z' suffix to flag these dates as UTC and then reparse them.
    let strDateUTC: string = the_date.toString() + 'Z';
    return new Date(strDateUTC).toLocaleString();
  }

  /**
   * Called when the global filter value changes.
   * (called on every keystroke)
   */
  onGlobalFilterChange() {
    if (this.searchKeyword.length == 0) this.onApplyGlobalFilter();
  }

  /**
   * Applies the global filter to lazy loaded data.
   */
  async onApplyGlobalFilter() {
    this.webAPI.paging.currentPage = 1;
    this.pageIndex = 0;
    this.refreshData();
  }

  async onEdit(template: MessageTemplate) {
    let pageIndex = ((this.webAPI.paging.currentPage - 1) * this.webAPI.paging.pageSize) + 1;
    sessionStorage.setItem("messageTemplateTotalRecords", this.webAPI.paging.totalRecords.toString());
    sessionStorage.setItem("messageTemplateCurrentPageNumber", this.webAPI.paging.currentPage.toString());
    sessionStorage.setItem("messageTemplateOrderBy", this.webAPI.paging.orderBy.toString());
    sessionStorage.setItem("messageTemplatePageSize", this.webAPI.paging.pageSize.toString());
    sessionStorage.setItem("messageTemplatePageIndex", pageIndex.toString());
    sessionStorage.setItem("messageTemplateSearchKeyword", this.searchKeyword.toString());
    sessionStorage.setItem("messageTemplateCategoryId", this.categoryId.toString());

    await this.router.navigate([
      '/marketing/template-library/edit/' + template.templateId,
    ]);
  }

  onDuplicate(event: any) {
    setTimeout(() => {
      let duplicate: MessageTemplate = JSON.parse(
        JSON.stringify(this.selectedTemplate)
      );

      // initialization
      duplicate.tenantId = this.userToken.TenantId;
      duplicate.title = '';
      duplicate.templateId = '00000000-0000-0000-0000-000000000000';
      duplicate.owner = this.userToken.TenantUserId;
      duplicate.ownerFullName = this.userToken.getFullName();
      duplicate.createdBy = this.userToken.TenantUserId;
      duplicate.createdOnUTC = null;
      duplicate.modifiedBy = this.userToken.TenantUserId;
      duplicate.modifiedOnUTC = null;
      duplicate.deletedOnUTC = null;
      duplicate.deletedBy = null;
      //todo: gaurav: need to fix this as per new req
      //this.templateEditor.loadTemplate(duplicate);
    }, 10);

    // give time for the Stripo Template View to clear;
    setTimeout(() => {
      this.showTemplateEditor = true;
    }, 250);
  }

  onDelete(template: MessageTemplate) {
    var ref = this.dialogService.open(ConfirmModalComponent, {
      width: '650px',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      styleClass: 'confirm-modal',
      baseZIndex: 10000,
      data: {
        message: 'Please confirm you want to delete template ' + template.title,
        successButtonText: 'Yes, I want to delete',
        cancelButtonText: 'Cancel',
      },
    });

    ref.onClose.subscribe(async (result: boolean) => {
      if (result) {
        this.messageTemplatesService
          .DeleteTemplate(template.templateId)
          .subscribe(
            (response: RequestResponse) => {
              if (response.status == 200) {
                this.toastService.showSuccess(
                  'Template ' + template.title + ' successfully deleted'
                );
                this.refreshData();
              } else {
                this.toastService.showError(response.message);
              }
            },
            (err) => {
              this.toastService.showError('Error : ' + err);
              this.loadingPage = false;
            }
          );
      }
    });
  }

  async onCopy(messageTemplate: MessageTemplate) {
    let pageIndex = ((this.webAPI.paging.currentPage - 1) * this.webAPI.paging.pageSize) + 1;
    sessionStorage.setItem("messageTemplateTotalRecords", this.webAPI.paging.totalRecords.toString());
    sessionStorage.setItem("messageTemplateCurrentPageNumber", this.webAPI.paging.currentPage.toString());
    sessionStorage.setItem("messageTemplateOrderBy", this.webAPI.paging.orderBy.toString());
    sessionStorage.setItem("messageTemplatePageSize", this.webAPI.paging.pageSize.toString());
    sessionStorage.setItem("messageTemplatePageIndex", pageIndex.toString());
    sessionStorage.setItem("messageTemplateSearchKeyword", this.searchKeyword.toString());
    sessionStorage.setItem("messageTemplateCategoryId", this.categoryId.toString());

    this.makeACopyDialog.alertMessageCopyTemplateName = false;
    this.makeACopyDialog.alertMessageCopyTemplateNameString = '';
    this.selectedTemplateClone = JSON.parse(JSON.stringify(messageTemplate));
    this.dialogTitle = messageTemplate.title;
    this.makeACopyDialog.title = messageTemplate.title;
    await this.makeACopyDialog.initializeDialog();
    setTimeout(() => {
      this.showMakeACopyDialog = true;
    }, 100);
  }

  async onCloseMakeACopyDialog() {
    setTimeout(() => {
      this.showMakeACopyDialog = false;
      //this.refreshData();
    }, 100);
  }

  async onSaveMakeACopy() {
    var copyResponse = await this.messageTemplatesService.CopyTemplate(
      this.selectedTemplateClone.templateId,
      this.selectedTemplateClone.title,
      this.selectedTemplateClone.isPublishingDb
    );

    if (copyResponse.data == true) {
      this.toastService.showSuccess('Template ' + this.selectedTemplateClone.title + ' succcessfully copied');
      this.makeACopyDialog.alertMessageCopyTemplateName = false;
      this.makeACopyDialog.alertMessageCopyTemplateNameString = '';
      // create template thumbnail in background
      let newMessageTemplateId = copyResponse.message;
      var responseThumbnail = await this.messageTemplatesService.CreateTemplateThumbnailURL(newMessageTemplateId, this.selectedTemplateClone.isPublishingDb);

      this.router.navigate(['/marketing/template-library/edit/' + copyResponse.message]);
      this.onCloseMakeACopyDialog();
      await this.refreshData();
    } else {
      this.makeACopyDialog.saveClicked = false;
      this.makeACopyDialog.alertMessageCopyTemplateName = true;
      this.makeACopyDialog.alertMessageCopyTemplateNameString =
        "Template '" + this.selectedTemplateClone.title + "' already exists.";
      this.toastService.showError(copyResponse.message);
    }
  }

  async onPublishChange(messageTemplate: MessageTemplate, $event) {
    //integarte with backend
    if (!messageTemplate.published) {
      this.messageTemplatesService
        .setPublished(messageTemplate.templateId, messageTemplate.published)
        .subscribe((response) => {
          if (response.status == 200) {
            this.toastService.showSuccess(
              messageTemplate.published
                ? 'Template Activated'
                : 'Template Deactivated'
            );
          } else {
            this.toastService.showError(
              (messageTemplate.published
                ? 'Error Activating! '
                : 'Error Deactivating! ') + response.message
            );
            messageTemplate.published = !messageTemplate.published;
            return false;
          }
        });
    } else {
      var ref = this.dialogService.open(ConfirmModalComponent, {
        width: '650px',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        styleClass: 'confirm-modal',
        baseZIndex: 10000,
        data: {
          message:
            'Please confirm you want to activate template ' +
            messageTemplate.title,
          successButtonText: 'Yes, I want to activate',
          cancelButtonText: 'Continue Editing',
        },
      });

      ref.onClose.subscribe(async (result: boolean) => {
        if (result) {
          this.messageTemplatesService
            .setPublished(messageTemplate.templateId, messageTemplate.published)
            .subscribe((response) => {
              if (response.status == 200) {
                this.toastService.showSuccess(
                  messageTemplate.published
                    ? 'Template Activated'
                    : 'Template Deactivated'
                );
              } else {
                this.toastService.showError(
                  (messageTemplate.published
                    ? 'Error Activating! '
                    : 'Error Deactivating! ') + response.message
                );
                messageTemplate.published = !messageTemplate.published;
                return false;
              }
            });
        } else {
          messageTemplate.published = false;
        }
      });
    }
  }

  async deleteTemplate() {
    if (this.loadingPage) {
      return;
    }

    this.loadingPage = true;
    this.appRef.tick();
    try {
      this.messageTemplatesService
        .DeleteTemplate(this.selectedTemplate.templateId)
        .subscribe(
          (response: RequestResponse) => {
            if (response.status == 200 && response?.message == '') {
              //this.loadingPage = false;
              this.refreshData();
            } else {
              this.loadingPage = false;
              this.toastService.showError(response.message);
            }
          },
          (err) => {
            this.toastService.showError(
              'Error while deleting template: ' + err
            );
            this.loadingPage = false;
          }
        );
    } catch (message: any) {
      this.loadingPage = false;
      console.error(message);
      this.toastService.showError(message);
    }
  }

  onClosePreviewDialog() {
    this.showPreviewDialog = false;
    this.showTextPreviewDialog = false;
    this.showEmailEditor = false;
  }

  getMessageTypeMenuItems(): MenuItem[] {
    let result = [
      {
        label: 'Email',
        command: () =>
          this.router.navigate(['/marketing/template-library/email']),
      },
      {
        label: 'Text',
        command: () =>
          this.router.navigate(['/marketing/template-library/sms']),
      },
    ];
    return result;
  }

  loadAllCategoryTemplates(event: any) {
    this.categoryId = '00000000-0000-0000-0000-000000000000';
    this.selectedCategoryItem = -1;
    if (this.webAPI.paging.currentPage != 1) {
      this.webAPI.paging.currentPage = 1;
      this.onGotoPage();
    } else {
      this.refreshData();
    }
  }

  loadCategoryTemplates(event: any, category: any, index: any) {
    this.categoryId = category.item_id;
    this.selectedCategoryItem = index;
    if (this.webAPI.paging.currentPage != 1) {
      this.webAPI.paging.currentPage = 1;
      this.onGotoPage();
    } else {
      this.refreshData();
    }
  }
}
