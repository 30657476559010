<div class="submit-a-ticket-main">
  <div class="content table-search-row second-header-fixed">
    <div class="text-wrapper">
      <div class="align-items-center d-flex justify-content-center">
        <label id="lblPageHeader">Submit a Ticket </label>
      </div>
    </div>
  </div>
  <div *ngIf="isStart()" class="m-auto" style="max-width:500px;">
    <div class="mt-5">
      <form [formGroup]="formGroup" class="grid-section form-container" (ngSubmit)="onSubmit()">
        <div class="row mb-4 ">
          <div class="col-6">
            <label class="form-label fw-bold">Name</label>
            <input required maxlength="50" class="form-control"  placeholder="First Name" formControlName="firstName" pInputText
                   aria-describedby="message-firstName-error">
            <small id="message-firstName-error" *ngIf="formControls['firstName'].errors?.required"
                   class="p-error block fs-6">
              Name is required.
            </small>
          </div>
          <div class="col-6">
            <label class="form-label fw-bold">Last Name</label>
            <input required maxlength="50" class="form-control"  placeholder="Last Name"  formControlName="lastName" pInputText
                   aria-describedby="message-last-name-error">
            <small id="message-last-name-error" *ngIf="formControls['lastName'].errors?.required"
                   class="p-error block fs-6">
              Last Name is required.
            </small>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label class="form-label fw-bold">Email</label>
            <input required maxlength="60" type="email" placeholder="Email Address" class="form-control" formControlName="email" pInputText
                   aria-describedby="email-error">
            <small id="email-error" *ngIf="formControls['email'].errors?.required" class="p-error block fs-6">
              Email address is required.
            </small>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <label class="form-label fw-bold">Company Name</label>
            <input required maxlength="50" placeholder="Company Name" type="text" class="form-control" formControlName="companyName" pInputText
                   aria-describedby="message-company-error">
            <small id="message-company-error" *ngIf="formControls['companyName'].errors?.required"
                   class="p-error block fs-6">Company name is required.</small>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label class="form-label fw-bold">Contact Phone Number</label>
            <input class="form-control" placeholder="Phone Number" formControlName="phoneNumber" pInputText>
            <small id="message-phone-error" *ngIf="formControls['phoneNumber'].errors?.pattern"
                   class="p-error block fs-6">Contact Phone Number is invalid.</small>
          </div>
        </div>
        <div class="row mb-4 ">
          <div class="col">
            <label class="form-label fw-bold">Issue Subject</label><br>
            <select name="subject" id="subject" formControlName="subject" class="form-control form-input" aria-describedby="message-subject-error"
            [class.ng-invalid]="(isFormSubmitted ||formControls['subject'].touched)  && !formControls['subject'].value">
              <option value="Password Reset">Password Reset</option>
              <option value="Billing">Billing</option>
              <option value="Error Message">Error Message</option>
              <option value="Templates">Templates</option>
              <option value="Training">Training</option>
              <option value="Other">Other</option>
            </select>
            <small id="message-subject-error" *ngIf="(isFormSubmitted ||formControls['subject'].touched) && !formControls['subject'].value"
                   class="p-error block fs-6">Subject is required.</small>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <label class="form-label fw-bold">Describe your issue</label>
            <textarea required maxlength="500" class="form-control" formControlName="description" aria-describedby="message-description-error"
            [class.ng-invalid]="(isFormSubmitted ||formControls['description'].touched) && !formControls['description'].value"></textarea>
            <small id="message-description-error" *ngIf="(isFormSubmitted ||formControls['description'].touched) && !formControls['description'].value"
                   class="p-error block fs-6">Description is required.</small>
          </div>
        </div>
        <div class="row mb-4" style="display:none;">
          <div class="col">
            <input formControlName="fileUpload" (change)="onFileSelected($event)" type="file" class="d-none"
                   #fileUpload>
            <button pButton pRipple type="button" class="p-button p-button-outlined p-button-rounded"
                    (click)="fileUpload.click()">
              <i role="button" class="pi pi-upload mx-2"></i>
              <span>Upload a File</span>
            </button>
            <span style="padding-left:5px;">{{fileName}}</span>
          </div>
        </div>
        <div class="mb-4">
          <button pButton pRipple class="p-button p-button-rounded">Submit</button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="isSuccess()" class="ticket-response"  >
    <p>
      Thank you for submitting your response to the focusIT Help Desk!
    </p>
    <p>
      Your ticket number is #{{responseTicket.ticketID}}. Your support case has been opened and we'll be in touch
      shortly.
    </p>
    <p>
      Urgent Issue? Call: 480-346-1299, Option 1
    </p>
  </div>
  <div>
    <div *ngIf="isFailure()" class="ticket-response">
      <p>
        An error has occurred {{responseTicket.message}}
      </p>
    </div>
  </div>
</div>
