import { BitwiseService } from 'src/services/bitwise.service';
import { ContactTypeService } from 'src/services/contactType.service';

/** 
* Implementation of the FocusIT.DataContracts.Pulse.NGENMasterContact [DataContract].
*/
export class NGENMasterContact {

  public TenantId: string = '00000000-0000-0000-0000-000000000000';
  public ContactTypes: number = 0;
  public Name: string = null;
  public Email: string = null;
  public MobilePhone: string = null;
  public WorkPhone: string = null;
  public Fax: string = null;
  public HomePhone: string = null;
  public Street: string = null;
  public City: string = null;
  public State: string = null;
  public Zip: string = null;
  public BirthDate: Date = null;
  public SSN: string = null;
  public Veteran: boolean = false;
  public Signature: string = null;
  public Picture: any = null;
  public ReferralSource: string = null;
  public Enabled: boolean = false;
  public LoanOriginationSystem: number = 0;
  public CreatedDateUTC: Date = null;
  public LastModifiedDateUTC: Date = null;
  public Hash: string = '';
  public Username: string = null;
  public Password: string = null;
  public Permissions: number = 0;
  public Owner: string = null;
  public AccountVerified: boolean = false;
  public FirstName: string = null;
  public LastName: string = null;
  public MiddleName: string = null;
  public DisplayContactType: string = null;
  public CompanyName: string = null;
  public UserSettings: string = null;

  public NameSuffix: string = null;
  public Gender: string = null;
  public StateLicenses: string = null;
  public NmlsId: string = null;
  public FacebookURL: string = null;
  public TwitterURL: string = null;
  public LinkedInURL: string = null;
  public LeadIntakeURL: string = null;
  public LeadIntakeDescription: string = null;
  public ContactId: string = '00000000-0000-0000-0000-000000000000';

  // Computed
  public DisplayCreatedDate: string = '';
  public DisplayLongBirthday: string = '';
  public DisplayVeteran: string = '';
  public DisplayBirthday: string = '';
  public DisplayBirthDate: string = '';
  public DisplaySSN: string = '';
  public DisplayOptedIn: string = '';

  public DisplayMobilePhone: string = '';
  public DisplayWorkPhone: string = '';
  public DisplayHomePhone: string = '';
  public DisplayFax: string = '';

  public MaskedSSN: string = '';
  public ShowCompany: boolean = false;
  public CanUploadImage: boolean = false;
  public IsLead: boolean = false;
  public IsBorrower: boolean = false;
  public IsCoBorrower: boolean = false;


  /**
  * Constructor - all parameters are optional.
  * Only passed parameters are assigned to their corresponding property.
  * 
  * @param TenantId
  * @param ContactTypes
  * @param Name
  * @param Email
  * @param MobilePhone
  * @param WorkPhone
  * @param Fax
  * @param HomePhone
  * @param Street
  * @param City
  * @param State
  * @param Zip
  * @param BirthDate
  * @param SSN
  * @param Veteran
  * @param Signature
  * @param Picture
  * @param ReferralSource
  * @param Enabled
  * @param LoanOriginationSystem
  * @param CreatedDateUTC
  * @param LastModifiedDateUTC
  * @param Hash
  * @param Username
  * @param Password
  * @param Permissions
  * @param Owner
  * @param AccountVerified
  * @param FirstName
  * @param LastName
  * @param MiddleName
  * @param DisplayContactType
  * @param CompanyName
  * @param UserSettings
  * @param NameSuffix
  * @param Gender
  * @param StateLicenses
  * @param NmlsId
  * @param FacebookURL
  * @param TwitterURL
  * @param LinkedInURL
  * @param LeadIntakeURL
  * @param LeadIntakeDescription
  * @param ContactId
  */
  constructor(
    TenantId?: string,
    ContactTypes?: number,
    Name?: string,
    Email?: string,
    MobilePhone?: string,
    WorkPhone?: string,
    Fax?: string,
    HomePhone?: string,
    Street?: string,
    City?: string,
    State?: string,
    Zip?: string,
    BirthDate?: Date,
    SSN?: string,
    Veteran?: boolean,
    Signature?: string,
    Picture?: any,
    ReferralSource?: string,
    Enabled?: boolean,
    LoanOriginationSystem?: number,
    CreatedDateUTC?: Date,
    LastModifiedDateUTC?: Date,
    Hash?: string,
    Username?: string,
    Password?: string,
    Permissions?: number,
    Owner?: string,
    AccountVerified?: boolean,
    FirstName?: string,
    LastName?: string,
    MiddleName?: string,
    DisplayContactType?: string,
    CompanyName?: string,
    UserSettings?: string,
    NameSuffix?: string,
    Gender?: string,
    StateLicenses?: string,
    NmlsId?: string,
    FacebookURL?: string,
    TwitterURL?: string,
    LinkedInURL?: string,
    LeadIntakeURL?: string,
    LeadIntakeDescription?: string,
    ContactId?: string,
    public ContactTypeDescriptions?: string) {
    if ((TenantId !== undefined) && (TenantId !== null)) this.TenantId = TenantId;
    if ((ContactTypes !== undefined) && (ContactTypes !== null)) this.ContactTypes = ContactTypes;
    if ((Name !== undefined) && (Name !== null)) this.Name = Name;
    if ((Email !== undefined) && (Email !== null)) this.Email = Email;
    if ((MobilePhone !== undefined) && (MobilePhone !== null)) this.MobilePhone = MobilePhone.replace('-', '').replace('-', '');
    if ((WorkPhone !== undefined) && (WorkPhone !== null)) this.WorkPhone = WorkPhone.replace('-', '').replace('-', '');
    if ((Fax !== undefined) && (Fax !== null)) this.Fax = Fax.replace('-', '').replace('-', '');
    if ((HomePhone !== undefined) && (HomePhone !== null)) this.HomePhone = HomePhone.replace('-', '').replace('-', '');
    if ((Street !== undefined) && (Street !== null)) this.Street = Street;
    if ((City !== undefined) && (City !== null)) this.City = City;
    if ((State !== undefined) && (State !== null)) this.State = State;
    if ((Zip !== undefined) && (Zip !== null)) this.Zip = Zip;
    if (BirthDate !== undefined) this.BirthDate = BirthDate;
    if ((SSN !== undefined) && (SSN !== null)) this.SSN = SSN;
    if ((Veteran !== undefined) && (Veteran !== null)) this.Veteran = Veteran;
    if ((Signature !== undefined) && (Signature !== null)) this.Signature = Signature;
    if ((Picture !== undefined) && (Picture !== null)) this.Picture = Picture;
    if ((ReferralSource !== undefined) && (ReferralSource !== null)) this.ReferralSource = ReferralSource;
    if ((Enabled !== undefined) && (Enabled !== null)) this.Enabled = Enabled;
    if ((LoanOriginationSystem !== undefined) && (LoanOriginationSystem !== null)) this.LoanOriginationSystem = LoanOriginationSystem;
    if (CreatedDateUTC !== undefined) this.CreatedDateUTC = CreatedDateUTC;
    if (LastModifiedDateUTC !== undefined) this.LastModifiedDateUTC = LastModifiedDateUTC;
    if ((Hash !== undefined) && (Hash !== null)) this.Hash = Hash;
    if ((Username !== undefined) && (Username !== null)) this.Username = Username;
    if ((Password !== undefined) && (Password !== null)) this.Password = Password;
    if ((Permissions !== undefined) && (Permissions !== null)) this.Permissions = Permissions;
    if ((Owner !== undefined) && (Owner !== null)) this.Owner = Owner;
    if ((AccountVerified !== undefined) && (AccountVerified !== null)) this.AccountVerified = AccountVerified;
    if ((FirstName !== undefined) && (FirstName !== null)) this.FirstName = FirstName;
    if ((LastName !== undefined) && (LastName !== null)) this.LastName = LastName;
    if ((MiddleName !== undefined) && (MiddleName !== null)) this.MiddleName = MiddleName;
    if ((DisplayContactType !== undefined) && (DisplayContactType !== null)) this.DisplayContactType = DisplayContactType;
    if ((CompanyName !== undefined) && (CompanyName !== null)) this.CompanyName = CompanyName;
    if ((UserSettings !== undefined) && (UserSettings !== null)) this.UserSettings = UserSettings;

    if ((NameSuffix !== undefined) && (NameSuffix !== null)) this.NameSuffix = NameSuffix;
    if ((Gender !== undefined) && (Gender !== null)) this.Gender = Gender;
    if ((StateLicenses !== undefined) && (StateLicenses !== null)) this.StateLicenses = StateLicenses;
    if ((NmlsId !== undefined) && (NmlsId !== null)) this.NmlsId = NmlsId;
    if ((FacebookURL !== undefined) && (FacebookURL !== null)) this.FacebookURL = FacebookURL;
    if ((TwitterURL !== undefined) && (TwitterURL !== null)) this.TwitterURL = TwitterURL;
    if ((LinkedInURL !== undefined) && (LinkedInURL !== null)) this.LinkedInURL = LinkedInURL;
    if ((LeadIntakeURL !== undefined) && (LeadIntakeURL !== null)) this.LeadIntakeURL = LeadIntakeURL;
    if ((LeadIntakeDescription !== undefined) && (LeadIntakeDescription !== null)) this.LeadIntakeDescription = LeadIntakeDescription;
    if ((ContactId !== undefined) && (ContactId !== null)) this.ContactId = ContactId;

    var helper: ContactTypeService = new ContactTypeService(new BitwiseService());
    this.ContactTypeDescriptions = helper.Descriptions(this.ContactTypes);
    this.IsLead = helper.IsLead(this.ContactTypes);
    this.IsBorrower = helper.IsBorrower(this.ContactTypes);
    this.IsCoBorrower = helper.IsCoBorrower(this.ContactTypes);
    this.ShowCompany = this.canShowCompany(this.ContactTypes);
    this.CanUploadImage = this.canUploadImage(this.ContactTypes);

    this.DisplayCreatedDate = this.toDisplayDate(this.CreatedDateUTC);
    this.DisplayVeteran = this.toYesNo(this.Veteran);
    this.DisplayBirthday = this.toDisplayDate(this.BirthDate);
    this.DisplayLongBirthday = this.toLongDisplayDate(this.BirthDate);
    this.DisplaySSN = this.toDisplaySSN(this.SSN);
    this.MaskedSSN = this.toMaskedSSN(this.SSN);
    this.DisplayOptedIn = this.toYesNo(this.Enabled);

    this.DisplayMobilePhone = this.formatPhone(this.MobilePhone);
    this.DisplayWorkPhone = this.formatPhone(this.WorkPhone);
    this.DisplayHomePhone = this.formatPhone(this.HomePhone);
    this.DisplayFax = this.formatPhone(this.Fax);
  }

  formatPhone(phone: string) {
    if ((phone == null) || (phone.length != 10))
      return '';

    return '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
  }

  /**
   * Returns 'Yes' if the value it true, 'No' otherwise.
   * 
   * @param value
   */
  toYesNo(value: boolean) {
    if ((value == undefined) || (value == null))
      return '';

    if (value)
      return 'Yes';
    return 'No';
  }

  /**
   * Returns true if 'Yes' us passed, false otherwise.
   * 
   * @param value
   */
  toBoolean(value: string) {
    if ((value == undefined) || (value == null))
      return false;

    if (value.toLowerCase() == 'yes')
      return true;
    return false;
  }

  toDisplaySSN(value: string) {

    if ((value == undefined) || (value == null))
      return '';

    var unmasked = value.replace('-', '').replace('-', '');
    if (unmasked.length != 9)
      return '';

    return (unmasked.substring(0, 3) + '-' + unmasked.substring(3, 5) + '-' + unmasked.substring(5, 9));
  }

  toMaskedSSN(value: string) {

    if ((value == undefined) || (value == null))
      return '';

    var unmasked = value.replace('-', '').replace('-', '');
    if (unmasked.length != 9)
      return '';

    return ('XXX-XX-' + unmasked.substring(5, 9));
  }

  toDisplayDate(value: any) {

    if ((value == undefined) || (value == null))
      return '';

    var date = new Date(value);

    var month = '00' + (date.getMonth() + 1);
    month = month.substring(month.length - 2, month.length);
    var day = '00' + date.getDate();
    day = day.substring(day.length - 2, day.length);

    return (month + '/' + day + '/' + date.getFullYear().toString());
  }

  toLongDisplayDate(value: any) {

    if ((value == undefined) || (value == null))
      return '';

    var months: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    var date = new Date(value);
    var result = '';
    result = months[date.getMonth()];
    result += ' ';
    result += date.getDate();

    var suffix = '';
    switch (date.getDate()) {
      case 1:
      case 21:
      case 31:
        suffix = 'st, '
        break;
      case 2:
      case 22:
        suffix = 'nd, '
        break;
      case 3:
      case 23:
        suffix = 'rd, '
        break;
      default:
        suffix = 'th, '
        break;
    }
    result += suffix;

    result += ' ';
    result += date.getFullYear();

    return result;
  }
 
  canShowCompany(value: any) {
    if ((value == undefined) || (value == null))
      return false;

    let helper: ContactTypeService = new ContactTypeService(new BitwiseService());
    if (helper.IsAppraiser(value))
      return true;
    if (helper.IsTitleInsurance(value))
      return true;
    if (helper.IsHazardInsurance(value))
      return true;
    if (helper.IsClosingAgent(value))
      return true;
    if (helper.IsReferral(value))
      return true;
    if (helper.IsRealtor(value))
      return true;

    return false;
  }

  canUploadImage(value: any) {
    if ((value == undefined) || (value == null))
      return false;

    let helper: ContactTypeService = new ContactTypeService(new BitwiseService());
    if (helper.IsRealtor(value) ||
        helper.IsPulseUser(value))
      return true;

    return false;
  }

  ///**
  // * Parses the contact's Name and sets the FirstName and LastName properties.
  // * 
  // * @param contact
  // */
  //setFirstAndLastName(contact: NGENMasterContact) {
  //  // No NGENMasterContact
  //  if ((contact == undefined) || (contact == null))
  //    return;

  //  // No Name
  //  if ((contact.Name == undefined) || (contact.Name == null)) {
  //    return;
  //  }

  //  // Compute First and Last Name properties
  //  try {
  //    if (contact.Name.length > 0) {
  //      var n = contact.Name.indexOf(" ");
  //      if (n > 0) {
  //        contact.FirstName = contact.Name.substring(0, n);
  //        contact.LastName = contact.Name.substring(n + 1);
  //      }
  //    }
  //  }
  //  catch { }
  //}
}

/**
* Collection of FocusIT.DataContracts.Pulse.NGENMasterContact [DataContract] objects.
*/
export class NGENMasterContactCollection {
  Items: NGENMasterContact[] = [];
}

